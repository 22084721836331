import {
  ACTION,
  Button,
  ButtonVariants,
  Dialog,
  FormControlSizes,
  IconName,
  ModeEnum,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Text,
  Tooltip,
  useDisclosure,
  useDynamicCallback,
} from '@talos/kyoko';
import { useBookAndModifyTradeDialog } from 'containers/Blotters/CustomerTrades/Dialogs/useBookAndModifyTradeDialog';
import { OrderDetailsTab } from 'containers/Trading/Markets/OrderDetails/OrderDetailsTab';
import { usePricingRules } from 'providers';
import { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRoleAuth } from '../../../hooks';
import { BookAndModifyTradeDialog } from '../../Blotters/CustomerTrades/Dialogs';
import { Blotters } from './Blotters';

const monitoringRoutes = [
  {
    id: 'blotters',
    path: '/dealer/monitoring/:id?',
    exact: true,
    component: Blotters,
  },
  {
    id: 'order-details',
    path: '/dealer/monitoring/order/:orderID',
    exact: false,
    component: ({ widthRef }) => <OrderDetailsTab widthRef={widthRef} />,
  },
  {
    id: 'customer-order-details',
    path: '/dealer/monitoring/customer-order/:orderID',
    exact: false,
    component: ({ widthRef }) => <OrderDetailsTab widthRef={widthRef} />,
  },
];

// TODO This component should match the better pattern we have in Monitoring/index.tsx where we have mixed entities able to exist in the same tabs context.
// this would allow us to have a better concept of "temporary tabs" within dealer monitoring.
export function Monitoring() {
  const { isAuthorized } = useRoleAuth();
  const authorizedToToggleTrading = isAuthorized(ACTION.EDIT_DEALER_TRADING);

  const bookModifyTradeDialog = useBookAndModifyTradeDialog();

  const { globalDefault, updatePricingRule } = usePricingRules();
  const [tradingEnabledLoading, setTradingEnabledLoading] = useState<boolean>(true);
  useEffect(() => {
    setTradingEnabledLoading(globalDefault == null);
  }, [globalDefault]);
  const tradingEnabled = globalDefault?.Mode === ModeEnum.Enabled;
  const tradingConfirmDialog = useDisclosure();

  const handleClickNewCustomerTrade = useDynamicCallback(() => {
    bookModifyTradeDialog.resetForm();
    bookModifyTradeDialog.open();
  });

  const widthRef = useRef<HTMLDivElement | null>(null);

  return (
    <Panel>
      <PanelHeader>
        <h2>Monitoring</h2>
        <PanelActions>
          <Button
            size={FormControlSizes.Default}
            variant={ButtonVariants.Default}
            startIcon={IconName.Plus}
            onClick={handleClickNewCustomerTrade}
            disabled={!isAuthorized(ACTION.DEALER_TRADING)}
            data-testid="new-customer-trade"
          >
            New Customer Trade
          </Button>
          <Tooltip tooltip={authorizedToToggleTrading ? null : 'Contact an Admin to modify this setting.'}>
            <Button
              disabled={!authorizedToToggleTrading || tradingEnabledLoading}
              size={FormControlSizes.Default}
              variant={tradingEnabled ? ButtonVariants.Positive : ButtonVariants.Negative}
              startIcon={IconName.LockClosed}
              onClick={() => tradingConfirmDialog.open()}
            >
              Trading is {tradingEnabled ? 'Enabled' : 'Disabled'}
            </Button>
          </Tooltip>
        </PanelActions>
      </PanelHeader>
      <PanelContent ref={widthRef}>
        <Switch>
          {monitoringRoutes.map(tab => (
            <Route path={tab.path} exact={tab.exact} key={tab.id}>
              {tab.component({ widthRef })}
            </Route>
          ))}
          <Redirect to="/dealer/monitoring" />
        </Switch>
      </PanelContent>
      <Dialog
        {...tradingConfirmDialog}
        onConfirm={() => {
          setTradingEnabledLoading(true);
          updatePricingRule({ ...globalDefault, Mode: tradingEnabled ? ModeEnum.Disabled : ModeEnum.Enabled });
        }}
        confirmLabel={tradingEnabled ? 'Disable Trading' : 'Enable Trading'}
        width={350}
        variant={tradingEnabled ? ButtonVariants.Negative : ButtonVariants.Positive}
        title="Confirmation"
      >
        <Text>
          Please confirm action to <strong>{tradingEnabled ? 'Disable' : 'Enable'}</strong> Customer Trading
        </Text>
      </Dialog>
      <BookAndModifyTradeDialog {...bookModifyTradeDialog} />
    </Panel>
  );
}
