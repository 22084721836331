import {
  Box,
  Dialog,
  Flex,
  NotificationVariants,
  OrderStatus,
  Put,
  abbreviateId,
  useGlobalToasts,
  useUserContext,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import { useCallback } from 'react';

import { useCustomerOrder } from 'hooks';

interface CancelOrderDialogProps extends UseDisclosureReturn {
  customerOrderID?: string;
}

export const CancelOrderDialog = ({ customerOrderID, ...props }: CancelOrderDialogProps) => {
  const { orgApiEndpoint } = useUserContext();
  const { add: addToast } = useGlobalToasts();
  const order = useCustomerOrder({ orderID: customerOrderID, tag: 'CancelOrderDialog' });

  const cancelOrder = useCallback(() => {
    Put(orgApiEndpoint as string, `/customer/orders/${customerOrderID}/cancel`)
      .then(() => {
        addToast({
          text: `Order canceled.`,
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) =>
        addToast({
          text: `Could not cancel order: ${e.message}`,
          variant: NotificationVariants.Negative,
        })
      );
  }, [addToast, customerOrderID, orgApiEndpoint]);

  if (order == null) {
    return null;
  }

  return (
    <Dialog
      {...props}
      confirmLabel="Cancel Order"
      cancelLabel="Return"
      title="Confirm cancellation"
      onConfirm={cancelOrder}
    >
      <Box>
        <Flex justifyContent="space-between">
          <h3>
            Order #<b>{abbreviateId(order.OrderID)}</b>
          </h3>
          <OrderStatus
            ordStatus={order.OrdStatus}
            decisionStatus={order.DecisionStatus}
            pricingMode={order.PricingMode}
          />
        </Flex>
        <p>Are you sure you want to cancel this order?</p>
      </Box>
    </Dialog>
  );
};
