import { Order, useObservable, useSubscription, type RequestStream } from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { filter, map } from 'rxjs/operators';

/**
 * Performs a WS subscription to `Order` given an `orderID`, and maintains the state of that order
 * in a `useState`. No subscription is made if `orderID` is missing. If `orderID` is provided, but
 * the order does not exist in the system, the returned state will not be updated as the order
 * could exist in the future.
 * @param orderID string
 * @param parentOrderID string
 * @param parentRFQID string
 * @param tag string
 */
export function useOrder({
  orderID,
  parentOrderID,
  parentRFQID,
  tag,
}: {
  orderID?: string;
  parentOrderID?: string;
  parentRFQID?: string;
  tag: string;
}): Order | undefined {
  const [request, setRequest] = useState<RequestStream | null>(null);
  const [order, setOrder] = useState<Order>();
  const { data: subscription } = useSubscription(request, { replay: false });
  const observable = useObservable(
    () =>
      subscription.pipe(
        filter((json: { data: Order[] }) => json.data.length > 0),
        map((json: { data: Order[] }) => new Order(json.data.at(-1)!))
      ),
    [subscription]
  );
  useEffect(() => {
    setOrder(undefined);
    if (orderID == null && parentOrderID == null && parentRFQID == null) {
      setRequest(null);
    } else {
      const sub = observable.subscribe(setOrder);
      setRequest({
        tag: `${tag}/useOrder`,
        name: 'Order',
        IncludeHedgeOrderStatus: true,
        ...(orderID != null && { OrderID: orderID }),
        ...(parentOrderID != null && { ParentOrderID: parentOrderID }),
        ...(parentRFQID != null && { ParentRFQID: parentRFQID }),
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, [observable, orderID, parentOrderID, parentRFQID, tag]);
  return order;
}
