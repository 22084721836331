import {
  FormRowStatus,
  ModeEnum,
  NotificationVariants,
  PositionSideTypeEnum,
  promisesHaveResolved,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  type ISubaccount,
  type RequiredProperties,
  type SubAccountWindowLimit,
  type UseFormTableProps,
} from '@talos/kyoko';

import { useCallback, useMemo, useState } from 'react';

import { useSubAccountWindowLimits } from 'providers';
import { useSubAccountLimitsForSubAccount, useSubAccountOptionsForSubAccount } from '../utils';
import { useColumns } from './useColumns';
import { validateWindowLimit } from './validation';

interface UseSubAccountWindowLimitsBlotterProps {
  forSubAccount?: ISubaccount;
  quickSearchParams?: UseFormTableProps<SubAccountWindowLimit>['quickSearchParams'];
}

export const useSubAccountWindowLimitsBlotter = ({
  forSubAccount,
  quickSearchParams,
}: UseSubAccountWindowLimitsBlotterProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { add: addToast } = useGlobalToasts();
  const {
    subAccountWindowLimitsList,
    createSubAccountWindowLimit,
    updateSubAccountWindowLimit,
    deleteSubAccountWindowLimit,
  } = useSubAccountWindowLimits();

  const { forSubAccountParentLimits } = useSubAccountLimitsForSubAccount({
    limitsList: subAccountWindowLimitsList,
    forSubAccount,
  });

  const data = useMemo(() => {
    return forSubAccount ? forSubAccountParentLimits : subAccountWindowLimitsList;
  }, [forSubAccount, forSubAccountParentLimits, subAccountWindowLimitsList]);

  const subAccountOptionsOverride = useSubAccountOptionsForSubAccount({ forSubAccount });

  const getSubAccountOptionsGroup = useCallback(
    (subAccount: ISubaccount | undefined) => {
      return subAccount === undefined
        ? 'Wildcard'
        : subAccount.Name === forSubAccount?.Name
        ? 'Selected Sub Account'
        : 'Parent Rollups';
    },
    [forSubAccount]
  );

  const columns = useColumns({
    subAccountOptionsOverride,
    initialSelectedItem: forSubAccount,
    getDropdownGroup: forSubAccount ? getSubAccountOptionsGroup : undefined,
  });

  const formTable = useFormTable<Partial<SubAccountWindowLimit>>({
    rowID: 'LimitID' satisfies keyof SubAccountWindowLimit,
    data,
    columns,
    quickSearchParams,
  });

  const handleAdd = useDynamicCallback(() => {
    formTable.addRow({
      Mode: ModeEnum.Enabled,
      Direction: PositionSideTypeEnum.Both,
      SubAccount: forSubAccount?.Name,
    });
  });

  const handleSave = useDynamicCallback(() => {
    setIsSaving(true);

    const rows = formTable.getRows();
    const requests: Promise<unknown>[] = [];

    try {
      for (const row of rows) {
        validateWindowLimit(row.data);
      }
    } catch (e) {
      // it is an Error but this is how you need to type it
      if (e instanceof Error) {
        addToast({
          text: `Could not save window limits: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      }
      setIsSaving(false);
      return;
    }

    for (const row of rows) {
      const data = row.data;
      switch (row.status) {
        case FormRowStatus.Added:
          requests.push(
            createSubAccountWindowLimit(row.data)
              .then(({ data }) => {
                row.setData(data[0]);
                return data[0];
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not add new Sub Account Window Limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        case FormRowStatus.Updated:
          if (!rowDataHasLimitID(data)) {
            break;
          }
          requests.push(
            updateSubAccountWindowLimit(data)
              .then(() => {
                row.setData(row.data);
                return row.data;
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not update Sub Account Window Limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        case FormRowStatus.Removed:
          if (!rowDataHasLimitID(data)) {
            break;
          }
          requests.push(
            deleteSubAccountWindowLimit(data.LimitID)
              .then(() => {
                row.remove(true);
                return row.data;
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not delete Sub Account Window Limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        default:
          break;
      }
    }
    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Sub Account Window Limits saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Sub Account Window Limits could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  });

  return {
    onAdd: handleAdd,
    onSave: handleSave,
    formTable,
    isSaving,
    nDataEntries: data?.length ?? 0,
  };
};

function rowDataHasLimitID(
  data: Partial<SubAccountWindowLimit>
): data is RequiredProperties<Partial<SubAccountWindowLimit>, 'LimitID'> {
  return data.LimitID != null;
}
