import type { Currency } from '../../../types';
import { InlineFormattedNumber, type InlineFormattedNumberProps } from '../../FormattedNumber';
import { Case, Default, Switch } from '../../Switch';
import { Text } from '../../Text';
import { ChartTooltipValueType } from './types';

export function ChartTooltipValue({
  data,
  valueType,
  currency,
  unit,
  ...props
}: {
  data: number | null | undefined;
  valueType: ChartTooltipValueType;
  currency?: Currency;
  unit?: string;
} & Omit<InlineFormattedNumberProps, 'currency'>) {
  return data != null ? (
    <Switch value={valueType}>
      <Case value={ChartTooltipValueType.currency}>
        <InlineFormattedNumber
          {...props}
          number={data}
          increment={currency?.DefaultIncrement ?? '0.01'}
          round={true}
          currency={currency?.Symbol}
        />
      </Case>
      <Case value={ChartTooltipValueType.bps}>
        <InlineFormattedNumber {...props} number={data} increment="0.01" round={true} currency="bps" />
      </Case>
      <Case value={ChartTooltipValueType.percent}>
        <InlineFormattedNumber {...props} number={data * 100} increment="0.01" round={true} currency="%" />
      </Case>
      <Case value={ChartTooltipValueType.milliseconds}>
        <InlineFormattedNumber {...props} number={data} increment="0.01" round={true} currency="ms" />
      </Case>
      <Case value={ChartTooltipValueType.number}>
        <InlineFormattedNumber {...props} number={data} currency={unit} />
      </Case>
      <Default>
        <Text>{data}</Text>
      </Default>
    </Switch>
  ) : (
    <Text>n/a</Text>
  );
}
