import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { usePortal } from '../../hooks';
import { useAppwideDrawerContext } from '../../providers/AppwideDrawerProvider';
import { Box, HStack } from '../Core';
import { Drawer } from '../Drawer';
import { Icon, ICON_SIZES, IconName } from '../Icons';
import { Tab, TabAppearance, TabButton, TabList, Tabs, TabSize } from '../Tabs';

export const AppwideDrawer = ({ enableFlexibleLayout }: { enableFlexibleLayout?: boolean }) => {
  const { drawer, contentSpec } = useAppwideDrawerContext();

  // Whenever the user changes the spec, we guarantee a remount of the drawer content.
  // React / JSX is tricky when you are doing stuff like passing in a function to render a component across react life cycles.
  // Doing this just makes things simpler. There may very well be much better ways of handling this whole work flow.
  const contentSpecHash = useMemo(() => (contentSpec ? uuid() : undefined), [contentSpec]);

  const location = useLocation();
  // Flexible layout uses a wider gap between layout elements
  const adjustedMargin =
    enableFlexibleLayout && location?.pathname.includes('trading') ? '-spacingLayout' : '-spacingTiny';

  const { setPortalRef } = usePortal('drawer-portal');

  return (
    <Drawer {...drawer} adjustForGapMargin={adjustedMargin} data-testid="app-wide-drawer">
      <HStack
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="backgroundBody"
        background="backgroundFilledTabList"
      >
        <Tabs appearance={TabAppearance.Filled} size={TabSize.Small} selectedIndex={0}>
          <TabList>
            <Tab label={contentSpec?.title} data-testid="app-wide-drawer-title" />
          </TabList>
        </Tabs>
        <HStack>
          {contentSpec?.renderHeaderContent && contentSpec?.renderHeaderContent()}
          <TabButton
            size={TabSize.Small}
            onClick={() => drawer.close()}
            suffix={<Icon icon={IconName.Close} size={ICON_SIZES.SMALL} />}
            appearance={TabAppearance.Filled}
          />
        </HStack>
      </HStack>

      <Box ref={setPortalRef} flex="1" key={contentSpecHash} style={{ overflowY: 'auto' }}>
        {contentSpec?.renderContent?.()}
      </Box>
    </Drawer>
  );
};
