import { getBlockchainAddressesForCurrency, truncateBlockchainAddress, type MarketAccount } from '@talos/kyoko';

export const getDestinationAddressDescription = (ma: MarketAccount, currency: string) => {
  const destinationAddresses = getBlockchainAddressesForCurrency(ma, currency);
  if (!destinationAddresses) {
    return '';
  }
  const firstAddress = destinationAddresses?.at(0)?.Address;
  if (destinationAddresses.length === 1 && firstAddress) {
    return `Address: ${truncateBlockchainAddress(firstAddress)}`;
  } else if (destinationAddresses.length > 1) {
    return 'Multiple Deposit Addresses';
  }
  return '';
};
