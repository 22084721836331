import { Get, MarketAccountStatusEnum, Post, useUserContext } from '@talos/kyoko';
import { useCallback } from 'react';

export function useTradingControls() {
  const { orgApiEndpoint } = useUserContext();

  const toggleTrading = useCallback(
    (marketAcountName: string, mode: MarketAccountStatusEnum) =>
      Post(
        orgApiEndpoint as string,
        `/market-accounts/${mode === MarketAccountStatusEnum.Active ? 'enable' : 'disable'}/${marketAcountName}`,
        null,
        {}
      ),
    [orgApiEndpoint]
  );

  const getMarketAccountHistory = useCallback(
    (marketAcountName: string) =>
      Get(orgApiEndpoint as string, `/market-accounts-history?MarketAccounts=${marketAcountName}`),
    [orgApiEndpoint]
  );

  return {
    toggleTrading,
    getMarketAccountHistory,
  };
}
