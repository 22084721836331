import { Dialog, Divider, IconName, Text, VStack, type DialogProps, type MarketCredential } from '@talos/kyoko';
import { SelectedCredentialsList } from './SelectedCredentialsList';

export const RemoveSelectedDialog = <T extends MarketCredential>({
  selectedItems,
  removeDialog,
  onConfirm,
}: {
  selectedItems: T[];
  removeDialog: DialogProps;
  onConfirm: (credentials: T[]) => void;
}) => {
  if (selectedItems.length === 0) {
    return null;
  }
  const titleText = `Delete ${selectedItems.length} Credential${selectedItems.length > 1 ? 's' : ''}`;
  const description = `Are you sure you want to delete ${selectedItems.length} credential${
    selectedItems.length > 1 ? 's' : ''
  }?`;
  return (
    <Dialog
      {...removeDialog}
      onConfirm={() => {
        onConfirm(selectedItems);
      }}
      confirmLabel="Confirm"
      cancelLabel="Return"
      title={titleText}
      headerIcon={IconName.ExclamationSolid}
      headerIconColor="colorTextWarning"
      stretchButtons
    >
      <VStack gap="spacingDefault" alignItems="stretch">
        <Text textAlign="left" color="colorTextAttention">
          {description}
        </Text>
        <Divider />
        <SelectedCredentialsList credentials={selectedItems} />
      </VStack>
    </Dialog>
  );
};
