import { useExposures } from 'providers';
import { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Button,
  ButtonVariants,
  FormRowStatus,
  FormTable,
  HStack,
  IconName,
  MarketAccountTypeEnum,
  ModeEnum,
  NotificationVariants,
  Text,
  VStack,
  promisesHaveResolved,
  useFormTable,
  useGlobalToasts,
  useMarketAccountsContext,
  type Column,
  type ColumnDef,
  type ExposureLimit,
} from '@talos/kyoko';

import { Loader, LoaderWrapper } from 'components/Loader';
import { LIMIT_CURRENCIES_MAP } from '../utils';

const DEFAULT_EXPOSURE_DEFINITION = 'OwedNOP';

export const CounterpartyLimits = () => {
  const { marketAccountsByName } = useMarketAccountsContext();
  const [isSaving, setIsSaving] = useState(false);
  const { add: addToast } = useGlobalToasts();

  const { exposureLimits, createExposureLimit, updateExposureLimit, deleteExposureLimit } = useExposures();

  const tradingMarketAccountsByName = useMemo(() => {
    const tradingMarketAccounts = new Map(marketAccountsByName);
    for (const [name, marketAccount] of tradingMarketAccounts) {
      if (marketAccount.Type !== MarketAccountTypeEnum.Trading) {
        tradingMarketAccounts.delete(name);
      }
    }
    return tradingMarketAccounts;
  }, [marketAccountsByName]);

  const columns = useMemo(
    () =>
      [
        { type: 'mode', field: 'Mode', title: 'Enabled', width: 80 },
        {
          type: 'marketAccount',
          field: 'MarketAccount',
          editable: true,
          params: { marketAccountsByName: tradingMarketAccountsByName },
        },
        {
          type: 'number',
          field: 'Qty',
          title: 'Threshold',
          editable: true,
        },
        {
          type: 'currency',
          field: 'ExposureCurrency',
          title: 'Threshold Currency',
          editable: true,
          params: {
            currencies: LIMIT_CURRENCIES_MAP,
          },
        },
        { type: 'filler', id: 'filler' },
        { type: 'remove', id: 'remove' },
      ] satisfies ColumnDef<ExposureLimit>[] as Column[],
    [tradingMarketAccountsByName]
  );

  const formTable = useFormTable<Partial<ExposureLimit>>({
    rowID: 'ExposureLimitID',
    data: exposureLimits ?? [],
    columns,
  });

  const handleNewExposureLimitButton = useCallback(() => {
    formTable.addRow({
      Mode: ModeEnum.Enabled,
      Qty: '',
      MarketAccount: '',
      Counterparty: '',
      ExposureCurrency: '',
      ExposureDefinition: '',
    });
  }, [formTable]);

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const rows: any[] = formTable.getRows();
    const requests: any[] = [];
    for (const row of rows) {
      switch (row.status) {
        case FormRowStatus.Added:
          requests.push(
            createExposureLimit({
              Mode: row.data.Mode,
              // Counterparty is left for backwards compability, it should be optional in the API as the backend can figure it out based on the marketAccount
              Counterparty: marketAccountsByName.get(row.data.MarketAccount)?.Market,
              MarketAccount: row.data.MarketAccount,
              Qty: row.data.Qty,
              ExposureCurrency: row.data.ExposureCurrency,
              ExposureDefinition: DEFAULT_EXPOSURE_DEFINITION,
            })
              .then(({ data }) => {
                row.setData(data[0]);
                return data[0];
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not add new exposure limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        case FormRowStatus.Updated:
          requests.push(
            updateExposureLimit({
              ExposureLimitID: row.data.ExposureLimitID,
              Mode: row.data.Mode,
              // Counterparty is left for backwards compability, it should be optional in the API as the backend can figure it out based on the marketAccount
              Counterparty: marketAccountsByName.get(row.data.MarketAccount)?.Market,
              MarketAccount: row.data.MarketAccount,
              Qty: row.data.Qty,
              ExposureCurrency: row.data.ExposureCurrency,
              ExposureDefinition: DEFAULT_EXPOSURE_DEFINITION,
            })
              .then(() => {
                row.setData(row.data);
                return row.data;
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not update exposure limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        case FormRowStatus.Removed:
          requests.push(
            deleteExposureLimit(row.data.ExposureLimitID)
              .then(() => {
                row.remove(true);
                return row.data;
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not delete exposure limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        default:
          break;
      }
    }
    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Counterparty Limits saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Counterparty Limits could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [addToast, deleteExposureLimit, formTable, createExposureLimit, updateExposureLimit, marketAccountsByName]);

  return exposureLimits ? (
    <VStack h="100%" w="100%" alignItems="flex-start">
      <VStack w="100%" alignItems="flex-start" my="spacingDefault" gap="spacingDefault">
        <Text>
          The maximum net open exposure of <b>spot</b> instruments that can be traded in a Market Account.
        </Text>
        <HStack gap="spacingDefault" w="100%" justifyContent="flex-end">
          <Button startIcon={IconName.Plus} onClick={handleNewExposureLimitButton}>
            Add Limit
          </Button>
          <Button variant={ButtonVariants.Primary} onClick={handleSave} disabled={isSaving || !formTable.isDirty}>
            Save
          </Button>
        </HStack>
      </VStack>
      <Box w="100%" h="100%">
        <FormTable {...formTable} />
      </Box>
    </VStack>
  ) : (
    <LoaderWrapper style={{ minHeight: '122px' }}>
      <Loader />
    </LoaderWrapper>
  );
};
