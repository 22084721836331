import styled from 'styled-components';

export const SoundOptions = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacingMedium}px;
  div {
    margin-bottom: ${({ theme }) => theme.spacingSmall}px;
    display: flex;
    flex-direction: row;
  }
`;
