import { SearchSelect } from '@talos/kyoko';
import { useSourceAndDestinationOptions } from 'containers/Transfers';
import type { SourceDestinationOption } from '../../../../../../containers/Transfers/types';
import type { TransferOverviewSelector } from '../../types';

export interface SourcesSelectorProps extends TransferOverviewSelector<SourceDestinationOption> {
  availableAmount?: string;
  currency?: string;
  destination?: SourceDestinationOption;
  onChange: (source?: SourceDestinationOption) => void;
  market?: string;
  source?: SourceDestinationOption;
}

const getLabel = (source: SourceDestinationOption) => source.label;

export function SourcesSelector({
  currency,
  destination,
  market,
  onChange,
  source,
  ...searchSelectProps
}: SourcesSelectorProps) {
  const { sourceOptions } = useSourceAndDestinationOptions({ currency, destination, market, source });

  return (
    <SearchSelect
      {...searchSelectProps}
      selection={source}
      options={sourceOptions}
      onChange={onChange}
      getLabel={getLabel}
      data-testid="source-select"
    />
  );
}
