import { useEffect, useState } from 'react';

// Helper hook to just every second return the current time
export const useDateNow = ({ updateIntervalMs = 1000 }) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, updateIntervalMs);

    return () => clearInterval(interval);
  }, [updateIntervalMs]);

  return date;
};
