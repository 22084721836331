import { ConnectionStatusEnum } from '@talos/kyoko';
import Big from 'big.js';
import { usePortfolioTreasury } from 'containers/Portfolio/providers';
import { useMemo } from 'react';
import { usePositionsConversions } from '../usePositionsConversions';

/**
 * Listens to the OTCTreasuryPositions and sums them all up after converting to home currency
 * @returns the total treasury amount in home currency as a number
 */
export const useTotalTreasury = () => {
  const { OTCTreasuryPositions } = usePortfolioTreasury();
  const rates = usePositionsConversions();

  const total: number | undefined = useMemo(() => {
    if (!rates || !OTCTreasuryPositions) {
      return undefined;
    }

    const positions = OTCTreasuryPositions.flatMap(mpos => mpos.Positions);
    let newTotal = Big(0);
    positions.forEach(position => {
      const rate = rates.get(position.Currency);
      if (rate && rate.Rate && rate.Status === ConnectionStatusEnum.Online) {
        newTotal = newTotal.plus(Big(position.Amount).times(Big(rate.Rate)));
      }
    });

    return newTotal.toNumber();
  }, [rates, OTCTreasuryPositions]);

  return total;
};
