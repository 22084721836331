import {
  FilterClauseType,
  IconName,
  MarketTypeEnum,
  useMarketAccountGroupsFilter,
  useMarketsContext,
  type BlotterTableFilter,
  type FilterClause,
  type FilterableProperty,
} from '@talos/kyoko';
import { isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useRelevantCurrenciesFilter } from '../../../hooks';
import { useMarketAccountsFilter } from '../../../hooks/filters/useMarketAccountsFilter';
import type { Balance, BalancesFilter } from '../../../types';

export const FILTERABLE_MARKET_TYPES = [MarketTypeEnum.Exchange, MarketTypeEnum.Dealer, MarketTypeEnum.Custodian];

export enum BalanceFilterKey {
  Currencies = 'Currencies',
  Markets = 'Markets',
  MarketTypes = 'MarketTypes',
  MarketAccounts = 'MarketAccounts',
  Groups = 'Groups',
}

interface UseBalancesV2FilterParams {
  initialFilter?: BalancesFilter;
  saveFilter: (newFilter: any) => void;
}

export const useBalancesV2Filter = ({ initialFilter, saveFilter }: UseBalancesV2FilterParams) => {
  const [filter, setFilter] = useState(initialFilter || {});

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const { filterableProperties } = useBalancesFilterableProperties();

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key],
        });
      });
    }
    return clauses;
  }, [filter]);

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
  };
};

export const useBalancesFilterableProperties = () => {
  const { marketsByName, marketsList } = useMarketsContext();

  const groupsFilter = useMarketAccountGroupsFilter();
  const currenciesFilter = useRelevantCurrenciesFilter();
  const accountsFilter = useMarketAccountsFilter();
  const filterableProperties: FilterableProperty[] = useMemo(() => {
    return [
      { ...currenciesFilter, label: 'Asset' },
      {
        key: BalanceFilterKey.MarketTypes,
        label: 'Market Type',
        icon: IconName.OfficeBuilding,
        options: FILTERABLE_MARKET_TYPES,
        getOptionLabel: (option: string) => option,
      },
      {
        key: BalanceFilterKey.Markets,
        label: 'Market',
        icon: IconName.PresentationChartLine,
        options: marketsList.map(market => market.Name),
        getOptionLabel: (marketName: string) => marketsByName.get(marketName)?.DisplayName ?? marketName,
      },
      { ...groupsFilter, key: BalanceFilterKey.Groups },
      { ...accountsFilter, key: BalanceFilterKey.MarketAccounts },
    ];
  }, [currenciesFilter, marketsList, accountsFilter, marketsByName, groupsFilter]);

  return { filterableProperties };
};

// Map from a ag-grid columnID to a filterbuilder key
// Returns undefined if the column can not be mapped to a filter builder clause
export function balanceColIDToFilterBuilderKey(colID: string): keyof BalancesFilter | undefined {
  switch (colID as keyof Balance) {
    case 'Currency':
      return 'Currencies';
    case 'marketAccountGroup':
      return 'Groups';
    case 'marketAccountName':
      return 'MarketAccounts';
    case 'Market':
      return 'Markets';
    case 'marketType':
      return 'MarketTypes';
  }
  return undefined;
}
