import type { ConnectionStatusEnum } from './types';

export type CurrencyConversionRate = {
  EquivalentCurrency: string;
  Currency?: string;
  Symbol?: string;
  Asset: string;
  Rate?: string;
  Status: ConnectionStatusEnum;
};

/** Just helps you get some typing help to filter down to rates that have .Rate non-null */
export function isCurrencyConversionRatePopulated(
  rate: CurrencyConversionRate
): rate is CurrencyConversionRate & { Rate: string } {
  return rate.Rate != null;
}
