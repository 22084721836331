import {
  CustomerSettlementPerspectiveEnum,
  MixpanelEvent,
  MixpanelEventProperty,
  Tab,
  TabAppearance,
  TabList,
  TabSize,
  Tabs,
  useDynamicCallback,
  useMixpanel,
} from '@talos/kyoko';

interface SettlementPerspectiveToggleProps {
  perspective: CustomerSettlementPerspectiveEnum;
  onChange: (newPerspective: CustomerSettlementPerspectiveEnum) => void;
}

export const SettlementPerspectiveToggle = ({ perspective, onChange }: SettlementPerspectiveToggleProps) => {
  const mixpanel = useMixpanel();

  const handleChange = useDynamicCallback((perspective: CustomerSettlementPerspectiveEnum) => {
    mixpanel.track(MixpanelEvent.ChangeSettlementPerspective, { [MixpanelEventProperty.Value]: perspective });
    onChange(perspective);
  });

  return (
    <Tabs
      selectedIndex={perspective === CustomerSettlementPerspectiveEnum.Dealer ? 0 : 1}
      appearance={TabAppearance.Pill}
      size={TabSize.Small}
      flex="0 0 auto"
      w="auto"
      data-testid="group-by-buttons"
    >
      <TabList suppressOverflowList={true}>
        <Tab
          isSelected={perspective === CustomerSettlementPerspectiveEnum.Dealer}
          onClick={() => handleChange(CustomerSettlementPerspectiveEnum.Dealer)}
          data-testid="dealer-view-button"
          label="Dealer View"
        />
        <Tab
          isSelected={perspective === CustomerSettlementPerspectiveEnum.Customer}
          onClick={() => handleChange(CustomerSettlementPerspectiveEnum.Customer)}
          data-testid="customer-view-button"
          label="Client View"
        />
      </TabList>
    </Tabs>
  );
};
