import { isMarketConfigured, type ConnectionType } from '@talos/kyoko';
import { useMemo } from 'react';
import type { AvailabilityCondition } from '../types';

interface UseMarketConfiguredConditionParams {
  connectionType: ConnectionType;
}

/**
 * A AvailabilityCondition asserting that the Market.[ConnectionType] is present (defined) and that Market.[ConnectionType].Status
 * is not "Unavailable"
 */
export const useMarketConfiguredCondition = ({
  connectionType,
}: UseMarketConfiguredConditionParams): AvailabilityCondition => {
  return useMemo(
    () =>
      ({
        id: 'marketConfigured',
        ready: true,
        condition: market => {
          const ok = isMarketConfigured(market, connectionType);
          return ok
            ? { available: true }
            : { available: false, failedReason: `Market is not configured for connection type ${connectionType}` };
        },
      } satisfies AvailabilityCondition),
    [connectionType]
  );
};
