import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useMarketAccountsContext } from '../../../../contexts/MarketAccountsContext';
import { useIntl } from '../../../../hooks';
import { MarketAccountTypeEnum } from '../../../../types/types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  marketAccount: {
    defaultMessage: 'Market Account',
    id: 'Filters.FilterBuilder.filters.marketAccount',
  },
});

/**
 * This hook creates the filter property definition for Trading Market Accounts properties.
 *
 * @returns Filter Property definition to use for Trading Market Accounts filters
 */

export function useTradingMarketAccountsFilter() {
  const { formatMessage } = useIntl();
  const { marketAccountsByName, marketAccountsList, isMarketAccountActive } = useMarketAccountsContext();
  return useMemo(
    () =>
      ({
        key: 'MarketAccounts',
        label: formatMessage(messages.marketAccount),
        icon: IconName.PresentationChartLine,
        options: marketAccountsList
          .filter(m => isMarketAccountActive(m) && m.Type === MarketAccountTypeEnum.Trading)
          .map(m => m.Name),
        getOptionLabel: (option: string) => marketAccountsByName.get(option)?.DisplayName || '',
      } satisfies FilterableProperty),

    [marketAccountsList, isMarketAccountActive, marketAccountsByName, formatMessage]
  );
}
