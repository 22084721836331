import {
  Card,
  CopyButton,
  FormControlSizes,
  InlineFormattedNumber,
  Side,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  abbreviateId,
  percentToBps,
  type CardProps,
  type Order,
  type RequiredProperties,
} from '@talos/kyoko';

export interface RFQCardProps extends CardProps {
  order: RequiredProperties<Order, 'RFQID'>;
}

export function RFQCard({ order, ...props }: RFQCardProps) {
  return (
    <Card {...props}>
      <Card.Subtitle>RFQ</Card.Subtitle>
      <Table w="100%">
        <Tbody>
          <Tr>
            <Th>RFQ ID</Th>
            <Td align="right">
              #{abbreviateId(order.RFQID)}
              <CopyButton size={FormControlSizes.Tiny} value={order.RFQID} />
            </Td>
          </Tr>
          <Tr>
            <Th>Allowed Slippage</Th>
            <Td align="right">
              <InlineFormattedNumber number={percentToBps(order.AllowedSlippage)} currency="BPS" />
            </Td>
          </Tr>
          <Tr>
            <Th>Aggressor Side</Th>
            <Td align="right">
              <Side side={order.AggressorSide}>{order.AggressorSide}</Side>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Card>
  );
}
