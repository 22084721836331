import { logger } from '../../../../packages/kyoko';
import { useCustomersByName } from './useCustomer';

// Given a counterparty, return the display name of the customer
export const useCustomerDisplayName = (counterparty?: string) => {
  const customersByName = useCustomersByName();
  if (!counterparty) {
    return 'Customer';
  }
  const displayName = customersByName?.get(counterparty)?.DisplayName;
  if (displayName) {
    return displayName;
  }
  if (counterparty != null && customersByName != null) {
    logger.error(new Error(`Unable to find customer display name for counterparty ${counterparty}`));
  }
  return counterparty;
};
