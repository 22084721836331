import {
  BlotterTable,
  Button,
  Flex,
  IconName,
  LoaderSizes,
  MixpanelEvent,
  PanelActions,
  createCSVFileName,
  useBlotterTable,
  useMixpanel,
  useObservable,
  type Column,
  type ROLE,
  type User,
} from '@talos/kyoko';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { map, of } from 'rxjs';
import { Loader, LoaderWrapper } from '../../../../components/Loader';
import { prettifyRole } from '../../../../tokens/roles';
import type { IEditUserDrawerTab } from '../types';
import { useUserSettings } from '../useUserSettings';

const columns: Column[] = [
  { type: 'date', field: 'UpdatedAt', sortable: true, title: 'Timestamp' },
  { type: 'text', field: 'Revision', width: 100, sortable: true, sort: '-' },
  { type: 'text', field: 'Action' },
  { type: 'text', field: 'Description' },
  {
    type: 'user',
    field: 'User',
    width: 120,
    params: {
      valueFormatWithMap: 'id',
    },
  },
  { type: 'text', field: 'Comment', title: 'Reason' },
];

type UserHistory = {
  RolesAdded?: ROLE[];
  RolesRemoved?: ROLE[];
  SubAccount?: string;
  SubAccountAction?: string;
  SubAccountUpdateAction?: string;
  Event?: string;
  Comment?: string;
  Revision: number;
  UpdateUserID: string;
};

interface UserHistoryTabProps {
  user: User;
}

export function UserHistoryTab({ user }: UserHistoryTabProps) {
  const { getUserHistory } = useUserSettings();
  const [history, setHistory] = useState<UserHistory[] | undefined>(undefined);
  const mixpanel = useMixpanel();

  useEffect(() => {
    getUserHistory(user).then(result => setHistory(result?.data ?? []));
  }, [getUserHistory, user]);

  const dataObservable = useObservable(
    () =>
      of(history ?? []).pipe(
        map(data => {
          const hydratedData = data.map(userHistory => {
            const description: string[] = [];
            let action = userHistory.Event || userHistory.Comment;

            switch (action) {
              case 'FORCE_LOGOUT':
                action = 'Force Logout';
                break;
              case 'RESET_APP_CONFIG':
                action = 'Reset App Config';
                break;
              case 'CREATE_RESET_PW_URL':
                action = 'Create Reset Password URL';
                break;
            }

            if (
              userHistory.RolesAdded &&
              userHistory.RolesAdded?.length > 0 &&
              userHistory.RolesRemoved &&
              userHistory.RolesRemoved?.length > 0
            ) {
              description.push(
                `+${userHistory.RolesAdded.map(role => prettifyRole(role)).join(', ')}, -${userHistory.RolesRemoved.map(
                  role => prettifyRole(role)
                ).join(', ')}`
              );
              action = 'Roles changed';
            } else if (userHistory.RolesAdded) {
              description.push(userHistory.RolesAdded.map(role => prettifyRole(role)).join(', '));
              action = 'Roles added';
            } else if (userHistory.RolesRemoved) {
              description.push(userHistory.RolesRemoved.map(role => prettifyRole(role)).join(', '));
              action = 'Roles removed';
            }

            if (userHistory.SubAccountUpdateAction) {
              description.push(`${userHistory.SubAccountAction} ${userHistory.SubAccount}`);
              action = `Sub account ${userHistory.SubAccountUpdateAction}`;
            }

            return {
              ...userHistory,
              Action: action,
              Description: description.join(', '),
              User: userHistory.UpdateUserID,
              RowID: `${userHistory.Revision}`,
            };
          });
          return { data: hydratedData, initial: true, type: 'UserHistory' };
        })
      ),
    [history]
  );

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'RowID',
    columns,
  });

  const handleExport = useCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: ['User History', user.Name].join(' - '),
      }),
    });
  }, [blotterTable, user.Name, mixpanel]);

  if (history == null) {
    return (
      <LoaderWrapper>
        <Loader size={LoaderSizes.SMALL} />
      </LoaderWrapper>
    );
  }

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <PanelActions>
        <Button startIcon={IconName.Download} onClick={handleExport}>
          Export CSV
        </Button>
      </PanelActions>
      <BlotterTable background="backgroundDrawer" {...blotterTable} />
    </Flex>
  );
}

export const useUserHistoryTab = ({ user }: { user: User }): IEditUserDrawerTab => {
  const component = useMemo(() => {
    return <UserHistoryTab user={user} />;
  }, [user]);

  return {
    name: 'History',
    viewable: true,
    isDirty: false,
    component,
    renderReasonInput: false,
    save: undefined,
  };
};
