import type { ICellRendererParams } from 'ag-grid-community';
import { useCallback } from 'react';
import { isEditingThisCell } from '../../BlotterTable/utils';
import { BaseSelect } from './BaseSelect';
import type { BaseSelectProps } from './types';

type BaseSelectCellRendererProps<TData, TValue> = {
  baseSelectProps: Omit<BaseSelectProps<TValue>, 'isDropdownOpened' | 'onClick'>;
  cellRendererParams: ICellRendererParams<TData>;
  /** We force the implementer to provide the colId explicitly in a non-undefinable manner for internal typing simplicity reasons */
  colId: string;
};

/**
 * This component helps you use the BaseSelect to make a cell appear to be editable. On click of the BaseSelect
 * component, AgGrid will be notified that this cell is now being edited. When this happens, your cellEditor
 * (implemented separately) will be rendered.
 */
export const BaseSelectCellRenderer = <TData, TValue>({
  baseSelectProps,
  cellRendererParams,
  colId,
}: BaseSelectCellRendererProps<TData, TValue>) => {
  const onStartEditing = useCallback(() => {
    cellRendererParams.api.stopEditing();
    setTimeout(() => {
      cellRendererParams.api.startEditingCell({
        rowIndex: cellRendererParams.node.rowIndex ?? 0,
        colKey: colId,
      });
    }, 0);
  }, [cellRendererParams.api, colId, cellRendererParams.node.rowIndex]);

  return (
    <BaseSelect
      {...baseSelectProps}
      isDropdownOpened={isEditingThisCell(cellRendererParams)}
      onClick={onStartEditing}
    />
  );
};
