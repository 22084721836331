import type { TabComponentSpec } from 'components/FlexibleLayout/types';
import { RecentTrades } from 'containers/Blotters/Trades/RecentTrades';
import { PORTFOLIO_DOCKVIEW_PANEL_GROUPS } from '../../../portfolioDashboardLayouts';

export type PortfolioComponentTypes = (typeof portfolioComponents)[number]['type'];
export const portfolioComponents = [
  {
    group: PORTFOLIO_DOCKVIEW_PANEL_GROUPS['blotters'],
    type: 'tradesBlotter',
    label: 'Trades',
    renderer: () => {
      return <RecentTrades blotterId="trades-pms-portfolio" />;
    },
  },
] as const satisfies TabComponentSpec[];
