import {
  type AGGRID_AUTOCOLUMN_ID,
  type Column,
  type ColumnDef,
  EMPTY_ARRAY,
  getAgGridColId,
  NumberVariants,
  toBigWithDefault,
  useDefaultColumns,
} from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import type { ReconAssetRow, SubAccountReconOverviewRow } from './reconCheckpointRows';
import { SubAccountAmountTooltip } from './SubAccountAmountTooltip';

export type ReconOverviewBlotterColumnIDs = 'Asset' | 'Status' | typeof AGGRID_AUTOCOLUMN_ID;

export const useSubAccountReconOverviewBlotterColumns = (defaultColumns = EMPTY_ARRAY) => {
  const columnDefinitions: Map<string, Column> = useMemo(() => {
    const defsArr = [
      {
        type: 'asset',
        id: 'Asset' satisfies ReconOverviewBlotterColumnIDs,
        title: 'Instrument',
        description: 'Financial instrument held in the account.',
        hide: true,
        field: 'Asset',
        params: {
          colorful: true,
        },
      },
      {
        type: 'reconCheckpointStatus',
        width: 100,
        field: 'Status' satisfies ReconOverviewBlotterColumnIDs,
        description: 'Current status of the reconciliation.',
      },
      {
        type: 'reconCheckpointEvalStatus',
        width: 100,
        field: 'EvalStatus',
        description: 'Current status of the reconciliation engine.',
      },
      {
        type: 'number',
        field: 'Breaks',
        width: 100,
        description: 'Cumulative number of reconciliation breaks, as of the selected checkpoint.',
      },
      {
        type: 'size',
        field: 'SubAccountAmount',
        params: {
          currencyField: 'Asset',
        },
        description: 'Cumulative position in the Sub Account, as of the selected checkpoint.',
        tooltipField: 'SubAccountDetails' satisfies keyof ReconAssetRow,
        tooltipComponent: SubAccountAmountTooltip,
      },
      {
        type: 'size',
        field: 'MarketAccountAmount',
        params: {
          currencyField: 'Asset',
        },
        width: 180,
        description: 'Cumulative position in the Market Account, as of the selected checkpoint.',
      },
      {
        type: 'size',
        field: 'BreakAmount',
        params: {
          currencyField: 'Asset',
          getSentiment: (params: ICellRendererParams<SubAccountReconOverviewRow>) => {
            return toBigWithDefault(params.node?.data?.breakAmount, 0).eq(0) ? undefined : NumberVariants.Warning;
          },
        },
      },
    ] satisfies ColumnDef<SubAccountReconOverviewRow>[];

    return new Map(defsArr.map(def => [getAgGridColId(def), def]));
  }, []);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};
