import { createContext, memo, useCallback, useContext, type PropsWithChildren } from 'react';
import { filter, map, shareReplay } from 'rxjs/operators';

import {
  CUSTOMER_BALANCE,
  POST,
  PUT,
  getCustomerBalanceRowID,
  request,
  useObservable,
  useStaticSubscription,
  useSubscription,
  useUserContext,
  wsScanToMap,
  type CustomerBalance,
  type CustomerBalanceTransaction,
} from '@talos/kyoko';
import type { Observable } from 'rxjs';

export interface UpdateCustomerBalanceProps {
  ExternalID: string;
  Counterparty: string;
  Currency: string;
  Amount: string;
  TxHash?: string;
  Comments?: string;
  ExternalComments?: string;
  MarketAccount: string;
}

export interface CustomerBalanceContextProps {
  customerBalancesMapObs: Observable<Map<string, CustomerBalance>>;
  customerBalancesObs: Observable<CustomerBalance[]>;
  createBalanceTransaction: (
    customerBalanceTransaction: Partial<CustomerBalanceTransaction>,
    forceWithdraw?: boolean
  ) => Promise<any>;
  updateCustomerBalance: (customerBalance: UpdateCustomerBalanceProps) => Promise<any>;
}

const CustomerBalanceContext = createContext<CustomerBalanceContextProps | null>(null);
CustomerBalanceContext.displayName = 'CustomerBalanceContext';

export function useCustomerBalance() {
  const context = useContext(CustomerBalanceContext);
  if (context == null) {
    throw new Error('Missing CustomerBalanceContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const CustomerBalanceProvider = memo(function CustomerBalanceProvider(props: PropsWithChildren<unknown>) {
  const { data: customerBalanceSub } = useStaticSubscription<CustomerBalance>(
    { name: CUSTOMER_BALANCE, tag: 'CustomerBalanceProvider', ShowZeroBalances: true },
    { loadAll: true }
  );

  const customerBalancesMapObs = useObservable<Map<string, CustomerBalance>>(
    () =>
      customerBalanceSub.pipe(
        wsScanToMap({
          getUniqueKey: cb => cb.rowID,
          newMapEachUpdate: false,
        })
      ),
    [customerBalanceSub]
  );

  const customerBalancesObs = useObservable<CustomerBalance[]>(
    () =>
      customerBalanceSub.pipe(
        wsScanToMap({
          getUniqueKey: cb => cb.rowID,
          newMapEachUpdate: false,
        }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerBalanceSub]
  );

  const { orgApiEndpoint } = useUserContext();
  const baseEndpoint = `${orgApiEndpoint}/customer-balances/transactions`;
  const createBalanceTransaction: CustomerBalanceContextProps['createBalanceTransaction'] = useCallback(
    (balanceTransaction, forceWithdraw = false) => {
      const endpoint = forceWithdraw ? `${baseEndpoint}/force-withdraw` : baseEndpoint;
      return request(POST, endpoint, balanceTransaction);
    },
    [baseEndpoint]
  );

  // Updating Customer Balance is for Talos Admins only
  const customerBalanceEndpoint = `${orgApiEndpoint}/customer-balances`;
  const updateCustomerBalance: CustomerBalanceContextProps['updateCustomerBalance'] = useCallback(
    customerBalance => request(PUT, customerBalanceEndpoint, customerBalance),
    [customerBalanceEndpoint]
  );

  return (
    <CustomerBalanceContext.Provider
      value={{
        customerBalancesMapObs,
        customerBalancesObs,
        createBalanceTransaction,
        updateCustomerBalance,
      }}
    >
      {props.children}
    </CustomerBalanceContext.Provider>
  );
});

export interface useCustomerBalanceSummaryProps {
  counterparty: string;
  currency: string;
  marketAccount: string;
}

export const useCustomerBalanceSummary = ({
  counterparty,
  currency,
  marketAccount,
}: useCustomerBalanceSummaryProps) => {
  const { data: customerBalanceSub } = useSubscription<CustomerBalance>({
    name: CUSTOMER_BALANCE,
    tag: 'useCustomerBalanceSummary',
    Counterparties: [counterparty],
    Currencies: [currency],
    // [1] Back-end filtering for MarketAccount is not supported yet
    MarketAccount: marketAccount,
  });

  const requestedRowID = getCustomerBalanceRowID({
    Counterparty: counterparty,
    Currency: currency,
    MarketAccount: marketAccount,
  });

  const singleCustomerBalanceObs = useObservable<CustomerBalance | undefined>(
    () =>
      // [2] Find the customerBalance with matching rowID.
      customerBalanceSub.pipe(
        map(response => response.data.find(cb => cb.rowID === requestedRowID)),
        filter(maybeCustomerBalance => maybeCustomerBalance !== undefined)
      ),
    [customerBalanceSub, requestedRowID]
  );

  return singleCustomerBalanceObs;
};
