import type { GroupPanelViewState, SerializedDockview, SerializedGridObject } from 'dockview';
import { BRANCH, LEAF } from '../../components/Layout/tokens';

/**
 * Get the group with the provided groupId from the layout
 * @param layout A Dockview layout object
 * @param groupId
 * @returns The desired group or undefined if not found
 */
export function getLayoutGroupById(
  layout: SerializedDockview,
  groupId: string
): SerializedGridObject<GroupPanelViewState> | undefined {
  let result: SerializedGridObject<GroupPanelViewState> | undefined = undefined;
  searchLayoutForGroupId(layout.grid.root, groupId);
  function searchLayoutForGroupId(layoutGroup: SerializedGridObject<GroupPanelViewState>, id: string) {
    if (layoutGroup.type === LEAF) {
      // Casting to get around type not being narrowed by checking the type property
      const data = layoutGroup.data as GroupPanelViewState;
      if (data.id === id) {
        result = layoutGroup;
        return result;
      }
    } else if (layoutGroup.type === BRANCH) {
      // Casting to get around type not being narrowed by checking the type property
      const data = layoutGroup.data as SerializedGridObject<GroupPanelViewState>[];
      for (const item of data) {
        const res = searchLayoutForGroupId(item, id);
        if (res) {
          return result;
        }
      }
    }
  }
  return result;
}
