import Big from 'big.js';
import { compact, keys, omitBy, pick, uniq } from 'lodash';
import type { ReactNode } from 'react';
import { defineMessages } from 'react-intl';

import type { ExecutionReport } from '../../../types/ExecutionReport';
import type { Order } from '../../../types/Order';
import {
  AllocationValueTypeEnum,
  OrderMarketStatusEnum,
  type IAllocation,
  type IOMSExecutionReport4203Markets,
} from '../../../types/types';
import { FormattedMessage } from '../../Intl';
import { useParameterRows, type ParameterRow } from '../../ParametersTable/useParameterRows';
import { Text } from '../../Text';

const messages = defineMessages({
  allocation: {
    defaultMessage: 'Allocation',
    id: 'ExecutionReportSummary.ExecutionReportDifference.allocation',
  },
});

const POTENTIALLY_UPDATED_EXECUTION_REPORT_KEYS: (keyof ExecutionReport)[] = [
  'Markets',
  'Price',
  'Parameters',
  'SubAccount',
  'OrderQty',
  'Allocation',
  'Comments',
];
export interface DifferenceItem {
  to: string;
  from?: string;
  type: 'replace' | 'add' | 'remove';
  label: NonNullable<ReactNode>;
  key: string;
}

export const useDifferenceBetweenExecutionReports = (
  currentExecutionReport: ExecutionReport | undefined,
  previousExecutionReport: ExecutionReport | undefined
): DifferenceItem[] | undefined => {
  const diffItemTypes: DifferenceItem[] = [];
  const curr = pick(currentExecutionReport, POTENTIALLY_UPDATED_EXECUTION_REPORT_KEYS);
  const prev = pick(previousExecutionReport, POTENTIALLY_UPDATED_EXECUTION_REPORT_KEYS);

  const keysOfDifferences = getKeysOfNaiveDiff(curr, prev);
  const previousParameters = useParameterRows(previousExecutionReport?.Strategy, previousExecutionReport?.Parameters);
  const currentParameters = useParameterRows(currentExecutionReport?.Strategy, currentExecutionReport?.Parameters);

  if (!previousExecutionReport || !currentExecutionReport || keysOfDifferences.length === 0) {
    return undefined;
  }

  for (const key of keysOfDifferences) {
    switch (key) {
      case 'Markets': {
        const markets = marketDiff(curr.Markets, prev.Markets);
        diffItemTypes.push(...markets);
        break;
      }
      case 'Parameters': {
        const parameters = parametersDiff(currentParameters, previousParameters);
        diffItemTypes.push(...parameters);
        break;
      }
      case 'Allocation': {
        const allocations = allocationDiff(curr.Allocation, prev.Allocation, currentExecutionReport.Currency);
        diffItemTypes.push(...allocations);
        break;
      }
      default: {
        const from = (previousExecutionReport[key] as string) ?? '';
        const to = (currentExecutionReport[key] as string) ?? '';
        if (to) {
          diffItemTypes.push({ from, to, type: 'replace', label: key, key });
        }
        break;
      }
    }
  }
  return compact(diffItemTypes);
};

export function allocationDiff(
  curr: IAllocation | undefined,
  prev: IAllocation | undefined,
  currency: Order['Currency'] | undefined
): DifferenceItem[] {
  if (!curr || !prev) {
    return [];
  }

  const currMap = new Map(curr.Allocations.map(a => [a.SubAccount, a.Value]));
  const prevMap = new Map(prev.Allocations.map(a => [a.SubAccount, a.Value]));

  const prevUnit = getUnit(prev, currency);
  const currUnit = getUnit(curr, currency);

  const prevMultiplier = prev.ValueType === AllocationValueTypeEnum.Percentage ? 100 : 1;
  const currMultiplier = curr.ValueType === AllocationValueTypeEnum.Percentage ? 100 : 1;

  const subAccounts = uniq([...curr.Allocations.map(m => m.SubAccount), ...prev.Allocations.map(m => m.SubAccount)]);

  if (currUnit !== prevUnit) {
    // Replace all since the unit changed
    return subAccounts.map<DifferenceItem>(subAccount => {
      const currValue = currMap.get(subAccount);
      const prevValue = prevMap.get(subAccount);
      const label = <SubAccountLabel label={subAccount} />;

      return {
        label,
        type: 'replace',
        to: `${Big(currValue || 0).times(currMultiplier)} ${currUnit}`,
        from: `${Big(prevValue || 0).times(prevMultiplier)} ${prevUnit}`,
        key: subAccount,
      };
    });
  }

  return compact(
    subAccounts.map<DifferenceItem | null>(subAccount => {
      const currValue = currMap.get(subAccount);
      const prevValue = prevMap.get(subAccount);

      const label = <SubAccountLabel label={subAccount} />;

      // New entry
      if (!prevValue && currValue) {
        return {
          label,
          to: `${Big(currValue).times(currMultiplier)} ${currUnit}`,
          type: 'add',
          key: subAccount,
        };
      }
      // Removal of entry
      if (prevValue && !currValue) {
        return {
          label,
          from: `${Big(prevValue).times(prevMultiplier)} ${prevUnit}`,
          to: `0 ${currUnit}`,
          type: 'replace',
          key: subAccount,
        };
      }
      // Update
      if (prevValue !== currValue && prevValue && currValue) {
        return {
          label,
          from: `${Big(prevValue).times(prevMultiplier)} ${prevUnit}`,
          to: `${Big(currValue).times(currMultiplier)} ${currUnit}`,
          type: 'replace',
          key: subAccount,
        };
      }
      return null;
    })
  );
}

function getUnit(allocation: IAllocation, currency?: string) {
  if (allocation.ValueType === AllocationValueTypeEnum.Percentage) {
    return '%';
  }
  if (allocation.ValueType === AllocationValueTypeEnum.Quantity) {
    return currency || 'Qty';
  }
  const _exhaustiveCheck: never = allocation.ValueType;

  return 'Qty';
}

function SubAccountLabel({ label }) {
  return (
    <Text>
      <FormattedMessage {...messages.allocation} />: <Text color="colorTextImportant">{label}</Text>
    </Text>
  );
}

/**
 * Compare two IOMSExecutionReport4203Market arrays. Decides based on MarketAccount (as key) and MarketStatus (as value)
 * what changes have occurred and in which direction.
 */
export const marketDiff = (
  curr: IOMSExecutionReport4203Markets[] | undefined,
  prev: IOMSExecutionReport4203Markets[] | undefined
): DifferenceItem[] => {
  if (!curr || !prev) {
    return [] as DifferenceItem[];
  }
  // Keep track of Disabled -> (whatever) and conversely, (whatever) -> Disabled.
  const prevMarketStatusMap = new Map<string, IOMSExecutionReport4203Markets['MarketStatus']>(
    prev.map(({ MarketAccount, MarketStatus }) => [MarketAccount, MarketStatus])
  );
  const currMarketStatusMap = new Map<string, IOMSExecutionReport4203Markets['MarketStatus']>(
    curr.map(({ MarketAccount, MarketStatus }) => [MarketAccount, MarketStatus])
  );

  const marketAccountList = uniq([...prevMarketStatusMap.keys(), ...currMarketStatusMap.keys()]);

  return compact(
    marketAccountList.map((marketAccount): DifferenceItem | null => {
      const prevMarketStatus = prevMarketStatusMap.get(marketAccount);
      const currMarketStatus = currMarketStatusMap.get(marketAccount);

      const currDisabled = currMarketStatus === OrderMarketStatusEnum.Disabled;
      const prevDisabled = prevMarketStatus === OrderMarketStatusEnum.Disabled;
      const isMarketRemoved = currDisabled && !prevDisabled;
      const isMarketReplaced = !currDisabled && prevDisabled;
      const isNewEntry = prevMarketStatus === undefined && currMarketStatus !== undefined;
      const isUnknownEntry = currMarketStatus === undefined && prevMarketStatus !== undefined;

      // Remove.
      if (isMarketRemoved) {
        return { from: marketAccount, to: marketAccount, type: 'remove', label: 'Market', key: marketAccount };
      }
      // Replace.
      if (isMarketReplaced) {
        return { from: marketAccount, to: marketAccount, type: 'replace', label: 'Market', key: marketAccount };
      }
      // Add/Remove with previous value not shown.
      if (isNewEntry) {
        const type = currMarketStatus === 'Disabled' ? 'remove' : 'add';
        return { to: marketAccount, type, label: 'Market', key: marketAccount };
      }
      // No current market status, and previous market status exists.
      if (isUnknownEntry) {
        return {
          from: marketAccount,
          to: '',
          type: prevMarketStatus === 'Disabled' ? 'add' : 'remove',
          label: 'Market',
          key: marketAccount,
        };
      }
      return null;
    })
  );
};

/**
 * Compares two parameterrows and returns a list of information about what was changed and in what way.
 */
export const parametersDiff = (curr: ParameterRow[], prev: ParameterRow[]): DifferenceItem[] => {
  const currRowMap = new Map<string, string>(curr.map(row => [row.title, row.value]));
  const prevRowMap = new Map<string, string>(prev.map(row => [row.title, row.value]));

  const listOfLabels = uniq([...currRowMap.keys(), ...prevRowMap.keys()]);

  return compact(
    listOfLabels.map((label): DifferenceItem | null => {
      const from = prevRowMap.get(label);
      const to = currRowMap.get(label);
      if (!from && to) {
        return { to, type: 'add', label, key: label };
      }
      if (from && !to) {
        // We just removed the previous value.
        return { to: from, type: 'remove', label, key: label };
      }
      if (from !== to && to) {
        return { to, from, type: 'replace', label, key: label };
      }
      return null;
    })
  );
};

/**
 * Naively compare two objects (with ===) and return keys of values that differ.
 */
export function getKeysOfNaiveDiff<T extends AnyObject>(curr: T, prev: T): (keyof T)[] {
  return keys({
    ...omitBy(curr, (v, k) => {
      return prev?.[k] === v;
    }),
    ...omitBy(prev, (v, k) => {
      return curr?.[k] === v;
    }),
  }) as (keyof T)[];
}
