import {
  BaseSelect,
  Box,
  Button,
  ButtonVariants,
  FormControlSizes,
  IconName,
  Text,
  truncateBlockchainAddress,
  useDynamicCallback,
  type TreasuryLink,
  type TreasuryLinkSourceOrDestinationField,
} from '@talos/kyoko';
import { useState } from 'react';
import { useTreasuryRequests } from '../hooks/useTreasuryRequests';

interface TreasuryMarketAccountSelectorAsWrapperProps {
  treasuryLink: TreasuryLink;
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField;
  onStartEditing?: () => void;
  isEditingThisCell: boolean;
}

// If we exclude getDescription, the height of the cell isn't calculated correctly
const getDescription = () => '';

export const TreasuryAddressSelectorAsWrapper = ({
  treasuryLink,
  sourceOrDestinationField,
  onStartEditing,
  isEditingThisCell,
}: TreasuryMarketAccountSelectorAsWrapperProps) => {
  const getLabel = useDynamicCallback((link: TreasuryLink) => {
    const address = link[sourceOrDestinationField];
    return address ? `Address: ${truncateBlockchainAddress(link[sourceOrDestinationField])}` : '';
  });

  const { resetTreasuryLinkField } = useTreasuryRequests();

  const [disabledForRequest, setDisabledForRequest] = useState<boolean>(false);

  const handleResetClicked: React.MouseEventHandler<HTMLButtonElement> = useDynamicCallback(async e => {
    e.stopPropagation();
    setDisabledForRequest(true);
    await resetTreasuryLinkField(treasuryLink, 'DestinationAddress').then(() => {
      treasuryLink.DestinationAddress = undefined;
      setDisabledForRequest(false);
    });
  });

  return (
    <BaseSelect
      onClick={onStartEditing}
      value={treasuryLink?.DestinationAddress ? treasuryLink : undefined}
      isDropdownOpened={isEditingThisCell}
      getLabel={getLabel}
      getDescription={getDescription}
      placeholder="Select"
      size={FormControlSizes.Small}
      suffix={
        treasuryLink.DestinationAddress != null && (
          <Box borderRadius="borderRadiusSmall" overflow="hidden" mr="spacingSmall">
            <Button
              variant={ButtonVariants.Primary}
              size={FormControlSizes.Tiny}
              startIcon={IconName.Reply}
              onClick={handleResetClicked}
              disabled={disabledForRequest}
              data-testid="link-property-reset-button"
            >
              <Text color="colorTextAttention">Reset</Text>
            </Button>
          </Box>
        )
      }
    />
  );
};
