import {
  ACTION,
  Dialog,
  Divider,
  OrderStatus,
  Text,
  VStack,
  abbreviateId,
  useDynamicCallback,
  type DialogProps,
  type Order,
} from '@talos/kyoko';

import { OMSView } from 'components/OMS/OMSView';
import { useRoleAuth } from 'hooks';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { modifyOrder } from '../../../../components/OMS/NewOrder/OrderSlice';
import { openView } from '../../../../components/OMS/OMSSlice';
import { SelectedOrdersList } from './SelectedOrdersList';
import { SelectedOrdersUsers } from './SelectedOrdersUsers';
import { CancelDialogWrapper, Row } from './styles';

interface BulkModifyWarningDialogProps extends DialogProps {
  selectedOrders: Order[];
}

export const BulkModifyWarningDialog = (props: BulkModifyWarningDialogProps) => {
  if (!props.selectedOrders.length) {
    return null;
  }
  return ModifyWarningDialog(props);
};

export const ModifyWarningDialog = (props: BulkModifyWarningDialogProps) => {
  return props.selectedOrders.length === 1 ? (
    <SingleOrderModifyWarningDialog {...props} />
  ) : (
    <MultipleOrdersModifyWarningDialog {...props} />
  );
};

const SingleOrderModifyWarningDialog = ({ selectedOrders, ...props }: BulkModifyWarningDialogProps) => {
  const dispatch = useAppStateDispatch();
  const { isAuthorized } = useRoleAuth();

  const selectedOrder = selectedOrders[0];

  const handleConfirm = useDynamicCallback(() => {
    dispatch(modifyOrder(selectedOrder));
    dispatch(openView(OMSView.NewOrderForm));
  });

  return (
    <Dialog
      {...props}
      onConfirm={handleConfirm}
      confirmLabel="Proceed"
      cancelLabel="Return"
      data-testid="modify-warning-dialog"
      confirmDisabled={!isAuthorized(ACTION.SUBMIT_ORDER)}
      title="Confirmation"
    >
      <CancelDialogWrapper>
        <Row>
          <h3>
            Order #<b>{abbreviateId(selectedOrder?.OrderID)}</b>
          </h3>
          <OrderStatus
            ordStatus={selectedOrder?.OrdStatus}
            decisionStatus={selectedOrder?.DecisionStatus}
            cumQty={selectedOrder?.CumQty}
            orderQty={selectedOrder?.OrderQty}
            pricingMode={selectedOrder?.PricingMode}
            text={selectedOrder?.Text}
          />
        </Row>
        <p>
          Are you sure you want to modify an order belonging to <b>{selectedOrder?.User}</b>?
        </p>
      </CancelDialogWrapper>
    </Dialog>
  );
};

const MultipleOrdersModifyWarningDialog = ({ selectedOrders, ...props }: BulkModifyWarningDialogProps) => {
  const { isAuthorized } = useRoleAuth();
  return (
    <Dialog
      {...props}
      data-testid="modify-warning-dialog"
      confirmLabel="Proceed"
      cancelLabel="Return"
      confirmDisabled={!isAuthorized(ACTION.SUBMIT_ORDER)}
      title="Confirmation"
    >
      <CancelDialogWrapper>
        <VStack gap="spacingDefault" alignItems="stretch">
          <Text style={{ textAlign: 'left' }} color="colorTextAttention">
            You are about to modify {selectedOrders.length} orders belonging to{' '}
            <SelectedOrdersUsers selectedOrders={selectedOrders} />
            .<br />
            Are you sure you want to proceed?
          </Text>
          <Divider />
          <SelectedOrdersList selectedOrders={selectedOrders} />
        </VStack>
      </CancelDialogWrapper>
    </Dialog>
  );
};
