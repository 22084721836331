import { DEFAULT_LAYOUT_SPEC } from 'components/AppLayout/Layouts/DefaultLayoutSpec';
import type { SerializedDockview } from 'dockview';
import { DEFAULT_GRID, WATCHLIST_PANELS } from '../../components/AppLayout/Layouts/Default';
import { BLOTTER_PANELS } from '../../components/AppLayout/Layouts/shared';
import type { LayoutSpec } from '../../types/LayoutConfig';
import type { MarketTab } from '../MarketTabs.types';
import { mapTabToPanel } from './mapTabToPanel';

export const convertMarketTabsToDockviewLayout = (marketTabs: MarketTab[]): LayoutSpec => {
  const dockViewLayout: SerializedDockview = {
    grid: DEFAULT_GRID,
    panels: {
      // OMS forms and Orders are not yet part of flexible layout
      // ...FORM_PANELS,
      // ...FEED_PANELS,
      ...WATCHLIST_PANELS,
      ...BLOTTER_PANELS,
      ...marketTabs.reduce((acc, tab) => {
        acc[tab.id] = mapTabToPanel(tab);
        return acc;
      }, {}),
    },
  };
  dockViewLayout.grid.root.data[0].data[0].data[1].data.views = marketTabs.map(tab => tab.id);

  return {
    ...DEFAULT_LAYOUT_SPEC,
    dockViewLayout,
  };
};
