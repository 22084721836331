import {
  logger,
  readableDate,
  type ChartTooltipResolution,
  type DateRangeFilter,
  type UseChartTooltipProps,
} from '@talos/kyoko';
import { differenceInSeconds } from 'date-fns';

export const SELECTABLE_RESOLUTIONS: SelectableResolution[] = ['15M', '1H', '4H', '1D'];
export type SelectableResolution = '15M' | '1H' | '4H' | '1D';
interface ResolutionMetadata {
  resolution: SelectableResolution;
  label: string;
  dateFormatter: NonNullable<UseChartTooltipProps['dateFormatter']>;
  secondsPer: number;
  tooltipResolution: ChartTooltipResolution;
}

// The max datapoints we ever want to ask the backend for. Used to calculate what resolution to use for a given date range filter
export const MAX_DATAPOINTS_IN_CHART = 500;

/**
 * Util function taking a string, asserting whether or not the given value is a member of the SelectableResolutions type
 * @param value
 * @returns
 */
export function isSelectableResolution(value: string): value is SelectableResolution {
  return Boolean(SELECTABLE_RESOLUTIONS.find(res => res === value));
}

export const RESOLUTION_METADATA: { [key in SelectableResolution]: ResolutionMetadata } = {
  '15M': {
    resolution: '15M',
    label: '15 minutes',
    dateFormatter: date => readableDate(date, true),
    secondsPer: 3600 / 4,
    tooltipResolution: '1M',
  },
  '1H': {
    resolution: '1H',
    label: '1 Hour',
    dateFormatter: date => readableDate(date, true),
    secondsPer: 3600,
    tooltipResolution: '1M',
  },
  '4H': {
    resolution: '4H',
    label: '4 Hours',
    dateFormatter: date => readableDate(date, true),
    secondsPer: 3600 * 4,
    tooltipResolution: '1M',
  },
  '1D': {
    resolution: '1D',
    label: '1 Day',
    dateFormatter: date => readableDate(date, true),
    secondsPer: 3600 * 24,
    tooltipResolution: '1D',
  },
};

const FALLBACK_RESOLUTION = '1D';

/**
 * Helper function to go from the StartDate and EndDate parameter of a chartDateRange to a resolution.
 * If StartDate is undefined, if there are 0 seconds between start and end, or if no valid resolution was found,
 * a fallback resolution ("1D") will be returned.
 * @returns either the found resolution or a fallback resolution
 */
export function dateRangeFilterToResolution(chartDateRange: DateRangeFilter): SelectableResolution {
  const { StartDate: start, EndDate: end } = chartDateRange;
  if (start == null) {
    logger.error(new Error(`Failed to resolve ChartDateRange to Resolution: ChartDateRange.StartDate undefined`), {
      extra: { chartDateRange },
    });
    return FALLBACK_RESOLUTION;
  }

  const startDate = new Date(start);
  const endDate = end ? new Date(end) : new Date();

  const secondsBetween = differenceInSeconds(endDate, startDate);

  if (secondsBetween === 0) {
    logger.error(new Error(`Failed to resolve ChartDateRange to Resolution: 0 seconds between start and end`), {
      extra: { chartDateRange },
    });
    return FALLBACK_RESOLUTION;
  }

  const foundResolution = SELECTABLE_RESOLUTIONS.find(resolution => {
    const metadata = RESOLUTION_METADATA[resolution];
    const dataPointsInInterval = secondsBetween / metadata.secondsPer;
    return dataPointsInInterval <= MAX_DATAPOINTS_IN_CHART;
  });

  if (foundResolution == null) {
    logger.error(new Error(`Failed to resolve ChartDateRange to Resolution: did not find any resolution`), {
      extra: { chartDateRange, secondsBetween },
    });
    return FALLBACK_RESOLUTION;
  }

  return foundResolution;
}
