import {
  OptionTypeEnum,
  SyntheticProductTypeEnum,
  formattedUTCDate,
  type Security,
  type StringSelectItem,
} from '@talos/kyoko';
import { OptionStrategies } from 'components/MultilegCombo/enums';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';
import { Option, type CoinSelectItem } from './Option';

export interface OptionStrategyData {
  name: OptionStrategies;
  legs: Option[];
  // not the most intuitive name since multiple legs can initiate
  initiatingLegs: boolean[];
  // we do not allow inversing ratios, a +1/-1 structure can never become negative/positive
  ratios: string[];
}

export function getSyntheticProductType(optionStrategy?: OptionStrategy) {
  if (!optionStrategy?.data) {
    return undefined;
  }

  switch (optionStrategy.data.name) {
    case OptionStrategies.VerticalSpread:
      return optionStrategy.legs[0].data.typeField.value?.value === OptionTypeEnum.Call
        ? SyntheticProductTypeEnum.CallSpread
        : SyntheticProductTypeEnum.PutSpread;
    case OptionStrategies.CalendarSpread:
      return optionStrategy.legs[0].data.typeField.value?.value === OptionTypeEnum.Call
        ? SyntheticProductTypeEnum.CallCalendarSpread
        : SyntheticProductTypeEnum.PutCalendarSpread;
    case OptionStrategies.Strangle:
    case OptionStrategies.Straddle:
    default:
      // Unsupported & therefore should not reach here under correct flows
      throw new Error(`Invalid OptionStrategy: ${optionStrategy.data.name}`);
  }
}

export abstract class OptionStrategy {
  protected readonly _data: OptionStrategyData;

  protected constructor(protected _referenceData: OMSReferenceDataState, data: OptionStrategyData) {
    this._data = data;
  }

  public get data(): OptionStrategyData {
    return this._data;
  }

  public get legs(): Option[] {
    return this._data.legs;
  }

  public get initiatingLegs(): boolean[] {
    return this._data.initiatingLegs;
  }

  public get ratios(): string[] {
    return this._data.ratios;
  }

  public setInitiatingLegs(initiatingLegs: boolean[]): OptionStrategy {
    const newData = { initiatingLegs };
    return this.create(newData);
  }

  public setLegs(legs: Option[]) {
    const newData = { legs };
    return this.create(newData);
  }

  public updateInitiatingLeg(isInitiating: boolean, legIndex: number): OptionStrategy {
    const copy = this._data.initiatingLegs.slice();
    copy[legIndex] = isInitiating;

    const hasAtLeastOneInitiating = copy.some(init => init);

    if (!hasAtLeastOneInitiating) {
      // reject the change removing last initiating leg
      return this.create(this.data);
    }

    const newData = {
      initiatingLegs: copy,
    };

    return this.create(newData);
  }

  // We can't directly apply the security (Option.createFromSecurity(this._referenceData, security)) because this would be wrong
  // Suppose we are creating a Put Calendar Spread, and user selects a Call Option from the quick search, if we allowed that as is
  // then it would violate a calendar spread definition since we would have a Call and Put leg when both legs should be of same type
  // to prevent breaking the option strategy definitions, we extract each component and try updating it individually, as such it would
  // run the strategy definitions as if user manually updated each property and ensuring it's validity
  public updateSecurity(security: Security, legIndex: number): OptionStrategy {
    const coin = security?.UnderlyingCode ?? security.BaseCurrency;
    const exchange = security.Markets?.[0];
    const expiry = security.Expiration;
    const strike = security.StrikePrice;
    const type = security.OptionType;

    const option = Option.createFromSecurity(this._referenceData, security);

    const coinItem = option.data.coinField.availableItems.find(item => item.value === coin);
    const exchangeItem = option.data.exchangeField.availableItems.find(item => item.value === exchange);
    const expiryItem = option.data.expiryField.availableItems.find(item => item.value === expiry);
    const strikeItem = option.data.strikeField.availableItems.find(item => item.value === strike);
    const typeItem = option.data.typeField.availableItems.find(item => item.value === type);

    if (!coinItem || !exchangeItem || !expiryItem || !strikeItem || !typeItem) {
      return this;
    }

    return this.updateCoin(coinItem)
      .updateExchange(exchangeItem)
      .updateExpiry(expiryItem, legIndex)
      .updateStrike(strikeItem, legIndex)
      .updateType(typeItem, legIndex);
  }

  public updateCoin(coin?: CoinSelectItem): OptionStrategy {
    const newData = {
      legs: this._data.legs.map(leg => leg.updateCoin(coin)),
    };

    return this.create(newData);
  }

  public updateExchange(exchange: StringSelectItem | undefined): OptionStrategy {
    const newData = { legs: this._data.legs.map(leg => leg.updateExchange(exchange)) };
    return this.create(newData);
  }

  public updateMarketAccount(marketAccount: StringSelectItem[] | undefined = [], legIndex?: number): OptionStrategy {
    const newData = {
      legs: this._data.legs.map((leg, index) => {
        if (index === legIndex) {
          return leg.updateMarketAccount(marketAccount);
        } else if (!leg.data.marketAccountField.hasValue) {
          return leg.updateMarketAccount(marketAccount);
        }
        return leg;
      }),
    };

    return this.create(newData);
  }

  public getPrettyNameForLegs(): string[] {
    return this.legs.map(leg => {
      const exchange = leg.data.exchangeField.value?.label;
      const coin = leg.data.coinField.value?.value;
      const expiry = leg.data.expiryField.value?.value;
      const formattedExpiry = expiry ? formattedUTCDate(expiry, '{dd}{mon}{yy}').toUpperCase() : '';
      const strike = leg.data.strikeField.value?.value;
      const type = leg.data.typeField.value?.value;

      return `${exchange} ${coin} ${formattedExpiry} ${strike} ${type?.substring(0, 1)}`;
    });
  }

  // Different strategies will override these differently to implement the underlying logic that makes up that strategy
  // e.g. A Straddle will enforce both legs have same expiry date but different strike price
  public abstract updateExpiry(expiry: StringSelectItem, legIndex?: number): OptionStrategy;

  public abstract updateStrike(strike: StringSelectItem, legIndex?: number): OptionStrategy;

  public abstract updateType(type: StringSelectItem, legIndex?: number): OptionStrategy;

  public abstract updateRatio(ratio: string, legIndex: number): OptionStrategy;

  public abstract getPrettyName(): string;

  protected abstract create(data: Partial<OptionStrategyData>): OptionStrategy;
}

OptionStrategy[immerable] = true;
