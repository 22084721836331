import { Orientation, type GroupviewPanelState, type SerializedDockview } from 'dockview';
import { BRANCH, LEAF } from '../../Layout/tokens';
import { BLOTTER_PANELS, BLOTTERS_GROUP } from './shared';

const PANELS: Record<string, GroupviewPanelState> = {
  // Not part of the flexible layout, yet...
  // ...FORM_PANELS,
  // ...FEED_PANELS,
  watchlist: { id: 'watchlist', contentComponent: 'watchlist', title: 'Watchlist' },
  'deepDive_BTC-USD': {
    id: 'deepDive_BTC-USD',
    contentComponent: 'deepDive',
    title: 'BTC-USD',
    params: {
      symbol: 'BTC-USD',
    },
  },
  'deepDive_ETH-USD': {
    id: 'deepDive_ETH-USD',
    contentComponent: 'deepDive',
    title: 'ETH-USD',
    params: {
      symbol: 'ETH-USD',
    },
  },
  'deepDive_SOL-USD': {
    id: 'deepDive_SOL-USD',
    contentComponent: 'deepDive',
    title: 'SOL-USD',
    params: {
      symbol: 'SOL-USD',
    },
  },
  'deepDive_XLM-USD': {
    id: 'deepDive_XLM-USD',
    contentComponent: 'deepDive',
    title: 'XLM-USD',
    params: {
      symbol: 'XLM-USD',
    },
  },
  ...BLOTTER_PANELS,
};

const GRID = {
  root: {
    type: BRANCH,
    data: [
      {
        type: BRANCH,
        data: [
          {
            type: BRANCH,
            data: [
              {
                type: LEAF,
                visible: true,
                data: { views: ['deepDive_BTC-USD'], activeView: 'deepDive_BTC-USD', id: 'main' },
                size: 100,
              },
              {
                type: LEAF,
                data: { views: ['deepDive_ETH-USD'], activeView: 'deepDive_ETH-USD', id: 'deepDives-2' },
                size: 100,
              },
            ],
            size: 145,
          },
          {
            type: BRANCH,
            data: [
              {
                type: LEAF,
                visible: true,
                data: { views: ['deepDive_SOL-USD'], activeView: 'deepDive_SOL-USD', id: 'deepDives-3' },
                size: 100,
              },
              {
                type: LEAF,
                data: { views: ['deepDive_XLM-USD'], activeView: 'deepDive_XLM-USD', id: 'deepDives-4' },
                size: 100,
              },
            ],
            size: 145,
          },
          BLOTTERS_GROUP,
        ],
        size: 300,
      },
      // Not part of the flexible layout, yet...
      // OMS_TAB_GROUP,
      // FEED_TAB_GROUP,
    ],
    size: 398,
  },
  width: 734,
  height: 398,
  orientation: Orientation.HORIZONTAL,
};

export const SYMBOL_VIEWS_LAYOUT: SerializedDockview = {
  grid: GRID,
  panels: PANELS,
};
