import { AccordionTable, Box, Flex, NoRowsOverlay } from '@talos/kyoko';
import Big from 'big.js';
import { sortBy } from 'lodash';
import { useCustomerMarketAccounts } from 'providers';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { EXPOSURE_WARNING_THRESHOLD } from '../types';
import { DealerSettlementAccordion } from './DealerSettlementAccordion';
import { SettleAll } from './SettleAll';
import { SettleTime } from './SettleTime';
import { HeaderCheckbox } from './TableHeaderCheckbox';
import { TreasuryVisualization } from './TreasuryVisualization/TreasuryVisualization';
import { WarningBottomBorder } from './styles';
import type { RelativeMarketPositions } from './useRelativeMarketPositions';

export interface DealerSettlementAccordionTableProps {
  marketPositions: RelativeMarketPositions[];
  showAll: boolean;
}

const COLUMNS = [
  {
    id: 'checkbox',
    label: <HeaderCheckbox />,
    width: '1%',
  },
  {
    id: 'dealer',
    label: 'Dealer',
    width: '15%',
  },
  {
    id: 'incoming',
    label: 'Incoming',
    width: '10%',
  },
  {
    id: 'outgoing',
    label: 'Settle Outgoing',
    width: '10%',
  },
  {
    id: 'usage',
    label: 'Credit usage',
    width: '40%',
  },
];

export const DealerSettlementAccordionTable = ({ marketPositions, showAll }: DealerSettlementAccordionTableProps) => {
  const { spacingBig } = useTheme();
  const { customerMarketAccountsByName } = useCustomerMarketAccounts();

  const [warningMarketPositions, normalMarketPositions] = useMemo(() => {
    let sortableMarketPositions = marketPositions.map(mpos => ({
      ...mpos,
      DisplayName: customerMarketAccountsByName.get(mpos.MarketAccount)?.DisplayName,
    }));

    if (!showAll) {
      sortableMarketPositions = sortableMarketPositions.filter(mpos =>
        mpos.Positions.some(pos => Big(pos.Amount).lt(0))
      );
    }

    const sorted = sortBy(sortableMarketPositions, item => item.DisplayName);

    const warning: RelativeMarketPositions[] = [];
    const normal: RelativeMarketPositions[] = [];

    sorted.forEach(mpos => {
      if (Big(mpos.usage ?? '0').gte(EXPOSURE_WARNING_THRESHOLD)) {
        warning.push(mpos);
      } else {
        normal.push(mpos);
      }
    });

    return [warning, normal];
  }, [marketPositions, customerMarketAccountsByName, showAll]);

  return (
    <Flex minWidth="fit-content" flex="1 0 0" flexDirection="column">
      <Flex
        w="100%"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb="spacingBig"
        minWidth="fit-content"
        gap="spacingBig"
      >
        <Box flexBasis="20%">
          <SettleTime />
        </Box>
        <Box flexBasis="60%" justifyContent="center" alignItems="center">
          <TreasuryVisualization />
        </Box>
        <Flex flexBasis="20%" justifyContent="flex-end" pl="spacingBig">
          <SettleAll marketPositions={marketPositions} />
        </Flex>
      </Flex>

      {warningMarketPositions.length + normalMarketPositions.length > 0 ? (
        <>
          <AccordionTable columns={COLUMNS} columnSpacing={spacingBig}>
            {warningMarketPositions.length > 0 && (
              <>
                {warningMarketPositions.map((marketPos, i) => {
                  return <DealerSettlementAccordion marketPositions={marketPos} key={marketPos.MarketAccount} />;
                })}
                <tbody>
                  <tr>
                    <WarningBottomBorder colSpan={100} />
                  </tr>
                </tbody>
              </>
            )}

            {normalMarketPositions.map((marketPos, i) => {
              return <DealerSettlementAccordion marketPositions={marketPos} key={marketPos.MarketAccount} />;
            })}
          </AccordionTable>
        </>
      ) : (
        <NoRowsOverlay />
      )}
    </Flex>
  );
};
