import { LookbackOption } from '@talos/kyoko';

/**
 * The dealer monitoring blotters had a different way of managing filters and tabs.
 * - The `name` of the subscription is now stored on the persisted tab state instead of the persisted blotter state
 * - The `Symbol` filter is now `Symbols` and is an array of strings instead of a single string
 * - The `Counterparty` is now an array of strings instead of a single string
 * - The `RFQID` and `OrderID` filters are now arrays (of length 1) of strings instead of single strings
 * - The `Statuses` filter has been moved to `_statuses`
 * - All empty/null filters have been removed
 */
export function replaceDealerMonitoringFilters(config: any) {
  const tabs = config.tabs?.['dealer/monitoring']?.items ?? [];

  // Move the PRESET_TABS from persisted blotter filters into persisted blotter tabs
  const tabKeys = new Set(tabs.map(tab => tab.id));
  const defaultPresetTabConfig = {
    closable: false,
    editable: false,
    defaultFilter: {
      _start: LookbackOption.Past30Days,
      HideAPICalls: true,
    },
  };
  // Note that we unshift these in backwards order so that they appear in the correct order in the UI
  if (!tabKeys.has('recent-trades')) {
    tabs.unshift({
      ...defaultPresetTabConfig,
      label: 'Recent Trades',
      id: 'recent-trades',
      name: 'CustomerTrade',
      defaultSort: '-TransactTime',
    });
  }
  if (!tabKeys.has('recent-quotes')) {
    tabs.unshift({
      ...defaultPresetTabConfig,
      label: 'Recent Quotes',
      id: 'recent-quotes',
      name: 'CustomerQuote',
      defaultSort: '-SubmitTime',
    });
  }
  if (!tabKeys.has('recent-orders')) {
    tabs.unshift({
      ...defaultPresetTabConfig,
      label: 'Recent Orders',
      id: 'recent-orders',
      name: 'CustomerOrder',
      defaultSort: '-SubmitTime',
    });
  }

  for (const tab of tabs) {
    if (tab.name != null) {
      // This tab has already been migrated
      continue;
    }

    const id = tab.id;
    const filter = config?.blotters2?.[`dealer/monitoring/${id}`]?.filter;

    if (filter == null) {
      continue;
    }

    // Move filter name to tab
    if (filter.name != null) {
      tab.name = filter.name;
      delete filter.name;
    }

    if (['recent-orders', 'recent-quotes', 'recent-trades'].includes(id)) {
      filter._start = LookbackOption.Past30Days;
      delete filter.StartDate;
    }

    for (const property in filter) {
      // Migrate old filters
      if (property === 'Symbol') {
        filter.Symbols = [filter[property]];
        delete filter[property];
        continue;
      }

      if (['RFQID', 'OrderID'].includes(property)) {
        filter[property] = [filter[property]];
        continue;
      }

      if (property === 'Counterparty') {
        filter.Counterparty = [filter[property]];
        continue;
      }

      if (property === 'Statuses') {
        filter._statuses = filter[property];
        delete filter[property];
        continue;
      }

      // Clear out empty filters
      if (filter[property] == null) {
        delete filter[property];
      }
    }
  }
}
