import { useState } from 'react';

import {
  Box,
  Card,
  EmptyView,
  Flex,
  HStack,
  Icon,
  IconName,
  IndicatorBadge,
  LoaderTalos,
  SymbolDisplay,
  SymbolDisplaySize,
  UnifiedLiquidityEnum,
  getSymbolsAcrossMarkets,
  useDynamicCallback,
  type Order,
} from '@talos/kyoko';
import type { useLatestOrderAnalytics } from 'hooks/useLatestOrderAnalytics';
import type { LogicalRange } from 'lightweight-charts';
import { Subject } from 'rxjs';
import { useTheme } from 'styled-components';
import { OrderExecutionChart } from './OrderExecutionChart';

export interface OrderExecutionChartsProps {
  order: Order;
  isMultileg: boolean;
  strategy?: string;
  height: number;
  width?: number;
  // If specified, reflow will resize the graph
  resizeAware?: boolean;
  analytics: ReturnType<typeof useLatestOrderAnalytics>;
}

export const OrderExecutionCharts = (props: OrderExecutionChartsProps) => {
  const { isMultileg, analytics, order } = props;
  const [visibleRangeSubject] = useState<Subject<LogicalRange | null>>(new Subject());
  const underlyingSymbols =
    order.unifiedLiquidity === UnifiedLiquidityEnum.Enabled ? getSymbolsAcrossMarkets(order) : [];

  const handleVisibleRangeChange = useDynamicCallback((range: LogicalRange | null) => {
    visibleRangeSubject.next(range);
  });

  return (
    <>
      {isMultileg ? (
        [0, 1, 2].map(legIndex => (
          <Card
            key={legIndex}
            data-testid={`order-execution-chart-${legIndex}`}
            title={
              <HStack justifyContent="flex-start" color="colorTextImportant">
                <IndicatorBadge w={18} h={18} mr="spacingDefault">
                  {legIndex === 0 ? <Icon icon={IconName.Share} rotate={90} /> : legIndex}
                </IndicatorBadge>
                <SymbolDisplay
                  symbol={analytics?.latestAnalytics?.[legIndex]?.Symbol}
                  showDescription={false}
                  p="0"
                  underlyingSymbols={legIndex === 0 ? underlyingSymbols : undefined}
                  size={SymbolDisplaySize.Small}
                />
              </HStack>
            }
          >
            <ChartForLeg
              legIndex={legIndex}
              visibleRangeSubject={visibleRangeSubject}
              onVisibleRangeChange={handleVisibleRangeChange}
              {...props}
            />
          </Card>
        ))
      ) : (
        <Card title="Order Execution" data-testid="order-execution-chart">
          <ChartForLeg legIndex={1} {...props} />
        </Card>
      )}
    </>
  );
};

const LEGEND_HEIGHT = 34;
const ChartForLeg = ({
  legIndex,
  analytics,
  visibleRangeSubject,
  onVisibleRangeChange,
  ...props
}: {
  legIndex: number;
  visibleRangeSubject?: Subject<LogicalRange | null>;
  onVisibleRangeChange?: (range: LogicalRange | null) => void;
} & OrderExecutionChartsProps) => {
  const currentLatestAnalytics = analytics?.latestAnalytics?.[legIndex];
  const currentlegOrParentAnalyticsObservable = analytics?.legOrParentAnalyticsObservable;
  const { spacingMedium } = useTheme();

  return (
    // chart height + legends + margin
    <Box minHeight={`${props.height + LEGEND_HEIGHT + spacingMedium}px`} position="relative">
      {!currentLatestAnalytics || !currentlegOrParentAnalyticsObservable ? (
        <Flex justifyContent="center" position="absolute" inset="0">
          {analytics.receivedAnalytics ? <EmptyView>Order Execution data not available</EmptyView> : <LoaderTalos />}
        </Flex>
      ) : (
        <OrderExecutionChart
          {...props}
          visibleRangeSubject={visibleRangeSubject}
          onVisibleRangeChange={onVisibleRangeChange}
          latestAnalytics={currentLatestAnalytics}
          legOrParentAnalyticsObservable={currentlegOrParentAnalyticsObservable}
          legIndex={legIndex}
        />
      )}
    </Box>
  );
};
