import {
  abbreviateId,
  useDynamicCallback,
  useRouter,
  useTabs,
  type TabProps,
  type UseTabs,
  type UseTabsProps,
} from '@talos/kyoko';
import type { OrderDetailsPath } from 'containers/Trading/Markets/OrderDetails/types';
import { cloneDeep } from 'lodash';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import { getIdFromMonitoringRoute, getOpenTabFromMonitoringRoute } from '../containers/Routes/routes';

export enum MonitoringTabType {
  Blotter = 'blotter',
  OrderDetails = 'order-details',
  CustomerOrderDetails = 'customer-order-details',
}

export interface IMontoringTab extends TabProps {
  id: string;
  label: string;
}

export interface BlotterMonitoringTab extends IMontoringTab {
  type: MonitoringTabType.Blotter;
}

export interface OrderDetailsMonitoringTab extends IMontoringTab {
  type: MonitoringTabType.OrderDetails | MonitoringTabType.CustomerOrderDetails;
  orderID: string;
  openTab: string;
}

export type MonitoringTab = OrderDetailsMonitoringTab | BlotterMonitoringTab;

const defaultTabs = [
  {
    id: 'blotters',
    type: MonitoringTabType.Blotter,
    label: 'Blotters',
  },
] satisfies MonitoringTab[];

const TABS_EXCLUDED_IDS = ['blotters'];
export const BLOTTER_PREFIX = 'monitoring-blotters';

function generateTabUrl(tab: MonitoringTab, withoutOpenTab?: boolean): string {
  switch (tab.type) {
    case MonitoringTabType.Blotter:
      return '/monitoring/blotters';
    case MonitoringTabType.OrderDetails:
      return `/monitoring/order/${tab.orderID}${withoutOpenTab ? '' : '/' + tab.openTab}`;
    case MonitoringTabType.CustomerOrderDetails:
      return `/monitoring/customer-order/${tab.orderID}${withoutOpenTab ? '' : '/' + tab.openTab}`;
  }
}

export const MonitoringTabsContext = createContext<
  | {
      tabs: UseTabs<MonitoringTab>;
      temporaryTab: MonitoringTab | undefined;
      selectedIndex: number;
      removeActiveTab: () => void;
      saveOrDiscardTemporaryTab: (value: boolean) => void;
      updateOrderDetailsMonitoringTab: (monitoringTabIndex: number, openTab: OrderDetailsPath) => void;
    }
  | undefined
>(undefined);
MonitoringTabsContext.displayName = 'MonitoringTabsContext';

export const useMonitoringTabs = () => {
  const context = useContext(MonitoringTabsContext);
  if (context == null) {
    throw new Error('Missing MonitoringTabsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export interface UseMonitoringTabsContextProps {
  initialTabs: MonitoringTab[];
  initialSelectedIndex: number;
  saveTabs: (monitoringTabs: MonitoringTab[]) => void;
}

/**
 * This hook is used by the app config provider.
 * The app config provider will provide the props for this hook, and in turn,
 * this hook will return the props to set on the `MonitoringTabsContext`.
 * The logic here is kind of messy, because we are trying to avoid infinite recursion
 * caused by updating the URL to match the selected tab, *and* updating the selected
 * tab to match the URL.
 * The logic has been pulled up to this layer, because it needs to be accessed by both the OrderDetails
 * (for the "X" button in the header to close the tab)
 * and the Monitoring components, and we need to ensure that they both have the same view of the tab state.
 * Specifically, we need *one* source of truth for the tab state, including any temporary tab (which isn't persisted),
 * so that OrderDetails can close the temporary tab if required.
 * `usePersistedTabs` does not give us this state shared between 2 separate components, so we need this context instead.
 */
export function useMonitoringTabsContext({
  initialTabs,
  initialSelectedIndex,
  saveTabs,
}: UseMonitoringTabsContextProps) {
  const { history } = useRouter();
  const location = useLocation();

  const handleSelectTab: UseTabsProps['onSelect'] = useDynamicCallback((index: number) => {
    const newSelectedTab = items[index];
    const tabUrl = generateTabUrl(newSelectedTab);
    if (!matchPath(location.pathname, { path: tabUrl, exact: false })) {
      history.push(tabUrl);
    }
  });

  const onTabsChanged = useDynamicCallback((items: MonitoringTab[]) => {
    saveTabs(
      items.filter(tab => {
        if (TABS_EXCLUDED_IDS.includes(tab.id)) {
          return false;
        }
        if (tab.isTemporary) {
          return false;
        }
        return true;
      })
    );
  });

  const tabs = useTabs<MonitoringTab>({
    onItemsChanged: onTabsChanged,
    initialItems: [
      ...defaultTabs,
      ...initialTabs.map<MonitoringTab>(t => ({ ...t, closable: true, reorderable: true })),
    ],
    initialSelectedIndex: initialSelectedIndex > defaultTabs.length + initialTabs.length - 1 ? 0 : initialSelectedIndex,
    showAddTab: false,
    tabLabeler: 'New Tab',
    onSelect: handleSelectTab,
  });
  const { items, setItems, selectedIndex, setSelectedIndex } = tabs;

  // keep track to where to return after discarding a temporary tab
  const previousSelectedIndex = useRef<number | null>(null);

  const prepareOrderDetailsTab = useDynamicCallback((orderID: string, openTab: string, isCustomerOrder: boolean) => {
    const newTabs = [...items];
    newTabs.push({
      id: uuid(),
      type: isCustomerOrder ? MonitoringTabType.CustomerOrderDetails : MonitoringTabType.OrderDetails,
      openTab,
      label: `#${abbreviateId(orderID)}`,
      orderID: orderID,
      isTemporary: true,
      closable: true,
      reorderable: true,
    });
    setItems(newTabs);
    previousSelectedIndex.current = selectedIndex;
    setSelectedIndex(newTabs.length - 1);
  });

  const saveOrDiscardTemporaryTab = useDynamicCallback((save: boolean) => {
    if (save) {
      const newTabs = items.slice();
      const tempIndex = newTabs.findIndex(t => t.isTemporary);
      const { isTemporary, ...updated } = newTabs[tempIndex];
      newTabs[tempIndex] = updated;
      tabs.setItems(newTabs);
      setSelectedIndex(newTabs.length - 1);
    } else {
      const newTabs = items.filter(t => !t.isTemporary);
      if (newTabs.length !== items.length) {
        tabs.setItems(newTabs);
        handleSelectTab(previousSelectedIndex.current || 0);
      }
    }
  });

  const updateOrderDetailsMonitoringTab = useDynamicCallback(
    (monitoringTabIndex: number, openTab: OrderDetailsPath) => {
      const tabs = cloneDeep(items);
      const item = tabs[monitoringTabIndex] as OrderDetailsMonitoringTab;
      item.openTab = openTab;
      setItems(tabs);
    }
  );

  // Detect monitoring tab index based on URL
  const selectTabFromURL = useDynamicCallback((locationPathName: string) => {
    // This useEffect sits quite high up in the providers chain (inside AppConfig), so
    // we need to ensure that we don't run this logic unless we're on a monitoring page
    if (!locationPathName?.startsWith('/monitoring')) {
      previousSelectedIndex.current = 0;
      return;
    }

    // Find the first tab whose URL matches the current location
    // Note that we are not looking for an exact match, because we want to match on all sub-tabs of order details.
    // e.g. For an order details tab, the generated URL might look like `/monitoring/order/12345-67890`, but the
    // current URL in the browser might be `/monitoring/order/12345-67890/trades`.
    const newSelectedIndex = items.findIndex(t =>
      matchPath(locationPathName, { path: generateTabUrl(t, true), exact: false })
    );
    // If `newSelectedIndex >= 0`, we found an existing tab that matches the current URL.
    if (newSelectedIndex >= 0) {
      if (!items[newSelectedIndex].isTemporary) {
        // The tab we're changing to is _not_ the temporary tab, so we'll close that temporary tab (if it exists).
        // We set the `previousSelectedIndex` here to the tab we want to switch to, because if we do close a temporary
        // tab, we're also going to update the URL to select the "previous selected index".
        previousSelectedIndex.current = newSelectedIndex;
        saveOrDiscardTemporaryTab(false);
      }
      // Set our new selected index.
      // This will trigger the `handleSelectTab`; if that logic was already called from `saveOrDiscardTemporaryTab`,
      // it will be a no-op (won't change the URL), but if we didn't close a temporary tab, `handleSelectTab` will
      // update the URL for us.
      setSelectedIndex(newSelectedIndex);
    } else {
      // No existing tab was found that matches the current URL
      // We need to create a new tab for this URL.
      const orderID = getIdFromMonitoringRoute(locationPathName);
      const openTab = getOpenTabFromMonitoringRoute(locationPathName);
      // Check that `orderID` isn't `undefined` or `''` (sometimes happens in real weird edge cases? Maybe just broken while I was developing ...)
      if (orderID) {
        const isCustomerOrderDetails = locationPathName.includes('customer-order');
        prepareOrderDetailsTab(orderID, openTab, isCustomerOrderDetails);
      } else {
        // URL doesn't contain a valid orderID, so we'll change the URL to a valid tab instead.
        handleSelectTab(
          (previousSelectedIndex.current ?? 0) > items.length - 1 ? 0 : previousSelectedIndex.current ?? 0
        );
      }
    }
  });

  useEffect(() => {
    // The implementation is in a separate, dynamic callback
    // so that we don't trigger this logic when the `items` array changes.
    // We only want this to trigger based on URL changes.
    selectTabFromURL(location.pathname);
  }, [selectTabFromURL, location.pathname]);

  const temporaryTab = useMemo(() => items.find(t => t.isTemporary), [items]);
  const removeActiveTab = useDynamicCallback(() => {
    if (temporaryTab) {
      saveOrDiscardTemporaryTab(false);
    } else {
      tabs.onRemove(tabs.selectedIndex);
    }
  });

  const value = useMemo(
    () => ({
      tabs,
      temporaryTab,
      removeActiveTab,
      saveOrDiscardTemporaryTab,
      updateOrderDetailsMonitoringTab,
      selectedIndex,
    }),
    [removeActiveTab, saveOrDiscardTemporaryTab, updateOrderDetailsMonitoringTab, tabs, temporaryTab, selectedIndex]
  );
  return value;
}
