import {
  FilterClauseType,
  getTypedKeys,
  useAssetsFilter,
  type FilterableProperty,
  type FilterClause,
  type UseFilterBuilderProps,
} from '@talos/kyoko';
import { compact, isArray } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useMemo, type ReactNode } from 'react';
import { useMarketAccountsFilter } from '../../../../hooks';
import { useContextBlotterFilter } from '../../../../providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import type { PositionsTableFilter } from '../../../Blotters/PositionsV3/types';
import { OperationsOverviewActionType } from '../reducer';
import { useOperationsOverviewContext } from './OperationsOverviewStateProvider';

export const OperationsOverviewFiltersContext = createContext<OperationsOverviewFiltersContextProps | undefined>(
  undefined
);

export type OperationsOverviewFiltersContextProps = {
  filterableProperties: FilterableProperty[];
  onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'];
  initialFilterClauses: UseFilterBuilderProps['initialFilterClauses'];
};

export function useOperationsOverviewFilters() {
  const context = useContext(OperationsOverviewFiltersContext);
  if (context === undefined) {
    throw new Error(
      'MissingOperationsOverviewFiltersContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

/**
 * This provider allows anyone in the page to have access to the filterable properties for example
 */
export const OperationsOverviewFiltersProvider = function OperationsOverviewFiltersProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { state, dispatch } = useOperationsOverviewContext();

  const assetsFilter = useAssetsFilter();
  const accountsFilter = useMarketAccountsFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof PositionsTableFilter>>([
        { ...assetsFilter, label: 'Instrument' },

        accountsFilter,
      ]),
    [assetsFilter, accountsFilter]
  );

  const { additionalFilterState, filterPropsMutator } = useContextBlotterFilter<{
    filterBuilderProps: UseFilterBuilderProps;
  }>();

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    const filter = state.filter;
    if (filter) {
      getTypedKeys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }
    return clauses;
  }, [state.filter]);

  const onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'] = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      const newFilter: PositionsTableFilter = {
        Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
        MarketAccounts:
          additionalFilterState?.MarketAccounts ?? filterClausesByPropertyKey.get('MarketAccounts')?.selections,
      };
      dispatch({
        type: OperationsOverviewActionType.FilterChange,
        payload: {
          filter: newFilter,
        },
      });
    },
    [dispatch, additionalFilterState]
  );

  const filterBuilderProps = useMemo(() => {
    return filterPropsMutator({
      filterBuilderProps: {
        properties: filterableProperties,
        initialFilterClauses,
        onFilterClausesChanged,
      },
    }).filterBuilderProps;
  }, [filterPropsMutator, filterableProperties, initialFilterClauses, onFilterClausesChanged]);

  // The ops overview page receives the MarketAccounts filter from a parent component. Here is where we push it into our own internal plumbing
  // to keep it in sync. The communication here is one way too
  useEffect(() => {
    if (additionalFilterState && 'MarketAccounts' in additionalFilterState) {
      dispatch({
        type: OperationsOverviewActionType.MarketAccountsChange,
        payload: {
          marketAccounts: additionalFilterState.MarketAccounts,
        },
      });
    }
  }, [additionalFilterState, dispatch]);

  const value = useMemo(() => {
    return {
      filterableProperties: filterBuilderProps.properties,
      initialFilterClauses: filterBuilderProps.initialFilterClauses,
      onFilterClausesChanged: filterBuilderProps.onFilterClausesChanged,
    };
  }, [filterBuilderProps]);

  return (
    <OperationsOverviewFiltersContext.Provider value={value}>{children}</OperationsOverviewFiltersContext.Provider>
  );
};
