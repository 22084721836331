import {
  MarketTypeEnum,
  SubAccountTypeEnum,
  toBigWithDefault,
  useMarketAccountsContext,
  useMarketsContext,
  useSecuritiesContext,
  type ISubaccount,
  type Market,
  type Security,
} from '@talos/kyoko';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useSubAccounts } from '../providers';
import { getPositionSecurities, type GetPositionSecuritiesParams, type PositionLike } from './getPositionSecurities';

export interface TradePositionDetails<T extends PositionLike> {
  /** The position-like entity these details are relevant for */
  position: T;
  /** A straight yes or no if you can trade this position */
  tradable: boolean;
  /** If you want to render a button for the user, whether or not the button should be visible given the input parameters */
  tradeButtonVisible: boolean;
  /** If closable is false this will be populated with a display string of why that's the case for the user to understand */
  notTradableReason?: string;
  /** The list of securities you can close the position using. If the position is not closable, this list will be empty. */
  possibleSecurities: Security[];
}

export interface GetTradePositionDetailsParams<T extends PositionLike> extends GetPositionSecuritiesParams<T> {
  marketsByName: Map<string, Market>;
  /** Provide a sub account field if you are working with sub account positions */
  subAccountField?: keyof T;
  /** Provide the subAccountsByName mapping if you are working with sub account positions */
  subAccountsByName?: Map<string, ISubaccount>;
}

export function getTradePositionDetails<T extends PositionLike>({
  action,
  position,
  assetType,
  assetField,
  marketAccountField,
  constrainSecuritiesToMarket,
  securitiesBySymbol,
  marketAccountsByName,
  marketsByName,
  subAccountField,
  subAccountsByName,
  spotTradingPairsByBaseCurrency,
  spotTradingPairsByQuoteCurrency,
}: GetTradePositionDetailsParams<T>): TradePositionDetails<T> {
  if (assetType == null) {
    return {
      position,
      tradable: false,
      tradeButtonVisible: false,
      notTradableReason: 'Unable to resolve asset type',
      possibleSecurities: [],
    };
  }

  // Jumping through some hoops to resolve the sub account type for the (maybe) given subAccountField + position
  const subAccountName = subAccountField ? get(position, subAccountField) : undefined;
  const subAccount =
    subAccountName && typeof subAccountName === 'string' ? subAccountsByName?.get(subAccountName) : undefined;
  if (subAccount && subAccount.Type === SubAccountTypeEnum.Rollup) {
    return {
      position,
      tradable: false,
      tradeButtonVisible: false,
      notTradableReason: 'Cannot trade Rollup Positions',
      possibleSecurities: [],
    };
  }

  // Jumping through some hoops to resolve the market type for the (maybe) given marketAccountField + position
  const marketAccountName = marketAccountField ? get(position, marketAccountField) : undefined;
  const marketAccount =
    marketAccountName && typeof marketAccountName === 'string'
      ? marketAccountsByName.get(marketAccountName)
      : undefined;
  const market = marketAccount ? marketsByName.get(marketAccount.Market) : undefined;
  if (market && market.Type === MarketTypeEnum.Custodian) {
    return {
      position,
      tradable: false,
      tradeButtonVisible: true,
      notTradableReason: 'Unable to close positions at custodians',
      possibleSecurities: [],
    };
  }

  if (action === 'Close') {
    // If we intend to close a position, it only makes sense to have the button enabled if the amount is non-zero.
    // For the remaining actions, buy and sell, you should still be able to buy and sell despite the amount being 0.
    const amount = toBigWithDefault(position?.Amount, 0);
    if (amount.eq(0)) {
      return {
        position,
        tradable: false,
        tradeButtonVisible: true,
        notTradableReason: 'Amount is 0',
        possibleSecurities: [],
      };
    }
  }

  const possibleSecurities = getPositionSecurities({
    action,
    position,
    assetType,
    constrainSecuritiesToMarket,
    assetField,
    marketAccountField,
    securitiesBySymbol,
    marketAccountsByName,
    spotTradingPairsByBaseCurrency,
    spotTradingPairsByQuoteCurrency,
  });

  if (possibleSecurities.length === 0) {
    return {
      position,
      tradable: false,
      tradeButtonVisible: true,
      notTradableReason: 'No instruments found',
      possibleSecurities,
    };
  }

  return {
    position,
    tradable: true,
    tradeButtonVisible: true,
    possibleSecurities,
  };
}

/**
 * The hook version of GetTradePositionDetailsParams.
 *
 * This type omits most of the reference data parameters all together, but also makes some optional
 * which you may want to override.
 */
type HookGetTradePositionDetailsParams<T extends PositionLike> = Omit<
  GetTradePositionDetailsParams<T>,
  | 'marketsByName'
  | 'subAccountsByName'
  | 'marketAccountsByName'
  | 'securitiesBySymbol'
  | 'spotTradingPairsByBaseCurrency'
  | 'spotTradingPairsByQuoteCurrency'
> &
  Partial<Pick<GetTradePositionDetailsParams<T>, 'spotTradingPairsByBaseCurrency' | 'spotTradingPairsByQuoteCurrency'>>;

/**
 * A hook version of `getTradePositionDetails` which will provide the necessary reference data sets
 * into the function so you don't have to grab all of them yourself.
 *
 * The hook will also provide the default spotTradingPairsByBase/QuoteCurrency parameters, but still lets you override them if you wish.
 *
 * Returns an unstable function.
 */
export const useGetTradePositionDetails = () => {
  const { marketsByName } = useMarketsContext();
  const { marketAccountsByName } = useMarketAccountsContext();
  const { subAccountsByName } = useSubAccounts();
  const { securitiesBySymbol, spotTradingPairsByBaseCurrency, spotTradingPairsByQuoteCurrency } =
    useSecuritiesContext();

  return useCallback(
    <T extends PositionLike>(params: HookGetTradePositionDetailsParams<T>) => {
      return getTradePositionDetails({
        spotTradingPairsByBaseCurrency,
        spotTradingPairsByQuoteCurrency,
        marketsByName,
        marketAccountsByName,
        subAccountsByName,
        securitiesBySymbol,
        ...params,
      });
    },
    [
      marketsByName,
      marketAccountsByName,
      subAccountsByName,
      securitiesBySymbol,
      spotTradingPairsByBaseCurrency,
      spotTradingPairsByQuoteCurrency,
    ]
  );
};
