import {
  cleanupInitialFilterDateRange,
  parseDate,
  type BlotterTableClientLocalFilter,
  type DateRangeFilter,
  type FilterableProperty,
} from '@talos/kyoko';
import { isAfter, isBefore } from 'date-fns';
import { isEqual } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import type { CareOrderBlotterEntity } from './types';

export interface CareOrderFilter extends DateRangeFilter {
  // Nothing here yet
}

interface UseCareOrderFilterParams {
  initialFilter?: CareOrderFilter;
  saveFilter: (newFilter: any) => void;
}

export function useCareOrderFilter({ initialFilter, saveFilter }: UseCareOrderFilterParams) {
  const [filter, setFilter] = useState(() => cleanupInitialFilterDateRange(initialFilter));

  const changeFilter = useCallback(
    (action: SetStateAction<CareOrderFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<CareOrderBlotterEntity>>(
    row => {
      const data = row.data;
      if (!data) {
        return false;
      }
      const startDate = filter.StartDate == null ? undefined : parseDate(filter.StartDate);
      const endDate = filter.EndDate == null ? undefined : parseDate(filter.EndDate);
      const submitTime = parseDate(data.SubmitTime);
      const timestamp = parseDate(data.Timestamp);
      if (startDate != null) {
        if (isBefore(submitTime, startDate) && isBefore(timestamp, startDate)) {
          return false;
        }
      }
      if (endDate != null) {
        if (isAfter(submitTime, endDate) && isAfter(timestamp, endDate)) {
          return false;
        }
      }
      return true;
    },
    [filter]
  );

  const filterableProperties: FilterableProperty[] = useMemo(() => [], []);

  const initialFilterClauses = useMemo(() => {
    return [];
  }, []);

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
    clientSideFilter,
  };
}

// Map from a ag-grid columnID to a filterbuilder key
// Returns undefined if the column can not be mapped to a filter builder clause
export function colIDToFilterBuilderKey(id: string): keyof CareOrderFilter | undefined {
  return undefined;
}
