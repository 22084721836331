import { useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Observable, type Subscriber } from 'rxjs';

export function useResizeObservable<T extends HTMLElement>() {
  const elementRef = useRef<T>(null);

  const [sizeObservable] = useState(
    new Observable((subscriber: Subscriber<ResizeObserverEntry>) => {
      const observer = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
        // https://stackoverflow.com/a/58701523
        window.requestAnimationFrame(() => {
          if (elementRef.current) {
            subscriber.next(entry);
          }
        });
      });

      if (elementRef.current) {
        observer.observe(elementRef.current);
      } else {
        console.warn(
          'Undefined element ref provided to useResizeObservable. Make sure the observed element is rendered while being observed'
        );
      }

      return () => observer.disconnect();
    })
  );

  return { elementRef, sizeObservable };
}
