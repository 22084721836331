import {
  Box,
  Flex,
  getCurrencyColor,
  HStack,
  IndicatorBadgeSizes,
  LoaderTalos,
  PieChart,
  setAlpha,
  Text,
  toBigWithDefault,
  useDynamicCallback,
  useHighchartsRef,
  VStack,
} from '@talos/kyoko';
import Big from 'big.js';
import type { Chart, Options } from 'highcharts';
import { groupBy, map as lodashMap, sortBy, sumBy } from 'lodash';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { type DefaultTheme, ThemeProvider, useTheme } from 'styled-components';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { portfolioAbbreviation } from '../../portfolioAbbreviation';
import { type BalancesPieDirection, type BalancesPiePointCustom, getPointCustomAttrs } from './types';

type ShowByKeys = 'market' | 'asset';

interface ChartFocus {
  label: string;
  value: string | undefined;
}

interface OperationsPieProps<T> {
  entities: T[] | undefined;
  /** Grab the property to group on from the balance. If undefined is returned, the value will not be included in any resulting groupings. */
  getGroupBy: (item: T) => string | undefined;
  /** Get the label of the item. Should be the label corresponding to the key we're grouping by */
  getLabel: (item: T) => string;
  /** Get the numeric value of the entity to be summed into the group's aggregate value */
  getValue: (item: T) => Big;

  /** Show by is just categorized into market or asset */
  showBy: ShowByKeys;
  /** Event called with the key of the slice */
  onSliceClick?: (key: string) => void;
  /** What time to show that the data is for. If the data is live, pass null. */
  asOf: string | null;
  /** The center label when no element is hovered */
  centerLabel: string;
}

export const OperationsPie = <T,>({
  entities,
  getGroupBy,
  getValue,
  getLabel,
  showBy,
  onSliceClick,
  asOf,
  centerLabel,
}: OperationsPieProps<T>) => {
  const { homeCurrency } = useDisplaySettings();
  const [hoveredPoint, setHoveredPoint] = useState<Highcharts.Point>();

  const { chartRef, setChartObject, isLoaded } = useHighchartsRef();
  const theme = useTheme();

  const chartData = useMemo(() => {
    if (!entities) {
      return undefined;
    }

    const positiveEntities: T[] = [];
    const negativeEntities: T[] = [];

    for (const entity of entities) {
      const value = getValue(entity);
      if (value.lt(0)) {
        negativeEntities.push(entity);
      } else if (value.gt(0)) {
        positiveEntities.push(entity);
      }
    }

    const positiveData = getDataPoints(positiveEntities, showBy, 'positive', theme, getValue, getGroupBy, getLabel);
    const negativeData = getDataPoints(negativeEntities, showBy, 'negative', theme, getValue, getGroupBy, getLabel);

    const total = [...positiveData, ...negativeData]
      .reduce((acc, dp) => acc.plus(toBigWithDefault(dp.custom.nonAbsValue, 0)), Big(0))
      .toFixed();

    return {
      positiveData,
      negativeData,
      total,
    };
  }, [entities, showBy, theme, getValue, getGroupBy, getLabel]);

  useEffect(() => {
    if (!isLoaded || !chartData) {
      return;
    }

    // updatePoints below is false so that the blotter applies our data as we provide it and does not try to be smart with delta updates. Delta updates break our pre-determined sort
    const series = chartRef.current?.series.at(0);
    if (series) {
      series.setData([...chartData.positiveData, ...chartData.negativeData], true, true, false);
    }
  }, [isLoaded, chartData, chartRef]);

  const [centerStyle, setCenterStyle] = useState<React.CSSProperties | null>(null);

  // We reset the the position of the center component programmatically whenever the chart Redraws at all
  const handleRedraw = useDynamicCallback((chart: Chart) => {
    const x = chart.plotLeft + chart.series[0].center[0];
    const y = chart.plotTop + chart.series[0].center[1];
    const innerDiameter = chart.series[0].center[3];
    const innerRadius = innerDiameter / 2;

    setCenterStyle({
      width: `${innerDiameter}px`,
      height: `${innerDiameter}px`,
      justifyContent: 'center',
      alignItems: 'center',
      left: x - innerRadius,
      top: y - innerRadius,
    });
  });

  const handleClick = useDynamicCallback((event: Highcharts.SeriesClickEventObject) => {
    const point = event.point;
    if (!point) {
      return;
    }

    const key = getPointCustomAttrs(point)?.key;
    key && onSliceClick?.(key);
  });

  const handleMouseOver = useDynamicCallback((point: Highcharts.Point) => {
    if (!point) {
      return;
    }

    setHoveredPoint(point);
  });

  const handleMouseOut = useDynamicCallback((point: Highcharts.Point) => {
    setHoveredPoint(undefined);
  });

  const options: Options = useMemo(
    () => ({
      type: 'pie',
      tooltip: {
        enabled: false,
      },
      chart: {
        animation: false,
        events: {
          redraw: function (event) {
            handleRedraw(this);
          },
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          type: 'pie',
          id: 'main',
          index: 0,
          borderRadius: 0,
          borderWidth: 1,
          borderColor: theme.backgroundBody,
          fillColor: 'transparent',
          color: 'transparent',
          events: {
            click: function (event) {
              handleClick(event);
            },
          },
          point: {
            events: {
              mouseOver: function (event) {
                handleMouseOver(this);
              },
              mouseOut: function (event) {
                handleMouseOut(this);
              },
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          animation: false,
          innerSize: '85%',
          center: ['50%', '50%'],
          minSize: 200,

          states: {
            hover: {
              halo: {
                size: 0,
              },
            },
          },

          dataLabels: {
            enabled: true,
            style: {
              textOutline: '0',
              color: theme.colorTextAttention,
              fontWeight: '400',
            },
            connectorColor: theme.colorTextMuted,
          },
        },
      },
    }),
    [theme, handleRedraw, handleClick, handleMouseOut, handleMouseOver]
  );

  const chartFocus = useMemo(() => {
    return hoveredPoint
      ? {
          label: hoveredPoint.name,
          value: getPointCustomAttrs(hoveredPoint)?.nonAbsValue?.toString(),
        }
      : { label: centerLabel, value: chartData?.total };
  }, [hoveredPoint, centerLabel, chartData?.total]);

  return (
    <Box w="100%" h="100%" position="relative" maxHeight="500px">
      {entities == null ? (
        <LoaderTalos />
      ) : entities.length === 0 ? (
        /* VStack to center the text on the screen */
        <VStack h="100%">
          <Text>No data found</Text>
        </VStack>
      ) : (
        <>
          {/* magic number 30 throttle resize makes it look like its perfectly fluid but we still get a bit of help with perf so its not gigaspammed */}
          <PieChart onChartCreated={setChartObject} options={options} throttleResizing={30} />
          {/* Wait to render the center bit until the chart itself has mounted and we've figured out how to place the center bit */}
          {centerStyle != null && (
            <Flex position="absolute" style={{ ...centerStyle, pointerEvents: 'none' }}>
              <Center theme={theme} snapshotDate={asOf} currency={homeCurrency} focus={chartFocus} />
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

const Center = ({
  snapshotDate,
  theme,
  currency,
  focus,
}: {
  snapshotDate: string | null;
  theme: DefaultTheme;
  currency: string;
  focus: ChartFocus;
}) => {
  const valueIsNegative = toBigWithDefault(focus.value, 0).lt(0);

  return (
    <ThemeProvider theme={theme}>
      <VStack data-testid="balances-pie-chart-center">
        <Text color="colorTextSubtle">{focus.label}</Text>
        <HStack alignItems="baseline" gap="spacingSmall" mb="spacingDefault">
          <Text
            weight="fontWeightMedium"
            fontSize="24px"
            color={valueIsNegative ? 'colorTextNegative' : 'colorTextImportant'}
          >
            {focus.value ? portfolioAbbreviation(focus.value) : '--'}
          </Text>
          <Text weight="fontWeightMedium" color="colorTextSubtle" fontSize="18px">
            {currency}
          </Text>
        </HStack>
        <AsOfDateBadge size={IndicatorBadgeSizes.Small} snapshotDate={snapshotDate} />
      </VStack>
    </ThemeProvider>
  );
};

function getDataPoints<T>(
  entities: T[],
  showBy: ShowByKeys,
  direction: BalancesPieDirection,
  theme: DefaultTheme,
  getValue: OperationsPieProps<T>['getValue'],
  getGroupBy: OperationsPieProps<T>['getGroupBy'],
  getLabel: OperationsPieProps<T>['getLabel']
) {
  const groups = groupBy(entities, entity => getGroupBy(entity));
  const dataPoints = lodashMap(groups, (entities, key) => ({
    key,
    // the label of the grouping can be grabbed from any member of the grouped array since that's what they're grouped on
    label: getLabel(entities[0]),
    value: sumBy(entities, b => getValue(b).toNumber()),
  }));

  const dataPointsTotal = sumBy(dataPoints, dp => dp.value);
  const sortedDataPoints = sortBy(dataPoints, dp => dp.value).reverse();

  const dataPointsCount = sortedDataPoints.length;
  const startingColor = direction === 'positive' ? theme.colorDataBlue : theme.colors.red.lighten;
  const totalOpacityReduction = 0.6;
  const opacityReductionPerIndex = dataPointsCount === 0 ? 0 : totalOpacityReduction / dataPointsCount;

  const data = sortedDataPoints.map((dp, i) => {
    let color = '';

    // colors
    if (showBy === 'asset' && direction === 'positive') {
      color = getCurrencyColor(dp.key) ?? theme.colorTextDefault;
    } else {
      // show by market _and_ negative direction assets
      // For negative coloring, we color them in the opposite direction. This is because theyre like sorted in opposite directions. Hence this inversion logic
      const colorIndex = direction === 'positive' ? i : sortedDataPoints.length - i - 1;
      color = setAlpha(1 - colorIndex * opacityReductionPerIndex, startingColor);
    }

    return {
      id: `${direction}-${dp.key}`,
      y: Math.abs(dp.value),
      custom: {
        key: dp.key,
        nonAbsValue: dp.value,
        totalDirectionValueAbs: Math.abs(dataPointsTotal),
        percentageOfDirection: Math.abs(dp.value) / Math.abs(dataPointsTotal),
        absValue: Math.abs(dp.value),
        direction,
      } satisfies BalancesPiePointCustom,
      color,
      name: dp.label,
    };
  });

  return data;
}
