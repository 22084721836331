import { useCallback, useState } from 'react';
import { Icon, IconName } from '../Icons';
import { GroupLabel, ItemContentWrapper, ItemHeader, ItemTrack, ItemTrackContainer, ItemWrapper } from './styles';
import type { TimelineGroupProps } from './types';

export function TimelineGroup({
  label,
  variant = 'default',
  icon = IconName.Circle,
  children,
  initialExpanded,
  onExpand,
}: TimelineGroupProps) {
  const [isExpanded, setExpanded] = useState<boolean>(initialExpanded);

  const handleExpandGroup = useCallback(() => {
    setExpanded(prev => !prev);
    onExpand();
  }, [onExpand]);

  return isExpanded ? (
    <>{children}</>
  ) : (
    <ItemWrapper>
      <ItemTrackContainer variant={variant}>
        <Icon icon={icon} size={16} />
        <ItemTrack />
      </ItemTrackContainer>
      <ItemContentWrapper>
        <ItemHeader>
          <GroupLabel onClick={handleExpandGroup}>{label}</GroupLabel>
        </ItemHeader>
      </ItemContentWrapper>
    </ItemWrapper>
  );
}
