import {
  Alert,
  AlertVariants,
  Button,
  Dialog,
  Flex,
  FormControlSizes,
  HedgeCommandStatusEnum,
  HedgeControlStatusEnum,
  HelpIcon,
  HStack,
  IconName,
  NotificationVariants,
  Text,
  Tooltip,
  useDisclosure,
  type IHedgeRule,
  type ToastProps,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { useHedgeRuleCommandMutation } from '../../containers/Trading/Markets/PositionAutoHedgingRules/queries';
import type { IHedgePositionStatusRow } from '../../providers/HedgePositionStatusProvider';

export function PositionAutoHedgingStatus({
  hedgePositionStatus,
  hedgeRule,
  addToast,
  hasFormChanged,
}: {
  hedgePositionStatus: IHedgePositionStatusRow;
  hedgeRule: IHedgeRule;
  addToast: (toast: ToastProps) => void;
  hasFormChanged: boolean;
}) {
  const ruleDisabled = hedgePositionStatus.HedgeControlStatus === HedgeControlStatusEnum.Disabled;

  const sendHedgeCommandMutation = useHedgeRuleCommandMutation();
  const sendHedgeCommand = useSendHedgeCommand({ hedgeRuleID: hedgeRule.HedgeRuleID, addToast });
  const openOrdersWillCancelWarningDialog = useDisclosure();

  const handleClickButton = useCallback(() => {
    const intent = ruleDisabled ? 'Enable' : 'Disable';
    if (intent === 'Disable') {
      if (
        hedgePositionStatus.HedgeControlStatus === HedgeControlStatusEnum.Hedging &&
        hedgePositionStatus.HedgeOrderID
      ) {
        openOrdersWillCancelWarningDialog.open();
      } else {
        sendHedgeCommand('Kill');
      }
    } else {
      sendHedgeCommand('Activate');
    }
  }, [
    hedgePositionStatus.HedgeControlStatus,
    hedgePositionStatus.HedgeOrderID,
    openOrdersWillCancelWarningDialog,
    ruleDisabled,
    sendHedgeCommand,
  ]);

  const disallowEnablingRule = hasFormChanged && ruleDisabled;

  return (
    <>
      <Alert
        variant={ruleDisabled ? AlertVariants.Negative : AlertVariants.Positive}
        dismissable={false}
        alignItems="center"
        p="spacingDefault"
      >
        Rule is currently {ruleDisabled ? 'disabled' : 'enabled'}.
        <HStack ml="auto" gap="spacingSmall">
          {disallowEnablingRule && (
            <Tooltip tooltip="Please save or discard your changes before enabling the rule.">
              <HelpIcon />
            </Tooltip>
          )}
          <Button
            disabled={disallowEnablingRule}
            data-testid="toggle-hedgerule"
            loading={sendHedgeCommandMutation.isPending}
            onClick={handleClickButton}
            size={FormControlSizes.Small}
          >
            {ruleDisabled ? 'Enable' : 'Disable'}
          </Button>
        </HStack>
      </Alert>

      <Dialog
        {...openOrdersWillCancelWarningDialog}
        usePortal={true}
        portalID="drawer-portal"
        stretchButtons
        title="Order actively hedging"
        dataTestId="open-orders-will-cancel-warning-dialog"
        onConfirm={() => {
          sendHedgeCommand('Kill');
        }}
      >
        There is an open order actively hedging this position. Disabling this rule will cancel the hedging order.
      </Dialog>
    </>
  );
}

export const PositionControlActions = ({
  hedgeRule,
  addToast,
  hedgePositionStatus,
}: {
  hedgeRule: IHedgeRule;
  addToast: (toast: ToastProps) => void;
  hedgePositionStatus: IHedgePositionStatusRow;
}) => {
  const sendHedgeCommandMutation = useHedgeRuleCommandMutation();
  const sendHedgeCommand = useSendHedgeCommand({ hedgeRuleID: hedgeRule.HedgeRuleID, addToast });

  return (
    <HStack
      py="spacingDefault"
      px="spacingMedium"
      alignItems="center"
      gap="spacingMedium"
      w="100%"
      justifyContent="space-between"
    >
      <Text>Tools:</Text>
      <Flex gap="spacingDefault" w="100%" justifyContent="flex-end">
        <Button
          data-testid="restart-hedge-control"
          disabled={hedgePositionStatus.HedgeControlStatus !== HedgeControlStatusEnum.Error}
          size={FormControlSizes.Small}
          loading={sendHedgeCommandMutation.isPending}
          startIcon={IconName.LightningBolt}
          onClick={() => {
            sendHedgeCommand('Activate');
          }}
        >
          Restart Control
        </Button>
      </Flex>
    </HStack>
  );
};

const useSendHedgeCommand = ({
  hedgeRuleID,
  addToast,
}: {
  hedgeRuleID: string;
  addToast: (toast: ToastProps) => void;
}) => {
  const sendHedgeCommandMutation = useHedgeRuleCommandMutation();

  return useCallback(
    (command: 'Activate' | 'Kill') => {
      sendHedgeCommandMutation
        .mutateAsync({
          Command: command,
          HedgeRuleID: hedgeRuleID,
        })
        .then(res => {
          if (res.Status === HedgeCommandStatusEnum.Success) {
            addToast({
              text: `Successfully ${command === 'Activate' ? 'enabled' : 'disabled'} rule.`,
              variant: NotificationVariants.Positive,
            });
          } else {
            addToast({
              text: `Failed to ${command === 'Activate' ? 'enable' : 'disable'} rule.\n${res.Error}`,
              variant: NotificationVariants.Negative,
            });
          }
        })
        .catch(() => {
          addToast({
            text: `Failed to ${command === 'Activate' ? 'enable' : 'disable'} rule.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, hedgeRuleID, sendHedgeCommandMutation]
  );
};
