import type { Market } from '@talos/kyoko';
import {
  ConnectionType,
  getMACKey,
  intersectTwoSets,
  useMarketAccountCurrenciesContext,
  useMarketAccountsContext,
  useMarketsContext,
  useObservableValue,
} from '@talos/kyoko';
import { sortBy, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { filterDestinationMarketAccounts, filterSourceMarketAccounts } from './filtering';
import { marketAccountToSourceDestinationOption, type SourceDestinationOption, type TransferForm } from './types';

export interface UseTransferFormOptionsOutput {
  providerOptions: Market[];
  assetOptions: string[];
  chainCurrencyOptions: ReturnType<typeof useChainCurrencyOptions>;
  sourceOptions: SourceDestinationOption[];
  destinationOptions: SourceDestinationOption[];
}

/**
 * This hook computes the options available to the user for selection within each input based on the provided selections through the form argument
 * @param form a partial TransferForm from which the hook grabs the current relevant selections for computing the options available to you
 * Returns some data sets representing the options available in the form
 */
export const useTransferFormOptions = (form: Partial<TransferForm>): UseTransferFormOptionsOutput => {
  const { provider, asset, source, destination } = form;
  const providerOptions = useProviderOptions();
  const assetOptions = useAssetOptions({ market: provider });
  const chainCurrencyOptions = useChainCurrencyOptions({ source, destination, currency: asset });
  const { sourceOptions, destinationOptions } = useSourceAndDestinationOptions({
    market: provider,
    currency: asset,
    source,
    destination,
  });

  const assetOptionsList = useMemo(() => {
    return [...(assetOptions ?? [])];
  }, [assetOptions]);

  const value = useMemo(() => {
    return {
      providerOptions,
      assetOptions: assetOptionsList,
      chainCurrencyOptions: chainCurrencyOptions,
      sourceOptions: sourceOptions,
      destinationOptions: destinationOptions,
    };
  }, [providerOptions, assetOptionsList, chainCurrencyOptions, sourceOptions, destinationOptions]);

  return value;
};

export const useProviderOptions = () => {
  const { marketsList, isMarketOnline } = useMarketsContext();

  const providerOptions = useMemo(() => {
    return uniqBy(
      marketsList.filter(m => isMarketOnline(m, ConnectionType.Transfers)),
      m => m.Name
    );
  }, [isMarketOnline, marketsList]);

  return providerOptions;
};

interface UseAssetOptionsParams {
  market?: string;
  marketAccountID?: number;
}

export const useAssetOptions = ({ market, marketAccountID }: UseAssetOptionsParams) => {
  const { acceptedCurrenciesByMarketObs, acceptedCurrenciesObs, acceptedCurrenciesByMarketAccountIDObs } =
    useMarketAccountCurrenciesContext();
  const assetsByMarket = useObservableValue(() => acceptedCurrenciesByMarketObs, [acceptedCurrenciesByMarketObs]);
  const assetsByMarketAccountID = useObservableValue(
    () => acceptedCurrenciesByMarketAccountIDObs,
    [acceptedCurrenciesByMarketAccountIDObs]
  );
  const assets = useObservableValue(() => acceptedCurrenciesObs, [acceptedCurrenciesObs]);

  if (marketAccountID) {
    return assetsByMarketAccountID?.get(marketAccountID);
  }

  if (market) {
    return assetsByMarket?.get(market);
  }

  return assets;
};

interface UseChainCurrencyOptionsParams {
  source?: SourceDestinationOption;
  destination?: SourceDestinationOption;
  currency?: string;
}

export const useChainCurrencyOptions = ({
  source,
  destination,
  currency,
}: UseChainCurrencyOptionsParams): { chainOptions: string[]; required: boolean } => {
  const { marketAccountCurrenciesByKeyObs } = useMarketAccountCurrenciesContext();
  const marketAccountCurrenciesByKey = useObservableValue(
    () => marketAccountCurrenciesByKeyObs,
    [marketAccountCurrenciesByKeyObs]
  );

  if (marketAccountCurrenciesByKey == null || currency == null || source == null || destination == null) {
    return { chainOptions: [], required: false };
  }

  // If both exist and can be resolved, the possible chain currency options you can select are the intersection of both of the two MACs chain currencies
  const sourceMAC = marketAccountCurrenciesByKey.get(getMACKey(source.marketAccount.MarketAccountID, currency));
  const destinationMAC = marketAccountCurrenciesByKey.get(
    getMACKey(destination.marketAccount.MarketAccountID, currency)
  );

  // If we are unable to find any MAC for the account, it is treated as there being an MAC with ChainCurrencySymbols = []
  const sourceChains = sourceMAC?.ChainCurrencySymbols ?? [];
  const destinationChains = destinationMAC?.ChainCurrencySymbols ?? [];

  if (sourceChains.length > 0 && destinationChains.length > 0) {
    // find the intersection between these two *populated* lists of chains
    return { chainOptions: [...intersectTwoSets(new Set(sourceChains), new Set(destinationChains))], required: true };
  }

  // A selection is required to be made in the form if either the source or dest chains are populated
  const required = sourceChains.length > 0 || destinationChains.length > 0;
  // Either one of them is populated, or none of them are, doesn't matter.
  return { chainOptions: [...sourceChains, ...destinationChains], required };
};

interface UseSourceAndDestinationOptionsParams {
  market?: string;
  currency?: string;
  source?: SourceDestinationOption;
  destination?: SourceDestinationOption;
}

export const useSourceAndDestinationOptions = ({
  market,
  currency,
  source,
  destination,
}: UseSourceAndDestinationOptionsParams) => {
  const { marketAccountsList } = useMarketAccountsContext();
  const { acceptedCurrenciesByMarketAccountIDObs } = useMarketAccountCurrenciesContext();

  const acceptedCurrenciesByMarketAccountID = useObservableValue(
    () => acceptedCurrenciesByMarketAccountIDObs,
    [acceptedCurrenciesByMarketAccountIDObs]
  );

  const { marketAccountCurrenciesByKeyObs } = useMarketAccountCurrenciesContext();
  const MACsByKey = useObservableValue(() => marketAccountCurrenciesByKeyObs, [marketAccountCurrenciesByKeyObs]);

  const sourceOptions = useMemo(() => {
    if (!acceptedCurrenciesByMarketAccountID || !MACsByKey) {
      return [];
    }

    const options = filterSourceMarketAccounts({
      marketAccounts: marketAccountsList,
      acceptedCurrenciesByMarketAccountID,
      MACsByKey,
      market,
      currency,
      destination: destination?.marketAccount.MarketAccountID,
    }).map(ma => marketAccountToSourceDestinationOption(ma, currency));
    const sortedOptions = sortBy(options, 'DisplayName');
    return sortedOptions;
  }, [currency, acceptedCurrenciesByMarketAccountID, market, marketAccountsList, MACsByKey, destination]);

  const destinationOptions = useMemo(() => {
    if (!acceptedCurrenciesByMarketAccountID || !MACsByKey) {
      return [];
    }

    const options = filterDestinationMarketAccounts({
      marketAccounts: marketAccountsList,
      acceptedCurrenciesByMarketAccountID,
      MACsByKey,
      market,
      currency,
      source: source?.marketAccount.MarketAccountID,
    }).map(ma => marketAccountToSourceDestinationOption(ma, currency));
    const sortedOptions = sortBy(options, 'DisplayName');
    return sortedOptions;
  }, [currency, acceptedCurrenciesByMarketAccountID, market, marketAccountsList, MACsByKey, source]);

  return {
    sourceOptions,
    destinationOptions,
  };
};
