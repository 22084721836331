import {
  FormControlSizes,
  HStack,
  IconButton,
  IconName,
  LoaderSizes,
  LoaderTalos,
  readableDateWithSeconds,
  Text,
  Tooltip,
} from '@talos/kyoko';
import type { useReconCheckpointNavigation } from './useReconCheckpointNavigation';

type ReconCheckpointNavigationProps = ReturnType<typeof useReconCheckpointNavigation> & {
  /** The currently used StartTime for viewing Recon Checkpoints. Undefined means now. */
  startTime: Date | undefined;
  /** The currently used Endtime for viewing ReconCheckpoints. Undefined means now. */
  endTime: Date | undefined;

  /** The last received Checkpoint.LastUpdateTime */
  lastUpdateTime: Date | undefined;
  /** Set to true to signify that something related to navigation is loading. Shows a spinner. */
  isLoading?: boolean;
};

export const ReconCheckpointNavigation = ({
  startTime,
  endTime,
  lastUpdateTime,
  resetToNow,
  stepBackwards,
  stepForwards,
  stepForwardsDisabled,
  stepBackwardsDisabled,
  resetDisabled,
  isLoading,
}: ReconCheckpointNavigationProps) => {
  return (
    <HStack gap="spacingDefault" color="colorTextImportant" fontSize="fontSizeSm">
      <Tooltip tooltip="Previous checkpoint">
        <IconButton
          icon={IconName.ChevronLeft}
          disabled={stepBackwardsDisabled}
          onClick={stepBackwards}
          size={FormControlSizes.Small}
          data-testid="recon-checkpoint-nav-step-backwards"
        />
      </Tooltip>

      <Tooltip tooltip="Next checkpoint">
        <IconButton
          icon={IconName.ChevronRight}
          disabled={stepForwardsDisabled}
          onClick={stepForwards}
          size={FormControlSizes.Small}
          data-testid="recon-checkpoint-nav-step-forwards"
        />
      </Tooltip>
      <Tooltip tooltip="Reset to current checkpoint">
        <IconButton
          icon={IconName.Refresh}
          disabled={resetDisabled}
          onClick={resetToNow}
          size={FormControlSizes.Small}
          data-testid="recon-checkpoint-nav-reset"
        />
      </Tooltip>
      <Tooltip tooltip="Timestamp of the last transaction evaluated by the Reconciliation Engine">
        <Text whiteSpace="nowrap">As of {readableDateWithSeconds(lastUpdateTime)}</Text>
      </Tooltip>
      {isLoading && <LoaderTalos size={LoaderSizes.XS} />}
    </HStack>
  );
};
