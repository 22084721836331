import {
  CopyCsv,
  CopyJson,
  Divider,
  type Exposure,
  IconName,
  selectAll,
  ShowJson,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
} from '@talos/kyoko';
import { getExpandCollapseMenus } from '@talos/kyoko/src/utils/gridMenu';
import type { GetContextMenuItemsParams, GridOptions, ICellRendererParams, MenuItemDef } from 'ag-grid-community';
import type { Position } from 'dockview';
import { compact, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';

export function RollupTreePositionMenu(
  params: ICellRendererParams & {
    onShowJson(data: Exposure): void;
  }
) {
  return (
    <>
      <ShowJson {...params} />
      <Divider orientation="horizontal" />
      <CopyJson {...params} />
      <CopyCsv {...params} />
    </>
  );
}

export function useRollupTreeMenu() {
  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems: GridOptions['getContextMenuItems'] = useCallback(
    (params: GetContextMenuItemsParams<Position>) => {
      const data = params?.node?.data;
      const groupData = params?.node?.groupData;
      if (isNil(data) && isNil(groupData)) {
        return [];
      }

      const items: Array<MenuItemDef | string> = [
        ...getExpandCollapseMenus(params),
        'separator',
        selectAll(params, mixpanel),
        'separator',
        ...getDefaultContextMenuItems(params),
        ...(data
          ? [
              {
                icon: `<i class="ag-icon ${IconName.Braces}"/>`,
                name: `Show JSON`,
                action: () => handleClickJson(data),
              },
              'separator',
            ]
          : []),
      ];

      return compact(items);
    },
    [getDefaultContextMenuItems, handleClickJson, mixpanel]
  );

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  return {
    getContextMenuItems,
    dialogs,
    onClickJson: handleClickJson,
  };
}
