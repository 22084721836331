import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import {
  initAgGrid,
  initBigJs,
  initDatadog,
  initIntercom,
  initMixpanel,
  initSentry,
  initWhyDidYouRender,
} from '@talos/kyoko';
import 'dockview/dist/styles/dockview.css';
import { isNil } from 'lodash';
import { successSubject, updateSubject } from 'providers/ServiceWorkerContext';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './devTools';
import { registerServiceWorker } from './registerServiceWorker';
import './utils/sugar';

const environment = import.meta.env.VITE_AVA_ENV;
const revision = import.meta.env.VITE_GIT_HASH;
const version = import.meta.env.VITE_NPM_PACKAGE_VERSION;

initWhyDidYouRender(environment);
initSentry({
  environment,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tunnel: import.meta.env.VITE_SENTRY_PROXY_URL,
});
initMixpanel({
  environment,
  mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN,
  apiHost: import.meta.env.VITE_MIXPANEL_PROXY_URL,
});
initAgGrid();
initIntercom(import.meta.env.VITE_INTERCOM_APP_ID);
initDatadog({
  environment,
  version: ['develop', 'staging', 'uat', 'alpha'].includes(environment) ? revision : version,
  applicationId: import.meta.env.VITE_DATADOG_APP_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  service: 'ava-ui',
});
initBigJs();

const rootElement = document.getElementById('app');
if (isNil(rootElement)) {
  throw Error('Could not find root element to render app into');
}
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
registerServiceWorker({
  onSuccess: registration => successSubject.next(registration),
  onUpdate: registration => updateSubject.next(registration),
});
