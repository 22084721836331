import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerBalance,
  CustomerUser,
  Order,
  type CustomerLedgerUpdateTypeEnum,
  type DetailsDrawerEntity,
} from '@talos/kyoko';
import { ANALYTICS_WIDTH } from '../../../styles/dimensions';

const initialState: DetailsDrawerState = {
  width: 560,
  entity: undefined,
  getDetailsHref: undefined,
  onClickTransaction: undefined,
};

export const detailsDrawerSlice = createSlice({
  name: 'detailsDrawer',
  initialState,
  reducers: {
    openDetails: (
      state,
      action: PayloadAction<{
        entity: DetailsDrawerState['entity'];
        getDetailsHref?: DetailsDrawerState['getDetailsHref'];
        onClickTransaction?: DetailsDrawerState['onClickTransaction'];
      }>
    ) => {
      const { entity, getDetailsHref, onClickTransaction } = action.payload;
      state.entity = entity;
      state.getDetailsHref = getDetailsHref;
      state.onClickTransaction = onClickTransaction;
      state.width = 560;
      if (entity instanceof Order) {
        state.width = ANALYTICS_WIDTH;
      }
      if (entity instanceof CustomerBalance) {
        state.width = 675;
      }
      if (entity instanceof CustomerUser) {
        state.width = 650;
      }
    },
  },
});

export const { openDetails } = detailsDrawerSlice.actions;

export interface DetailsDrawerState {
  width: number;
  entity?: DetailsDrawerEntity;
  getDetailsHref?: (entity: DetailsDrawerEntity) => string | undefined;
  onClickTransaction?: (customerBalance: CustomerBalance, transactionType?: CustomerLedgerUpdateTypeEnum) => void;
}
