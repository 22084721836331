import {
  EMPTY_OBJECT,
  FilterClauseType,
  IconName,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  orderStatusFilterTextToBackendStatus,
  removeEmptyFilters,
  useDateRangeFilter,
  useMarketAccountsContext,
  useOrderStatusFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type CustomerOrder,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type OrdStatusEnum,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useCustomerUsers } from 'hooks/useCustomer';
import { compact, isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import {
  useAccountsFilter,
  useCounterpartyFilter,
  useIDFilter,
  useSidesFilter,
  useStrategiesFilter,
  useSubAccountsFilter,
  useUsersFilter,
} from '../../../hooks';
import { useMarketAccountsFilter } from '../../../hooks/filters/useMarketAccountsFilter';

export interface CustomerOrdersTableFilter extends DateRangeFilter {
  Counterparty?: string[];
  MarketAccounts?: string[];
  Sides?: string[];
  Symbols?: string[];
  Strategies?: string[];
  /** These are the order status texts we are filtering by on the client side */
  _statuses?: string[];
  /* These are the order statuses that we are filtering by on the server */
  Statuses?: OrdStatusEnum[];
  Users?: string[];
  CustomerUsers?: string[];
  SubAccounts?: string[];
  Accounts?: string[];
  OrderID?: string;
  ClOrdID?: string;
  RFQID?: string;
}

export interface UseCustomerOrdersFilterParams<TData extends CustomerOrder> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useCustomerOrdersFilter<TData extends CustomerOrder>({
  persistedBlotterTable,
}: UseCustomerOrdersFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<CustomerOrdersTableFilter>((initialFilter as CustomerOrdersTableFilter) || {});
  const { marketAccountsByName } = useMarketAccountsContext();

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<CustomerOrder>>(
    row => {
      const { data } = row;
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Strategies', data, 'Strategy')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Statuses', data, 'OrdStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Users', data, 'User')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'CustomerUsers', data, 'CustomerUser')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'SubAccounts', data, 'SubAccount')) {
        return false;
      }
      if (filter.ClOrdID && filter.ClOrdID !== data?.ClOrdID) {
        return false;
      }

      if (filter.Accounts && filter.Accounts.length > 0) {
        const sourceAccountID = data ? marketAccountsByName.get(data.MarketAccount)?.SourceAccountID : undefined;
        if (sourceAccountID && !filter.Accounts?.includes(sourceAccountID)) {
          return false;
        }
      }
      return true;
    },
    [filter, marketAccountsByName]
  );

  const subAccountsFilter = useSubAccountsFilter(EMPTY_OBJECT);
  const usersFilter = useUsersFilter();
  const counterpartyFilter = useCounterpartyFilter({ singleSelect: true });
  const symbolFilter = useSymbolsFilter();
  const marketAccountsFilter = useMarketAccountsFilter();
  const customerUsers = useCustomerUsers();
  const accountsFilter = useAccountsFilter();
  const strategiesFilter = useStrategiesFilter();
  const sidesFilter = useSidesFilter();
  const orderStatusFilter = useOrderStatusFilter();
  const idFilter = useIDFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof CustomerOrdersTableFilter>>([
        { ...counterpartyFilter, label: 'Customer' },
        { ...marketAccountsFilter, label: 'Customer Market Account' },
        symbolFilter,
        sidesFilter,
        strategiesFilter,
        orderStatusFilter,
        usersFilter,
        {
          key: 'CustomerUsers',
          label: 'Customer User',
          icon: IconName.User,
          options: customerUsers?.map(c => c.Email) ?? [],
          getOptionLabel: (option: string) => option,
        },
        subAccountsFilter,
        accountsFilter,
        { ...idFilter, key: 'OrderID', label: 'Order ID' },
        { ...idFilter, key: 'RFQID', label: 'RFQ ID' },
        { ...idFilter, key: 'ClOrdID', label: 'ClOrdID' },
      ]),
    [
      counterpartyFilter,
      marketAccountsFilter,
      symbolFilter,
      usersFilter,
      subAccountsFilter,
      customerUsers,
      accountsFilter,
      strategiesFilter,
      sidesFilter,
      orderStatusFilter,
      idFilter,
    ]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CustomerOrdersTableFilter)[]).forEach((key: keyof CustomerOrdersTableFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'Statuses':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: CustomerOrdersTableFilter = removeEmptyFilters<CustomerOrdersTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CustomerOrdersTableFilter),
          OrderID: filterClausesByPropertyKey.get('OrderID')?.selections?.[0],
          RFQID: filterClausesByPropertyKey.get('RFQID')?.selections?.[0],
          ClOrdID: filterClausesByPropertyKey.get('ClOrdID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Counterparty: 'Counterparty',
  MarketAccount: 'MarketAccounts',
  Side: 'Sides',
  Symbol: 'Symbols',
  Strategy: 'Strategies',
  OrdStatus: '_statuses',
  User: 'Users',
  CustomerUser: 'CustomerUsers',
  SubAccount: 'SubAccounts',
  marketAccount: 'Accounts',
  OrderID: 'OrderID',
  RFQID: 'RFQID',
  ClOrdID: 'ClOrdID',
} satisfies Partial<Record<keyof CustomerOrder | 'marketAccount', keyof CustomerOrdersTableFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof CustomerOrdersTableFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

type CustomerOrdersServerFilter = Pick<CustomerOrdersTableFilter, 'StartDate' | 'EndDate' | 'OrderID' | 'RFQID'> & {
  Statuses?: string[];
  Counterparty?: string;
};

export function onlyServerFilterKeys(filter: CustomerOrdersTableFilter | undefined) {
  if (!filter) {
    return filter;
  }

  const serverFilter = {
    StartDate: filter.StartDate,
    EndDate: filter.EndDate,
    Statuses: orderStatusFilterTextToBackendStatus(filter._statuses),
    OrderID: filter.OrderID,
    RFQID: filter.RFQID,
    Counterparty: filter.Counterparty?.at(0),
  } satisfies CustomerOrdersServerFilter;

  return serverFilter;
}
