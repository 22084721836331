import { useEffect, useRef, useState } from 'react';

/**
 * Determine if an element is intersecting with the viewport (by default, the browser window)
 * - Internally this uses the IntersectionObserver (@see https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver)
 * @param ref React ref of the element to observe
 * @param options Intersection Observer's constructor options, with options to specify the viewport params
 * @returns whether the element is intersecting with the viewport
 */
export const useIsIntersecting = (ref: React.RefObject<Element>, options: IntersectionObserverInit = {}): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [options, ref]);

  return isIntersecting;
};
