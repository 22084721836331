import type { TabGroupSpec } from 'components/FlexibleLayout/types';

export const PORTFOLIO_DOCKVIEW_PANEL_GROUPS = [
  {
    type: 'blotters',
    label: 'Blotters',
  },
].reduce((res, group) => {
  res[group.type] = group;
  return res;
}, {} as { [key: string]: TabGroupSpec });

export type PortfolioViewType = 'portfolio-management' | 'risk' | 'operations';
