import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { Allocation, OrdTypeEnum, Order, Trade } from '@talos/kyoko';
import { AllocationValueTypeEnum, SideEnum } from '@talos/kyoko';
import { prepareAllocationOrderForUI } from '../../../utils/order';

export const getInitialState = (): ManualTradeEntryState => ({
  tradeBeingModified: undefined,
  form: {},
});

export const manualTradeSlice = createSlice({
  name: 'manualTradeSlice',
  initialState: getInitialState(),
  reducers: {
    prime: (state, { payload: trade }: PayloadAction<Trade | undefined>) => {
      if (trade == null) {
        state.tradeBeingModified = undefined;
        state.form = {};
        return;
      }
      state.tradeBeingModified = trade;
      state.form = {
        quantity: trade.Quantity,
        price: trade.Price,
        symbol: trade.Symbol,
        side: SideEnum[trade.Side!],
        fee: trade.Fee,
        feeCurrency: trade.FeeCurrency,
        group: trade.Group,
        market: trade.Market,
        marketAccount: trade.MarketAccount,
        amount: trade.Amount,
        comments: trade.Comments,
        transactTime: new Date(trade.TransactTime),
        orderId: trade.OrderID,
        ...getAllocations({
          SubAccount: trade.SubAccount,
          Allocation: trade.Allocation,
        }),
      };
    },
  },
});

const getAllocations = (
  order: Pick<Order, 'Allocation' | 'SubAccount'>
): {
  subAccountAllocations: Allocation[] | undefined;
  allocationValueType: AllocationValueTypeEnum | undefined;
} => {
  const { subAccountAllocations, allocationValueType } = order.Allocation
    ? prepareAllocationOrderForUI(order)
    : {
        subAccountAllocations: [
          {
            subAccount: order.SubAccount || '',
            value: '100',
          },
        ],
        allocationValueType: AllocationValueTypeEnum.Percentage,
      };

  return { subAccountAllocations, allocationValueType };
};

export const { prime } = manualTradeSlice.actions;
export interface ManualTradeEntryState {
  tradeBeingModified: Trade | undefined;
  form: ManualTradeEntryFormState;
}

export interface ManualTradeEntryFormState {
  side?: SideEnum;
  transactTime?: Date;
  symbol?: string;
  subAccount?: string;
  quantity?: string;
  amount?: string;
  price?: string;
  subAccountId?: number;
  fee?: string;
  feeCurrency?: string;
  market?: string;
  marketAccount?: string;
  comments?: string;
  group?: string;
  ordType?: OrdTypeEnum;
  subAccountAllocations?: Allocation[];
  allocationValueType?: AllocationValueTypeEnum;
  // We allow talos admins + support to modify regular (non-manual, non-orphan) trades. In which case, this property would be defined.
  orderId?: string;
}
