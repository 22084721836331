import { Button, ButtonVariants, IconName } from '@talos/kyoko';
import { useDerivedPositionsFormState, usePositionsFormDispatch } from 'containers/Portfolio/providers';
import { useCallback, useEffect, useState } from 'react';
import { PositionsActionType } from '../PositionsFormReducer';

export interface OnlyButtonProps {
  mktacc: string;
  className?: string;
}

export const OnlyButton = ({ mktacc, className }: OnlyButtonProps) => {
  const dispatch = usePositionsFormDispatch();
  const { isOnlySelectedMarketAccount } = useDerivedPositionsFormState();
  const [sayAll, setSayAll] = useState(false);

  useEffect(() => {
    setSayAll(isOnlySelectedMarketAccount(mktacc));
  }, [isOnlySelectedMarketAccount, mktacc]);

  const handleClicked = useCallback(
    e => {
      e.stopPropagation();
      if (sayAll) {
        dispatch({
          type: PositionsActionType.MarketAccountAllClicked,
          payload: {
            marketAccount: mktacc,
          },
        });
      } else {
        // Only
        dispatch({
          type: PositionsActionType.MarketAccountOnlyClicked,
          payload: {
            marketAccount: mktacc,
          },
        });
      }
    },
    [dispatch, mktacc, sayAll]
  );

  return (
    <Button className={className} startIcon={IconName.EyeShow} onClick={handleClicked} variant={ButtonVariants.Default}>
      {sayAll ? 'All' : 'Only'}
    </Button>
  );
};
