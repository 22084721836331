import type { UseAccordionFilterBuilderOutput } from '@talos/kyoko';
import { createContext, useContext, useMemo, useState, type Dispatch, type SetStateAction } from 'react';

export const PerformanceInteractionsContext = createContext<PerformanceInteractionsContextProps | undefined>(undefined);

export type PerformanceInteractionsContextProps = {
  /** Open a filter builder clause, and create it if it doesn't exist */
  openClause: UseAccordionFilterBuilderOutput['openClause'] | undefined;
  setOpenClause: Dispatch<SetStateAction<UseAccordionFilterBuilderOutput['openClause'] | undefined>>;

  zoomOutChart: (() => void) | undefined;
  setZoomOutChart: Dispatch<SetStateAction<(() => void) | undefined>>;
};

export function usePerformanceInteractions() {
  const context = useContext(PerformanceInteractionsContext);
  if (context === undefined) {
    throw new Error(
      'Missing PerformanceInteractionsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

// This provider holds a few functions at the top of the page which any child can use in order to interact with different elements of the page
export const PerformanceInteractionsProvider = function PerformanceInteractionsProvider({ children }) {
  const [openClause, setOpenClause] = useState<PerformanceInteractionsContextProps['openClause']>(undefined);
  const [zoomOutChart, setZoomOutChart] = useState<(() => void) | undefined>(undefined);

  const value = useMemo(() => {
    return {
      openClause,
      setOpenClause,
      zoomOutChart,
      setZoomOutChart,
    };
  }, [openClause, zoomOutChart]);

  return <PerformanceInteractionsContext.Provider value={value}>{children}</PerformanceInteractionsContext.Provider>;
};
