import {
  Box,
  Delete,
  Dialog,
  Text,
  TradeStatus,
  abbreviateId,
  logger,
  useUserContext,
  type DialogProps,
  type Trade,
} from '@talos/kyoko';
import { useCallback } from 'react';

import { useTheme } from 'styled-components';
import { CancelDialogWrapper, Row } from '../styles';

interface CancelDialogProps extends DialogProps {
  selectedTrades: Trade[];
}

export const CancelDialog = ({ selectedTrades, ...props }: CancelDialogProps) => {
  const { orgApiEndpoint } = useUserContext();
  const theme = useTheme();

  const cancelManualTrade = useCallback(
    (tradeID: string) => {
      if (!tradeID) {
        logger.error(new Error(`tried to cancel manual trade without tradeID`));
      }
      Delete(orgApiEndpoint ?? '', `/trades/${tradeID}`, null).catch((e: ErrorEvent) => {
        logger.error(new Error(`Failed to cancel manual trade: ${e?.message}`));
      });
    },
    [orgApiEndpoint]
  );

  // Could add bulk cancel but this is not a real usecase today
  if (selectedTrades.length !== 1) {
    return null;
  }

  return (
    <Dialog
      {...props}
      onConfirm={() => cancelManualTrade(selectedTrades[0].TradeID)}
      confirmLabel="Cancel Trade"
      cancelLabel="Return"
      title="Cancel Trade"
      stretchButtons
    >
      <CancelDialogWrapper>
        <Row>
          <h3>
            Trade #<b>{abbreviateId(selectedTrades[0].TradeID)}</b>
          </h3>
          <TradeStatus theme={theme} tradeStatus={selectedTrades[0].TradeStatus} />
        </Row>
        <Box mt="spacingDefault">
          <Text>Are you sure you want to cancel this trade?</Text>
        </Box>
      </CancelDialogWrapper>
    </Dialog>
  );
};
