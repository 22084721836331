import { ACTION, Button, FormControlSizes, HamburgerMenu, type CustomerTrade } from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { useRoleAuth } from '../../../hooks/useRoleAuth';
import { isTradeCancelled } from './isTradeCancelled';

export function CustomerTradeMenu({
  data: customerTrade,
  onModifyTrade,
  onCancelTrade,
  ...params
}: ICellRendererParams & {
  onShowJson(data: CustomerTrade): void;
  onModifyTrade(data: CustomerTrade): void;
  onCancelTrade(data: CustomerTrade): void;
}) {
  const { isAuthorized } = useRoleAuth();
  return (
    <>
      {isAuthorized(ACTION.DEALER_TRADING) && (
        <>
          <Button
            size={FormControlSizes.Small}
            width="100%"
            ghost={true}
            disabled={isTradeCancelled(customerTrade)}
            onClick={() => onModifyTrade(customerTrade)}
          >
            Modify
          </Button>
          <Button
            size={FormControlSizes.Small}
            width="100%"
            ghost={true}
            disabled={isTradeCancelled(customerTrade)}
            onClick={() => onCancelTrade(customerTrade)}
          >
            Cancel
          </Button>
        </>
      )}
      <HamburgerMenu {...params} data={customerTrade} />
    </>
  );
}
