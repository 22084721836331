import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch, type RouteProps } from 'react-router-dom';

export function useRouter<
  TRouteParams extends { [K in keyof TRouteParams]?: string } = AnyObject,
  TQueryParams extends Record<string, string> = Record<string, string>
>(path: undefined | string | string[] | RouteProps = undefined) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<TRouteParams>();
  const match = useRouteMatch<TRouteParams>(path as any);
  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search).entries()) as Partial<TQueryParams>,
    [location.search]
  );

  const setQueryParams = useCallback(
    (newQueryParams: TQueryParams) => {
      history.push({
        search: new URLSearchParams(newQueryParams).toString(),
      });
    },
    [history]
  );

  return {
    history,
    location,
    params,
    match,
    queryParams,
    setQueryParams,
  };
}
