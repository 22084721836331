import { identity, values } from 'lodash';
import { useMemo } from 'react';
import { CustomerBalanceTransactionStatusEnum } from '../../../../types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

/**
 * This hook creates the filter property definition for CustomerBalanceTransactionStatusEnums properties.
 *
 * @returns Filter Property definition to use for CustomerBalanceTransactionStatusEnums filters
 */

export function useCustomerTransactionStatusesFilter() {
  return useMemo(
    () =>
      ({
        key: 'Statuses',
        label: 'Status',
        icon: IconName.CheckCircle,
        options: values(CustomerBalanceTransactionStatusEnum),
        getOptionLabel: identity,
      } as const satisfies FilterableProperty),
    []
  );
}
