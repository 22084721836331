import { memo, useEffect } from 'react';

import {
  Flex,
  FormTable,
  IndicatorBadge,
  VStack,
  useConstant,
  useDynamicCallback,
  useFormTable,
  type AgGridMultiSelectDropdownProps,
  type Column,
} from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-enterprise';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { useCustomerMarketAccounts } from 'providers';
import { useCustomerUserDispatch, useCustomerUserSelector } from '../state/hooks';
import {
  deleteCustomerAccount,
  updateCustomerAccountPermissions,
  type CustomerAccountPermissions,
  type PermissionTypes,
} from '../state/permissions/permissionsSlice';
import type { Warning } from './types';

const permissionDisplayNames = new Map([
  ['read', 'View'],
  ['write', 'Trade'],
  ['admin', 'Admin'],
]);

export const CustomerUserPermissionsFormTable = memo((props: { onWarning: (warning: Warning) => void }) => {
  const dispatch = useCustomerUserDispatch();

  const { customerAccounts, deletedCustomerAccounts, dirtyCustomerAccounts } = useCustomerUserSelector(
    state => state.permissions
  );
  const customerName = useCustomerUserSelector(state => state.userDetails.displayName);

  const { customerMarketAccountsByName } = useCustomerMarketAccounts();

  const valueSetter = useDynamicCallback(
    ({ newValue, data }: { newValue: PermissionTypes[]; data: CustomerAccountPermissions }) => {
      if (newValue.includes('write') && !customerAccounts.flatMap(c => c.permissions).find(p => p === 'write')) {
        props.onWarning({
          message: `${
            customerName ?? 'New user'
          } will only be able to trade into the accounts explicitly configured with the Trade permission. Trading permissions to all other accounts will be removed.`,
        });
      }
      dispatch(updateCustomerAccountPermissions({ ...data, permissions: newValue }));
    }
  );

  const columns = useConstant<Column[]>([
    {
      type: 'custom',
      field: 'customerAccount',
      title: 'Account',
      flex: 1,
      params: {
        valueFormatter: ({ value }) => customerMarketAccountsByName.get(value)?.SourceAccountID || value,
      },
    },
    {
      type: 'custom',
      title: 'Permissions',
      width: 200,
      id: 'permission',
      params: {
        editable: true,
        suppressKeyboardEvent: () => true,
        cellEditor: 'multiSelectDropdown',
        cellEditorPopup: true,
        cellEditorParams: (params: CustomCellEditorProps) => {
          return {
            ...params,
            multiSelectProps: {
              options: [...permissionDisplayNames.keys()],
              getLabel: permission => permissionDisplayNames.get(permission) || permission,
            },
          } satisfies AgGridMultiSelectDropdownProps<string>;
        },
        valueGetter: ({ data }: { data: CustomerAccountPermissions }) => data.permissions,
        valueSetter: valueSetter,
        cellRenderer: ({ value }: { value: string[] }) => (
          <Flex gap="spacingSmall" alignItems="center">
            {value.map(permission => (
              <IndicatorBadge key={permission} children={permissionDisplayNames.get(permission) || permission} />
            ))}
          </Flex>
        ),
      },
    },
    {
      type: 'remove',
      id: 'remove',
      params: {
        onClick: ({ node }: { node: IRowNode<CustomerAccountPermissions> }) => {
          node.data && dispatch(deleteCustomerAccount(node.data.customerAccount));
        },
      },
    },
  ]);

  const formTable = useFormTable<CustomerAccountPermissions>({
    rowID: 'customerAccount',
    data: customerAccounts,
    dirtyRowIds: dirtyCustomerAccounts,
    deletedRowIds: deletedCustomerAccounts,
    columns,
  });

  const { reset } = formTable;
  useEffect(() => {
    reset(customerAccounts, dirtyCustomerAccounts, deletedCustomerAccounts);
  }, [reset, customerAccounts, dirtyCustomerAccounts, deletedCustomerAccounts]);

  return (
    <VStack alignItems="stretch" h="100%">
      <FormTable {...formTable} />
    </VStack>
  );
});
