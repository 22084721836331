import type { GroupPanelViewState, SerializedGridObject } from 'dockview';
import { Orientation, type GroupviewPanelState, type SerializedDockview } from 'dockview';
import { DEFAULT_COLUMNS } from '../../../tokens/appconfig';
import { BRANCH, LEAF } from '../../Layout/tokens';
import { BLOTTER_PANELS, BLOTTERS_GROUP } from './shared';

// TODO Remove options if not enabled
export const DEFAULT_MAIN_PANELS: Record<string, GroupviewPanelState> = {
  market: {
    id: 'market',
    contentComponent: 'marketCards',
    title: 'Market',
    params: { columns: DEFAULT_COLUMNS },
  },
  options: { id: 'options', contentComponent: 'options', title: 'Options' },
};

export const WATCHLIST_PANELS: Record<string, GroupviewPanelState> = {
  watchlist: {
    id: 'watchlist',
    contentComponent: 'watchlist',
    title: 'Watchlist',
    // maximumWidth: MAX_WATCHLIST_WIDTH,
    // minimumWidth: MIN_WATCHLIST_WIDTH,
  },
};

export const DEFAULT_PANELS: Record<string, GroupviewPanelState> = {
  // Not part of the flexible layout, yet...
  // ...FORM_PANELS,
  // ...FEED_PANELS,
  ...WATCHLIST_PANELS,
  ...DEFAULT_MAIN_PANELS,
  ...BLOTTER_PANELS,
};

export const DEFAULT_GRID: {
  root: SerializedGridObject<GroupPanelViewState>;
  height: number;
  width: number;
  orientation: Orientation;
} = {
  root: {
    type: BRANCH,
    data: [
      {
        type: BRANCH,
        data: [
          {
            type: BRANCH,
            data: [
              {
                type: LEAF,
                visible: true,
                data: { views: ['watchlist'], activeView: 'watchlist', id: 'watchlists' },
                size: 370,
              },
              {
                type: LEAF,
                data: {
                  views: ['market', 'options'],
                  activeView: 'market',
                  id: 'main',
                },
                size: 1100,
              },
            ],
            size: 700,
          },
          BLOTTERS_GROUP,
        ],
        size: 1100,
      },
      // OMS_TAB_GROUP,
      // FEED_TAB_GROUP,
    ],
  },
  width: 1600,
  height: 900,
  orientation: Orientation.HORIZONTAL,
};

export const DEFAULT_LAYOUT: SerializedDockview = {
  grid: DEFAULT_GRID,
  panels: DEFAULT_PANELS,
};
