import Big from 'big.js';
import { useEffect, type SetStateAction } from 'react';
import { usePrevious } from 'react-use';
import { distinctUntilChanged, filter, map } from 'rxjs';

import {
  CustomerBalanceTransactionStatusEnum,
  CustomerBalanceTransactionTypeEnum,
  NotificationVariants,
  TransferStatusEnum,
  format,
  useGlobalToasts,
  useObservable,
  useObservableValue,
  type CustomerTransaction,
} from '@talos/kyoko';

import { isEqual } from 'lodash';
import { useCustomerCredit, useTransfers } from 'providers';

interface UseTransferListenerForClientIDProps {
  /**
   * The generated clientID value to be tracked over websocket
   * Transfer messages so that we can identity when it changes
   * states through to the point of completion or failure/rejection.
   */
  clientID: string;
  /**
   * Callback to clear out form values once a transaction has
   * been initiated and is currently being processed by the server.
   */
  handleClear: () => void;
  /**
   * Callback to set the form to a loading state once a transaction has
   * initially been submitted and is flagged as pending by the server.
   */
  setIsLoading: (value: SetStateAction<boolean>) => void;
  /**
   * Callback to set a form's show-error state, so that errors can
   * be hidden upon resolution of a given transaction.
   */
  setShowErrors: (value: SetStateAction<boolean>) => void;
}

/**
 * This hook sets up some helpful side-effects for the main transaction
 * form, using a supplied clientID, and associated callbacks, to track
 * a transaction through to its ultimate resolution by listening for
 * specific messages on the Transfer socket subscription, and checking
 * whether they hold updated Status values for the supplied clientID.
 * Moving it out into its own hook allows us to mask away this cruft.
 */
export function useTransferListenerForClientID({
  clientID,
  handleClear,
  setIsLoading,
  setShowErrors,
}: UseTransferListenerForClientIDProps) {
  const { add: addToast } = useGlobalToasts();
  const { transfersObs } = useTransfers();

  const transferResponseObservable = useObservable(
    () =>
      transfersObs.pipe(
        // Find the last relevant message
        map(transfers => [...transfers].reverse().find(t => t.ClientID === clientID)),
        filter(transfer => transfer != null),
        distinctUntilChanged((p, n) => (p && n ? isEqual(p, n) : false))
      ),

    [clientID, transfersObs]
  );

  const response = useObservableValue(() => transferResponseObservable, [transferResponseObservable]);

  // Transfer Status Log Messages
  const prevStatus = usePrevious(response?.Status);

  useEffect(() => {
    if (!response) {
      return;
    }

    // Ignore writing to log messages if the status hasn't changed
    const { Text: text, Status: status } = response;

    if (prevStatus != null && status === prevStatus) {
      return;
    }

    let message = '';
    let variant = NotificationVariants.Default;

    switch (status) {
      case TransferStatusEnum.Pending:
        message = 'Your transfer is pending';
        setIsLoading(true);
        break;
      case TransferStatusEnum.Processing:
        message = 'Your transfer is processing';
        setIsLoading(false);
        handleClear();
        break;
      case TransferStatusEnum.Rejected:
        message = 'Your transfer was rejected';
        variant = NotificationVariants.Negative;
        setIsLoading(false);
        setShowErrors(false);
        break;
      case TransferStatusEnum.Completed:
        message = 'Your transfer was completed';
        variant = NotificationVariants.Positive;
        setIsLoading(false);
        setShowErrors(false);
        break;
      default:
        return;
    }

    if (text) {
      message += `: ${text}`;
    } else {
      message += '.';
    }

    addToast({
      text: message,
      variant,
    });
  }, [addToast, handleClear, prevStatus, response, setIsLoading, setShowErrors]);
}

/**
 * This hook takes a CustomerTransaction record and evaluates its Status
 * against a list of defined CustomerBalanceTransactionStatusEnum values.
 * Depending on the current status, it may compute warning text about a
 * customer's current exposure levels, or completion or rejection status
 * of the transaction, then return that computed status tot he consumer.
 *
 * @returns string | null
 */
export function useCustomerCreditWarning(entity: CustomerTransaction) {
  const { customerCreditByMarketAccount } = useCustomerCredit();

  if (entity == null) {
    return null;
  }

  const customerCredit = customerCreditByMarketAccount?.get(entity.MarketAccount);
  let warningText: string | null = null;

  switch (entity.Status) {
    case CustomerBalanceTransactionStatusEnum.PendingApproval:
    case CustomerBalanceTransactionStatusEnum.PendingTransfer:
      if (
        Big(customerCredit?.Exposure || 0).gt(0) &&
        entity.TransactionType !== CustomerBalanceTransactionTypeEnum.Deposit
      ) {
        warningText = `This client has open exposure of ${format(customerCredit?.Exposure)} ${
          customerCredit?.ExposureCurrency
        }`;
      }
      warningText = null;
      break;

    case CustomerBalanceTransactionStatusEnum.Rejected:
      warningText = `${entity.TreasuryUser || entity.DealerUser || 'System'} Rejected the ${
        entity.TransactionType
      } request`;
      break;

    case CustomerBalanceTransactionStatusEnum.Completed:
      warningText = `${entity.TransactionType} Completed`;
      break;
  }

  return warningText;
}

export function useApproveTransactionDisabledTooltip(
  approveDisabled: boolean,
  disabledForMatchingUser: boolean,
  initiatorUser?: string,
  user?: string
) {
  if (!approveDisabled) {
    // Button is enabled, no need to show a tooltip
    return undefined;
  }

  if (disabledForMatchingUser) {
    if (initiatorUser === user) {
      return 'Initiator User cannot confirm the transfer';
    }
    return 'User who approved withdrawal cannot confirm the transfer';
  }

  return 'Not authorized to approve transactions';
}
