import { LedgerAccountTypeEnum, useMarketAccountsContext } from '@talos/kyoko';
import { useSubAccounts } from '../../../../providers/SubAccountsContext';

/**
 * Guveb an account name (either a market account name or sub account name) and a ledger account type enum, resolves the name
 * into either a sub account or market account.
 */
export const useAccount = (account: string, type: LedgerAccountTypeEnum) => {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { subAccountsByName } = useSubAccounts();

  return type === LedgerAccountTypeEnum.SubAccount ? subAccountsByName.get(account) : marketAccountsByName.get(account);
};
