export type ReplaceTokens = {
  [key: string]: string;
};

export const replaceTokens = (str: string, tokens: ReplaceTokens | undefined): string => {
  if (!tokens) {
    return str;
  }
  let result = str;
  Object.keys(tokens).forEach(key => {
    result = result.replaceAll(`{{${key}}}`, tokens[key]);
  });
  return result;
};
