import {
  Box,
  Button,
  Divider,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  IndicatorBadge,
  IndicatorBadgeSizes,
  LegDirectionEnum,
  SideEnum,
  Text,
  Tooltip,
  VStack,
  getOppositeSide,
  setAlpha,
  useMarketAccountsContext,
  useMarketsContext,
  useSecuritiesContext,
  type RequiredProperties,
  type Security,
} from '@talos/kyoko';
import type { LegParams } from 'providers/OMSContext.types';
import { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

interface MultilegDetailsParams {
  legParams?: LegParams[];
  security: RequiredProperties<Security, 'MultilegDetails'>;
  side?: SideEnum;
  onEdit?: () => void;
}

export const MultilegDetails = ({ legParams, security, side, onEdit }: MultilegDetailsParams) => {
  return (
    <VStack gap="spacingSmall" overflow="hidden">
      {security.MultilegDetails?.Legs.map(leg => (
        <Box key={leg.Index} w="100%">
          {leg.Index !== 0 && <Divider orientation="vertical" />}
          <LegDetails
            idx={leg.Index}
            details={security.MultilegDetails}
            overrideParams={legParams?.[leg.Index]}
            side={side}
            onEdit={onEdit}
          />
        </Box>
      ))}
    </VStack>
  );
};

type ValueAndState = [string, ValueState];

enum ValueState {
  Added,
  Removed,
  Existing,
}

const LegDetails = ({
  idx,
  details,
  overrideParams,
  side,
  onEdit,
}: {
  idx: number;
  details: NonNullable<Security['MultilegDetails']>;
  overrideParams?: LegParams;
} & Pick<MultilegDetailsParams, 'side' | 'onEdit'>) => {
  const theme = useTheme();
  const { marketAccountsByName } = useMarketAccountsContext();
  const { marketsByName } = useMarketsContext();
  const { securitiesBySymbol } = useSecuritiesContext();

  const legDetails = details.Legs[idx];

  // Resolve display property from Security + Overrides and note if it was an override or not
  const allMarkets = useMemo<ValueAndState[]>(() => {
    const valueToState = new Map<string, ValueState>();
    legDetails.Markets?.forEach(market => {
      const state =
        overrideParams === undefined || overrideParams.Markets.length === 0
          ? ValueState.Existing
          : overrideParams?.Markets?.find(m => m === market.Market || m === market.MarketAccount) === undefined
          ? ValueState.Removed
          : ValueState.Existing;

      valueToState.set(
        marketAccountsByName.get(market.MarketAccount)?.DisplayName ??
          marketsByName.get(market.Market)?.DisplayName ??
          market.Market,
        state
      );
    });

    overrideParams?.Markets.forEach(market => {
      const key = marketAccountsByName.get(market)?.DisplayName ?? marketsByName.get(market)?.DisplayName ?? market;
      if (valueToState.has(key)) {
        return;
      }
      const state =
        // If Markets===null, all markets are allowed and selected markets can be viewed as "existing"
        legDetails.Markets === null
          ? ValueState.Existing
          : legDetails.Markets?.find(m => m.Market === market || m.MarketAccount === market) === undefined
          ? ValueState.Added
          : ValueState.Existing;

      valueToState.set(key, state);
    });

    return [...valueToState.entries()].sort(ValueAndOverrideSortFn);
  }, [legDetails.Markets, marketAccountsByName, marketsByName, overrideParams]);

  const legSide = getSideFromDirection(legDetails.Direction, side);
  const legSideColor =
    legSide === SideEnum.Buy ? 'green.lighten' : legSide === SideEnum.Sell ? 'red.lighten' : undefined;

  const initiates = !overrideParams ? details.Parameters.LegParams[idx].Initiating : overrideParams.Initiating;

  return (
    <LegContainer>
      <VStack
        w="100%"
        px="spacingComfortable"
        py="spacingDefault"
        gap="spacingSmall"
        color="colorTextImportant"
        data-testid={`ml-details-leg-${idx}`}
      >
        <HStack w="100%" justifyContent="space-between">
          <Text fontSize="fontSizeSmall">
            Leg {idx + 1}
            <Text size="fontSizeSmall" ml="spacingSmall" color={theme.colors.gray['080']}>
              {securitiesBySymbol.get(legDetails.Symbol)?.DisplaySymbol ?? legDetails.Symbol}
            </Text>
          </Text>
          {onEdit && (
            <Button
              startIcon={IconName.PencilField}
              size={FormControlSizes.Tiny}
              onClick={() => onEdit()}
              data-testid="multileg-local-edit"
            >
              Edit
            </Button>
          )}
        </HStack>
        <Divider color={theme.backgroundDivider} />
        <HStack w="100%" justifyContent="space-between">
          <Text color="colorTextDefault" size="fontSizeSmall">
            Direction
          </Text>
          <Text size="fontSizeSmall" color={legSideColor}>
            {legSide}
          </Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text color="colorTextDefault" size="fontSizeSmall">
            Markets
          </Text>
          <OverflowBox items={allMarkets} />
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text color="colorTextDefault" size="fontSizeSmall">
            Initiating
          </Text>
          <Icon color="colorTextDefault" icon={initiates ? IconName.Check : IconName.Close} />
        </HStack>
      </VStack>
    </LegContainer>
  );
};

function ValueAndOverrideSortFn(a: ValueAndState, b: ValueAndState) {
  if (a[1] === b[1]) {
    return a[0].localeCompare(b[0]);
  }
  return a[1] - b[1];
}

function getStateColor(state: ValueState | undefined): string | undefined {
  switch (state) {
    case ValueState.Added:
      return 'green.lighten';
    case ValueState.Removed:
      return 'red.lighten';
  }
  return undefined;
}

function getSideFromDirection(direction: LegDirectionEnum, side: SideEnum | undefined): LegDirectionEnum | SideEnum {
  if (!side) {
    return direction;
  }
  if (direction === LegDirectionEnum.Same) {
    return side;
  }
  return getOppositeSide(side);
}

export const OverflowBox = styled(({ items }: { items: ValueAndState[] }) => {
  const firstDisplayValue = items.at(0)?.[0] || 'All';
  const firstState = items.at(0)?.[1];

  return (
    <HStack gap="spacingTiny">
      <Text
        title={firstDisplayValue}
        textAlign="right"
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        size="fontSizeSmall"
        color={getStateColor(firstState)}
        textDecoration={firstState === ValueState.Removed ? 'line-through' : undefined}
      >
        {firstDisplayValue}
      </Text>
      {items.length > 1 && (
        <Tooltip
          tooltip={
            <VStack alignItems="flex-start">
              {items.slice(1).map(item => (
                <Text
                  key={item[0]}
                  p="spacingSmall"
                  color={getStateColor(item[1])}
                  textDecoration={item[1] === ValueState.Removed ? 'line-through' : undefined}
                >
                  {item[0]}
                </Text>
              ))}
            </VStack>
          }
        >
          <IndicatorBadge size={IndicatorBadgeSizes.Large} children={`+${items.length - 1} More`} color="gray.090" />
        </Tooltip>
      )}
    </HStack>
  );
})`
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.spacingSmall}px;
  background: ${({ theme }) => setAlpha(0.08, theme.colors.gray['100'])};
  padding: ${({ theme }) => `${theme.spacingTiny}px ${theme.spacingSmall}px`};

  :hover {
    background: ${({ theme }) => setAlpha(0.04, theme.colors.gray['100'])};
  }
`;
const LegContainer = styled(HStack)`
  border-radius: ${({ theme }) => theme.spacingSmall}px;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  background: ${({ theme }) => setAlpha(0.32, theme.colors.gray['000'])};
`;
