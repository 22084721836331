import { Checkbox, HelpIcon, NotificationVariants, useGlobalToasts } from '@talos/kyoko';
import { FormHeader, FormRow, FormText } from 'components/Form';
import { useTradingSettings } from 'providers/AppConfigProvider';
import { useTheme } from 'styled-components';
import { TRADE_ALLOCATIONS_SETTINGS } from 'tokens/tooltips';

export function SubAccountsSettings() {
  const { spacingTiny } = useTheme();
  const { add: addToast } = useGlobalToasts();
  const {
    rememberSubAccountId,
    setRememberSubAccountId,
    useTradeAllocations,
    setUseTradeAllocations,
    showOrderFormRollupSelector,
    setShowOrderFormRollupSelector,
  } = useTradingSettings();

  return (
    <>
      <FormHeader>Sub Accounts</FormHeader>
      <FormRow>
        <Checkbox
          id="subAccId"
          name="subAccId"
          checked={rememberSubAccountId}
          onChange={e => {
            e.preventDefault();
            setRememberSubAccountId(e.target.checked);
            addToast({
              text: `${e.target.checked ? 'Remembering' : 'Not remembering'} most recent sub account.`,
              variant: NotificationVariants.Positive,
            });
          }}
        >
          Remember Sub Account selection on Order/RFQ forms.
        </Checkbox>
      </FormRow>

      <FormRow>
        <Checkbox
          id="subAccAlloc"
          name="subAccAlloc"
          checked={useTradeAllocations}
          onChange={e => {
            e.preventDefault();
            setUseTradeAllocations(e.target.checked);
            addToast({
              text: `Sub account allocations ${e.target.checked ? 'enabled' : 'disabled'}.`,
              variant: NotificationVariants.Positive,
            });
          }}
        >
          Use Sub Account Allocations when submitting an Order/RFQ.
        </Checkbox>
        <HelpIcon style={{ marginLeft: spacingTiny, marginRight: 'auto' }} tooltip={TRADE_ALLOCATIONS_SETTINGS} />
      </FormRow>

      <FormRow>
        <Checkbox
          id="showRollupSelector"
          name="showRollupSelector"
          checked={showOrderFormRollupSelector}
          onChange={e => {
            e.preventDefault();
            setShowOrderFormRollupSelector(e.target.checked);
            addToast({
              text: `Order Form Rollup selector ${e.target.checked ? 'enabled' : 'disabled'}.`,
              variant: NotificationVariants.Positive,
            });
          }}
        >
          Show the Rollup selector in the Order Form
        </Checkbox>
        <HelpIcon
          style={{ marginLeft: spacingTiny, marginRight: 'auto' }}
          tooltip="Showing the Rollup selector in the Order Form can help you filter the list of selectable Sub Accounts to something more manageable if you have a lot of Sub Accounts. The selector will only show up if there are any Rollups set up in the system."
        />
      </FormRow>

      <FormText>Adding Sub Accounts makes it a required field when submitting orders to an exchange.</FormText>
    </>
  );
}
