import { useMemo, useState } from 'react';
import { Observable, iif, map } from 'rxjs';
import { useEndpointsContext } from '../contexts';
import { CUSTOMER_ORDER_SUMMARY } from '../tokens';
import type { SubscriptionResponse } from '../types';
import { CustomerDealSummary } from '../types/CustomerOrderSummary';
import type { ICustomerOrderSummary } from '../types/types';
import { request } from '../utils';
import { useObservable, useObservableValue } from './useObservable';
import { useSubscription, type UseSubscriptionRequest } from './useSubscription';

export function useCustomerSummary(
  type: 'quote' | 'order',
  { id, tag }: { id?: string; tag: string }
): CustomerDealSummary | null {
  const wsRequest: UseSubscriptionRequest | null = useMemo(() => {
    return type === 'order' && id
      ? { name: CUSTOMER_ORDER_SUMMARY, OrderID: id, tag }
      : type === 'quote' && id
      ? { name: CUSTOMER_ORDER_SUMMARY, RFQID: id, tag }
      : null;
  }, [id, type, tag]);

  const { data: summaryWsObsRaw } = useSubscription<ICustomerOrderSummary>(wsRequest);

  const [wsSummaryAvailable, setWsSummaryAvailable] = useState(true);
  const summaryWsObs = useObservable<CustomerDealSummary | null>(
    () =>
      summaryWsObsRaw.pipe(
        map(json => {
          const summary = json.data.at(-1);
          if (summary) {
            return new CustomerDealSummary({
              Fees: summary.PerCurrencyFees,
              FilledSpread: summary.ActualSpread,
              PnL: summary.PerCurrencyPnL,
              SalesCommission: summary.SalesCommission,
              Spread: summary.TargetSpread,
            });
          } else {
            setWsSummaryAvailable(false);
            return null;
          }
        })
      ),

    [summaryWsObsRaw]
  );

  const { orgApiEndpoint } = useEndpointsContext();
  const summaryRESTObs = useObservable<CustomerDealSummary | null>(
    () =>
      new Observable<CustomerDealSummary | null>(subscriber => {
        if (id == null) {
          subscriber.next(null);
          subscriber.complete();
          return;
        }
        request<SubscriptionResponse<CustomerDealSummary>>('GET', `${orgApiEndpoint}/customer/${type}s/${id}/summary`)
          .then(response => {
            const summary = response.data.at(-1);
            if (summary) {
              subscriber.next(new CustomerDealSummary(summary));
            }
            subscriber.complete();
          })
          .catch(error => {
            subscriber.error(error);
          });
      }),
    [id, orgApiEndpoint, type]
  );

  const shouldUseWs = wsSummaryAvailable && wsRequest !== null;

  const result = useObservableValue(
    () => iif(() => shouldUseWs, summaryWsObs, summaryRESTObs),
    [shouldUseWs, summaryWsObs, summaryRESTObs],
    null
  );

  return result;
}
