import { ACTION } from '@talos/kyoko';
import { useFeatureFlag, useRoleAuth } from '../../../hooks';

export const useCanViewAccountSegregation = () => {
  const { enableAccountSegregation, enableAccountSegregationConfiguration } = useFeatureFlag();
  const { isAuthorized } = useRoleAuth();
  const canViewAccountSegregation =
    ((enableAccountSegregation || enableAccountSegregationConfiguration) &&
      isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS)) ||
    isAuthorized(ACTION.VIEW_FILTER_PERMISSIONS_FEATURE_DISABLED);

  return canViewAccountSegregation;
};
