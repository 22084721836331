import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  AllocationValueTypeEnum,
  BaseField,
  DateField,
  DateTimeDurationPickerValueType,
  EMPTY_ARRAY,
  Field,
  SelectorField,
  formatDuration,
  prettyName,
  type Allocation,
  type Duration,
  type NumericField,
  type OrderStrategy,
  type ParameterRow,
} from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import type { AppState } from 'providers/AppStateProvider/types';
import type { IOrderPreset } from '../../../providers/OrderPresetsContext';
import { endTimeValidation, notInPastValidation } from '../NewOrder/FieldRules';
import { mapAllocationsToField } from '../NewOrder/OrderSlice';
import type { OrderFormState } from '../NewOrder/types';
import { initializeStrategyParams } from '../NewOrder/utils';
import { initialRefDataState } from '../OMSReferenceDataSlice';
import type { OMSReferenceDataState } from '../types';
import { allocationTotalValidation } from './FieldRules';
import { securityRelatedParams } from './tokens';
import type { OrderPresetState, OrderPresetsDependencies } from './types';

/**
 * Small utility to turn e.g. "Limit" into "L" and "Price Protection" into "PP".
 * @param str
 */
function getStrategyParameterShorthand(str: string) {
  return prettyName(str)
    .split(' ')
    .map(word => word.charAt(0))
    .join('');
}

/**
 * Generate a name suggestion for a preset based on the result from `useParameterRows`.
 * @param strategyName string
 * @param parameterRows ParameterRow[]
 */
function getPresetNameSuggestion(strategyName: string, parameterRows: ParameterRow[]) {
  const parameters = parameterRows
    .filter(row => !!row.value)
    .map(row => `${getStrategyParameterShorthand(row.title)} ${row.value}`)
    .join(', ');

  if (parameters === '') {
    return strategyName;
  }

  return `${strategyName} - ${parameters}`;
}

export const getInitialState = (): OrderPresetState => ({
  referenceData: initialRefDataState,
  dependencies: {
    tradableSubAccounts: [],
  },
  form: {
    nameField: new Field({ value: '', name: 'Name', isRequired: true }),
    strategyField: new SelectorField({ idProperty: 'Name', name: 'Strategy' }),
    parameters: {},
    allocations: EMPTY_ARRAY,
  },
  isOpen: false,
  initialized: false,
  presetBeingModified: undefined,
});

const validateAllocations = (state: WritableDraft<OrderPresetState>) => {
  // If there are no sub accounts configured in the system, then allocations is not applicable
  // However it is a required field if it is configured
  const isSubAccountRequired = state.dependencies.tradableSubAccounts.length > 0;

  state.form.allocations = state.form.allocations.map(pair => ({
    subAccountField: pair.subAccountField.setIsRequired(isSubAccountRequired).validate(),
    valueField: pair.valueField.validate(isSubAccountRequired ? [allocationTotalValidation] : [], state.form),
  }));
};

const populateDropdownsFromRefData = (state: WritableDraft<OrderPresetState>) => {
  const { strategies } = state.referenceData;
  state.form.strategyField = state.form.strategyField.updateAvailableItems(strategies.strategiesList);
};

const handleStrategyParamChange = (
  state: WritableDraft<OrderPresetState>,
  { key, value }: { key: string; value?: any },
  isSystemOverride = false
) => {
  const parameterField = state.form.parameters[key];
  if (parameterField) {
    state.form.parameters[key] = parameterField.updateValue(value, isSystemOverride);
    if (key === 'StartTime' && state.form.parameters.EndTime) {
      state.form.parameters.EndTime = (state.form.parameters.EndTime as DateField).setRelativeDate(value);
    }
    validateStrategyParams(state);
  }
};

const handleStrategyChange = (
  state: WritableDraft<OrderPresetState>,
  strategyName?: string,
  isSystemOverride = false
) => {
  const strategy = strategyName && state.referenceData.strategies.strategiesByName.get(strategyName);
  if (!strategy) {
    return;
  }

  state.form.strategyField = state.form.strategyField.updateValue(strategy, isSystemOverride);

  const newParameters = initializeStrategyParams({
    security: undefined, // TODO Add support for percentage based strategy params
    strategy,
    settings: state.referenceData.settings,
    excludedParams: securityRelatedParams,
  });

  // When switching strategies we carry over the previous parameter values if it also exists in the new strategy
  Object.keys(newParameters).forEach(paramName => {
    const existingParamField = state.form.parameters[paramName];
    if (existingParamField && existingParamField.isTouched) {
      // Different strategies might have same parameter with same Name but different DisplayName
      // So only safe to carry over the value, not the entire Field object
      if (existingParamField instanceof DateField && existingParamField.dateTimeDuration) {
        // special case with DateTime field duration, since value is an absolute point in time, whereas duration is relative
        // to the point when we actually submit the order. so if there is a duration we want to carry over the duration
        newParameters[paramName] = newParameters[paramName].updateValue(
          existingParamField.dateTimeDuration,
          isSystemOverride
        );
      } else if (newParameters[paramName] instanceof SelectorField) {
        // For enum parameters not only we need to ensure it exists in the new strategy but the previous enum value is also valid
        const enumValueExists = (newParameters[paramName] as SelectorField<number>).availableItems.includes(
          existingParamField.value
        );
        if (enumValueExists) {
          newParameters[paramName] = newParameters[paramName].updateValue(existingParamField.value, isSystemOverride);
        }
      } else {
        newParameters[paramName] = newParameters[paramName].updateValue(existingParamField.value, isSystemOverride);
      }
    }
  });

  state.form.parameters = newParameters;
};

const validateStrategyParams = (state: WritableDraft<OrderPresetState>) => {
  Object.keys(state.form.parameters).forEach(key => {
    if (state.form.parameters[key].isDisabled) {
      // e.g. When order has started, start time is disabled and we don't want to validate it since it *will* be in the past
      return;
    }
    if (key === 'StartTime' && state.form.parameters.EndTime) {
      state.form.parameters.StartTime = state.form.parameters.StartTime.validate([notInPastValidation], state);
    }
    if (key === 'EndTime' && state.form.parameters.StartTime) {
      state.form.parameters.EndTime = state.form.parameters.EndTime.validate(
        [notInPastValidation, endTimeValidation],
        state
      );
    }
  });
};

export const orderPresetSlice = createSlice({
  name: 'orderPreset',
  initialState: getInitialState(),
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = action.payload;
      if (state.initialized && state.form.strategyField.availableItems.length === 0) {
        populateDropdownsFromRefData(state);
      }
    },
    setDependencies: (state, action: PayloadAction<OrderPresetsDependencies>) => {
      state.dependencies = action.payload;

      if (!state.presetBeingModified) {
        if (state.form.allocations.length && action.payload.tradableSubAccounts?.length != null) {
          // Filter out sub-accounts that are no longer tradeable
          state.form.allocations = state.form.allocations.filter(a =>
            a.subAccountField.value
              ? action.payload.tradableSubAccounts!.find(sb => sb.Name === a.subAccountField.value)
              : true
          );
        }

        validateAllocations(state);
      }
    },
    initializeNewPreset: (state, action: PayloadAction<undefined>) => {
      populateDropdownsFromRefData(state);

      state.form.nameField = state.form.nameField.updateValue('');
      state.form.strategyField = state.form.strategyField.updateValue(state.form.strategyField.availableItems[0]);
      state.form.parameters = initializeStrategyParams({
        security: undefined, // TODO
        strategy: state.form.strategyField.value,
        settings: state.referenceData.settings,
        excludedParams: securityRelatedParams,
      });
      state.form.allocations = EMPTY_ARRAY;

      state.presetBeingModified = undefined;
      state.isOpen = true;
      state.initialized = true;
    },
    prime: (
      state,
      action: PayloadAction<{ form: OrderFormState; updateSelectedPreset?: boolean } | { preset: IOrderPreset }>
    ) => {
      populateDropdownsFromRefData(state);

      if ('form' in action.payload) {
        const { form: orderForm, updateSelectedPreset } = action.payload;

        // If we have a preset selected, we want to populate the form with that preset's values
        const preset = orderForm.orderPresetField.value;
        if (preset && updateSelectedPreset) {
          state.presetBeingModified = preset;
          state.form.nameField = state.form.nameField.updateValue(preset.name);
        } else {
          state.presetBeingModified = undefined;
          state.form.nameField = state.form.nameField.updateValue(
            getPresetNameSuggestion(
              orderForm.strategyField.value!.DisplayName,
              Object.keys(orderForm.parameters)
                .filter(key => orderForm.parameters[key].isVisible && !securityRelatedParams.includes(key))
                .map(key => {
                  const strategy = state.referenceData.strategies.strategiesByName.get(
                    orderForm.strategyField.value!.Name
                  );
                  const field = orderForm.parameters[key];
                  if (field instanceof SelectorField) {
                    return {
                      title: key,
                      value:
                        strategy?.Parameters.find(p => p.Name === key)?.EnumValues.find(e => e.Index === field.value)
                          ?.Name ?? field.value,
                    };
                  }
                  if (field instanceof DateField) {
                    return {
                      title: key,
                      value: formatDuration(field.dateTimeDuration?.value as Duration),
                    };
                  }
                  return {
                    title: key,
                    value: field.displayValue,
                  };
                })
            )
          );
        }
        state.form.strategyField = state.form.strategyField.updateValueFromID(orderForm.strategyField.value?.Name);
        state.form.parameters = initializeStrategyParams({
          security: undefined, // TODO
          strategy: state.form.strategyField.value,
          settings: state.referenceData.settings,
          excludedParams: securityRelatedParams,
        });
        Object.keys(orderForm.parameters).forEach(key => {
          if (securityRelatedParams.includes(key)) {
            return;
          }
          const orderFormField = orderForm.parameters?.[key];
          const value = orderFormField instanceof DateField ? orderFormField.dateTimeDuration : orderFormField.value;
          if (orderFormField) {
            state.form.parameters[key] = state.form.parameters[key].updateValue(value, true).setTouched(true);
          }
        });
        state.form.allocations = orderForm.allocations;
      } else {
        const { preset } = action.payload;
        state.form.nameField = state.form.nameField.updateValue(preset.name);
        state.form.strategyField = state.form.strategyField.updateValueFromID(preset.strategy);
        state.form.parameters = initializeStrategyParams({
          security: undefined, // TODO
          strategy: state.form.strategyField.value,
          settings: state.referenceData.settings,
          excludedParams: securityRelatedParams,
        });
        preset.parameters.forEach(({ key, value }) => {
          state.form.parameters[key] = state.form.parameters[key].updateValue(value, true).setTouched(true);
        });
        state.form.allocations = mapAllocationsToField(preset.allocations, AllocationValueTypeEnum.Percentage);
        state.presetBeingModified = preset;
      }

      state.isOpen = true;
      state.initialized = true;
    },
    closeDialog: (state, action: PayloadAction<undefined>) => {
      state.isOpen = false;
    },
    setPresetName: (state, action: PayloadAction<string>) => {
      state.form.nameField = state.form.nameField.updateValue(action.payload);
    },
    setStrategy: (state, action: PayloadAction<OrderStrategy>) => {
      handleStrategyChange(state, action.payload?.Name);
    },
    setStrategyParams: (state, { payload }: PayloadAction<{ key: string; value?: any }>) => {
      handleStrategyParamChange(state, payload);
    },
    setAllocations: (state, action: PayloadAction<Allocation[]>) => {
      state.form.allocations = mapAllocationsToField(action.payload, AllocationValueTypeEnum.Percentage, true);
      validateAllocations(state);
    },
    touchAll: state => {
      Object.keys(state.form).forEach(key => {
        if (state.form[key] instanceof BaseField) {
          state.form[key] = state.form[key].setTouched(true);
        }
      });

      Object.keys(state.form.parameters).forEach(param => {
        state.form.parameters[param] = state.form.parameters[param].setTouched(true);
      });

      state.form.allocations = state.form.allocations.map(pair => ({
        subAccountField: pair.subAccountField.setTouched(true),
        valueField: pair.valueField.setTouched(true),
      }));
    },
  },
});

export const {
  setReferenceData,
  setPresetName,
  setStrategy,
  setStrategyParams,
  setAllocations,
  initializeNewPreset,
  prime,
  closeDialog,
  touchAll,
  setDependencies,
} = orderPresetSlice.actions;

// DERIVED state below
export const selectCanSavePreset = createSelector(
  (state: AppState) => state.presets.form,
  (state: AppState) => state.presets.dependencies.tradableSubAccounts.length,
  (form, numTradableSubAccounts) => {
    const hasName = !!form.nameField.value;
    const hasStrategy = !!form.strategyField.value;
    const hasValidParameters = parametersAreValid(form.parameters);
    const hasValidAllocations = allocationsAreValid(form.allocations, numTradableSubAccounts);
    const isFormValid = hasName && hasStrategy && hasValidParameters && hasValidAllocations;

    if (!isFormValid) {
      return {
        valid: false,
        message: 'Unable to save a preset until all required strategy parameters are set.',
      };
    }

    const startTime = form.parameters.StartTime as DateField | undefined;
    const endTime = form.parameters.EndTime as DateField | undefined;

    // Check that start/end times are unset, or if set, that they are durations
    const isStartTimeDateTime =
      !!startTime?.value && startTime?.dateTimeDuration?.type === DateTimeDurationPickerValueType.DateTime;
    const isEndTimeDateTime =
      !!endTime?.value && endTime?.dateTimeDuration?.type === DateTimeDurationPickerValueType.DateTime;
    if (isStartTimeDateTime || isEndTimeDateTime) {
      return {
        valid: false,
        message: 'Unable to save a preset when a specific time is set for the Start or End Time.',
      };
    }

    return {
      valid: true,
      message: undefined,
    };
  }
);

function parametersAreValid(parameters: Record<string, BaseField<any>>) {
  const parametersHasError = Object.keys(parameters).some(key => parameters[key].hasError);
  return !parametersHasError;
}

function allocationsAreValid(
  allocations: { subAccountField: Field<string>; valueField: NumericField }[],
  numTradableSubAccounts: number
) {
  const isSubAccountRequired = numTradableSubAccounts > 0;
  const allocationsHasError = isSubAccountRequired
    ? allocations.length === 0 ||
      allocations.flatMap(alloc => [alloc.subAccountField, alloc.valueField]).some(field => field.hasError)
    : false;
  return !allocationsHasError;
}
