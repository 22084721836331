import type { RowGroupsOpenedState } from '@talos/kyoko';
import { useOperationsOverviewContext } from '../providers/OperationsOverviewStateProvider';
import { OperationsOverviewBlotter } from './OperationsOverviewBlotter';
import type { OperationsOverviewBlotterColumnSpecification } from './useOperationsOverviewBlotterColumns';

const sharedColumns = [
  'Amount',
  'Equivalent.Amount',
  'Equivalent.UnrealizedPnL',
  'Equivalent.Equity',
  'Equivalent.InitialMargin',
  'Equivalent.MaintenanceMargin',
] satisfies OperationsOverviewBlotterColumnSpecification[];

const defaultRowGroupsOpened: RowGroupsOpenedState = {};

const byMarketColumns = [
  'reconWarning',
  { field: 'Market', rowGroupIndex: 0, rowGroup: true, hide: true },
  { field: 'MarketAccount', rowGroupIndex: 1, rowGroup: true, hide: true },
  { id: 'underlying', rowGroupIndex: 2, rowGroup: true, hide: true },
  'Asset',
  ...sharedColumns,
] satisfies OperationsOverviewBlotterColumnSpecification[];

const byAssetColumns = [
  'reconWarning',
  { field: 'Asset', rowGroup: true, hide: true },
  'MarketAccount',
  ...sharedColumns,
] satisfies OperationsOverviewBlotterColumnSpecification[];

// This component wraps the blotter and specifically handles grabbing the correct blotterID and passing it to the blotter,
// also making sure to re-mount the blotter on blotterID change
export const OperationsOverviewBlotterContainer = () => {
  const {
    state: { showBy },
  } = useOperationsOverviewContext();

  const columnsToUse = showBy === 'Asset' ? byAssetColumns : byMarketColumns;
  // flatten our 3 showBys to just two since mktacc and mkt are to be shown the same way in the blotter at least
  const byAssetOrMarket = showBy === 'Asset' ? 'Asset' : 'Market';

  // We keep two blotter IDs, one for each showBy (by market or by asset). These are regarded as different blotters for the sake of persistance
  const blotterID = `OPERATIONS_OVERVIEW_BLOTTER/${byAssetOrMarket}`;
  return (
    <OperationsOverviewBlotter
      key={blotterID}
      blotterID={blotterID}
      defaultColumns={columnsToUse}
      defaultRowGroupsOpened={defaultRowGroupsOpened}
    />
  );
};
