import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash';
import type { Paths } from '../../../utils';
import type { AgGridSizeProps } from '../../AgGrid/types';
import type { SizeValue } from '../aggFuncs/types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export interface GreekColumnParams<T extends object = any> {
  /**  Security field to use to resolve the underlying currency */
  securityField?: Paths<T>;
  /** Increment for rendering. Defaults to 0.01 */
  increment?: string;
  /** Whether or not to make the text red when negative */
  highlightNegative?: boolean;
}

/** Render a greek with support for aggregation if securityField is provided. */
const greek: ColDefFactory<Column<GreekColumnParams>> = column => ({
  width: 100,
  ...baseColumn(column),
  type: 'numericColumn',
  cellRenderer: 'sizeColumn',
  headerClass: () => 'ag-right-aligned-header',
  cellRendererParams: (params: ICellRendererParams<unknown, SizeValue>): AgGridSizeProps => {
    // We want to reuse the sizeColumn renderer, but don't want to show any currency for greeks.
    if (params.value == null) {
      return { ...params }; // just default params without any value (as its added below)
    }
    return { ...params, highlightNegative: column.params?.highlightNegative, value: { ...params.value, currency: '' } };
  },
  valueGetter: (params: ValueGetterParams<unknown>): SizeValue => {
    if (!params.data || !column.field) {
      return { value: '', currency: '' };
    }

    const value = get(params.data, column.field);

    // Try to resolve an underlying currency here.
    const security: string | undefined = column.params?.securityField
      ? get(params.data, column.params.securityField)
      : undefined;
    const underlying = security ? params.context.current.securitiesBySymbol?.get(security) : undefined;

    return { value, currency: underlying?.BaseCurrency ?? '', increment: column.params?.increment ?? '0.01' };
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, SizeValue>): string => {
    if (value == null) {
      return '';
    }
    return typeof value.value === 'string' ? value.value : value.value?.toFixed() ?? '';
  },
  // TODO this aggfunc is bugged. turn it off for all usages for now. we need to build another similar aggfunc or something for greeks specifically
  // because they kinda dont have any currency, and they should maybe only be aggregated within their product type, etc...... too many unknowns and
  // and one bug so disabling here and leaving this note for the next dev
  //aggFunc: column.aggregate ? sizeAggFunc : undefined,
});

export const delta: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: true, increment: '0.01' } }),
});

export const gamma: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: false, increment: '0.01' } }),
});

export const vega: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: false, increment: '0.0001' } }),
});

export const theta: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: false, increment: '0.01' } }),
});

export const rho: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: false, increment: '0.01' } }),
});
