import { DockviewReact, type IDockviewReactProps } from 'dockview';
import 'dockview/dist/styles/dockview.css';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { TAB_COMPONENTS, WrapComponentsForDockView } from '../panelUtils';
import { DockViewStyles } from '../styles';
import type { TabComponentSpec } from '../types';
import { CreateAddTabComponent } from './CreateAddTabComponent';
import { TabSetRightComponent } from './TabSetRightComponent';

interface GridLayoutProps {
  availablePanels: TabComponentSpec[];
  onReady: IDockviewReactProps['onReady'];
}

const DockViewLayout: React.FC<GridLayoutProps> = ({ availablePanels, onReady }) => {
  const theme = useTheme();
  const AddTabComponent = useMemo(() => CreateAddTabComponent(availablePanels), [availablePanels]);
  const panelComponents = useMemo(() => WrapComponentsForDockView(availablePanels), [availablePanels]);
  return (
    <>
      <DockViewStyles />
      <DockviewReact
        onReady={onReady}
        components={panelComponents}
        className="dockview-theme-dark"
        gap={theme.spacingContent}
        leftHeaderActionsComponent={AddTabComponent}
        rightHeaderActionsComponent={TabSetRightComponent}
        tabComponents={TAB_COMPONENTS}
      />
    </>
  );
};

export default DockViewLayout;
