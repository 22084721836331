import { SearchSelect } from '@talos/kyoko';
import { useSourceAndDestinationOptions } from 'containers/Transfers';
import type { SourceDestinationOption } from '../../../../../../containers/Transfers/types';
import type { TransferOverviewSelector } from '../../types';

export interface DestinationsSelectorProps extends TransferOverviewSelector<SourceDestinationOption> {
  currency?: string;
  destination?: SourceDestinationOption;
  onChange: (destination?: SourceDestinationOption) => void;
  market?: string;
  source?: SourceDestinationOption;
}

const getLabel = (destination: SourceDestinationOption) => destination.label;

export function DestinationsSelector({
  currency,
  destination,
  market,
  onChange,
  source,
  ...searchSelectProps
}: DestinationsSelectorProps) {
  const { destinationOptions } = useSourceAndDestinationOptions({ currency, destination, market, source });

  return (
    <SearchSelect
      {...searchSelectProps}
      selection={destination}
      options={destinationOptions}
      onChange={onChange}
      getLabel={getLabel}
      data-testid="destination-select"
    />
  );
}
