import { useCallback } from 'react';
import { useMixpanel, useStrategiesContext } from '../../contexts';
import { ExecutionReport, type CustomerExecutionReport } from '../../types';
import { ParametersTable } from '../ParametersTable';
import { Side } from '../Side';
import { SmartSummary, type SmartSummaryProps } from '../SmartSummary';
import { Table, Tbody, Td, Th, Tr } from '../Table';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { Header, Wrapper } from './styles';

import { defineMessages } from 'react-intl';
import { MixpanelEvent } from '../../tokens/mixpanel';
import { isEntityRFQ } from '../../utils/order';
import { Flex } from '../Core';
import { FormattedMessage } from '../Intl';
import { ExecutionReportDifference } from './ExecutionReportDifference';
import { useDifferenceBetweenExecutionReports } from './ExecutionReportDifference/utils';

const messages = defineMessages({
  allowedSlippage: {
    defaultMessage: 'Allowed slippage',
    id: 'ExecutionReportSummary.allowedSlippage',
  },
  diff: {
    defaultMessage: 'Diff',
    id: 'ExecutionReportSummary.diff',
  },
  none: {
    defaultMessage: 'None',
    id: 'ExecutionReportSummary.none',
  },
  params: {
    defaultMessage: 'Params',
    id: 'ExecutionReportSummary.params',
  },
  rfq: {
    defaultMessage: 'RFQ',
    id: 'ExecutionReportSummary.rfq',
  },
});

export function ExecutionReportSummary({
  executionReport,
  prevExecutionReport,
  showDiff = false,
  ...props
}: {
  executionReport: ExecutionReport | CustomerExecutionReport;
  prevExecutionReport?: ExecutionReport | CustomerExecutionReport;
  showDiff?: boolean;
} & Omit<SmartSummaryProps, 'entity' | 'type'>) {
  const { strategiesByName } = useStrategiesContext();
  const strategy = strategiesByName.get(executionReport?.Strategy || '');
  const isRFQ = isEntityRFQ(executionReport);

  const isReportsComparable =
    executionReport instanceof ExecutionReport && prevExecutionReport instanceof ExecutionReport;

  const differences = useDifferenceBetweenExecutionReports(
    isReportsComparable ? executionReport : undefined,
    isReportsComparable ? prevExecutionReport : undefined
  );

  const mixpanel = useMixpanel();
  const handleOnViewDiff = useCallback(() => {
    mixpanel.track(MixpanelEvent.ViewTimelineDiff);
  }, [mixpanel]);

  return (
    <Wrapper>
      <Header>
        <div style={{ display: 'inline-flex', gap: '5px' }}>
          <Side side={executionReport.Side}>{executionReport.Side}</Side>
          {executionReport.Symbol}
          <Text color="colorTextMuted" style={{ textTransform: 'uppercase' }}>
            {isRFQ ? <FormattedMessage {...messages.rfq} /> : strategy?.DisplayName}
          </Text>
        </div>
        <Flex flexDirection="row" gap="spacingDefault">
          {!!differences?.length && showDiff && (
            <Tooltip
              tooltip={<ExecutionReportDifference differences={differences} />}
              onOpen={handleOnViewDiff}
              placement="bottom"
              showUnderline={true}
              usePortal
            >
              <Text size="fontSizeSmall" color="colorTextSubtle">
                <FormattedMessage {...messages.diff} />
              </Text>
            </Tooltip>
          )}
          <Tooltip
            showUnderline={true}
            tooltip={
              isRFQ ? (
                <Table>
                  <Tbody>
                    <Tr>
                      <Th>
                        <FormattedMessage {...messages.allowedSlippage} />
                      </Th>
                      <Td>
                        {executionReport.AllowedSlippage ?? (
                          <Text color="colorTextMuted">
                            <FormattedMessage {...messages.none} />
                          </Text>
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              ) : (
                <ParametersTable order={executionReport} />
              )
            }
            placement="bottom"
          >
            <Text color="colorTextSubtle" size="fontSizeSmall">
              <FormattedMessage {...messages.params} />
            </Text>
          </Tooltip>
        </Flex>
      </Header>
      <SmartSummary entity={executionReport} type="order" showSide={false} showLastMarket={true} {...props} />
    </Wrapper>
  );
}
