import {
  ACTION,
  Box,
  Button,
  ButtonVariants,
  Card,
  ConnectionStatusIndicator,
  Divider,
  Drawer,
  DrawerFooter,
  Flex,
  FormControlSizes,
  HStack,
  IconButton,
  IconName,
  IndicatorDotVariants,
  Tab,
  TabList,
  TabSize,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useConnectionStatus,
  useDynamicCallback,
  useMarketsContext,
  usePortal,
  type DrawerProps,
  type MarketAccount,
  type MarketCredential,
} from '@talos/kyoko';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useRoleAuth } from '../../../hooks';
import { DrawerHeader } from '../Credentials/styles';
import { useTradingControlsGeneralTab } from './tabs/TradingControlsGeneralTab';
import { useTradingControlsHistoryTab } from './tabs/TradingControlsHistoryTab';
import { RIGHT_ITEMS_PORTAL_ID } from './types';

interface DetailsDrawerProps {
  selectedEntity: MarketAccount | undefined;
  marketCredentials: MarketCredential[];
  handleOpenCredential?: (credentialID: number) => void;
}

export const TradingControlsDetailsDrawer = memo((props: DetailsDrawerProps & DrawerProps) => {
  const { isAuthorized } = useRoleAuth();
  const { marketsByName } = useMarketsContext();
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { selectedEntity, marketCredentials, handleOpenCredential, close } = props;

  const market = useMemo(() => {
    return selectedEntity ? marketsByName.get(selectedEntity.Market) : undefined;
  }, [marketsByName, selectedEntity]);

  const connectionStatus = useConnectionStatus({
    CredentialID: selectedEntity?.CredentialID ?? -1,
    Market: selectedEntity?.Market ?? '',
  });

  const marketCredential = useMemo(
    () => marketCredentials.find(credential => credential.CredentialID === selectedEntity?.CredentialID),
    [marketCredentials, selectedEntity]
  );

  const generalTab = useTradingControlsGeneralTab({ entity: selectedEntity });
  const historyTab = useTradingControlsHistoryTab({ entity: selectedEntity });

  const viewableTabs = useMemo(() => {
    return [generalTab, historyTab];
  }, [generalTab, historyTab]);

  const isDirty = viewableTabs.some(tab => tab.isDirty);
  const isValid = viewableTabs.every(tab => tab.isValid);

  const handleClose = useDynamicCallback(() => {
    close();
  });

  const handleSaveChanges = useCallback(async () => {
    try {
      setIsSaving(true);
      for (const [tabIndex, tab] of viewableTabs.entries()) {
        if (tab.isDirty) {
          await tab.save().catch(e => {
            setActiveTabIndex(tabIndex); // there was an error in this tab, so we need to show it
            throw e; // rethrow to stop the loop
          });
        }
      }
      handleClose();
    } catch (e) {
      // ignore
    } finally {
      setIsSaving(false);
    }
  }, [viewableTabs, handleClose]);

  const handleTabChange = useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);

  // Allow each tab to add buttons to the right of the tabs by using a portal
  const { setPortalRef } = usePortal(RIGHT_ITEMS_PORTAL_ID);

  const handleOpenCredentialDrawer = useCallback(
    (credentialID: number) => {
      close();
      handleOpenCredential?.(credentialID);
    },
    [close, handleOpenCredential]
  );

  return (
    <Drawer {...props}>
      {selectedEntity && (
        <>
          <DrawerHeader>
            <Flex gap="spacingDefault" alignItems="center">
              <Text color="colorTextImportant">Trading Controls - {selectedEntity.DisplayName}</Text>
            </Flex>
            <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
          </DrawerHeader>
          <Flex flex="1" flexDirection="column">
            <Card background="transparent">
              <Table w="100%">
                <Tbody>
                  <Tr>
                    <Th>Market</Th>
                    <Td align="right" data-testid="entity-market">
                      {market?.DisplayName || selectedEntity.Market}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Market Type</Th>
                    <Td align="right" data-testid="entity-market-type">
                      {market?.Type}
                    </Td>
                  </Tr>
                  {handleOpenCredential && marketCredential && isAuthorized(ACTION.VIEW_CREDENTIALS) && (
                    <Tr>
                      <Th>Credential</Th>
                      <Td align="right" data-testid="entity-credential">
                        <HStack gap="spacingSmall" justifyContent="flex-end">
                          {marketCredential.Label}
                          <Button
                            endIcon={IconName.ArrowRight}
                            size={FormControlSizes.Small}
                            onClick={() => {
                              handleOpenCredentialDrawer(marketCredential.CredentialID);
                            }}
                          >
                            View
                          </Button>
                        </HStack>
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Th>Status</Th>
                    <Td align="right" data-testid="entity-status">
                      {connectionStatus?.Status ? (
                        <ConnectionStatusIndicator
                          status={connectionStatus.Status}
                          iconRight={false}
                          style={{ justifyContent: 'flex-end' }}
                        />
                      ) : (
                        'N/A'
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Card>
            <Divider color="gray.010" />

            <Tabs w="100%" h="100%" selectedIndex={activeTabIndex} onSelect={handleTabChange} size={TabSize.Large}>
              <TabList isBordered style={{ flexShrink: 0 }} pl="0" rightItems={<Box ref={setPortalRef} />}>
                {viewableTabs.map(tab => (
                  <Tab
                    showDot={tab.isDirty}
                    dotVariant={IndicatorDotVariants.Primary}
                    label={tab.name}
                    key={tab.name}
                    style={{ marginLeft: `${theme.spacingSmall}px` }}
                  />
                ))}
              </TabList>
              <Box h="100%" overflow="auto">
                {viewableTabs.map(tab => (
                  <Box
                    key={tab.name}
                    display={viewableTabs[activeTabIndex].name === tab.name ? 'block' : 'none'}
                    p="spacingDefault"
                    pb="0"
                    w="100%"
                    h="100%"
                  >
                    {viewableTabs[activeTabIndex].name === tab.name && tab.component}
                  </Box>
                ))}
              </Box>
            </Tabs>
          </Flex>
          <DrawerFooter>
            <Button onClick={handleClose}>Close</Button>
            <Button
              onClick={handleSaveChanges}
              disabled={!isDirty || !isValid}
              variant={ButtonVariants.Primary}
              loading={isSaving}
            >
              Update
            </Button>
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
});
