export class CustomerCredit {
  Counterparty!: string;
  ExposureCurrency!: string;
  ExposureLimit!: string;
  Exposure!: string;
  MarketAccount!: string;
  CreditCurrency?: string;

  RowID?: string;

  constructor(data: CustomerCredit) {
    this.CreditCurrency = data.ExposureCurrency;
    Object.assign(this, data);
  }
}
