import { compact, isEmpty, isObject } from 'lodash';
import { useMemo } from 'react';
import { getAgGridColId } from './columns/getAgGridColId';
import type { Column } from './columns/types';

/**
 * Given an array of column ids / columns, and a map of "base" column definitions, we construct a final set of columns to pass to `useBlotterTable`.
 * The columns defined in the `defaultColumns` parameter are spread on top of the base column definition found in the `columnDefinitions` array.
 * Note that if a column is present in the `defaultColumns` array, but not present in the `columnDefinitions` map, it will _not_ be present in the final array of columns.
 * @param defaultColumns The columns to show by default. The properties set on these columns wll be spread on top of the properties in the column definitions map columns.
 * @param columnDefinitions The "official" definitions of the columns for this blotter
 * @returns An array of column definitions to pass to `useBlotterTable`
 */
export function useDefaultColumns(
  defaultColumns: (string | Partial<Column>)[],
  columnDefinitions: Map<string, Column>
) {
  const columns = useMemo(() => {
    const allColDefs = new Map<string, Column>(columnDefinitions);
    // 1. First, add the default column definitions
    for (const colDef of defaultColumns) {
      // If `colDef` is just a column id, that just means that the column should default to visible
      const colId = isObject(colDef) ? getAgGridColId(colDef) : colDef;
      if (columnDefinitions.has(colId)) {
        allColDefs.set(colId, {
          ...((columnDefinitions.get(colId) ?? {}) as Column),
          hide: false,
          // If `colDef` is an object, it contains overrides for the original column definition
          ...((isObject(colDef) ? colDef : {}) as Column),
        });
      }
    }

    if (!isEmpty(defaultColumns)) {
      const defaultColumnIds = defaultColumns.map(c => (isObject(c) ? getAgGridColId(c) : c));
      const defaultColumnIdsSet = new Set(defaultColumnIds);

      // 2. If default columns were specified, hide all the columns that _weren't_ in that list
      for (const [colId, colDef] of columnDefinitions.entries()) {
        if (!defaultColumnIdsSet.has(colId)) {
          allColDefs.set(colId, {
            ...colDef,
            hide: true,
          });
        }
      }

      // 3. We now have our completed map (allColDefs) of columns with proper state applied from the defaultColumns onto the base definitions.
      // Now we just want to reflect the order of columns provided in the input defaultColumns array. We simply just put all defaultColumnsDefs first,
      // and then follow that up with the remainder (already ordered) list of allColDefs
      const defaultColumnDefsArr = compact(defaultColumnIds.map(c => allColDefs.get(c)));
      const restColDefsArr = [...allColDefs.values()].filter(
        colDef => !defaultColumnIdsSet.has(getAgGridColId(colDef))
      );
      return [...defaultColumnDefsArr, ...restColDefsArr];
    }

    return [...allColDefs.values()];
  }, [columnDefinitions, defaultColumns]);

  return columns;
}
