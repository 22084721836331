import {
  Card,
  Divider,
  InlineFormattedNumber,
  ParametersTable,
  Table,
  TableSize,
  Tbody,
  Td,
  Th,
  Tr,
  percentToBps,
  useStrategiesContext,
  type CardProps,
  type Order,
} from '@talos/kyoko';
import { MarketAccountNames } from '../../../../../components/MarketAccountNames';
import { MarketNames } from '../../../../../components/MarketNames';

export interface ParametersCardProps extends CardProps {
  order: Order;
  showExpandedDetails: boolean;
}

export function ParametersCard({ order, showExpandedDetails, ...props }: ParametersCardProps) {
  const { strategiesByName } = useStrategiesContext();
  const strategy = order.Strategy ? strategiesByName.get(order.Strategy) : undefined;

  return (
    <Card title="Parameters" {...props}>
      <Table w="100%">
        <Tbody>
          <Tr>
            <Th>Strategy</Th>
            <Td align="right">{order.RFQID == null ? strategy?.DisplayName ?? order.Strategy : 'RFQ'}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Divider my="spacingComfortable" />
      {order.AllowedSlippage && (
        <Table w="100%">
          <Tbody>
            <Tr>
              <Th>Allowed slippage</Th>
              <Td align="right">
                <InlineFormattedNumber number={percentToBps(order.AllowedSlippage)} currency="bps" />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      )}
      <ParametersTable order={order} size={TableSize.Default} w="100%" />
      <Divider my="spacingComfortable" />
      <Table w="100%">
        <Tbody>
          {showExpandedDetails && (
            <Tr>
              <Th>Selected Markets</Th>
              <Td align="right">
                <MarketNames markets={order.selectedMarkets} />
              </Td>
            </Tr>
          )}
          <Tr>
            <Th>Selected Market Accounts</Th>
            <Td align="right">
              <MarketAccountNames marketAccounts={order.selectedMarketAccounts} />
            </Td>
          </Tr>
          {showExpandedDetails && (
            <Tr>
              <Th>Traded Markets</Th>
              <Td align="right">
                <MarketNames markets={order.tradedMarkets} />
              </Td>
            </Tr>
          )}
          <Tr>
            <Th>Traded Market Accounts</Th>
            <Td align="right">
              <MarketAccountNames marketAccounts={order.tradedMarketAccounts} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Card>
  );
}
