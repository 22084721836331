import { compact, uniq } from 'lodash';
import { useEffect, useState } from 'react';

import { logger, useUserContext } from '@talos/kyoko';
import { useOrders } from 'providers/OrdersProvider';

import { useTradingSettings } from 'providers/AppConfigProvider';

export function useGroups() {
  const { enableGroups } = useTradingSettings();
  const { historicalOrders } = useOrders();
  const { orgApiEndpoint } = useUserContext();
  const [groupOptions, setGroupOptions] = useState<string[]>([]);
  useEffect(() => {
    if (orgApiEndpoint && enableGroups) {
      historicalOrders(orgApiEndpoint)
        .then(orders => {
          setGroupOptions(uniq(compact(orders.data.map(order => order.Group))));
        })
        .catch(e => {
          // This usually just happens during testing, but we can gracefully fall back to an empty array
          logger.error(e);
        });
    }
  }, [orgApiEndpoint, historicalOrders, enableGroups]);
  return groupOptions;
}
