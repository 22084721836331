import type { RowGroupsOpenedState } from '@talos/kyoko';
import type { CustomerBalancesColumn, CustomerBalancesFilter, CustomerBalancesTabsProps } from './types';

export const defaultCustomerBalancesColumns: CustomerBalancesColumn[] = [
  { field: 'Counterparty', rowGroup: true, rowGroupIndex: 0, hide: true },
  'Currency',
  'MarketAccount',
  'Equivalent.Amount',
  'Amount',
  'AvailableAmount',
  'OutstandingBuy',
  'OutstandingSell',
  'PendingWithdrawal',
  'UnsettledAmount',
];

export const defaultCustomerBalancesFilter: CustomerBalancesFilter = {};

export const defaultCustomerBalancesRowGroupsOpened: RowGroupsOpenedState = {};

export const DEFAULT_CUSTOMER_BALANCES_TABS: CustomerBalancesTabsProps[] = [
  {
    label: 'Balances',
    id: 'customer-balances-default',
    defaultColumns: defaultCustomerBalancesColumns,
    defaultFilter: defaultCustomerBalancesFilter,
    defaultRowGroupsOpened: defaultCustomerBalancesRowGroupsOpened,
    showZeroBalances: false,
  },
];
