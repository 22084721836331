import { logger, type SubscriptionResponse } from '@talos/kyoko';

interface TData {
  Timestamp: string;
}

export interface SeriesDefinition<T extends TData> {
  name: string;
  entityToPoint: (entity: T) => number[]; // [x, y]s
}

/** A SerieBuilder is a construct holding everything we need to go from ws json entity to highcharts point */
interface SerieBuilder<T extends TData> {
  serie: Highcharts.Series;
  entityToPoint: SeriesDefinition<T>['entityToPoint'];
}

interface ConsumePortfolioChartSubscriptionParams<T extends TData> {
  /**
   * A ref which this consumer stores all its data in if provided.
   * Can be skipped, in which case all data received will not be stored anywhere except as points in the chart itself
   */
  currentDataPointsByTsRef?: React.MutableRefObject<Map<number, T>>;
  /** Json from the dynamic chart subscription */
  json: SubscriptionResponse<T>;
  /** Reset property from the dynamic chart hook output */
  reset: boolean;
  /** The chart object */
  chart: Highcharts.Chart;
  /**
   * Series definitions for each series you want to inject data into when a new update is received
   */
  seriesDefinitions: SeriesDefinition<T>[];
  /**
   * If you're using a separate navigator series (not connected to any base series), you can pass in a definition for it here
   * to dynamically populate it with data.
   *
   * The navigator series will only be populated with _reset_ ("initial") data, and then not have any more data pushed to it.
   */
  navigatorSeriesDefinition?: SeriesDefinition<T>;
}

/**
 * This hook is an attempt to encapsulate some of the core logic of how we inject ws json data
 * into some set of highcharts series. This hook is intended to be used together with the output
 * of the `usePortfolioChartSubscription` hook.
 */
export function consumePortfolioChartSubscription<T extends TData>({
  currentDataPointsByTsRef,
  json,
  reset,
  chart,
  seriesDefinitions,
  navigatorSeriesDefinition,
}: ConsumePortfolioChartSubscriptionParams<T>) {
  const seriesBuilders = getSerieBuilders(chart, seriesDefinitions);
  const navigatorSerieBuilder = navigatorSeriesDefinition
    ? getSerieBuilders(chart, [navigatorSeriesDefinition])[0]
    : undefined;

  // Raise warning if any of the series are missing :/
  if (seriesBuilders.length !== seriesDefinitions.length) {
    logger.warn('Unable to find all relevant series for applying chart update', {
      extra: {
        seriesDefinitions,
        // The person examining this event can check whats missing between these two
        foundSeries: seriesBuilders.map(sb => sb.serie.name),
      },
    });
  }

  if (reset) {
    seriesBuilders.forEach(sb => sb.serie.setData([], false, false));
    navigatorSerieBuilder?.serie.setData([], false, false);
    currentDataPointsByTsRef?.current.clear();
  }

  if (json.data.length === 0) {
    if (reset) {
      chart.redraw(false);
    }

    return;
  }

  const first = json.data[0];
  const last = json.data[json.data.length - 1];

  const firstTimestamp = new Date(first.Timestamp).getTime();
  const lastTimestamp = new Date(last.Timestamp).getTime();

  // I know that its one series, many series, but it makes readability easier
  seriesBuilders.forEach(({ serie, entityToPoint }) => {
    // Go through all the existing data in our series and index it by ts for quicker lookup in the next loop
    const serieTsToExistingPoints = new Map<number, Highcharts.Point[]>();
    for (const point of serie.data) {
      if (!point) {
        continue;
      }

      if (point.x > lastTimestamp) {
        break; // we're done
      }

      if (point.x > firstTimestamp) {
        // We're between, insert here
        // In some cases, because of highcharts grouping complexity mostly, we may duplicate data at certain timestamps.
        // To defend against this over time, we collect an array of points at each relevant timestamp, and iterate over that array
        // when removing pre-existing points where we're receiving new data.
        // There _should_ only be one data point per x value (per series). So this is just being defensive after observing that
        // highcharts is pretty complex, and sometimes incorrect things happen.
        serieTsToExistingPoints.set(point.x, [...(serieTsToExistingPoints.get(point.x) ?? []), point]);
      }
    }

    for (const update of json.data) {
      const point = entityToPoint(update);
      const existingPoints = serieTsToExistingPoints.get(point[0]);

      if (existingPoints) {
        existingPoints.forEach(point => point.remove(false));
      }

      serie.addPoint(point, false, false, false, false);
    }

    // This is a hack resolving this mouse wheel zoom issue: https://github.com/highcharts/highcharts/issues/19475
    if (reset || serie.data.length === 0) {
      const xAxisMin = chart.xAxis[0].min;
      if (xAxisMin != null) {
        const xData: number[] = serie['xData']; // existing but hidden property containing the unix ts of all data
        // Only add the hacky null data point at the min of the xAxis if the real data doesn't already have a data point at that timestamp
        if (xData.length > 0 && xData[0] > xAxisMin) {
          serie.addPoint([xAxisMin, null], false, false, false, false);
        }
      }
    }
  });

  // We only set navigator data on a reset update, or if its entirely empty.
  if (navigatorSerieBuilder && (reset || navigatorSerieBuilder.serie.data.length === 0)) {
    for (const update of json.data) {
      navigatorSerieBuilder.serie.addPoint(navigatorSerieBuilder.entityToPoint(update), false, false, false, false);
    }
  }

  if (currentDataPointsByTsRef != null) {
    for (const update of json.data) {
      currentDataPointsByTsRef.current.set(new Date(update.Timestamp).getTime(), update);
    }
  }

  chart.redraw(false);
}

function getSerieBuilders<T extends TData>(chart: Highcharts.Chart, definitions: SeriesDefinition<T>[]) {
  return definitions
    .map(definition => ({
      serie: chart.series.find(s => s.name === definition.name),
      entityToPoint: definition.entityToPoint,
    }))
    .filter(isSerieBuilder);
}

type MaybeSerieBuilder<T extends TData> = Omit<SerieBuilder<T>, 'serie'> & Partial<Pick<SerieBuilder<T>, 'serie'>>;
function isSerieBuilder<T extends TData>(maybeBuilder: MaybeSerieBuilder<T>): maybeBuilder is SerieBuilder<T> {
  return maybeBuilder.serie != null;
}
