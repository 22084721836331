import { SearchSelect, useMarketsContext, type Market, type MarketConfig, type MarketCredential } from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { useCredentials } from '../useCredentials';

interface SelectMarketCredentialProps {
  selectedCredential?: MarketCredential;
  selectedMarket: Market | undefined;
  marketConfigs: MarketConfig[];
  onSelected: (market: Market | undefined) => void;
}

const getMarketLabel = (market: Market) => market.DisplayName || market.Name;

export function SelectMarketCredential({
  selectedCredential,
  selectedMarket,
  marketConfigs,
  onSelected,
}: SelectMarketCredentialProps) {
  const { marketsByName } = useMarketsContext();
  const { hiddenMarkets } = useCredentials();

  const availableMarkets = useMemo((): Market[] => {
    return marketConfigs
      .map(marketConfig => marketsByName?.get(marketConfig.name))
      .filter((m): m is Market => !!m && !hiddenMarkets.includes(m.Name));
  }, [marketConfigs, marketsByName, hiddenMarkets]);

  const handleChange = useCallback(
    (market: Market | undefined) => {
      onSelected(market);
    },
    [onSelected]
  );

  return (
    <SearchSelect
      data-testid="market"
      disabled={selectedCredential != null}
      options={availableMarkets}
      getLabel={getMarketLabel}
      onChange={handleChange}
      selection={selectedMarket || undefined}
      initialSortByLabel={true}
    />
  );
}
