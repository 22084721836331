import chroma from 'chroma-js';
import {
  createChart,
  CrosshairMode,
  LineStyle,
  type IChartApi,
  type ISeriesApi,
  type LogicalRange,
  type SeriesMarker,
  type Time,
  type UTCTimestamp,
} from 'lightweight-charts';
import { useCallback, useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import {
  Box,
  Flex,
  format,
  formattedDate,
  getOppositeSide,
  Icon,
  ICON_SIZES,
  IconName,
  InlineFormattedNumber,
  LegDirectionEnum,
  Side,
  Text,
  useDynamicCallback,
  useElementSize,
  useSecuritiesContext,
  useSecurity,
  type Order,
  type OrderAnalytic,
} from '@talos/kyoko';

import { ORDER_SIDES, STRATEGY_GROUP } from 'tokens/order';
import {
  ChartLegend,
  ChartLegendItem,
  ChartLegendLabel,
  ChartLegendValue,
  ChartMeta,
  ChartWrapper,
  TradeMarkerPopover,
} from './styles';

import { toBigWithDefault } from '@talos/kyoko';
import { last } from 'lodash';
import { useStrategies } from 'providers/StrategiesProvider';
import type { Observable, Subject } from 'rxjs';
import { ThemeProvider, useTheme } from 'styled-components';

// date-fns's lastMillisecond actually gives the final millisecond (e.g. 1.999), whereas we actually
// consider the start of the next second to be the end
// const endOfSecond = date => addMilliseconds(lastMillisecond(date), 1);

// Chart expects _localized_ unix epoch in seconds
function formatTimeForChart(str: string): UTCTimestamp {
  const date = new Date(str);
  const offset = date.getTimezoneOffset() * 60 * 1000;
  return ((date.getTime() - offset) / 1000) as UTCTimestamp;
}

export interface OrderExecutionChartProps {
  order: Order;
  isMultileg: boolean;
  strategy?: string;
  height: number;
  width?: number;
  // If specified, reflow will resize the graph
  resizeAware?: boolean;
  latestAnalytics: OrderAnalytic;
  legOrParentAnalyticsObservable: Observable<{
    data: Record<number, OrderAnalytic[]>;
    initial?: boolean | undefined;
  }>;
  legIndex?: number;
  visibleRangeSubject?: Subject<LogicalRange | null>;
  onVisibleRangeChange?: (range: LogicalRange | null) => void;
}

export const OrderExecutionChart = ({
  strategy,
  height,
  width,
  resizeAware = false,
  order,
  isMultileg,
  latestAnalytics,
  legOrParentAnalyticsObservable,
  legIndex = 0,
  visibleRangeSubject,
  onVisibleRangeChange,
}: OrderExecutionChartProps) => {
  const {
    elementRef: ref,
    size: { clientWidth },
  } = useElementSize<HTMLDivElement>();
  const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
  const [chart, setChart] = useState<IChartApi | undefined>(undefined);
  const [resolution, setResolution] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const { securitiesBySymbol } = useSecuritiesContext();
  const orderSecurity = useSecurity(order.Symbol);
  const [series, setSeries] = useState<{
    avgPx?: ISeriesApi<'Line'>;
    marketMidPx?: ISeriesApi<'Line'>;
    arrivalPx?: ISeriesApi<'Line'>;
    cumulativeVWAP?: ISeriesApi<'Line'>;
    limitPx?: ISeriesApi<'Line'>;
    trades?: ISeriesApi<'Line'>;
  }>({});
  const [legendValues, setLegendValues] = useState<{
    avgPx?: string;
    marketMidPx?: string;
    arrivalPx?: string;
    cumulativeVWAP?: string;
  }>({});
  const isMovingCrosshair = useRef(false);
  const didInteractWithChart = useRef(false);
  const symbol = latestAnalytics?.Symbol || order.Symbol;
  const security = useSecurity(symbol);
  const { strategiesByName: strategies } = useStrategies();
  const theme = useTheme();

  const legDetails = isMultileg ? orderSecurity?.MultilegDetails?.Legs?.[legIndex - 1] : undefined;
  const side = legDetails?.Direction === LegDirectionEnum.Opposite ? getOppositeSide(order.Side) : order.Side;

  const maybeLegSecurity = securitiesBySymbol.get(symbol) || orderSecurity;
  const currency = maybeLegSecurity?.PositionCurrency || order.Currency || '';

  const orderStrategy = strategies?.get(strategy ?? 'Limit');

  useEffect(() => {
    if (ref.current != null && security != null) {
      setChart(prev => {
        if (prev != null || ref.current === null) {
          return prev;
        }
        const chart = createChart(ref.current, {
          height,
          width,
          localization: {
            priceFormatter: price => format(price, { spec: security.DefaultPriceIncrement, round: true }),
          },
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            borderColor: theme.borderColorChartAxis,
            rightOffset: theme.spacingSmall,
            shiftVisibleRangeOnNewBar: false,
          },
          rightPriceScale: {
            borderColor: theme.borderColorChartAxis,
          },
          layout: {
            background: {
              color: 'transparent',
            },
            textColor: theme.colors.gray['080'],
            fontFamily: 'Roboto',
            fontSize: 10,
          },
          crosshair: {
            vertLine: {
              color: theme.colors.gray['040'],
            },
            horzLine: {
              visible: false,
              labelBackgroundColor: 'transparent',
            },
            mode: CrosshairMode.Normal,
          },
          grid: {
            vertLines: {
              visible: true,
              color: theme.borderColorChartAxis,
            },
            horzLines: {
              visible: true,
              color: theme.borderColorChartAxis,
            },
          },
        });
        return chart;
      });
    }
  }, [ref, security, height, width, theme]);

  useEffect(() => {
    if (chart != null && security != null) {
      let lastValues = {};

      const limitPriceLine = chart.addLineSeries({
        color: side === ORDER_SIDES.BUY ? chroma(theme.colors.green.dim).hex() : chroma(theme.colors.red.dim).hex(),
        priceLineVisible: false,
        crosshairMarkerVisible: false,
        lineWidth: 1,
        lineStyle: LineStyle.Dotted,
        visible: false, // Hide it for now, see ch25883
        title: 'Limit',
      });
      const vwapLine = chart.addLineSeries({
        color: '#45A3F9',
        priceLineVisible: false,
        crosshairMarkerVisible: false,
        lineWidth: 1,
        lineStyle: LineStyle.Dashed,
        visible: true,
      });
      const arrivalPriceLine = chart.addLineSeries({
        color: '#A355FE',
        priceLineVisible: false,
        lineWidth: 1,
        lineStyle: LineStyle.Dashed,
        crosshairMarkerVisible: false,
        visible: true,
      });
      const avgExecPriceLine = chart.addLineSeries({
        color: '#E3653D',
        priceLineVisible: false,
        crosshairMarkerVisible: false,
        lineWidth: 1,
        lineStyle: LineStyle.Dashed,
        visible: true,
      });
      const tradeMarkersLine = chart.addLineSeries({
        color: 'transparent',
        priceLineVisible: false,
        lastValueVisible: false,
        crosshairMarkerVisible: false,
        visible: true,
      });
      const marketMidPriceLine = chart.addLineSeries({
        color: theme.colors.gray['080'],
        priceLineVisible: false,
        crosshairMarkerVisible: false,
        lineWidth: 1,
        visible: true,
      });

      setSeries({
        avgPx: avgExecPriceLine,
        marketMidPx: marketMidPriceLine,
        arrivalPx: arrivalPriceLine,
        cumulativeVWAP: vwapLine,
        limitPx: limitPriceLine,
        trades: tradeMarkersLine,
      });

      const tradeMarkers: (SeriesMarker<Time> & { [key: string]: any })[] = [];

      const subscription = legOrParentAnalyticsObservable.subscribe(json => {
        const analytics = json.data[legIndex];
        if (!analytics) {
          return null;
        }

        setResolution(last(analytics)?.Resolution || '');

        const endTime = last(analytics)?.EndTime;
        if (endTime) {
          setTimestamp(formattedDate(endTime) ?? '');
        }

        const vwap = analytics.map(a => ({
          time: formatTimeForChart(a.EndTime),
          ...('CumulativeVWAP' in a &&
            a.CumulativeVWAP !== '0' && {
              value: parseFloat(a.CumulativeVWAP),
            }),
        }));
        const arrivalPrice = analytics.map(a => ({
          time: formatTimeForChart(a.EndTime),
          ...(a.ArrivalPx && a.ArrivalPx !== '0' && { value: parseFloat(a.ArrivalPx) }),
        }));
        const avgExecPrice = analytics.map(a => ({
          time: formatTimeForChart(a.EndTime),
          ...(a.AvgPx && a.AvgPx !== '0' && { value: parseFloat(a.AvgPx) }),
        }));
        const marketMidPrice = analytics.map(a => ({
          time: formatTimeForChart(a.EndTime),
          ...(a.MarketMidPx && a.MarketMidPx !== '0' && { value: parseFloat(a.MarketMidPx) }),
        }));
        const limitPrice = analytics.map(a => ({
          time: formatTimeForChart(a.EndTime),
          ...(a.Price && a.Price !== '0' && a.Price != null && { value: parseFloat(a.Price) }),
        }));

        const tradedPrice = analytics.map(a => ({
          time: formatTimeForChart(a.EndTime),
          ...(a.TradedAvgPx && a.TradedAvgPx !== '0' && { value: parseFloat(a.TradedAvgPx) }),
        }));

        if (json.initial) {
          avgExecPriceLine.setData(avgExecPrice);
          arrivalPriceLine.setData(arrivalPrice);
          vwapLine.setData(vwap);
          marketMidPriceLine.setData(marketMidPrice);
          limitPriceLine.setData(limitPrice);
          tradeMarkersLine.setData(tradedPrice);
        } else {
          for (let i = 0; i < analytics.length; i++) {
            avgExecPriceLine.update(avgExecPrice[i]);
            arrivalPriceLine.update(arrivalPrice[i]);
            vwapLine.update(vwap[i]);
            marketMidPriceLine.update(marketMidPrice[i]);
            limitPriceLine.update(limitPrice[i]);
            tradeMarkersLine.update(tradedPrice[i]);
          }
        }

        if (!didInteractWithChart.current) {
          chart.timeScale().fitContent();
        }

        // Add markers
        for (const analytic of analytics) {
          if (toBigWithDefault(analytic.TradeCount, 0).gt(0)) {
            tradeMarkers.push({
              time: formatTimeForChart(analytic.Timestamp),
              shape: 'circle',
              position: 'inBar',
              size: 0.01,
              color:
                side === ORDER_SIDES.BUY ? chroma(theme.colors.green.dim).hex() : chroma(theme.colors.red.dim).hex(),
              id: analytic.Timestamp,
              tradeCount: analytic.TradeCount,
              tradedAmt: analytic.TradedAmt,
              tradedQty: analytic.TradedQty,
              tradedAvgPx: analytic.TradedAvgPx,
            });
          }
        }
        tradeMarkersLine.setMarkers(tradeMarkers);

        lastValues = {
          avgPx: avgExecPrice.at(-1)?.value,
          marketMidPx: marketMidPrice.at(-1)?.value,
          arrivalPx: arrivalPrice.at(-1)?.value,
          cumulativeVWAP: vwap.at(-1)?.value,
        };

        if (!isMovingCrosshair.current) {
          setLegendValues(lastValues);
        }
      });

      const handleCrosshairMoved = params => {
        if (params.point) {
          isMovingCrosshair.current = true;
          setLegendValues(prev => ({
            avgPx: params.seriesData.get(avgExecPriceLine)?.value || prev.avgPx,
            marketMidPx: params.seriesData.get(marketMidPriceLine)?.value || prev.marketMidPx,
            arrivalPx: params.seriesData.get(arrivalPriceLine)?.value || prev.arrivalPx,
            cumulativeVWAP: params.seriesData.get(vwapLine)?.value || prev.cumulativeVWAP,
          }));

          const toolTip = popoverRef;
          const container = ref.current;

          if (container == null || toolTip == null) {
            return;
          }
          if (
            params.hoveredObjectId == null ||
            params.point === undefined ||
            !params.time ||
            params.point.x < 0 ||
            params.point.x > container.clientWidth ||
            params.point.y < 0 ||
            params.point.y > container.clientHeight
          ) {
            toolTip.style.display = 'none';
            return;
          }

          const hoveredMarker = tradeMarkers.find(m => m.id === params.hoveredObjectId);
          if (!hoveredMarker) {
            return;
          }
          toolTip.style.display = 'block';
          toolTip.innerHTML = renderToStaticMarkup(
            <ThemeProvider theme={theme}>
              <Box p="spacingSmall">
                <Flex justifyContent="space-between" mb="spacingSmall">
                  <Side side={side}>{side}</Side>
                  <Text color="colorTextMuted">
                    {hoveredMarker.tradeCount} {hoveredMarker.tradeCount === '1' ? 'trade' : 'trades'}
                  </Text>
                </Flex>
                <InlineFormattedNumber
                  number={hoveredMarker?.tradedQty}
                  increment={security.DefaultSizeIncrement}
                  round={false}
                  currency={currency}
                />
                <Icon
                  icon={IconName.AtSymbol}
                  size={ICON_SIZES.SMALL}
                  color="colorTextMuted"
                  style={{ verticalAlign: 'text-top', margin: `0 ${theme.spacingTiny}px` }}
                />
                <InlineFormattedNumber
                  increment={security.DefaultPriceIncrement}
                  number={hoveredMarker?.tradedAvgPx}
                  currency={security.QuoteCurrency}
                />
              </Box>
            </ThemeProvider>
          );

          const toolTipRect = toolTip.getBoundingClientRect();
          const toolTipHeight = toolTipRect.height;
          const toolTipWidth = toolTipRect.width;
          const toolTipMargin = theme.spacingDefault;

          const price = params.seriesData.get(avgExecPriceLine)?.value;
          const coordinate = avgExecPriceLine.priceToCoordinate(price);

          if (coordinate === null) {
            return;
          }

          toolTip.style.left =
            Math.max(0, Math.min(params.point.x - toolTipWidth / 2, container.clientWidth - toolTipWidth / 2)) + 'px';
          toolTip.style.top = Math.max(0, params.point.y - toolTipHeight - toolTipMargin) + 'px';
        } else {
          isMovingCrosshair.current = false;
          setLegendValues(lastValues);
        }
      };
      chart.subscribeCrosshairMove(handleCrosshairMoved);

      return () => {
        subscription.unsubscribe();
        chart.removeSeries(vwapLine);
        chart.removeSeries(arrivalPriceLine);
        chart.removeSeries(avgExecPriceLine);
        chart.removeSeries(marketMidPriceLine);
        chart.removeSeries(limitPriceLine);
        chart.removeSeries(tradeMarkersLine);
        chart.unsubscribeCrosshairMove(handleCrosshairMoved);
        if (onVisibleRangeChange != null) {
          chart.timeScale().unsubscribeVisibleLogicalRangeChange(onVisibleRangeChange);
        }
      };
    }
  }, [
    currency,
    security,
    side,
    chart,
    legOrParentAnalyticsObservable,
    ref,
    popoverRef,
    theme,
    legIndex,
    onVisibleRangeChange,
  ]);

  useEffect(() => {
    const sub = visibleRangeSubject?.subscribe(range => {
      didInteractWithChart.current = true;
      chart && range && chart.timeScale().setVisibleLogicalRange(range);
    });
    return () => sub?.unsubscribe();
  }, [chart, visibleRangeSubject]);

  const handleClickLegend = useCallback(
    key => {
      series[key].applyOptions({
        visible: !series[key].options().visible,
      });
      // Trigger re-render
      setSeries(prev => ({ ...prev }));
    },
    [series]
  );

  const isMouseDown = useRef(false);
  const handleMouseMove = useDynamicCallback(() => {
    if (isMouseDown.current) {
      const timeScale = chart?.timeScale();
      didInteractWithChart.current = true;
      timeScale && onVisibleRangeChange && onVisibleRangeChange(timeScale.getVisibleLogicalRange());
    }
  });

  const handleWheel = useDynamicCallback(() => {
    const timeScale = chart?.timeScale();
    didInteractWithChart.current = true;
    timeScale && onVisibleRangeChange && onVisibleRangeChange(timeScale.getVisibleLogicalRange());
  });

  // Because we call `fitContent()` every time a new data point is added, we also have the code below
  // for preventing `fitContent()` to be called if the user did manual panning/zooming
  useEffect(() => {
    const internalRefValue = ref.current;
    if (internalRefValue) {
      const handleMouseDown = () => (isMouseDown.current = true);
      const handleMouseUp = () => (isMouseDown.current = false);

      internalRefValue.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseUp);
      internalRefValue.addEventListener('mousemove', handleMouseMove);
      internalRefValue.addEventListener('wheel', handleWheel);
      return () => {
        if (internalRefValue) {
          internalRefValue.removeEventListener('mousedown', handleMouseDown);
          window.removeEventListener('mouseup', handleMouseUp);
          internalRefValue.removeEventListener('mousemove', handleMouseMove);
          internalRefValue.removeEventListener('wheel', handleWheel);
        }
      };
    }
  }, [ref, handleMouseMove, handleWheel]);

  useEffect(() => {
    if (clientWidth && resizeAware && chart) {
      chart.applyOptions({ width: clientWidth });
    }
  }, [chart, clientWidth, resizeAware]);

  if (security == null) {
    return null;
  }

  return (
    <>
      <ChartLegend>
        <ChartLegendItem onClick={() => handleClickLegend('avgPx')} visible={!!series.avgPx?.options().visible}>
          <ChartLegendLabel color="#E3653D">Avg Exec Px</ChartLegendLabel>
          <ChartLegendValue>
            {legendValues.avgPx == null ? (
              'N/A'
            ) : (
              <InlineFormattedNumber
                number={legendValues.avgPx}
                increment={security.DefaultPriceIncrement}
                currency={security.QuoteCurrency}
              />
            )}
          </ChartLegendValue>
        </ChartLegendItem>
        <ChartLegendItem
          onClick={() => handleClickLegend('marketMidPx')}
          visible={!!series.marketMidPx?.options().visible}
        >
          <ChartLegendLabel color="colors.gray.080">Market Mid Px</ChartLegendLabel>
          <ChartLegendValue>
            {legendValues.marketMidPx == null ? (
              'N/A'
            ) : (
              <InlineFormattedNumber
                number={legendValues.marketMidPx}
                increment={security.DefaultPriceIncrement}
                currency={security.QuoteCurrency}
              />
            )}
          </ChartLegendValue>
        </ChartLegendItem>
        <ChartLegendItem onClick={() => handleClickLegend('arrivalPx')} visible={!!series.arrivalPx?.options().visible}>
          <ChartLegendLabel color="#A355FE">Arrival Px</ChartLegendLabel>
          <ChartLegendValue>
            {legendValues.arrivalPx == null ? (
              'N/A'
            ) : (
              <InlineFormattedNumber
                number={legendValues.arrivalPx}
                increment={security.DefaultPriceIncrement}
                currency={security.QuoteCurrency}
              />
            )}
          </ChartLegendValue>
        </ChartLegendItem>
        {orderStrategy?.Group !== STRATEGY_GROUP.DEALER && (
          <ChartLegendItem
            onClick={() => handleClickLegend('cumulativeVWAP')}
            visible={!!series.cumulativeVWAP?.options().visible}
          >
            <ChartLegendLabel color="#45A3F9">Market VWAP</ChartLegendLabel>
            <ChartLegendValue>
              {legendValues.cumulativeVWAP == null ? (
                'N/A'
              ) : (
                <InlineFormattedNumber
                  number={legendValues.cumulativeVWAP}
                  increment={security.DefaultPriceIncrement}
                  currency={security.QuoteCurrency}
                />
              )}
            </ChartLegendValue>
          </ChartLegendItem>
        )}
      </ChartLegend>
      <div style={{ position: 'relative' }}>
        <ChartWrapper ref={ref} />
        <ChartMeta>
          {resolution} / {timestamp}
        </ChartMeta>
        <TradeMarkerPopover ref={setPopoverRef} />
      </div>
    </>
  );
};
