import { VStack } from '@talos/kyoko';
import styled from 'styled-components';

export function EmptyRowsDisplay() {
  return <Container>There are no rows to show.</Container>;
}

const Container = styled(VStack)`
  flex: 1;
  width: 100%;
  min-height: 165px;
`;
Container.defaultProps = {
  fontSize: 'fontSizeSmall',
  color: 'gray.090',
};
