import ReactAutocomplete from 'react-autocomplete';

import { AutocompleteResults, AutocompleteWrapper } from './styles';

export function Autocomplete({ style = {}, isCentered, ...props }) {
  return (
    <AutocompleteWrapper style={style} isCentered={isCentered}>
      <ReactAutocomplete
        {...props}
        menuStyle={{}}
        renderItem={({ id, label }, isHighlighted) => (
          <AutocompleteResults key={id} isHighlighted={isHighlighted}>
            {label}
          </AutocompleteResults>
        )}
      />
    </AutocompleteWrapper>
  );
}
