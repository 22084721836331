import { useSubscription, type Position } from '@talos/kyoko';
import { usePortfolioManagementBlotterPositionRequestContext } from '../PortfolioManagementPositionRequestProvider';

/** Retrieve WS subscription to get Position feed for the PMS Perf blotter
 * - key for retrieving pnl lookup data for each position
 */
export const usePMSSubAccountPositionsSub = () => {
  const { request } = usePortfolioManagementBlotterPositionRequestContext();

  const { data: subscription } = useSubscription<Position>(request, {
    replay: false,
    loadAll: true,
  });

  return subscription;
};
