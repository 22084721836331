import { useDrawer, useMarketAccountsContext, type MarketAccount } from '@talos/kyoko';
import { useCallback, useState } from 'react';

export const useTradingControlsDetailsDrawerWrapper = () => {
  const [selectedMarketAccount, setSelectedMarketAccount] = useState<MarketAccount | undefined>();
  const { marketAccountsByName } = useMarketAccountsContext();

  const tradingDetailsDrawer = useDrawer({
    position: 'relative',
    width: 420,
    placement: 'right',
  });

  const handleOpenTradingControls = useCallback(
    (marketAccountName: string) => {
      setSelectedMarketAccount(marketAccountsByName.get(marketAccountName));
      tradingDetailsDrawer.open();
    },
    [tradingDetailsDrawer, marketAccountsByName]
  );

  return {
    selectedMarketAccount,
    tradingDetailsDrawer,
    handleOpenTradingControls,
  };
};
