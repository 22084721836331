import { Box, CustomerUserConfigLayoutType, FormGroup, Icon, IconName, SearchSelect } from '@talos/kyoko';

interface CustomerUserLayoutDropdownProps {
  layoutType?: CustomerUserConfigLayoutType | null;
  setLayoutType: (layoutType?: CustomerUserConfigLayoutType) => void;
}
export interface LayoutItem {
  displayName: string;
  subtext?: string;
  value?: CustomerUserConfigLayoutType;
}

function getLabel(item: LayoutItem) {
  return item.displayName;
}

const layoutItems: LayoutItem[] = [
  {
    displayName: 'Default',
    subtext: 'Users with the Default layout will inherit their layout from the whitelabel UI configuration.',
  },
  {
    displayName: 'Trading Layout',
    value: CustomerUserConfigLayoutType.TradingLayout,
  },
  {
    displayName: 'RFQ Layout',
    value: CustomerUserConfigLayoutType.SimpleRFQLayout,
  },
];

export const DEFAULT_LAYOUT_ITEM = layoutItems[0];

export function CustomerUserLayoutDropdown({ layoutType, setLayoutType }: CustomerUserLayoutDropdownProps) {
  const layoutItem = layoutItems.find(i => i.value === layoutType) ?? DEFAULT_LAYOUT_ITEM;
  return (
    <FormGroup label="Layout" controlId="layout">
      <SearchSelect
        w="100%"
        selection={layoutItem}
        options={layoutItems}
        getLabel={getLabel}
        onChange={layoutItem => setLayoutType(layoutItem?.value)}
        data-testid="layout"
      />
      {layoutItem?.subtext && (
        <Box>
          <Icon icon={IconName.InformationCircle} /> {layoutItem.subtext}
        </Box>
      )}
    </FormGroup>
  );
}
