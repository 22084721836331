import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useCurrenciesContext } from '../../../../contexts';
import { useIntl } from '../../../../hooks';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  currency: {
    defaultMessage: 'Currency',
    id: 'Filters.FilterBuilder.filters.currency',
  },
});

/**
 * This hook creates the filter property definition for Currencies.
 *
 * @returns Filter Property definition to use for Currency properties.
 */
export function useCurrenciesFilter() {
  const { formatMessage } = useIntl();
  const { currenciesList } = useCurrenciesContext();

  return useMemo(
    () =>
      ({
        key: 'Currencies',
        label: formatMessage(messages.currency),
        icon: IconName.CurrencyDollar,
        options: currenciesList.map(c => c.Symbol),
        getOptionLabel: option => option,
      } as const satisfies FilterableProperty),
    [currenciesList, formatMessage]
  );
}
