import { IconName, type FilterableProperty } from '@talos/kyoko';
import { useMemo } from 'react';
import { prettifySubAccount, useSubAccounts } from '../../providers/SubAccountsContext';

interface UseSubAccountsFilterParams {
  /**
   * Whether or not to include Sub Account Rollups in the options.
   * When true, will also group and add a description to each option.
   * Defaults to false.
   */
  includeRollups?: boolean;
}

/**
 * This hook creates the filter property definition for Sub Accounts properties.
 * This filter will filter on sub accounts with type Book and Rollup
 *
 * @returns Filter Property definition to use for Sub Accounts filters
 */
export function useSubAccountsFilter({ includeRollups = false }: UseSubAccountsFilterParams) {
  const { allSubAccounts, subAccountsByName, allSubAccountBooks } = useSubAccounts();
  const subAccountsToUse = includeRollups ? allSubAccounts : allSubAccountBooks;

  return useMemo(
    () => ({
      ...({
        key: 'SubAccounts',
        label: includeRollups ? 'Sub Account / Rollup' : 'Sub Account',
        icon: IconName.Users,
        control: 'multi-select',
        options: subAccountsToUse?.map(sa => sa.Name) ?? [],
        getOptionLabel: (subAccountName: string) => prettifySubAccount(subAccountName, subAccountsByName),
        getOptionDescription: includeRollups
          ? (subAccountName: string) => subAccountsByName?.get(subAccountName)?.Type ?? 'Unknown'
          : undefined,
        getOptionGroup: includeRollups
          ? (subAccountName: string) => subAccountsByName?.get(subAccountName)?.Type ?? 'Unknown'
          : undefined,
        dropdownOptions: {
          dropdownWidth: '300px',
          maxHeight: 300,
        },
      } as const satisfies FilterableProperty),
    }),
    [subAccountsToUse, subAccountsByName, includeRollups]
  );
}
