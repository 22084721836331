import { useTheme } from 'styled-components';

import {
  ConnectionModeEnum,
  ConnectionStatusIndicator,
  MarketLogo,
  MarketTypeEnum,
  NavButton,
  StatusIndicator,
  Tooltip,
  useConnectionStatus,
  useMarketAccountsContext,
  type FormControlSizes,
  type Market,
  type MarketAccount,
  type MarketCredential,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { MarketItem, MarketTitle, Status, StatusTitle } from './styles';

interface MarketConfigProps {
  formControlSize: FormControlSizes;
  market: Market;
  marketCredential: MarketCredential;
  canEditCredential: boolean;
}

export const MarketCredentialItem = ({
  market,
  marketCredential,
  formControlSize,
  canEditCredential,
  ...props
}: MarketConfigProps) => {
  const { backgroundPrimary, colorTextSubtle } = useTheme();
  const connectionStatus = useConnectionStatus(marketCredential);

  const { marketAccountsByCredentialIDByName } = useMarketAccountsContext();

  // Only show for Dealers and Exchanges as providers like Fireblocks have too many Market Accounts
  const marketAccountNamesStr = useMemo(() => {
    if ([MarketTypeEnum.Dealer, MarketTypeEnum.Exchange].includes(market.Type)) {
      const mktAccsMap =
        marketAccountsByCredentialIDByName.get(marketCredential.CredentialID) ?? new Map<string, MarketAccount>();
      return [...mktAccsMap.keys()].join(',');
    }

    return undefined;
  }, [market.Type, marketCredential.CredentialID, marketAccountsByCredentialIDByName]);

  return (
    <MarketItem {...props}>
      <MarketLogo market={market?.Name} />
      <MarketTitle>
        <Tooltip tooltip={marketAccountNamesStr} placement="right">
          <div>
            <h3>{marketCredential.Label}</h3>
            <p>
              {market.Type}
              {marketCredential.Label !== market.DisplayName && ` - ${market.DisplayName}`}
            </p>
          </div>
        </Tooltip>
      </MarketTitle>
      {connectionStatus && (
        <Status>
          <StatusTitle>Status</StatusTitle>
          <ConnectionStatusIndicator status={connectionStatus?.Status} popoverText={connectionStatus?.ErrorCode} />
        </Status>
      )}
      <Status>
        <StatusTitle>Mode</StatusTitle>
        <StatusIndicator
          text={marketCredential.Mode === ConnectionModeEnum.Up ? 'Enabled' : 'Disabled'}
          color={marketCredential.Mode === ConnectionModeEnum.Up ? backgroundPrimary : colorTextSubtle}
          iconRight
        />
      </Status>
      <NavButton
        // NavButton's `to` still works if disabled={true}
        to={canEditCredential ? `/settings/credentials/${market.Name}/${marketCredential.Name}` : `#`}
        size={formControlSize}
        disabled={!canEditCredential}
      >
        Edit
      </NavButton>
    </MarketItem>
  );
};
