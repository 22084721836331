import type { FilterableProperty } from '@talos/kyoko';
import { IconName, OrdTypeEnum } from '@talos/kyoko';
import { values } from 'lodash';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for OrdType properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for OrdType filters
 */
export function useOrdTypeFilter<TKey extends string = 'OrdTypes', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
) {
  return useMemo(
    () => ({
      ...({
        key: 'OrdTypes' as TKey,
        label: 'Order Type',
        icon: IconName.ArrowUpDown,
        options: values(OrdTypeEnum),
        getOptionLabel: (option: string) => option,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [overrides]
  );
}
