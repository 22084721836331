import {
  IconName,
  PRODUCT_TYPE_TOOLTIP_MAP,
  hasSyntheticProductType,
  prettyFeeMode,
  type ColumnDef,
  type RequiredProperties,
  type Security,
} from '@talos/kyoko';
import { useMemo } from 'react';

export function useMultilegSecmasterColumns({ handleEdit }: { handleEdit: (sec: Security) => any }) {
  const columns = useMemo<ColumnDef<RequiredProperties<Security, 'MultilegDetails'>>[]>(
    () => [
      {
        type: 'text',
        field: 'Symbol',
        sortable: true,
        width: 180,
      },
      {
        type: 'text',
        field: 'DisplaySymbol',
        sortable: true,
      },
      {
        type: 'text',
        field: 'Description',
        sortable: true,
      },
      {
        type: 'custom',
        title: 'Product Type',
        id: 'ProductType',
        sortable: true,
        params: {
          valueGetter: ({ data }: { data: RequiredProperties<Security, 'MultilegDetails'> }) =>
            hasSyntheticProductType(data) ? data.MultilegDetails.SyntheticProductType : data.ProductType,
          tooltipValueGetter: ({ data }: { data?: RequiredProperties<Security, 'MultilegDetails'> }) =>
            hasSyntheticProductType(data) && data?.MultilegDetails.SyntheticProductType
              ? PRODUCT_TYPE_TOOLTIP_MAP[data?.MultilegDetails.SyntheticProductType]
              : data?.MultilegDetails.SyntheticProductType || '',
        },
      },
      {
        type: 'number',
        field: 'MinSizeIncrement',
        sortable: true,
      },
      {
        type: 'number',
        field: 'MinPriceIncrement',
        sortable: true,
      },
      {
        type: 'custom',
        field: 'MultilegDetails.FeeMode',
        title: 'Fee Mode',
        params: {
          valueGetter: ({ data }: { data: RequiredProperties<Security, 'MultilegDetails'> }) => {
            return prettyFeeMode(data.MultilegDetails.FeeMode);
          },
        },
        sortable: true,
      },
      {
        id: '_legOneSymbol',
        title: 'Leg 1 Symbol',
        type: 'custom',
        sortable: false,
        params: {
          valueGetter: ({ data }: { data: RequiredProperties<Security, 'MultilegDetails'> }) => {
            return data.MultilegDetails.Legs[0]?.Symbol;
          },
        },
      },
      {
        id: '_legOneMarkets',
        title: 'Leg 1 Markets',
        type: 'custom',
        sortable: false,
        params: {
          valueGetter: ({ data }: { data: RequiredProperties<Security, 'MultilegDetails'> }) =>
            data.MultilegDetails.Legs[0]?.Markets?.map(m => m.MarketAccount || m.Market).join(', ') || 'All',
        },
      },
      {
        id: '_legTwoSymbol',
        title: 'Leg 2 Symbol',
        type: 'custom',
        sortable: false,
        params: {
          valueGetter: ({ data }: { data: RequiredProperties<Security, 'MultilegDetails'> }) =>
            data.MultilegDetails.Legs[1]?.Symbol,
        },
      },
      {
        id: '_legTwoMarkets',
        title: 'Leg 2 Markets',
        type: 'custom',
        sortable: false,
        params: {
          valueGetter: ({ data }: { data: RequiredProperties<Security, 'MultilegDetails'> }) =>
            data.MultilegDetails.Legs[1]?.Markets?.map(m => m.MarketAccount || m.Market).join(', ') || 'All',
        },
      },
      {
        type: 'iconButton',
        id: 'edit-button',
        pinned: 'right',
        width: 60,
        params: {
          onClick: row => handleEdit(row.node.data),
          icon: IconName.Pencil,
        },
      },
    ],
    [handleEdit]
  );

  return { columns };
}
