import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { MarketOrder } from '@talos/kyoko';

export const getInitialState = (): ManualOrderFillState => ({
  currentOrder: undefined,
});

export const manualOrderFillSlice = createSlice({
  name: 'manualOrderFill',
  initialState: getInitialState(),
  reducers: {
    prime: (state, { payload: order }: PayloadAction<MarketOrder | undefined>) => {
      state.currentOrder = order;
    },
  },
});

export const selectCurrentOrder = (state: { manualOrderFill: ManualOrderFillState }) =>
  state.manualOrderFill.currentOrder;

export const { prime } = manualOrderFillSlice.actions;
export interface ManualOrderFillState {
  currentOrder: MarketOrder | undefined;
}
