import { map, shareReplay, type Observable } from 'rxjs';
import { AggregationsContext } from '../contexts';
import { useObservable, useObservableValue, useStaticSubscription } from '../hooks';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { AggregationType, type Aggregation } from '../types/Aggregation';

const AGGREGATION = 'Aggregation';

function filterAggregation(aggregation: Aggregation): boolean {
  // Only show aggregations with type System and ones not starting with "_"
  if (aggregation.DisplayName?.charAt(0) === '_') {
    return false;
  }

  if (aggregation.AggregationType !== AggregationType.System) {
    return false;
  }

  return true;
}

export const AggregationsProvider = function AggregationsProvider({ children }) {
  const { data: subscription } = useStaticSubscription<Aggregation>({
    name: AGGREGATION,
    tag: AGGREGATION,
  });

  const aggregationsByNameObs = useObservable(
    () =>
      subscription.pipe(
        map(json => ({ ...json, data: json.data.filter(filterAggregation) })),
        wsScanToMap({ getUniqueKey: d => d.Name, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const aggregationsListObs: Observable<Aggregation[]> = useObservable(
    () =>
      aggregationsByNameObs.pipe(
        map(memo => [...memo.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [aggregationsByNameObs]
  );

  const aggregationsByName = useObservableValue(
    () => aggregationsByNameObs,
    [aggregationsByNameObs],
    new Map<string, Aggregation>()
  );
  const aggregationsList = useObservableValue(() => aggregationsListObs, [aggregationsListObs], [] as Aggregation[]);

  return (
    <AggregationsContext.Provider
      value={{ aggregationsByNameObs, aggregationsListObs, aggregationsByName, aggregationsList }}
    >
      {children}
    </AggregationsContext.Provider>
  );
};
