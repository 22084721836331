import {
  IndicatorBadge,
  InlineFormattedNumber,
  SideEnum,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  isOption,
  toBigWithDefault,
  useCurrency,
  useSecuritiesContext,
  type CardProps,
  type Order,
  type OrderAnalytic,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import { useMemo } from 'react';
import { MakerTakerChart } from './MakerTakerChart';
import { Tables } from './styles';

export function TradingCardContent({
  latestAnalytics,
  order,
  leg: propsLeg,
}: {
  order: Order;
  latestAnalytics: Record<number, OrderAnalytic>;
  leg?: number;
} & CardProps) {
  const { securitiesBySymbol } = useSecuritiesContext();
  const isMultileg = order.LegSummary && propsLeg !== undefined;
  // Analytics for non-multileg are accessed by indexing leg 1.
  // And legs are displayed 1 and 2, but indexed 0 and 1
  const leg = isMultileg ? propsLeg - 1 : 1;

  const orderAnalytics = latestAnalytics[propsLeg ?? 1];
  const { maker, taker } = useMemo(() => {
    let maker = 0;
    let taker = 0;
    if (orderAnalytics) {
      const { CumQty, CrossingFilledQty } = orderAnalytics;
      if (CumQty != null && !Big(CumQty).eq(0) && CrossingFilledQty != null) {
        maker = Big(CumQty).minus(CrossingFilledQty).div(CumQty).toNumber();
        taker = Big(CrossingFilledQty).div(CumQty).toNumber();
      }
    }
    return {
      maker,
      taker,
    };
  }, [orderAnalytics]);

  const currentLegSummary = useMemo(() => {
    return order.LegSummary?.find(legSummary => legSummary.LegIndex === leg);
  }, [leg, order.LegSummary]);

  const security = securitiesBySymbol.get(order.Symbol);
  const bps: string = useMemo(() => {
    if (!currentLegSummary) {
      return getBps(order, security);
    } else {
      return getBps({ ...currentLegSummary, OrderQty: currentLegSummary.CumQty as string }, security);
    }
  }, [currentLegSummary, order, security]);

  const cumFee = useMemo(() => {
    if (isMultileg) {
      return currentLegSummary?.CumFee;
    }
    return order.CumFee;
  }, [currentLegSummary?.CumFee, isMultileg, order.CumFee]);
  const feeCurrency = useCurrency(isMultileg ? currentLegSummary?.FeeCurrency : order.FeeCurrency);

  if (!orderAnalytics) {
    return null;
  }

  const isCumFeeZero = Big(cumFee ?? 0).eq(0);
  return (
    <>
      <Tables mb="spacingDefault">
        <Table>
          <Tbody>
            <Tr>
              {isMultileg ? <Th>Leg</Th> : null}
              <Th>Fees</Th>
              <Th align="right">Amount</Th>
              <Th align="right">BPS</Th>
            </Tr>
            <Tr>
              {isMultileg ? (
                <Td width="10%">
                  <IndicatorBadge children={leg + 1} />
                </Td>
              ) : null}
              <Td width="50%">Exchange</Td>
              <Td align="right" width="25%">
                <InlineFormattedNumber
                  round
                  number={cumFee}
                  increment={feeCurrency?.DefaultIncrement}
                  currency={feeCurrency?.Symbol}
                />
              </Td>
              <Td align="right" width="25%">
                {isCumFeeZero ? 'N/A' : <InlineFormattedNumber number={bps} increment="0.01" />}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Tables>
      <MakerTakerChart maker={maker} taker={taker} />
    </>
  );
}

// https://talostrading.atlassian.net/browse/UI-2839
function getBps(
  { Side, AvgPx, AvgPxAllIn, OrderQty, CumFee }: Pick<Order, 'Side' | 'AvgPx' | 'AvgPxAllIn' | 'OrderQty' | 'CumFee'>,
  security: Security | undefined
): string {
  if (
    AvgPx === undefined ||
    toBigWithDefault(AvgPx, 0).eq(0) ||
    AvgPxAllIn === undefined ||
    toBigWithDefault(AvgPxAllIn, 0).eq(0)
  ) {
    return '';
  }

  // https://talostrading.atlassian.net/browse/UI-3891
  if (isOption(security) && security?.BaseCurrency === security?.QuoteCurrency) {
    return toBigWithDefault(CumFee, 0)
      .div(OrderQty || 1)
      .mul(10_000)
      .toFixed(2);
  }

  if (Side === SideEnum.Sell) {
    return Big(Big(Big(AvgPx).minus(AvgPxAllIn)).div(AvgPx || 1))
      .mul(10_000)
      .toFixed(2);
  } else if (Side === SideEnum.Buy) {
    return Big(Big(Big(AvgPxAllIn).minus(AvgPx)).div(AvgPx || 1))
      .mul(10_000)
      .toFixed(2);
  }

  return '';
}
