import { useCallback, useMemo, useState } from 'react';

import {
  ACTION,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  BlotterTable,
  BlotterTableFilters,
  Box,
  Button,
  ButtonVariants,
  CUSTOMER_ADDRESS,
  FormControlSizes,
  IconName,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  filterExistsAndExcludes,
  useAccordionFilterBuilder,
  useDisclosure,
  useDynamicCallback,
  usePersistedBlotterTable,
  usePortal,
  useWSFilterPipe,
  useWsBlotterTable,
  type Column,
  type ColumnDef,
  type ICustomerAddress,
  type ICustomerRoutingInfo,
} from '@talos/kyoko';
import type { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { useRoleAuth } from 'hooks';
import { useCustomersContext } from 'hooks/useCustomer';
import { keys } from 'lodash';
import { useBlotterState } from 'providers/BlottersContext';
import { AddNewAddressDialog } from './AddNewAddressDialog';
import { useCustomerAddressesFilter } from './useCustomerAddressesFilter';

function getCustomerAddressKey(ca: ICustomerAddress) {
  return ca.CustomerAddressID;
}

const BLOTTER_ID = 'dealer-customer-addresses';

export function CustomerAddresses() {
  const { isAuthorized } = useRoleAuth();

  const [routingInfoMap] = useState<Map<string, ICustomerRoutingInfo>>(new Map());
  const { getCustomerWithdrawAddress } = useCustomersContext();

  const RevealRoutingInfoRenderer = useDynamicCallback(
    ({ data, value, api }: ICellRendererParams<ICustomerAddress>) => {
      if (value != null || data == null) {
        return value;
      }

      const handleOnReveal = () => {
        getCustomerWithdrawAddress(data.CustomerAddressID).then(res => {
          const firstRoutingInfo = res.data.at(0)?.RoutingInfo;
          routingInfoMap.set(data.CustomerAddressID, {
            WalletAddress: firstRoutingInfo?.WalletAddress ?? '* No Wallet Address *',
            Memo: firstRoutingInfo?.Memo ?? '* No Memo *',
            DestinationTag: firstRoutingInfo?.DestinationTag ?? '* No Destination Tag *',
          });
          api.refreshCells({ columns: ['WalletAddress', 'Memo'] });
        });
      };

      return (
        <Button
          data-testid="reveal-routing-info-button"
          startIcon={IconName.EyeShow}
          size={FormControlSizes.Small}
          onClick={handleOnReveal}
        >
          Reveal
        </Button>
      );
    }
  );

  const columns = useMemo<Column[]>(
    () =>
      [
        { field: 'Status', type: 'addressStatus' },
        { field: 'Timestamp', type: 'date', sortable: true, sort: '-' },
        { field: 'AddressType', type: 'transactionType', width: 150, sortable: true },
        { field: 'Currency', type: 'currency', width: 150 },
        { field: 'Name', type: 'text' },
        { field: 'CustomerAddressID', type: 'text', hide: true },
        { field: 'Counterparty', type: 'counterparty' },
        { field: 'MarketAccount', type: 'marketAccountSourceAccountID', hide: true },
        { field: 'CustomerUser', type: 'text', hide: true },
        {
          title: 'Wallet Address',
          id: 'WalletAddress',
          type: 'custom',
          params: {
            cellRenderer: RevealRoutingInfoRenderer,
            valueGetter: ({ data }: ValueGetterParams<ICustomerAddress>) =>
              data && routingInfoMap.get(data.CustomerAddressID)?.WalletAddress,
          },
        },
        {
          title: 'Memo',
          id: 'Memo',
          type: 'custom',
          params: {
            cellRenderer: RevealRoutingInfoRenderer,
            valueGetter: ({ data }: ValueGetterParams<ICustomerAddress>) =>
              data && routingInfoMap.get(data.CustomerAddressID)?.Memo,
          },
        },
      ] satisfies ColumnDef<ICustomerAddress>[],
    [RevealRoutingInfoRenderer, routingInfoMap]
  );

  const { filterValueCustomerAddresses, setFilterValueCustomerAddresses } = useBlotterState();

  const persistedBlotterTable = usePersistedBlotterTable(BLOTTER_ID, { columns });

  const filterResults = useCustomerAddressesFilter({
    persistedBlotterTable,
  });

  const rxjsFilterFunc = useCallback(
    (data: ICustomerAddress) => {
      if (filterExistsAndExcludes(filterResults.filter, 'AddressRoutingTypes', data, 'AddressRoutingType')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'AddressTypes', data, 'AddressType')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'Counterparties', data, 'Counterparty')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'Currencies', data, 'Currency')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'Statuses', data, 'Status')) {
        return false;
      }

      return true;
    },
    [filterResults.filter]
  );

  const filterPipe = useWSFilterPipe({ getUniqueKey: getCustomerAddressKey, filterFunc: rxjsFilterFunc });

  const blotterTable = useWsBlotterTable<{ name: typeof CUSTOMER_ADDRESS; tag: string }, ICustomerAddress>({
    initialSort: persistedBlotterTable.initialSort,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: persistedBlotterTable.columns,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    initialRequest: {
      name: CUSTOMER_ADDRESS,
      tag: 'CustomerAddresses',
    },
    rowID: 'CustomerAddressID',
    pipe: filterPipe,
    quickSearchParams: {
      filterText: filterValueCustomerAddresses,
    },
  });

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: keys(filterResults.filter).length > 0 },
    filterBuilderProps: filterResults.filterBuilderProps,
  });

  const dialog = useDisclosure();

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  const headerActions = useMemo(() => {
    return (
      <PanelActions>
        <Box ref={filtersContainerRef} />
        <Button
          size={FormControlSizes.Small}
          startIcon={IconName.Plus}
          onClick={() => dialog.open()}
          variant={ButtonVariants.Positive}
          disabled={!isAuthorized(ACTION.DEALER_TRADING)}
          data-testid="customer-addresses-new-address-button"
        >
          New Customer Address
        </Button>
      </PanelActions>
    );
  }, [filtersContainerRef, isAuthorized, dialog]);

  return (
    <Panel>
      <PanelHeader>
        <h2>Customer Addresses</h2>
        {headerActions}
      </PanelHeader>
      <PanelContent>
        <BlotterTableFilters
          {...filterBuilderAccordion}
          quickFilterText={filterValueCustomerAddresses}
          onQuickFilterTextChanged={setFilterValueCustomerAddresses}
        />
        <BlotterTable {...blotterTable} />
      </PanelContent>
      <AddNewAddressDialog {...dialog} />
    </Panel>
  );
}
