import {
  Box,
  IconName,
  MultiSelectCheckboxDropdown,
  useMarketAccountsContext,
  type FilterableSelectProperty,
} from '@talos/kyoko';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo } from 'react';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../stateManagement/portfolioViewLayoutSlice.hooks';

interface Item {
  key: string;
  itemId: string;
  label: string;
}

const { changeMarketAccountIds } = getPortfolioViewActions();
export const PMSMarketAccountSelector = () => {
  const { selectedMarketAccountIds } = usePortfolioViewStateSelector();
  const { marketAccountsList, isMarketAccountActive } = useMarketAccountsContext();
  const dispatch = useAppStateDispatch();

  const marketAccountOptions = useMemo(() => {
    const result = marketAccountsList
      .filter(item => isMarketAccountActive(item))
      .map((item): Item => {
        return {
          key: item.Name,
          itemId: item.Name,
          label: item.DisplayName ?? item.Name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return result;
  }, [isMarketAccountActive, marketAccountsList]);

  const selections = useMemo(() => {
    return marketAccountOptions.filter(option => selectedMarketAccountIds?.includes(option.itemId));
  }, [marketAccountOptions, selectedMarketAccountIds]);
  const property = useMemo(() => {
    const updatedProperty: FilterableSelectProperty<string, Item> = {
      key: 'marketAccount',
      label: 'Market Account',
      options: marketAccountOptions,
      getOptionLabel: (option: Item) => option.label,
      matchThreshold: 100,
      icon: IconName.Identification,
    };
    return updatedProperty;
  }, [marketAccountOptions]);

  return (
    <Box w="350px" data-testid="market-account-select">
      <MultiSelectCheckboxDropdown<Item>
        prefix="Market Account"
        defaultLabel="All Market Accounts"
        property={property}
        selections={selections}
        onSelectionsChange={selections => {
          dispatch(changeMarketAccountIds(selections.map(selection => selection.itemId)));
        }}
      />
    </Box>
  );
};
