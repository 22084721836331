import { CustomerSettlementPerspectiveEnum, formattedDate, type SettlementPreview } from '@talos/kyoko';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useCustomersByName } from '../../../../../hooks/useCustomer';
import type { SettlementOption } from '../../useTradeSettlementRequests';

interface UseCustomerSettlementDetailsParams {
  preview: SettlementPreview;
  settlementOption: SettlementOption;
}

interface UseCustomerSettlementDetailsOutput {
  /**
   * The label to be used for the counterparty of these settlement details. If the settlement preview is from the perspective of the customer,
   * this will say "Dealer". If it is from the perspective of the Dealer, it will be the customer's display name. If we are unable to resolve
   * the customer, "Client" will be returned.
   */
  counterpartyLabel: string;
  /**
   * The label for ourselves, which will depend on what the perspective is of the settlement. If the perspective is Dealer, the label will be "Dealer".
   * If the perspective is customer, the returned label will be the customers display name (or "Client" if the customer cannot be resolved)
   */
  ownerLabel: string;
  /** The label of the customer the settlement is with. If none can be resolved, will be "Client". */
  customerLabel: string;
  /**
   * A grid of details to be used in exports. This grid is a list of [label, display value] tuples to be rendered as-is.
   * For example [["Customer", "Some Name"], ["Trades", "5"]]
   */
  gridDetails: [string, string][];
}

/**
 * Computes some Settlement Details given a settlement preview and the selected settlement option.
 *
 * Returns an array of arrays with display values for rendering / exporting.
 */
export const useCustomerSettlementDetails = ({
  preview,
  settlementOption,
}: UseCustomerSettlementDetailsParams): UseCustomerSettlementDetailsOutput => {
  const customersByName = useCustomersByName();
  const perspective = preview.perspective;

  // Try to resolve a trades time range. If we can resolve it, it will be shown in the modal.
  const tradesTimeRange = useMemo(() => {
    if (settlementOption.type !== 'TradeIDs') {
      return undefined;
    }

    const trades = settlementOption.settleableTrades;
    if (trades.length < 2) {
      return undefined;
    }

    const sortedTradeDates = trades
      .map(trade => new Date(trade.TransactTime))
      .sort((a, b) => a.getTime() - b.getTime());
    return `${timeRangeDateFormatter(sortedTradeDates.at(0))} - ${timeRangeDateFormatter(
      sortedTradeDates.at(-1),
      true
    )}`;
  }, [settlementOption]);

  const customerName = useMemo(() => {
    if (settlementOption.type === 'Counterparty') {
      return settlementOption.Counterparty;
    }

    if (settlementOption.type === 'TradeIDs') {
      return settlementOption.settleableTrades.at(0)?.Counterparty;
    }

    return undefined;
  }, [settlementOption]);

  const customer = customerName ? customersByName?.get(customerName) : undefined;
  const customerLabel = customer?.DisplayName ?? customerName ?? 'Client';

  // The counterparty is dependent on the perspective we're in. If we are the customer, our counterparty is the dealer, and vice versa.
  const counterpartyLabel = perspective === CustomerSettlementPerspectiveEnum.Customer ? 'Dealer' : customerLabel;

  // The "owner" is whoever this report is from the perspective of.
  const ownerLabel = perspective === CustomerSettlementPerspectiveEnum.Dealer ? 'Dealer' : customerLabel;

  // Try to resolve a trade count. If we can resolve it, it will be shown in the modal.
  const tradesCount = preview?.IncludingTrades.length;

  // Try to resolve an OrderID. If we can resolve it, it will be shown in the modal.
  const orderID = settlementOption.type === 'OrderIDs' ? settlementOption.OrderIDs.at(0) : undefined;

  const gridDetails: [string, string][] = useMemo(
    () =>
      compact([
        counterpartyLabel != null && ['Counterparty', counterpartyLabel],
        tradesCount != null && ['Total Trades', tradesCount.toString()],
        tradesTimeRange != null && ['Trades time range', tradesTimeRange],
        orderID != null && ['Order ID', orderID],
      ]),
    [counterpartyLabel, tradesTimeRange, tradesCount, orderID]
  );

  return { counterpartyLabel, gridDetails, ownerLabel, customerLabel };
};

function timeRangeDateFormatter(date: Date | undefined, showTimezone?: boolean): string {
  return formattedDate(date, `{d} {Mon} {yyyy}, {HH}:{mm}${showTimezone ? ' (UTC{ZZ})' : ''}`);
}
