import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { AppState } from 'providers/AppStateProvider/types';
import type { OMSView } from './OMSView';
import type { OMSState } from './types';

const initialState: OMSState = {
  openedView: undefined,
};

export const OMSSlice = createSlice({
  name: 'oms',
  initialState,
  reducers: {
    openView: (state, action: PayloadAction<OMSView>) => {
      state.openedView = action.payload;
    },
    closeView: state => {
      state.openedView = undefined;
    },
  },
});

export const selectView = (state: AppState) => state.OMS.openedView;

export const { openView, closeView } = OMSSlice.actions;
