import type { KeysBeginningWith } from '@talos/kyoko';
import { createContext, useContext } from 'react';
import { OrderStrategiesEnum } from '../tokens/orderStrategy';
import type { IDDHOrderDefault } from './AppConfigProvider';

interface OrderEntryContextProps {
  lastStrategyUsed: OrderStrategiesEnum;
  setLastStrategyUsed: (lastStrategy: OrderStrategiesEnum) => void;
  lastOrderFormUsed: string;
  setLastOrderFormUsed: (lastOrderFormUsed: string) => void;

  // DDH Order form defaults
  ddhDefaults: IDDHOrderDefault[];
  ddhDefaultsByID: Map<string, IDDHOrderDefault>;
  saveDDHDefaults: (ddhDefault: IDDHOrderDefault) => void;
}

export const DefaultOrderEntryConfig: Omit<
  OrderEntryContextProps,
  KeysBeginningWith<OrderEntryContextProps, 'set'> | 'saveDDHDefaults'
> = {
  lastStrategyUsed: OrderStrategiesEnum.Limit,
  lastOrderFormUsed: '',
  ddhDefaults: [],
  ddhDefaultsByID: new Map(),
} satisfies Omit<OrderEntryContextProps, KeysBeginningWith<OrderEntryContextProps, 'set'> | 'saveDDHDefaults'>;

export const DefaultOrderEntryMethods = {
  setLastStrategyUsed: () => {},
  setLastOrderFormUsed: () => {},
  saveDDHDefaults: () => {},
} satisfies Pick<OrderEntryContextProps, KeysBeginningWith<OrderEntryContextProps, 'set'> | 'saveDDHDefaults'>;

export const OrderEntryContext = createContext({
  ...DefaultOrderEntryConfig,
  ...DefaultOrderEntryMethods,
} as OrderEntryContextProps);
OrderEntryContext.displayName = 'OrderEntryContext';
export const useOrderEntry = () => useContext(OrderEntryContext);
