import { LoaderSizes } from '@talos/kyoko';
import { Square, Wrapper } from './styles';

export { LoaderWrapper, Overlay } from './styles';

export const Loader = ({ size = LoaderSizes.DEFAULT, ...props }) => {
  return (
    <Wrapper size={size} data-testid="loader" {...props}>
      <Square />
      <Square />
      <Square />

      <Square />
      <Square />
      <Square />

      <Square />
      <Square />
      <Square />
    </Wrapper>
  );
};

Loader.defaultProps = {
  size: LoaderSizes.DEFAULT,
};
