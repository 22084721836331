import {
  ACTION,
  Button,
  ButtonVariants,
  DecisionStatusEnum,
  FormControlSizes,
  IconButton,
  IconName,
  MixpanelEvent,
  NotificationVariants,
  OrdTypeEnum,
  TimeInForceEnum,
  Toasts,
  calcDateFromDuration,
  canParseAsDuration,
  formatToBackendDuration,
  logger,
  parseDate,
  parseDuration,
  useMixpanel,
  useToasts,
  wait,
  type SideEnum,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { has } from 'lodash';
import { OrgConfigurationKey, useStrategies, useSubAccounts } from 'providers';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import type { OMSForm } from 'providers/OMSContext.types';
import { useOrders } from 'providers/OrdersProvider';
import { useOrgConfiguration } from 'providers/OrgConfigurationProvider';
import type { SendOrderArgs } from 'providers/orders.types';
import { memo, useCallback, useEffect, useRef, useState, type ChangeEvent, type DragEvent } from 'react';
import { v1 as uuid } from 'uuid';
import { closeView } from '../OMSSlice';
import { Actions, BulkOrderErrors, OmsToasts, OrderImportContainer, Top, TopClose, TopTitle, Wrapper } from '../styles';
import { OrderImportHelp } from './OrderImportHelp';
import { OrderImportProvider, useOrderImportContext } from './OrderImportProvider';
import { OrderValidationRow } from './OrderValidationRow';

export const OrderImport = memo(function OrderImport() {
  return (
    <OrderImportProvider>
      <OrderImportDriver />
    </OrderImportProvider>
  );
});

/**  How long the UI will wait between calling NewOrderSingle to not time out with the OMS */
export const PLACE_ORDER_THROTTLE_INTERVAL = 400;

export const OrderImportDriver = memo(function OrderImportDriver() {
  const mixpanel = useMixpanel();
  const { getConfig } = useOrgConfiguration();
  const { parseOrders, clear, orders, hasErrors, errors, fileErrors } = useOrderImportContext();
  const ordersService = useOrders();
  const { tradableSubAccounts } = useSubAccounts();
  const dispatch = useAppStateDispatch();
  const { isAuthorized } = useRoleAuth();
  const [importFile, setImportFile] = useState<File | null>(null);
  const [importFileName, setImportFileName] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toasts, add: addToast, remove: removeToast } = useToasts();
  const [inFlightOrder, setInFlightOrder] = useState<OMSForm | null>(null);

  const [hasFileHovering, setHasFileHovering] = useState(false);

  const { strategiesList: strategies } = useStrategies();

  const handleDragOver = useCallback((ev: DragEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    ev.preventDefault(); // Required to get the onDrop event to fire correctly
    setHasFileHovering(true);
  }, []);

  const handleDragLeave = useCallback((ev: DragEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    setHasFileHovering(false);
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();

      setImportFile(e.dataTransfer.files[0]);
      setHasFileHovering(false);
    },
    [setImportFile]
  );

  const handleLoad = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setImportFile(e.target?.files ? e.target.files[0] : null);
    },
    [setImportFile]
  );

  const handleClear = useCallback(() => {
    setImportFile(null);
    clear();
  }, [clear]);

  // Force the file input to actually reupload files that have changed but have the same name
  const handleClick = useCallback(() => setImportFileName(''), []);

  const fileSelectorRef = useRef<HTMLInputElement | null>(null);
  const handleSelectFile = useCallback(() => {
    mixpanel.track(MixpanelEvent.UploadFileForOrderImport);
    if (fileSelectorRef.current) {
      fileSelectorRef.current.click();
    }
  }, [mixpanel]);

  useEffect(() => {
    if (importFile == null) {
      return;
    }
    parseOrders(importFile);
    setImportFile(null);
  }, [importFile, parseOrders]);

  const formToOrderArgs = useCallback(
    (form: OMSForm): SendOrderArgs => {
      const clOrdID = uuid();

      const isStrategyRequired = form.initialDecisionStatus !== DecisionStatusEnum.Staged;

      const requiredProps = ['ordType', 'orderSide'];
      if (isStrategyRequired) {
        requiredProps.push('strategy');
      }
      requiredProps.forEach((prop: string) => {
        if (!has(form, prop)) {
          throw new Error(`Missing required property: ${prop}`);
        }
      });

      const selectedStrategy = strategies?.find(strat => strat.Name === form.strategy);
      if (isStrategyRequired && !selectedStrategy) {
        throw new Error(`Could not find strategy ${form.strategy}`);
      }

      const parameters = { ...form.parameters };

      if (parameters.endTime && canParseAsDuration(parameters.endTime as string)) {
        if (form.initialDecisionStatus === DecisionStatusEnum.Staged) {
          parameters['duration'] = formatToBackendDuration(parseDuration(parameters['endTime'] as string));
          delete parameters['endTime'];
        } else {
          parameters['endTime'] = calcDateFromDuration(parseDuration(parameters['endTime'] as string), parseDate());
        }
      }

      const data: SendOrderArgs = {
        price: form.ordType !== OrdTypeEnum.Market && form.price ? form.price : undefined,
        symbol: form.symbol,
        clOrdID,
        side: form.orderSide as SideEnum,
        orderQty: form.quantity,
        parameters,
        marketAccounts: form.orderMarketAccounts,
        ordType: form.ordType,
        subAccountAllocations: form.subAccountAllocations?.slice(),
        timeInForce: TimeInForceEnum.GoodTillCancel,
        selectedStrategy,
        allocationValueType: form.allocationValueType,
        subAccounts: tradableSubAccounts,
        useTradeAllocations: false, // trade allocations are not supported through import yet
        orderCurrency: form.orderCurrency, // TODO don't send when non spot
        // for now no expected fill price + quantity
      };

      if (form.group) {
        data.group = form.group;
      }

      if (form.comment) {
        data.comment = form.comment;
      }

      if (form.initialDecisionStatus) {
        data.initialDecisionStatus = form.initialDecisionStatus;
      }

      return data;
    },
    [strategies, tradableSubAccounts]
  );

  const handleClickPlaceOrder = useCallback(async () => {
    setIsSubmitting(true);
    try {
      mixpanel.track(MixpanelEvent.SendBulkOrder);
      for (const order of orders) {
        setInFlightOrder(order);
        const args = formToOrderArgs(order);
        ordersService.send(args);
        await wait(getConfig(OrgConfigurationKey.PlaceOrderThrottleMillis, PLACE_ORDER_THROTTLE_INTERVAL));
      }
    } catch (e: any) {
      logger.error(e);
      addToast({
        text: `Could not submit order: ${e?.message ? `: ${e.message}` : ''}`,
        variant: NotificationVariants.Negative,
        timeout: 5000,
        dismissable: true,
      });
    } finally {
      setIsSubmitting(false);
      setInFlightOrder(null);
      dispatch(closeView()); // TODO... Should wait for exe report to close form
    }
  }, [addToast, dispatch, formToOrderArgs, getConfig, mixpanel, orders, ordersService]);

  return (
    <Wrapper>
      <Top>
        <TopTitle>
          <span>Import Orders</span>
        </TopTitle>
        <div />
        <TopClose>
          <IconButton size={FormControlSizes.Small} ghost icon={IconName.Close} onClick={() => dispatch(closeView())} />
        </TopClose>
      </Top>
      <OrderImportContainer
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        showFileDrop={hasFileHovering}
      >
        {fileErrors.length > 0 && (
          <BulkOrderErrors data-testid="order-import-file-errors">
            {fileErrors.map((error, idx) => {
              return <div key={idx}>{error}</div>;
            })}
          </BulkOrderErrors>
        )}
        {orders.map((order, idx) => (
          <OrderValidationRow
            order={order}
            orderErrors={errors[idx] || {}}
            key={idx}
            isSending={order === inFlightOrder}
          />
        ))}
        {fileErrors.length === 0 && orders.length === 0 && <OrderImportHelp />}
      </OrderImportContainer>
      {toasts?.length > 0 && (
        <OmsToasts>
          <Toasts toasts={toasts} remove={removeToast} />
        </OmsToasts>
      )}
      <input
        type="file"
        data-testid="import-orders-file"
        onClick={handleClick}
        onDrop={handleDrop}
        onChange={handleLoad}
        ref={fileSelectorRef}
        value={importFileName}
        accept="text/csv"
        style={{ visibility: 'hidden' }}
      />

      {fileErrors.length === 0 && orders.length === 0 ? (
        <Actions>
          <Button data-testid="upload-file-button" variant={ButtonVariants.Positive} onClick={handleSelectFile}>
            Upload File
          </Button>
        </Actions>
      ) : (
        <Actions>
          <Button onClick={handleClear} variant={ButtonVariants.Default}>
            Back
          </Button>
          <Button
            disabled={!isAuthorized(ACTION.SUBMIT_ORDER) || isSubmitting || hasErrors}
            type="button"
            onClick={() => isAuthorized(ACTION.SUBMIT_ORDER) && handleClickPlaceOrder()}
            data-testid="order-import-send"
            variant={ButtonVariants.Positive}
          >
            {hasErrors ? 'Fix Errors and Reupload' : `Place ${orders.length} Orders`}
          </Button>
        </Actions>
      )}
    </Wrapper>
  );
});
