import { LookbackOption, cleanupInitialFilterDateRange, type DateRangeFilter } from '@talos/kyoko';
import type { BalancesBlotterShowBy } from 'containers/Blotters/BalancesV2/types';
import type { BalancesFilter } from '../../../../types';
import type { VisualizationVariant } from '../tokens';
import type { MergedBalance, MergedBalanceIndexKeys } from '../types';

export interface TreasuryManagementState {
  /** Whether we're showing by counterparty or by asset currently */
  showBy: BalancesBlotterShowBy;
  /** The state of the Show Unsettled toggle */
  showUnsettled: boolean;
  /** The current mode of visualization */
  visualization: VisualizationVariant;
  /** The current state of "drilling", defined by a path of keys. One "step" is one entry in this array, where one step can be either one level of drilling or several. */
  drillKeys: MergedBalance[MergedBalanceIndexKeys][][];
  /** The current drillKeys, but flattened. Will always be in sync with the drillKeys state. */
  flattenedDrillKeys: MergedBalance[MergedBalanceIndexKeys][];
  /** Currently applied filters */
  filter: BalancesFilter;
  /** Whether or not to receive "0" balances over ws */
  showZeroBalances: boolean;
  /** The date range applied to the request underlying the Historical value chart */
  chartRequestDateRange: DateRangeFilter;
  /**
   * The point in time at which we display your positions / balances. If null, is interpreted as "now".
   * This is an ISO string ready to be used in the subscription, which can also be stored in AppConfig.
   */
  snapshotDate: string | null;
}

export const DEFAULT_TREASURY_MANAGEMENT_STATE: TreasuryManagementState = {
  showBy: 'counterparty',
  showUnsettled: true,
  visualization: 'Value',
  drillKeys: [],
  flattenedDrillKeys: [],
  filter: {},
  showZeroBalances: false,
  chartRequestDateRange: {
    _start: LookbackOption.PastYear,
  },
  snapshotDate: null,
};

/**
 * Returns a hydrated / up to date default treasury management state
 */

export function getDefaultTreasuryManagementState(): TreasuryManagementState {
  return {
    ...DEFAULT_TREASURY_MANAGEMENT_STATE,
    // Since this is a lookback function we need to compute this on-demand as opposed to just cleaning it up in the constant.
    chartRequestDateRange: cleanupInitialFilterDateRange(DEFAULT_TREASURY_MANAGEMENT_STATE.chartRequestDateRange),
  };
}
