import { useCurrency } from '../../hooks';
import {
  CustomerBalanceTransactionStatusEnum,
  CustomerBalanceTransactionTypeEnum,
  type CustomerTransaction,
} from '../../types';
import { InlineFormattedNumber } from '../FormattedNumber';
import { FormattedMessage } from '../Intl';
import { getFriendlyTransferStatus } from '../Status';
import { Text } from '../Text';
import { messages } from './messages';

export const CustomerTransactionLabel = ({ entity }: { entity: CustomerTransaction }) => {
  const currencyInfo = useCurrency(entity.Currency);

  switch (entity.Initiator) {
    // Transaction Label for Initiator=Dealer
    case 'Dealer':
      switch (entity.TransactionType) {
        case CustomerBalanceTransactionTypeEnum.Deposit:
          switch (entity.Status) {
            case CustomerBalanceTransactionStatusEnum.PendingApproval:
              return (
                <Text>
                  <FormattedMessage
                    {...messages.userEnteredTypeForQuantity}
                    values={{
                      quantity: () => (
                        <InlineFormattedNumber
                          number={entity.Quantity}
                          currency={entity.Currency}
                          increment={currencyInfo?.DefaultIncrement}
                        />
                      ),
                      transactionType: entity.TransactionType,
                      user: entity.InitiatorUser,
                    }}
                  />
                </Text>
              );
            case CustomerBalanceTransactionStatusEnum.Completed:
              if (entity.DealerUser) {
                return (
                  <Text>
                    <FormattedMessage
                      {...messages.completedUserApprovedTypeForQuantity}
                      values={{
                        quantity: () => (
                          <InlineFormattedNumber
                            number={entity.Quantity}
                            currency={entity.Currency}
                            increment={currencyInfo?.DefaultIncrement}
                          />
                        ),
                        transactionType: entity.TransactionType,
                        user: entity.DealerUser,
                      }}
                    />
                  </Text>
                );
              }
              return (
                <Text>
                  <FormattedMessage
                    {...messages.completedUserEnteredTypeForQuantity}
                    values={{
                      quantity: () => (
                        <InlineFormattedNumber
                          number={entity.Quantity}
                          currency={entity.Currency}
                          increment={currencyInfo?.DefaultIncrement}
                        />
                      ),
                      transactionType: entity.TransactionType,
                      user: entity.InitiatorUser,
                    }}
                  />
                </Text>
              );
            case CustomerBalanceTransactionStatusEnum.Rejected:
              return (
                <Text>
                  <FormattedMessage
                    {...messages.userRejectedTypeForQuantity}
                    values={{
                      quantity: () => (
                        <InlineFormattedNumber
                          number={entity.Quantity}
                          currency={entity.Currency}
                          increment={currencyInfo?.DefaultIncrement}
                        />
                      ),
                      transactionType: entity.TransactionType,
                      user: entity.DealerUser,
                    }}
                  />
                </Text>
              );
            default:
              return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
          }
        case CustomerBalanceTransactionTypeEnum.Withdrawal:
          switch (entity.Status) {
            case CustomerBalanceTransactionStatusEnum.PendingTransfer:
              return (
                <Text>
                  <FormattedMessage
                    {...messages.userEnteredTypeForQuantity}
                    values={{
                      quantity: () => (
                        <InlineFormattedNumber
                          number={entity.Quantity}
                          currency={entity.Currency}
                          increment={currencyInfo?.DefaultIncrement}
                        />
                      ),
                      transactionType: entity.TransactionType,
                      user: entity.InitiatorUser,
                    }}
                  />
                </Text>
              );
            case CustomerBalanceTransactionStatusEnum.Completed:
              if (entity.TreasuryUser) {
                return (
                  <Text>
                    <FormattedMessage
                      {...messages.completedUserConfirmedTransferForQuantity}
                      values={{
                        quantity: () => (
                          <InlineFormattedNumber
                            number={entity.Quantity}
                            currency={entity.Currency}
                            increment={currencyInfo?.DefaultIncrement}
                          />
                        ),
                        user: entity.TreasuryUser,
                      }}
                    />
                  </Text>
                );
              }
              return (
                <Text>
                  <FormattedMessage
                    {...messages.completedTypeForQuantity}
                    values={{
                      quantity: () => (
                        <InlineFormattedNumber
                          number={entity.Quantity}
                          currency={entity.Currency}
                          increment={currencyInfo?.DefaultIncrement}
                        />
                      ),
                      transactionType: entity.TransactionType,
                    }}
                  />
                </Text>
              );
            case CustomerBalanceTransactionStatusEnum.Rejected:
              if (entity.TreasuryUser) {
                return (
                  <Text>
                    <FormattedMessage
                      {...messages.userRejectedTypeRequest}
                      values={{
                        transactionType: entity.TransactionType,
                        user: entity.TreasuryUser,
                      }}
                    />
                  </Text>
                );
              }
              return (
                <Text>
                  <FormattedMessage
                    {...messages.userEnteredTypeForQuantity}
                    values={{
                      quantity: () => (
                        <InlineFormattedNumber
                          number={entity.Quantity}
                          currency={entity.Currency}
                          increment={currencyInfo?.DefaultIncrement}
                        />
                      ),
                      transactionType: entity.TransactionType,
                      user: entity.InitiatorUser,
                    }}
                  />
                </Text>
              );
            default:
              return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
          }
        default:
          return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
      }

    // Transaction Label for Initiator=Customer
    case 'Customer':
      switch (entity.Status) {
        case CustomerBalanceTransactionStatusEnum.PendingApproval:
          return (
            <Text>
              <FormattedMessage
                {...messages.userApprovedTypeForQuantity}
                values={{
                  quantity: () => (
                    <InlineFormattedNumber
                      number={entity.Quantity}
                      currency={entity.Currency}
                      increment={currencyInfo?.DefaultIncrement}
                    />
                  ),
                  transactionType: entity.TransactionType,
                  user: 'System',
                }}
              />
            </Text>
          );
        case CustomerBalanceTransactionStatusEnum.PendingTransfer:
          return (
            <Text>
              <FormattedMessage
                {...messages.userApprovedTypeForQuantity}
                values={{
                  quantity: () => (
                    <InlineFormattedNumber
                      number={entity.Quantity}
                      currency={entity.Currency}
                      increment={currencyInfo?.DefaultIncrement}
                    />
                  ),
                  transactionType: entity.TransactionType,
                  user: entity.DealerUser,
                }}
              />
            </Text>
          );
        case CustomerBalanceTransactionStatusEnum.Completed:
          return (
            <Text>
              <FormattedMessage
                {...messages.completedUserConfirmedTransfer}
                values={{
                  user: entity.TreasuryUser,
                }}
              />
            </Text>
          );
        case CustomerBalanceTransactionStatusEnum.Rejected:
          return (
            <Text>
              <FormattedMessage
                {...messages.userRejectedTypeRequest}
                values={{
                  transactionType: entity.TransactionType,
                  user: entity.TreasuryUser || entity.DealerUser || 'System',
                }}
              />
            </Text>
          );
        default:
          return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
      }

    // Fallback
    default:
      return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
  }
};
