import {
  FormGroup,
  GlobalCustomerUserConfigTradingLayoutArrangements,
  SearchSelect,
  type CustomerUserConfigLayoutType,
} from '@talos/kyoko';
import type { LayoutArrangementItem } from './types';
import { useLayoutArrangementItems } from './useLayoutArrangementItems';

interface CustomerUserLayoutArrangementDropdownProps {
  layoutType?: CustomerUserConfigLayoutType | null;
  layoutArrangement?: string | null;
  setLayoutArrangement: (layoutArrangement?: string) => void;
}

function getLabel(item: LayoutArrangementItem) {
  return item.displayName;
}

export function CustomerUserLayoutArrangementDropdown({
  layoutType,
  layoutArrangement,
  setLayoutArrangement,
}: CustomerUserLayoutArrangementDropdownProps) {
  const layoutArrangementItems = useLayoutArrangementItems(layoutType);
  if (layoutArrangementItems.length === 0) {
    return null;
  }
  const selectedItem = layoutArrangementItems.find(i => i.value === layoutArrangement) ?? layoutArrangementItems[0];
  return (
    <FormGroup label="Layout Arrangement" controlId="layoutArrangement">
      <SearchSelect
        w="100%"
        selection={selectedItem}
        initialSortByLabel={false}
        options={layoutArrangementItems}
        getLabel={getLabel}
        onChange={layoutArrangement =>
          setLayoutArrangement(
            layoutArrangement?.value === GlobalCustomerUserConfigTradingLayoutArrangements.Default
              ? undefined
              : layoutArrangement?.value
          )
        }
        data-testid="layout-arrangement"
      />
    </FormGroup>
  );
}
