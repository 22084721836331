import { useCallback, type ReactNode } from 'react';
import { AccordionTableHeader, Table } from './styles';

export type AccordionTableColumn = {
  label: ReactNode;
  id: string;
  width?: string;
  clickable?: boolean;
};

export type AccordionTableProps = {
  columns: AccordionTableColumn[];
  children: ReactNode;
  columnSpacing?: number;
  onColumnClick?: (id: string) => void;
  stickyHeader?: boolean;
  showHeader?: boolean;
  alignChevron?: 'left' | 'right';
};

export const AccordionTable = ({
  columns,
  children,
  columnSpacing,
  onColumnClick,
  stickyHeader = false,
  showHeader = true,
  alignChevron = 'right',
}: AccordionTableProps) => {
  const handleHeaderClick = useCallback(
    (column: AccordionTableColumn) => {
      if (column.clickable && onColumnClick) {
        onColumnClick(column.id);
      }
    },
    [onColumnClick]
  );

  return (
    <Table columnSpacing={columnSpacing} stickyHeader={stickyHeader}>
      {showHeader && (
        <thead>
          <tr>
            {alignChevron === 'left' && <AccordionTableHeader />}
            {columns.map((column, i) => (
              <AccordionTableHeader
                style={{ width: column.width ?? '' }}
                key={i}
                clickable={column.clickable}
                onClick={() => handleHeaderClick(column)}
              >
                {column.label}
              </AccordionTableHeader>
            ))}
            {alignChevron === 'right' && <AccordionTableHeader />}
          </tr>
        </thead>
      )}
      {children}
    </Table>
  );
};
