import { daysBetween, formattedDay, parseDate, type DateFnInput } from '../../utils';

export interface LoanDurationProps {
  /** Optional start time to use when calculating remaining days until termination */
  timestamp?: DateFnInput;

  /** Formatted termination timestamp string */
  terminationTime: DateFnInput;

  /** Show/hide exact termination date */
  showDate?: boolean;

  /** Show/hide days until termination */
  showDays?: boolean;
}

export function LoanDuration({ timestamp, terminationTime, showDate = false, showDays = true }: LoanDurationProps) {
  if (terminationTime == null) {
    return <>Open term</>;
  }

  if (!(showDate || showDays)) {
    throw new Error('Must show termination date or days until termination.');
  }

  const days = daysBetween(parseDate(timestamp ?? new Date()), parseDate(terminationTime));
  const date = formattedDay(terminationTime);

  let result = '';
  if (showDays && showDate) {
    result = `${days} (${date})`;
  } else if (showDays) {
    result = days;
  } else {
    result = date;
  }

  return <>{result}</>;
}
