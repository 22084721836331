import {
  ACTION,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  Button,
  ButtonVariants,
  FormControlSizes,
  HStack,
  IconName,
  MixpanelEventSource,
  usePortal,
} from '@talos/kyoko';
import { useRoleAuth, useTransfersDrawer } from 'hooks';

export function TransfersFilterRow({ showNewTransfer }: { showNewTransfer: boolean }) {
  const { openTransfersDrawer } = useTransfersDrawer();
  const { isAuthorized } = useRoleAuth();
  const canSubmitTransfer = isAuthorized(ACTION.SUBMIT_TRANSFER);
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  return (
    <HStack gap="spacingSmall">
      {canSubmitTransfer && showNewTransfer && (
        <Button
          startIcon={IconName.Plus}
          variant={ButtonVariants.Default}
          size={FormControlSizes.Small}
          onClick={() => openTransfersDrawer({ source: MixpanelEventSource.TransferBlotter })}
        >
          New Transfer
        </Button>
      )}
      <Box ref={filtersContainerRef} />
    </HStack>
  );
}
