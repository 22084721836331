import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  MixpanelEvent,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  columnToColumnState,
  tabLabelerEnumerated,
  useDynamicCallback,
  useMixpanel,
  usePersistedTabs,
  usePortal,
  useTabs,
  type Column,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import { useState } from 'react';
import type { BalancesFilter } from '../../../types';
import { BalancesV2Blotter } from './BalancesV2Blotter';
import { defaultBalancesTabs } from './defaults';
import { BALANCES_V2_BLOTTER_PREFIX } from './tokens';
import type { BalancesV2TabProps } from './types';

const tabLabeler = tabLabelerEnumerated('Balances');

export const BalancesV2 = () => {
  const mixpanel = useMixpanel();
  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);

  const handleSelect = useDynamicCallback(() => setInitialFiltersOpen(false));
  const handleAdd = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.AddTab);
    setInitialFiltersOpen(true);
  });

  const persistedTabs = usePersistedTabs<BalancesV2TabProps>(BALANCES_V2_BLOTTER_PREFIX, {
    defaultInitialItems: defaultBalancesTabs,
    defaultInitialSelectedIndex: 0,
    onSelect: handleSelect,
  });

  const tabs = useTabs<BalancesV2TabProps>({
    ...persistedTabs,
    showAddTab: true,
    tabLabeler,
    onAdd: handleAdd,
    allowClosingLastTab: false,
  });

  const handleCloneTab = useDynamicCallback(
    (
      filter: BalancesFilter,
      columns: Column[],
      rowGroupsOpenedState: RowGroupsOpenedState | undefined,
      showZeroBalances: boolean,
      showTotals: boolean
    ) => {
      tabs.clone(tabs.selectedIndex, {
        defaultColumns: columns.map(columnToColumnState),
        defaultFilter: filter,
        defaultRowGroupsOpened: rowGroupsOpenedState,
        showZeroBalances,
        showTotals,
      });
    }
  );

  const handleUpdateTab = useDynamicCallback((updatedTab: BalancesV2TabProps) => {
    tabs.updateItem(updatedTab);
  });

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <Tabs {...tabs} h="100%" size={TabSize.Large} data-testid="balances-blotter-wrapper">
      <TabList isBordered rightItems={<Box ref={filtersContainerRef} />}>
        {tabs.items.map((tab, idx) => (
          <Tab key={idx} {...tab} />
        ))}
      </TabList>
      <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        {tabs.items.map(tab => {
          return (
            <AccordionGroup key={tab.id}>
              <BalancesV2Blotter
                key={tab.id}
                blotterID={`${BALANCES_V2_BLOTTER_PREFIX}/${tab.id}`}
                tab={tab}
                onUpdateTab={handleUpdateTab}
                onCloneTab={handleCloneTab}
                initialIsOpen={initialFiltersOpen}
              />
            </AccordionGroup>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};
