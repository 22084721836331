import {
  Box,
  IndicatorDotVariants,
  MixpanelEventSource,
  MixpanelSourceProvider,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  useDrawer,
  usePortal,
  type SubAccount,
} from '@talos/kyoko';
import { useState } from 'react';
import { EditSubAccountRollupsDrawer } from './EditSubAccountRollupsDrawer';
import { EditSubAccountsDrawer } from './EditSubAccountsDrawer';
import { SubAccountRollups } from './SubAccountRollups';
import { SubAccounts } from './SubAccounts';
import { SUB_ACCOUNT_SETTINGS_PANEL_ACTIONS_PORTAL_ID } from './types';

type SubAccountTabs = 'Sub Accounts' | 'Sub Account Rollups';
const ALL_TABS: SubAccountTabs[] = ['Sub Accounts', 'Sub Account Rollups'];

export function SubAccountSettings() {
  const [activeTab, setActiveTab] = useState(0);

  const subAccountDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
    onIsOpenChange: opened => {
      if (opened) {
        rollupDrawer.close();
      }
    },
  });

  const rollupDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
    onIsOpenChange: opened => {
      if (opened) {
        subAccountDrawer.close();
      }
    },
  });

  const [selectedSubAccountName, setSelectedSubAccountName] = useState<SubAccount['Name'] | null>(null);

  const { setPortalRef: actionItemsRef } = usePortal(SUB_ACCOUNT_SETTINGS_PANEL_ACTIONS_PORTAL_ID);

  return (
    <>
      <Panel>
        <PanelHeader>
          <h2>Sub Accounts</h2>
          <PanelActions>
            <Box ref={actionItemsRef} />
          </PanelActions>
        </PanelHeader>
        <PanelContent>
          <Tabs w="100%" h="100%" selectedIndex={activeTab} onSelect={setActiveTab} size={TabSize.Large}>
            <TabList isBordered>
              {ALL_TABS.map(name => (
                <Tab showDot={false} dotVariant={IndicatorDotVariants.Primary} label={name} key={name} />
              ))}
            </TabList>
            <TabPanels display="flex" flex="1 1 auto" alignItems="stretch" overflow="auto">
              <MixpanelSourceProvider value={MixpanelEventSource.SubAccounts}>
                <SubAccounts
                  selectedSubAccountName={selectedSubAccountName}
                  setSelectedSubAccountName={setSelectedSubAccountName}
                  drawer={subAccountDrawer}
                />
              </MixpanelSourceProvider>
              <MixpanelSourceProvider value={MixpanelEventSource.SubAccountRollups}>
                <SubAccountRollups
                  selectedSubAccountName={selectedSubAccountName}
                  setSelectedSubAccountName={setSelectedSubAccountName}
                  drawer={rollupDrawer}
                />
              </MixpanelSourceProvider>
            </TabPanels>
          </Tabs>
        </PanelContent>
      </Panel>

      {/* We render the drawers out here so a user can navigate between the book and rollup tabs without the drawer closing */}
      <EditSubAccountsDrawer subAccountName={selectedSubAccountName} {...subAccountDrawer} />
      <EditSubAccountRollupsDrawer selectedSubAccountName={selectedSubAccountName} {...rollupDrawer} />
    </>
  );
}
