import { useDynamicCallback } from '@talos/kyoko';
import { useMemo } from 'react';
import { ErrorBoundary } from '../../../../../components/ErrorBoundary';
import type { SettlementMonitoringTab } from '../../tabs';
import { useSettlementMonitoringState } from '../providers/SettlementMonitoringStateProvider';
import { CUSTOMER_POSITION_MONITORING_BLOTTER_PREFIX } from '../tokens';
import { PositionMonitoringByAccountBlotter } from './ByAccount/PositionMonitoringByAccountBlotter';
import { PositionMonitoringByAssetBlotter } from './ByAsset/PositionMonitoringByAssetBlotter';
import { defaultPositionMonitoringByAccountColumns, defaultPositionMonitoringByAssetColumns } from './defaults';
import type { PositionMonitoringBlotterConfig } from './types';

export const PositionMonitoringBlotterWrapper = ({ tab }: { tab: SettlementMonitoringTab }) => {
  const {
    state: { groupBy, groupByCounterparty },
  } = useSettlementMonitoringState();

  // We only really persist the filter in the top-level tabs. Not columns or row group opened state, just for simplicity for now.

  const positionsBlotterConfig = useMemo(
    () =>
      ({
        defaultFilter: tab.filter,
        defaultColumns:
          groupBy === 'account' ? defaultPositionMonitoringByAccountColumns : defaultPositionMonitoringByAssetColumns,
      } satisfies PositionMonitoringBlotterConfig),
    [tab.filter, groupBy]
  );

  const handleUpdatePositionsBlotterConfig = useDynamicCallback((updatedConfig: PositionMonitoringBlotterConfig) => {
    // If we in the future want to persist more things from the blotter into the page tabs, we would implement this function
  });

  // Suffixing the blotterID with groupBy means that for each tab.id, we have two unique blotter states: one for each group by (asset / cpty)
  // This allows us to separate column definitions uniquely
  const blotterID = `${CUSTOMER_POSITION_MONITORING_BLOTTER_PREFIX}/${tab.id}/${groupBy}`;
  // We need to key on groupByCounterparty too because we have to rebuild the three when our grouping definition changes
  const key = `${blotterID}${groupByCounterparty}`;

  return (
    <ErrorBoundary>
      {groupBy === 'asset' ? (
        <PositionMonitoringByAssetBlotter
          key={key}
          blotterID={blotterID}
          config={positionsBlotterConfig}
          updateConfig={handleUpdatePositionsBlotterConfig}
        />
      ) : (
        <PositionMonitoringByAccountBlotter
          key={key}
          blotterID={blotterID}
          config={positionsBlotterConfig}
          updateConfig={handleUpdatePositionsBlotterConfig}
        />
      )}
    </ErrorBoundary>
  );
};
