/**
 * Given an app config, this will replace a V1 blotter (with id `oldV1BlotterID`) with a V2 version of the same
 * (mapped to the new id `newV2BlotterID`).
 *
 * @param replacedConfig App config
 * @param oldV1BlotterID ID of the V1 blotter we want to migrate from
 * @param newV2BlotterID ID of the V2 blotter we want to migrate to
 * @param defaultSort The default sorting to apply if none was found
 * @param newColIDs Mappings from `old col ID` to `new col ID`
 */

export function replaceBlotterV1Config(
  replacedConfig: any,
  oldV1BlotterID: string,
  newV2BlotterID: string,
  defaultSort: string,
  newColIDs: { [key: string]: string }
) {
  if (replacedConfig == null) {
    return;
  }
  if (!('blotters2' in replacedConfig)) {
    replacedConfig.blotters2 = {};
  }

  // Find first column that contains the sorting
  const oldBlotterConfig = replacedConfig.blotters[oldV1BlotterID];
  const sort = oldBlotterConfig.sort.find(col => col.sort != null);

  // Apply it in the new config
  const newBlotterConfig: any = {
    sort: sort == null ? defaultSort : `${sort === 'desc' ? '+' : '-'}${newColIDs[sort.colId]}`,
    columns: [],
  };

  // Iterate through the old persisted blotter config and add to the new one.
  // Note that previously we only persisted _visible_ columns, so we always do `hide: false` in this loop.
  for (let i = 0; i < oldBlotterConfig.columns.length; i++) {
    const oldColID = oldBlotterConfig.columns[i];
    const width = oldBlotterConfig.widths[i];

    // Skip unwanted/no-longer-existing columns
    if (oldColID in newColIDs) {
      newBlotterConfig.columns.push({
        id: newColIDs[oldColID],
        hide: false,
        width,
      });
    }
  }

  // Apply new config and delete old one
  replacedConfig.blotters2[newV2BlotterID] = newBlotterConfig;
  delete replacedConfig.blotters[oldV1BlotterID];
}
