import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  MixpanelEvent,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  beginningOfDay,
  formattedDateForSubscription,
  tabLabelerEnumerated,
  useDynamicCallback,
  useMixpanel,
  usePersistedTabs,
  usePortal,
  useTabs,
  type Column,
  type TabProps,
} from '@talos/kyoko';
import { addDays } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { RECON_MISMATCHES_BLOTTER_PREFIX } from '../tokens';
import { ReconMismatchesBlotter } from './ReconMismatchesBlotter';
import {
  RECON_MISMATCH_ROW_COUNT_COLID,
  useReconMismatchColumns,
  type ReconMismatchColumn,
} from './useReconMismatchesColumns';
import type { BlotterTableReconMismatchesFilter } from './useReconMismatchesFilter';

export interface ReconMismatchesTabProps extends TabProps {
  defaultColumns: Column[];
  defaultFilter?: BlotterTableReconMismatchesFilter;
}

const ALL_MISMATCHES_TAB_ID = 'all-mismatches';
const DEFAULT_MISMATCHES_COLUMNS: ReconMismatchColumn[] = [
  'ID',
  'Asset',
  RECON_MISMATCH_ROW_COUNT_COLID,
  'MissingDelta',
  'StartTime',
  'EndTime',
  'State',
  'Resolution',
  'Comments',
];

export function getReconMismatchesBlotterDefaultStartDate() {
  return formattedDateForSubscription(addDays(beginningOfDay(), -2));
}

const tabLabeler = tabLabelerEnumerated('Mismatches');

export const ReconMismatches = () => {
  const mixpanel = useMixpanel();
  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);

  const handleSelect = useDynamicCallback(() => setInitialFiltersOpen(false));
  const handleAdd = useDynamicCallback(() => setInitialFiltersOpen(true));

  const defaultColumns = useReconMismatchColumns({ defaultColumns: DEFAULT_MISMATCHES_COLUMNS });

  const allMismatchesTab: ReconMismatchesTabProps = useMemo(() => {
    return {
      label: 'All',
      id: ALL_MISMATCHES_TAB_ID,
      closable: true,
      editable: true,
      defaultFilter: {
        StartDate: getReconMismatchesBlotterDefaultStartDate(),
      },
      defaultColumns,
    };
  }, [defaultColumns]);

  const newTabDefaults = useMemo(() => {
    return {
      defaultFilter: {
        StartDate: getReconMismatchesBlotterDefaultStartDate(),
      } as BlotterTableReconMismatchesFilter,
      defaultColumns,
    };
  }, [defaultColumns]);

  const persistedTabs = usePersistedTabs<ReconMismatchesTabProps>(RECON_MISMATCHES_BLOTTER_PREFIX, {
    defaultInitialItems: [allMismatchesTab],
    defaultInitialSelectedIndex: 0,
    onSelect: handleSelect,
  });

  const tabs = useTabs<ReconMismatchesTabProps>({
    ...persistedTabs,
    showAddTab: true,
    tabLabeler,
    onAdd: handleAdd,
    allowClosingLastTab: false,
  });

  const handleCloneTab = useCallback(
    (filter: BlotterTableReconMismatchesFilter, columns: Column[]) => {
      mixpanel.track(MixpanelEvent.CloneTab);
      tabs.clone(tabs.selectedIndex, {
        defaultColumns: columns,
        defaultFilter: filter,
      });
    },
    [mixpanel, tabs]
  );

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <Tabs {...tabs} h="100%" size={TabSize.Large} data-testid="recon-mismatches-blotter">
      <TabList isBordered rightItems={<Box ref={filtersContainerRef} />}>
        {tabs.items.map((tab, idx) => (
          <Tab key={idx} {...tab} />
        ))}
      </TabList>
      <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        {tabs.items.map(tab => (
          <AccordionGroup key={tab.id}>
            <ReconMismatchesBlotter
              key={tab.id}
              blotterID={`${RECON_MISMATCHES_BLOTTER_PREFIX}/${tab.id}`}
              tabLabel={tab.label}
              // Checking default columns length is for backwards compatibility with old configs circa 2.36.0
              defaultColumns={
                'defaultColumns' in tab && tab.defaultColumns.length > 0
                  ? tab.defaultColumns
                  : newTabDefaults.defaultColumns
              }
              defaultFilter={tab.defaultFilter ?? newTabDefaults.defaultFilter}
              onCloneTab={handleCloneTab}
              initialIsOpen={initialFiltersOpen}
            />
          </AccordionGroup>
        ))}
      </TabPanels>
    </Tabs>
  );
};
