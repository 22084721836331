import { Button, ButtonVariants, IconName, Modal, useDisclosure } from '@talos/kyoko';
import { useCallback, useEffect, useState } from 'react';
import { usePositionsFormContext } from '../providers/PositionsFormProvider';
import type { MarketPositions } from '../types';
import { SETTLE_MODAL_WIDTH } from '../utils';
import { SettleModalContent } from './Settle/SettleModalContent';

export interface SettleAllButtonProps {
  marketPositions: MarketPositions[];
}

export const SettleAll = ({ marketPositions }: SettleAllButtonProps) => {
  const { state } = usePositionsFormContext();

  const [sayAll, setSayAll] = useState(true);
  const [settleable, setSettleable] = useState(false); // seems like a safe default

  const modal = useDisclosure();

  const { open: openModal, close: closeModal } = modal;

  useEffect(() => {
    let isSettleable = false;
    let isEverythingDefault = true;
    for (const marketAccountMap of state.values()) {
      for (const entry of marketAccountMap.values()) {
        // Do logic for settleable or not boolean
        if (entry.checked && entry.inputValue) {
          isSettleable = true;
        }

        // Do logic for
        if (entry.wsAmount !== '0') {
          if (entry.isOutgoing) {
            const entryIsDefault = entry.checked && !entry.isManuallySet;
            if (!entryIsDefault) {
              isEverythingDefault = false;
            }
          } else {
            const entryIsDefault = !entry.checked && !entry.isManuallySet;
            if (!entryIsDefault) {
              isEverythingDefault = false;
            }
          }
        }
      }
    }

    setSayAll(isEverythingDefault);
    setSettleable(isSettleable);
  }, [state]);

  const handleSettleAllClicked = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      <Button
        variant={ButtonVariants.Primary}
        startIcon={IconName.ArrowLeftRight}
        disabled={!settleable}
        onClick={handleSettleAllClicked}
      >
        Settle {sayAll ? 'all' : 'selected'}
      </Button>
      <Modal {...modal} width={SETTLE_MODAL_WIDTH}>
        <SettleModalContent marketPositions={marketPositions} close={closeModal} />
      </Modal>
    </>
  );
};
