import { Table, Tbody, Td, Text, Th, Tr, abbreviateId, formattedDate } from '@talos/kyoko';
import { useMemo } from 'react';
import { BulkModifyOrderStatusEnum, type BulkModifyOrderModel } from '../Dialogs/types';
import { BulkEditOrderStatus } from './BulkEditOrderStatus';

interface OrdersEndTimeProps {
  orders: BulkModifyOrderModel[];
}

export function BulkModifyOrdersEndTime({ orders }: OrdersEndTimeProps) {
  const timeLabel = useMemo(
    () => (orders.every(order => order.status === BulkModifyOrderStatusEnum.Success) ? 'New end time' : 'End time'),
    [orders]
  );

  return (
    <Table w="100%">
      <Tbody>
        <Tr>
          <Th>
            <Text size="fontSizeSmall" transform="uppercase">
              Orders
            </Text>
          </Th>
          <Th align="right">
            <Text size="fontSizeSmall" transform="uppercase">
              {timeLabel}
            </Text>
          </Th>
        </Tr>
        {orders.map((order, index) => (
          <Tr key={index}>
            <Td title={order?.error ?? ''}>Order #{abbreviateId(order.orderData.OrderID)}</Td>
            <Td align="right" title={order?.error ?? ''}>
              {order.newEndTime && order.status === BulkModifyOrderStatusEnum.Success
                ? formattedDate(order.newEndTime)
                : formattedDate(order.orderData.Parameters?.EndTime ?? order.orderData.EndTime)}
              <BulkEditOrderStatus status={order.status} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
