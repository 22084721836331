import type { SerializedDockview } from 'dockview';
import { Orientation } from 'dockview';
import { getDockViewPanelDataLeaves } from '../../getDockViewPanelDataLeaves';
import type { OperationsComponentType } from './operations.components';
import { OPERATIONS_PANELS, type OperationsPanelType } from './operations.panels';

export const getOperationsLayout: () => SerializedDockview = () => {
  const dataLeaves = getDockViewPanelDataLeaves<OperationsPanelType, OperationsComponentType>({
    panels: OPERATIONS_PANELS,
    mainPanel: {
      views: ['orders', 'trades', 'transfers'],
      activeView: 'orders',
      leafId: 'blotters',
      size: 900,
    },
  });

  return {
    grid: {
      root: {
        type: 'branch',
        data: dataLeaves,
      },
      width: 1600,
      height: 900,
      orientation: Orientation.HORIZONTAL,
    },
    panels: OPERATIONS_PANELS,
  };
};
