import { RECON_MISMATCH, useObservableValue, useSubscription, wsScanToMap, type ReconMismatch } from '@talos/kyoko';
import { useMemo } from 'react';
import { map } from 'rxjs';

export const useReconMismatches = (mismatchIDs: string[], tag: string) => {
  const request = useMemo(
    () => ({ name: RECON_MISMATCH, ReconMismatchIDs: mismatchIDs, tag: `${tag}/useReconMismatches` }),
    [mismatchIDs, tag]
  );
  const { data } = useSubscription<ReconMismatch>(request);
  return useObservableValue(
    () =>
      data.pipe(
        wsScanToMap({ getUniqueKey: rm => rm.ID, newMapEachUpdate: false }),
        map(map => [...map.values()])
      ),
    [data]
  );
};
