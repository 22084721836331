import { getBlockchainAddressesForCurrency, truncateBlockchainAddress, type MarketAccount } from '@talos/kyoko';
import { v4 as uuid } from 'uuid';

export interface TransferForm {
  clientID: string;
  provider: string;
  amount: string;
  asset: string;
  chainCurrency: string | undefined;
  source: SourceDestinationOption | undefined;
  destination: SourceDestinationOption | undefined;
  reference: string;
  description: string;
}

export function getEmptyTransferForm(): TransferForm {
  return {
    clientID: uuid(),
    provider: '',
    asset: '',
    chainCurrency: '',
    amount: '',
    source: undefined,
    destination: undefined,
    reference: '',
    description: '',
  };
}

export interface SourceDestinationOption {
  label: string;
  description?: string;
  addressForCurrency?: string;
  marketAccount: MarketAccount;
}

export function marketAccountToSourceDestinationOption(
  marketAccount: MarketAccount,
  currency: string | undefined
): SourceDestinationOption {
  const blockchainAddressForCurrency = currency
    ? getBlockchainAddressesForCurrency(marketAccount, currency)?.at(0)?.Address
    : undefined;

  return {
    label: marketAccount.DisplayName ?? marketAccount.Name,
    description: blockchainAddressForCurrency ? truncateBlockchainAddress(blockchainAddressForCurrency) : undefined,
    addressForCurrency: blockchainAddressForCurrency,
    marketAccount,
  };
}
