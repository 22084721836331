import {
  ACTION,
  AccordionRow,
  AccordionRowBody,
  AccordionRowBodyCell,
  AccordionRowCell,
  AccordionRowHeader,
  Box,
  Button,
  ButtonVariants,
  Dialog,
  Flex,
  FormControlSizes,
  HStack,
  IconName,
  LoaderSizes,
  useAccordion,
  useDisclosure,
  useEndpointsContext,
} from '@talos/kyoko';
import { Loader } from 'components/Loader';
import { useRoleAuth } from 'hooks';
import { useAggregationMarkets } from 'hooks/useAggregationMarkets';
import { useEffect, useState } from 'react';
import type { Aggregation, AggregationMarket } from 'types';
import { AggregationMarketsTable } from './AggregationMarketsTable';
import { getAggregationMarketRowID } from './utils';

export function AggregationAccordion({
  aggregation,
  onDeleteAggregation,
  onEditAggregation,
}: {
  aggregation: Aggregation;
  onDeleteAggregation: (name: string) => void;
  onEditAggregation: (a: Aggregation) => void;
}) {
  const accordion = useAccordion({ id: aggregation.Name });
  const [aggregationMarkets, setAggregationMarkets] = useState<AggregationMarket[]>();
  const { orgApiEndpoint } = useEndpointsContext();
  const { listAggregationMarkets } = useAggregationMarkets(aggregation.Name);
  const { isAuthorized } = useRoleAuth();

  const deleteAggregationDialog = useDisclosure();

  useEffect(() => {
    if (accordion.isOpen) {
      listAggregationMarkets()
        .then(value => setAggregationMarkets(value.data.map(a => ({ ...a, RowID: getAggregationMarketRowID(a) }))))
        .catch((e: ErrorEvent) => {
          console.error(e);
        });
    }
  }, [aggregation.Name, accordion.isOpen, orgApiEndpoint, listAggregationMarkets]);
  return (
    <AccordionRow {...accordion}>
      <AccordionRowHeader>
        <AccordionRowCell>{aggregation.DisplayName}</AccordionRowCell>
        <AccordionRowCell>{aggregation.Name}</AccordionRowCell>
        <AccordionRowCell>
          <Flex justifyContent="flex-end">
            <Button
              startIcon={IconName.Pencil}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={e => {
                e.stopPropagation();
                onEditAggregation(aggregation);
              }}
              disabled={!isAuthorized(ACTION.DEALER_TRADING)}
            >
              Edit Display Name
            </Button>
          </Flex>
        </AccordionRowCell>
      </AccordionRowHeader>
      <AccordionRowBody>
        <tr>
          <AccordionRowBodyCell colSpan={100}>
            <Box pt="spacingDefault" pb="spacingDefault">
              {aggregationMarkets == null ? (
                <HStack>
                  <Loader size={LoaderSizes.TINY} />
                </HStack>
              ) : (
                <AggregationMarketsTable
                  aggregation={aggregation}
                  aggregationMarkets={aggregationMarkets}
                  onDeleteAggregation={() => deleteAggregationDialog.open()}
                />
              )}
            </Box>
          </AccordionRowBodyCell>
        </tr>
      </AccordionRowBody>
      <Dialog
        {...deleteAggregationDialog}
        title="Delete Aggregation"
        onConfirm={() => onDeleteAggregation(aggregation.Name)}
        confirmLabel="Delete Aggregation"
        showClose={true}
        variant={ButtonVariants.Negative}
      >
        <Box pt="spacingLarge" pb="spacingLarge">
          Are you sure you want to delete aggregation &quot;{aggregation.DisplayName}&quot;?
        </Box>
      </Dialog>
    </AccordionRow>
  );
}
