import {
  CUSTOMER_CREDIT,
  CreditBlotterExposure,
  Exposure,
  ExposureTypeEnum,
  filterExistsAndExcludes,
  useSubscription,
  useWSFilterPipe,
  wsMerge,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { map } from 'rxjs';
import type { SettlementMonitoringFilter } from '../../types';
import { usePositionMonitoringBaseObs } from '../usePositionMonitoringBaseObs';
import type { ByAccountEntity } from './types';

function getExposureKey(cbe: CreditBlotterExposure) {
  return cbe.rowID;
}

interface UsePositionMonitoringByAccountObsParams {
  filter: SettlementMonitoringFilter;
}

export const usePositionMonitoringByAccountObs = ({ filter }: UsePositionMonitoringByAccountObsParams) => {
  const { dataObs: positionsDataObs, totalsDataObs } = usePositionMonitoringBaseObs({ filter });

  const { data: exposuresDataObs } = useSubscription<Exposure>({
    name: CUSTOMER_CREDIT,
    tag: 'Settlement Monitoring - Exposures',
  });

  const exposuresFilterFunc = useCallback(
    (item: CreditBlotterExposure): boolean => {
      if (filterExistsAndExcludes(filter, 'MarketAccounts', item, 'MarketAccount')) {
        return false;
      }

      return true;
    },
    // Despite this frontend Exposures filtering operator not checking all properties of the SettlementMonitoringFilter filter,
    // we still need to be reacting on the entirety of the filter object here.
    // This is due to the necessity of each stream passing an initial: true message whenever some property of the filter clause
    // changes. Only then will wsMerge release a initial: true message.
    // TODO start thinking about how we can encapsulate this better.
    [filter]
  );

  const exposuresFilterPipe = useWSFilterPipe({ getUniqueKey: getExposureKey, filterFunc: exposuresFilterFunc });

  const creditBlotterExposuresObs = useMemo(() => {
    return exposuresDataObs.pipe(
      map(json => ({
        ...json,
        data: json.data.map(e => new CreditBlotterExposure(new Exposure(e), ExposureTypeEnum.Exposure)),
      })),
      exposuresFilterPipe
    );
  }, [exposuresDataObs, exposuresFilterPipe]);

  const dataObs = useMemo(
    () => wsMerge<ByAccountEntity>({ sources: [positionsDataObs, creditBlotterExposuresObs] }),
    [positionsDataObs, creditBlotterExposuresObs]
  );

  return { dataObs, totalsDataObs };
};
