import {
  CustomerOrder,
  OrdStatusEnum,
  request as makeRequest,
  useEndpointsContext,
  useObservable,
  useSubscription,
  type CustomerDealSummary,
  type RequestStream,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

const SESSION_START = new Date();

/**
 * Looks up a particular customer Order and retrieves the deal summary for that order. Returns the combined entity of
 * the two lookups by enriching the customer order.
 */
export function useCustomerOrder({
  orderID,
  rfqID,
  tag,
}: {
  orderID?: string;
  rfqID?: string;
  tag: string;
}): CustomerOrder | undefined {
  const [request, setRequest] = useState<RequestStream | null>(null);
  const [customerOrder, setCustomerOrder] = useState<CustomerOrder>();
  const { data: subscription } = useSubscription(request, { replay: false });
  const { orgApiEndpoint } = useEndpointsContext();

  const observable = useObservable(
    () =>
      subscription.pipe(
        filter((json: { data: CustomerOrder[] }) => json.data.length > 0),
        map((json: { data: CustomerOrder[] }) => new CustomerOrder(json.data.at(-1)!)),
        mergeMap(customerOrder => {
          if (customerOrder.OrdStatus === OrdStatusEnum.Filled && new Date(customerOrder.SubmitTime) > SESSION_START) {
            return from(
              makeRequest<SubscriptionResponse<CustomerDealSummary>>(
                'GET',
                `${orgApiEndpoint}/customer/orders/${customerOrder.OrderID}/summary`
              )
            ).pipe(
              catchError(() => {
                return of(undefined);
              }),
              switchMap(response => {
                return of(
                  new CustomerOrder({
                    ...customerOrder,
                    summary: response?.data?.[0],
                  })
                );
              })
            );
          }
          return of(customerOrder);
        })
      ),
    [orgApiEndpoint, subscription]
  );
  useEffect(() => {
    setCustomerOrder(undefined);
    if (orderID == null && rfqID == null) {
      setRequest(null);
    } else {
      const sub = observable.subscribe(setCustomerOrder);
      setRequest({
        name: 'CustomerOrder',
        tag,
        ...(orderID != null && { OrderID: orderID }),
        ...(rfqID != null && { RFQID: rfqID }),
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, [observable, orderID, rfqID, tag]);
  return customerOrder;
}
