import { BlotterTableWrapper, Box } from '@talos/kyoko';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: ${({ theme }) => theme.spacingSmall}px;
  max-height: 100vh;
  overflow: hidden;
`;

export const SecuritiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const Main = styled.main<{ blotterHeight?: number }>`
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  gap: ${({ theme }) => theme.spacingTiny}px;
  height: ${({ blotterHeight }) => `calc(100% - ${blotterHeight}px)`};
  width: 100%;
  overflow: hidden;
`;

export const ExpandableBottomPanelWrapper = styled(Box)`
  max-height: ${({ theme }) => `calc(100% + ${theme.spacingSmall}px)`};
`;

export const BlotterAwareBox = styled(Box)`
  ${BlotterTableWrapper}.ag-theme-balham-dark {
    padding-left: ${({ theme }) => theme.spacingComfortable}px;
    padding-right: ${({ theme }) => theme.spacingComfortable}px;
  }
`;
