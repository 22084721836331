import {
  AccordionGroup,
  type SubAccountReconMatch,
  SubAccountReconMatchStatusEnum,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  TabSize,
  useTabs,
} from '@talos/kyoko';

import { BreakDetailsBlotter } from './BreakDetailsBlotter';
import type { BreakDetailsBlotterTabProps } from './types';

const TABS: BreakDetailsBlotterTabProps[] = [
  {
    id: 'break-details-bottom-blotter-breaks',
    label: 'Breaks',
    defaultFilter: {
      Statuses: [
        SubAccountReconMatchStatusEnum.Unmatched,
        SubAccountReconMatchStatusEnum.UnmatchedMarketAccount,
        SubAccountReconMatchStatusEnum.UnmatchedSubAccount,
      ],
    },
  },
  {
    id: 'break-details-bottom-blotter-matched',
    label: 'Matched',
    defaultFilter: {
      Statuses: [SubAccountReconMatchStatusEnum.Matched],
    },
  },
  {
    id: 'break-details-bottom-blotter-resolved',
    label: 'Resolved',
    defaultFilter: {
      Statuses: [SubAccountReconMatchStatusEnum.Resolved],
    },
  },
  {
    id: 'break-details-bottom-blotter-all',
    label: 'All',
    defaultFilter: {
      Statuses: [
        SubAccountReconMatchStatusEnum.Unmatched,
        SubAccountReconMatchStatusEnum.UnmatchedMarketAccount,
        SubAccountReconMatchStatusEnum.UnmatchedSubAccount,
        SubAccountReconMatchStatusEnum.Resolved,
        SubAccountReconMatchStatusEnum.Matched,
      ],
    },
  },
];

export const BreakDetailsBlotterWrapper = ({
  onClickResolve,
  checkpointID,
}: {
  onClickResolve: (match: SubAccountReconMatch) => void;
  checkpointID: string;
}) => {
  const tabs = useTabs<BreakDetailsBlotterTabProps>({
    initialItems: TABS,
    showAddTab: false,
  });

  return (
    <Tabs {...tabs} h="100%" size={TabSize.Large}>
      <TabList isBordered>
        {tabs.items.map((tab, idx) => (
          <Tab key={idx} {...tab} />
        ))}
      </TabList>
      <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        {tabs.items.map(tab => (
          <AccordionGroup key={tab.id}>
            <BreakDetailsBlotter tab={tab} onClickResolve={onClickResolve} checkpointID={checkpointID} />
          </AccordionGroup>
        ))}
      </TabPanels>
    </Tabs>
  );
};
