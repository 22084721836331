import { Box, HStack, Icon, IconName, SubAccountTypeEnum } from '@talos/kyoko';
import { useTheme } from 'styled-components';
import type { Item } from './PMSPortfolioSelector';

const ICON_WIDTH = 16;
const LEVEL_INDENT_WIDTH = 20;

const RollupPrefix = ({ level }: { level: number }) => (
  <>
    {level === 0 ? null : <Box w={`${level * LEVEL_INDENT_WIDTH}px`} />}
    <Box>
      <Icon size={ICON_WIDTH} icon={IconName.ChevronDown} />
    </Box>
  </>
);

const BookPrefix = ({ level, hasRollups }: { level: number; hasRollups: boolean }) => {
  const theme = useTheme();
  return (
    <>
      {level === 0 ? (
        hasRollups ? (
          <Box data-testid="base-level-indent" w={`${ICON_WIDTH}px`} />
        ) : null
      ) : (
        <>
          <Box w={`${level * LEVEL_INDENT_WIDTH}px`} />
          <Icon
            size={ICON_WIDTH}
            color={theme.colors.gray['080']}
            title="Rollup Child Book"
            icon={IconName.Connector}
          />
        </>
      )}
    </>
  );
};

/** Indent display for Portfolio Selector Label */
export const PortfolioSelectorLabelPrefix = ({ item, hasRollups }: { item: Item; hasRollups: boolean }) => {
  const level = item.level;
  const prefix =
    item.type === SubAccountTypeEnum.Rollup ? (
      <RollupPrefix level={level} />
    ) : (
      <BookPrefix level={level} hasRollups={hasRollups} />
    );
  return (
    <HStack gap="spacingSmall" pr="spacingSmall" alignItems="center">
      {prefix}
    </HStack>
  );
};
