import { createContext, useContext, type ReactNode } from 'react';
import type { BreakDetailsState } from '../../types';

export function useBreakDetailsState() {
  const context = useContext(BreakDetailsStateContext);
  if (context === undefined) {
    throw new Error('Missing BreakDetailsStateContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context as { state: BreakDetailsState };
}

const BreakDetailsStateContext = createContext({});

export const BreakDetailsStateProvider = ({ children, state }: { children: ReactNode; state: BreakDetailsState }) => {
  return <BreakDetailsStateContext.Provider value={{ state }}>{children}</BreakDetailsStateContext.Provider>;
};
