import { Orientation, type SerializedDockview } from 'dockview';
import { getDockViewPanelDataLeaves } from '../../getDockViewPanelDataLeaves';
import type { PortfolioComponentTypes } from './portfolioManagement.components';
import { PORTFOLIO_MANAGEMENT_PANELS, type PortfolioManagementPanelType } from './portfolioManagement.panels';

export const getPortfolioManagementLayout: () => SerializedDockview = () => {
  const dataLeaves = getDockViewPanelDataLeaves<PortfolioManagementPanelType, PortfolioComponentTypes>({
    panels: PORTFOLIO_MANAGEMENT_PANELS,
    mainPanel: {
      views: ['trades'],
      activeView: 'trades',
      leafId: '1',
      size: 600,
    },
  });
  const result: SerializedDockview = {
    grid: {
      root: {
        type: 'branch',
        data: dataLeaves,
        size: 1600,
      },
      width: 1600,
      height: 900,
      orientation: Orientation.VERTICAL,
    },
    activeGroup: '2',
    panels: PORTFOLIO_MANAGEMENT_PANELS,
  };
  return result;
};
