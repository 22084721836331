import { removeEmptyFilters } from '@talos/kyoko';
import { isEqual } from 'lodash';

import type { BalancesFilter } from '../../../types';
import type { OperationsOverviewShowBy, OperationsOverviewState } from './types';

export enum OperationsOverviewActionType {
  ShowByChange,
  FilterChange,
  MarketAccountsChange, // a part of the filter
  ShowZeroBalancesChange,
}

interface ShowByChangeAction {
  type: OperationsOverviewActionType.ShowByChange;
  payload: {
    showBy: OperationsOverviewShowBy;
  };
}

interface FilterChangeAction {
  type: OperationsOverviewActionType.FilterChange;
  payload: {
    filter: BalancesFilter;
  };
}

interface ShowZeroBalancesChangeAction {
  type: OperationsOverviewActionType.ShowZeroBalancesChange;
  payload: {
    showZeroBalances: boolean;
  };
}

interface MarketAccountsChangeAction {
  type: OperationsOverviewActionType.MarketAccountsChange;
  payload: {
    marketAccounts: string[] | undefined;
  };
}

export type OperationsOverviewAction =
  | ShowByChangeAction
  | FilterChangeAction
  | ShowZeroBalancesChangeAction
  | MarketAccountsChangeAction;

export const operationsOverviewReducer = (state: OperationsOverviewState, action: OperationsOverviewAction) => {
  switch (action.type) {
    case OperationsOverviewActionType.ShowByChange: {
      return {
        ...state,
        showBy: action.payload.showBy,
      };
    }

    case OperationsOverviewActionType.FilterChange: {
      const currentFilters = removeEmptyFilters(state.filter);
      const nextFilters = removeEmptyFilters(action.payload.filter);
      if (isEqual(currentFilters, nextFilters)) {
        return state;
      }

      return {
        ...state,
        filter: nextFilters,
      };
    }

    case OperationsOverviewActionType.ShowZeroBalancesChange: {
      return {
        ...state,
        showZeroBalances: action.payload.showZeroBalances,
      };
    }

    case OperationsOverviewActionType.MarketAccountsChange: {
      const currentFilters = removeEmptyFilters(state.filter);
      const nextFilters = removeEmptyFilters({ ...state.filter, MarketAccounts: action.payload.marketAccounts });
      if (isEqual(currentFilters, nextFilters)) {
        return state;
      }

      return { ...state, filter: nextFilters };
    }

    default:
      return state;
  }
};
