import Big from 'big.js';
import { PnlLookbackEnum, ReconStreamStatusEnum, type ProductTypeEnum } from '../types/types';
import { toBigWithDefault } from '../utils';
import { WarningSeverity } from './WarningSeverity';

export class Position {
  AssetType!: ProductTypeEnum;

  Asset!: string;

  MarketAccount!: string;

  SubAccount?: string;

  Amount!: string;

  AvgCost?: string;

  AvgCostCurrency?: string;

  RealizedPnL?: string;

  UnrealizedPnL?: string;

  TotalPnL?: string;

  PnLCurrency?: string;

  CumFees?: string;
  FeeCurrency?: string;

  FundingPnL?: string;
  FundingPnLCurrency?: string;
  UnsettledAmount?: string;
  UnsettledAvgCost?: string;
  UnsettledPnL?: string;

  Delta?: string;
  DeltaCurrency?: string;

  Gamma?: string;

  Vega?: string;

  Theta?: string;

  Rho?: string;

  UnderlyingPrice?: string;

  MarkPrice?: string;

  LiquidationPrice?: string;

  get elpVsMp() {
    // Defend against nullish and empty strings
    if (!this.Amount || !this.LiquidationPrice || !this.MarkPrice) {
      return undefined;
    }

    try {
      return Big(Big(this.LiquidationPrice).div(this.MarkPrice)).minus(1).toFixed();
    } catch (e) {
      return undefined;
    }
  }

  LastUpdateTime!: string;

  Status!: string;

  PositionSource!: string;

  OutstandingBuy?: string;
  OutstandingSell?: string;

  /* -- Attributes picked from Balances when creating synthetic Positions -- */
  Market?: string;
  Equity?: string;
  InitialMargin?: string;
  MaintenanceMargin?: string;

  /** Equivalent.Amount + Equivalent.Equity */
  get equivalentTotalEquity() {
    return toBigWithDefault(this.Equivalent?.Amount, 0).plus(toBigWithDefault(this.Equivalent?.Equity, 0)).toFixed();
  }
  /* -- */

  Expiration?: string;

  UnderlyingQuoteCurrency?: string;
  MarkPriceCurrency?: string;

  Equivalent?: PositionEquivalent;

  ReconStatus?: PositionReconStatus;

  get reconWarning(): PositionWarning | undefined {
    if (!this.ReconStatus) {
      return undefined;
    }

    const severity = getReconStatusWarningSeverity(this.ReconStatus?.Status);

    if (severity == null) {
      return undefined;
    }

    return {
      severity,
      asset: this.Asset,
      reconStatus: this.ReconStatus,
    };
  }

  get warningSeverity(): WarningSeverity | undefined {
    return this.reconWarning?.severity;
  }

  get rowID() {
    return getPositionID(this.PositionSource, this.MarketAccount, this.Asset, this.SubAccount);
  }

  PnLLookbacks?: {
    [key in PnlLookbackEnum]?: PositionPnlLookback;
  };

  PnLTags?: {
    [key in PnLTagEnum]?: PositionPnlTag;
  };

  marketAccountGroup?: string;

  /** Summation of PnLTags.EndOfDay.TradeUnrealizedPnL and PnLTags.EndOfDay.OpeningUnrealizedPnL */
  get eodTotalPnL() {
    const dayTradePnl = this.PnLTags?.EndOfDay?.TradeUnrealizedPnL;
    const openingPnl = this.PnLTags?.EndOfDay?.OpeningUnrealizedPnL;

    // If neither of these properties exist, return undefined as opposed to just defaulting to 0.
    if (dayTradePnl == null && openingPnl == null) {
      return undefined;
    }

    const dayTradePnlBig = toBigWithDefault(dayTradePnl, 0);
    const openingPnlBig = toBigWithDefault(openingPnl, 0);

    return dayTradePnlBig.plus(openingPnlBig).toFixed();
  }

  /** Summation of PnLTags.EndOfDay.Equivalent.TradeUnrealizedPnL and PnLTags.EndOfDay.Equivalent.OpeningUnrealizedPnL */
  get eodTotalPnLEquivalent() {
    const dayTradePnl = this.PnLTags?.EndOfDay?.Equivalent?.TradeUnrealizedPnL;
    const openingPnl = this.PnLTags?.EndOfDay?.Equivalent?.OpeningUnrealizedPnL;

    // If neither of these properties exist, return undefined as opposed to just defaulting to 0.
    if (dayTradePnl == null && openingPnl == null) {
      return undefined;
    }

    const dayTradePnlBig = toBigWithDefault(dayTradePnl, 0);
    const openingPnlBig = toBigWithDefault(openingPnl, 0);

    return dayTradePnlBig.plus(openingPnlBig).toFixed();
  }

  constructor(
    data: Omit<
      Position,
      | 'reconWarning'
      | 'warningSeverity'
      | 'rowID'
      | 'elpVsMp'
      | 'eodTotalPnL'
      | 'eodTotalPnLEquivalent'
      | 'equivalentTotalEquity'
    >
  ) {
    // Should be the auto generated interface from ava.xml
    Object.assign(this, data);
  }
}

export function getPositionID(source: string, marketAccount: string, asset: string, subAccount?: string) {
  return `${source}-${marketAccount}-${subAccount}-${asset}`;
}

export interface PositionEquivalent {
  Currency: string;
  Amount: string;
  ConversionRate?: string;
  AvgCost?: string;
  RealizedPnL?: string;
  UnrealizedPnL?: string;
  AvgCostConversionRate?: string;
  PnLConversionRate?: string;
  OutstandingBuy?: string;
  OutstandingSell?: string;
  Delta?: string;
  CumFees?: string;
  FundingPnL?: string;
  UnsettledAmount?: string;
  UnsettledPnL?: string;
  MarkPrice?: string;
  QuoteConversionRate?: string;
  TotalPnL?: string;
  DeltaConversionRate?: string;
  /* -- Attributes picked from Balances when creating synthetic Positions -- */
  Equity?: string;
  InitialMargin?: string;
  MaintenanceMargin?: string;
  /* -- */
}

export interface PositionPnlLookback {
  PnLDelta: string;
  Currency: string;
  OpenTimestamp: string;
  Equivalent: {
    Currency: string;
    PnLDelta: string;
  };
}

export interface PositionPnlTag {
  OpenTimestamp: string;
  OpeningPosition: string;
  OpeningPriceMark: string;
  OpeningUnrealizedPnL: string;
  TradePositionDelta: string;
  TradeUnrealizedPnL: string;
  PnLCurrency: string;
  Equivalent?: {
    OpeningPosition: string;
    OpeningUnrealizedPnL: string;
    OpeningPriceMark: string;
    TradePositionDelta: string;
    TradeUnrealizedPnL: string;
    Currency: string;
  };
}

export enum PnLTagEnum {
  EndOfDay = 'EndOfDay',
}

export function isPnLTagEnum(value: string | undefined): value is PnLTagEnum {
  return value ? (Object.values(PnLTagEnum) as string[]).includes(value) : false;
}

export function isPnlLookbackEnum(value: string | undefined): value is PnlLookbackEnum {
  return value ? (Object.values(PnlLookbackEnum) as string[]).includes(value) : false;
}

export const PNL_LOOKBACKS = [
  PnlLookbackEnum.Today,
  PnlLookbackEnum.H24,
  PnlLookbackEnum.WeekToDate,
  PnlLookbackEnum.D7,
  PnlLookbackEnum.MonthToDate,
  PnlLookbackEnum.D30,
  PnlLookbackEnum.YearToDate,
  PnlLookbackEnum.D365,
] as const;

// Might be changed to be just a ReconStatus type that can be placed onto different types like above in the future
// For now keep unique for Position like this until further clarity
export interface PositionReconStatus {
  Revision: number;
  LastMismatchTime: string;
  Status: ReconStreamStatusEnum;
  TotalMismatch: string;
}

export interface PositionWarning {
  severity: WarningSeverity;
  asset: string;
  reconStatus: PositionReconStatus;
}

export function isPositionWarning(warning: any): warning is PositionWarning {
  return warning != null && 'severity' in warning && 'asset' in warning && 'reconStatus' in warning;
}

export function getReconStatusWarningSeverity(reconStatusEnum: ReconStreamStatusEnum): WarningSeverity | undefined {
  switch (reconStatusEnum) {
    case ReconStreamStatusEnum.MarketOffline:
    case ReconStreamStatusEnum.MarketStale:
      return WarningSeverity.MEDIUM;
    case ReconStreamStatusEnum.MatchingSinceLatestMismatch:
    case ReconStreamStatusEnum.LedgerAheadSinceLatestMismatch:
      return WarningSeverity.LOW;
    default:
      return undefined;
  }
}
