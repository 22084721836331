import {
  Button,
  ButtonGroup,
  ButtonVariants,
  FormControlSizes,
  HStack,
  MixpanelEvent,
  copyText,
  isTalosUser,
  useDynamicCallback,
  useJsonModal,
  useMixpanel,
  type BoxProps,
  type CustomerOrder,
  type Order,
  type OrderAnalytic,
} from '@talos/kyoko';
import { useGetExecutionReportSummary, useUser } from '../../../../hooks';

export function TopBarUtils({
  order,
  customerOrder,
  latestAnalytics,
}: {
  order: Order;
  customerOrder?: CustomerOrder;
  /**
   * if null, don't render the analytics button
   */
  latestAnalytics: Record<number, OrderAnalytic> | null | undefined;
} & BoxProps) {
  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();
  const getExecutionReportSummary = useGetExecutionReportSummary();

  const handleCopyDetails = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CopyDetails);
    copyText(getExecutionReportSummary({ ...order, Counterparty: customerOrder?.Counterparty }));
  });
  const handleCopyUrl = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CopyURL);
    copyText(window.location.href);
  });

  const handleCopyJSON = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CopyJSON);
    copyText(JSON.stringify(order, null, 2));
  });

  const { handleClickJson: handleClickAnalyticsJson, jsonModal: analyticsJSONModal } = useJsonModal();
  const user = useUser();

  return (
    <HStack gap="spacingDefault">
      <ButtonGroup size={FormControlSizes.Small}>
        <Button variant={ButtonVariants.Default} onClick={handleCopyDetails}>
          Copy Details
        </Button>
        <Button variant={ButtonVariants.Default} onClick={handleCopyUrl}>
          Copy URL
        </Button>
      </ButtonGroup>
      <ButtonGroup size={FormControlSizes.Small}>
        <Button variant={ButtonVariants.Default} onClick={() => handleClickJson(order)}>
          Show JSON
        </Button>
        {jsonModal}
        <Button variant={ButtonVariants.Default} onClick={handleCopyJSON}>
          Copy JSON
        </Button>

        {latestAnalytics !== null && isTalosUser(user) && (
          <>
            <Button
              variant={ButtonVariants.Default}
              onClick={() => handleClickAnalyticsJson(latestAnalytics ?? { '0': null, '1': null })}
            >
              Show Analytics
            </Button>
            {analyticsJSONModal}
          </>
        )}
      </ButtonGroup>
    </HStack>
  );
}
