import type { ExecutionReport, SubscriptionResponse } from '@talos/kyoko';
import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';

interface ExecutionReportsContextProps {
  executionReportsByClOrdID: Observable<Map<string, ExecutionReport>>;
  executionReportsByOrderID: Observable<Map<string, ExecutionReport>>;
  executionReportSub: Observable<SubscriptionResponse<ExecutionReport, string>>;
}

export const ExecutionReportsContext = createContext<ExecutionReportsContextProps | undefined>(undefined);
ExecutionReportsContext.displayName = 'ExecutionReportsContext';

export const useExecutionReports = () => {
  const context = useContext(ExecutionReportsContext);
  if (context === undefined) {
    throw new Error('Missing ExecutionReportsContext.Provider further up the tree. Did you forget to add it?');
  }
  return context;
};
