import Big from 'big.js';
import { toNumber } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTheme } from 'styled-components';
import {
  consumePortfolioChartSubscription,
  type SeriesDefinition,
} from '../../PortfolioChart/consumePortfolioChartSubscription';
import { getPointUnderlyingData } from '../../PortfolioChart/utils';
import type { HistoricalPositionStat } from '../../types';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import type { PnLUnit } from '../types';
import { BENCHMARK_SERIES_NAME, DELTA_SERIES_NAME, NAVIGATOR_SERIES_NAME, PERFORMANCE_SERIES_NAME } from './tokens';
import { useChartSubscriptions } from './useChartSubscriptions';

interface UseHistoricalPerformanceData {
  chartRef: React.MutableRefObject<Highcharts.Chart | undefined>;
  isLoaded: boolean;
}

export function useHistoricalPerformanceData({ isLoaded, chartRef }: UseHistoricalPerformanceData) {
  const { dataLoading, dataResetting, subscription } = useChartSubscriptions();
  const {
    state: { pnlUnit },
  } = usePerformanceContext();
  const theme = useTheme();

  const pnlUnitRef = useRef(pnlUnit);
  useEffect(() => {
    pnlUnitRef.current = pnlUnit;
  }, [pnlUnit]);

  // unix ts to stat
  const currentDataPointsByTsRef = useRef(new Map<number, HistoricalPositionStat>());

  const seriesDefinitions: SeriesDefinition<HistoricalPositionStat>[] = useMemo(() => {
    return [
      {
        name: PERFORMANCE_SERIES_NAME,
        entityToPoint: stat => statToPerformancePoint(stat, pnlUnitRef.current),
      },
      {
        name: BENCHMARK_SERIES_NAME,
        entityToPoint: stat => [
          new Date(stat.Timestamp).getTime(),
          toNumber(stat.BenchmarkIntervalPnLPercent?.['BTC']),
        ],
      },
    ];
  }, []);

  const navigatorSeriesDefinition: SeriesDefinition<HistoricalPositionStat> = useMemo(() => {
    return {
      name: NAVIGATOR_SERIES_NAME,
      entityToPoint: stat => statToPerformancePoint(stat, pnlUnitRef.current),
    };
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    const mySub = subscription.subscribe(({ json, reset }) => {
      if (reset) {
        chart.zoomOut();
      }
      consumePortfolioChartSubscription({
        currentDataPointsByTsRef,
        json,
        reset,
        chart,
        seriesDefinitions,
        navigatorSeriesDefinition,
      });

      applyChangesToDeltaSeries(chart, currentDataPointsByTsRef);
    });

    return () => mySub.unsubscribe();
  }, [subscription, theme, seriesDefinitions, navigatorSeriesDefinition, isLoaded, chartRef]);

  useUpdateEffect(() => {
    // Whenever we toggle the pnlunit, we go through and update all data points in the chart, switching their unit
    // using the memory we have of each data point
    if (!isLoaded) {
      return;
    }
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    const performanceSeries = chart.series.find(s => s.name === PERFORMANCE_SERIES_NAME);
    if (!performanceSeries) {
      return;
    }
    const performanceSeriesXData: number[] | undefined = performanceSeries['xData'];
    if (!performanceSeriesXData) {
      return;
    }

    const newData = performanceSeriesXData
      .map(xOfDataPointToFlip => {
        const fullDataPoint = currentDataPointsByTsRef.current.get(xOfDataPointToFlip);
        return fullDataPoint ? statToPerformancePoint(fullDataPoint, pnlUnit) : undefined;
      })
      .filter(pointIsDefined);

    performanceSeries.setData(newData, true, false);
  }, [pnlUnit]);

  return {
    currentDataPointsByTsRef,
    dataLoading,
    dataResetting,
  };
}

function statToPerformancePoint(stat: HistoricalPositionStat, pnlUnit: PnLUnit) {
  return [new Date(stat.Timestamp).getTime(), toNumber(pnlUnit === 'amount' ? stat.PnLTotal : stat.IntervalPnLPercent)];
}

function pointIsDefined(point: number[] | undefined): point is number[] {
  return point != null;
}

// Custom application and calculation of the delta series into the chart
function applyChangesToDeltaSeries(
  chart: Highcharts.Chart,
  currentDataPointsByTsRef?: React.MutableRefObject<Map<number, HistoricalPositionStat>>
) {
  if (!currentDataPointsByTsRef) {
    return;
  }

  const performanceSeries = chart.series.find(s => s.name === PERFORMANCE_SERIES_NAME);
  const deltaSeries = chart.series.find(s => s.name === DELTA_SERIES_NAME);
  if (!performanceSeries || !deltaSeries) {
    return;
  }
  // After inserting a bunch of new data into the performance series, we recompute the entire data array of the deltaSeries
  const deltaData = performanceSeries.points.map((p, index, ps) => {
    if (p.x == null) {
      return undefined;
    }

    const pointRawData = getPointUnderlyingData(p);
    if (pointRawData == null || pointRawData.length === 0) {
      return undefined;
    }

    const firstPointX = pointRawData[0][0];

    // delta always 0 at index 0
    if (index === 0) {
      return [firstPointX, 0];
    }

    // All data points are grouped data points. We need to grab the unix ts (x) of the first data point within each grouped data point.
    const prevPointRawData = getPointUnderlyingData(ps[index - 1]);
    if (!prevPointRawData || prevPointRawData.length === 0) {
      // If we for some reason can't resolve either of these, return a 0 delta
      return [firstPointX, 0];
    }

    const pointStat = currentDataPointsByTsRef.current.get(pointRawData[0][0]);
    const prevPointStat = currentDataPointsByTsRef.current.get(prevPointRawData[0][0]);
    if (!pointStat || !prevPointStat) {
      // If we for some reason can't resolve either of these, return a 0 delta
      return [firstPointX, 0];
    }

    const bigAtX = Big(pointStat.PnLTotal ?? 0);
    const bigAtXMinus1 = Big(prevPointStat.PnLTotal ?? 0);
    return [firstPointX, bigAtX.minus(bigAtXMinus1).toNumber()];
  });

  deltaSeries.setData(
    deltaData.filter(pointIsDefined), // filter is 2x as fast as lodash compact
    true, // redraw chart
    false // dont animate
  );
}
