import {
  MultiSelectorField,
  ProductTypeEnum,
  SelectorField,
  formattedUTCDate,
  getCurrencyPairFromSecurity,
  type Security,
  type StringSelectItem,
} from '@talos/kyoko';
import { compareAsc } from 'date-fns';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';
import {
  MarketsWithMicroContracts,
  getExchangeAndTypeFromSecurity,
  getSettlementTypeFromExchangeAndType,
  getShortExchangeName,
  isSecurityExchangeAndTypeMatch,
} from './utils';

interface FutureData {
  exchangeAndTypeField: SelectorField<StringSelectItem>;
  symbolField: SelectorField<SymbolSelectItem>;
  expiryField: SelectorField<StringSelectItem>;
  marketAccountField: MultiSelectorField<StringSelectItem>;
}

// https://talostrading.atlassian.net/browse/UI-2958
// This is in order to support exchanges that support multiple derivatives with the exact same CCY but with different contract sizes
export interface SymbolSelectItem extends StringSelectItem {
  exchange: string;
  // we need to keep track of the ccyPair, e.g. 'BTC-USD' since this is the value we build our context lookup maps on
  underlyingCcyPair: string;
  // metadata used in multileg combo to filter out un-eligible symbols
  baseCurrency: string;
  quoteCurrency: string;
}

const getSymbolSelectItem = (security: Security): SymbolSelectItem => {
  const exchange = security?.Markets?.[0] || '';
  const ccyPair = getCurrencyPairFromSecurity(security);
  const displayValue = MarketsWithMicroContracts.includes(exchange) ? security.DisplaySymbol : ccyPair;
  return {
    value: displayValue,
    label: displayValue,
    exchange,
    underlyingCcyPair: ccyPair,
    baseCurrency: security?.BaseCurrency,
    quoteCurrency: security?.QuoteCurrency,
  };
};

export class Future {
  public static readonly type = ProductTypeEnum.Future;
  private readonly _data: FutureData;

  private constructor(private _referenceData: OMSReferenceDataState, data: FutureData) {
    this._data = data;
  }

  public static createFromBlank(referenceData: OMSReferenceDataState) {
    const uniqueCcyPairs = Future.getAvailableSymbols(referenceData);
    const uniqueExchangeAndTypes = Future.getAvailableExchanges(referenceData);

    const data = {
      exchangeAndTypeField: new SelectorField<StringSelectItem>({
        name: 'Exchange & Type',
        idProperty: 'value',
        availableItems: uniqueExchangeAndTypes,
      }),
      symbolField: new SelectorField<SymbolSelectItem>({
        name: 'Symbol',
        idProperty: 'value',
        availableItems: uniqueCcyPairs,
      }),
      expiryField: new SelectorField<StringSelectItem>({
        name: 'Expiry',
        idProperty: 'value',
        isDisabled: true,
      }),
      marketAccountField: new MultiSelectorField<StringSelectItem>({
        name: 'Market Account',
        idProperty: 'value',
      }),
    };

    return new Future(referenceData, data);
  }

  public static createFromSecurity(referenceData: OMSReferenceDataState, security: Security): Future {
    const market = security?.Markets?.[0];
    const type = security?.SettleValueType;
    const expiration = security.Expiration;

    if (!market || !type || !expiration) {
      throw new Error(`${security.Symbol} is not a Future`);
    }

    const exchangeAndType = getExchangeAndTypeFromSecurity(security, referenceData.markets.marketsByName);

    const symbol = getSymbolSelectItem(security);
    const availableSymbols = Future.getSymbolsForExchange(referenceData, exchangeAndType);
    const availableExchanges: StringSelectItem[] = Future.getExchangesForSymbol(referenceData, symbol);
    const availableExpiries: StringSelectItem[] = Future.getExpiriesForExchange(referenceData, exchangeAndType, symbol);

    const data = {
      exchangeAndTypeField: new SelectorField({
        name: 'Exchange & Type',
        idProperty: 'value',
        value: availableExchanges.find(ex => ex.value === exchangeAndType),
        availableItems: availableExchanges,
      }),
      symbolField: new SelectorField({
        name: 'Coin',
        idProperty: 'value',
        value: availableSymbols.find(c => c.value === symbol.value),
        availableItems: availableSymbols,
      }),
      expiryField: new SelectorField<StringSelectItem>({
        name: 'Expiry',
        idProperty: 'value',
        value: availableExpiries.find(expiry => expiry.value === expiration),
        availableItems: availableExpiries,
      }),
      marketAccountField: new MultiSelectorField<StringSelectItem>({
        name: 'Market Account',
        idProperty: 'value',
      }),
    };

    return new Future(referenceData, data);
  }

  public get data(): FutureData {
    return this._data;
  }

  public get security(): Security | undefined {
    const { symbolField, exchangeAndTypeField, expiryField } = this.data;
    if (!symbolField.hasValue || !exchangeAndTypeField.hasValue || !expiryField.hasValue) {
      return undefined;
    }
    const [BaseCurrency, QuoteCurrency] = symbolField.value!.value.split('-');
    const type = getSettlementTypeFromExchangeAndType(exchangeAndTypeField.value?.value);

    return this._referenceData.securities.futures.find(future => {
      if (MarketsWithMicroContracts.includes(symbolField.value?.exchange || '')) {
        return future.DisplaySymbol === symbolField.value?.value;
      }

      const marketShortName = getShortExchangeName(future, this._referenceData.markets.marketsByName);

      return (
        future.BaseCurrency === BaseCurrency &&
        future.QuoteCurrency === QuoteCurrency &&
        exchangeAndTypeField.value!.value.includes(marketShortName) &&
        future.SettleValueType === type &&
        future.Expiration === expiryField.value!.value
      );
    });
  }

  public updateSymbol = (symbol: SymbolSelectItem | undefined) => {
    const newData = {
      ...this._data,
      symbolField: this._data.symbolField.updateValue(symbol),
      exchangeAndTypeField: this._data.exchangeAndTypeField.updateAvailableItems(
        symbol
          ? Future.getExchangesForSymbol(this._referenceData, symbol)
          : Future.getAvailableExchanges(this._referenceData)
      ),
    };
    return this.updateData(newData);
  };

  public updateExchangeAndType = (exchangeAndType: StringSelectItem | undefined) => {
    const newData = {
      ...this._data,
      exchangeAndTypeField: this._data.exchangeAndTypeField.updateValue(exchangeAndType),
      symbolField: this._data.symbolField.updateAvailableItems(
        exchangeAndType
          ? Future.getSymbolsForExchange(this._referenceData, exchangeAndType.value)
          : Future.getAvailableSymbols(this._referenceData)
      ),
    };
    return this.updateData(newData);
  };

  public updateExpiry = (expiry: StringSelectItem | undefined) => {
    const newData = {
      ...this._data,
      expiryField: this._data.expiryField.updateValue(expiry),
    };
    return this.updateData(newData);
  };

  public updateMarketAccount = (marketAccounts: StringSelectItem[] | undefined) => {
    const newData = {
      ...this._data,
      marketAccountField: this._data.marketAccountField.updateValue(marketAccounts),
    };
    return this.updateData(newData);
  };

  public getType(): ProductTypeEnum {
    return Future.type;
  }

  private updateData(data: Partial<FutureData>): Future {
    const newData = {
      ...this._data,
      ...data,
    };
    return new Future(this._referenceData, this.invariant(newData));
  }

  private invariant(data: FutureData): FutureData {
    const updated = {
      ...data,
      expiryField: data.expiryField.setDisabled(true),
    };

    if (updated.exchangeAndTypeField.hasValue && updated.symbolField.hasValue) {
      updated.expiryField = updated.expiryField
        .setDisabled(false)
        .updateAvailableItems(
          Future.getExpiriesForExchange(
            this._referenceData,
            updated.exchangeAndTypeField.value!.value,
            updated.symbolField.value!
          )
        );
    }

    return updated;
  }

  private static getAvailableSymbols(referenceData: OMSReferenceDataState): SymbolSelectItem[] {
    // reduce to map so we get rid of duplicates, i.e. there are multiple futures with 'BTC-USD' as ccyPair
    const futureSymbols = referenceData.securities.futures.reduce((acc, future) => {
      const symbolSelectItem = getSymbolSelectItem(future);
      return acc.set(symbolSelectItem.value, symbolSelectItem);
    }, new Map());

    return Array.from(futureSymbols.values());
  }

  private static getAvailableExchanges(referenceData: OMSReferenceDataState): StringSelectItem[] {
    const futures = referenceData.securities.futures;
    const markets = futures.map(future => getExchangeAndTypeFromSecurity(future, referenceData.markets.marketsByName));

    return Array.from(new Set(markets)).map(ex => ({
      label: ex.split('&')[0],
      value: ex,
    }));
  }

  private static getExchangesForSymbol(
    referenceData: OMSReferenceDataState,
    symbol: SymbolSelectItem
  ): StringSelectItem[] {
    if (!symbol) {
      return Future.getAvailableExchanges(referenceData);
    }

    const futures = referenceData.securities.futuresByCurrencyPair.get(symbol.underlyingCcyPair) || [];
    const allSymbols = referenceData.securities.futures.map(getSymbolSelectItem);

    const markets = futures
      .filter(future => {
        const exchangesSupportingSymbol = allSymbols.filter(s => s.value === symbol.value).map(c => c.exchange);
        return exchangesSupportingSymbol.includes(future.Markets[0]);
      })
      .map(future => getExchangeAndTypeFromSecurity(future, referenceData.markets.marketsByName));

    return Array.from(new Set(markets)).map(ex => ({
      label: ex.split('&')[0],
      value: ex,
    }));
  }

  private static getSymbolsForExchange(
    referenceData: OMSReferenceDataState,
    exchangeAndType: string
  ): SymbolSelectItem[] {
    if (!exchangeAndType) {
      return Future.getAvailableSymbols(referenceData);
    }
    const futures = referenceData.securities.futures || [];
    const relevantFutures = futures.filter(p =>
      isSecurityExchangeAndTypeMatch(p, exchangeAndType, referenceData.markets.marketsByName)
    );

    const futureSymbols = relevantFutures.reduce((acc, future) => {
      const symbolSelectItem = getSymbolSelectItem(future);
      return acc.set(symbolSelectItem.value, symbolSelectItem);
    }, new Map());

    return Array.from(futureSymbols.values());
  }

  private static getExpiriesForExchange(
    referenceData: OMSReferenceDataState,
    exchangeAndType: string,
    currencyPair: SymbolSelectItem
  ): StringSelectItem[] {
    const type = getSettlementTypeFromExchangeAndType(exchangeAndType);

    const expirationByMarket = referenceData.securities.futuresByMarketByCurrency.get(currencyPair.underlyingCcyPair)!;
    const market = exchangeAndType.split('&')[1];
    const expirations = expirationByMarket?.get(market) || new Set();

    const expiries = Array.from(expirations.values())
      .filter(future => (type ? future.SettleValueType === type : true))
      .filter(future =>
        MarketsWithMicroContracts.includes(currencyPair.exchange)
          ? future.DisplaySymbol === currencyPair.value
          : !MarketsWithMicroContracts.includes(future.Markets[0])
      )
      .map(future => future.Expiration)
      .sort((aExpiry, bExpiry) => compareAsc(new Date(aExpiry), new Date(bExpiry)));

    return Array.from(new Set(expiries)).map(expiry => ({
      label: formattedUTCDate(expiry, `{dd} {Mon} {yyyy}`),
      value: expiry,
    }));
  }
}

Future[immerable] = true;
