import { useCallback, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import type { UseDisclosureProps, UseDisclosureReturn } from './useDisclosure';
import { useDisclosure } from './useDisclosure';

export type UseDisclosureWithContextReturn<T> = ReturnType<typeof useDisclosureWithContext<T>>;
export function useDisclosureWithContext<TContext>(props?: UseDisclosureProps): Omit<
  UseDisclosureReturn,
  'open' | 'getButtonProps' | 'getDisclosureProps'
> & {
  open: (context: TContext) => void;
  onCloseComplete: () => void;
  context: TContext | undefined;
  openId: string | undefined;
} {
  const disclosure = useDisclosure(props);
  const { open: onDisclosureOpen } = disclosure;
  const [context, setContext] = useState<TContext>();
  const [openId, setOpenId] = useState<string>();
  const open = useCallback(
    (context: TContext) => {
      setContext(context);
      setOpenId(v4());
      onDisclosureOpen();
    },
    [onDisclosureOpen]
  );
  const onCloseComplete = useCallback(() => {
    setContext(undefined);
  }, []);
  const result = useMemo(() => {
    return {
      ...disclosure,
      openId,
      context,
      open,
      onCloseComplete,
    };
  }, [context, disclosure, onCloseComplete, open, openId]);
  return result;
}
