import { CurrencyIcon } from '@talos/kyoko';
import { CurrencyText, CurrencyWrapper } from '../styles';

export const CurrencyDisplayer = ({
  currency,
  gap,
  iconSize,
  className,
}: {
  currency: string;
  gap?: number;
  iconSize?: number;
  className?: string;
}) => {
  return (
    <CurrencyWrapper gap={gap} className={className}>
      <CurrencyIcon currency={currency} size={iconSize ? iconSize : 24} />
      <CurrencyText>{currency}</CurrencyText>
    </CurrencyWrapper>
  );
};
