import {
  OrdTypeEnum,
  abbreviateId,
  format,
  isCounterCurrency,
  useDynamicCallback,
  useSecuritiesContext,
  type SideEnum,
} from '@talos/kyoko';
import { useCustomersByName } from 'hooks/useCustomer';
import { compact } from 'lodash';
import { useStrategies } from 'providers/StrategiesProvider';

interface ExecutionReportLike {
  OrderID: string;
  Side: SideEnum;
  Symbol: string;
  Currency?: string;
  AmountCurrency?: string;
  OrderQty: string;
  Price?: string;
  OrdType: OrdTypeEnum;
  Strategy?: string;

  Counterparty?: string;
}

/**
 * Returns a utility function that can compute a short one line summary of an order/execution report.
 * Useful for copying an order to the clipboard.
 */
export function useGetExecutionReportSummary(): (executionReportLike: ExecutionReportLike) => string {
  const customersByName = useCustomersByName();
  const { strategiesByName } = useStrategies();
  const { securitiesBySymbol } = useSecuritiesContext();
  return useDynamicCallback((executionReportLike: ExecutionReportLike) => {
    const id = `#${abbreviateId(executionReportLike.OrderID)}`;
    const side = executionReportLike.Side.toUpperCase();

    const security = securitiesBySymbol.get(executionReportLike.Symbol);
    const symbol = security?.DisplaySymbol ?? executionReportLike.Symbol;
    const isCcy = isCounterCurrency(executionReportLike.Currency, security!);
    const size = format(executionReportLike.OrderQty, {
      spec: isCcy ? security?.DefaultPriceIncrement : security?.DefaultSizeIncrement,
    });
    const sizeCurrency = executionReportLike.Currency;
    const price =
      executionReportLike.OrdType === OrdTypeEnum.Market
        ? ''
        : `@ ${format(executionReportLike.Price, {
            spec: isCcy ? security?.DefaultSizeIncrement : security?.DefaultPriceIncrement,
          })}`;
    const priceCurrency = executionReportLike.AmountCurrency;
    const customer =
      customersByName?.get(executionReportLike.Counterparty!)?.DisplayName ?? executionReportLike.Counterparty;
    const strategy =
      executionReportLike.OrdType === OrdTypeEnum.Market
        ? 'Market'
        : strategiesByName.get(executionReportLike.Strategy!)?.DisplayName ?? executionReportLike.Strategy ?? 'Limit';
    return compact([id, customer, side, symbol, size, sizeCurrency, price, priceCurrency, strategy]).join(' ');
  });
}
