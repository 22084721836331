import {
  formattedDate,
  InlineFormattedNumber,
  isOption,
  NumericField,
  Text,
  Unit,
  type Market,
  type MarketDataStatistics,
  type Security,
} from '@talos/kyoko';
import { INDEX_PRICE, MARK_PRICE_IV, NEXT_FUNDING_TIME, PREDICTED_NEXT_FUNDING_RATE } from 'tokens/marketDataStats';
import {
  OPTIONS_GREEKS,
  PERCENT_KEYS,
  POSITION_CURRENCY_KEYS,
  QUOTE_CURRENCY_KEYS,
  SETTLEMENT_CURRENCY_KEYS,
  UNDERLYING_QUOTE_CURRENCY_KEYS,
} from './consts';
import { rateify } from './rateify';
import { timedRate } from './timedRate';

export interface FormatValueProps {
  marketDataStatistics: MarketDataStatistics;
  marketDataKey: keyof MarketDataStatistics;
  security: Security;
  marketsByName: Map<string, Market>;
}
export function FormatValue({ marketDataStatistics, marketDataKey, security, marketsByName }: FormatValueProps) {
  const {
    PositionCurrency,
    SettlementCurrency,
    QuoteCurrency,
    MinPriceIncrement,
    MinSizeIncrement,
    UnderlyingQuoteCurrency,
  } = security || {};
  const rawValue = marketDataStatistics[marketDataKey]!;

  // Pattern match on true
  switch (true) {
    case marketDataKey === PREDICTED_NEXT_FUNDING_RATE:
      return <>{timedRate(marketDataStatistics, PREDICTED_NEXT_FUNDING_RATE, NEXT_FUNDING_TIME)}</>;
    case marketDataKey.endsWith('Time'):
      return <>{formattedDate(rawValue)}</>;
    case marketDataKey.endsWith('Rate'):
      return <>{rateify(rawValue)}</>;
    case QUOTE_CURRENCY_KEYS.includes(marketDataKey):
      return (
        <InlineFormattedNumber increment={MinPriceIncrement} number={rawValue} currency={QuoteCurrency} align="right" />
      );
    case UNDERLYING_QUOTE_CURRENCY_KEYS.includes(marketDataKey):
      return (
        <InlineFormattedNumber
          increment={MinPriceIncrement}
          number={rawValue}
          currency={UnderlyingQuoteCurrency}
          align="right"
        />
      );
    case PERCENT_KEYS.includes(marketDataKey):
      return (
        <Text size="fontSizeSmall">
          {new NumericField({ value: rawValue, unit: Unit.Percent, precision: 2 }).displayValue}%
        </Text>
      );
    case POSITION_CURRENCY_KEYS.includes(marketDataKey):
      return (
        <InlineFormattedNumber
          increment={MinSizeIncrement}
          number={rawValue}
          currency={PositionCurrency}
          align="right"
        />
      );
    case SETTLEMENT_CURRENCY_KEYS.includes(marketDataKey):
      return (
        <InlineFormattedNumber
          increment={MinSizeIncrement}
          number={rawValue}
          currency={SettlementCurrency}
          align="right"
        />
      );
    case marketDataKey === INDEX_PRICE:
      return (
        <InlineFormattedNumber
          increment={MinPriceIncrement}
          number={rawValue}
          currency={isOption(security) ? UnderlyingQuoteCurrency : QuoteCurrency}
          align="right"
        />
      );
    case isOption(security) && (marketDataKey === MARK_PRICE_IV || OPTIONS_GREEKS.includes(marketDataKey)):
      return (
        <InlineFormattedNumber
          increment={marketDataKey === 'Delta' ? '0.0001' : marketDataKey === 'Gamma' ? '0.000001' : '0.01'}
          number={rawValue}
          align="right"
        />
      );
    case marketDataKey === 'Market': {
      const market = marketsByName.get(rawValue);
      return <>{market?.DisplayName ?? rawValue}</>;
    }
    default:
      return <>{rawValue}</>;
  }
}
