import {
  DELETE,
  ModeEnum,
  PATCH,
  POST,
  PRICING_RULE,
  UpdateActionEnum,
  request,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  useUserContext,
  type Customer,
  type PricingRule,
} from '@talos/kyoko';
import type React from 'react';
import { createContext, memo, useCallback, useContext, useMemo } from 'react';
import { map, scan, shareReplay } from 'rxjs/operators';
import { OrgConfigurationKey, useOrgConfiguration } from './OrgConfigurationProvider';

export const PRICING_CONFIG_UPDATE_ID = 'PRICING_CONFIG_UPDATE_ID';

const DEFAULT_PRICING_RULE: PricingRule = {
  Counterparty: '',
  Mode: ModeEnum.Disabled,
};

const PricingRules = createContext<PricingRulesProps | null>(null);
PricingRules.displayName = 'PricingRulesContext';

export const usePricingRules = () => {
  const context = useContext(PricingRules);
  if (context == null) {
    throw new Error('Missing PricingRules.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export interface PricingRulesProps {
  pricingRules: PricingRule[] | undefined;
  globalDefault: PricingRule;
  isGlobalDefaultLoading: boolean;
  newCounterparty: (data: Pick<Customer, 'Name' | 'DisplayName'>) => Promise<any>;
  updateCounterparty: (data: Partial<Customer>) => Promise<any>;
  deleteCounterparty: (counterparty: string) => Promise<any>;
  updatePricingRule: (data: Partial<PricingRule>) => Promise<any>;
}

export const PricingRulesProvider = memo(function PricingRulesProvider(props: React.PropsWithChildren<unknown>) {
  // can't useFeatureFlag() here because it causes a circular dependency.
  const { getConfig } = useOrgConfiguration();
  const pricingRulesPageSize = getConfig(OrgConfigurationKey.PricingRulesPageSize, 5000);
  const { data: pricingRulesSub } = useStaticSubscription<PricingRule>(
    { name: PRICING_RULE, limit: pricingRulesPageSize, tag: 'PricingRulesProvider' },
    { loadAll: true }
  );
  const { data: globalPricingRuleSub, isLoading: isGlobalDefaultLoading } = useStaticSubscription(
    { name: PRICING_RULE, OnlyGlobalRule: true, tag: 'PricingRulesProvider' },
    { loadAll: true }
  );

  const pricingRulesObs = useObservable<PricingRule[]>(
    () =>
      pricingRulesSub.pipe(
        scan((pricingRules, json) => {
          json.initial && pricingRules.clear();
          json.data.forEach((rule: PricingRule) => {
            const key = `${rule.Counterparty}${rule.Symbol}`;
            if (pricingRules.get(key)) {
              pricingRules.delete(key);
            }
            if (rule.Counterparty && rule.UpdateAction !== UpdateActionEnum.Remove && rule.Counterparty) {
              pricingRules.set(key, rule);
            }
          });
          return pricingRules;
        }, new Map()),
        map(pricingRules => [...pricingRules.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [pricingRulesSub]
  );

  const GLOBAL_DEFAULT_KEY = 'GLOBAL_DEFAULT';
  const globalPricingRuleObs = useObservable(
    () =>
      globalPricingRuleSub.pipe(
        scan((pricingRules, json) => {
          json.initial && pricingRules.clear();
          json.data.forEach((rule: PricingRule) => {
            // Global default
            const newDefault = rule;
            if (!newDefault.PricingAggregation) {
              // Apply Default Pricing Aggregation "Dealer" to the Global Default
              newDefault.PricingAggregation = 'dealer';
            }
            if (newDefault.Spread) {
              // Delete "Spread" and Pre-Populate "BidSpread" and "OfferSpread"
              if (newDefault.BidSpread == null) {
                newDefault.BidSpread = newDefault.Spread;
              }
              if (newDefault.OfferSpread == null) {
                newDefault.OfferSpread = newDefault.Spread;
              }
              delete newDefault.Spread;
            }
            pricingRules.set(GLOBAL_DEFAULT_KEY, newDefault);
          });
          return pricingRules;
        }, new Map()),
        map(pricingRules => pricingRules.get(GLOBAL_DEFAULT_KEY) ?? DEFAULT_PRICING_RULE),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [globalPricingRuleSub]
  );

  const globalDefault = useObservableValue(() => globalPricingRuleObs, [globalPricingRuleObs], DEFAULT_PRICING_RULE);
  const pricingRules = useObservableValue(() => pricingRulesObs, [pricingRulesObs]);

  const { orgApiEndpoint } = useUserContext();
  const endpoint = `${orgApiEndpoint}/pricing-rules`;
  const customersEndpoint = `${orgApiEndpoint}/customers`;

  const newCounterparty = useCallback(
    counterparty => request(POST, customersEndpoint, counterparty),
    [customersEndpoint]
  );
  const updatePricingRule = useCallback(pricingRule => request(PATCH, endpoint, pricingRule), [endpoint]);
  const updateCounterparty = useCallback(
    counterparty => request(PATCH, customersEndpoint, counterparty),
    [customersEndpoint]
  );
  const deleteCounterparty = useCallback(
    counterparty => request(DELETE, `${customersEndpoint}/${encodeURIComponent(counterparty)}`),
    [customersEndpoint]
  );

  const value = useMemo<PricingRulesProps>(
    () => ({
      pricingRules,
      globalDefault,
      isGlobalDefaultLoading,
      newCounterparty,
      updatePricingRule,
      deleteCounterparty,
      updateCounterparty,
    }),
    [
      pricingRules,
      globalDefault,
      isGlobalDefaultLoading,
      newCounterparty,
      updatePricingRule,
      deleteCounterparty,
      updateCounterparty,
    ]
  );

  return <PricingRules.Provider value={value}>{props.children}</PricingRules.Provider>;
});
