import { TransferStatusEnum, type Transfer } from '@talos/kyoko';
import type { MarketPositions } from 'containers/Portfolio/types';
import { useMemo } from 'react';
import { useTransfersByPosition } from './useTransfersByPosition';

const PROCESSING_STATES = [TransferStatusEnum.Pending, TransferStatusEnum.Processing];

export const useTransfersProcessing = (marketPositions: MarketPositions[]) => {
  const transfersByPosition = useTransfersByPosition();

  const transfersProcessing = useMemo(() => {
    if (!transfersByPosition) {
      return [];
    }
    let transfers: Transfer[] = [];

    for (const mpos of marketPositions) {
      const mktAccMap = transfersByPosition.get(mpos.MarketAccount);
      if (!mktAccMap) {
        continue;
      }

      for (const pos of mpos.Positions) {
        const positionTransfers = mktAccMap.get(pos.Currency);
        if (positionTransfers) {
          const ongoingPositionTransfers = positionTransfers.filter(transfer =>
            PROCESSING_STATES.includes(transfer.status)
          );
          transfers = transfers.concat(ongoingPositionTransfers);
        }
      }
    }

    return transfers;
  }, [transfersByPosition, marketPositions]);

  return transfersProcessing;
};
