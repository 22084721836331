import type { HierarchicalGridData } from '../components/PortfolioRiskBlotter/useRollupTreeGridBuilders';
import type { PortfolioRiskGridData } from './PortfolioRiskGridData';
import type { PortfolioRiskSubscriptionData } from './PortfolioRiskSubscriptionData';

export class PortfolioRiskDataItem implements HierarchicalGridData {
  hierarchicalRowInfo: string[];
  skipAggregation: boolean;
  rowID: string;

  wsData: PortfolioRiskSubscriptionData;
  gridData: PortfolioRiskGridData;

  constructor(gridData: PortfolioRiskGridData, wsData: PortfolioRiskSubscriptionData) {
    this.hierarchicalRowInfo = gridData.hierarchicalRowInfo;
    this.skipAggregation = gridData.skipAggregation;
    this.rowID = gridData.rowID;

    this.wsData = wsData;
    this.gridData = gridData;
  }
}
