import { AccordionRow, AccordionRowBody, AccordionRowCell, Checkbox, useAccordion } from '@talos/kyoko';
import Big from 'big.js';
import type React from 'react';
import { memo, useCallback, useMemo } from 'react';
import { HeaderMarketAccountLogo } from '../components/HeaderMarketAccountLogo';
import { usePositionsFormContext } from '../providers/PositionsFormProvider';
import { Exposure } from './Exposure/Exposure';
import { PositionsActionType } from './PositionsFormReducer';
import { SettlementBody } from './SettlementBody/SettlementBody';
import { SummarizedPositions } from './SummarizedPositions/SummarizedPositions';
import { AnimatedOnlyButton, DealerWrapper, SettlementAccordionRowHeader } from './styles';
import type { RelativeMarketPositions } from './useRelativeMarketPositions';
import { CheckedType, getCheckedType, isChecked, isIndeterminate, isUnchecked } from './utils';

export interface DealerSettlementAccordionProps {
  marketPositions: RelativeMarketPositions;
}

export const DealerSettlementAccordion = memo(({ marketPositions }: DealerSettlementAccordionProps) => {
  const accordion = useAccordion({
    id: marketPositions.MarketAccount,
  });

  const { state, dispatch } = usePositionsFormContext();
  const checked: CheckedType = useMemo(() => {
    const myMarketAccountMap = state.get(marketPositions.MarketAccount);
    if (!myMarketAccountMap) {
      return CheckedType.Unchecked;
    } // shouldnt happen

    const wrappedMap = new Map([[marketPositions.MarketAccount, myMarketAccountMap]]);
    return getCheckedType(wrappedMap);
  }, [state, marketPositions]);

  const noOutgoingPosition = useMemo(() => {
    return !marketPositions.Positions.some(pos => Big(pos.Amount).lt(0));
  }, [marketPositions]);

  const checkboxDisabled = useMemo(() => {
    if (checked) {
      // if we're checked, we should always be able to uncheck ourselves.
      return false;
    } else {
      // The checkbox should be disabled if there isn't any outgoing (negative amount) position within this market account
      return noOutgoingPosition;
    }
  }, [checked, noOutgoingPosition]);

  const handleCheckChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newChecked = e.target.checked;
      dispatch({
        type: PositionsActionType.MarketAccountCheckedChange,
        payload: {
          marketAccount: marketPositions.MarketAccount,
          checked: newChecked,
        },
      });
    },
    [dispatch, marketPositions]
  );

  const mktAccMap = state.get(marketPositions.MarketAccount);

  return (
    <AccordionRow {...accordion}>
      <SettlementAccordionRowHeader showOnlyButton={!isUnchecked(checked)} applyLowerOpacity={noOutgoingPosition}>
        <AccordionRowCell>
          <Checkbox
            disabled={checkboxDisabled}
            onChange={handleCheckChange}
            checked={isChecked(checked)}
            indeterminate={isIndeterminate(checked)}
            onClick={e => e.stopPropagation()}
          />
        </AccordionRowCell>
        <AccordionRowCell>
          <DealerWrapper>
            <HeaderMarketAccountLogo marketAccountName={marketPositions.MarketAccount} />
            <AnimatedOnlyButton mktacc={marketPositions.MarketAccount} />
          </DealerWrapper>
        </AccordionRowCell>
        <SummarizedPositions positions={marketPositions.Positions} />
        <AccordionRowCell>
          <Exposure relativeMarketPositions={marketPositions} />
        </AccordionRowCell>
      </SettlementAccordionRowHeader>
      <AccordionRowBody p="0">
        <tr>
          <SettlementBody formStateMap={mktAccMap} marketPositions={marketPositions} />
        </tr>
      </AccordionRowBody>
    </AccordionRow>
  );
});
