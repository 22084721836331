import {
  NotificationVariants,
  Toasts,
  TransferStatusEnum,
  useObservableValue,
  useToasts,
  wsPickFromMemoryStream,
  wsSubscriptionMemory,
  type Transfer,
} from '@talos/kyoko';
import { useProcessingTransfersContext } from 'containers/Portfolio/providers/ProcessingTransfersProvider';
import type { MarketPositions } from 'containers/Portfolio/types';
import { useTransfers } from 'providers';
import { useEffect, useState } from 'react';
import { useUnmount } from 'react-use';
import { map } from 'rxjs';
import { TransfersInfoToastContent, type TransfersToastRow } from './TransfersInfoToastContent';
import { ToastContent } from './TransfersProcessingToastContent';
import { SettlementToastContainer } from './styles';

interface RelatedTransfersWidgetProps {
  marketPositions: MarketPositions[];
}

const INFO_TIMEOUT = 3000;
const SPINNER_TIMEOUT = INFO_TIMEOUT + 1000;

export const RelatedTransfersWidget = ({ marketPositions }: RelatedTransfersWidgetProps) => {
  const { toasts, add: addToast, remove: removeToast } = useToasts();
  const { nTransfersProcessing } = useProcessingTransfersContext();
  const { transfersStreamObs } = useTransfers();

  const latestTransferData = useObservableValue(
    () =>
      transfersStreamObs.pipe(
        // Shove all transfers into memory
        wsSubscriptionMemory(transfer => transfer.TransferID),
        // As new messages come across, pick only the relevant properties we care about and make new objects.
        // Then, compare the new and old version of each incoming entry to see if there's a relevant change.
        // This pipe will only emit a message if there is some relevant change.
        wsPickFromMemoryStream({
          getUniqueKey: transfer => transfer.TransferID,
          pick: transfer => ({
            TransferID: transfer.TransferID,
            Currency: transfer.Currency,
            Amount: transfer.Amount,
            Status: transfer.Status,
          }),
        }),
        map(response => response.data)
      ),
    [transfersStreamObs]
  );
  const [spinnerClearTimeout, setSpinnerClearTimeout] = useState<number>();

  useEffect(() => {
    if (latestTransferData != null) {
      const rows = transferStreamToToastRows(latestTransferData);
      if (rows.length === 0) {
        return;
      }
      const toastVariant = rows.some(row => row.status === TransferStatusEnum.Rejected)
        ? NotificationVariants.Warning
        : NotificationVariants.Positive;
      addToast({
        timeout: INFO_TIMEOUT,
        text: <TransfersInfoToastContent rows={rows} />,
        variant: toastVariant,
      });
    }
  }, [latestTransferData, addToast]);

  useEffect(() => {
    if (nTransfersProcessing > 0 && toasts.length === 0) {
      addToast({
        id: '1',
        text: <ToastContent marketPositions={marketPositions} />,
      });
    } else if (nTransfersProcessing === 0 && toasts.length > 0 && !spinnerClearTimeout) {
      // there are 0 transfers processing but toasts still showing. let's initiate timer to clear our main toast
      const timeout = window.setTimeout(() => {
        removeToast('1');
        window.clearTimeout(spinnerClearTimeout);
        setSpinnerClearTimeout(undefined);
      }, SPINNER_TIMEOUT);
      setSpinnerClearTimeout(timeout);
    } else if (nTransfersProcessing > 0 && toasts.length > 0 && spinnerClearTimeout != null) {
      // we have transfers processing and a toast which is being cleared soon. revoke the clearing timer.
      window.clearTimeout(spinnerClearTimeout);
      setSpinnerClearTimeout(undefined);
    }
  }, [nTransfersProcessing, addToast, toasts, removeToast, marketPositions, spinnerClearTimeout]);

  useUnmount(() => {
    // Make sure we clear any remaining timeouts.
    if (spinnerClearTimeout) {
      window.clearTimeout(spinnerClearTimeout);
    }
  });

  return (
    <SettlementToastContainer>
      <Toasts toasts={toasts} remove={removeToast} />
    </SettlementToastContainer>
  );
};

function transferStreamToToastRows(
  transfers: Pick<Transfer, 'Amount' | 'TransferID' | 'Status' | 'Currency'>[]
): TransfersToastRow[] {
  // iterate over the received transfers and map to rows
  return transfers
    .filter(
      transfer => transfer.Status === TransferStatusEnum.Completed || transfer.Status === TransferStatusEnum.Rejected
    )
    .map(transfer => ({
      currency: transfer.Currency,
      amount: transfer.Amount,
      status: transfer.Status,
    }));
}
