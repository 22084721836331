import type { TreasuryLinkDirectionEnum, TreasuryLinkTypeEnum } from '@talos/kyoko';
import { usePortfolioAccounts } from '../../../../providers';
import { getEffectiveTreasuryLink } from '../../Addresses/hooks/useEffectiveTreasuryLink';
import { usePortfolioTreasury } from '../../providers';

interface UseAvailableBalanceOutput {
  availableBalance: string | undefined;
  sourceMarketAccount: string | undefined;
}

/**
 * Given a market account and a currency, fetch the current treasury balance for this pairing
 * The MarketAccount (venue) is used to lookup the respective treasury market account (eg fireblocks), which is where the available balance is stored
 * @param marketAccount
 * @param currency
 */
export const useAvailableBalance = (
  marketAccount: string,
  currency: string,
  type: TreasuryLinkTypeEnum,
  direction: TreasuryLinkDirectionEnum
): UseAvailableBalanceOutput => {
  const { treasuryLinksBySpecificnessKey } = usePortfolioAccounts();
  const { OTCTreasuryPositionsByMarketAccountAndCurrency } = usePortfolioTreasury();

  const maybeLink =
    treasuryLinksBySpecificnessKey &&
    getEffectiveTreasuryLink({
      treasuryLinksBySpecificnessKey,
      sourceOrDestinationField: 'SourceMarketAccount',
      marketAccount,
      currency,
      type,
      direction,
    });

  const availableBalance = maybeLink?.SourceMarketAccount
    ? OTCTreasuryPositionsByMarketAccountAndCurrency?.get(maybeLink.SourceMarketAccount)?.get(currency)?.Amount
    : undefined;

  return {
    availableBalance,
    sourceMarketAccount: maybeLink?.SourceMarketAccount,
  };
};
