import { Flex, Text, Tooltip } from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import styled from 'styled-components';

export const EllipsisWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colorTextSubtle};
`;

export const AgMarketAccountRenderer = (params: ICellRendererParams) => {
  const valueFormatted = params.valueFormatted;
  const marketAccounts = valueFormatted?.split(', ') ?? [];

  return (
    <EllipsisWrapper>
      <Tooltip
        usePortal
        placement="top-start"
        tooltip={
          <>
            {marketAccounts.map(marketAccount => (
              <Flex alignItems="center" key={marketAccount} justifyContent="flex-start">
                <span>{marketAccount}</span>
              </Flex>
            ))}
          </>
        }
      >
        <EllipsisWrapper>
          <Text color="colorTextDefault">{valueFormatted}</Text>
        </EllipsisWrapper>
      </Tooltip>
    </EllipsisWrapper>
  );
};
