import { useEffect, useRef, useState } from 'react';

/**
 * useServiceInstantiator is a hook that manages a service instance and destroys it when the dependencies change.
 * @param serviceInstantiator serviceInstantiator is a function that creates a new instance of the service.
 * @param deps deps are the dependencies that will trigger the service to be recreated.
 * @returns The service instance.
 */
export function useServiceInstantiator<TService extends { destroy: () => void }>(
  serviceInstantiator: () => TService,
  deps: unknown[]
): TService {
  const [_, forceUpdate] = useState(0); // state variable to force a re-render
  const serviceRef = useRef<TService>();
  const isActive = useRef(false);
  if (!serviceRef.current) {
    serviceRef.current = serviceInstantiator();
    isActive.current = true;
  }

  useEffect(() => {
    if (!isActive.current) {
      // Create a new service with the updated dependencies
      // ensure a re-render to force the new service to be used
      serviceRef.current = serviceInstantiator();
      isActive.current = true;
      forceUpdate(prev => prev + 1);
    }

    return () => {
      // Destroy the service when the dependencies change
      // non-null assertion is safe here because we initialize the serviceRef with a value
      serviceRef.current!.destroy();
      isActive.current = false;
    };
    // deps are managed by the eslint hook plugin
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return serviceRef.current;
}
