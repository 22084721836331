import { useHomeCurrencyRatesValue, type CurrencyConversionRate } from '@talos/kyoko';
import { useEffect, useMemo, useState } from 'react';
import { usePortfolioPositions } from '../providers/PortfolioPositionsProvider';
import { usePortfolioTreasury } from '../providers/PortfolioTreasuryProvider';

/**
 * Listens to all Positions and provides a map of Currency -> ConversionRate for all the Position currencies
 * @returns a map for all conversions you need in home currency for Positions
 */
export const usePositionsConversions = () => {
  const { OTCPositionsList } = usePortfolioPositions();
  const { OTCTreasuryPositions } = usePortfolioTreasury();
  const [currs, setCurrs] = useState<string[]>([]);

  useEffect(() => {
    setCurrs(prevCurrs => {
      const prevCurrsSet = new Set(prevCurrs);
      const all = (OTCPositionsList ?? []).concat(OTCTreasuryPositions ?? []);
      const newCurrs = all.flatMap(mpos => mpos.Positions.map(pos => pos.Currency));
      const newCurrsSet = new Set(newCurrs);
      const sameLength = prevCurrsSet.size === newCurrsSet.size;
      const sameContents = sameLength && [...prevCurrsSet.values()].every(curr => newCurrsSet.has(curr));
      return sameContents ? prevCurrs : [...newCurrsSet];
    });
  }, [OTCPositionsList, OTCTreasuryPositions]);

  const rates: CurrencyConversionRate[] | undefined = useHomeCurrencyRatesValue(currs);

  const ratesByCurrency = useMemo(() => {
    if (!rates) {
      return undefined;
    }

    return new Map(rates.map(rate => [rate.Asset, rate]));
  }, [rates]);

  return ratesByCurrency;
};
