import {
  Button,
  FormControlSizes,
  HamburgerMenu,
  Icon,
  IconName,
  MixpanelEvent,
  Text,
  filterByCellValueMenuItem,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type Column,
  type ColumnDef,
  type ExecutionReport,
  type FilterableProperty,
  type MixpanelInstance,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-enterprise';
import { isCurrentRouteMonitoringOrderDetails, isCurrentRouteOrderDetails } from 'containers/Routes/routes';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { compact } from 'lodash';
import { useMemo, type ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { colIDToFilterBuilderKey } from './useExecutionReportFilter';

export function useExecutionReportMenu({
  openClause,
  filterableProperties,
  generateOrderDetailsRoute,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const mixpanel = useMixpanel();
  const history = useHistory();
  const location = useLocation();
  const { handleClickJson, jsonModal } = useJsonModal();

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const openDetailsItem = useDynamicCallback((report: ExecutionReport) =>
    openDetailsMenuItem({
      report,
      mixpanel,
      history,
      generateOrderDetailsRoute,
    })
  );

  const columns = useMemo(
    () =>
      compact([
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => {
              return (
                <>
                  <HamburgerMenu {...params} onShowJson={handleClickJson} />
                  {!isCurrentRouteMonitoringOrderDetails(location.pathname) &&
                    !isCurrentRouteOrderDetails(location.pathname) &&
                    params.node.data && (
                      <Button
                        size={FormControlSizes.Small}
                        ghost
                        onClick={() => {
                          mixpanel.track(MixpanelEvent.OpenOrderDetails);
                          history.push(
                            generateOrderDetailsRoute({
                              orderID: params.node.data.OrderID!,
                              tab: 'details',
                              type: 'principal',
                            })
                          );
                        }}
                        width="100%"
                      >
                        <Text mr="spacingDefault">Open Details</Text> <Icon icon={IconName.Deepdive} />
                      </Button>
                    )}
                </>
              );
            },
          },
        },
      ] satisfies ColumnDef<ExecutionReport>[]),
    [handleClickJson, location.pathname, generateOrderDetailsRoute, history, mixpanel]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const executionReport = params?.node?.data;
    if (executionReport == null) {
      return [];
    }
    return compact([
      {
        name: `Show JSON`,
        action: () => handleClickJson(executionReport),
      },
      ...filterByCellValueMenuItem({
        params,
        filterableProperties,
        openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      'separator',
      !isCurrentRouteMonitoringOrderDetails(location.pathname) &&
        !isCurrentRouteOrderDetails(location.pathname) &&
        openDetailsItem(executionReport),
      ...getDefaultContextMenuItems(params),
    ]);
  });

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}

const openDetailsMenuItem = ({
  history,
  mixpanel,
  report,
  generateOrderDetailsRoute,
}: {
  history: ReturnType<typeof useHistory>;
  mixpanel: MixpanelInstance;
  report: ExecutionReport;
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
}) => {
  if (!report.OrderID) {
    return null;
  }
  return {
    name: `Open details`,
    action: () => {
      mixpanel.track(MixpanelEvent.OpenOrderDetails);
      history.push(generateOrderDetailsRoute({ orderID: report.OrderID!, tab: 'details', type: 'principal' }));
    },
    icon: `<i class="ag-icon ${IconName.Deepdive}"/>`,
  };
};
