import { AllocationValueTypeEnum, type Allocation, type SubAccount } from '@talos/kyoko';

export function prettifyAllocationAmount(
  allocation: Allocation,
  allocationValueType?: AllocationValueTypeEnum,
  currency?: string
) {
  return allocationValueType === AllocationValueTypeEnum.Percentage
    ? `${allocation.value}%`
    : `${allocation.value} ${currency || 'Qty'}`;
}

export function prettifyAllocation(
  allocation: Allocation,
  allocationValueType?: AllocationValueTypeEnum,
  currency?: string,
  subAccountsByName?: Map<string, SubAccount>
) {
  const amount = prettifyAllocationAmount(allocation, allocationValueType, currency);
  return `${subAccountsByName?.get(allocation.subAccount)?.DisplayName ?? allocation.subAccount} ${amount}`;
}

export function prettifyAllocations(
  allocations: Allocation[],
  allocationValueType?: AllocationValueTypeEnum,
  currency?: string,
  subAccountsByName?: Map<string, SubAccount>
) {
  return allocations
    .map(allocation => prettifyAllocation(allocation, allocationValueType, currency, subAccountsByName))
    .join(', ');
}
