import { PERIODS, periodToChartDateRange, type PerformanceState } from '../types';

export const DEFAULT_PERFORMANCE_STATE: PerformanceState = {
  showing: 'Performance',
  filter: {},
  period: 'MonthToDate',
  snapshotDate: null,
  pnlUnit: 'amount', // amount is the only pnl unit available to users
  chartRequestDateRange: PERIODS['MonthToDate'].getDateRangeFilter(),
  showDelta: true,
  benchmarks: [],
  pnlLookbacks: [],
  showInsights: true,
  subAccounts: [],
  showZeroBalances: false,
  lifeToDateStartTime: null,
  product: 'spot',
};

/**
 * Returns a up to date default treasury management state
 */
export function getDefaultPerformanceState(): PerformanceState {
  return {
    ...DEFAULT_PERFORMANCE_STATE,
    chartRequestDateRange: periodToChartDateRange(DEFAULT_PERFORMANCE_STATE.period),
  };
}
