import { API_PERMISSION_WILDCARD } from '@talos/kyoko';
import { MultiAndSelectAll } from 'components/MultiAndSelectAll';
import { sortBy } from 'lodash';
import { useSubAccounts } from 'providers';
import { useCallback, useMemo, type Dispatch, type SetStateAction } from 'react';

const ALL_SUB_ACCOUNTS_LABEL = 'All Sub Accounts';

export function SubAccountsDropdown({
  subAccountsToAdd,
  setSubAccountsToAdd,
  onAddSubAccount,
  includeRollups = true,
}: {
  subAccountsToAdd: string[];
  setSubAccountsToAdd: Dispatch<SetStateAction<string[]>>;
  onAddSubAccount: (subAccounts: string[]) => void;
  /** Whether or not to include Sub Account Rollups in the set of selectable Sub Accounts. Defaults to true (effectively showing all Sub Accounts) */
  includeRollups?: boolean;
}) {
  const { allSubAccounts, allSubAccountBooks, subAccountsByName } = useSubAccounts();

  const availableSubAccounts = useMemo(() => {
    return sortBy((includeRollups ? allSubAccounts : allSubAccountBooks) || [], item => item.DisplayName).map(
      s => s.Name
    );
  }, [allSubAccounts, allSubAccountBooks, includeRollups]);

  const getLabel = useCallback(
    (subAccountName: string) => {
      return subAccountName === API_PERMISSION_WILDCARD
        ? ALL_SUB_ACCOUNTS_LABEL
        : subAccountsByName?.get(subAccountName)?.DisplayName || subAccountName;
    },
    [subAccountsByName]
  );

  return (
    <MultiAndSelectAll
      placeholder="Search sub account name..."
      allSelectionsValue={API_PERMISSION_WILDCARD}
      availableSelections={availableSubAccounts}
      selectionsToAdd={subAccountsToAdd}
      setSelectionsToAdd={setSubAccountsToAdd}
      onAddSelections={onAddSubAccount}
      getLabel={getLabel}
    />
  );
}
