import {
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  Divider,
  DrawerFooter,
  Flex,
  HStack,
  LoaderSizes,
  LoaderTalos,
  NotificationVariants,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  Text,
  VStack,
  columnTypes,
  getSettlementInstructionDirectionLabel,
  isSettlementInstructionDirection,
  useBehaviorSubject,
  useBlotterTable,
  useDynamicCallback,
  useGlobalToasts,
  useTabs,
  type ColumnDef,
  type CustomerSettlementPerspectiveEnum,
  type SettlementInstruction,
  type SettlementPreview,
  type SubscriptionResponse,
  type TabProps,
} from '@talos/kyoko';
import type { GridOptions } from 'ag-grid-community';
import { useEffect, useMemo, useState } from 'react';
import { map, shareReplay } from 'rxjs';
import { useTradeSettlementRequests, type SettlementOption } from '../../useTradeSettlementRequests';
import { SettlementExportButton } from './SettlementExportButton';
import { SettlementOverviewCard } from './SettlementOverview';
import { SettlementPerspectiveToggle } from './SettlementPerspectiveToggle';

interface CustomerSettlementDrawerProps {
  settlementOption: SettlementOption;
  close: () => void;
}

const TABS: TabProps[] = [
  {
    label: 'Summary',
  },
];

enum SettlementStep {
  Previewing = 'Previewing',
  Settling = 'Settling',
  Settled = 'Settled',
}

export const CustomerSettlementDrawerContent = ({ settlementOption, close }: CustomerSettlementDrawerProps) => {
  const { previewSettlement, settleTrades } = useTradeSettlementRequests();
  const { add: addToast } = useGlobalToasts();

  const [preview, setPreview] = useState<SettlementPreview | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  // Tracks what the user is currently doing in the drawer
  const [step, setStep] = useState(SettlementStep.Previewing);

  // Whenever the perspective toggle value changes, flip the perspective of the preview.
  const handlePerspectiveChange = useDynamicCallback((newPerspective: CustomerSettlementPerspectiveEnum) => {
    setPreview(currentPreview => {
      if (currentPreview?.perspective !== newPerspective) {
        return currentPreview?.getOtherPerspective();
      } else {
        return currentPreview; // noop
      }
    });
  });

  // Whenever the settlementOption changes, we effectively reset the drawer state in this useEffect.
  // When we want to preview a settlement, a new settlement option object will always be created.
  useEffect(() => {
    setPreview(undefined);
    setError(undefined);
    setStep(SettlementStep.Previewing);

    previewSettlement(settlementOption)
      .then(setPreview)
      .catch((e: ErrorEvent) => setError(e.message));
  }, [settlementOption, previewSettlement]);

  const handleConfirm = useDynamicCallback(() => {
    setStep(SettlementStep.Settling);
    settleTrades(settlementOption)
      .then(response => {
        setStep(SettlementStep.Settled);
        addToast({
          variant: NotificationVariants.Positive,
          text: `${response.IncludingTrades.length} trade(s) settled successfully.`,
        });
      })
      .catch((e: ErrorEvent) => {
        setStep(SettlementStep.Previewing);
        addToast({
          variant: NotificationVariants.Negative,
          text: `Unable to settle trades: ${e.message}`,
        });
      });
  });

  const { observable: previewObservable } = useBehaviorSubject(() => preview, [preview]);
  const dataObs = useMemo(
    () =>
      previewObservable.pipe(
        map(preview => {
          return {
            data: preview?.Instructions ?? [],
            // Always initial because its based on absolute rest responses
            initial: true,
            type: '',
            ts: '',
          } satisfies SubscriptionResponse<SettlementInstruction>;
        }),
        shareReplay(1)
      ),
    [previewObservable]
  );

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        title: 'Account',
        editable: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const nothingToSettle = preview?.Instructions.length === 0;

  const blotterTable = useBlotterTable({
    dataObservable: dataObs,
    rowID: 'rowID' satisfies keyof SettlementInstruction,
    columns,
    ...({
      groupRemoveSingleChildren: true,
      showOpenedGroup: true,
      autoGroupColumnDef,
      groupDefaultExpanded: -1, // -1 means all group rows start expanded
      // for some reason theres a problem with context menus in modals... cant click out of an open context menu :/, so disable for now. dont need it anyway
      suppressContextMenu: true,
      suppressRowClickSelection: true,
    } satisfies GridOptions),
  });

  const tabs = useTabs({ initialItems: TABS });

  return (
    <Flex
      h="100%"
      flexDirection="column"
      justifyContent="space-between"
      data-testid="customer-settlement-drawer-content"
    >
      <Box h="100%">
        {error ? (
          <VStack w="100%" h="100%" p="spacingLarge">
            <Text>Unable to fetch settlement preview: {error}</Text>
          </VStack>
        ) : !preview ? (
          <VStack py="spacingHuge" gap="spacingLarge">
            <LoaderTalos size={LoaderSizes.MEDIUM} />
            <Text>Fetching settlement preview...</Text>
          </VStack>
        ) : (
          <Flex flexDirection="column" w="100%" h="100%">
            <Box p="spacingMedium">
              <HStack justifyContent="space-between">
                <SettlementPerspectiveToggle perspective={preview.perspective} onChange={handlePerspectiveChange} />
                <SettlementExportButton
                  preview={preview}
                  settlementOption={settlementOption}
                  getInstructionsBlotterSheetData={blotterTable.getSheetDataForExcel}
                />
              </HStack>
            </Box>

            <Divider orientation="horizontal" color="colors.gray.000" />

            <Box p="spacingMedium">
              <SettlementOverviewCard
                preview={preview}
                settlementOption={settlementOption}
                getSettlementInstructionsCSV={blotterTable.getDataAsCSV}
              />
            </Box>

            <Divider orientation="horizontal" color="colors.gray.000" />

            <Box h="100%" pt="0" p="spacingMedium" data-testid="customer-settlement-drawer-bottom-content">
              <Tabs {...tabs} h="100%" size={TabSize.Large}>
                <TabList isBordered>
                  {tabs.items.map((tab, index) => (
                    <Tab key={index} {...tab} />
                  ))}
                </TabList>

                <TabPanels h="100%">
                  <BlotterTable {...blotterTable} background="backgroundDrawer" />
                </TabPanels>
              </Tabs>
            </Box>
          </Flex>
        )}
      </Box>
      <DrawerFooter>
        <Button onClick={close}>Close</Button>
        <Button
          variant={ButtonVariants.Primary}
          disabled={!preview || nothingToSettle || !(step === SettlementStep.Previewing)}
          loading={step === SettlementStep.Settling}
          onClick={handleConfirm}
          data-testid="customer-settlement-drawer-settle-button"
        >
          {step === SettlementStep.Settled ? 'Settled' : nothingToSettle ? 'Nothing to Settle' : 'Settle Trades'}
        </Button>
      </DrawerFooter>
    </Flex>
  );
};

const columns: ColumnDef<SettlementInstruction>[] = [
  {
    type: 'account',
    field: 'OnBehalfOfAccountID',
    rowGroup: true,
    hide: true,
    params: {
      prefixCustomerMarketAccountsWithCpty: true,
    },
  },
  {
    type: 'asset',
    field: 'Asset',
    width: 100,
    suppressHeaderMenuButton: true,
    params: {
      showDescription: false,
    },
  },
  {
    type: 'text',
    field: 'Direction',
    params: {
      getLabel: value =>
        isSettlementInstructionDirection(value) ? getSettlementInstructionDirectionLabel(value) : value,
    },
    suppressHeaderMenuButton: true,
    width: 100,
    sort: '+',
  },
  {
    type: 'size',
    field: 'directionalAmount',
    flex: 1,
    suppressHeaderMenuButton: true,
    params: {
      currencyField: 'Asset',
      highlightNegative: true,
      inlineFormattedNumberAlignment: 'right',
    },
  },
];
