import { Note } from './Note';
import { useNotes } from './NotesProvider';
import { Wrapper } from './styles';

export * from './NotesProvider';
export { Square, Squares } from './styles';
export { NoteVariant, type NoteProps } from './types';

export const Notes = () => {
  const { notes } = useNotes();
  return notes.length ? (
    <Wrapper>
      {notes.map(note => (
        <Note {...note} key={note.id} />
      ))}
    </Wrapper>
  ) : null;
};
