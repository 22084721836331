import { useEffectOnce } from '@talos/kyoko';
import { useSubAccounts } from 'providers';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { usePortfolioSettings } from 'providers/PortfolioSettingContext';
import { useEffect } from 'react';
import { getInitPortfolioViewState } from '../stateManagement/portfolioViewLayoutSlice';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../stateManagement/portfolioViewLayoutSlice.hooks';

const { setPortfolioViewState } = getPortfolioViewActions();

/** Manage Portfolio View State in App Config Storage */
export function useManagePortfolioViewStateStorage(): {
  portfolioViewState: ReturnType<typeof usePortfolioViewStateSelector>;
} {
  const { portfolioViewState: initialPortfolioViewState, setPortfolioViewState: setPortfolioViewStateAppConfig } =
    usePortfolioSettings();
  const dispatch = useAppStateDispatch();
  const { subAccountsByID } = useSubAccounts();

  // TODO: consider refactoring this to a wrapper component that sets the initial state
  // initialize the portfolio view state with the stored state from the app config
  useEffectOnce(() => {
    if (initialPortfolioViewState) {
      const selectedPortfolioId = initialPortfolioViewState.selectedPortfolioId;
      dispatch(
        setPortfolioViewState(
          getInitPortfolioViewState({
            ...initialPortfolioViewState,
            selectedPortfolioId: selectedPortfolioId
              ? subAccountsByID.get(selectedPortfolioId)?.SubaccountID
              : undefined,
          })
        )
      );
      setPortfolioViewStateAppConfig(portfolioViewState);
    }
  });

  const portfolioViewState = usePortfolioViewStateSelector();
  useEffect(() => {
    setPortfolioViewStateAppConfig(portfolioViewState);
  }, [portfolioViewState, setPortfolioViewStateAppConfig]);

  return { portfolioViewState };
}
