import { API_PERMISSION_WILDCARD, useMarketAccountsContext } from '@talos/kyoko';
import { compact } from 'lodash';
import { useMarketAccountPermissionFilters } from 'providers/MarketAccountPermissionFiltersProvider';
import { useCallback } from 'react';

/**
 * Get a function returning a list of DisplayNames of market accounts permissioned to a subaccount
 */
export function useGetMarketAccountsDisplayNameBySubAccount() {
  const { marketAccountDisplayNameByName } = useMarketAccountsContext();
  const { marketAccountFilterIDsBySubAccountName, marketAccountPermissionFiltersByFilterID } =
    useMarketAccountPermissionFilters();

  return useCallback(
    (subAccountName: string): string[] => {
      const filterPermissionIDs = marketAccountFilterIDsBySubAccountName?.get(subAccountName) || [];
      const filterPermissions = compact(
        Array.from(filterPermissionIDs).map(permID => marketAccountPermissionFiltersByFilterID?.get(permID) || null)
      );

      if (filterPermissions.find(perm => perm.Filter.MarketAccount === API_PERMISSION_WILDCARD)) {
        return ['All Market Accounts'];
      }

      return filterPermissions.map(
        perm => marketAccountDisplayNameByName.get(perm.Filter.MarketAccount) || perm.Filter.MarketAccount
      );
    },
    [marketAccountDisplayNameByName, marketAccountFilterIDsBySubAccountName, marketAccountPermissionFiltersByFilterID]
  );
}
