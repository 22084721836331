import {
  IconName,
  InlineFormattedNumber,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useSecuritiesContext,
  type Order,
} from '@talos/kyoko';
import Big from 'big.js';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { memo, useMemo } from 'react';
import { EmptyRowsDisplay } from '../EmptyRowsDisplay';
import { DistributionTable, DistributionWrapper, LegendIndicator, MarketName } from '../styles';
import { REMAINDER } from '../types';
import { useChartOptions } from '../useChartOptions';
import { useMarketDistribution } from '../useMarketDistribution';

export interface FilledMarketDistributionChartProps {
  legIndex?: 0 | 1;
  order: Order;
}

export const FilledMarketDistributionChart = memo(function FilledMarketDistributionChart({
  legIndex,
  order,
}: FilledMarketDistributionChartProps) {
  const { distribution } = useMarketDistribution({
    order: order,
    legIndex,
    includeZeroCumQty: false,
    variant: 'Market',
  });

  const highchartOptions = useChartOptions({ distribution, centerText: 'Market Distribution' });
  const remainderFreeDistribution = useMemo(() => distribution.filter(d => d.id !== REMAINDER), [distribution]);

  const { securitiesBySymbol } = useSecuritiesContext();
  const currentSymbol =
    (legIndex != null && securitiesBySymbol.get(order.Symbol)?.MultilegDetails?.Legs[legIndex].Symbol) || order.Symbol;
  const currentSecurity = securitiesBySymbol.get(currentSymbol);

  if (highchartOptions == null) {
    return null;
  }

  return (
    <DistributionWrapper>
      <HighchartsReact highcharts={Highcharts} options={highchartOptions} />
      <DistributionTable w="100%">
        <Thead>
          <Tr>
            <Th>Market</Th>
            <Th align="left" />
            <Th align="right">Average price</Th>
            <Th align="right">Quantity</Th>
            <Th align="right">Fees</Th>
            <Th align="right">Pct</Th>
          </Tr>
        </Thead>
        {remainderFreeDistribution.length !== 0 && (
          <Tbody>
            {remainderFreeDistribution.map(market => {
              // For RFQs, AvgPxAllIn is not set, then we need to fallback to avgPx and remove fee icon
              const feeIncludedInPrice = !Big(market.avgPxAllIn || 0).eq(0) && !Big(market.avgPx || 0).eq(0);
              const avgPx = !Big(market.avgPxAllIn || 0).eq(0) ? market.avgPxAllIn : market.avgPx;

              return (
                <Tr key={market.id}>
                  <Td minimize>
                    <MarketName title={market.market}>{market.market}</MarketName>
                  </Td>
                  <Td align="left">
                    <LegendIndicator background={market.color} />
                  </Td>
                  <Td minimize>
                    <InlineFormattedNumber
                      number={avgPx}
                      startIcon={feeIncludedInPrice ? IconName.Fees : undefined}
                      currency={market.priceCurrency}
                      increment={market.priceIncrement}
                    />
                  </Td>
                  <Td minimize>
                    <InlineFormattedNumber
                      number={market.cumQty}
                      currency={market.sizeCurrency}
                      increment={market.sizeIncrement}
                    />
                  </Td>
                  <Td>
                    <InlineFormattedNumber
                      number={market.cumFee}
                      currency={currentSecurity?.QuoteCurrency}
                      increment={currentSecurity?.DefaultPriceIncrement}
                    />
                  </Td>
                  <Td minimize>{`${(market.y * 100 || 0).toFixed(2)}%`}</Td>
                </Tr>
              );
            })}
          </Tbody>
        )}
      </DistributionTable>
      {remainderFreeDistribution.length === 0 && <EmptyRowsDisplay />}
    </DistributionWrapper>
  );
});
