import Big, { type BigSource } from 'big.js';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { Direction } from '../../types';
import { InlineFormattedNumber } from '../FormattedNumber';
import { FormattedMessage } from '../Intl';
import { Text } from '../Text';
import { BenchmarkWrapper } from './styles';

const messages = defineMessages({
  notApplicable: {
    defaultMessage: 'N/A %',
    id: 'Benchmark.notApplicable',
  },
});

export interface BenchmarkProps {
  /**
   * A value to be compared to the provided `benchmark` in order to show a change percentage.
   * A 10.5% value should be provided as "10.5" or 10.5.
   */
  value?: BigSource;
  /** A benchmark to compare the given value to in order to show a change percentage. */
  benchmark?: BigSource;
  /** If you already have the percentage change, you can pass it in yourself here. */
  percentageChange?: string | number;
  /** The direction of change to be interpreted as positive (green), defaults to Ascending (+). */
  positiveDirection?: Direction;
}

export function Benchmark({ value, benchmark, percentageChange, positiveDirection = Direction.Asc }: BenchmarkProps) {
  const theme = useTheme();

  const providedPercentageChange = percentageChange ? Big(percentageChange) : undefined;

  const calculatedPercentageChange =
    benchmark != null && value != null && Big(benchmark).gt(0)
      ? Big(value).div(benchmark).minus(1).times(100)
      : undefined;

  const percentageChangeToUse = providedPercentageChange ?? calculatedPercentageChange;

  const background =
    !percentageChangeToUse || percentageChangeToUse.eq(0)
      ? theme.colors.gray['010']
      : percentageChangeToUse.gt(0) && positiveDirection === Direction.Asc
      ? theme.colors.green.dim
      : theme.colors.red.dim;
  const color =
    !percentageChangeToUse || percentageChangeToUse.eq(0)
      ? theme.colors.gray['090']
      : percentageChangeToUse.gt(0) && positiveDirection === Direction.Asc
      ? theme.colorTextPositive
      : theme.colorTextNegative;

  return percentageChangeToUse ? (
    <BenchmarkWrapper background={background} color={color}>
      <InlineFormattedNumber
        showSign={true}
        number={percentageChangeToUse}
        increment="0.01"
        currency="%"
        align="right"
        background="transparent"
        highlightColor={color}
        round={true}
      />
    </BenchmarkWrapper>
  ) : (
    <BenchmarkWrapper background={theme.colors.gray['050']}>
      <Text color={theme.colorTextDefault}>
        <FormattedMessage {...messages.notApplicable} />
      </Text>
    </BenchmarkWrapper>
  );
}
