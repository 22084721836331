import {
  getMACKey,
  Icon,
  IconName,
  isTalosUser,
  Text,
  Tooltip,
  useMarketAccountCurrenciesContext,
  useObservableValue,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { useUser } from '../../hooks/useUser';

interface AdminMACHelpTooltipProps {
  account?: number;
  currency?: string;
}

export function AdminMACHelpTooltip({ account, currency }: AdminMACHelpTooltipProps) {
  const user = useUser();

  // Only show anything if we are TALOS_ADMIN, or if the necessary keys to get a MAC are defined
  if (!isTalosUser(user) || account == null || currency == null) {
    return null;
  }

  return (
    <Tooltip tooltip={<MACTooltip account={account} currency={currency} />}>
      <Icon icon={IconName.QuestionMarkCircle} />
    </Tooltip>
  );
}

function MACTooltip({ account, currency }: Required<AdminMACHelpTooltipProps>) {
  const { marketAccountCurrenciesByKeyObs } = useMarketAccountCurrenciesContext();
  const marketAccountCurrenciesByKey = useObservableValue(
    () => marketAccountCurrenciesByKeyObs,
    [marketAccountCurrenciesByKeyObs]
  );

  const stringifiedMAC = useMemo(() => {
    if (!marketAccountCurrenciesByKey) {
      return null;
    }
    const mac = marketAccountCurrenciesByKey.get(getMACKey(account, currency));
    return JSON.stringify(mac, null, 2);
  }, [marketAccountCurrenciesByKey, account, currency]);

  return stringifiedMAC == null ? <Text textAlign="left">No MAC found</Text> : <pre>{stringifiedMAC}</pre>;
}
