import {
  HelpIcon,
  MixpanelEvent,
  MixpanelEventProperty,
  SearchSelect,
  Text,
  VStack,
  useMixpanel,
  type OrderStrategy,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { useStrategies } from '../../../providers';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';

const getStrategyLabel = (strategy: OrderStrategy) => strategy.DisplayName ?? strategy.Name;

export const DefaultStrategy = () => {
  const { strategiesList: strategies } = useStrategies();
  const { defaultOrderStrategy, setDefaultOrderStrategy } = useTradingSettings();
  const mixpanel = useMixpanel();

  const availableStrategies = useMemo(
    () => strategies.filter(strategy => strategy.InstrumentScope === 'Native' || strategy.InstrumentScope === 'All'),
    [strategies]
  );

  const selectedStrategy = useMemo(
    () => availableStrategies.find(strategy => strategy.Name === defaultOrderStrategy),
    [availableStrategies, defaultOrderStrategy]
  );

  const handleStrategyChange = useCallback(
    (newStrategy: OrderStrategy | undefined) => {
      // Either pass in the name, or if we cleared the select (newStrategy is undefined) pass in empty string
      setDefaultOrderStrategy(newStrategy ? newStrategy.Name : '');
      mixpanel.track(MixpanelEvent.DefaultStrategy, {
        [MixpanelEventProperty.Strategy]: newStrategy ? newStrategy.Name : '',
      });
    },
    [setDefaultOrderStrategy, mixpanel]
  );

  return (
    <VStack gap="spacingDefault" alignItems="flex-start">
      <Text color="colorTextImportant">Default strategy for new orders</Text>
      <SearchSelect
        id="strategy"
        onChange={handleStrategyChange}
        selection={selectedStrategy}
        options={availableStrategies}
        getLabel={getStrategyLabel}
        showClear
        style={{ width: '200px' }}
        suffix={
          selectedStrategy?.Description && (
            <Text size="fontSizeSmall" style={{ lineHeight: 1.5 }}>
              <HelpIcon tooltip={selectedStrategy.Description} />
            </Text>
          )
        }
      />
    </VStack>
  );
};
