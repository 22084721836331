import { MarketAccountTypeEnum, ProductTypeEnum, type RowGroupsOpenedState } from '@talos/kyoko';
import type { SettlementMonitoringFilter } from '../types';
import type { PositionMonitoringColumn } from './types';

export const defaultPositionMonitoringByAccountColumns = [
  'MarketAccount',
  'Asset',
  'incoming',
  'incomingEquivalent',
  'outgoing',
  'outgoingEquivalent',
  'creditUsages',
  'AvgCost',
  'MarkPrice',
  'UnsettledPnL',
] satisfies PositionMonitoringColumn[];

export const defaultPositionMonitoringByAssetColumns = [
  { field: 'Asset', rowGroup: true, hide: true },
  { id: 'counterparty' },
  'MarketAccount',
  'incoming',
  'incomingEquivalent',
  'outgoing',
  'outgoingEquivalent',
  'UnsettledPnL',
  'Equivalent.UnsettledPnL',
  'AvgCost',
  'MarkPrice',
] satisfies PositionMonitoringColumn[];

export const defaultPositionMonitoringFilter: SettlementMonitoringFilter = {
  AssetTypes: [ProductTypeEnum.Spot],
  MarketAccountTypes: [MarketAccountTypeEnum.Customer],
};

export const defaultPositionMonitoringRowGroupsOpened: RowGroupsOpenedState = {};
