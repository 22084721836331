import { invariant } from '@epic-web/invariant';
import { logger, useCurrenciesContext, wrapForErrorHandling, type WebsocketRequest } from '@talos/kyoko';
import { getIncludeCashFilter } from 'hooks/useIncludeCashFilter';
import { useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { useCallback, useMemo } from 'react';
import { map, pipe } from 'rxjs';
import type { PortfolioRiskDataItem } from '../../types/PortfolioRiskDataItem';
import type { PortfolioRiskSubscriptionData } from '../../types/PortfolioRiskSubscriptionData';
import { ROLLUP_GROUP_PREFIX } from '../../types/types';
import { buildHierarchicalDataPath, convertToPortfolioRiskGridData } from '../../types/utils';
import type { useRequestStateTagging } from '../../useRequestStateTagging';
import type { ExtraStateType, FromRiskObsValueMin, ToObsValue } from '../PortfolioManagementProvider.types';

function isBookLevelData(hierarchicalDataPath: string[]) {
  return hierarchicalDataPath.at(-3)?.startsWith(ROLLUP_GROUP_PREFIX);
}

export function usePortfolioRiskGridDataConverterPipe<TRequest extends Omit<WebsocketRequest, 'tag'>>(
  tagMapRef: ReturnType<typeof useRequestStateTagging<TRequest, ExtraStateType>>['tagMapRef']
) {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { subAccountsByName, subAccountsByID } = useSubAccounts();
  const { rollupMembershipsByChildParent } = useSubAccountRollupMemberships();

  /** this mechanism converts the Portfolio Risk Subscription data into the data used in the actual grids */
  const convertSubToDataResults = useCallback(
    (row: PortfolioRiskSubscriptionData, tag: string): PortfolioRiskDataItem[] => {
      // get the extra state from the tag map, which ensures we have the correct state for this data stream
      const tagMapEntry = tagMapRef.current.get(tag);
      invariant(tagMapEntry?.extraState, `extraState field required to process risk data`);
      const { showRollupHierarchy, selectedPortfolioId, includeCash, treatStablecoinsAsCash } = tagMapEntry.extraState;
      const isRowValidForIncludeCash = getIncludeCashFilter(includeCash, treatStablecoinsAsCash, currenciesBySymbol);

      const isValidToShow = isRowValidForIncludeCash(row.Asset);
      if (!isValidToShow) {
        return [];
      }

      const resultData: PortfolioRiskDataItem[] = [];
      const hierarchicalDataPaths = buildHierarchicalDataPath({
        contextSubAccountId: selectedPortfolioId ?? -1,
        node: row,
        showRollupHierarchy,
        rollupMembershipsByChildParent,
        subAccountsByName,
        subAccountsByID,
      });

      // Using the hierarchical data paths, we can ensure we only count the data once by
      // skipping aggregation for all but the first data path
      const dataPaths = hierarchicalDataPaths
        .map(item => {
          return {
            dataPathJoined: item.join('::'),
            dataPath: item,
          };
        })
        .sort((a, b) => {
          return a.dataPathJoined.localeCompare(b.dataPathJoined);
        })
        .map((item, index) => {
          return {
            dataPath: item.dataPath,
            skipAggregation: index > 0,
          };
        });

      // convert the single row to multiple rows based on the hierarchical data paths, this
      // will allow us to show the data in the correct dynamic hierarchy in the grid
      for (const hierarchicalDataPath of dataPaths) {
        // If we are in rollup hierarchy mode, we only want to show the Book level data
        if (showRollupHierarchy && isBookLevelData(hierarchicalDataPath.dataPath)) {
          continue;
        }
        const convertedRow = convertToPortfolioRiskGridData(
          row,
          hierarchicalDataPath.dataPath,
          hierarchicalDataPath.skipAggregation
        );
        resultData.push(convertedRow);
      }

      return resultData;
    },
    [currenciesBySymbol, rollupMembershipsByChildParent, subAccountsByID, subAccountsByName, tagMapRef]
  );

  return useMemo(() => {
    return pipe(
      wrapForErrorHandling<FromRiskObsValueMin, ToObsValue>({
        wrappedPipe: pipe(
          map(message => {
            const tag = message.tag;
            if (!tag) {
              throw new Error(`all PMS messages should have a tag`);
            }
            return {
              ...message,
              data: message.data.flatMap(datapoint => convertSubToDataResults(datapoint, tag)),
            };
          })
        ),
        errorHandler: error => {
          logger.error(
            new Error(
              `Portfolio Management batch reader error (processing will continue): ${
                error instanceof Error ? error.message : error
              }`,
              {
                cause: error,
              }
            )
          );
        },
      })
    );
  }, [convertSubToDataResults]);
}
