import { deserializeDateRangeOrLookback, logger, type DateRangeOrLookback } from '@talos/kyoko';
import { getInitPortfolioViewState } from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import {
  portfolioViewStateSchema,
  type PortfolioViewState,
} from 'containers/Portfolio/PortfolioManagement/types/types';
import type { AppConfigStateMethods } from 'providers/AppConfigProvider';
import type { AppConfigState } from 'providers/AppConfigProvider/AppConfigProvider.types';
import type { PortfolioSettingsContext } from 'providers/PortfolioSettingContext';
import { useMemo, useRef, type ContextType } from 'react';

const defaultPortfolioViewState = getInitPortfolioViewState({});

/** Retrieve Portfolio View State from storage for {@see AppConfigState} */
export function usePortfolioAppConfigSettings(
  state: AppConfigState,
  methods: AppConfigStateMethods
): ContextType<typeof PortfolioSettingsContext> {
  const isValidatedRef = useRef(false);

  function validatePortfolioViewState(viewState: AppConfigState['portfolioViewState']): PortfolioViewState {
    if (isValidatedRef.current) {
      return viewState;
    }
    isValidatedRef.current = true;
    try {
      const validated = portfolioViewStateSchema.validateSync(viewState);
      let dateRangeToUse: DateRangeOrLookback | undefined = undefined;
      try {
        // deserialize the date range if it was set
        dateRangeToUse = validated.dateRange ? deserializeDateRangeOrLookback(validated.dateRange) : undefined;
      } catch (e) {
        if (e instanceof Error) {
          logger.error(
            new Error(
              `Error deserializing date range ${JSON.stringify(validated.dateRange)}: ${
                e instanceof Error ? e.message : e
              }`,
              {
                cause: e,
              }
            )
          );
        }
      }
      const validateWithDateRange = {
        ...validated,
        dateRange: dateRangeToUse,
      };
      return getInitPortfolioViewState(validateWithDateRange);
    } catch (e) {
      logger.error(
        new Error(
          `Error validating portfolio view state: ${e instanceof Error ? e.message : e}, defaulting to initial state`,
          { cause: e }
        )
      );
      // if validation fails, return the initial portfolio state so the user can still use the app
      return defaultPortfolioViewState;
    }
  }

  return useMemo<ContextType<typeof PortfolioSettingsContext>>(() => {
    return {
      enablePMSLayout: state.enablePMSLayout,
      treatStablecoinsAsCash: state.treatStablecoinsAsCash,
      portfolioViewState: validatePortfolioViewState(state.portfolioViewState),
      setEnablePMSLayout: methods.setEnablePMSLayout,
      setTreatStablecoinsAsCash: methods.setTreatStablecoinsAsCash,
      setPortfolioViewState: methods.setPortfolioViewState,
    };
  }, [
    state.enablePMSLayout,
    state.treatStablecoinsAsCash,
    state.portfolioViewState,
    methods.setEnablePMSLayout,
    methods.setTreatStablecoinsAsCash,
    methods.setPortfolioViewState,
  ]);
}
