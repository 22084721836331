import type { UseComboboxStateChange } from 'downshift';
import { useRef, type ReactNode } from 'react';
import { useDynamicCallback } from '../../../hooks';
import { Button, type ButtonProps } from '../../Button';
import { Box } from '../../Core';
import type { AutocompleteResultsProps } from '../Autocomplete';
import { AutocompleteDropdown, FuseAutocompleteResult } from '../AutocompleteDropdown';
import { useDropdownPopper, type UseDropdownPopperProps } from '../Dropdown';
import { Input } from '../Input';
import { useSearchSelect, type UseSearchSelectProps } from '../SearchSelect';

export type ButtonSelectProps<T> = {
  useSearchSelectProps: Omit<UseSearchSelectProps<T>, 'onChange' | 'selection' | 'inputRef'>;
  useDropdownPopperProps: Omit<UseDropdownPopperProps, 'isOpen' | 'referenceElement' | 'dropdownRef'>;
  children: ReactNode;
  onSelection: (item: T) => void;
  onClose?: () => void;
  dropdownHeight?: AutocompleteResultsProps<T>['maxHeight']; // TODO clean up the maxHeight <-> dropdownHeight thing so theres just one
  dataTestID?: string;
} & Pick<ButtonProps, 'size' | 'dim' | 'variant' | 'disabled'>;

export const ButtonSelect = <T,>({
  useSearchSelectProps,
  useDropdownPopperProps,
  children,
  onSelection,
  size,
  dim,
  variant,
  disabled,
  onClose,
  dropdownHeight,
  dataTestID,
}: ButtonSelectProps<T>) => {
  const dropdownReferenceElement = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useDynamicCallback((item: T | undefined) => {
    if (item) {
      searchSelect.closeMenu();
      onSelection(item);
    }
  });

  const handleIsOpenChange = useDynamicCallback((changes: UseComboboxStateChange<T>) => {
    if (changes.isOpen === false) {
      onClose?.();
    }
  });

  const searchSelect = useSearchSelect({
    ...useSearchSelectProps,
    inputRef,
    selection: undefined,
    onChange: handleChange,
    onIsOpenChange: handleIsOpenChange,
  });

  const dropdown = useDropdownPopper({
    isOpen: searchSelect.isOpen,
    referenceElement: dropdownReferenceElement.current,
    dropdownWidth: '100px',
    ...useDropdownPopperProps,
  });

  const handleButtonClick = useDynamicCallback(() => {
    if (!searchSelect.isOpen) {
      searchSelect.openMenu();
    }
  });

  return (
    <Box position="relative" data-testid={dataTestID}>
      <Button
        size={size}
        dim={dim}
        variant={variant}
        disabled={disabled}
        ref={dropdownReferenceElement}
        onClick={handleButtonClick}
      >
        {children}
        <AutocompleteDropdown
          {...searchSelect}
          {...dropdown}
          maxHeight={dropdownHeight}
          size={size}
          portalize
          renderResult={FuseAutocompleteResult}
          /* We have to render an invisible input here to make downshift happy */
          childrenAboveResults={
            <Input
              placeholder="Search..."
              data-testid="spot-trading-symbol-selector"
              {...searchSelect.getInputProps({ ref: inputRef })}
            />
          }
        />
      </Button>
    </Box>
  );
};
