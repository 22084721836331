import {
  CUSTOMER_ORDER,
  DEFAULT_MAX_ROWS,
  isAfterOrEqual,
  useConstant,
  useSubscription,
  type CustomerOrder,
} from '@talos/kyoko';
import { useCallback, useEffect, useMemo } from 'react';
import { BehaviorSubject, map } from 'rxjs';
import { scan } from 'rxjs/operators';
import type { BlotterTableOrderFilter } from './useOrderFilter';

// Hopefully this is a temporary UI workaround - backend should return full blotter rows rather than relying UI
// combining Order and CustomerOrder
export const useRecentCustomerOrders = function useRecentCustomerOrders(filter: BlotterTableOrderFilter) {
  const request = useMemo(() => {
    const req: any = {
      name: CUSTOMER_ORDER,
      tag: 'CUSTOMER_RECENT_ORDERS',
      HideApiCalls: true,
      Throttle: '1s',
      sort_by: '-SubmitTime',
    };
    if (filter.StartDate) {
      req.StartDate = filter.StartDate;
    }
    if (filter.EndDate) {
      req.EndDate = filter.EndDate;
    }
    return req;
  }, [filter.StartDate, filter.EndDate]);

  const { data: openCustomerOrders, nextPage } = useSubscription<CustomerOrder>(request, { loadAll: false });

  const loadMore = useCallback(
    (numberOfRows: number) => {
      if (numberOfRows < DEFAULT_MAX_ROWS) {
        nextPage();
      }
    },
    [nextPage]
  );

  const customerOrderByOrderIDObs: BehaviorSubject<Map<string, CustomerOrder>> = useConstant(
    new BehaviorSubject(new Map())
  );

  useEffect(() => {
    const subscription = openCustomerOrders
      .pipe(
        map(open => open.data),
        scan((orders, data) => {
          data.forEach(d => {
            const id = d.OrderID;
            const existingOrder = orders.get(id);
            if (
              existingOrder == null ||
              !d.Timestamp ||
              !existingOrder?.Timestamp ||
              isAfterOrEqual(new Date(d.Timestamp), new Date(existingOrder.Timestamp))
            ) {
              orders.set(id, d);
            }
          });
          return orders;
        }, new Map<string, CustomerOrder>())
      )
      .subscribe(orders => {
        customerOrderByOrderIDObs.next(orders);
        loadMore(orders.size);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [openCustomerOrders, customerOrderByOrderIDObs, loadMore]);

  return customerOrderByOrderIDObs;
};
