import { Dialog, FormGroup, Input, NotificationVariants, useGlobalToasts, type useDisclosure } from '@talos/kyoko';
import { usePricingRules } from 'providers';
import { PRICING_CONFIG_UPDATE_ID } from 'providers/PricingRulesProvider';
import { useCallback, useState } from 'react';

interface NewCustomerDialogProps {
  dialog: ReturnType<typeof useDisclosure>;
  onSuccess?: () => void;
}

export const NewCustomerDialog = ({ dialog, onSuccess }: NewCustomerDialogProps) => {
  const [counterparty, setCounterparty] = useState('');
  const [displayName, setDisplayName] = useState('');
  const { newCounterparty } = usePricingRules();
  const { add: addToast, remove: removeToast } = useGlobalToasts();

  const handleNewCustomer = useCallback(
    (counterparty: string, displayName: string) => {
      addToast({
        text: `Creating new customer...`,
        variant: NotificationVariants.Primary,
        id: PRICING_CONFIG_UPDATE_ID,
        timeout: undefined,
      });
      newCounterparty({
        Name: counterparty,
        DisplayName: displayName,
      })
        .then(() => {
          removeToast(PRICING_CONFIG_UPDATE_ID);
          onSuccess?.();
        })
        .catch((e: ErrorEvent) => {
          removeToast(PRICING_CONFIG_UPDATE_ID);
          addToast({
            text: `Failed to create new customer: ${e.message}`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, newCounterparty, removeToast, onSuccess]
  );

  return (
    <Dialog
      {...dialog}
      onConfirm={() => handleNewCustomer(counterparty, displayName)}
      confirmLabel="Create Customer"
      title="Create a New Customer"
      showClose={true}
      confirmDisabled={!counterparty || !displayName}
    >
      <FormGroup label="Name">
        <Input
          data-testid="new-customer-name"
          value={counterparty}
          autoComplete="off"
          onChange={e => setCounterparty(e.target.value)}
        />
      </FormGroup>

      <FormGroup label="Display Name">
        <Input
          data-testid="new-customer-display-name"
          value={displayName}
          autoComplete="off"
          onChange={e => setDisplayName(e.target.value)}
        />
      </FormGroup>
    </Dialog>
  );
};
