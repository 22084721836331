import { Get, isTalosUser, useDynamicCallback, useEndpointsContext, type MarketCredential } from '@talos/kyoko';
import { useMemo } from 'react';
import { useUser } from '../../../hooks';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../providers';

export function useCredentials() {
  const { orgApiEndpoint } = useEndpointsContext();
  const { getConfig } = useOrgConfiguration();
  const user = useUser();

  const getCredentialHistory = useDynamicCallback((credental: MarketCredential) =>
    Get(`${orgApiEndpoint}/organization/markets/${credental.Market}/credentials/${credental.CredentialID}/history`)
  );

  // If a Market is listed here, it will not appear in the Markets dropdown in the Add Credentials drawer for non-Talos users
  const hiddenMarkets = useMemo(() => {
    return isTalosUser(user)
      ? []
      : getConfig(OrgConfigurationKey.AdminCredentialsBlacklist, '')
          .split(',')
          .map(market => market.trim());
  }, [getConfig, user]);

  return {
    getCredentialHistory,
    hiddenMarkets,
  };
}
