import { HStack, MarketLogo, Text, useMarketsContext } from '@talos/kyoko';

export const HeaderMarketLogo = ({ marketName }: { marketName: string }) => {
  const { marketsByName } = useMarketsContext();
  const market = marketsByName.get(marketName);

  return (
    <HStack gap="spacingDefault">
      <MarketLogo market={marketName} size={24} />
      <Text>{market?.DisplayName ?? marketName}</Text>
    </HStack>
  );
};
