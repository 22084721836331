import { updateXAxisPlotline } from '@talos/kyoko';
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTheme } from 'styled-components';
import { snapshotDateToPlotLine } from './utils';

interface UseChartPlotlineParams {
  isLoaded: boolean;
  snapshotDate: string | null | undefined;
  chartRef: React.MutableRefObject<Highcharts.Chart | undefined>;
  plotlineID: string;
}

/**
 * This hook encapsulates the behavior of keeping some concept of a "snapshot date" (point in time)
 * synced up to some chart. Any time the snapshotDate parameter updates, this hook will ensure that
 * the update is propagated to the chart.
 *
 * Also returns the initialPlotline for you to seed the highcharts options with to get the plotline from first render.
 */
export const useChartPlotline = ({ isLoaded, snapshotDate, chartRef, plotlineID }: UseChartPlotlineParams) => {
  const theme = useTheme();

  const [initialPlotline] = useState(() => {
    return snapshotDate ? [snapshotDateToPlotLine(snapshotDate, theme)] : undefined;
  });

  // This useEffect keeps the chart plotline and snapshotDate in sync
  // When the "snapshotDate" state changes, the change is here set in the chart via the highcharts api
  useUpdateEffect(() => {
    if (!isLoaded) {
      return;
    }
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    const newPlotLine = snapshotDate ? snapshotDateToPlotLine(snapshotDate, theme) : undefined;
    updateXAxisPlotline(chart, plotlineID, newPlotLine);

    // When a plot line changes, if we are already hovering some point, make sure to refresh the tooltip
    // Impact is that the tooltip will realize its now hovering over the "as of" point, and add the bottom description to it.
    const maybeHoveredPoint = chart.hoverPoint;
    if (maybeHoveredPoint) {
      setTimeout(() => chart.tooltip.refresh(maybeHoveredPoint), 0);
    }
  }, [snapshotDate, chartRef]);

  return { initialPlotline };
};
