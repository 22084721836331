import { createContext, memo, useContext, useEffect, useState } from 'react';

import { useUser } from 'hooks/useUser';

export interface IntercomContextProps {
  unreadCount: number;
}

export const IntercomContext = createContext<IntercomContextProps | undefined>(undefined);
IntercomContext.displayName = 'IntercomContext';

export const useIntercom = () => {
  const context = useContext(IntercomContext);
  if (context === undefined) {
    throw new Error('Missing IntercomContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const IntercomProvider = memo(function IntercomProvider(props: React.PropsWithChildren<unknown>) {
  const user = useUser();
  const [instance, setInstance] = useState<IntercomContextProps>({
    unreadCount: 0,
  });

  useEffect(() => {
    let intercomUpdateInterval: ReturnType<typeof setInterval> | null = null;
    if (window.Intercom && user) {
      window.Intercom('boot', {
        app_id: import.meta.env.VITE_INTERCOM_APP_ID,
        user_id: user.Auth0ID || user.ID || '',
        user_hash: user.IntercomUserHash,
        name: user.Name,
        Organization: user.Organization,
        custom_launcher_selector: '#talos-intercom',
      });

      intercomUpdateInterval = setInterval(() => {
        // Manually check for new messages once every 90 seconds
        window.Intercom('update');
      }, 90 * 1000);

      window.Intercom('onUnreadCountChange', unreadCount =>
        setInstance(prev => ({
          ...prev,
          unreadCount,
        }))
      );
    }

    return () => {
      if (intercomUpdateInterval != null) {
        clearInterval(intercomUpdateInterval);
        intercomUpdateInterval = null;
      }
      if (window.Intercom && !user) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);

  return <IntercomContext.Provider value={instance}>{props.children}</IntercomContext.Provider>;
});
