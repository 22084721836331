import type { MarketAccount, TreasuryLink } from '@talos/kyoko';

export const EXPOSURE_WARNING_THRESHOLD = 0.95;

export interface MarketPositions {
  MarketAccount: string;
  Positions: Position[];
}

export interface Position {
  Currency: string;
  Amount: string;
  LastUpdateTime: string;
}

export interface TransferInstruction {
  MarketAccount: string;
  Amount: string;
  Currency: string;
  DestinationMarketAccount: string;
  SourceMarketAccount: string;
}

export type DestinationAccount = Omit<MarketAccount, 'SourceAccountID' | 'Capabilities'>;

export type SourceAccount = MarketAccount;

// Helper type to remove the non-necessary fields for making treasury link related requests
export type TreasuryLinkRequest = Omit<
  TreasuryLink,
  'Type' | 'getMarketAccountOrWildcard' | 'specificnessKey' | 'transactionType' | 'Status' | 'LinkID' | 'clone'
> &
  Partial<Pick<TreasuryLink, 'Type' | 'Status' | 'LinkID'>>;

export interface TransferResponse {
  groupId: string;
}

export interface PortfolioExposure {
  MarketAccount: string;
  Currency: string;
  Exposure: string;
  ExposureLimit: string;
}

export interface PortfolioHistoricalPosition {
  EquivalentCurrency: string;
  EquivalentPosition: string;
  Timestamp: string;
}

export interface HistoricalPositionStat {
  Timestamp: string;
  EquivalentCurrency: string;
  PnLTotal: string;
  PnLPercent: string;
  PnLDelta: string;
  PnLDeltaPercent: string;
  IntervalPnL: string;
  IntervalPnLPercent: string;
  BuyVolume: string;
  SellVolume: string;
  BenchmarkIntervalPnLPercent?: { [key: string]: string };
}
