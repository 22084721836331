import { useElementSizeOfRef, useSecurity, type UseElementSizeParams } from '@talos/kyoko';
import type { RefObject } from 'react';
import { Loader, LoaderWrapper } from '../../../../components/Loader';
import { useOrderAndCustomerOrder } from '../../../../hooks';
import { OrderDetailsInnerContainer } from './OrderDetailsInnerContainer';
import type { OrderDetailsPath } from './types';

const THRESHOLDS: UseElementSizeParams['thresholds'] = [['offsetWidth', [1000, 1200, 1400, 1600]]];

interface OrderDetailsProps {
  widthRef: RefObject<HTMLDivElement>;
  orderID?: string;
  customerOrderID?: string;
  selectedTab: OrderDetailsPath | undefined;
  onSelectTab: (path: OrderDetailsPath | undefined) => void;
  onClose: () => void;
  useLink: boolean;
}

// Wrapping here is done for cypress component testing
// Content should indepenent of the type of tab/container that renders
export function OrderDetails({
  widthRef,
  orderID,
  customerOrderID,
  onSelectTab,
  selectedTab,
  onClose,
  useLink,
}: OrderDetailsProps) {
  const {
    order,
    customerOrder,
    isLoading: isLoadingOrder,
  } = useOrderAndCustomerOrder({
    orderID,
    customerOrderID,
    tag: 'OrderDetails',
  });

  const security = useSecurity(order?.Symbol);

  const {
    size: { offsetWidth: width },
  } = useElementSizeOfRef<HTMLDivElement>({ ref: widthRef, thresholds: THRESHOLDS });

  if (isLoadingOrder || order == null || width === undefined) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <OrderDetailsInnerContainer
      order={order}
      customerOrder={customerOrder}
      security={security}
      width={width}
      onSelectTab={onSelectTab}
      selectedTab={selectedTab}
      onClose={onClose}
      useLink={useLink}
    />
  );
}
