import {
  getMarketSecurityStatusKey,
  MARKET_SECURITY_STATUS,
  useObservable,
  useStaticSubscription,
  wsScanToMap,
  wsSubscriptionCache,
  type MarketSecurityStatus,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';
import { createContext, useContext, type PropsWithChildren } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';

export interface MarketSecurityStatusesContextProps {
  marketSecurityStatusesByKeyObs: Observable<Map<string, MarketSecurityStatus>>;
  marketSecurityStatusesListObs: Observable<MarketSecurityStatus[]>;
  marketSecurityStatusesByMarketAccountSymbolObs: Observable<Map<string, Map<string, MarketSecurityStatus>>>;
  marketSecurityStatusesCache: Observable<MinimalSubscriptionResponse<MarketSecurityStatus>>;
}

const MarketSecurityStatusesContext = createContext<MarketSecurityStatusesContextProps | null>(null);
MarketSecurityStatusesContext.displayName = 'MarketSecurityStatusesContext';

export function useMarketSecurityStatuses() {
  const context = useContext(MarketSecurityStatusesContext);
  if (context == null) {
    throw new Error('Missing MarketSecurityStatusesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const MarketSecurityStatusesProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { data } = useStaticSubscription<MarketSecurityStatus>({
    name: MARKET_SECURITY_STATUS,
    tag: 'MarketSecurityStatusesProvider',
  });

  // You can use this cache to populate blotters. It emits SubscriptionResponse<MarketSecurityStatus> messages as if
  // they're coming from the backend directly, while preventing subscribe timing race conditions
  const marketSecurityStatusesCache = useObservable(
    () => data.pipe(wsSubscriptionCache(mss => getMarketSecurityStatusKey(mss.MarketAccount, mss.Symbol))),
    [data]
  );

  const marketSecurityStatusesByKeyObs = useObservable(
    () =>
      marketSecurityStatusesCache.pipe(
        wsScanToMap({
          getUniqueKey: mss => getMarketSecurityStatusKey(mss.MarketAccount, mss.Symbol),
          newMapEachUpdate: true,
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      ),
    [marketSecurityStatusesCache]
  );

  const marketSecurityStatusesByMarketAccountSymbolObs = useObservable(
    () =>
      marketSecurityStatusesByKeyObs.pipe(
        map(mssByKey => {
          const map = new Map<string, Map<string, MarketSecurityStatus>>();
          for (const mss of mssByKey.values()) {
            const innerMap = map.get(mss.MarketAccount) ?? new Map();
            innerMap.set(mss.Symbol, mss);
            map.set(mss.MarketAccount, innerMap);
          }
          return map;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketSecurityStatusesByKeyObs]
  );

  const marketSecurityStatusesListObs = useObservable(
    () =>
      marketSecurityStatusesByKeyObs.pipe(
        map(byKeyMap => [...byKeyMap.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketSecurityStatusesByKeyObs]
  );

  return (
    <MarketSecurityStatusesContext.Provider
      value={{
        marketSecurityStatusesByKeyObs,
        marketSecurityStatusesListObs,
        marketSecurityStatusesByMarketAccountSymbolObs,
        marketSecurityStatusesCache,
      }}
    >
      {children}
    </MarketSecurityStatusesContext.Provider>
  );
};
