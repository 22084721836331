import styled from 'styled-components';

export const IconWrapper = styled.span`
  margin-left: ${({ theme }) => theme.spacingSmall}px;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const PricingRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacingDefault}px;
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacingSmall}px;
  margin-top: ${({ theme }) => theme.spacingSmall}px;
  h2,
  p {
    margin: 0px;
  }
`;

export const DetailsParams = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacingDefault}px;

  dl {
    margin: 2px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  dt {
    color: ${({ theme }) => theme.colors.gray['070']};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizeSmall}rem;
    letter-spacing: 0.05em;
    margin-bottom: 2px;
  }

  dd {
    margin: 0;
    margin-left: ${({ theme }) => theme.spacingSmall}px;
    font-size: ${({ theme }) => theme.fontSizeLarge}rem;
  }
`;

export const Left = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacingSmall}px;
`;
export const Center = styled.div`
  height: 50px;
`;
export const Right = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const HeaderItem = styled.div`
  color: ${({ theme }) => theme.colors.gray['090']};
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.spacingMedium}px;
`;

export const ExpandableSection = styled.div`
  margin-top: ${({ theme }) => theme.spacingDefault}px;
`;

export const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  margin: 0px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.gray['100']};
  margin-right: ${({ theme }) => theme.spacingDefault}px;

  i {
    vertical-align: middle;
  }
`;

export const Timestamp = styled.p`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
`;

export const HeaderItems = styled.div`
  display: flex;
  align-items: flex-start;

  p,
  h3 {
    margin: 0px;
    padding: 0px;
    font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  }

  p {
    margin-bottom: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const HeaderRow = styled(Row)`
  align-items: flex-start;
`;

export const BlotterWrapper = styled.div`
  min-width: 1100px;
  overflow-x: hidden;
  height: 100%;
  & > div {
    height: 100%;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacingDefault}px;
`;

export const FormContent = styled.div`
  margin-top: ${({ theme }) => theme.spacingLarge}px;
  min-height: 200px;
  height: 100%;
`;
