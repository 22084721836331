import type { Market, MarketAccount, MinimalSubscriptionResponse } from '@talos/kyoko';
import { logger } from '@talos/kyoko';
import { map, pipe } from 'rxjs';
import { Balance, type IBalance } from '../../../types/Balance';

/**
 * Returns a pipe which performs some initial, constant, filtering on balances.
 * @returns a pipe returning filtered balances
 */
export function ignoreSpecificBalances(marketAccountsByID: Map<number, MarketAccount>) {
  return pipe(
    map(([json, destinationMarketAccounts]: [MinimalSubscriptionResponse<IBalance>, Set<string>]) => {
      const missingMarketAccountIds = new Set<number>();
      const filteredBalances: IBalance[] = [];
      for (const balance of json.data) {
        const marketAccount = marketAccountsByID.get(balance.MarketAccountID);

        if (marketAccount == null) {
          missingMarketAccountIds.add(balance.MarketAccountID);
          continue;
        }

        if (
          !marketAccount.Group?.startsWith('_') &&
          balance.Market !== 'dealer' &&
          !destinationMarketAccounts.has(marketAccount.Name)
        ) {
          filteredBalances.push(balance);
        }
      }

      // After looping, if there were any missing mkt accs, notify!
      if (missingMarketAccountIds.size > 0) {
        logger.warn(`Can't find MarketAccounts for balances`, {
          extra: {
            missingMarketAccountIDs: [...missingMarketAccountIds],
          },
        });
      }

      return { ...json, data: filteredBalances };
    })
  );
}

/**
 * This function returns a pipe which maps an incoming Balance (IBalance) to a Balance instance which is also enriched with metadata,
 * which contains more metadata about the entity such that it can be more easily used in the Treasury Management page
 */
export function enrichBalances(marketAccountsByID: Map<number, MarketAccount>, marketsByName: Map<string, Market>) {
  // This pipe goes from IBalance -> Balance also enriched with certain fields
  return pipe(
    map((json: MinimalSubscriptionResponse<IBalance>) => {
      const enrichedBalances: Balance[] = [];
      for (const balance of json.data) {
        const marketAccount = marketAccountsByID.get(balance.MarketAccountID);
        const marketType = marketsByName.get(balance.Market)?.Type;
        const filteredBalance = new Balance(balance);
        filteredBalance.enrichBalance({
          marketAccountGroup: marketAccount?.Group,
          marketAccountName: marketAccount?.Name,
          marketType: marketType,
        });
        enrichedBalances.push(filteredBalance);
      }

      const newJson: MinimalSubscriptionResponse<Balance> = {
        ...json,
        data: enrichedBalances,
      };
      return newJson;
    })
  );
}
