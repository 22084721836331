import { useMemo } from 'react';

import {
  CustomerUserConfigLayoutTypesAndArrangements,
  EMPTY_ARRAY,
  GlobalCustomerUserConfigTradingLayoutArrangements,
} from '@talos/kyoko';

import { useFeatureFlag } from 'hooks';
import type { LayoutArrangementItem } from '../CustomerUserDetails/types';

export function useLayoutArrangementItems(layoutType) {
  const { supportedLayoutArrangements } = useFeatureFlag();

  return useMemo<LayoutArrangementItem[]>(() => {
    const options = CustomerUserConfigLayoutTypesAndArrangements.get(layoutType);
    if (options) {
      const validOptions =
        // Get all keys from the list of supported arrangements, which can be either Global or Protected types
        supportedLayoutArrangements
          // Filter the list of keys down to only the items that are listed in supportedLayoutArrangements.
          // In this way, we can map specific protected arrangements to specific customers.
          .filter(option => !!options[option])
          .map(option => ({
            displayName: options[option],
            value: option,
          }));

      if (validOptions.length > 0) {
        const index = validOptions.findIndex(
          option => option.value === GlobalCustomerUserConfigTradingLayoutArrangements.Default
        );

        // Always ensure that our "Default" option is the first option in the list. If we find
        // it anywhere here, just splice it out, and we'll unshift it onto the top down below,
        // which also happens if it's not found anywhere here.
        if (index >= 0) {
          validOptions.splice(index, 1);
        }

        validOptions.unshift({
          displayName: GlobalCustomerUserConfigTradingLayoutArrangements.Default,
          value: GlobalCustomerUserConfigTradingLayoutArrangements.Default,
        });
      }

      return validOptions;
    }

    return EMPTY_ARRAY;
  }, [layoutType, supportedLayoutArrangements]);
}
