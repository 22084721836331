import {
  Box,
  Button,
  ButtonVariants,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  IndicatorBadge,
  IndicatorDotVariants,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  useDynamicCallback,
  type DrawerProps,
  type SubAccount,
} from '@talos/kyoko';
import { useSubAccounts } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { Prompt } from 'react-router';
import { SubAccountDrawerHeader } from './styles';
import { useSubAccountGeneralTab } from './tabs/SubAccountGeneralTab';
import { useSubAccountRollupMembersTab } from './tabs/SubAccountRollupMembershipsTab';

type EditSubAccountRollupsDrawerProps = DrawerProps & { selectedSubAccountName: SubAccount['Name'] | null };

export function EditSubAccountRollupsDrawer({
  selectedSubAccountName,
  ...drawerProps
}: EditSubAccountRollupsDrawerProps) {
  const { subAccountsByName } = useSubAccounts();

  const subAccount = selectedSubAccountName ? subAccountsByName?.get(selectedSubAccountName) : undefined;

  return (
    <Drawer {...drawerProps}>
      {subAccount && <EditSubAccountRollups key={subAccount.Name} subAccount={subAccount} {...drawerProps} />}
    </Drawer>
  );
}

function EditSubAccountRollups({ subAccount, close, isOpen }: { subAccount: SubAccount } & DrawerProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);

  const generalTab = useSubAccountGeneralTab({
    subAccount,
  });
  const rollupMembersTab = useSubAccountRollupMembersTab({
    subAccount,
  });

  const viewableTabs = useMemo(() => {
    return [generalTab, rollupMembersTab].filter(tab => tab.viewable);
  }, [generalTab, rollupMembersTab]);

  const handleSaveChanges = useCallback(() => {
    viewableTabs.forEach(tab => tab.save());
  }, [viewableTabs]);

  const isDirty = viewableTabs.some(tab => tab.isDirty);
  const isDirtyMap = new Map(viewableTabs.map(tab => [tab.name, tab.isDirty]));

  const handleClose = useDynamicCallback(() => {
    if (!isDirty) {
      return close();
    }
    const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      close();
    }
  });
  return (
    <>
      <Prompt when={isOpen && isDirty} message="You have unsaved changes. Are you sure you want to leave?" />
      <SubAccountDrawerHeader>
        <Flex gap="spacingSmall">
          <Text color="colorTextImportant">{subAccount.DisplayName}</Text>
          <IndicatorBadge children="Sub Account Rollup" />
        </Flex>
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
      </SubAccountDrawerHeader>
      <DrawerContent>
        <Tabs w="100%" h="100%" selectedIndex={activeTabIndex} onSelect={handleTabChange} size={TabSize.Large}>
          <TabList isBordered>
            {viewableTabs.map(tab => (
              <Tab
                showDot={isDirtyMap.get(tab.name)}
                dotVariant={IndicatorDotVariants.Primary}
                label={tab.name}
                suffix={<IndicatorBadge children={tab.badgeContent} />}
                key={tab.name}
              />
            ))}
          </TabList>
          <Flex h="100%" flexDirection="column" pt="spacingMedium">
            {viewableTabs.map(tab => (
              <Box
                key={tab.name}
                display={viewableTabs[activeTabIndex].name === tab.name ? 'flex' : 'none'}
                w="100%"
                h="100%"
              >
                {tab.component}
              </Box>
            ))}
          </Flex>
        </Tabs>
      </DrawerContent>
      <DrawerFooter>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSaveChanges} disabled={!isDirty} variant={ButtonVariants.Primary}>
          Save
        </Button>
      </DrawerFooter>
    </>
  );
}
