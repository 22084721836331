import { CUSTOMER_ORDER, isAfterOrEqual, useConstant, useStaticSubscription, type CustomerOrder } from '@talos/kyoko';
import { useEffect } from 'react';
import { BehaviorSubject, map } from 'rxjs';
import { scan } from 'rxjs/operators';
import { OPEN_ORDER_STATUSES } from '../../../providers/constants';

// Following existing pattern in OrdersProvider.tsx but for CustomerOrders
// The customerOrderByOrderIDObs is split into a constant BehaviorSubject and useEffect
// in order to ensure that we have a stable observable instance returned
export const useOpenCustomerOrders = function useOpenCustomerOrders() {
  const { data: openCustomerOrders } = useStaticSubscription<CustomerOrder>(
    {
      name: CUSTOMER_ORDER,
      tag: 'CUSTOMER_OPEN_ORDERS',
      Statuses: OPEN_ORDER_STATUSES,
      HideApiCalls: true,
      Throttle: '1s',
      sort_by: '-SubmitTime',
    },
    { loadAll: true }
  );

  const customerOrderByOrderIDObs: BehaviorSubject<Map<string, CustomerOrder>> = useConstant(
    new BehaviorSubject(new Map())
  );
  useEffect(() => {
    const subscription = openCustomerOrders
      .pipe(
        map(open => open.data),
        scan((orders, data) => {
          data.forEach(d => {
            const id = d.OrderID;
            const existingOrder = orders.get(id);

            if (
              existingOrder == null ||
              !d.Timestamp ||
              !existingOrder?.Timestamp ||
              isAfterOrEqual(new Date(d.Timestamp), new Date(existingOrder.Timestamp))
            ) {
              orders.set(id, d);
            }
          });
          return orders;
        }, new Map<string, CustomerOrder>())
      )
      .subscribe(orders => customerOrderByOrderIDObs.next(orders));
    return () => {
      subscription.unsubscribe();
    };
  }, [openCustomerOrders, customerOrderByOrderIDObs]);

  return customerOrderByOrderIDObs;
};
