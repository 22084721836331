import { InlineFormattedNumber, percentToBps, type InlineFormattedNumberProps } from '@talos/kyoko';

export interface SpreadFilledSpreadProps extends InlineFormattedNumberProps {
  spread: Parameters<typeof percentToBps>[0];
  filledSpread: Parameters<typeof percentToBps>[0];
}

export function SpreadFilledSpread({ spread, filledSpread, ...props }: SpreadFilledSpreadProps) {
  return (
    <>
      {!spread ? '-' : <InlineFormattedNumber number={percentToBps(spread)} currency="BPS" {...props} />}
      {' / '}
      {!filledSpread ? '-' : <InlineFormattedNumber number={percentToBps(filledSpread)} currency="BPS" {...props} />}
    </>
  );
}
