import styled from 'styled-components';

import { Box } from '@talos/kyoko';

export const ChartWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  overflow: hidden;
`;

export const ChartLegend = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingMedium}px;
  display: flex;
  justify-content: space-between;
`;

export const ChartLegendLabel = styled(Box)``;
ChartLegendLabel.defaultProps = {
  fontSize: 'fontSizeTiny',
  textTransform: 'uppercase',
};

export const ChartLegendValue = styled(Box)``;
ChartLegendValue.defaultProps = {
  fontSize: 'fontSizeDefault',
  color: 'colorTextImportant',
};

export const ChartLegendItem = styled.button.attrs(() => ({ type: 'button' }))<{ visible: boolean }>`
  flex: 0 0 auto;
  text-align: left;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: block;
  white-space: nowrap;
  transition: opacity 150ms;

  ${({ visible }) => !visible && `opacity: 0.5;`};
`;

export const TradeMarkerPopover = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  display: none;
  padding: ${({ theme }) => theme.spacingSmall}px;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  color: ${({ theme }) => theme.colors.gray['100']};
  background: ${({ theme }) => theme.colors.gray.main};
  text-align: left;
  z-index: 1000;
  pointer-events: none;
`;

export const ChartMeta = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colorTextMuted};
  display: flex;
  align-items: center;
  z-index: 1000;

  > * + * {
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;
