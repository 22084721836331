// We need to keep this security util file in the ava repository since ava extends the kyoko security type
// in kyoko.d.ts

import {
  isCalendarSpread,
  isMultileg,
  isSecurityQuantoFuture,
  useSecuritiesContext,
  type FeeModeEnum,
  type OrderStrategy,
  type RequiredProperties,
  type Security,
} from '@talos/kyoko';
import { useCallback } from 'react';

/** Type guard for multileg securities. */
export const isMultiLegSecurity = (security?: Security): security is RequiredProperties<Security, 'MultilegDetails'> =>
  !!(security && security?.MultilegDetails && isMultileg(security));

/**
 * Asserts if the security must and can only be traded in terms of contracts.
 * As of: https://talostrading.atlassian.net/browse/UI-2673
 * Multileg where Leg[0] has NO positionCurrency
 * @returns A function that asserts if the security must be traded in terms of contracts.
 */
export const useAssertSecurityOnlyPlaceInQtyContract = () => {
  const { securitiesBySymbol } = useSecuritiesContext();

  return useCallback(
    (security?: Security) => {
      return assertSecurityOnlyPlaceInQtyContract(securitiesBySymbol, security);
    },
    [securitiesBySymbol]
  );
};

export function assertSecurityOnlyPlaceInQtyContract(securitiesBySymbol: Map<string, Security>, security?: Security) {
  if (isCalendarSpread(security) || isSecurityQuantoFuture(security)) {
    return true;
  }
  if (!isMultiLegSecurity(security)) {
    return false;
  }

  const legOneSymbol = security.MultilegDetails.Legs.at(0)?.Symbol;
  const legOneSecurity = legOneSymbol ? securitiesBySymbol.get(legOneSymbol) : undefined;

  if (!legOneSecurity) {
    return false;
  }
  const legOnePositionCurrency = legOneSecurity.PositionCurrency;
  if (!legOnePositionCurrency) {
    return true;
  }

  return false;
}

export const isOrderTypeLimitAllIn = (showAllInPrices: FeeModeEnum | null | undefined, security?: Security) => {
  return !!showAllInPrices;
};

export const isStrategySupportedBySecurity = (strategy: OrderStrategy, security: Security | undefined) => {
  if (!security) {
    return false;
  }
  if (strategy.InstrumentScope === 'All') {
    return true;
  }
  // For compatibility assume: Securities without a Composition are Native, strategys without an InstrumentScope are Native
  if (security.Composition !== 'Synthetic') {
    return strategy.InstrumentScope === undefined || strategy.InstrumentScope === 'Native';
  }
  return strategy.InstrumentScope === security.Composition;
};
