import {
  ACTION,
  ConnectionModeEnum,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  filterByCellValueMenuItem,
  getRowNodesToOperateOn,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useGlobalToasts,
  useJsonModal,
  useMixpanel,
  useUserContext,
  wait,
  type FilterableProperty,
  type MixpanelInstance,
  type UseDisclosureReturn,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItems, GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-enterprise';
import { useRoleAuth } from 'hooks';
import { compact } from 'lodash';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { useRef, useState } from 'react';
import { EnableDisableSelectedDialog } from './Dialogs/EnableDisableSelected';
import { canEnableSecurityStatus, isMarketDataEnabled, isSecurityStatusEnabled, updateSecurityStatus } from './helpers';
import type { ActionType, EnableDisableDialogType, MarketSecurityStatusLocal } from './types';
import { colIDToFilterBuilderKey } from './useSecurityMasterFilter';

const BULK_UPDATE_DELAY = 100;

export const useSecurityMastersMenu = ({
  openClause,
  filterableProperties,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}): {
  getContextMenuItems: GetContextMenuItems<MarketSecurityStatusLocal>;
  dialogComponents: JSX.Element[];
} => {
  const selectedItemsRef = useRef<MarketSecurityStatusLocal[]>([]);
  const [actionType, setActionType] = useState<ActionType>('Enable');
  const [type, setType] = useState<EnableDisableDialogType>('Symbol');
  const [itemsToChange, setItemsToChange] = useState<MarketSecurityStatusLocal[]>([]);
  const { add: addToast } = useGlobalToasts();
  const { orgApiEndpoint } = useUserContext();
  const { isAuthorized } = useRoleAuth();
  const { getConfig } = useOrgConfiguration();
  const ableToEdit = isAuthorized(ACTION.EDIT_SECMASTER);
  const mixpanel = useMixpanel();

  const dialog = useDisclosure();

  const onEnableDisable = useDynamicCallback(async (mktStatus: MarketSecurityStatusLocal[]) => {
    const mktStatusToDisable = mktStatus.filter(status => isSecurityStatusEnabled(status));
    const mktStatusToEnable = mktStatus.filter(status => !isSecurityStatusEnabled(status));
    for (const status of mktStatusToDisable) {
      /*  */
      mixpanel.track(MixpanelEvent.MarketSecurity, {
        [MixpanelEventProperty.Enabled]: false,
        [MixpanelEventProperty.Method]: 'Symbol',
      });
      updateSecurityStatus({
        orgApiEndpoint,
        mktSecStatus: status,
        data: {
          Mode: ConnectionModeEnum.Down,
        },
        addToast,
      });
      await wait(getConfig(OrgConfigurationKey.OrgSecmasterEditDelay, BULK_UPDATE_DELAY));
    }
    for (const status of mktStatusToEnable) {
      if (canEnableSecurityStatus(status)) {
        mixpanel.track(MixpanelEvent.MarketSecurity, {
          [MixpanelEventProperty.Enabled]: true,
          [MixpanelEventProperty.Method]: 'Symbol',
        });
        updateSecurityStatus({
          orgApiEndpoint,
          mktSecStatus: status,
          data: {
            Mode: ConnectionModeEnum.Up,
          },
          addToast,
        });
      }
      await wait(getConfig(OrgConfigurationKey.OrgSecmasterEditDelay, BULK_UPDATE_DELAY));
    }
  });

  const onEnableDisableMarketData = useDynamicCallback(async (mktStatus: MarketSecurityStatusLocal[]) => {
    const mktStatusToDisable = mktStatus.filter(status => isMarketDataEnabled(status));
    const mktStatusToEnable = mktStatus.filter(status => !isMarketDataEnabled(status));
    for (const status of mktStatusToDisable) {
      mixpanel.track(MixpanelEvent.MarketSecurity, {
        [MixpanelEventProperty.Enabled]: false,
        [MixpanelEventProperty.Method]: 'MarketData',
      });
      updateSecurityStatus({
        orgApiEndpoint,
        mktSecStatus: status,
        data: {
          Capabilities: {
            ...status.Capabilities,
            MarketData: false,
          },
        },
        addToast,
      });
      await wait(getConfig(OrgConfigurationKey.OrgSecmasterEditDelay, BULK_UPDATE_DELAY));
    }
    for (const status of mktStatusToEnable) {
      mixpanel.track(MixpanelEvent.MarketSecurity, {
        [MixpanelEventProperty.Enabled]: true,
        [MixpanelEventProperty.Method]: 'MarketData',
      });
      updateSecurityStatus({
        orgApiEndpoint,
        mktSecStatus: status,
        data: {
          Capabilities: {
            ...status.Capabilities,
            MarketData: true,
          },
        },
        addToast,
      });
      await wait(getConfig(OrgConfigurationKey.OrgSecmasterEditDelay, BULK_UPDATE_DELAY));
    }
  });

  const disableItem = useDynamicCallback((mktStatus: MarketSecurityStatusLocal[]) =>
    disableSecurityMenuItem({ mktStatus, onEnableDisable, dialog, mixpanel, setActionType, setType, setItemsToChange })
  );
  const enableItem = useDynamicCallback((mktStatus: MarketSecurityStatusLocal[]) =>
    enableSecurityMenuItem({ mktStatus, onEnableDisable, dialog, mixpanel, setActionType, setType, setItemsToChange })
  );
  const disableMarketDataItem = useDynamicCallback((mktStatus: MarketSecurityStatusLocal[]) =>
    disableMarketDataMenuItem({
      mktStatus,
      onEnableDisableMarketData,
      dialog,
      mixpanel,
      setActionType,
      setType,
      setItemsToChange,
    })
  );
  const enableMarketDataItem = useDynamicCallback((mktStatus: MarketSecurityStatusLocal[]) =>
    enableMarketDataMenuItem({
      mktStatus,
      onEnableDisableMarketData,
      dialog,
      mixpanel,
      setActionType,
      setType,
      setItemsToChange,
    })
  );

  const getMenuItems = useDynamicCallback((mktStatus: MarketSecurityStatusLocal[]) => {
    const menuItems: (MenuItemDef | null)[] = [];
    if (ableToEdit) {
      const actions = compact([
        disableItem(mktStatus),
        enableItem(mktStatus),
        enableMarketDataItem(mktStatus),
        disableMarketDataItem(mktStatus),
      ]);
      menuItems.push(...actions);
    }
    return menuItems;
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { jsonModal, handleClickJson } = useJsonModal();

  const getContextMenuItems: GetContextMenuItems<MarketSecurityStatusLocal> = useDynamicCallback(
    (params: GetContextMenuItemsParams) => {
      selectedItemsRef.current = getRowNodesToOperateOn(params).map(node => node.data);
      const menuItems = getMenuItems(selectedItemsRef.current);

      return compact([
        params.node?.data
          ? {
              name: 'Show JSON',
              action: () => handleClickJson(params.node?.data),
              icon: `<i class="ag-icon ${IconName.Braces}"/>`,
            }
          : undefined,
        'separator',
        ...filterByCellValueMenuItem({
          params,
          filterableProperties,
          openClause,
          colIDToFilterBuilderKey,
          mixpanel,
        }),
        ...menuItems,
        ...getDefaultContextMenuItems(params),
      ]);
    }
  );

  return {
    getContextMenuItems,
    dialogComponents: [
      <EnableDisableSelectedDialog
        key="resume-dialog"
        selectedItems={itemsToChange}
        enableDisableDialog={dialog}
        onConfirm={mktStatus => {
          if (type === 'Symbol') {
            onEnableDisable(mktStatus);
          } else {
            onEnableDisableMarketData(mktStatus);
          }
        }}
        type={type}
        actionType={actionType}
      />,
      jsonModal,
    ],
  };
};

interface SecurityMastersMenuItemProps {
  mktStatus: MarketSecurityStatusLocal[];
  mixpanel: MixpanelInstance;
  setActionType: React.Dispatch<React.SetStateAction<ActionType>>;
  setType: React.Dispatch<React.SetStateAction<EnableDisableDialogType>>;
  setItemsToChange: React.Dispatch<React.SetStateAction<MarketSecurityStatusLocal[]>>;
}

const disableSecurityMenuItem = ({
  mktStatus,
  onEnableDisable,
  mixpanel,
  dialog,
  setActionType,
  setType,
  setItemsToChange,
}: SecurityMastersMenuItemProps & {
  onEnableDisable: (mktStatus: MarketSecurityStatusLocal[]) => void;
  dialog: UseDisclosureReturn;
}) => {
  const mktStatusToDisable = mktStatus.filter(status => isSecurityStatusEnabled(status));
  if (mktStatusToDisable.length < 1) {
    return null;
  }

  const name = mktStatusToDisable.length === 1 ? 'Disable Symbol' : 'Disable ' + mktStatusToDisable.length + ' Symbols';
  return {
    name,
    action: () => {
      mixpanel.track(MixpanelEvent.MarketSecurity, {
        [MixpanelEventProperty.Enabled]: false,
        [MixpanelEventProperty.Method]: 'Symbol',
      });
      if (mktStatusToDisable.length === 1) {
        onEnableDisable(mktStatusToDisable);
      } else {
        mixpanel.track(MixpanelEvent.MarketSecurityEnableDisableDialogOpened);
        setItemsToChange(mktStatusToDisable);
        setActionType('Disable');
        setType('Symbol');
        dialog.open();
      }
    },
    icon: `<i class="ag-icon ${IconName.CloseCircleSolid}"/>`,
    mktStatusToDisable,
  };
};

const enableSecurityMenuItem = ({
  mktStatus,
  onEnableDisable,
  mixpanel,
  dialog,
  setActionType,
  setType,
  setItemsToChange,
}: SecurityMastersMenuItemProps & {
  onEnableDisable: (mktStatus: MarketSecurityStatusLocal[]) => void;
  dialog: UseDisclosureReturn;
}) => {
  // We can enable a market security status if the market we belong to is not set to Down, and we haven't already requested to enable the market security status
  const mktStatusToEnable = mktStatus.filter(canEnableSecurityStatus);
  if (mktStatusToEnable.length < 1) {
    return null;
  }

  const name = mktStatusToEnable.length === 1 ? 'Enable Symbol' : 'Enable ' + mktStatusToEnable.length + ' Symbols';
  return {
    name,
    action: () => {
      mixpanel.track(MixpanelEvent.MarketSecurity, {
        [MixpanelEventProperty.Enabled]: true,
        [MixpanelEventProperty.Method]: 'Symbol',
      });
      if (mktStatusToEnable.length === 1) {
        onEnableDisable(mktStatusToEnable);
      } else {
        mixpanel.track(MixpanelEvent.MarketSecurityEnableDisableDialogOpened);
        setItemsToChange(mktStatusToEnable);
        setActionType('Enable');
        setType('Symbol');
        dialog.open();
      }
    },
    icon: `<i class="ag-icon ${IconName.CheckCircleSolid}"/>`,
    mktStatusToEnable,
  };
};

const disableMarketDataMenuItem = ({
  mktStatus,
  onEnableDisableMarketData,
  mixpanel,
  dialog,
  setActionType,
  setType,
  setItemsToChange,
}: SecurityMastersMenuItemProps & {
  onEnableDisableMarketData: (mktStatus: MarketSecurityStatusLocal[]) => void;
  dialog: UseDisclosureReturn;
}) => {
  const mktStatusToDisable = mktStatus.filter(status => isMarketDataEnabled(status));
  if (mktStatusToDisable.length < 1) {
    return null;
  }

  const name =
    mktStatusToDisable.length === 1 ? 'Disable Market Data' : 'Disable ' + mktStatusToDisable.length + ' Market Data';
  return {
    name,
    action: () => {
      mixpanel.track(MixpanelEvent.MarketSecurity, {
        [MixpanelEventProperty.Enabled]: false,
        [MixpanelEventProperty.Method]: 'MarketData',
      });
      if (mktStatusToDisable.length === 1) {
        onEnableDisableMarketData(mktStatusToDisable);
      } else {
        mixpanel.track(MixpanelEvent.MarketSecurityEnableDisableDialogOpened);
        setItemsToChange(mktStatusToDisable);
        setActionType('Disable');
        setType('MarketData');
        dialog.open();
      }
    },
    icon: `<i class="ag-icon ${IconName.CloseCircleSolid}"/>`,
    mktStatusToDisable,
  };
};

const enableMarketDataMenuItem = ({
  mktStatus,
  onEnableDisableMarketData,
  mixpanel,
  dialog,
  setActionType,
  setType,
  setItemsToChange,
}: SecurityMastersMenuItemProps & {
  onEnableDisableMarketData: (mktStatus: MarketSecurityStatusLocal[]) => void;
  dialog: UseDisclosureReturn;
}) => {
  const mktStatusToEnable = mktStatus.filter(status => !isMarketDataEnabled(status));
  if (mktStatusToEnable.length < 1) {
    return null;
  }

  const name =
    mktStatusToEnable.length === 1 ? 'Enable Market Data' : 'Enable ' + mktStatusToEnable.length + ' Market Data';
  return {
    name,
    action: () => {
      mixpanel.track(MixpanelEvent.MarketSecurity, {
        [MixpanelEventProperty.Enabled]: true,
        [MixpanelEventProperty.Method]: 'MarketData',
      });
      if (mktStatusToEnable.length === 1) {
        onEnableDisableMarketData(mktStatusToEnable);
      } else {
        mixpanel.track(MixpanelEvent.MarketSecurityEnableDisableDialogOpened);
        setItemsToChange(mktStatusToEnable);
        setActionType('Enable');
        setType('MarketData');
        dialog.open();
      }
    },
    icon: `<i class="ag-icon ${IconName.CheckCircleSolid}"/>`,
    mktStatusToEnable,
  };
};
