import {
  PresenceEnum,
  getScaleFromIncrement,
  type BaseField,
  type FieldData,
  type OrderStrategy,
  type Security,
  type StrategyLike,
} from '@talos/kyoko';
import { mapParameterToField } from './Strategies';

// Ideally we will have one initializeStrategyParams for every form but splitting Order form & Sales order form
// for now as both have different validations
//[ UI-4182] - Change Strategy Qty fields validation in order form
export const initializeStrategyParams = ({
  security,
  strategy,
  isModify,
}: {
  security: Security | undefined;
  strategy: StrategyLike | OrderStrategy | undefined;
  isModify: boolean;
}): { [key: string]: BaseField<FieldData, any> } => {
  const params = {};

  if (strategy) {
    strategy.Parameters
      // Only TopLevelHidden param that exists seem to be OrdType and only applicable on Market strategy -- and we can handle that manually
      .filter(param => param.Presence !== PresenceEnum.TopLevelHidden && param.Presence !== PresenceEnum.Hidden)
      .forEach(param => {
        params[param.Name] = mapParameterToField(
          param,
          getScaleFromIncrement(security?.MinPriceIncrement),
          getScaleFromIncrement(security?.MinSizeIncrement),
          undefined,
          isModify
        );
      });
  }

  return params;
};
