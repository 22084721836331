import { startCase } from 'lodash';
import { useMemo } from 'react';
import { CustomerAddressStatusEnum } from '../../../../types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

/**
 * This hook creates the filter property definition for the CustomerAddressStatusEnum.
 */
export function useCustomerAddressStatusFilter() {
  return useMemo(
    () =>
      ({
        key: 'Statuses',
        label: 'Status',
        icon: IconName.CheckCircle,
        control: 'multi-select',
        options: Object.keys(CustomerAddressStatusEnum),
        getOptionLabel: (option: string) => startCase(option),
      } as const satisfies FilterableProperty),
    []
  );
}
