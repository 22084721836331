import { logger, useDynamicCallback, useGlobalToasts, type ISubaccount } from '@talos/kyoko';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  OrgConfigurationKey,
  useOrgConfiguration,
  useSubAccountRollupMemberships,
  useSubAccounts,
} from '../../providers';

/**
 * This hook serves to encapsulate the logic of waiting for a newly cloned sub account to have all its
 * related records needed post-cloning propagated to the relevant providers in the frontend.
 *
 * For example, when you clone a Sub Account with some trading permissions assigned to that model sub account, your usage of the newly created
 * clone might very well be dependant on all the cloned trading permissions being received by the frontend. Rollup memberships are another example of a related
 * entity besides trading permissions.
 *
 * After cloning a sub account, you can call the verifyCloning function this hook returns. The function returns a promise which will give you
 * the information you need about if all the relevant cloning records have been propagated to the providers in the UI.
 */
export const useSubAccountCloningVerification = () => {
  const { add: addToast } = useGlobalToasts();
  const { getConfig } = useOrgConfiguration();
  const relatedDataTimeoutMs = getConfig(OrgConfigurationKey.DuplicateSubAccountRelatedDataFailureTimeoutMs, 5000);
  const { rollupMembershipsByChildParent } = useSubAccountRollupMemberships();
  const { tradableSubAccountNamesSet } = useSubAccounts();

  const [modelSubAccount, setModelSubAccount] = useState<ISubaccount | undefined>();
  const [newlyClonedSubAccount, setNewlyClonedSubAccount] = useState<ISubaccount | undefined>();
  const [deferredPromise, setDeferredPromise] = useState<
    | {
        resolve: (value: ISubaccount) => void;
        reject: (reason: string) => void;
      }
    | undefined
  >(undefined);

  const verifyCloning = useDynamicCallback((_modelSubAccount: ISubaccount, _newlyClonedSubAccount: ISubaccount) => {
    const promise = new Promise<ISubaccount>((resolve, reject) => {
      // We defer the resolution of this promise. See https://stackoverflow.com/questions/31069453/creating-a-es6-promise-without-starting-to-resolve-it
      setDeferredPromise({ resolve, reject });
    });
    setModelSubAccount(_modelSubAccount);
    setNewlyClonedSubAccount(_newlyClonedSubAccount);
    return promise;
  });

  /**
   * A boolean piece of state representing whether or not we have observed the newlyClonedSubAccount in the tradableSubAccountsSet data set.
   *
   * If we for some reason are unable to calculate this (eg missing inputs), returns false.
   * If the tradableSubAccountsSet data set isnt relevant to wait for, true is returned.
   */
  const newlyClonedSubAccountPropagatedToTradableSet = useMemo(() => {
    if (!modelSubAccount || !newlyClonedSubAccount) {
      return false;
    }

    if (!tradableSubAccountNamesSet.has(modelSubAccount.Name)) {
      return true;
    }

    return tradableSubAccountNamesSet.has(newlyClonedSubAccount.Name);
  }, [modelSubAccount, newlyClonedSubAccount, tradableSubAccountNamesSet]);

  /**
   * A boolean piece of state representing whether or not we have observed the newlyClonedSubAccount in the rollup memberships data set.
   *
   * If we for some reason are unable to calculate this (eg missing inputs), returns false.
   * If the sub account we cloned was not in the rollup memberships data set to begin with, the newly cloned account wont be either,
   * and this will instantly return true.
   */
  const newlyClonedSubAccountPropagatedToRollupMemberships = useMemo(() => {
    if (!modelSubAccount || !newlyClonedSubAccount) {
      return false;
    }

    if (!rollupMembershipsByChildParent.has(modelSubAccount.SubaccountID)) {
      return true;
    }

    return rollupMembershipsByChildParent.has(newlyClonedSubAccount.SubaccountID);
  }, [modelSubAccount, newlyClonedSubAccount, rollupMembershipsByChildParent]);

  const verifying = modelSubAccount && newlyClonedSubAccount && deferredPromise != null;
  const necessaryDataSetsPopulated =
    newlyClonedSubAccountPropagatedToRollupMemberships && newlyClonedSubAccountPropagatedToTradableSet;

  const relatedDataFailureTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    if (!verifying) {
      return;
    }

    // We are now waiting for the related entities verification clauses to pass before being done with the modal.
    // We start a timeout which, if the ws updates never arrive, will handle notifying the user that something mightve gone wrong.
    // when the updates do arrive, we confirm and return the cloned subaccount.
    if (!necessaryDataSetsPopulated) {
      relatedDataFailureTimeout.current = setTimeout(() => {
        // we never received the necessary ws updates to guarantee visibility despite waiting for some time.
        logger.warn('Did not receive the necessary updates to guarantee usability of the newly created Sub Account.');

        clearTimeout(relatedDataFailureTimeout.current);

        deferredPromise.reject(
          'Did not receive the necessary updates to guarantee usability of the newly created Sub Account.'
        );
        setModelSubAccount(undefined);
        setNewlyClonedSubAccount(undefined);
      }, relatedDataTimeoutMs);
    } else {
      // everythings loaded!
      clearTimeout(relatedDataFailureTimeout.current);

      deferredPromise.resolve(newlyClonedSubAccount);
      setModelSubAccount(undefined);
      setNewlyClonedSubAccount(undefined);
    }
  }, [
    verifying,
    necessaryDataSetsPopulated,
    addToast,
    relatedDataTimeoutMs,
    modelSubAccount,
    newlyClonedSubAccount,
    deferredPromise,
  ]);

  return {
    /**
     * A function for you to call with the newly cloned sub account and its model sub account.
     *
     * Calling this function induces the hook to verify the cloning operation (comparing the two sub accounts). Once completed,
     * the promise returned by this function will be either resolved with the sub account or rejected with a reason string.
     */
    verifyCloning,
  };
};
