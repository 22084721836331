import { enableMapSet, setAutoFreeze } from 'immer';
import { createContext } from 'react';
import { createDispatchHook, createSelectorHook, createStoreHook, type ReactReduxContextValue } from 'react-redux';
import type { AppStateStore } from '.';
import type { AppState } from './types';

enableMapSet();

// By default we have immer autoFreeze disabled due to the fact NOT all of our Context maps are producing new instances each time with the parameter `newMapEachUpdate: true`.
// If we didn't disable it, then parts of the code base mutating these collections would throw an error.

// However by not freezing it forces immer to loop through each item in the collection instead of faster ref comparisons when doing equality checks which can cause performance issues.
// For collections that are however using `newMapEachUpdate: true`, we should manually freeze() them as seen in the reference data slice to avoid a performance issue such as UI-4080.

// Because this is an easy thing to forget to do, we should look at turning auto freeze back on and setting newMapEachUpdate to true for all context maps.
// The reason this hasn't been done yet however is due to worry over if we will be causing addition re-renders that we were not doing before, so this needs to be checked first.
setAutoFreeze(false);

export const AppStateContext = createContext<ReactReduxContextValue | null>(null);

export const useAppStateDispatch = createDispatchHook(AppStateContext).withTypes<AppStateStore['dispatch']>();
export const useAppStateSelector = createSelectorHook(AppStateContext).withTypes<AppState>();
export const useAppStateStore = createStoreHook(AppStateContext).withTypes<AppStateStore>();
