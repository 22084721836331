import type { TabProps } from './types';
import type { TabLabelerFn } from './useTabs';

interface NewTabLabelByTypeParams<T extends TabProps, TypeKey extends keyof T> {
  typeKey: TypeKey;
  typeToLabel: (type: T[TypeKey]) => string;
}

/**
 * Given a typeKey and a way to resolve `T[typeKey] => string`, returns a function to put on `useTabs.tabLabeler`.
 * This function creates correctly numerated tab when you have several tab types sharing one TabList.
 *
 * For example, you can have tabs Market, Market (1), Option. Adding a new tab of type Market will add a new tab: Market (2), and adding
 * a new tab of type Option will create a new tab with label Option (2).
 */
export function tabLabelerByType<T extends TabProps, TypeKey extends keyof T>({
  typeKey,
  typeToLabel,
}: NewTabLabelByTypeParams<T, TypeKey>): TabLabelerFn<T> {
  return (newTab, currentTabs, action) => {
    return getTabLabelForType({ typeKey, typeToLabel, tabs: currentTabs, newTabType: newTab[typeKey] });
  };
}

interface GetTabLabelForTypeParams<T extends TabProps, TypeKey extends keyof T> {
  tabs: T[];
  typeKey: TypeKey;
  newTabType: T[TypeKey];
  typeToLabel: (type: T[TypeKey]) => string;
}

/**
 * Given a list of tabs, what type you're looking for and some resolving helpers, returns a label for you to use for a new tab of this type.
 *
 * For example, you can have tabs Market, Market (1), Option. Calling this function with type Market will return Market (2),
 * calling it with type Option will return Option (2).
 */
export function getTabLabelForType<T extends TabProps, TypeKey extends keyof T>({
  tabs,
  newTabType,
  typeKey,
  typeToLabel,
}: GetTabLabelForTypeParams<T, TypeKey>) {
  const labelRegex = /\((\d+)\)/i;
  const { totalTabsOfType, maxCount } = tabs.reduce(
    ({ totalTabsOfType, maxCount }, existingTab) => {
      if (existingTab[typeKey] === newTabType) {
        // only count tabs that are of the same type as the one we are adding
        // Search for a count in parentheses at the end of the tab label
        const tabLabelSuffix = labelRegex.exec(existingTab.label ?? '')?.[1];
        const tabLabelCount = tabLabelSuffix ? parseInt(tabLabelSuffix, 10) : 0;
        // count the total number of this type of tab, as well as the highest suffix we've seen
        return { totalTabsOfType: totalTabsOfType + 1, maxCount: Math.max(maxCount, tabLabelCount) };
      }
      return { totalTabsOfType, maxCount };
    },
    { totalTabsOfType: 0, maxCount: 0 }
  );

  // Append a counter to the new tab name, higher than the highest suffix we've seen
  // This prevents an issue where if we add 3 tabs, delete the second one, and then add a new one,
  // we'd end up with 2 tabs with the suffix ` (3)` - our new one would have ` (4)`
  const label = `${typeToLabel(newTabType)}${
    totalTabsOfType > 0 ? ` (${Math.max(totalTabsOfType, maxCount) + 1})` : ''
  }`;

  return label;
}

/** A simple tab labeler which just enumerates new tab names, adding (1), (2) etc after some default tab prefix */
export function tabLabelerEnumerated<T extends TabProps>(prefix: string): TabLabelerFn<T> {
  return (tab, currentTabs, action) => {
    // When adding a new tab, the tab isnt in the list of currentTabs.
    const indexInCurrentTabs = currentTabs.findIndex(currentTab => currentTab.id === tab.id);
    const countToPutInLabel = indexInCurrentTabs === -1 ? currentTabs.length + 1 : indexInCurrentTabs + 1;
    return `${prefix} (${countToPutInLabel})`;
  };
}
