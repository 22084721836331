import {
  isEditingThisCell,
  useDynamicCallback,
  type TreasuryLink,
  type TreasuryLinkSourceOrDestinationField,
} from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import { TreasuryAddressSelectorAsWrapper } from './TreasuryAddressSelectorAsWrapper';

export function TreasuryAddressSelectorCellRenderer(params: ICellRendererParams<TreasuryLink>) {
  const sourceOrDestinationField = params.colDef?.colId as TreasuryLinkSourceOrDestinationField;

  const onStartEditing = useDynamicCallback(() => {
    params.api.stopEditing();
    setTimeout(() => {
      params.api.startEditingCell({ rowIndex: params.node.rowIndex ?? 0, colKey: sourceOrDestinationField });
    }, 0);
  });

  if (params.data) {
    return (
      <TreasuryAddressSelectorAsWrapper
        treasuryLink={params.data}
        sourceOrDestinationField={sourceOrDestinationField}
        onStartEditing={onStartEditing}
        isEditingThisCell={isEditingThisCell(params)}
      />
    );
  }
}
