import { HISTORICAL_POSITION_STAT, type DateRangeFilter, type ProductTypeEnum, type RequestStream } from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { usePortfolioChartSubscription } from '../../PortfolioChart/usePortfolioChartSubscription';
import { dateRangeFilterToResolution, type SelectableResolution } from '../../SelectableResolution';
import type { HistoricalPositionStat } from '../../types';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import {
  PRODUCTS,
  periodToChartDateRange,
  type PerformanceFilter,
  type PerformanceState,
  type Product,
} from '../types';

const requestResetKeys = ['SubAccounts', 'Symbols', 'AssetTypes'] satisfies (keyof HistoricalPositionsRequest)[];

export interface HistoricalPositionsRequest extends PerformanceFilter, DateRangeFilter, RequestStream {
  name: string;
  EquivalentCurrency: string;
  Resolution: SelectableResolution;
  IntervalStart?: string;
  BenchmarkSymbols?: string[];
  SubAccounts?: string[];
  AssetTypes?: ProductTypeEnum[];
}

export function useChartSubscriptions() {
  const { homeCurrency } = useDisplaySettings();

  const { state } = usePerformanceContext();
  const { period, product } = state;

  // On top of the request taking in a standard DateRangeFilter, it also takes in an IntervalStart argument.
  // The IntervalStart argument tells the backend when the first data point of the period is.
  // When the chart is zoomed in, and the StartDate is no longer == start of the selected period, we have to let the backend
  // know what left-most point all zoomed in data points should be relative to.
  const intervalStart = useMemo(() => {
    return periodToChartDateRange(period).StartDate;
  }, [period]);

  const [request, setRequest] = useState<HistoricalPositionsRequest>(
    getRequest(state, homeCurrency, intervalStart, product)
  );

  useEffect(() => {
    setRequest(prev => {
      const newRequest = getRequest(state, homeCurrency, intervalStart, product);
      return isEqual(prev, newRequest) ? prev : newRequest;
    });
  }, [intervalStart, homeCurrency, state, product]);

  const { isLoading, dataResetting, subscription } = usePortfolioChartSubscription<
    HistoricalPositionStat,
    HistoricalPositionsRequest
  >({
    request,
    subscriptionOptions: { loadAll: true },
    // Whenever the filter changes we trigger the "reset" behavior, causing the chart to wipe its existing data and start fresh.
    resetDataOnRequestFieldChanges: requestResetKeys,
  });

  return {
    dataLoading: isLoading,
    dataResetting,
    subscription,
  };
}

function getRequest(
  state: PerformanceState,
  homeCurrency: string,
  intervalStart: string | undefined,
  product: Product
): HistoricalPositionsRequest {
  return {
    name: HISTORICAL_POSITION_STAT,
    EquivalentCurrency: homeCurrency,
    tag: 'PortfolioPerformanceChart',
    Resolution: dateRangeFilterToResolution(state.chartRequestDateRange),
    IntervalStart: intervalStart,
    // We always request for the benchmark, we just toggle the visibility of it in the chart itself
    BenchmarkSymbols: ['BTC'],
    SubAccounts: state.subAccounts,
    AssetTypes: PRODUCTS[product].productTypes,
    ...state.filter,
    ...state.chartRequestDateRange,
  };
}
