import {
  IconName,
  NotificationVariants,
  OrdStatusEnum,
  QuoteStatusEnum,
  copyText,
  isCustomerOrder,
  isCustomerQuote,
  isOrder,
  useConstant,
  useDynamicCallback,
  useGlobalToasts,
  type CustomerOrder,
  type CustomerQuote,
  type Order,
} from '@talos/kyoko';
import type { MenuItemDef } from 'ag-grid-community';
import { useCopyCustomerOrderExecutionText } from 'components/SummaryCard/utils';
import { useCustomerOrder } from 'hooks';
import { useEffect, useState } from 'react';

type DataEntity = CustomerOrder | CustomerQuote | Order;
export const useCopyCustomerOrderTextMenuItem = (): ((data?: DataEntity) => MenuItemDef | null) => {
  const { add: addToast } = useGlobalToasts();
  const [customerOrderID, setCustomerOrderID] = useState<string | undefined>();
  const [customerRFQID, setCustomerRFQID] = useState<string | undefined>();

  // Only ends up being defined if DataEntity is a CustomerQuote
  const customerOrderFromCustomerQuote = useCustomerOrder({
    rfqID: customerRFQID,
    tag: 'useCopyCustomerOrderTextMenuItem',
  });

  const [waitingForOrderCopy, setWaitingForOrderCopy] = useState<boolean>(false);
  const customerOrderCopyText = useCopyCustomerOrderExecutionText({
    // If customerOrderFromCustomerQuote is defined, use its OrderID, otherwise use customerOrderID
    customerOrderID: customerOrderFromCustomerQuote?.OrderID ?? customerOrderID,
    tag: 'useCopyCustomerOrderTextMenuItem',
  });

  // Copy From Customer Order or Order
  const handleCopyCustomerOrderDetailsFromCustomerOrderID = useDynamicCallback((orderID: string) => {
    setCustomerOrderID(orderID);
    setWaitingForOrderCopy(true);
  });

  // Copy From Customer Quote
  const handleCopyCustomerOrderDetailsFromCustomerQuote = useDynamicCallback((rfqID: string) => {
    setCustomerRFQID(rfqID);
    setWaitingForOrderCopy(true);
  });

  useEffect(() => {
    if (waitingForOrderCopy && customerOrderCopyText) {
      copyText(customerOrderCopyText);
      addToast({ variant: NotificationVariants.Positive, text: 'Details copied to clipboard.', timeout: 2000 });
      // Reset the state so we don't copy the same text again
      setWaitingForOrderCopy(false);
    }
  }, [addToast, customerOrderCopyText, waitingForOrderCopy]);

  const copyMenuItemDefaults = useConstant({
    name: `Copy Customer Order Details`,
    icon: `<i class="ag-icon ${IconName.ClipboardCopy}"/>`,
  });

  return (data?: DataEntity) => {
    if (data == null) {
      return null;
    }

    if (isCustomerOrder(data)) {
      return {
        ...copyMenuItemDefaults,
        action: () => handleCopyCustomerOrderDetailsFromCustomerOrderID(data.OrderID),
        disabled: ![OrdStatusEnum.Filled, OrdStatusEnum.DoneForDay].includes(data.OrdStatus),
      };
    }
    if (isOrder(data)) {
      if (!data.customerOrderID) {
        return null;
      }
      return {
        ...copyMenuItemDefaults,
        action: () => handleCopyCustomerOrderDetailsFromCustomerOrderID(data.customerOrderID!),
        disabled: ![OrdStatusEnum.Filled, OrdStatusEnum.DoneForDay].includes(data.OrdStatus),
      };
    }
    if (isCustomerQuote(data)) {
      return {
        ...copyMenuItemDefaults,
        action: () => handleCopyCustomerOrderDetailsFromCustomerQuote(data.RFQID),
        disabled: ![QuoteStatusEnum.Filled].includes(data.QuoteStatus),
      };
    }

    return null;
  };
};
