import type { TabProps } from '@talos/kyoko';
import { v4 as uuid } from 'uuid';
import { DEFAULT_RECON_OVERVIEW_STATE, type ReconOverviewState } from './types';

export enum ReconTabType {
  ReconOverview = 'Recon Overview',
}

export interface ReconOverviewTab extends TabProps, ReconOverviewState {
  id: string;
  tabType: ReconTabType.ReconOverview;
  showZeroBalances?: boolean;
}

export type ReconTab = ReconOverviewTab;

interface ReconTabTypeDetail {
  getDefaultState: () => ReconTab;
}

// This static details set helps us define different tab types in one place and tells the page how to interact with them completely
// When we add new tab types to the page in the future, the only place we change is here
export const RECON_TABS_DETAILS = {
  [ReconTabType.ReconOverview]: {
    getDefaultState: (): ReconOverviewTab => ({
      id: uuid(),
      tabType: ReconTabType.ReconOverview,
      ...DEFAULT_RECON_OVERVIEW_STATE,
    }),
  },
} as const satisfies { [key in ReconTabType]: ReconTabTypeDetail };
