import { runValidation, useDynamicCallback } from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { object, string } from 'yup';
import type { SchemaLike } from 'yup/lib/types';
import { PositionTypeEnum } from '../../../hooks/usePositionRequests';
import type { UpdatePositionForm } from './types';

export const useUpdatePositionValidation = (form: UpdatePositionForm, positionType: PositionTypeEnum) => {
  const [errors, setErrors] = useState<Partial<Record<keyof UpdatePositionForm, string>>>({});
  const [touched, setTouched] = useState<Partial<Record<keyof UpdatePositionForm, boolean>>>({});

  const setAllTouched = useDynamicCallback(() =>
    setTouched({
      updateType: true,
      subAccount: true,
      marketAccount: true,
      asset: true,
      amount: true,
      avgCost: true,
      avgCostCurrency: true,
      timestamp: true,
      comments: true,
    } satisfies Record<keyof UpdatePositionForm, boolean>)
  ); // static test to ensure we arent missing any

  useEffect(() => {
    const schema: Partial<Record<keyof UpdatePositionForm, SchemaLike>> = {
      updateType: string().required('Update Type is required'),
      asset: string().required('Asset is required'),
      amount: string().required('Amount is required'),
    };

    // If avgCost is defined, then we also have to send an AvgCostCurrency
    if (form.avgCost) {
      schema.avgCostCurrency = string().required('Avg Cost Currency is required');
    }

    if (positionType === PositionTypeEnum.SubAccountPosition) {
      schema.subAccount = string().required('Sub Account is required');
    }

    if (positionType === PositionTypeEnum.MarketAccountPosition) {
      schema.marketAccount = string().required('Market Account is required');
    }

    setErrors(prev => {
      const next = runValidation(object().shape(schema), form);
      return isEqual(prev, next) ? prev : next;
    });
  }, [form, positionType]);

  return { errors, touched, setTouched, setAllTouched };
};
