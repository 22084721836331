import { useConnectionStatusContext, type MarketCredential } from '../contexts';
import { useObservableValue } from './useObservable';

export const useConnectionStatus = (marketCredential?: Pick<MarketCredential, 'CredentialID' | 'Market'>) => {
  const { connectionStatusByCredentialIDAndMarketName } = useConnectionStatusContext();
  const statuses = useObservableValue(
    () => connectionStatusByCredentialIDAndMarketName,
    [connectionStatusByCredentialIDAndMarketName]
  );
  return marketCredential != null
    ? statuses?.get(marketCredential.CredentialID)?.get(marketCredential.Market)
    : undefined;
};
