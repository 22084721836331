import {
  BEST_BID_IV,
  BEST_OFFER_IV,
  DELEVERAGE_QUEUE,
  FUNDING_RATE,
  FUNDING_TIME,
  LAST_PRICE,
  MARK_PRICE,
  MARK_PRICE_IV,
  NEXT_FUNDING_TIME,
  OPEN_INTEREST,
  OPEN_VALUE,
  PREDICTED_NEXT_FUNDING_RATE,
  SYMBOL,
  TURNOVER24H,
  UNDERLYING_PRICE,
  VOLUME24H,
} from 'tokens/marketDataStats';

export const BLACKLISTED_KEYS = [FUNDING_TIME, NEXT_FUNDING_TIME, SYMBOL, DELEVERAGE_QUEUE];
export const POSITION_CURRENCY_KEYS = [VOLUME24H, OPEN_INTEREST];
export const QUOTE_CURRENCY_KEYS = [MARK_PRICE, LAST_PRICE];
export const UNDERLYING_QUOTE_CURRENCY_KEYS = [UNDERLYING_PRICE];
export const SETTLEMENT_CURRENCY_KEYS = [TURNOVER24H, OPEN_VALUE];
export const PERCENT_KEYS = [MARK_PRICE_IV, BEST_BID_IV, BEST_OFFER_IV, PREDICTED_NEXT_FUNDING_RATE, FUNDING_RATE];
export const OPTIONS_GREEKS = ['Delta', 'Gamma', 'Vega', 'Theta', 'Rho'];
