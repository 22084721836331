import { Flex } from '@talos/kyoko';
import styled from 'styled-components';

export const DrawerHeader = styled(Flex)`
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacingDefault}px ${({ theme }) => theme.spacingMedium}px;
  min-height: 3.5rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;
