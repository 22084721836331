import {
  Post,
  SettlementPreview,
  request,
  useDynamicCallback,
  useEndpointsContext,
  type DataResponse,
  type SettlementResponse,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../providers';
import { SettleableTrade, type ISettleableTrade } from './CustomerSettlementMonitoring/TradeSettlementBlotter/types';

export type SettlementOption =
  | {
      type: 'Counterparty';
      Counterparty: string;
    }
  | {
      type: 'OrderIDs';
      OrderIDs: string[];
    }
  | {
      type: 'TradeIDs';
      settleableTrades: SettleableTrade[];
    };

export const useTradeSettlementRequests = () => {
  const { orgApiEndpoint } = useEndpointsContext();
  const { getConfig } = useOrgConfiguration();

  const settleTrade = useDynamicCallback((tradeID: string) => {
    return request('POST', `${orgApiEndpoint}/dealer/trades/${tradeID}/settle`, {});
  });

  const previewSettlement = useDynamicCallback((settlementOption: SettlementOption) => {
    return request<SubscriptionResponse<SettlementPreview>>(
      'POST',
      `${orgApiEndpoint}/settlement/preview`,
      settlementOptionToRequestBody(settlementOption)
    ).then(json => new SettlementPreview(json.data[0]));
  });

  const previewSettlementTrades = useDynamicCallback((settlementOption: SettlementOption) => {
    if (!orgApiEndpoint) {
      throw new Error('Orgapi endpoint not loaded');
    }

    return Post<DataResponse<ISettleableTrade>>(
      orgApiEndpoint,
      '/settlement/preview-trades',
      settlementOptionToRequestBody(settlementOption),
      // 50k below is an arbitrary very high number just to make sure we dont get stuck paginating forever if something goes wrong in BE for example. Getting trades can be expensive for BE
      {
        paginateRecords: parseInt(getConfig(OrgConfigurationKey.SettlementReportMaxTradeRecordsPaginated, '50000')),
        headers: { 'Content-Type': 'application/json' },
      }
    ).then(json => json.data.map(trade => new SettleableTrade(trade)));
  });

  const settleTrades = useDynamicCallback((settlementOption: SettlementOption) => {
    return request<SubscriptionResponse<SettlementResponse>>(
      'POST',
      `${orgApiEndpoint}/settlement`,
      settlementOptionToRequestBody(settlementOption)
    ).then(json => json.data[0]);
  });

  return {
    settleTrade,
    previewSettlement,
    previewSettlementTrades,
    settleTrades,
  };
};

function settlementOptionToRequestBody(settlementOption: SettlementOption) {
  if (settlementOption.type === 'TradeIDs') {
    return { TradeIDs: settlementOption.settleableTrades.map(trade => trade.TradeID) };
  }

  if (settlementOption.type === 'Counterparty') {
    return { Counterparty: settlementOption.Counterparty };
  }

  return {
    OrderIDs: settlementOption.OrderIDs,
  };
}
