import {
  DELETE,
  GET,
  PATCH,
  POST,
  PUT,
  request,
  UpdateActionEnum,
  useObservable,
  USER_GROUP,
  USER_GROUP_MEMBERSHIP,
  useStaticSubscription,
  useUserContext,
  wsScanToMap,
  type Response,
} from '@talos/kyoko';
import type React from 'react';
import { memo, useCallback, useMemo } from 'react';
import { scan, shareReplay } from 'rxjs/operators';
import type { UserGroup, UserGroupMembership } from 'types';
import { UserGroupsContext } from './UserGroupsContext';

export const UserGroupsProvider = memo(function UserGroupsProvider(props: React.PropsWithChildren<unknown>) {
  const { data: userGroupsSub } = useStaticSubscription<UserGroup>({
    name: USER_GROUP,
    tag: USER_GROUP,
  });

  const { data: membershipsSub } = useStaticSubscription<UserGroupMembership>({
    name: USER_GROUP_MEMBERSHIP,
    tag: USER_GROUP_MEMBERSHIP,
  });

  const userGroupsByID = useObservable(
    () =>
      userGroupsSub.pipe(
        wsScanToMap({ getUniqueKey: d => d.GroupID, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [userGroupsSub]
  );

  const membershipsByUser = useObservable(
    () =>
      membershipsSub.pipe(
        scan((map, json) => {
          json.initial && map.clear();
          const data = json?.data || [];
          for (const entity of data) {
            const key = entity.User;
            const current = map.get(key) || [];

            if (entity.UpdateAction === UpdateActionEnum.Update) {
              map.set(key, [...current, entity]);
            } else if (entity.UpdateAction === UpdateActionEnum.Remove) {
              map.set(
                key,
                current.filter(curr => curr.GroupID !== entity.GroupID)
              );
            }

            if (map.get(key).length === 0) {
              map.delete(key);
            }
          }
          return map;
        }, new Map()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [membershipsSub]
  );

  const membershipsByGroupID = useObservable(
    () =>
      membershipsSub.pipe(
        scan((map, json) => {
          json.initial && map.clear();
          const data = json?.data || [];
          for (const entity of data) {
            const key = entity.GroupID;
            const current = map.get(key) || [];

            if (entity.UpdateAction === UpdateActionEnum.Update) {
              map.set(key, [...current, entity]);
            } else if (entity.UpdateAction === UpdateActionEnum.Remove) {
              map.set(
                key,
                current.filter(curr => curr.GroupID !== entity.GroupID)
              );
            }

            if (map.get(key).length === 0) {
              map.delete(key);
            }
          }
          return map;
        }, new Map()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [membershipsSub]
  );

  const { orgApiEndpoint } = useUserContext();
  const endpoint = `${orgApiEndpoint}/user-groups`;

  const listUserGroups = useCallback(() => request<Response<UserGroup>>(GET, endpoint, null), [endpoint]);
  const getUserGroup = useCallback(id => request<Response<UserGroup>>(GET, `${endpoint}/${id}`, null), [endpoint]);
  const createUserGroup = useCallback(userGroup => request<Response<UserGroup>>(POST, endpoint, userGroup), [endpoint]);
  const updateUserGroup = useCallback(
    (id, userGroup) => request<Response<UserGroup>>(PUT, `${endpoint}/${id}`, userGroup),
    [endpoint]
  );
  const deleteUserGroup = useCallback(
    id => request<Response<UserGroup>>(DELETE, `${endpoint}/${id}`, null),
    [endpoint]
  );

  const listMemberships = useCallback(groupID => request(GET, `${endpoint}/${groupID}/users`, null), [endpoint]);
  const updateMembership = useCallback(
    (groupID, userID) => request(PUT, `${endpoint}/${groupID}/users/${userID}`, null),
    [endpoint]
  );
  const batchUpdateMemberships = useCallback(
    (groupID, action, data) => request(PATCH, `${endpoint}/${groupID}/users`, { action, data }),
    [endpoint]
  );
  const deleteMembership = useCallback(
    (groupID, userID) => request(DELETE, `${endpoint}/${groupID}/users/${userID}`, null),
    [endpoint]
  );

  const value = useMemo(
    () => ({
      userGroupsByID,
      membershipsByGroupID,
      membershipsByUser,

      listUserGroups,
      getUserGroup,
      createUserGroup,
      updateUserGroup,
      deleteUserGroup,

      listMemberships,
      updateMembership,
      batchUpdateMemberships,
      deleteMembership,
    }),
    [
      batchUpdateMemberships,
      createUserGroup,
      deleteMembership,
      deleteUserGroup,
      getUserGroup,
      listMemberships,
      listUserGroups,
      membershipsByGroupID,
      membershipsByUser,
      updateMembership,
      updateUserGroup,
      userGroupsByID,
    ]
  );

  return <UserGroupsContext.Provider value={value}>{props.children}</UserGroupsContext.Provider>;
});
