import { useMemo } from 'react';
import { map } from 'rxjs/operators';
import { MARKET_DATA_STATISTICS } from '../tokens';
import { useObservable, useObservableValue } from './useObservable';
import { useSubscription } from './useSubscription';

export interface MarketDataStatistics {
  Symbol: string;
  Market: string;
  MaxOrderPrice?: string;
  MinOrderPrice?: string;
  MarkPrice?: string;
  OpenInterest?: string;
  OpenValue?: string;
  Turnover24h?: string;
  LastPrice?: string;
  Volume24h?: string;
  FundingRate?: string;
  PredictedNextFundingRate?: string;
  FundingTime?: string;
  NextFundingTime?: string;
  BestBidIV: string;
  BestOfferIV: string;
  Delta: string;
  Gamma: string;
  IndexPrice: string;
  MarkPriceIV: string;
  Rho: string;
  Theta: string;
  UnderlyingPrice: string;
  Vega: string;
}

export const useMarketDataStatistics = (symbol: string, markets: string[], tag: string) => {
  const request = useMemo(() => {
    return !symbol
      ? null
      : {
          name: MARKET_DATA_STATISTICS,
          tag,
          Symbol: symbol,
          Markets: markets,
        };
  }, [symbol, markets, tag]);

  const { data: sub } = useSubscription(request);
  const source = useObservable<MarketDataStatistics>(() => sub.pipe(map(next => next.data?.[0] || {})), [sub]);
  const marketDataStatistics = useObservableValue(() => source, [source]);
  return { source, marketDataStatistics };
};
