import { FilterClauseType, type InitialFilterClause } from '@talos/kyoko';
import { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { useMemo } from 'react';
import type { BalancesFilter } from 'types';
import { getDefaultTreasuryManagementState } from './providers/getDefaultTreasuryManagementState';
import { useTreasuryManagementTabs } from './providers/TreasuryManagementStateAndTabsProvider';
import { TreasuryManagementTab } from './TreasuryManagementTab';

export const OperationsReports = () => {
  // We're only going to show one tab, so we don't need to worry about the tabId
  const { tabs, filtersInitialOpen } = useTreasuryManagementTabs();
  const { filterPropsMutator } = useContextBlotterFilter();

  // This memo computes the current initialFilterClauses based on the current tab.
  // When the current tab changes, it will re-compute and re-pass to the child FilterBuilder
  const initialFilterClausesResolved: InitialFilterClause[] = useMemo(() => {
    const filterToUse = tabs.items[0].filter ?? getDefaultTreasuryManagementState().filter;
    const filterKeys = Object.keys(filterToUse) as (keyof BalancesFilter)[];
    const initialFilterClauses = filterKeys
      .map(key => {
        const selections = filterToUse[key];

        if (selections == null) {
          // safeguard
          return undefined;
        }

        return {
          key,
          type: FilterClauseType.INCLUSIVE,
          selections,
        };
      })
      .compact();

    // To avoid a pre-rendering state change around the accordion filters,
    // have the external props mutator from {@link useContextBlotterFilter} remove the externally handled filtering clauses
    const resolved =
      filterPropsMutator({
        filterBuilderProps: {
          initialFilterClauses,
        },
      }).filterBuilderProps?.initialFilterClauses ?? [];
    return resolved;
  }, [filterPropsMutator, tabs.items]);

  return (
    // Within each tab we remount some providers and the components to get a clean slate each time
    <TreasuryManagementTab
      tabId={tabs.items[0].id!}
      initialFilterClauses={initialFilterClausesResolved}
      filtersInitialOpen={filtersInitialOpen}
    />
  );
};
