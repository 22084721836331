import {
  Box,
  Button,
  ButtonVariants,
  ConnectionModeEnum,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  IndicatorDotVariants,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  Tooltip,
  Tour,
  useDisclosure,
  useDynamicCallback,
  useGlobalToasts,
  useMarketsContext,
  useMixpanel,
  usePortal,
  useUserContext,
  type DrawerProps,
  type MarketConfig,
  type MarketCredential,
} from '@talos/kyoko';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { RemoveSelectedDialog } from './Dialogs/RemoveSelectedDialog';
import { DrawerHeader } from './styles';
import { useCredentialFeesTab } from './tabs/CredentialFeesTab';
import { useCredentialHistoryTab } from './tabs/CredentialHistoryTab';
import { useEditCredentialTab } from './tabs/EditCredentialGeneralTab';
import { useMarketAccountsTab } from './tabs/MarketAccountsTab';
import { RIGHT_ITEMS_PORTAL_ID } from './tabs/types';

type EditCredentialDrawerProps = {
  marketCredentials: MarketCredential[];
  marketConfigs: MarketConfig[];
  selectedCredential: MarketCredential;
  externalIP: string;
  onSaved: () => void;
  mktAccsWithFees: Set<string> | undefined;
  handleMarketAccountClick?: (marketAccountName: string) => void;
};

export const EditCredentialDrawer = memo((props: EditCredentialDrawerProps & DrawerProps) => {
  const {
    marketCredentials,
    marketConfigs,
    selectedCredential,
    externalIP,
    onSaved,
    mktAccsWithFees,
    handleMarketAccountClick,
    close,
    isOpen,
  } = props;
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const { marketsByName } = useMarketsContext();
  const { deleteMarketCredential } = useUserContext();
  const { add: addToast } = useGlobalToasts();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const selectedMarket = useMemo(
    () => marketsByName.get(props.selectedCredential?.Market),
    [marketsByName, props.selectedCredential]
  );
  const selectedMarketConfig = useMemo(
    () => marketConfigs.find(mktCfg => mktCfg.name === selectedMarket?.Name),
    [marketConfigs, selectedMarket]
  );

  const isCredentialDisabled = useMemo(
    () => selectedCredential?.Mode === ConnectionModeEnum.Down,
    [selectedCredential]
  );

  const generalTab = useEditCredentialTab({
    marketCredentials,
    marketConfigs,
    selectedCredential,
    selectedMarket,
    externalIP,
    selectedMarketConfig,
  });
  const marketAccountsTab = useMarketAccountsTab({
    credential: selectedCredential,
    handleMarketAccountClick,
  });
  const historyTab = useCredentialHistoryTab({
    credential: selectedCredential,
    marketConfigs,
  });
  const feesTab = useCredentialFeesTab({
    credential: selectedCredential,
    selectedMarketConfig,
    mktAccsWithFees,
  });

  const deleteDialog = useDisclosure();

  const viewableTabs = useMemo(() => {
    return [generalTab, marketAccountsTab, historyTab, feesTab].filter(tab => tab.viewable);
  }, [generalTab, marketAccountsTab, historyTab, feesTab]);

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      mixpanel.track(MixpanelEvent.ChangeCredentialsTab, {
        [MixpanelEventProperty.TabLabel]: viewableTabs[tabIndex].name,
      });
      setActiveTabIndex(tabIndex);
    },
    [mixpanel, viewableTabs]
  );

  const handleSaveChanges = useCallback(async () => {
    try {
      setIsSaving(true);
      for (const [tabIndex, tab] of viewableTabs.entries()) {
        if (tab.isDirty) {
          await tab.save().catch(e => {
            setActiveTabIndex(tabIndex); // there was an error in this tab, so we need to show it
            throw e; // rethrow to stop the loop
          });
        }
      }
      onSaved?.();
    } catch (e) {
      // ignore
    } finally {
      setIsSaving(false);
    }
  }, [viewableTabs, onSaved]);

  const handleOpenDeleteDialog = useCallback(() => deleteDialog.open(), [deleteDialog]);

  const isDirty = viewableTabs.some(tab => tab.isDirty);
  const isDirtyMap = new Map(viewableTabs.map(tab => [tab.name, tab.isDirty]));

  const handleClose = useDynamicCallback(() => {
    if (!isDirty) {
      return close();
    }

    const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      close();
    }
  });

  const handleDeleteCredential = useCallback(
    (credential: MarketCredential) => {
      setIsDeleting(true);
      deleteMarketCredential(credential.Market, credential.Name)
        .then(() => {
          addToast({
            text: `Deleted ${credential.Label}`,
            variant: NotificationVariants.Positive,
          });
          onSaved?.();
        })
        .catch(e => {
          addToast({
            text: e?.toString() || `Could not delete ${credential.Label}`,
            variant: NotificationVariants.Negative,
          });
        })
        .finally(() => {
          setIsDeleting(false);
        });
    },
    [addToast, deleteMarketCredential, onSaved]
  );

  // Allow each tab to add buttons to the right of the tabs by using a portal
  const { setPortalRef } = usePortal(RIGHT_ITEMS_PORTAL_ID);

  useEffect(() => {
    setIsSaving(false);
  }, [selectedCredential]);

  useEffect(() => {
    if (activeTabIndex > viewableTabs.length - 1) {
      setActiveTabIndex(0);
    }
  }, [activeTabIndex, viewableTabs]);

  const selectedItems = useMemo(() => [selectedCredential], [selectedCredential]);

  return (
    <Drawer {...props}>
      {selectedCredential && (
        <>
          <Prompt when={isOpen && isDirty} message="You have unsaved changes. Are you sure you want to leave?" />
          <DrawerHeader>
            <Flex gap="spacingDefault" alignItems="center">
              <Text color="colorTextImportant">Edit Credential</Text>
            </Flex>
            <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
          </DrawerHeader>
          <DrawerContent>
            <Tabs w="100%" h="100%" selectedIndex={activeTabIndex} onSelect={handleTabChange} size={TabSize.Large}>
              <TabList
                isBordered
                suppressOverflowList
                flex="0 0 auto"
                rightItems={<Box ref={setPortalRef} />}
                ml={-theme.spacingComfortable}
              >
                {viewableTabs.map(tab => (
                  <Tab
                    showDot={isDirtyMap.get(tab.name)}
                    dotVariant={IndicatorDotVariants.Primary}
                    label={tab.name}
                    key={tab.name}
                    id={tab.id}
                    suffix={tab.tour && <Tour tour={tab.tour} />}
                  />
                ))}
              </TabList>
              <Box h="100%" pt="spacingMedium" overflow="auto">
                {viewableTabs.map((tab, tabIndex) =>
                  activeTabIndex <= tabIndex ? (
                    <Box
                      key={tab.name}
                      display={viewableTabs[activeTabIndex].name === tab.name ? 'block' : 'none'}
                      w="100%"
                      h="100%"
                    >
                      {viewableTabs[activeTabIndex].name === tab.name && tab.component}
                    </Box>
                  ) : null
                )}
              </Box>
            </Tabs>
          </DrawerContent>
          <DrawerFooter>
            <Button onClick={handleClose}>Close</Button>
            <Tooltip
              tooltip={
                !isCredentialDisabled
                  ? 'Unable to Delete an Enabled Credential. Disable the Credential to Delete.'
                  : null
              }
            >
              <Button
                disabled={!isCredentialDisabled || isSaving}
                variant={ButtonVariants.Negative}
                onClick={handleOpenDeleteDialog}
                loading={isDeleting}
              >
                Delete
              </Button>
            </Tooltip>
            <Button
              onClick={handleSaveChanges}
              disabled={!isDirty || isDeleting}
              variant={ButtonVariants.Primary}
              loading={isSaving}
            >
              Save
            </Button>
          </DrawerFooter>
        </>
      )}
      <RemoveSelectedDialog
        selectedItems={selectedItems}
        removeDialog={deleteDialog}
        onConfirm={() => handleDeleteCredential(selectedCredential)}
      />
    </Drawer>
  );
});
