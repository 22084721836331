import { Checkbox, HelpIcon, MixpanelEvent, useMixpanel } from '@talos/kyoko';
import { useTradingSettings } from '../../../../providers/TradingSettingsContext';

export function DefaultReduceOnly() {
  const mixpanel = useMixpanel();
  const { enableReduceOnly, setEnableReduceOnly } = useTradingSettings();

  return (
    <Checkbox
      id="enableReduceOnly"
      name="enableReduceOnly"
      checked={enableReduceOnly}
      onChange={e => {
        mixpanel.track(
          e.target.checked
            ? MixpanelEvent.EnableReduceOnlyByDefaultEnabled
            : MixpanelEvent.EnableReduceOnlyByDefaultDisabled
        );
        setEnableReduceOnly(e.target.checked);
      }}
    >
      Enable Reduce Only by Default{' '}
      <HelpIcon tooltip="Enables the Reduce Only toggle by default on supporting symbols." />
    </Checkbox>
  );
}
