import type { InitialFilterClause } from '@talos/kyoko';
import { useCallback, useRef } from 'react';
import {
  useContextBlotterFilterProvider,
  type ContextBlotterFilterMergeArg,
  type ContextBlotterFilterState,
} from './index';

/** Get current state and filtering functions for the {@link ContextBlotterFilterProvider}
 * @returns filterPropsMutator - A 'cleanup' function that can be used to modify the filter input variables to
 * 1) match the input filter so it doesn't need immediate updates, AND
 * 2) remove any filter clauses from the accordion filter that are already being managed by the external context filter
 * @returns additionalFilterState - The current filter state for the context
 * - to be used in useEffects or related hooks to update the filter state
 */
export function useContextBlotterFilter<T extends ContextBlotterFilterMergeArg = ContextBlotterFilterMergeArg>(): {
  filterPropsMutator: ({ filterBuilderProps }: T) => T;
  additionalFilterState: ContextBlotterFilterState | undefined;
} {
  const { filter: providerFilter } = useContextBlotterFilterProvider();

  // Store the initial filter clauses and filter state, based on the initial component and provider filter
  // - this only gets set once per component instance, so it's safe to use a ref here
  const initialFilterPropsRef = useRef<{
    initialFilter: ContextBlotterFilterState | undefined;
    initialFilterClauses?: InitialFilterClause[] | undefined;
  }>();

  const filterPropsMutator = useCallback(
    (arg: T): T => {
      const { filterBuilderProps, filterableProperties, changeFilter, initialFilter: argInitialFilter } = arg;
      if (!providerFilter) {
        return arg;
      }
      if (!initialFilterPropsRef.current) {
        // initialize the ref to the initial filter and clauses
        initialFilterPropsRef.current = {
          initialFilter: {
            ...argInitialFilter,
            ...providerFilter.state,
          },
          initialFilterClauses: filterBuilderProps?.initialFilterClauses?.filter(
            clause => !providerFilter.keys.includes(clause.key as keyof ContextBlotterFilterState)
          ),
        };
      }

      return {
        ...arg,
        initialFilter: initialFilterPropsRef.current.initialFilter,
        filterBuilderProps: filterBuilderProps
          ? {
              ...filterBuilderProps,
              initialFilterClauses: initialFilterPropsRef.current.initialFilterClauses,
              properties:
                arg.filterBuilderProps?.properties?.filter(
                  ({ key }) => !providerFilter.keys.includes(key as keyof ContextBlotterFilterState)
                ) ?? [],
            }
          : undefined,
        filterableProperties: filterableProperties
          ? filterableProperties.filter(
              ({ key }) => !providerFilter.keys.includes(key as keyof ContextBlotterFilterState)
            )
          : undefined,
        changeFilter: changeFilter
          ? action => {
              changeFilter(filter => {
                const newFilter = action instanceof Function ? action(filter) : action;
                return {
                  ...newFilter,
                  SubAccounts: filter.SubAccounts ?? newFilter.SubAccounts,
                  MarketAccounts: filter.MarketAccounts ?? newFilter.MarketAccounts,
                };
              });
            }
          : undefined,
      } satisfies ContextBlotterFilterMergeArg;
    },
    [providerFilter]
  );

  if (!providerFilter) {
    return {
      additionalFilterState: undefined,
      filterPropsMutator: props => props,
    };
  }

  return {
    additionalFilterState: providerFilter?.state,
    filterPropsMutator,
  };
}
