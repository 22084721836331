import type { MarketAccount } from '@talos/kyoko';
import { useMemo } from 'react';
import { useCustomersByName } from '../../../hooks/useCustomer';

export const useSortedCustomerMarketAccountsList = (customerMarketAccountsList: MarketAccount[] | undefined) => {
  const customersByName = useCustomersByName();
  const sortedCustomerMarketAccountsList = useMemo(() => {
    if (!customerMarketAccountsList || !customersByName) {
      return undefined;
    }

    return customerMarketAccountsList.sort((a, b) => {
      // Sort by Counterparty, Secondary sort by source account id
      if (a.Counterparty === b.Counterparty && a.SourceAccountID && b.SourceAccountID) {
        return a.SourceAccountID.localeCompare(b.SourceAccountID);
      } else {
        // Prioritize Customer Display Name over Counterparty Name
        const cNameA = customersByName.get(a.Counterparty)?.DisplayName || a.Counterparty || '';
        const cNameB = customersByName.get(b.Counterparty)?.DisplayName || b.Counterparty || '';
        return cNameA.localeCompare(cNameB);
      }
    });
  }, [customerMarketAccountsList, customersByName]);

  return sortedCustomerMarketAccountsList;
};
