import { useDateNow } from '@talos/kyoko';
import { addDays } from 'date-fns';
import { useMemo } from 'react';

// Super simple hook to just put the date time picker maxValue logic in one place for the date selection in treasury mgmt
export const useMaxDateValue = () => {
  // Get current date without getting a new date on every single render
  const dateNow = useDateNow({ updateIntervalMs: 60000 });
  // DateTimePicker maxValue is exlusive, so add one day to now. It also only works on days, not time, so just get tomorrow like this
  const dateTomorrow = useMemo(() => addDays(dateNow, 1), [dateNow]);
  return dateTomorrow;
};
