import { memo, useEffect, useState } from 'react';

import {
  Box,
  Flex,
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  useCurrencyConversionRateValue,
  useMarketAccountsContext,
} from '@talos/kyoko';

import { useSubAccounts } from 'providers';
import { Link } from '../styles';

export const OrderImportHelp = memo(() => {
  const { Rate: btcusdRate } = useCurrencyConversionRateValue('BTC', 'USD') ?? {};
  const { marketAccountsByName } = useMarketAccountsContext();
  const { tradableSubAccounts } = useSubAccounts();

  const [limitExampleCSV, setLimitExampleCSV] = useState<string>('');

  useEffect(() => {
    // Get set of Active Trading MarketAccounts but with only one from each Market
    const markets: string[] = [];
    const marketAccounts: string[] = [];

    for (const [name, ma] of marketAccountsByName) {
      if (
        ma.Type === MarketAccountTypeEnum.Trading &&
        ma.Status === MarketAccountStatusEnum.Active &&
        SAMPLE_EXCHANGES.includes(ma.Market) &&
        !markets.includes(ma.Market)
      ) {
        markets.push(ma.Market);
        marketAccounts.push(name);
      }
    }

    // Include Sub accounts if they exist in the system
    const subAccount = tradableSubAccounts && tradableSubAccounts.length > 0 ? tradableSubAccounts[0].Name : '';

    const startTime = new Date(Date.now() + 1 * 3600 * 1000 /*1 hr in ms*/).toISOString();

    const rawOrder = ['BTC-USD', marketAccounts.join(';'), 'Buy', '0.2', 'BTC', 'Limit', btcusdRate, startTime];

    if (subAccount !== '') {
      rawOrder.push(subAccount);
    }

    // Only include the subaccount header if they are used in the system
    setLimitExampleCSV(
      encodeURI(
        `${CSV_START_TEXT}${REQUIRED_KEYS.join(',')},Price,StartTime${
          subAccount === '' ? '' : ',Subaccount'
        }\n${rawOrder.join(',')}\n`
      )
    );
  }, [btcusdRate, marketAccountsByName, tradableSubAccounts]);

  const blankExampleCSV = encodeURI(`${CSV_START_TEXT}${REQUIRED_KEYS.join(',')}\n`);

  const marketExampleCSV = encodeURI(`${CSV_START_TEXT}${REQUIRED_KEYS.join(',')}\n${SAMPLE_MARKET.join(',')}\n`);

  return (
    <Box ml="spacingLarge" mr="spacingLarge" justifyContent="center">
      <Flex flexDirection="column" justifyContent="center" flex={1}>
        <h3>How to import orders from a csv file</h3>
        <p>The first row of the file is a header with required keys</p>
        <Table w="100%">
          <Tbody>
            <Tr>
              <Th>Key</Th>
              <Th>Value</Th>
            </Tr>
            {REQUIRED_KEYS.map(key => (
              <Tr key={key}>
                <Td>{key}</Td>
                <Td>{REQUIRED_KEYS_INFO[key]}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <p>
          Depending on the strategy, additional columns are used to specify required parameters, refer to{' '}
          <Link href="https://docs.talostrading.com/#order-strategy" target="_blank">
            https://docs.talostrading.com/#order-strategy
          </Link>{' '}
          for these parameters.
        </p>
        <h4>Sample Files:</h4>
        <Link href={blankExampleCSV} download="talos_import_blank_example.csv" data-testid="order-import-blank-example">
          blank-example
        </Link>
        <Link
          href={marketExampleCSV}
          download="talos_import_market_example.csv"
          data-testid="order-import-market-example"
        >
          market-order-example
        </Link>
        <Link href={limitExampleCSV} download="talos_import_limit_example.csv" data-testid="order-import-limit-example">
          limit-order-example
        </Link>
      </Flex>
    </Box>
  );
});

const CSV_START_TEXT = 'data:text/csv;charset=utf-8,';

const REQUIRED_KEYS_INFO = {
  Symbol: (
    <>
      Talos symbol e.g. BTC-USD or bybit:BTC-PERP, refer to{' '}
      <Link
        href="https://docs.google.com/spreadsheets/d/1zDgZ9PFUTtkEeY8AAeh-_0YkCWnkPNqNp097D2Fbjvw/edit#gid=113374571"
        target="_blank"
      >
        link
      </Link>{' '}
      for supported symbols
    </>
  ),
  Market: 'Market or market account names',
  Side: 'Side, either Buy or Sell',
  OrderQty: 'Order quantity in units of Currency',
  Currency: 'The currency that the order quantity is specified in',
  Strategy: 'Strategy for the order',
};

export const REQUIRED_KEYS = Object.keys(REQUIRED_KEYS_INFO);

const SAMPLE_MARKET = ['BTC-USD', 'coinbase', 'Buy', '1000', 'USD', 'Market'];

// TODO get rid of this once it is easer to get all the MarketAccounts that are valid and active from Security.Markets
const SAMPLE_EXCHANGES = ['coinbase', 'kraken', 'b2c2', 'galaxy'];
