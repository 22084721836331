import type { MarketDataStatistics } from '@talos/kyoko';
import { formatDistanceStrict } from 'date-fns';
import { rateify } from './rateify';

export function timedRate(
  stats: MarketDataStatistics,
  rateKey: keyof MarketDataStatistics,
  timeKey: keyof MarketDataStatistics
) {
  const value = rateify(stats[rateKey]!);
  const unit = `in ${formatDistanceStrict(Sugar.Date.create(), Sugar.Date.create(stats[timeKey]))}`;
  return (
    <>
      {value} {unit}
    </>
  );
}
