import Big from 'big.js';
import { useEffect, useState } from 'react';
import { array, date, number, object, string, type AnySchema, type NumberSchema, type StringSchema } from 'yup';

import {
  AllocationValueTypeEnum,
  DecisionStatusEnum,
  InstrumentCompositionEnum,
  OrdTypeEnum,
  OrderFormSides,
  ParameterTypeEnum,
  PresenceEnum,
  PriceProtectionEnum,
  ProductTypeEnum,
  ReduceFirstEnum,
  ReduceOnlyEnum,
  UnifiedLiquidityEnum,
  allowContracts,
  calcDateFromDuration,
  canParseAsDuration,
  emptyDuration,
  format,
  getMinIncrementForContract,
  isDateInThePast,
  isDuration,
  isOption,
  parseDate,
  prettyName,
  runValidation,
  toBigWithDefault,
  useCurrency,
  useSecurity,
  validatePositiveValues,
  validatePrecision,
  validateSubAccountAllocations,
  type Allocation,
  type BuyingPower,
  type IStrategyParameterEnum,
  type MarketAccount,
  type OrderStrategy,
  type RequiredProperties,
  type SubAccount,
} from '@talos/kyoko';

import { isAfter } from 'date-fns';
import { camelCase, isEqual, isNil, keys, map, uniq } from 'lodash';
import { useTradingSettings } from 'providers/AppConfigProvider';
import type { OMSForm } from 'providers/OMSContext.types';
import { ORDER_SIDES } from 'tokens/order';
import { ParameterKeysEnum } from 'tokens/orderStrategy';
import { ensureFloat } from 'utils/order';
import type { AnyObject } from 'yup/lib/types';
import { useOrderStartTime } from './useOrderStartTime';

// https://github.com/jquense/yup/issues/298#issuecomment-559017330
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export interface OrderValidationProps {
  order: OMSForm;
  subAccounts?: RequiredProperties<Partial<SubAccount>, 'Name' | 'SubaccountID'>[];
  strategies?: OrderStrategy[];
  subAccountAllocations: Allocation[];
  allocationValueType: string;
  buyingPower?: Map<string, BuyingPower>;
  validateBuyingPower: boolean;
  /** Whether or not to validate the Min Size. Defaults to true. */
  validateMinSize?: boolean;
  isModifying: boolean;
}

interface ErrorsSchema {
  quantity: NumberSchema;
  strategy?: StringSchema;
  price?: NumberSchema;
  orderMarketAccounts?: AnySchema;
  orderSide?: StringSchema;
  orderCurrency?: StringSchema;
  subAccountAllocations?: AnySchema;
  parameters?: AnySchema;
}

interface WarningsSchema {
  quantity?: NumberSchema;
}

export const PercentIncrementPrecision = 1;
export const TargetParticipationRatePrecision = '0.01';

export const useOrderValidation = ({
  order,
  subAccounts,
  strategies,
  subAccountAllocations,
  allocationValueType,
  buyingPower,
  validateBuyingPower,
  validateMinSize = true,
  isModifying,
}: OrderValidationProps) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [warnings, setWarnings] = useState<{ [key: string]: string }>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const security = useSecurity(order.symbol);

  const orderCurrencyInfo = useCurrency(order?.orderCurrency);
  const { useTradeAllocations } = useTradingSettings();
  const orderStartTime = useOrderStartTime(order);

  useEffect(() => {
    const errorsSchema: ErrorsSchema = {
      quantity: number().typeError('Please enter a Quantity').required('Please enter a Quantity'),
    };

    const isStagedOrder = order.initialDecisionStatus === DecisionStatusEnum.Staged;
    const shouldValidatePrice =
      order.ordType !== OrdTypeEnum.Market && ((isStagedOrder && order.strategy) || !isStagedOrder);

    const warningsSchema: WarningsSchema = {};
    if (!isStagedOrder) {
      errorsSchema.strategy = string().required('Please select a strategy');
    }

    if (shouldValidatePrice) {
      errorsSchema.price = number().typeError('Please enter a Limit Price').required('Please enter a Limit Price');
      if (security?.Composition !== InstrumentCompositionEnum.Synthetic) {
        errorsSchema.price = errorsSchema.price.min(0, `Limit Price must be greater than 0`);
      }
    }
    errorsSchema.orderMarketAccounts = array<MarketAccount>()
      .typeError('Please select at least 1 market')
      .required('Please select at least 1 market')
      .min(1, 'Please select at least 1 market');
    errorsSchema.orderSide = string().oneOf([ORDER_SIDES.BUY, ORDER_SIDES.SELL], 'Please select an order side');

    applySubAccountAllocationsSchema({
      errorsSchema,
      touched,
      isModifying,
      allocationValueType,
      useTradeAllocations,
      subAccounts,
      quantity: order.quantity,
    });

    if (security != null) {
      const { MinimumSize, MinSizeIncrement, MinPriceIncrement, QuoteCurrency } = security;

      const { MinIncrement } = orderCurrencyInfo || {};

      let minSize = MinimumSize;
      let minIncr = MinSizeIncrement;
      if (!isOption(security) && order.orderCurrency === QuoteCurrency && MinIncrement) {
        minSize = MinIncrement;
        minIncr = MinIncrement;
      }
      const allowQtyInContracts = allowContracts(security);

      if (order.orderCurrency) {
        const acceptedCurrencies: string[] = uniq([security.BaseCurrency, security.QuoteCurrency]);
        errorsSchema.orderCurrency = string().oneOf(
          acceptedCurrencies,
          `Currency must be one of ${acceptedCurrencies.join(' or ')}`
        );
      }

      // https://talostrading.atlassian.net/browse/UI-4686
      // We need to disable min size validation in the frontend and rely on the backend temporarily
      if (validateMinSize) {
        if (allowQtyInContracts && order?.orderCurrency) {
          // sc-77242
          const minIncrForContract = getMinIncrementForContract(security, order.orderCurrency);
          if (minIncrForContract) {
            errorsSchema.quantity = errorsSchema.quantity
              .min(parseFloat(minIncrForContract), `Min quantity is ${minIncrForContract}`)
              .test(
                'precision',
                `Qty must be a multiple of ${minIncrForContract}`,
                q => toBigWithDefault(q, 1).lt(minIncrForContract) || validatePrecision(minIncrForContract, q)
              );
          } else {
            minSize = orderCurrencyInfo?.MinIncrement ?? '0.01';
            minIncr = orderCurrencyInfo?.MinIncrement ?? '0.01';
            errorsSchema.quantity = errorsSchema.quantity
              .min(parseFloat(minSize), `Min quantity is ${minSize}`)
              .test('precision', `Min increment is ${minIncr}`, q => validatePrecision(minIncr, q));
          }
        } else {
          errorsSchema.quantity = errorsSchema.quantity
            .min(parseFloat(minSize), `Min quantity is ${minSize}`)
            .test('precision', `Min increment is ${minIncr}`, q => validatePrecision(minIncr, q));
        }
      }

      if (shouldValidatePrice) {
        errorsSchema.price = (errorsSchema.price as NumberSchema).test(
          'precision',
          `Min price increment is ${MinPriceIncrement}`,
          p => validatePrecision(MinPriceIncrement, p)
        );
      }

      if (strategies != null && order.strategy) {
        const selectedStrategy = strategies.find(s => s.Name === (order.strategy as string));
        const parametersSchema = (selectedStrategy?.Parameters ?? []).reduce((result, param) => {
          let paramSchema: AnySchema;
          // Don't validate `startTime` while modifying an order.
          // Order only has a `startTime` if it has started.
          const key = camelCase(param.Name);
          if (isModifying && param.Name === ParameterKeysEnum.StartTime && isDateInThePast(orderStartTime)) {
            return result;
          }

          // Don't validate hidden parameters.
          if (param.Presence === PresenceEnum.Hidden) {
            return result;
          }

          // Pick param errorsSchema
          switch (param.Type) {
            case ParameterTypeEnum.Date:
              if (param.Name === ParameterKeysEnum.StartTime) {
                paramSchema = date()
                  .transform((currentValue, originalValue, context) => {
                    if (context.isType(currentValue)) {
                      return currentValue;
                    }
                    if (isDuration(originalValue)) {
                      if (isEqual(originalValue, emptyDuration)) {
                        return undefined;
                      }
                      currentValue = calcDateFromDuration(originalValue, parseDate());
                      return currentValue;
                    }
                    return undefined;
                  })
                  .typeError(`Date is invalid`)
                  .test(
                    'not-in-past',
                    `Date cannot be in the past`,
                    value => isNil(value) || isAfter(value, parseDate())
                  );
              } else if (param.Name === ParameterKeysEnum.EndTime) {
                paramSchema = string();
                if (canParseAsDuration(order.parameters.endTime as string)) {
                  paramSchema = string();
                } else {
                  paramSchema = date()
                    .transform((currentValue, originalValue, context) => {
                      if (context.isType(currentValue)) {
                        return currentValue;
                      }
                      if (isDuration(originalValue)) {
                        if (isEqual(originalValue, emptyDuration)) {
                          return undefined;
                        }
                        currentValue = calcDateFromDuration(originalValue, orderStartTime);
                        return currentValue;
                      }
                      return undefined;
                    })
                    .typeError(`Date is invalid`)
                    .min(
                      orderStartTime,
                      `${prettyName(param.Name)} must be after ${prettyName(ParameterKeysEnum.StartTime)}`
                    )
                    .test(
                      'not-in-past',
                      `Date cannot be in the past`,
                      value => isNil(value) || isAfter(value, parseDate())
                    );
                }
              } else {
                paramSchema = date()
                  .typeError(`Date is invalid`)
                  .test(
                    'not-in-past',
                    `Date cannot be in the past`,
                    value => isNil(value) || isAfter(value, parseDate())
                  );
              }
              break;
            case ParameterTypeEnum.Duration:
            case ParameterTypeEnum.Interval:
              paramSchema = string();
              break;
            case ParameterTypeEnum.Aggregation:
              paramSchema = string();
              break;
            case ParameterTypeEnum.String:
              paramSchema = string();
              break;
            case ParameterTypeEnum.Enum:
              paramSchema = number().oneOf(
                map(param.EnumValues as IStrategyParameterEnum[], 'Index'),
                `${prettyName(param.Name)} must be one of the values in the list`
              );
              break;
            case ParameterTypeEnum.PriceProtection: {
              // Don't expect or validate Price Protection for non spot
              if (security.ProductType !== ProductTypeEnum.Spot) {
                return result;
              }
              paramSchema = string().oneOf(
                keys(PriceProtectionEnum),
                `${prettyName(param.Name)} must be one of ${keys(PriceProtectionEnum).join(' or ')}`
              );
              break;
            }
            case ParameterTypeEnum.UnifiedLiquidity: {
              paramSchema = string().oneOf(
                keys(UnifiedLiquidityEnum),
                `${prettyName(param.Name)} must be one of ${keys(UnifiedLiquidityEnum).join(' or ')}`
              );
              break;
            }
            case ParameterTypeEnum.ReduceOnly: {
              paramSchema = string().oneOf(
                keys(ReduceOnlyEnum),
                `${prettyName(param.Name)} must be one of ${keys(ReduceOnlyEnum).join(' or ')}`
              );
              break;
            }
            case ParameterTypeEnum.ReduceFirst: {
              paramSchema = string().oneOf(
                keys(ReduceFirstEnum),
                `${prettyName(param.Name)} must be one of ${keys(ReduceFirstEnum).join(' or ')}`
              );
              break;
            }

            default:
              paramSchema = number()
                .typeError(`${prettyName(param.Name)} is invalid`)
                .min(0, `${prettyName(param.Name)} must be greater than 0`);
              break;
          }

          // If not required, allow empty string as submission
          if (param.Presence === PresenceEnum.Required) {
            paramSchema = paramSchema.required(`${prettyName(param.Name)} is required`);
          } else {
            paramSchema = paramSchema.transform(emptyStringToNull).nullable();
          }

          // Special case MaxImbalanceAmt Parameter which will always be in USD and not in units of the order
          if (param.Type === ParameterTypeEnum.Qty && param.Name !== 'MaxImbalanceAmt') {
            paramSchema = (paramSchema as NumberSchema).max(
              order.quantity as unknown as number,
              `${prettyName(param.Name)} is greater than quantity`
            );
            if (validateMinSize) {
              paramSchema = (paramSchema as NumberSchema).min(
                minSize as unknown as number,
                `Min ${prettyName(param.Name)} is ${minSize}`
              );
            }
          }
          if (param.Type === ParameterTypeEnum.Percent) {
            paramSchema = (paramSchema as NumberSchema)
              .max(100, `Invalid percentage value`)
              .min(0, `Invalid percentage value`)
              .test('precision', `Min increment is ${PercentIncrementPrecision}`, q => {
                return q == null || validatePrecision(PercentIncrementPrecision, q);
              });
          }
          if (param.Type === ParameterTypeEnum.TargetParticipationRate) {
            paramSchema = (paramSchema as NumberSchema)
              .max(50, `Value must be at most 50%`)
              .min(0.5, `Value must be at least 0.5%`)
              .test('precision', `Min increment is ${TargetParticipationRatePrecision}`, q => {
                return q == null || validatePrecision(TargetParticipationRatePrecision, q);
              });
          }

          result[key] = paramSchema;
          return result;
        }, {});
        errorsSchema.parameters = object().shape(parametersSchema);
      }

      // Buying Power Max Available Check
      // If Modifying, don't check for Buying Power
      if (
        validateBuyingPower &&
        buyingPower != null &&
        !isModifying &&
        order.quantity &&
        order.orderMarketAccounts &&
        order.orderMarketAccounts.length > 0
      ) {
        let maxAvailableAmount = Big(0);
        let hasMissingBuyingPower = false;
        order.orderMarketAccounts.forEach(marketAccount => {
          const bp = buyingPower?.get(marketAccount);
          const side = order.orderSide ? OrderFormSides[order.orderSide] : undefined;
          if (bp?.getMaxSize({ side }) == null) {
            hasMissingBuyingPower = true;
            return;
          }
          maxAvailableAmount = maxAvailableAmount.plus(bp.getMaxSize({ side }) || '0');
        });

        // If any buying power entry is missing, don't display an error.
        if (!hasMissingBuyingPower && maxAvailableAmount.lt(order.quantity || '0')) {
          // RFQ Qty Exceeds Max Available Buying Power
          warningsSchema.quantity = number().max(
            parseFloat(maxAvailableAmount.toFixed()),
            `Exceeds max available by ${format(
              Big(order.quantity || '0')
                .minus(maxAvailableAmount)
                .toFixed(2)
            )} ${order.orderCurrency}`
          );
        }
      }
    }

    // Run Errors validation
    setErrors(prev => {
      const next = runValidation(object().shape(errorsSchema as AnyObject), order);
      if (isEqual(prev, next)) {
        return prev;
      }
      return next;
    });

    // Run Warnings validation
    setWarnings(prev => {
      const next = runValidation(object().shape(warningsSchema as AnyObject), order);
      if (isEqual(prev, next)) {
        return prev;
      }
      return next;
    });
  }, [
    order,
    security,
    subAccounts,
    strategies,
    subAccountAllocations,
    allocationValueType,
    orderCurrencyInfo,
    buyingPower,
    isModifying,
    validateBuyingPower,
    useTradeAllocations,
    touched,
    orderStartTime,
    validateMinSize,
  ]);

  return { errors, warnings, touched, setTouched };
};

export interface SubAccountAllocationsValidationParams {
  errorsSchema: { subAccountAllocations?: AnySchema };
  touched: { [key: string]: boolean };
  isModifying: boolean;
  allocationValueType: string;
  useTradeAllocations: boolean;
  subAccounts: OrderValidationProps['subAccounts'];
  quantity?: string;
}

export function applySubAccountAllocationsSchema({
  errorsSchema,
  touched,
  isModifying,
  allocationValueType,
  useTradeAllocations,
  subAccounts,
  quantity,
}: SubAccountAllocationsValidationParams) {
  let subAccountAllocationsSchema = array<Allocation>(
    object({
      subAccount: string()[!isModifying ? 'required' : 'notRequired']('Please select a Sub Account'),
      value: string().default(''),
    }).typeError('Please select a Sub Account')
  )
    .typeError('Please select a Sub Account')
    .test('non-zero and non-negative', `Percentages must be greater than 0`, arr => {
      return arr == null ? false : validatePositiveValues(arr.map(item => item.value));
    });

  subAccountAllocationsSchema = subAccountAllocationsSchema.min(1, 'Please select a Sub Account');

  subAccountAllocationsSchema = subAccountAllocationsSchema.test(
    'allocations',
    `${allocationValueType === AllocationValueTypeEnum.Percentage ? 'Percentage' : 'Allocation'} Total Must Equal ${
      allocationValueType === AllocationValueTypeEnum.Percentage ? '100' : 'Quantity'
    }`,
    arr => {
      if (!useTradeAllocations) {
        return true;
      } else if (arr == null) {
        return false;
      } else if (arr.length > 0) {
        return validateSubAccountAllocations(
          arr as Allocation[],
          allocationValueType === AllocationValueTypeEnum.Percentage ? 100 : ensureFloat(quantity || 0)
        );
      }
      return true;
    }
  );

  // When there are no SubAccounts in the system, we don't need the subAccountAllocationsSchema,
  // regardless of whether sub account selection is required or not.
  if (subAccounts?.length && (touched['subAccountAllocations'] || !isModifying)) {
    errorsSchema.subAccountAllocations = subAccountAllocationsSchema;
  }
}
