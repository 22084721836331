import {
  BlotterTableExtrasMenu,
  type MinimalSubscriptionResponse,
  Text,
  type UseBlotterTable,
  formattedTime,
  useBlotterTableExtrasMenu,
  useObservableValue,
} from '@talos/kyoko';
import { type Observable, map } from 'rxjs';
import type { PortfolioRiskDataItem } from '../types/PortfolioRiskDataItem';
import { RiskBlotterExportMenuItems } from './RiskBlotterExportMenuItems';

export const RiskBlotterSuffixMenu = ({
  riskObs,
  exportDataAsCSV,
  exportDataAsExcel,
  label,
}: {
  riskObs: Observable<MinimalSubscriptionResponse<PortfolioRiskDataItem>>;
  exportDataAsCSV: UseBlotterTable<unknown>['exportDataAsCSV'];
  exportDataAsExcel: UseBlotterTable<unknown>['exportDataAsExcel'];
  label: string;
}) => {
  const lastUpdateTime = useObservableValue(
    () =>
      riskObs.pipe(
        map(item => {
          return item.data?.at(-1)?.gridData.BatchTime;
        })
      ),
    [riskObs]
  );

  const extrasMenuPopover = useBlotterTableExtrasMenu();
  const timestampToShow = lastUpdateTime ? formattedTime(new Date(lastUpdateTime)) : '';

  return (
    <BlotterTableExtrasMenu {...extrasMenuPopover}>
      <Text>{`Last updated: ${timestampToShow}`}</Text>
      <RiskBlotterExportMenuItems
        exportDataAsCSV={exportDataAsCSV}
        exportDataAsExcel={exportDataAsExcel}
        label={label}
        handleClose={extrasMenuPopover.close}
      />
    </BlotterTableExtrasMenu>
  );
};
