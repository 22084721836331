import { useDrawer, type MarketCredential } from '@talos/kyoko';
import { useCallback, useState } from 'react';

interface useCredentialDrawerProps {
  marketCredentials: MarketCredential[];
}

export const useEditCredentialDrawerWrapper = ({ marketCredentials }: useCredentialDrawerProps) => {
  const [selectedCredential, setSelectedCredential] = useState<MarketCredential | undefined>();

  const credentialDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
  });

  const handleOpenCredential = useCallback(
    (credentialID: number) => {
      setSelectedCredential(marketCredentials.find(marketCredential => marketCredential.CredentialID === credentialID));
      credentialDrawer.open();
    },
    [marketCredentials, credentialDrawer]
  );

  return {
    selectedCredential,
    credentialDrawer,
    handleOpenCredential,
  };
};
