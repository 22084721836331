import { CareOrder, type ExecutionReport, type Order, type Quote, type RequestStream } from '@talos/kyoko';
import Big from 'big.js';
import { buildCreateAppStateApi } from './buildCreateAppStateApi';
import { noopBaseQuery } from './noopBaseQuery';
import type { AppStateListenerStart } from './types';
import { buildWsEndpointBuilder } from './wsEndpointBuilder';

export interface BaseWsQuery extends Omit<RequestStream, 'name'> {}

export const streamingDataSlice = buildCreateAppStateApi()({
  reducerPath: 'streamingData',
  baseQuery: noopBaseQuery,
  keepUnusedDataFor: 30,
  endpoints: builder => {
    const wsBuilder = buildWsEndpointBuilder<typeof builder>(builder);
    return {
      getOrders: wsBuilder.query<Map<string, Order>, BaseWsQuery>({
        buildRequest: ({ tag = 'streamingDataSlice', ...args }) => ({ name: 'Order', tag, ...args }),
        initializeCache: (data: Order[]) => data.reduce((map, order) => map.set(order.OrderID, order), new Map()),
        updateCache: (draft, data: Order[]) =>
          new Map(data.reduce((map, order) => map.set(order.OrderID, order), draft)),
      }),
      getExecutionReports: wsBuilder.query<Map<string, ExecutionReport>, BaseWsQuery>({
        buildRequest: ({ tag = 'streamingDataSlice', ...args }) => ({ name: 'ExecutionReport', tag, ...args }),
        initializeCache: (data: ExecutionReport[]) =>
          data.reduce((map, report) => map.set(report.ExecID, report), new Map()),
        updateCache: (draft, data: ExecutionReport[]) =>
          new Map(data.reduce((map, report) => map.set(report.ExecID, report), draft)),
      }),
      getQuotes: wsBuilder.query<Map<string, Quote>, BaseWsQuery>({
        buildRequest: ({ tag = 'streamingDataSlice', ...args }) => ({ name: 'Quote', tag, ...args }),
        initializeCache: (data: Quote[]) => data.reduce((map, quote) => map.set(quote.RFQID, quote), new Map()),
        updateCache: (draft, data: Quote[]) => new Map(data.reduce((map, quote) => map.set(quote.RFQID, quote), draft)),
      }),
      getCareOrders: wsBuilder.query<Map<string, CareOrder>, BaseWsQuery>({
        buildRequest: ({ tag = 'streamingDataSlice', ...args }) => ({ name: 'CareOrder', tag, ...args }),
        initializeCache: (data: CareOrder[]) => data.reduce((map, order) => map.set(order.OrderID, order), new Map()),
        updateCache: (draft, data: CareOrder[]) =>
          new Map(data.reduce((map, order) => map.set(order.OrderID, order), draft)),
      }),
    };
  },
});

export function setupListeners(startListening: AppStateListenerStart) {
  /**
   * NOTE: TEMPORARY, WILL BE REMOVED IN A FUTURE PR
   *
   * Compute the "working quantity" for the care order to be used in validation.
   * The working quantity is the sum of the OrderQty of all child quotes that are not terminal.
   *
   * Soon(tm) the available Care Order quantity will be provided as a field on the Care Order itself,
   * meaning we no longer need all of this crazy logic.
   */

  const unsubscribeCursedWorkingQtyListener = startListening({
    // When anything subscribes to `CareOrder`...
    matcher: streamingDataSlice.endpoints.getCareOrders.matchPending,
    effect: async (action, listener) => {
      // ...and it does so specifying an `OrderID`...
      const careOrderQueryArg = action.meta.arg.originalArgs;
      const orderID = careOrderQueryArg.OrderID as string | undefined;
      if (orderID == null) {
        return;
      }

      // ...then also subscribe to `Quotes`...
      const quotesQueryArg = { ParentRFQID: orderID, tag: 'streamingDataSlice/WorkingQty' };

      // Here's where the magic happens!
      // Now that we have both WS subs, we can create _another_ listener
      // to compute the `WorkingQty` when either the CareOrder or the Quotes update.
      const selectCareOrders = streamingDataSlice.endpoints.getCareOrders.select(action.meta.arg.originalArgs);
      const selectQuotes = streamingDataSlice.endpoints.getQuotes.select(quotesQueryArg);

      const unsubscribeUpdatesListener = startListening({
        predicate(_, next, prev) {
          const didCareOrdersUpdate = selectCareOrders(next).data !== selectCareOrders(prev).data;
          const didQuotesUpdate = selectQuotes(next).data !== selectQuotes(prev).data;
          return didCareOrdersUpdate || didQuotesUpdate;
        },
        effect(_, listener) {
          const state = listener.getState();
          const careOrders = selectCareOrders(state);
          const quotes = selectQuotes(state);
          if (careOrders.isLoading || careOrders.data == null || quotes.isLoading || quotes.data == null) {
            return;
          }
          // Now, obviously there should only be one care order in this map
          // since the WS sub included an `OrderID`
          const careOrder = careOrders.data.get(orderID);
          if (careOrder == null) {
            return;
          }

          // Temporarily unsub so the dispatch below (which runs synchronously)
          // doesn't re-trigger this effect
          listener.unsubscribe();

          // Compute the working quantity...
          let workingQty = Big(0);
          for (const quote of quotes.data.values()) {
            if (!quote.isTerminal && quote.OrderQty != null) {
              workingQty = workingQty.plus(quote.OrderQty);
            }
          }

          // ...and finally update the cached data!
          listener.dispatch(
            streamingDataSlice.util.updateQueryData('getCareOrders', careOrderQueryArg, draft => {
              const existing = draft.get(orderID);
              if (existing == null) {
                return;
              }
              const next = new CareOrder(existing as CareOrder);
              next.WorkingQty = workingQty.toFixed();
              draft.set(orderID, next);
            })
          );

          // Re-subscribe so we capture WS updates again
          listener.subscribe();
        },
      });

      // Now that the listener is registered, we can subscribe to quotes
      const quotes = listener.dispatch(streamingDataSlice.endpoints.getQuotes.initiate(quotesQueryArg));

      // Run cleanup of all the above when this specific care orders subscription is closed.
      const queryCacheKey = action.meta.arg.queryCacheKey;
      await listener.condition(() => {
        const hasSubscriber = listener.getState().streamingData.queries[queryCacheKey] != null;
        return !hasSubscriber;
      });
      quotes.unsubscribe();
      unsubscribeUpdatesListener();
    },
  });

  return () => {
    unsubscribeCursedWorkingQtyListener();
  };
}

export const { useGetCareOrdersQuery, useGetOrdersQuery, useGetExecutionReportsQuery, useGetQuotesQuery } =
  streamingDataSlice;
