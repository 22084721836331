import {
  Box,
  Checkbox,
  FormControlSizes,
  FormGroup,
  HStack,
  Input,
  OnboardingFormField,
  Text,
  type CredentialTemplate,
  type CredentialTemplateFields,
  type MarketCredential,
  type ReplaceTokens,
} from '@talos/kyoko';
import type { AddCredentialFormArg, AddEditCredentialForm } from '../types';

type CredentialsFormProps = {
  credentialTemplate?: CredentialTemplate;
  selectedCredential?: MarketCredential;
  externalIP: string;
  updateForm: (args: AddCredentialFormArg) => void;
  form: AddEditCredentialForm;
  initialFormValues?: AddEditCredentialForm;
  errors: AddEditCredentialForm;
  tokensToReplace?: ReplaceTokens;
};

export function CredentialsForm({
  credentialTemplate,
  selectedCredential,
  externalIP,
  updateForm,
  form,
  initialFormValues,
  errors,
  tokensToReplace,
}: CredentialsFormProps) {
  return (
    <>
      <FormGroup error={errors?.label} controlId="label" label="Name">
        <Input
          data-testid="label"
          autoFocus
          autoComplete="off"
          id="label"
          name="label"
          type="text"
          value={(form?.label as string) ?? ''}
          invalid={!!errors?.label}
          onChange={e => {
            updateForm({ key: 'label', value: e.target.value });
          }}
        />
      </FormGroup>
      {credentialTemplate &&
        credentialTemplate.fields.map((field, index) => (
          <OnboardingFormField
            size={FormControlSizes.Default}
            key={index}
            type={getFieldType(field)}
            value={form?.[field.name]}
            placeholder={getPlaceholder(field, form, initialFormValues, selectedCredential != null)}
            setValue={val => updateForm({ key: field.name, value: val })}
            error={errors?.[field.name]}
            tokensToReplace={tokensToReplace}
            {...field}
          />
        ))}
      <FormGroup controlId="externalIP" label="System IP-address">
        <Input
          data-testid="external-ip"
          autoComplete="off"
          name="externalIP"
          id="externalIP"
          type="text"
          readOnly={true}
          value={externalIP}
        />
      </FormGroup>
      {form.isDisclaimerRequired && (
        <FormGroup
          error={errors?.isDisclaimerRequired && !form.isDisclaimerChecked ? 'This field is required' : null}
          controlId="disclaimer"
          label=""
        >
          <Box p="spacingSmall">
            <Text size="fontSizeSmall">
              Pursuant to the terms of your User Agreement with Coinbase Bermuda, you have agreed to share your login IP
              address and any relevant details that Coinbase Bermuda deems necessary in order to determine your
              eligibility for the Coinbase International Exchange.
              <br />
              <br />
              Talos is required to provide this information to Coinbase Bermuda as a condition to granting your access
              to the Coinbase International Exchange. Coinbase Bermuda will handle this information in accordance with
              your User Agreement.
              <br />
              <br />
              By checking the box below you hereby consent to Talos sharing your login IP address and any relevant
              details that Coinbase Bermuda deems necessary in order to determine your eligibility for the Coinbase
              International Exchange
            </Text>
          </Box>
          <HStack w="100%" p="spacingSmall" justifyContent="space-between" alignItems="center">
            <Text fontWeight="bold">I hereby agree to the above terms.</Text>
            <Checkbox
              name="disclaimer"
              id="disclaimer"
              checked={form.isDisclaimerChecked}
              onChange={e => updateForm({ key: 'isDisclaimerChecked', value: e.target.checked })}
            />
          </HStack>
        </FormGroup>
      )}
    </>
  );
}

function getFieldType(
  field: CredentialTemplateFields
): 'file' | 'textarea' | 'password' | 'checkbox' | 'select' | undefined {
  if (field.cleartext) {
    return undefined;
  } else if (!field.type) {
    return 'password';
  }
  return field.type;
}

function getPlaceholder(
  field: CredentialTemplateFields,
  form: AddEditCredentialForm,
  initialFormValues: AddEditCredentialForm | undefined,
  isEditing: boolean
): string {
  // if field is not required and value is empty we no longer need to show placeholder to let user know that this field will be cleared
  if (!field.required && form?.[field.name] === '') {
    return '';
  }

  return initialFormValues?.[field.name] ?? (isEditing && !field.cleartext ? '********' : '');
}
