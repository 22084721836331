import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { CustomerRole, CustomerUser, CustomerUserConfigLayoutType } from '@talos/kyoko';

export interface UserDetailsSlice {
  customerUserId?: string;
  displayName?: string;
  email?: string;
  counterparty?: string;
  initialCounterparty?: string;
  externalId?: string;
  initialExternalId?: string;
  selectedRoles?: CustomerRole[];
  initialRoles?: CustomerRole[];
  layoutType?: CustomerUserConfigLayoutType | null;
  initialLayoutType?: CustomerUserConfigLayoutType | null;
  layoutArrangement?: string | null;
  initialLayoutArrangement?: string | null;
  isDirty: boolean;
}

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: {} as UserDetailsSlice,
  reducers: {
    initCustomerUser: (_, { payload: customerUser }: PayloadAction<CustomerUser | undefined>) => {
      return {
        customerUserId: customerUser?.CustomerUserID,
        displayName: customerUser?.DisplayName,
        email: customerUser?.Email,

        counterparty: customerUser?.Counterparty,
        initialCounterparty: customerUser?.Counterparty,

        externalId: customerUser?.ExternalID,
        initialExternalId: customerUser?.ExternalID,

        selectedRoles: customerUser?.Roles,
        initialRoles: customerUser?.Roles,

        isDirty: false,
      };
    },

    loadField: (state, { payload: fields }: PayloadAction<Omit<UserDetailsSlice, 'isDirty'>>) => {
      return { ...state, ...fields };
    },

    updateField: (state, { payload: fields }: PayloadAction<Omit<UserDetailsSlice, 'isDirty'>>) => {
      return { ...state, ...fields, isDirty: true };
    },
  },
});

export const { initCustomerUser, loadField, updateField } = userDetailsSlice.actions;
