import { useEffect, useMemo, useState, type PropsWithChildren } from 'react';
import { useMeasure } from 'react-use';
import { useTheme } from 'styled-components';
import { Box, HStack } from '../Core';
import { ControlPrefix } from '../Form/styles';
import { Icon, ICON_SIZES, IconName } from '../Icons';
import { OverflowItemsRenderer } from './OverflowItemsRenderer';

type VisibleAndOverflowItems = {
  visibleItems: string[];
  overflowItems: string[];
};
type SizingMap = [number, VisibleAndOverflowItems][];
function buildSizingMap<T>(
  items: T[],
  labelFontSize: number,
  chipFontSize: number,
  getItemLabel: (item: T) => string
): SizingMap {
  const sizingMap: SizingMap = [];

  const labels = items.map(item => getItemLabel(item));

  for (let index = 0; index <= items.length; index++) {
    const displayedLabel = labels.slice(0, index).join(', ');
    const labelWidth = measureLabelWidth(displayedLabel, labelFontSize);
    const remainderSize = items.length - index;
    const chipSize = remainderSize > 0 ? measureLabelWidth(`+${remainderSize}`, chipFontSize) : 0;
    sizingMap.push([
      labelWidth + chipSize,
      {
        visibleItems: labels.slice(0, index),
        overflowItems: labels.slice(index),
      },
    ]);
  }

  return sizingMap;
}

/**
 * Calculate Visible and Overflow items for {@ses OverflowItemBox}
 * @param param0.items - List of items to display
 * @param param0.getItemLabel - Function to get the label for an item
 * @param param0.labelFontSize - Font size (rem's from theme fontSize*) for the label text display + overflow measurement
 * @param param0.chipFontSize - Font size (rem's from theme fontSize*) for the chip text display + overflow measurement
 */
function useCalcVisibleAndOverflowItems<T>({
  items,
  getItemLabel,
  labelFontSize,
  chipFontSize = labelFontSize,
}: {
  items: T[];
  getItemLabel: (item: T) => string;
  labelFontSize: number;
  chipFontSize?: number;
}) {
  const [containerRef, { width: containerWidth }] = useMeasure<HTMLDivElement>();
  const [isReady, setIsReady] = useState(false);
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [overflowItems, setOverflowItems] = useState<string[]>([]);

  const sizingMap = useMemo(
    () => buildSizingMap(items, labelFontSize, chipFontSize, getItemLabel),
    [items, labelFontSize, chipFontSize, getItemLabel]
  );

  useEffect(() => {
    if (containerWidth) {
      const result = sizingMap.toReversed().find(([displaySize]) => displaySize <= containerWidth) ?? sizingMap.at(-1);
      if (result) {
        const [_, { visibleItems, overflowItems }] = result;
        setIsReady(true);
        setVisibleItems(visibleItems);
        setOverflowItems(overflowItems);
      }
    }
  }, [containerWidth, sizingMap]);

  return {
    isReady,
    visibleItems,
    overflowItems,
    containerRef,
  };
}

/**
 * Overflowing Textbox and '+#' chip component for use on top of components like the MultiSelectCheckboxDropdown to
 * display selected items and overflow items.
 * @param param0.prefix - Prefix component to display before the items
 * @param param0.defaultLabel - Default label to display when no items are selected
 * @param param0.items - List of items to display
 * @param param0.getItemLabel - Function to get the label for an item
 * @param param0.tooltipZIndex - Z-index for the tooltip (allows overriding the default to be over other popovers)
 * @param param0.labelFontSize - Font size (rem's from theme.fontSize*) for the label text display + overflow measurement
 * @param param0.chipFontSize - Font size (rem's from theme.fontSize*) for the chip text display + overflow measurement
 * @returns
 */
export function OverflowItemsBox<T = any>({
  prefix,
  defaultLabel,
  items,
  getItemLabel,
  tooltipZIndex,
  labelFontSize,
  chipFontSize = labelFontSize,
}: PropsWithChildren<{
  prefix: React.ReactNode;
  defaultLabel: React.ReactNode;
  items: T[];
  getItemLabel: (item: T) => string;
  tooltipZIndex?: number;
  labelFontSize: number;
  chipFontSize?: number;
}>) {
  const theme = useTheme();
  const { containerRef, overflowItems, visibleItems, isReady } = useCalcVisibleAndOverflowItems({
    items,
    getItemLabel,
    labelFontSize,
    chipFontSize,
  });

  return (
    <HStack gap="spacingSmall" maxWidth="100%" overflow="hidden" fontSize={labelFontSize}>
      <ControlPrefix data-testid="select-button-prefix" mr={0}>
        {prefix}
      </ControlPrefix>
      <Box flex="auto" position="relative" maxWidth="100%" h={theme.spacingMedium}>
        <Box position="absolute" left="0" right="0" top="0" bottom="0">
          <Box
            data-testid="overflow-item-box"
            ref={containerRef}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            w="100%"
            h="100%"
          >
            <OverflowItemsRenderer
              isReady={isReady}
              defaultLabel={defaultLabel}
              visibleItems={visibleItems}
              overflowItems={overflowItems}
              tooltipZIndex={tooltipZIndex}
              chipFontSize={chipFontSize}
            />
          </Box>
        </Box>
      </Box>
      <Icon icon={IconName.ChevronDown} size={ICON_SIZES.MEDIUM} />
    </HStack>
  );
}

/**
 * Calculate the width of a label to use in the overflow
 * @param label Label to display
 * @param fontSize Label font size (in rem's, use the theme.fontSize* values)
 * @returns
 */
const measureLabelWidth = (label: string, fontSize: number) => {
  const span = document.createElement('span');
  span.style.visibility = 'hidden';
  span.style.whiteSpace = 'nowrap';
  span.style.fontSize = `${fontSize}rem`; // Adjust font size as needed
  span.innerText = label;
  document.body.appendChild(span);
  const width = span.offsetWidth + 16; // Add padding/margin as needed
  document.body.removeChild(span);
  return width;
};
