import type { InitialFilterClause, ToggleHorizontalOption } from '@talos/kyoko';
import {
  Accordion,
  AccordionBody,
  Box,
  Divider,
  FilterBuilder,
  FilterBuilderClearAllButton,
  FilterBuilderToggleButton,
  FormControlSizes,
  HStack,
  Text,
  ToggleHorizontal,
  useAccordionFilterBuilder,
} from '@talos/kyoko';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useOperationsOverviewFilters } from '../providers/OperationsOverviewFiltersProvider';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import { useOperationsOverviewContext } from '../providers/OperationsOverviewStateProvider';
import { OperationsOverviewActionType } from '../reducer';
import { SHOW_BY_DICT, type OperationsOverviewShowBy } from '../types';

const SHOW_BY_OPTIONS: ToggleHorizontalOption<OperationsOverviewShowBy>[] = Object.values(SHOW_BY_DICT).map(entry => ({
  value: entry.showBy,
  displayName: entry.label,
}));

interface ControlPanelProps {
  initialFilterClauses: InitialFilterClause[];
}

export function ControlPanel({ initialFilterClauses }: ControlPanelProps) {
  const { filterableProperties, onFilterClausesChanged } = useOperationsOverviewFilters();
  const { setOpenClause } = useOperationsOverviewInteractions();
  const { state, dispatch } = useOperationsOverviewContext();

  const handleShowByChange = useCallback(
    (type: OperationsOverviewShowBy) => {
      dispatch({
        type: OperationsOverviewActionType.ShowByChange,
        payload: {
          showBy: type,
        },
      });
    },
    [dispatch]
  );

  const { accordion, filterBuilder, openClause } = useAccordionFilterBuilder({
    filterBuilderProps: { initialFilterClauses, properties: filterableProperties, onFilterClausesChanged },
  });

  // Register the openClause function with the interactions provider
  useEffect(() => {
    setOpenClause(() => openClause);
  }, [openClause, setOpenClause]);

  return (
    <ControlPanelWrapper>
      <HStack px="spacingDefault" gap="spacingDefault" justifyContent="space-between" w="100%" py="spacingDefault">
        <Text>Positions and Equity by {SHOW_BY_DICT[state.showBy].label}</Text>

        <HStack gap="spacingDefault">
          <ToggleHorizontal
            options={SHOW_BY_OPTIONS}
            value={state.showBy}
            onChange={handleShowByChange}
            optionDataTestId="show-by-selector"
          />
          <Divider orientation="vertical" />
          <FilterBuilderClearAllButton
            removeAllFilterClauses={filterBuilder.removeAllFilterClauses}
            disabled={filterBuilder.filterClauses.length === 0}
            size={FormControlSizes.Small}
          />
          <FilterBuilderToggleButton
            filterClauses={filterBuilder.filterClauses}
            isOpen={accordion.isOpen}
            onClick={() => accordion.toggle()}
            size={FormControlSizes.Small}
          />
        </HStack>
      </HStack>
      <Box w="100%">
        <Accordion {...accordion}>
          <AccordionBody p="0" px="spacingMedium" borderTopWhenOpened>
            <Box py="spacingMedium">
              <FilterBuilder {...filterBuilder} />
            </Box>
          </AccordionBody>
        </Accordion>
      </Box>
    </ControlPanelWrapper>
  );
}

const ControlPanelWrapper = styled(Box)`
  border-bottom: 2px solid ${({ theme }) => theme.backgroundBody};
  background: ${({ theme }) => theme.colors.gray.main};
`;
