import {
  ModeEnum,
  arrayContentsEqual,
  isValidEmail,
  useDynamicCallback,
  type CustomerUser,
  type CustomerUserConfigLayoutType,
} from '@talos/kyoko';
import { useCustomersContext } from 'hooks/useCustomer';
import { useCustomerUserDispatch, useCustomerUserSelector } from '../state/hooks';
import { initPermissions } from '../state/permissions/permissionsSlice';
import { initCustomerUser, loadField } from '../state/userDetails/userDetailsSlice';

export function useCustomerUserDetails() {
  const dispatch = useCustomerUserDispatch();

  const customersService = useCustomersContext();
  const state = useCustomerUserSelector(state => state.userDetails);

  const loadCustomerUser = useDynamicCallback(async (customerUser?: CustomerUser) => {
    dispatch(initCustomerUser(customerUser));
    if (customerUser) {
      if (customerUser.MarketAccountAuthorizations) {
        dispatch(initPermissions(customerUser.MarketAccountAuthorizations));
      }
      const configs = await customersService.getCustomerUserConfigs(customerUser.CustomerUserID);
      const layoutType = getValue('layoutType', configs) as CustomerUserConfigLayoutType;
      const layoutArrangement = getValue('layoutArrangement', configs);
      dispatch(
        loadField({
          layoutType,
          layoutArrangement,
          initialLayoutType: layoutType,
          initialLayoutArrangement: layoutArrangement,
        })
      );
    }
  });

  const {
    displayName,
    email,
    counterparty,
    initialCounterparty,
    externalId,
    initialExternalId,
    selectedRoles,
    initialRoles,
    layoutType,
    layoutArrangement,
    initialLayoutType,
    initialLayoutArrangement,
  } = state;

  const updateCustomer = useDynamicCallback((customerUserId: string) => {
    const customerUserUpdate: Partial<CustomerUser> = {};
    if (counterparty !== initialCounterparty) {
      customerUserUpdate.Counterparty = counterparty;
    }
    if (externalId !== initialExternalId) {
      customerUserUpdate.ExternalID = externalId;
    }

    if (Object.keys(customerUserUpdate).length === 0) {
      return Promise.resolve(); // nothing to do
    }
    return customersService.updateCustomerUser({ CustomerUserID: customerUserId, ...customerUserUpdate });
  });

  const updateRoles = useDynamicCallback(
    (customerUserId: string, initialRoleNames?: string[], selectedRoleNames?: string[]) => {
      if (arrayContentsEqual(initialRoleNames, selectedRoleNames)) {
        return Promise.resolve(); // nothing to do
      }
      customersService.updateCustomerUserRole(customerUserId, initialRoleNames ?? [], selectedRoleNames ?? []);
    }
  );

  const updateLayout = useDynamicCallback((customerUserId: string) => {
    return Promise.all([
      updateValue('layoutType', initialLayoutType?.toString(), layoutType?.toString()),
      updateValue('layoutArrangement', initialLayoutArrangement?.toString(), layoutArrangement?.toString()),
    ]);

    function updateValue(type: string, initialValue?: string, selectedValue?: string) {
      if (selectedValue === initialValue) {
        return Promise.resolve(); // nothing to do
      }
      if (!selectedValue) {
        return customersService.removeCustomerUserConfig(customerUserId, type);
      }
      return customersService.upsertCustomerUserConfig(customerUserId, type, selectedValue);
    }
  });

  const saveCustomerUser = useDynamicCallback((): Promise<string> => {
    if (!displayName || displayName.length === 0) {
      return Promise.reject('Invalid customer name');
    } else if (!email || !isValidEmail(email)) {
      return Promise.reject('Invalid e-mail');
    } else if (!counterparty) {
      return Promise.reject('A counterparty must be selected');
    } else if (!selectedRoles || selectedRoles.length === 0) {
      return Promise.reject('At least one role must be selected');
    }

    const customerUser: Partial<CustomerUser> = {
      DisplayName: displayName,
      Email: email,
      Counterparty: counterparty,
      ExternalID: externalId,
      Mode: ModeEnum.Enabled,
    };
    const customerUserId = state.customerUserId;
    const initialRoleNames = initialRoles?.map(role => role.Name);
    const selectedRoleNames = selectedRoles?.map(role => role.Name);

    return customerUserId
      ? Promise.all([
          // for some reason only the create customer endpoint takes roles, but if updating them, we need to call another endpoint
          updateCustomer(customerUserId),
          updateRoles(customerUserId, initialRoleNames, selectedRoleNames),
          updateLayout(customerUserId),
        ]).then(() => customerUserId)
      : // the api actually takes strings, not CustomerRole[]
        customersService.createCustomerUser({ ...customerUser, Roles: selectedRoleNames as any }).then(({ data }) => {
          const newCustomerId = data[0].CustomerUserID;
          return updateLayout(newCustomerId).then(() => newCustomerId);
        });
  });

  return { loadCustomerUser, saveCustomerUser };
}

function getValue(key: string, configs: { Key: string; Value: string }[]) {
  const value = configs.find(config => config.Key === key)?.Value;
  return value ? JSON.parse(value) : null;
}
