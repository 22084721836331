import {
  Box,
  Button,
  ButtonVariants,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  FormGroup,
  IconButton,
  IconName,
  IndicatorBadge,
  IndicatorDotVariants,
  Input,
  MixpanelEvent,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  useDynamicCallback,
  useMixpanel,
  type DrawerProps,
  type RequiredProperties,
  type User,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Prompt } from 'react-router';
import styled from 'styled-components';
import { prettifyRole } from '../../../tokens/roles';
import { useEditMarketCredentialsTab } from './tabs/EditMarketCredentialsTab';
import { useEditMarketSessionLimitTab } from './tabs/EditMarketSessionLimitTab';
import { useEditUserGeneralTab } from './tabs/EditUserGeneralTab';
import { useEditUserSubAccounts } from './tabs/EditUserSubAccounts';
import { useUserHistoryTab } from './tabs/UserHistoryTab';

type EditUserDrawerProps = DrawerProps & { user: User | undefined; onSaved: () => Promise<void> };

function userIsDefined(props: EditUserDrawerProps): props is RequiredProperties<EditUserDrawerProps, 'user'> {
  return props.user != null;
}

export function EditUserDrawer(props: EditUserDrawerProps) {
  return (
    <Drawer {...props} data-testid="edit-user-drawer">
      {userIsDefined(props) && <EditUser key={props.user.Name + props.user.ID} {...props} />}
    </Drawer>
  );
}

function EditUser({ user, close, onSaved, isOpen }: RequiredProperties<EditUserDrawerProps, 'user'>) {
  const mixpanel = useMixpanel();
  const [isSaving, setIsSaving] = useState(false);
  const [reason, setReason] = useState('');

  const generalTab = useEditUserGeneralTab({ user });
  const subAccountsTab = useEditUserSubAccounts({ user });
  const marketCredentialsTab = useEditMarketCredentialsTab({ user });
  const marketSessionLimitsTab = useEditMarketSessionLimitTab({
    user,
  });
  const historyTab = useUserHistoryTab({ user });

  const allTabs = useMemo(
    () => [generalTab, subAccountsTab, marketCredentialsTab, marketSessionLimitsTab, historyTab],
    [generalTab, subAccountsTab, marketCredentialsTab, marketSessionLimitsTab, historyTab]
  );
  const viewableTabs = useMemo(() => allTabs.filter(tab => tab.viewable), [allTabs]);

  const isDirty = viewableTabs.some(tab => tab.isDirty);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = viewableTabs[activeTabIndex];
  const handleTabChange = useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);

  const handleSaveChanges = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.EditUser);
    setIsSaving(true);

    const promises = compact(viewableTabs.map(tab => tab.save?.()));
    Promise.allSettled(promises).then(() => {
      // allSettled always goes into the .then
      // We dont do any results checking here to keep things simple. We let the individual save functions
      // take care of alerting the user if anything has gone wrong.
      setReason('');
      onSaved?.();
      setIsSaving(false);
    });
  });

  const handleClose = useDynamicCallback(() => {
    if (!isDirty) {
      return close();
    }
    const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      close();
    }
  });

  return (
    <>
      <Prompt when={isOpen && isDirty} message="You have unsaved changes. Are you sure you want to leave?" />
      <UserDrawerHeader>
        <Flex gap="spacingSmall" alignItems="center">
          <Text color="colorTextImportant">{user.Name}</Text>
          {user.Roles && (
            <Flex gap="spacingSmall" flexWrap="wrap">
              {user.Roles.map(role => (
                <IndicatorBadge key={role} children={prettifyRole(role)} />
              ))}
            </Flex>
          )}
        </Flex>
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
      </UserDrawerHeader>
      <DrawerContent p="0">
        <Flex flexDirection="column" overflow="hidden">
          <Tabs
            flex="1"
            overflow="hidden"
            selectedIndex={activeTabIndex}
            onSelect={handleTabChange}
            size={TabSize.Large}
          >
            <TabList isBordered flex="0 0 auto">
              {viewableTabs.map(tab => (
                <Tab
                  showDot={tab.isDirty}
                  dotVariant={IndicatorDotVariants.Primary}
                  label={tab.name}
                  suffix={<IndicatorBadge children={tab.badgeContent} />}
                  key={tab.name}
                />
              ))}
            </TabList>
            <Flex
              flexDirection="column"
              p="spacingComfortable"
              pt="spacingMedium"
              gap="spacingMedium"
              overflow="auto"
              flex="1"
            >
              {viewableTabs.map(tab => (
                <Box key={tab.name} display={activeTab.name === tab.name ? 'flex' : 'none'} w="100%" h="100%">
                  {tab.component}
                </Box>
              ))}
            </Flex>
          </Tabs>

          {activeTab.renderReasonInput && (
            <Box
              alignItems="flext-start"
              borderTop="1px solid"
              borderColor="borderColorDivider"
              mt="spacingDefault"
              pt="spacingDefault"
            >
              <FormGroup label="Reason (optional)" controlId="reason">
                <Input id="reason" value={reason} onChange={e => setReason(e.target.value)} />
              </FormGroup>
            </Box>
          )}
        </Flex>
      </DrawerContent>
      <DrawerFooter>
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={handleSaveChanges}
          disabled={!isDirty}
          variant={ButtonVariants.Primary}
          loading={isSaving}
          data-testid="edit-user-drawer-save-button"
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
}

const UserDrawerHeader = styled(Flex)`
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacingDefault}px ${({ theme }) => theme.spacingMedium}px;
  min-height: 3.5rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;
