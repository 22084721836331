import { baseTreeGroupColumnDef } from '@talos/kyoko';
import { autoGroupSortingDefaults } from '@talos/kyoko/src/components/BlotterTable/columns/autoGroupSortingDefaults';
import type { GetDataPath, GridOptions, GroupCellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { useRollupTreeAutoGroupCellSelector } from './useRollupTreeAutoGroupCellSelector';

interface UseRollupTreeGridBuildersOutput {
  blotterParams: Pick<GridOptions, 'getDataPath' | 'autoGroupColumnDef' | 'showOpenedGroup' | 'treeData'>;
}

/** Properties created client side for row uniqueness and grouping */
export interface HierarchicalGridData {
  /** Hierarchical Data Path for Tree Data Grid usage */
  hierarchicalRowInfo: string[];
  /** skip agg (most likely due to duplicate data entries) */
  skipAggregation: boolean;
  /** unique id to pass to ag-grid */
  rowID: string;
}
/** Minimal Properties required for Position Grid Builders */
export type RollupTreePositionData = { SubAccount: string } & HierarchicalGridData;

const getDataPath: GetDataPath = <TData extends HierarchicalGridData>(node: TData) => {
  return node.hierarchicalRowInfo;
};

// GroupCellRenderer InnerRenderer supports framework components though the type is not updated in the ag-grid types
// Newer ag-grid versions use IGroupCellRendererParams: https://www.ag-grid.com/javascript-data-grid/group-cell-renderer/
export type GroupCellRendererParamsCustom = Pick<
  Partial<GroupCellRendererParams>,
  'suppressCount' | 'suppressDoubleClickExpand' | 'checkbox' | 'innerRendererParams'
> & {
  innerRenderer: React.FC<any>;
};

/** Get AgGrid requirements for constructing the Hierarchical Rollup->SubAccount->Position Grids  */
export const useRollupTreeGridBuilders = <TData extends RollupTreePositionData>(): UseRollupTreeGridBuildersOutput => {
  const subAccountCellSelector = useRollupTreeAutoGroupCellSelector();

  const autoGroupColumnDef = useMemo(
    (): GridOptions['autoGroupColumnDef'] => ({
      ...baseTreeGroupColumnDef,
      headerName: 'Rollup/SubAccount - Asset',
      pinned: 'left',
      ...autoGroupSortingDefaults,
      valueFormatter: ({ data, context, node }: ValueFormatterParams<TData>) => {
        const subAccountName = data?.SubAccount ?? node?.key;
        if (!subAccountName) {
          return 'Unknown';
        }

        const subAccount = context.current.subAccountsByName?.get(subAccountName);
        return subAccount?.DisplayName ?? subAccount?.Name ?? '';
      },
      // TODO - Add filterValueGetter for right click filtering logic
      ...subAccountCellSelector,
    }),
    [subAccountCellSelector]
  );

  const output: UseRollupTreeGridBuildersOutput = useMemo(
    () => ({
      blotterParams: {
        getDataPath,
        autoGroupColumnDef,
        showOpenedGroup: true,
        treeData: true,
      },
    }),
    [autoGroupColumnDef]
  );

  return output;
};
