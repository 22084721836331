import type {
  Allocation,
  AllocationValueTypeEnum,
  CamelCased,
  DecisionStatusEnum,
  Duration,
  MarketOrder,
  OrdTypeEnum,
  PriceProtectionEnum,
  ReduceFirstEnum,
  ReduceOnlyEnum,
  SideEnum,
  UnifiedLiquidityEnum,
} from '@talos/kyoko';
import type { OrderStrategiesEnum, ParameterKeysEnum } from 'tokens/orderStrategy';

// Hardcoded constant used by the backend as the semantic Market key for mutlileg orders on our "internal market"
export const MULTILEG_MARKET = 'multileg';
export const MULTILEG_MARKET_ACCOUNT = 'multileg/multileg';

export type LegParams = {
  Symbol?: string;
  Markets: string[];
  Initiating: boolean;
  LegIndex: number | undefined;
};

export type OMSFormOrderParameters = CamelCased<
  {
    [ParameterKeysEnum.StartTime]?: string | Date | Duration | null;
    [ParameterKeysEnum.EndTime]?: string | Date | Duration | null;
    [ParameterKeysEnum.Duration]?: string | null;
    [ParameterKeysEnum.PriceOffset]?: string | null;
    [ParameterKeysEnum.ShowQty]?: string | null;
    [ParameterKeysEnum.PriceProtection]?: PriceProtectionEnum | null;
    [ParameterKeysEnum.UnifiedLiquidity]?: UnifiedLiquidityEnum | null;
    [ParameterKeysEnum.ReduceOnly]?: ReduceOnlyEnum | null;
    [ParameterKeysEnum.ReduceFirst]?: ReduceFirstEnum | null;
    [key: string]: unknown | string | undefined;
  },
  false
>;

export interface OMSForm {
  // Order and RFQ Form params
  symbol: string;
  quantity: string;
  price: string;
  group?: string;
  ordType?: OrdTypeEnum;
  orderCurrency: string;
  orderSide?: SideEnum;
  subAccountAllocations?: Allocation[];
  orderMarketAccounts: string[];
  strategy?: OrderStrategiesEnum;
  parameters: OMSFormOrderParameters;
  duration?: string;
  customDuration?: boolean;
  startTime?: Date;
  submitTime?: Date;
  endTime?: Date;
  initialDecisionStatus?: DecisionStatusEnum;
  allocationValueType?: AllocationValueTypeEnum;
  // Sales RFQ Params
  customer?: string;
  customerQuantity?: string;
  customerMarketAccount?: string;
  // Idk why this is also tacked on here....
  trade?: Partial<ManualTradeForm>;
  marketOrder?: MarketOrder;
  comment?: string;
  //Multileg specific
  legParams?: LegParams[];
}

// This might be able to get rid of
export interface ManualTradeForm {
  side: SideEnum;
  transactTime: Date;
  symbol?: string;
  subAccount?: string;
  quantity?: string;
  amount?: string;
  price?: string;
  subAccountId?: number;
  fee?: string;
  feeCurrency?: string;
  market?: string;
  marketAccount?: string;
  comments?: string;
  group?: string;
  ordType?: OrdTypeEnum;
  subAccountAllocations?: Allocation[];
  allocationValueType?: AllocationValueTypeEnum;
  // We allow talos admins + support to modify regular (non-manual, non-orphan) trades. In which case, this property would be defined.
  orderId?: string;
}
