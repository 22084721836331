import {
  getTreasuryLinkSpecificness,
  type TreasuryLink,
  type TreasuryLinkSourceOrDestinationField,
  type TreasuryLinkSpecificness,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { usePortfolioAccounts } from '../../../../providers/PortfolioAccountsProvider';
import type { TreasuryLinkConstraints } from './shared';
import { getRelevantTreasuryLinks, useRelevantTreasuryLinks } from './useRelevantTreasuryLinks';

/**
 * This file contains helper hooks and functions for working with TreasuryLinks.
 *
 * In TreasuryLinks land, there is a concept of fallbacks ingrained. There are four levels of specificness as
 * can be seen in the TreasuryLinkSpecificness enum directly below.
 */

interface UseEffectiveTreasuryLinkParams extends TreasuryLinkConstraints {
  /** Either Source or Destination to look for */
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField;
}

/**
 * Returns the effective treasury link given the constraints.
 *
 * See TreasuryLink.ts for a description of what an "effective" treasury link is.
 */
export const useEffectiveTreasuryLink = ({
  sourceOrDestinationField,
  ...constraints
}: UseEffectiveTreasuryLinkParams): TreasuryLink | undefined => {
  const { treasuryLinksBySpecificnessKey } = usePortfolioAccounts();
  return useMemo(() => {
    if (!treasuryLinksBySpecificnessKey) {
      return undefined;
    }

    return getEffectiveTreasuryLink({
      treasuryLinksBySpecificnessKey: treasuryLinksBySpecificnessKey,
      sourceOrDestinationField,
      ...constraints,
    });
  }, [treasuryLinksBySpecificnessKey, sourceOrDestinationField, constraints]);
};

interface GetEffectiveTreasuryLinkParams extends UseEffectiveTreasuryLinkParams {
  treasuryLinksBySpecificnessKey: Map<string, Map<string, TreasuryLink>>;
}

/**
 * A non-hook version of useEffectiveTreasuryLink.
 *
 * Returns the effective treasury link given the constraints.
 *
 * See TreasuryLink.ts for a description of what an "effective" treasury link is.
 */
export function getEffectiveTreasuryLink({
  sourceOrDestinationField,
  treasuryLinksBySpecificnessKey,
  ...constraints
}: GetEffectiveTreasuryLinkParams): TreasuryLink | undefined {
  const relevantLinks = getRelevantTreasuryLinks({ treasuryLinksBySpecificnessKey, ...constraints });
  // Going from most specific to least specific in the list of relevant fields, we return the first link which has *field* specified.
  return relevantLinks.find(link => link[sourceOrDestinationField] != null);
}

/**
 * Given a TreasuryLink and a specification of which field, SourceMarketAccount or DestinationMarketAccount,
 * return the "effective" source or destination account.
 *
 * The "effective" source or destination is the one that actually takes effect. It can either be the source/destination from the passed in link directly,
 * or it will be some fallback from another (more generic) link, or there is none at all.
 *
 * See TreasuryLink.ts for more descriptions about effective treasury links.
 */
export const useEffectiveTreasuryLinkSourceOrDestination = (
  link: Pick<TreasuryLink, 'Type' | 'Direction' | 'Currency' | 'MarketAccount'> & Partial<Pick<TreasuryLink, 'LinkID'>>,
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField
):
  | {
      effectiveSourceOrDestination: string;
      effectiveSourceOrDestinationSpecificness: TreasuryLinkSpecificness;
    }
  | undefined => {
  const relevantLinks = useRelevantTreasuryLinks({
    type: link.Type,
    direction: link.Direction,
    currency: link.Currency,
    marketAccount: link.MarketAccount,
    linkID: link.LinkID,
  });

  if (relevantLinks == null) {
    return undefined;
  }

  const effectiveLinkForField = relevantLinks.find(link => link[sourceOrDestinationField] != null);
  if (!effectiveLinkForField) {
    return undefined;
  }

  const effectiveSourceOrDestination = effectiveLinkForField[sourceOrDestinationField];
  if (effectiveSourceOrDestination == null) {
    // can't happen due to the .find operation but adding this for type narrowing
    return undefined;
  }
  const effectiveSourceOrDestinationSpecificness = getTreasuryLinkSpecificness(effectiveLinkForField);
  return { effectiveSourceOrDestination, effectiveSourceOrDestinationSpecificness };
};

/**
 * Exactly similar to useEffectiveTreasuryLinkSourceOrDestination, but it will give you the "second most effective link", as in,
 * it will ignore the first effective link it finds (pretending that its not there), and look for a second one.
 *
 * This can be seen as "the fallback source/destination if the currently effective source/destination link is reset".
 */
export const useFallbackTreasuryLinkSourceOrDestination = (
  link: Pick<TreasuryLink, 'Type' | 'Direction' | 'Currency' | 'MarketAccount'> & Partial<Pick<TreasuryLink, 'LinkID'>>,
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField
):
  | {
      fallbackSourceOrDestination: string;
      fallbackSourceOrDestinationSpecificness: TreasuryLinkSpecificness;
    }
  | undefined => {
  const relevantLinks = useRelevantTreasuryLinks({
    type: link.Type,
    direction: link.Direction,
    currency: link.Currency,
    marketAccount: link.MarketAccount,
    linkID: link.LinkID,
  });

  if (relevantLinks == null) {
    return undefined;
  }

  // Do a first check for the effective link
  const effectiveLinkForField = relevantLinks.find(link => link[sourceOrDestinationField] != null);
  if (!effectiveLinkForField) {
    return undefined;
  }

  // Check again while also ignoring the found effective link
  const fallbackLinkForField = relevantLinks.find(
    link => link[sourceOrDestinationField] != null && link !== effectiveLinkForField
  );
  if (!fallbackLinkForField) {
    return undefined;
  }

  const fallbackSourceOrDestination = fallbackLinkForField[sourceOrDestinationField];
  if (fallbackSourceOrDestination == null) {
    // can't happen due to the .find operation but adding this for type narrowing
    return undefined;
  }
  const fallbackSourceOrDestinationSpecificness = getTreasuryLinkSpecificness(fallbackLinkForField);
  return { fallbackSourceOrDestination, fallbackSourceOrDestinationSpecificness };
};
