import {
  Button,
  ButtonVariants,
  ConnectionTypeEnum,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  FormGroup,
  HStack,
  IconButton,
  IconName,
  Input,
  NotificationVariants,
  SearchSelect,
  Text,
  VStack,
  useConstant,
  useGlobalToasts,
  type CustomerUser,
  type DrawerProps,
  type ICustomerFIXConnection,
  type SearchSelectProps,
} from '@talos/kyoko';
import { useCustomerUsers, useCustomersContext } from 'hooks/useCustomer';
import { keysIn } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generateTimeSelectionOptions } from './utils';

export enum FIXConnectionTypeEnum {
  MarketData = 'MARKETDATA',
  Orders = 'ORDERS',
  DropCopy = 'DROPCOPY',
}

const fixConnectionTypeOptions = {
  [FIXConnectionTypeEnum.Orders]: {
    name: 'Orders',
    description: 'Orders FIX Connection',
    includedConnectionTypes: { OrdersRequests: true, OrdersUpdates: true, TradesUpdates: true },
  },
  [FIXConnectionTypeEnum.DropCopy]: {
    name: 'DropCopy',
    description: 'Post-Trade FIX Connection',
    includedConnectionTypes: { [ConnectionTypeEnum.OrdersUpdates]: true, [ConnectionTypeEnum.TradesUpdates]: true },
  },
  [FIXConnectionTypeEnum.MarketData]: {
    name: 'MarketData',
    description: 'MarketDataDepth and MarketDataTrades Connection Types',
    includedConnectionTypes: {
      [ConnectionTypeEnum.MarketDataDepth]: true,
      [ConnectionTypeEnum.MarketDataTrades]: true,
    },
  },
} as const;

type CustomerFIXConnectionDrawerProps = {
  customerFIXConnection?: ICustomerFIXConnection;
  onSave: (forceRefresh?: boolean) => void;
} & DrawerProps;

// Fields that are always included and enabled for editing
const enabledAndAlwaysIncludedFields: (keyof ICustomerFIXConnection)[] = [
  'CustomerUser',
  'ConnectionType',
  'URL',
  'BeginString',
  'SenderCompID',
  'TargetCompID',
  'StartTime',
  'EndTime',
];

// Required Fields need to be filled in with a non-empty value
const requiredFields: (keyof ICustomerFIXConnection)[] = [
  'CustomerUser',
  'ConnectionType',
  'URL',
  'SenderCompID',
  'TargetCompID',
  'BeginString',
];

// Paired Fields need to appear together or not at all
const pairedFields: (keyof ICustomerFIXConnection)[][] = [['StartTime', 'EndTime']];

// Fields that are always hidden
const hiddenFields: (keyof ICustomerFIXConnection)[] = [
  'UpdateAction',
  'Revision',
  'Timestamp',
  'ConnectionID',
  'ServiceID',
];

export function CustomerFIXConnectionDrawer({
  customerFIXConnection,
  onSave,
  ...props
}: CustomerFIXConnectionDrawerProps) {
  const isNewFIXConnection = customerFIXConnection == null;
  const { add: addToast } = useGlobalToasts();

  const [form, setForm] = useState<Partial<ICustomerFIXConnection>>({});

  useEffect(() => {
    if (isNewFIXConnection) {
      setForm({});
    } else if (customerFIXConnection.ConnectionID !== form.ConnectionID) {
      setForm(customerFIXConnection);
    }
  }, [form.ConnectionID, isNewFIXConnection, customerFIXConnection]);

  const { upsertCustomerFIXConnection, deleteCustomerFIXConnection } = useCustomersContext();

  const handleDelete = useCallback(() => {
    if (isNewFIXConnection) {
      return;
    }
    if (window.confirm(`Are you sure you want to delete this connection?`)) {
      deleteCustomerFIXConnection(customerFIXConnection.TargetCompID)
        .then(() => {
          props.close();
          addToast({
            text: 'Customer FIX Connection deleted',
            variant: NotificationVariants.Positive,
          });
          onSave(true);
        })
        .catch(e => {
          addToast({
            text: e.message,
            variant: NotificationVariants.Negative,
          });
        });
    }
  }, [addToast, deleteCustomerFIXConnection, isNewFIXConnection, onSave, customerFIXConnection?.TargetCompID, props]);

  const handleSave = useCallback(() => {
    // Check for missing paired fields
    const missingPairedFields = pairedFields
      // Both fields are required or neither.
      .filter(([field1, field2]) => Boolean(form[field1]) !== Boolean(form[field2]))
      .map(([field1, field2]) => (form[field1] == null ? field1 : field2));

    const missingRequiredFields = requiredFields.filter(field => !form[field]);
    const missingFields = [...missingPairedFields, ...missingRequiredFields];
    if (missingFields.length > 0) {
      addToast({
        text: `Missing required fields: ${missingFields.join(', ')}`,
        variant: NotificationVariants.Negative,
      });
      return;
    }

    upsertCustomerFIXConnection([form])
      .then(res => {
        setForm(res.data.at(0) ?? {});
        props.close();
        addToast({
          text: 'Customer FIX Connection saved',
          variant: NotificationVariants.Positive,
        });
        onSave();
      })
      .catch(e => {
        addToast({
          text: e.message,
          variant: NotificationVariants.Negative,
        });
      });
  }, [addToast, upsertCustomerFIXConnection, form, onSave, props]);

  const customerUsers = useCustomerUsers();

  const getCustomerUserSelection = useCallback(
    (key: string) => {
      return customerUsers?.find(option => option.Email === form[key]);
    },
    [customerUsers, form]
  );

  const keys = useMemo(() => {
    if (!isNewFIXConnection) {
      return (
        Array.from(
          new Set([...enabledAndAlwaysIncludedFields, ...Object.keys(customerFIXConnection)])
        ) as (keyof ICustomerFIXConnection)[]
      ).filter(key => !hiddenFields.includes(key));
    } else {
      return enabledAndAlwaysIncludedFields;
    }
  }, [isNewFIXConnection, customerFIXConnection]);

  const updateForm = useCallback(
    (key: keyof ICustomerFIXConnection, value?: ICustomerFIXConnection[keyof ICustomerFIXConnection]) => {
      const dupedForm = JSON.parse(JSON.stringify(form));
      if (value === undefined) {
        // If value is undefined, remove the key from the form
        delete dupedForm[key];
      } else {
        // Update the form with the new value
        dupedForm[key] = value;
      }
      setForm(dupedForm);
    },
    [form]
  );

  const DrawerSearchSelect = ({
    field,
    ...props
  }: SearchSelectProps<any> & { field: keyof ICustomerFIXConnection }) => {
    return (
      <SearchSelect
        disabled={!enabledAndAlwaysIncludedFields.includes(field)}
        showClear={!requiredFields.includes(field)}
        data-testid={`drawer-search-select-${field}`}
        {...props}
      />
    );
  };

  const timeOptions = useConstant(generateTimeSelectionOptions());

  return (
    <Drawer {...props} data-testid="customer-fix-connection-drawer">
      <HStack justifyContent="space-between" w="100%" flexDirection="row" p="spacingMedium">
        {isNewFIXConnection ? (
          <Text color="colorTextImportant">New Customer FIX Connection</Text>
        ) : (
          <Text color="colorTextImportant">Modify CustomerFIX Connection</Text>
        )}
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={() => props.close()} />
      </HStack>
      <DrawerContent rows="1fr">
        <VStack w="100%" justifyContent="flex-start" overflow="scroll">
          <Flex flexDirection="column" w="100%">
            <Flex flexDirection="column" w="100%">
              {keys.map(key => (
                <FormGroup key={key} label={`${key}${requiredFields.includes(key) ? '*' : ''}`}>
                  {key === 'CustomerUser' && customerUsers ? (
                    <DrawerSearchSelect
                      field={key}
                      selection={getCustomerUserSelection(key)}
                      options={customerUsers}
                      getLabel={option => option.Email}
                      onChange={(customerUser?: CustomerUser) => updateForm(key, customerUser?.Email)}
                    />
                  ) : key === 'ConnectionType' ? (
                    <DrawerSearchSelect
                      field={key}
                      options={keysIn(fixConnectionTypeOptions)}
                      selection={form[key] ?? ''}
                      getLabel={option => fixConnectionTypeOptions[option]?.name}
                      getDescription={option => fixConnectionTypeOptions[option]?.description}
                      onChange={selection => updateForm(key, selection)}
                    />
                  ) : key === 'URL' ? (
                    <Input
                      placeholder="fix://:port-number"
                      value={form[key] ?? ''}
                      onChange={e => updateForm(key, e.target.value)}
                    />
                  ) : key === 'BeginString' ? (
                    <Input placeholder="FIX4.4" onChange={e => updateForm(key, e.target.value)} />
                  ) : key === 'StartTime' || key === 'EndTime' ? (
                    <DrawerSearchSelect
                      field={key}
                      options={timeOptions}
                      selection={form[key]}
                      getLabel={item => `${item} UTC`}
                      onChange={(selection?: string) => updateForm(key, selection)}
                    />
                  ) : (
                    <Input
                      value={(form[key] ?? '') as string}
                      readOnly={!enabledAndAlwaysIncludedFields.includes(key)}
                      onChange={e => updateForm(key, e.target.value)}
                      data-testid={`drawer-input-${key}`}
                    />
                  )}
                </FormGroup>
              ))}
            </Flex>
          </Flex>
        </VStack>
      </DrawerContent>
      <DrawerFooter>
        {!isNewFIXConnection && (
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleDelete}
            data-testid="customer-fix-connection-delete-button"
          >
            Delete
          </Button>
        )}
        <Button
          onClick={handleSave}
          variant={ButtonVariants.Primary}
          disabled={requiredFields.some(field => !form[field])}
          data-testid="customer-fix-connection-save-button"
        >
          Save
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}
