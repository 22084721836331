import {
  FilterClauseType,
  IconName,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useMarketsFilter } from '../../../hooks';
import { useConnectionStatusFilter } from '../../../hooks/filters/useConnectionStatusFilter';
import { useMarketTypesFilter } from '../../../hooks/filters/useMarketTypesFilter';
import type { HydratedMarketCredential } from './types';

export interface CredentialsBlotterTableFilter {
  Types?: string[];
  Markets?: string[];
  Statuses?: string[];
  Mode?: string[];
}

interface UseCredentialsFilterProps<TData extends HydratedMarketCredential> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}

export const useCredentialsFilter = <TData extends HydratedMarketCredential>(
  props: UseCredentialsFilterProps<TData>
) => {
  const { persistedBlotterTable } = props;
  const { initialFilter, onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<CredentialsBlotterTableFilter>(() =>
    cleanupInitialFilterDateRange(initialFilter)
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<HydratedMarketCredential>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'Markets', data, 'Market')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Types', data, 'MarketType')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Statuses', data, 'Status')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Mode', data, 'Mode')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const marketsFilter = useMarketsFilter();
  const marketTypesFilter = useMarketTypesFilter();
  const connectionStatusFilter = useConnectionStatusFilter();
  const filterableProperties: FilterableProperty[] = useMemo(
    () => [
      marketTypesFilter,
      marketsFilter,
      connectionStatusFilter,
      {
        key: 'Mode',
        label: 'Mode',
        control: 'single-select',
        icon: IconName.CheckCircle,
        options: ['Up', 'Down'],
        getOptionLabel: (option: string) => (option === 'Up' ? 'Enabled' : 'Disabled'),
      },
    ],
    [marketsFilter, marketTypesFilter, connectionStatusFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CredentialsBlotterTableFilter)[]).forEach((key: keyof CredentialsBlotterTableFilter) => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key] as string[],
        });
      });
    }
    return clauses;
  }, [filter]);

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<CredentialsBlotterTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as CredentialsBlotterTableFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;

  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    filterBuilderProps,
  };
};

export function colIDToFilterBuilderKey(id: string): keyof CredentialsBlotterTableFilter | undefined {
  switch (id as keyof HydratedMarketCredential) {
    case 'MarketType':
      return 'Types';
    case 'Market':
      return 'Markets';
    case 'Status':
      return 'Statuses';
    case 'Mode':
      return 'Mode';
  }

  return undefined;
}
