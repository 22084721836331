import { ConnectionModeEnum, HStack, Icon, IconName, type MarketCredential, Text, VStack } from '@talos/kyoko';

interface SelectedCredentialsListProps {
  credentials: MarketCredential[];
  mode?: ConnectionModeEnum;
}

const CREDENTIALS_TO_SHOW = 5;

/*
 * This component is used to display the selected credentials in the enable/disable.
 * When the number of credentials is greater than 5, it will display the first 5 credentials
 * and a message indicating the number of credentials that are not shown.
 */
export function SelectedCredentialsList({ credentials, mode }: SelectedCredentialsListProps) {
  return (
    <>
      {credentials.map((credential, idx, arr) => {
        if (idx < CREDENTIALS_TO_SHOW) {
          return (
            <HStack key={credential.CredentialID} justifyContent="space-between">
              <Text>{credential.Label}</Text>
              {mode && (
                <HStack gap="spacingSmall">
                  <Icon icon={IconName.ArrowRight} />
                  <Text fontWeight="bold">{mode === ConnectionModeEnum.Up ? 'Enable' : 'Disable'}</Text>
                </HStack>
              )}
            </HStack>
          );
        }
        if (idx === arr.length - 1) {
          return (
            <VStack key={credential.CredentialID} alignItems="flex-start">
              <strong>+ {arr.length - CREDENTIALS_TO_SHOW} credentials</strong>
            </VStack>
          );
        }
        return null;
      })}
    </>
  );
}
