import { FieldValidationLevel, type FieldValidationRule, type NumericField } from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import { ORDER_SIDES } from 'tokens/order';
import type { SalesRFQState } from './types';

export const saleQuantityIsAccountedFor: FieldValidationRule<
  NumericField,
  WritableDraft<Pick<SalesRFQState, 'dependencies' | 'form'>>
> = (field, context) => {
  if (field.value !== '') {
    const { form } = context ?? {};
    if (form) {
      const { customerAccountField, customerField, rfqCurrencyField, sideField } = form;
      // We only care if we're trying to sell more quantity than what we own,
      // so only pay attention to ORDER_SIDES.SELL or ORDER_SIDES.TWOWAY
      if (sideField.value !== ORDER_SIDES.BUY && customerAccountField.value && customerField.value) {
        const { dependencies } = context ?? {};
        if (dependencies) {
          const { customerBalances } = dependencies;
          const balance = customerBalances?.find(b => {
            return (
              b.Counterparty === customerField.value?.Name &&
              // For 0 balances Back-end is sending down empty MarketAccount strings
              (b.MarketAccount === customerAccountField.value?.Name || b.MarketAccount === '') &&
              b.Currency === rfqCurrencyField.value
            );
          });
          // If we don't have an AvailableAmount value, *or* our field value is greater, show error
          if (!balance?.AvailableAmount || Number(field.value) > Number(balance.AvailableAmount)) {
            return {
              message: `${field.name} exceeds available amount to sell`,
              level: FieldValidationLevel.Warning,
            };
          }
        }
      }
    }
  }

  return null;
};
