import {
  ButtonVariants,
  EMPTY_ARRAY,
  FormControlSizes,
  HStack,
  Icon,
  IndicatorBadge,
  MixpanelEvent,
  MixpanelEventSource,
  Tooltip,
  useMixpanel,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { HeaderButton } from '../../../Header/styles';
import type { TradingControlsDialogProps } from './types';

export function TradingControlsDialogButton(props: TradingControlsDialogProps) {
  const { dialog, enabled, dialogInfo, loading } = props;
  const mixpanel = useMixpanel(MixpanelEventSource.TradingControls);

  const handleOpen = useCallback(() => {
    dialog.open();
    mixpanel.track(MixpanelEvent.TradingControlsOpenDialog);
  }, [dialog, mixpanel]);

  if (!enabled) {
    return null;
  }

  const warnings = dialogInfo?.warnings ?? EMPTY_ARRAY;
  const tooltip = warnings.length > 0 ? <TradingControlsDialogButtonTooltip warnings={warnings} /> : null;

  return (
    <HeaderButton
      border="left"
      onClick={handleOpen}
      size={FormControlSizes.Small}
      variant={ButtonVariants.Default}
      data-testid="header-button-trading-controls"
      loading={loading}
    >
      <Tooltip tooltip={tooltip} placement="bottom">
        <HStack gap="spacingSmall">
          Trading Controls
          <IndicatorBadge
            variant={dialogInfo?.variant}
            children={
              <HStack gap="spacingSmall" alignItems="flex-start">
                {dialogInfo?.iconName && <Icon icon={dialogInfo.iconName} />}
                {dialogInfo?.counter}
              </HStack>
            }
          />
        </HStack>
      </Tooltip>
    </HeaderButton>
  );
}

function TradingControlsDialogButtonTooltip({ warnings }: { warnings: string[] }) {
  return (
    <div>
      {warnings.map((warning, index) => (
        <span key={index}>
          {warning}
          <br />
        </span>
      ))}
    </div>
  );
}
