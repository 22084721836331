import { ConnectionStatusEnum, IconName, connectionStatusText, type FilterableProperty } from '@talos/kyoko';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for ConnectionStatus properties.
 *
 * @returns Filter Property definition to use for ConnectionStatus filters
 */
export const useConnectionStatusFilter = () => {
  return useMemo(
    () => ({
      ...({
        key: 'Statuses',
        label: 'Status',
        icon: IconName.StatusOnline,
        options: Object.values(ConnectionStatusEnum),
        getOptionLabel: (status: string) => connectionStatusText(status as ConnectionStatusEnum),
      } as const satisfies FilterableProperty),
    }),
    []
  );
};
