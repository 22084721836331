import { EMPTY_ARRAY, IconName, useCurrenciesContext, useObservableValue, type FilterableProperty } from '@talos/kyoko';
import { useMemo } from 'react';
import { of } from 'rxjs';
import { useFeatureFlag } from '../useFeatureFlag';
import { useRelevantCurrenciesObs } from '../useRelevantCurrencies';

/**
 * This hook creates the filter property definition for Relevant currencies.
 *
 * Relevant currencies are defined as all currencies represented in the users received set of balances,
 * and all currencies which have a MarketAccountCurrency assigned to it.
 *
 * This variant of currencies filtering was created to support a temporary implementation of Select All functionality.
 * We can only reasonably Select All currencies if there's fewer of them than usual, hence the "relevant" currencies bit.
 *
 * For performance reasons, we conditionally only perform a narrowing of all currencies -> relevant currencies if the org config
 * for enabling the Select All functionality is enabled.
 *
 * @returns Filter Property definition to use for Currency properties.
 */
export function useRelevantCurrenciesFilter() {
  const { currenciesList } = useCurrenciesContext();
  const { enableCurrenciesFilterSelectAll } = useFeatureFlag();
  const relevantCurrenciesObs = useRelevantCurrenciesObs();

  const allCurrenciesOptions: string[] = useMemo(() => {
    if (enableCurrenciesFilterSelectAll) {
      // no point in computing the options array for real since we know were not gonna use it
      return [];
    }

    return currenciesList.map(c => c.Symbol);
  }, [enableCurrenciesFilterSelectAll, currenciesList]);

  const relevantCurrenciesOptions: string[] = useObservableValue(
    () => (enableCurrenciesFilterSelectAll ? relevantCurrenciesObs : of(EMPTY_ARRAY)),
    [enableCurrenciesFilterSelectAll, relevantCurrenciesObs],
    EMPTY_ARRAY
  );

  const currencyOptions = enableCurrenciesFilterSelectAll ? relevantCurrenciesOptions : allCurrenciesOptions;

  // A somewhat arbitrary cap of 1000 currencies as the maximum we can send, and the feature flag has to be on to enable the feature.
  const allowSelectAll = currencyOptions.length <= 1000 && enableCurrenciesFilterSelectAll;

  return useMemo(
    () =>
      ({
        control: 'multi-select',
        key: 'Currencies',
        label: 'Currency',
        icon: IconName.CurrencyDollar,
        options: currencyOptions,
        getOptionLabel: option => option,
        clearUnsupportedSelectionsOnInit: false,
        allowSelectAll,
      } as const satisfies FilterableProperty),
    [currencyOptions, allowSelectAll]
  );
}
