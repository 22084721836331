import { MixpanelEvent } from '@talos/kyoko';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { TradingSettingCheckbox } from './TradingSettingCheckbox';

export function ExclusivePriceOnRePrimingCheckbox() {
  const { exclusivelyPrimePriceOnRePriming, setExclusivelyPrimePriceOnRePriming } = useTradingSettings();

  return (
    <TradingSettingCheckbox
      id="exclusivelyPrimePriceOnRePriming"
      isEnabled={exclusivelyPrimePriceOnRePriming}
      onChecked={setExclusivelyPrimePriceOnRePriming}
      mixPanelEvent={MixpanelEvent.ExclusivelyPrimePriceOnRePriming}
      toastDisplayName="Do not prime qty when re-priming rate in Order Form: "
      displayName="Do not prime qty when re-priming rate in Order Form"
      displayDescription="If enabled, the new order form will not re-prime the Qty field when the user re-primes the rate from a market card, deepdive, etc. This will not apply if the user primes for a different symbol."
    />
  );
}
