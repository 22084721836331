import {
  FormGroup,
  Input,
  NotificationVariants,
  SubAccountTypeEnum,
  logger,
  useDynamicCallback,
  useGlobalToasts,
  type ISubaccount,
  type SubAccount,
} from '@talos/kyoko';
import { useSubAccounts } from 'providers';
import { useCallback, useState } from 'react';
import { useSubAccountRollups } from '../../../../providers/SubAccountRollupsProvider';
import type { IEditSubAccountsDrawerTab } from '../types';

interface SubAccountGeneralTabProps {
  newSubAccountName: string;
  setNewSubAccountName: React.Dispatch<React.SetStateAction<string>>;
}

export function SubAccountGeneralTab({ newSubAccountName, setNewSubAccountName }: SubAccountGeneralTabProps) {
  return (
    <FormGroup label="Sub Account Display Name" mb="0" w="100%">
      <Input onChange={e => setNewSubAccountName(e.target.value)} value={newSubAccountName} />
    </FormGroup>
  );
}

export function useSubAccountGeneralTab({ subAccount }: { subAccount: SubAccount }): IEditSubAccountsDrawerTab {
  const { add: addToast } = useGlobalToasts();

  const { updateSubAccount } = useSubAccounts();
  const { updateSubAccountRollup } = useSubAccountRollups();

  const update = useDynamicCallback((account: Pick<ISubaccount, 'DisplayName' | 'SubaccountID'>) => {
    if (account.DisplayName) {
      const updateFunc = subAccount.Type === SubAccountTypeEnum.Book ? updateSubAccount : updateSubAccountRollup;

      updateFunc(account)
        .then(() => {
          addToast({
            text: `Sub account ${account.DisplayName} updated.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch(e => {
          logger.error(e);
          addToast({
            text: `Could not update sub account${e?.message ? `: ${e.message}` : ''}.`,
            variant: NotificationVariants.Negative,
          });
        });
    }
  });
  const [newSubAccountName, setNewSubAccountName] = useState<string>(subAccount.DisplayName || subAccount.Name);
  const isDirty = subAccount.DisplayName !== newSubAccountName;
  const handleSaveChanges = useCallback(async () => {
    if (isDirty) {
      update({ SubaccountID: subAccount.SubaccountID, DisplayName: newSubAccountName });
    }
  }, [isDirty, update, subAccount.SubaccountID, newSubAccountName]);

  return {
    name: 'General',
    save: handleSaveChanges,
    isDirty,
    viewable: true,
    component: (
      <SubAccountGeneralTab newSubAccountName={newSubAccountName} setNewSubAccountName={setNewSubAccountName} />
    ),
  };
}
