import {
  ConnectionType,
  ModeEnum,
  OrdTypeEnum,
  bigMax,
  toBigWithDefault,
  type Customer,
  type CustomerConfiguration,
  type CustomerOrder,
  type Market,
  type MarketAccount,
  type Order,
  type ParameterLike,
  type Security,
} from '@talos/kyoko';
import { compact, sortBy } from 'lodash';
import { isOrderTypeLimitAllIn } from '../../utils/security';

export function getOrderAndRFQMarketAccounts(
  securityMarkets: string[],
  testMarket: (m: Market | string, connectionType: ConnectionType) => boolean,
  marketAccountsByMarket: Map<string, MarketAccount[]>
): { orderMarketAccounts: string[]; rfqMarketAccounts: string[] } {
  const orderMarketAccounts = securityMarkets
    .filter(m => testMarket(m, ConnectionType.Orders))
    .flatMap(
      marketName => sortBy(marketAccountsByMarket.get(marketName)?.slice(), item => item.MarketAccountID)[0]?.Name
    )
    .filter((marketAccountName?: string): marketAccountName is string => marketAccountName != null) as string[];
  const rfqMarketAccounts = securityMarkets
    .filter(m => testMarket(m, ConnectionType.RFQ))
    .flatMap(
      marketName => sortBy(marketAccountsByMarket.get(marketName)?.slice(), item => item.MarketAccountID)[0]?.Name
    )
    .filter((marketAccountName?: string): marketAccountName is string => marketAccountName != null) as string[];
  return { orderMarketAccounts, rfqMarketAccounts };
}

export function mapOrderToCustomerOrder(order: Order): CustomerOrder {
  const customerOrder = {
    ...order,
    // Remember: The ParentOrderID in Order is the OrderID in CustomerOrder
    OrderID: order.ParentOrderID || '',
    PricingParameters: order.customerPricingParams,
    Counterparty: order.customerOrder || '',
    MarketAccount: order.customerAccount || '',
    OrderQty: order.customerQuantity || '',
    Price: order.customerPrice || '',
    AvgPx: order.customerAvgPx || '',
    CustomerUser: '',
    AmountCurrency: order.AmountCurrency || '',
    CumAmt: order.CumAmt || '',
    ExecID: order.ExecID || '',
    LeavesQty: order.LeavesQty || '',
    Strategy: order.customerStrategy || order.Strategy,
    // Sales Order ETH-BTC translates to ETH-BTC:USD-2 in Principal land but remains as ETH-BTC in dealer
    Symbol: order.customerSymbol || order.Symbol,
  };

  return customerOrder;
}

export function getNormalizedOrderQty(
  security: Security | undefined,
  orderCurrency: string | undefined,
  size?: string
): string {
  if (!orderCurrency) {
    // number of Contracts
    // For some security the NormalSize is less than MinimumSize; in these case use MinimumSize
    const initialSize = bigMax(security?.MinimumSize, security?.NormalSize, '1');
    return size || initialSize?.toFixed() || '';
  } else {
    return toBigWithDefault(security?.NotionalMultiplier, 1)
      .times(size || security?.NormalSize || 1)
      .toFixed();
  }
}

export const getOrdType = (showAllInPrices, security, strategy): OrdTypeEnum => {
  if (strategy?.Name === 'Market') {
    return OrdTypeEnum.Market;
  }
  // OrdTypes are sent in StrategyParams as a TopLevelHidden just so we read the value and send it back
  const beOrdTypeParamHack: ParameterLike | undefined = strategy?.Parameters.find(p => p.Name === 'OrdType');
  if (beOrdTypeParamHack) {
    return beOrdTypeParamHack.DefaultValue as OrdTypeEnum;
  }
  return isOrderTypeLimitAllIn(showAllInPrices, security) ? OrdTypeEnum.LimitAllIn : OrdTypeEnum.Limit;
};

/* If Customer Configuration Filtering is enabled, we should only take the enabled customer configurations,
   then filter out any disabled customer pricing rules. Return the filtered customers list. */
export const getSelectableCustomers = (
  enabledCustomers: Customer[],
  customerConfigurations: CustomerConfiguration[]
) => {
  if (enabledCustomers.length === 0) {
    // Customers weren't loaded yet
    return [];
  }

  if (customerConfigurations.length === 0) {
    // Customer configurations weren't loaded yet, or are not being used.
    return enabledCustomers;
  }

  const enabledCustomersMapByName = new Map(enabledCustomers.map(c => [c.Name, c]));
  // Return only the customers that have enabled configurations
  return compact(
    customerConfigurations.map(cc => {
      // If the customer configuration is disabled, return null
      if (cc.Mode === ModeEnum.Disabled) {
        return null;
      }
      // Find the not disabled customer that matches the configuration
      const foundCustomerForConfiguration = enabledCustomersMapByName.get(cc.Counterparty);
      if (foundCustomerForConfiguration == null) {
        return null;
      }

      // Return the found customer
      return foundCustomerForConfiguration;
    })
  );
};
