import { Box, Flex, HStack } from '@talos/kyoko';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const MarketAccountListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.gray['050']}`};
  padding-bottom: ${({ theme }) => theme.spacingTiny}px;

  div:last-of-type {
    margin-left: auto;
  }
`;

export const ListWrapper = styled.div<{ scrollContent: boolean }>`
  ${({ scrollContent }) =>
    scrollContent
      ? `
    overflow-y: auto;
    flex: 1 1 0;
    `
      : ''}

  p {
    margin: ${({ theme }) => theme.spacingSmall}px 0;
  }

  ${MarketAccountListHeader} {
    margin-top: ${({ theme }) => theme.spacingDefault}px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const MarketAccountRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: ${({ theme }) => theme.spacingSmall}px 0;
  position: relative;

  :not(:last-child)&::before {
    content: '';
    position: absolute;
    z-index: 0;
    width: 2px;
    top: ${({ theme }) => theme.baseSize - 1}px;
    bottom: ${({ theme }) => -theme.baseSize * 0.3125}px;
    left: ${({ theme }) => theme.baseSize * 0.5 - 1}px;
    background: ${({ theme }) => theme.borderColorCheckbox};
  }
`;

export const MarketAccountName = styled.div`
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

export const MarketAccountBalances = styled.div`
  flex: 1 0 auto;
  text-align: right;
`;

export const Currency = styled.span`
  padding-left: 3px;
  color: ${({ theme }) => theme.colors.gray['070']};
`;

export const CheckboxTitle = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray['100']};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export const LimitWarning = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacingTiny}px;
  margin: ${({ theme }) => `${theme.spacingTiny}px 0px ${theme.spacingSmall}px`};
  color: ${({ theme }) => theme.colors.yellow.lighten};
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
`;

export const MarketAccountGroupWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacingTiny}px 0`};
`;

export const MarketAccountGroup = styled.div``;

export const MarketName = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  color: ${({ theme }) => theme.colorTextSubtle};
`;

// SelectionGrid styles
export const MarketAccountCheckbox = styled.div`
  margin: ${({ theme }) => `${theme.spacingSmall}px`} 0;
  position: relative;
`;

export const BodyText = styled(Flex)<{ selected: boolean }>`
  align-items: center;
  color: ${({ theme, selected }) => (selected ? `${theme.colorTextImportant}` : `${theme.colorTextDefault}`)};
  gap: ${({ theme }) => theme.spacingDefault}px;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  text-align: right;
`;

export const MarketsHeader = styled(HStack)`
  color: ${({ theme }) => theme.colorTextAttention};
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.gray['030']}`};
`;

MarketsHeader.defaultProps = {
  p: 'spacingComfortable',
  justifyContent: 'flex-start',
  gap: 'spacingDefault',
  w: '100%',
};

export const MarketsWrapper = styled.div`
  height: 100%;
  width: 100%;

  .ag-floating-top {
    border: 0;
  }

  .ag-row {
    .ag-cell:first-child {
      padding-left: 12px;
    }

    &.in-group {
      .ag-cell {
        border-top: 0;
        border-bottom: 0;
        line-height: 2;
      }
    }

    .ag-row-group-leaf-indent {
      margin-left: 0;
    }

    &.in-group:not(.last-in-group) {
      border-bottom: 0;

      ${MarketAccountCheckbox}::before {
        content: '';
        position: absolute;
        width: 2px;
        top: ${({ theme }) => theme.baseSize + 6}px;
        bottom: ${({ theme }) => -theme.baseSize * 0.3125}px;
        left: ${({ theme }) => theme.baseSize * 0.5 - 1}px;
        background: ${({ theme }) => theme.borderColorCheckbox};
      }
    }

    &.in-group:not(.first-in-group) {
      ${MarketAccountCheckbox}::after {
        content: '';
        position: absolute;
        width: 2px;
        bottom: ${({ theme }) => theme.baseSize + 4}px;
        top: ${({ theme }) => -theme.baseSize * 0.3125}px;
        left: ${({ theme }) => theme.baseSize * 0.5 - 1}px;
        background: ${({ theme }) => theme.borderColorCheckbox};
      }
    }
  }
`;
