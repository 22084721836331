import {
  NotificationVariants,
  useDynamicCallback,
  useGlobalToasts,
  useOnKeyDown,
  type CustomerUser,
  type DrawerProps,
} from '@talos/kyoko';
import { useFeatureFlag } from 'hooks';
import { useCustomerUsersByID } from 'hooks/useCustomer';
import { useEffect, useMemo, useRef } from 'react';
import { useCustomerApiKeys } from './CustomerApiKeys/useCustomerApiKeys';
import { useCustomerUserDetails } from './CustomerUserDetails/useCustomerUserDetails';
import type { Warning } from './CustomerUserPermissions/types';
import { useCustomerUserPermissions } from './CustomerUserPermissions/useCustomerUserPermissions';
import { isLoading, isSaving } from './state/drawer/drawerSlice';
import { useCustomerUserDispatch, useCustomerUserSelector } from './state/hooks';
import { selectIsDrawerDirty, selectTabDefs } from './state/store';

export type CustomerUserDetailsDrawerProps = {
  customerUserID?: string;
  onClose: () => void;
};

export type NewUserDrawerProps = DrawerProps & CustomerUserDetailsDrawerProps;

export function useCustomerUserDetailsDrawer(props: NewUserDrawerProps & { onWarning: (warning: Warning) => void }) {
  const state = useCustomerUserSelector(state => state);
  const customerUsersByID = useCustomerUsersByID();
  const { add: addToast } = useGlobalToasts();
  const dispatch = useCustomerUserDispatch();

  const customerDetails = useCustomerUserDetails();
  const apiKeys = useCustomerApiKeys();
  const permissions = useCustomerUserPermissions();

  const customerUser = useMemo(
    () => (props.customerUserID ? customerUsersByID?.get(props.customerUserID) : undefined),
    [props.customerUserID, customerUsersByID]
  );

  const userSettingsRef = useRef<HTMLInputElement>(null);

  const loadCustomerAndFocus = useDynamicCallback(async (customerUser?: CustomerUser) => {
    dispatch(isLoading(true));
    await customerDetails.loadCustomerUser(customerUser);
    dispatch(isLoading(false));
    if (!customerUser) {
      // doesn't always focus without the timeout
      setTimeout(() => userSettingsRef.current?.focus());
    }
  });

  useEffect(() => {
    loadCustomerAndFocus(customerUser);
  }, [customerUser, loadCustomerAndFocus]);

  const closeDrawer = useDynamicCallback(() => {
    props.close();
    props.onClose();
  });
  const { enableCustomerAccountRestrictions } = useFeatureFlag();
  const isDrawerDirty = useCustomerUserSelector(state => selectIsDrawerDirty(state, enableCustomerAccountRestrictions));

  const handleClose = useDynamicCallback(() => {
    if (!props.isOpen) {
      // this component stays mounted even when closed so we don't want the esc key to continue to show this confirmation window
      return;
    }

    if (isDrawerDirty) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        closeDrawer();
      }
    } else {
      closeDrawer();
    }
  });

  const onSave = useDynamicCallback(async () => {
    try {
      dispatch(isSaving(true));
      await Promise.all([
        customerDetails.saveCustomerUser().then(customerUserId => permissions.savePermissions(customerUserId)),
        apiKeys.saveApiKeys(),
      ]);
      addToast({
        text: 'Customer user saved.',
        variant: NotificationVariants.Positive,
      });

      // if we were editing an existing user, we close the drawer otherwise we clear it but leave it open for convenient creation of many users
      if (state.userDetails.customerUserId) {
        closeDrawer();
      } else {
        loadCustomerAndFocus();
      }
    } catch (e) {
      // if something went wrong we don't want to close the drawer so any issues can be resolved and retried
      addToast({
        text: `Could not save customer user: ${e?.toString()}`,
        variant: NotificationVariants.Negative,
      });
    } finally {
      dispatch(isSaving(false));
    }
  });

  const handleSave = useDynamicCallback(async () => {
    // for each write permission, there must be a read permission for the same account, unless there are no read permissions in which case there is an implicit read permission for the account
    const readPermissions = state.permissions.customerAccounts.filter(p => p.permissions.includes('read'));
    if (
      state.permissions.customerAccounts.length === 0 ||
      readPermissions.length === 0 ||
      !state.permissions.customerAccounts.find(p => p.permissions.includes('write') && !p.permissions.includes('read'))
    ) {
      onSave();
    } else {
      props.onWarning({
        message: `Trade permission does not include View permissions for an account. ${
          customerUser?.DisplayName ?? 'New user'
        } will not be able to see orders or trades for this account. Do you want to proceed?`,
        onOk: onSave,
      });
    }
  });

  useOnKeyDown({ key: 'Escape' }, handleClose);

  const tabDefs = useCustomerUserSelector(state => selectTabDefs(state, enableCustomerAccountRestrictions));

  const isSaveDisabled = !isDrawerDirty || state.drawer.isSaving;

  return {
    handleClose,
    handleSave,
    userSettingsRef,
    customerUser,
    tabDefs,
    isSaveDisabled,
    isLoading: state.drawer.isLoading,
  };
}
