import {
  Box,
  FormControlSizes,
  FuseAutocompleteResult,
  HStack,
  Icon,
  IconButton,
  IconName,
  MixpanelEvent,
  SearchSelect,
  useDynamicCallback,
  type OrderStrategy,
  type RenderResultFunc,
  type SearchSelectProps,
  type Security,
} from '@talos/kyoko';
import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo, useState } from 'react';
import { useOrderPresets, type IOrderPreset } from '../../providers/OrderPresetsContext';
import { useStrategies } from '../../providers/StrategiesProvider';
import { isStrategySupportedBySecurity } from '../../utils/security';

const labelGetter = (value: IOrderPreset) => value.name;

export interface IOrderPresetSearchSelectProps extends Omit<SearchSelectProps<IOrderPreset>, 'getLabel' | 'options'> {
  showNavigation?: boolean;
  options: IOrderPreset[];
  onToggleFavorite?: (orderPreset: IOrderPreset) => void;
}

export function OrderPresetSearchSelect({
  options: _options,
  showNavigation = false,
  onToggleFavorite,
  ...props
}: IOrderPresetSearchSelectProps) {
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const options = useMemo(
    () => _options.filter(option => (showOnlyFavorites ? option.isFavorite : true)),
    [showOnlyFavorites, _options]
  );
  const favoriteOptions = useMemo(() => options.filter(option => option.isFavorite), [options]);

  const toggleFavorite = useDynamicCallback((orderPreset: IOrderPreset) => {
    onToggleFavorite?.(orderPreset);
    mixpanel.track(MixpanelEvent.FavoriteOrderPreset);
  });

  const renderFavoriteIcon = useCallback(
    (orderPreset: IOrderPreset) => {
      return (
        <Icon
          icon={orderPreset.isFavorite ? IconName.StarSolid : IconName.Star}
          color={orderPreset.isFavorite ? 'yellow.lighten' : 'gray.080'}
          size="fontSizeLarge"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            toggleFavorite(orderPreset);
          }}
          p="spacingComfortable"
          spaceBefore="-spacingComfortable"
        />
      );
    },
    [toggleFavorite]
  );

  const renderResult: RenderResultFunc<IOrderPreset> = useDynamicCallback((searchResults, disabled) =>
    FuseAutocompleteResult(searchResults, disabled, renderFavoriteIcon)
  );

  const navigableOptions = favoriteOptions.length ? favoriteOptions : options;
  const currentIndex = navigableOptions.findIndex(preset => preset.id === props.selection?.id);
  const nextIndex = (currentIndex + 1) % navigableOptions.length;
  const prevIndex = (currentIndex - 1 + navigableOptions.length) % navigableOptions.length;

  return (
    <HStack>
      {showNavigation && options.length > 0 && (
        <IconButton
          icon={IconName.ChevronLeft}
          size={props.size}
          onClick={() => prevIndex !== currentIndex && props.onChange(navigableOptions.at(prevIndex))}
          className="order-presets-prev"
        />
      )}
      <SearchSelect
        searchPrefix={
          <Box ml="-spacingSmall">
            <IconButton
              ghost
              onClick={() => setShowOnlyFavorites(!showOnlyFavorites)}
              icon={showOnlyFavorites ? IconName.StarSolid : IconName.Star}
              color={showOnlyFavorites ? 'yellow.lighten' : 'gray.080'}
              className="toggle-favorites"
            />
          </Box>
        }
        prefix={
          <Icon icon={IconName.Preferences} color={props.selection ? 'colors.orange.lighten' : 'colors.gray.080'} />
        }
        placeholder={options.length ? 'Select a preset' : 'No preset available'}
        dropdownSize={FormControlSizes.Default}
        w="100%"
        options={options}
        dropdownWidth={300}
        getLabel={labelGetter}
        showClear={true}
        portalize={true}
        disabled={options.length === 0}
        // We do sorting ourselves as we need to consider favorites
        initialSortByLabel={false}
        renderResult={renderResult}
        style={{ lineHeight: 1 }}
        {...props}
      />
      {showNavigation && options.length > 0 && (
        <IconButton
          icon={IconName.ChevronRight}
          size={props.size}
          onClick={() => nextIndex !== currentIndex && props.onChange(navigableOptions.at(nextIndex))}
          className="order-presets-next"
        />
      )}
    </HStack>
  );
}

export function useOrderPresetSearchSelectOptions({ security }: { security: Security | undefined }) {
  const { orderPresetsList } = useOrderPresets();
  const { strategiesList } = useStrategies();

  const options = useMemo(
    () => filterOrderPresets(orderPresetsList, { security, strategies: strategiesList }),
    [strategiesList, security, orderPresetsList]
  );

  return options;
}

export function filterOrderPresets(
  orderPresets: IOrderPreset[],
  {
    security,
    strategies,
  }: {
    security: Security | undefined;
    strategies: OrderStrategy[];
  }
) {
  const filteredStrategies = strategies
    .filter(strat => isStrategySupportedBySecurity(strat, security))
    .map(strat => strat.Name);

  return orderPresets
    .filter(preset => filteredStrategies.includes(preset.strategy))
    .sort((a, b) => {
      if (a.isFavorite && !b.isFavorite) {
        return -1;
      }
      if (!a.isFavorite && b.isFavorite) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
}
