import {
  InlineFormattedNumber,
  Table,
  TableSize,
  Tbody,
  Td,
  Text,
  Th,
  Tooltip,
  Tr,
  prettyName,
  useMarketAccountsContext,
  useMarketDataStatistics,
  useMarketsContext,
  useObservableValue,
  useSecurity,
  type Market,
  type MarketDataStatistics,
  type TableProps,
} from '@talos/kyoko';
import Big from 'big.js';
import { useBalances } from 'hooks/useBalances';
import { useEffect, useMemo, useState } from 'react';
import { DELEVERAGE_QUEUE, OPEN_POSITION } from 'tokens/marketDataStats';
import { MARKET_DATA_STATS } from 'tokens/tooltips';
import type { BalancesFilter } from '../../types';
import { DeleverageQueueIndicator } from './DeleverageQueueIndicator';
import { FormatValue } from './FormatValue';
import { BLACKLISTED_KEYS } from './consts';
import { StatsWrapper } from './styles';
import { prettify } from './utils';

export interface ContractDetailsProps {
  symbol: string;
  selectedMarketAccounts: string[];
  tag: string;
}
export const ContractDetails = ({ symbol, selectedMarketAccounts, tag, size }: ContractDetailsProps & TableProps) => {
  const security = useSecurity(symbol);
  const { marketsByName } = useMarketsContext();
  const { marketAccountsByName } = useMarketAccountsContext();

  const balancesFilter: BalancesFilter = useMemo(
    () => ({
      MarketAccounts: [selectedMarketAccounts[0]],
    }),
    [selectedMarketAccounts]
  );
  const { balancesByCurrencyMarketAccountIDObs } = useBalances({
    filter: balancesFilter,
    tag: 'Contract Details',
    openStreamWithoutFilter: false,
  });
  const balancesByCurrencyMarketAccountID = useObservableValue(
    () => balancesByCurrencyMarketAccountIDObs,
    [balancesByCurrencyMarketAccountIDObs]
  );
  const { QuoteCurrency } = security || {};

  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);
  useEffect(() => {
    setSelectedMarkets(
      selectedMarketAccounts
        .map(maName => marketsByName.get(maName))
        .filter<Market>((maybeMarket): maybeMarket is Market => maybeMarket !== undefined)
        .map<string>(market => market.Name)
    );
  }, [selectedMarketAccounts, marketsByName]);

  // Map our received market account names to market account ids for balance lookups
  const selectedMarketAccountIDs = selectedMarketAccounts.flatMap(
    marketAccountName => marketAccountsByName.get(marketAccountName)?.MarketAccountID || []
  );

  const { marketDataStatistics } = useMarketDataStatistics(symbol, selectedMarkets, tag);

  // This doesnt work!!!!!!! And i dont think it has worked for a long time. There's several things wrong... Also it doesnt even make sense
  // to check balances for contracts... they would be positions in that case, BalanceDelta stream only returns
  // spot positions, which wouldnt have contract details.......
  const balancesByMarketAccountID = balancesByCurrencyMarketAccountID?.get(selectedMarketAccounts[0]);
  const balance =
    balancesByMarketAccountID && selectedMarketAccountIDs.length > 0
      ? balancesByMarketAccountID.get(selectedMarketAccountIDs[0]) // TODO why are there multiple selected market accounts in this control?
      : undefined;

  if (!marketDataStatistics || !security) {
    return <Text color="colorTextSubtle">Details Unavailable</Text>;
  }

  const queuePosition = marketDataStatistics[DELEVERAGE_QUEUE];

  // When we support trading and balances for BitMEX we can show additional info if the user has a position
  // We'll have to reduce row margin then.
  const hasPosition = Big(balance?.AvailableAmount || '0').gt(0);

  return (
    <StatsWrapper>
      <Table size={size || TableSize.Small}>
        {hasPosition && ( // TODO this part never shows and hasnt for some time!
          <Tbody>
            <Tr>
              <Th>
                {MARKET_DATA_STATS[OPEN_POSITION] ? (
                  <Tooltip tooltip={MARKET_DATA_STATS[OPEN_POSITION]}>{prettyName(OPEN_POSITION)}</Tooltip>
                ) : (
                  prettyName(OPEN_POSITION)
                )}
              </Th>
              <Td align="right">
                <InlineFormattedNumber
                  number={`${balance?.AvailableAmount}` || '0'}
                  currency={QuoteCurrency}
                  align="right"
                />
              </Td>
            </Tr>
            <Tr>
              <Th>
                {MARKET_DATA_STATS[DELEVERAGE_QUEUE] ? (
                  <Tooltip tooltip={MARKET_DATA_STATS[DELEVERAGE_QUEUE]}>{prettyName(DELEVERAGE_QUEUE)}</Tooltip>
                ) : (
                  prettyName(DELEVERAGE_QUEUE)
                )}
              </Th>
              <Td align="right">
                <DeleverageQueueIndicator value={queuePosition} />
              </Td>
            </Tr>
          </Tbody>
        )}
        <Tbody>
          {/* TODO: check keys against actual enums to improve type-safety, or highlight type-wise if it's impossible */}
          {(Object.keys(marketDataStatistics) as Array<keyof MarketDataStatistics>).map(key => {
            return (
              !BLACKLISTED_KEYS.includes(key) && (
                <Tr key={key}>
                  <Th>
                    {MARKET_DATA_STATS[key] ? (
                      <Tooltip tooltip={MARKET_DATA_STATS[key]}>{prettify(key)}</Tooltip>
                    ) : (
                      prettify(key)
                    )}
                  </Th>
                  <Td align="right">
                    <FormatValue {...{ marketDataStatistics, marketDataKey: key, security, marketsByName }} />
                  </Td>
                </Tr>
              )
            );
          })}
        </Tbody>
      </Table>
    </StatsWrapper>
  );
};
