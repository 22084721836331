import { Card, EmptyView, Flex, LoaderTalos, type CardProps, type Order } from '@talos/kyoko';
import type { useLatestOrderAnalytics } from 'hooks/useLatestOrderAnalytics';
import { useTheme } from 'styled-components';
import { TradingCardContent } from './TradingCardContent';

export function TradingCard({
  order,
  isMultileg,
  analytics,
  ...props
}: { order: Order; isMultileg?: boolean; analytics: ReturnType<typeof useLatestOrderAnalytics> } & CardProps) {
  const { latestAnalytics, receivedAnalytics } = analytics;
  const { spacingHuge } = useTheme();
  if (latestAnalytics == null) {
    return (
      <Card title="Trading" {...props}>
        <Flex justifyContent="center" alignItems="center" minHeight={spacingHuge * (isMultileg ? 4 : 2)}>
          {receivedAnalytics ? <EmptyView>No trading analytics available</EmptyView> : <LoaderTalos />}
        </Flex>
      </Card>
    );
  }

  return (
    <Card title="Trading" {...props}>
      {isMultileg ? (
        <div>
          <TradingCardContent order={order} leg={1} latestAnalytics={latestAnalytics} />
          <TradingCardContent order={order} leg={2} latestAnalytics={latestAnalytics} />
        </div>
      ) : (
        <TradingCardContent order={order} latestAnalytics={latestAnalytics} />
      )}
    </Card>
  );
}
