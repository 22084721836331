import { FormControlSizes } from '@talos/kyoko';
import styled, { css } from 'styled-components';

export const LABEL_WIDTH = 80;

export const INPUT_SIZES = {
  DEFAULT: 25,
};

export const INPUT_VARIANTS = {
  DEFAULT: 'DEFAULT',
  NEGATIVE: 'NEGATIVE',
  WARNING: 'WARNING',
};

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  color: ${({ color, theme }) => color || theme.colors.red.lighten};
  text-transform: uppercase;
  padding: ${({ theme }) => `${theme.spacingTiny}px 0 ${theme.spacingSmall}px 0`};
  word-break: break-word;

  svg {
    display: block;
    min-width: 15px !important;
    margin-right: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  color: ${({ theme }) => theme.colors.red.lighten};
  text-transform: uppercase;
  position: absolute;
`;

export const FormLabel = styled.label<{ isSmall?: boolean; disabled?: boolean }>`
  color: ${({ color, theme }) => color || theme.colors.gray['100']};
  flex-shrink: 0;

  ${({ isSmall, theme }) =>
    isSmall
      ? `
    font-size: ${theme.fontSizeSmall}rem;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    text-transform: uppercase;
    `
      : `
      font-size: ${theme.fontSizeDefault}rem;
      font-weight: ${({ theme }) => theme.fontWeightMedium};
      `}

  ${p => p.disabled && `color: ${p.theme.colors['070']}`}
`;

const invalidState = css`
  background: inherit;
  ${({ theme }) => `
    :not(:disabled) {
      border-color: ${theme.colors.red.lighten};
    }
  `}
`;

const input = css`
  padding: ${({ theme }) => theme.spacingSmall}px;
  background: ${({ theme }) => theme.colors.black.mute};
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray['070']};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  width: 100%;
  height: ${INPUT_SIZES.DEFAULT}px;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  color: ${({ theme }) => theme.colors.gray['090']};
  transition: border-color 300ms ease;
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.gray['080']};
  }
  ::-webkit-inner-spin-button {
    display: none;
  }
  &:disabled {
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
  &:not(:read-only):not(:disabled),
  select:not(:disabled) {
    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.gray['090']};
    }
    &:hover {
      border-color: ${({ theme }) => theme.colors.gray['090']};
    }
  }
  &[type='number'] {
    text-align: right;
  }
  &:invalid,
  &.invalid {
    ${invalidState}
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray['060']};
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;

export const TextArea = styled.textarea<{ touched?: boolean; invalid?: boolean }>`
  ${input};
  resize: vertical;
  height: auto;
  min-height: ${INPUT_SIZES.DEFAULT}px;

  ${({ touched, invalid }) =>
    touched &&
    `
    &:invalid {
      ${invalidState}
    }

    ${invalid && invalidState}
  `}
`;

export const FormRow = styled.div<{ align?: string }>`
  margin-bottom: ${({ theme }) => theme.spacingDefault}px;
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  flex-direction: row;

  &:first-child {
    margin-top: ${({ theme }) => theme.spacingDefault}px;
  }

  ${FormLabel} {
    min-width: 30%;
    padding-right: ${({ theme }) => theme.spacingLarge}px;
    flex: 0;
  }

  ${TextArea} {
    width: 70%;
    flex: 1;
  }

  ${ErrorMessage} {
    width: 70%;
    margin-left: 30%;
  }
`;

export const Wrapper = styled.label<{ isDisabled: boolean }>`
  ${p =>
    p.isDisabled &&
    `
    opacity: 0.4;
    &:hover,
    &:hover input:disabled {
      cursor: not-allowed
    }
  `}
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
`;

export const Box = styled.label``;

export const CheckBoxLabel = styled.label<{ isChecked: boolean }>`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacingSmall}px;
  color: ${props => (props.isChecked ? props.theme.colors.gray['100'] : props.theme.colors.gray['080'])};
  transition: color 0.25s ease;
  white-space: nowrap;
  line-height: 1;
`;

export const Currency = styled.div`
  margin: 0 0 0 ${({ theme }) => theme.spacingDefault}px;
`;

export const FormText = styled.p`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  color: ${({ theme }) => theme.colors.gray['100']};
`;

export const FormHeader = styled.h3`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray['070']}`};
  padding-bottom: ${({ theme }) => theme.spacingSmall}px;
  margin: ${({ theme }) => theme.spacingBig}px 0 ${({ theme }) => theme.spacingDefault}px;
`;

export const AutocompleteWrapper = styled.div<{ isCentered: boolean }>`
  flex: 1;

  div {
    width: 100%;
    position: relative;
  }

  ${props =>
    props.isCentered &&
    `
		margin-left: auto;
		margin-right: auto;
  `}

  input {
    padding: 0 ${({ theme }) => theme.spacingDefault}px;
    line-height: ${({ theme }) => theme.baseSize * FormControlSizes.Default - 2}px;
    background: ${({ theme }) => theme.backgroundInput};
    border: 1px solid ${({ theme }) => theme.colors.gray['050']};
    font-size: ${({ theme }) => theme.fontSizeDefault}rem;
    color: ${({ theme }) => theme.colors.gray['100']};
    border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
    outline: none;
    width: 100%;

    &:focus {
      box-shadow: none;
    }

    + div {
      position: absolute;
      max-height: 150px;
      left: 0 !important;
      right: 0 !important;
      top: 100% !important;
      border: 1px solid ${({ theme }) => theme.colors.gray['050']};
      background: ${({ theme }) => theme.colors.gray['030']};
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
      border-top: 0;
      overflow-y: auto;
      z-index: 1;
    }
  }
`;

export const AutocompleteResults = styled.div<{ isHighlighted: boolean }>`
  padding: 3px ${({ theme }) => theme.spacingSmall}px;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  ${props =>
    props.isHighlighted &&
    `
		background: ${props.theme.colors.gray['070']};
		cursor: pointer;
	`}
  color: ${({ theme }) => theme.colors.gray['100']};
`;

export const Underline = styled.span`
  display: inline-block;
  padding-bottom: 1px;
  margin-bottom: -2px;
  border-bottom: dotted 1px ${({ theme }) => theme.colors.gray['060']};
  cursor: help;
`;
