import { Flex, FormControlSizes, IconName, Popover, usePopoverState } from '@talos/kyoko';
import type { DockviewApi, DockviewGroupPanel, IDockviewHeaderActionsProps } from 'dockview';
import { v1 as uuid } from 'uuid';
import { TabIconButton, TabTypeButton } from '../styles';
import type { TabComponentSpec } from '../types';

function addPanel(api: DockviewApi, tab: TabComponentSpec, group: DockviewGroupPanel) {
  const panelCount = api.panels.filter(p => p.view.contentComponent === tab.type).length;
  api.addPanel({
    id: uuid(),
    title: panelCount > 0 ? `${tab.label} ${panelCount + 1}` : tab.label,
    component: tab.type,
    position: {
      referenceGroup: group,
    },
  });
}

export function CreateAddTabComponent(availablePanels: TabComponentSpec[]): React.FC<IDockviewHeaderActionsProps> {
  const NewComponent = (dockViewProps: IDockviewHeaderActionsProps) => {
    const popover = usePopoverState({ placement: 'bottom-start', trigger: 'click', closeOnClickOutside: true });
    const onClickTabType = (tab: TabComponentSpec) => {
      popover.close();
      addPanel(dockViewProps.containerApi, tab, dockViewProps.group);
    };
    return (
      <Popover {...popover}>
        <TabIconButton ghost size={FormControlSizes.Small} icon={IconName.Plus} />
        <Flex flexDirection="column" gap="spacingSmall">
          {availablePanels.map(tab => (
            <TabTypeButton
              key={tab.type}
              onClick={() => onClickTabType(tab)}
              endIcon={IconName.ArrowRightCircle}
              dim={true}
            >
              {tab.label}
            </TabTypeButton>
          ))}
        </Flex>
      </Popover>
    );
  };
  return NewComponent;
}
