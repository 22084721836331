import {
  HEDGE_ORDER_STATUS,
  useObservable,
  useStaticSubscription,
  wsSubscriptionCache,
  type HedgeOrderStatus,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import type { Observable } from 'rxjs';

export interface HedgeOrderStatusContextProps {
  hedgeOrderStatusObs: Observable<MinimalSubscriptionResponse<HedgeOrderStatus>>;
}

export const HedgeOrderStatusesContext = createContext<HedgeOrderStatusContextProps | undefined>(undefined);
HedgeOrderStatusesContext.displayName = 'HedgeOrderStatusContext';

export function useHedgeOrderStatuses(): HedgeOrderStatusContextProps {
  const context = useContext(HedgeOrderStatusesContext);
  if (!context) {
    throw new Error('Missing HedgeOrderStatus.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const HedgeOrderStatusesProvider = function HedgeOrderStatusProvider(props: PropsWithChildren<unknown>) {
  const { data: subscription } = useStaticSubscription<HedgeOrderStatus>({
    name: HEDGE_ORDER_STATUS,
    tag: 'HedgeOrderStatusProvider',
  });

  const hedgeOrderStatusCache = useObservable(
    () => subscription.pipe(wsSubscriptionCache(hos => hos.InitiatingOrderID, 'HedgeOrderStatus')),
    [subscription]
  );

  const value = useMemo<HedgeOrderStatusContextProps>(() => {
    return { hedgeOrderStatusObs: hedgeOrderStatusCache };
  }, [hedgeOrderStatusCache]);

  return <HedgeOrderStatusesContext.Provider value={value}>{props.children}</HedgeOrderStatusesContext.Provider>;
};
