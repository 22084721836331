import { configureStore } from '@reduxjs/toolkit';
import { apiKeysSlice, isApiKeysTabDirty } from './apiKeys/apiKeysSlice';
import { drawerSlice } from './drawer/drawerSlice';
import { isPermissionsTabDirty, permissionsSlice } from './permissions/permissionsSlice';
import { userDetailsSlice } from './userDetails/userDetailsSlice';

export function getCustomerUserStore() {
  return configureStore({
    reducer: {
      drawer: drawerSlice.reducer,
      userDetails: userDetailsSlice.reducer,
      apiKeys: apiKeysSlice.reducer,
      permissions: permissionsSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
  });
}

export interface TabDef {
  label: 'General' | 'Account Restrictions' | 'API Keys';
  isDirty: boolean;
}
export interface TabDefs {
  general: TabDef;
  permissions: TabDef;
  apiKeys: TabDef;
}

export const selectTabDefs = (state: CustomerUserState, enableCustomerAccountRestrictions: boolean): TabDef[] => {
  const tabDefs: TabDef[] = [
    {
      label: 'General',
      isDirty: state.userDetails.isDirty,
    },
  ];

  if (enableCustomerAccountRestrictions) {
    tabDefs.push({
      label: 'Account Restrictions',
      isDirty: isPermissionsTabDirty(state.permissions),
    });
  }

  if (state.userDetails.customerUserId) {
    tabDefs.push({
      label: 'API Keys',
      isDirty: isApiKeysTabDirty(state.apiKeys),
    });
  }

  return tabDefs;
};

export const selectIsDrawerDirty = (state: CustomerUserState, enableCustomerAccountRestrictions: boolean) => {
  return selectTabDefs(state, enableCustomerAccountRestrictions).some(tabDef => tabDef.isDirty);
};

export type CustomerUserStore = ReturnType<typeof getCustomerUserStore>;
export type CustomerUserState = ReturnType<CustomerUserStore['getState']>;
export type CustomerUserDispatch = CustomerUserStore['dispatch'];
