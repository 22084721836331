import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useMarketAccountsContext } from '../contexts';

/**
 * Returns the list of present MarketAccount.Groups in the system, after filtering out bad / hidden groups.
 * See implementation for more details on this filtering.
 */
export const useMarketAccountGroups = () => {
  const { marketAccountsList } = useMarketAccountsContext();

  const groups = useMemo(() => {
    return uniq(marketAccountsList.map(acc => acc.Group)).filter(group => !ignoreGroup(group));
  }, [marketAccountsList]);

  return groups;
};

function ignoreGroup(group: string | undefined) {
  return group == null || group.startsWith('_') || group === '';
}
