import {
  ACTION,
  API_PERMISSION_WILDCARD,
  Box,
  Button,
  ButtonVariants,
  CheckboxFuseAutocompleteDropdown,
  Flex,
  IconName,
  MarketAccountTypeEnum,
  MarketTypeEnum,
  MultiSelectInput,
  useDropdownPopper,
  useMarketAccountsContext,
  useMarketsContext,
  useMultiSelectAutocomplete,
  useRenderMultiSelectSelection,
  type AutocompleteGroupSorterFunc,
} from '@talos/kyoko';

import { useRoleAuth } from 'hooks';
import { useCallback, useMemo, useRef, type Dispatch, type SetStateAction } from 'react';
const ALL_MARKET_ACCOUNTS_LABEL = 'All Market Accounts';
const ALL_CHECKED_ARRAY = [API_PERMISSION_WILDCARD];

const groupIndexes = {
  [MarketTypeEnum.Exchange]: 0,
  [MarketTypeEnum.Dealer]: 1,
  [MarketAccountTypeEnum.Gateway]: 2,
  [MarketTypeEnum.Dark]: 3,
  [MarketTypeEnum.External]: 4,
  [MarketTypeEnum.InternalAlgo]: 5,
  [MarketTypeEnum.Custodian]: 6,
  [MarketAccountTypeEnum.Unknown]: 7,
  Other: 8,
};

const groupSorter: AutocompleteGroupSorterFunc = (g1, g2) =>
  (groupIndexes[g1.group] || 0) - (groupIndexes[g2.group] || 0);

export function MarketAccountsDropdown({
  marketAccountsToAdd,
  setMarketAccountsToAdd,
  onAddMarketAccounts,
  allChecked,
}: {
  marketAccountsToAdd: string[];
  setMarketAccountsToAdd: Dispatch<SetStateAction<string[]>>;
  onAddMarketAccounts: (marketAccounts: string[]) => void;
  allChecked: boolean;
}) {
  const { marketAccountsList, marketAccountDisplayNameByName, marketAccountsByName } = useMarketAccountsContext();
  const { marketTypeByName } = useMarketsContext();
  const { isAuthorized } = useRoleAuth();
  const canAddMarketAccounts = isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS);

  const availableMarketAccounts = useMemo(() => {
    return ALL_CHECKED_ARRAY.concat((marketAccountsList || []).map(s => s.Name));
  }, [marketAccountsList]);
  const isItemDisabled = useCallback(
    (subAccount: string) => {
      return allChecked ? subAccount !== API_PERMISSION_WILDCARD : false;
    },
    [allChecked]
  );

  const getLabel = useCallback(
    (marketAccountName: string) =>
      marketAccountName === API_PERMISSION_WILDCARD
        ? ALL_MARKET_ACCOUNTS_LABEL
        : marketAccountDisplayNameByName.get(marketAccountName) || marketAccountName,
    [marketAccountDisplayNameByName]
  );

  const getGroup = useCallback(
    (marketAccountName: string) => {
      if (marketAccountName === API_PERMISSION_WILDCARD) {
        return 'All';
      }

      const marketAccount = marketAccountsByName.get(marketAccountName);
      if (!marketAccount) {
        return 'Unknown';
      }

      // MarketAccountType = Trading also happens for transfer market accounts for some reason. When the type is trading,
      // rely on the Market.Type instead for the grouping.
      // See: https://talostrading.atlassian.net/browse/PORT-13352
      if (marketAccount.Type === MarketAccountTypeEnum.Trading) {
        const marketType = marketTypeByName.get(marketAccount.Market) || 'Other';
        return marketType;
      }

      if (marketAccount.Type === MarketAccountTypeEnum.Transfer) {
        // Combine Transfer market accounts and Custodian markets into one group under the Custodian label here
        return MarketTypeEnum.Custodian;
      }

      return marketAccount.Type;
    },
    [marketAccountsByName, marketTypeByName]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete({
    selections: allChecked ? ALL_CHECKED_ARRAY : marketAccountsToAdd,
    items: availableMarketAccounts,
    getLabel: getLabel,
    isItemDisabled,
    initialSortByLabel: false,
    onChange: setMarketAccountsToAdd,
    clearInputAfterSelection: false,
    removeItemOnInputBackspace: true,
    highlightInputTextAfterSelection: true,
    inputRef,
    getGroup,
    groupSorter,
  });

  const { isOpen, closeMenu, openMenu, getInputProps, getMenuProps } = autocompleteOutput;
  const { getDropdownProps, getSelectedItemProps, removeSelectedItem } = multipleSelectionOutput;
  const handleClickOutside = useCallback(() => closeMenu(), [closeMenu]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement: dropdownRef.current,
    dropdownWidth: '240px',
    dropdownPlacement: 'bottom-end',
    onClickOutside: handleClickOutside,
  });

  const renderMultiSelectItem = useRenderMultiSelectSelection({
    removeSelectedItem,
    getSelectedItemProps,
    getLabel,
  });

  return (
    <Flex gap="spacingSmall" w="100%">
      <Box flex={1}>
        <Box ref={dropdownRef} flex={1}>
          <MultiSelectInput
            {...getInputProps(getDropdownProps({ ref: inputRef }))}
            placeholder="Search market account name..."
            onClick={openMenu}
            items={multipleSelectionOutput.selectedItems.map((item, index) => renderMultiSelectItem(item, index))}
            disabled={!canAddMarketAccounts}
          />
        </Box>
        <div {...getMenuProps()}>
          <CheckboxFuseAutocompleteDropdown
            {...autocompleteOutput}
            {...dropdownPopper}
            selectedItems={multipleSelectionOutput.selectedItems}
            addSelectedItem={multipleSelectionOutput.addSelectedItem}
            removeSelectedItem={multipleSelectionOutput.removeSelectedItem}
            maxHeight={400}
          />
        </div>
      </Box>
      <Button
        variant={ButtonVariants.Primary}
        startIcon={IconName.Plus}
        disabled={(marketAccountsToAdd.length === 0 && !allChecked) || !canAddMarketAccounts}
        onClick={() => onAddMarketAccounts(marketAccountsToAdd)}
      >
        Add
      </Button>
    </Flex>
  );
}
