import type { RequiredProperties } from '../utils/types';
import type { IApiPermissionFilter } from './types';

export type ApiPermissionFilter = Pick<
  IApiPermissionFilter,
  'Action' | 'Filter' | 'FilterID' | 'Subject' | 'UpdateAction'
> &
  Partial<IApiPermissionFilter>;

export type ISubAccountApiPermissionFilter = IApiPermissionFilter & {
  Filter: RequiredProperties<IApiPermissionFilter['Filter'], 'SubAccount'>;
  Subject: RequiredProperties<IApiPermissionFilter['Subject'], 'User'>;
};

export type IMarketAccountApiPermissionFilter = IApiPermissionFilter & {
  Filter: RequiredProperties<IApiPermissionFilter['Filter'], 'MarketAccount'>;
  Subject: RequiredProperties<IApiPermissionFilter['Subject'], 'SubAccount'>;
};

export type SubAccountApiPermissionFilter = ApiPermissionFilter & {
  Filter: RequiredProperties<ApiPermissionFilter['Filter'], 'SubAccount'>;
  Subject: RequiredProperties<ApiPermissionFilter['Subject'], 'User'>;
};

export type MarketAccountApiPermissionFilter = ApiPermissionFilter & {
  Filter: RequiredProperties<ApiPermissionFilter['Filter'], 'MarketAccount'>;
  Subject: RequiredProperties<ApiPermissionFilter['Subject'], 'SubAccount'>;
};

export const API_PERMISSION_WILDCARD = '*';
