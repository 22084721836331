import {
  Box,
  CUSTOMER_QUOTE,
  CUSTOMER_QUOTE_HISTORY,
  CUSTOMER_TRANSACTION_HISTORY,
  CustomerOrder,
  CustomerQuote,
  CustomerTrade,
  CustomerTransaction,
  ExecutionTimeline,
  Order,
  PricingModeEnum,
  QUOTE,
  QUOTE_HISTORY,
  Quote,
  mapWsResponseToArray,
  scanWsResponseToArray,
  useObservable,
  useSubscription,
  type BoxProps,
  type RequestStream,
} from '@talos/kyoko';
import { useExecutionReports } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';
import { map, scan, shareReplay } from 'rxjs/operators';
import { useCustomerExecutionReports } from '../../hooks/useCustomerExecutionReports';

export interface ActivityCardProps {
  entity?: Order | Quote | CustomerOrder | CustomerQuote | CustomerTrade | CustomerTransaction;
  statuses?: string[];
}

export function ActivityCard({ entity, statuses, ...boxProps }: ActivityCardProps & BoxProps) {
  return (
    <Box w="100%" h="100%" {...boxProps}>
      {entity instanceof Order && (
        <OrderExecutionTimeline
          statuses={statuses}
          orderID={entity.OrderID}
          rfqID={entity.RFQID}
          includeQuoteHistory={entity.PricingMode === PricingModeEnum.SpreadToFixing}
        />
      )}
      {entity instanceof Quote && (
        <QuoteExecutionTimeline
          rfqID={entity.RFQID}
          includeQuoteHistory={entity.PricingMode === PricingModeEnum.SpreadToFixing}
        />
      )}
      {entity instanceof CustomerOrder && (
        <CustomerOrderExecutionTimeline
          statuses={statuses}
          orderID={entity.OrderID}
          rfqID={entity.RFQID}
          includeQuoteHistory={entity.PricingMode === PricingModeEnum.SpreadToFixing}
        />
      )}
      {entity instanceof CustomerQuote && (
        <CustomerQuoteExecutionTimeline
          rfqID={entity.RFQID}
          includeQuoteHistory={entity.PricingMode === PricingModeEnum.SpreadToFixing}
        />
      )}
      {entity instanceof CustomerTrade && <CustomerTradeExecutionTimeline tradeID={entity.TradeID} />}
      {entity instanceof CustomerTransaction && <CustomerTransactionExecutionTimeline clReqID={entity.ClReqID} />}
    </Box>
  );
}

function CustomerTransactionExecutionTimeline({ clReqID }: { clReqID: string }) {
  const customerTransactions = useCustomerTransactionHistory({ clReqID, tag: 'CustomerTransactionExecutionTimeline' });
  return <ExecutionTimeline customerTransactions={customerTransactions} />;
}

function OrderExecutionTimeline({
  rfqID,
  orderID,
  statuses,
  includeQuoteHistory = false,
}: {
  rfqID?: string;
  orderID: string;
  statuses?: string[];
  includeQuoteHistory?: boolean;
}) {
  const executionReports = useExecutionReports({ orderID, statuses, tag: 'OrderExecutionTimeline' });
  const quotes = useQuotes({ rfqID, tag: 'OrderExecutionTimeline', includeQuoteHistory });
  return <ExecutionTimeline executionReports={executionReports} quotes={quotes} statuses={statuses} />;
}

function QuoteExecutionTimeline({ rfqID, includeQuoteHistory }: { rfqID?: string; includeQuoteHistory?: boolean }) {
  const executionReports = useExecutionReports({ rfqID, tag: 'OrderExecutionTimeline' });
  const quotes = useQuotes({ rfqID, tag: 'QuoteExecutionTimeline', includeQuoteHistory });
  return <ExecutionTimeline executionReports={executionReports} quotes={quotes} />;
}

function CustomerOrderExecutionTimeline({
  rfqID,
  orderID,
  statuses,
  includeQuoteHistory = false,
}: {
  rfqID?: string;
  orderID: string;
  statuses?: string[];
  includeQuoteHistory?: boolean;
}) {
  const executionReports = useExecutionReports({
    parentOrderID: orderID,
    statuses,
    tag: 'CustomerOrderExecutionTimeline',
  });
  const quotes = useQuotes({ parentRFQID: rfqID, tag: 'CustomerOrderExecutionTimeline', includeQuoteHistory });

  const customerExecutionReports = useCustomerExecutionReports({ orderID, rfqID, statuses });
  const customerQuotes = useCustomerQuotes({ rfqID, tag: 'CustomerOrderExecutionTimeline', includeQuoteHistory });

  return (
    <ExecutionTimeline
      statuses={statuses}
      customerExecutionReports={customerExecutionReports}
      customerQuotes={customerQuotes}
      executionReports={executionReports}
      quotes={quotes}
      showInitiatorLabels={true}
    />
  );
}

function CustomerQuoteExecutionTimeline({
  rfqID,
  includeQuoteHistory = false,
}: {
  rfqID?: string;
  includeQuoteHistory?: boolean;
}) {
  const executionReports = useExecutionReports({ parentRFQID: rfqID, tag: 'CustomerQuoteExecutionTimeline' });
  const quotes = useQuotes({ parentRFQID: rfqID, tag: 'CustomerQuoteExecutionTimeline', includeQuoteHistory });

  const customerExecutionReports = useCustomerExecutionReports({ rfqID });
  const customerQuotes = useCustomerQuotes({ rfqID, tag: 'CustomerQuoteExecutionTimeline', includeQuoteHistory });

  // The back-end sends us duplicates of the quotes with and without the EventIndicator field
  // so we exclude those here...
  const customerQuotesWithoutEventIndicator = useMemo(
    () =>
      includeQuoteHistory
        ? customerQuotes.pipe(map(data => data.filter(quote => quote.EventIndicator == null)))
        : customerQuotes,
    [customerQuotes, includeQuoteHistory]
  );

  return (
    <ExecutionTimeline
      customerExecutionReports={customerExecutionReports}
      customerQuotes={customerQuotesWithoutEventIndicator}
      executionReports={executionReports}
      quotes={quotes}
      showInitiatorLabels={true}
    />
  );
}

function CustomerTradeExecutionTimeline({ tradeID }: { tradeID: string }) {
  const customerTrades = useCustomerTradeHistory({ tradeID, tag: 'CustomerTradeExecutionTimeline' });

  return <ExecutionTimeline customerTrades={customerTrades} />;
}

interface CustomerTransactionReq extends RequestStream {
  ClReqID: string;
}
function useCustomerTransactionHistory({
  clReqID,
  tag,
}: {
  clReqID?: string;
  tag: string;
}): Observable<CustomerTransaction[]> {
  const [request, setRequest] = useState<CustomerTransactionReq | null>(null);
  useEffect(() => {
    if (clReqID == null) {
      setRequest(null);
    } else if (clReqID) {
      setRequest({ name: CUSTOMER_TRANSACTION_HISTORY, tag, ClReqID: clReqID });
    }
  }, [clReqID, tag]);
  const { data: subscription } = useSubscription<CustomerTransaction>(request);
  return useObservable<CustomerTransaction[]>(
    () => subscription.pipe(scanWsResponseToArray(d => new CustomerTransaction(d))),
    [subscription]
  );
}

function useQuotes({
  rfqID,
  parentRFQID,
  tag,
  includeQuoteHistory = false,
}: {
  rfqID?: string;
  parentRFQID?: string;
  tag: string;
  includeQuoteHistory?: boolean;
}): Observable<Quote[]> {
  const [request, setRequest] = useState<RequestStream | null>(null);
  useEffect(() => {
    if (rfqID == null && parentRFQID == null) {
      setRequest(null);
    } else {
      setRequest({
        name: includeQuoteHistory ? QUOTE_HISTORY : QUOTE,
        tag,
        ...(rfqID != null && { RFQID: rfqID }),
        ...(parentRFQID != null && { ParentRFQID: parentRFQID }),
        SendMarkets: true,
      });
    }
  }, [rfqID, parentRFQID, tag, includeQuoteHistory]);
  const { data: subscription } = useSubscription(request);
  return useObservable<Quote[]>(
    () =>
      subscription.pipe(
        includeQuoteHistory ? scanWsResponseToArray(d => new Quote(d)) : mapWsResponseToArray(d => new Quote(d))
      ),
    [subscription, includeQuoteHistory]
  );
}

function useCustomerQuotes({
  rfqID,
  tag,
  includeQuoteHistory = false,
}: {
  rfqID?: string;
  tag: string;
  includeQuoteHistory?: boolean;
}): Observable<CustomerQuote[]> {
  const [request, setRequest] = useState<any>(null);
  useEffect(() => {
    if (rfqID == null) {
      setRequest(null);
    } else {
      setRequest({
        name: includeQuoteHistory ? CUSTOMER_QUOTE_HISTORY : CUSTOMER_QUOTE,
        tag,
        RFQID: rfqID,
      });
    }
  }, [rfqID, tag, includeQuoteHistory]);
  const { data: subscription } = useSubscription(request);
  return useObservable<CustomerQuote[]>(
    () =>
      subscription.pipe(
        includeQuoteHistory
          ? scanWsResponseToArray(d => new CustomerQuote(d))
          : mapWsResponseToArray(d => new CustomerQuote(d))
      ),
    [subscription, includeQuoteHistory]
  );
}

function useCustomerTradeHistory({ tradeID, tag }: { tradeID: string; tag: string }): Observable<CustomerTrade[]> {
  const [request, setRequest] = useState<RequestStream | null>(null);
  useEffect(() => {
    if (tradeID == null) {
      setRequest(null);
    } else {
      setRequest({
        name: 'CustomerTradeHistory',
        tag,
        TradeID: tradeID,
      });
    }
  }, [tradeID, tag]);
  const { data: subscription } = useSubscription(request);
  return useObservable<CustomerTrade[]>(
    () =>
      subscription.pipe(
        scan((result: CustomerTrade[], json) => {
          json.initial && (result = []);
          json.data.forEach(d => {
            result.push(new CustomerTrade(d));
          });
          return result;
        }, []),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );
}
