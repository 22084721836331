import { getDefaultCustomerMarketAccount, type Customer, type MarketAccount, type SelectorField } from '@talos/kyoko';
import type { WritableDraft } from 'immer';

export const getDefaultMarketAccountForCustomer = (
  customerAccountField: WritableDraft<SelectorField<MarketAccount>>,
  allCustomerMarketAccounts: WritableDraft<MarketAccount[]>,
  customer?: Customer
) => {
  if (!customer) {
    return customerAccountField.updateAvailableItems(allCustomerMarketAccounts);
  }

  const filteredAccounts = allCustomerMarketAccounts.filter(ma => ma.Counterparty === customer?.Name);
  const defaultAccount = getDefaultCustomerMarketAccount(filteredAccounts);

  return customerAccountField
    .updateAvailableItems(filteredAccounts)
    .updateValue(defaultAccount, true)
    .setDisabled(filteredAccounts.length === 1);
};
