import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { Box, MEDIA, Panel as SettingsPanel, maxWidth } from '@talos/kyoko';

export const FADE_IN_TRANSITION = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  delay: 300,
  config: { duration: 200 },
  unique: true,
};

export const Panel = styled(SettingsPanel)`
  padding: 0;
`;

export const FontSizeWrapper = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizeLarge}rem;
  ${maxWidth(MEDIA.medium)} {
    font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  }
`;

export const FormWrapper = styled.div`
  max-width: 480px;
  width: 100%;
  ${maxWidth(MEDIA.medium)} {
    max-width: 300px;
  }
  flex-grow: 1;
  min-width: 200px;
`;

export const MarketsListWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  background: ${({ theme }) => theme.backgroundContent};
  width: 100%;
`;

export const MarketsWrapper = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  overflow-y: overlay;
  width: 100%;
  background: ${({ theme }) => theme.backgroundContent};
`;

export const CredentialsPanelContent = styled(animated.div)`
  position: relative;
  gap: ${({ theme }) => theme.spacingHuge}px;
  display: flex;
  flex: 1;
  width: 100%;
  transition: transform 500ms ease, opacity 500ms ease;
  padding: 0 ${({ theme }) => theme.spacingBig}px;
  background: ${({ theme }) => theme.backgroundContent};
`;

export const MarketItem = styled.div`
  display: flex;
  padding: 0 ${({ theme }) => theme.spacingBig}px;
  align-items: center;
  height: 60px;
  flex: 0 1 1024px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColorDivider};
  ${maxWidth(MEDIA.medium)} {
    padding: 0 ${({ theme }) => theme.spacingBig}px;
  }
  > svg {
    margin-right: ${({ theme }) => theme.spacingDefault}px;
  }
  > a {
    width: 80px;
  }
`;

export const MarketTitle = styled.div`
  h3,
  p {
    min-width: 50px;
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    margin: 0;
  }
  margin-right: auto;
`;

/* Status */

export const Status = styled.div`
  width: 90px;
  text-align: right;
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacingLarge}px;
`;

export const StatusTitle = styled.div`
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colorTextSubtle};
`;

export const StatusInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorTextImportant};
  justify-content: flex-end;
  i {
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const FeeRatesWrapper = styled(Box)`
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  background: ${({ theme }) => theme.colors.gray['020']};
`;
