import { AccordionGroup, Flex, type FilterClause } from '@talos/kyoko';
import { PerformanceBottomContent } from './components/PerformanceBottomContent';
import { PerformanceTopContent } from './components/PerformanceTopContent';
import { PerformanceFiltersProvider } from './providers/PerformanceFiltersProvider';
import { PerformanceInteractionsProvider } from './providers/PerformanceInteractionsProvider';
import { PerformancePositionsProvider } from './providers/PerformancePositionsProvider';

export const PerformanceTab = ({
  tabId,
  initialFilterClauses,
  filtersInitialOpen,
}: {
  tabId: string;
  initialFilterClauses: FilterClause[];
  filtersInitialOpen: boolean;
}) => {
  return (
    <Flex h="100%" flexDirection="column" overflow="hidden">
      <PerformanceFiltersProvider key={tabId}>
        <PerformancePositionsProvider>
          <PerformanceInteractionsProvider>
            <AccordionGroup>
              <PerformanceTopContent
                initialFilterClauses={initialFilterClauses}
                initialFiltersOpen={filtersInitialOpen}
              />
            </AccordionGroup>
            <PerformanceBottomContent />
          </PerformanceInteractionsProvider>
        </PerformancePositionsProvider>
      </PerformanceFiltersProvider>
    </Flex>
  );
};
