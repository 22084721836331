import {
  ACTION,
  Button,
  ButtonVariants,
  Divider,
  FormControlSizes,
  Input,
  MixpanelEvent,
  NotificationVariants,
  VStack,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useGlobalDialog,
  useGlobalToasts,
  useMixpanel,
  type Column,
  type User,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams, MenuItemDef } from 'ag-grid-enterprise';
import { compact } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useRoleAuth } from '../../../hooks';
import { useUserSettings } from './useUserSettings';

export function UsersBlotterTableMenu({
  data: user,
  ssoEnabled,
  onEditUser,
  onDeleteUser,
  onDeleteAppConfig,
  onResetPassword,
  onCreatePasswordChangeUrl,
  onForceLogout,
  onReEnableUser,
}: ICellRendererParams & {
  ssoEnabled: boolean;
  onDeleteUser(user: User): void;
  onEditUser(user: User): void;
  onDeleteAppConfig(user: User): void;
  onResetPassword(user: User): void;
  onSendPasswordResetEmail(user: User): void;
  onCreatePasswordChangeUrl(user: User): void;
  onForceLogout(user: User): void;
  onReEnableUser(user: User): void;
}) {
  return (
    <>
      {!user.DeletedAt && (
        <>
          <Button
            size={FormControlSizes.Small}
            disabled={user.ID == null}
            ghost={true}
            onClick={() => onEditUser(user)}
          >
            Edit &quot;{user.Name}&quot;
          </Button>
          <Divider orientation="horizontal" />
          <Button
            size={FormControlSizes.Small}
            disabled={user.ID == null}
            ghost={true}
            onClick={() => onDeleteAppConfig(user)}
          >
            Reset App Config
          </Button>
          {!ssoEnabled && (
            <Button
              size={FormControlSizes.Small}
              disabled={user.ID == null}
              ghost={true}
              onClick={() => onResetPassword(user)}
            >
              Send Password Reset Email
            </Button>
          )}
          {!ssoEnabled && (
            <Button
              size={FormControlSizes.Small}
              disabled={user.ID == null}
              ghost={true}
              onClick={() => onCreatePasswordChangeUrl(user)}
            >
              Create Password Change Url
            </Button>
          )}
          <Button
            size={FormControlSizes.Small}
            disabled={user.ID == null}
            ghost={true}
            onClick={() => onForceLogout(user)}
          >
            Force Logout
          </Button>

          {user.ID != null && (
            <Button
              size={FormControlSizes.Small}
              variant={ButtonVariants.Negative}
              ghost={true}
              onClick={() => onDeleteUser(user)}
            >
              Remove
            </Button>
          )}
        </>
      )}
      {user.DeletedAt && (
        <Button
          size={FormControlSizes.Small}
          disabled={user.ID == null}
          ghost={true}
          onClick={() => onReEnableUser(user)}
        >
          Re-enable
        </Button>
      )}
    </>
  );
}

export interface UseUsersBlotterTableMenu {
  column: Column;
  getContextMenuItems: (params: GetContextMenuItemsParams) => Array<string | MenuItemDef>;
}

export function useUsersBlotterTableMenu({
  onDeleteUser,
  onEditUser,
  onReEnableUser,
  ssoEnabled,
}): UseUsersBlotterTableMenu {
  const { add: addToast } = useGlobalToasts();
  const { isAuthorized } = useRoleAuth();
  const mixpanel = useMixpanel();
  const { deleteUser, deleteAppConfig, createResetPasswordUrl, sendResetPasswordEmail, logoutUser, reEnableUser } =
    useUserSettings();
  const { open } = useGlobalDialog();

  const handleDeleteUser = useCallback(
    (user: User) => {
      open({
        onConfirm: async () => {
          mixpanel.track(MixpanelEvent.RemoveUser);
          try {
            await deleteUser(user);
            addToast({
              text: `User ${user.Name} (${user.Email}) removed.`,
              variant: NotificationVariants.Positive,
            });
            onDeleteUser(user);
          } catch (e) {
            addToast({
              text: (e as Error).message,
              variant: NotificationVariants.Negative,
            });
          }
        },
        title: 'Remove user',
        showClose: true,
        content: (
          <>
            Are you sure you want to delete {user.Name} ({user.Email})?
          </>
        ),
      });
    },
    [onDeleteUser, deleteUser, addToast, open, mixpanel]
  );

  const handleCreateChangePasswordUrl = useCallback(
    (user: User) => {
      createResetPasswordUrl(user).then(res => {
        open({
          title: 'Create Password Change Url',
          onConfirm: () => {
            mixpanel.track(MixpanelEvent.CreatePassChangeUrl);
          },
          showClose: true,
          showCancel: false,
          confirmLabel: 'Close',
          content: (
            <VStack gap="spacingDefault">
              Send the following URL to {user.Name} ({user.Email}). It&apos;s valid for 5 minutes.
              <br />
              <Input type="text" readOnly value={res.data[0].Url} />
            </VStack>
          ),
        });
      });
    },
    [createResetPasswordUrl, open, mixpanel]
  );

  const handleSendResetPasswordEmail = useCallback(
    (user: User) => {
      open({
        onConfirm: () => {
          mixpanel.track(MixpanelEvent.SendPasswordResetEmail);
          if (user.ID == null) {
            return;
          }
          sendResetPasswordEmail(user)
            .then(() => {
              addToast({
                text: `Sent password change email to ${user.Name} (${user.Email}).`,
                variant: NotificationVariants.Positive,
              });
            })
            .catch(e => {
              addToast({
                text: (e as Error).message,
                variant: NotificationVariants.Negative,
              });
            });
        },
        title: 'Send Password Reset Email',
        showClose: true,
        content: (
          <>
            {user.Name} ({user.Email}) will receive an email with a link to continue the process. Proceed?
          </>
        ),
      });
    },
    [sendResetPasswordEmail, addToast, open, mixpanel]
  );

  const handleDeleteAppConfig = useCallback(
    (user: User) => {
      open({
        onConfirm: async () => {
          mixpanel.track(MixpanelEvent.ResetAppConfig);
          try {
            await deleteAppConfig(user);
            addToast({
              text: `App config for ${user.Name} (${user.Email}) has been reset.`,
              variant: NotificationVariants.Positive,
            });
          } catch (e) {
            addToast({
              text: (e as Error).message,
              variant: NotificationVariants.Negative,
            });
          }
        },
        title: 'Reset App Config',
        showClose: true,
        content: (
          <>
            This will reset the app config of {user.Name} ({user.Email}) to its default values. Proceed?
          </>
        ),
      });
    },
    [deleteAppConfig, addToast, open, mixpanel]
  );

  const handleForceLogout = useCallback(
    (user: User) => {
      open({
        onConfirm: async () => {
          mixpanel.track(MixpanelEvent.ForceLogout);
          try {
            await logoutUser(user);
            addToast({
              text: `Logged out ${user.Name} (${user.Email}) from all sessions.`,
              variant: NotificationVariants.Positive,
            });
          } catch (e) {
            addToast({
              text: (e as Error).message,
              variant: NotificationVariants.Negative,
            });
          }
        },
        title: 'Force Logout',
        showClose: true,
        content: (
          <>
            {user.Name} ({user.Email}) will be logged out of all sessions. Proceed?
          </>
        ),
      });
    },
    [logoutUser, addToast, open, mixpanel]
  );

  const handleReEnableUser = useCallback(
    async (user: User) => {
      try {
        await reEnableUser(user);
        addToast({
          text: `User ${user.Name} (${user.Email}) re-enabled.`,
          variant: NotificationVariants.Positive,
        });
        onReEnableUser();
      } catch (e) {
        addToast({
          text: (e as Error).message,
          variant: NotificationVariants.Negative,
        });
      }
    },
    [addToast, reEnableUser, onReEnableUser]
  );

  const column = useMemo<Column>(
    () => ({
      id: 'menu',
      type: 'hamburgerMenu',
      params: {
        renderItems: params => (
          <UsersBlotterTableMenu
            {...params}
            ssoEnabled={ssoEnabled}
            onDeleteUser={handleDeleteUser}
            onEditUser={onEditUser}
            onDeleteAppConfig={handleDeleteAppConfig}
            onResetPassword={handleSendResetPasswordEmail}
            onCreatePasswordChangeUrl={handleCreateChangePasswordUrl}
            onForceLogout={handleForceLogout}
            onReEnableUser={handleReEnableUser}
          />
        ),
      },
    }),
    [
      ssoEnabled,
      handleDeleteAppConfig,
      handleDeleteUser,
      handleSendResetPasswordEmail,
      handleCreateChangePasswordUrl,
      handleForceLogout,
      handleReEnableUser,
      onEditUser,
    ]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const user = params?.node?.data;
    const contextMenuItems = isAuthorized(ACTION.EDIT_USERS)
      ? !user.DeletedAt
        ? [
            {
              name: `Edit "${user?.Name}"`,
              action: () => onEditUser(user),
            },
            'separator',
            {
              name: `Reset App Config`,
              action: () => handleDeleteAppConfig(user),
            },
            !ssoEnabled
              ? {
                  name: `Send Password Reset Email`,
                  action: () => handleSendResetPasswordEmail(user),
                }
              : null,
            !ssoEnabled
              ? {
                  name: `Create Password Change Url`,
                  action: () => handleCreateChangePasswordUrl(user),
                }
              : null,
            {
              name: `Force Logout`,
              action: () => handleForceLogout(user),
            },
            {
              name: `Remove`,
              action: () => handleDeleteUser(user),
            },
          ]
        : [
            {
              name: `Re-enable`,
              action: () => handleReEnableUser(user),
            },
          ]
      : [];

    return compact([...contextMenuItems, ...getDefaultContextMenuItems(params)]);
  });

  return {
    column,
    getContextMenuItems,
  };
}
