import type { BigSource } from 'big.js';
import type { Order, Trade } from '../../types';

// The super simple functions in this file are just exported so its quicker to implement the summary line

export function getTradeAmount(entity: Trade): BigSource {
  return entity.Amount;
}

export function getTradeQuantity(entity: Trade): BigSource {
  return entity.Quantity;
}

export function getOrderAmount(entity: Order): BigSource {
  return entity.CumAmt ?? '0';
}

export function getOrderQuantity(entity: Order): BigSource {
  return entity.CumQty ?? '0';
}
