import type { AmountEntry } from './PositionsFormReducer';

export enum CheckedType {
  Unchecked,
  Checked,
  Indeterminate,
}

export const isChecked = (check: CheckedType) => check === CheckedType.Checked;
export const isUnchecked = (check: CheckedType) => check === CheckedType.Unchecked;
export const isIndeterminate = (check: CheckedType) => check === CheckedType.Indeterminate;

export function getCheckedType(stateMap: Map<string, Map<string, AmountEntry>>): CheckedType {
  // we're checked if there are any underlying outgoing amount checked
  let totOutgoing = 0;
  let checkedOutgoing = 0;
  let checked = 0;
  for (const mktAccMap of [...stateMap.values()]) {
    for (const entry of [...mktAccMap.values()]) {
      if (entry.isOutgoing) {
        totOutgoing += 1;
      }

      if (entry.checked) {
        checked += 1;
      }

      if (entry.checked && entry.isOutgoing) {
        checkedOutgoing += 1;
      }
    }
  }

  if (checked === 0) {
    return CheckedType.Unchecked;
  } else if (checkedOutgoing === totOutgoing) {
    return CheckedType.Checked;
  } else {
    return CheckedType.Indeterminate;
  }
}
