import { useCallback, useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  ButtonVariants,
  FeeModeEnum,
  Flex,
  FormControlSizes,
  HStack,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  MixpanelSourceContext,
  Toggle,
  useMixpanel,
  useWatchlistSettings,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useAppLayoutConfig } from 'providers/AppLayoutConfig/AppLayoutConfigContext';
import { useEffectOnce } from 'react-use';
import { FEES_TOGGLE, FIRM_LIQUIDITY_TOGGLE } from 'tokens/tooltips';
import { closePanel, togglePanel } from '../../components/AppLayout/AppLayoutSlice';
import { useTabLabelResolver } from '../../hooks/useTabLabelResolver';
import { getLayoutGroupById } from '../../providers/AppLayoutContextProvider/getLayoutGroupById';
import { useAppStateDispatch, useAppStateSelector } from '../../providers/AppStateProvider';
import { mapDisplayPartToPath, mapPathToDisplayParts } from '../Routes/utils';
import { HeaderButtons } from './HeaderButtons';
import { HeaderButton, HeaderWrapper } from './styles';

export const Header = () => {
  return (
    <MixpanelSourceContext.Provider value={MixpanelEventSource.Header}>
      <InternalHeader />
    </MixpanelSourceContext.Provider>
  );
};

const InternalHeader = () => {
  const mixpanel = useMixpanel();
  const { showAllInPrices, setShowAllInPrices, showFirmLiquidity, setShowFirmLiquidity } = useDisplaySettings();

  const handleToggleAllInPrices = useCallback(
    showAllInPrices => {
      const fees = showAllInPrices ? FeeModeEnum.Taker : null;
      mixpanel.track(MixpanelEvent.ToggleFees, { [MixpanelEventProperty.Enabled]: fees });
      setShowAllInPrices(fees);
    },
    [mixpanel, setShowAllInPrices]
  );

  const handleToggleFirmLiquidity = useCallback(
    showFirmLiquidity => {
      mixpanel.track(MixpanelEvent.ToggleFirmLiquidity, { [MixpanelEventProperty.Enabled]: showFirmLiquidity });
      setShowFirmLiquidity(showFirmLiquidity);
    },
    [mixpanel, setShowFirmLiquidity]
  );

  const { isVisible, setIsVisible } = useWatchlistSettings();

  const { enableFlexibleLayout } = useAppLayoutConfig();
  const dispatch = useAppStateDispatch();
  const currentLayout = useAppStateSelector(state => state.appLayout.currentLayout);
  const openPanels = useAppStateSelector(state => state.appLayout.openPanels);

  const toggleWatchlistVisible = useCallback(() => {
    const nextVisible = !isVisible;
    mixpanel.track(MixpanelEvent.ToggleWatchlist, { [MixpanelEventProperty.Enabled]: nextVisible });
    setIsVisible(nextVisible);
    if (enableFlexibleLayout) {
      dispatch(togglePanel('watchlists'));
    }
  }, [dispatch, mixpanel, isVisible, setIsVisible, enableFlexibleLayout]);

  // Temporary fix to sync state for Watchlist panel visibility until we can remove feature flag for Flexible Layout
  useEffectOnce(() => {
    if (!isVisible) {
      dispatch(closePanel('watchlists'));
    }
  });

  const location = useLocation();

  const { resolveTabLabel } = useTabLabelResolver();

  const breadcrumbItems = useMemo(() => {
    const parts = compact(mapPathToDisplayParts(resolveTabLabel, location.pathname));

    return parts.map((part, index) => {
      const to = mapDisplayPartToPath(parts.slice(0, index + 1), location.pathname);

      const capitalize = !part.startsWith('#');

      return (
        <BreadcrumbItem
          key={index}
          to={index < parts.length - 1 ? `/${to}` : '#'}
          onClick={e => !to && e.preventDefault()}
          style={{ textTransform: capitalize ? 'capitalize' : 'none' }}
        >
          {part}
        </BreadcrumbItem>
      );
    });
  }, [location.pathname, resolveTabLabel]);

  const showWatchlist = useMemo(
    () =>
      !currentLayout ||
      (currentLayout?.collapsablePanels.findIndex(panel => panel.groupId === 'watchlists') !== -1 &&
        getLayoutGroupById(currentLayout.dockViewLayout, 'watchlists')),
    [currentLayout]
  );

  const watchlistIsVisible = isVisible || openPanels.includes('watchlists');

  return (
    <HeaderWrapper>
      <Route path="/trading">
        {showWatchlist && (
          <HeaderButton
            border="right"
            onClick={toggleWatchlistVisible}
            size={FormControlSizes.Small}
            variant={watchlistIsVisible ? ButtonVariants.Priority : ButtonVariants.Default}
            startIcon={IconName.PresentationChartLine}
          >
            Watchlist
          </HeaderButton>
        )}
      </Route>
      <HStack justifyContent="flex-start" flex="1" pl="spacingComfortable">
        <Route
          path={[
            '/trading',
            '/dealer',
            '/transfers',
            '/portfolio',
            '/portfolio-engineering',
            '/analytics',
            '/monitoring',
            '/settings',
            '/admin',
          ]}
        >
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        </Route>
      </HStack>
      <Flex>
        <Route path="/trading">
          <Flex gap="spacingComfortable" pr="spacingComfortable">
            <Toggle
              size={FormControlSizes.Small}
              checked={!!showAllInPrices}
              onChange={handleToggleAllInPrices}
              tooltip={FEES_TOGGLE}
              label="Fees"
            />
            <Toggle
              size={FormControlSizes.Small}
              checked={showFirmLiquidity}
              onChange={handleToggleFirmLiquidity}
              tooltip={FIRM_LIQUIDITY_TOGGLE}
              label="Firm Liquidity"
            />
          </Flex>
        </Route>
        <HeaderButtons />
      </Flex>
    </HeaderWrapper>
  );
};
