import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabSize,
  Tabs,
  useDynamicCallback,
  useMarketAccountsContext,
  type MarketAccount,
  type MarketConfig,
  type MarketCredential,
  type TabProps,
} from '@talos/kyoko';
import { useEffect, useMemo, useState } from 'react';
import { FeeEditor } from './components/FeeEditor';
import { useFeeEditor, type FeeEditorOutput } from './components/useFeeEditor';
import type { ICredentialDrawerTab } from './types';

interface CredentialFeesTabProps extends UseCredentialFeesTab {
  selectableMarketAccunts: MarketAccount[];
  feeEditor: FeeEditorOutput;
  selectedTabIndex: number;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

export function CredentialFeesTab({
  selectableMarketAccunts,
  selectedMarketConfig,
  feeEditor,
  selectedTabIndex,
  setSelectedTabIndex,
}: CredentialFeesTabProps) {
  const tabItems: TabProps[] = useMemo(
    () =>
      selectableMarketAccunts.map(marketAccount => ({
        label: marketAccount.DisplayName,
        id: marketAccount.Name,
        showDot: feeEditor.form.get(marketAccount.Name)?.dirty,
      })),
    [selectableMarketAccunts, feeEditor.form]
  );

  if (!selectedMarketConfig) {
    return null;
  }

  return (
    <>
      <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex} size={TabSize.Large}>
        {selectableMarketAccunts.length > 1 && (
          <Box pb="spacingLarge">
            <TabList isBordered={true}>
              {tabItems.map(tab => (
                <Tab {...tab} key={tab.id} />
              ))}
            </TabList>
          </Box>
        )}
        <TabPanels>
          {selectableMarketAccunts?.map(marketAccount => (
            <TabPanel key={marketAccount.Name}>
              <FeeEditor marketAccount={marketAccount} marketConfig={selectedMarketConfig} feeEditor={feeEditor} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
}

interface UseCredentialFeesTab {
  credential: MarketCredential;
  selectedMarketConfig: MarketConfig | undefined;
  mktAccsWithFees: Set<string> | undefined;
}

export function useCredentialFeesTab(props: UseCredentialFeesTab): ICredentialDrawerTab {
  const { credential, mktAccsWithFees } = props;
  const { marketAccountsList } = useMarketAccountsContext();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const selectableMarketAccunts = useMemo(() => {
    if (!credential || !mktAccsWithFees) {
      return [];
    }
    return marketAccountsList.filter(
      mktAct => mktAct.CredentialID === credential.CredentialID && mktAccsWithFees.has(mktAct.Name)
    );
  }, [credential, marketAccountsList, mktAccsWithFees]);

  const feeEditor = useFeeEditor({ selectableMarketAccunts });
  const { resetForm } = feeEditor;

  const handleSaveChanges = useDynamicCallback(async (): Promise<void> => {
    try {
      await feeEditor.save();
    } catch (err: any) {
      // Some of the tabs have errors, so we need to show the first tab with errors
      setSelectedTabIndex(err?.selectedIndex ?? 0);
      throw err;
    }
  });

  // Reset feeEditor form whenever credential changes
  useEffect(() => {
    resetForm();
  }, [credential, resetForm]);

  return {
    name: 'Fees',
    component: (
      <CredentialFeesTab
        {...props}
        selectableMarketAccunts={selectableMarketAccunts}
        feeEditor={feeEditor}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
    ),
    isDirty: feeEditor.isDirty,
    viewable: selectableMarketAccunts.length > 0,
    save: handleSaveChanges,
  };
}
