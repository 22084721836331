export function portfolioAbbreviation(value: string): string {
  // If we're in the millions, (M), add two decimals, eg: 1M -> 1.07M
  if (value == null) {
    return '';
  }

  const parsed = parseFloat(value.toString());
  if (isNaN(parsed)) {
    return '';
  }

  if (parsed === 0) {
    return '0';
  }

  if (parsed > 0 && parsed < 0.1) {
    return '<0.1';
  }

  if (parsed < 0 && parsed > -0.1) {
    return '>-0.1';
  }

  let decimals: number | undefined = undefined;
  if (Math.abs(parsed) >= 1000000) {
    decimals = 2;
  } else if (Math.abs(parsed) < 100) {
    decimals = 1;
  }

  return Sugar.Number.abbr(parsed, decimals).toUpperCase();
}
