import { Button } from '../Button';
import type { ExecutionTimelineEntry, WithTimestamp } from '../ExecutionTimeline/types';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { Overlay, ToBottomButtonWrapper } from './styles';
import { useScrollToBottom } from './useScrollToBottom';

export type TimelineOverlayProps<T> = {
  scrollListElement: HTMLDivElement | null;
  items: ExecutionTimelineEntry<T>[];
  scrollToBottom: () => void;
};

// Just positions itself absolutely over the nearest position: relative element
export const TimelineScrollOverlay = <T extends WithTimestamp>({
  scrollListElement,
  items,
  scrollToBottom,
}: TimelineOverlayProps<T>) => {
  const { showToBottom } = useScrollToBottom(scrollListElement, items);

  return (
    <>
      {showToBottom && (
        <Overlay>
          <ToBottomButtonWrapper>
            <Button size={FormControlSizes.Small} onClick={scrollToBottom} startIcon={IconName.ArrowDown}>
              To bottom
            </Button>
          </ToBottomButtonWrapper>
        </Overlay>
      )}
    </>
  );
};
