import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { pushUnique, type MarketAccountAuthorizations } from '@talos/kyoko';
import { initCustomerUser } from '../userDetails/userDetailsSlice';

export type PermissionTypes = 'read' | 'write' | 'admin';
const DefaultPermission: PermissionTypes = 'read';
export interface CustomerAccountPermissions {
  customerAccount: string;
  permissions: PermissionTypes[];
  initialPermissions?: PermissionTypes[]; // undefined for new rows
}

function getInitialState() {
  return {
    customerAccounts: [] as CustomerAccountPermissions[],
    dirtyCustomerAccounts: [] as string[],
    addedCustomerAccounts: [] as string[],
    deletedCustomerAccounts: [] as string[],
  };
}

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: getInitialState(),
  reducers: {
    initPermissions: (state, { payload }: PayloadAction<MarketAccountAuthorizations>) => {
      Object.entries(payload).forEach(([customerAccount, permissions]) => {
        // permissions will look like read::secmaster, read::positions, read::marketdata, read::orders, read::pricing, read::entity, read::limits, read::quotes
        // the ones we are interested in for this screen is read::orders
        const orderPermissions = permissions
          .filter(p => p.includes('::orders'))
          .map(p => p.split('::')[0] as PermissionTypes);

        state.customerAccounts.push({
          customerAccount,
          permissions: orderPermissions,
          initialPermissions: orderPermissions,
        });
      });
    },
    addCustomerAccounts: (state, { payload: customerAccounts }: PayloadAction<string[]>) => {
      customerAccounts.forEach(customerAccount => {
        if (!state.customerAccounts.some(p => p.customerAccount === customerAccount)) {
          const newPermission = {
            customerAccount,
            permissions: [DefaultPermission],
          };
          state.customerAccounts.push(newPermission);
          state.dirtyCustomerAccounts.push(newPermission.customerAccount);
          state.addedCustomerAccounts.push(newPermission.customerAccount);
        }
      });
    },

    updateCustomerAccountPermissions: (state, { payload }: PayloadAction<CustomerAccountPermissions>) => {
      getCustomerAccountPermissions(state.customerAccounts, payload.customerAccount).permissions = payload.permissions;
      state.dirtyCustomerAccounts = pushUnique(state.dirtyCustomerAccounts, payload.customerAccount);
    },

    deleteCustomerAccount: (state, { payload }: PayloadAction<string>) => {
      if (state.addedCustomerAccounts.includes(payload)) {
        // if we have only just added this row, just remove it without having to save.
        state.customerAccounts = state.customerAccounts.filter(p => p.customerAccount !== payload);
        state.addedCustomerAccounts = state.addedCustomerAccounts.filter(id => id !== payload);
        state.dirtyCustomerAccounts = state.dirtyCustomerAccounts.filter(id => id !== payload);
      } else {
        state.deletedCustomerAccounts = pushUnique(state.deletedCustomerAccounts, payload);
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(initCustomerUser, _ => {
      return getInitialState();
    });
  },
});

export function getCustomerAccountPermissions(permissions: CustomerAccountPermissions[], customerAccount: string) {
  const customerAccountPermissions = permissions.find(permission => permission.customerAccount === customerAccount);
  if (!customerAccountPermissions) {
    throw new Error(`Account ${customerAccount} not found`);
  }
  return customerAccountPermissions;
}

export const { initPermissions, addCustomerAccounts, updateCustomerAccountPermissions, deleteCustomerAccount } =
  permissionsSlice.actions;

type PermissionState = ReturnType<typeof permissionsSlice.reducer>;
export function isPermissionsTabDirty(state: PermissionState) {
  return state.dirtyCustomerAccounts.length > 0 || state.deletedCustomerAccounts.length > 0;
}
