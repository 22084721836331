import { Box } from '@talos/kyoko';
import DockViewLayout from 'components/FlexibleLayout/DockViewLayout';
import { useDockViewProvider } from 'components/FlexibleLayout/DockViewProvider';
import { useMemo } from 'react';
import { workspaceDockviewComponentsMap } from '../layoutConfiguration/dockviewWorkspaceConfigs/dockViewComponents';
import { usePortfolioRouting } from '../usePortfolioRouting';

export const DockViewWrapper = () => {
  const dockviewContext = useDockViewProvider();
  const { selectedTab } = usePortfolioRouting();
  const panels = useMemo(() => {
    const panelResult =
      selectedTab?.layoutType === 'flexible' ? workspaceDockviewComponentsMap[selectedTab.availablePanels] : [];
    return panelResult;
  }, [selectedTab]);
  return panels.length > 0 ? (
    <>
      <Box position="relative" w="100%" h="100%">
        <Box position="absolute" top="0" left="0" right="0" bottom="0">
          <DockViewLayout onReady={dockviewContext.handleReady} availablePanels={panels} />
        </Box>
      </Box>
    </>
  ) : null;
};
