import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import type { LoanQuoteTerminationTypeEnum, LoanStatusEnum } from './types';

export class Loan {
  Status!: LoanStatusEnum;

  Timestamp!: string;

  LoanID!: string;

  Qty!: string;

  NegotiationID!: string;

  OriginationTime!: string;

  Lender!: string;

  Borrower!: string;

  PrincipalCurrency!: string;

  CollateralCurrency!: string;

  Rate!: string;

  CollateralRatio!: string;

  TerminationType!: LoanQuoteTerminationTypeEnum;

  TerminationTime!: string;

  ClientID!: string;

  Revision!: number;

  RevisionBy?: string;

  constructor(data: Loan) {
    Object.assign(this, data);
  }
}

interface UseLoanColumns {
  defaultColumns?: (keyof Loan | Partial<Column>)[];
}

export function useLoanColumns({ defaultColumns = EMPTY_ARRAY }: UseLoanColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'Status',
              type: 'loanStatus',
            },
            {
              field: 'Timestamp',
              type: 'date',
              sortable: true,
              sort: '-',
            },
            {
              field: 'LoanID',
              type: 'text',
            },
            {
              field: 'Qty',
              type: 'size',
              params: { currencyField: 'PrincipalCurrency' },
            },
            {
              field: 'NegotiationID',
              type: 'text',
            },
            {
              field: 'OriginationTime',
              type: 'date',
              sortable: true,
              sort: '-',
            },
            {
              field: 'Lender',
              type: 'loanParticipant',
            },
            {
              field: 'Borrower',
              type: 'loanParticipant',
            },
            {
              field: 'PrincipalCurrency',
              type: 'currency',
            },
            {
              field: 'CollateralCurrency',
              type: 'currency',
            },
            {
              field: 'Rate',
              type: 'percent',
            },
            {
              field: 'CollateralRatio',
              type: 'percent',
            },
            {
              field: 'TerminationType',
              type: 'text',
            },
            {
              field: 'TerminationTime',
              type: 'date',
            },
            {
              field: 'ClientID',
              type: 'id',
            },
            {
              field: 'Revision',
              type: 'text',
            },
            {
              field: 'RevisionBy',
              type: 'user',
            },
          ] satisfies ColumnDef<Loan>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<Loan>[] as Column[]).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
