import { OptionTypeEnum } from '@talos/kyoko';
import styled, { css } from 'styled-components';

export const Title = styled.div<{ allowWrapping?: boolean }>`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray['080']};
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  letter-spacing: 0.05em;
  ${props =>
    props.allowWrapping
      ? ''
      : css`
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
  flex: 1;
  margin: ${({ theme }) => theme.spacingTiny}px 0;

  // overflow: hidden makes flex think its ok to shrink this item
  flex-shrink: 0;
`;

export const OptionType = styled.span<{ type?: OptionTypeEnum }>`
  color: ${({ theme, type }) => (type === OptionTypeEnum.Put ? theme.colors.red.lighten : theme.colors.green.lighten)};
`;
