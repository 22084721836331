import { invariant } from '@epic-web/invariant';
import { useSubAccountRelationMap } from 'hooks';
import { useSubAccounts } from 'providers';
import { useCallback } from 'react';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';

/** Memoized retriever to get sub accounts names related to the specific subAccount Id
 * @returns Callback function that returns the subAccount names for the requested subAccount Id
 */
export function useGetSubAccountNamesForRequest(): (contextSubAccountId: number) => string[] {
  const { showRollupHierarchy } = usePortfolioViewStateSelector();
  const { subAccountsByID } = useSubAccounts();
  const relationshipMap = useSubAccountRelationMap();
  const getSubAccountForRequest = useCallback(
    (contextSubAccountId: number): string[] => {
      let resultIds: number[] = [];
      if (contextSubAccountId === -1) {
        return [];
      }
      if (!showRollupHierarchy) {
        resultIds = [contextSubAccountId];
      } else {
        const subAccountRelation = relationshipMap.get(contextSubAccountId);
        if (!subAccountRelation) {
          return [];
        }
        resultIds = [...subAccountRelation.children, contextSubAccountId, ...subAccountRelation.parents];
      }
      return resultIds.map(id => {
        const subAccount = subAccountsByID.get(id);
        invariant(subAccount, `SubAccount with id ${id} not found`);
        return subAccount.Name;
      });
    },
    [relationshipMap, showRollupHierarchy, subAccountsByID]
  );
  return getSubAccountForRequest;
}
