import {
  Box,
  Button,
  ButtonVariants,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  IndicatorDotVariants,
  MixpanelEvent,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  useDynamicCallback,
  useMixpanel,
  type DrawerProps,
} from '@talos/kyoko';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NewUserGeneralTab, useNewUserGeneralTab } from './tabs/NewUserGeneralTab';

import { Prompt } from 'react-router';

type NewUserDrawerProps = DrawerProps & { onSaved: () => Promise<void> };

export function NewUserDrawer(props: NewUserDrawerProps) {
  return (
    <Drawer {...props}>
      <NewUser {...props} />
    </Drawer>
  );
}

type NewUserTabs = 'General';
const ALL_TABS: NewUserTabs[] = ['General'];

function NewUser({ close, onSaved, isOpen }: NewUserDrawerProps) {
  const canViewAccountSegregation = false;
  const viewableTabs: NewUserTabs[] = canViewAccountSegregation ? ALL_TABS : ['General'];
  const [isSaving, setIsSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const activeTabName = ALL_TABS[activeTab];
  const mixpanel = useMixpanel();
  const handleTabChange = useCallback((tabIndex: number) => {
    setActiveTab(tabIndex);
  }, []);

  const { handleSaveChanges: handleSaveNewUser, resetUserForm, ...NewUserGeneral } = useNewUserGeneralTab();

  const handleSaveChanges = useCallback(async () => {
    mixpanel.track(MixpanelEvent.NewUser);
    setIsSaving(true);
    handleSaveNewUser()
      ?.then(() => onSaved?.())
      .catch(() => {}) // do nothing
      .finally(() => {
        setIsSaving(false);
      });
  }, [handleSaveNewUser, onSaved, mixpanel]);

  const isDirty = NewUserGeneral.isDirty;
  const isValid = NewUserGeneral.isValid;

  const isDirtyMap: Record<NewUserTabs, boolean> = {
    General: NewUserGeneral.isDirty,
  };

  const handleClose = useDynamicCallback(() => {
    if (!isDirty) {
      return close();
    }
    const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      close();
    }
  });

  useEffect(() => {
    if (!isOpen) {
      resetUserForm();
    }
  }, [isOpen, resetUserForm]);

  return (
    <>
      <Prompt when={isOpen && isDirty} message="You have unsaved changes. Are you sure you want to leave?" />
      <UserDrawerHeader>
        <Flex gap="spacingSmall" alignItems="center">
          <Text color="colorTextImportant">New User</Text>
        </Flex>
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
      </UserDrawerHeader>
      <DrawerContent p="0">
        <Tabs flex="1" selectedIndex={activeTab} onSelect={handleTabChange} size={TabSize.Large}>
          <TabList flex="0 0 auto" isBordered>
            {viewableTabs.map(name => (
              <Tab showDot={isDirtyMap[name]} dotVariant={IndicatorDotVariants.Primary} label={name} key={name} />
            ))}
          </TabList>
          <Flex h="100%" flexDirection="column" p="spacingComfortable" pt="spacingMedium" gap="spacingMedium">
            <Box
              flexDirection="column"
              gap="spacingMedium"
              w="100%"
              h="100%"
              display={activeTabName === 'General' ? 'flex' : 'none'}
            >
              <NewUserGeneralTab {...NewUserGeneral} />
            </Box>
          </Flex>
        </Tabs>
      </DrawerContent>
      <DrawerFooter>
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={handleSaveChanges}
          disabled={!isValid}
          variant={ButtonVariants.Primary}
          loading={isSaving}
          data-testid="create-user-drawer-save-button"
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
}

const UserDrawerHeader = styled(Flex)`
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacingDefault}px ${({ theme }) => theme.spacingMedium}px;
  min-height: 3.5rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;
