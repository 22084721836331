import { Box, PortalContextProvider, useIsIntersecting, type BoxProps } from '@talos/kyoko';
import { useRef, type PropsWithChildren } from 'react';

/** Ensures each component is has appropriate dockview setup
 * - Ensure portal context is available (for filters, etc)
 * - Only render if visible - to avoid issues with reused portals
 */
export function DockViewPanelWrapper({ children, className, ...props }: PropsWithChildren<BoxProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsIntersecting(ref);
  return (
    <Box ref={ref} h="100%" w="100%" background="backgroundContent" {...props}>
      {isVisible && <PortalContextProvider>{children}</PortalContextProvider>}
    </Box>
  );
}
