import { Box, Flex, Text } from '@talos/kyoko';
import { CustomerMarketAccountsDropdown } from './CustomerMarketAccountsDropdown';
import { CustomerUserPermissionsFormTable } from './CustomerUserPermissionsFormTable';
import type { Warning } from './types';
// modelled after settings -> users -> sub accounts tab
export function CustomerUserPermissions(props: { onWarning: (warning: Warning) => void }) {
  return (
    <Flex flexDirection="column" gap="spacingMedium" w="100%" h="100%" pt="16px">
      <Box flexDirection="column" gap="spacingMedium" display="flex">
        <Text>
          By default, a user is able to view all accounts, and if the user has trading permissions, can trade into all
          accounts.
          <p>Use this form to restrict the accounts a user can view and trade.</p>
          <p>
            The Trade permission is not inclusive of the View permission. A user must have both View and Trade to place
            and view orders for the configured account.
          </p>
        </Text>
        <Text>Selected Customer Accounts</Text>
        <CustomerMarketAccountsDropdown onWarning={props.onWarning} />
      </Box>
      <CustomerUserPermissionsFormTable onWarning={props.onWarning} />
    </Flex>
  );
}
