import {
  BlotterDensity,
  BlotterTable,
  IconName,
  NotificationVariants,
  TreasuryLinkTransactionType,
  TreasuryLinkTypeEnum,
  format,
  getBlockchainAddressesForCurrency,
  getTreasuryLinkDirection,
  truncateBlockchainAddress,
  useBlotterTable,
  useCurrenciesContext,
  useDynamicCallback,
  useGlobalToasts,
  useMarketsContext,
  type AgGridSearchSelectDropdownProps,
  type Column,
  type MarketAccount,
  type MinimalSubscriptionResponse,
  type TreasuryLink,
  type TreasuryLinkSourceOrDestinationField,
} from '@talos/kyoko';
import type { IRowNode, ValueSetterParams } from 'ag-grid-community';
import { usePortfolioAccounts } from 'providers';
import { useMemo } from 'react';
import { usePortfolioTreasury } from '../providers';
import type { DestinationAccount } from '../types';
import { getTreasuryAccountDisplayValue, useAddressSummary } from './hooks/useAddressSummary';
import {
  getEffectiveTreasuryLink,
  useEffectiveTreasuryLinkSourceOrDestination,
} from './hooks/useEffectiveTreasuryLink';
import { useTreasuryRequests } from './hooks/useTreasuryRequests';

import type { CustomCellEditorProps } from 'ag-grid-react';
import { useFeatureFlag } from 'hooks';
import type { Observable } from 'rxjs';
import { TreasuryAddressSelectorCellRenderer } from './TreasuryAddressSelector/TreasuryAddressSelectorCellRenderer';
import { TreasuryMarketAccountSelectorCellRenderer } from './TreasuryMarketAccountSelector/TreasuryMarketAccountSelectorCellRenderer';
import { getDestinationAddressDescription } from './utils';

export interface EditableCurrencyRowsBlotterProps {
  type: TreasuryLinkTypeEnum;
  transactionType?: TreasuryLinkTransactionType;
  entity?: MarketAccount | DestinationAccount;
  treasuryLinksObs: Observable<MinimalSubscriptionResponse<TreasuryLink>>;
}

type AutocompleteItem = {
  label: string;
  value?: string;
  description?: string;
};

export const CURRENCY_COLUMN_WIDTH = 320;

export const EditableCurrencyRowsBlotter = ({
  type,
  entity,
  transactionType,
  treasuryLinksObs,
}: EditableCurrencyRowsBlotterProps) => {
  const { sourceAccountsList, sourceAccountsByName, treasuryLinksBySpecificnessKey } = usePortfolioAccounts();
  const { marketsByName } = useMarketsContext();
  const { OTCTreasuryPositionsByMarketAccountAndCurrency } = usePortfolioTreasury();
  const { currenciesBySymbol } = useCurrenciesContext();
  const { putTreasuryLink, deleteTreasuryLink } = useTreasuryRequests();
  const { add: addToast } = useGlobalToasts();
  const { showCustomerDepositAddressSelection } = useFeatureFlag();
  const marketAccountName = entity?.Name;
  const isDefaultEntity = marketAccountName == null;
  const showDestinationAddressColumn = useMemo(
    () => showCustomerDepositAddressSelection && !isDefaultEntity,
    [showCustomerDepositAddressSelection, isDefaultEntity]
  );

  // What we're getting here is the potential fallbacks for the SOURCE side only without any currency spec
  // This is very specific customer stuff
  const effectiveWithdrawalSource = useEffectiveTreasuryLinkSourceOrDestination(
    {
      Type: TreasuryLinkTypeEnum.CustomerTransfer,
      Direction: getTreasuryLinkDirection(
        TreasuryLinkTypeEnum.CustomerTransfer,
        TreasuryLinkTransactionType.Withdrawals
      ),
      MarketAccount: marketAccountName,
    },
    'SourceMarketAccount'
  );

  // Note: this function is only relevant for Customer Deposit flows
  const getEffectiveDestinationMarketAccountForCurrency = useDynamicCallback(
    (currency: string | undefined, linkID: string | undefined) => {
      if (treasuryLinksBySpecificnessKey == null) {
        return undefined;
      }
      return getEffectiveTreasuryLink({
        treasuryLinksBySpecificnessKey,
        currency,
        marketAccount: marketAccountName,
        sourceOrDestinationField: 'DestinationMarketAccount',
        type: TreasuryLinkTypeEnum.CustomerTransfer,
        direction: getTreasuryLinkDirection(
          TreasuryLinkTypeEnum.CustomerTransfer,
          TreasuryLinkTransactionType.Deposits
        ),
        linkID,
      })?.DestinationMarketAccount;
    }
  );

  const summaries = useAddressSummary(type, transactionType, entity);
  const tooltipFirstColumn = summaries.length > 0 && summaries[0].headerTooltip;
  const tooltipSecondColumn = summaries.length > 1 && summaries[1].headerTooltip;

  const cellEditorParams = useDynamicCallback((params: CustomCellEditorProps<TreasuryLink>) => {
    const field = params.colDef?.field as TreasuryLinkSourceOrDestinationField;
    const currency = params.data.Currency;
    const linkID = params.data.LinkID;

    const getAutocompleteItems = (): AutocompleteItem[] => {
      if (currency == null || sourceAccountsList == null) {
        return [];
      }

      switch (field) {
        case 'DestinationAddress': {
          const depositAccountName = getEffectiveDestinationMarketAccountForCurrency(currency, linkID);
          if (depositAccountName == null) {
            // Very unlikely case where no default of any kind is selected for the deposit account
            return [];
          }

          const maSource = sourceAccountsByName?.get(depositAccountName);
          if (maSource) {
            const addresses = getBlockchainAddressesForCurrency(maSource, currency) ?? [];

            return addresses
              .filter(({ Address }) => Address != null) // if there's no address we cant select this value, theres no address to be set on the link
              .map(({ Address, Name }) => ({
                value: Address,
                label: Name ?? truncateBlockchainAddress(Address),
                description: truncateBlockchainAddress(Address),
              }));
          } else {
            return [];
          }
        }
        case 'SourceMarketAccount':
        case 'DestinationMarketAccount': {
          const sourceAccountName =
            params.data?.SourceMarketAccount ?? effectiveWithdrawalSource?.effectiveSourceOrDestination;
          const maSource = sourceAccountsByName?.get(sourceAccountName ?? '');

          return sourceAccountsList
            .filter(ma => {
              // Filter clause 1:
              // For withdrawal links with a source set (either specifically or via fallback),
              // ensure that the Destination field options are in the same Market as the selected source.
              if (transactionType === TreasuryLinkTransactionType.Withdrawals) {
                if (field === 'DestinationMarketAccount' && sourceAccountName) {
                  return maSource ? maSource?.Market === ma.Market : true;
                }
              }

              return true;
            })
            .map(ma => {
              const displayValue = getTreasuryAccountDisplayValue(ma, marketsByName.get(ma.Market));
              const label = displayValue ?? ma.DisplayName;
              const balance = OTCTreasuryPositionsByMarketAccountAndCurrency?.get(ma.Name)?.get(currency)?.Amount;

              const getDescription = (field: TreasuryLinkSourceOrDestinationField) => {
                if (field === 'SourceMarketAccount' && balance) {
                  return `Balance: ${format(balance, {
                    spec: currenciesBySymbol.get(currency)?.DefaultIncrement,
                  })} ${currency}`;
                } else if (field === 'DestinationMarketAccount') {
                  return getDestinationAddressDescription(ma, currency);
                }
                return undefined;
              };

              return {
                value: ma.Name,
                label,
                description: getDescription(field),
              };
            });
        }
      }
    };

    const items = getAutocompleteItems();

    return {
      ...params,
      useSearchSelectParams: {
        items,
        getLabel: (s: AutocompleteItem) => s.label,
        getDescription: (s: AutocompleteItem) => s.description ?? '',
      },
    } satisfies AgGridSearchSelectDropdownProps<AutocompleteItem>;
  });

  const treasuryLinkValueSetter = useDynamicCallback((params: ValueSetterParams<TreasuryLink>) => {
    const field = params.colDef.field as TreasuryLinkSourceOrDestinationField;
    const newFieldValue = params.newValue.value as string;
    if (field === 'DestinationAddress' && params.data.DestinationMarketAccount == null) {
      params.data.DestinationMarketAccount = getEffectiveDestinationMarketAccountForCurrency(
        params.data.Currency,
        params.data.LinkID
      );
      addToast({
        text: `Your Market Account Selection Has Been Made Automatically`,
        variant: NotificationVariants.Primary,
      });
    }

    putTreasuryLink({ ...params.data, [field]: newFieldValue })
      .then(() => {
        addToast({
          text: `Link updated.`,
          variant: NotificationVariants.Positive,
        });
        // Immediately recognize this value change in the blotter table
        params.data[field] = newFieldValue;
        return true;
      })
      .catch((e: ErrorEvent) => {
        addToast({
          text: `Could not update link: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      });
    return false;
  });

  const deleteColumn: Column = useMemo(() => {
    return {
      id: 'delete',
      type: 'iconButton',
      pinned: 'right',
      width: 45,
      frozen: true,
      suppressColumnsToolPanel: true,
      params: {
        onClick: ({ node }: { node: IRowNode<TreasuryLink> }) => node.data && deleteTreasuryLink(node.data.LinkID),
        icon: IconName.Trash,
      },
    };
  }, [deleteTreasuryLink]);

  const columns: Column[] = useMemo(() => {
    if (transactionType === TreasuryLinkTransactionType.Deposits) {
      return [
        {
          field: 'Currency',
          title: 'Asset',
          type: 'asset',
          width: CURRENCY_COLUMN_WIDTH,
          params: { iconSize: 24 },
          sort: '+',
        },
        {
          field: 'DestinationMarketAccount',
          type: 'custom',
          flex: 1,
          title: 'Deposit Account',
          params: {
            editable: true,
            suppressKeyboardEvent: () => true,
            cellEditor: 'searchSelectDropdown',
            cellEditorPopup: true,
            cellEditorParams,
            valueSetter: treasuryLinkValueSetter,
            cellRenderer: TreasuryMarketAccountSelectorCellRenderer,
            headerComponent: 'tooltipHeader',
            headerComponentParams: { tooltip: tooltipFirstColumn },
          },
        },
        ...(showDestinationAddressColumn
          ? [
              {
                field: 'DestinationAddress',
                type: 'custom',
                flex: 1,
                title: 'Deposit Address',
                params: {
                  editable: true,
                  suppressKeyboardEvent: () => true,
                  cellEditor: 'searchSelectDropdown',
                  cellEditorPopup: true,
                  cellEditorParams,
                  valueSetter: treasuryLinkValueSetter,
                  cellRenderer: TreasuryAddressSelectorCellRenderer,
                  headerComponent: 'tooltipHeader',
                  headerComponentParams: { tooltip: tooltipSecondColumn },
                },
              } satisfies Column,
            ]
          : []),
        deleteColumn,
      ];
    }

    const effectiveColumns: Column[] = [
      {
        field: 'Currency',
        title: 'Asset',
        type: 'asset',
        width: CURRENCY_COLUMN_WIDTH,
        params: { iconSize: 24 },
        sort: '+',
      },
      {
        field: 'SourceMarketAccount',
        type: 'custom',
        flex: 1,
        title: 'From Source',
        params: {
          editable: true,
          suppressKeyboardEvent: () => true,
          cellEditor: 'searchSelectDropdown',
          cellEditorPopup: true,
          cellEditorParams,
          valueSetter: treasuryLinkValueSetter,
          cellRenderer: TreasuryMarketAccountSelectorCellRenderer,
          headerComponent: 'tooltipHeader',
          headerComponentParams: { tooltip: tooltipFirstColumn },
        },
      },
    ];
    if (!isDefaultEntity) {
      effectiveColumns.push({
        field: 'DestinationMarketAccount',
        type: 'custom',
        flex: 1,
        title: 'To Destination',
        params: {
          editable: true,
          suppressKeyboardEvent: () => true,
          cellEditor: 'searchSelectDropdown',
          cellEditorPopup: true,
          cellEditorParams,
          valueSetter: treasuryLinkValueSetter,
          cellRenderer: TreasuryMarketAccountSelectorCellRenderer,
          headerComponent: 'tooltipHeader',
          headerComponentParams: { tooltip: tooltipSecondColumn },
        },
      });
    }

    effectiveColumns.push(deleteColumn);

    return effectiveColumns;
  }, [
    cellEditorParams,
    isDefaultEntity,
    showDestinationAddressColumn,
    tooltipFirstColumn,
    tooltipSecondColumn,
    transactionType,
    treasuryLinkValueSetter,
    deleteColumn,
  ]);

  const blotterTable = useBlotterTable({
    dataObservable: treasuryLinksObs,
    rowID: 'LinkID' satisfies keyof TreasuryLink,
    columns,
    density: BlotterDensity.VeryComfortable,
  });

  return <BlotterTable {...blotterTable} />;
};
