import type { SubAccountPositionLimit } from '@talos/kyoko';

const REQUIRED = ['RejectThreshold', 'ThresholdAsset'] satisfies (keyof SubAccountPositionLimit)[];

export function validatePositionLimit(limit: Partial<SubAccountPositionLimit>) {
  const problemField = REQUIRED.find(requirement => limit[requirement] == null || limit[requirement] === '');
  if (problemField) {
    throw new Error(`All limits must specify a ${problemField}`);
  }
}
