import { invariant } from '@epic-web/invariant';
import {
  ControlPrefix,
  DateRangePicker,
  FormControlSizes,
  LookbackOption,
  TimePickerPrecision,
  type ShortcutOption,
} from '@talos/kyoko';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../stateManagement/portfolioViewLayoutSlice.hooks';
import { usePortfolioRouting } from '../usePortfolioRouting';

export const DATERANGE_SHORTCUT_OPTIONS: ShortcutOption[] = [
  { value: LookbackOption.Today },
  { value: LookbackOption.Past24Hours },
  { value: LookbackOption.PastWeek },
  { value: LookbackOption.Past30Days },
  { value: LookbackOption.PastMonth },
  { value: LookbackOption.Past90Days },
  { value: LookbackOption.PastYear },
];

const { updateDateRange } = getPortfolioViewActions();
export const PMSDateRangeFilter = () => {
  const { selectedTab } = usePortfolioRouting();
  const { dateRange } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();

  const showDatePicker = selectedTab.activeFilters?.includes('DateRange');
  invariant(
    showDatePicker && typeof dateRange !== 'undefined',
    'If DateRange is shown, dateRange must be defined (maybe a default was missed?)'
  );

  return (
    <>
      {showDatePicker ? (
        <DateRangePicker
          value={dateRange}
          shortcuts={DATERANGE_SHORTCUT_OPTIONS}
          onChange={value => {
            dispatch(updateDateRange(value));
          }}
          maxTimePickerPrecision={TimePickerPrecision.MILLISECOND}
          size={FormControlSizes.Small}
          prefix={<ControlPrefix>Date Range</ControlPrefix>}
          clearable={false}
        />
      ) : null}
    </>
  );
};
