import {
  Delete,
  Get,
  Patch,
  Post,
  Put,
  createCookie,
  isUserUpsertAllowed,
  logger,
  parseCookie,
  readCookie,
  stringifyCookie,
  type ICounterparty,
  type MarketAccount,
  type Organization,
  type User,
} from '@talos/kyoko';
import { sortBy } from 'lodash';

export const listCaptures = () => {
  return Get(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/captures`);
};

export const listOrganizations = () => {
  return Get<Organization[]>(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/organizations`).then(orgs =>
    sortBy(orgs, 'Name')
  );
};

export const listOrganizationsWithVM = () => {
  return listOrganizations().then(orgs => orgs.filter(o => o.Attrs && o.Attrs['HasOrgVm']));
};

interface CreateOrganizationParams {
  name: string;
  zone: string;
  createMonitorUser: boolean;
  createPullRequest: boolean;
  whitelabelDomainName: string;
  orgCreationJiraRef: string;
}
export const createOrganization = ({
  name,
  zone,
  createMonitorUser,
  createPullRequest,
  whitelabelDomainName,
  orgCreationJiraRef,
}: CreateOrganizationParams) => {
  return Post(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/organizations`, {
    name,
    zone,
    createMonitorUser,
    createPullRequest,
    whitelabelDomainName,
    orgCreationJiraRef,
  });
};

export const updateOrganizationAttrs = ({ id, attrs }: { id: number; attrs: Record<string, unknown> }) => {
  return Patch(
    import.meta.env.VITE_AVA_API_ENDPOINT,
    `/admin/organizations/${id}/attrs`,
    {
      attrs: JSON.stringify(attrs),
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

interface UserUpsert {
  Name: string;
  Email: string;
  ID: string;
  Roles?: string[];
}

export const deleteOrganization = org => {
  return Delete(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/organizations/${org.ID}`);
};

export const updateUserRoles = (user: UserUpsert) => {
  const { ok, message } = isUserUpsertAllowed(user);
  if (!ok) {
    return Promise.reject({ message });
  }

  return Put(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users/${user.ID}/roles`, {
    roles: user.Roles,
  });
};

export const listUsersForAdmin = () => Get<User[]>(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users`);

const userAdded = user =>
  Put(`${window.location.protocol}//${user.OrgApiEndpoint}/v1`, `/organization/users/${user.ID}`, {}).then(() => user);

export const inviteUser = (user: Omit<UserUpsert, 'ID'> & { OrgID: number }) => {
  const { ok, message } = isUserUpsertAllowed(user);
  if (!ok) {
    return Promise.reject({ message });
  }

  return Post(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/organizations/${user.OrgID}/users`, {
    name: user.Name,
    email: user.Email,
    roles: user.Roles,
  }).then(user => userAdded(user));
};
export const createUser = user => {
  const { ok, message } = isUserUpsertAllowed({ Name: user.name, Email: user.email, Roles: user.roles });
  if (!ok) {
    return Promise.reject({ message });
  }

  return Put(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/organizations/${user.orgID}/users`, {
    auth0ID: user.auth0ID,
    name: user.name,
    roles: user.roles,
  }).then(user => userAdded(user));
};

export const deleteUser = (user: User) => {
  return Delete(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/organizations/x/users/${user.ID}`).then(() =>
    Delete(`${window.location.protocol}//${user.OrgApiEndpoint}/v1`, `/organization/users/${user.ID}`, {}).then(
      () => {}
    )
  );
};

export const morphTo = (user, reason: string | null = null) => {
  let path = `/admin/users/${user.ID}/morph`;
  if (reason != null) {
    path += `?reason=${encodeURIComponent(reason)}`;
  }
  return Put(import.meta.env.VITE_AVA_API_ENDPOINT, path).then(res => {
    const cookieValue = readCookie(import.meta.env.VITE_SESSION_COOKIE);
    const cookieData = parseCookie(cookieValue);
    if (cookieData == null) {
      logger.error(new Error('tried to morph to user without having a cookie set'));
    } else {
      cookieData.is_morphed = true;
      createCookie(import.meta.env.VITE_SESSION_COOKIE, stringifyCookie(cookieData), cookieData.expires_at * 1000);
    }
    return res;
  });
};

export const unmorph = (): Promise<void> => {
  return Delete(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users/morph`);
};

export const listMarketAccounts = (organization: Organization): Promise<MarketAccount[]> =>
  Get(`${window.location.protocol}//${organization.OrgApiEndpoint}`, `/v1/organization/accounts`);

export const listCounterparties = (organization: Organization): Promise<ICounterparty[]> =>
  Get(`${window.location.protocol}//${organization.OrgApiEndpoint}`, `/v1/organization/counterparties`);

export const createCounterparty = (
  organization: Organization,
  counterparty: { name: string; displayName: string }
): Promise<ICounterparty> =>
  Post(`${window.location.protocol}//${organization.OrgApiEndpoint}`, `/v1/organization/counterparties`, {
    name: counterparty.name,
    displayName: counterparty.displayName,
  });

export const createMarketAccount = (
  organization: Organization,
  marketAccount: {
    market: string;
    sourceAccountID: string;
    counterparty: string;
    referenceData: string;
    description: string;
  }
): Promise<MarketAccount> =>
  Post(`${window.location.protocol}//${organization.OrgApiEndpoint}`, `/v1/organization/accounts`, {
    market: marketAccount.market,
    sourceAccountID: marketAccount.sourceAccountID,
    counterparty: marketAccount.counterparty,
    referenceData: marketAccount.referenceData,
    description: marketAccount.description,
  });

export const deleteAppConfig = (userID: string | null) =>
  Delete(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users/${userID}/app_config`);

export const forceLogout = userID => Delete(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users/${userID}/logout`);

export const changeOrganization = (orgID: number | undefined) =>
  Put(import.meta.env.VITE_AVA_API_ENDPOINT, `/organizations/${orgID}/use`);

export const createResetPasswordUrl = (userID: string) => {
  return Post(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users/${userID}/change-password`);
};

export const sendResetPasswordEmail = (userID: string) => {
  return Post(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users/${userID}/send-reset-password-email`);
};

export const searchUsersByDomain = (domain: string) =>
  Get(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users?search-by-domain=${domain}`);

export const searchUsers = search =>
  Get(import.meta.env.VITE_AVA_API_ENDPOINT, `/admin/users?search=${encodeURIComponent(search)}`);
