import {
  Divider,
  EllipsisText,
  HStack,
  InlineFormattedNumber,
  Text,
  VStack,
  type Currency,
  type DateFormatter,
} from '@talos/kyoko';
import { BlueMarkerBox } from '../../components/BlueMarkerBox';
import type { HistoricalPositionStat } from '../../types';
import type { PnLUnit } from '../types';
import { TOOLTIP_WIDTH } from './tokens';
import { getNumberVariant } from './utils';

interface PerformanceSeriesTooltipProps {
  point: Highcharts.TooltipFormatterContextObject;
  homeCurrencyInfo: Currency | undefined;
  dateFormatter: DateFormatter;
  plotLineX: number | undefined;
  pnlUnit: PnLUnit;
  subAccountLabel: string;
  positionStat: HistoricalPositionStat;
}

export function PerformanceSeriesTooltip({
  point,
  homeCurrencyInfo,
  dateFormatter,
  plotLineX,
  subAccountLabel,
  positionStat,
}: PerformanceSeriesTooltipProps) {
  const showPlotlineDescription = plotLineX === point.x;

  if (point.x == null) {
    return null;
  }

  return (
    <VStack
      gap="spacingDefault"
      alignItems="flex-start"
      fontSize="fontSizeMd"
      minWidth={TOOLTIP_WIDTH}
      overflow="hidden"
    >
      <HStack justifyContent="space-between" w="100%" gap="spacingDefault">
        <Text weight="fontWeightMedium" color="colorTextAttention">
          {dateFormatter(new Date(point.x))}
        </Text>
        <EllipsisText weight="fontWeightMedium" textOverflow="ellipsis" color="colorTextDefault">
          {subAccountLabel}
        </EllipsisText>
      </HStack>
      <HStack justifyContent="space-between" gap="spacingDefault" w="100%">
        <Text>Closing PnL</Text>
        <InlineFormattedNumber
          number={positionStat.PnLTotal}
          increment={homeCurrencyInfo?.DefaultIncrement ?? '0.01'}
          currency={homeCurrencyInfo?.Symbol}
          variant={getNumberVariant(positionStat.PnLTotal)}
          round
        />
      </HStack>
      <HStack justifyContent="space-between" gap="spacingDefault" w="100%">
        <Text>Interval PnL</Text>
        <InlineFormattedNumber
          number={positionStat.IntervalPnL}
          increment={homeCurrencyInfo?.DefaultIncrement ?? '0.01'}
          currency={homeCurrencyInfo?.Symbol}
          variant={getNumberVariant(positionStat.IntervalPnL)}
          round
        />
      </HStack>
      {showPlotlineDescription && (
        <>
          <Divider orientation="horizontal" />
          <HStack gap="spacingDefault">
            <BlueMarkerBox />
            <Text color="colorTextDefault">Date in blotter and insights</Text>
          </HStack>
        </>
      )}
    </VStack>
  );
}
