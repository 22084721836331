import { identity } from 'lodash';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useIntl } from '../../../../hooks';
import { useMarketAccountGroups } from '../../../../hooks/useMarketAccountGroups';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  group: {
    defaultMessage: 'Group',
    id: 'Filters.FilterBuilder.filters.group',
  },
});

/**
 * This hook creates the filter property definition for Market Accounts properties.
 *
 * @returns Filter Property definition to use for Market Accounts filters
 */
export function useMarketAccountGroupsFilter() {
  const { formatMessage } = useIntl();
  const groups = useMarketAccountGroups();

  return useMemo(
    () =>
      ({
        key: 'MarketAccountGroups',
        label: formatMessage(messages.group),
        icon: IconName.UserGroup,
        options: groups,
        getOptionLabel: identity,
      } as const satisfies FilterableProperty),
    [groups, formatMessage]
  );
}
