import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import type { MixpanelEvent } from '../../tokens/mixpanel';
import type { ACTION } from '../../types';
import type { BoxProps } from '../Core';
import type { IconName } from '../Icons/IconNames';

export const isSidebarSubTitle = (object: SidebarSubItem): object is SidebarSubTitle => {
  return 'type' in object && object.type === 'title';
};

export const isSidebarDivider = (object: SidebarSubItem): object is SidebarDivider => {
  return 'type' in object && object.type === 'divider';
};

export const isSidebarSubNavItem = (object: SidebarSubItem): object is SidebarSubNavItem => {
  return 'path' in object && 'component' in object;
};

export type SidebarProps = PropsWithChildren<
  BoxProps & {
    initialPinned?: boolean;
    expandedWidth?: string | number;
  }
>;

export interface SidebarSubNavItem {
  /** Eg "dealer", "portfolio" -- as in without the leading "/" */
  path: string;
  /** Label shown in the Nav Sidebar */
  label: string;
  /** Icon shown in the Nav Sidebar */
  icon?: IconName;
  /** Whether or not this path has subnavitems, such as Dealer having "Monitoring" sub nav item etc. Leave undefined for false. */
  hasSubNavItems?: boolean;
  /** Passed to the authorization function. For example, VIEW_DEALER. Leave undefined for none. */
  requiredPermission?: ACTION;
  /** The component to render when this nav is active */
  component?: JSX.Element;
  /** Mixpanel event to track on click of this SidebarSubNavItem */
  mixpanelEvent?: MixpanelEvent;
  /** The id of the nav item */
  id?: string;
  /** Tour step to show using id as anchor */
  tour?: string;
  /** Indent the tab name with a connector icon */
  menuIndent?: boolean;
}

export interface SidebarSubTitle {
  label: string;
  type: 'title';
  icon?: IconName;
}

export interface SidebarDivider {
  type: 'divider';
}

export interface SidebarCustom {
  type: 'custom';

  [key: string]: unknown;
}

export type SidebarSubItem = SidebarSubNavItem | SidebarSubTitle | SidebarDivider | SidebarCustom;

export interface SidebarContextProps {
  isExpanded: boolean;
  isPinned: boolean;
  showSubNavItems: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  setPinned: Dispatch<SetStateAction<boolean>>;
  setShowSubNavItems: Dispatch<SetStateAction<boolean>>;
}
