import { DELETE, GET, PATCH, request, useEndpointsContext } from '@talos/kyoko';
import { useCallback } from 'react';
import type { Aggregation, AggregationMarket } from '../types';

interface AggregationMarketResponse {
  data: AggregationMarket[];
}

export function useAggregationMarkets(name: Aggregation['Name']) {
  const { orgApiEndpoint } = useEndpointsContext();
  const endpoint = `${orgApiEndpoint}/aggregations/${encodeURIComponent(name)}/markets`;

  const listAggregationMarkets = useCallback(() => request<AggregationMarketResponse>(GET, endpoint, null), [endpoint]);
  const upsertAggregationMarket = useCallback(
    am => request<AggregationMarketResponse>(PATCH, `${orgApiEndpoint}/aggregations/markets`, am),
    [orgApiEndpoint]
  );
  const deleteAggregationMarket = useCallback(
    id => request<AggregationMarketResponse>(DELETE, `${endpoint}/${id}`, null),
    [endpoint]
  );

  return {
    listAggregationMarkets,
    upsertAggregationMarket,
    deleteAggregationMarket,
  };
}

export function useAggregationMarketsWithoutName() {
  const { orgApiEndpoint } = useEndpointsContext();

  const upsertAggregationMarket = useCallback(
    (am: Partial<AggregationMarket>) =>
      request<AggregationMarketResponse>(PATCH, `${orgApiEndpoint}/aggregations/markets`, am),
    [orgApiEndpoint]
  );

  return {
    upsertAggregationMarket,
  };
}
