import {
  LegDirectionEnum,
  OrderMarketStatusEnum,
  SettleValueTypeEnum,
  getCurrencyColor,
  getMarketColor,
  getVWAPFromMarkets,
  isCounterCurrency,
  isMultileg,
  isPerpetualSwap,
  isUnifiedLiquidityOrder,
  notEmpty,
  setAlpha,
  toBigWithDefault,
  useCurrenciesContext,
  useMarketsContext,
  useSecuritiesContext,
  type IOMSExecutionReport4203Markets,
  type Order,
  type RequiredProperties,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import { REMAINDER, type MarketDistributionItem } from './types';
import { getOrderQuantities, useGetMarketProportionGetter } from './utils';

type UseMarketDistributionProps = {
  order: Order;
  includeZeroCumQty?: boolean;
  /**
   * GroupBy key, when null is specified no grouping is done.
   * */
  variant: 'Symbol' | 'Market' | null;
  legIndex?: 0 | 1;
};

export function useMarketDistribution({
  order,
  legIndex,
  includeZeroCumQty = false,
  variant,
}: UseMarketDistributionProps) {
  const { marketsByName } = useMarketsContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { currenciesBySymbol } = useCurrenciesContext();

  const theme = useTheme();
  const sortByKey = variant || 'Market';

  const orderSecurity = securitiesBySymbol.get(order.Symbol);
  const isMultilegOrder = isMultileg(orderSecurity);
  // If the order is multileg, we'll need to use the underlying security for currency display information.
  const maybeLegSecurity = useMemo(() => {
    if (orderSecurity && isMultilegOrder && legIndex !== undefined) {
      const legSymbol = orderSecurity.MultilegDetails?.Legs[legIndex].Symbol || '';
      return securitiesBySymbol.get(legSymbol);
    }
  }, [legIndex, securitiesBySymbol, orderSecurity, isMultilegOrder]);

  /** Since we're not sure if we're displaying a leg or a regular order, we need to know which security to base
   *  our calculations on. */
  const tradedSecurity = maybeLegSecurity || orderSecurity;
  const getMarketProportion = useGetMarketProportionGetter({ order, orderSecurity, tradedSecurity, legIndex });
  const isOrderUnifiedLiquidity = isUnifiedLiquidityOrder(order);
  const isOrderCounterCurrency = isCounterCurrency(order.Currency, tradedSecurity);

  const distribution = useMemo(() => {
    if (!order || !orderSecurity || !tradedSecurity) {
      return [];
    }

    const tradedMarkets = order.Markets.filter(
      (market): market is RequiredProperties<IOMSExecutionReport4203Markets, NonNullable<'Market'>> =>
        // Make sure market is nonNull for secure id
        market.Market != null &&
        // Remove zero cumQty unless specified to include it
        (!(market.CumQty == null || Big(market.CumQty).eq(0)) || includeZeroCumQty) &&
        // Remove disabled market statuses unless traded on
        !(includeZeroCumQty && market.MarketStatus === OrderMarketStatusEnum.Disabled) &&
        // Filter on legIndex if it is defined
        (isMultilegOrder ? market.LegIndex === legIndex : legIndex === undefined)
    );

    const series: MarketDistributionItem[] = sortBy(
      getVWAPFromMarkets(order, securitiesBySymbol, tradedMarkets, variant),
      item => item[sortByKey]
    )
      .map<MarketDistributionItem | undefined>(market => {
        const marketProportion = getMarketProportion(market);

        if (marketProportion == null) {
          return undefined;
        }

        // Due to UL/ML: each Order.Market can trade on a different symbol than
        // their parent. The Size/Price-Currencies needs to be available on each DistributionSeries

        // market.Symbol should always exist, but for some markets it does not. Then grab the order.Symbol
        const symbolSecurity = securitiesBySymbol.get(market.Symbol ?? order.Symbol);
        const isOrderMarketCounterCurrency = isCounterCurrency(market.Currency, symbolSecurity);
        const isParentAndMarketInOppositeCurrencies = isOrderCounterCurrency !== isOrderMarketCounterCurrency;

        let marketSizeCurrency;
        if (isOrderUnifiedLiquidity) {
          // If the order is UL, we *want to normalize the order to the parent currency and amount currency*
          if (isPerpetualSwap(symbolSecurity)) {
            // Special logic for perps, some can be inverted, which means we could be normalizing to either base or quote
            marketSizeCurrency =
              symbolSecurity?.SettleValueType === SettleValueTypeEnum.Inverted
                ? symbolSecurity.QuoteCurrency
                : symbolSecurity?.BaseCurrency;
          } else {
            // For unified liquidity, we normalize to the currency the Order itself is placed in.
            // This'll be something like USD, where the market orders will be in various USD-like currencies: USDT, USDC, USD
            // But because we are unified we already assume these to be the same, so use the main one on the order itself
            // **Secondly**, the parent order can be in counter currency, but the market order might not be. If this is the case that
            // the two are placed in this opposite way, we pick order.AmountCurrency.
            marketSizeCurrency = isParentAndMarketInOppositeCurrencies ? order.AmountCurrency : order.Currency;
          }
        } else {
          // not unified liquidity
          marketSizeCurrency = market.Currency;
        }

        // For orders that are unified liquidity, our price currency should normalize to that of the parent order.
        // In reality, this'll converge market orders priced in USDT, USDC, USD to all be shown in USD terms (unified!)
        // However, if we're showing "by Symbol", then we don't want this differentiation. We then want it to show the actual quote ccy of the market order
        const marketPriceCurrency =
          isOrderUnifiedLiquidity && variant !== 'Symbol'
            ? tradedSecurity.QuoteCurrency
            : symbolSecurity?.QuoteCurrency;

        const marketSizeIncrement = marketSizeCurrency
          ? currenciesBySymbol.get(marketSizeCurrency)?.DefaultIncrement
          : undefined;
        const marketPriceIncrement = symbolSecurity?.DefaultPriceIncrement;

        const marketName = (market.Market && marketsByName.get(market.Market)?.DisplayName) || market.Market;
        const symbol = symbolSecurity?.DisplaySymbol || market.Symbol;
        const name = variant === 'Symbol' ? symbol : marketName;

        let leavesQty = market.LeavesQty;
        // We need to normalize LeavesQty for Unified Perps when variant is null because we're not doing that in getVWAPFromMarkets for this variant
        if (isOrderUnifiedLiquidity && isPerpetualSwap(symbolSecurity)) {
          leavesQty = toBigWithDefault(market.LeavesQty, 0)
            .times(variant === null ? 1 : toBigWithDefault(symbolSecurity?.NotionalMultiplier, 1))
            .toFixed();
        }

        return {
          name: name,
          market: marketName,
          y: marketProportion.toNumber(),
          cumQty: market.CumQty,
          avgPxAllIn: market.AvgPxAllIn,
          avgPx: market.AvgPx,
          color: getColor({ security: symbolSecurity, securitiesBySymbol, market, variant, theme }),
          id: `${market.Market}-${market.Symbol}`,
          cumFee: market.CumFee,
          leavesQty,
          price: market.Price,
          sizeCurrency: marketSizeCurrency,
          priceCurrency: marketPriceCurrency,
          sizeIncrement: marketSizeIncrement,
          priceIncrement: marketPriceIncrement,
          symbol: symbol,
        };
      })
      .filter(notEmpty);

    const { orderQty, cumQty } = getOrderQuantities({ order, orderSecurity, legIndex });
    // Calculate the remainder by subtracting calculated orderQty from cumqty.
    const remainderY = orderQty.eq(0) ? Big(0) : orderQty.sub(cumQty).div(orderQty);

    series.push({
      id: REMAINDER,
      y: remainderY.toNumber(),
      color: theme.colors.gray['030'],
      symbol: 'N/A',
      market: 'N/A',
    });

    return series;
  }, [
    order,
    orderSecurity,
    tradedSecurity,
    isOrderCounterCurrency,
    variant,
    sortByKey,
    legIndex,
    theme,
    includeZeroCumQty,
    getMarketProportion,
    securitiesBySymbol,
    marketsByName,
    isOrderUnifiedLiquidity,
    isMultilegOrder,
    currenciesBySymbol,
  ]);

  return { distribution };
}

function getColor({
  security,
  securitiesBySymbol,
  market,
  variant,
  theme,
}: {
  security: Security | undefined;
  securitiesBySymbol: Map<string, Security>;
  market: RequiredProperties<IOMSExecutionReport4203Markets, 'Market'>;
  variant: string | null;
  theme: DefaultTheme;
}) {
  const currency = security?.QuoteCurrency;
  let color: MarketDistributionItem['color'] = theme.colors.blue.lighten;
  if (variant === 'Symbol' && currency) {
    color = getCurrencyColor(currency) ?? color;
    if (isMultileg(security)) {
      const leg = security.MultilegDetails?.Legs[0];
      if (leg) {
        const legSecurity = leg?.Symbol ? securitiesBySymbol.get(leg.Symbol) : undefined;
        const legCurrency =
          leg.Direction === LegDirectionEnum.Same ? legSecurity?.QuoteCurrency : legSecurity?.BaseCurrency;
        color = (legCurrency && getCurrencyColor(legCurrency)) ?? color;
      } else {
        color = setAlpha(0.75, color);
      }
    }
  } else {
    color = getMarketColor(market.Market, theme) ?? color;
  }

  return color;
}
