import { useTheme } from 'styled-components';

import type { Allocation, AllocationValueTypeEnum } from '@talos/kyoko';

import { truncate } from 'lodash';
import { useSubAccounts } from '../../../providers';
import { prettifyAllocationAmount } from '../../../utils/allocation';

export interface OMSCardTradeAllocationsProps {
  subAccountAllocations: Allocation[];
  allocationValueType?: AllocationValueTypeEnum;
  currency: string;
}

export const OMSCardTradeAllocations = ({
  subAccountAllocations,
  allocationValueType,
  currency,
}: OMSCardTradeAllocationsProps) => {
  const theme = useTheme();
  const { subAccountsByName } = useSubAccounts();
  return (
    <table cellSpacing="0" data-testid="oms-card-sub-accounts">
      <tbody>
        {subAccountAllocations.map(alloc => {
          if (alloc.subAccount == null) {
            return null;
          }

          const amount = prettifyAllocationAmount(alloc, allocationValueType, currency);
          const subAccount = truncate(subAccountsByName?.get(alloc.subAccount)?.DisplayName ?? alloc.subAccount, {
            length: 30,
          });
          return (
            <tr key={alloc.subAccount}>
              <td style={{ textAlign: 'right', paddingRight: theme.spacingSmall }}>{amount}</td>
              <td>{subAccount}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
