import { ACTION } from '@talos/kyoko';
import { useFeatureFlag, useRoleAuth } from '../../../hooks';
import { NoMatchPage } from '../../NoMatchPage';
import { TradingControlsBlotter } from './TradingControlsBlotter';

export function TradingControls() {
  const { isAuthorized } = useRoleAuth();
  const { enableTradingControls } = useFeatureFlag();

  if (enableTradingControls && isAuthorized(ACTION.VIEW_TRADING_CONTROLS)) {
    return <TradingControlsBlotter />;
  } else {
    return <NoMatchPage />;
  }
}
