import { SUB_ACCOUNT_RECON_MATCH, type SubAccountReconMatch, useSubscription, useWSFilterPipe } from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { map } from 'rxjs';
import { buildReconMatchRows } from './reconMatchRows';
import type { BreakDetailsBlotterFilter } from './types';

function getUniqueKey(match: SubAccountReconMatch) {
  return match.ID;
}

export const useReconMatchesObs = (filter: BreakDetailsBlotterFilter, checkpointID: string) => {
  const checkpointIDs = useMemo(() => [checkpointID], [checkpointID]);
  const { data } = useSubscription<SubAccountReconMatch>({
    name: SUB_ACCOUNT_RECON_MATCH,
    tag: 'useReconMatches',
    CheckpointIDs: checkpointIDs,
  });

  const filterFunc = useCallback(
    (item: SubAccountReconMatch) => {
      if (filter.Statuses && !filter.Statuses.includes(item.Status)) {
        return false;
      }

      return true;
    },
    [filter.Statuses]
  );

  const filterPipe = useWSFilterPipe({ getUniqueKey, filterFunc });
  const outputObs = useMemo(
    () =>
      data.pipe(
        filterPipe,
        map(json => ({ ...json, data: json.data.flatMap(buildReconMatchRows) }))
      ),
    [data, filterPipe]
  );

  return outputObs;
};
