import {
  useBehaviorSubject,
  useObservable,
  useSubscription,
  type SubscriptionResponse,
  type UseSubscriptionOptions,
  type WebsocketRequest,
} from '@talos/kyoko';
import { get, isEqual, set } from 'lodash';
import { useEffect, useState } from 'react';
import { filter, map, pairwise, startWith, tap, withLatestFrom } from 'rxjs';
import { v4 as uuid } from 'uuid';

interface UsePortfolioChartSubscriptionParams<Request extends WebsocketRequest> {
  /** Standard useSubscription request */
  request: Request;
  /** Standard UseSubscription options object */
  subscriptionOptions: UseSubscriptionOptions;
  /** Keys to the fields on the request which, when changed, should result in the data in the chart being reset. */
  resetDataOnRequestFieldChanges: (keyof Request)[];
}

/**
 * This hook wraps useSubscription and allows you to pipe in some resetting metadata
 * into the return type of the pipe itself.
 *
 * When the `resetOnChangeOfVariable` variable changes, this hook will return `dataResetting: true`, and then once
 * the first subsequent subscription response comes in, will set `dataResetting` back to `false` and also attach a `reset: true`
 * property to the subscription result.
 *
 * We are essentially replacing the more standard `initial: true` behavior and implications with our own interpretation of a "reset" action.
 */
export const usePortfolioChartSubscription = <T, Request extends WebsocketRequest>({
  request,
  subscriptionOptions,
  resetDataOnRequestFieldChanges,
}: UsePortfolioChartSubscriptionParams<Request>) => {
  // We also want to keep track of whether or not the data is "resetting" for our consumer.
  // The implementer of this hook sets which fields on the request that, when changed, should result in a "data reset" event.
  // See in the "wrappedSubscription" below how new resetObservable
  // emissions lead to "reset" being passed along, instructing consumers to reset data on the next data receival

  const [resetter, setResetter] = useState<Partial<Request> | null>(null);
  useEffect(() => {
    setResetter(prevFields => {
      const newFields = getFields(request, resetDataOnRequestFieldChanges);
      return isEqual(prevFields, newFields) ? prevFields : newFields;
    });
  }, [request, resetDataOnRequestFieldChanges]);

  const [dataResetting, setDataResetting] = useState(true);
  useEffect(() => {
    setDataResetting(true);
  }, [resetter]);

  const { observable: resetObservable } = useBehaviorSubject(() => resetter, [resetter]);

  const { isLoading, data } = useSubscription<T>(request, subscriptionOptions);

  // Wrap the subscription here and just tap emissions to flip the state of dataLoading
  const wrappedSubscription = useObservable(
    () =>
      data.pipe(
        tap(() => {
          setDataResetting(false);
        }),
        withLatestFrom(resetObservable.pipe(map(() => uuid()))), // attach a uuid which will be changed whenever the filters change
        startWith([undefined, undefined]), // pairwise will not forward any messages unless two messages have come in. so we give it an empty fake message here to start with. https://rxjs.dev/api/operators/pairwise
        pairwise(), // pairwise simply forwards the previous and next message together as a tuple
        map(([prev, next]) => {
          // Always forward the new data, but compare the filterID we attached to the prev and next message.
          // If the filter id has changed, we need to pass a reset: true message!
          const isFirstMessage = prev[1] == null;
          const reset = !isFirstMessage && prev[1] !== next[1];
          return { json: next[0], reset };
        }),
        filter(wrappedMessageIsDefined) // just for typing properly
      ),
    [data, resetObservable]
  );

  return {
    subscription: wrappedSubscription,
    isLoading,
    dataResetting,
  };
};

function wrappedMessageIsDefined<T>(wrappedMessage: {
  json: SubscriptionResponse<T, string> | undefined;
  reset: boolean;
}): wrappedMessage is {
  json: SubscriptionResponse<T, string>;
  reset: boolean;
} {
  return wrappedMessage.json != null;
}

function getFields<T, FieldsToGet extends keyof T>(object: T, fieldsToGet: FieldsToGet[]) {
  const derivedObject = {};
  fieldsToGet.forEach(field => {
    set(derivedObject, field, get(object, field));
  });
  return derivedObject;
}
