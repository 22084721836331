import { HStack, Icon, IconName, MarketAccountStatusEnum, Text } from '@talos/kyoko';
import { transparentize } from 'polished';
import { useTheme } from 'styled-components';

interface TradingStatusPops {
  status: MarketAccountStatusEnum | undefined;
}

export function TradingStatus({ status }: TradingStatusPops) {
  const theme = useTheme();

  let iconColor: string;
  let icon: IconName;
  let text;

  if (!status) {
    return null;
  }

  switch (status) {
    case MarketAccountStatusEnum.Active:
      iconColor = theme.colors.green.lighten;
      icon = IconName.CheckCircleSolid;
      text = 'Trading is currently enabled';
      break;
    case MarketAccountStatusEnum.Inactive:
      iconColor = theme.colors.yellow.lighten;
      icon = IconName.ExclamationCircleSolid;
      text = 'Market Account is Inactive';
      break;
    case MarketAccountStatusEnum.Disabled:
      iconColor = theme.colors.red.lighten;
      icon = IconName.ExclamationCircleSolid;
      text = 'Trading is currently disabled';
      break;
    default: {
      const _exhaustiveCheck: never = status;
      throw new Error(`Unknown Market Account Status : ${status}`);
    }
  }

  return (
    <HStack
      gap="spacingDefault"
      background={transparentize(0.96, theme.colors.gray['100'])}
      p="spacingMedium"
      justifyContent="flex-start"
    >
      <>
        <Icon color={iconColor} icon={icon} />
        <Text color="colors.gray.100">{text}</Text>
      </>
    </HStack>
  );
}
