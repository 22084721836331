import {
  IconName,
  InlineFormattedNumber,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useSecuritiesContext,
  type Order,
} from '@talos/kyoko';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { memo, useMemo } from 'react';
import { EmptyRowsDisplay } from '../EmptyRowsDisplay';
import { DistributionTable, DistributionWrapper, LegendIndicator, MarketName } from '../styles';
import { REMAINDER } from '../types';
import { useChartOptions } from '../useChartOptions';
import { useMarketDistribution } from '../useMarketDistribution';

const SYMBOL_STYLE = { width: 'fit-content', textWrap: 'wrap' } as const;

interface SymbolDistributionChartProps {
  order: Order;
  legIndex?: 0 | 1;
}

export const SymbolDistributionChart = memo(function SymbolDistributionChart({
  order,
  legIndex,
}: SymbolDistributionChartProps) {
  const { distribution } = useMarketDistribution({
    order: order,
    includeZeroCumQty: false,
    legIndex,
    variant: 'Symbol',
  });

  const highchartOptions = useChartOptions({ distribution, centerText: 'Symbol Distribution' });
  const remainderFreeDistribution = useMemo(() => distribution.filter(d => d.id !== REMAINDER), [distribution]);

  const { securitiesBySymbol } = useSecuritiesContext();
  const currentSymbol =
    (legIndex != null && securitiesBySymbol.get(order.Symbol)?.MultilegDetails?.Legs[legIndex].Symbol) || order.Symbol;
  const currentSecurity = securitiesBySymbol.get(currentSymbol);

  if (highchartOptions == null) {
    return null;
  }

  return (
    <DistributionWrapper>
      <HighchartsReact highcharts={Highcharts} options={highchartOptions} />
      <DistributionTable w="100%">
        <Thead>
          <Tr>
            <Th>Symbol</Th>
            <Th align="left" />
            <Th align="right">Average price</Th>
            <Th align="right">Quantity</Th>
            <Th align="right">Fees</Th>
            <Th align="right">Pct</Th>
          </Tr>
        </Thead>
        {remainderFreeDistribution.length !== 0 && (
          <Tbody>
            {remainderFreeDistribution.map(market => {
              return (
                <Tr key={market.id}>
                  <Td style={SYMBOL_STYLE} minimize>
                    <MarketName title={market.symbol}>{market.symbol}</MarketName>
                  </Td>
                  <Td align="left">
                    <LegendIndicator background={market.color} />
                  </Td>
                  <Td minimize>
                    <InlineFormattedNumber
                      pretty
                      number={market.avgPxAllIn}
                      startIcon={IconName.Fees}
                      currency={market.priceCurrency}
                      increment={market.priceIncrement}
                    />
                  </Td>
                  <Td minimize>
                    <InlineFormattedNumber
                      pretty
                      number={market.cumQty}
                      currency={market.sizeCurrency}
                      increment={market.sizeIncrement}
                    />
                  </Td>
                  <Td minimize>
                    <InlineFormattedNumber
                      number={market.cumFee}
                      currency={currentSecurity?.QuoteCurrency}
                      increment={currentSecurity?.DefaultPriceIncrement}
                    />
                  </Td>
                  <Td minimize>{`${(market.y * 100 || 0).toFixed(2)}%`}</Td>
                </Tr>
              );
            })}
          </Tbody>
        )}
      </DistributionTable>
      {remainderFreeDistribution.length === 0 && <EmptyRowsDisplay />}
    </DistributionWrapper>
  );
});
