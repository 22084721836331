import { formattedDateForSubscription, useObservable, type DateRangeFilter } from '@talos/kyoko';
import { isEqual } from 'lodash';
import { createContext, useContext, useMemo, useState } from 'react';
import { Subject, filter, map, pairwise, startWith, type Observable } from 'rxjs';

export enum ChartMessageType {
  VisibleAreaChanged,
}

export interface VisibleAreaChangedMessage {
  type: ChartMessageType.VisibleAreaChanged;
  payload: {
    min: number;
    max: number | undefined;
  };
}

export type ChartMessage = VisibleAreaChangedMessage;

export interface ChartMessagingContextProps {
  messenger: Subject<ChartMessage>;
  listener: Observable<ChartMessage>;
}

export const ChartMessagingContext = createContext<ChartMessagingContextProps | undefined>(undefined);

export function useChartMessaging() {
  const context = useContext(ChartMessagingContext);
  if (context === undefined) {
    throw new Error('Missing ChartMessagingContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const ChartMessagingProvider = function ChartMessagingProvider({ children }) {
  const [subject] = useState(() => {
    return new Subject<ChartMessage>();
  });

  const value = useMemo(() => {
    return {
      messenger: subject,
      // Convert the listener to an observable variant of the subject just so you can't send messages using the listener
      listener: subject.asObservable(),
    };
  }, [subject]);

  return <ChartMessagingContext.Provider value={value}>{children}</ChartMessagingContext.Provider>;
};

/**
 * A hook which connects to the ChartMessagingProvider, returning an observable
 * which will emit VisibleAreaChanged messages but transformed into DateRangeFilter objects
 * for use in mutating your chart request date range.
 *
 * The returned observable is not debounced and probably should be debounced before use.
 */
export const useChartDateRangeObs = () => {
  const { listener } = useChartMessaging();

  const chartDateRangeObs = useObservable<DateRangeFilter>(
    () =>
      listener.pipe(
        filter(chartMessageIsVisibleAreaMessage),
        map(message => ({ ...message.payload })),
        startWith({ min: undefined, max: undefined }),
        pairwise(),
        filter(([prev, next]) => {
          const isFirstMessage = prev[0] == null;
          if (isFirstMessage) {
            return true;
          }

          return !isEqual(prev, next); // allow message if prev and next are different
        }),
        map(([_, { min, max }]) => {
          return {
            StartDate: min != null ? formattedDateForSubscription(new Date(min)) : undefined,
            EndDate: max != null ? formattedDateForSubscription(new Date(max)) : undefined,
          };
        })
      ),
    [listener]
  );

  return chartDateRangeObs;
};

// Need to create a function here to assert the typing properly in an rxjs filter pipe
function chartMessageIsVisibleAreaMessage(message: ChartMessage): message is VisibleAreaChangedMessage {
  return message.type === ChartMessageType.VisibleAreaChanged;
}
