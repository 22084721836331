import { HStack, Icon, IconName, MarketAccountStatusEnum, Text, VStack } from '@talos/kyoko';
import type { TradingControlsBlotterEntity } from '../types';

interface SelectedMarketAccountsListProps {
  marketAccounts: TradingControlsBlotterEntity[];
  mode: MarketAccountStatusEnum;
}

const ITEMS_TO_SHOW = 5;

/*
 * This component is used to display the selected Market Accounts in the enable/disable.
 * When the number of Market Accounts is greater than 5, it will display the first 5 Market Accounts
 * and a message indicating the number of Market Accounts that are not shown.
 */
export function SelectedMarketAccountsList({ marketAccounts, mode }: SelectedMarketAccountsListProps) {
  return (
    <>
      {marketAccounts.map((marketAccount, idx, arr) => {
        if (idx < ITEMS_TO_SHOW) {
          return (
            <HStack key={marketAccount.MarketAccountID} justifyContent="space-between">
              <Text>{marketAccount.DisplayName ?? marketAccount.Name}</Text>
              <HStack gap="spacingSmall">
                <Icon icon={IconName.ArrowRight} />
                <Text fontWeight="bold">{mode === MarketAccountStatusEnum.Active ? 'Enable' : 'Disable'}</Text>
              </HStack>
            </HStack>
          );
        }
        if (idx === arr.length - 1) {
          return (
            <VStack key={marketAccount.MarketAccountID} alignItems="flex-start">
              <strong>+ {arr.length - ITEMS_TO_SHOW} Market Accounts</strong>
            </VStack>
          );
        }
        return null;
      })}
    </>
  );
}
