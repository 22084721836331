import {
  Box,
  Button,
  ButtonVariants,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  IndicatorBadge,
  IndicatorDotVariants,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  useDynamicCallback,
  type DrawerProps,
  type SubAccount,
} from '@talos/kyoko';
import { useSubAccounts } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { Prompt } from 'react-router';
import { SubAccountDrawerHeader } from './styles';
import { useSubAccountGeneralTab } from './tabs/SubAccountGeneralTab';
import { useSubAccountRollupMembersTab } from './tabs/SubAccountRollupMembershipsTab';
import { useSubAccountUsersTab } from './tabs/SubAccountUsersTab';
import { useSubAccountsMarketAccountsTab } from './tabs/SubAccountsMarketAccountsTab';

type EditSubAccountsDrawerProps = DrawerProps & { subAccountName: SubAccount['Name'] | null };

export function EditSubAccountsDrawer({ subAccountName, ...drawerProps }: EditSubAccountsDrawerProps) {
  const { subAccountsByName } = useSubAccounts();

  const subAccount = subAccountName ? subAccountsByName?.get(subAccountName) : undefined;

  return (
    <Drawer {...drawerProps} data-testid="edit-sub-accounts-drawer">
      {subAccount && <EditSubAccounts key={subAccount.Name} subAccount={subAccount} {...drawerProps} />}
    </Drawer>
  );
}

function EditSubAccounts({ subAccount, close, isOpen }: { subAccount: SubAccount } & DrawerProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);

  const generalTab = useSubAccountGeneralTab({
    subAccount,
  });
  const usersTab = useSubAccountUsersTab({ subAccount });
  const marketAccountsTab = useSubAccountsMarketAccountsTab({ subAccount });
  const rollupMembershipsTab = useSubAccountRollupMembersTab({
    subAccount,
  });

  const viewableTabs = useMemo(() => {
    return [generalTab, usersTab, marketAccountsTab, rollupMembershipsTab].filter(tab => tab.viewable);
  }, [generalTab, usersTab, marketAccountsTab, rollupMembershipsTab]);

  const handleSaveChanges = useCallback(() => {
    viewableTabs.forEach(tab => tab.save());
  }, [viewableTabs]);

  const isDirty = viewableTabs.some(tab => tab.isDirty);
  const isDirtyMap = new Map(viewableTabs.map(tab => [tab.name, tab.isDirty]));

  const handleClose = useDynamicCallback(() => {
    if (!isDirty) {
      return close();
    }

    const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      close();
    }
  });

  return (
    <>
      <Prompt when={isOpen && isDirty} message="You have unsaved changes. Are you sure you want to leave?" />
      <SubAccountDrawerHeader>
        <Flex gap="spacingSmall">
          <Text color="colorTextImportant">{subAccount.DisplayName}</Text>
        </Flex>
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
      </SubAccountDrawerHeader>
      <DrawerContent>
        <Tabs
          data-testid="edit-user-subaccount-tabs"
          w="100%"
          h="100%"
          selectedIndex={activeTabIndex}
          onSelect={handleTabChange}
          size={TabSize.Large}
        >
          <TabList isBordered flex="0 0 auto">
            {viewableTabs.map(tab => (
              <Tab
                data-testid={`edit-user-subaccount-tab-${tab.name}`}
                showDot={isDirtyMap.get(tab.name)}
                dotVariant={IndicatorDotVariants.Primary}
                label={tab.name}
                suffix={tab.badgeContent ? <IndicatorBadge children={tab.badgeContent} /> : null}
                key={tab.name}
              />
            ))}
          </TabList>
          <Flex h="100%" flexDirection="column" pt="spacingMedium">
            {viewableTabs.map(tab => (
              <Box
                key={tab.name}
                display={viewableTabs[activeTabIndex].name === tab.name ? 'flex' : 'none'}
                w="100%"
                h="100%"
              >
                {tab.component}
              </Box>
            ))}
          </Flex>
        </Tabs>
      </DrawerContent>
      <DrawerFooter>
        <Button onClick={handleClose}>Close</Button>
        <Button
          data-testid="subaccounts-drawer-save-button"
          onClick={handleSaveChanges}
          disabled={!isDirty}
          variant={ButtonVariants.Primary}
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
}
