import { PUT, request, useDynamicCallback, useEndpointsContext, type LedgerUpdateTypeEnum } from '@talos/kyoko';

export enum PositionTypeEnum {
  MarketAccountPosition = 'MarketAccountPosition',
  SubAccountPosition = 'SubAccountPosition',
}

export interface UpdatePositionParams {
  Type: LedgerUpdateTypeEnum;
  AccountName: string;
  Asset: string;
  Amount: string;
  AvgCost?: string;
  AvgCostCurrency?: string;
  LastUpdateTime?: string;
  Comments?: string;
}

export const usePositionRequests = () => {
  const { orgApiEndpoint } = useEndpointsContext();

  const updatePosition = useDynamicCallback((params: UpdatePositionParams, positionType: PositionTypeEnum) => {
    // Note that we are passing an array of params here, the backend accepts an array but for now for simplicity we just allow one update in this function
    return request(
      PUT,
      `${orgApiEndpoint}/positions/${
        positionType === PositionTypeEnum.MarketAccountPosition ? 'market-accounts' : 'subaccounts'
      }`,
      [params]
    );
  });

  return {
    updatePosition,
  };
};
