import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { pushUnique, type CustomerUserApiKey } from '@talos/kyoko';
import { initCustomerUser } from '../userDetails/userDetailsSlice';

function getInitialState() {
  return {
    // setting as undefined instead of [] in order to display the loading spinner in ag-grid
    keys: undefined as CustomerUserApiKey[] | undefined,
    dirtyKeys: [] as string[],
    keysToDelete: [] as string[],
    fetching: false,
  };
}

export const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState: getInitialState(),
  reducers: {
    fetchingKeys: state => {
      state.fetching = true;
    },
    setApiKeys: (state, { payload: apiKeys }: PayloadAction<CustomerUserApiKey[]>) => {
      state.keys = apiKeys.map(apiKey => ({ ...apiKey, WhitelistedIps: apiKey.WhitelistedIps ?? '' }));
      state.fetching = false;
    },
    addApiKey: (state, { payload: apiKey }: PayloadAction<CustomerUserApiKey>) => {
      state.keys?.push(apiKey);
    },
    updateApiKey: (state, { payload }: PayloadAction<CustomerUserApiKey>) => {
      if (state.keys) {
        const keyToUpdate = getKey(state.keys, payload.CustomerUserApiKeyID);
        if (keyToUpdate) {
          keyToUpdate.WhitelistedIps = payload.WhitelistedIps;
          state.dirtyKeys = pushUnique(state.dirtyKeys, payload.CustomerUserApiKeyID);
        }
      }
    },
    deleteApiKey: (state, { payload }: PayloadAction<CustomerUserApiKey>) => {
      state.keysToDelete = pushUnique(state.keysToDelete, payload.CustomerUserApiKeyID);
    },
    apiKeySaved: (state, { payload: apiKey }: PayloadAction<string>) => {
      state.dirtyKeys = state.dirtyKeys.filter(key => key !== apiKey);
    },
    apiKeyDeleted: (state, { payload: apiKey }: PayloadAction<string>) => {
      // even though the drawer closes after saving, we do all this anyway because if any of the steps fail the drawer remains open,
      // and it should be displayed in a consistent state to what did actually succeed.
      state.keysToDelete = state.keysToDelete.filter(key => key !== apiKey);
      state.keys = state.keys?.filter(key => key.CustomerUserApiKeyID !== apiKey);
    },
  },
  extraReducers: builder => {
    builder.addCase(initCustomerUser, _ => {
      return getInitialState();
    });
  },
});

type ApiKeysState = ReturnType<typeof apiKeysSlice.reducer>;
export function isApiKeysTabDirty(state: ApiKeysState) {
  return state.keysToDelete.length > 0 || state.dirtyKeys.length > 0;
}

function getKey(keys: CustomerUserApiKey[], customerUserApiKeyId: string) {
  return keys.find(key => key.CustomerUserApiKeyID === customerUserApiKeyId);
}

export const { setApiKeys, addApiKey, updateApiKey, deleteApiKey, fetchingKeys, apiKeySaved, apiKeyDeleted } =
  apiKeysSlice.actions;
