import { formattedDateForSubscription, orderStatusFilterTextToBackendStatus, parseDate } from '@talos/kyoko';
import { useUser } from 'hooks';
import { compact, isEmpty, keys, pick } from 'lodash';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { useCallback } from 'react';
import { exportOMSOrders } from 'utils/blotterQueries';
import type { BlotterTableOrderFilter } from './useOrderFilter';
type UseHandleExportOrdersProps = {
  filter: BlotterTableOrderFilter;
};

export function useHandleExportOrders({ filter }: UseHandleExportOrdersProps) {
  const user = useUser();
  const { subAccountsByName } = useSubAccounts();

  return useCallback(() => {
    const serverFilter = onlyServerFilterKeys(filter) || {};
    const stringFilters = keys(serverFilter)
      .map(key => {
        const value: string[] = serverFilter[key];
        if (isEmpty(value)) {
          return undefined;
        }
        switch (key) {
          case 'StartDate':
          case 'EndDate':
            return undefined;
          case 'Statuses':
            return `OrdStatus:${value}`;
          case 'Users':
            return `User:${value}`;
          case 'Symbols':
            return `Symbol:${value}`;
          case 'SubAccounts':
            // Map sub accont Names to their IDs to make this API happy
            return `SubAccountID:${compact(value.map(subAcctName => subAccountsByName?.get(subAcctName)))}`;
        }
        return `${key}:${value}`;
      })
      .compact();

    exportOMSOrders(user.OrgApiEndpoint, {
      startDate: formattedDateForSubscription(serverFilter.StartDate),
      endDate: formattedDateForSubscription(serverFilter.EndDate || parseDate(null)),
      orderBy: '-SubmitTime',
      filter: stringFilters.length > 0 ? stringFilters : undefined,
    });
  }, [filter, subAccountsByName, user.OrgApiEndpoint]);
}

function onlyServerFilterKeys(filter: BlotterTableOrderFilter | undefined) {
  if (!filter) {
    return filter;
  }
  const serverFilter = pick(filter, ['StartDate', 'EndDate', 'Statuses', 'SubAccounts', 'Symbols', 'HideApiCalls']);
  if (filter._statuses) {
    serverFilter.Statuses = orderStatusFilterTextToBackendStatus(filter._statuses);
  }
  return serverFilter;
}
