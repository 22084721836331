import Big from 'big.js';
import { useMarketsContext } from 'providers';
import { useCallback, useEffect, useMemo, useState, type ChangeEvent } from 'react';
import { useBalances, useBalancesRequests } from '../../../hooks';

import {
  Dialog,
  FormGroup,
  Input,
  MarketTypeEnum,
  MixpanelEvent,
  NotificationVariants,
  SearchSelect,
  Text,
  format,
  useGlobalToasts,
  useMarketAccountsContext,
  useMixpanel,
  useObservableValue,
  type DialogProps,
  type Market,
  type MarketAccount,
} from '@talos/kyoko';

import { AccountCurrencyWrapper, SummaryLines, SummaryWrapper } from '../styles';
const MAX_INPUT_LENGTH = 25;

interface BalanceValues {
  accountID?: number;
  amount?: string;
  market?: string;
  currency?: string;
}

interface SummaryValues {
  previous?: string;
  difference?: string;
  new?: string;
}

interface UpdateBalancesDialogProps extends DialogProps {
  initialBalanceValues: BalanceValues;
}

const getMarketLabel = (m: Market) => m.DisplayName ?? m.Name;
const getMarketAccountLabel = (ma: MarketAccount) => ma.DisplayName ?? ma.Name;
const getCurrencyLabel = (c: string) => c;

export const UpdateBalancesDialog = ({ initialBalanceValues, ...props }: UpdateBalancesDialogProps) => {
  const { marketAccountsList, marketAccountsByID } = useMarketAccountsContext();
  const { marketsByName, marketsList } = useMarketsContext();
  const [summaryValues, setSummaryValues] = useState<SummaryValues>({});
  const [initialBalance, setInitialBalance] = useState<string | undefined>(undefined);
  const [balanceValues, setBalanceValues] = useState<BalanceValues>({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  // We need all the balances in order to create the set of currencies you can modify balances for
  const { balancesByMarketAccountIDCurrencyObs, balancesByCurrencyMarketAccountIDObs } = useBalances({
    filter: {},
    showZeroBalances: true,
    tag: 'Update Balances Dialog',
    openStreamWithoutFilter: true,
  });
  const balancesByMarketAccountIDCurrency = useObservableValue(
    () => balancesByMarketAccountIDCurrencyObs,
    [balancesByMarketAccountIDCurrencyObs]
  );
  const balancesByCurrencyMarketAccountID = useObservableValue(
    () => balancesByCurrencyMarketAccountIDObs,
    [balancesByCurrencyMarketAccountIDObs]
  );

  const { updateBalance } = useBalancesRequests();

  const { add: addToast } = useGlobalToasts();
  const [shouldUpdateInitialBalance, setShouldUpdateInitialBalance] = useState(true);
  const { close } = props;

  const mixpanel = useMixpanel();

  // Filter out invalid number errors in the inputs
  const filteredAmount = balanceValues.amount == null || balanceValues.amount === '' ? '0' : balanceValues.amount;

  const marketAccountOptions = useMemo(() => {
    if (balanceValues?.market == null) {
      return [];
    }
    return marketAccountsList.filter(ma => ma.Market === balanceValues.market);
  }, [marketAccountsList, balanceValues]);

  const marketOptions = useMemo(() => {
    return marketsList.filter(market => market.Type === MarketTypeEnum.External);
  }, [marketsList]);

  const currencyOptions = useMemo(() => {
    // Grab the keys from the first layer of the balances by currency map and use these as options
    return [...(balancesByCurrencyMarketAccountID?.keys() ?? [])];
  }, [balancesByCurrencyMarketAccountID]);

  useEffect(() => {
    setBalanceValues(initialBalanceValues);
    setShouldUpdateInitialBalance(false); // Prevent triggering for balances updates
  }, [initialBalanceValues]);

  useEffect(() => {
    // Allow resetting the input box to new found balance when currency & accountID changes
    setShouldUpdateInitialBalance(true);
  }, [balanceValues.currency, balanceValues.accountID]);

  useEffect(() => {
    if (balanceValues.accountID == null || balanceValues.currency == null) {
      return;
    }

    const foundBalance = balancesByMarketAccountIDCurrency
      ?.get(balanceValues.accountID)
      ?.get(balanceValues.currency)?.Amount;
    if (foundBalance) {
      setInitialBalance(foundBalance);
    } else {
      setInitialBalance('0');
    }
  }, [
    balanceValues.accountID,
    balanceValues.currency,
    balancesByMarketAccountIDCurrency,
    setBalanceValues,
    shouldUpdateInitialBalance,
  ]);

  useEffect(() => {
    shouldUpdateInitialBalance && setBalanceValues(prev => ({ ...prev, amount: initialBalance }));
  }, [initialBalance, shouldUpdateInitialBalance]);

  useEffect(() => {
    if (filteredAmount == null || balanceValues?.currency == null) {
      return setSummaryValues({});
    }
    const newValue = Big(filteredAmount).toFixed();
    const diffAmount = Big(newValue || 0)
      .minus(initialBalance || 0)
      .toFixed();

    setSummaryValues({
      previous: `${format(initialBalance || 0)} ${balanceValues.currency}`,
      new: `${format(newValue || 0)} ${balanceValues.currency}`,
      difference: `${Big(diffAmount).gt(0) ? '+' : ''}${format(diffAmount || 0)} ${balanceValues.currency}`,
    });
  }, [filteredAmount, balanceValues.currency, initialBalance]);

  const handleOnConfirm = useCallback(() => {
    if (balanceValues.accountID == null || balanceValues.currency == null || balanceValues.amount == null) {
      addToast({
        text: 'Account, Currency and Amount fields are required.',
        variant: NotificationVariants.Negative,
        dismissable: false,
      });
      return;
    }

    setConfirmLoading(true);
    mixpanel.track(MixpanelEvent.UpdateBalances);
    return updateBalance({
      AccountID: balanceValues.accountID,
      Currency: balanceValues.currency,
      Amount: balanceValues.amount,
    })
      .then(() => {
        addToast({
          text: 'Balance update successful.',
          variant: NotificationVariants.Positive,
          dismissable: false,
        });
        close();
      })
      .catch(error => {
        addToast({
          text: error?.toString() || 'Could not update balance.',
          variant: NotificationVariants.Negative,
          dismissable: false,
        });
      })
      .then(() => {
        setConfirmLoading(false);
      });
  }, [addToast, balanceValues.accountID, balanceValues.amount, balanceValues.currency, close, updateBalance, mixpanel]);

  const amountInvalid =
    balanceValues?.amount !== '' && balanceValues?.amount !== '-' && balanceValues?.amount !== filteredAmount;
  const confirmDisabled = amountInvalid || filteredAmount === '' || Big(filteredAmount || 0).eq(initialBalance || 0);

  const handleMarketChange = useCallback((newMarket: Market | undefined) => {
    setBalanceValues(prev => ({ ...prev, market: newMarket?.Name, accountID: undefined }));
  }, []);

  const handleAccountChange = useCallback((newMktAcc: MarketAccount | undefined) => {
    setBalanceValues(prev => ({ ...prev, accountID: newMktAcc?.MarketAccountID }));
  }, []);

  const handleCurrencyChange = useCallback((newCurrency: string | undefined) => {
    setBalanceValues(prev => ({ ...prev, currency: newCurrency }));
  }, []);

  const handleAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length < MAX_INPUT_LENGTH) {
      setBalanceValues(prev => ({ ...prev, amount: value }));
      setShouldUpdateInitialBalance(false);
    }
  }, []);

  if (!balancesByMarketAccountIDCurrency || !balancesByCurrencyMarketAccountID) {
    return null;
  }

  return (
    <Dialog
      {...props}
      width={450}
      confirmLabel="Confirm"
      title="Update Balance"
      confirmDisabled={confirmDisabled}
      showClose={true}
      confirmLoading={confirmLoading}
      onConfirm={handleOnConfirm}
    >
      <SummaryWrapper>
        <FormGroup label="Market">
          <SearchSelect
            selection={balanceValues.market != null ? marketsByName.get(balanceValues.market) : undefined}
            options={marketOptions}
            getLabel={getMarketLabel}
            onChange={handleMarketChange}
          />
        </FormGroup>
        <AccountCurrencyWrapper>
          <div style={{ width: '100%' }}>
            <FormGroup label="Account">
              <SearchSelect
                selection={
                  balanceValues?.accountID != null ? marketAccountsByID.get(balanceValues.accountID) : undefined
                }
                options={marketAccountOptions}
                getLabel={getMarketAccountLabel}
                onChange={handleAccountChange}
              />
            </FormGroup>
          </div>
          <div style={{ width: '200px' }}>
            <FormGroup label="Currency">
              <SearchSelect
                selection={balanceValues.currency}
                options={currencyOptions}
                getLabel={getCurrencyLabel}
                onChange={handleCurrencyChange}
              />
            </FormGroup>
          </div>
        </AccountCurrencyWrapper>
        <FormGroup label="Amount">
          <Input
            invalid={amountInvalid}
            autoComplete="off"
            value={balanceValues.amount || ''}
            disabled={balanceValues.accountID == null || balanceValues.currency == null}
            type="number"
            autoFocus
            onChange={handleAmountChange}
            suffix={balanceValues?.currency}
          />
        </FormGroup>
        <SummaryLines>
          <p>
            Previous Balance: <span>{summaryValues?.previous}</span>
          </p>
          <p>
            Difference: <span>{summaryValues?.difference}</span>
          </p>
          <p>
            New Balance:
            <Text color="colorTextImportant" weight="fontWeightMedium">
              {summaryValues?.new}
            </Text>
          </p>
        </SummaryLines>
      </SummaryWrapper>
    </Dialog>
  );
};
