import styled from 'styled-components';

import { Box, Button, Flex, Divider as KyokoDivider, type ButtonProps } from '@talos/kyoko';

export const ToggleButtonWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.gray['020']};
  border-radius: 5px;
  gap: 2px;
  padding: 2px;
  width: 100%;
`;

interface ToggleButtonProps extends ButtonProps {
  isSelected: boolean;
}

export const ToggleButton = styled(Button)<ToggleButtonProps>`
  border-radius: 3px;
  flex: 1 1 0px;
  flex-basis: 0;
  width: 50%;
  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.gray['050'] : 'transparent')};
`;

export const Divider = styled(KyokoDivider)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray['010']};
`;

export const ConfirmTransferSourceDestination = styled(Box)`
  border: 1px dashed ${({ theme }) => theme.colors.gray['050']};
`;

export const Source = styled(Box)`
  background: ${({ theme }) => theme.colors.gray['030']};
  border-radius: 3px;
`;

export const Destination = Source;

export const CloseButton = styled(Box)`
  border-left: 2px solid ${({ theme }) => theme.colors.gray['010']};
`;
