import { useDisclosure, useDynamicCallback } from '@talos/kyoko';
import { useState } from 'react';
import { initialState } from './initialState';
import type { CustomerTradeForm } from './types';

export function useBookAndModifyTradeDialog() {
  const dialog = useDisclosure();
  const [customerTradeForm, setCustomerTradeForm] = useState<CustomerTradeForm>(initialState);
  const updateForm = useDynamicCallback((form: Partial<CustomerTradeForm>) =>
    setCustomerTradeForm(prev => ({ ...prev, ...form }))
  );
  return {
    ...dialog,
    form: customerTradeForm,
    updateForm,
    resetForm: () => setCustomerTradeForm(initialState),
  };
}
