import type { CompositePipeFunction, ITradeAllocation } from '@talos/kyoko';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export const extractAllocationsPipe: CompositePipeFunction<ITradeAllocation> = pipe(
  map(json => {
    return {
      ...json,
      data: json.data
        .map(tradeAllocation =>
          tradeAllocation.Allocations.map((allocation, index) => ({
            ...tradeAllocation,
            ...allocation,
            id: `${tradeAllocation.TradeID}${index}`,
          }))
        )
        .flat(),
    };
  })
);
