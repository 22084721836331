import type { Column } from '@talos/kyoko';

export const columns: Column[] = [
  { field: 'TransactTime', title: 'Date', type: 'date' },
  { field: 'Side', type: 'side' },
  { field: 'Symbol', type: 'security' },
  { field: 'Quantity', type: 'size', params: { currencyField: 'Currency' } },
  { field: 'Price', type: 'price', params: { quoteCurrencyField: 'QuoteCurrency' } },
  { field: 'PriceAllIn', type: 'price', title: 'Filled Price All-In', params: { assetField: 'Symbol' }, hide: true },
  { field: 'Amount', type: 'size', params: { currencyField: 'AmountCurrency' } },
  { field: 'Fee', type: 'size', params: { currencyField: 'FeeCurrency' } },
  { field: 'User', type: 'user' },
  { field: 'TradeID', type: 'id' },
  { field: 'MarketAccount', type: 'marketAccount' },
  { field: 'Market', type: 'market', hide: true },
  { field: 'SubAccount', type: 'subAccount' },
  { field: 'Group', type: 'group', hide: true },
  { field: 'OrderID', type: 'id', hide: true },
  { field: 'MarketTradeID', type: 'id', hide: true },
  { field: 'Timestamp', type: 'date', hide: true },
  { field: 'TradeStatus', title: 'Status', type: 'tradeStatus' },
  { field: 'AggressorSide', type: 'side', hide: true },
  { field: 'Comments', type: 'text' },
  { field: 'AllocationID', type: 'id', hide: true },
  { field: 'MarketOrderID', type: 'id', hide: true },
  { field: 'MarketExecID', type: 'id', hide: true },
  { field: 'ParentTradeID', type: 'id', hide: true },
  { field: 'QuoteID', type: 'id', hide: true },
  { field: 'RFQID', type: 'id', hide: true },
  { field: 'TalosFee', type: 'size', params: { currencyField: 'TalosFeeCurrency' }, hide: true },
  { field: 'TradeSource', type: 'text', hide: true },
];
