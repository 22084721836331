import { Box } from '@talos/kyoko';
import styled from 'styled-components';

export const PortfolioTh = styled.th<{ align?: 'left' | 'right' }>`
  padding-bottom: ${({ theme }) => theme.spacingMedium}px;
  text-align: ${({ align }) => (align ? align : 'left')};
  padding-right: ${({ align, theme }) => align === 'right' && theme.spacingMedium + 'px'};
  font-weight: ${({ theme }) => theme.fontWeightRegular};
  color: ${({ theme }) => theme.colorTextSubtle};
`;

export const CurrencyText = styled.span`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
`;

export const CurrencyWrapper = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ theme, gap }) => (gap ? gap : theme.spacingLarge)}px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacingMedium}px;
`;

export const Name = styled.span`
  font-size: ${({ theme }) => theme.fontSizeLarge * theme.baseSize}px;
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const TopTabContentContainer = styled(Box)`
  border-bottom: 2px solid ${({ theme }) => theme.backgroundBody};
  background: ${({ theme }) => theme.colors.gray.main};
`;

export const BlotterWrapper = styled.div`
  border-top: ${({ theme }) => `${theme.spacingTiny}px solid ${theme.backgroundBody}`};
  max-height: 100%;
  width: 100%;
`;

export const XScrollableContainer = styled(Box)`
  overflow-y: hidden;
  overflow-x: auto;
  flex: 1;
`;
