import { logger, useDynamicTabsStorage, type TabProps } from '@talos/kyoko';
import { useCallback } from 'react';
import { useMarketTabs } from '../providers';

interface ResolveTabLabelParams {
  /** The ID of the set of tabs you want to resolve within */
  tabSetID: string;
  /** The id of the specific tab you're looking for */
  tabID: string;
  /** The key of the "ID" property on the Tab object. Defaults to "id", but can be set to for example "OrderID", or whatever else */
  tabIDKey?: string;
  /**
   * The hook does tab label resolution on the set of currently persisted tabs. If you want to do resolution on the set of persisted tabs
   * plus some set of static (hard-coded) tabs, pass them here.
   */
  nonPersistedTabs?: TabProps[];
}

/**
 * This hook returns a function which helps you resolve tab ids to their labels.
 * It hooks up to the AppConfig state, as in the same place that usePersistedTabs connects to.
 * It resolves tab uuids -> labels by grabbing tabs from the AppConfig TabStorage, and also allows you to pass in
 * non-persisted tabs (in other words static hard coded tabs), which are also used to look within first.
 */
export const useTabLabelResolver = () => {
  const { getState } = useDynamicTabsStorage();
  const { items: marketTabs } = useMarketTabs();

  const resolveTabLabel = useCallback(
    ({ tabSetID, tabID, tabIDKey = 'id', nonPersistedTabs }: ResolveTabLabelParams) => {
      if (nonPersistedTabs) {
        const maybeFoundTab = nonPersistedTabs.find(tab => tab[tabIDKey] === tabID);
        if (maybeFoundTab) {
          return maybeFoundTab.label ?? maybeFoundTab[tabIDKey];
        }
      }

      // Market tabs are legacy and stored differently in the AppConfig, so we grab those from the useMarketTabs context instead.
      if (tabSetID === 'marketTabs') {
        return marketTabs.find(tab => tab[tabIDKey] === tabID)?.label;
      } else {
        const tabsState = getState(tabSetID);

        if (!tabsState) {
          // Shouldn't happen
          logger.error(
            new Error(
              'Unable to resolve tab ID to label in useTabLabelResolver due to not finding any tabs the given tab set ID'
            ),
            {
              extra: {
                tabSetID,
              },
            }
          );
          return undefined;
        }

        return tabsState.items.find(tab => tab[tabIDKey] === tabID)?.label;
      }
    },
    [getState, marketTabs]
  );

  return {
    resolveTabLabel,
  };
};
