import {
  ACTION,
  API_PERMISSION_WILDCARD,
  ApiPermissionActionEnum,
  SUB_ACCOUNT,
  USER_SUB_ACCOUNT_PERMISSION_FILTER,
  UpdateActionEnum,
  request,
  useDynamicCallback,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  useUserContext,
  wsScanToMap,
  type IApiPermissionFilter,
  type ISubaccount,
  type SubAccount,
} from '@talos/kyoko';
import type React from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { combineLatest, map, scan } from 'rxjs';
import { useSubAccountRollups } from './SubAccountRollupsProvider';
import { SubAccountsContext, type ClonedSubAccountData } from './SubAccountsContext';

export const SubAccountsProvider = memo(function SubAccountsProvider({ children }: React.PropsWithChildren<unknown>) {
  const [isLoaded, setIsLoaded] = useState(false);

  const { subAccountRollupsByNameObs, createSubAccountRollup, updateSubAccountRollup, deleteSubAccountRollup } =
    useSubAccountRollups();

  const { data: subAccountBooksObs, push } = useStaticSubscription<ISubaccount>(
    {
      name: SUB_ACCOUNT,
      tag: 'SubAccountsProvider',
    },
    {
      loadAll: true,
    }
  );

  const onSuccessfulCRUDOperation = useDynamicCallback((result: ISubaccount[]) => {
    push({
      type: '',
      ts: '',
      data: result,
    });
  });

  const { createSubAccount, updateSubAccount, deleteSubAccount, cloneSubAccount } = useSubAccountRequests({
    onSuccess: onSuccessfulCRUDOperation,
  });

  const subAccountBooksByNameObs = useObservable(
    () => subAccountBooksObs.pipe(wsScanToMap({ getUniqueKey: s => s.Name, newMapEachUpdate: true })),
    [subAccountBooksObs]
  );

  const subAccountsByNameObs = useObservable(
    () =>
      combineLatest([subAccountBooksByNameObs, subAccountRollupsByNameObs]).pipe(
        map(([booksMap, rollupsMap]) => {
          return new Map([...booksMap.entries(), ...rollupsMap.entries()]);
        })
      ),
    [subAccountBooksByNameObs, subAccountRollupsByNameObs]
  );

  const subAccountsByIDObs = useObservable(
    () =>
      subAccountsByNameObs.pipe(
        map(mapByName => new Map([...mapByName.values()].map(subAccount => [subAccount.SubaccountID, subAccount])))
      ),
    [subAccountsByNameObs]
  );

  const allSubAccountsObs = useObservable(
    () => subAccountsByNameObs.pipe(map(map => [...map.values()])),
    [subAccountsByNameObs]
  );

  const allSubAccounts = useObservableValue(() => allSubAccountsObs, [allSubAccountsObs]);
  const allSubAccountBooks = useObservableValue(
    () => subAccountBooksByNameObs.pipe(map(map => [...map.values()])),
    [subAccountBooksByNameObs]
  );
  const allSubAccountRollups = useObservableValue(
    () => subAccountRollupsByNameObs.pipe(map(map => [...map.values()])),
    [subAccountRollupsByNameObs]
  );

  const subAccountsByName = useObservableValue(() => subAccountsByNameObs, [subAccountsByNameObs]);
  const subAccountsByID = useObservableValue(() => subAccountsByIDObs, [subAccountsByIDObs]);

  const { tradableSubAccounts, tradableSubAccountNamesSet } = useTradableSubAccounts({
    allSubAccounts: allSubAccountBooks,
    tag: 'SubAccountsProvider',
  });

  useEffect(() => {
    if (!isLoaded) {
      const hasLoadedNow = [allSubAccounts, subAccountsByID, subAccountsByName, allSubAccountRollups].every(
        dataset => dataset != null
      );
      if (hasLoadedNow) {
        setIsLoaded(hasLoadedNow);
      }
    }
  }, [isLoaded, allSubAccounts, subAccountsByID, subAccountsByName, allSubAccountRollups]);

  const subAccountsEnabled = allSubAccounts != null && allSubAccounts.length > 0;

  return (
    <SubAccountsContext.Provider
      value={{
        isLoaded,
        subAccountsEnabled,

        allSubAccounts: allSubAccounts!,
        allSubAccountBooks: allSubAccountBooks!,
        allSubAccountRollups: allSubAccountRollups!,
        subAccountsByName: subAccountsByName!,
        subAccountsByID: subAccountsByID!,

        tradableSubAccounts,
        tradableSubAccountNamesSet,

        createSubAccount,
        updateSubAccount,
        deleteSubAccount,
        cloneSubAccount,
        createSubAccountRollup,
        updateSubAccountRollup,
        deleteSubAccountRollup,
      }}
    >
      {children}
    </SubAccountsContext.Provider>
  );
});

const useTradableSubAccounts = ({
  allSubAccounts,
  tag,
}: {
  allSubAccounts: ISubaccount[] | undefined;
  tag: string;
}) => {
  const { enableAccountSegregation } = useFeatureFlag();
  const { isAuthorized } = useRoleAuth();

  const { data: userSubAccountPermissionsFilterRawObs } = useStaticSubscription<IApiPermissionFilter>({
    name: USER_SUB_ACCOUNT_PERMISSION_FILTER,
    tag: `${tag}/useTradableSubAccounts`,
  });

  // This is an intermediate data set representing the tradable sub accounts,
  // should not be exported. Instead, use the `tradableSubAccountNamesSet` below for any external use case.
  // This will only be populated if the account segregation feature is enabled
  const permissionedTradableSubAccountNames = useObservableValue(
    () =>
      userSubAccountPermissionsFilterRawObs.pipe(
        scan((writeSubAccountsSet, json) => {
          // Always create a new reference

          const newTradableNamesSet = new Set(writeSubAccountsSet);
          for (const data of json.data) {
            const subAccountFilter = data.Filter.SubAccount;
            if (subAccountFilter && data.Action === ApiPermissionActionEnum.Write) {
              if (data.UpdateAction === UpdateActionEnum.Remove) {
                newTradableNamesSet.delete(subAccountFilter);
              } else {
                newTradableNamesSet.add(subAccountFilter);
              }
            }
          }
          return newTradableNamesSet;
        }, new Set<string>())
      ),
    [userSubAccountPermissionsFilterRawObs]
  );

  const tradableSubAccountNamesSet = useMemo(() => {
    // If the feature isn't enabled it will be unpopulated, and thus we return empty set here
    if (permissionedTradableSubAccountNames == null) {
      return new Set<string>();
    }

    const canTradeAll =
      permissionedTradableSubAccountNames.has(API_PERMISSION_WILDCARD) ||
      !enableAccountSegregation ||
      isAuthorized(ACTION.BYPASS_FILTER_PERMISSIONS_RESTRICTIONS_SUBACCOUNTS);

    if (canTradeAll) {
      return new Set(allSubAccounts?.map(sub => sub.Name));
    }

    return permissionedTradableSubAccountNames;
  }, [permissionedTradableSubAccountNames, enableAccountSegregation, isAuthorized, allSubAccounts]);

  const tradableSubAccounts = useMemo(() => {
    return allSubAccounts?.filter(acc => tradableSubAccountNamesSet.has(acc.Name));
  }, [allSubAccounts, tradableSubAccountNamesSet]);

  return {
    tradableSubAccounts,
    tradableSubAccountNamesSet,
  };
};

const useSubAccountRequests = ({ onSuccess }: { onSuccess: (result: ISubaccount[]) => void }) => {
  const { orgApiEndpoint } = useUserContext();

  const updateSubAccount = useCallback(
    ({ SubaccountID, DisplayName }: Pick<SubAccount, 'DisplayName' | 'SubaccountID'>): Promise<SubAccount> => {
      return orgApiEndpoint
        ? request<{ data: SubAccount[] }>('POST', `${orgApiEndpoint}/subaccounts`, {
            SubaccountID,
            DisplayName: DisplayName.trim(),
          }).then(res => {
            onSuccess(res.data);
            return res.data[0];
          })
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint, onSuccess]
  );

  const deleteSubAccount = useCallback(
    (subAccountID: SubAccount['SubaccountID']) => {
      return orgApiEndpoint
        ? request('DELETE', `${orgApiEndpoint}/subaccounts/${subAccountID}`)
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint]
  );

  const createSubAccount = useCallback(
    (name: string, displayName?: string): Promise<SubAccount> => {
      return orgApiEndpoint
        ? request<{ data: SubAccount[] }>('POST', `${orgApiEndpoint}/subaccounts`, {
            Name: name.trim(),
            DisplayName: (displayName ?? name).trim(),
          }).then(res => {
            onSuccess(res.data);
            return res.data[0];
          })
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint, onSuccess]
  );

  const cloneSubAccount = useCallback(
    (nameOfSubAccToClone: number | string, nameOfNewSubAccount: string, displayNameOfNewSubAccount?: string) => {
      return orgApiEndpoint
        ? request<{ data: ClonedSubAccountData[] }>(
            'POST',
            `${orgApiEndpoint}/subaccounts/${nameOfSubAccToClone}/clone`,
            {
              Name: nameOfNewSubAccount.trim(),
              DisplayName: (displayNameOfNewSubAccount ?? nameOfNewSubAccount).trim(),
            }
          ).then(res => {
            onSuccess([res.data[0].SubAccount]);
            return res.data[0];
          })
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint, onSuccess]
  );

  return {
    updateSubAccount,
    deleteSubAccount,
    createSubAccount,
    cloneSubAccount,
  };
};
