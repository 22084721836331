import { useWSFilterPipe, type ExecutionReport } from '@talos/kyoko';
import { useCallback } from 'react';

type FilterableExecutionReport = Pick<ExecutionReport, 'ExecType' | 'OrdStatus' | 'ExecID'>;
const getExecutionReportKey = (item: FilterableExecutionReport) => item.ExecID;

/**
 * Given a set of statuses, returns an rxjs filtering pipe which filters your ExecutionReport-like entities
 */
export function useExecutionReportFilteringPipe<T extends FilterableExecutionReport>(statuses?: string[]) {
  const filterFunc = useCallback((item: T) => includeExecutionReport(item, statuses ?? []), [statuses]);
  return useWSFilterPipe({ getUniqueKey: getExecutionReportKey, filterFunc });
}

/**
 * Check if given executionReport meets filtering criteria (if provided)
 * @param executionReport
 * @param statuses
 */
function includeExecutionReport<T extends FilterableExecutionReport>(executionReport: T, statuses: string[]): boolean {
  if (!statuses.length) {
    return true;
  }

  return statuses.some(status => status === executionReport.ExecType);
}
