import { ConnectionStatusEnum, getRelativeSizes } from '@talos/kyoko';
import Big from 'big.js';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useMemo } from 'react';
import { usePortfolioExposures } from '../providers/PortfolioExposuresProvider';
import { usePortfolioPositions } from '../providers/PortfolioPositionsProvider';
import type { MarketPositions } from '../types';
import { getExposureUsage } from './Exposure/useExposure';
import { usePositionsConversions } from './usePositionsConversions';

export interface RelativeMarketPositions extends MarketPositions {
  relativeLimit: number;
  usage: number | undefined;
}

export const useRelativeMarketPositions = () => {
  const { OTCPositionsList } = usePortfolioPositions();
  const { exposuresByMarketAccount } = usePortfolioExposures();
  const { homeCurrency } = useDisplaySettings();

  const rates = usePositionsConversions();

  const relativeMarketPositions: RelativeMarketPositions[] | undefined = useMemo(() => {
    if (!OTCPositionsList || !exposuresByMarketAccount || !rates) {
      return undefined;
    }

    const exposureLimits = OTCPositionsList.map(mpos => {
      const exposure = exposuresByMarketAccount.get(mpos.MarketAccount);
      if (!exposure) {
        return 0;
      }

      const limit = Big(exposuresByMarketAccount.get(mpos.MarketAccount)?.ExposureLimit ?? '0').toNumber();

      if (exposure.Currency !== homeCurrency) {
        // we need to convert.
        const rate = rates.get(exposure.Currency);
        if (!rate || rate.Status !== ConnectionStatusEnum.Online || !rate.Rate) {
          return 0;
        }

        return Big(limit).times(Big(rate.Rate)).toNumber();
      }

      return limit;
    });

    const relativeSizes = getRelativeSizes(exposureLimits);

    return OTCPositionsList.map((mpos, i) => {
      const exposure = exposuresByMarketAccount.get(mpos.MarketAccount);
      const usage = exposure ? getExposureUsage(exposure) : undefined;
      return {
        ...mpos,
        relativeLimit: relativeSizes ? relativeSizes[i] : 1,
        usage,
      };
    });
  }, [OTCPositionsList, exposuresByMarketAccount, rates, homeCurrency]);

  return relativeMarketPositions;
};
