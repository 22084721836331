import type { LayoutSpec } from '../../../types/LayoutConfig';
import { DEFAULT_LAYOUT } from './Default';
import { DEFAULT_LAYOUT_ID } from './DefaultLayoutId';
import { WATCHLIST_CONTSTRAINTS } from './shared';

export const DEFAULT_LAYOUT_SPEC: LayoutSpec = {
  label: 'Default',
  id: DEFAULT_LAYOUT_ID,
  dockViewLayout: DEFAULT_LAYOUT,
  constraints: [WATCHLIST_CONTSTRAINTS],
  collapsablePanels: [
    {
      groupId: 'watchlists',
      panelIds: ['watchlist'],
      direction: 'left' as const,
      defaultVisible: true,
      defaultSize: 420,
      buttonLabel: 'Watchlist',
    },
  ],
};
