import { all as mergeAll } from 'deepmerge';
import type { Options } from 'highcharts/highcharts';
import { useMemo } from 'react';
import { EMPTY_OBJECT } from '../../../utils';

/** Merge options memoized */
export function useChartOptionsOverrides(
  initial: Options,
  options: Options = EMPTY_OBJECT,
  seriesOptions: Options = EMPTY_OBJECT
): Options {
  return useMemo(
    () => mergeAll([initial, options, seriesOptions], { arrayMerge: (_, source) => source }),
    [initial, options, seriesOptions]
  );
}
