import type { Column, Trade } from '@talos/kyoko';

export const DEFAULT_TRADE_COLUMNS: (keyof Trade | Partial<Column>)[] = [
  'TransactTime',
  'Side',
  'Symbol',
  'Quantity',
  'Price',
  'Fee',
  {
    field: 'PriceAllIn',
    title: 'Filled Price All-In',
    type: 'price',
    sortable: true,
    params: { assetField: 'Symbol' },
  },
  'Amount',
  'MarketAccount',
  { field: 'AggressorSide', type: 'side', sortable: true },
  'SubAccount',
  'TradeID',
  'TradeStatus',
  { field: 'OrderID', type: 'id', sortable: true },
  { field: 'User', type: 'user', sortable: true },
  { field: 'EID', type: 'text', hide: true },
  { field: 'Comments', type: 'text', hide: true },
  { field: 'Group', type: 'text', sortable: true, hide: true },
  { field: 'Market', type: 'market', sortable: true, hide: true },
  { field: 'MarketTradeID', title: 'Market Trade ID', type: 'text', hide: true },
  { field: 'ParentTradeID', title: 'Parent Trade ID', type: 'text', hide: true },
  { field: 'QuoteID', type: 'id', sortable: true, hide: true },
  { field: 'TradeAction', type: 'text', hide: true },
  { field: 'RFQID', type: 'id', hide: true },
  { field: 'Timestamp', type: 'date', sortable: true, hide: true, params: { milliseconds: true } },
  { field: 'TradeSource', type: 'text', hide: true },
];
