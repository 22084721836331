import {
  ACTION,
  Box,
  Button,
  ButtonVariants,
  CheckboxFuseAutocompleteDropdown,
  Flex,
  IconName,
  MultiSelectInput,
  useDropdownPopper,
  useMultiSelectAutocomplete,
  useRenderMultiSelectSelection,
  type User,
} from '@talos/kyoko';
import { useRoleAuth, useUsers } from 'hooks';
import { useCallback, useRef, type Dispatch, type SetStateAction } from 'react';
import { prettifyRole } from 'tokens/roles';

const userGetLabel = (user: User) => user.Name;
const userGetDescription = (user: User) => user.Roles?.map(role => prettifyRole(role)).join(', ') || '';

export function UserDropdown({
  usersToAdd,
  setUsersToAdd,
  onAddUser,
}: {
  usersToAdd: User[];
  setUsersToAdd: Dispatch<SetStateAction<User[]>>;
  onAddUser: (Users: User[]) => void;
}) {
  const { isAuthorized } = useRoleAuth();
  const canEditFilterPermissions = isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS);

  const users = useUsers();
  const inputRef = useRef<HTMLInputElement>(null);
  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete({
    selections: usersToAdd,
    items: users,
    getLabel: userGetLabel,
    getDescription: userGetDescription,
    initialSortByLabel: true,
    onChange: setUsersToAdd,
    clearInputAfterSelection: false,
    removeItemOnInputBackspace: true,
    highlightInputTextAfterSelection: true,
    inputRef,
  });

  const { isOpen, closeMenu, openMenu, getInputProps, getMenuProps } = autocompleteOutput;
  const { getDropdownProps, getSelectedItemProps, removeSelectedItem } = multipleSelectionOutput;
  const handleClickOutside = useCallback(() => closeMenu(), [closeMenu]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement: dropdownRef.current,
    dropdownWidth: '240px',
    dropdownPlacement: 'bottom-end',
    onClickOutside: handleClickOutside,
  });

  const renderMultiSelectItem = useRenderMultiSelectSelection({
    removeSelectedItem,
    getLabel: userGetLabel,
    getSelectedItemProps,
  });

  return (
    <Flex gap="spacingSmall" w="100%">
      <Box flex={1}>
        <Box ref={dropdownRef} flex={1}>
          <MultiSelectInput
            disabled={!canEditFilterPermissions}
            {...getInputProps(getDropdownProps({ ref: inputRef }))}
            placeholder="Search user name..."
            onClick={openMenu}
            items={multipleSelectionOutput.selectedItems.map((item, index) => renderMultiSelectItem(item, index))}
          />
        </Box>

        <div {...getMenuProps()}>
          <CheckboxFuseAutocompleteDropdown
            {...autocompleteOutput}
            {...dropdownPopper}
            selectedItems={multipleSelectionOutput.selectedItems}
            addSelectedItem={multipleSelectionOutput.addSelectedItem}
            removeSelectedItem={multipleSelectionOutput.removeSelectedItem}
            groupMaxHeight={400}
            maxHeight={400}
          />
        </div>
      </Box>
      <Button
        variant={ButtonVariants.Primary}
        startIcon={IconName.Plus}
        disabled={usersToAdd.length === 0 || !canEditFilterPermissions}
        onClick={() => onAddUser(usersToAdd)}
      >
        Add
      </Button>
    </Flex>
  );
}
