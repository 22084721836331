import {
  ACTION,
  Dialog,
  Divider,
  HStack,
  OrdTypeEnum,
  Text,
  VStack,
  abbreviateId,
  getSymbolsAcrossMarkets,
  isOrderComplete,
  isOrderPaused,
  useParameterRows,
  useSecurity,
  useStrategiesContext,
  useUserContext,
  type DialogProps,
  type ExecutionReport,
  type Order,
} from '@talos/kyoko';
import { OrderCardHeader } from 'components/OMS/OMSCard/OrderCardHeader';
import { OrderCardSummary } from 'components/OMS/OMSCard/OrderCardSummary';
import { useOrders } from 'providers';
import { useCallback } from 'react';
import { useRoleAuth } from '../../../../hooks';
import { SelectedOrdersList } from './SelectedOrdersList';
import { SelectedOrdersUsers } from './SelectedOrdersUsers';
import { ResumeDialogWrapper } from './styles';

interface BulkResumeDialogProps {
  selectedOrders?: Order[];
  resumeDialog: DialogProps;
}

export const BulkResumeDialog = ({ selectedOrders, resumeDialog }: BulkResumeDialogProps) => {
  if (!selectedOrders || selectedOrders.length === 0) {
    return null;
  }

  if (selectedOrders.length === 1) {
    return <ResumeDialog resumeDialog={resumeDialog} selectedOrder={selectedOrders[0]} />;
  }

  return <ResumeMultipleDialog resumeDialog={resumeDialog} selectedOrders={selectedOrders} />;
};

interface ResumeDialogProps {
  selectedOrder?: Order | ExecutionReport;
  resumeDialog: DialogProps;
}

export const ResumeDialog = ({ selectedOrder, resumeDialog }: ResumeDialogProps) => {
  const { isAuthorized } = useRoleAuth();
  const orders = useOrders();
  const security = useSecurity(selectedOrder?.Symbol);
  const { strategiesByName: strats } = useStrategiesContext();
  const paramRows = useParameterRows(selectedOrder?.Strategy, selectedOrder?.Parameters);

  if (selectedOrder == null || security == null) {
    return null;
  }

  const paused = isOrderPaused(selectedOrder.DecisionStatus);

  const {
    Strategy,
    OrdStatus,
    StartTime,
    EndTime,
    DecisionStatus,
    CumQty,
    OrderQty,
    SubmitTime,
    OrderID,
    OrdType,
    PricingMode,
  } = selectedOrder;

  const underlyingSymbols = getSymbolsAcrossMarkets(selectedOrder);
  const showFees = OrdType === OrdTypeEnum.LimitAllIn;

  const selectedStrategy = Strategy ? strats?.get(Strategy) : undefined;

  return (
    <Dialog
      {...resumeDialog}
      width={320}
      onConfirm={() => {
        selectedOrder && orders.resume(OrderID);
      }}
      confirmLabel="Resume Order"
      cancelLabel="Return"
      confirmDisabled={!paused || !isAuthorized(ACTION.SUBMIT_ORDER)}
      title="Confirm Resumption"
      stretchButtons
    >
      <ResumeDialogWrapper>
        <VStack gap="spacingMedium" alignItems="stretch">
          <h3 style={{ textAlign: 'left' }}>
            Order #<b>{abbreviateId(OrderID)}</b>
          </h3>
          <Text style={{ textAlign: 'left' }} color="colorTextAttention">
            Are you sure you want to resume this order?
          </Text>
          <Divider />
          <VStack gap="spacingDefault" alignItems="stretch">
            <OrderCardHeader
              security={security}
              selectedStrategy={selectedStrategy}
              strategy={Strategy}
              ordStatus={OrdStatus}
              startTime={StartTime}
              endTime={EndTime}
              decisionStatus={DecisionStatus}
              pricingMode={PricingMode}
              cumQty={CumQty ?? ''}
              orderQty={OrderQty ?? ''}
              text={selectedOrder.Text}
              submitTime={SubmitTime}
              orderID={OrderID}
              underlyingSymbols={underlyingSymbols}
              unifiedLiquidity={selectedOrder.Parameters?.UnifiedLiquidity}
            />
            <Divider />
            <OrderCardSummary security={security} showFees={showFees} {...selectedOrder} customerOrder={undefined} />
          </VStack>
          {paramRows.length > 0 && (
            <>
              <Divider />
              <VStack gap="spacingDefault" alignItems="stretch">
                {paramRows.map(row => {
                  return (
                    <HStack justifyContent="space-between" key={row.title}>
                      <Text color="colorTextAttention">{row.title}</Text>
                      <Text>{row.value}</Text>
                    </HStack>
                  );
                })}
              </VStack>
            </>
          )}
        </VStack>
      </ResumeDialogWrapper>
    </Dialog>
  );
};

const ResumeMultipleDialog = ({ selectedOrders, resumeDialog }: Required<BulkResumeDialogProps>) => {
  const { isAuthorized } = useRoleAuth();
  const { resume } = useOrders();
  const { user } = useUserContext();
  const onHandleResume = useCallback(
    () => selectedOrders.forEach(selectedOrder => resume(selectedOrder.OrderID)),
    [selectedOrders, resume]
  );

  return (
    <Dialog
      {...resumeDialog}
      onConfirm={onHandleResume}
      confirmLabel="Resume Orders"
      cancelLabel="Return"
      confirmDisabled={
        selectedOrders.every(selectedOrder => isOrderComplete(selectedOrder.OrdStatus)) ||
        !isAuthorized(ACTION.SUBMIT_ORDER)
      }
      title="Confirm Resumption"
    >
      <ResumeDialogWrapper>
        <VStack gap="spacingDefault" alignItems="stretch">
          {selectedOrders.every(selectedOrder => selectedOrder.User === user?.Name) ? (
            <Text style={{ textAlign: 'left' }} color="colorTextAttention">
              Are you sure you want to resume these {selectedOrders.length} orders?
            </Text>
          ) : (
            <Text style={{ textAlign: 'left' }} color="colorTextAttention">
              You are about to resume {selectedOrders.length} orders belonging to{' '}
              <SelectedOrdersUsers selectedOrders={selectedOrders} />
              .<br />
              Are you sure you want to proceed?
            </Text>
          )}
          <Divider />
          <SelectedOrdersList selectedOrders={selectedOrders} />
        </VStack>
      </ResumeDialogWrapper>
    </Dialog>
  );
};
