import { MARKET_EXPOSURE, useObservable, useStaticSubscription, wsScanToMap, type IMarketExposure } from '@talos/kyoko';
import { createContext, memo, useContext, useMemo, type PropsWithChildren } from 'react';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export interface MarketExposuresContextProps {
  marketExposuresByKeyObs: Observable<Map<string, IMarketExposure>>;
  marketExposuresListObs: Observable<IMarketExposure[]>;
}

export function getMarketExposureKey(me: IMarketExposure) {
  return `${me.MarketAccount}-${me.ExposureDefinition}-${me.Currency}-${me.ExposureSide}`;
}

const MarketExposuresContext = createContext<MarketExposuresContextProps | null>(null);
MarketExposuresContext.displayName = 'MarketExposuresContext';

export function useMarketExposures() {
  const context = useContext(MarketExposuresContext);
  if (context == null) {
    throw new Error('Missing MarketExposuresContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const MarketExposuresProvider = memo(function MarketExposuresProvider(props: PropsWithChildren<unknown>) {
  const { data: marketExposuresRawObs } = useStaticSubscription<IMarketExposure>({
    name: MARKET_EXPOSURE,
    tag: 'MarketExposuresProvider',
  });

  const marketExposuresByKeyObs = useObservable(
    () =>
      marketExposuresRawObs.pipe(
        wsScanToMap({ getUniqueKey: getMarketExposureKey, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketExposuresRawObs]
  );

  const marketExposuresListObs = useObservable(
    () =>
      marketExposuresByKeyObs.pipe(
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketExposuresByKeyObs]
  );

  const value = useMemo(
    () => ({
      marketExposuresByKeyObs,
      marketExposuresListObs,
    }),
    [marketExposuresByKeyObs, marketExposuresListObs]
  ) satisfies MarketExposuresContextProps;

  return <MarketExposuresContext.Provider value={value}>{props.children}</MarketExposuresContext.Provider>;
});
