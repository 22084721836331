import { HStack, IconButton } from '@talos/kyoko';
import styled from 'styled-components';

export const CredentialWrapper = styled(HStack)`
  gap: ${({ theme }) => theme.spacingSmall}px;
  justify-content: space-between;
  width: 100%;

  ${IconButton} {
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover {
    ${IconButton} {
      opacity: 1;
    }
  }
`;
