import { FormControlSizes, HStack, IconName } from '@talos/kyoko';
import type { IDockviewHeaderActionsProps } from 'dockview';
import { useCallback, useEffect, useState } from 'react';
import { TabIconButton } from '../styles';

export const TabSetRightComponent = ({
  api,
  containerApi,
  group,
  activePanel,
  ...props
}: IDockviewHeaderActionsProps) => {
  const [maximized, setMaximized] = useState<boolean>(api.isMaximized());

  const canMaximize = group.maximumWidth === Number.MAX_SAFE_INTEGER && group.maximumHeight === Number.MAX_SAFE_INTEGER;

  useEffect(() => {
    const disposables = [
      containerApi.onDidMaximizedGroupChange(() => {
        setMaximized(api.isMaximized());
      }),
    ];

    return () => {
      disposables.forEach(disposable => disposable.dispose());
    };
  }, [api, containerApi]);

  const onMaximize = useCallback(() => {
    if (maximized) {
      api.exitMaximized();
    } else {
      api.maximize();
    }
  }, [api, maximized]);

  return (
    <HStack pr="spacingSmall">
      <HStack borderLeft="solid 1px" borderRight="solid 1px" borderColor="backgroundBody" />
      <HStack>
        {canMaximize && (
          <TabIconButton
            onClick={onMaximize}
            icon={maximized ? IconName.ArrowsReduce : IconName.ArrowsExpand}
            ghost
            size={FormControlSizes.Small}
          />
        )}
      </HStack>
    </HStack>
  );
};
