import { Button, ButtonVariants, FormControlSizes, IconName, NoteVariant, useNotes } from '@talos/kyoko';
import { createContext, useEffect, useRef } from 'react';
import { ReplaySubject } from 'rxjs';

export const successSubject = new ReplaySubject<ServiceWorkerRegistration>(undefined);
export const updateSubject = new ReplaySubject<ServiceWorkerRegistration>(undefined);

export const ServiceWorkerContext = createContext(null);
ServiceWorkerContext.displayName = 'ServiceWorkerContext';

const activateNewServiceWorker = () => {
  window.location.reload();
};

export const ServiceWorkerProvider = function ServiceWorkerProvider({ children }) {
  const { addNote } = useNotes();
  const isShowingNotification = useRef(false);

  // Subscribe to service worker updates
  useEffect(() => {
    const sub = updateSubject.subscribe(() => {
      if (!isShowingNotification.current) {
        addNote({
          id: import.meta.env.VITE_GIT_HASH,
          closable: true,
          icon: IconName.Speakerphone,
          variant: NoteVariant.Announcement,
          text: (
            <>
              <strong>There&apos;s a new version available! </strong>
              Click the button to the right or refresh your page to update.
            </>
          ),
          actions: (
            <>
              <Button
                size={FormControlSizes.Small}
                variant={ButtonVariants.Positive}
                onClick={activateNewServiceWorker}
              >
                Update
              </Button>
            </>
          ),
        });
        isShowingNotification.current = true;
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [addNote]);

  return <>{children}</>;
};
