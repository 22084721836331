import { useDynamicCallback, type UseTabsProps } from '@talos/kyoko';
import { useSubAccounts } from '../../../../providers';
import type { PerformanceTabProps } from '../providers/types';
import { getPeriodLabel } from '../types';

export const usePerformanceTabLabeler = (): UseTabsProps<PerformanceTabProps>['tabLabeler'] => {
  const { subAccountsByName } = useSubAccounts();

  return useDynamicCallback((tab: PerformanceTabProps) => {
    const selectedSubAccount = tab.subAccounts[0];

    const subAccountLabel = selectedSubAccount
      ? subAccountsByName?.get(selectedSubAccount)?.DisplayName ?? selectedSubAccount
      : 'All';
    return `${subAccountLabel}: ${getPeriodLabel(tab.period)}`;
  });
};
