import {
  EMPTY_OBJECT,
  FilterClauseType,
  IconName,
  TradeStatusEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useSecuritiesContext,
  useTradingMarketAccountsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type Trade,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { isEqual, keys, values } from 'lodash';
import { useSubAccounts } from 'providers';
import type { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter, useMarketsFilter, useSidesFilter, useSubAccountsFilter, useUsersFilter } from '../../../hooks';

export interface BlotterTableTradeFilter extends DateRangeFilter {
  Markets?: string[];
  MarketAccounts?: string[];
  Sides?: string[];
  Statuses?: string[];
  SubAccounts?: string[];
  Symbols?: string[];
  Users?: string[];
  HideApiCalls?: boolean;
  OrderID?: string;
  RFQID?: string;
}

interface UseTradeFilterParams<TData extends Trade> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
  /**
   * If filtering by orderID, we will only show market trades for multileg.
   * If not filtering by orderID, we will show all trades.
   * As well as show the columns for orderID and RFQID.
   */
  filterByOrderID: boolean;
  additionalFilterState: ReturnType<typeof useContextBlotterFilter>['additionalFilterState'];
}

export type UseTradeFilterOutput<TData extends Trade> = {
  initialFilter: BlotterTableTradeFilter | undefined;
  filter: BlotterTableTradeFilter;
  clientSideFilter: BlotterTableClientLocalFilter<TData>;
  changeFilter: (action: SetStateAction<BlotterTableFilter>) => void;
  filterableProperties: FilterableProperty[];
  filterBuilderProps: UseFilterBuilderProps;
  blotterTableFilterProps: Partial<BlotterTableFiltersProps>;
};

export function useTradeFilter<TData extends Trade>({
  persistedBlotterTable,
  filterByOrderID,
  additionalFilterState,
}: UseTradeFilterParams<TData>): UseTradeFilterOutput<TData> {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;

  const [initialFilter] = useState(() => persistedBlotterTable.initialFilter);
  const [filter, setFilter] = useState<BlotterTableTradeFilter>(() => cleanupInitialFilterDateRange(initialFilter));

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const prevFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(prevFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;

      const activeFilter = {
        ...filter,
        ...additionalFilterState,
      };

      if (filterExistsAndExcludes(activeFilter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      if (filterExistsAndExcludes(activeFilter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(activeFilter, 'Statuses', data, 'TradeStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(activeFilter, 'Users', data, 'User')) {
        return false;
      }
      if (filterExistsAndExcludes(activeFilter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (
        activeFilter.SubAccounts &&
        activeFilter.SubAccounts.length > 0 &&
        !(data?.SubAccount && activeFilter.SubAccounts.includes(data?.SubAccount))
      ) {
        return false;
      }
      if (data?.MarketTradeID === undefined && data?.Market === 'multileg' && filterByOrderID) {
        // [UI-3900]: Do not show parent trades for multileg orders (only show market trades)
        return false;
      }
      return true;
    },
    [additionalFilterState, filter, filterByOrderID]
  );

  const { subAccountsEnabled } = useSubAccounts();
  const { securitiesList, securitiesBySymbol } = useSecuritiesContext();

  const tradingMarketAccountsFilter = useTradingMarketAccountsFilter();
  const marketsFilter = useMarketsFilter({ onlyTradingMarkets: true });
  const subAccountsFilter = useSubAccountsFilter(EMPTY_OBJECT);
  const usersFilter = useUsersFilter();
  const sidesFilter = useSidesFilter();
  const idFilter = useIDFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      [
        marketsFilter,
        tradingMarketAccountsFilter,
        sidesFilter,
        {
          key: 'Statuses',
          label: 'Status',
          icon: IconName.CheckCircle,
          options: values(TradeStatusEnum),
          getOptionLabel: (option: string) => option,
        },
        subAccountsEnabled ? subAccountsFilter : undefined,
        {
          key: 'Symbols',
          label: 'Symbol',
          icon: IconName.CurrencyDollar,
          options: securitiesList.map(sec => sec.Symbol),
          getOptionLabel: (option: string) => securitiesBySymbol.get(option)?.DisplaySymbol || '',
        },
        usersFilter,
        !filterByOrderID ? { ...idFilter, key: 'OrderID', label: 'Order ID' } : null,
        !filterByOrderID ? { ...idFilter, key: 'RFQID', label: 'RFQ ID' } : null,
      ].compact(),
    [
      marketsFilter,
      tradingMarketAccountsFilter,
      securitiesBySymbol,
      securitiesList,
      subAccountsEnabled,
      subAccountsFilter,
      usersFilter,
      sidesFilter,
      idFilter,
      filterByOrderID,
    ]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof BlotterTableTradeFilter)[]).forEach((key: keyof BlotterTableTradeFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'TimestampField':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: BlotterTableTradeFilter = removeEmptyFilters<BlotterTableTradeFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => {
              let selections: undefined | string | string[];
              switch (key) {
                case 'OrderID':
                case 'RFQID':
                  // below are exact search and only one value is supported by backend
                  selections = filterClausesByPropertyKey.get(key)?.selections?.[0];
                  break;
                default:
                  selections = filterClausesByPropertyKey.get(key)?.selections;
              }
              return [key, selections];
            })
          ) satisfies BlotterTableTradeFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter, additionalFilterState?.DateRange);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;

  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    filterableProperties,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

// Map from a ag-grid columnID to a filterbuilder key
// Returns undefined if the column can not be mapped to a filter builder clause
export function colIDToFilterBuilderKey(id: string): string | undefined {
  switch (id) {
    case 'Symbol':
      return 'Symbols';
    case 'SubAccount':
      return 'SubAccounts';
    case 'Market':
      return 'Markets';
    case 'MarketAccount':
      return 'MarketAccounts';
    case 'Side':
      return 'Sides';
    case 'TradeStatus':
      return 'Statuses';
    case 'User':
      return 'Users';
    case 'OrderID':
      return 'OrderID';
    case 'RFQID':
      return 'RFQID';
  }
  return undefined;
}
