import type { Aggregation, FilterableProperty } from '@talos/kyoko';
import { AggregationType, IconName } from '@talos/kyoko';
import { useAggregations } from 'hooks/useAggregations';
import { useEffect, useMemo, useState } from 'react';

/**
 * This hook creates the filter property definition for PricingAggregation properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for PricingAggregation filters
 */
export function usePricingAggregationsFilter<TKey extends string = 'PricingAggregations', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
): FilterableProperty<TKey> {
  const aggregationsService = useAggregations();
  const [aggregations, setAggregations] = useState<Aggregation[]>();
  useEffect(() => {
    if (aggregations == null) {
      aggregationsService.listAggregations(AggregationType.Customer).then(({ data }) => setAggregations(data));
    }
  }, [aggregations, aggregationsService]);

  return useMemo(
    () => ({
      ...({
        key: 'PricingAggregations' as TKey,
        label: 'Pricing Aggregation',
        field: 'PricingAggregation',
        icon: IconName.CreditCard,
        options: aggregations?.map(aggregation => aggregation.Name) ?? [],
        getOptionLabel: aggName => aggregations?.find(agg => agg.Name === aggName)?.DisplayName ?? aggName,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [aggregations, overrides]
  );
}
