import {
  bpsToPercent,
  Dialog,
  Divider,
  HStack,
  InlineFormattedNumber,
  Input,
  LoaderTalos,
  percentToBps,
  SideEnum,
  Text,
  useSecurity,
  VStack,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import Big, { type BigSource } from 'big.js';
import { useCustomerOrder, useOrder } from 'hooks';
import { useCustomersContext } from 'hooks/useCustomer';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface ModifyOrderSpreadDialogProps extends UseDisclosureReturn {
  customerOrderID?: string;
}

const tag = 'ModifyOrderSpreadDialog';

export const ModifyOrderSpreadDialog = ({ customerOrderID, ...props }: ModifyOrderSpreadDialogProps) => {
  const [spreadBPS, setSpreadBPS] = useState<string>('');

  useEffect(() => {
    setSpreadBPS('');
  }, [customerOrderID]);

  const customerOrder = useCustomerOrder({ orderID: customerOrderID, tag });
  const order = useOrder({ parentOrderID: customerOrderID, tag });
  const security = useSecurity(customerOrder?.Symbol);
  const customerService = useCustomersContext();

  const isLoaded = customerOrder != null && order != null && security != null;

  const effectivePricingParameterField = useMemo(
    () => (customerOrder?.Side === SideEnum.Buy ? 'OfferSpread' : 'BidSpread'),
    [customerOrder]
  );

  const effectiveSpread = useMemo(() => {
    if (!isLoaded) {
      return '0';
    }
    return (
      // Try to use specific OfferSpread/BidSpread parameter, fallback to Spread parameter
      customerOrder.PricingParameters?.[effectivePricingParameterField] ??
      (customerOrder.PricingParameters?.Spread || '0')
    );
  }, [customerOrder, effectivePricingParameterField, isLoaded]);

  const estimatedHedgeOrderPx: BigSource = useMemo(() => {
    if (!isLoaded) {
      return '0';
    }
    return Big(customerOrder.Price || 0).times(Big(1).plus(bpsToPercent(spreadBPS) || effectiveSpread));
  }, [customerOrder, effectiveSpread, isLoaded, spreadBPS]);

  const modifyOrderSpread = useCallback(() => {
    if (!customerOrderID || !spreadBPS) {
      return;
    }
    customerService.modifyCustomerOrderSpread(customerOrderID, bpsToPercent(spreadBPS));
  }, [customerOrderID, customerService, spreadBPS]);

  return (
    <Dialog
      {...props}
      onConfirm={() => modifyOrderSpread()}
      confirmLabel="Update Spread"
      cancelLabel="Cancel"
      width={450}
      showClose={true}
      closeOnClickOutside={false}
      title="Modify Spread"
      confirmDisabled={!isLoaded || !spreadBPS}
    >
      {!isLoaded ? (
        <LoaderTalos />
      ) : (
        <VStack w="100%" gap="spacingDefault">
          <VStack w="100%" gap="spacingDefault">
            <HStack w="100%" justifyContent="space-between">
              <Text>Customer Order PX</Text>
              <InlineFormattedNumber
                number={customerOrder.Price}
                currency={security?.QuoteCurrency}
                increment={security?.DefaultPriceIncrement}
              />
            </HStack>
            <HStack w="100%" justifyContent="space-between">
              <Text>Hedge Order PX</Text>
              <InlineFormattedNumber
                number={order.Price}
                currency={security?.QuoteCurrency}
                increment={security?.DefaultPriceIncrement}
              />
            </HStack>
            <HStack w="100%" justifyContent="space-between">
              <Text>Estimated Hedge Order PX</Text>
              <span>
                ~
                <InlineFormattedNumber
                  number={estimatedHedgeOrderPx}
                  currency={security?.QuoteCurrency}
                  increment={security?.DefaultPriceIncrement}
                />
              </span>
            </HStack>
          </VStack>
          <Divider />
          <VStack w="100%" gap="spacingDefault">
            <HStack w="100%" justifyContent="space-between">
              <Text>New Spread</Text>
              <Text>Current: {percentToBps(effectiveSpread)}</Text>
            </HStack>
            <Input
              value={spreadBPS}
              onChange={e => setSpreadBPS(e.target.value)}
              suffix="BPS"
              type="number"
              placeholder={percentToBps(effectiveSpread)}
            />
          </VStack>
        </VStack>
      )}
    </Dialog>
  );
};
