import type { FilterableProperty } from '@talos/kyoko';
import { IconName, useObservableValue } from '@talos/kyoko';
import { useCustomersByName, useCustomersContext } from 'hooks/useCustomer';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { map } from 'rxjs';

interface UseCounterpartyFilterParams {
  /** If true, will only allow the user to select one counterparty. Defaults to false. */
  singleSelect?: boolean;
}

/**
 * This hook creates the filter property definition for Counterparty properties.
 *
 * @returns Filter Property definition to use for Counterparty filters
 */
export function useCounterpartyFilter({ singleSelect }: UseCounterpartyFilterParams = {}) {
  const { customers: customersObs } = useCustomersContext();
  const customerNames = useObservableValue(
    () => customersObs.pipe(map(cs => sortBy(cs, 'DisplayName').map(c => c.Name))),
    [customersObs],
    []
  );
  const customersByName = useCustomersByName();

  return useMemo(
    () => ({
      ...({
        key: 'Counterparty',
        label: 'Counterparty',
        field: 'Counterparty',
        icon: IconName.Counterparty,
        control: singleSelect ? 'single-select' : 'multi-select',
        options: customerNames,
        getOptionLabel: name => customersByName?.get(name)?.DisplayName ?? name,
        clearUnsupportedSelectionsOnInit: false, // the options are loaded async so this needs to be off
      } as const satisfies FilterableProperty),
    }),
    [customerNames, customersByName, singleSelect]
  );
}
