import {
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Flex,
  LoaderTalos,
  Portal,
  useSecurity,
  type CustomerOrder,
  type Order,
} from '@talos/kyoko';
import { MarketDistributionCard } from 'components/MarketDistribution/MarketDistributionTabs';
import { useLatestOrderAnalytics } from 'hooks/useLatestOrderAnalytics';
import { KeyMetricsCard } from '../../../../components/OrderAnalytics/KeyMetricsCard';
import { TradingCard } from '../../../../components/OrderAnalytics/TradingCard';
import { OrderExecutionCharts } from '../../../../components/OrderExecutionChart';
import { ANALYTICS_WIDTH } from '../../../../styles/dimensions';
import { TopBarUtils } from './TopBarUtils';
import { AnalyticsWrapper } from './styles';

export interface AnalyticsTabProps {
  order: Order;
  customerOrder?: CustomerOrder;
  isOrderMultileg: boolean;
  width: number;
}

export function AnalyticsTab({ order, customerOrder, isOrderMultileg, width }: AnalyticsTabProps) {
  const orderSecurity = useSecurity(order.Symbol);

  const analytics = useLatestOrderAnalytics({
    order: order,
    tag: 'AnalyticsTab',
  });

  if (orderSecurity == null) {
    return <LoaderTalos />;
  }

  const isWideScreen = width > 1200;

  return (
    <>
      <Portal portalId={BLOTTER_TABLE_FILTERS_CONTAINER_ID}>
        <TopBarUtils order={order} customerOrder={customerOrder} latestAnalytics={analytics.latestAnalytics} />
      </Portal>
      <AnalyticsWrapper>
        <Flex gap="spacingDefault" flexDirection={isWideScreen ? 'row' : 'column'}>
          <Flex
            flex={isWideScreen ? '1' : '1 0 auto'}
            flexDirection="column"
            gap="spacingDefault"
            overflow="hidden"
            minWidth={isWideScreen ? undefined : `${ANALYTICS_WIDTH}px`}
          >
            <OrderExecutionCharts
              isMultileg={isOrderMultileg}
              order={order}
              strategy={order.Strategy}
              height={300}
              resizeAware
              analytics={analytics}
            />
            {!isOrderMultileg && <TradingCard order={order} isMultileg={isOrderMultileg} analytics={analytics} />}
          </Flex>

          <Flex
            flex={1}
            flexDirection="column"
            gap="spacingDefault"
            maxWidth={isWideScreen ? `${ANALYTICS_WIDTH}px` : undefined}
            minWidth={isWideScreen ? undefined : `${ANALYTICS_WIDTH}px`}
          >
            <KeyMetricsCard isMultileg={isOrderMultileg} order={order} analytics={analytics} />
            <MarketDistributionCard
              unifiedLiquidity={order.unifiedLiquidity}
              order={order}
              isMultileg={isOrderMultileg}
            />
            {isOrderMultileg && <TradingCard order={order} isMultileg={isOrderMultileg} analytics={analytics} />}
          </Flex>
        </Flex>
      </AnalyticsWrapper>
    </>
  );
}
