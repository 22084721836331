import {
  Box,
  ButtonGroup,
  Card,
  FormControlSizes,
  HStack,
  MixpanelEvent,
  MixpanelEventProperty,
  SymbolDisplay,
  SymbolDisplaySize,
  Tab,
  TabList,
  TabSize,
  Tabs,
  ToggleButton,
  UnifiedLiquidityEnum,
  getSymbolsAcrossMarkets,
  useMixpanel,
  type Order,
} from '@talos/kyoko';
import { memo, useCallback, useMemo, useState } from 'react';
import { FilledMarketDistributionChart } from '../FilledMarketDistributionChart';
import { OpenMarketDistributionChart } from '../OpenMarketDistributionChart';
import { SymbolDistributionChart } from '../SymbolDistributionChart';

const MarketDistributionTabNames = ['Filled', 'Open', 'Symbols'] as const;
type MarketDistributionTabName = (typeof MarketDistributionTabNames)[number];

export interface MarketDistributionChartProps {
  order: Order;
  unifiedLiquidity: UnifiedLiquidityEnum;
  isMultileg: boolean;
}

export const MarketDistributionCard = memo(function MarketDistributionTabs({
  unifiedLiquidity,
  isMultileg,
  order,
}: MarketDistributionChartProps) {
  const isUnifiedLiquidity = unifiedLiquidity === UnifiedLiquidityEnum.Enabled;
  const [activeTab, setActiveTab] = useState(0);
  const [activeLeg, setActiveLeg] = useState<0 | 1 | undefined>(isMultileg && !isUnifiedLiquidity ? 0 : undefined);
  const mixpanel = useMixpanel();

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      setActiveTab(tabIndex);
      mixpanel.track(MixpanelEvent.ChangeMarketDistributionTab, {
        [MixpanelEventProperty.TabLabel]: MarketDistributionTabNames[tabIndex],
      });
    },
    [mixpanel]
  );

  const tabs: MarketDistributionTabName[] = useMemo(() => {
    if (isUnifiedLiquidity) {
      return [...MarketDistributionTabNames];
    } else {
      return MarketDistributionTabNames.filter(n => n !== 'Symbols');
    }
  }, [isUnifiedLiquidity]);

  const activeTabName = tabs[activeTab];

  return (
    <Card
      header={
        <Tabs m="-spacingComfortable" mr="0" selectedIndex={activeTab} onSelect={handleTabChange} size={TabSize.Large}>
          <TabList suppressOverflowList={true} w="100%">
            {tabs.map(name => (
              <Tab label={name} key={name} />
            ))}
          </TabList>
        </Tabs>
      }
      actions={
        isMultileg && (
          <ButtonGroup size={FormControlSizes.Small}>
            {isUnifiedLiquidity && (
              <ToggleButton onClick={() => setActiveLeg(undefined)} selected={activeLeg === undefined}>
                Order
              </ToggleButton>
            )}
            <ToggleButton onClick={() => setActiveLeg(0)} selected={activeLeg === 0}>
              Leg 1
            </ToggleButton>
            <ToggleButton onClick={() => setActiveLeg(1)} selected={activeLeg === 1}>
              Leg 2
            </ToggleButton>
          </ButtonGroup>
        )
      }
    >
      <TabContent activeTabName={activeTabName} order={order} isMultileg={isMultileg} activeLeg={activeLeg} />
    </Card>
  );
});

const TabContent = ({
  activeTabName,
  activeLeg,
  order,
  isMultileg,
}: {
  activeTabName: MarketDistributionTabName;
  order: Order;
  isMultileg: boolean;
  activeLeg: 0 | 1 | undefined;
}) => {
  const symbol = activeLeg != null ? order?.LegSummary?.find(leg => leg.LegIndex === activeLeg)?.Symbol : order.Symbol;
  const underlyingSymbols =
    order.unifiedLiquidity === UnifiedLiquidityEnum.Enabled && activeLeg == null ? getSymbolsAcrossMarkets(order) : [];

  return (
    <Box w="100%" h="100%">
      {isMultileg && (
        <HStack justifyContent="space-between">
          <SymbolDisplay
            symbol={symbol}
            showDescription={false}
            p="0"
            size={SymbolDisplaySize.Small}
            underlyingSymbols={underlyingSymbols}
          />
        </HStack>
      )}
      {activeTabName === 'Filled' && <FilledMarketDistributionChart order={order} legIndex={activeLeg} />}
      {activeTabName === 'Open' && <OpenMarketDistributionChart order={order} legIndex={activeLeg} />}
      {activeTabName === 'Symbols' && <SymbolDistributionChart order={order} legIndex={activeLeg} />}
    </Box>
  );
};
