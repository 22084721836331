import {
  tabLabelerEnumerated,
  useDynamicCallback,
  usePersistedTabs,
  useTabs,
  type TabProps,
  type UseTabs,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState,
  type ContextType,
  type PropsWithChildren,
} from 'react';
import { useUpdateEffect } from 'react-use';
import {
  TreasuryManagementActionType,
  treasuryManagementReducer,
  type TreasuryManagementAction,
} from '../TreasuryManagementReducer';
import { TREASURY_MANAGEMENT_BLOTTER_TABS_ID } from '../tokens';
import {
  DEFAULT_TREASURY_MANAGEMENT_STATE,
  getDefaultTreasuryManagementState,
  type TreasuryManagementState,
} from './getDefaultTreasuryManagementState';

export type TreasuryManagementTabState = Pick<
  TreasuryManagementState,
  'filter' | 'showBy' | 'showZeroBalances' | 'snapshotDate'
>;

export type TreasuryManagementTabProps = TabProps & TreasuryManagementTabState;

export const DEFAULT_TREASURY_TAB_ID = 'default-treasury';

const DEFAULT_TAB: TreasuryManagementTabProps = {
  label: 'Treasury',
  id: DEFAULT_TREASURY_TAB_ID,
  closable: true,
  editable: true,
  filter: DEFAULT_TREASURY_MANAGEMENT_STATE.filter,
  showBy: DEFAULT_TREASURY_MANAGEMENT_STATE.showBy,
  showZeroBalances: DEFAULT_TREASURY_MANAGEMENT_STATE.showZeroBalances,
  snapshotDate: DEFAULT_TREASURY_MANAGEMENT_STATE.snapshotDate,
};

const tabLabeler = tabLabelerEnumerated('Treasury');

export const TreasuryManagementTabsContext = createContext<TreasuryManagementTabsContextProps | undefined>(undefined);

export interface TreasuryManagementTabsContextProps {
  tabs: UseTabs<TreasuryManagementTabProps>;
  currentTab: TreasuryManagementTabProps;
  cloneCurrentTab: () => void;
  filtersInitialOpen: boolean;
}

export function useTreasuryManagementTabs(): NonNullable<ContextType<typeof TreasuryManagementTabsContext>> {
  const context = useContext(TreasuryManagementTabsContext);
  if (context === undefined) {
    throw new Error('Missing TreasuryManagementTabsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const TreasuryManagementContext = createContext<
  | {
      state: TreasuryManagementState;
      dispatch: React.Dispatch<TreasuryManagementAction>;
    }
  | undefined
>(undefined);

export function useTreasuryManagementContext(): NonNullable<ContextType<typeof TreasuryManagementContext>> {
  const context = useContext(TreasuryManagementContext);
  if (context === undefined) {
    throw new Error('Missing TreasuryManagementContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const TreasuryManagementStateAndTabsProvider = function TreasuryManagementStateAndTabsProvider({
  children,
}: PropsWithChildren) {
  const [filtersInitialOpen, setFiltersInitialOpen] = useState(false);

  const persistedTabs = usePersistedTabs<TreasuryManagementTabProps>(TREASURY_MANAGEMENT_BLOTTER_TABS_ID, {
    defaultInitialItems: [DEFAULT_TAB],
    defaultInitialSelectedIndex: 0,
  });

  // DynamicCallback here needed because persistedTabs is referenced, so the setup/reuse is not in order
  // - Refactoring to state-based reducer usage in tabs would likely fix this
  const handleTabSelect = useDynamicCallback((i: number) => {
    persistedTabs.onSelect(i);

    dispatch({
      type: TreasuryManagementActionType.TabChange,
      payload: {
        ...getSharedDataBetweenTabAndReducer({ ...getDefaultTreasuryManagementState(), ...tabs.items[i] }),
      },
    });
  });

  const tabs: UseTabs<TreasuryManagementTabProps> = useTabs<TreasuryManagementTabProps>({
    ...persistedTabs,
    initialItems: persistedTabs.initialItems,
    showAddTab: true,
    tabLabeler,
    onSelect: handleTabSelect,
    allowClosingLastTab: false,
    onAdd: () => setFiltersInitialOpen(true),
    onCreateTab: () => getDefaultTreasuryManagementState(), // Spread the default tab state onto every newly created tab
  });

  const cloneCurrentTab = useDynamicCallback(() => {
    tabs.clone(tabs.selectedIndex);
  });

  const [state, dispatch] = useReducer(treasuryManagementReducer, {
    // grab any data which might be saved in the current tab and use that over the page defaults
    ...getDefaultTreasuryManagementState(),
    ...getSharedDataBetweenTabAndReducer(tabs.items[tabs.selectedIndex]),
  });

  //Whenever the state changes we update the tab stored state
  useUpdateEffect(() => {
    const currentTabState = getSharedDataBetweenTabAndReducer(tabs.items[tabs.selectedIndex]);
    const changedTabState = getSharedDataBetweenTabAndReducer(state);

    if (!isEqual(currentTabState, changedTabState)) {
      const newTabs = tabs.items.map((item: TreasuryManagementTabProps) => {
        if (item.id !== tabs.items[tabs.selectedIndex].id) {
          return item;
        }

        return {
          ...item,
          ...getSharedDataBetweenTabAndReducer(state),
        };
      });

      tabs.setItems(newTabs);
      persistedTabs.onItemsChanged(newTabs);
    }
  }, [state]);

  const stateValue = useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state, dispatch]);

  const tabsValue = useMemo(() => {
    return {
      tabs,
      currentTab: tabs.items[tabs.selectedIndex],
      cloneCurrentTab,
      filtersInitialOpen,
    };
  }, [tabs, cloneCurrentTab, filtersInitialOpen]);

  return (
    <TreasuryManagementTabsContext.Provider value={tabsValue}>
      <TreasuryManagementContext.Provider value={stateValue}>{children}</TreasuryManagementContext.Provider>
    </TreasuryManagementTabsContext.Provider>
  );
};

// This function just grabs the overlap we have between the two types. It helps us bridge between the two concepts
// when saving state reducer data to the tab, or grabbing state reducer value from a previously saved tab
export function getSharedDataBetweenTabAndReducer(state: TreasuryManagementTabState) {
  return {
    showBy: state.showBy,
    filter: state.filter,
    showZeroBalances: state.showZeroBalances,
    snapshotDate: state.snapshotDate,
  };
}
