import {
  FilterClauseType,
  IconName,
  ReconResolutionEnum,
  ReconResolutionEnumLabels,
  ReconStateEnum,
  ReconStateEnumLabels,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  useCurrenciesFilter,
  type BlotterTableFilter,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type ReconMismatch,
} from '@talos/kyoko';
import { compact, isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useMarketAccountsFilter } from '../../../../hooks/filters/useMarketAccountsFilter';

const FILTERABLE_MISMATCH_STATES = [
  ReconStateEnum.PendingReview,
  ReconStateEnum.ManualResolved,
  ReconStateEnum.AutoResolved,
  ReconStateEnum.Ignored,
];

const FILTERABLE_RESOLUTIONS = [
  ReconResolutionEnum.Unknown,
  ReconResolutionEnum.MissedFees,
  ReconResolutionEnum.MissedTrade,
  ReconResolutionEnum.MissedTransfer,
  ReconResolutionEnum.BelowThreshold,
  ReconResolutionEnum.InitialPosition,
];

const getReconResolutionEnumLabel = (item: ReconResolutionEnum) => ReconResolutionEnumLabels[item];
const getReconStateEnumLabel = (item: ReconStateEnum) => ReconStateEnumLabels[item];

export interface BlotterTableReconMismatchesFilter extends DateRangeFilter {
  Symbols?: string[];
  States?: ReconStateEnum[];
  MarketAccounts?: string[];
  Resolutions?: ReconResolutionEnum[];
}

interface UseReconMismatchesFilterParams {
  initialFilter?: BlotterTableReconMismatchesFilter;
  saveFilter: (newFilter: any) => void;
}

export const useReconMismatchesFilter = ({ initialFilter, saveFilter }: UseReconMismatchesFilterParams) => {
  const [filter, setFilter] = useState(() => cleanupInitialFilterDateRange(initialFilter));

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const currenciesFilter = useCurrenciesFilter();
  const accountsFilter = useMarketAccountsFilter();
  const filterableProperties: FilterableProperty[] = useMemo(() => {
    return compact([
      { ...currenciesFilter, label: 'Asset', key: 'Symbols' },
      accountsFilter,
      {
        key: 'States',
        label: 'State',
        icon: IconName.CircleDotted,
        options: FILTERABLE_MISMATCH_STATES,
        getOptionLabel: option => getReconStateEnumLabel(option as ReconStateEnum),
      },
      {
        key: 'Resolutions',
        label: 'Resolution',
        icon: IconName.CheckCircle,
        options: FILTERABLE_RESOLUTIONS,
        getOptionLabel: option => getReconResolutionEnumLabel(option as ReconResolutionEnum),
      },
    ]);
  }, [currenciesFilter, accountsFilter]);

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key],
        });
      });
    }
    return clauses;
  }, [filter]);

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
  };
};

// Map from a ag-grid columnID to a filterbuilder key
// Returns undefined if the column can not be mapped to a filter builder clause
export function colIDToFilterBuilderKey(id: string): string | undefined {
  switch (id) {
    case 'Asset':
      return 'Symbols';
    case 'State':
      return 'States';
    case 'MarketAccount':
      return 'MarketAccounts';
    case 'Resolution':
      return 'Resolutions';
  }
  return undefined;
}

export function clientSideReconMismatchFilterCheck(
  mismatch: ReconMismatch,
  filter: BlotterTableReconMismatchesFilter | undefined
): boolean {
  if (filter == null) {
    return true;
  }

  if (filterExistsAndExcludes(filter, 'States', mismatch, 'State')) {
    return false;
  }
  if (filterExistsAndExcludes(filter, 'Resolutions', mismatch, 'Resolution')) {
    return false;
  }

  return true;
}
