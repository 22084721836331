import { useTheme } from 'styled-components';
import { Icon, IconName } from '../../Icons';
import { Table, Tbody, Td, Th, Tr } from '../../Table';
import { Text } from '../../Text';
import { Wrapper } from './styles';
import type { DifferenceItem } from './utils';

export const ExecutionReportDifference = ({ differences }: { differences: DifferenceItem[] }) => {
  return (
    <Wrapper w="100%" maxWidth="500px">
      <Table w="100%" bordered>
        <Tbody>
          {differences.map(item => (
            <ChangedItem item={item} key={item.key} />
          ))}
        </Tbody>
      </Table>
    </Wrapper>
  );
};

const ChangedItem = ({ item }: { item: DifferenceItem }) => {
  const theme = useTheme();
  const isRemoval = item.type === 'remove';

  const fromDecoration = isRemoval ? undefined : 'line-through';

  const toColor = isRemoval ? theme.colors.red.lighten : theme.colors.green.lighten;
  const toDecoration = isRemoval ? 'line-through' : undefined;

  return (
    <Tr>
      <Th scope="row">{item.label}</Th>
      <Td align="right">
        <Text color="colorTextMuted" textDecoration={fromDecoration}>
          {item.from}
        </Text>
      </Td>
      <Td color="colorTextMuted">
        {item.type === 'replace' && <Icon icon={IconName.ArrowRight} />}
        {item.type === 'remove' && <Icon icon={IconName.ArrowRight} />}
        {item.type === 'add' && <Icon icon={IconName.Plus} />}
      </Td>
      <Td>
        <Text color={toColor} textDecoration={toDecoration}>
          {item.to}
        </Text>
      </Td>
    </Tr>
  );
};
