import { formattedUTCDate, OptionTypeEnum, toBigWithDefault, type StringSelectItem } from '@talos/kyoko';
import { OptionStrategies } from 'components/MultilegCombo/enums';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';
import { Option, OPTION_DATE_FORMAT } from './Option';
import { OptionStrategy, type OptionStrategyData } from './OptionStrategy';

/*
 * Strategy containing either 2 calls or 2 puts at a +/- ratio (buying one and selling one)
 * They have different expiration but same strike price (different strike == Diagonal Spread)
 * Used to bet on limited price movement until the near month option expires
 */
export class CalendarSpread extends OptionStrategy {
  public static createFromBlank(referenceData: OMSReferenceDataState, partial?: Partial<OptionStrategyData>) {
    const data: OptionStrategyData = {
      name: OptionStrategies.CalendarSpread,
      legs: [Option.createFromBlank(referenceData), Option.createFromBlank(referenceData)],
      initiatingLegs: [true, false],
      ratios: ['1', '-1'],
      ...partial,
    };

    return new CalendarSpread(referenceData, data);
  }

  public updateExpiry = (expiry: StringSelectItem, legIndex: number) => {
    const otherLegIndex = legIndex === 0 ? 1 : 0;
    const otherLegExpiry = this._data.legs[otherLegIndex].data.expiryField.value;

    const violateExpiryRule = expiry.value === otherLegExpiry?.value;
    const oppositeLegExpiry = violateExpiryRule ? undefined : otherLegExpiry;

    const newData = {
      ...this._data,
      legs: [
        this._data.legs[0].updateExpiry(legIndex === 0 ? expiry : oppositeLegExpiry),
        this._data.legs[1].updateExpiry(legIndex === 1 ? expiry : oppositeLegExpiry),
      ],
    };

    return this.ensureLegOrdering(newData);
  };

  // Based on feedback Product decided to losen the restriction on strike price and essentially treat
  // the Calendar Spreads as Diagonal Spreads as well
  public updateStrike = (strike: StringSelectItem, legIndex) => {
    const newData = {
      legs: [
        legIndex === 0 ? this._data.legs[0].updateStrike(strike) : this._data.legs[0],
        legIndex === 1 ? this._data.legs[1].updateStrike(strike) : this._data.legs[1],
      ],
    };
    return this.create(newData);
  };

  public updateType = (type: StringSelectItem) => {
    const newData = {
      ...this._data,
      legs: [this._data.legs[0].updateType(type), this._data.legs[1].updateType(type)],
    };
    return this.ensureLegOrdering(newData);
  };

  public updateRatio = (ratio: string, legIndex: number) => {
    const isRatioValid = legIndex === 0 ? toBigWithDefault(ratio, 0)?.gt(0) : toBigWithDefault(ratio, 0)?.lt(0);

    if (!ratio || ratio === '0' || ratio === '-' || !isRatioValid) {
      // ratio must maintain +1/-1 structure
      return this.create(this.data);
    }

    const copy = this._data.ratios.slice();
    copy[legIndex] = ratio;

    const newData = {
      ratios: copy,
    };

    return this.create(newData);
  };

  public getPrettyName(): string {
    const exchange = this.legs[0].data.exchangeField.value?.label;
    const coin = this.legs[0].data.coinField.value?.value;
    const type = this.legs[0].data.typeField.value?.value === OptionTypeEnum.Call ? 'CCAL' : 'PCAL';

    const leg1Expiry = this.legs[0].data.expiryField.value?.value
      ? formattedUTCDate(new Date(this.legs[0].data.expiryField.value?.value), OPTION_DATE_FORMAT).toUpperCase()
      : '';

    const leg2Expiry = this.legs[1].data.expiryField.value?.value
      ? formattedUTCDate(new Date(this.legs[1].data.expiryField.value?.value), OPTION_DATE_FORMAT).toUpperCase()
      : '';

    const strike = this.legs[1].data.strikeField.value?.value;

    return `${exchange} ${coin} ${type} ${leg1Expiry} ${leg2Expiry} ${strike}`;
  }

  protected create(newData: Partial<OptionStrategyData>) {
    const data = {
      ...this._data,
      ...newData,
    };
    return new CalendarSpread(this._referenceData, data);
  }

  private ensureLegOrdering(data: OptionStrategyData): CalendarSpread {
    const leg1Expiry = data.legs[0].data.expiryField.value;
    const leg2Expiry = data.legs[1].data.expiryField.value;

    if (leg1Expiry && leg2Expiry) {
      // Calendar spread implies that the first to expiry should be on the 2nd leg (the one we are selling)
      // Hence if order is wrong then we flip the expiry values - (not the option itself)
      const leg1ExpiryDate = new Date(leg1Expiry.value);
      const leg2ExpiryDate = new Date(leg2Expiry.value);
      const shouldFlip = leg1ExpiryDate.valueOf() < leg2ExpiryDate.valueOf();

      if (shouldFlip) {
        return this.create({
          legs: [
            data.legs[0].updateExpiry(data.legs[1].data.expiryField.value),
            data.legs[1].updateExpiry(data.legs[0].data.expiryField.value),
          ],
        });
      }
    }

    return this.create(data);
  }
}

CalendarSpread[immerable] = true;
