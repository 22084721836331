import type { Security } from '@talos/kyoko';
import { createContext, useContext } from 'react';

export interface TradedSecuritiesContextProps {
  tradedSecuritiesMap: Map<string, Security> | undefined;
}
export const TradedSecuritiesContext = createContext<TradedSecuritiesContextProps | undefined>(undefined);
TradedSecuritiesContext.displayName = 'TradedSecuritiesContext';

export const useTradedSecurities = () => {
  const context = useContext(TradedSecuritiesContext);
  if (context === undefined) {
    throw new Error('Missing TradedSecuritiesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
