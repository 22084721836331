import { ButtonGroup, Toggle, type BoxProps, type FormControlSizes } from '@talos/kyoko';
import { isEqual } from 'lodash';

export type ToggleParameterProps<T> = {
  value: T;
  onChange: (value: T) => void;
  size: FormControlSizes;
  on: T;
  off: T;
  disabled?: boolean;
} & Omit<BoxProps, 'onChange'>;

export function ToggleParameter<T>({ value, on, off, onChange, disabled, ...rest }: ToggleParameterProps<T>) {
  return (
    <ButtonGroup ml="auto" py="spacingSmall" {...rest}>
      <Toggle disabled={disabled} checked={isEqual(value, on)} onChange={b => (b ? onChange(on) : onChange(off))} />
    </ButtonGroup>
  );
}
