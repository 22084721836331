import { abbreviate, useHomeCurrencyRateValue } from '@talos/kyoko';
import Big from 'big.js';
import { usePortfolioExposures } from 'containers/Portfolio/providers/PortfolioExposuresProvider';
import type { PortfolioExposure } from 'containers/Portfolio/types';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useMemo } from 'react';

/**
 * Calculates the exposure usage and the abbreviated exposure limit string
 * @param exposure
 */
export const useExposure = (marketAccountName: string) => {
  const { exposuresByMarketAccount } = usePortfolioExposures();
  const exposure = exposuresByMarketAccount?.get(marketAccountName);
  const { homeCurrency } = useDisplaySettings();
  const rate = useHomeCurrencyRateValue(exposure?.Currency);

  const usage = useMemo(() => {
    if (!exposure) {
      return undefined;
    }
    return getExposureUsage(exposure);
  }, [exposure]);

  const limitString = useMemo(() => {
    if (!exposure) {
      return undefined;
    }

    const conversionNeeded = exposure.Currency !== homeCurrency;
    if (!conversionNeeded) {
      return `${abbreviate(exposure.ExposureLimit)} ${exposure.Currency}`;
    }

    if (!rate || !rate.Rate) {
      return undefined;
    }
    return `${abbreviate(Big(exposure.ExposureLimit).times(rate.Rate).toFixed())} ${homeCurrency}`;
  }, [exposure, homeCurrency, rate]);

  return { usage, limitString };
};

/**
 * Maps the usePortfolioExposures data to a usage (0-1) metric
 */
export const useExposureUsages = () => {
  const { exposuresByMarketAccount } = usePortfolioExposures();

  const usagesByMarketAccount = useMemo(() => {
    if (!exposuresByMarketAccount) {
      return undefined;
    }

    const newMap = new Map<string, number | undefined>();
    exposuresByMarketAccount.forEach((exposure, mktAcc) => {
      newMap.set(mktAcc, getExposureUsage(exposure));
    });

    return newMap;
  }, [exposuresByMarketAccount]);

  return usagesByMarketAccount;
};

export function getExposureUsage(exposure: PortfolioExposure): number | undefined {
  const limitVal = Big(exposure.ExposureLimit || '0');
  const positionVal = Big(exposure.Exposure || '0');

  if (limitVal.eq(0)) {
    return undefined;
  }

  if (positionVal.eq(0)) {
    return 0;
  }

  return positionVal.div(limitVal).toNumber();
}
