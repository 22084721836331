import { FormControlSizes, Toggle, useDynamicCallback } from '@talos/kyoko';
import {
  SettlementMonitoringActionType,
  useSettlementMonitoringState,
} from '../providers/SettlementMonitoringStateProvider';
import type { SettlementMonitoringState } from '../types';

export const GroupByCounterpartyControl = () => {
  const {
    state: { groupByCounterparty },
    dispatch,
  } = useSettlementMonitoringState();

  const handleClick = useDynamicCallback((groupByCounterparty: SettlementMonitoringState['groupByCounterparty']) => {
    dispatch({
      type: SettlementMonitoringActionType.GroupByCounterpartyChange,
      payload: {
        groupByCounterparty,
      },
    });
  });

  return (
    <Toggle
      checked={groupByCounterparty}
      onChange={handleClick}
      label="Group by Counterparty"
      size={FormControlSizes.Small}
      data-testid="group-by-counterparty-toggle"
    />
  );
};
