import type { TypedGroupviewPanelState } from '../../types/LayoutConfig';
import { type MarketTab, MarketTabType } from '../MarketTabs.types';
import { fillToNumColumns } from './fillToNumColumns';

export const mapTabToPanel = (tab: MarketTab): TypedGroupviewPanelState => {
  const shared = {
    id: tab.id,
    title: tab.label,
  };
  const defaultParams = { editable: true, closable: true };
  switch (tab.type) {
    case MarketTabType.Market:
      return {
        ...shared,
        contentComponent: 'marketCards',
        params: {
          ...defaultParams,
          columns: fillToNumColumns(tab.columns ?? []),
          defaults: tab.defaults,
        },
      };
    case MarketTabType.Option:
      return {
        ...shared,
        contentComponent: 'options',
        params: {
          ...defaultParams,
          ...tab.data,
        },
      };
    case MarketTabType.OrderDetails:
      return {
        ...shared,
        contentComponent: 'orderDetails',
        params: {
          ...defaultParams,
          orderID: tab.orderID,
          selectedTab: tab.openTab,
        },
      };
    case MarketTabType.PriceBlotter:
      return {
        ...shared,
        contentComponent: 'priceGrid',
        params: {
          ...defaultParams,
          ...tab.data,
        },
      };
    case MarketTabType.DeepDive:
      return {
        ...shared,
        contentComponent: 'deepDive',
        params: {
          ...defaultParams,
          symbol: tab.symbol,
          ...tab.data,
        },
      };
    case MarketTabType.ReconDetails:
      return {
        ...shared,
        contentComponent: 'reconMismatches',
        params: {
          ...defaultParams,
          mismatchIDs: tab.mismatchIDs,
        },
      };
    case MarketTabType.Multileg:
      return {
        ...shared,
        contentComponent: 'multilegDeepDive',
        params: {
          ...defaultParams,
          ...tab.data,
        },
      };
    case MarketTabType.AccountLedgerEventsDetails:
      return {
        ...shared,
        contentComponent: 'accountLedgerEvents',
        params: {
          ...defaultParams,
          account: tab.account,
          accountType: tab.accountType,
          asset: tab.asset,
        },
      };
    case MarketTabType.PositionAutoHedging:
      return {
        ...shared,
        contentComponent: 'positionAutoHedgingControls',
        params: {
          ...defaultParams,
        },
      };
    case MarketTabType.CareOrders:
      return {
        ...shared,
        contentComponent: 'careOrders',
        params: {
          ...defaultParams,
        },
      };
  }
};
