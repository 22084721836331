import { CustomerExecutionReport, useObservable, useSubscription } from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { map, scan, type Observable } from 'rxjs';
import { useExecutionReportFilteringPipe } from './useExecutionReportFilteringPipe';

export function useCustomerExecutionReports({
  orderID,
  rfqID,
  statuses,
}: {
  orderID?: string;
  rfqID?: string;
  statuses?: string[];
}): Observable<CustomerExecutionReport[]> {
  const [request, setRequest] = useState<any>(null);
  useEffect(() => {
    if (orderID == null && rfqID == null) {
      setRequest(null);
    } else {
      setRequest({
        name: 'CustomerExecutionReport',
        ...(orderID != null && { OrderID: orderID }),
        ...(rfqID != null && { RFQID: rfqID }),
        SendMarkets: true,
      });
    }
  }, [orderID, rfqID]);

  const { data: subscription } = useSubscription(request);
  const executionReportFilteringPipe = useExecutionReportFilteringPipe<CustomerExecutionReport>(statuses);

  return useObservable<CustomerExecutionReport[]>(
    () =>
      subscription.pipe(
        map(json => {
          return {
            ...json,
            data: json.data.map(item => new CustomerExecutionReport(item)),
          };
        }),
        executionReportFilteringPipe,
        scan((acc, json) => {
          if (json.initial) {
            acc = [];
          }
          return acc.concat(json.data);
        }, [] as CustomerExecutionReport[])
      ),
    [subscription, executionReportFilteringPipe]
  );
}
