import type { SubscriptionResponse, Transfer } from '@talos/kyoko';
import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { TransferForm } from '../containers/Transfers/types';

interface TransfersContext {
  transfersObs: Observable<Transfer[]>;
  transfersStreamObs: Observable<SubscriptionResponse<Transfer>>;
  initiateTransfer;
  /**
   * Given a set of initial transfer options, either returns a verified transfer primer, or undefined if there is
   * no valid primeable transfer given the inputs provided.
   * @params tryWithLinkedAccount -- if true, will check to see if there is some linked account mirroring the source or destination
   * account and fallback to a primeable transfer based on those linked account(s) instead. Defaults to false.
   * @returns either a valid primeable transfer option set, or undefined if there is none.
   */
  getPrimeableTransfer: (selections: PrimeTransferParams, tryWithLinkedAccount?: boolean) => TransferForm | undefined;
}

export interface PrimeTransferParams {
  asset?: string;
  source?: number;
  destination?: number;
}
export const Transfers = createContext<TransfersContext | undefined>(undefined);
Transfers.displayName = 'TransfersContext';

export const useTransfers = () => {
  const context = useContext(Transfers);
  if (context === undefined) {
    throw new Error('Missing Transfers.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
