import { Provider } from 'react-redux';
import { CustomerUserDetailsDrawer as CustomerDrawer } from './CustomerUserDetailsDrawer';
import { CustomerUserDetailsContext } from './state/hooks';
import { getCustomerUserStore } from './state/store';
import type { NewUserDrawerProps } from './useCustomerUserDetailsDrawer';

const customerUserStore = getCustomerUserStore();
export function CustomerUserDetailsDrawer(props: NewUserDrawerProps) {
  return (
    <Provider context={CustomerUserDetailsContext} store={customerUserStore}>
      <CustomerDrawer {...props} key={props.customerUserID} />
    </Provider>
  );
}
