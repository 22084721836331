import {
  ExecTypeEnum,
  EXECUTION_REPORT,
  useObservable,
  useStaticSubscription,
  type ExecutionReport,
} from '@talos/kyoko';
import type React from 'react';
import { memo } from 'react';
import { asyncScheduler } from 'rxjs';
import { scan, shareReplay, startWith, throttleTime } from 'rxjs/operators';
import { ExecutionReportsContext } from './ExecutionReportsContext';

const RECENT_TRADES_THROTTLE_MS = 1000;

export const ExecutionReportsProvider = memo(function ExecutionReportsProvider(
  props: React.PropsWithChildren<unknown>
) {
  // Execution Reports
  // TODO this should be filtered with a RequestedUser but needs BE enhancement
  const { data: executionReportSub } = useStaticSubscription<ExecutionReport>({
    name: EXECUTION_REPORT,
    tag: 'EXECUTION_REPORTS',
    SendMarkets: true,
    HideAPICalls: true,
    Throttle: '1s',
    CoalesceExecs: [ExecTypeEnum.Restated, ExecTypeEnum.Trade],
  });

  /**
   * Note that this gives observable emits the same Map instance every time,
   * so using `useObservableValue` won't result in any re-renders.
   */
  const executionReportsByOrderID = useObservable(
    () =>
      executionReportSub.pipe(
        scan((map, updates) => {
          for (const update of updates.data) {
            const prev = map.get(update.OrderID);
            // `text` field updates no longer cause a `revision` update so the same rev is ok
            if (prev == null || update.Revision >= prev.Revision) {
              map.set(update.OrderID, update);
            }
          }
          return map;
        }, new Map<string, ExecutionReport>()),
        startWith(new Map<string, ExecutionReport>()),
        throttleTime(RECENT_TRADES_THROTTLE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [executionReportSub]
  );

  /**
   * Note that this gives observable emits the same Map instance every time,
   * so using `useObservableValue` won't result in any re-renders.
   */
  const executionReportsByClOrdID = useObservable(
    () =>
      executionReportSub.pipe(
        scan((map, updates) => {
          for (const update of updates.data) {
            const prev = map.get(update.ClOrdID);
            // `text` field updates no longer cause a `revision` update so the same rev is ok
            if (prev == null || update.Revision >= prev.Revision) {
              map.set(update.ClOrdID, update);
            }
          }
          return map;
        }, new Map<string, ExecutionReport>()),
        startWith(new Map<string, ExecutionReport>()),
        throttleTime(RECENT_TRADES_THROTTLE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [executionReportSub]
  );

  return (
    <ExecutionReportsContext.Provider
      value={{
        executionReportsByClOrdID,
        executionReportsByOrderID,
        executionReportSub,
      }}
    >
      {props.children}
    </ExecutionReportsContext.Provider>
  );
});
