import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import {
  AllocationValueTypeEnum,
  MixpanelEvent,
  SearchSelect,
  VStack,
  getAllChildrenOfSubAccount,
  getDisplayNameOrNameLabel,
  useMixpanel,
  type Allocation,
  type ISubaccount,
  type SubAccount,
} from '@talos/kyoko';

import { FormGroup } from '@talos/kyoko';
import { useFeatureFlag } from 'hooks';
import { sortBy } from 'lodash';
import { useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { useTradingSettings } from 'providers/AppConfigProvider';
import { AllocationsSelector, type AllocationChange, type AllocationsSelectorProps } from '../../AllocationsSelector';

export type OMSSubAccountsProps = Omit<
  AllocationsSelectorProps,
  'onAllocationsChange' | 'onAllocationsValueTypeChange' | 'subAccountOptions' | 'showAddAllocationsButton'
> & {
  onUpdate: (
    changes: { allocationValueType: AllocationValueTypeEnum } | { subAccountAllocations: Allocation[] }
  ) => void;

  /** Whether or not to hide the +Allocate button. */
  hideAddAllocation?: boolean;
  /**
   * Whether or not to show the Rollup selector. Will only actually show if this is set to true and
   * there are any rollups in the system
   */
  showRollupSelector?: boolean;
  /**
   * If set to true, this component will update the defaultSubAccountId property for you on every relevant change.
   * If set to false, this component will not do this.
   *
   * This should be set to true if in the value logic into this component you are using Trading Settings -> defaultSubAccountId in some way.
   *
   * Made a required property just to avoid mistakes in the case that you forget that this property exists.
   */
  updateDefaultSubAccountOnChange: boolean;
};

const EMPTY_SUB_ACCOUNT_SELECTION = '';

export const OMSSubAccounts = memo(function OMSSubAccounts({
  subAccountAllocations,
  disabled = false,
  onUpdate,
  allocationValueType,
  useAllocations,
  hideAddAllocation = false,
  showRollupSelector: showRollupSelectorProp = false,
  updateDefaultSubAccountOnChange,
  ...props
}: OMSSubAccountsProps) {
  useEffect(() => {
    // Safeguard against poorly formatted subAccountAllocations
    if (subAccountAllocations == null || subAccountAllocations.length === 0) {
      onUpdate({
        subAccountAllocations: [{ subAccount: EMPTY_SUB_ACCOUNT_SELECTION, value: '100' }],
        allocationValueType: AllocationValueTypeEnum.Percentage,
      });
    }
  }, [subAccountAllocations, onUpdate]);

  const mixpanel = useMixpanel();
  const { setDefaultSubAccountId } = useTradingSettings();
  const { tradableSubAccounts, allSubAccountRollups } = useSubAccounts();
  const { rollupMembershipsByParentChild } = useSubAccountRollupMemberships();
  const { enableAccountSegregation } = useFeatureFlag();
  const showRollupSelector = showRollupSelectorProp && allSubAccountRollups.length > 0;

  const [rollup, setRollup] = useState<ISubaccount | undefined>(undefined);

  const usingSubAccounts = (tradableSubAccounts?.length ?? 0) > 0 || enableAccountSegregation;
  const showAddAllocationsButton =
    !hideAddAllocation && useAllocations && subAccountAllocations?.length < (tradableSubAccounts?.length ?? 0);

  const subAccountOptions: (SubAccount | Pick<SubAccount, 'Name'>)[] = useMemo(() => {
    if (!tradableSubAccounts || tradableSubAccounts.length === 0) {
      return [];
    }

    return sortBy(tradableSubAccounts, item => item.Name);
  }, [tradableSubAccounts]);

  const handleAllocationsChange = useCallback(
    (newAllocations: Allocation[], change: AllocationChange) => {
      // There are different types of updates that can happen. You can change either the sub account itself or the value (numeric) of an allocation.
      if (change.type === 'modification' && change.key === 'subAccount') {
        const id = tradableSubAccounts?.find(sa => sa.Name === change.value)?.SubaccountID;
        if (updateDefaultSubAccountOnChange) {
          setDefaultSubAccountId(id);
        }

        // These lines handle the case where a sub account is selected programmatically which is not a child of the selected rollup. This is a valid case.
        // When this occurrs, we clear the rollup selection before proceeding to update the sub account selection.
        const childIdsOfSelectedRollup = rollup
          ? getAllChildrenOfSubAccount(rollup.SubaccountID, rollupMembershipsByParentChild)
          : undefined;
        if (childIdsOfSelectedRollup != null && id != null && !childIdsOfSelectedRollup.has(id)) {
          setRollup(undefined);
        }
      }

      mixpanel.track(useAllocations ? MixpanelEvent.ChangeAllocations : MixpanelEvent.ChangeSubAccount);
      onUpdate({ subAccountAllocations: newAllocations });
    },
    [
      onUpdate,
      setDefaultSubAccountId,
      tradableSubAccounts,
      useAllocations,
      mixpanel,
      rollup,
      rollupMembershipsByParentChild,
      updateDefaultSubAccountOnChange,
    ]
  );

  const handleAllocationsValueTypeChange = useCallback(
    (newValueType: AllocationValueTypeEnum) => {
      onUpdate({ allocationValueType: newValueType });
    },
    [onUpdate]
  );

  const allChildrenOfSelectedRollup = useMemo(() => {
    if (!rollup) {
      return undefined;
    }

    return getAllChildrenOfSubAccount(rollup.SubaccountID, rollupMembershipsByParentChild);
  }, [rollup, rollupMembershipsByParentChild]);

  const availableSubAccountOptions: string[] = useMemo(() => {
    const usedOptions = subAccountAllocations?.map(item => item.subAccount);

    const performRollupNarrowing = allChildrenOfSelectedRollup != null;
    const narrowedSubAccountOptions = performRollupNarrowing
      ? subAccountOptions.filter(option =>
          'SubaccountID' in option ? allChildrenOfSelectedRollup?.has(option.SubaccountID) : true
        )
      : subAccountOptions;

    return narrowedSubAccountOptions
      .filter(item => {
        const usedName = usedOptions.find(i => i === item.Name);
        return usedName == null || !usedOptions?.includes(usedName);
      })
      .map(account => account.Name);
  }, [subAccountOptions, subAccountAllocations, allChildrenOfSelectedRollup]);

  if (!usingSubAccounts) {
    return null;
  }

  return (
    <>
      <VStack alignItems="initial">
        {showRollupSelector && (
          <FormGroup
            label="Rollup"
            tooltip="Selecting a Rollup filters the selectable items in the Sub Account selector below to only show Sub Accounts which are assigned to it, either directly or indirectly."
          >
            <SearchSelect
              disabled={disabled}
              data-testid="rollup-select"
              selection={rollup}
              options={allSubAccountRollups}
              getLabel={getDisplayNameOrNameLabel}
              onChange={setRollup}
              showClear
              w="100%"
            />
          </FormGroup>
        )}
        <AllocationsSelector
          subAccountAllocations={subAccountAllocations}
          allocationValueType={allocationValueType}
          subAccountOptions={availableSubAccountOptions}
          onAllocationsChange={handleAllocationsChange}
          onAllocationsValueTypeChange={handleAllocationsValueTypeChange}
          useAllocations={useAllocations}
          showAddAllocationsButton={showAddAllocationsButton}
          disabled={disabled}
          {...props}
        />
      </VStack>
    </>
  );
});
