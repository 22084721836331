import { useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useAsync } from 'react-use';

import {
  ACTION,
  AggregationType,
  Box,
  Button,
  ButtonVariants,
  Dialog,
  IconName,
  Input,
  ModeEnum,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Text,
  Toggle,
  useDisclosure,
  useGlobalToasts,
  type PricingRule,
} from '@talos/kyoko';

import { Loader, LoaderWrapper } from 'components/Loader';
import { useAggregations } from 'hooks/useAggregations';
import { usePricingRules } from 'providers';
import { PRICING_CONFIG_UPDATE_ID } from 'providers/PricingRulesProvider';
import { NewCustomerDialog } from '../Dialogs/NewCustomerDialog';
import { CustomerPricingTable } from './CustomerPricingTable';
import { EditDefaultPricingRuleDialog } from './EditDefaultPricingRuleDialog';

import type { GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { useRoleAuth } from 'hooks';
import { useCustomers } from 'hooks/useCustomer';
import { keys } from 'lodash';
import { TitleRow } from '../styles';

function getPricingRuleKey(pricingRule: PricingRule) {
  return `${pricingRule.Counterparty}${pricingRule.Symbol}`;
}

export const CustomerPricing = () => {
  const {
    updatePricingRule,
    updateCounterparty,
    deleteCounterparty,
    globalDefault,
    isGlobalDefaultLoading,
    pricingRules: allRules,
  } = usePricingRules();
  const [hideDisabledSymbols, setHideDisabledSymbols] = useState<boolean>(true);
  const pricingRules = useMemo(() => {
    // still include disabled global rules and counterparty level parent nodes
    return allRules?.filter(
      pr => !hideDisabledSymbols || pr.Mode === ModeEnum.Enabled || !pr.Counterparty || !pr.Symbol
    );
  }, [hideDisabledSymbols, allRules]);

  const { add: addToast, remove: removeToast } = useGlobalToasts();
  const customers = useCustomers();
  const [gridApi, setGridApi] = useState<GridApi<PricingRule>>();
  const handleGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);
  const [counterparty, setCounterparty] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [updatedIDs, setUpdatedIDs] = useState<string[]>([]);
  const { listAggregations } = useAggregations();
  const { value: aggregations } = useAsync(
    () => listAggregations(AggregationType.Customer).then(({ data }) => data),
    []
  );
  const { isAuthorized } = useRoleAuth();

  const [pricingRulesArr, setPricingRulesArr] = useState<PricingRule[] | undefined>();
  useEffect(() => {
    // Deep copy original pricing rules array
    if (pricingRules) {
      setPricingRulesArr(JSON.parse(JSON.stringify(pricingRules)));
    } else {
      setPricingRulesArr(undefined);
    }
  }, [pricingRules]);

  useEffect(() => {
    removeToast(PRICING_CONFIG_UPDATE_ID);
  }, [removeToast, pricingRulesArr]);

  useEffect(() => {
    if (pricingRulesArr == null || gridApi == null) {
      return;
    }
    /* This useEffect solves the following problems:
       1) Group Rows (node.group) can't be initialized with data (so we populate them here)
       2) Group Rows also have their own row as a child. This isn't good, because we
       don't want to duplicate the default values when a group is expanded.
       3) The edit static renderer needs to be force refreshed to update its onClick handler
     */

    // gridApi.api.forEachNode will return empty if this isn't in a setTimeout
    setTimeout(() => {
      const pricingRulesByCounterparty = {};
      for (const pr of pricingRulesArr) {
        if (pr.Counterparty && !pr.Symbol) {
          pricingRulesByCounterparty[pr.Counterparty] = pr;
        }
      }
      gridApi.forEachNode((node: IRowNode<PricingRule>) => {
        // Wonky workaround to get name of counterparty on the group node
        if (node.group) {
          const allLeafChildren = node.allLeafChildren ?? [];
          const firstLeafChildData = allLeafChildren.at(0)?.data;
          if (firstLeafChildData) {
            const pr = pricingRulesByCounterparty[firstLeafChildData.Counterparty];
            node.setData(pr);
          }
        }
      });
      gridApi.refreshCells({ columns: ['Edit'], force: true, suppressFlash: true });
      // Set widths to normal values, as AG Grid can be glitchy when loading columns this way.
      gridApi.sizeColumnsToFit();
    }, 0);
  }, [pricingRulesArr, gridApi]);

  const handleEditPricingRule = useCallback(
    // If this is being invoked in a loop as part of a batch
    // from handleEditPricingRules, we suppress any toast messages
    // here at the individual level, return a Promise without a catch
    // chain on it, and then execute a single toast within that function,
    // once all of the Promises have settled one way or another, with
    // a generated message, depending on how many passed vs failed.
    (data: PricingRule, partOfBatch = false) => {
      const preparedData: PricingRule = { ...data };
      keys(preparedData).forEach(key => {
        // Remove all blank fields from the final message
        if (preparedData[key] == null || preparedData[key] === '') {
          delete preparedData[key];
        }
      });
      if (!partOfBatch) {
        removeToast(PRICING_CONFIG_UPDATE_ID);
      }
      // Store this Promise to return to the calling function
      const promise = updatePricingRule(preparedData).then(() => {
        if (!preparedData.Counterparty && !preparedData.Symbol) {
          if (!partOfBatch) {
            addToast({
              text: `Updated Default Pricing Rule Configuration. Your grid will refresh automatically.`,
              variant: NotificationVariants.Positive,
            });
          }
          // This is a soft refresh for empty cells to be populated with the new values in the data
          gridApi?.refreshCells();
        }

        // When we get a confirmation back from the backend, remove the record of the "dirty update" from the updatedIDs data set
        const updatedKey = getPricingRuleKey(data);
        setUpdatedIDs(prev => prev.filter(key => key !== updatedKey));
      });

      // If this is an individual call to this function, attach the catch
      // handler. Otherwise, let it reject and check its settlement status
      // in the parent function, to compute the overall toast output.
      if (!partOfBatch) {
        promise.catch(error => {
          removeToast(PRICING_CONFIG_UPDATE_ID);
          addToast({
            text: `Could not update: ${error?.toString()}`,
            variant: NotificationVariants.Negative,
          });
        });
      }

      return promise;
    },
    [addToast, gridApi, removeToast, updatePricingRule]
  );

  const handleEditAllPricingRules = useCallback(() => {
    if (gridApi == null) {
      return;
    }
    const promises = (pricingRulesArr ?? [])
      .map(data => {
        if (updatedIDs.includes(getPricingRuleKey(data))) {
          const rowNode = gridApi.getRowNode(getPricingRuleKey(data)) as IRowNode;
          if (data.OfferSpread || data.BidSpread) {
            // If OfferSpread or BidSpread exists, remove Spread
            delete data.Spread;
            // If only 1 of OfferSpread or BidSpread exists, populate the other
            if (data.OfferSpread && !data.BidSpread) {
              rowNode.setDataValue('BidSpread', gridApi?.getValue('BidSpread', rowNode));
              gridApi.refreshCells({ columns: ['BidSpread'], force: true, suppressFlash: true });
            } else if (!data.OfferSpread && data.BidSpread) {
              rowNode.setDataValue('OfferSpread', gridApi?.getValue('OfferSpread', rowNode));
              gridApi.refreshCells({ columns: ['OfferSpread'], force: true, suppressFlash: true });
            }
          }
          //If Fee is specified we need to save the FeeMode from the parent.
          if (data.Fee && !data.FeeMode) {
            rowNode.setDataValue('FeeMode', gridApi?.getValue('FeeMode', rowNode));
            gridApi.refreshCells({ columns: ['FeeMode'], force: true, suppressFlash: true });
          }
          return handleEditPricingRule(data, true);
        }

        return undefined;
      })
      .filter(Boolean);

    Promise.allSettled(promises).then(results => {
      // Execute a single removeToast here before processing the output
      removeToast(PRICING_CONFIG_UPDATE_ID);
      const { successful, errors } = results.reduce<{ successful: number; errors: Set<string> }>(
        (acc, result) => {
          if (result.status === 'rejected') {
            acc.errors.add((result.reason as Error).toString());
          } else {
            acc.successful++;
          }
          return acc;
        },
        { successful: 0, errors: new Set() }
      );

      if (errors.size === 0) {
        // Differentiate the output based on how many passed
        addToast({
          text: `Updated Pricing Rule Configurations.`,
          variant: NotificationVariants.Primary,
        });
      } else {
        // or how many of the overall batch failed
        addToast({
          text: (
            <Box>
              <Box>
                <Text>
                  Could not update
                  {successful === 0 ? '' : ` ${errors.size} Pricing Rule Configuration${errors.size === 1 ? '' : 's'}`}:
                  {errors.size === 1 ? ` ${[...errors][0]}` : ''}
                </Text>
                {errors.size > 1 && (
                  <ul>
                    {[...errors].map(error => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                )}
              </Box>
            </Box>
          ),
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [addToast, gridApi, handleEditPricingRule, pricingRulesArr, removeToast, updatedIDs]);

  const onGroupCellChanged = useCallback(
    (counterparty, data, api) => {
      if (!pricingRulesArr) {
        return;
      }
      api.forEachNode(node => {
        if (node.group === true) {
          // When group cells are edited, we need to manually update the pricingRulesArr.
          // This is once again because group rows weren't meant to be editable in this manner.
          const index = pricingRulesArr.map(item => item.Counterparty).indexOf(counterparty);
          if (index >= 0) {
            Object.keys(data).forEach(key => {
              pricingRulesArr[index][key] = data[key];
            });
          }
        }
      });
    },
    [pricingRulesArr]
  );

  const handleOnCellValueChanged = useCallback(
    ({ node, data, api }) => {
      setUpdatedIDs(prev => [...prev, getPricingRuleKey(data)]);
      if (node.group) {
        const key = node.key;
        const editedCounterparty = customers?.find(cp => cp?.DisplayName === key)?.Name || data?.Counterparty;
        const editedData = node.data;
        onGroupCellChanged(editedCounterparty, editedData, api);
      }
    },
    [customers, onGroupCellChanged, setUpdatedIDs]
  );

  const handleEditCounterparty = useCallback(() => {
    updateCounterparty({ Name: counterparty, DisplayName: displayName }).then(() => {
      addToast({
        text: `Editing counterparty...`,
        variant: NotificationVariants.Primary,
      });
    });
  }, [addToast, counterparty, displayName, updateCounterparty]);

  const handleDeleteCounterparty = useCallback(() => {
    deleteCounterparty(counterparty).then(() => {
      addToast({
        text: `Deleting counterparty... Manually refresh page for updates.`,
        variant: NotificationVariants.Primary,
      });
    });
  }, [addToast, counterparty, deleteCounterparty]);

  const newCustomerDialog = useDisclosure();
  const editCounterpartyDialog = useDisclosure();
  const deleteCounterpartyDialog = useDisclosure();

  const handleNewCustomerButton = useCallback(() => {
    newCustomerDialog.open();
  }, [newCustomerDialog]);

  const editDefaultPricingRuleDialog = useDisclosure();

  const hasUnsavedChanges = updatedIDs.length > 0;

  const headerActions = (
    <PanelActions>
      <Toggle
        label="Hide Disabled Symbols"
        checked={hideDisabledSymbols}
        onChange={hide => setHideDisabledSymbols(hide)}
        data-testid="hide-disabled-symbols"
      />
      <Button
        startIcon={IconName.Pencil}
        onClick={() => editDefaultPricingRuleDialog.open()}
        variant={ButtonVariants.Priority}
        disabled={isGlobalDefaultLoading}
        data-testid="pricing-rule-edit-global"
      >
        Default Pricing Rule
      </Button>
      <Button
        startIcon={IconName.Plus}
        onClick={handleNewCustomerButton}
        variant={ButtonVariants.Positive}
        disabled={!isAuthorized(ACTION.DEALER_TRADING)}
      >
        Add Customer
      </Button>
      <Button
        variant={ButtonVariants.Primary}
        onClick={handleEditAllPricingRules}
        disabled={!hasUnsavedChanges || !isAuthorized(ACTION.DEALER_TRADING)}
      >
        Save
      </Button>
    </PanelActions>
  );

  const handleOnEditCounterparty = useCallback(
    ({ Counterparty }) => {
      setCounterparty(Counterparty);
      setDisplayName(customers?.find(cp => cp?.Name === Counterparty)?.DisplayName ?? '');
      editCounterpartyDialog.open();
    },
    [customers, editCounterpartyDialog]
  );

  const handleOnDeleteCounterparty = useCallback(
    ({ Counterparty }) => {
      setCounterparty(Counterparty);
      setDisplayName(customers?.find(cp => cp?.Name === Counterparty)?.DisplayName ?? '');
      deleteCounterpartyDialog.open();
    },
    [customers, deleteCounterpartyDialog]
  );

  return (
    <Panel>
      <PanelHeader>
        <h2>Customers and Pricing</h2>
        {headerActions}
      </PanelHeader>
      <PanelContent>
        <Prompt when={hasUnsavedChanges} message="You have unsaved changes. Are you sure you want to leave?" />
        {pricingRulesArr ? (
          <CustomerPricingTable
            rowData={pricingRulesArr}
            onCellValueChanged={handleOnCellValueChanged}
            onGridReady={handleGridReady}
            globalDefault={globalDefault}
            onEditCounterparty={handleOnEditCounterparty}
            onDeleteCounterparty={handleOnDeleteCounterparty}
            aggregations={aggregations}
            customers={customers}
          />
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </PanelContent>
      <NewCustomerDialog dialog={newCustomerDialog} />
      <EditDefaultPricingRuleDialog
        {...editDefaultPricingRuleDialog}
        confirmLabel="Save"
        title="Default Pricing Rule"
        showClose={true}
        width={650}
        autoFocusFirstElement={false}
        handleEditPricingRule={handleEditPricingRule}
      />
      <Dialog
        {...editCounterpartyDialog}
        onConfirm={handleEditCounterparty}
        confirmLabel="Edit Display Name"
        title="Edit Counterparty"
        showClose={true}
        width={400}
      >
        <Box>
          <TitleRow>
            <Text>
              Display Name of: <Text color="colorTextImportant">{counterparty}</Text>
            </Text>
          </TitleRow>
          <Input value={displayName || ''} autoComplete="off" onChange={e => setDisplayName(e.target.value)} />
        </Box>
      </Dialog>
      <Dialog
        {...deleteCounterpartyDialog}
        onConfirm={handleDeleteCounterparty}
        confirmLabel="Delete Counterparty"
        title="Delete Counterparty"
        showClose={true}
        width={400}
      >
        <Box textAlign="left">
          <Box mb="spacingMedium">
            <Text color="colorTextWarning">
              This action cannot be reversed. Under the covers, this will delete the customer, market account, and
              pricing rules.
            </Text>
          </Box>
          <TitleRow>
            <Text color="colorTextImportant">
              {counterparty} ({displayName})
            </Text>
          </TitleRow>
          <Text>Are you sure you want to delete this counterparty?</Text>
        </Box>
      </Dialog>
    </Panel>
  );
};
