import { createContext, useContext, useMemo } from 'react';
import { useCurrenciesContext, useSecuritiesContext } from '../contexts';
import { useDynamicCallback } from '../hooks';
import type { Asset } from '../types/Asset';
import type { Currency } from '../types/Currency';
import type { Security } from '../types/Security';

export interface AssetsContextProps {
  assetsList: Asset[];
  resolveSecurityOrCurrency: (asset: string) => { currency: Currency | undefined; security: Security | undefined };
  getAssetDisplaySymbol: (asset: string | undefined) => string;
}

export const AssetsContext = createContext<AssetsContextProps | undefined>(undefined);

export function useAssetsContext() {
  const context = useContext(AssetsContext);
  if (context === undefined) {
    throw new Error('Missing AssetsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const AssetsProvider = function AssetsProvider({ children }) {
  const { securitiesList, securitiesBySymbol } = useSecuritiesContext();
  const { currenciesList, currenciesBySymbol } = useCurrenciesContext();

  const assetsList = useMemo(() => {
    return [...currenciesList, ...securitiesList];
  }, [currenciesList, securitiesList]);

  const resolveSecurityOrCurrency = useDynamicCallback((asset: string) => {
    return { currency: currenciesBySymbol.get(asset), security: securitiesBySymbol.get(asset) };
  });

  const getAssetDisplaySymbol = useDynamicCallback((asset: string | undefined) => {
    if (!asset) {
      return '';
    }

    const { currency, security } = resolveSecurityOrCurrency(asset);
    return security ? security.DisplaySymbol ?? security.Symbol : currency?.Symbol ?? '';
  });

  return (
    <AssetsContext.Provider value={{ assetsList, resolveSecurityOrCurrency, getAssetDisplaySymbol }}>
      {children}
    </AssetsContext.Provider>
  );
};
