import {
  SettleValueTypeEnum,
  SideEnum,
  allowContracts,
  isDelta1Multileg,
  isDeribitLinearOption,
  isOptionStrategy,
  isSecurityQuantoFuture,
  useSecuritiesContext,
  useStrategiesContext,
  type OrderFormSides,
  type OrderStrategy,
  type Security,
} from '@talos/kyoko';
import { uniq } from 'lodash';
import { useCallback } from 'react';
import { useTradingSettings } from '../../providers/TradingSettingsContext';
import { STRATEGY_GROUP } from '../../tokens/order';
import { assertSecurityOnlyPlaceInQtyContract } from '../../utils/security';

/**
 * A hook to get correct order currency and all available order currencies
 * for specified security, order side and strategy.
 */
export function useGetOrderCurrency(): {
  getOrderCurrency: (
    security: Security | undefined,
    orderSide?: SideEnum | OrderFormSides,
    strategy?: string,
    currentOrderCurrency?: string
  ) => string;
  getAllowedCurrencies: (security: Security | undefined, orderSide: SideEnum | undefined, strategy: string) => string[];
} {
  const { strategiesByName } = useStrategiesContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { enableDerivativeContractDefault } = useTradingSettings();

  /*
   * Get order currency based on security, order side and strategy.
   *
   * @param security Security
   * @param orderSide the side of the order
   * @param strategy the strategy of the order
   * @param currentOrderCurrency current order currency
   */
  const getOrderCurrencyFn = useCallback(
    (
      security: Security | undefined,
      orderSide?: SideEnum | OrderFormSides,
      strategy?: string,
      currentOrderCurrency?: string
    ) => {
      return getOrderCurrency(
        security,
        securitiesBySymbol,
        strategiesByName,
        enableDerivativeContractDefault,
        orderSide,
        strategy,
        currentOrderCurrency
      );
    },
    [securitiesBySymbol, strategiesByName, enableDerivativeContractDefault]
  );

  /*
   * Get allowed order currencies based on security, order side and strategy.
   *
   * @param security Security
   * @param orderSide the side of the order
   * @param strategy the strategy of the order
   */
  const getAllowedCurrenciesFn = useCallback(
    (security: Security | undefined, orderSide: SideEnum | undefined, strategy: string): string[] => {
      return getAllowedCurrencies(security, securitiesBySymbol, strategiesByName, orderSide, strategy);
    },
    [securitiesBySymbol, strategiesByName]
  );

  return {
    getOrderCurrency: getOrderCurrencyFn,
    getAllowedCurrencies: getAllowedCurrenciesFn,
  };
}

export function getOrderCurrency(
  security: Security | undefined,
  securitiesBySymbol: Map<string, Security>,
  strategiesByName: Map<string, OrderStrategy>,
  enableDerivativeContractDefault: boolean,
  orderSide?: SideEnum | OrderFormSides,
  strategy?: string,
  currentOrderCurrency?: string
) {
  if (!security) {
    return '';
  }

  // https://talostrading.atlassian.net/browse/UI-3375
  if (isDelta1Multileg(security)) {
    const leg1Symbol = security.MultilegDetails?.Legs?.[0]?.Symbol;
    if (!leg1Symbol) {
      return '';
    } else {
      const leg1HasPositionCcy = securitiesBySymbol.get(leg1Symbol)?.PositionCurrency;
      // https://talostrading.atlassian.net/browse/UI-3954
      if (leg1HasPositionCcy && security.SettleValueType === SettleValueTypeEnum.Inverted) {
        return securitiesBySymbol.get(leg1Symbol)?.QuoteCurrency || '';
      } else {
        return leg1HasPositionCcy ? securitiesBySymbol.get(leg1Symbol)?.BaseCurrency || '' : '';
      }
    }
  }

  if (isOptionStrategy(security)) {
    return security.BaseCurrency;
  }

  if (isSecurityQuantoFuture(security) || assertSecurityOnlyPlaceInQtyContract(securitiesBySymbol, security)) {
    return '';
  }

  if (!currentOrderCurrency && allowContracts(security) && enableDerivativeContractDefault) {
    return '';
  }

  const selectedStrategy = strategiesByName.get(strategy ?? '');
  if (selectedStrategy && selectedStrategy.Group === STRATEGY_GROUP.DARK) {
    // UI-2626
    return orderSide === SideEnum.Buy ? security.QuoteCurrency : security.BaseCurrency;
  } else {
    if (currentOrderCurrency != null) {
      // do not change currency if we're here and currency is already selected
      return currentOrderCurrency;
    }
    return security?.SettleValueType === SettleValueTypeEnum.Inverted ? security.QuoteCurrency : security.BaseCurrency;
  }
}

export function getAllowedCurrencies(
  security: Security | undefined,
  securitiesBySymbol: Map<string, Security>,
  strategiesByName: Map<string, OrderStrategy>,
  orderSide: SideEnum | undefined,
  strategy: string
) {
  if (!security) {
    return [];
  }

  if (isDeribitLinearOption(security)) {
    // https://talostrading.atlassian.net/browse/UI-4388
    return [security.BaseCurrency, ''];
  }

  // https://talostrading.atlassian.net/browse/UI-3375
  if (isDelta1Multileg(security)) {
    const leg1Symbol = security.MultilegDetails?.Legs?.[0]?.Symbol;
    if (!leg1Symbol) {
      return [''];
    } else {
      const leg1HasPositionCcy = securitiesBySymbol.get(leg1Symbol)?.PositionCurrency;
      // https://talostrading.atlassian.net/browse/UI-3954
      if (leg1HasPositionCcy && security.SettleValueType === SettleValueTypeEnum.Inverted) {
        return [securitiesBySymbol.get(leg1Symbol)?.QuoteCurrency || ''];
      } else {
        return leg1HasPositionCcy ? [securitiesBySymbol.get(leg1Symbol)?.BaseCurrency || ''] : [''];
      }
    }
  }

  if (isOptionStrategy(security)) {
    return [security.BaseCurrency];
  }

  if (assertSecurityOnlyPlaceInQtyContract(securitiesBySymbol, security)) {
    return [''];
  }
  const result: string[] = [];
  const selectedStrategy = strategiesByName.get(strategy);
  if (selectedStrategy && selectedStrategy.Group === STRATEGY_GROUP.DARK) {
    // UI-2626
    if (orderSide === SideEnum.Buy) {
      result.push(security.QuoteCurrency);
    } else {
      result.push(security.BaseCurrency);
    }
  } else {
    result.push(security.BaseCurrency);
    result.push(security.QuoteCurrency);
  }
  if (allowContracts(security)) {
    result.push('');
  }
  return uniq(result);
}
