import Big from 'big.js';
import { useCurrenciesContext } from '../../contexts';
import type { LoanQuote } from '../../types';
import { abbreviateId } from '../../utils';
import { Box, Grid, HStack, type BoxProps } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Icon, IconName } from '../Icons';
import { Dd, Dl, Dt } from '../Lists';
import { LoanDuration } from '../LoanDuration';
import { Notification, NotificationVariants } from '../Notification';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

export interface LoanQuoteSummaryProps extends BoxProps {
  loanQuote: LoanQuote;
  showVersion: boolean;
}

export function LoanQuoteSummary({ loanQuote, showVersion, ...props }: LoanQuoteSummaryProps) {
  const { currenciesBySymbol } = useCurrenciesContext();
  const {
    QuoteReqID,
    Revision,
    AmendmentCount,
    ValidationError,
    Qty,
    CollateralRatio,
    Timestamp,
    TerminationTime,
    PrincipalCurrency,
    CollateralCurrency,
    Rate,
    Comments,
  } = loanQuote;
  const principalCurrency = currenciesBySymbol.get(PrincipalCurrency ?? '');

  return (
    <Box fontSize="fontSizeSmall" {...props}>
      <Grid columns="auto repeat(3, 1fr)" gap="spacingDefault">
        <Dl>
          <Dt>Principal Amount</Dt>
          <Dd>
            <InlineFormattedNumber
              number={Qty}
              currency={PrincipalCurrency}
              increment={principalCurrency?.DefaultIncrement}
            />
          </Dd>
        </Dl>
        <Dl alignItems="flex-end">
          <Dt>Collateral</Dt>
          <Dd>
            {CollateralRatio == null ? (
              '-'
            ) : (
              <InlineFormattedNumber number={Big(CollateralRatio).times(100).toFixed()} increment="1" currency="%" />
            )}
            {' / '}
            {CollateralCurrency ?? '-'}
          </Dd>
        </Dl>
        <Dl alignItems="flex-end">
          <Dt>Term</Dt>
          <Dd>
            <LoanDuration timestamp={Timestamp} terminationTime={TerminationTime} />
          </Dd>
        </Dl>
        <Dl alignItems="flex-end">
          <Dt>Rate</Dt>
          <Dd>
            {Rate == null ? (
              '-'
            ) : (
              <InlineFormattedNumber number={Big(Rate).times(100).toFixed()} increment="0.01" currency="%" />
            )}
          </Dd>
        </Dl>
        {Comments && (
          <Box gridColumn="1/5">
            <Dt>Comments</Dt>
            <Dd>{Comments}</Dd>
          </Box>
        )}
      </Grid>
      {(loanQuote.Text || ValidationError) && (
        <Notification variant={NotificationVariants.Warning} mt="spacingLarge">
          {ValidationError}
          {ValidationError && loanQuote.Text && `: `}
          {loanQuote.Text}
        </Notification>
      )}
      {showVersion && (
        <HStack justifyContent="space-between" mt="spacingLarge">
          <HStack>
            {QuoteReqID != null && (
              <Tooltip tooltip="QuoteReqID">
                <Icon icon={IconName.Hashtag} variant="Subtle" />
                <Text color="colorTextSubtle">{abbreviateId(QuoteReqID)}</Text>
              </Tooltip>
            )}
          </HStack>
          <HStack gap="spacingLarge">
            <Text color="colorTextSubtle">Rev: {Revision}</Text>
            <Text color="colorTextSubtle">Amd: {AmendmentCount}</Text>
          </HStack>
        </HStack>
      )}
    </Box>
  );
}
