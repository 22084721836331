import Big from 'big.js';
import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  ButtonVariants,
  DELETE,
  FeeModeEnum,
  FormActions,
  LoaderTalos,
  NotificationVariants,
  NumberInput,
  PATCH,
  POST,
  RadioButton,
  Toasts,
  request,
  useToasts,
  useUserContext,
  type FormControlSizes,
  type Market,
  type MarketAccount,
  type MarketConfig,
} from '@talos/kyoko';
import { useFees, type Fee } from 'providers/FeesProvider';
import {
  CustomFeeLevelInputs,
  FeeCell,
  FeeDescription,
  FeeLabel,
  FeeRow,
  FeeSection,
  FeeTitle,
  FeesWrapper,
  Options,
} from './styles';

enum FeeSource {
  Market = 'MARKET',
  Custom = 'CUSTOM',
}

export interface FeeEditorProps {
  formControlSize: FormControlSizes;
  market: Market;
  marketAccount: MarketAccount;
  marketConfig: MarketConfig;
}

const toPercentString = (value?: string) =>
  Big(value ?? '0')
    .times(100)
    .toFixed();

export const FeeEditor = ({ market, marketAccount, marketConfig, formControlSize }: FeeEditorProps) => {
  const { orgApiEndpoint } = useUserContext();
  const { listCustomFees, listMarketFees } = useFees();

  const [feeSource, setFeeSource] = useState(FeeSource.Market);
  // Stored as percentages.
  const [customMakerFee, setCustomMakerFee] = useState<string>('0.0');
  const [customTakerFee, setCustomTakerFee] = useState<string>('0.0');
  const [isTouched, setIsTouched] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [exchangeFees, setExchangeFees] = useState<FeeGroup[]>([]);
  // Stored as basis points
  const [savedCustomFees, setSavedCustomFees] = useState<Fee>();

  const { add: addToast, remove: removeToast, toasts } = useToasts();

  useEffect(() => {
    if (marketAccount != null) {
      listMarketFees().then(response => {
        const marketFees = response.filter(fee => fee.MarketAccount === marketAccount.Name);
        setExchangeFees(groupFees(marketFees));

        if (marketFees.length === 0) {
          setFeeSource(FeeSource.Custom);
        }
      });
    }
  }, [listMarketFees, marketAccount]);

  useEffect(() => {
    if (marketAccount != null) {
      listCustomFees().then(response => {
        const customFeesForMkt = response.find(fee => fee.MarketAccount === marketAccount.Name);
        setSavedCustomFees(customFeesForMkt);

        if (customFeesForMkt != null) {
          setFeeSource(FeeSource.Custom);
          setCustomMakerFee(toPercentString(customFeesForMkt.MakerFeeRate));
          setCustomTakerFee(toPercentString(customFeesForMkt.TakerFeeRate));
        }
      });
    }
  }, [listCustomFees, marketAccount]);

  const setFee = useCallback((side: FeeModeEnum, newFee: string) => {
    setFeeSource(FeeSource.Custom);
    if (side === FeeModeEnum.Maker) {
      setCustomMakerFee(newFee);
    } else {
      setCustomTakerFee(newFee);
    }
    setIsTouched(true);
  }, []);

  const selectFeeSource = useCallback(
    (newFeeSource: FeeSource) => {
      setFeeSource(newFeeSource);
      if (
        (newFeeSource === FeeSource.Market && savedCustomFees != null) ||
        (newFeeSource === FeeSource.Custom && savedCustomFees == null)
      ) {
        setIsTouched(true);
      }
    },
    [savedCustomFees]
  );

  const onCancel = useCallback(() => {
    setFeeSource(savedCustomFees == null ? FeeSource.Market : FeeSource.Custom);
    setCustomMakerFee(toPercentString(savedCustomFees?.MakerFeeRate));
    setCustomTakerFee(toPercentString(savedCustomFees?.TakerFeeRate));
    setIsTouched(false);
  }, [savedCustomFees]);

  const onSaveFeeRates = useCallback(
    async e => {
      e.preventDefault();
      if (isSaving) {
        return;
      }
      setIsSaving(true);
      try {
        if (feeSource === FeeSource.Custom) {
          const newFee: Fee = {
            MarketAccount: marketAccount.Name, // Used as unique ID
            MakerFeeRate: customMakerFee ? Big(customMakerFee).div(100.0).toFixed() : '0',
            TakerFeeRate: customTakerFee ? Big(customTakerFee).div(100.0).toFixed() : '0',
          };
          const reqType = savedCustomFees == null ? POST : PATCH;

          await request(reqType, `${orgApiEndpoint}/fees/configs`, newFee);
          setSavedCustomFees(newFee);
        } else {
          await request(DELETE, `${orgApiEndpoint}/fees/configs/${marketAccount.Name}/symbol/default`, {});
        }

        addToast({
          variant: NotificationVariants.Positive,
          text: `Fee rates saved`,
          timeout: 5000,
        });
      } catch (error: any) {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Could not save fees. ${error?.toString() ?? ''}`,
          timeout: 5000,
        });
      } finally {
        setIsSaving(false);
        setIsTouched(false);
      }
    },
    [customMakerFee, customTakerFee, feeSource, isSaving, marketAccount, orgApiEndpoint, savedCustomFees, addToast]
  );

  const hasExchangeFees = exchangeFees != null && exchangeFees.length > 0;
  const hasCustomFees = savedCustomFees != null;
  const savedFeeSource = hasCustomFees ? FeeSource.Custom : FeeSource.Market;
  const buttonsDisabled = !isTouched || (feeSource === savedFeeSource && feeSource !== FeeSource.Custom);

  if (!hasExchangeFees && !hasCustomFees) {
    return (
      <Box h="250px">
        <LoaderTalos />
      </Box>
    );
  }

  return (
    <FeesWrapper>
      <Box position="absolute" style={{ top: 0, right: 0 }}>
        <Toasts toasts={toasts} remove={removeToast} />
      </Box>
      <Options>
        {hasExchangeFees && (
          <FeeSection selected={feeSource === FeeSource.Market}>
            <Box alignSelf="center" mr="spacingDefault">
              <RadioButton
                checked={feeSource === FeeSource.Market}
                id="Market"
                name="Market"
                onChange={() => selectFeeSource(FeeSource.Market)}
                isDisabled={exchangeFees.length === 0}
              >
                {}
              </RadioButton>
            </Box>

            <FeeTitle>
              <h3 onClick={() => selectFeeSource(FeeSource.Market)}>Fee Rates from Exchange</h3>
            </FeeTitle>
            <FeeDescription>
              <p>These fee rates were retrieved from the exchange</p>
            </FeeDescription>
            {exchangeFees.map((fee: FeeGroup, index: number) => (
              <FeeRow key={index}>
                <FeeCell style={{ textAlign: 'left' }}>
                  {fee.Symbols.length > 0 ? fee.Symbols.join(', \n') : '*'}
                </FeeCell>
                <FeeCell>
                  <FeeLabel>Maker</FeeLabel> {toPercentString(fee.MakerFeeRate)}%
                </FeeCell>
                <FeeCell>
                  <FeeLabel>Taker</FeeLabel> {toPercentString(fee.TakerFeeRate)}%
                </FeeCell>
              </FeeRow>
            ))}
          </FeeSection>
        )}

        <FeeSection selected={feeSource === FeeSource.Custom}>
          {hasExchangeFees && (
            <Box alignSelf="center" mr="spacingDefault">
              <RadioButton
                checked={feeSource === FeeSource.Custom}
                id="Custom"
                name="Custom"
                onChange={() => selectFeeSource(FeeSource.Custom)}
                isDisabled={exchangeFees.length === 0}
              >
                {}
              </RadioButton>
            </Box>
          )}
          <FeeTitle>
            <h3 onClick={() => selectFeeSource(FeeSource.Custom)}>Custom Fee Level</h3>
          </FeeTitle>
          <FeeDescription>
            <p>
              Enter your own fee level to override the exchange level.{' '}
              {marketConfig.feesUrl ? (
                <>
                  For reference, you can find more information here:{' '}
                  <a href={marketConfig.feesUrl} target="_blank" rel="noreferrer">
                    {marketConfig.feesUrl}
                  </a>
                </>
              ) : (
                ''
              )}
            </p>
          </FeeDescription>
          <CustomFeeLevelInputs gap="spacingLarge" mt="spacingLarge">
            <FeeCell />
            <FeeCell>
              <NumberInput
                value={customMakerFee}
                onChange={value => setFee(FeeModeEnum.Maker, value)}
                size={formControlSize}
                suffix="%"
                defaultIncrement="0.01"
                minIncrement="0.0001"
                prefix={FeeModeEnum.Maker}
                disabled={feeSource !== FeeSource.Custom}
              />
            </FeeCell>
            <FeeCell>
              <NumberInput
                value={customTakerFee}
                onChange={value => setFee(FeeModeEnum.Taker, value)}
                size={formControlSize}
                suffix="%"
                defaultIncrement="0.01"
                minIncrement="0.0001"
                prefix={FeeModeEnum.Taker}
                disabled={feeSource !== FeeSource.Custom}
              />
            </FeeCell>
          </CustomFeeLevelInputs>
        </FeeSection>
      </Options>

      <FormActions size={formControlSize} style={{ marginBottom: 0 }}>
        <Button disabled={buttonsDisabled} size={formControlSize} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          size={formControlSize}
          variant={ButtonVariants.Primary}
          disabled={buttonsDisabled}
          onClick={onSaveFeeRates}
        >
          Update Fee Rate
        </Button>
      </FormActions>
    </FeesWrapper>
  );
};

interface FeeGroup {
  Symbols: string[];
  MakerFeeRate: string;
  TakerFeeRate: string;
}

function groupFees(fees: Fee[]): FeeGroup[] {
  const groupedFees = fees.reduce((res, fee) => {
    const key = `${fee.MakerFeeRate}___${fee.TakerFeeRate}`;
    if (res.has(key)) {
      res.get(key)?.Symbols.push(fee.Symbol || '');
    } else {
      res.set(key, {
        Symbols: [fee.Symbol || ''],
        MakerFeeRate: fee.MakerFeeRate,
        TakerFeeRate: fee.TakerFeeRate,
      });
    }
    return res;
  }, new Map<string, FeeGroup>());
  return Array.from(groupedFees.values());
}
