import {
  Box,
  ControlPrefix,
  FormControlSizes,
  FuseAutocompleteResult,
  HStack,
  Icon,
  IconName,
  itemIsAutocompleteGroup,
  JITTooltip,
  SearchSelect,
  SubAccountTypeEnum,
  type AutocompleteItemsProp,
  type FuseSearchObject,
  type RenderResultFunc,
  type SearchSelectProps,
} from '@talos/kyoko';
import { EllipsisBoxWithTooltip } from '@talos/kyoko/src/components/Form/BaseSelect/EllipsisBoxWithTooltip';
import type Fuse from 'fuse.js';
import { useRollupTreeList } from 'hooks';
import { useSubAccounts } from 'providers';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useCallback, useMemo } from 'react';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import { PortfolioSelectorLabelPrefix } from './PortfolioSelectorLabelPrefix';

export interface Item {
  key: string;
  itemId: number;
  type: SubAccountTypeEnum;
  label: string;
  level: number;
  description: string;
  searchString: string;
  parents: number[];
}

const sortFilterHierarchy: AutocompleteItemsProp<Item>['sortFilterOverride'] = (fullList, searchText) => {
  const fullItemById = new Map(fullList.map(item => [item.item.item.itemId, item]));
  const filteredList = fullList.filter(item =>
    item.item.item.searchString.toLowerCase().includes(searchText.toLowerCase())
  );
  const sortedList = filteredList.sort((fuseA, fuseB) => {
    const a = fuseA.item.item;
    const b = fuseB.item.item;
    const aSearch = a.searchString.toLowerCase();
    const bSearch = b.searchString.toLowerCase();
    const search = searchText.toLowerCase();
    const startWithA = aSearch.startsWith(search);
    const startWithB = bSearch.startsWith(search);
    if (startWithA && !startWithB) {
      return -1;
    }
    if (!startWithA && startWithB) {
      return 1;
    }
    const aIndex = aSearch.indexOf(search) ?? -1;
    const bIndex = bSearch.indexOf(search) ?? -1;
    if (aIndex > -1 || bIndex > -1) {
      return aIndex - bIndex;
    }

    return aSearch.localeCompare(bSearch);
  });

  // build final result with parents
  const finalResult = sortedList.reduce((result, next) => {
    // if the items's parent are not in the list, add them
    const parents = next.item.item.parents;
    const parentItems = parents.map(parentId => fullItemById.get(parentId)).compact();
    const parentItemsToInsert = parentItems.filter(
      item => !result.find(r => r.item.item.itemId === item.item.item.itemId)
    );
    result.push(...parentItemsToInsert);
    result.push(next);

    return result;
  }, [] as typeof sortedList);
  return finalResult;
};

const { changeSelectedPortfolioId } = getPortfolioViewActions();
export const PMSPortfolioSelector = () => {
  const { subAccountsByID } = useSubAccounts();
  const rollupTree = useRollupTreeList();
  const dispatch = useAppStateDispatch();
  const { selectedPortfolioId } = usePortfolioViewStateSelector();
  const options = useMemo(() => {
    const newOptions = (rollupTree ?? [])
      .map<Item | undefined>(hierarchyNode => {
        const subAccountResolved = subAccountsByID.get(hierarchyNode.subAccountId);
        return !subAccountResolved
          ? undefined
          : {
              key: hierarchyNode.key,
              level: hierarchyNode.level,
              type: subAccountResolved.Type,
              itemId: subAccountResolved.SubaccountID,
              label: subAccountResolved.DisplayName,
              description: subAccountResolved.Type,
              searchString: hierarchyNode.searchDescription,
              parents: hierarchyNode.parents,
            };
      })
      .compact();
    return newOptions;
  }, [rollupTree, subAccountsByID]);

  const currentSelection = useMemo(
    () => options.find(option => option.itemId === selectedPortfolioId),
    [options, selectedPortfolioId]
  );

  const hasRollups = options.some(option => option.type === SubAccountTypeEnum.Rollup);

  const renderResult = useCallback<NonNullable<SearchSelectProps<Item>['renderResult']>>(
    (searchResult, disabled) => RenderResult(searchResult, disabled, hasRollups),
    [hasRollups]
  );

  return (
    <Box w="300px">
      <SearchSelect
        data-testid="portfolio-select"
        prefix={
          <JITTooltip placement="bottom" tooltip="Book or Rollup to represent as the selected Portfolio">
            <ControlPrefix color="gray.090" mr={0}>
              Portfolio
            </ControlPrefix>
          </JITTooltip>
        }
        dropdownPlacement="bottom-start"
        placeholder="Select..."
        selection={currentSelection}
        renderResult={renderResult}
        initialSortByLabel={false}
        dropdownWidth="300px"
        size={FormControlSizes.Small}
        getLabel={item => item.label}
        showDescriptionInButton
        fuseDistance={100}
        disableFuzzyMatching
        sortFilterOverride={sortFilterHierarchy}
        options={options}
        customButtonLayout={PortfolioSelectorLayout}
        scrollSelectionIntoViewIndex={(scrollItems, item) =>
          scrollItems.findIndex(i => (itemIsAutocompleteGroup(i) ? 0 : i.itemId === item.itemId))
        }
        onChange={selectedItem => {
          selectedItem &&
            dispatch(
              changeSelectedPortfolioId({
                selectedPortfolioId: selectedItem.itemId,
              })
            );
        }}
      />
    </Box>
  );
};

const RenderResult: (
  searchResult: Fuse.FuseResult<FuseSearchObject<Item>>,
  disabled: boolean,
  /** if list has no rollups, don't do the base indent */
  hasRollups: boolean
) => ReturnType<RenderResultFunc<Item>> = (searchResult, disabled, hasRollups) => {
  const RenderPrefix = useCallback(
    () => <PortfolioSelectorLabelPrefix item={searchResult.item.item} hasRollups={hasRollups} />,
    [hasRollups, searchResult.item.item]
  );
  const RenderSuffix = useCallback(
    () =>
      searchResult.item.item.type === SubAccountTypeEnum.Rollup ? (
        <Box>
          <Icon size={16} icon={IconName.TreeDiagram} title="Rollup" />
        </Box>
      ) : null,
    [searchResult.item.item]
  );
  return (
    <HStack gap="spacingSmall" alignItems="center" justifyContent="flex-start">
      <RenderPrefix />
      {FuseAutocompleteResult(searchResult, disabled)}
      <RenderSuffix />
    </HStack>
  );
};

const PortfolioSelectorLayout = ({ item }: { item: Item | undefined }): React.ReactNode => {
  if (!item) {
    return null;
  }
  return (
    <HStack justifyContent="flex-start" alignItems="center" gap="spacingSmall">
      <Box display="flex" as="span" maxWidth="100%">
        <EllipsisBoxWithTooltip data-testid="selection-label" tooltipTestId="selection-label-overflow">
          {item.label}
        </EllipsisBoxWithTooltip>
        <Box flex="none" as="span" pl="spacingSmall" color="gray.090" data-testid="selection-description">
          {item.description}
        </Box>
      </Box>
    </HStack>
  );
};
