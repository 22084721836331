import {
  BlotterTable,
  Button,
  Flex,
  IconName,
  LoaderSizes,
  MixpanelEvent,
  Portal,
  createCSVFileName,
  useBlotterTable,
  useMixpanel,
  useObservable,
  type Column,
  type MarketAccount,
} from '@talos/kyoko';
import { useCallback, useEffect, useState } from 'react';
import { map, of } from 'rxjs';
import { Loader, LoaderWrapper } from '../../../../components/Loader';
import {
  RIGHT_ITEMS_PORTAL_ID,
  type ITradingControlsDrawerTab,
  type MarketAccountHistory,
  type MarketAccountHistoryBlotterEntity,
} from '../types';
import { useTradingControls } from '../useTradingControls';

interface TradingControlsGeneralHistoryProps extends UseTradingControlsHistoryProps {}

const columns: Column[] = [
  { type: 'text', field: 'RowID', sortable: true, hide: true },
  { type: 'text', field: 'RowIndex', sortable: true, hide: true, sort: '-' },
  { type: 'text', field: 'Action', width: 100 },
  { type: 'text', field: 'Description', width: 180 },
  { type: 'date', field: 'UpdatedAt', sortable: true, width: 120, title: 'Timestamp' },
  { type: 'text', field: 'Revision', width: 75, sortable: true },
  {
    type: 'user',
    field: 'User',
    width: 120,
    params: {
      valueFormatWithMap: 'id',
    },
  },
];

export function TradingControlsHistoryTab({ entity }: TradingControlsGeneralHistoryProps) {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState<MarketAccountHistory[] | undefined>([]);
  const mixpanel = useMixpanel();

  const { getMarketAccountHistory } = useTradingControls();

  useEffect(() => {
    if (!entity) {
      return;
    }
    setHistory([]);
    setLoading(true);
    getMarketAccountHistory(entity?.Name)
      .then(history => setHistory(history?.data ?? []))
      .finally(() => setLoading(false));
  }, [entity, getMarketAccountHistory]);

  const dataObservable = useObservable(
    () =>
      of(history ?? []).pipe(
        map((data: MarketAccountHistory[]) => {
          let rowIndex = 1;
          const result: MarketAccountHistoryBlotterEntity[] = [];
          data.forEach((ch: MarketAccountHistory) => {
            const row = {
              UpdatedAt: ch.Timestamp,
              Revision: ch.Revision,
              User: ch.UpdateUser,
              Status: ch.Status,
            };
            if (ch.Status) {
              result.push({
                ...row,
                Action: 'Status changed',
                Description: `Status changed to ${ch.Status}`,
                RowID: `${ch.Revision}`,
                RowIndex: rowIndex,
              });
              rowIndex++;
            }
          });
          return { data: result, initial: true, type: 'MarketAccountHistory' };
        })
      ),
    [history]
  );

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'RowID',
    columns,
  });

  const handleExport = useCallback(() => {
    if (!entity) {
      return;
    }
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: [entity.Name, 'History'].join('-'),
      }),
    });
  }, [blotterTable, entity, mixpanel]);

  return (
    <>
      {loading && (
        <LoaderWrapper>
          <Loader size={LoaderSizes.SMALL} />
        </LoaderWrapper>
      )}
      <Flex flexDirection="column" w="100%" h="100%" style={{ display: loading ? 'none' : 'flex' }}>
        <Portal portalId={RIGHT_ITEMS_PORTAL_ID}>
          <Button startIcon={IconName.Download} onClick={handleExport}>
            Export CSV
          </Button>
        </Portal>
        <BlotterTable background="backgroundDrawer" {...blotterTable} />
      </Flex>
    </>
  );
}

interface UseTradingControlsHistoryProps {
  entity: MarketAccount | undefined;
}

export function useTradingControlsHistoryTab(props: UseTradingControlsHistoryProps): ITradingControlsDrawerTab {
  const { entity } = props;

  return {
    name: 'History',
    save: Promise.resolve,
    isDirty: false,
    isValid: true,
    component: <TradingControlsHistoryTab entity={entity} />,
  };
}
