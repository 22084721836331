import type { Column, TabProps } from '@talos/kyoko';
import { pick } from 'lodash';
import {
  entityDisplayNames,
  type MonitoringBlotterTableFilter,
  type entityByRequestName,
} from '../../Monitoring/Blotters/types';

export interface DealerMonitoringBlotterTabProps extends TabProps {
  tag?: string;
  name?: CustomerEntityName;
  defaultFilter?: MonitoringBlotterTableFilter;
  defaultColumns?: Column[];
}

export const customerEntityDisplayNames = pick(entityDisplayNames, [
  'CustomerOrder',
  'CustomerQuote',
  'CustomerExecutionReport',
  'CustomerTrade',
]);

export type CustomerEntityName = keyof typeof customerEntityDisplayNames;

export type DealerBlotterTableEntity = (typeof entityByRequestName)[CustomerEntityName];
