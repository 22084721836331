import {
  Box,
  Panel,
  Tab,
  TabAppearance,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  tabLabelerByType,
  useDynamicCallback,
  usePersistedTabs,
  useTabs,
} from '@talos/kyoko';
import { identity } from 'lodash';
import { Fragment } from 'react';

import { useTheme } from 'styled-components';
import { ReconOverview } from './ReconOverview';
import { RECON_TABS_DETAILS, type ReconTab, ReconTabType } from './tabs';

const TAB_PANELS_STYLE: React.CSSProperties = {
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

const DEFAULT_TABS: ReconTab[] = [
  {
    ...RECON_TABS_DETAILS[ReconTabType.ReconOverview].getDefaultState(),
    id: 'recon-overview-default',
    label: 'Recon Overview',
    closable: false,
    editable: false,
  },
];

const tabLabeler = tabLabelerByType<ReconTab, keyof ReconTab>({
  typeKey: 'tabType',
  typeToLabel: identity,
});

/** This is the component used for rendering the Recon page inside the old Portfolio layout where you can have multiple tabs */
export const PortfolioReconOldLayout = () => {
  const persistedTabs = usePersistedTabs('portfolio/reconciliation', {
    defaultInitialItems: DEFAULT_TABS,
  });

  const tabs = useTabs({
    ...persistedTabs,
    showAddTab: false,
    allowClosingLastTab: false,
    tabLabeler,
  });

  const handleUpdateTab = useDynamicCallback((updatedTab: ReconTab) => {
    tabs.updateItem(updatedTab);
  });

  const theme = useTheme();

  return (
    <Panel w="100%" position="relative" justifyContent="start" flex="1" alignItems="left" h="100%" overflow="hidden">
      <Tabs {...tabs} flex="1" appearance={TabAppearance.Filled} size={TabSize.Small}>
        <Box style={{ borderBottom: `2px solid`, borderBottomColor: theme.backgroundBody }}>
          <TabList isBordered>
            {tabs.items.map((tab, idx) => (
              <Tab key={idx} {...tab} />
            ))}
          </TabList>
        </Box>
        <TabPanels style={TAB_PANELS_STYLE}>
          {tabs.items.map(tab => (
            /* The key here is important. Make sure to re-mount the underlying components completely when the tab changes. */
            <Fragment key={tab.id}>
              {tab.tabType === ReconTabType.ReconOverview && <ReconOverview tab={tab} updateTab={handleUpdateTab} />}
            </Fragment>
          ))}
        </TabPanels>
      </Tabs>
    </Panel>
  );
};

/** Component used to render the portfolio recon stuff within the new PMS layout where you can only have one tab etc */
export const PortfolioReconPMSLayout = () => {
  const persistedTabs = usePersistedTabs('portfolio/operations/reconciliation', {
    defaultInitialItems: DEFAULT_TABS,
  });

  const tabs = useTabs({
    ...persistedTabs,
  });

  const handleUpdateTab = useDynamicCallback((updatedTab: ReconTab) => {
    tabs.updateItem(updatedTab);
  });

  const selectedTab = tabs.items[tabs.selectedIndex];

  return <ReconOverview tab={selectedTab} updateTab={handleUpdateTab} />;
};
