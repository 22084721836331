export enum MultilegComboType {
  SyntheticCross = 'SyntheticCross',
  Delta1Spread = 'Delta1Spread',
  OptionsCalendarSpread = 'OptionsCalendarSpread',
  OptionsVerticalSpread = 'OptionsVerticalSpread',
}

export function isMultilegOptionComboType(type?: MultilegComboType): boolean {
  return type
    ? [MultilegComboType.OptionsVerticalSpread, MultilegComboType.OptionsCalendarSpread].includes(type)
    : false;
}
