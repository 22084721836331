import { Box, Table, Td, Th, Thead, Tr } from '@talos/kyoko';
import styled, { css } from 'styled-components';

export const DistributionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DistributionTableRow = styled(Tr)<{ faded?: boolean }>`
  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.5;
    `};
`;

export const DistributionTable = styled(Table).attrs({ 'data-testid': 'distribution-table' })`
  margin-top: ${({ theme }) => theme.spacingComfortable}px;

  overflow-x: auto;
  white-space: nowrap;

  ${Thead}, ${Tr} {
    border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
  }

  ${Tr} {
    ${Th}, ${Td} {
      padding-left: ${({ theme }) => theme.spacingComfortable}px;
      padding-right: ${({ theme }) => theme.spacingComfortable}px;
    }
    ${Td} {
      text-align: right;
    }

    ${Td}:first-child, ${Td}:nth-child(2) {
      text-align: left;
    }

    ${Td}:first-child, ${Th}:first-child {
      padding-left: 0;
    }

    ${Td}:last-child, ${Th}:last-child {
      padding-right: 0;
    }
  }
`;

export const LegendIndicator = styled(Box)`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-block;
`;

export const MarketName = styled.span`
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-overflow: ellipsis;
  top: 5px;
  white-space: nowrap;
`;
