import { ShowJson, type ITradeAllocation } from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-enterprise';

export function TradeAllocationMenu(
  params: ICellRendererParams & {
    onShowJson(data: ITradeAllocation): void;
  }
) {
  return (
    <>
      <ShowJson {...params} />
    </>
  );
}
