import {
  useObservableValue,
  wsScanToMap,
  wsSubscriptionCache,
  type MinimalSubscriptionResponse,
  type Position,
} from '@talos/kyoko';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { useUnifiedPositionsBalancesObs } from '../../../Blotters/PositionsV3/Unified/useUnifiedPositionsBalancesObs';
import { useOperationsOverviewContext } from './OperationsOverviewStateProvider';

export const OperationsOverviewPositionsContext = createContext<OperationsOverviewPositionsContextProps | undefined>(
  undefined
);

export type OperationsOverviewPositionsContextProps = {
  positions: Position[] | undefined;
  positionsObs: Observable<MinimalSubscriptionResponse<Position>>;
};

export function useOperationsOverviewPositions() {
  const context = useContext(OperationsOverviewPositionsContext);
  if (context === undefined) {
    throw new Error(
      'Missing OperationsOverviewPositionsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export const OperationsOverviewPositionsProvider = function OperationsOverviewPositionsProvider({
  children,
}: PropsWithChildren) {
  const {
    state: { filter, showZeroBalances },
  } = useOperationsOverviewContext();

  const positionsObs = useUnifiedPositionsBalancesObs({
    tag: 'operations-overview-page',
    filter,
    showZeroBalances,
  });

  const cachedPositionsObs = useMemo(() => positionsObs.pipe(wsSubscriptionCache(pos => pos.rowID)), [positionsObs]);

  const positions = useObservableValue(
    () =>
      cachedPositionsObs.pipe(
        wsScanToMap({ getUniqueKey: pos => pos.rowID, newMapEachUpdate: false }),
        map(mapping => [...mapping.values()]),
        shareReplay({ bufferSize: 1, refCount: true })
      ),
    [cachedPositionsObs]
  );

  const value = useMemo(() => {
    return {
      positions,
      positionsObs: cachedPositionsObs,
    };
  }, [positions, cachedPositionsObs]);

  return (
    <OperationsOverviewPositionsContext.Provider value={value}>{children}</OperationsOverviewPositionsContext.Provider>
  );
};
