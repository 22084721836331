import { useMarketAccountsContext } from '@talos/kyoko';

export function MarketAccountNames({ marketAccounts }: { marketAccounts: string[] }) {
  const { marketAccountDisplayNameByName } = useMarketAccountsContext();
  return (
    <>
      {marketAccounts
        .map(marketAccount => marketAccountDisplayNameByName.get(marketAccount) ?? marketAccount)
        .join(', ')}
    </>
  );
}
