import { EndpointsContext, useUserContext, type EndpointsContextProps } from '@talos/kyoko';
import type { PropsWithChildren } from 'react';

export const EndpointsContextProvider = function EndpointsContextProvider({
  wsEndpoint,
  orgApiEndpoint,
  children,
}: PropsWithChildren<{
  wsEndpoint?: string;
  orgApiEndpoint?: string;
}>) {
  const userContext = useUserContext();
  const value: EndpointsContextProps = {
    apiEndpoint: import.meta.env.VITE_AVA_API_ENDPOINT,
    wsEndpoint: wsEndpoint ?? userContext.orgWsEndpoint,
    orgApiEndpoint: orgApiEndpoint ?? userContext.orgApiEndpoint,
  };
  return <EndpointsContext.Provider value={value}>{children}</EndpointsContext.Provider>;
};
