import {
  SUB_ACCOUNT_ROLLUP,
  request,
  useDynamicCallback,
  useObservable,
  useStaticSubscription,
  useUserContext,
  wsScanToMap,
  type ISubaccount,
  type SubAccount,
} from '@talos/kyoko';
import type React from 'react';
import { createContext, memo, useCallback, useContext } from 'react';
import type { Observable } from 'rxjs';

export interface SubAccountRollupsContextProps {
  subAccountRollupsByNameObs: Observable<Map<string, SubAccount>>;
  createSubAccountRollup: (name: SubAccount['Name']) => Promise<SubAccount>;
  deleteSubAccountRollup: (subAccountID: SubAccount['SubaccountID']) => Promise<void>;
  updateSubAccountRollup: (account: Pick<SubAccount, 'SubaccountID' | 'DisplayName'>) => Promise<SubAccount>;
}

const SubAccountRollupsContext = createContext<SubAccountRollupsContextProps | null>(null);
SubAccountRollupsContext.displayName = 'SubAccountRollupsContext';

// Only consumed by SubAccountsProvider.tsx
export function useSubAccountRollups() {
  const context = useContext(SubAccountRollupsContext);
  if (context == null) {
    throw new Error('Missing SubAccountRollupsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const SubAccountRollupsProvider = memo(function SubAccountRollupsProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const { data: subAccountRollupsObs, push } = useStaticSubscription<ISubaccount>(
    {
      name: SUB_ACCOUNT_ROLLUP,
      tag: 'SubAccountRollupsProvider',
    },
    {
      loadAll: true,
    }
  );

  const handleSuccessfulCRUDOperation = useDynamicCallback((result: ISubaccount[]) => {
    push({
      type: '',
      ts: '',
      data: result,
    });
  });

  const { deleteSubAccountRollup, updateSubAccountRollup, createSubAccountRollup } = useSubAccountRollupRequests({
    onSuccess: handleSuccessfulCRUDOperation,
  });

  const subAccountRollupsByNameObs = useObservable(
    () => subAccountRollupsObs.pipe(wsScanToMap({ getUniqueKey: s => s.Name, newMapEachUpdate: true })),
    [subAccountRollupsObs]
  );
  return (
    <SubAccountRollupsContext.Provider
      value={{
        subAccountRollupsByNameObs,
        createSubAccountRollup,
        deleteSubAccountRollup,
        updateSubAccountRollup,
      }}
    >
      {children}
    </SubAccountRollupsContext.Provider>
  );
});

const useSubAccountRollupRequests = ({ onSuccess }: { onSuccess: (result: ISubaccount[]) => void }) => {
  const { orgApiEndpoint } = useUserContext();

  const deleteSubAccountRollup = useCallback(
    (subAccountID: SubAccount['SubaccountID']) => {
      return orgApiEndpoint
        ? request('DELETE', `${orgApiEndpoint}/subaccounts/${subAccountID}`)
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint]
  );

  const updateSubAccountRollup = useCallback(
    ({ SubaccountID, DisplayName }: Pick<SubAccount, 'DisplayName' | 'SubaccountID'>): Promise<SubAccount> => {
      return orgApiEndpoint
        ? request<{ data: SubAccount[] }>('POST', `${orgApiEndpoint}/subaccounts`, {
            SubaccountID,
            DisplayName: DisplayName,
          }).then(res => {
            onSuccess(res.data);
            return res.data[0];
          })
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint, onSuccess]
  );

  const createSubAccountRollup = useCallback(
    (Name: SubAccount['Name']): Promise<SubAccount> => {
      return orgApiEndpoint
        ? request<{ data: SubAccount[] }>('POST', `${orgApiEndpoint}/subaccounts/rollups`, {
            Name,
            DisplayName: Name,
          }).then(res => {
            onSuccess(res.data);
            return res.data[0];
          })
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint, onSuccess]
  );

  return {
    deleteSubAccountRollup,
    updateSubAccountRollup,
    createSubAccountRollup,
  };
};
