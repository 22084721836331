import { cssVar, Text } from '@talos/kyoko';
import { useDisplaySettings } from 'providers/AppConfigProvider';

type ExposureDisplayProps = {
  label: string;
  show: boolean;
  value: string | undefined;
  numberColor?: string;
  currencyColor?: string;
};

export const ExposureDisplay = ({
  label,
  value,
  show,
  currencyColor = cssVar('colors.gray.090'),
  numberColor = cssVar('colors.gray.100'),
}: ExposureDisplayProps) => {
  const { homeCurrency } = useDisplaySettings();
  // Note the 16px minHeight is to prevent the component from collapsing when the value is empty, therefore
  // causing Highcharts to auto-size the chart improperly
  return (
    <Text minHeight="16px" fontSize="fontSizeMd">
      {!show ? null : (
        <>
          {label} <Text color={numberColor}>{value ?? ''}</Text> <Text color={currencyColor}>{homeCurrency}</Text>
        </>
      )}
    </Text>
  );
};
