import type * as Highcharts from 'highcharts/highstock';
import { useCallback, useState } from 'react';

export interface UseAxisZoomHandlerParams {
  /** Optional callback, called on the highcharts <x|y>Axis.events.afterSetExtremes event with the relevant axis and event */
  onAxisAfterSetExtremes?: (axis: Highcharts.Axis, event: Highcharts.AxisSetExtremesEventObject) => void;
}

/**
 * Handles some complexity around listening to axis zooming events / actions / settings and determining whether or not some event
 * constitutes the axis being "zoomed" or not.
 * @returns whether or not the axis is deemed zoomed, and a callback to attach to the axis.events.afterSetExtremes property
 */
export function useAxisZoomHandler({ onAxisAfterSetExtremes }: UseAxisZoomHandlerParams) {
  const [axisZoomed, setAxisZoomed] = useState(false);
  const handleAxisAfterSetExtremes = useCallback(
    (axis: Highcharts.Axis, event: Highcharts.AxisSetExtremesEventObject) => {
      const zoomingEnabled = event.userMax != null && event.userMin != null;
      const axisZoomed = zoomingEnabled && (event.userMax !== event.dataMax || event.userMin !== event.dataMin);

      setAxisZoomed(axisZoomed);
      onAxisAfterSetExtremes?.(axis, event);
    },
    [onAxisAfterSetExtremes]
  );

  return {
    axisZoomed,
    handleAxisAfterSetExtremes,
  };
}
