import { Box, Button, Flex } from '@talos/kyoko';
import styled from 'styled-components';

export const FeesWrapper = styled.form`
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeeSection = styled.div<{ selected: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacingBig}px;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;

  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  transition: opacity 200ms;
`;

export const FeeTitle = styled.div`
  grid-row-start: 1;
  grid-column-start: 2;

  h3 {
    margin: 0;
  }
`;

export const FeeDescription = styled(Box)`
  margin-top: ${({ theme }) => theme.spacingDefault}px;

  grid-row-start: 2;
  grid-column-start: 2;

  p {
    margin: 0;
  }
`;

export const FeeLabel = styled.label`
  margin-right: ${({ theme }) => theme.spacingDefault}px;
  color: ${({ theme }) => theme.colorTextSubtle};
`;

export const FeeCell = styled.div`
  flex: 1;
  text-align: right;
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const FeeRow = styled.div`
  grid-column-start: 2;

  margin-top: ${({ theme }) => theme.spacingLarge}px;

  padding: ${({ theme }) => theme.spacingMedium}px;
  background: ${({ theme }) => theme.colors.gray['030']};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;

  display: flex;
  align-items: center;
  min-height: 26px;
  gap: ${({ theme }) => theme.spacingLarge}px;
`;

export const CustomFeeLevelInputs = styled(Flex)`
  grid-row-start: 3;
  grid-column-start: 2;
`;

export const ShowMoreButton = styled(Button)`
  background: transparent;
  display: block;
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingDefault}px 0 0`};
`;
