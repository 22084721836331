import { TreasuryLinkTypeEnum } from '@talos/kyoko';

interface DefaultAddressSummary {
  description?: string;
  title?: string;
}

export const getLinkTypeSummary = (type: TreasuryLinkTypeEnum): DefaultAddressSummary => {
  let title: string | undefined;
  let description: string | undefined;
  switch (type) {
    case TreasuryLinkTypeEnum.CustomerTransfer: {
      title = 'Global Customer Defaults';
      description = 'Defaults apply to all customers below. Defaults are optional and can be overridden per customer.';
      break;
    }
    case TreasuryLinkTypeEnum.ExchangeRebalance: {
      title = 'Global Exchange Defaults';
      description = 'Defaults apply to all exchanges below. Defaults are optional and can be overridden per exchange.';
      break;
    }
    case TreasuryLinkTypeEnum.OTCSettlement: {
      title = 'Global OTC Settlement Defaults';
      description =
        'Defaults apply to all OTC counterparties below. Defaults are optional and can be overridden per counterparty.';
      break;
    }
  }

  return { description, title };
};
