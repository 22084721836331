import type React from 'react';
import { useCallback, useEffect, useRef, type FocusEvent, type KeyboardEvent, type MouseEvent } from 'react';
import styled, { useTheme } from 'styled-components';
import { useDynamicCallback } from '../../../hooks';
import { Z_INDEX } from '../../../styles';
import { Button, InternalButtonSpan } from '../../Button';
import type { FilterableSelectProperty } from '../../Filters';
import { FilterCheckboxDropdown } from '../../Filters/FilterBuilder/RHS/FilterCheckboxDropdown';
import { DEFAULT_FILTER_BUILDER_DROPDOWN_WIDTH } from '../../Filters/FilterBuilder/tokens';
import { OverflowItemsBox } from '../../OverflowItemsBox';
import { useDropdownPopper } from '../Dropdown';
import { useMultiSelectAutocomplete } from '../MultiSelect/useMultiselectAutocomplete';
import { useMultiSelectSelectionManager } from '../MultiSelect/useMultiSelectSelectionManager';

type MultiSelectCheckboxDropdownProps<T> = {
  prefix: React.ReactNode;
  defaultLabel?: React.ReactNode;
  property: FilterableSelectProperty<string, T>;
  selections: T[];
  onSelectionsChange: (newSelections: T[]) => void;
  onDropdownTabOut?: (event: KeyboardEvent<HTMLElement>) => void;
};

/** Dropdown component to support MultiSelect scenarios.
 * - This is distinct from the MultiSelect component as it doesn't involve chip selection (the box is a simple managed control button),
 * and is based on the PropertyMultiSelectList implementation used in filters  */
export function MultiSelectCheckboxDropdown<T = string>({
  prefix,
  defaultLabel = 'Select...',
  property,
  selections,
  onSelectionsChange,
}: MultiSelectCheckboxDropdownProps<T>) {
  const theme = useTheme();
  const { getOptionLabel } = property;
  const inputRef = useRef<HTMLInputElement>(null);
  const refElement = useRef<HTMLButtonElement>(null);

  const { dropdownItems, handleSelectionsChange, handleOpenChange } = useMultiSelectSelectionManager<T>({
    property,
    selections,
    onSelectionsChange,
  });

  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete<T>({
    selections: selections,
    items: dropdownItems,
    initialSortByLabel: false,
    getLabel: getOptionLabel,
    getDescription: property.getOptionDescription,
    getGroup: property.getOptionGroup,
    groupSorter: property.groupSorter,
    onChange: handleSelectionsChange,
    onIsOpenChange: handleOpenChange,
    clearInputAfterSelection: false,
    removeItemOnInputBackspace: false,
    highlightInputTextAfterSelection: true,
    inputRef,
    matchThreshold: property.matchThreshold,
  });

  const { isOpen, openMenu, closeMenu } = autocompleteOutput;

  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement: refElement.current,
    dropdownWidth: property.dropdownOptions?.dropdownWidth ?? DEFAULT_FILTER_BUILDER_DROPDOWN_WIDTH,
    dropdownPlacement: 'bottom-start',
  });

  const updatePopperPosition = dropdownPopper.popper.update;

  useEffect(() => {
    if (selections) {
      // every time `the clauses change, we update the popper position
      updatePopperPosition && updatePopperPosition();
    }
  }, [selections, updatePopperPosition]);

  const handleOpenClick = useCallback(
    (e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>) => {
      e.preventDefault();
      updatePopperPosition && updatePopperPosition();
      openMenu();
      setTimeout(() => inputRef.current?.focus(), 0);
    },
    [openMenu, updatePopperPosition]
  );

  const handleClearAll = useCallback(() => {
    onSelectionsChange([]);
  }, [onSelectionsChange]);

  const handleSelectAll = useCallback(() => {
    onSelectionsChange(property.options);
  }, [onSelectionsChange, property.options]);

  const getOptionsLabel = useDynamicCallback(getOptionLabel);

  return (
    <>
      <MultiSelectCheckboxDropdownButton
        data-testid="base-select-button"
        ref={refElement}
        onClick={e => handleOpenClick(e)}
        flex="auto"
        width="100%"
        isOpen={isOpen}
      >
        <OverflowItemsBox
          prefix={prefix}
          defaultLabel={defaultLabel}
          items={multipleSelectionOutput.selectedItems}
          getItemLabel={getOptionsLabel}
          tooltipZIndex={Z_INDEX.dropdown + 1}
          labelFontSize={theme.fontSizeSmall}
          chipFontSize={theme.fontSizeTiny}
        />
      </MultiSelectCheckboxDropdownButton>
      <FilterCheckboxDropdown
        {...autocompleteOutput}
        {...dropdownPopper}
        data-testid="selection-dropdown"
        property={property}
        selectedItems={multipleSelectionOutput.selectedItems}
        inputRef={inputRef}
        onSelectAll={handleSelectAll}
        onClearAll={handleClearAll}
        onTabOut={closeMenu}
        getDropdownProps={multipleSelectionOutput.getDropdownProps}
        addSelectedItem={multipleSelectionOutput.addSelectedItem}
        removeSelectedItem={multipleSelectionOutput.removeSelectedItem}
        maxHeight={property.dropdownOptions?.maxHeight}
        groupMaxHeight={property.dropdownOptions?.groupMaxHeight}
      />
    </>
  );
}

const MultiSelectCheckboxDropdownButton = styled(Button)<{
  isOpen: boolean;
}>`
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingDefault}px`};
  justify-content: flex-start;
  text-align: left;
  ${({ theme, isOpen }) => {
    return isOpen
      ? `
      border-color: ${theme.borderColorSelectHover};
      background: ${theme.backgroundSelectHover};
      color: ${theme.colorTextImportant};
    `
      : ``;
  }};

  > ${InternalButtonSpan} {
    display: unset;
    flex: auto;
  }
`;
