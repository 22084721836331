import {
  EMPTY_OBJECT,
  IconName,
  MarketAccountTypeEnum,
  useDynamicCallback,
  useMarketAccountsContext,
  type FilterableProperty,
  type MarketAccount,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { useCustomersByName } from '../useCustomer';

interface UseMarketAccountsFilterParams {
  /** Pass in a custom filter to filter the selectable options however you'd like */
  customOptionsFilter?: (marketAccount: MarketAccount) => boolean;
}

/**
 * This hook creates the filter property definition for Market Accounts properties.
 *
 * @returns Filter Property definition to use for Market Accounts filters
 */
export function useMarketAccountsFilter({ customOptionsFilter }: UseMarketAccountsFilterParams = EMPTY_OBJECT) {
  const { marketAccountsList, marketAccountsByName } = useMarketAccountsContext();
  const customersByName = useCustomersByName();

  const marketAccountOptions = useMemo(() => {
    if (customOptionsFilter) {
      return marketAccountsList.filter(customOptionsFilter).map(ma => ma.Name);
    }

    return marketAccountsList.map(ma => ma.Name);
  }, [marketAccountsList, customOptionsFilter]);

  const getOptionLabel = useDynamicCallback((marketAccountName: string) => {
    const marketAccount = marketAccountsByName.get(marketAccountName);
    if (marketAccount?.Type === MarketAccountTypeEnum.Customer) {
      const customerName = marketAccount.Counterparty;
      const customer = customersByName?.get(customerName);
      if (customer != null) {
        return `${customer.DisplayName ?? customer.Name} - ${marketAccount.SourceAccountID ?? marketAccount.Name}`;
      }

      return marketAccountName;
    }
    return marketAccount?.DisplayName ?? marketAccountName;
  });

  return useMemo(
    () =>
      ({
        key: 'MarketAccounts',
        label: 'Market Account',
        icon: IconName.Identification,
        options: marketAccountOptions,
        getOptionLabel: getOptionLabel,
        getOptionDescription: (ma: string) => {
          const marketAccount = marketAccountsByName.get(ma);
          if (marketAccount?.Type === MarketAccountTypeEnum.Customer) {
            return marketAccount.Name;
          }
          return '';
        },
        dropdownOptions: {
          dropdownWidth: '350px',
        },
      } as const satisfies FilterableProperty),
    [marketAccountOptions, marketAccountsByName, getOptionLabel]
  );
}
