import { FieldValidationLevel, type Field, type FieldValidationRule } from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import type { SalesOrderFormState } from './types';

export const validateOrderMarkets: FieldValidationRule<Field<boolean>, WritableDraft<SalesOrderFormState>> = (
  field,
  context
) => {
  if (!field.value) {
    const orderMarkets = context?.orderMarketsField;

    if (!orderMarkets?.hasValue) {
      return {
        message: `Please select the Markets in the preview order`,
        level: FieldValidationLevel.Error,
      };
    }
  }

  return null;
};
