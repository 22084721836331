import type { MergedBalanceIndexKeys } from './types';

export const TREASURY_BLOTTER_PREFIX = 'treasury-management/balances';
export const DEV_NOGROUP_GROUP = '$DEV_NOGROUP_GROUP';
export const KEYS_BY_COUNTERPARTY: MergedBalanceIndexKeys[] = ['marketAccountID', 'currency'];
export const KEYS_BY_ASSET: MergedBalanceIndexKeys[] = ['currency', 'marketAccountID'];

export const VisualizationVariants = ['Value', 'Equal'] as const;
export type VisualizationVariant = (typeof VisualizationVariants)[number];

export const TREASURY_MANAGEMENT_BLOTTER_TABS_ID = 'portfolio/treasury-mgmt-tabs';
