import type { ProductTypeEnum } from '@talos/kyoko';

export interface SettlementMonitoringFilter {
  AssetTypes?: ProductTypeEnum[];
  Symbols?: string[];
  MarketAccounts?: string[];
  MarketAccountTypes?: string[];
}

export interface SettlementMonitoringState {
  groupBy: 'account' | 'asset';
  filter: SettlementMonitoringFilter;
  counterpartyType: 'customers'; // setting this so we can expand to more types in the future without having to do a migration
  summaryOpen: boolean;
  groupByCounterparty: boolean;
}

export const DEFAULT_CUSTOMER_SETTLEMENT_MONITORING_STATE: SettlementMonitoringState = {
  groupBy: 'account',
  filter: {},
  counterpartyType: 'customers',
  summaryOpen: false,
  groupByCounterparty: false,
};
