import { ProductTypeEnum, type CurrencySelectItem, type Security, type StringSelectItem } from '@talos/kyoko';
import { Future, Option, Perp, Spot, type CoinSelectItem, type SymbolSelectItem } from 'components/OMS/NewOrder/models';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../OMS/types';
import { MultilegComboType } from '../enums';

export type Leg = Spot | Option | Future | Perp | undefined;

// Facade to encapsulate all the dirty work of interacting with the underlying Spot/Future/Perp/Options used in Delta1/Cross ML
// Note that similar to other classes used within the Redux store, this class is immutable
export class LegsContainer {
  constructor(
    private readonly _referenceData: OMSReferenceDataState,
    private readonly _legs: Leg[],
    private readonly _initiatingLegs: boolean[],
    private readonly _instrumentType?: MultilegComboType
  ) {}

  public static isLegOption(leg: Leg): leg is Option {
    return leg instanceof Option;
  }

  public static isLegFuture(leg: Leg): leg is Future {
    return leg instanceof Future;
  }

  public static isLegPerp(leg: Leg): leg is Perp {
    return leg instanceof Perp;
  }

  public static isLegSpot(leg: Leg): leg is Spot {
    return leg instanceof Spot;
  }

  public static isLegDefined(leg: Leg): leg is Spot {
    return leg !== undefined;
  }

  public get instrumentType(): MultilegComboType | undefined {
    return this._instrumentType;
  }

  public get legs(): Leg[] {
    return this._legs;
  }

  public get initiatingLegs(): boolean[] {
    return this._initiatingLegs;
  }

  public get hasOption(): boolean {
    return this._legs.some(leg => leg instanceof Option);
  }

  public get hasFuture(): boolean {
    return this._legs.some(leg => leg instanceof Future);
  }

  public get hasPerp(): boolean {
    return this._legs.some(leg => leg instanceof Perp);
  }

  public get supportsGreeks(): boolean {
    return this.hasOption;
  }

  public get supportsMark(): boolean {
    return this.hasOption || this.hasFuture || this.hasPerp;
  }

  public get supportsExchange(): boolean {
    return this.hasOption || this.hasFuture || this.hasPerp;
  }

  public getAvailableProductTypes(legIndex: number): ProductTypeEnum[] {
    if (this._instrumentType === MultilegComboType.SyntheticCross) {
      return [ProductTypeEnum.Spot];
    }

    // For delta1, leg1 will always contain all product types and we filter leg2 based on leg1
    // Temporarily not supporting Options (BE will allow it to create the symbol but not fully supported yet)
    if (legIndex === 0) {
      return [ProductTypeEnum.Spot, ProductTypeEnum.PerpetualSwap, ProductTypeEnum.Future];
    }

    if (LegsContainer.isLegSpot(this._legs[0])) {
      return [ProductTypeEnum.Spot, ProductTypeEnum.PerpetualSwap, ProductTypeEnum.Future];
    }
    if (LegsContainer.isLegPerp(this._legs[0])) {
      return [ProductTypeEnum.Spot, ProductTypeEnum.PerpetualSwap, ProductTypeEnum.Future];
    }
    if (LegsContainer.isLegFuture(this._legs[0])) {
      return [ProductTypeEnum.Spot, ProductTypeEnum.PerpetualSwap, ProductTypeEnum.Future];
    }
    // if (LegsContainer.isLegOption(this._legs[0])) {
    //   return [ProductTypeEnum.Future];
    // }

    return [];
  }

  public getLegSecurity(legIndex: number): Security | undefined {
    const leg = this._legs[legIndex];
    return leg?.security;
  }

  public updateSecurityForLeg(security: Security, legIndex: number): LegsContainer {
    const newLegs = [...this._legs];
    let newLeg: Leg;
    switch (security.ProductType) {
      case ProductTypeEnum.PerpetualSwap:
        newLeg = Perp.createFromSecurity(this._referenceData, security);
        break;
      case ProductTypeEnum.Future:
        newLeg = Future.createFromSecurity(this._referenceData, security);
        break;
      case ProductTypeEnum.Option:
        newLeg = Option.createFromSecurity(this._referenceData, security);
        break;
      case ProductTypeEnum.Spot:
        newLeg = Spot.createFromSecurity(this._referenceData, security);
        break;
      default:
        newLeg = undefined;
    }
    newLegs[legIndex] = newLeg;

    return this.newInstance(newLegs).ensureLegsAreValid();
  }

  public updateProductTypeForLeg(productType: ProductTypeEnum, legIndex: number): LegsContainer {
    let newLeg: Leg;
    switch (productType) {
      case ProductTypeEnum.PerpetualSwap:
        newLeg = Perp.createFromBlank(this._referenceData);
        break;
      case ProductTypeEnum.Future:
        newLeg = Future.createFromBlank(this._referenceData);
        break;
      case ProductTypeEnum.Option:
        newLeg = Option.createFromBlank(this._referenceData);
        break;
      case ProductTypeEnum.Spot:
        newLeg = Spot.createFromBlank(this._referenceData);
        break;
      default:
        newLeg = undefined;
    }
    const newLegs = [...this._legs];
    newLegs[legIndex] = newLeg;

    return this.newInstance(newLegs).ensureLegsAreValid();
  }

  // when we change the first leg we want to make sure the 2nd leg is still valid since 1st leg contains all product types
  private ensureLegsAreValid(): LegsContainer {
    if (this._legs[1]) {
      const availableProductTypes = this.getAvailableProductTypes(1);

      if (!availableProductTypes.includes(this._legs[1].getType())) {
        const newLegs = [...this._legs];
        newLegs[1] = undefined;
        return this.newInstance(newLegs);
      }
    }
    return this;
  }

  public setLegs(legs: Leg[]): LegsContainer {
    return this.newInstance(legs);
  }

  public updateInitiatingLegs(initiating: boolean, legIndex: number): LegsContainer {
    const otherIndex = legIndex === 0 ? 1 : 0;
    const newInitiatingLegs = [...this._initiatingLegs];

    if (this._initiatingLegs[legIndex] && !initiating && !this._initiatingLegs[otherIndex]) {
      // ensure at least 1 leg is initiating
      return this.newInstance();
    }
    newInitiatingLegs[legIndex] = initiating;
    return this.newInstance(undefined, newInitiatingLegs);
  }

  public setInitiatingLegs(initiatingLegs: boolean[]): LegsContainer {
    return this.newInstance(undefined, initiatingLegs);
  }

  public getProductTypeForLeg(legIndex: number): ProductTypeEnum | undefined {
    const leg = this._legs[legIndex];
    return LegsContainer.isLegFuture(leg)
      ? ProductTypeEnum.Future
      : LegsContainer.isLegOption(leg)
      ? ProductTypeEnum.Option
      : LegsContainer.isLegPerp(leg)
      ? ProductTypeEnum.PerpetualSwap
      : LegsContainer.isLegSpot(leg)
      ? ProductTypeEnum.Spot
      : undefined;
  }

  public updateInstrumentType(instrument: MultilegComboType): LegsContainer {
    return this.newInstance(undefined, undefined, instrument);
  }

  public updateSymbol(symbol: CurrencySelectItem | undefined, legIndex: number): LegsContainer {
    let newLeg = this._legs[legIndex];
    if (LegsContainer.isLegOption(newLeg)) {
      newLeg = newLeg.updateCoin(symbol as CoinSelectItem);
    } else if (LegsContainer.isLegFuture(newLeg)) {
      newLeg = newLeg.updateSymbol(symbol as SymbolSelectItem);
    } else if (LegsContainer.isLegDefined(newLeg)) {
      newLeg = newLeg.updateSymbol(symbol);
    }
    const newLegs = [...this._legs];
    newLegs[legIndex] = newLeg;

    return this.newInstance(newLegs);
  }

  public getAvailableSymbols(legIndex: number): StringSelectItem[] {
    const leg = this._legs[legIndex];
    const baseCurrency = legIndex === 1 ? this._legs[0]?.security?.BaseCurrency : this._legs[1]?.security?.BaseCurrency;
    const quoteCurrency =
      legIndex === 1 ? this._legs[0]?.security?.QuoteCurrency : this._legs[1]?.security?.QuoteCurrency;

    let availableItems: CurrencySelectItem[] = [];

    if (LegsContainer.isLegOption(leg)) {
      availableItems = leg.data.coinField.availableItems;
    } else if (LegsContainer.isLegFuture(leg)) {
      availableItems = leg.data.symbolField.availableItems;
    } else if (LegsContainer.isLegDefined(leg)) {
      availableItems = leg.data.symbolField.availableItems;
    }

    return availableItems.filter(item => {
      if (this.instrumentType === MultilegComboType.Delta1Spread) {
        return !baseCurrency ? true : item.baseCurrency === baseCurrency;
      }
      if (this.instrumentType === MultilegComboType.SyntheticCross) {
        const validCross =
          item.baseCurrency === baseCurrency ||
          item.baseCurrency === quoteCurrency ||
          item.quoteCurrency === baseCurrency ||
          item.quoteCurrency === quoteCurrency;
        return !baseCurrency && !quoteCurrency ? true : validCross;
      }
      return true;
    });
  }

  public getSymbolForLeg(legIndex: number) {
    const leg = this._legs[legIndex];
    return LegsContainer.isLegOption(leg) ? leg.data.coinField.value : leg?.data.symbolField.value;
  }

  public updateExchange(exchange: StringSelectItem | undefined, legIndex: number): LegsContainer {
    let newLeg = this._legs[legIndex];
    if (LegsContainer.isLegOption(newLeg)) {
      newLeg = newLeg.updateExchange(exchange);
    } else if (LegsContainer.isLegSpot(newLeg)) {
      throw new Error('Invalid operation: Tried to update exchange on a Spot security');
    } else if (LegsContainer.isLegPerp(newLeg) || LegsContainer.isLegFuture(newLeg)) {
      newLeg = newLeg.updateExchangeAndType(exchange).updateMarketAccount(undefined);
    }
    const newLegs = [...this._legs];
    newLegs[legIndex] = newLeg;

    return this.newInstance(newLegs);
  }

  public getAvailableExchanges(legIndex: number): StringSelectItem[] {
    const leg = this._legs[legIndex];
    if (LegsContainer.isLegOption(leg)) {
      return leg.data.exchangeField.availableItems;
    } else if (LegsContainer.isLegSpot(leg)) {
      return [];
    } else {
      return leg?.data.exchangeAndTypeField.availableItems || [];
    }
  }

  public getExchangeForLeg(legIndex: number) {
    const leg = this._legs[legIndex];
    return LegsContainer.isLegFuture(leg) || LegsContainer.isLegPerp(leg)
      ? leg.data.exchangeAndTypeField.value
      : LegsContainer.isLegOption(leg)
      ? leg.data.exchangeField.value
      : undefined;
  }

  public updateExpiry(expiry: StringSelectItem | undefined, legIndex: number): LegsContainer {
    let newLeg = this._legs[legIndex];
    if (LegsContainer.isLegOption(newLeg)) {
      newLeg = newLeg.updateExpiry(expiry);
    } else if (LegsContainer.isLegFuture(newLeg)) {
      newLeg = newLeg.updateExpiry(expiry);
    } else {
      throw new Error('Invalid operation: Tried to update expiry on a Spot / Perp security');
    }
    const newLegs = [...this._legs];
    newLegs[legIndex] = newLeg;

    return this.newInstance(newLegs);
  }

  public getAvailableExpiries(legIndex: number): StringSelectItem[] {
    const leg = this._legs[legIndex];
    if (LegsContainer.isLegOption(leg)) {
      return leg.data.expiryField.availableItems;
    } else if (LegsContainer.isLegFuture(leg)) {
      return leg.data.expiryField.availableItems;
    } else {
      return [];
    }
  }

  public getExpiryForLeg(legIndex: number) {
    const leg = this._legs[legIndex];
    return LegsContainer.isLegFuture(leg) || LegsContainer.isLegOption(leg) ? leg.data.expiryField.value : undefined;
  }

  public updateStrike(strike: StringSelectItem | undefined, legIndex: number): LegsContainer {
    let newLeg = this._legs[legIndex];
    if (LegsContainer.isLegOption(newLeg)) {
      newLeg = newLeg.updateStrike(strike);
    } else {
      throw new Error('Invalid operation: Tried to update strike on a non Option security');
    }
    const newLegs = [...this._legs];
    newLegs[legIndex] = newLeg;

    return this.newInstance(newLegs);
  }

  public getStrikeForLeg(legIndex: number) {
    const leg = this._legs[legIndex];
    return LegsContainer.isLegOption(leg) ? leg.data.strikeField.value : undefined;
  }

  public getAvailableStrikes(legIndex: number): StringSelectItem[] {
    const leg = this._legs[legIndex];
    if (LegsContainer.isLegOption(leg)) {
      return leg.data.strikeField.availableItems;
    } else {
      return [];
    }
  }

  public updateType(type: StringSelectItem, legIndex: number): LegsContainer {
    let newLeg = this._legs[legIndex];
    if (LegsContainer.isLegOption(newLeg)) {
      newLeg = newLeg.updateType(type);
    } else {
      throw new Error('Invalid operation: Tried to update type on a non Option security');
    }
    const newLegs = [...this._legs];
    newLegs[legIndex] = newLeg;

    return this.newInstance(newLegs);
  }

  public getAvailableTypes(legIndex: number): StringSelectItem[] {
    const leg = this._legs[legIndex];
    if (LegsContainer.isLegOption(leg)) {
      return leg.data.typeField.availableItems;
    } else {
      return [];
    }
  }

  public getTypeForLeg(legIndex: number) {
    const leg = this._legs[legIndex];
    return LegsContainer.isLegOption(leg) ? leg.data.typeField.value : undefined;
  }

  public updateMarketAccounts(accounts: StringSelectItem[], legIndex: number): LegsContainer {
    let newLeg = this._legs[legIndex];
    newLeg = newLeg?.updateMarketAccount(accounts);
    const newLegs = [...this._legs];
    newLegs[legIndex] = newLeg;

    return this.newInstance(newLegs);
  }

  public getMarketAccountsForLeg(legIndex: number): StringSelectItem[] {
    const leg = this._legs[legIndex];
    return leg?.data.marketAccountField.value || [];
  }

  private newInstance(
    legs: Leg[] = this._legs,
    initiatingLegs: boolean[] = this._initiatingLegs,
    instrumentType: MultilegComboType | undefined = this._instrumentType
  ): LegsContainer {
    return new LegsContainer(this._referenceData, legs, initiatingLegs, instrumentType);
  }
}

LegsContainer[immerable] = true;
