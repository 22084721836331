import { AccordionRowHeader, Box } from '@talos/kyoko';
import styled from 'styled-components';
import { OnlyButton } from './OnlyButton/OnlyButton';

export const Content = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DealerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacingLarge}px;
`;

export const DealerName = styled.span`
  font-size: ${({ theme }) => theme.fontSizeLarge}rem;
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const AnimatedOnlyButton = styled(OnlyButton)`
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease;
  pointer-events: none;
`;

export const SettlementAccordionRowHeader = styled(AccordionRowHeader)<{
  showOnlyButton: boolean;
  applyLowerOpacity: boolean;
}>`
  ${({ applyLowerOpacity }) => applyLowerOpacity && `opacity: 0.7`};

  ${({ showOnlyButton }) =>
    showOnlyButton &&
    `
  &:hover {
    ${AnimatedOnlyButton} {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  `}
`;

export const WarningBottomBorder = styled.td`
  padding: 0;
  height: 1px;
  background: ${({ theme }) => theme.colors.gray['100']};
`;

export const BorderBox = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.borderColorDivider};
`;
