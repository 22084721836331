import {
  Card,
  CurrencyIcon,
  Divider,
  HStack,
  IndicatorBadge,
  InlineFormattedNumber,
  ReconMismatchStatus,
  Text,
  VStack,
  formattedDate,
  reduceReconMismatchAmounts,
  reduceReconMismatchStartAndEndTimes,
  reduceReconStatuses,
  type ReconMismatch,
} from '@talos/kyoko';
import { useMemo } from 'react';

interface ReconMismatchesSummaryProps {
  reconMismatches: ReconMismatch[];
}

export const ReconMismatchesSummary = ({ reconMismatches }: ReconMismatchesSummaryProps) => {
  const plural = reconMismatches.length > 1;
  const reducedReconStatus = useMemo(() => reduceReconStatuses(reconMismatches.map(r => r.State)), [reconMismatches]);
  const reducedTimes = useMemo(() => reduceReconMismatchStartAndEndTimes(reconMismatches), [reconMismatches]);
  const reducedAmounts = useMemo(() => reduceReconMismatchAmounts(reconMismatches), [reconMismatches]);

  return (
    <Card>
      <VStack fontSize="fontSizeSmall" gap="spacingSmall">
        {/* Mismatches count and states */}
        <HStack justifyContent="space-between" w="100%">
          <IndicatorBadge
            children={
              <Text color="colorTextAttention">
                {reconMismatches.length} {plural ? 'Mismatches' : 'Mismatch'} selected
              </Text>
            }
          />
          {reducedReconStatus === 'Mixed States' || reducedReconStatus === 'No State' ? (
            reducedReconStatus
          ) : (
            <ReconMismatchStatus status={reducedReconStatus} />
          )}
        </HStack>

        {/* Mismatches date range */}
        {reducedTimes && (
          <HStack w="100%" justifyContent="space-between">
            <Text>Between</Text>
            <Text color="colorTextAttention">
              {formattedDate(reducedTimes.StartTime)} - {formattedDate(reducedTimes.EndTime)}
            </Text>
          </HStack>
        )}

        {reducedAmounts.length > 0 && (
          <>
            <Divider orientation="horizontal" my="spacingDefault" />
            {reducedAmounts.map(({ asset, amount }) => (
              <HStack w="100%" justifyContent="space-between" key={asset}>
                <HStack gap="spacingSmall">
                  <CurrencyIcon currency={asset} />
                  <Text>{asset}</Text>
                </HStack>
                <InlineFormattedNumber number={amount} currency={asset} align="right" />
              </HStack>
            ))}
          </>
        )}
      </VStack>
    </Card>
  );
};
