import { Component, type ErrorInfo, type PropsWithChildren } from 'react';

import { Crossmark, logger, type FlexProps } from '@talos/kyoko';
import { ErrorContainer, Wrapper } from './styles';

export class ErrorBoundary extends Component<
  PropsWithChildren<{ message?: string } & FlexProps>,
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, info: ErrorInfo) {
    // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info.componentStack;
    renderingError.cause = error;
    logger.error(error);
  }

  override render() {
    if (this.state.hasError) {
      return (
        <Wrapper {...this.props}>
          <ErrorContainer>
            <div>
              <Crossmark />
            </div>
            <div>
              {this.props.message || (
                <>
                  <p>An unexpected error occurred.</p>
                  <p>
                    If this issue persists, please get in touch with Talos support and we will resolve the problem as
                    soon as possible.
                  </p>
                  <p>
                    <a href="mailto:support@talos.com">support@talos.com</a>
                  </p>
                  <p>{import.meta.env.VITE_SUPPORT_PHONE}</p>
                </>
              )}
            </div>
          </ErrorContainer>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}
