import type { BlotterTableClientLocalFilter, User } from '@talos/kyoko';
import { intersection, pick } from 'lodash';
import { useCallback, useState } from 'react';

export interface UsersBlotterTableFilter {
  Roles?: string[];
  ShowDeletedUsers: boolean;
}

export const useUserFilter = () => {
  const [filter, setFilter] = useState<UsersBlotterTableFilter>({
    ShowDeletedUsers: false,
  });

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<User>>(
    row => {
      const data = row.data;

      if (filter.Roles && filter.Roles.length > 0 && intersection(data?.Roles ?? [], filter.Roles).length === 0) {
        return false;
      }
      return true;
    },
    [filter]
  );

  function onlyServerFilterKeys(filter?: UsersBlotterTableFilter) {
    return filter == null ? filter : pick(filter, ['ShowDeletedUsers']);
  }

  return {
    filter,
    changeFilter: setFilter,
    clientSideFilter,
    onlyServerFilterKeys,
  };
};
