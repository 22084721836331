import { Meter } from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import { isCareOrderRow, type CareOrderBlotterEntity } from './types';
import type { CareOrderProgressBar } from './utils';

export function AgCareOrderProgress(params: ICellRendererParams<CareOrderBlotterEntity>) {
  if (isCareOrderRow(params.data)) {
    const bars: CareOrderProgressBar[] = params.value;
    return (
      <Meter label={params.valueFormatted!}>
        {bars.map(bar =>
          bar.valuePercent.eq(0) ? null : (
            <Meter.Bar
              key={bar.id}
              width={bar.valuePercent.toNumber() * 100}
              color={bar.color}
              appearance={bar.appearance}
            />
          )
        )}
      </Meter>
    );
  }

  return <Meter label={params.valueFormatted!} {...params.value.meter} showInitialAnimation={false} animate={false} />;
}
