import { useTheme, type DefaultTheme } from 'styled-components';
import type { Currency } from '../../types';
import type { RequiredProperties } from '../../utils';
import { Flex } from '../Core';
import { CurrencyIcon } from '../CurrencyIcon';
import { Text } from '../Text';

type CurrencyRendererProps = {
  currencyString?: string;
  currency?: RequiredProperties<Partial<Currency>, 'Description' | 'Symbol'>;
  showDescription?: boolean;
  showIcon?: boolean;
  theme?: DefaultTheme;
  iconSize?: number;
  colorful?: boolean;
  labelTextColor?: React.CSSProperties['color'] | keyof DefaultTheme;
  descriptionTextColor?: React.CSSProperties['color'] | keyof DefaultTheme;
};

export function CurrencyRenderer({
  currencyString,
  currency,
  showDescription,
  showIcon = true,
  theme,
  iconSize = 16,
  colorful,
  labelTextColor,
  descriptionTextColor,
}: CurrencyRendererProps) {
  const hookTheme = useTheme();
  const themeToUse = theme ?? hookTheme;
  const { fontSizeTiny, colorTextSubtle, colorTextImportant } = themeToUse;

  const labelColor = labelTextColor ?? colorTextImportant;
  const descriptionColor = descriptionTextColor ?? colorTextSubtle;

  const symbol = currencyString ?? currency?.Symbol;

  return (
    <Flex alignItems="center" gap="spacingSmall">
      {showIcon && symbol && (
        <CurrencyIcon currency={symbol} size={iconSize} style={ICON_STYLE} theme={themeToUse} colorful={colorful} />
      )}
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <Text color={labelColor}>{symbol}</Text>
        {showDescription && (
          <Text as="div" color={descriptionColor} size={fontSizeTiny}>
            {currency?.Description}
          </Text>
        )}
      </div>
    </Flex>
  );
}

const ICON_STYLE = {
  flexShrink: 0,
};
