import { useEnrichedDeltaBalancesObs } from '../../../Blotters/BalancesV2/useEnrichedDeltaBalancesObs';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';

export const useBalancesSub = () => {
  const {
    state: { filter, showZeroBalances },
  } = useTreasuryManagementContext();

  const { enrichedDeltaBalancesObs } = useEnrichedDeltaBalancesObs({
    requestTagSuffix: '-TreasuryManagement',
    throttle: '2s',
    showZeroBalances,
    filter,
  });

  return enrichedDeltaBalancesObs;
};
