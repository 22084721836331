import {
  AccordionRowBodyCell,
  Divider,
  Flex,
  Icon,
  IconName,
  Modal,
  Popover,
  Text,
  useDisclosure,
  useMarketAccountsContext,
  usePopoverState,
} from '@talos/kyoko';
import Big from 'big.js';
import { PortfolioTh } from 'containers/Portfolio/styles';
import type { MarketPositions, Position } from 'containers/Portfolio/types';
import { memo, useCallback, useMemo, useState } from 'react';
import { SETTLE_MODAL_WIDTH } from '../../utils';
import type { AmountEntry } from '../PositionsFormReducer';
import { SettleModalContent } from '../Settle/SettleModalContent';
import { CurrencySettlementRow } from './CurrencySettlementRow';
import { SettlementBodyContainer } from './styles';

export interface SettlementBodyProps {
  marketPositions: MarketPositions;
  formStateMap?: Map<string, AmountEntry>;
}

export const SettlementBody = memo(({ marketPositions, formStateMap }: SettlementBodyProps) => {
  const { Positions, MarketAccount } = marketPositions;
  const { marketAccountsByName } = useMarketAccountsContext();
  const mktAccDisplayName = marketAccountsByName.get(MarketAccount)?.DisplayName;

  const [incoming, outgoing] = useMemo(() => {
    const newIncoming: Position[] = [];
    const newOutgoing: Position[] = [];

    Positions.forEach(pos => {
      if (Big(pos.Amount).lt(0)) {
        newOutgoing.push(pos);
      } else {
        newIncoming.push(pos);
      }
    });

    return [newIncoming, newOutgoing];
  }, [Positions]);

  const [positionToSettle, setPositionToSettle] = useState<Position | undefined>();

  const modal = useDisclosure({});

  const { open: openModal, close: closeModal } = modal;

  const handleSettleClick = useCallback(
    (position: Position, amount: string) => {
      setPositionToSettle({ ...position, Amount: amount });
      openModal();
    },
    [openModal]
  );

  const settleModalMarketPositionsObj = useMemo(() => {
    return [
      {
        ...marketPositions,
        Positions: positionToSettle ? [positionToSettle] : [],
      },
    ];
  }, [marketPositions, positionToSettle]);

  const renderPositions = (positions: Position[]) => {
    return positions.map(pos => {
      const rowFormState = formStateMap?.get(pos.Currency);
      return (
        rowFormState && (
          <CurrencySettlementRow
            key={pos.Currency}
            position={pos}
            formState={rowFormState}
            marketAccount={MarketAccount}
            onSettleClick={handleSettleClick}
          />
        )
      );
    });
  };

  const popover = usePopoverState({
    trigger: 'hover',
    placement: 'top',
  });

  return (
    <AccordionRowBodyCell colSpan={100}>
      <SettlementBodyContainer>
        <table style={{ width: '100%', borderSpacing: '0px' }}>
          <thead>
            <tr>
              <PortfolioTh style={{ width: '1%' }} />
              <PortfolioTh>Asset</PortfolioTh>
              <PortfolioTh style={{ width: '1%', textAlign: 'right', whiteSpace: 'nowrap' }}>
                <Flex alignItems="center" gap="spacingSmall">
                  <span>Balance available</span>
                  <Popover {...popover}>
                    <Icon icon={IconName.InformationCircle} />
                    <Text>
                      Showing available balances for settlement with {mktAccDisplayName}. Review your account setup.
                    </Text>
                  </Popover>
                </Flex>
              </PortfolioTh>
              <PortfolioTh style={{ width: '4%' }} />
              <PortfolioTh style={{ width: '1%', textAlign: 'right', whiteSpace: 'nowrap' }}>
                Balance to be settled
              </PortfolioTh>
              <PortfolioTh style={{ width: '4%' }} />
              <PortfolioTh style={{ width: '1%' }}>Settle amount</PortfolioTh>
            </tr>
          </thead>
          <tbody>
            {renderPositions(outgoing)}
            {incoming.length > 0 && outgoing.length > 0 && (
              <tr>
                <td colSpan={5} height="10px">
                  <Flex h="100%" alignItems="center">
                    <Divider w="100%" />
                  </Flex>
                </td>
              </tr>
            )}
            {incoming.length > 0 && renderPositions(incoming)}
          </tbody>
        </table>
        <Modal {...modal} width={SETTLE_MODAL_WIDTH}>
          <SettleModalContent marketPositions={settleModalMarketPositionsObj} close={closeModal} />
        </Modal>
      </SettlementBodyContainer>
    </AccordionRowBodyCell>
  );
});
