import { Tab, TabAppearance, TabList, Tabs, TabSize, type TabsProps } from '../Tabs';

export type ToggleHorizontalOption<ValueType extends string> = { value: ValueType; displayName: string };

/**
 * Toggle Horizontal control - A horizontal list of buttons that can be toggled like a radio button bar
 * - Internally leverages the Tabs component
 * - Name inspired by the design team who called it a "ToggleHorizontal"
 * @param options The list of options to display
 * @param onChange The function to call when an option is selected
 * @param value The value of the selected option
 */
export const ToggleHorizontal = <TValue extends string>({
  options,
  onChange,
  value,
  size = TabSize.Small,
  optionDataTestId,
  ...props
}: {
  options: Array<ToggleHorizontalOption<TValue>>;
  onChange: (value: TValue) => void;
  value: TValue;
  size?: TabSize;
  optionDataTestId?: string;
} & Omit<TabsProps, 'onChange' | 'selectedIndex'>) => {
  return (
    <Tabs
      selectedIndex={options.findIndex(option => option.value === value)}
      appearance={TabAppearance.Pill}
      size={size}
      flex="0 0 auto"
      w="auto"
      {...props}
    >
      <TabList suppressOverflowList={true}>
        {options.map(option => (
          <Tab
            key={option.value}
            isSelected={value === option.value}
            onClick={() => onChange(option.value)}
            label={option.displayName}
            data-testid={optionDataTestId}
          />
        ))}
      </TabList>
    </Tabs>
  );
};
