import { isObject } from 'lodash';
import { useMemo } from 'react';
import { getAgGridColId } from './columns/getAgGridColId';
import type { Column } from './columns/types';
import { isColumnGroup, type ColumnGroup, type ColumnOrColumnGroup } from './types';

/**
 * Group-enabled version of useDefaultColumns
 * @see useDefaultColumns
 * @param columnDefinitions The visible definitions of the columns for this blotter
 * @returns An array of column definitions to pass to `useBlotterTable`
 */
export function useDefaultColumnsWithGroupings(
  defaultVisibleColumns: (string | Column | ColumnGroup)[],
  columnDefinitions: Map<string, Column>
): ColumnOrColumnGroup[] {
  const columns = useMemo(() => {
    const allColDefs = new Map<string, Column | ColumnGroup>();
    function buildColumnFromDef(colDef: Column | string): Column {
      const colId = isObject(colDef) ? getAgGridColId(colDef) : colDef;
      return {
        ...((columnDefinitions.get(colId) ?? {}) as Column),
        hide: false,
        // If `colDef` is an object, it contains overrides for the original column definition
        ...((isObject(colDef) ? colDef : {}) as Column),
      };
    }

    for (const col of defaultVisibleColumns) {
      if (isObject(col) && isColumnGroup(col)) {
        const columnGroup: ColumnGroup = {
          ...col,
          children: col.children.map(buildColumnFromDef),
        };
        allColDefs.set(columnGroup.groupId, columnGroup);
      } else {
        const column = buildColumnFromDef(col);
        const colId = getAgGridColId(column);
        allColDefs.set(colId, column);
      }
    }

    for (const col of columnDefinitions.values()) {
      const colId = getAgGridColId(col);
      if (!allColDefs.has(colId)) {
        allColDefs.set(colId, {
          ...col,
          hide: true,
        });
      }
    }

    return [...allColDefs.values()];
  }, [columnDefinitions, defaultVisibleColumns]);

  return columns;
}
