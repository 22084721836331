import { CustomerBalance, CustomerTransaction } from '../../types/CustomerBalance';
import { CustomerExecutionReport } from '../../types/CustomerExecutionReport';
import { CustomerOrder } from '../../types/CustomerOrder';
import { CustomerQuote } from '../../types/CustomerQuote';
import { CustomerTrade } from '../../types/CustomerTrade';
import { CustomerUser } from '../../types/CustomerUser';
import { ExecutionReport } from '../../types/ExecutionReport';
import { Order } from '../../types/Order';
import { Quote } from '../../types/Quote';

export type DetailsDrawerEntity =
  | Order
  | Quote
  | ExecutionReport
  | CustomerOrder
  | CustomerExecutionReport
  | CustomerQuote
  | CustomerTrade
  | CustomerBalance
  | CustomerUser
  | CustomerTransaction;

export const isDetailsDrawerEntity = (entity: any): entity is DetailsDrawerEntity =>
  entity instanceof Order ||
  entity instanceof Quote ||
  entity instanceof ExecutionReport ||
  entity instanceof CustomerOrder ||
  entity instanceof CustomerExecutionReport ||
  entity instanceof CustomerQuote ||
  entity instanceof CustomerTrade ||
  entity instanceof CustomerBalance ||
  entity instanceof CustomerUser ||
  entity instanceof CustomerTransaction;
