import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// TODO: remove this hook and use native `useSearchParams` once we upgrade react-router-dom to v6
// https://reactrouter.com/en/main/hooks/use-search-params
export function useSearchParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
