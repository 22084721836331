import {
  ACTION,
  Alert,
  AlertVariants,
  Dialog,
  type DialogProps,
  Divider,
  EMPTY_ARRAY,
  Field,
  FieldValidationLevel,
  type FieldValidationRule,
  FormControlSizes,
  FormGroup,
  type ISubaccount,
  Input,
  MixpanelEvent,
  NotificationVariants,
  SearchSelect,
  Toggle,
  Tooltip,
  getDisplayNameOrNameLabel,
  useDynamicCallback,
  useGlobalToasts,
  useMixpanel,
} from '@talos/kyoko';
import { values } from 'lodash';
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRoleAuth } from '../../hooks';
import { useSubAccounts } from '../../providers';
import { useSubAccountCloningVerification } from './useSubAccountCloningVerification';

interface Form {
  name: Field<string>;
  displayName: Field<string>;
}

export const VALIDATION_RULES: { [key in keyof Form]: FieldValidationRule<Field<string>>[] } = {
  name: [
    // .Name must include at least one alphanumeric character
    field => {
      const valueContainsAtLeastOneAlphaNumericChar = field.value?.split('').some(char => char.match(/^[0-9a-zA-Z]$/));
      if (!valueContainsAtLeastOneAlphaNumericChar) {
        return {
          message: 'Name must include at least one alpha numeric character',
          level: FieldValidationLevel.Error,
        };
      }

      // OK
      return null;
    },
    // we block the user from using certain characters
    field => {
      const foundDisallowedChar = [','].find(char => field.value?.includes(char));
      if (foundDisallowedChar != null) {
        return {
          level: FieldValidationLevel.Error,
          message: `Name must not contain "${foundDisallowedChar}"`,
        };
      }

      // OK
      return null;
    },
  ],
  displayName: [], // no rules we cool
};

type CreateSubAccountModalProps = DialogProps & {
  /** Set the model sub account right off the bat. To be used if you're priming from a sub account entity for example. */
  initialModelSubAccount?: ISubaccount;
  onSuccessfulCreation: (newSubAcc: ISubaccount) => void;
};

/**
 * The CreateSubAccountModal helps you create Sub Accounts. It allows you to create brand new sub accounts, but also allows the user to create
 * new sub accounts as clones of already existing ones.
 *
 * **Note** If you are using the initialModelSubAccount flow, you have to also set that as the key of the drawer. This so that
 * the component remounts (clearing all its state and starting fresh) on every new primed model sub account.
 */
export const CreateSubAccountModal = ({
  onSuccessfulCreation,
  initialModelSubAccount,
  ...dialogProps
}: CreateSubAccountModalProps) => {
  const mixpanel = useMixpanel();
  const { tradableSubAccounts, cloneSubAccount, createSubAccount } = useSubAccounts();
  const { add: addToast } = useGlobalToasts();
  const { isAuthorized } = useRoleAuth();

  const onlyCloningAllowed = isAuthorized(ACTION.CLONE_SUB_ACCOUNTS) && !isAuthorized(ACTION.EDIT_SUB_ACCOUNTS);

  // CreateByCloning toggle is initially set to true if either thats all the user can do or if there's an initial model account passed.
  const [createByCloning, setCreateByCloning] = useState((onlyCloningAllowed || !!initialModelSubAccount) ?? false);
  const [modelSubAccount, setModelSubAccount] = useState<ISubaccount | undefined>(initialModelSubAccount);

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState(() => {
    const initialName = initialModelSubAccount ? `${initialModelSubAccount.Name} copy` : '';
    const initialDisplayName = initialModelSubAccount
      ? `${initialModelSubAccount.DisplayName ?? initialModelSubAccount.Name} copy`
      : '';

    return {
      name: new Field<string>({ value: initialName }),
      displayName: new Field<string>({ value: initialDisplayName }),
    };
  });

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setForm(curr => ({
      ...curr,
      name: curr.name.updateValue(e.target.value).setTouched(true).validate(VALIDATION_RULES.name),
    }));
  }, []);

  const handleDisplayNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setForm(curr => ({
      ...curr,
      displayName: curr.displayName.updateValue(e.target.value).validate(VALIDATION_RULES.displayName),
    }));
  }, []);

  const resetForm = useCallback(() => {
    setForm(curr => ({
      name: curr.name.updateValue('').setTouched(false),
      displayName: curr.displayName.updateValue('').setTouched(false),
    }));
  }, []);

  const touchAll = useCallback((touched: boolean) => {
    setForm(curr => ({
      name: curr.name.setTouched(touched),
      displayName: curr.displayName.setTouched(touched),
    }));
  }, []);

  useEffect(() => {
    // When the dialog is freshly opened, we want to clean some stuff out from previous openings of the modal
    if (dialogProps.isOpen) {
      touchAll(false);
    }
  }, [dialogProps.isOpen, touchAll]);

  const { close: closeDialog } = dialogProps;

  const handleConfirm = useDynamicCallback(() => {
    createByCloning ? handleClone() : handleCreate();
  });

  const handleClone = useDynamicCallback(async () => {
    mixpanel.track(MixpanelEvent.CloneSubAccount);

    const newName = form.name.value;
    const newDisplayName = form.displayName.value;
    if (!newName || !newDisplayName) {
      return;
    }

    if (!modelSubAccount) {
      return;
    }

    setLoading(true);
    let clone: ISubaccount | undefined;

    // Using async-await syntax to write the code in correct order with the complexity around error handling here
    // The promise is split into two distinct parts because we want to handle the errors differently in the two steps.
    // Step 1 is to perform the cloning
    try {
      clone = (await cloneSubAccount(modelSubAccount.SubaccountID, newName, newDisplayName)).SubAccount;
      if (!clone) {
        // shouldnt happen, mostly for typescript to be happy.
        throw new Error();
      }
    } catch (e: any) {
      setLoading(false);
      addToast({
        variant: NotificationVariants.Negative,
        text: `Failed to create Sub Account: ${e.message}`,
      });

      return;
    }

    // Step 2, verify the cloning. Only proceed once verified
    try {
      await verifyCloning(modelSubAccount, clone);
      addToast({
        variant: NotificationVariants.Positive,
        text: 'Successfully created Sub Account.',
      });
    } catch (e: any) {
      addToast({
        variant: NotificationVariants.Warning,
        text: 'Did not receive the necessary updates to guarantee usability of the newly created Sub Account. Try refreshing your page.',
      });
      setLoading(false);
      // The sub account was created, but verification failed. Since the sub account was _created_, we still close the dialog...
      // at this stage, the user might have to do some manual work...
      closeDialog();
      return;
    }

    // All done
    resetForm();
    setLoading(false);
    onSuccessfulCreation(clone);
    closeDialog();
  });

  const handleCreate = useDynamicCallback(() => {
    setLoading(true);

    const newName = form.name.value;
    const newDisplayName = form.displayName.value;
    if (!newName || !newDisplayName) {
      return;
    }

    createSubAccount(newName, newDisplayName)
      .then(newSubAccount => {
        onSuccessfulCreation(newSubAccount);
        addToast({
          variant: NotificationVariants.Positive,
          text: 'Successfully created Sub Account.',
        });
        closeDialog();
        resetForm();
      })
      .catch((e: ErrorEvent) => {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Failed to create Sub Account: ${e.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const { verifyCloning } = useSubAccountCloningVerification();

  const someError = useMemo(() => [...values(form)].some(field => field.errors.length > 0), [form]);

  // A name always has to be provided. The model sub account only has to be selected if we're gonna create by cloning.
  const confirmDisabled = someError || (modelSubAccount == null && createByCloning);

  return (
    <Dialog
      {...dialogProps}
      title="Create Sub Account"
      confirmLabel="Create Sub Account"
      width={480}
      confirmDisabled={confirmDisabled}
      onConfirm={handleConfirm}
      closeOnConfirm={false}
      confirmLoading={loading}
      alignContent="left"
      onConfirmMouseOver={() => touchAll(true)}
    >
      <FormGroup
        label="Name"
        tooltip="The unique identifier of your Sub Account"
        error={form.name.isTouched && form.name.errorString}
      >
        <Input
          value={form.name.value}
          onChange={handleNameChange}
          invalid={form.name.isTouched && form.name.hasInvalidValue}
          data-testid="sub-account-creation-name-input"
        />
      </FormGroup>

      <FormGroup
        label="Display Name"
        tooltip="The label of your Sub Account"
        error={form.displayName.isTouched && form.displayName.errorString}
      >
        <Input
          value={form.displayName.value}
          onChange={handleDisplayNameChange}
          invalid={form.displayName.isTouched && form.displayName.hasInvalidValue}
          data-testid="sub-account-creation-display-name-input"
        />
      </FormGroup>

      <FormGroup inline label="Copy settings from existing sub account" justifyContent="space-between">
        <Tooltip
          tooltip={onlyCloningAllowed ? 'Your user is only able to clone sub accounts which already exist' : undefined}
        >
          <Toggle
            size={FormControlSizes.Small}
            checked={createByCloning}
            onChange={setCreateByCloning}
            disabled={onlyCloningAllowed}
            data-testid="create-by-cloning-toggle"
          />
        </Tooltip>
      </FormGroup>

      {createByCloning && (
        <>
          <Divider orientation="horizontal" mb="spacingMedium" />

          <Alert variant={AlertVariants.Info} dismissable={false} mb="spacingMedium">
            Your new sub account will inherit the settings from the sub account below including associated rollups and
            permissions.
          </Alert>

          <FormGroup label="Model Sub Account" mb="spacingMedium">
            <SearchSelect
              selection={modelSubAccount}
              options={tradableSubAccounts ?? EMPTY_ARRAY}
              onChange={setModelSubAccount}
              getLabel={getDisplayNameOrNameLabel}
              data-testid="model-sub-account-selector"
            />
          </FormGroup>
        </>
      )}
    </Dialog>
  );
};
