import type * as Highcharts from 'highcharts/highcharts';
import { BaseChart, type BaseChartProps } from './BaseChart';
import { useChartOptionsOverrides, useHighchartsRef, useSeriesOptions } from './hooks';
import type { SharedChartProps } from './types';

export type SeriesPieOptions = Highcharts.SeriesPieOptions;

export interface PieChartProps extends SharedChartProps<Highcharts.SeriesPieOptions>, BaseChartProps {}

export const DEFAULT_PIECHART_OPTIONS: Highcharts.Options = {
  chart: {
    type: 'pie',
  },
  legend: {
    enabled: true,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
};
export function PieChart({ series, seriesObservable, onChartCreated, options, ...props }: PieChartProps) {
  const { chartRef, isLoaded, setChartObject } = useHighchartsRef({ onChartCreated });

  const seriesOptions = useSeriesOptions({ series, seriesObservable, chartRef, isLoaded });
  const combinedOptions = useChartOptionsOverrides(DEFAULT_PIECHART_OPTIONS, options, seriesOptions);

  return <BaseChart options={combinedOptions} onChartCreated={setChartObject} {...props} />;
}
