import {
  ICON_SIZES,
  Icon,
  IconName,
  InlineFormattedNumber,
  InstrumentCompositionEnum,
  OrderMarketStatusEnum,
  Table,
  Tbody,
  Td,
  Th,
  Tooltip,
  Tr,
  isEntityRFQ,
  isOrderComplete,
  prettyName,
  toBigWithDefault,
  useCurrenciesContext,
  useSecuritiesContext,
  useSecurity,
  type Order,
} from '@talos/kyoko';
import { useMarketsContext } from 'providers';
import { Fragment } from 'react';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  ${Tr}:not(:first-child) ${Th}:only-child {
    padding-top: ${({ theme }) => theme.spacingDefault}px;
  }

  ${Th}, ${Td} {
    padding: ${({ theme }) => theme.spacingTiny}px;
  }

  ${Td}:first-child {
    color: ${({ theme }) => theme.colors.gray['080']};
    padding-left: 0;
  }
`;

export interface QuantityTableProps {
  order: Order;
}

export const QuantityTable = ({ order, ...props }: QuantityTableProps) => {
  const { marketsByName } = useMarketsContext();

  const security = useSecurity(order.Symbol);
  const { securitiesBySymbol } = useSecuritiesContext();
  const { currenciesBySymbol } = useCurrenciesContext();

  const theme = useTheme();

  if (security == null) {
    return null;
  }

  const isRFQ = isEntityRFQ(order);
  const isMulti = security.Composition === InstrumentCompositionEnum.Synthetic;

  return (
    <Wrapper {...props}>
      <Table>
        <Tbody>
          {order.Markets?.map(m => {
            const { AvgPx, CumQty, LeavesQty, Currency, Price, MarketStatus, Text } = m;
            const showMarket =
              toBigWithDefault(LeavesQty, 0).gt(0) ||
              toBigWithDefault(CumQty, 0).gt(0) ||
              (!isRFQ && MarketStatus !== OrderMarketStatusEnum.Disabled);
            const hasError =
              (MarketStatus !== OrderMarketStatusEnum.Online && MarketStatus !== OrderMarketStatusEnum.Disabled) ||
              (Text && Text !== '');

            const market = marketsByName.get(m.Market || '');

            // See https://app.shortcut.com/talostrading/story/36861/add-currency-and-amountcurrency-to-order-markets-repeating-group
            // There's inconsistency for Currency/AmountCurrency between orders and RFQs, so we need to have some extra logic in here
            // to figure out which currency to display.
            const sizeCurrency = Currency;
            let priceCurrency = security.QuoteCurrency;
            const sizeIncrement = currenciesBySymbol.get(Currency ?? '')?.DefaultIncrement ?? '0';
            let priceIncrement = currenciesBySymbol.get(priceCurrency ?? '')?.DefaultIncrement ?? '0';

            // For multi get the currencies and increments from the Leg's security. For ccy multileg orders there is no additional logic
            if (isMulti) {
              const legSecurity =
                m.LegIndex != null
                  ? securitiesBySymbol.get(security.MultilegDetails?.Legs[m.LegIndex]?.Symbol || '')
                  : undefined;
              if (legSecurity) {
                priceCurrency = legSecurity.QuoteCurrency;
                priceIncrement = legSecurity.DefaultPriceIncrement;
              }
            }

            const inlineFormattedNumberBg = theme.colors.gray['020'];

            return (
              showMarket && (
                <Fragment key={m.Market}>
                  <Tr>
                    <Th colSpan={4}>
                      <Tooltip
                        usePortal
                        disabled={!hasError}
                        tooltip={Text && Text !== '' ? Text : prettyName(MarketStatus)}
                      >
                        {market?.DisplayName ?? m.Market}{' '}
                        {hasError && (
                          <Icon icon={IconName.ExclamationCircle} color={theme.colors.yellow.lighten} size={10} />
                        )}
                      </Tooltip>
                    </Th>
                  </Tr>
                  <Tr data-testid={`open-qty-${m.Market}`}>
                    <Td>Open</Td>
                    <Td align="right">
                      <InlineFormattedNumber
                        number={LeavesQty}
                        increment={sizeIncrement}
                        currency={sizeCurrency}
                        background={inlineFormattedNumberBg}
                      />
                    </Td>
                    <Td minimize={true}>
                      <Icon icon={IconName.AtSymbol} color="Subtle" size={ICON_SIZES.SMALL} />
                    </Td>
                    <Td align="right">
                      <InlineFormattedNumber
                        number={Price}
                        increment={priceIncrement}
                        currency={priceCurrency}
                        background={inlineFormattedNumberBg}
                        align="right"
                      />
                    </Td>
                  </Tr>
                  <Tr data-testid={`filled-qty-${m.Market}`}>
                    <Td>Filled</Td>
                    <Td align="right">
                      <InlineFormattedNumber
                        number={CumQty}
                        increment={sizeIncrement}
                        currency={sizeCurrency}
                        background={
                          isOrderComplete(order.OrdStatus) ? theme.colors.gray['020'] : theme.colors.gray.main
                        }
                      />
                    </Td>
                    <Td minimize={true}>
                      <Icon icon={IconName.AtSymbol} color="Subtle" size={ICON_SIZES.SMALL} />
                    </Td>
                    <Td align="right">
                      <InlineFormattedNumber
                        number={AvgPx}
                        increment={priceIncrement}
                        currency={priceCurrency}
                        background={inlineFormattedNumberBg}
                        align="right"
                      />
                    </Td>
                  </Tr>
                </Fragment>
              )
            );
          })}
        </Tbody>
      </Table>
    </Wrapper>
  );
};
