import type { SVGProps } from 'react';
import { useTheme } from 'styled-components';
import { parseColor } from '../../styles';
import { SVGIcon } from '../Icons';
import { ENV_COLORS } from '../Sidebar/styles';

export const TalosLogo = ({
  fill = 'gray.100',
  collapsed,
  ref,
  ...props
}: { collapsed?: boolean } & SVGProps<SVGElement>) => {
  const theme = useTheme();
  const fillColor = parseColor(theme, fill);
  const envColor = parseColor(theme, ENV_COLORS[import.meta.env.VITE_AVA_ENV ?? '']);
  return (
    <SVGIcon {...props} viewBox={`0 0 ${collapsed ? 14 : 100} 14`} style={collapsed ? { margin: '0 auto' } : {}}>
      {!collapsed && (
        <>
          <path
            d="M10.5331 0.335876L9.2084 2.60567H6.51858V13.5992H4.01454V2.60567H0V0.335876H10.5331Z"
            fill={fillColor}
          />
          <path
            d="M24.4911 0.335876L28.1664 13.5992H25.5896L24.2084 8.37303L23.2552 10.6509H20.1696L19.3699 13.6073H16.8013L20.4766 0.335876H24.4911ZM24.2084 8.37303L22.6009 2.41988H22.3748L20.7593 8.37303H24.2084Z"
            fill={fillColor}
          />
          <path d="M39.0226 0.335876V11.3294H43.8045L45.1615 13.6073H36.5186V0.335876H39.0226Z" fill={fillColor} />
          <path
            d="M56.6317 0.0693054C60.0243 0.0693054 62.0921 2.16947 62.0921 5.56204V8.36494C62.0921 11.8544 60.0243 13.8577 56.6317 13.8577C53.2391 13.8577 51.1713 11.8464 51.1713 8.36494V5.56204C51.1793 2.16947 53.2391 0.0693054 56.6317 0.0693054ZM53.6753 8.48611C53.6753 10.4005 54.6204 11.6283 56.6317 11.6283C58.6995 11.6283 59.5881 10.3197 59.5881 8.48611V5.44895C59.5881 3.57496 58.4895 2.30679 56.6317 2.30679C54.7173 2.30679 53.6753 3.65574 53.6753 5.44895V8.48611Z"
            fill={fillColor}
          />
          <path
            d="M79.0791 4.27771V4.84313H76.6558V4.44733C76.6558 2.92876 75.6865 2.2664 74.176 2.2664C72.8513 2.2664 72.0355 2.92876 72.0355 3.84152C72.0355 5.09354 73.2067 5.31971 74.8061 5.6832L74.9757 5.72359C77.7059 6.3294 79.3134 7.29871 79.3134 9.70582C79.3134 12.3795 77.2293 13.8738 74.4022 13.8738C71.1631 13.8738 69.0791 12.1291 69.0791 9.01922V8.59919H71.5428V8.93845C71.5428 10.5701 72.4717 11.6687 74.483 11.6687C76.0904 11.6687 76.8497 10.8124 76.8497 9.82698C76.8497 8.67189 75.8642 8.29225 74.1195 7.9126L73.9499 7.87221C71.2035 7.2664 69.5718 6.32132 69.5718 3.91422C69.5718 1.5475 71.5993 0.0693054 74.176 0.0693054C77.0113 0.0693054 79.0791 1.62019 79.0791 4.27771Z"
            fill={fillColor}
          />
        </>
      )}
      <path d={collapsed ? 'M4 10H0V14H4V10Z' : 'M90 10H86V14H90V10Z'} fill={fillColor} />
      <path d={collapsed ? 'M9 10H5V14H9V10Z' : 'M95 10H91V14H95V10Z'} fill={fillColor} />
      <path d={collapsed ? 'M4 5H0V9H4V5Z' : 'M90 5H86V9H90V5Z'} fill={fillColor} />
      <path d={collapsed ? 'M14 0H10V4H14V0Z' : 'M100 0H96V4H100V0Z'} fill={envColor || fillColor} />
      <path d={collapsed ? 'M9 0H5V4H9V0Z' : 'M95 0H91V4H95V0Z'} fill={envColor || fillColor} />
      <path d={collapsed ? 'M14 5H10V9H14V5Z' : 'M100 5H96V9H100V5Z'} fill={envColor || fillColor} />
    </SVGIcon>
  );
};
