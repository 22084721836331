import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { modeColor } from '../../styles';
import { Z_INDEX } from '../../styles/layout';
import { Box, Grid } from '../Core';

type WrapperProps = { position: 'absolute' | 'fixed' | 'relative'; width?: number; placement: 'left' | 'right' };

export const Wrapper = styled(animated.div)<WrapperProps>`
  height: 100%;
  background: ${({ theme }) => theme.backgroundDrawer};
  backdrop-filter: ${({ theme }) => theme.backdropFilterDrawer};
  z-index: ${Z_INDEX.drawer};
  ${({ width }) => width !== null && `width: ${width}px`};
  min-width: min-content;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${({ position, placement }) =>
    position === 'fixed' &&
    `
    position: fixed;
    top: 0;
    bottom: 0;
    ${placement === 'left' ? `left: 0;` : `right: 0`};
  `};

  ${({ position, placement, theme }) =>
    position === 'absolute' &&
    `
    position: absolute;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 40px ${modeColor(theme, theme.colors.black.mute, theme.colors.black.dim)};
    ${placement === 'left' ? `left: 0;` : `right: 0`};
  `};

  ${({ position }) =>
    position === 'relative' &&
    `
    position: relative;
  `};
`;

export const DrawerHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 ${({ theme }) => theme.spacingMedium}px;
  min-height: 3.5rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  flex: 0;
  align-items: center;
`;

export const DrawerContent = styled(Grid)``;
DrawerContent.defaultProps = {
  overflow: 'hidden',
  flex: 1,
  p: 'spacingMedium',
  pb: '0',
};

export const DrawerFooter = styled(Grid)`
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  border-top: solid 1px ${({ theme }) => theme.colors.gray['010']};
`;
DrawerFooter.defaultProps = {
  p: 'spacingMedium',
  gap: 'spacingMedium',
};

const noForwardProps = new Set(['width']);

export const DrawerWrapper = styled(Box).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !noForwardProps.has(prop) && defaultValidatorFn(prop),
})<{ width?: number }>`
  background: ${({ theme }) => theme.backgroundDrawer};
  transition: width 200ms ease, margin 200ms ease;
  width: ${({ width }) => (width != null ? `${width}px` : 'auto')};
  height: 100%;
  overflow: hidden;
  flex: 0 0 auto;

  @media print {
    display: none;
  }
`;

export const DrawerContentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !noForwardProps.has(prop) && defaultValidatorFn(prop),
})<{ width?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width != null ? `${width}px` : 'auto')};
  height: 100%;
  overflow: hidden;
`;
