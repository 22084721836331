import type { ColumnDef } from '@talos/kyoko';
import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { TradePositionButton, type TradePositionButtonProps } from '../../components/TradePositionButton';
import type { PositionLike } from '../getPositionSecurities';

type UseTradePositionColumnParams<T extends PositionLike> = {
  /** The id to put on the column. Defaults to "closePosition" */
  columnId?: string;
  /** Apply custom colDefParams to style the column how you wish */
  colDefParams?: ColDef<T>;
  /** Whether or not to show a title in the column header. Defaults to false. */
  showTitle?: boolean;
} & Pick<
  TradePositionButtonProps<T>,
  'getAssetType' | 'action' | 'subAccountField' | 'constrainSecuritiesToMarket' | 'assetField' | 'marketAccountField'
>;

export const useTradePositionColumn = <T extends PositionLike>({
  constrainSecuritiesToMarket,
  assetField,
  action,
  marketAccountField,
  subAccountField,
  columnId = 'tradePosition',
  colDefParams,
  showTitle = false,
  getAssetType,
}: UseTradePositionColumnParams<T>) => {
  return useMemo(
    () =>
      ({
        type: 'custom',
        id: columnId,
        params: {
          headerName: showTitle ? capitalize(action) : undefined,
          width: 80,
          ...colDefParams,
          cellRenderer: function CellRenderer(params: ICellRendererParams<T, unknown>) {
            const position = params.node.data;

            // Don't render the buttons for group rows, pinned rows, or rows where we cant find a position data point
            if (params.node.group || params.node.isRowPinned() || !position) {
              return <></>;
            }

            return (
              <TradePositionButton
                position={position}
                constrainSecuritiesToMarket={constrainSecuritiesToMarket}
                assetField={assetField}
                action={action}
                marketAccountField={marketAccountField}
                subAccountField={subAccountField}
                getAssetType={getAssetType}
              />
            );
          },
        } satisfies ColDef<T>,
      } satisfies ColumnDef<T>),
    [
      columnId,
      colDefParams,
      showTitle,
      constrainSecuritiesToMarket,
      assetField,
      action,
      marketAccountField,
      subAccountField,
      getAssetType,
    ]
  );
};

/** A default set of ColDef options you can use to pin the closePosition column to the right of the blotter */
export const pinClosePositionColumnColDefParams: ColDef = {
  suppressHeaderMenuButton: true,
  suppressColumnsToolPanel: true,
  pinned: 'right',
  lockVisible: true,
  lockPinned: true,
};
