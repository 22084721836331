import type { Chart, Options, SeriesOptionsType } from 'highcharts/highcharts';
import { useEffect, useState, type MutableRefObject } from 'react';
import type { Observable } from 'rxjs';
import { getSeriesId } from '../utils';

export const useSeriesOptions = ({
  series,
  seriesObservable,
  chartRef,
  isLoaded,
  mergeSeriesUpdates = false,
}: {
  series?: SeriesOptionsType[];
  seriesObservable?: Observable<SeriesOptionsType[]>;
  chartRef: MutableRefObject<Chart | undefined>;
  isLoaded: boolean;
  mergeSeriesUpdates?: boolean;
}) => {
  const [seriesOptions, setSeriesOptions] = useState<Options>({});
  useEffect(() => {
    if (seriesObservable) {
      const sub = seriesObservable?.subscribe(newSeries => {
        if (!isLoaded && mergeSeriesUpdates) {
          setSeriesOptions(prev => {
            if (prev.series) {
              // TODO Merge newSeries with existing
              console.error('Merging pre-render updates is not yet implemented');
              return prev;
            } else {
              return {
                series: newSeries,
              };
            }
          });
        } else if (mergeSeriesUpdates) {
          const chart = chartRef.current;
          if (!chart) {
            throw new Error('Chart must be loaded before merging series updates');
          }
          newSeries.forEach(serie => {
            const chartSerie = chart.series.find(s => getSeriesId(s) === getSeriesId(serie));
            if ('data' in serie && Array.isArray(serie.data)) {
              serie.data?.forEach(d => {
                chartSerie?.addPoint(d);
              });
            }
          });
        } else {
          setSeriesOptions({
            series: newSeries,
          });
        }
      });
      return () => sub?.unsubscribe();
    } else if (series) {
      setSeriesOptions({
        series,
      });
    }
  }, [seriesObservable, series, isLoaded, mergeSeriesUpdates, chartRef]);
  return seriesOptions;
};
