import {
  Flex,
  FormGroup,
  Input,
  isValidEmail,
  MultiSelect,
  NotificationVariants,
  ROLE,
  useDynamicCallback,
  useGlobalToasts,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';
import { prettifyRole } from 'tokens/roles';
import type { NewUserForm, NewUserFormArg } from '../types';
import { useUserSettings } from '../useUserSettings';

const getRoleLabel = prettifyRole;
const getRoleDescription = (role: string | undefined) => role || ' ';

export function NewUserGeneralTab({
  updateForm,
  userForm,
}: Pick<ReturnType<typeof useNewUserGeneralTab>, 'updateForm' | 'userForm'>) {
  const { editableRoles } = useUserSettings();

  return (
    <Flex flexDirection="column" w="100%">
      <FormGroup label="Name" controlId="name">
        <Input
          id="name"
          data-testid="name-input"
          value={userForm.Name}
          onChange={e => updateForm({ key: 'Name', value: e.target.value })}
        />
      </FormGroup>
      <FormGroup label="Email" controlId="email">
        <Input
          id="email"
          value={userForm.Email}
          onChange={e => updateForm({ key: 'Email', value: e.target.value })}
          inputType="email"
          data-testid="email-input"
        />
      </FormGroup>
      <FormGroup label="Roles" controlId="roles">
        <MultiSelect
          selections={(userForm?.Roles ?? []).filter(role => editableRoles.includes(role))}
          options={editableRoles}
          onChange={selections => updateForm({ key: 'Roles', value: selections })}
          getLabel={getRoleLabel}
          getDescription={getRoleDescription}
          data-testid="new-user-roles-multiselect"
        />
      </FormGroup>
    </Flex>
  );
}

const EMPTY_USER_FORM: NewUserForm = {
  Name: '',
  Email: '',
  Roles: [ROLE.READ_ONLY],
};

export function useNewUserGeneralTab() {
  const { add: addToast } = useGlobalToasts();
  const { createUser } = useUserSettings();

  const [userForm, setUserForm] = useState<NewUserForm>(EMPTY_USER_FORM);
  const updateForm = useDynamicCallback(({ key, value }: NewUserFormArg) => {
    setUserForm(prev => ({ ...prev, [key]: value }));
  });
  const isDirty = useMemo(() => {
    return userForm.Name !== '' || userForm.Email !== '' || (userForm?.Roles ?? []).length > 0;
  }, [userForm]);

  const isValid = useMemo(() => {
    return userForm.Name !== '' && isValidEmail(userForm.Email) && (userForm?.Roles ?? []).length > 0;
  }, [userForm]);

  const handleSaveChanges = useDynamicCallback(() => {
    if (!isDirty) {
      return;
    }

    // Wrap our request promise in an outer promise so we still emit the rejection to any subsequent listeners
    // while retaining the ability to catch and show a toast on failure
    return new Promise<void>((resolve, reject) => {
      createUser(userForm)
        .then(() => {
          addToast({
            text: `User ${userForm.Name} ${userForm.Email} added.`,
            variant: NotificationVariants.Positive,
          });
          resolve();
        })
        .catch((e: Error) => {
          addToast({
            text: `Failed to create user: ${e.message}`,
            variant: NotificationVariants.Negative,
          });
          reject();
        });
    });
  });

  const resetUserForm = useCallback(() => {
    setUserForm(EMPTY_USER_FORM);
  }, []);

  return {
    resetUserForm,
    isDirty,
    isValid,
    handleSaveChanges,
    updateForm,
    userForm,
  };
}
