import {
  AssetTransaction,
  BuyingPower,
  CareExecutionReport,
  CareOrder,
  CustomerExecutionReport,
  CustomerOrder,
  CustomerQuote,
  CustomerTrade,
  ExecutionReport,
  MarketExecutionReport,
  MarketOrder,
  MarketQuote,
  MarketTrade,
  Order,
  Quote,
  Trade,
  type Column,
  type DateRangeFilter,
  type TabProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';

export const entityByRequestName = {
  Order: Order,
  Trade: Trade,
  Quote: Quote,
  ExecutionReport: ExecutionReport,
  MarketOrder: MarketOrder,
  MarketQuote: MarketQuote,
  MarketTrade: MarketTrade,
  MarketExecutionReport: MarketExecutionReport,
  CustomerOrder: CustomerOrder,
  CustomerQuote: CustomerQuote,
  CustomerExecutionReport: CustomerExecutionReport,
  CustomerTrade: CustomerTrade,
  AssetTransaction: AssetTransaction,
  BuyingPower: BuyingPower,
  CareOrder: CareOrder,
  CareExecutionReport: CareExecutionReport,
} as const;

export type EntityName = keyof typeof entityByRequestName;

export type BlotterTableEntity = (typeof entityByRequestName)[EntityName];

const entityGroups = {
  OMS: 'OMS',
  Market: 'Market',
  Customer: 'Customer',
  Care: 'Care',
} as const;

interface EntityGroupAndDisplayName {
  group: (typeof entityGroups)[keyof typeof entityGroups];
  name: string;
}
export const entityDisplayNames = {
  Order: { group: 'OMS', name: 'Orders' },
  Trade: { group: 'OMS', name: 'Trades' },
  Quote: { group: 'OMS', name: 'Quotes' },
  ExecutionReport: { group: 'OMS', name: 'Execution Reports' },
  MarketOrder: { group: 'Market', name: 'Market Orders' },
  MarketQuote: { group: 'Market', name: 'Market Quotes' },
  MarketTrade: { group: 'Market', name: 'Market Trades' },
  MarketExecutionReport: { group: 'Market', name: 'Market Execution Reports' },
  AssetTransaction: { group: 'Market', name: 'Cash Transactions' },
  CustomerOrder: { group: 'Customer', name: 'Customer Orders' },
  CustomerQuote: { group: 'Customer', name: 'Customer Quotes' },
  CustomerExecutionReport: { group: 'Customer', name: 'Customer Execution Reports' },
  CustomerTrade: { group: 'Customer', name: 'Customer Trades' },
  BuyingPower: { group: 'Market', name: 'Buying Power' },
  CareOrder: { group: 'Care', name: 'Care Orders' },
  CareExecutionReport: { group: 'Care', name: 'Care Execution Reports' },
} satisfies { [K in EntityName]: EntityGroupAndDisplayName };

export interface MonitoringBlotterTabProps extends TabProps {
  tag?: string;
  name?: EntityName;
  defaultFilter?: MonitoringBlotterTableFilter;
  defaultColumns?: Column[]; //(keyof Position | Partial<Column<any>>)[];
}
export const MONITORING_BLOTTER_PREFIX = 'monitoring-blotter';

export interface MonitoringBlotterTableFilter extends DateRangeFilter {
  Statuses?: string[];
  HideAPICalls?: boolean;
}

export interface UseMonitoringBlotterFilterParams<TData extends BlotterTableEntity> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
  selectedEntity: EntityName;
}
