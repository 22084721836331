import { usePersistedTabs, useTabs, type TabProps, type UseTabs } from '@talos/kyoko';
import { isEqual } from 'lodash';
import { createContext, useContext, useMemo, useReducer, type ContextType, type PropsWithChildren } from 'react';
import { useUpdateEffect } from 'react-use';

import { operationsOverviewReducer, type OperationsOverviewAction } from '../reducer';
import { DEFAULT_OPERATIONS_OVERVIEW_STATE, type OperationsOverviewState } from '../types';

export type OperationsOverviewTabState = Pick<OperationsOverviewState, 'filter' | 'showBy' | 'showZeroBalances'>;

export type OperationsOverviewTabProps = TabProps & OperationsOverviewTabState;

const DEFAULT_OPS_OVERVIEW_TAB_ID = 'pms/operations/overview-default';
const OPERATIONS_OVERVIEW_TABS_ID = 'pms/operations/overview';

const DEFAULT_TAB: OperationsOverviewTabProps = {
  label: 'Overview',
  id: DEFAULT_OPS_OVERVIEW_TAB_ID,
  closable: false,
  editable: false,
  filter: DEFAULT_OPERATIONS_OVERVIEW_STATE.filter,
  showBy: DEFAULT_OPERATIONS_OVERVIEW_STATE.showBy,
  showZeroBalances: DEFAULT_OPERATIONS_OVERVIEW_STATE.showZeroBalances,
};

export const OperationsOverviewContext = createContext<
  | {
      state: OperationsOverviewState;
      dispatch: React.Dispatch<OperationsOverviewAction>;
    }
  | undefined
>(undefined);

export function useOperationsOverviewContext(): NonNullable<ContextType<typeof OperationsOverviewContext>> {
  const context = useContext(OperationsOverviewContext);
  if (context === undefined) {
    throw new Error('Missing OperationsOverviewContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const OperationsOverviewStateProvider = function OperationsOverviewStateProvider({
  children,
}: PropsWithChildren) {
  const persistedTabs = usePersistedTabs<OperationsOverviewTabProps>(OPERATIONS_OVERVIEW_TABS_ID, {
    defaultInitialItems: [DEFAULT_TAB],
    defaultInitialSelectedIndex: 0,
  });

  const tabs: UseTabs<OperationsOverviewTabProps> = useTabs<OperationsOverviewTabProps>({
    ...persistedTabs,
    initialItems: persistedTabs.initialItems,
    allowClosingLastTab: false,
  });

  const [state, dispatch] = useReducer(operationsOverviewReducer, {
    // grab any data which might be saved in the current tab and use that over the page defaults
    ...DEFAULT_OPERATIONS_OVERVIEW_STATE,
    ...getSharedDataBetweenTabAndReducer(tabs.items[tabs.selectedIndex]),
  });

  //Whenever the state changes we update the tab stored state
  useUpdateEffect(() => {
    const currentTabState = getSharedDataBetweenTabAndReducer(tabs.items[tabs.selectedIndex]);
    const changedTabState = getSharedDataBetweenTabAndReducer(state);

    if (!isEqual(currentTabState, changedTabState)) {
      const newTabs = tabs.items.map((item: OperationsOverviewTabProps) => {
        if (item.id !== tabs.items[tabs.selectedIndex].id) {
          return item;
        }

        return {
          ...item,
          ...getSharedDataBetweenTabAndReducer(state),
        };
      });

      tabs.setItems(newTabs);
      persistedTabs.onItemsChanged(newTabs);
    }
  }, [state]);

  const stateValue = useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state, dispatch]);

  return <OperationsOverviewContext.Provider value={stateValue}>{children}</OperationsOverviewContext.Provider>;
};

// This function just grabs the overlap we have between the two types. It helps us bridge between the two concepts
// when saving state reducer data to the tab, or grabbing state reducer value from a previously saved tab
export function getSharedDataBetweenTabAndReducer(state: OperationsOverviewState) {
  return {
    showBy: state.showBy,
    filter: state.filter,
    showZeroBalances: state.showZeroBalances,
  };
}
