import {
  type ColumnDef,
  type DateRangeFilter,
  type ITradeSettleReport,
  SideEnum,
  type TabProps,
  type Trade,
  type TradeSettleReport,
  type TradeSettleStatusEnum,
  toBig,
} from '@talos/kyoko';
import type { SettlementMonitoringFilter } from '../types';
export type TradeSettlementColumn =
  | Partial<ColumnDef<Trade>>
  | Partial<ColumnDef<ITradeSettleReport>>
  | keyof SettleableTrade;

export interface TradeSettlementTabProps extends TabProps {
  defaultColumns: TradeSettlementColumn[];
  defaultFilter: TradeSettlementFilter;
}

export interface TradeSettlementInternalFilter extends DateRangeFilter {
  Statuses?: string[];
}

export interface TradeSettlementFilter extends TradeSettlementInternalFilter, SettlementMonitoringFilter {}

export class SettleableTrade {
  TradeID!: string;
  Currency?: string;
  Quantity!: string;
  Amount!: string;
  AmountCurrency?: string;
  Side?: SideEnum;
  TransactTime!: string;
  Price!: string | undefined;
  Symbol!: string;
  Counterparty?: string | undefined;
  MarketAccount!: string;
  OrderID!: string;

  Due?: string;
  DueAsset?: string;
  Proceeds?: string;
  ProceedsAsset?: string;
  CumSettledDue?: string;
  CumSettledProceeds?: string;
  SettleReportID?: string;
  SettleStatus?: TradeSettleStatusEnum;

  /**
   * Same as Quantity, but will be flipped negative (Quantity*-1) if the trade's Side = Sell.
   */
  get directionalQuantity() {
    if (this.Side == null) {
      return undefined;
    }

    if (this.Side === SideEnum.Buy) {
      return this.Quantity;
    }

    const qtyBig = toBig(this.Quantity);
    if (!qtyBig) {
      return undefined;
    }

    return qtyBig.times(-1).toFixed();
  }

  /** Returns a new SettleableTrade instance representing the other side of this trade */
  getAsOtherSide(): SettleableTrade {
    return new SettleableTrade({
      ...this,
      Side: this.Side === SideEnum.Buy ? SideEnum.Sell : SideEnum.Buy,
      Due: this.Proceeds,
      CumSettledDue: this.CumSettledProceeds,
      DueAsset: this.ProceedsAsset,
      Proceeds: this.Due,
      CumSettledProceeds: this.CumSettledDue,
      ProceedsAsset: this.DueAsset,
    });
  }

  static fromTradeAndTSR(trade: Trade, tsr?: TradeSettleReport): SettleableTrade {
    return new SettleableTrade(combineTradeAndTSR(trade, tsr));
  }

  constructor(settleableTrade: ISettleableTrade) {
    return Object.assign(this, settleableTrade);
  }
}

/** ISettleableTrade interface is the non-class representation of a Settleable Trade */
export type ISettleableTrade = Omit<SettleableTrade, 'directionalQuantity' | 'getAsOtherSide'>;

function combineTradeAndTSR(trade: Trade, tsr: TradeSettleReport | undefined): ISettleableTrade {
  return {
    TradeID: trade.TradeID,
    OrderID: trade.OrderID,
    Counterparty: trade.Counterparty,
    MarketAccount: trade.MarketAccount,
    TransactTime: trade.TransactTime,
    Symbol: trade.Symbol,
    Currency: trade.Currency,
    Quantity: trade.Quantity,
    Amount: trade.Amount,
    AmountCurrency: trade.AmountCurrency,
    Price: trade.Price,
    Side: trade.Side,
    SettleReportID: tsr?.ReportID,
    Due: tsr?.Due,
    DueAsset: tsr?.DueAsset,
    Proceeds: tsr?.Proceeds,
    ProceedsAsset: tsr?.ProceedsAsset,
    CumSettledDue: tsr?.CumSettledDue,
    CumSettledProceeds: tsr?.CumSettledProceeds,
    SettleStatus: tsr?.Status,
  };
}
