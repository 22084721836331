import { createContext, useContext } from 'react';

export interface IOrderPresetsContext {
  orderPresetsList: IOrderPreset[];
  orderPresetsByID: Map<string, IOrderPreset>;
  createOrderPreset: (preset: IOrderPreset) => void;
  updateOrderPreset: (id: string, preset: Omit<IOrderPreset, 'id'>) => void;
  deleteOrderPreset: (id: string) => void;
}

export interface IOrderPreset {
  id: string;
  strategy: string;
  parameters: { key: string; value: any }[];
  allocations: { subAccount: string; value: string }[];
  name: string;
  isFavorite: boolean;
}

export const OrderPresetsContext = createContext<IOrderPresetsContext | undefined>(undefined);
OrderPresetsContext.displayName = 'OrderPresetsContext';

export function useOrderPresets() {
  const context = useContext(OrderPresetsContext);
  if (context === undefined) {
    throw new Error('Missing OrderPresetsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
