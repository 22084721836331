import { WebSocketClient } from '@talos/kyoko';

let wsClient: WebSocketClient;
/**
 * @returns The global WebSocketClient instance.
 */
export function getWsClient() {
  if (wsClient == null) {
    wsClient = new WebSocketClient();
  }
  return wsClient;
}
