import { forwardRef, useCallback, useEffect, useReducer, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  ACTION,
  ConnectionCapabilitiesEnum,
  ConnectionModeEnum,
  Dialog,
  Flex,
  FormGroup,
  Input,
  Toggle,
  useGlobalToasts,
  useUserContext,
  type DialogProps,
  type SizeBucket,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { updateSecurityStatus } from './helpers';
import type { MarketSecurityMode, MarketSecurityStatusLocal } from './types';

enum Action {
  Initialize = 'Init',
  Mode = 'Mode',
  SizeBuckets = 'SizeBuckets',
  MinPriceIncrement = 'MinPriceIncrement',
  MinSizeIncrement = 'MinSizeIncrement',
  MinimumSize = 'MinimumSize',
  MaximumSize = 'MaximumSize',
  MinimumAmount = 'MinimumAmount',
  MarketDataMode = 'MarketDataMode',
}

interface EditMarketSecModeDialogProps extends DialogProps {
  mktSecStatus: MarketSecurityStatusLocal;
}

export const EditMarketSecModeDialog = forwardRef<HTMLDivElement | null, EditMarketSecModeDialogProps>(
  function EditMarketSecModeDialog(props: EditMarketSecModeDialogProps, ref) {
    const { spacingMedium } = useTheme();
    const { mktSecStatus } = props;
    const [changedValue, setChangedValue] = useState(false);
    const [state, dispatch] = useReducer(editMarketSecModeReducer, {
      status: mktSecStatus,
      Mode: ConnectionModeEnum.Down,
      SizeBuckets: '',
      MinPriceIncrement: '',
      MinSizeIncrement: '',
      MinimumSize: '',
      MaximumSize: '',
      MinimumAmount: '',
      MarketDataMode: false,
    });
    const { orgApiEndpoint } = useUserContext();
    const { add: addToast } = useGlobalToasts();
    const { isAuthorized } = useRoleAuth();

    const handleConfirm = useCallback(() => {
      if (mktSecStatus === undefined) {
        return;
      }
      const diff = makeDiff(state, mktSecStatus);
      return updateSecurityStatus({
        orgApiEndpoint,
        mktSecStatus,
        data: diff,
        addToast,
      });
    }, [addToast, mktSecStatus, orgApiEndpoint, state]);

    const handleChange = useCallback(
      (action: Action, value: any) => {
        switch (action) {
          case Action.Initialize:
            break;
          default:
            if (!changedValue) {
              setChangedValue(true);
            }
            dispatch({
              type: action,
              value,
            });
            break;
        }
      },
      [dispatch, changedValue]
    );

    // When binding to a new mktSecStatus (and on the first render), initialize the state of the reducer
    useEffect(() => {
      if (mktSecStatus == null) {
        return;
      }
      dispatch({
        type: Action.Initialize,
        state: {
          status: mktSecStatus,
          Mode: mktSecStatus.RequestedEnabled !== undefined ? mktSecStatus.RequestedEnabled : mktSecStatus.Enabled,
          SizeBuckets:
            mktSecStatus.RequestedSizeBuckets !== undefined
              ? sizeBucketsToString(mktSecStatus.RequestedSizeBuckets)
              : '',
          MinPriceIncrement:
            mktSecStatus.RequestedMinPriceIncrement !== undefined ? mktSecStatus.RequestedMinPriceIncrement : '',
          MinSizeIncrement:
            mktSecStatus.RequestedMinSizeIncrement !== undefined ? mktSecStatus.RequestedMinSizeIncrement : '',
          MinimumSize: mktSecStatus.RequestedMinimumSize !== undefined ? mktSecStatus.RequestedMinimumSize : '',
          MaximumSize: mktSecStatus.RequestedMaximumSize !== undefined ? mktSecStatus.RequestedMaximumSize : '',
          MinimumAmount: mktSecStatus.RequestedMinimumAmount !== undefined ? mktSecStatus.RequestedMinimumAmount : '',
          MarketDataMode:
            mktSecStatus.RequestedCapabilities?.[ConnectionCapabilitiesEnum.MarketData] !== undefined
              ? mktSecStatus.RequestedCapabilities?.[ConnectionCapabilitiesEnum.MarketData] === true
              : mktSecStatus.Capabilities?.[ConnectionCapabilitiesEnum.MarketData] === true,
        },
      });
    }, [mktSecStatus]);

    return (
      <Dialog
        {...props}
        ref={ref}
        onConfirm={handleConfirm}
        title={`${mktSecStatus.Symbol} @ ${mktSecStatus.MarketAccount}`}
        width={360}
        confirmDisabled={!changedValue}
      >
        <Flex justifyContent="space-between" mr={spacingMedium}>
          <p>Enable</p>
          <Toggle
            checked={state.Mode === ConnectionModeEnum.Up}
            onChange={(val: boolean) =>
              handleChange(Action.Mode, val ? ConnectionModeEnum.Up : ConnectionModeEnum.Down)
            }
          />
        </Flex>
        <Flex justifyContent="space-between" mr={spacingMedium}>
          <p>Enable Market Data</p>
          <Toggle
            checked={state.MarketDataMode}
            onChange={(val: boolean) => handleChange(Action.MarketDataMode, val)}
          />
        </Flex>
        <FormGroup label="Size Buckets">
          <Input
            type="text"
            placeholder={sizeBucketsToString(state.status?.MarketSizeBuckets || state.status?.SizeBuckets || [])}
            onChange={evt => handleChange(Action.SizeBuckets, evt.target.value)}
            value={state.SizeBuckets}
            clearable
          />
        </FormGroup>
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Min Price Increment">
            <Input
              type="text"
              placeholder={state.status?.MarketMinPriceIncrement || state.status?.MinPriceIncrement}
              onChange={evt => handleChange(Action.MinPriceIncrement, evt.target.value)}
              value={state.MinPriceIncrement}
              clearable
            />
          </FormGroup>
        )}
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Min Size Increment">
            <Input
              type="text"
              placeholder={state.status?.MarketMinPriceIncrement || state.status?.MinSizeIncrement}
              onChange={evt => handleChange(Action.MinSizeIncrement, evt.target.value)}
              value={state.MinSizeIncrement}
              clearable
            />
          </FormGroup>
        )}
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Max Size">
            <Input
              type="text"
              placeholder={state.status?.MarketMaximumSize || state.status?.MaximumSize}
              onChange={evt => handleChange(Action.MaximumSize, evt.target.value)}
              value={state.MaximumSize}
              clearable
            />
          </FormGroup>
        )}
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Min Size">
            <Input
              type="text"
              placeholder={state.status?.MarketMinimumSize || state.status?.MinimumSize}
              onChange={evt => handleChange(Action.MinimumSize, evt.target.value)}
              value={state.MinimumSize}
              clearable
            />
          </FormGroup>
        )}
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Min Amount">
            <Input
              type="text"
              placeholder={state.status?.MarketMinimumAmount || state.status?.MinimumAmount}
              onChange={evt => handleChange(Action.MinimumAmount, evt.target.value)}
              value={state.MinimumAmount}
              clearable
            />
          </FormGroup>
        )}
      </Dialog>
    );
  }
);

function stringToBuckets(str: string): string[] {
  return str.split(',').map(s => {
    return s.trim();
  });
}

function sizeBucketsToString(buckets: SizeBucket[] | undefined): string {
  return buckets?.map(bucket => bucket.Size).join(', ') || '';
}

const writeableProps = Object.values<string>(Action).filter(a => a !== 'Init');

// Based on the state of the reducer, generate a diff that will be sent to the server to execute the change.
export function makeDiff(state: EditMarketSecModeState, orig: MarketSecurityStatusLocal): Partial<MarketSecurityMode> {
  const diff: Partial<MarketSecurityMode> = {};
  writeableProps.forEach(prop => {
    if (orig[prop] !== state[prop]) {
      switch (prop) {
        case Action.SizeBuckets: {
          const oldBuckets = sizeBucketsToString(orig.SizeBuckets);
          if (state.SizeBuckets === '') {
            diff.SizeBuckets = null;
          } else if (oldBuckets !== state.SizeBuckets) {
            diff.SizeBuckets = stringToBuckets(state.SizeBuckets);
          }
          break;
        }
        case Action.MarketDataMode:
          if (state.MarketDataMode !== orig.Capabilities?.MarketData) {
            diff.Capabilities = {
              ...orig.Capabilities,
              ...{
                MarketData: state[Action.MarketDataMode],
              },
            };
          }
          break;
        case Action.Mode:
          if (state.Mode !== orig.RequestedEnabled) {
            diff.Mode = state.Mode;
          }
          break;
        default:
          if (state[prop] === '') {
            diff[prop] = null;
          } else {
            diff[prop] = state[prop];
          }
      }
    }
  });
  return diff;
}

export interface EditMarketSecModeState {
  status?: MarketSecurityStatusLocal;
  Mode: ConnectionModeEnum;
  SizeBuckets: string;
  MinPriceIncrement: string;
  MinSizeIncrement: string;
  MinimumSize: string;
  MaximumSize: string;
  MinimumAmount: string;
  MarketDataMode: boolean;
}

interface InitializeAction {
  type: Action.Initialize;
  state: EditMarketSecModeState;
}

interface SetModeAction {
  type: Action.Mode;
  value: ConnectionModeEnum;
}

interface SetStringAction {
  type:
    | Action.SizeBuckets
    | Action.MinPriceIncrement
    | Action.MinSizeIncrement
    | Action.MaximumSize
    | Action.MinimumSize
    | Action.MinimumAmount;
  value: string;
}

interface SetBoolAction {
  type: Action.MarketDataMode;
  value: boolean;
}

type EditAction = InitializeAction | SetModeAction | SetStringAction | SetBoolAction;

const editMarketSecModeReducer = (state: EditMarketSecModeState, action: EditAction): EditMarketSecModeState => {
  switch (action.type) {
    case Action.Initialize:
      return { ...state, ...action.state };
    default:
      return { ...state, [action.type]: action.value };
  }
};
