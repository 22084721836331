import { isSpot } from '../utils';
import type { Currency } from './Currency';
import type { Security } from './Security';

export type Asset = Security | Currency;

export function getAssetDisplaySymbol(asset: Asset) {
  return assetIsSecurity(asset) ? asset.DisplaySymbol ?? asset.Symbol : asset.Symbol;
}

// A security has a quote currency and a base currency, which a currency does not
export function assetIsSecurity(asset: Asset): asset is Security {
  return 'QuoteCurrency' in asset || 'BaseCurrency' in asset;
}

export function assetIsCurrency(asset: Asset): asset is Currency {
  return !assetIsSecurity(asset);
}

/**
 * Given an asset (either a security or currency), returns whether or not this asset is a spot asset
 */
export function isAssetSpot({ security, currency }: { security?: Security; currency?: Currency }) {
  if (currency) {
    return true;
  }

  if (security) {
    return isSpot(security) ?? false;
  }

  return false;
}

/**
 * Given an asset (either a security or currency), returns whether the asset is a Spot Security
 */
export function isAssetSpotSecurity({ security, currency }: { security?: Security; currency?: Currency }) {
  return isSpot(security) ?? false;
}
