import { POSITION_SUB_ACCOUNT, useSubscription, type Position } from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { customEODToRequestEOD } from '../../../Blotters/PositionsV3/SubAccounts/useSubAccountPositionsBlotterRequest';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../../../Blotters/PositionsV3/tokens';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import { PRODUCTS, type PerfSubAccountPositionsRequest } from '../types';

export const useSubAccountPositionsSub = () => {
  const {
    state: { pnlLookbacks, filter, subAccounts, showZeroBalances, product },
  } = usePerformanceContext();

  const { customEOD, homeCurrency } = useDisplaySettings();

  const [request, setRequest] = useState<PerfSubAccountPositionsRequest | null>(null);

  // Whenever customEOD changes, check if we should amend our request.
  useEffect(() => {
    setRequest(currentRequest => {
      const newRequest: PerfSubAccountPositionsRequest = {
        name: POSITION_SUB_ACCOUNT,
        tag: 'portfolio/performance',
        ShowZeroBalances: showZeroBalances,
        EquivalentCurrency: homeCurrency,
        Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
        EndOfDay: customEODToRequestEOD(customEOD),
        PnLLookbacks: pnlLookbacks,
        AssetTypes: PRODUCTS[product].productTypes,
        SubAccounts: subAccounts,
        ...filter,
      };

      return isEqual(currentRequest, newRequest) ? currentRequest : newRequest;
    });
  }, [customEOD, pnlLookbacks, filter, homeCurrency, subAccounts, showZeroBalances, product]);

  const { data } = useSubscription<Position>(request);
  return data;
};
