import { BLOTTER_TABLE_FILTERS_CONTAINER_ID, Box, HStack, usePortal, VStack } from '@talos/kyoko';
import type { PropsWithChildren } from 'react';

export const BlotterContainer = ({ children }: PropsWithChildren) => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack h="40px" w="100%" justifyContent="right" gap="spacingComfortable" background="backgroundContent">
        <Box ref={filtersContainerRef} pr="spacingComfortable" />
      </HStack>
      <Box flex="1">{children}</Box>
    </VStack>
  );
};
