import { Flex, Spinner, Text } from '@talos/kyoko';
import type { MarketPositions } from 'containers/Portfolio/types';
import { useTheme } from 'styled-components';
import { useTransfersProcessing } from '../useTransfersProcessing';

interface ToastContentProps {
  marketPositions: MarketPositions[];
}

export const ToastContent = ({ marketPositions }: ToastContentProps) => {
  const { baseSize, fontSizeDefault } = useTheme();
  const transfersProcessing = useTransfersProcessing(marketPositions);

  return (
    <Flex gap="spacingDefault">
      {transfersProcessing.length > 0 && <Spinner size={baseSize * fontSizeDefault} />}
      <Text size="fontSizeDefault">{transfersProcessing.length} ongoing transfer(s)</Text>
    </Flex>
  );
};
