import { ConnectionModeEnum, NotificationVariants, PATCH, request, type ToastProps } from '@talos/kyoko';
import type { MarketSecurityMode, MarketSecurityStatusLocal } from './types';

export const isSecurityStatusEnabled = (securityStatus: MarketSecurityStatusLocal) => {
  return securityStatus.RequestedEnabled === ConnectionModeEnum.Up;
};

export const isMarketDataEnabled = (securityStatus: MarketSecurityStatusLocal) => {
  return securityStatus.Capabilities?.MarketData === true;
};

export function canEnableSecurityStatus(status: MarketSecurityStatusLocal): boolean {
  return status.MarketEnabled !== ConnectionModeEnum.Down && status.RequestedEnabled !== ConnectionModeEnum.Up;
}

export const updateSecurityStatus = ({
  orgApiEndpoint,
  mktSecStatus,
  data,
  addToast,
}: {
  orgApiEndpoint?: string;
  mktSecStatus: MarketSecurityStatusLocal;
  data: Partial<MarketSecurityMode>;
  addToast: (
    toast: Omit<ToastProps, 'id'> & {
      id?: string | undefined;
    }
  ) => void;
}) => {
  const endpoint = `${orgApiEndpoint}/market-security-modes/${mktSecStatus?.MarketAccount}/symbol/${mktSecStatus?.Symbol}`;
  return request<Partial<MarketSecurityMode>>(PATCH, endpoint, data)
    .then(res => {
      addToast({
        text: `Updated ${mktSecStatus?.Symbol} @ ${mktSecStatus?.MarketAccount}`,
        variant: NotificationVariants.Positive,
      });
    })
    .catch((e: ErrorEvent) => {
      addToast({
        text: e?.toString() || `Could not update ${mktSecStatus?.Symbol} @ ${mktSecStatus?.MarketAccount} `,
        variant: NotificationVariants.Negative,
      });
    });
};
