import { ExecTypeEnum, ExecutionReport, useObservable, useSubscription, type RequestStream } from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { map, scan, type Observable } from 'rxjs';
import { useExecutionReportFilteringPipe } from './useExecutionReportFilteringPipe';

interface ExecutionReportsRequest extends RequestStream {
  name: string;
  SendMarkets: boolean;
  CoalesceExecs: ExecTypeEnum[];
  tag: string;
  orderID?: string;
  RFQID?: string;
  ParentOrderID?: string;
  ParentRFQID?: string;
}

export function useExecutionReports({
  orderID,
  rfqID,
  parentOrderID,
  parentRFQID,
  tag,
  statuses,
}: {
  orderID?: string;
  rfqID?: string;
  parentOrderID?: string;
  parentRFQID?: string;
  tag: string;
  statuses?: string[];
}): Observable<ExecutionReport[]> {
  const [request, setRequest] = useState<ExecutionReportsRequest | null>(null);

  useEffect(() => {
    if ([orderID, rfqID, parentOrderID, parentRFQID].every(id => id == null)) {
      setRequest(null);
    } else {
      setRequest({
        name: 'ExecutionReport',
        tag,
        ...(orderID != null && { OrderID: orderID }),
        ...(rfqID != null && { RFQID: rfqID }),
        ...(parentOrderID != null && { ParentOrderID: parentOrderID }),
        ...(parentRFQID != null && { ParentRFQID: parentRFQID }),
        SendMarkets: true,
        CoalesceExecs: [ExecTypeEnum.Restated, ExecTypeEnum.Trade],
      });
    }
  }, [tag, orderID, rfqID, parentOrderID, parentRFQID]);

  const { data: subscription } = useSubscription<ExecutionReport>(request, { loadAll: true });
  const executionReportFilteringPipe = useExecutionReportFilteringPipe<ExecutionReport>(statuses);

  return useObservable<ExecutionReport[]>(
    () =>
      subscription.pipe(
        map(json => {
          return {
            ...json,
            data: json.data.map(item => new ExecutionReport(item)),
          };
        }),
        executionReportFilteringPipe,
        scan((acc, json) => {
          if (json.initial) {
            acc = [];
          }
          return acc.concat(json.data);
        }, [] as ExecutionReport[])
      ),
    [subscription, executionReportFilteringPipe]
  );
}
