import type { MarketAccount, MarketAccountStatusEnum, MarketCredential, MarketTypeEnum } from '@talos/kyoko';

export interface TradingControlsBlotterEntity extends MarketAccount {
  ConnectionStatus: string | undefined;
  MarketType: MarketTypeEnum | undefined;
  EquityMarginRatio: string | undefined;
  GlobalEquityMarginRatio: string | undefined;
  MarketCredential: MarketCredential | undefined;
}

export interface ITradingControlsDrawerTab {
  name: string;
  save: () => Promise<void>;
  isDirty: boolean;
  isValid: boolean;
  component: JSX.Element;
}

export interface MarketAccountHistory {
  Revision: number;
  UpdateUser: string;
  Timestamp: string;
  Status: MarketAccountStatusEnum;
}

export interface MarketAccountHistoryBlotterEntity {
  UpdatedAt: string;
  Revision: number;
  User: string;
  Status: MarketAccountStatusEnum;
  Action: string;
  Description: string;
  RowID: string;
  RowIndex: number;
}

export const RIGHT_ITEMS_PORTAL_ID = 'rightItemsPortalID';
export const MIN_EQUITY_MARGIN_RATIO_INCREMENT = '0.00000001';
