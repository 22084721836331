import {
  DepthTypeEnum,
  FeeModeEnum,
  LiquidityTypeEnum,
  MARKET_DATA_SNAPSHOT,
  MARKET_DATA_STATISTICS,
  useSubscription,
  type MarketDataSnapshot,
  type MarketDataStatistics,
} from '@talos/kyoko';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useEffect, useMemo } from 'react';
import { asyncScheduler } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import { selectResolvedSecurities, updateMarketData, updateStatistics } from '../MultilegComboSlice';

// Given a list of securities, i.e. each leg in an Option strategy
// Subscribe to MarketStatistics and MarketDataSnapshot in order to enrich the rows to display more info
export const useRowEnricher = ({ tag }: { tag: string }): void => {
  const dispatch = useAppStateDispatch();
  const { showAllInPrices } = useDisplaySettings();
  const securities = useAppStateSelector(selectResolvedSecurities);

  const statisticsRequest = useMemo(() => {
    const onlyResolved = securities.filter(x => !!x);
    if (!onlyResolved.length) {
      return [];
    }

    return onlyResolved.flatMap(option => [
      {
        name: MARKET_DATA_STATISTICS,
        tag: `${tag}/useRowEnricher`,
        Markets: option!.Markets,
        Symbol: option!.Symbol,
      },
    ]);
  }, [securities, tag]);

  const snapshotRequest = useMemo(() => {
    const onlyResolved = securities.filter(x => !!x);
    if (!onlyResolved.length) {
      return [];
    }

    return onlyResolved.flatMap(option => [
      {
        name: MARKET_DATA_SNAPSHOT,
        DepthType: DepthTypeEnum.Price,
        tag: `${tag}/useRowEnricher`,
        Markets: option!.Markets,
        Symbol: option!.Symbol,
        LiquidityType: LiquidityTypeEnum.Indicative,
        UnifiedLiquidity: 'Disabled',
        Depth: 1,
        FeeMode: showAllInPrices ? FeeModeEnum.Taker : undefined,
      },
    ]);
  }, [securities, showAllInPrices, tag]);

  const { data: statisticsSubscription } = useSubscription<MarketDataStatistics>(statisticsRequest);
  const { data: snapshotsSubscription } = useSubscription<MarketDataSnapshot>(snapshotRequest);

  useEffect(() => {
    // note: this is still a single subscription on websocket, we simply then branch out to individuals based on Symbol
    // this allows us to immediately get the first response for each symbol and then throttle the rest going forward
    const individualSubscriptions = securities.map(s => {
      return statisticsSubscription
        .pipe(filter(statisticResponse => statisticResponse.data?.[0]?.Symbol === s?.Symbol))
        .pipe(throttleTime(5_000, asyncScheduler, { leading: true, trailing: true }))
        .subscribe(statisticResponse => {
          dispatch(updateStatistics(statisticResponse.data?.[0]));
        });
    });
    return () => {
      individualSubscriptions.forEach(s => s.unsubscribe());
    };
  }, [statisticsSubscription, dispatch, securities]);

  useEffect(() => {
    const individualSubscriptions = securities.map(s => {
      return snapshotsSubscription
        .pipe(filter(snapshotResponse => snapshotResponse.data?.[0]?.Symbol === s?.Symbol))
        .pipe(throttleTime(5_000, asyncScheduler, { leading: true, trailing: true }))
        .subscribe(snapshotResponse => {
          dispatch(updateMarketData(snapshotResponse.data?.[0]));
        });
    });
    return () => {
      individualSubscriptions.forEach(s => s.unsubscribe());
    };
  }, [snapshotsSubscription, dispatch, securities]);
};
