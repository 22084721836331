/**
 * Initialize intercom with the snippet listed on the intercom docs
 */
export function initIntercom(APP_ID?: string) {
  if (import.meta.env.MODE === 'development') {
    return;
  }
  /* eslint-disable */
  (function () {
    var w = window;
    // @ts-expect-error This snippet is copied verbatim from intercom's docs.
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      // @ts-expect-error This snippet is copied verbatim from intercom's docs.
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        // @ts-expect-error This snippet is copied verbatim from intercom's docs.
        i.c(arguments);
      };
      // @ts-expect-error This snippet is copied verbatim from intercom's docs.
      i.q = [];
      // @ts-expect-error This snippet is copied verbatim from intercom's docs.
      i.c = function (args) {
        // @ts-expect-error This snippet is copied verbatim from intercom's docs.
        i.q.push(args);
      };
      // @ts-expect-error This snippet is copied verbatim from intercom's docs.
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        var x = d.getElementsByTagName('script')[0];
        // @ts-expect-error This snippet is copied verbatim from intercom's docs.
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
        // @ts-expect-error This snippet is copied verbatim from intercom's docs.
      } else if (w.attachEvent) {
        // @ts-expect-error This snippet is copied verbatim from intercom's docs.
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
  /* eslint-enable */
}
