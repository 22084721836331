import type { CSSProperties, ReactNode } from 'react';
import type { BoxProps } from '../Core';
import { Actions, CardWrapper, Header, Subtitle, Title } from './styles';

export interface CardProps extends Omit<BoxProps, 'title'> {
  title?: ReactNode;
  actions?: ReactNode;
  header?: ReactNode;
  children?: ReactNode;
  style?: CSSProperties;
}

export function Card({ title, actions, header, children, ...props }: CardProps) {
  return (
    <CardWrapper {...props}>
      {(header || title || actions) && (
        <Header>
          {header}
          {title && <Title>{title}</Title>}
          {actions && <Actions>{actions}</Actions>}
        </Header>
      )}
      {children}
    </CardWrapper>
  );
}

Card.Header = Header;
Card.Title = Title;
Card.Subtitle = Subtitle;
Card.Actions = Actions;
