import Big from 'big.js';
import { AllocationValueTypeEnum, type IAllocation } from '../types/types';
import { logger } from '../utils';
import type { SubAccount } from './SubAccount';

/** A frontend-specific representation of an Allocation to Sub Accounts */
export interface Allocation {
  subAccount: string;
  value: string;
}

/**
 * Given an IAllocation, returns a frontend usable array of subAccountAllocations along side the allocationValueType
 *
 * Percent params 0.2 --> 20
 *
 * allocation.valueType --> allocationValueType
 *
 * allocation.allocations --> subAccountAllocations
 */
export function convertReceivedAllocationForUI(allocation: IAllocation) {
  const allocations = allocation.Allocations;
  const allocationValueType = allocation.ValueType;
  const subAccountAllocations: Allocation[] = [];

  if (allocationValueType === AllocationValueTypeEnum.Percentage) {
    for (let i = 0; i < allocations.length; i++) {
      subAccountAllocations.push({
        subAccount: allocations[i].SubAccount,
        value: Big(allocations[i].Value || '0')
          .times(100)
          .toFixed(),
      });
    }
  } else {
    for (let i = 0; i < allocations.length; i++) {
      subAccountAllocations.push({
        subAccount: allocations[i].SubAccount,
        value: allocations[i].Value || '',
      });
    }
  }

  return {
    subAccountAllocations,
    allocationValueType,
  };
}

// TODO get rid of this or the Allocation interface... Or better split the functionality
interface RequestAllocation {
  SubAccount: string;
  Value: string;
}

/**
 * Converts the frontend Allocation structure into the backend-supported formats for making requests
 *
 * Parameter quantity is optional -- setting it to undefined is allowed, but it should only be used if you only intend
 * to use the Percentage AllocationValueTypeEnum.
 */
export const prepareAllocationsForRequest = ({
  subAccountAllocations,
  subAccounts,
  allocationValueType,
  quantity,
}: {
  subAccountAllocations?: Allocation[];
  subAccounts: SubAccount[] | undefined;
  allocationValueType?: AllocationValueTypeEnum;
  quantity: string | undefined;
}):
  | {
      ValueType: AllocationValueTypeEnum;
      Allocations: RequestAllocation[];
    }
  | undefined => {
  if (subAccounts === undefined) {
    return undefined;
  }
  if (allocationValueType == null) {
    logger.error(new Error(`allocationValueType is null while preparing allocations`));
    return undefined;
  }
  if (subAccountAllocations == null) {
    logger.error(new Error(`subAccountAllocations is null while preparing allocations`));
    return undefined;
  }
  if (allocationValueType === AllocationValueTypeEnum.Quantity && quantity == null) {
    logger.error(new Error(`Trying to prepare Allocations of type Quantity without providing a quantity`));
    return undefined;
  }
  const allocationsArr: RequestAllocation[] = [];

  if (subAccountAllocations?.length === 1) {
    const subAccount = subAccountAllocations[0].subAccount;
    if (!subAccount) {
      logger.error(new Error(`Single allocation is incorrectly formatted`), {
        extra: {
          allocation: subAccountAllocations[0],
        },
      });
      return undefined;
    }

    if (allocationValueType === AllocationValueTypeEnum.Percentage) {
      return {
        ValueType: allocationValueType,
        Allocations: [{ SubAccount: subAccount, Value: '1' }],
      };
    }

    if (allocationValueType === AllocationValueTypeEnum.Quantity && quantity != null) {
      return {
        ValueType: allocationValueType,
        Allocations: [{ SubAccount: subAccount, Value: quantity }],
      };
    }

    return undefined;
  }

  if (allocationValueType === AllocationValueTypeEnum.Percentage) {
    for (let i = 0; i < subAccountAllocations.length; i++) {
      const percentValue = subAccountAllocations[i].value || '100';
      const subAccount = subAccountAllocations[i].subAccount;
      const value = Big(percentValue).div(100).toFixed();
      if (!subAccount) {
        logger.error(new Error(`Allocation is incorrectly formatted`), {
          extra: {
            allocation: JSON.stringify(subAccountAllocations[i]),
          },
        });
        return undefined;
      }
      allocationsArr.push({ SubAccount: subAccount, Value: value });
    }
  } else {
    for (let i = 0; i < subAccountAllocations.length; i++) {
      const value = subAccountAllocations[i].value || quantity;
      if (value == null) {
        return undefined;
      }

      const subAccount = subAccountAllocations[i].subAccount;
      if (!subAccount) {
        logger.error(new Error(`Allocation is incorrectly formatted`), {
          extra: {
            allocation: JSON.stringify(subAccountAllocations[i]),
          },
        });
        return undefined;
      }
      allocationsArr.push({ SubAccount: subAccount, Value: value });
    }
  }

  // Validation for sending allocations array
  for (const obj of allocationsArr) {
    if (!obj || !obj.SubAccount || !obj.Value || !subAccounts.map(sa => sa.Name).includes(obj.SubAccount)) {
      logger.error(new Error(`Allocation failed validation`), {
        extra: {
          allocation: JSON.stringify(obj),
        },
      });
      return undefined;
    }
  }

  return {
    ValueType: allocationValueType,
    Allocations: allocationsArr,
  };
};
