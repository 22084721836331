import { useRef } from 'react';
import { JsonModal } from '../components/JsonModal';
import { Modal } from '../components/Modal';
import { useMixpanel } from '../contexts';
import { MixpanelEvent } from '../tokens';
import { EMPTY_OBJECT, logger } from '../utils';
import { useDisclosure } from './useDisclosure';
import { useDynamicCallback } from './useDynamicCallback';

interface UseJsonModalParams {
  /** When handleClickJson is invoked, you can have a customMixpanelEvent be sent. The default sent Mixpanel event is `MixpanelEvent.ShowJSON`. */
  customMixpanelEvent?: MixpanelEvent;
}

/**
 * A helper hook encapsulating the logic needed to show a Json Modal with some data inside it
 * @returns `handleClickJson`, to be invoked whenever you want to show some data in the json modal, and then `jsonModal` which is the ReactNode
 * of the json modal itself
 */
export function useJsonModal<R>({ customMixpanelEvent }: UseJsonModalParams = EMPTY_OBJECT) {
  const selectedJsonItem = useRef('');
  const jsonModal = useDisclosure();
  const mixpanel = useMixpanel();

  const handleClickJson = useDynamicCallback((data: R | undefined) => {
    mixpanel.track(customMixpanelEvent ?? MixpanelEvent.ShowJSON);
    try {
      if (data instanceof Map) {
        selectedJsonItem.current = JSON.stringify(mapToObject(data), replacer, 2);
      } else {
        selectedJsonItem.current = JSON.stringify(data, replacer, 2);
      }
    } catch (e) {
      logger.error(e as Error);
    }
    jsonModal.open();
  });

  return {
    handleClickJson,
    jsonModal: (
      <Modal {...jsonModal} key="json-modal">
        <JsonModal isVisible={jsonModal.isOpen}>{selectedJsonItem.current}</JsonModal>
      </Modal>
    ),
  };
}

function mapToObject(aMap: Map<any, any>) {
  const obj = Object.create(null);
  for (const [k, v] of aMap) {
    if (v instanceof Map) {
      obj[k.toString()] = mapToObject(v); // handle Maps that have Maps as values
    } else {
      // calling toString handles case where map key is not a string JSON requires key to be a string
      obj[k.toString()] = v;
    }
  }
  return obj;
}

function replacer(key: string, value: any) {
  if (value instanceof Map) {
    return mapToObject(value);
  } else {
    return value;
  }
}
