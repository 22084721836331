import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { useTheme } from 'styled-components';
import type { MarketConfig } from '../../contexts';
import type { Market } from '../../types';
import { Button, IconButton } from '../Button';
import { HStack } from '../Core';
import type { FormControlSizes } from '../Form/types';
import { Icon, IconName } from '../Icons';
import { Guide, GuideContent, GuideImage, GuideStep, GuideText, GuideWrapper, Index, Indexes } from './styles';

export const OnboardingGuide = ({
  guide,
  externalIP,
  formControlSize,
  selectedMarket,
}: {
  guide?: MarketConfig['guide'];
  externalIP: string;
  formControlSize: FormControlSizes;
  selectedMarket: Market;
}) => {
  const theme = useTheme();
  const [currentStep, setCurrentStep] = useState(0);
  const guideElementRef = useRef<HTMLDivElement>(null);

  const replaceExternalIP = step => {
    return step.replaceAll('{{ExternalIP}}', externalIP);
  };

  useEffect(() => {
    if (guide != null && guideElementRef.current != null) {
      const subscription = fromEvent(guideElementRef.current, 'scroll')
        .pipe(debounceTime(200))
        .subscribe(() => {
          if (guideElementRef.current != null) {
            const index = Math.round(
              (guideElementRef.current?.scrollLeft + theme.spacingHuge) / guideElementRef.current?.offsetWidth
            );
            setCurrentStep(index);
          }
        });
      return () => subscription.unsubscribe();
    }
  }, [theme, guide]);

  const setStep = index => {
    if (guideElementRef.current != null) {
      setCurrentStep(index);
      guideElementRef.current?.scrollTo({
        left: ((guideElementRef.current?.offsetWidth ?? 0) + theme.spacingHuge) * index,
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  const prevStep = () => setStep(currentStep - 1);
  const nextStep = () => setStep(currentStep + 1);

  return (
    <GuideWrapper>
      {guide != null && guide.length > 1 && (
        <Indexes>
          <IconButton
            icon={IconName.ChevronLeft}
            size={formControlSize}
            disabled={currentStep === 0}
            onClick={prevStep}
          />
          <div>
            {guide?.map((_, index) => (
              <Index key={index} onClick={() => setStep(index)}>
                {index <= currentStep && (
                  <Icon
                    icon={IconName.DotSolid}
                    color={index === currentStep ? theme.colorTextPrimary : theme.colorTextMuted}
                  />
                )}
              </Index>
            ))}
          </div>
          <Button
            endIcon={IconName.ChevronRight}
            size={formControlSize}
            disabled={currentStep === guide.length - 1}
            onClick={nextStep}
          >
            Next
          </Button>
        </Indexes>
      )}
      {guide != null ? (
        <Guide ref={guideElementRef}>
          <HStack w="100%" gap="spacingHuge" justifyContent="flex-start" alignItems="flex-start">
            {guide.map((step, index) => (
              <GuideStep key={index}>
                <GuideContent>
                  <GuideText>
                    <h2 style={{ marginTop: 0 }}>{step.header}</h2>
                    <ReactMarkdown children={replaceExternalIP(step.text)} />
                  </GuideText>
                  <GuideImage>{step.image && <img src={step.image} alt="Screenshot" />}</GuideImage>
                </GuideContent>
              </GuideStep>
            ))}
          </HStack>
        </Guide>
      ) : (
        <GuideContent>
          <GuideText>
            <h2>Contact Us</h2>
            <p>Contact support@talostrading.com for help getting onboarded to {selectedMarket.DisplayName}</p>
          </GuideText>
        </GuideContent>
      )}
    </GuideWrapper>
  );
};
