import { FormControlSizes, Toggle, useDynamicCallback } from '@talos/kyoko';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';

export const ShowDeltaControl = () => {
  const {
    state: { showDelta },
    dispatch,
  } = usePerformanceContext();

  const handleChange = useDynamicCallback((checked: boolean) => {
    dispatch({
      type: PerformanceActionType.ShowDeltaChange,
      payload: {
        showDelta: checked,
      },
    });
  });

  return <Toggle checked={showDelta} onChange={handleChange} label="Delta" size={FormControlSizes.Small} />;
};
