import { ControlPrefix, FormControlSizes, SearchSelect, useDynamicCallback } from '@talos/kyoko';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import type { PnLUnit } from '../types';

const options: PnLUnit[] = ['amount', 'percentage'];

export const PnLUnitControl = () => {
  const { homeCurrency } = useDisplaySettings();

  const {
    state: { pnlUnit },
    dispatch,
  } = usePerformanceContext();

  const handleChange = useDynamicCallback((newPnlUnit: PnLUnit | undefined) => {
    if (newPnlUnit == null) {
      return;
    }

    dispatch({
      type: PerformanceActionType.PnLUnitChange,
      payload: {
        pnlUnit: newPnlUnit,
      },
    });
  });

  const getLabel = useDynamicCallback((pnlUnit: PnLUnit) => {
    return pnlUnit === 'percentage' ? '%' : homeCurrency;
  });

  return (
    <SearchSelect
      prefix={<ControlPrefix>PnL Unit</ControlPrefix>}
      selection={pnlUnit}
      options={options}
      getLabel={getLabel}
      onChange={handleChange}
      size={FormControlSizes.Small}
    />
  );
};
