import {
  ConnectionModeEnum,
  ConnectionStatusEnum,
  HeartBeatStatusEnum,
  type MarketCredentialWithConnectionStatus,
} from '@talos/kyoko';
import type { DefaultTheme } from 'styled-components';

const UNHEALTHY_STATUSES = [ConnectionStatusEnum.Starting, ConnectionStatusEnum.Stopping, ConnectionStatusEnum.Error];

/**
 * Given a list of market and the current status of the heartbeat service,
 * return a status indicator bullet variant.
 *
 * @see HeartBeatStatusEnum
 * @see CONNECTION_STATUS_BULLET
 */
export const computeGlobalStatusColor = (
  marketCredentials: MarketCredentialWithConnectionStatus[],
  heartbeatStatus: string,
  theme: DefaultTheme
) => {
  if (heartbeatStatus === HeartBeatStatusEnum.OFFLINE) {
    return theme.colorTextNegative;
  }

  for (const market of marketCredentials) {
    const status = market.connectionStatus?.Status ?? ConnectionStatusEnum.Unavailable;
    if (UNHEALTHY_STATUSES.includes(status)) {
      return theme.colorTextWarning;
    }
  }
  return theme.colorTextPositive;
};

/**
 * Given a list of market, returns a string on the form "(A / B)" where
 * A is the number of "connected" markets and B is the number of "configured" markets
 *
 * @see shouldIncrementNumerator
 * @see shouldIncrementDenominator
 */
export const computeNumConnectedMarkets = (markets: MarketCredentialWithConnectionStatus[]) => {
  let numerator = 0;
  let denominator = 0;
  for (const market of markets) {
    if (shouldIncrementNumerator(market)) {
      numerator++;
    }
    if (shouldIncrementDenominator(market)) {
      denominator++;
    }
  }

  return `${numerator}/${denominator}`;
};

/**
 * increment denominator as long as a market has a connection which is configured
 */
export const shouldIncrementDenominator = (market: MarketCredentialWithConnectionStatus) =>
  isConnConfiguredAndNotOffline(market);

/**
 * increment numerator unless a market has a connection
 * which is configured but not online
 */
export const shouldIncrementNumerator = (market: MarketCredentialWithConnectionStatus) =>
  isConnConfiguredAndNotOffline(market) && isHealthy(market);

const isHealthy = ({ connectionStatus }: MarketCredentialWithConnectionStatus) =>
  !!(
    connectionStatus &&
    !UNHEALTHY_STATUSES.includes(connectionStatus.Status) &&
    [...Object.values(connectionStatus.ConnectionType)].every(s => s === true)
  );

export const isConnConfiguredAndNotOffline = ({ credential, connectionStatus }: MarketCredentialWithConnectionStatus) =>
  !!(
    credential.Mode === ConnectionModeEnum.Up &&
    connectionStatus !== undefined &&
    connectionStatus.Status !== ConnectionStatusEnum.Offline
  );
