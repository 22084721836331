import type { Response } from '@talos/kyoko';
import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { UserGroup, UserGroupMembership } from 'types';

type UserGroupsContextProps = {
  userGroupsByID: Observable<Map<string, UserGroup>>;
  membershipsByGroupID: Observable<Map<string, UserGroupMembership[]>>;
  membershipsByUser: Observable<Map<string, UserGroupMembership[]>>;

  listUserGroups(): Promise<Response<UserGroup>>;
  getUserGroup(id: string): Promise<Response<UserGroup>>;
  createUserGroup(userGroup: Pick<UserGroup, 'DisplayName'>): Promise<Response<UserGroup>>;
  updateUserGroup(id: string, userGroup: Pick<UserGroup, 'GroupID' | 'DisplayName'>): Promise<Response<UserGroup>>;
  deleteUserGroup(id: string): Promise<Response<UserGroup>>;

  listMemberships: any;
  updateMembership: any;
  batchUpdateMemberships: any;
  deleteMembership: any;
};

export const UserGroupsContext = createContext<UserGroupsContextProps | undefined>(undefined);
UserGroupsContext.displayName = 'UserGroupsContext';

export const useUserGroups = () => {
  const context = useContext(UserGroupsContext);
  if (context == null) {
    throw new Error('Missing CustomerContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
