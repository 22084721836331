import { Button, ButtonVariants, FormControlSizes, IconName, Text, useDynamicCallback } from '@talos/kyoko';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';

export const InsightsToggleButton = () => {
  const {
    state: { showInsights },
    dispatch,
  } = usePerformanceContext();

  const handleClick = useDynamicCallback(() => {
    dispatch({
      type: PerformanceActionType.ShowInsightsChange,
      payload: {
        showInsights: !showInsights,
      },
    });
  });

  return (
    <Button
      variant={showInsights ? ButtonVariants.Primary : ButtonVariants.Default}
      size={FormControlSizes.Small}
      startIcon={IconName.LightBulb}
      onClick={handleClick}
    >
      <Text weight="fontWeightMedium">Insights</Text>
    </Button>
  );
};
