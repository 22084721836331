import type { LayoutSpec, Section } from '../../../types/LayoutConfig';
import { DEFAULT_LAYOUT_SPEC } from './DefaultLayoutSpec';
import { SYMBOL_VIEWS_LAYOUT_SPEC } from './SymbolViewsLayoutSpec';

const DEFAULT_TRADING_LAYOUTS: Record<string, LayoutSpec> = {
  default: DEFAULT_LAYOUT_SPEC,
  symbolViews: SYMBOL_VIEWS_LAYOUT_SPEC,
};

export const DEFAULT_LAYOUTS: Record<Section, Record<string, LayoutSpec>> = {
  trading: DEFAULT_TRADING_LAYOUTS,
};
