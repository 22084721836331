import { HStack, VStack, type BoxProps } from '../Core';
import { Divider } from '../Divider';
import { Text } from '../Text';

interface TextDividerProps extends BoxProps {
  text: string;
}

/**
 * A horizontal divider with a starting Text acting as a title of the divider.
 *
 *
 * Like this:
 * @example
 * Title -------------------------
 */
export function TextDivider({ text, ...boxProps }: TextDividerProps) {
  return (
    <HStack w="100%" gap="spacingComfortable" alignItems="center" {...boxProps}>
      <Text weight="fontWeightBold" color="colorTextImportant">
        {text}
      </Text>
      <VStack flex="1">
        <Divider orientation="horizontal" />
      </VStack>
    </HStack>
  );
}
