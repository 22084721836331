import type { ConnectionModeEnum } from '@talos/kyoko';

export type AddEditCredentialForm = {
  label?: string;
  isDisclaimerRequired?: boolean;
  isDisclaimerChecked?: boolean;
  [key: string]: any;
};

export const OMIT_FORM_KEYS = ['isDisclaimerRequired', 'isDisclaimerChecked'];

export type AddCredentialFormArg = {
  [K in keyof Required<AddEditCredentialForm>]: { key: K; value: AddEditCredentialForm[K] };
}[keyof AddEditCredentialForm];

export interface ICredentialDrawerTab {
  name: string;
  viewable: boolean;
  save: () => Promise<void>;
  isDirty: boolean;
  component: JSX.Element;
  id?: string; // Optional id for the tab
  tour?: string; // Optional tour name for the tab
}

export interface CredentialHistory {
  Label?: string;
  Mode?: ConnectionModeEnum;
  SecretsChanged?: string[];
  Revision: number;
  UpdateUserID: string;
  UpdatedAt: string;
}

export interface MarketAccountBlotterEntity {
  Name: string;
  DisplayName: string;
  Status: string;
}

export const RIGHT_ITEMS_PORTAL_ID = 'rightItemsPortalID';
