import { HStack, MarketLogo, Text, useMarketAccountsContext } from '@talos/kyoko';

export const HeaderMarketAccountLogo = ({ marketAccountName }: { marketAccountName: string }) => {
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccount = marketAccountsByName.get(marketAccountName);

  if (!marketAccount) {
    return null;
  }

  return (
    <HStack gap="spacingDefault">
      <MarketLogo market={marketAccount.Market} size={24} />
      <Text>{marketAccount?.DisplayName ?? marketAccountName}</Text>
    </HStack>
  );
};
