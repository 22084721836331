import type { CSSProperties } from 'react';
import styled from 'styled-components';

import { Table, Tbody, Td, Thead } from '@talos/kyoko';

export const MarketDisplayName = styled(Td)`
  min-width: 80px;
`;

interface WrapperProps {
  width?: CSSProperties['width'];
}
export const Wrapper = styled(Table)<WrapperProps>`
  ${({ theme, width }) => `
  ${(width && `width: ${width};`) || null}

  && {
    font-size: ${theme.fontSizeDefault}rem;
    font-weight: ${({ theme }) => theme.fontWeightRegular};
  }

  ${Thead} th,
  ${Tbody} td {
    padding: ${theme.spacingSmall}px 0;
    white-space: nowrap;
  }
  ${Tbody} ${MarketDisplayName} {
    padding: ${theme.spacingSmall}px;
    white-space: nowrap;
  }

  ${Thead} th:first-child,
  ${Tbody} td:first-child {
    padding-left: ${theme.spacingSmall}px;
    text-align: left;
    color: ${theme.colorTextDefault};
  }

  ${Tbody} tr:not(first-child) {
    border-top: ${theme.borderColorDivider};
  }
`}
`;
