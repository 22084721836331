import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { StringSelectItem } from '@talos/kyoko';
import {
  ProductTypeEnum,
  SelectorField,
  SyntheticProductTypeEnum,
  isExpired,
  isNumericText,
  logger,
  type MarketDataSnapshot,
  type MarketDataStatistics,
  type SelectItem,
} from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import type { AppState } from 'providers/AppStateProvider/types';
import type { MultilegTabData } from '../../providers/MarketTabs.types';
import {
  Butterfly,
  CalendarSpread,
  Future,
  OPTION_SIDES,
  Option,
  Perp,
  Spot,
  Straddle,
  Strangle,
  VerticalSpread,
  type OptionStrategy,
} from '../OMS/NewOrder/models';
import { initialRefDataState } from '../OMS/OMSReferenceDataSlice';
import type { OMSReferenceDataState } from '../OMS/types';
import { MultilegComboType, OptionStrategies, isMultilegOptionComboType } from './enums';
import { LegsContainer, type Leg } from './models';
import type {
  CustomLegRow,
  GroupableSelectItem,
  MultilegComboState,
  MultilegPrimePayload,
  OptionStrategyRow,
} from './types';

const supportedInstruments: GroupableSelectItem<MultilegComboType>[] = [
  {
    value: MultilegComboType.Delta1Spread,
    label: 'Delta 1',
    group: 'Multileg Type',
  },
  {
    value: MultilegComboType.SyntheticCross,
    label: 'Synthetic Cross',
    group: 'Multileg Type',
  },
  {
    value: MultilegComboType.OptionsCalendarSpread,
    label: 'Options Calendar Spread',
    group: 'Options Structures',
  },
  {
    value: MultilegComboType.OptionsVerticalSpread,
    label: 'Options Vertical Spread',
    group: 'Options Structures',
  },
];

export const multilegComboState: MultilegComboState = {
  referenceData: initialRefDataState,
  instrumentField: new SelectorField({
    availableItems: supportedInstruments,
    value: supportedInstruments[0],
    isDisabled: true,
    idProperty: 'value',
  }),
  isLoading: false,
  isEditing: false,
  resolvedSymbol: undefined,
  statistics: [],
  marketData: [],
  editableProperties: {},
  compactOrderBook: true,
};

const handleOptionStrategyChanged = (
  state: WritableDraft<MultilegComboState>,
  optionStrategyType: OptionStrategies | undefined
) => {
  const existing = state.optionStrategy;

  switch (optionStrategyType) {
    case OptionStrategies.Strangle:
      state.optionStrategy = Strangle.createFromBlank(state.referenceData);
      break;
    case OptionStrategies.Straddle:
      state.optionStrategy = Straddle.createFromBlank(state.referenceData);
      break;
    case OptionStrategies.VerticalSpread:
      state.optionStrategy = VerticalSpread.createFromBlank(state.referenceData);
      break;
    case OptionStrategies.CalendarSpread:
      state.optionStrategy = CalendarSpread.createFromBlank(state.referenceData);
      break;
    case OptionStrategies.Butterfly:
      state.optionStrategy = Butterfly.createFromBlank(state.referenceData);
      break;
    default:
      state.optionStrategy = undefined;
  }

  // Re-apply coin, exchange and market account fields if previously selected
  if (existing && state.optionStrategy) {
    state.optionStrategy = state.optionStrategy.updateCoin(existing.legs[0].data.coinField.value);
    state.optionStrategy = state.optionStrategy.updateExchange(existing.legs[0].data.exchangeField.value);
    state.optionStrategy.legs.forEach((_, index) => {
      const currentMarketAccount = existing.legs[index]?.data.marketAccountField.value;
      if (currentMarketAccount) {
        const newMarketAccount = state.optionStrategy!.legs[index].data.marketAccountField.availableItems.find(
          m => m.value === currentMarketAccount[0]?.value
        );
        state.optionStrategy = state.optionStrategy!.updateMarketAccount(
          newMarketAccount ? [newMarketAccount] : [],
          index
        );
      }
    });
  }
};

const hydrateOptionState = (
  state: WritableDraft<MultilegComboState>,
  multilegInstrument?: MultilegComboType,
  initiatingLegs?: boolean[],
  resolvedSymbol?: string,
  syntheticProductType?: SyntheticProductTypeEnum
) => {
  multilegComboSlice.caseReducers.updateInstrument(state, {
    type: multilegComboSlice.actions.updateInstrument.type,
    payload: supportedInstruments.find(x => x.value === multilegInstrument)!,
  });

  if (multilegInstrument) {
    const optionStrategyType =
      multilegInstrument === MultilegComboType.OptionsCalendarSpread
        ? OptionStrategies.CalendarSpread
        : OptionStrategies.VerticalSpread;
    handleOptionStrategyChanged(state, optionStrategyType);
    // Switch to 'Puts' if needed
    if (
      syntheticProductType === SyntheticProductTypeEnum.PutSpread ||
      syntheticProductType === SyntheticProductTypeEnum.PutCalendarSpread
    ) {
      state.optionStrategy = state.optionStrategy?.updateType(OPTION_SIDES[1], 0).updateType(OPTION_SIDES[1], 1);
    }
  } else {
    handleOptionStrategyChanged(state, undefined);
  }

  if (initiatingLegs && state.optionStrategy) {
    state.optionStrategy = state.optionStrategy.setInitiatingLegs(initiatingLegs);
  }

  if (resolvedSymbol && state.optionStrategy) {
    const security = state.referenceData.securities.securitiesBySymbol.get(resolvedSymbol);
    if (!security || security.EndTime) {
      return;
    }

    const resolvedOptions: Option[] = (security?.MultilegDetails?.Legs || [])
      .map(leg => state.referenceData.securities.securitiesBySymbol.get(leg.Symbol))
      .filter(x => !!x)
      .map((leg, index) => {
        const desiredMarketAccount = security.MultilegDetails?.Legs?.[index].Markets?.[0]?.MarketAccount;
        const option = Option.createFromSecurity(state.referenceData, leg!);
        const marketAccount = option.data.marketAccountField.availableItems.find(
          (s: StringSelectItem) => s.value === desiredMarketAccount
        );
        return option.updateMarketAccount(marketAccount ? [marketAccount] : []);
      });

    const resolvedInitiating: boolean[] = security.MultilegDetails!.Parameters.LegParams.map(p => p.Initiating);
    state.optionStrategy = state.optionStrategy.setLegs(resolvedOptions).setInitiatingLegs(resolvedInitiating);

    state.resolvedSymbol = resolvedSymbol;
    state.instrumentField = state.instrumentField.setDisabled(true);
  }
};

const hydrateCustomLegsState = (
  state: WritableDraft<MultilegComboState>,
  instrumentType: MultilegComboType,
  resolvedSymbol?: string
) => {
  multilegComboSlice.caseReducers.updateInstrument(state, {
    type: multilegComboSlice.actions.updateInstrument.type,
    payload: supportedInstruments.find(x => x.value === instrumentType)!,
  });

  if (resolvedSymbol && state.customLegs) {
    const security = state.referenceData.securities.securitiesBySymbol.get(resolvedSymbol);
    if (!security || security.EndTime) {
      return;
    }

    const resolvedLegs: Leg[] = (security.MultilegDetails?.Legs || [])
      .map(leg => state.referenceData.securities.securitiesBySymbol.get(leg.Symbol))
      .filter(sec => !!sec && !isExpired(sec))
      .map((leg, index) => {
        let newLeg: Leg;
        try {
          switch (leg!.ProductType) {
            case ProductTypeEnum.Option:
              newLeg = Option.createFromSecurity(state.referenceData, leg!);
              break;
            case ProductTypeEnum.PerpetualSwap:
              newLeg = Perp.createFromSecurity(state.referenceData, leg!);
              break;
            case ProductTypeEnum.Future:
              newLeg = Future.createFromSecurity(state.referenceData, leg!);
              break;
            case ProductTypeEnum.Spot:
              newLeg = Spot.createFromSecurity(state.referenceData, leg!);
              break;
            default:
              newLeg = undefined;
          }
        } catch (error) {
          // we still can get exception from createFromSecurity if the security is not valid
          logger.error(error as Error);
        }
        const marketAccount = security.MultilegDetails?.Legs?.[index].Markets?.map(m => m.MarketAccount) || [];
        const marketAccountItems = marketAccount.map(marketAccountName => {
          return {
            value: marketAccountName,
            label:
              state.referenceData.marketAccounts.marketAccountsByName.get(marketAccountName)?.DisplayName ??
              marketAccountName,
          };
        });
        if (newLeg) {
          return newLeg.updateMarketAccount(marketAccountItems);
        }
        return undefined;
      });

    const resolvedInitiating: boolean[] = security.MultilegDetails!.Parameters.LegParams.map(p => p.Initiating);
    state.customLegs = state.customLegs.setLegs(resolvedLegs).setInitiatingLegs(resolvedInitiating);

    state.resolvedSymbol = resolvedSymbol;
    state.instrumentField = state.instrumentField.setDisabled(true);
  }
};

const applyInitialState = (state: WritableDraft<MultilegComboState>) => {
  state.customLegs = new LegsContainer(
    state.referenceData,
    [Spot.createFromBlank(state.referenceData), undefined],
    [true, true]
  );
  multilegComboSlice.caseReducers.updateInstrument(state, {
    type: multilegComboSlice.actions.updateInstrument.type,
    payload: state.instrumentField.value!,
  });
  state.resolvedSymbol = undefined;
  state.instrumentField = state.instrumentField.setDisabled(false);
  if (!state.referenceData.settings.enableOptionTrading) {
    state.instrumentField = state.instrumentField.updateAvailableItems(
      supportedInstruments.filter(
        x => x.value !== MultilegComboType.OptionsCalendarSpread && x.value !== MultilegComboType.OptionsVerticalSpread
      )
    );
  }
};

export const multilegComboSlice = createSlice({
  name: 'multilegCombo',
  initialState: multilegComboState,
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = action.payload;
      if (action.payload?.options?.expirationByMarketByCurrencyIdentity.size && !state.customLegs) {
        applyInitialState(state);
      }
    },
    updateInstrument: (state, action: PayloadAction<SelectItem<MultilegComboType>>) => {
      state.resolvedSymbol = undefined;
      state.isEditing = false;

      state.instrumentField = state.instrumentField.updateValue(action.payload);
      if (state.customLegs) {
        state.customLegs = state.customLegs?.updateInstrumentType(action.payload?.value);
      }

      if (action.payload?.value === MultilegComboType.Delta1Spread && state.customLegs) {
        const areBothLegsSpot = state.customLegs.legs.every(leg => leg instanceof Spot);
        if (areBothLegsSpot) {
          state.customLegs = state.customLegs.setLegs([
            state.customLegs.legs[0] as Leg,
            Future.createFromBlank(state.referenceData),
          ]);
        }
        return;
      }
      if (action.payload?.value === MultilegComboType.SyntheticCross && state.customLegs) {
        const validLegs = state.customLegs.legs.map(leg => {
          if (leg instanceof Spot) {
            return leg;
          }
          return Spot.createFromBlank(state.referenceData);
        });
        state.customLegs = state.customLegs.setLegs(validLegs);
        return;
      }

      const optionStrategyType =
        action.payload?.value === MultilegComboType.OptionsCalendarSpread
          ? OptionStrategies.CalendarSpread
          : OptionStrategies.VerticalSpread;
      handleOptionStrategyChanged(state, optionStrategyType);
    },
    updateOptionStrategy: (
      state,
      action: PayloadAction<{
        optionStrategy: OptionStrategy;
        isEditableParam?: boolean;
      }>
    ) => {
      state.optionStrategy = action.payload.optionStrategy;
      if (!action.payload.isEditableParam) {
        // if we change anything that isn't editable while in edit mode, we exist and switch to new creation mode
        state.isEditing = false;
        state.resolvedSymbol = undefined;
        state.editableProperties = {};
      }
    },
    updateCustomLegs: (
      state,
      action: PayloadAction<{
        customLegs: LegsContainer;
        isEditableParam?: boolean;
      }>
    ) => {
      state.customLegs = action.payload.customLegs;
      if (!action.payload.isEditableParam) {
        // if we change anything that isn't editable while in edit mode, we exist and switch to new creation mode
        state.isEditing = false;
        state.resolvedSymbol = undefined;
        state.editableProperties = {};
      }

      if (state.customLegs.instrumentType === MultilegComboType.Delta1Spread) {
        const leg1Security = state.customLegs.legs[0]?.security;
        const leg2Security = state.customLegs.legs[1]?.security;
        const mismatch = leg1Security?.BaseCurrency !== leg2Security?.BaseCurrency;
        if (leg1Security && leg2Security && mismatch) {
          state.customLegs = state.customLegs.updateSymbol(undefined, 1);
        }
      }
      if (state.customLegs.instrumentType === MultilegComboType.SyntheticCross) {
        const leg1Security = state.customLegs.legs[0]?.security;
        const leg2Security = state.customLegs.legs[1]?.security;
        const mismatchBase =
          leg1Security?.BaseCurrency !== leg2Security?.BaseCurrency &&
          leg1Security?.BaseCurrency !== leg2Security?.QuoteCurrency;
        const mismatchQuote =
          leg1Security?.QuoteCurrency !== leg2Security?.BaseCurrency &&
          leg1Security?.QuoteCurrency !== leg2Security?.QuoteCurrency;
        if (leg1Security && leg2Security && mismatchBase && mismatchQuote) {
          state.customLegs = state.customLegs.updateSymbol(undefined, 1);
        }
      }
    },
    updateStatistics: (state, action: PayloadAction<MarketDataStatistics>) => {
      const symbol = action.payload?.Symbol;
      let index;

      if (
        state.instrumentField.value?.value === MultilegComboType.OptionsCalendarSpread ||
        state.instrumentField.value?.value === MultilegComboType.OptionsVerticalSpread
      ) {
        index = state.optionStrategy?.legs.findIndex(leg => leg.security?.Symbol === symbol) ?? -1;
      } else {
        index = state.customLegs?.legs.findIndex(leg => leg?.security?.Symbol === symbol);
      }

      if (index !== -1) {
        state.statistics[index] = action.payload;
      }
    },
    updateMarketData: (state, action: PayloadAction<MarketDataSnapshot>) => {
      const symbol = action.payload?.Symbol;
      let index;

      if (
        state.instrumentField.value?.value === MultilegComboType.OptionsCalendarSpread ||
        state.instrumentField.value?.value === MultilegComboType.OptionsVerticalSpread
      ) {
        index = state.optionStrategy?.legs.findIndex(leg => leg.security?.Symbol === symbol) ?? -1;
      } else {
        index = state.customLegs?.legs.findIndex(leg => leg?.security?.Symbol === symbol);
      }

      if (index !== -1) {
        state.marketData[index] = action.payload;
      }
    },
    hydrateMultilegState: (state, { payload }: PayloadAction<MultilegTabData & { tabID: string }>) => {
      if (state.tabID !== payload?.tabID) {
        state.tabID = payload.tabID;
        applyInitialState(state);
      }

      const { instrumentType, initiatingLegs, resolvedSymbol, compactOrderBook } = payload;

      state.compactOrderBook = compactOrderBook ?? true;

      switch (instrumentType) {
        case MultilegComboType.OptionsCalendarSpread:
        case MultilegComboType.OptionsVerticalSpread:
          hydrateOptionState(state, instrumentType, initiatingLegs, resolvedSymbol);
          break;
        case MultilegComboType.Delta1Spread:
          hydrateCustomLegsState(state, MultilegComboType.Delta1Spread, resolvedSymbol);
          break;
        case MultilegComboType.SyntheticCross:
          hydrateCustomLegsState(state, MultilegComboType.SyntheticCross, resolvedSymbol);
          break;
      }
    },
    // main difference between hydrate and prime is the argument type and also persisting to tab state when we prime
    // also in latest spec we always hydrate fully valid securities (else we don't persist) whereas prime can either prime a valid
    // security or it primes a new blank screen to create the multileg
    primeMultilegState: (
      state,
      { payload: { multilegType, security, syntheticProductType } }: PayloadAction<MultilegPrimePayload>
    ) => {
      const instrumentType = multilegType;

      if (!security) {
        applyInitialState(state);
        // we manually clear the below 3 properties because we otherwise carry it over when switching strategies; however in this case we want a blank state
        state.optionStrategy = state.optionStrategy
          ?.updateCoin(undefined)
          .updateExchange(undefined)
          .updateMarketAccount(undefined);
      }

      if (instrumentType === MultilegComboType.SyntheticCross) {
        hydrateCustomLegsState(state, MultilegComboType.SyntheticCross, security?.Symbol);
      } else if (instrumentType === MultilegComboType.Delta1Spread) {
        hydrateCustomLegsState(state, MultilegComboType.Delta1Spread, security?.Symbol);
      } else {
        hydrateOptionState(state, instrumentType, undefined, security?.Symbol, syntheticProductType);
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsEditing: (state, action: PayloadAction<boolean | undefined>) => {
      state.isEditing = action.payload;
      state.editableProperties = {};
      if (action.payload || action.payload === undefined) {
        state.instrumentField = state.instrumentField.setDisabled(false);
        return;
      }
      state.instrumentField = state.instrumentField.setDisabled(true);
      if (isMultilegOptionComboType(state.instrumentField.value?.value)) {
        // revert back to resolvedSymbol state
        hydrateOptionState(
          state,
          state.instrumentField.value?.value as MultilegComboType,
          undefined,
          state.resolvedSymbol
        );
        return;
      }
      hydrateCustomLegsState(state, state.instrumentField.value!.value, state.resolvedSymbol);
    },
    setResolvedSymbol: (state, action: PayloadAction<string>) => {
      state.resolvedSymbol = action.payload;
      if (!action.payload) {
        applyInitialState(state);
      }
    },
    updateEditableProperty: (state, action: PayloadAction<{ key: string; value: string }>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      if (key === 'minPriceIncrement' || key === 'minSizeIncrement' || key === 'maxMarketDataDepth') {
        // if not numeric then don't update
        if (isNumericText(value)) {
          state.editableProperties[key] = value;
        }
      } else {
        state.editableProperties[key] = value;
      }
    },
    setCompactOrderBook: (state, action: PayloadAction<boolean>) => {
      state.compactOrderBook = action.payload;
    },
    clear: state => {
      applyInitialState(state);
    },
  },
});

export const {
  setReferenceData,
  updateInstrument,
  updateOptionStrategy,
  updateCustomLegs,
  updateStatistics,
  updateMarketData,
  hydrateMultilegState,
  primeMultilegState,
  setIsLoading,
  setIsEditing,
  setResolvedSymbol,
  updateEditableProperty,
  setCompactOrderBook,
  clear,
} = multilegComboSlice.actions;

export const selectIsReferenceDataReady = createSelector(
  (state: AppState) => state.multilegCombo.referenceData.options.expirationByMarketByCurrencyIdentity,
  expirationByMarketByCurrencyIdentity => expirationByMarketByCurrencyIdentity.size > 0
);

export const selectIsEntryMode = createSelector(
  (state: AppState) => state.multilegCombo.isEditing,
  (state: AppState) => state.multilegCombo.isLoading,
  (state: AppState) => state.multilegCombo.resolvedSymbol,
  (isEditing, isLoading, resolvedSymbol) => {
    if (isLoading) {
      return false;
    }
    if (isEditing) {
      return true;
    }
    return !resolvedSymbol;
  }
);

export const selectOptionStrategy = createSelector(
  (state: AppState) => state.multilegCombo.optionStrategy,
  (optionStrategy?: OptionStrategy) => optionStrategy
);

export const selectCustomLegs = createSelector(
  (state: AppState) => state.multilegCombo.customLegs,
  (customLegs?: LegsContainer) => customLegs
);

export const selectResolvedSecurities = createSelector(
  selectCustomLegs,
  selectOptionStrategy,
  (state: AppState) => state.multilegCombo.instrumentField.value?.value,
  (customLegs?: LegsContainer, optionStrategy?: OptionStrategy, instrument?: MultilegComboType) => {
    if (
      instrument === MultilegComboType.OptionsCalendarSpread ||
      instrument === MultilegComboType.OptionsVerticalSpread
    ) {
      return (optionStrategy?.legs || []).map(leg => leg.security);
    }

    return (customLegs?.legs || []).map(l => l?.security);
  }
);

export const selectIsValid = createSelector(
  selectResolvedSecurities,
  selectCustomLegs,
  selectOptionStrategy,
  (state: AppState) => state.multilegCombo.instrumentField.value?.value,
  (securities, customLegs?: LegsContainer, optionStrategy?: OptionStrategy, instrument?: MultilegComboType) => {
    if (
      instrument === MultilegComboType.OptionsCalendarSpread ||
      instrument === MultilegComboType.OptionsVerticalSpread
    ) {
      const hasMarketAccounts = optionStrategy?.legs.every(leg => leg.data.marketAccountField.value.length > 0);

      return !optionStrategy || !securities?.length
        ? false
        : hasMarketAccounts && securities.filter(x => !!x).length === optionStrategy?.legs.length;
    } else {
      if (!customLegs) {
        return false;
      }

      const hasRequiredMarketAccounts = customLegs.legs.every(leg => {
        if (LegsContainer.isLegSpot(leg)) {
          return true;
        }
        return (leg?.data.marketAccountField.value.length || 0) > 0;
      });

      return hasRequiredMarketAccounts && securities.filter(x => !!x).length === customLegs?.legs.length;
    }
  }
);

export const selectOptionStrategyAsRows = createSelector(
  selectResolvedSecurities,
  (state: AppState) => state.multilegCombo.statistics,
  (state: AppState) => state.multilegCombo.marketData,
  selectOptionStrategy,
  (securities, statistics, marketData, optionStrategy?: OptionStrategy): OptionStrategyRow[] => {
    if (!optionStrategy) {
      return [];
    }

    return optionStrategy.legs.map((leg, index) => {
      const symbol = securities[index]?.Symbol;

      // When we change the security we don't want to display previous security info
      const hasUpToDateStatistics = statistics[index]?.Symbol === symbol;
      const hasUpToDateMarketData = marketData[index]?.Symbol === symbol;

      return {
        id: (index + 1).toString(),
        leg: (index + 1).toString(),
        coin: leg.data.coinField.value,
        exchange: leg.data.exchangeField.value,
        marketAccount: leg.data.marketAccountField.value,
        strike: leg.data.strikeField.value,
        type: leg.data.typeField.value,
        expiryDate: leg.data.expiryField.value,
        ratio: optionStrategy.ratios[index],
        initiating: optionStrategy.initiatingLegs[index],
        underlyingPrice: hasUpToDateStatistics ? statistics[index]?.UnderlyingPrice : undefined,
        quoteCurrency: securities[index]?.QuoteCurrency,
        underlyingQuoteCurrency: securities[index]?.UnderlyingQuoteCurrency,
        currency: securities[index]?.BaseCurrency,
        bid: hasUpToDateMarketData ? marketData[index]?.Bids?.[0]?.Price : undefined,
        offer: hasUpToDateMarketData ? marketData[index]?.Offers?.[0]?.Price : undefined,
        mark: {
          MarkPriceIV: hasUpToDateStatistics ? statistics[index]?.MarkPriceIV : undefined,
          MarkPrice: hasUpToDateStatistics ? statistics[index]?.MarkPrice : undefined,
        },
        delta: hasUpToDateStatistics ? statistics[index]?.Delta : undefined,
        gamma: hasUpToDateStatistics ? statistics[index]?.Gamma : undefined,
        vega: hasUpToDateStatistics ? statistics[index]?.Vega : undefined,
        theta: hasUpToDateStatistics ? statistics[index]?.Theta : undefined,
      };
    });
  }
);

export const selectIsOptionPayoffAvailable = createSelector(
  selectIsValid,
  selectOptionStrategyAsRows,
  (isValid, rows) => {
    if (!isValid) {
      return false;
    }
    return rows.length && rows.every(row => row.bid && row.offer && row.underlyingPrice);
  }
);

export const selectCustomLegsAsRows = createSelector(
  selectResolvedSecurities,
  (state: AppState) => state.multilegCombo.statistics,
  (state: AppState) => state.multilegCombo.marketData,
  (state: AppState) => state.multilegCombo.customLegs,
  (securities, statistics, marketData, customLegs?: LegsContainer): CustomLegRow[] => {
    if (!customLegs) {
      return [];
    }

    return customLegs.legs.map((leg, index) => {
      const symbol = securities[index]?.Symbol;

      // When we change the security we don't want to display previous security info
      const hasUpToDateStatistics = statistics[index]?.Symbol === symbol;
      const hasUpToDateMarketData = marketData[index]?.Symbol === symbol;

      return {
        id: (index + 1).toString(),
        leg: (index + 1).toString(),
        productType: customLegs.getProductTypeForLeg(index) ?? '',
        symbol: customLegs.getSymbolForLeg(index),
        exchange: customLegs.getExchangeForLeg(index),
        markets: customLegs.getMarketAccountsForLeg(index),
        expiry: customLegs.getExpiryForLeg(index),
        strike: customLegs.getStrikeForLeg(index),
        type: customLegs.getTypeForLeg(index),
        initiating: customLegs.initiatingLegs[index],
        ratio: index === 0 ? 1 : -1,
        underlyingPrice: hasUpToDateStatistics ? statistics[index]?.UnderlyingPrice : undefined,
        quoteCurrency: securities[index]?.QuoteCurrency,
        underlyingQuoteCurrency: securities[index]?.UnderlyingQuoteCurrency,
        currency: securities[index]?.BaseCurrency,
        bid: hasUpToDateMarketData ? marketData[index]?.Bids?.[0]?.Price : undefined,
        offer: hasUpToDateMarketData ? marketData[index]?.Offers?.[0]?.Price : undefined,
        mark: {
          MarkPriceIV: hasUpToDateStatistics ? statistics[index]?.MarkPriceIV : undefined,
          MarkPrice: hasUpToDateStatistics ? statistics[index]?.MarkPrice : undefined,
        },
        delta: hasUpToDateStatistics ? statistics[index]?.Delta : undefined,
        gamma: hasUpToDateStatistics ? statistics[index]?.Gamma : undefined,
        vega: hasUpToDateStatistics ? statistics[index]?.Vega : undefined,
        theta: hasUpToDateStatistics ? statistics[index]?.Theta : undefined,
      };
    });
  }
);
