import { useConstant, wsStitchWith, type MinimalSubscriptionResponse, type Order } from '@talos/kyoko';
import { useEffect, useMemo } from 'react';
import { ReplaySubject, map, type Observable } from 'rxjs';
import { BulkOrdStatusEnum, type BulkOrdStatusEnumValues } from '../../../../../hooks/useBulkOrderSubmissionSending';
import { useOrders } from '../../../../../providers';
import type { ClosablePosition } from '../useGetBulkClosablePositions';
import type { BulkClosePositionEntity } from './types';

/**
 * Given an array of `ClosablePositions`, will build an output observable stream to be used by the BulkClosePositionBlotter.
 * The given array of ClosablePositions can happily change.
 *
 * The output observable is a merger of SyntheticOrder and Order, wrapped into a BulkClosePositionEntity. As orders are placed by the job runner, this hook will pick them up
 * and emit the new order instead of the synthetic order. The consuming will be indexing its data based on ClOrdId making this a replacement.
 */
export const useBulkClosePositionBlotterObs = (
  closablePositions: ClosablePosition[],
  clOrdIdStates: Map<string, BulkOrdStatusEnumValues>
): Observable<MinimalSubscriptionResponse<BulkClosePositionEntity>> => {
  const syntheticOrdersSubject = useConstant(new ReplaySubject<MinimalSubscriptionResponse<BulkClosePositionEntity>>());

  // This useEffect builds base data from the closablePositions and clOrdId states of these args and pushes this data
  // into the root of the output observable.
  useEffect(() => {
    const data: BulkClosePositionEntity[] = closablePositions.map(pos =>
      buildSyntheticBulkClosePositionEntity(pos, clOrdIdStates)
    );

    syntheticOrdersSubject.next({
      initial: true,
      data,
    });
  }, [clOrdIdStates, closablePositions, syntheticOrdersSubject]);

  const { recentOrders } = useOrders();

  const recentOrdersSubscriptionResponses: Observable<MinimalSubscriptionResponse<Order>> = useMemo(
    // Need to map the incoming absolute array of orders into a SubscriptionResponse message for compatability with wsStitchWith
    () => recentOrders.pipe(map(orders => ({ initial: true, data: orders }))),
    [recentOrders]
  );

  const combinedOrdersObs: Observable<MinimalSubscriptionResponse<BulkClosePositionEntity>> = useMemo(() => {
    return syntheticOrdersSubject.pipe(
      // New usage I figured out with wsStitchWith. Instead of actually stitching anything I just swap out the synthetic order
      // for the real order when we get it. Pretty neat.
      wsStitchWith({
        secondarySource: recentOrdersSubscriptionResponses.pipe(
          map(message => ({ ...message, data: message.data.map(buildRealBulkClosePositionEntity) }))
        ),
        getPrimaryTypeKey: o => o.order.ClOrdID,
        getSecondaryTypeKey: o => o.order.ClOrdID,
        stitch: (syntheticEntity, realEntity) => {
          return realEntity ? realEntity : syntheticEntity;
        },
      })
    );
    // two stable inputs here for reference
  }, [syntheticOrdersSubject, recentOrdersSubscriptionResponses]);

  return combinedOrdersObs;
};

/**
 * Given a ClosablePosition entity, together with a bulk order submission state mapping, builds BulkClosePosition entities.
 * These entities are then used by the blotter. In the blotter, both synthetic orders (to be submitted) and real orders co-exist.
 *
 * The synthetic order within the entity is a representation of what the order should look like for closing the respective position.
 * As our closing functionality becomes more complex (eg more strategies with params), this mapping will also become more complex.
 *
 * @param closablePosition The ClosablePosition entity to build into a BulkClosePositionEntity for viewing in the blotter
 * @param clOrdIdsStates The Bulk Order Submission states, grabbed from `useBulkOrderSubmissionSending`
 * @returns a BulkClosePositionEntity for rendering in the BulkClosePosition blotter
 */
export function buildSyntheticBulkClosePositionEntity(
  closablePosition: ClosablePosition,
  clOrdIdsStates?: Map<string, BulkOrdStatusEnumValues>
): BulkClosePositionEntity {
  return {
    order: {
      Symbol: closablePosition.orderArgs.symbol,
      Side: closablePosition.orderArgs.side,
      OrderQty: closablePosition.orderArgs.orderQty,
      Price: closablePosition.orderArgs.price,
      Parameters: closablePosition.orderArgs.parameters,
      ClOrdID: closablePosition.orderArgs.clOrdID,
      Currency: closablePosition.orderArgs.orderCurrency,
      Strategy: closablePosition.orderArgs.selectedStrategy?.Name,

      // overrides specific to the synthetic order.
      tradedMarketAccounts: closablePosition.orderArgs.marketAccounts,
      OrdStatus: clOrdIdsStates?.get(closablePosition.orderArgs.clOrdID) ?? BulkOrdStatusEnum.PendingSubmission,
    },
    rowID: closablePosition.orderArgs.clOrdID,
    editable: true,
    ...closablePosition,
  };
}

export function buildRealBulkClosePositionEntity(order: Order): BulkClosePositionEntity {
  return {
    order,
    editable: false,
    rowID: order.ClOrdID,
  };
}
