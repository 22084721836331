import { Icon, IconName, type IconProps } from '@talos/kyoko';

type DDHIconProps = Omit<IconProps, 'icon' | 'color'>;

export const DDHIcon = (props: DDHIconProps) => {
  return <Icon icon={IconName.DeltaStacked} color="colors.blue.lighten" {...props} />;
};

export const HedgeOrderIcon = (props: DDHIconProps) => {
  return <Icon icon={IconName.DeltaSingle} color="colors.blue.lighten" {...props} />;
};
