import { useTransition } from '@react-spring/web';
import Big from 'big.js';
import type { ReactNode } from 'react';
import { getFractions } from '../../utils/relativeSize';
import { Bar, BarPart, Container, Headers, SectionContainer, SectionHeader, SectionWrapper } from './styles';

export interface DistributionPart {
  id: string;
  value: Big;
  color: string;
}

export interface DistributionNamedSection {
  id: string;
  header: ReactNode;
  parts: DistributionPart[];
}

export interface DistributionBarProps {
  sections: DistributionNamedSection[];
  roundLeft?: boolean;
  roundRight?: boolean;
  direction?: 'ltr' | 'rtl';
}

export const DistributionBar = ({
  sections,
  roundLeft = true,
  roundRight = true,
  direction = 'ltr',
}: DistributionBarProps) => {
  // compute the size of each section
  const sectionSizes = getFractions(
    sections.map(section => section.parts.reduce((prev, curr) => prev.plus(curr.value), Big(0)))
  );

  // Assume here that the color of a part is unique, which does make sense!
  const transitions = useTransition(sections, {
    keys: section => section.id,
    initial: (section, i) => ({ flexBasis: getWidth(i, sectionSizes) }),
    from: { flexBasis: '0%' },
    enter: (section, i) => ({ flexBasis: getWidth(i, sectionSizes) }),
    update: (section, i) => ({ flexBasis: getWidth(i, sectionSizes) }),
    leave: { flexBasis: '0%' },
    config: {
      duration: 200,
    },
  });

  return (
    <Container>
      <Headers direction={direction}>
        {sections.map((section, i) => {
          const sectionWidth = getWidth(i, sectionSizes);
          return (
            <SectionWrapper key={i} style={{ flexBasis: sectionWidth, alignSelf: 'flex-end' }}>
              <SectionHeader> {section.header} </SectionHeader>
            </SectionWrapper>
          );
        })}
      </Headers>
      <Bar roundLeft={roundLeft} roundRight={roundRight} direction={direction}>
        {transitions((transition, item) => (
          <SectionWrapper style={{ ...transition }}>
            <BarSection parts={item.parts} />
          </SectionWrapper>
        ))}
      </Bar>
    </Container>
  );
};

function BarSection({ parts }: { parts: DistributionPart[] }) {
  const partSizes = getFractions(parts.map(part => part.value));

  const transitions = useTransition(parts, {
    keys: part => part.id,
    initial: (part, i) => ({ width: getWidth(i, partSizes) }),
    from: { width: '0%' },
    enter: (part, i) => ({ width: getWidth(i, partSizes) }),
    update: (part, i) => ({ width: getWidth(i, partSizes) }),
    leave: { width: '0%' },
    config: {
      duration: 200,
    },
  });

  return (
    <SectionContainer>
      {transitions((transition, item) => (
        <BarPart
          style={{
            background: item.color,
            ...transition,
          }}
        />
      ))}
    </SectionContainer>
  );
}

const getWidth = (i: number, widths: number[] | undefined) => {
  if (!widths) {
    return '100%';
  }
  // We always want to round up such that there are no super small gaps after rounding
  // widths to one decimal point.
  return Math.ceil(widths[i] * 100 * 10) / 10 + '%';
};
