import {
  getInitPortfolioViewState,
  type PortfolioViewLayoutState,
} from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { createContext, useContext } from 'react';
import type { AppConfigState } from './AppConfigProvider/AppConfigProvider.types';

export interface PortfolioSettingsContextValue {
  enablePMSLayout: boolean;
  setEnablePMSLayout: (enablePMS: boolean) => AppConfigState;
  treatStablecoinsAsCash: boolean;
  setTreatStablecoinsAsCash: (treatStablecoinsAsCash: boolean) => AppConfigState;
  portfolioViewState: PortfolioViewLayoutState['viewState'];
  /** Update View State in App Config Storage */
  setPortfolioViewState: (viewState: PortfolioViewLayoutState['viewState']) => AppConfigState;
}

export const DefaultPortfolioSettingsConfig: PortfolioSettingsContextValue = {
  /** early impl has the name 'enablePMS', has been renamed to allow to be true by default */
  enablePMSLayout: true,
  setEnablePMSLayout: () => ({} as AppConfigState),
  treatStablecoinsAsCash: true,
  setTreatStablecoinsAsCash: () => ({} as AppConfigState),
  portfolioViewState: getInitPortfolioViewState({}),
  setPortfolioViewState: () => ({} as AppConfigState),
};

export const PortfolioSettingsContext = createContext<PortfolioSettingsContextValue | undefined>(undefined);
PortfolioSettingsContext.displayName = 'PortfolioSettingsContext';

export function usePortfolioSettings() {
  const context = useContext(PortfolioSettingsContext);
  if (context === undefined) {
    throw new Error('Missing PortfolioSettingsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/** Best check to determine if the Portfolio Mamangement System layout is active */
export function useIsPMSActive() {
  const { enablePMSLayout } = usePortfolioSettings();
  const { enablePortfolioManagementSystem } = useFeatureFlag();
  return enablePMSLayout && enablePortfolioManagementSystem;
}
