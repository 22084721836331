import {
  AppwideDrawerContentType,
  MixpanelEventSource,
  MixpanelSourceProvider,
  useAppwideDrawerContext,
  useDynamicCallback,
} from '@talos/kyoko';
import type { SettlementOption } from '../../useTradeSettlementRequests';
import { CustomerSettlementDrawerContent } from './CustomerSettlementDrawerContent';

export const useCustomerSettlementDrawer = () => {
  const { openDrawer, closeDrawer } = useAppwideDrawerContext();

  const openCustomerSettlementDrawer = useDynamicCallback((settlementOption: SettlementOption) => {
    openDrawer({
      type: AppwideDrawerContentType.CustomerSettlement,
      title: 'Trade Settlement',
      width: 640,
      renderContent: () => (
        <MixpanelSourceProvider value={MixpanelEventSource.CustomerSettlementDrawer}>
          <CustomerSettlementDrawerContent settlementOption={settlementOption} close={closeDrawer} />
        </MixpanelSourceProvider>
      ),
    });
  });

  return { openCustomerSettlementDrawer };
};
