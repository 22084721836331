import type { User } from '@talos/kyoko';
import { createContext, useContext } from 'react';

export type UsersContextState = {
  isLoaded: boolean;
  users: User[];
  userByUserName: Map<string, User>;
  userByID: Map<string, User>;
  refetchUsers: (includeDeleted?: boolean) => Promise<void>;
};
export const UsersContext = createContext<UsersContextState | undefined>(undefined);
UsersContext.displayName = 'UsersContext';
export const useUsersContext = (): UsersContextState => {
  const context = useContext(UsersContext);
  if (context == null) {
    throw new Error('Missing CustomerContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
