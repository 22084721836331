import { animated, useTransition } from '@react-spring/web';
import { useCallback, useEffect, useState } from 'react';

import {
  ACTION,
  ButtonVariants,
  Icon,
  IconName,
  Input,
  MixpanelEvent,
  MixpanelEventProperty,
  NavButton,
  PanelActions,
  PanelHeader,
  useMixpanel,
  useUserContext,
  type MarketCredential,
} from '@talos/kyoko';
import { Loader, LoaderWrapper } from 'components/Loader';
import { useRoleAuth } from 'hooks';
import { sortBy } from 'lodash';
import { useMarketsContext } from 'providers';
import { MarketCredentialItem } from './MarketCredentialItem';
import { useResponsiveSize } from './hooks';
import { MarketsWrapper, Panel } from './styles';

export { EditMarketCredential } from './EditMarketCredential';
export { NewMarketCredential } from './NewMarketCredential';

const HIDE_ITEM_TRANSITION = {
  from: { height: 0, opacity: 0, overflow: 'hidden' },
  enter: { height: 60, opacity: 1, overflow: 'hidden' },
  leave: { height: 0, opacity: 0, overflow: 'hidden' },
  config: { duration: 200 },
};

export const Markets = () => {
  const { user, listMarketCredentials } = useUserContext();
  const { marketsByName } = useMarketsContext();
  const mixpanel = useMixpanel();
  const [marketCredentials, setMarketCredentials] = useState<MarketCredential[]>([]);
  const [filteredCredentials, setFilteredCredentials] = useState<MarketCredential[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const { isAuthorized } = useRoleAuth();

  const canEditCredential = isAuthorized(ACTION.EDIT_MARKET_SETTINGS);

  useEffect(() => {
    if (user != null) {
      listMarketCredentials().then(credentials => {
        const sortedCreds = sortBy(credentials, item => item.Label);
        setMarketCredentials(sortedCreds);
        setFilteredCredentials(sortedCreds);
      });
    }
  }, [user, listMarketCredentials]);

  const handleFilterChange = useCallback(
    e => {
      const value = e.target.value;
      setFilterValue(value);
      mixpanel.track(MixpanelEvent.FilterQuickSearch, {
        [MixpanelEventProperty.Source]: 'Credentials',
      });
      setFilteredCredentials(sortAndFilterCredentials(marketCredentials, value));
    },
    [marketCredentials, mixpanel]
  );

  const resetFilter = useCallback(() => {
    setFilterValue('');
    mixpanel.track(MixpanelEvent.FilterQuickSearch, {
      [MixpanelEventProperty.Source]: 'Credentials',
    });
    setFilteredCredentials(marketCredentials);
  }, [marketCredentials, mixpanel]);

  const dataReady = marketCredentials != null;

  const itemTransitions = useTransition(dataReady ? filteredCredentials : [], {
    ...HIDE_ITEM_TRANSITION,
    keys: filteredCredentials.map(item => item.CredentialID),
  });

  const formControlSize = useResponsiveSize();

  return (
    <Panel alignItems="initial" justifyContent="initial">
      <PanelHeader>
        <h2>Credentials</h2>
        <PanelActions>
          <Input
            placeholder="Filter list"
            width="240px"
            onChange={handleFilterChange}
            value={filterValue}
            size={formControlSize}
            suffix={<Icon onClick={resetFilter} icon={filterValue === '' ? IconName.Filter : IconName.Close} />}
          />
          <NavButton
            to="/settings/credentials/new"
            size={formControlSize}
            variant={ButtonVariants.Positive}
            disabled={!canEditCredential}
          >
            Add Credential
          </NavButton>
        </PanelActions>
      </PanelHeader>
      <MarketsWrapper>
        {dataReady ? (
          itemTransitions((style, marketCredential) => {
            const market = marketsByName.get(marketCredential.Market);
            return (
              market != null && (
                <animated.div style={style} key={marketCredential.CredentialID}>
                  <MarketCredentialItem
                    market={market}
                    marketCredential={marketCredential}
                    formControlSize={formControlSize}
                    canEditCredential={canEditCredential}
                  />
                </animated.div>
              )
            );
          })
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </MarketsWrapper>
    </Panel>
  );
};

function sortAndFilterCredentials(marketCredentials: MarketCredential[], filter: string) {
  return marketCredentials.filter(mc => {
    return (
      mc.Label.toLowerCase().includes(filter.toLowerCase()) || mc.Market.toLowerCase().includes(filter.toLowerCase())
    );
  });
}
