import type { Order } from '@talos/kyoko';

export enum BulkModifyOrderStatusEnum {
  Pending = 'Pending',
  Error = 'Error',
  Success = 'Success',
}

export interface BulkModifyOrderModel {
  newOrderId: string;
  newEndTime?: Date | string | null;
  status?: BulkModifyOrderStatusEnum;
  error?: string;
  orderData: Order;
}
