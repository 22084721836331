import { BALANCE_DELTA, removeEmptyFilters, useObservable, useSubscription, wsScanToDoubleMap } from '@talos/kyoko';
import { useEffect, useMemo, useState } from 'react';
import { map, filter as rxjsFilter, scan, shareReplay } from 'rxjs/operators';

import { isEmpty, isEqual } from 'lodash';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { Balance, type BalancesFilter, type BalancesRequest, type IBalance } from '../types/Balance';

interface UseBalancesParams {
  /** The filter to apply to the BalanceDelta stream */
  filter: BalancesFilter;
  /** The tag to be placed on the stream for debugging purposes */
  tag: string;
  /** Whether or not the backend should return balances where Balance.Amount is 0, defaults to false */
  showZeroBalances?: boolean;
  /** Throttle to place on the backend, defaults to 500ms */
  throttle?: string;
  /**
   * Whether or not to open the stream if the provided filter is empty. Must be set.
   *
   * If true, this means that we allow the case where we open a stream without filters, thus potentially receiving all Balances.
   * This can have performance implications if there are a lot of accounts, and a lot of currencies.
   *
   * If false, this means that an empty filter being provided results in the stream staying closed / being closed.
   * Whenever the filter is updated to be non-empty, the stream will be opened again.
   */
  openStreamWithoutFilter: boolean;
}

/**
 * The useBalances hook opens up a new BalanceDelta stream for each invocation of the hook. As in, there is no underlying
 * BalancesProvider which shares data between all invocations of the useBalances hook.
 */
export const useBalances = ({
  filter,
  tag,
  showZeroBalances = false,
  throttle = '500ms',
  openStreamWithoutFilter,
}: UseBalancesParams) => {
  const { homeCurrency } = useDisplaySettings();
  const [wsRequest, setWsRequest] = useState<BalancesRequest | null>(null);

  const { data, push } = useSubscription<IBalance>(wsRequest, { loadAll: true });
  const balancesObs = useMemo(
    () => data.pipe(map(json => ({ ...json, data: json.data.map(b => new Balance(b)) }))),
    [data]
  );

  useEffect(() => {
    setWsRequest(currentRequest => {
      const cleanedFilter = removeEmptyFilters(filter);

      // Close the subscription (set wsRequest to null) if the filter is null and thats not allowed
      if (isEmpty(cleanedFilter) && !openStreamWithoutFilter) {
        // If the current request is _not_ null, as in we probably have received some data,
        // and now were going to stop the stream, then we push an empty message to clear all downstream data sets
        // so we dont leave behind any stale data
        if (currentRequest != null) {
          push({ initial: true, ts: '', type: BALANCE_DELTA, data: [] });
        }
        return null;
      }

      const maybeNewRequest = {
        name: BALANCE_DELTA,
        tag: tag,
        Throttle: throttle,
        EquivalentCurrency: homeCurrency,
        ShowZeroBalances: showZeroBalances,
        ...cleanedFilter,
      };

      if (!isEqual(currentRequest, maybeNewRequest)) {
        return maybeNewRequest;
      }

      return currentRequest;
    });
  }, [homeCurrency, showZeroBalances, tag, filter, throttle, openStreamWithoutFilter, push]);

  const balancesByCurrencyMarketAccountIDObs = useObservable(
    () =>
      balancesObs.pipe(
        wsScanToDoubleMap({
          getKey1: b => b.Currency,
          getKey2: b => b.MarketAccountID,
          // Please discuss with wider team if the below newInner/OuterMapEachUpdate: true needs changing to false
          newInnerMapsEachUpdate: true,
          newOuterMapEachUpdate: true,
          deleteInnerMapOnEmptied: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        })
      ),
    [balancesObs]
  );

  const balancesByMarketAccountIDCurrencyObs = useObservable(
    () =>
      balancesObs.pipe(
        wsScanToDoubleMap({
          getKey1: b => b.MarketAccountID,
          getKey2: b => b.Currency,
          newInnerMapsEachUpdate: true,
          newOuterMapEachUpdate: true,
          deleteInnerMapOnEmptied: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        })
      ),
    [balancesObs]
  );

  const currenciesWithBalanceObs = useObservable(
    () =>
      balancesObs.pipe(
        scan(
          ({ currencies, changed }, json) => {
            changed = false;
            if (json.initial) {
              currencies.clear();
            }

            for (const balance of json.data) {
              if (!currencies.has(balance.Currency)) {
                currencies.add(balance.Currency);
                changed = true;
              }
            }

            return { currencies, changed };
          },
          { currencies: new Set<string>(), changed: false }
        ),
        rxjsFilter(({ changed }) => changed), // only forward messages if anything changes
        map(({ currencies }) => [...currencies]), // only forward the currencies, and that as an array
        shareReplay({
          bufferSize: 1,
          refCount: false,
        })
      ),
    [balancesObs]
  );

  return {
    balancesByCurrencyMarketAccountIDObs,
    balancesByMarketAccountIDCurrencyObs,
    currenciesWithBalanceObs,
  };
};
