import {
  Button,
  EmptyView,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  MarketOrder,
  MixpanelEvent,
  OrdStatusEnum,
  OrderWarnings,
  Popover,
  WarningSeverity,
  scanWsResponseToArray,
  useMarketsContext,
  useMixpanel,
  useObservable,
  useObservableValue,
  usePopoverState,
  useSubscription,
  type Order,
  type OrderWarningItem,
} from '@talos/kyoko';
import { differenceInMinutes, formatDistanceToNowStrict } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { scan, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OPEN_ORDER_STATUSES } from '../../../../providers/constants';

export function Warnings({ order }: { order: Order }) {
  const mixpanel = useMixpanel();
  const handlePopoverOpen = useCallback(() => {
    mixpanel.track(MixpanelEvent.HoverWarningIcon);
  }, [mixpanel]);
  const popover = usePopoverState({
    trigger: 'hover',
    closeOnClickOutside: true,
    onOpen: handlePopoverOpen,
  });
  const { close: closePopover } = popover;

  const { marketsByName } = useMarketsContext();
  const marketOrdersObservable = useMarketOrders({ parentOrderID: order.OrderID, ordStatus: OPEN_ORDER_STATUSES });
  const marketOrders = useObservableValue(
    () =>
      marketOrdersObservable.pipe(
        scan((memo, marketOrders) => {
          for (const marketOrder of marketOrders) {
            memo.set(marketOrder.OID!, marketOrder);
          }
          return new Map(memo);
        }, new Map<string, MarketOrder>()),
        map(marketOrders => [...marketOrders.values()])
      ),
    [marketOrdersObservable],
    []
  );
  const { url: matchUrl } = useRouteMatch()!;

  const warning = useMemo(() => {
    let orderWarning = order.warning;

    for (const marketOrder of marketOrders) {
      // Check if market order has been pending for >= 1 minute
      if (
        [OrdStatusEnum.PendingNew, OrdStatusEnum.PendingReplace, OrdStatusEnum.PendingCancel].includes(
          marketOrder.OrdStatus!
        ) &&
        differenceInMinutes(new Date(), new Date(marketOrder.SubmitTime!)) >= 1
      ) {
        const warning: OrderWarningItem = {
          label: 'Pending child orders',
          severity: WarningSeverity.HIGH,
          value: (
            <HStack justifyContent="space-between" gap="spacingDefault">
              Child order {marketOrder.OID} stuck pending on{' '}
              {marketsByName?.get(marketOrder.Market!)?.DisplayName ?? marketOrder.Market} for about{' '}
              {formatDistanceToNowStrict(new Date(marketOrder.SubmitTime!), {
                unit: 'minute',
              })}
              <Button
                forwardedAs={Link}
                to={`${matchUrl}/market-orders?q=${marketOrder.OID}`}
                size={FormControlSizes.Small}
                onClick={closePopover}
              >
                Show
              </Button>
            </HStack>
          ),
        };
        if (orderWarning == null) {
          orderWarning = {
            severity: WarningSeverity.MEDIUM,
            topLevelWarnings: [],
            warningItems: [],
          };
        }
        orderWarning.topLevelWarnings.push(warning);
      }
    }
    return orderWarning;
  }, [order.warning, marketOrders, marketsByName, matchUrl, closePopover]);

  return (
    <>
      <Popover {...popover}>
        <Button disabled={warning == null}>
          {warning?.topLevelWarnings.length ?? 0}{' '}
          <Icon
            icon={IconName.ExclamationSolid}
            color={warning?.topLevelWarnings.length ? 'colors.yellow.default' : undefined}
            spaceBefore="spacingSmall"
          />
        </Button>
        {warning == null ? <EmptyView>No errors</EmptyView> : <OrderWarnings warning={warning} />}
      </Popover>
    </>
  );
}

function useMarketOrders({
  parentOrderID,
  ordStatus,
}: {
  parentOrderID?: string;
  ordStatus?: OrdStatusEnum[];
}): Observable<MarketOrder[]> {
  const [request, setRequest] = useState<any>(null);
  useEffect(() => {
    if ([parentOrderID].every(id => id == null)) {
      setRequest(null);
    } else {
      setRequest({
        name: 'MarketOrder',
        ...(parentOrderID != null && { ParentOrderID: parentOrderID }),
        ...(ordStatus != null && { OrdStatus: ordStatus }),
      });
    }
  }, [parentOrderID, ordStatus]);
  const { data: subscription } = useSubscription<MarketOrder>(request, { loadAll: true });
  return useObservable<MarketOrder[]>(
    () => subscription.pipe(scanWsResponseToArray(d => new MarketOrder(d))),
    [subscription]
  );
}
