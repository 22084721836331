import type { LedgerAccountTypeEnum } from '@talos/kyoko';
import { generatePath } from 'react-router-dom';
import type { OrderDetailsPath, OrderRouteType } from '../Trading/Markets/OrderDetails/types';

export const getDeepDiveRoute = (symbol?: string, id?: string) =>
  `/trading/symbol/${symbol ? symbol : ':symbol'}/${id ? id : ':id'}`;

export const getReconDetailsRoute = (params: { mismatchIDs: string[] }) => {
  const base = getBaseRoute('trading');
  return generatePath(`${base}/recon-mismatches/details?mismatchIDs=${params.mismatchIDs.join(',')}`);
};

export const getAccountLedgerEventsDetailsRoute = (params: {
  asset: string;
  account: string;
  accountType: LedgerAccountTypeEnum;
}) => {
  const base = getBaseRoute('trading');
  return `${base}/account-ledger-events?accountType=${params.accountType}&account=${params.account}&asset=${params.asset}`;
};

export const getOrderDetailsRoute = getOrderDetailsRouteFn('trading');
export const getMonitoringOrderDetailsRoute = getOrderDetailsRouteFn('monitoring');
export const getDealerMonitoringOrderDetailsRoute = getOrderDetailsRouteFn('dealerMonitoring');

function getOrderDetailsRouteFn(
  context: 'trading' | 'monitoring' | 'dealerMonitoring'
): (params?: { orderID: string; type: OrderRouteType; tab?: OrderDetailsPath }) => string {
  const base = getBaseRoute(context);
  return params => {
    const orderType = params?.type === 'customer' ? 'customer-order' : 'order';
    return generatePath(`${base}/${orderType}/:orderID${params?.tab == null ? '' : `/${params.tab}`}`, params);
  };
}

function getBaseRoute(context: 'trading' | 'monitoring' | 'dealerMonitoring') {
  if (context === 'monitoring') {
    return '/monitoring';
  }
  if (context === 'dealerMonitoring') {
    return '/dealer/monitoring';
  }
  return '/trading';
}

export const getAnalyticsOrderDetailsRoute = (params?: { orderID: string; tab?: OrderDetailsPath }) =>
  generatePath(`/analytics/order/:orderID${params?.tab == null ? '' : `/${params.tab}`}`, params);
export const getMarketRoute = (id?: string) => `/trading/${id ? id : ''}`;

export const getDataFromDeepDiveRoute = (pathname: string) => {
  // ['', 'trading', 'symbol', :symbol, :id]
  const [, , , symbol, id] = pathname.split('/');
  return [symbol, id];
};

export const getOpenTabFromOrderRoute = (pathname: string) => {
  // ['', 'trading', 'order', :id, :tab]
  const [, , , , openTab] = pathname.split('/');
  return openTab as OrderDetailsPath;
};

export const getIdFromOrderRoute = (pathname: string) => {
  // ['', 'trading', 'order', :id]
  const [, , , id] = pathname.split('/');
  return id;
};

export const getIdFromMarketRoute = (pathname: string) => {
  // ['', 'trading', :id]
  const [, , id] = pathname.split('/');
  return id;
};

export function getOpenTabFromMonitoringRoute(pathname: string) {
  // ['', 'monitoring', 'blotters', :id, :tab]
  const [, , , , openTab] = pathname.split('/');
  return openTab;
}

export function getIdFromMonitoringRoute(pathname: string) {
  // ['', 'monitoring', 'blotters', :id]
  const [, , , id] = pathname.split('/');
  return id;
}

export function getIdFromAnalyticsRoute(pathname: string) {
  // ['', 'analytics', :id]
  // ['', 'analytics', 'order', ':orderId']
  const parts = pathname.split('/');
  if (parts[2] === 'order') {
    return parts[3];
  }
  return parts[2];
}

export const isCurrentRouteOrderDetails = (pathname: string) =>
  pathname?.startsWith('/trading/order') || pathname?.startsWith('/trading/customer-order');
export const isCurrentRouteCustomerOrderDetails = (pathname: string) => pathname?.includes('/customer-order');
export const isCurrentRouteDeepDive = (pathname: string) => pathname?.startsWith('/trading/symbol');
export const isCurrentRouteReconDetails = (pathname: string) =>
  pathname.startsWith('/trading/recon-mismatches/details');
export const isCurrentRouteAccountLedgerEventsDetails = (pathname: string) =>
  pathname.startsWith('/trading/account-ledger-events');
export const isCurrentRouteMarket = (pathname: string) =>
  pathname.startsWith('/trading') && !isCurrentRouteOrderDetails(pathname) && !isCurrentRouteDeepDive(pathname);
export const isCurrentRouteSettings = (pathname: string) => pathname?.startsWith('/settings');

export const isCurrentRoutePortfolioEngineering = (pathname: string) => pathname?.startsWith('/portfolio-engineering');

export function isCurrentRouteMonitoringBlotter(pathname: string) {
  return pathname?.startsWith('/monitoring/blotters');
}

export const isCurrentRouteDealerMonitoringOrderDetails = (pathname: string) =>
  pathname?.startsWith('/dealer/monitoring/order') || pathname?.startsWith('/dealer/monitoring/customer-order');
export const isCurrentRouteMonitoringOrderDetails = (pathname: string) =>
  pathname?.startsWith('/monitoring/order') || pathname?.startsWith('/monitoring/customer-order');

export function isCurrentRouteAnalytics(pathname: string) {
  return pathname?.startsWith('/analytics') && !isCurrentRouteAnalyticsOrderDetails(pathname);
}

export const isCurrentRouteAnalyticsOrderDetails = (pathname: string) => pathname?.startsWith('/analytics/order');

export function isCurrentRouteDealer(pathname: string) {
  return pathname?.startsWith('/dealer');
}
