import { Menu, Portal } from '@talos/kyoko';
import { CommonHeaderMenuItems } from '../components/HeaderMenu/CommonHeaderMenuItems';
import { MenuDockView } from '../components/HeaderMenu/MenuDockView';
import { getKeyForUserLayout } from '../stateManagement/portfolioViewLayoutSlice';
import { usePortfolioViewRouteStateSelector } from '../stateManagement/portfolioViewLayoutSlice.hooks';
import { usePortfolioRouting } from '../usePortfolioRouting';

export const PMS_HEADER_MENU_PORTAL_ID = 'pms-header-menu-portal';

export const PortfolioWorkspaceHeaderMenuPortal = () => {
  const { selectedView, selectedTab } = usePortfolioRouting();
  const { userLayoutMapForViewTab } = usePortfolioViewRouteStateSelector();

  const headerMenuOptionLength = selectedTab.activeControls?.menuOptions.length ?? 0;

  switch (selectedTab.layoutType) {
    case 'fixed':
      return (
        <Portal portalId={PMS_HEADER_MENU_PORTAL_ID}>
          {headerMenuOptionLength > 0 ? (
            <Menu data-testid="portfolio-dashboard-header-menu" triggerRound={false}>
              <CommonHeaderMenuItems />
            </Menu>
          ) : null}
        </Portal>
      );
    case 'flexible':
      return (
        <Portal portalId={PMS_HEADER_MENU_PORTAL_ID}>
          <Menu data-testid="portfolio-dashboard-header-menu" triggerRound={false}>
            <CommonHeaderMenuItems />
            <MenuDockView
              getLayoutForCurrentView={() => {
                const viewTabKey = getKeyForUserLayout(selectedView.value, selectedTab.label);
                return userLayoutMapForViewTab[viewTabKey];
              }}
            />
          </Menu>
        </Portal>
      );
  }
};
