import { get, sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { asyncScheduler } from 'rxjs';
import { map, shareReplay, throttleTime } from 'rxjs/operators';
import { MarketsContext } from '../contexts';
import { useObservable, useObservableRef, useObservableValue, useStaticSubscription } from '../hooks';
import { wsPickFromMemoryStream } from '../pipes';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { wsSubscriptionMemory } from '../pipes/wsSubscriptionMemory';
import { ConnectionType } from '../types/ConnectionType';
import type { Market } from '../types/Market';
import { ConnectionStatusEnum, type MarketTypeEnum } from '../types/types';

const MARKET = 'Market';
const MAIN_DEBOUNCE_MS = 10000;

export const MarketsProvider = function MarketsProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: subscription } = useStaticSubscription<Market>({ name: MARKET, tag: 'MarketsProvider' });

  const subscriptionMemory = useObservable(() => subscription.pipe(wsSubscriptionMemory(m => m.Name)), [subscription]);

  const marketsByNameObs = useObservable(
    () =>
      subscriptionMemory.pipe(
        map(({ memory }) => memory),
        throttleTime(MAIN_DEBOUNCE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscriptionMemory]
  );

  const marketsByNameRef = useObservableRef(() => marketsByNameObs, [marketsByNameObs], new Map<string, Market>());

  // This list is already sorted by DisplayName, no need to sort again unless you rearrange the order of the elements
  const marketsListObs = useObservable(
    () =>
      marketsByNameObs.pipe(
        map(memo => [...memo.values()]),
        map(marketsList => sortBy(marketsList, 'DisplayName')),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketsByNameObs]
  );

  const marketsByName = useObservableValue(
    () => marketsByNameObs.pipe(map(memo => new Map(memo))), // For future reference: This memo => new Map(memo) is here to trigger change detection
    [marketsByNameObs],
    new Map<string, Market>()
  );

  const marketDisplayNameByName = useObservableValue(
    () =>
      subscriptionMemory.pipe(
        wsPickFromMemoryStream({
          getUniqueKey: m => m.Name,
          pick: market => ({ Name: market.Name, DisplayName: market.DisplayName }),
        }),
        wsScanToMap({ getUniqueKey: m => m.Name, getInsertable: item => item.DisplayName, newMapEachUpdate: true })
      ),
    [subscriptionMemory],
    new Map<string, string>()
  );

  const marketTypeByName = useObservableValue(
    () =>
      subscriptionMemory.pipe(
        wsPickFromMemoryStream({
          getUniqueKey: m => m.Name,
          pick: market => ({ Name: market.Name, Type: market.Type }),
        }),
        wsScanToMap({ getUniqueKey: m => m.Name, getInsertable: item => item.Type, newMapEachUpdate: true })
      ),
    [subscriptionMemory],
    new Map<string, MarketTypeEnum>()
  );

  const marketsList = useObservableValue(() => marketsListObs, [marketsListObs], []);

  useEffect(() => {
    if (!isLoaded && marketsList.length > 0) {
      setIsLoaded(true);
    }
  }, [isLoaded, marketsList]);

  const status = useCallback(
    (m: Market | string, connectionType: ConnectionType) => {
      const name = typeof m === 'string' ? m : m.Name;
      const market = marketsByNameRef.current.get(name);
      return market != null && get(market, `${connectionType}.Status`);
    },
    [marketsByNameRef]
  );

  const isMarketSupported = useCallback(
    (m: Market | string, connectionType: ConnectionType) => {
      return status(m, connectionType) != null;
    },
    [status]
  );

  const isMarketConfigured = useCallback(
    (m: Market | string, connectionType: ConnectionType) => {
      return isMarketSupported(m, connectionType) && status(m, connectionType) !== ConnectionStatusEnum.Unavailable;
    },
    [status, isMarketSupported]
  );

  const isMarketOnline = useCallback(
    (m: Market | string, connectionType: ConnectionType) => {
      return status(m, connectionType) === ConnectionStatusEnum.Online;
    },
    [status]
  );

  const isMarketOffline = useCallback(
    (m: Market | string, connectionType: ConnectionType) => {
      return status(m, connectionType) === ConnectionStatusEnum.Offline;
    },
    [status]
  );

  const marketsWithConnTypesConfigured = useCallback(
    (connectionTypes: ConnectionType[]) =>
      marketsListObs.pipe(
        map(markets => markets?.filter(m => connectionTypes.some(t => isMarketConfigured(m, t)))),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketsListObs, isMarketConfigured]
  );

  const marketsWithConnTypesSupported = useCallback(
    (connectionTypes: ConnectionType[] = Object.values(ConnectionType)) =>
      marketsListObs.pipe(
        map(markets => markets?.filter(m => connectionTypes.some((t: ConnectionType) => isMarketSupported(m, t)))),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketsListObs, isMarketSupported]
  );

  return (
    <MarketsContext.Provider
      value={{
        isLoaded,
        marketsByName,
        marketDisplayNameByName,
        marketTypeByName,
        marketsList,
        marketsWithConnTypesConfigured,
        marketsWithConnTypesSupported,
        isMarketSupported,
        isMarketConfigured,
        isMarketOnline,
        isMarketOffline,
      }}
    >
      {children}
    </MarketsContext.Provider>
  );
};

// Static functions which dont need access to live data from the markets provider itself
export function isMarketSupported(m: Market, connectionType: ConnectionType): boolean {
  return get(m, `${connectionType}.Status`) != null;
}

// Replacing the isMarketConfigured from above but as a pure function
export function isMarketConfigured(m: Market, connectionType: ConnectionType): boolean {
  const status = get(m, `${connectionType}.Status`);
  return status != null && status !== ConnectionStatusEnum.Unavailable;
}

export function isMarketOnline(m: Market, connectionType: ConnectionType): boolean {
  const status = get(m, `${connectionType}.Status`);
  return status != null && status === ConnectionStatusEnum.Online;
}
