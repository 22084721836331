import { map, startWith, type Observable } from 'rxjs';

interface WsResponse<T> {
  data: T[];
  initial?: boolean;
}

/**
 * This pipe emits a list of the newly received items upon reception
 * @param createEntity Factory function to map each entity into another type (e.g. Object -> Order)
 * @returns
 */
export function mapWsResponseToArray<TInput, TOutput>(
  createEntity: (data: TInput) => TOutput
): (source: Observable<WsResponse<TInput>>) => Observable<TOutput[]> {
  return (source: Observable<WsResponse<TInput>>) =>
    source.pipe(
      map(json => json.data.map(createEntity)),
      startWith([] as TOutput[])
    );
}
