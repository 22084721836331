import {
  CurrencyIcon,
  Divider,
  ExposureLimitSideTypeEnum,
  HStack,
  InlineFormattedNumber,
  MarketAccountTypeEnum,
  NumberVariants,
  Text,
  VStack,
  getOppositeExposureSide,
  useMarketAccountsContext,
  type CreditBlotterExposure,
  type IUniformExposure,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useCustomersByName } from '../../../hooks/useCustomer';

interface CreditUsageTooltipProps {
  data: CreditBlotterExposure;
}

export function CreditUsageTooltip({ data }: CreditUsageTooltipProps) {
  const { marketAccountsByName } = useMarketAccountsContext();
  const customersByName = useCustomersByName();

  const marketAccountName = data.effectiveSide?.MarketAccount;

  // Conditional support for rendering the proper display name for customer market accounts if thats what we are provided
  const marketAccountDisplayName = useMemo(() => {
    const marketAccount = marketAccountName ? marketAccountsByName.get(marketAccountName) : undefined;

    if (marketAccount?.Type === MarketAccountTypeEnum.Customer) {
      const counterparty = marketAccount ? customersByName?.get(marketAccount.Counterparty) : undefined;
      const counterpartyDisplayName = counterparty?.DisplayName ?? marketAccount.Counterparty ?? 'Unknown Counterparty';
      const marketAccountDisplayName = marketAccount.SourceAccountID ?? marketAccountName ?? 'Unknown Account';
      return `${counterpartyDisplayName} - ${marketAccountDisplayName}`;
    }

    return marketAccount?.DisplayName ?? marketAccountName ?? 'Unknown Account';
  }, [marketAccountName, marketAccountsByName, customersByName]);

  const effectiveExposureSide = data.effectiveSide;
  if (!effectiveExposureSide) {
    return <></>;
  }

  return (
    <VStack minWidth="350px" gap="spacingSmall" data-testid="credit-usage-tooltip-content">
      {/* The first row here, kinda like the header */}
      <HStack
        w="100%"
        justifyContent="space-between"
        color="colorTextImportant"
        fontSize="fontSizeMd"
        data-testid="effective-limit-top-row"
      >
        <Text fontWeight="fontWeightMedium">{marketAccountDisplayName}</Text>
        {effectiveExposureSide.currency ? (
          <HStack gap="spacingSmall">
            <CurrencyIcon currency={effectiveExposureSide.currency} />
            <Text>{effectiveExposureSide.currency}</Text>
          </HStack>
        ) : (
          <Text>{effectiveExposureSide.ExposureDefinition}</Text>
        )}
      </HStack>

      {/* Middle row */}
      <HStack w="100%" justifyContent="space-between" fontSize="fontSizeMd" data-testid="effective-limit-middle-row">
        <LimitText input={effectiveExposureSide} highlightSentiment />
        <LimitBreakdown effectiveExposureSide={effectiveExposureSide} />
      </HStack>

      {/* Third row, showing usage and remainder of limit */}
      <HStack w="100%" justifyContent="space-between" data-testid="effective-limit-bottom-row">
        <Text>
          <InlineFormattedNumber
            currency="%"
            increment="0.01"
            number={effectiveExposureSide.usage?.usage?.times(100)}
            round
          />{' '}
          of limit
        </Text>
        <Text>
          Remaining{' '}
          <InlineFormattedNumber
            number={effectiveExposureSide.usage?.remainder}
            currency={effectiveExposureSide.usage?.currency}
            align="right"
          />
        </Text>
      </HStack>

      {/* Last optional row, showing the potential other side (not in use) limit */}
      <NonEffectiveLimit data={data} />
    </VStack>
  );
}

function LimitText({ input, highlightSentiment }: { input: IUniformExposure; highlightSentiment?: boolean }) {
  const theme = useTheme();
  const prefix: string = input.ExposureSide === ExposureLimitSideTypeEnum.Both ? '' : input.ExposureSide + ' ';
  const textColor =
    input.ExposureSide === ExposureLimitSideTypeEnum.Both || !highlightSentiment
      ? theme.colorTextDefault
      : input.ExposureSide === ExposureLimitSideTypeEnum.Long
      ? theme.colors.green.lighten
      : theme.colors.red.lighten;

  return <Text color={textColor}>{prefix}Limit</Text>;
}

function LimitBreakdown({
  effectiveExposureSide,
}: {
  effectiveExposureSide: NonNullable<CreditBlotterExposure['effectiveSide']>;
}) {
  if (effectiveExposureSide.limit == null) {
    return <></>;
  }

  const isEffectiveExposureShort = effectiveExposureSide.ExposureSide === ExposureLimitSideTypeEnum.Short;
  const exposureNumberVariant = isEffectiveExposureShort ? NumberVariants.Negative : undefined;

  return (
    <HStack gap="spacingSmall">
      <InlineFormattedNumber
        variant={exposureNumberVariant}
        number={effectiveExposureSide.exposure}
        currency={effectiveExposureSide.ExposureCurrency}
        align="right"
      />
      /
      <InlineFormattedNumber
        variant={NumberVariants.Muted}
        number={effectiveExposureSide.limit}
        currency={effectiveExposureSide.ExposureCurrency}
        align="right"
      />
    </HStack>
  );
}

function NonEffectiveLimit({ data }: { data: CreditBlotterExposure }) {
  const effectiveExposureSide = data.effectiveSide?.ExposureSide;
  const oppositeSide = effectiveExposureSide != null ? getOppositeExposureSide(effectiveExposureSide) : undefined;
  const oppositeSideInput = oppositeSide != null ? data.getExposureSide(oppositeSide) : undefined;

  if (!oppositeSideInput) {
    return <></>;
  }

  return (
    <>
      <Divider orientation="horizontal" />
      <HStack w="100%" justifyContent="space-between" data-testid="non-effective-limit-row">
        <LimitText input={oppositeSideInput} />
        <InlineFormattedNumber
          number={oppositeSideInput.limit}
          currency={oppositeSideInput.ExposureCurrency}
          variant={NumberVariants.Muted}
          align="right"
        />
      </HStack>
    </>
  );
}
