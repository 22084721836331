import type { GridOptions } from 'ag-grid-community';

/** Reasonable sorting defaults for Auto-Group Columns
 * (sort order restricted to asc/desc unless there's a particular
 * reason for hierarchical data to show as unsorted) */
export const autoGroupSortingDefaults: Pick<
  NonNullable<GridOptions['autoGroupColumnDef']>,
  'sort' | 'sortingOrder' | 'comparator'
> = {
  sortingOrder: ['asc', 'desc'],
  comparator: (a, b) => {
    // Safe sorting since a and b are any's
    if (a && !b) {
      return -1;
    }
    if (!a && b) {
      return 1;
    }
    if (!a && !b) {
      return 0;
    }
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b);
    }
    return a < b ? -1 : a > b ? 1 : 0;
  },
};
