import { OrderCardSummary as KyokoOrderCardSummary, type Order } from '@talos/kyoko';
import { prettifySubAccount, useSubAccounts } from 'providers';
import { useTradingSettings } from 'providers/AppConfigProvider';
import { prepareAllocationOrderForUI } from 'utils/order';
import { OMSCardTradeAllocations } from './OMSCardTradeAllocations';
import { DetailTable } from './styles';

type OrderCardSummaryProps = Omit<
  React.ComponentProps<typeof KyokoOrderCardSummary>,
  'subAccountDetails' | 'tableDetails'
> &
  Partial<Pick<Order, 'Group' | 'SubAccount'>>;

export const OrderCardSummary = (props: OrderCardSummaryProps) => {
  const { Currency = '', SubAccount = '-', Group = '-' } = props;

  const { subAccountAllocations, allocationValueType } = prepareAllocationOrderForUI(props);
  const { enableGroups } = useTradingSettings();
  const { allSubAccounts, subAccountsByName } = useSubAccounts();
  const enableSubAccounts = !!(allSubAccounts && allSubAccounts.length);

  return (
    <KyokoOrderCardSummary
      {...props}
      subAccountDetails={
        <>
          {(enableSubAccounts || enableGroups) && (
            <DetailTable>
              <tbody>
                <tr>
                  <td>{enableSubAccounts && subAccountAllocations?.length ? 'Sub Account(s)' : 'Sub Account'}</td>
                  <td>{enableGroups ? 'Group' : ''}</td>
                </tr>
                <tr>
                  <td>
                    {enableSubAccounts && !subAccountAllocations?.length
                      ? prettifySubAccount(SubAccount, subAccountsByName)
                      : ''}
                  </td>
                  <td>{enableGroups ? Group : ''}</td>
                </tr>
              </tbody>
            </DetailTable>
          )}
          {enableSubAccounts && subAccountAllocations?.length ? (
            <OMSCardTradeAllocations
              subAccountAllocations={subAccountAllocations}
              allocationValueType={allocationValueType}
              currency={Currency}
            />
          ) : null}
        </>
      }
    />
  );
};
