import { Box } from '@talos/kyoko';
import styled from 'styled-components';

/** Maintain blotter left-side indent for PMS styling
 * - the Blotters by default have a 8px left padding,
 * - this component adds a common 4px left-padding to maintain the common PMS 12px left padding alignment
 */
export const BlotterIndent = styled(Box)`
  padding-left: ${({ theme }) => theme.spacingSmall}px;
  background-color: ${({ theme }) => theme.backgroundContent};
`;
