import Big from 'big.js';
import type { MarketPositions, Position, TransferInstruction } from '../types';

export interface AmountEntry {
  wsAmount: string;
  isManuallySet: boolean;
  isOutgoing: boolean;
  checked: boolean;
  inputValue: string;
  currency: string;
}

export type PositionsFormState = Map<string, Map<string, AmountEntry>>;

export enum PositionsActionType {
  WSMarketPositionsChanged,
  HeaderCheckedChange,
  MarketAccountCheckedChange,
  TransferInstructionsDelivered,
  MarketAccountOnlyClicked,
  MarketAccountAllClicked,
  AmountClicked,
  AmountInputValueChange,
  AmountCheckedChange,
}

interface AmountInputValueChangeAction {
  type: PositionsActionType.AmountInputValueChange;
  payload: {
    marketAccount: string;
    currency: string;
    inputValue: string;
  };
}

interface AmountCheckedChangeAction {
  type: PositionsActionType.AmountCheckedChange;
  payload: {
    marketAccount: string;
    currency: string;
    checked: boolean;
  };
}

interface AmountClickedAction {
  type: PositionsActionType.AmountClicked;
  payload: {
    marketAccount: string;
    currency: string;
  };
}

interface MarketAccountOnlyClickedAction {
  type: PositionsActionType.MarketAccountOnlyClicked;
  payload: {
    marketAccount: string;
  };
}

interface MarketAccountAllClickedAction {
  type: PositionsActionType.MarketAccountAllClicked;
  payload: {
    marketAccount: string;
  };
}

interface TransferInstructionsDeliveredAction {
  type: PositionsActionType.TransferInstructionsDelivered;
  payload: {
    transferInstructions: TransferInstruction[];
  };
}

interface MarketAccountCheckedChangeAction {
  type: PositionsActionType.MarketAccountCheckedChange;
  payload: {
    marketAccount: string;
    checked: boolean;
  };
}

interface HeaderCheckedChangeAction {
  type: PositionsActionType.HeaderCheckedChange;
  payload: {
    checked: boolean;
  };
}

interface WSMarketPositionsChangedAction {
  type: PositionsActionType.WSMarketPositionsChanged;
  payload: {
    marketPositions: MarketPositions[];
  };
}

export type PositionsAction =
  | AmountInputValueChangeAction
  | AmountCheckedChangeAction
  | AmountClickedAction
  | TransferInstructionsDeliveredAction
  | MarketAccountCheckedChangeAction
  | HeaderCheckedChangeAction
  | MarketAccountOnlyClickedAction
  | MarketAccountAllClickedAction
  | WSMarketPositionsChangedAction;

// Map from MarketAccount to the positions within it
export const positionsFormReducer = (state: PositionsFormState, action: PositionsAction) => {
  switch (action.type) {
    case PositionsActionType.AmountClicked: {
      const payload = action.payload;
      const entry = state.get(payload.marketAccount)?.get(payload.currency);
      if (!entry) {
        return state;
      }

      const newEntry = {
        ...entry,
        checked: true,
        inputValue: Big(entry.wsAmount).abs().toFixed(),
        isManuallySet: false,
      };
      return newMapWithReplacedInternalEntry(state, payload.marketAccount, payload.currency, newEntry);
    }

    case PositionsActionType.AmountCheckedChange: {
      const payload = action.payload;
      const entry = state.get(payload.marketAccount)?.get(payload.currency);
      if (!entry) {
        return state;
      }

      const newChecked = payload.checked;
      const newEntry = handleEntryCheckedChange(entry, newChecked);
      return newMapWithReplacedInternalEntry(state, payload.marketAccount, payload.currency, newEntry);
    }

    case PositionsActionType.AmountInputValueChange: {
      const payload = action.payload;
      const entry = state.get(payload.marketAccount)?.get(payload.currency);
      if (!entry) {
        return state;
      }

      const newInputValue = payload.inputValue;

      // If the entry is unchecked and we start typing, we check it!
      const maybeNewChecked = entry.checked ? entry.checked : !entry.checked;
      return newMapWithReplacedInternalEntry(state, payload.marketAccount, payload.currency, {
        isManuallySet: true,
        inputValue: newInputValue,
        checked: maybeNewChecked,
      });
    }

    case PositionsActionType.MarketAccountCheckedChange: {
      const payload = action.payload;
      const marketAccountMap = state.get(payload.marketAccount);

      if (!marketAccountMap) {
        return state;
      } // shouldnt happen

      const newMarketAccountMap = handleMarketAccountCheckChange(marketAccountMap);
      return new Map([...state.entries()]).set(payload.marketAccount, newMarketAccountMap);
    }

    case PositionsActionType.MarketAccountOnlyClicked: {
      // Uncheck all others, thats all
      const selectedMarketAccount = action.payload.marketAccount;
      const newMap = new Map([...state.entries()]);
      newMap.forEach((mktAccMap, key) => {
        if (selectedMarketAccount !== key) {
          const newMktAccMap = handleMarketAccountCheckChange(mktAccMap, false);
          newMap.set(key, newMktAccMap);
        }
      });

      return newMap;
    }

    case PositionsActionType.MarketAccountAllClicked: {
      // Check all other outgoing entries, thats all
      const selectedMarketAccount = action.payload.marketAccount;
      const newMap = new Map([...state.entries()]);
      newMap.forEach((mktAccMap, key) => {
        if (selectedMarketAccount !== key) {
          const newMktAccMap = handleMarketAccountCheckChange(mktAccMap, true);
          newMap.set(key, newMktAccMap);
        }
      });

      return newMap;
    }

    case PositionsActionType.HeaderCheckedChange: {
      // We toggle all MarketAccountCheckboxes to either true or false.
      // Calculate which of the two states the next state should be, then iterate over all MarketAccounts and apply

      // If there is a single checked outgoing amount, next state is false, else true
      const someAmountEntryChecked = [...state.values()].some(mktAccMap =>
        [...mktAccMap.values()].some(amountEntry => amountEntry.checked)
      );
      const newCheckedState = !someAmountEntryChecked;

      // Iterate over all mktAccMaps and apply the new checked state
      const newMap = new Map([...state.entries()]);
      newMap.forEach((mktAccMap, key) => {
        const newMktAccMap = handleMarketAccountCheckChange(mktAccMap, newCheckedState);
        newMap.set(key, newMktAccMap);
      });

      return newMap;
    }

    case PositionsActionType.TransferInstructionsDelivered: {
      const payload = action.payload;

      const newMap = new Map([...state.entries()]);

      for (const instruction of payload.transferInstructions) {
        const marketAccountMap = newMap.get(instruction.MarketAccount);
        if (!marketAccountMap) {
          continue;
        }
        const newMarketAccountMap = new Map(marketAccountMap);
        const entry = newMarketAccountMap.get(instruction.Currency);
        if (entry) {
          newMarketAccountMap.set(instruction.Currency, {
            ...entry,
            checked: false,
            isManuallySet: false,
            inputValue: '',
          });

          newMap.set(instruction.MarketAccount, newMarketAccountMap);
        }
      }

      return newMap;
    }

    case PositionsActionType.WSMarketPositionsChanged: {
      // This action is started when we receive a WS update for the OTCPositions data
      // For every checked and not-manually-modified amount entry, we want to set the inputValue = OTCPosition.Amount
      // Semantically, if a user hasn't updated an inputValue manually, they want to settle the full OTCPosition.Amount so we keep this updated for them this way.
      const { marketPositions } = action.payload;
      const newMap = new Map([...state.entries()]);

      // For each Position, try to find the entry in the current state and update it
      // If there is no entry, then add a new one representing this Position
      marketPositions.forEach(mpos => {
        const maybeMktAccMap = newMap.get(mpos.MarketAccount);
        const newMktAccMap = new Map([...(maybeMktAccMap?.entries() ?? [])]);
        newMap.set(mpos.MarketAccount, newMktAccMap);

        mpos.Positions.forEach(pos => {
          const entry = newMktAccMap.get(pos.Currency);
          if (!entry) {
            // create a new entry here
            const newEntry = getInitialFormStateForAmount(pos);
            newMktAccMap.set(pos.Currency, newEntry);
          } else {
            // We are going to apply any changes to the entry here.
            // Note that a position can go from positive to negative.
            const isNewPositionOutgoing = Big(pos.Amount).lt(0);
            const hasPositionChangedDirection = isNewPositionOutgoing !== entry.isOutgoing;
            if (hasPositionChangedDirection) {
              if (entry.isManuallySet) {
                // the user has manually set some state in this entry before it switched direction
                // we must reset and uncheck the entry as a user's wish to settle an incoming amount is not at all
                // the same as a users wish to settle an outgoing amount for example.
                newMktAccMap.set(pos.Currency, {
                  ...entry,
                  checked: false,
                  inputValue: '',
                  isOutgoing: isNewPositionOutgoing,
                });
              } else {
                // no manual changes have been made, so just update the entry to be a default entry whichever direction it is
                newMktAccMap.set(pos.Currency, getInitialFormStateForAmount(pos));
              }
            } else {
              // Standard case behavior for when a position changes while staying either incoming or outgoing and not going between the two directions
              const shouldOverrideInputValue = !entry.isManuallySet && entry.checked && entry.isOutgoing;
              const maybeNewInputValue = shouldOverrideInputValue ? Big(pos.Amount).abs().toFixed() : entry.inputValue;
              newMktAccMap.set(pos.Currency, {
                ...entry,
                inputValue: maybeNewInputValue,
                wsAmount: pos.Amount,
              });
            }
          }
        });
      });

      // We also need to check if any positions are unaccounted for in the ws message
      // in the future when we move to delta update messages this wont be an issue
      const marketPositionsByMarketAccount = new Map(marketPositions.map(mpos => [mpos.MarketAccount, mpos.Positions]));
      newMap.forEach((mktAccMap, mktAcc) => {
        const newMktAccMap = new Map(mktAccMap.entries());
        mktAccMap.forEach(entry => {
          if (marketPositionsByMarketAccount.get(mktAcc)?.find(pos => pos.Currency === entry.currency) == null) {
            // there's a missing entry, this means that the Amount is 0 in the backend.
            if (entry.wsAmount !== '0') {
              // If our internal record is not zero, then this absence has not been handled in some previous cycle and we should handle it now
              newMktAccMap.set(entry.currency, {
                ...entry,
                wsAmount: '0',
                inputValue: '',
                checked: false,
                isOutgoing: false,
              });

              newMap.set(mktAcc, newMktAccMap);
            }
          }
        });
      });

      return newMap;
    }

    default:
      return state;
  }
};

export function getInitialFormStateForAmount(position: Position): AmountEntry {
  const isOutgoing = Big(position.Amount).lt(0);
  const inputValue = isOutgoing ? Big(position.Amount).abs().toFixed() : '';

  return {
    wsAmount: position.Amount,
    isManuallySet: false,
    isOutgoing: isOutgoing,
    checked: isOutgoing,
    inputValue,
    currency: position.Currency,
  };
}

function newMapWithReplacedInternalEntry(
  oldMap: PositionsFormState,
  marketAccount: string,
  currency: string,
  newEntry: Partial<AmountEntry>
): PositionsFormState {
  // new outer-most map
  const newMap = new Map([...oldMap.entries()]);
  const oldMarketAccountMap = newMap.get(marketAccount);
  if (!oldMarketAccountMap) {
    return oldMap;
  }

  const entryToReplace = oldMarketAccountMap.get(currency);
  if (!entryToReplace) {
    return oldMap;
  }

  // new market-account level map as well
  const newMarketAccountMap = new Map([...oldMarketAccountMap.entries()]);

  newMarketAccountMap.set(currency, {
    ...entryToReplace,
    ...newEntry, // override what's provided
  });

  newMap.set(marketAccount, newMarketAccountMap);

  return newMap;
}

/**
 * Given a market account map and the knowledge that we should be changing the checked state,
 * computes what to do and returns a new map representing the new state.
 * @param mktAccMap
 */
function handleMarketAccountCheckChange(
  marketAccountMap: Map<string, AmountEntry>,
  newChecked?: boolean
): Map<string, AmountEntry> {
  // set all child checked states to either true or false.
  const isMarketAccountChecked = [...marketAccountMap.values()].some(entry => entry.checked);

  // use the newChecked value if provided (its like an override)
  const newOutgoingChildrenChecked = newChecked != null ? newChecked : !isMarketAccountChecked;

  const newMarketAccountMap = new Map<string, AmountEntry>();
  for (const [currency, amountEntry] of marketAccountMap.entries()) {
    // if we're unchecking the MarketAccount checkbox, we always uncheck all children.
    // However when checking the MarketAccount checkbox we only re-check the outgoing ones, they are the defaults.
    const newChecked = amountEntry.isOutgoing ? newOutgoingChildrenChecked : false;
    const newEntry = handleEntryCheckedChange(amountEntry, newChecked);
    newMarketAccountMap.set(currency, newEntry);
  }

  return newMarketAccountMap;
}

function handleEntryCheckedChange(entry: AmountEntry, newChecked: boolean): AmountEntry {
  const maybeNewInputValue = newChecked && entry.isOutgoing ? Big(entry.wsAmount).abs().toFixed() : '';

  // if we are unchecking an outgoing amount, we are deciding to not settle automatically, thus manually set is true
  // if we are checking an outgoing amount, we are deciding to automatically settle the entire amount (because it populates the field), thus manually set is false
  const isManuallySet = newChecked === false && entry.isOutgoing;
  return {
    ...entry,
    checked: newChecked,
    isManuallySet,
    inputValue: maybeNewInputValue,
  };
}
