import { compactArray } from '@talos/kyoko';
import type { GroupPanelViewState, SerializedGridObject } from 'dockview';
import type { PanelMapping } from './dockviewLayoutTypes';

export function getDockViewPanelDataLeaves<TPanelType extends string, TComponentType extends string>({
  panels,
  mainPanel,
  bottomPanel,
}: {
  panels: PanelMapping<TPanelType, TComponentType>;
  mainPanel: {
    views: TPanelType[];
    activeView: TPanelType;
    leafId: string;
    size: number;
  };
  bottomPanel?: {
    views: TPanelType[];
    activeView: TPanelType;
    leafId: string;
    size: number;
  };
}): SerializedGridObject<GroupPanelViewState>[] {
  return compactArray([
    {
      type: 'leaf',
      data: {
        views: mainPanel.views,
        activeView: mainPanel.activeView,
        id: mainPanel.leafId,
      },
      size: mainPanel.size,
    } satisfies SerializedGridObject<GroupPanelViewState>,
    bottomPanel
      ? ({
          type: 'leaf',
          data: {
            views: bottomPanel.views,
            activeView: bottomPanel.activeView,
            id: bottomPanel.leafId,
          },
          size: bottomPanel.size,
        } satisfies SerializedGridObject<GroupPanelViewState>)
      : undefined,
  ]);
}
