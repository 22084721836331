import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface DrawerState {
  isSaving?: boolean;
  isLoading?: boolean;
}

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {} as DrawerState,
  reducers: {
    isSaving: (state, { payload: isSaving }: PayloadAction<boolean>) => {
      state.isSaving = isSaving;
    },
    isLoading: (state, { payload: isBusy }: PayloadAction<boolean>) => {
      state.isLoading = isBusy;
    },
  },
});

export const { isSaving, isLoading } = drawerSlice.actions;
