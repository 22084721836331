import type { ReactElement } from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, type DefaultTheme } from 'styled-components';
import { IntlProvider } from '../providers/IntlProvider';

export function renderToHTML(component: ReactElement, theme: DefaultTheme, locale?: string) {
  const div = document.createElement('div');
  const root = createRoot(div);

  flushSync(() =>
    root.render(
      <ThemeProvider theme={theme}>
        <IntlProvider locale={locale}>{component}</IntlProvider>
      </ThemeProvider>
    )
  );
  // Grab the inner HTML to return
  const returnValue = div.innerHTML;
  // Unmount the component since we're not going to need it anymore
  root.unmount();
  return returnValue;
}
