import Big from 'big.js';
import { useCurrenciesContext } from '../../contexts';
import type { Loan } from '../../types';
import { abbreviateId } from '../../utils';
import { Box, Grid, HStack, type BoxProps } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Icon, IconName } from '../Icons';
import { Dd, Dl, Dt } from '../Lists';
import { LoanDuration } from '../LoanDuration';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

export interface LoanSummaryProps extends BoxProps {
  loan: Loan;
  showVersion: boolean;
}

export function LoanSummary({ loan, showVersion, ...props }: LoanSummaryProps) {
  const { currenciesBySymbol } = useCurrenciesContext();
  const {
    LoanID,
    Revision,
    Qty,
    CollateralRatio,
    Timestamp,
    TerminationTime,
    PrincipalCurrency,
    CollateralCurrency,
    Rate,
  } = loan;
  const principalCurrency = currenciesBySymbol.get(PrincipalCurrency ?? '');

  return (
    <Box fontSize="fontSizeSmall" {...props}>
      <Grid columns="auto repeat(3, 1fr)" gap="spacingDefault">
        <Dl>
          <Dt>Principal Amount</Dt>
          <Dd>
            <InlineFormattedNumber
              number={Qty}
              currency={PrincipalCurrency}
              increment={principalCurrency?.DefaultIncrement}
            />
          </Dd>
        </Dl>
        <Dl alignItems="flex-end">
          <Dt>Collateral</Dt>
          <Dd>
            {CollateralRatio == null ? (
              '-'
            ) : (
              <InlineFormattedNumber number={Big(CollateralRatio).times(100).toFixed()} increment="1" currency="%" />
            )}
            {' / '}
            {CollateralCurrency ?? '-'}
          </Dd>
        </Dl>
        <Dl alignItems="flex-end">
          <Dt>Term</Dt>
          <Dd>
            <LoanDuration timestamp={Timestamp} terminationTime={TerminationTime} />
          </Dd>
        </Dl>
        <Dl alignItems="flex-end">
          <Dt>Rate</Dt>
          <Dd>
            {Rate == null ? (
              '-'
            ) : (
              <InlineFormattedNumber number={Big(Rate).times(100).toFixed()} increment="0.01" currency="%" />
            )}
          </Dd>
        </Dl>
      </Grid>
      {showVersion && (
        <HStack justifyContent="space-between" mt="spacingLarge">
          <HStack>
            {LoanID != null && (
              <Tooltip tooltip="LoanID">
                <Icon icon={IconName.Hashtag} variant="Subtle" />
                <Text color="colorTextSubtle">{abbreviateId(LoanID)}</Text>
              </Tooltip>
            )}
          </HStack>
          <HStack gap="spacingLarge">
            <Text color="colorTextSubtle">Rev: {Revision}</Text>
          </HStack>
        </HStack>
      )}
    </Box>
  );
}
