import { POST, request, useEndpointsContext } from '@talos/kyoko';
import { useCallback } from 'react';
import type { TransferInstruction } from '../../types';

export const useSettleRequests = () => {
  const { orgApiEndpoint } = useEndpointsContext();
  const transfersEndpoint = `${orgApiEndpoint}/treasury/transfer`;

  const postTransferInstructions = useCallback(
    (instructions: TransferInstruction[]) => {
      return request(POST, transfersEndpoint, instructions);
    },
    [transfersEndpoint]
  );

  return { postTransferInstructions };
};
