import {
  isCFD,
  isFuture,
  isOption,
  isOptionStrategy,
  isPerpetualSwap,
  prettyName,
  type MarketDataStatistics,
  type Security,
} from '@talos/kyoko';
import { BEST_BID_IV, BEST_OFFER_IV, MARK_PRICE_IV } from 'tokens/marketDataStats';

export const canViewContractDetails = (security?: Security) => {
  return (
    isFuture(security) ||
    isPerpetualSwap(security) ||
    isOption(security) ||
    isOptionStrategy(security) ||
    isCFD(security)
  );
};

export function prettify(key: keyof MarketDataStatistics) {
  if (key === MARK_PRICE_IV) {
    return 'Mark IV';
  }
  if (key === BEST_BID_IV) {
    return 'Best Bid IV';
  }
  if (key === BEST_OFFER_IV) {
    return 'Best Offer IV';
  }
  return prettyName(key.replace('predicted', '')).replace('24H', ' 24h');
}
