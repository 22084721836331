import { createAction, createReducer, type UnknownAction } from '@reduxjs/toolkit';
import { PnlLookbackEnum, POSITION_SUB_ACCOUNT, type Column } from '@talos/kyoko';
import {
  columnsToRequestPnlTags,
  customEODToRequestEOD,
} from 'containers/Blotters/PositionsV3/SubAccounts/useSubAccountPositionsBlotterRequest';
import type { PerfSubAccountPositionsRequest } from 'containers/Portfolio/Performance/types';
import { castDraft } from 'immer';
import { isEqual } from 'lodash';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { createContext, useContext, useMemo, useReducer, useRef, type Dispatch, type PropsWithChildren } from 'react';
import { useGetSubAccountNamesForRequest } from '../providers/pipes/useGetSubAccountNamesForRequest';
import { usePortfolioViewStateSelector } from '../stateManagement/portfolioViewLayoutSlice.hooks';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from './tokens';

const actions = {
  setColumnsForTags: createAction<Column[]>('setColumnsForTags'),
};
const reducer = createReducer<{
  tagColumns?: Column[];
}>({}, builder => {
  builder.addCase(actions.setColumnsForTags, (state, action) => {
    state.tagColumns = castDraft(action.payload);
  });
});

const PortfolioManagementPositionRequestContext = createContext<
  | {
      actions: typeof actions;
      request: PerfSubAccountPositionsRequest | null;
      dispatch: Dispatch<UnknownAction>;
    }
  | undefined
>(undefined);

/** Retrieve WS subscription to get Position feed for the PMS Perf blotter
 * - key for retrieving pnl lookup data for each position
 */
export const PortfolioManagementPositionRequestProvider = ({
  subTag,
  children,
}: PropsWithChildren<{ subTag: string }>) => {
  const [state, dispatch] = useReducer(reducer, reducer.getInitialState());
  const { selectedPortfolioId, showZeroBalances } = usePortfolioViewStateSelector();
  const getSubAccountNamesForRequest = useGetSubAccountNamesForRequest();

  const { customEOD, homeCurrency } = useDisplaySettings();

  const lastRequest = useRef<PerfSubAccountPositionsRequest | null>(null);
  const contextValue = useMemo(() => {
    const newRequest: PerfSubAccountPositionsRequest | null = selectedPortfolioId
      ? {
          name: POSITION_SUB_ACCOUNT,
          tag: subTag,
          ShowZeroBalances: showZeroBalances,
          EquivalentCurrency: homeCurrency,
          Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
          EndOfDay: customEODToRequestEOD(customEOD),
          PnLLookbacks: [
            PnlLookbackEnum.H24,
            PnlLookbackEnum.D30,
            PnlLookbackEnum.D7,
            PnlLookbackEnum.D365,
            PnlLookbackEnum.MonthToDate,
            PnlLookbackEnum.Today,
            PnlLookbackEnum.WeekToDate,
            PnlLookbackEnum.YearToDate,
          ],
          // Due to RiskPosition prefix, we need to pass index 2 into columnsToRequestPnlTags
          Tags: state.tagColumns ? columnsToRequestPnlTags(state.tagColumns, 2) : undefined,
          SubAccounts: getSubAccountNamesForRequest(selectedPortfolioId),
        }
      : null;
    if (!isEqual(lastRequest.current, newRequest)) {
      lastRequest.current = newRequest;
    }
    return {
      actions,
      request: lastRequest.current,
      dispatch,
    };
  }, [
    selectedPortfolioId,
    subTag,
    showZeroBalances,
    homeCurrency,
    customEOD,
    state.tagColumns,
    getSubAccountNamesForRequest,
  ]);

  return (
    <PortfolioManagementPositionRequestContext.Provider value={contextValue}>
      {children}
    </PortfolioManagementPositionRequestContext.Provider>
  );
};

export function usePortfolioManagementBlotterPositionRequestContext() {
  const context = useContext(PortfolioManagementPositionRequestContext);
  if (!context) {
    throw new Error(
      'Missing PortfolioManagementBlotterPositionRequestProvider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}
