import type { TabProps } from '@talos/kyoko';
import { v4 as uuid } from 'uuid';
import {
  DEFAULT_CUSTOMER_SETTLEMENT_MONITORING_STATE,
  type SettlementMonitoringState,
} from './CustomerSettlementMonitoring/types';

export enum SettlementTabType {
  SettlementMonitoring = 'Settlement Monitoring',
}

export interface SettlementMonitoringTab extends TabProps, SettlementMonitoringState {
  id: string;
  tabType: SettlementTabType.SettlementMonitoring;
}

export type SettlementTab = SettlementMonitoringTab /* | ... add more types here  */;

export interface SettlementTabTypeDetail {
  getDefaultState: () => SettlementTab;
}

// This static details set helps us define different tab types in one place and tells the page how to interact with them completely
// When we add new tab types to the page in the future, the only place we change is here
export const SETTLEMENT_TABS_DETAILS: { [key in SettlementTabType]: SettlementTabTypeDetail } = {
  [SettlementTabType.SettlementMonitoring]: {
    getDefaultState: () =>
      ({
        id: uuid(),
        tabType: SettlementTabType.SettlementMonitoring,
        ...DEFAULT_CUSTOMER_SETTLEMENT_MONITORING_STATE,
      } satisfies SettlementMonitoringTab),
  },
} as const;
