import {
  BaseSelect,
  Box,
  Button,
  ButtonVariants,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  IndicatorBadge,
  MarketAccountStatusEnum,
  NotificationVariants,
  SearchSelect,
  Text,
  Tooltip,
  TreasuryLinkStatusEnum,
  VStack,
  format,
  getOppositeSourceOrDestinationField,
  getTreasuryLinkSpecificness,
  getTreasuryLinkSpecificnessKey,
  logger,
  useCurrency,
  useDynamicCallback,
  useGlobalToasts,
  useMarketAccountsContext,
  useMarketsContext,
  type MarketAccount,
  type TreasuryLink,
  type TreasuryLinkDirectionEnum,
  type TreasuryLinkSourceOrDestinationField,
  type TreasuryLinkTypeEnum,
} from '@talos/kyoko';
import { usePortfolioTreasury } from 'containers/Portfolio/providers';
import { isEqual } from 'lodash';
import { usePortfolioAccounts } from 'providers';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  getSourceOrDestinationFallbackLabel,
  getTreasuryAccountDisplayValue,
  getTreasuryLinkTypeLabel,
} from '../hooks/useAddressSummary';
import {
  useEffectiveTreasuryLinkSourceOrDestination,
  useFallbackTreasuryLinkSourceOrDestination,
} from '../hooks/useEffectiveTreasuryLink';
import { useTreasuryRequests } from '../hooks/useTreasuryRequests';
import { CustomWrapperForGrayedText } from '../styles';
import { getDestinationAddressDescription } from '../utils';

type AutocompleteItem = {
  label: string;
  value: string;
  description?: string;
};

interface TreasuryMarketAccountSelectorAsWrapperProps {
  treasuryLink: TreasuryLink;
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField;
  onStartEditing?: () => void;
  isEditingThisCell: boolean;
}

const useInvalidText = (
  effectiveSourceOrDestination: string | undefined,
  effectiveSourceOrDestinationOpposite: string | undefined
): string | undefined => {
  const { marketAccountsByName } = useMarketAccountsContext();

  const myMarket = effectiveSourceOrDestination
    ? marketAccountsByName?.get(effectiveSourceOrDestination)?.Market
    : undefined;
  const theirMarket = effectiveSourceOrDestinationOpposite
    ? marketAccountsByName?.get(effectiveSourceOrDestinationOpposite)?.Market
    : undefined;

  const isMarketMismatch = theirMarket != null && myMarket != null && theirMarket !== myMarket;
  if (isMarketMismatch) {
    return 'Source and Withdrawal Destination markets must match.';
  }

  const marketAccount = effectiveSourceOrDestination
    ? marketAccountsByName?.get(effectiveSourceOrDestination)
    : undefined;

  if (effectiveSourceOrDestination && marketAccount == null) {
    return 'Market Account no longer exists. Please select a different account.';
  }

  if (marketAccount?.Status === MarketAccountStatusEnum.Inactive) {
    return 'Market Account is Inactive.';
  }

  return undefined;
};

export const TreasuryMarketAccountSelectorAsWrapper = ({
  treasuryLink,
  sourceOrDestinationField,
  onStartEditing,
  isEditingThisCell,
}: TreasuryMarketAccountSelectorAsWrapperProps) => {
  const { OTCTreasuryPositionsByMarketAccountAndCurrency } = usePortfolioTreasury();
  const { add: addToast } = useGlobalToasts();

  const { resetTreasuryLinkField } = useTreasuryRequests();
  const { colorTextWarning } = useTheme();
  const { marketsByName } = useMarketsContext();
  const { marketAccountsByName } = useMarketAccountsContext();
  const [disabledForRequest, setDisabledForRequest] = useState<boolean>(false);

  const sourceOrDestinationFieldOpposite = getOppositeSourceOrDestinationField(sourceOrDestinationField);

  // For our treasury link, we need to know what the effective source and destination are, since our link itself
  // can have either or both of SourceMarketAccount, DestinationMarketAccount be undefined.
  const effectiveField = useEffectiveTreasuryLinkSourceOrDestination(treasuryLink, sourceOrDestinationField);
  const effectiveFieldOpposite = useEffectiveTreasuryLinkSourceOrDestination(
    treasuryLink,
    sourceOrDestinationFieldOpposite
  );
  const fallbackField = useFallbackTreasuryLinkSourceOrDestination(treasuryLink, sourceOrDestinationField);

  const linkTypeLabel = getTreasuryLinkTypeLabel(treasuryLink.Type);

  const effectiveFieldMarketAccount = effectiveField?.effectiveSourceOrDestination
    ? marketAccountsByName.get(effectiveField.effectiveSourceOrDestination)
    : undefined;

  const effectiveFieldMarket = effectiveFieldMarketAccount?.Market
    ? marketsByName.get(effectiveFieldMarketAccount?.Market)
    : undefined;

  const displayValue =
    getTreasuryAccountDisplayValue(effectiveFieldMarketAccount, effectiveFieldMarket) ??
    effectiveField?.effectiveSourceOrDestination;

  const currencyInfo = useCurrency(treasuryLink.Currency);

  // Grab the balance for the effective source account.
  // Only relevant for Source and if there's a market account and currency specified.
  const effectiveSourceAccountBalance = useMemo(() => {
    if (
      effectiveFieldMarketAccount?.Name &&
      treasuryLink.Currency &&
      sourceOrDestinationField === 'SourceMarketAccount'
    ) {
      return OTCTreasuryPositionsByMarketAccountAndCurrency?.get(effectiveFieldMarketAccount.Name)?.get(
        treasuryLink.Currency
      )?.Amount;
    }
    return undefined;
  }, [
    OTCTreasuryPositionsByMarketAccountAndCurrency,
    treasuryLink,
    sourceOrDestinationField,
    effectiveFieldMarketAccount,
  ]);

  const invalidText = useInvalidText(
    effectiveField?.effectiveSourceOrDestination,
    effectiveFieldOpposite?.effectiveSourceOrDestination
  );

  const handleResetField = useCallback(
    (treasuryLink: TreasuryLink, sourceOrDestinationField: TreasuryLinkSourceOrDestinationField) => {
      setDisabledForRequest(true);
      resetTreasuryLinkField(treasuryLink, sourceOrDestinationField)
        .then(() => {
          addToast({
            text: `Link field reset. Wait for your selection to update.`,
            variant: NotificationVariants.Positive,
          });
          // This button will not update until the WS update is received. Disable it to prevent unintended resets.
          setTimeout(() => {
            setDisabledForRequest(false);
          }, 5000);
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: `Could not update link: ${e.message}`,
            variant: NotificationVariants.Negative,
          });
          setDisabledForRequest(false);
        });
    },
    [addToast, resetTreasuryLinkField]
  );

  const showSourceMarketAccountBalanceWarning =
    invalidText == null &&
    effectiveSourceAccountBalance == null &&
    sourceOrDestinationField === 'SourceMarketAccount' &&
    effectiveField?.effectiveSourceOrDestination != null;

  // The helper text for the Reset button on a non-fallback source/destination selection.
  // "What are we resetting to?"
  const resetTooltipLabel = fallbackField
    ? `Reset to ${getSourceOrDestinationFallbackLabel(
        fallbackField.fallbackSourceOrDestinationSpecificness,
        linkTypeLabel
      )}`
    : undefined;

  const getLabel = useCallback(
    (_: MarketAccount | undefined) => {
      return displayValue;
    },
    [displayValue]
  );

  const getDescription = useCallback(
    (_: MarketAccount | undefined) => {
      if (sourceOrDestinationField === 'SourceMarketAccount' && effectiveSourceAccountBalance) {
        return `Balance: ${format(effectiveSourceAccountBalance, { spec: currencyInfo?.DefaultIncrement })} ${
          treasuryLink.Currency
        }`;
      }

      if (
        sourceOrDestinationField === 'DestinationMarketAccount' &&
        effectiveFieldMarketAccount &&
        treasuryLink.Currency != null
      ) {
        return getDestinationAddressDescription(effectiveFieldMarketAccount, treasuryLink.Currency);
      }

      return '';
    },
    [effectiveSourceAccountBalance, sourceOrDestinationField, treasuryLink, currencyInfo, effectiveFieldMarketAccount]
  );

  const overrideStyle: React.CSSProperties | undefined = showSourceMarketAccountBalanceWarning
    ? { borderColor: colorTextWarning }
    : undefined;

  const handleResetClicked = useDynamicCallback((e: React.MouseEvent) => {
    handleResetField(treasuryLink, sourceOrDestinationField);
    // Stop the propagation to stop the dropdown from opening by clicking on inside the BaseSelect
    e.stopPropagation();
  });

  const treasuryLinkSpecificness = getTreasuryLinkSpecificness(treasuryLink);
  // If the treasury link we used to grab the effective field is _less specific_ than our own link, then we are showing a fallback.
  const showingAFallback =
    (effectiveField && effectiveField?.effectiveSourceOrDestinationSpecificness < treasuryLinkSpecificness) ?? false;

  return (
    <CustomWrapperForGrayedText useGrayText={showingAFallback}>
      <BaseSelect
        onClick={onStartEditing}
        value={effectiveFieldMarketAccount}
        isDropdownOpened={isEditingThisCell}
        getLabel={getLabel}
        getDescription={getDescription}
        placeholder="Select"
        size={FormControlSizes.Small}
        style={overrideStyle}
        invalid={invalidText != null}
        suffix={
          <HStack gap="spacingSmall">
            {invalidText ? (
              <Tooltip tooltip={invalidText} usePortal={true}>
                <Icon icon={IconName.ExclamationSolid} color="colors.red.lighten" />
              </Tooltip>
            ) : showSourceMarketAccountBalanceWarning ? (
              <Tooltip
                tooltip={
                  <VStack alignItems="flex-start">
                    <Text>Address has no {treasuryLink.Currency} balance.</Text>
                    <Text>Please verify account info or select a different account.</Text>
                  </VStack>
                }
                usePortal={true}
              >
                <Icon icon={IconName.ExclamationSolid} color="colors.yellow.lighten" />
              </Tooltip>
            ) : null}
            {showingAFallback && effectiveField && (
              <IndicatorBadge
                textTransform="uppercase"
                mr="spacingSmall"
                children={getSourceOrDestinationFallbackLabel(
                  effectiveField.effectiveSourceOrDestinationSpecificness,
                  linkTypeLabel
                )}
              />
            )}
            {/* Show the reset button if there is some selection and its not a fallback */}
            {!showingAFallback && effectiveFieldMarketAccount != null && (
              <Box borderRadius="borderRadiusSmall" overflow="hidden" mr="spacingSmall">
                {/* If we are not showing a fallback, we have the ability to reset ourselves.
                If we have the ability to reset ourselves, we show a tooltip displaying what a reset would do,
                "what are we resetting to?" */}
                <Tooltip tooltip={!disabledForRequest && resetTooltipLabel} usePortal={true}>
                  <Button
                    variant={ButtonVariants.Primary}
                    size={FormControlSizes.Tiny}
                    startIcon={IconName.Reply}
                    onClick={handleResetClicked}
                    disabled={disabledForRequest}
                    data-testid="link-property-reset-button"
                  >
                    Reset
                  </Button>
                </Tooltip>
              </Box>
            )}
          </HStack>
        }
      />
    </CustomWrapperForGrayedText>
  );
};

interface TreasuryMarketAccountSelectorProps {
  type: TreasuryLinkTypeEnum;
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField;
  direction: TreasuryLinkDirectionEnum;
  marketAccountName?: string;
  allowReset?: boolean;
  placeholder?: string;
}

export const TreasuryMarketAccountSelector = memo(
  ({
    type,
    allowReset,
    sourceOrDestinationField,
    direction,
    marketAccountName,
    placeholder,
  }: TreasuryMarketAccountSelectorProps) => {
    const { marketAccountsByName } = useMarketAccountsContext();
    const { sourceAccountsList, treasuryLinksBySpecificnessKey } = usePortfolioAccounts();
    const { marketsByName } = useMarketsContext();
    const { putTreasuryLink } = useTreasuryRequests();
    const { add: addToast } = useGlobalToasts();

    const sourceOrDestinationFieldOpposite = getOppositeSourceOrDestinationField(sourceOrDestinationField);

    const effectiveField = useEffectiveTreasuryLinkSourceOrDestination(
      {
        Type: type,
        MarketAccount: marketAccountName,
        Direction: direction,
      },
      sourceOrDestinationField
    );

    const effectiveFieldOpposite = useEffectiveTreasuryLinkSourceOrDestination(
      {
        Type: type,
        MarketAccount: marketAccountName,
        Direction: direction,
      },
      sourceOrDestinationFieldOpposite
    );

    const effectiveFieldMarketAccount = effectiveField?.effectiveSourceOrDestination
      ? marketAccountsByName.get(effectiveField.effectiveSourceOrDestination)
      : undefined;

    const effectiveFieldMarket = effectiveFieldMarketAccount?.Market
      ? marketsByName.get(effectiveFieldMarketAccount?.Market)
      : undefined;

    const globalAccountDisplayValue = getTreasuryAccountDisplayValue(effectiveFieldMarketAccount, effectiveFieldMarket);

    const marketsByNameRef = useRef(marketsByName);

    const theirMarket = effectiveFieldOpposite
      ? marketAccountsByName?.get(effectiveFieldOpposite.effectiveSourceOrDestination)?.Market
      : undefined;

    const addressOptions: AutocompleteItem[] = useMemo(() => {
      const marketsByName = marketsByNameRef.current;

      return (
        sourceAccountsList?.map(s => {
          const displayValue = getTreasuryAccountDisplayValue(s, marketsByName.get(s.Market));
          const label = displayValue ?? s.DisplayName;

          return {
            value: s.Name,
            label,
          };
        }) ?? []
      );
    }, [sourceAccountsList]);

    const filteredAddressOptions: AutocompleteItem[] = useMemo(() => {
      return theirMarket && sourceOrDestinationField === 'DestinationMarketAccount'
        ? addressOptions?.filter(({ value }) => marketAccountsByName?.get(value)?.Market === theirMarket)
        : addressOptions;
    }, [addressOptions, sourceOrDestinationField, marketAccountsByName, theirMarket]);

    const getLabel = useCallback((item: AutocompleteItem) => item.label, []);
    const getDescription = useCallback((item: AutocompleteItem) => item.description ?? '', []);

    const { resetTreasuryLinkField } = useTreasuryRequests();

    // This is the treasury link of our "row" itself. It might exist or it might not exist. Our "row"
    // can just not be represented by its own treasury link yet and instead is just surfacing values from fallbacks on the page
    const treasuryLinkOfRow = useMemo(() => {
      if (!treasuryLinksBySpecificnessKey) {
        return undefined;
      }

      const treasuryLinksAtSpecificness = treasuryLinksBySpecificnessKey.get(
        getTreasuryLinkSpecificnessKey({ Type: type, Direction: direction, MarketAccount: marketAccountName })
      );
      if (!treasuryLinksAtSpecificness) {
        return undefined;
      }

      return [...treasuryLinksAtSpecificness.values()].at(0);
    }, [treasuryLinksBySpecificnessKey, direction, marketAccountName, type]);

    const handleResetField: React.MouseEventHandler<HTMLButtonElement> = useDynamicCallback(e => {
      e.stopPropagation();
      // We should always be able to find it since thats the only case where we can reset in the first place.
      // If we don't find it, something is wrong. Raise warning.
      if (!treasuryLinkOfRow) {
        logger.error(new Error(`Treasury link market account reset action performed without any link found`), {
          extra: {
            type,
            direction,
            marketAccountName,
          },
        });
        return;
      }

      resetTreasuryLinkField(treasuryLinkOfRow, sourceOrDestinationField)
        .then(() => {
          addToast({
            text: `Link updated.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: `Could not update link: ${e.message}`,
            variant: NotificationVariants.Negative,
          });
        });
    });

    const handleSelectionMade = useCallback(
      (item: AutocompleteItem | undefined) => {
        // There might already be an Active treasury link representing our row (treasuryLinkOfRow), and if there is one, update that one. Otherwise create new.
        const newTreasuryLink = {
          MarketAccount: marketAccountName,
          Direction: direction,
          Type: type,
        };

        const newOrExistingTreasuryLinkToUpdate = {
          ...(treasuryLinkOfRow || newTreasuryLink),
          Status: TreasuryLinkStatusEnum.Active, // always set to active when a selection is made
          [sourceOrDestinationField]: item?.value,
        };

        putTreasuryLink(newOrExistingTreasuryLinkToUpdate)
          .then(() => {
            addToast({
              text: `Link updated.`,
              variant: NotificationVariants.Positive,
            });
          })
          .catch((e: ErrorEvent) => {
            addToast({
              text: `Could not update link: ${e.message}`,
              variant: NotificationVariants.Negative,
            });
          });
      },
      [addToast, direction, marketAccountName, putTreasuryLink, type, sourceOrDestinationField, treasuryLinkOfRow]
    );

    const invalidText = useInvalidText(
      effectiveField?.effectiveSourceOrDestination,
      effectiveFieldOpposite?.effectiveSourceOrDestination
    );

    // We have a selection if our own treasury link (treasuryLinkOfRow) has specified the sourceOrDestinationField.
    // We then find the corresponding option to that selection and return that, since the search select is using AutocompleteItems
    const selection = useMemo(() => {
      if (!treasuryLinkOfRow) {
        return undefined;
      }

      return filteredAddressOptions.find(option => treasuryLinkOfRow[sourceOrDestinationField] === option.value);
    }, [treasuryLinkOfRow, filteredAddressOptions, sourceOrDestinationField]);

    return (
      <SearchSelect
        data-testid={`treasury-market-account-selector-as-wrapper-${sourceOrDestinationField}`}
        w="100%"
        invalid={invalidText != null}
        touched={true}
        selection={selection}
        options={filteredAddressOptions}
        onChange={handleSelectionMade}
        getLabel={getLabel}
        getDescription={getDescription}
        placeholder={
          placeholder ?? globalAccountDisplayValue ?? effectiveField?.effectiveSourceOrDestination ?? 'Optional'
        }
        showClear={!allowReset}
        suffix={
          <>
            <HStack gap="spacingDefault">
              {invalidText && (
                <Tooltip tooltip={invalidText}>
                  <Icon icon={IconName.ExclamationSolid} color="colors.red.lighten" />
                </Tooltip>
              )}
              {allowReset && selection && (
                <Box borderRadius="borderRadiusSmall" overflow="hidden" mr="spacingSmall">
                  <Tooltip tooltip="Reset to Global Default">
                    <Button
                      variant={ButtonVariants.Primary}
                      size={FormControlSizes.Tiny}
                      startIcon={IconName.Reply}
                      onClick={handleResetField}
                      data-testid="link-property-reset-button"
                    >
                      <Text color="colorTextAttention">Reset</Text>
                    </Button>
                  </Tooltip>
                </Box>
              )}
            </HStack>
          </>
        }
      />
    );
  },
  (p, n) => isEqual(p, n)
);
