import {
  ACTION,
  Button,
  ButtonVariants,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  Text,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  type Allocation,
  type ISubaccount,
} from '@talos/kyoko';
import { useMemo, useState } from 'react';
import { useRoleAuth } from '../../../hooks';
import { CreateSubAccountModal } from '../../CreateSubAccountModal';

interface UseSubAccountCreationParams {
  /** The current selections. The selections in OMSSubAccounts are modeled as Allocations */
  subAccountAllocations: Allocation[];
  /** The effective on-change function for the OMSSubAccounts component */
  handleUpdateAllocation: <K extends keyof Allocation>(index: number, key: K, value: Allocation[K]) => void;
}

/**
 * This hook just serves to isolate the logic OMSSubAccounts needs to be able to create sub accounts on the fly.
 * This is only done for increased readability.
 *
 * If Sub Account creation is disabled, this hook will return undefined.
 */
export const useSubAccountCreation = ({
  subAccountAllocations,
  handleUpdateAllocation,
}: UseSubAccountCreationParams) => {
  const mixpanel = useMixpanel();
  const [lastTouchedSelector, setLastTouchedSelector] = useState<number | undefined>(undefined);
  const createSubAccountModal = useDisclosure();
  const { isAuthorized } = useRoleAuth();

  const handleSuccessfulSubAccCreation = useDynamicCallback((newSubAccount: ISubaccount) => {
    const indexOfSelectionToReplace = lastTouchedSelector;
    if (indexOfSelectionToReplace == null) {
      return;
    }

    // Just some defensive programming, no special business logic case here or anything like that
    const indexNoLongerExists = indexOfSelectionToReplace >= subAccountAllocations.length;
    if (indexNoLongerExists) {
      return;
    }

    handleUpdateAllocation(indexOfSelectionToReplace, 'subAccount', newSubAccount.Name);
  });

  const allowCreation = isAuthorized(ACTION.CLONE_SUB_ACCOUNTS) || isAuthorized(ACTION.EDIT_SUB_ACCOUNTS);

  const handleCreateNewSubAccountClick = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CreateSubAccountClick, {
      [MixpanelEventProperty.Source]: MixpanelEventSource.OMS,
    });
    createSubAccountModal.open();
  });

  const dropdownSuffix = useMemo(() => {
    return (
      <HStack background="backgroundDropdownGroupHeader" py="spacingDefault" px="spacingComfortable">
        <Button
          variant={ButtonVariants.Primary}
          onClick={handleCreateNewSubAccountClick}
          width="100%"
          size={FormControlSizes.Small}
          data-testid="create-sub-account-button"
        >
          <HStack gap="spacingSmall">
            <Icon icon={IconName.Plus} />
            <Text>Create Sub Account</Text>
          </HStack>
        </Button>
      </HStack>
    );
  }, [handleCreateNewSubAccountClick]);

  if (!allowCreation) {
    return undefined;
  }

  const modal = (
    <CreateSubAccountModal {...createSubAccountModal} onSuccessfulCreation={handleSuccessfulSubAccCreation} />
  );

  return {
    /**
     * Should be updated when your search select goes from open to close to indicate which was last touched.
     * The lastTouchedSelector state allows us to know which sub account dropdown we are in in the case that there are several allocation selections made.
     * The lastTouchedSelector selection is the one to be replaced upon successful sub account creation.
     */
    setLastTouchedSelector,
    /** The modal, just to be rendered as `{modal}` */
    modal,
    /** Props to be spread into the OMSSubAccounts Sub account selector SearchSelect component */
    searchSelectProps: {
      dropdownSuffix,
    },
  };
};
