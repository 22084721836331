import { Box } from '@talos/kyoko';
import { lighten } from 'polished';
import styled from 'styled-components';

export const BlotterStylesWrapper = styled(Box)`
  .cell-span {
    background: var(--ag-background-color);
    border-bottom: 1px solid var(--ag-border-color) !important;
  }

  .ag-row.ag-row-hover.row-lighten {
    background: ${({ theme }) => lighten(0.02, theme.backgroundBlotterTableRowHover)};
  }

  .row-lighten {
    background: ${({ theme }) => lighten(0.02, theme.backgroundBlotterTableRow)};
  }

  .ag-row.ag-row-selected {
    .cell-span {
      background: ${({ theme }) => theme.backgroundBlotterTableRowSelected};
    }

    .row-lighten {
      background: ${({ theme }) => lighten(0.02, theme.backgroundBlotterTableRowSelected)};
    }
  }

  .ag-row.ag-row-hover {
    .cell-span {
      background: ${({ theme }) => theme.backgroundBlotterTableRowHover};
    }
  }
`;
