import type { CustomerTrade } from '../../types';
import { Flex, HStack, VStack } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Side } from '../Side';
import { SmartSummary } from '../SmartSummary';
import { Text } from '../Text';

export function CustomerTradeSummary({ entity }: { entity: CustomerTrade }) {
  if (!entity?.Symbol) {
    return null;
  }

  const smartSummaryEntity = {
    Symbol: entity.Symbol,
    Currency: entity.Currency!,
    AmountCurrency: entity.AmountCurrency,
    OrderQty: entity.Quantity,
    Price: entity.Price,
  };

  const user = entity.TradeSource === 'DealerUser' ? entity.UpdateUser : entity.User;

  return (
    <Flex flexDirection="column" gap="spacingDefault">
      <VStack gap="spacingDefault" alignItems="initial" fontSize="fontSizeSmall">
        <Flex alignItems="center" gap="spacingSmall">
          <Side side={entity.Side}>{entity.Side}</Side>
          {entity.Symbol}
        </Flex>
        <SmartSummary entity={smartSummaryEntity} type="trade" showSide={false} />
        <HStack gap="spacingDefault" justifyContent="flex-start">
          <Flex flex="1" gap="spacingSmall">
            <Text color="colorTextDefault">Amount </Text>
            <InlineFormattedNumber highlightAll number={entity.Amount} currency={entity.AmountCurrency} />
          </Flex>
          {user && (
            <Flex flex="1" justifyContent="flex-end" gap="spacingSmall">
              <Text color="colorTextDefault">User </Text>
              {user}
            </Flex>
          )}
          <Flex justifyContent="flex-end" gap="spacingSmall">
            <Text color="colorTextDefault">Rev. </Text>
            {entity.Revision}
          </Flex>
        </HStack>
      </VStack>
    </Flex>
  );
}
