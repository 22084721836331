import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h1 {
    color: ${({ theme }) => theme.colors.gray['090']};
  }
  h3 {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray['080']};
  }
`;
