export const generateTimeSelectionOptions = () => {
  const result: string[] = [];
  for (let i = 0; i < 24; i++) {
    const hours = i < 10 ? `0${i}` : i;
    ['00', '15', '30', '45'].forEach(minutes => {
      result.push(`${hours}:${minutes}:00`);
    });
  }
  return result;
};
