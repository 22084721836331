import { Box, EMPTY_ARRAY, HelpIcon, SearchSelect, Text, type ISubaccount } from '@talos/kyoko';
import { useSubAccounts } from '../../../providers';

function getSubAccountLabel(sa: ISubaccount) {
  return sa.DisplayName ?? sa.Name;
}

function getSubAccountType(sa: ISubaccount) {
  return sa.Type;
}

interface ForSubAccountSelectorProps {
  forSubAccount: ISubaccount | undefined;
  onForSubAccountChange: (forSubAccount: ISubaccount | undefined) => void;
}

export const ForSubAccountSelector = ({ forSubAccount, onForSubAccountChange }: ForSubAccountSelectorProps) => {
  const { allSubAccounts } = useSubAccounts();

  return (
    <Box w="350px" minWidth="350px">
      <SearchSelect
        selection={forSubAccount}
        options={allSubAccounts ?? EMPTY_ARRAY}
        getLabel={getSubAccountLabel}
        getDescription={getSubAccountType}
        getGroup={getSubAccountType}
        onChange={onForSubAccountChange}
        showClear
        maxHeight={400}
        dropdownWidth={300}
        placeholder="Filter"
        data-testid="for-sub-account-selector"
        prefix={
          <Text whiteSpace="nowrap" weight="fontWeightMedium" color="colorTextSubtle" mr="spacingDefault">
            Sub Account / Rollup
          </Text>
        }
        suffix={
          <HelpIcon
            tooltip="When filtered, all applicable limits are shown including limits inherited from parent rollups."
            usePortal
          />
        }
      />
    </Box>
  );
};
