import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { ExecutionReport } from '@talos/kyoko';
import {
  ACTION,
  Alert,
  AlertVariants,
  Box,
  Button,
  DateTimeDurationPicker,
  Dialog,
  Divider,
  ExecTypeEnum,
  Flex,
  FormGroup,
  NotificationVariants,
  OrderMarketStatusEnum,
  Text,
  calcDateFromDuration,
  isDuration,
  isEmptyDuration,
  notEmpty,
  parseDate,
  useGlobalToasts,
  wsScanToDoubleMap,
  type DialogProps,
  type Duration,
  type Order,
  type UseDisclosureReturn,
} from '@talos/kyoko';

import { useExecutionReports, useOrders, useStrategies, useSubAccounts } from 'providers';
import { filter, map } from 'rxjs';
import { v1 as uuid } from 'uuid';
import { useRoleAuth } from '../../../../hooks';
import { BulkEditOrderStatus } from '../BulkModify/BulkEditOrderStatus';
import { BulkModifyOrdersEndTime } from '../BulkModify/BulkModifyOrdersEndTime';
import { BulkModifyOrderStatusEnum, type BulkModifyOrderModel } from './types';

// Show this number of orders initially, more will be available after clicking 'Show more' button
const INITIAL_ORDERS_LIMIT = 5;

const MAX_ORDERLIST_HEIGHT = 242;

type BulkModifyDialogProps = DialogProps &
  UseDisclosureReturn & {
    selectedOrders?: Order[];
  };

export const BulkModifyDialog = ({ selectedOrders, ...props }: BulkModifyDialogProps) => {
  if (!selectedOrders) {
    return null;
  }

  return <ModifyWarningDialog selectedOrders={selectedOrders} key={props.isOpen.toString()} {...props} />;
};

export const ModifyWarningDialog = ({
  selectedOrders,
  ...props
}: DialogProps &
  UseDisclosureReturn & {
    selectedOrders: Order[];
  }) => {
  const [latestTime, setLatestTime] = useState(new Date());
  const { executionReportSub } = useExecutionReports();
  const ordersService = useOrders();
  const { strategiesByName: strategies } = useStrategies();
  const { tradableSubAccounts } = useSubAccounts();
  const { add: addToast } = useGlobalToasts();
  const [orders, setOrders] = useState<BulkModifyOrderModel[]>([]);
  const [orderIDs, setOrderIDs] = useState<string[]>([]);
  const [endTime, setEndTime] = useState<string | Date | Duration | null>(null);
  const [showOrdersLimit, setShowOrdersLimit] = useState(INITIAL_ORDERS_LIMIT);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [relativeEndTime, setRelativeEndTime] = useState<Date>(new Date());
  const timer = useRef<ReturnType<typeof setInterval> | undefined>();
  const resolveRef = useRef<(value) => void>();
  const { isAuthorized } = useRoleAuth();
  const [touched, setTouched] = useState(false);

  const currentEndDate = useMemo(() => {
    if (isDuration(endTime)) {
      return calcDateFromDuration(endTime, relativeEndTime);
    } else {
      return endTime;
    }
  }, [endTime, relativeEndTime]);

  const ordersToModify = useMemo(
    () => orders.filter(order => !order.status || order.status !== BulkModifyOrderStatusEnum.Error),
    [orders]
  );
  const ordersWithErrors = useMemo(
    () => orders.filter(order => order.status === BulkModifyOrderStatusEnum.Error),
    [orders]
  );
  const endTimeValid = useMemo(() => Boolean(currentEndDate && currentEndDate >= new Date()), [currentEndDate]);

  const modifyOrders = useCallback((): Promise<void> => {
    setLatestTime(new Date());
    return new Promise(resolve => {
      resolveRef.current = resolve;
      const ids: string[] = [];
      const newOrders = orders.map(order => {
        const orderData = order.orderData;
        const orderStrategy = strategies?.get(orderData.Strategy ?? 'Limit');
        const parameters = { ...orderData.Parameters, EndTime: currentEndDate };
        const marketAccounts = (orderData.Markets || [])
          .filter(m => m.MarketStatus !== OrderMarketStatusEnum.Disabled)
          .map(m => m.MarketAccount)
          .compact();

        // Watch orders for status changes (it can be Cancelled etc.)
        ids.push(orderData.OrderID);
        if (order?.status !== BulkModifyOrderStatusEnum.Success) {
          const clOrdID = uuid();
          ordersService.modify({
            clOrdID,
            symbol: orderData.Symbol,
            price: orderData.Price,
            orderID: orderData.OrderID,
            orderQty: orderData.OrderQty,
            marketAccounts,
            parameters,
            group: orderData?.Group ?? '',
            selectedStrategy: orderStrategy!,
            subAccount: orderData.SubAccount,
            subAccounts: tradableSubAccounts,
          });
          return {
            ...order,
            newOrderId: clOrdID,
            newEndTime: currentEndDate,
            status: BulkModifyOrderStatusEnum.Pending,
          };
        } else {
          return order;
        }
      });
      setOrderIDs(ids);
      setOrders(newOrders);
      setConfirmLoading(true);
    }).then(() => {
      addToast({
        text: `Orders End Time updated.`,
        variant: NotificationVariants.Positive,
      });
    });
  }, [orders, ordersService, strategies, tradableSubAccounts, currentEndDate, addToast]);

  const handleDateChange = useCallback(
    newValue => {
      setTouched(true);
      const value = isDuration(newValue.value) && isEmptyDuration(newValue.value) ? null : newValue.value;
      setEndTime(value);
    },
    [setEndTime]
  );

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    setShowOrdersLimit(INITIAL_ORDERS_LIMIT);
    timer.current = setInterval(() => setRelativeEndTime(new Date()), 1000);
    const ids: string[] = [];
    setLatestTime(new Date());
    setOrders(
      selectedOrders.map(order => {
        // Watch orders for status changes (it can be Cancelled etc.)
        ids.push(order.OrderID);
        return {
          orderData: order,
          newOrderId: '',
          status: undefined,
          error: '',
        };
      })
    );
    setOrderIDs(ids);
    setEndTime(null);
    setConfirmLoading(false);
  }, [selectedOrders, props.isOpen]);

  useEffect(() => {
    return () => {
      if (!props.isOpen) {
        timer.current && clearInterval(timer.current);
      }
    };
  }, [props.isOpen]);

  useEffect(() => {
    if (!props.isOpen || !confirmLoading) {
      return;
    }
    const sub = executionReportSub
      .pipe(
        map(res => {
          res.data = res.data.filter(
            report =>
              // Only look at reports for the orders we are modifying
              orderIDs.includes(report.OrderID) &&
              // And ignore reports that are older than the modify request
              parseDate(report.Timestamp) >= latestTime
          );
          return res;
        }),
        filter(res => res.data.length > 0),
        wsScanToDoubleMap({
          getKey1: (er: ExecutionReport) => er.OrderID,
          getKey2: (er: ExecutionReport) => er.ExecType,
          newInnerMapsEachUpdate: false,
          newOuterMapEachUpdate: false,
        })
      )
      .subscribe(executionReports => {
        orderIDs.forEach(orderID => {
          if (executionReports.has(orderID)) {
            const reports = executionReports.get(orderID)!;

            const errorReports = [
              ExecTypeEnum.Rejected,
              ExecTypeEnum.ReplaceRejected,
              ExecTypeEnum.Canceled,
              ExecTypeEnum.Expired,
              ExecTypeEnum.UpdateRejected,
            ]
              .map(execType => reports.get(execType))
              .filter(notEmpty);
            const successReports = [ExecTypeEnum.Replaced].map(execType => reports.get(execType)).filter(notEmpty);
            const error = errorReports.length > 0 ? errorReports[0].Text : '';

            setOrders(orders => {
              return orders.map(order => {
                // Order status changed
                if (order.orderData.OrderID === orderID) {
                  order.error = error;
                  order.status =
                    errorReports.length > 0
                      ? BulkModifyOrderStatusEnum.Error
                      : successReports.length > 0
                      ? BulkModifyOrderStatusEnum.Success
                      : order.status;
                }
                return order;
              });
            });
          }
        });
      });

    return () => {
      sub.unsubscribe();
    };
  }, [orderIDs, executionReportSub, props.isOpen, latestTime, confirmLoading]);

  useEffect(() => {
    if (!orders.length || !resolveRef.current) {
      return;
    }

    // All were processed correctly, there are no errors, we can close the dialog
    if (orders.every(o => o?.error === '' && o?.status === BulkModifyOrderStatusEnum.Success)) {
      resolveRef.current(true);
    }
    // All were processed and matched with ExecutionReport status but maybe errors? We can submit form again.
    if (orders.every(o => o?.status !== BulkModifyOrderStatusEnum.Pending)) {
      setConfirmLoading(false);
    }
  }, [orders]);

  return (
    <Dialog
      dataTestId="bulk-modify-dialog"
      {...props}
      title="Modify End Time"
      onConfirm={modifyOrders}
      confirmLabel={!confirmLoading && ordersWithErrors.length > 0 ? 'Retry Orders with Errors' : 'Update End Time'}
      confirmDisabled={!endTimeValid || !isAuthorized(ACTION.SUBMIT_ORDER)}
      confirmLoading={confirmLoading}
      cancelLabel="Cancel"
      alignContent="left"
      showClose={true}
      width={520}
    >
      <Flex gap="spacingDefault" flexDirection="column">
        <Box maxHeight={MAX_ORDERLIST_HEIGHT} overflow="hidden auto">
          <BulkModifyOrdersEndTime orders={ordersToModify.slice(0, showOrdersLimit)} />
        </Box>
        {ordersToModify.length > showOrdersLimit && (
          <Button ghost onClick={() => setShowOrdersLimit(ordersToModify.length)} width="100%">
            Show {ordersToModify.length - showOrdersLimit} More Orders
            <BulkEditOrderStatus
              status={
                orders.some(order => order.status === BulkModifyOrderStatusEnum.Pending)
                  ? BulkModifyOrderStatusEnum.Pending
                  : orders.every(order => order.status === BulkModifyOrderStatusEnum.Success)
                  ? BulkModifyOrderStatusEnum.Success
                  : null
              }
            />
          </Button>
        )}
        {ordersWithErrors.length > 0 && (
          <Box mt="spacingDefault">
            <Alert variant={AlertVariants.Warning} dismissable={false}>
              <Text size="fontSizeDefault" color="colorTextImportant">
                Unable to update the new end time for orders below.
              </Text>
            </Alert>
            <Box maxHeight={MAX_ORDERLIST_HEIGHT} overflow="hidden auto">
              <BulkModifyOrdersEndTime orders={ordersWithErrors} />
            </Box>
          </Box>
        )}
        <Divider mt="spacingDefault" mb="spacingDefault" />

        <FormGroup label="New End Time">
          <DateTimeDurationPicker
            dataTestId="bulk-modify-end-time-picker"
            tabIndex={-1}
            onChange={handleDateChange}
            value={typeof endTime === 'string' ? parseDate(endTime) : endTime}
            invalid={!endTimeValid && touched}
            portalize={true}
            calcDurationRelativeTo={relativeEndTime}
            width="100%"
          />
        </FormGroup>
      </Flex>
    </Dialog>
  );
};
