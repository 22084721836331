export type BalancesPieDirection = 'positive' | 'negative';

/**
 * Reachable from the Highcharts point.custom
 */
export interface BalancesPiePointCustom {
  /** The key of the group, for example an asset or a market account name */
  key: string;
  nonAbsValue: number;
  totalDirectionValueAbs: number;
  absValue: number;
  percentageOfDirection: number;
  direction: BalancesPieDirection;
}

/** Super simple function just to tuck away how ugly it is to grab these custom attributes... */
export function getPointCustomAttrs(point: Highcharts.Point): BalancesPiePointCustom | undefined {
  return point['custom'];
}
