import type { DefaultTheme } from 'styled-components';

export const AS_OF_PLOTLINE_ID = 'AsOfPlotline';

export const DYNAMIC_CHART_Z_INDEXES = {
  series: 1,
  plotLine: 10,
} as const;

export function snapshotDateToPlotLine(snapshotDate: string, theme: DefaultTheme) {
  return getPlotLine(new Date(snapshotDate).getTime(), theme);
}

export function getPlotLine(x: number, theme: DefaultTheme): Highcharts.XAxisPlotLinesOptions {
  return {
    value: x,
    width: 1,
    color: theme.colors.blue.default,
    dashStyle: 'Dash',
    zIndex: DYNAMIC_CHART_Z_INDEXES.plotLine,
    id: AS_OF_PLOTLINE_ID,
  } satisfies Highcharts.XAxisPlotLinesOptions;
}

/**
 * Given a Highcharts.Point, returns the raw underlying data behind this point. This is useful if you are working in a chart
 * where data points could be grouped and be representations of underlying data.
 *
 * If the function isn't able to resolve the underlying data for whatever reason, returns undefined.
 *
 * Assumes that the data point is grouped (can be enforced in the Highcharts options to always group), and assumes
 * that the raw data is in the form of [number, number] (x, y)
 */
export function getPointUnderlyingData(point: Highcharts.Point | undefined): [number, number | null][] | undefined {
  if (point == null) {
    return undefined;
  }
  if (!point.dataGroup) {
    // not grouped, return my own data
    return [[point.x, point.y ?? null]];
  }
  const underlyingRawSeriesData: [number, number][] | undefined = point.series.options['data'];
  if (!underlyingRawSeriesData) {
    return undefined;
  }

  const pointGroupInfo = point.dataGroup;
  if (!pointGroupInfo) {
    // We force grouping to be true so this shouldnt happen
    return undefined;
  }

  return underlyingRawSeriesData.slice(pointGroupInfo.start, pointGroupInfo.start + pointGroupInfo.length);
}
