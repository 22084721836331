import { Box, Divider, Flex, Text, type BoxProps } from '@talos/kyoko';
import styled, { css } from 'styled-components';

/**
 * A component for section
 * ```
 * {title}----------
 * ```
 */
export function SectionTitle({
  title,
  fontSize = 'fontSizeLarge',
  textTransform,
  fontWeight = 'bold',
  color = 'colorTextImportant',
  showNumber = false,
  ...rest
}: { title: string; showNumber?: boolean } & BoxProps) {
  return (
    <SectionTitleBox showNumber={showNumber} gap="spacingDefault" {...rest}>
      <TitleWrapper
        textTransform={textTransform}
        weight={fontWeight}
        color={color}
        fontSize={fontSize}
        whiteSpace="nowrap"
      >
        {title}
      </TitleWrapper>
      <Box w="100%" alignSelf="center">
        <Divider />
      </Box>
    </SectionTitleBox>
  );
}

const TitleWrapper = styled(Text)``;

const SectionTitleBox = styled(Flex)<{ showNumber: boolean }>`
  
  ${({ showNumber }) =>
    showNumber &&
    css`
  counter-increment: number;
  ${TitleWrapper} {
    &:before {
      color: ${({ theme }) => theme.colorTextImportant};
      content: counter(number) '. ';
    }
    `}
  }
`;
