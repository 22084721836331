import type { TreasuryLinkDirectionEnum, TreasuryLinkTypeEnum } from '@talos/kyoko';
import Big from 'big.js';
import { usePositionsFormContext } from 'containers/Portfolio/providers/PositionsFormProvider';
import type { MarketPositions, TransferInstruction } from 'containers/Portfolio/types';
import { compact } from 'lodash';
import { usePortfolioAccounts } from 'providers/PortfolioAccountsProvider';
import { useMemo } from 'react';
import { getEffectiveTreasuryLink } from '../../Addresses/hooks/useEffectiveTreasuryLink';

/**
 * Given positions, maps the positions to their respective TreasuryLinks potentially filtered by type and direction.
 * This is then used to, given the positions and the calculated links, map each position to a TransferInstruction
 * which are then returned. Either that or undefined if we're loading or something went wrong.
 * @param positions
 * @param linkTypeFilter
 * @param directionFilter
 * @returns a list of TransferInstructions or undefined
 */
export const useTransferInstructions = (
  marketPositions: MarketPositions[],
  linkTypeFilter: TreasuryLinkTypeEnum,
  directionFilter: TreasuryLinkDirectionEnum
): TransferInstruction[] | undefined => {
  const { treasuryLinksBySpecificnessKey } = usePortfolioAccounts();
  const { state: formState } = usePositionsFormContext();

  const transferInstructions = useMemo(() => {
    if (!treasuryLinksBySpecificnessKey) {
      return undefined;
    }

    let totalNewInstructionsList: TransferInstruction[] = [];

    marketPositions.forEach(mpos => {
      const linksAndPositions = mpos.Positions.filter(pos => {
        const formEntry = formState.get(mpos.MarketAccount)?.get(pos.Currency);
        if (!formEntry) {
          return false;
        }

        return formEntry.checked && formEntry.inputValue; // checked and valid input
      })
        .map(pos => {
          const inputValue = formState.get(mpos.MarketAccount)?.get(pos.Currency)?.inputValue as string; // we know this is true.

          const linkForSourceMarketAccount = getEffectiveTreasuryLink({
            sourceOrDestinationField: 'SourceMarketAccount',
            treasuryLinksBySpecificnessKey,
            type: linkTypeFilter,
            direction: directionFilter,
            marketAccount: mpos.MarketAccount,
            currency: pos.Currency,
          });

          const linkForDestinationMarketAccount = getEffectiveTreasuryLink({
            sourceOrDestinationField: 'DestinationMarketAccount',
            treasuryLinksBySpecificnessKey,
            type: linkTypeFilter,
            direction: directionFilter,
            marketAccount: mpos.MarketAccount,
            currency: pos.Currency,
          });

          return {
            SourceMarketAccount: linkForSourceMarketAccount?.SourceMarketAccount,
            DestinationMarketAccount: linkForDestinationMarketAccount?.DestinationMarketAccount,
            position: { ...pos, Amount: inputValue },
          };
        })
        .filter(sourceAndDestinationsDefined);

      const newTransferInstructions: TransferInstruction[] = compact(
        linksAndPositions.map(({ SourceMarketAccount, DestinationMarketAccount, position }) => {
          // Guard position.Amount for safety. If there is no amount theres no need to show a transfer instruction, omit
          const amount = position.Amount != null && position.Amount !== '' ? Big(position.Amount) : undefined;

          return amount
            ? {
                MarketAccount: mpos.MarketAccount,
                Amount: amount.abs().toFixed(),
                Currency: position.Currency,
                DestinationMarketAccount,
                SourceMarketAccount,
              }
            : undefined;
        })
      );

      totalNewInstructionsList = totalNewInstructionsList.concat(newTransferInstructions ?? []);
    });

    return totalNewInstructionsList;
  }, [treasuryLinksBySpecificnessKey, marketPositions, formState, linkTypeFilter, directionFilter]);

  return transferInstructions;
};

function sourceAndDestinationsDefined<T extends { SourceMarketAccount?: string; DestinationMarketAccount?: string }>(
  value: T
): value is T & { SourceMarketAccount: string; DestinationMarketAccount: string } {
  return value.SourceMarketAccount != null && value.DestinationMarketAccount != null;
}
