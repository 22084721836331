import Big from 'big.js';
import { cloneDeep, get, set } from 'lodash';
import { map, pipe, scan } from 'rxjs';
import type { MinimalSubscriptionResponse } from '../types/SubscriptionResponse';
import { toBig } from '../utils';

/**
 * An AggregationSpec defines what you'd like to aggregate, specifying the valuePath and currencyPath.
 */
export interface AggregationSpec {
  valuePath: string;
  currencyPath: string;
}

/**
 * A CurrencyAggregation is a representation of the `aggDeltaUpdatesByCurrency` pipe's "working" result,
 * containing the provided valuePath and currencyPath, and the resulting aggsByCurrency in a Map.
 */
export interface CurrencyAggregation {
  valuePath: string;
  currencyPath: string;
  aggsByCurrency: Map<string, Big>;
}

/**
 * The CurrencyAggregationOutput represents the output which will be used by end-users in for example blotters, where
 * both the valuePath and currencyPath have been aggregated to just one value and one currency output.
 */
export interface CurrencyAggregationOutput {
  valuePath: string;
  value: Big;
  currencyPath: string;
  currency: string;
}

/**
 * Diff is just an internal helper type to make things more readable.
 */
interface Diff {
  value: Big;
  valuePath: string;
  currency: string;
  currencyPath: string;
}

/**
 * A function returning an RxJS pipe which scans delta updates for some stream into an aggregate.
 * Only handles additive updates for now, no UpdateAction handling.
 *
 * The pipe aggregates values while taking the value's currency into consideration. As entities are aggregated,
 * values are aggregated within their own currencies. The result is an output of type CurrencyAggregation, where the
 * resulting aggregations for each found currency is represented in the aggsByCurrency map.
 *
 * Assumptions: this pipe assumes that the currency (resolved by currencyPath) on any given entity will not change across updates.
 * This case is currently not handled. If we want to handle this case in the future, we need to track which entities contribute to each currency's
 * aggregate, and then clear a currency aggregate when there are 0 contributing entities to a now-0 aggregate value.
 *
 * @param getUniqueKey a function to get a unique key for any item T
 * @param aggSpecs an array of specifications for what on entities of type T to aggregate on.
 */
export function aggDeltaUpdatesByCurrency<T>(getUniqueKey: (item: T) => string, aggSpecs: AggregationSpec[]) {
  return pipe(
    scan(
      ({ entries, currencyAggsByPath }, json: MinimalSubscriptionResponse<T>) => {
        applyUpdate(json, entries, currencyAggsByPath, getUniqueKey, aggSpecs);

        return { entries, currencyAggsByPath };
      },
      {
        entries: new Map<string, T>(),
        currencyAggsByPath: new Map<string, CurrencyAggregation>(),
      }
    ),
    map(({ currencyAggsByPath }) => currencyAggsByPath)
  );
}

function applyUpdate<T>(
  json: MinimalSubscriptionResponse<T>,
  entries: Map<string, T>,
  currencyAggsByPath: Map<string, CurrencyAggregation>,
  getUniqueKey: (item: T) => string,
  aggSpecs: AggregationSpec[]
): void {
  if (json.initial) {
    currencyAggsByPath.clear();
    entries.clear();
  }

  for (const data of json.data) {
    // Apply any diff
    const key = getUniqueKey(data);
    const maybeEntry = entries.get(key);
    calcAndApplyDiffs(maybeEntry, data, aggSpecs, currencyAggsByPath);

    // Store the new data as a used entry in the current aggs
    entries.set(key, data);
  }
}

/**
 * This function calculates and then mutates `currencyAggsByPath` directly with the found diff (if any).
 *
 * @param curr the current entry used in calculating the agg
 * @param next the update for the current entry
 * @param aggSpecs the aggregation specification
 * @param currencyAggsByPath the current state of the aggregation pipe
 */
export function calcAndApplyDiffs<T>(
  currentEntity: T | undefined,
  nextEntity: T,
  aggSpecs: AggregationSpec[],
  currencyAggsByPath: Map<string, CurrencyAggregation>
) {
  for (const { valuePath, currencyPath } of aggSpecs) {
    const nextValue: string | undefined = get(nextEntity, valuePath);
    const nextCurrency: string | undefined = get(nextEntity, currencyPath);
    // The value and/or currency might not exist on this entity T. If so, just skip.
    // We dont handle currencies going from defined -> undefined -> defined
    if (!nextValue || !nextCurrency) {
      continue;
    }

    const currentBig = toBig(get(currentEntity, valuePath));
    const currentCurrency: string | undefined = get(currentEntity, currencyPath);
    const nextBig = Big(nextValue);

    if (currentEntity == null || currentBig == null || currentCurrency == null) {
      // If there is no current entity, our change is purely additive. Apply directly.
      applyDiff(currencyAggsByPath, { value: nextBig, valuePath, currency: nextCurrency, currencyPath });
      continue;
    }

    if (currentCurrency !== nextCurrency) {
      // We're switching currencies. This means that we need to subtract from the currency we're leaving (in addition to adding to the currency we're going to)
      applyDiff(currencyAggsByPath, {
        value: currentBig.times(-1),
        valuePath: valuePath,
        currency: currentCurrency,
        currencyPath,
      });
      applyDiff(currencyAggsByPath, { value: nextBig, valuePath, currency: nextCurrency, currencyPath });
    } else {
      // Else there's a change within the existing currency. We need to calculate the difference between the two bigs and apply that.
      // Eg: nextBig = 50, currentBig = 40 -> value (diff) = 10
      applyDiff(currencyAggsByPath, {
        value: nextBig.minus(currentBig),
        valuePath,
        currency: nextCurrency,
        currencyPath,
      });
    }
  }
}

/**
 * Some logic to, given the current state of the agg pipe, apply the diff correctly.
 */
function applyDiff(currencyAggsByPath: Map<string, CurrencyAggregation>, diff: Diff) {
  const workingAgg = currencyAggsByPath.get(diff.valuePath);

  if (!workingAgg) {
    // The path doesn't exist yet, so just add the diff purely additively and return
    currencyAggsByPath.set(diff.valuePath, {
      valuePath: diff.valuePath,
      currencyPath: diff.currencyPath,
      aggsByCurrency: new Map([[diff.currency, diff.value]]),
    });
    return;
  }

  // The path exists, so now we just gotta apply the diff to the relevant currency of the workingAgg
  const currentValueForCurrency = workingAgg.aggsByCurrency.get(diff.currency);
  if (!currentValueForCurrency) {
    workingAgg.aggsByCurrency.set(diff.currency, diff.value);
  } else {
    workingAgg.aggsByCurrency.set(diff.currency, currentValueForCurrency.plus(diff.value));
  }
}

/**
 * A utility function to help the implementer merge a calculated agg with an object T.
 * The function will iterate over each key (path) of the agg Map and set that aggregated value on the onto object at the path.
 * The set value is a string (Big.toFixed() is called).
 * The function deepclones the provided "onto" object, meaning that no mutation is done.
 * @param agg
 * @param onto
 * @returns a deepcloned object where stringified agg Bigs have been set at each path in the agg map
 */
export function mergeAggWith<T extends object>(
  outputAggsByPath: Map<string, CurrencyAggregationOutput> | undefined,
  onto: T
): T {
  const clone = cloneDeep(onto);

  outputAggsByPath?.forEach(outputAgg => {
    set(clone, outputAgg.valuePath, outputAgg.value.toFixed());
    set(clone, outputAgg.currencyPath, outputAgg.currency);
  });

  return clone;
}
