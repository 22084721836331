import {
  Alert,
  AlertVariants,
  Box,
  Dialog,
  MixpanelEvent,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  type DialogProps,
} from '@talos/kyoko';
import { useCallback, useEffect, useState } from 'react';
import { useBulkOrderSubmissionSending } from '../../../../hooks/useBulkOrderSubmissionSending';
import { BulkClosePositionBlotter } from './Blotter/BulkClosePositionBlotter';
import { useBulkClosePositionBlotterObs } from './Blotter/useBulkClosePositionBlotterObs';
import { ConfirmCancellationPreview } from './ConfirmCancellationPreview';
import type { ClosablePosition } from './useGetBulkClosablePositions';

/**  */
interface BulkCloseDialogProps {
  /** The orders to send. These should be derived using utility hooks such as useGetBulkClosablePositions for example */
  closablePositions: ClosablePosition[];
  dialog: DialogProps;
}

export const BulkCloseDialog = ({ closablePositions: inputClosablePositions, dialog }: BulkCloseDialogProps) => {
  const mixpanel = useMixpanel();

  // Internal state of our closable positions. These are kept in a different piece of state since they can be modified by the user.
  const [closablePositions, setClosablePositions] = useState<ClosablePosition[]>([]);

  // Whenever the incoming order args change, we match that 1:1 in our internal state. This is like a reset.
  useEffect(() => {
    setClosablePositions(inputClosablePositions);
  }, [inputClosablePositions]);

  const updateOrderSymbol = useCallback(
    (updatedClOrdId: string, newSymbol: string) => {
      mixpanel.track(MixpanelEvent.EditBulkClosePositionSymbol);

      // Find the orderArg object for the clOrdId and update its symbol
      setClosablePositions(currentClosablePositions =>
        currentClosablePositions.map(current =>
          current.orderArgs.clOrdID === updatedClOrdId
            ? { ...current, orderArgs: { ...current.orderArgs, symbol: newSymbol } }
            : current
        )
      );
    },
    [mixpanel]
  );

  const handleRowDeleted = useCallback(
    (clOrdId: string) => {
      mixpanel.track(MixpanelEvent.RemoveBulkClosePositionRow);

      // Find the orderArg object for the clOrdId and remove it
      setClosablePositions(currentClosablePositions =>
        currentClosablePositions.filter(cp => cp.orderArgs.clOrdID !== clOrdId)
      );
    },
    [mixpanel]
  );

  const { sendOrders, clOrdIdsStates, isSending, stopSendingOrders } = useBulkOrderSubmissionSending();
  const dataObservable = useBulkClosePositionBlotterObs(closablePositions, clOrdIdsStates);

  const [hasSentOrders, setHasSentOrders] = useState(false);
  const [hasCancelled, setHasCancelled] = useState(false);

  const handleConfirm = useDynamicCallback(() => {
    if (hasSentOrders) {
      dialog.close();
    } else {
      mixpanel.track(MixpanelEvent.SendBulkClosePositionOrders);
      sendOrders(closablePositions.map(cp => cp.orderArgs));
      setHasSentOrders(true);
    }
  });

  useEffect(() => {
    if (dialog.isOpen) {
      // When we change to the open state, clear state
      setHasSentOrders(false);
      setHasCancelled(false);
    }
  }, [dialog.isOpen]);

  const nothingToSend = closablePositions.length === 0;

  const previewDisclosure = useDisclosure();

  const handleCancel = useCallback(() => {
    if (isSending) {
      previewDisclosure.open();
    } else {
      dialog.close();
    }
  }, [isSending, previewDisclosure, dialog]);

  const handleConfirmCancellation = useCallback(() => {
    stopSendingOrders();
    setHasCancelled(true);
    previewDisclosure.close();
  }, [stopSendingOrders, previewDisclosure]);

  const confirmDisabled = nothingToSend || hasCancelled;
  const confirmLabel = (() => {
    // I feel this is easier to read than a nested ternary. Put logic into a function and execute right away to resolve to its value.
    if (hasCancelled) {
      return 'Cannot be resumed';
    }

    if (nothingToSend) {
      return 'Nothing to send';
    }

    if (hasSentOrders) {
      return 'Done';
    }

    return 'Send Market Orders';
  })();

  return (
    <Dialog
      {...dialog}
      width={960} // magic number, this is the width to show all columns in initial preview in its current configuration
      minHeight="400px"
      confirmLabel={confirmLabel}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      closeOnConfirm={false}
      closeOnCancel={false}
      confirmDisabled={confirmDisabled}
      confirmLoading={isSending}
      closeOnClickOutside={false}
      closeOnEscape={false}
      preview={
        <ConfirmCancellationPreview
          previewDisclosure={previewDisclosure}
          onConfirmCancellation={handleConfirmCancellation}
        />
      }
      title="Bulk Close"
    >
      <Box h="100%" w="100%">
        <Alert variant={AlertVariants.Info} dismissable={false} mb="spacingDefault">
          Confirm that you would like to close the following {closablePositions.length} position(s) using Market Orders.
          After sending the orders, this dialog remains open. Closing this dialog stops order submission.
        </Alert>

        <Box h="300px" w="100%">
          <BulkClosePositionBlotter
            dataObservable={dataObservable}
            confirming={!hasSentOrders}
            onSymbolChanged={updateOrderSymbol}
            onRowDeleted={handleRowDeleted}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
