import { buildCreateApi, coreModule } from '@reduxjs/toolkit/query';
import { reactHooksModule } from '@reduxjs/toolkit/query/react';
import { createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import { AppStateContext } from './AppStateContext';

/**
 * Because we're using multiple stores, we have to create this custom `createApi` function to
 * ensure that the hooks are correctly bound to the correct store.
 *
 * - This needs a delayed start so that hooks come in properly
 */
export const buildCreateAppStateApi = () =>
  buildCreateApi(
    coreModule(),
    reactHooksModule({
      hooks: {
        //** Ideally this would use withTypes<> for better typing, but it presently causes a circular TS reference */
        useDispatch: createDispatchHook(AppStateContext),
        useSelector: createSelectorHook(AppStateContext),
        useStore: createStoreHook(AppStateContext),
      },
    })
  );
