import { RefDataCacheProvider as KyokoRefDataCacheProvider, useUserContext } from '@talos/kyoko';
import { OrgConfigurationKey, useOrgConfiguration } from './OrgConfigurationProvider';

export const RefDataCacheProvider = function RefDataCacheProvider({ children }: React.PropsWithChildren<unknown>) {
  const { isLoaded: isOrgConfigLoaded, getConfig } = useOrgConfiguration();
  const { user } = useUserContext();

  const enableCaching = getConfig(OrgConfigurationKey.EnableCache, true);
  const cacheVersion = getConfig(OrgConfigurationKey.CacheVersion, '1');

  if (!isOrgConfigLoaded) {
    return null;
  }

  // Include the org id and user id in the cache version to bust the cache when the user or org changes
  const versionStr = `${user.UsingOrgID ?? 0}-${user.ID}-${cacheVersion}`;

  return (
    <KyokoRefDataCacheProvider enableCaching={enableCaching} version={versionStr}>
      {children}
    </KyokoRefDataCacheProvider>
  );
};
