import { useEffect } from 'react';
import { useContextBlotterFilter } from '../../../providers/ContextBlotterFilterProvider/useContextBlotterFilter';

/**
 * Connects your local showZeroBalances state to any parent showZeroBalances state. If the parent state changes,
 * that change is reflected into your local state.
 *
 * Returns a boolean telling you whether or not you should render the ShowZeroBalances toggle.
 */
export function useShowZeroBalancesToggle(showZeroBalances: boolean, onChange: (value: boolean) => void) {
  const { additionalFilterState } = useContextBlotterFilter();
  const hasExternalZeroBalancesFilter = additionalFilterState && 'showZeroBalances' in additionalFilterState;

  useEffect(() => {
    if (
      additionalFilterState != null &&
      'showZeroBalances' in additionalFilterState &&
      showZeroBalances !== additionalFilterState.showZeroBalances
    ) {
      onChange(additionalFilterState.showZeroBalances ?? false);
    }
  }, [additionalFilterState, onChange, showZeroBalances]);

  return { showZeroBalancesToggle: !hasExternalZeroBalancesFilter };
}
