import {
  FilterClauseType,
  IconName,
  TransferStatusEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  useCurrenciesContext,
  useMarketAccountsContext,
  useMarketsContext,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type Transfer,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useUsers } from 'hooks/useUsers';
import { compact, isEqual, keys, uniq } from 'lodash';
import { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';

export interface TransfersBlotterFilter extends DateRangeFilter {
  Provider?: string[];
  Currency?: string[];
  Status?: string[];
  Submitter?: string[];
  Account?: string[];
}

export interface UseTransfersFilterParams<TData extends Transfer> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useTransfersFilter<TData extends Transfer>({ persistedBlotterTable }: UseTransfersFilterParams<TData>) {
  const { initialFilter, onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<TransfersBlotterFilter>(() => cleanupInitialFilterDateRange(initialFilter));
  const { marketAccountsByID } = useMarketAccountsContext();
  const { additionalFilterState } = useContextBlotterFilter();

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;

      if (additionalFilterState && 'MarketAccounts' in additionalFilterState) {
        // external MarketAccounts filter applies to the Account column filter
        filter.Account = additionalFilterState.MarketAccounts;
      }

      if (filterExistsAndExcludes(filter, 'Provider', data, 'Market')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Currency', data, 'Currency')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Status', data, 'Status')) {
        return false;
      }

      if (filterExistsAndExcludes(filter, 'Submitter', data, 'User')) {
        return false;
      }

      if (data && filter.Account?.length) {
        const fromMarketAccount = marketAccountsByID.get(data.FromMarketAccountID)?.Name ?? '';
        const toMarketAccount = marketAccountsByID.get(data.ToMarketAccountID)?.Name ?? '';
        if (!(filter.Account.includes(fromMarketAccount) || filter.Account.includes(toMarketAccount))) {
          return false;
        }
      }

      return true;
    },
    [additionalFilterState, filter, marketAccountsByID]
  );
  const { marketsByName, marketsList } = useMarketsContext();
  const { currenciesList } = useCurrenciesContext();
  const users = useUsers();
  const { marketAccountsList, marketAccountsByName } = useMarketAccountsContext();

  const filterableProperties = useMemo(() => {
    return compact<FilterableProperty<keyof TransfersBlotterFilter>>([
      {
        key: 'Provider',
        label: 'Provider',
        control: 'multi-select',
        icon: IconName.PresentationChartLine,
        options: marketsList.map(market => market.Name),
        getOptionLabel: (marketName: string) => marketsByName.get(marketName)?.DisplayName ?? marketName,
      },
      {
        key: 'Currency',
        label: 'Currency',
        icon: IconName.CurrencyDollar,
        options: currenciesList.map(cur => cur.Symbol),
        getOptionLabel: (option: string) => option,
      },
      {
        key: 'Status',
        label: 'Status',
        icon: IconName.CheckCircle,
        options: Object.keys(TransferStatusEnum),
        getOptionLabel: status => status,
      },
      {
        key: 'Submitter',
        label: 'Submitter',
        icon: IconName.User,
        options: uniq(users.map(u => u.Name) || []),
        getOptionLabel: (option: string) => option,
      },
      // if MarketAccounts filter is externally set, do not show the Account filter
      // TODO: Adjust this so it's directly compatible to useContextBlotterFilter's
      // filter cleanup function (due to the key difference, that can't be done right now)
      additionalFilterState && 'MarketAccounts' in additionalFilterState
        ? undefined
        : {
            key: 'Account',
            label: 'Account',
            icon: IconName.Identification,
            options: marketAccountsList.map(marketAccount => marketAccount.Name),
            getOptionLabel: (marketName: string) => marketAccountsByName.get(marketName)?.DisplayName ?? marketName,
          },
    ]);
  }, [
    additionalFilterState,
    currenciesList,
    marketAccountsByName,
    marketAccountsList,
    marketsByName,
    marketsList,
    users,
  ]);
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof TransfersBlotterFilter)[]).forEach((key: keyof TransfersBlotterFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
    clientSideFilter,
  };
}
