import { useMarketAccountsContext } from '@talos/kyoko';
import { SecurtyMasterBlotter } from './SecurityMasterBlotter';

// Wrapper here is to ensure that transfrom pipe has the market accounts loaded before being initialized
export function SecurityMaster() {
  const { marketAccountsByName } = useMarketAccountsContext();
  if (marketAccountsByName.size === 0) {
    return null;
  }
  return <SecurtyMasterBlotter marketAccountsByName={marketAccountsByName} />;
}
