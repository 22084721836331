import { Checkbox, HelpIcon, MixpanelEvent, useMixpanel } from '@talos/kyoko';
import { useTradingSettings } from '../../../../providers/TradingSettingsContext';

export function DefaultReduceFirst() {
  const mixpanel = useMixpanel();
  const { enableReduceFirst, setEnableReduceFirst } = useTradingSettings();

  return (
    <Checkbox
      id="enableReduceFirst"
      name="enableReduceFirst"
      checked={enableReduceFirst}
      onChange={e => {
        mixpanel.track(
          e.target.checked
            ? MixpanelEvent.EnableReduceFirstByDefaultEnabled
            : MixpanelEvent.EnableReduceFirstByDefaultDisabled
        );
        setEnableReduceFirst(e.target.checked);
      }}
    >
      Enable Reduce First by Default{' '}
      <HelpIcon tooltip="Enables the Reduce First toggle by default when Unified Liquidity is enabled on supporting symbols." />
    </Checkbox>
  );
}
