import { isOrderComplete, type Order, toBig, toBigWithDefault, useMarketDataStatistics } from '@talos/kyoko';
import type Big from 'big.js';
import { compact } from 'lodash';

interface UseDDHCalculateHedgeQtyProps {
  estimatedHedgeQty: Big | undefined;
  delta: Big | undefined;
}

export function useDDHCalculateHedgeQty(
  order: Order,
  usingMarketDataStatistics: boolean
): UseDDHCalculateHedgeQtyProps {
  const isComplete = isOrderComplete(order.OrdStatus);
  const { marketDataStatistics } = useMarketDataStatistics(
    !isComplete && usingMarketDataStatistics ? order.Symbol : '',
    compact(order.Markets.map(market => market.Market || '')),
    'DDH'
  );

  if (!marketDataStatistics || !marketDataStatistics.Delta || !marketDataStatistics.UnderlyingPrice) {
    return {
      estimatedHedgeQty: undefined,
      delta: undefined,
    };
  }

  const deltaBig = toBig(marketDataStatistics?.Delta);
  const underlyingPriceBig = toBig(marketDataStatistics?.UnderlyingPrice);

  if (!deltaBig || !underlyingPriceBig) {
    return {
      estimatedHedgeQty: undefined,
      delta: undefined,
    };
  }

  return {
    estimatedHedgeQty: toBigWithDefault(order.CumQty, 0).times(deltaBig).times(underlyingPriceBig),
    delta: deltaBig,
  };
}
