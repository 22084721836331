import {
  useEffectOnce,
  useUserContext,
  type DrawerProps,
  type MarketConfig,
  type MarketCredential,
} from '@talos/kyoko';
import { memo, useCallback, useEffect, useState } from 'react';
import { useFees } from '../../../providers';
import { EditCredentialDrawer } from './EditCredentialDrawer';

type CredentialDrawerProps = {
  marketCredentials: MarketCredential[];
  selectedCredential: MarketCredential | undefined;
  onSaved: () => void;
} & DrawerProps;

/**
 * Wrapper for the EditCredentialDrawer component that fetches the necessary data for the drawer and can be embedded
 * in other components with minimal setup paired with useEditCredentialDrawerWrapper.
 */
export const EditCredentialDrawerWrapper = memo((props: CredentialDrawerProps) => {
  const { marketCredentials, selectedCredential, onSaved, close } = props;
  const [marketConfigs, setMarketConfigs] = useState<MarketConfig[]>([]);
  const [mktAccsWithFees, setMktAccsWithFees] = useState<Set<string>>();
  const { listMarketConfigs, orgMetadata } = useUserContext();
  const { listMarketFees } = useFees();
  const [externalIP, setExternalIP] = useState('');

  const handleOnSaved = useCallback(() => {
    close();
    onSaved();
  }, [close, onSaved]);

  useEffect(() => {
    orgMetadata().then(metadata => setExternalIP(metadata['ExternalIP']));
  }, [orgMetadata]);

  useEffectOnce(() => {
    listMarketConfigs().then(configs => setMarketConfigs(configs));
    listMarketFees().then(fees => setMktAccsWithFees(new Set(fees.map(fee => fee.MarketAccount))));
  });

  if (!selectedCredential) {
    return null;
  }

  return (
    <EditCredentialDrawer
      {...props}
      marketCredentials={marketCredentials}
      marketConfigs={marketConfigs}
      externalIP={externalIP}
      onSaved={handleOnSaved}
      mktAccsWithFees={mktAccsWithFees}
      selectedCredential={selectedCredential!}
    />
  );
});
