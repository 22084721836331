import { FormControlSizes, MEDIA } from '@talos/kyoko';
import { useState } from 'react';
import { useEvent } from 'react-use';

export const useResponsiveSize = () => {
  const [formControlSize, setFormControlSize] = useState(
    window.innerWidth > MEDIA.medium ? FormControlSizes.Default : FormControlSizes.Small
  );
  useEvent('resize', () => {
    setFormControlSize(window.innerWidth > MEDIA.medium ? FormControlSizes.Default : FormControlSizes.Small);
  });
  return formControlSize;
};
