import { ACTION, useMarketAccountsContext, type Allocation } from '@talos/kyoko';
import { useMemo } from 'react';
import { usePermittedTradableMarketAccountsSet, useRoleAuth } from '../../../hooks';
import { useSubAccounts } from '../../../providers';

/**
 * This function returns all tradable market account names that the user has access to
 */
export function useAllTradableMarketAccountNames(): string[] {
  const { marketAccountsList } = useMarketAccountsContext();
  const { tradableSubAccounts } = useSubAccounts();
  const { isAuthorized } = useRoleAuth();

  const subAccountAllocations = useMemo<Allocation[] | -1>((): Allocation[] | -1 => {
    if (isAuthorized(ACTION.VIEW_ALL_MARKET_ACCOUNTS_IN_TRADING_CONTROLS)) {
      return -1;
    }
    const allocations = tradableSubAccounts?.map(sa => ({
      subAccount: sa.Name,
      value: '',
    }));
    return allocations?.[0] ? allocations : [{ subAccount: '', value: '100' }];
  }, [tradableSubAccounts, isAuthorized]);

  const permittedTradableMarketAccountsSet = usePermittedTradableMarketAccountsSet({
    subAccountAllocations,
    combineAllMarketAccounts: true,
  });

  return useMemo(() => {
    if (permittedTradableMarketAccountsSet === null) {
      return marketAccountsList.map(m => m.Name);
    } else {
      return Array.from(permittedTradableMarketAccountsSet);
    }
  }, [permittedTradableMarketAccountsSet, marketAccountsList]);
}
