import { isTalosUser, ROLE, type User } from '@talos/kyoko';
import { ALL_ROLE_ITEMS } from '../../../tokens/roles';

// Get available roles based on logged in user role
// and allowed roles for org.user.admin from runtime config
export const getEditableRoles = (user: User, whitelistedRoles: string) => {
  if (!user.Roles) {
    return [];
  }

  // If Talos user, just return all roles
  if ([ROLE.TALOS_ADMIN, ROLE.TALOS_ONBOARDING_ADMIN, ROLE.TALOS_SUPPORT].some(role => user.Roles?.includes(role))) {
    return ALL_ROLE_ITEMS.map(option => option.value);
  }

  if (user.Roles?.includes(ROLE.USER_ADMIN)) {
    const roles = whitelistedRoles.split(',');
    return ALL_ROLE_ITEMS.filter(role => roles.includes(role.value)).map(option => option.value);
  }

  return [];
};

export function getViewableRoles(user: User) {
  if (isTalosUser(user)) {
    return ALL_ROLE_ITEMS.map(option => option.value);
  }

  // Allow viewing all roles except for talos.* and marketplace.*
  return ALL_ROLE_ITEMS.filter(role => !role.value.startsWith('talos.') && !role.value.startsWith('marketplace.')).map(
    option => option.value
  );
}
