import { useEffect, useRef, useState } from 'react';

/**
 * useEffectOnce is a workaround for the StrictMode changes in React 18.
 * Where possible, use the standard useEffect hook and use return value to clean up in order to avoid this.
 * See https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e
 * @param effect The effect to run once, with a cleanup function to run once on component unmount
 */
export const useEffectOnce = (effect: Parameters<typeof useEffect>[0]) => {
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [_value, setValue] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setValue(val => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
