import { PRINCIPAL_MAIN_HEADER_HEIGHT, Toasts, Z_INDEX, useGlobalToasts } from '@talos/kyoko';
import styled from 'styled-components';
import { GLOBAL_TOASTS_WIDTH } from 'styles/dimensions';

export const GlobalToasts = () => {
  const globalToasts = useGlobalToasts();
  return (
    <GlobalToastsWrapper>
      <Toasts {...globalToasts} direction="column-reverse" />
    </GlobalToastsWrapper>
  );
};

const GlobalToastsWrapper = styled.div`
  position: fixed;
  top: ${({ theme }) => PRINCIPAL_MAIN_HEADER_HEIGHT - theme.spacingDefault}px;
  left: 50%;
  transform: translateX(-50%);
  width: ${GLOBAL_TOASTS_WIDTH}px;
  z-index: ${Z_INDEX.globalToast};
`;
