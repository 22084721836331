import {
  Box,
  DecisionStatusEnum,
  Flex,
  LoaderSizes,
  LoaderTalos,
  ParametersTable,
  Side,
  SideEnum,
  SmartSummary,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useSecurity,
} from '@talos/kyoko';
import { useOrderValidation } from 'hooks';
import { useStrategies, useSubAccounts } from 'providers';
import type { OMSForm } from 'providers/OMSContext.types';
import { memo, useEffect } from 'react';
import { cleanParametersForOrder } from 'utils/order';
import { BulkOrderErrors, BulkOrderWrapper } from '../styles';
import { useOrderImportContext, type OrderErrors } from './OrderImportProvider';

export interface OrderValidationRowArgs {
  order: OMSForm;
  orderErrors: OrderErrors;
  isSending: boolean;
}

export const OrderValidationRow = memo(({ order, orderErrors, isSending }: OrderValidationRowArgs) => {
  const { strategiesList: strategies, strategiesByName } = useStrategies();
  const security = useSecurity(order.symbol);
  const { tradableSubAccounts } = useSubAccounts();
  const orderImportContext = useOrderImportContext();
  const { errors, warnings } = useOrderValidation({
    order: order,
    subAccounts: tradableSubAccounts,
    strategies,
    subAccountAllocations: order.subAccountAllocations || [],
    allocationValueType: order.allocationValueType || '',
    buyingPower: undefined,
    validateBuyingPower: false,
    validateMinSize: false,
    isModifying: false,
  });
  const strategy = strategiesByName.get(order.strategy || '');
  const parameters =
    strategy !== undefined
      ? cleanParametersForOrder({
          parameters: order.parameters,
          selectedStrategy: strategy,
        })
      : {};

  const displayOrder = {
    AmountCurrency: security?.QuoteCurrency,
    Strategy: order.strategy,
    OrdType: order.ordType,
    Symbol: order.symbol,
    Currency: order.orderCurrency,
    Side: order.orderSide || SideEnum.Buy,
    Parameters: parameters,
    OrderQty: order.quantity,
    Price: order.price,
    DecisionStatus: order.initialDecisionStatus,
  };

  useEffect(() => {
    Object.keys(warnings).forEach(warning => {
      if (!orderErrors[warning]) {
        orderImportContext.setOrderError(order, warning, warnings[warning]);
      }
    });
    Object.keys(errors).forEach(error => {
      if (!orderErrors[error]) {
        orderImportContext.setOrderError(order, error, errors[error]);
      }
    });
  }, [errors, order, orderErrors, orderImportContext, orderImportContext.setOrderError, warnings]);

  return (
    <BulkOrderWrapper>
      <Flex flexDirection="column" gap="spacingDefault" flex={1}>
        <Box ml="-spacingSmall" mr="-spacingSmall">
          <Table w="100%">
            <Tbody>
              <Tr>
                <Td colSpan={2}>
                  <Flex alignItems="center" gap="spacingSmall">
                    <Side side={displayOrder.Side}>{displayOrder.Side}</Side>
                    {displayOrder.Symbol}
                    {strategy && (
                      <Text color="colorTextSubtle" transform="uppercase">
                        {strategy?.DisplayName}
                      </Text>
                    )}
                    <Tooltip showUnderline={true} tooltip={<ParametersTable order={displayOrder} />} placement="bottom">
                      <Text color="colorTextSubtle" size="fontSizeSmall">
                        Params
                      </Text>
                    </Tooltip>
                    {displayOrder.DecisionStatus === DecisionStatusEnum.Staged && (
                      <Text size="fontSizeSmall" transform="uppercase">
                        Staged
                      </Text>
                    )}
                  </Flex>
                </Td>
                <Td rowSpan={2}>
                  <Box flex={`0 0 ${LoaderSizes.SMALL}px`}>{isSending && <LoaderTalos size={LoaderSizes.SMALL} />}</Box>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={2}>
                  <SmartSummary entity={displayOrder} type="order" showSide={false} />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <BulkOrderErrors data-testid="order-import-errors">
        {Object.keys(orderErrors).map(prop => {
          return <div key={prop}>{orderErrors[prop]}</div>;
        })}
      </BulkOrderErrors>
    </BulkOrderWrapper>
  );
});
