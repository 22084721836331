import { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { useRestBlotterTable, type Column } from '../BlotterTable';
import { EntityAdminPage } from './EntityAdminPage';
import type { EntityPageClass, EntityPageRecord } from './types';
import { useEntityAdminPage, type useEntityAdminPageProps } from './useEntityAdminPage';

export interface EntityAdminPageRESTProps<T extends EntityPageRecord> extends useEntityAdminPageProps<T> {
  /** The initial path for the Websocket API endpoint. */
  path: string;

  /** Callback function when columns are ready. */
  onColumnsReady?: (columns: Column<T>[]) => void;

  /** The number of records to paginate until */
  blotterRowCount?: number;

  /** The number of records to paginate at a time */
  paginationSize?: number;
}

export const EntityAdminPageREST = <T extends EntityPageRecord>({
  onColumnsReady,
  blotterRowCount,
  paginationSize,
  ...props
}: EntityAdminPageRESTProps<T>) => {
  const blotterTableApiRef = useRef<{ refresh?: (force?: boolean) => void }>({});

  const { blotterTableProps, ...entityAdminPage } = useEntityAdminPage<T>({
    ...props,
    blotterTableApiRef,
  });

  const blotterTableREST = useRestBlotterTable<EntityPageClass<T>>({
    // "limit" here is misleading - it's actually the number of records to fetch at a time
    request: { path: props.path, limit: paginationSize },
    onColumnsReady,
    blotterRowCount,
    ...blotterTableProps,
  });

  useEffectOnce(() => {
    blotterTableApiRef.current.refresh = blotterTableREST.refresh;
  });

  return <EntityAdminPage<T> blotterTable={blotterTableREST} {...entityAdminPage} />;
};
