import { ROLE } from '../types/Auth';
import type { User } from '../types/User';

const TALOS_EMAIL_DOMAIN = '@talostrading.com';
const TALOS_PROXY_EMAIL_DOMAIN = 'talostrading.localhost:3000';
const TALOS_EMAIL_PRODMON_PREFIX = 'prodmon+';
const TALOS_NAME_PRODMON_PREFIX = 'Monitor-';

export function isUserProdMon(user: Pick<User, 'Name' | 'Email'>): boolean {
  const { Name, Email } = user;

  return (
    (Email.endsWith(TALOS_EMAIL_DOMAIN) || Email.endsWith(TALOS_PROXY_EMAIL_DOMAIN)) &&
    Email.startsWith(TALOS_EMAIL_PRODMON_PREFIX) &&
    Name.startsWith(TALOS_NAME_PRODMON_PREFIX)
  );
}

// When editing and creating new users, we can check that the user we are creating is okay.
// We specify some app-wide rules in here to reuse.
export function isUserUpsertAllowed(user: { Name: string; Email: string; Roles?: string[] }): {
  ok: boolean;
  message?: string;
} {
  // 1. If the user is a prodmon user we are not allowed to
  if (isUserProdMon(user) && user.Roles?.includes(ROLE.TALOS_ADMIN)) {
    return { ok: false, message: 'Talos.Admin cannot be associated with Prodmon users.' };
  }

  return { ok: true };
}
