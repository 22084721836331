import {
  TreasuryLinkDirectionEnum,
  TreasuryLinkTypeEnum,
  getTreasuryLinkSpecificnessKey,
  type TreasuryLink,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { usePortfolioAccounts } from '../../../../providers/PortfolioAccountsProvider';
import type { TreasuryLinkConstraints } from './shared';

/**
 * Given a set of constraints, get all _relevant_ links in order of most specific to least specific.
 */
export const useRelevantTreasuryLinks = (constraints: TreasuryLinkConstraints): TreasuryLink[] | undefined => {
  const { treasuryLinksBySpecificnessKey } = usePortfolioAccounts();
  return useMemo(() => {
    if (!treasuryLinksBySpecificnessKey) {
      return undefined;
    }

    return getRelevantTreasuryLinks({ treasuryLinksBySpecificnessKey: treasuryLinksBySpecificnessKey, ...constraints });
  }, [treasuryLinksBySpecificnessKey, constraints]);
};

interface GetRelevantTreasuryLinksParams extends TreasuryLinkConstraints {
  treasuryLinksBySpecificnessKey: Map<string, Map<string, TreasuryLink>>;
}

/**
 * Non-hook version of useRelevantTreasuryLinks.
 *
 * Given a set of constraints, get all _relevant_ links in order of most specific to least specific.
 */
export function getRelevantTreasuryLinks({
  treasuryLinksBySpecificnessKey,
  ...constraints
}: GetRelevantTreasuryLinksParams): TreasuryLink[] {
  const { globalLinkKey, globalWithCurrencyLinkKey, marketAccountLinkKey, marketAccountWithCurrencyLinkKey } =
    getAllTreasuryLinkKeys(constraints);

  /*
   * This is where we need to add some smarts.
   *
   * Depending on the constraints passed in, we can figure out what we're asking for.
   * Post 2.45.0, there can now be several links per SpecificnessKey. Previously, the SpecificnessKey was unique.
   *
   * However, this new non-uniqueness only lives in one specific case: Customer Deposit TreasuryLinks at the MarketAccountAndCurrency Specificness level
   *
   * The business case for this is that you can define several TreasuryLinks for each CustomerTransfer+Inbound(Deposit)+MarketAccount+Currency
   */

  const atMarketAccountAndCurrencySpecificnessLevel = marketAccountWithCurrencyLinkKey != null;
  const marketAccountCurrencyLinks = atMarketAccountAndCurrencySpecificnessLevel
    ? treasuryLinksBySpecificnessKey?.get(marketAccountWithCurrencyLinkKey)
    : undefined;

  let relevantMarketAccountCurrencyLink: TreasuryLink | undefined = undefined;
  if (marketAccountCurrencyLinks) {
    if (constraints.linkID) {
      // The implementer has passed in a specific LinkID. In this case, the only relevant link at this level is the link with this LinkID exactly.
      relevantMarketAccountCurrencyLink = marketAccountCurrencyLinks.get(constraints.linkID);
    } else {
      // We are at the MarketAccount+Currency specificness level, but we do not have any LinkID provided.
      // Recall that multiple links per specificness is only possible for the Customer Deposit case (and at the MarketAccount+Currency Specificness level).
      const multipleLinksPossible =
        constraints.type === TreasuryLinkTypeEnum.CustomerTransfer &&
        constraints.direction === TreasuryLinkDirectionEnum.Inbound;

      if (!multipleLinksPossible) {
        // If multiple links are not possible, we can happily return the first - and what should be the only - Link found at this level.
        relevantMarketAccountCurrencyLink = [...marketAccountCurrencyLinks.values()].at(0); // either link or undefined, both OK
      }
    }
  }

  const remainingRelevantTreasuryLinks = compact([marketAccountLinkKey, globalWithCurrencyLinkKey, globalLinkKey]).map(
    key => {
      const linksByLinkID = treasuryLinksBySpecificnessKey.get(key);
      if (!linksByLinkID) {
        return undefined;
      }
      // In this case here, the model returns a Map of TreasuryLinks by LinkID, however the business model is that at these remaining levels of specificness,
      // there is only one link per. So we just grab the first element. So we're either returning a TreasuryLink, or undefined, which is fine too (we compact the array).
      return [...linksByLinkID.values()].at(0);
    }
  );

  return compact([relevantMarketAccountCurrencyLink, ...remainingRelevantTreasuryLinks]);
}

/**
 * Given the constraints provided, returns the keys we can produce
 *
 * A key is not produced if the constraints needed for that specificness key are not all provided
 */
export function getAllTreasuryLinkKeys({ type, direction, currency, marketAccount }: TreasuryLinkConstraints): {
  globalLinkKey?: string;
  globalWithCurrencyLinkKey?: string;
  marketAccountLinkKey?: string;
  marketAccountWithCurrencyLinkKey?: string;
} {
  const globalLinkKey =
    type && direction
      ? getTreasuryLinkSpecificnessKey({
          Type: type,
          Direction: direction,
        })
      : undefined;

  const globalWithCurrencyLinkKey =
    type && direction && currency
      ? getTreasuryLinkSpecificnessKey({
          Type: type,
          Direction: direction,
          Currency: currency,
        })
      : undefined;

  const marketAccountLinkKey =
    marketAccount && type && direction
      ? getTreasuryLinkSpecificnessKey({
          MarketAccount: marketAccount,
          Type: type,
          Direction: direction,
        })
      : undefined;

  const marketAccountWithCurrencyLinkKey =
    marketAccount && type && direction && currency
      ? getTreasuryLinkSpecificnessKey({
          MarketAccount: marketAccount,
          Type: type,
          Direction: direction,
          Currency: currency,
        })
      : undefined;

  return {
    globalLinkKey,
    globalWithCurrencyLinkKey,
    marketAccountLinkKey,
    marketAccountWithCurrencyLinkKey,
  };
}
