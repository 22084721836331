export interface MarketDistributionItem {
  id: string;
  y: number;
  color: string;
  symbol: string;
  market: string;
  name?: string;
  cumQty?: string;
  orderQty?: string;
  avgPxAllIn?: string;
  avgPx?: string;
  cumFee?: string;
  feeCurrency?: string;
  price?: string;
  leavesQty?: string;
  priceCurrency?: string;
  sizeCurrency?: string;
  sizeIncrement?: string;
  priceIncrement?: string;
}
export const REMAINDER = 'REMAINDER';
