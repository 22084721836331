import { createGlobalState } from 'react-use';

/**
 * This is just for testing purposes. Don't use!
 * Prefer using the 'type' property from useTheme() instead.
 * @deprecated
 */
export const useThemeType = createGlobalState<'light' | 'dark'>(
  localStorage.getItem('theme') === 'light' ? 'light' : 'dark'
);
