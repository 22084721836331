import {
  Box,
  Flex,
  FormControlSizes,
  HStack,
  IconButton,
  IconName,
  isTalosUser,
  Modal,
  type ModalProps,
  Text,
  useDisclosure,
  useDynamicCallback,
  usePortal,
  VStack,
} from '@talos/kyoko';
import { BlotterIndent } from 'containers/Portfolio/PortfolioManagement/components/BlotterIndent';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useUser } from '../../../../hooks';
import { BreakDetails } from '../BreakDetails';
import type { ReconTab } from '../tabs';
import type { BreakDetailsState } from '../types';
import type { ReconAssetRow } from './blotter/reconCheckpointRows';
import { SubAccountReconOverviewBlotter } from './blotter/SubAccountReconOverviewBlotter';
import { ReconCheckpointNavigation } from './ReconCheckpointNavigation';
import { RECON_OVERVIEW_BLOTTER_PORTAL_ID } from './tokens';
import { useReconOverviewDataObsWithNav } from './useReconOverviewDataObsWithNav';

interface ReconOverviewProps {
  tab: ReconTab;
  updateTab: (tab: ReconTab) => void;
}

export const ReconOverview = ({ tab, updateTab }: ReconOverviewProps) => {
  const detailModal = useDisclosure();
  const [selectedBreak, setSelectedBreak] = useState<ReconAssetRow>();
  const user = useUser();

  const showZeroBalances = tab.showZeroBalances ?? false;
  const setShowZeroBalances = useCallback(
    (newShowZeroBalances: boolean) => {
      const newTab = { ...tab, showZeroBalances: newShowZeroBalances };
      updateTab(newTab);
    },
    [updateTab, tab]
  );

  const handleViewBreakDetails = useDynamicCallback((assetRow: ReconAssetRow) => {
    setSelectedBreak(assetRow);
    detailModal.open();
  });

  const { setPortalRef: filtersContainerRef } = usePortal(RECON_OVERVIEW_BLOTTER_PORTAL_ID);
  const theme = useTheme();

  const { dataObservable, dataIsLoading, reconCheckpointNav, dataStartTime, dataEndTime, dataLastUpdateTime } =
    useReconOverviewDataObsWithNav({ showZeroBalances, tag: 'overview' });

  const showCheckpointNav = isTalosUser(user);

  return (
    <>
      <HStack h="100%" w="100%" gap="2px">
        <VStack h="100%" w="100%">
          <HStack
            w="100%"
            py="spacingSmall"
            px="spacingComfortable"
            justifyContent="space-between"
            gap="spacingDefault"
            borderBottom={`2px solid ${theme.backgroundBody}`}
            background="colors.gray.main"
          >
            {showCheckpointNav ? (
              <ReconCheckpointNavigation
                startTime={dataStartTime}
                endTime={dataEndTime}
                lastUpdateTime={dataLastUpdateTime}
                isLoading={dataIsLoading}
                {...reconCheckpointNav}
              />
            ) : (
              // render empty div here to keep HStack behavior consistent between the two cases
              <div />
            )}
            <Box ref={filtersContainerRef} />
          </HStack>
          <Box flex="auto" w="100%">
            <BlotterIndent flex="auto" h="100%">
              <SubAccountReconOverviewBlotter
                dataObservable={dataObservable}
                onViewCheckpointDetails={handleViewBreakDetails}
                blotterID={`portfolio/reconciliation/${tab.id}/recon-overview`}
                showZeroBalances={showZeroBalances}
                setShowZeroBalances={setShowZeroBalances}
              />
            </BlotterIndent>
          </Box>
        </VStack>
      </HStack>
      <BreakDetailsModal selectedBreak={selectedBreak} {...detailModal} />
    </>
  );
};

type BreakDetailsModalProps = {
  selectedBreak: ReconAssetRow | undefined;
} & ModalProps;

const BreakDetailsModal = ({ selectedBreak, ...modalProps }: BreakDetailsModalProps) => {
  const breakDetailsState: BreakDetailsState | undefined = useMemo(() => {
    if (!selectedBreak) {
      return undefined;
    }

    return {
      filter: {},
      checkpointID: selectedBreak.checkpointID,
      subAccounts: selectedBreak.SubAccounts,
      asset: selectedBreak.Asset,
      startTime: selectedBreak.StartTime,
      endTime: selectedBreak.EndTime,
      lastUpdateTime: selectedBreak.LastUpdateTime,
    };
  }, [selectedBreak]);

  const modalContainerRef = useRef<HTMLDivElement>(null);

  if (!breakDetailsState) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      minHeight="90vh"
      maxHeight="90vh"
      minWidth="90vw"
      maxWidth="90vw"
      position="relative"
      data-testid="sub-acc-recon-modal"
      closeOnClickOutside={false} // can lead to bad ux, just use the top-right x button
    >
      <Flex flexDirection="column" ref={modalContainerRef} position="absolute" top="0" bottom="0" right="0" left="0">
        {/* PopperHeader component doesnt exist in master so build our own for now */}
        <HStack
          justifyContent="space-between"
          p="spacingSmall"
          pl="spacingDefault"
          background="backgroundModalHeader"
          borderBottom="2px solid"
          borderColor="backgroundBody"
        >
          <Text color="colorTextImportant">Reconciliation: Detailed View</Text>
          <IconButton
            ghost
            color="colorTextSubtle"
            size={FormControlSizes.Small}
            icon={IconName.Close}
            onClick={() => modalProps.close()}
            data-testid="close-dialog-button"
          />
        </HStack>
        <BreakDetails state={breakDetailsState} modalContainerRef={modalContainerRef} />
      </Flex>
    </Modal>
  );
};
