export const CONNECTION_TYPE_UI = {
  MarketData: 'Market Data', // markets.yml
  Trading: 'Orders', // markets.yml
  BalanceUpdates: 'Balances', // markets.yml
  MarketDataAndTrading: 'Trading', // markets.yml
  MarketDataTradingAndRFQ: 'Trading & RFQ', // markets.yml
  TransfersAndBalanceUpdates: 'Balances & Transfers',

  Orders: 'Orders', // websocket
  RFQ: 'RFQ', // websocket
  Balances: 'Balances', // websocket
  Transfers: 'Transfers', // websocket
};
