import {
  Flex,
  Icon,
  IconName,
  InlineFormattedNumber,
  Popover,
  Text,
  TreasuryLinkDirectionEnum,
  TreasuryLinkTypeEnum,
  useMarketAccountsContext,
  usePopoverState,
  type Currency,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { useAvailableBalance } from './useAvailableBalance';

interface AvailableBalanceCellProps {
  marketAccount: string;
  currency: Currency;
}

export const AvailableBalanceCell = ({ marketAccount, currency }: AvailableBalanceCellProps) => {
  const { availableBalance, sourceMarketAccount } = useAvailableBalance(
    marketAccount,
    currency.Symbol,
    TreasuryLinkTypeEnum.OTCSettlement,
    TreasuryLinkDirectionEnum.Outbound
  );
  const { marketAccountsByName } = useMarketAccountsContext();

  const sourceMarketAccountDisplayName = useMemo(() => {
    if (!sourceMarketAccount) {
      return undefined;
    }
    return marketAccountsByName.get(sourceMarketAccount)?.DisplayName;
  }, [sourceMarketAccount, marketAccountsByName]);

  const popover = usePopoverState({
    trigger: 'hover',
    placement: 'top',
  });

  return (
    <>
      {availableBalance !== undefined ? (
        <InlineFormattedNumber
          number={availableBalance}
          currency={currency?.Symbol}
          increment={currency?.DefaultIncrement}
        />
      ) : (
        <Flex alignItems="center" gap="spacingSmall">
          <Text>None</Text>
          <Popover {...popover}>
            <Icon icon={IconName.InformationCircle} />
            {sourceMarketAccountDisplayName ? (
              <Text>
                No available {currency.Symbol} balances found for account {sourceMarketAccountDisplayName}
              </Text>
            ) : (
              // Shouldnt happen
              <Text>Something went wrong when trying to lookup your {currency.Symbol} balance for this account</Text>
            )}
          </Popover>
        </Flex>
      )}
    </>
  );
};
