import {
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  EXPANDABLE_HEADER_HEIGHT,
  FormControlSizes,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  Toggle,
  VStack,
  columnTypes,
  createCSVFileName,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  selectAll,
  useAggDeltaUpdatesPipe,
  useBlotterTable,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  type Leaves,
  type Position,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridOptions,
} from 'ag-grid-community';
import { CONTROL_TOOLTIPS } from 'containers/Blotters/tooltips';
import { compact } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { colIDToFilterBuilderKey as positionsColIDToFilterBuilderKey } from '../../../Blotters/PositionsV3/types';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { useShowZeroBalancesToggle } from '../../PortfolioManagement/useShowZeroBalancesContextualToggle';
import { useOperationsOverviewFilters } from '../providers/OperationsOverviewFiltersProvider';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import { useOperationsOverviewPositions } from '../providers/OperationsOverviewPositionsProvider';
import { useOperationsOverviewContext } from '../providers/OperationsOverviewStateProvider';
import { OperationsOverviewActionType } from '../reducer';
import {
  useOperationsOverviewBlotterColumns,
  type OperationsOverviewBlotterColumnSpecification,
} from './useOperationsOverviewBlotterColumns';

const ABOVE_BLOTTER_MENU_ROW_HEIGHT = '40px';

export const aggSpecs = [
  { valuePath: 'Equivalent.Amount', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.Equity', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.UnrealizedPnL', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.InitialMargin', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.MaintenanceMargin', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.UnrealizedPnL', currencyPath: 'Equivalent.Currency' },
] satisfies { valuePath: Leaves<Position>; currencyPath: Leaves<Position> }[];

function getPositionKey(position: Position) {
  return position.rowID;
}

interface OperationsOverviewBlotterProps {
  blotterID: string;
  defaultColumns: OperationsOverviewBlotterColumnSpecification[];
  defaultRowGroupsOpened: RowGroupsOpenedState;
}

export const OperationsOverviewBlotter = ({
  blotterID,
  defaultColumns,
  defaultRowGroupsOpened,
}: OperationsOverviewBlotterProps) => {
  const mixpanel = useMixpanel();
  const columns = useOperationsOverviewBlotterColumns(defaultColumns);

  const { filterableProperties } = useOperationsOverviewFilters();
  const { state, dispatch } = useOperationsOverviewContext();

  const { openClause, setGoToGroupRow } = useOperationsOverviewInteractions();

  const { positionsObs } = useOperationsOverviewPositions();

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams<Position>) => {
    if (openClause) {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey: positionsColIDToFilterBuilderKey,
        openClause,
        mixpanel,
      });
    }

    return [];
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems: GetContextMenuItems<Position> = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const items = [selectAll(params, mixpanel), 'separator', ...getDefaultContextMenuItems(params)];

    if (openClause) {
      items.unshift(
        ...filterByCellValueMenuItem({
          params,
          filterableProperties,
          openClause,
          colIDToFilterBuilderKey: positionsColIDToFilterBuilderKey,
          mixpanel,
        })
      );
    }

    return compact(items);
  });

  const persisted = usePersistedBlotterTable(blotterID, {
    persistFilter: false, // this is handled elsewhere in operations overview
    columns,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const pinnedRowDataPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getPositionKey,
    aggSpecs: aggSpecs,
  });

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        title: 'Grouping',
        editable: false,
        hide: false,
        width: 250,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const blotter = useBlotterTable<Position>({
    dataObservable: positionsObs,
    pinnedRowDataPipe: pinnedRowDataPipe,
    showPinnedRows: true,
    rowID: 'rowID' satisfies keyof Position,
    groupDisplayType: 'singleColumn',
    getExtraMainMenuItems,
    getContextMenuItems,
    sort: persisted.initialSort,
    columns: persisted.columns,
    onColumnsChanged: persisted.onColumnsChanged,
    onSortChanged: persisted.onSortChanged,
    selection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
    ...persistedRowGroupsOpened.blotterTableProps,
    // sticky group rows becomes too much for this blotter with our level of indentation. Both performance-wise (saw some big lags) and how much it flickered. AgGrid needs to work more on this one.
    ...({ showOpenedGroup: true, autoGroupColumnDef, suppressGroupRowsSticky: true } satisfies GridOptions),
  });

  const { expandAllGroups, collapseAllGroups, highlightGroupRow } = blotter;

  useEffect(() => {
    setGoToGroupRow(() => highlightGroupRow);
  }, [setGoToGroupRow, highlightGroupRow]);

  const handleExport = useDynamicCallback(() => {
    blotter.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Operations - Overview',
      }),
    });
  });

  const handleShowZeroBalancesChange = useCallback(
    (showZeroBalances: boolean) => {
      dispatch({
        type: OperationsOverviewActionType.ShowZeroBalancesChange,
        payload: {
          showZeroBalances,
        },
      });
    },
    [dispatch]
  );

  const { showZeroBalancesToggle } = useShowZeroBalancesToggle(state.showZeroBalances, handleShowZeroBalancesChange);

  return (
    <Box h={`calc(100% - ${EXPANDABLE_HEADER_HEIGHT}px)`}>
      <HStack
        data-testid="treasury-management-blotter-controls"
        justifyContent="space-between"
        gap="spacingComfortable"
        px="spacingDefault"
        h={ABOVE_BLOTTER_MENU_ROW_HEIGHT}
      >
        <AsOfDateBadge snapshotDate={null} /> {/* live */}
        <HStack justifyContent="flex-end" w="100%" gap="spacingSmall">
          <Input
            prefix={<Icon icon={IconName.Search} />}
            clearable={true}
            size={FormControlSizes.Small}
            width="120px"
            value={blotter.blotterTableFiltersProps.quickFilterText}
            onChange={e => blotter.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <IconButton
            icon={IconName.ListExpand}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={expandAllGroups}
            data-testid="expand-all-button"
          />
          <IconButton
            icon={IconName.ListCollapse}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={collapseAllGroups}
          />

          {showZeroBalancesToggle && (
            <>
              <Divider orientation="vertical" mx="spacingSmall" />
              <Toggle
                tooltip={CONTROL_TOOLTIPS.showZeroBalances}
                size={FormControlSizes.Small}
                checked={state.showZeroBalances}
                onChange={handleShowZeroBalancesChange}
                label="Show Zero Balances"
              />
            </>
          )}
          <Divider orientation="vertical" mx="spacingSmall" />
          <Button startIcon={IconName.DocumentUpload} size={FormControlSizes.Small} onClick={handleExport}>
            Export
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <VStack h={`calc(100% - ${ABOVE_BLOTTER_MENU_ROW_HEIGHT})`} w="100%">
        <Box px="spacingDefault" flex="1" w="100%">
          <BlotterTable {...blotter} />
        </Box>
      </VStack>
    </Box>
  );
};
