import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { SerializedDockview } from 'dockview';
import type { AppState } from '../../providers/AppStateProvider/types';
import type { LayoutSpec, PanelConfig, PanelType, Section } from '../../types/LayoutConfig';
import type { MinimizedPanel } from '../Layout/types';
import { getDefaultState } from './getDefaultState';

export interface AppLayoutState {
  layoutSection: Section;
  openPanels: string[];
  temporaryPanel: TemporaryPanel | undefined;
  minimizedPanels: MinimizedPanel[];
  maximizedPanels: string[];
  currentLayout: LayoutSpec | undefined;
  layoutOptions: Record<string, LayoutSpec> | undefined;
}

export interface MinimizePanelAction extends MinimizedPanel {}

export interface CurrentLayoutAction {
  layoutConfig: LayoutSpec;
}

interface InitLayoutAction {
  layoutConfig: LayoutSpec;
  layoutSection: Section;
}

interface TemporaryPanel {
  type: PanelType;
  params: PanelConfig;
  title: string;
}

const initialState: AppLayoutState = {
  layoutSection: 'trading',
  temporaryPanel: undefined,
  openPanels: [],
  maximizedPanels: [],
  minimizedPanels: [],
  currentLayout: undefined,
  layoutOptions: undefined,
};

export const appLayoutSlice = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    setTemporaryPanel: (state, action: PayloadAction<TemporaryPanel | undefined>) => {
      state.temporaryPanel = action.payload;
    },
    openPanel: (state, action: PayloadAction<string>) => {
      state.openPanels = state.openPanels.concat(action.payload);
    },
    closePanel: (state, action: PayloadAction<string>) => {
      state.openPanels = state.openPanels.filter(id => id !== action.payload);
    },
    togglePanel: (state, action: PayloadAction<string>) => {
      const isOpen = state.openPanels.includes(action.payload);
      if (isOpen) {
        state.openPanels = state.openPanels.filter(id => id !== action.payload);
      } else {
        state.openPanels = state.openPanels.concat(action.payload);
      }
    },
    minimizePanel: (state, action: PayloadAction<MinimizePanelAction>) => {
      state.minimizedPanels = state.minimizedPanels.concat(action.payload);
      state.maximizedPanels = state.maximizedPanels.filter(groupId => groupId !== action.payload.groupId);
    },
    maximizePanel: (state, action: PayloadAction<string>) => {
      state.maximizedPanels = state.maximizedPanels.concat(action.payload);
      state.minimizedPanels = state.minimizedPanels.filter(panel => panel.groupId !== action.payload);
    },
    restorePanel: (state, action: PayloadAction<string>) => {
      state.minimizedPanels = state.minimizedPanels.filter(panel => panel.groupId !== action.payload);
      state.maximizedPanels = state.maximizedPanels.filter(groupId => groupId !== action.payload);
    },
    updateDockviewLayout: (state, action: PayloadAction<SerializedDockview>) => {
      if (state.currentLayout) {
        state.currentLayout.dockViewLayout = action.payload;
      }
    },
    setLayoutOptions: (state, action: PayloadAction<Record<string, LayoutSpec>>) => {
      state.layoutOptions = action.payload;
    },
    setCurrentLayout: (state, action: PayloadAction<CurrentLayoutAction>) => {
      state.currentLayout = action.payload.layoutConfig;
      state.openPanels =
        state.currentLayout?.collapsablePanels.filter(panel => panel.defaultVisible).map(panel => panel.groupId) ?? [];
    },
    initLayout: (state, action: PayloadAction<InitLayoutAction>) => {
      if (action.payload.layoutSection == null && state.layoutSection == null) {
        return;
      }

      const defaultState = getDefaultState(
        action.payload.layoutSection ?? state.layoutSection,
        action.payload.layoutConfig.id
      );
      if (defaultState == null) {
        return;
      }

      state.layoutSection = action.payload.layoutSection ?? state.layoutSection;

      // Merge the default state with the layout config
      // TODO Add migrations for layout config to ensure that new additions are added to the layout config
      state.currentLayout = { ...defaultState, ...action.payload.layoutConfig };
      state.openPanels =
        state.currentLayout?.collapsablePanels.filter(panel => panel.defaultVisible).map(panel => panel.groupId) ?? [];
    },
  },
});

export const selectOpenPanels = (state: AppState) => state.appLayout.openPanels;
export const selectMinimizedPanels = (state: AppState) => state.appLayout.minimizedPanels;
export const selectMaximizedPanels = (state: AppState) => state.appLayout.maximizedPanels;
export const selectCurrentLayout = (state: AppState) => state.appLayout.currentLayout;

export const {
  initLayout,
  setLayoutOptions,
  setCurrentLayout,
  updateDockviewLayout,
  maximizePanel,
  minimizePanel,
  restorePanel,
  togglePanel,
  openPanel,
  closePanel,
  setTemporaryPanel,
} = appLayoutSlice.actions;
