import { Button, FormControlSizes, IconName, useDynamicCallback } from '@talos/kyoko';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceInteractions } from '../providers/PerformanceInteractionsProvider';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';

export const ResetChartControl = () => {
  const { dispatch, state } = usePerformanceContext();
  const { zoomOutChart } = usePerformanceInteractions();

  // When we reset we zoom out the chart _and_ set any snapshotDate setting to null
  const handleReset = useDynamicCallback(() => {
    zoomOutChart?.();
    if (state.snapshotDate != null) {
      dispatch({
        type: PerformanceActionType.SnapshotDateChange,
        payload: {
          snapshotDate: null,
        },
      });
    }
  });

  return (
    <Button startIcon={IconName.ZoomReset} size={FormControlSizes.Small} onClick={handleReset}>
      Reset chart
    </Button>
  );
};
