import { HStack, Icon, IconName, Meter } from '@talos/kyoko';
import { EXPOSURE_WARNING_THRESHOLD } from 'containers/Portfolio/types';
import { useTheme } from 'styled-components';
import type { RelativeMarketPositions } from '../useRelativeMarketPositions';
import { AnimatedHStack, LimitText, LimitWarningContainer, LimitWarningText } from './styles';
import { useExposure } from './useExposure';

export interface ExposureProps {
  relativeMarketPositions: RelativeMarketPositions;
}

export const Exposure = ({ relativeMarketPositions }: ExposureProps) => {
  const theme = useTheme();

  const { usage, limitString } = useExposure(relativeMarketPositions.MarketAccount);

  if (!limitString || usage == null) {
    return null;
  }

  const warning = usage != null && usage > EXPOSURE_WARNING_THRESHOLD;
  const meterColor = warning ? theme.colorTextWarning : theme.colorTextPrimary;
  const relativeWidth = relativeMarketPositions.relativeLimit * 100 + '%';

  return (
    <HStack gap="spacingMedium" justifyContent="flex-start">
      <AnimatedHStack style={{ minWidth: '60px', width: relativeWidth }}>
        <Meter value={Math.min(usage, 1)} showLabel={false} color={meterColor} />
      </AnimatedHStack>
      <LimitText warning={false}>{limitString}</LimitText>
      {warning && (
        <LimitWarningContainer>
          <Icon
            icon={IconName.ExclamationCircle}
            color={theme.colorTextWarning}
            size={theme.fontSizeBig * theme.baseSize}
          />
          <LimitWarningText>{usage < 1 ? 'Approaching limit' : 'At limit'}</LimitWarningText>
        </LimitWarningContainer>
      )}
    </HStack>
  );
};
