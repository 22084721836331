import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useIntl } from '../../../../hooks';
import { getStringLabel } from '../../../Form';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  feeCurrency: {
    defaultMessage: 'Fee Currency',
    id: 'Filters.FilterBuilder.filters.feeCurrency',
  },
});

/**
 * This hook creates the filter property definition for FeeCurrency properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for FeeCurrency filters
 */
export function useFeeCurrenciesFilter<TKey extends string = 'FeeCurrencies', P = FilterableProperty<TKey>>(
  feeCurrencies: string[] | undefined,
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
): FilterableProperty<TKey> {
  const { formatMessage } = useIntl();
  return useMemo(
    () => ({
      ...({
        key: 'FeeCurrencies' as TKey,
        label: formatMessage(messages.feeCurrency),
        field: 'FeeCurrency',
        icon: IconName.CurrencyDollar,
        options: feeCurrencies ?? [],
        getOptionLabel: getStringLabel,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [feeCurrencies, formatMessage, overrides]
  );
}
