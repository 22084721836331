import { useMemo } from 'react';
import { map, pipe, shareReplay } from 'rxjs';
import { aggDeltaUpdatesByCurrency, mergeAggWith } from '../pipes';
import type { AggregationSpec, CurrencyAggregationOutput } from '../pipes/aggDeltaUpdatesByCurrency';

export interface UseAggDeltaUpdatesPipeParams<T> {
  /** Function to get a unique key for each entry */
  getUniqueKey: (item: T) => string;
  /** The specification of what paths to aggregate on */
  aggSpecs: AggregationSpec[];
  /** Apply some kind of static data to the total row data entry, for example currencies or other reference data. */
  additionalTotalRowData?: object;
}

export function useAggDeltaUpdatesPipe<T>({
  getUniqueKey,
  aggSpecs,
  additionalTotalRowData,
}: UseAggDeltaUpdatesPipeParams<T>) {
  return useMemo(() => {
    return pipe(
      aggDeltaUpdatesByCurrency(getUniqueKey, aggSpecs),
      // Only forward an aggregation if there is only one currency within that aggregation
      map(currencyAggsByPath => {
        return new Map<string, CurrencyAggregationOutput>(
          [...currencyAggsByPath.values()]
            .filter(currencyAggByPath => currencyAggByPath.aggsByCurrency.size === 1)
            .map(currencyAggByPath => {
              const [currency, value] = [...currencyAggByPath.aggsByCurrency.entries()][0];
              return [
                currencyAggByPath.valuePath,
                {
                  value,
                  currency,
                  valuePath: currencyAggByPath.valuePath,
                  currencyPath: currencyAggByPath.currencyPath,
                },
              ];
            })
        );
      }),
      // Lastly merge the agg object with some static data
      map(agg =>
        mergeAggWith(agg, {
          groupColumnValue: `Totals`,
          rowID: 'totals-row',
          ...additionalTotalRowData,
        })
      ),
      shareReplay(1) // Keep this obs hot, and replay latest message on new (late) subs
    );
  }, [getUniqueKey, aggSpecs, additionalTotalRowData]);
}
