import {
  Panel,
  Tab,
  TabAppearance,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  tabLabelerByType,
  useDynamicCallback,
  usePersistedTabs,
  useTabs,
} from '@talos/kyoko';
import { identity } from 'lodash';
import { Fragment } from 'react';
import { CustomerSettlementMonitoring } from './CustomerSettlementMonitoring';
import { SettlementMonitoringFiltersProvider } from './CustomerSettlementMonitoring/providers/SettlementMonitoringFiltersProvider';
import { SettlementMonitoringInteractionsProvider } from './CustomerSettlementMonitoring/providers/SettlementMonitoringInteractionsProvider';
import { SettlementMonitoringStateProvider } from './CustomerSettlementMonitoring/providers/SettlementMonitoringStateProvider';
import { SETTLEMENT_TABS_DETAILS, SettlementTabType, type SettlementTab } from './tabs';

const TAB_PANELS_STYLE: React.CSSProperties = {
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

const DEFAULT_TABS: SettlementTab[] = [
  {
    ...SETTLEMENT_TABS_DETAILS[SettlementTabType.SettlementMonitoring].getDefaultState(),
    id: 'settlement-monitoring-default',
    label: 'Settlement Monitoring',
    closable: false,
    editable: false,
  },
];

const tabLabeler = tabLabelerByType<SettlementTab, keyof SettlementTab>({
  typeKey: 'tabType',
  typeToLabel: identity,
});

export const Settlement = () => {
  const persistedTabs = usePersistedTabs('portfolio/settlement', {
    defaultInitialItems: DEFAULT_TABS,
  });

  const handleCreateTab = useDynamicCallback(() => {
    // For now we're hardcoding this to always return the default state of the SettlementMonitoring tab type
    // When we add a second tabtype, we need to replace the creation workflow with the useAddTabPopover workflow.
    return SETTLEMENT_TABS_DETAILS[SettlementTabType.SettlementMonitoring].getDefaultState();
  });

  const tabs = useTabs({
    ...persistedTabs,
    showAddTab: true,
    allowClosingLastTab: false,
    onCreateTab: handleCreateTab,
    tabLabeler,
  });

  const handleUpdateTab = useDynamicCallback((updatedTab: SettlementTab) => {
    tabs.updateItem(updatedTab);
  });

  return (
    <Panel w="100%" position="relative" justifyContent="start" flex="1" alignItems="left" h="100%" overflow="hidden">
      <Tabs {...tabs} flex="1" appearance={TabAppearance.Filled} size={TabSize.Small}>
        <TabList isBordered>
          {tabs.items.map((tab, idx) => (
            <Tab key={idx} {...tab} />
          ))}
        </TabList>
        <TabPanels style={TAB_PANELS_STYLE}>
          {tabs.items.map(tab => (
            <Fragment key={tab.id}>
              {tab.tabType === SettlementTabType.SettlementMonitoring && (
                <SettlementMonitoringStateProvider tab={tab} onUpdateTab={handleUpdateTab}>
                  <SettlementMonitoringFiltersProvider>
                    <SettlementMonitoringInteractionsProvider>
                      <CustomerSettlementMonitoring tab={tab} />
                    </SettlementMonitoringInteractionsProvider>
                  </SettlementMonitoringFiltersProvider>
                </SettlementMonitoringStateProvider>
              )}
            </Fragment>
          ))}
        </TabPanels>
      </Tabs>
    </Panel>
  );
};
