import {
  Box,
  Button,
  ButtonGroup,
  ButtonVariants,
  IconName,
  MixpanelEvent,
  PreviewView,
  Text,
  useMixpanel,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import { useCallback } from 'react';

interface ConfirmCancellationPreviewProps {
  previewDisclosure: UseDisclosureReturn;
  onConfirmCancellation: () => void;
}

export const ConfirmCancellationPreview = ({
  previewDisclosure,
  onConfirmCancellation,
}: ConfirmCancellationPreviewProps) => {
  const mixpanel = useMixpanel();
  const handleConfirmCancellation = useCallback(() => {
    mixpanel.track(MixpanelEvent.CancelBulkOrderSubmission);
    onConfirmCancellation();
  }, [mixpanel, onConfirmCancellation]);

  // The PreviewView doesn't work if its mounted immediatelly alongside the normal Dialog contents. You need to defer its rendering.
  if (!previewDisclosure.isOpen) {
    return <></>;
  }

  return (
    <PreviewView
      disclosure={previewDisclosure}
      header="Confirm Cancellation"
      footer={
        <ButtonGroup gap="spacingComfortable">
          <Button
            onClick={() => previewDisclosure.close()}
            startIcon={IconName.ArrowLeft}
            data-testid="confirm-cancellation-return"
          >
            Return
          </Button>
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleConfirmCancellation}
            data-testid="confirm-cancellation-confirm"
          >
            Confirm Cancellation
          </Button>
        </ButtonGroup>
      }
    >
      <Box p="spacingComfortable">
        <Text>
          Cancelling stops the bulk order submission at its current state. This dialog will need to be re-opened from
          the Positions Blotter.
        </Text>
      </Box>
    </PreviewView>
  );
};
