import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import type { LoanTransactionStatusEnum, LoanTransactionTypeEnum } from './types';

export class LoanTransaction {
  Status!: LoanTransactionStatusEnum;

  Type!: LoanTransactionTypeEnum;

  Timestamp!: string;

  TransactionID!: string;

  Currency!: string;

  Amount!: string;

  CreateTime!: string;

  Comments?: string;

  FromParticipant!: string;

  ToParticipant!: string;

  SendersFromMarketAccount?: string;

  SendersToMarketAccount?: string;

  RecipientsToMarketAccount?: string;

  ConversionRate!: string;

  LoanID!: string;

  ClientID?: string;

  TransferClientID!: string;

  SenderTransferID?: string;

  Revision!: number;

  RevisionBy?: string;

  constructor(data: LoanTransaction) {
    Object.assign(this, data);
  }
}

interface UseLoanTransactionColumns {
  defaultColumns?: (keyof LoanTransaction | Partial<Column>)[];
}

export function useLoanTransactionColumns({ defaultColumns = EMPTY_ARRAY }: UseLoanTransactionColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'Status',
              type: 'loanTransactionStatus',
            },
            {
              field: 'Type',
              type: 'text',
            },
            {
              field: 'Timestamp',
              type: 'date',
              sortable: true,
              sort: '-',
            },
            {
              field: 'TransactionID',
              type: 'text',
            },
            {
              field: 'Currency',
              type: 'currency',
            },
            {
              field: 'Amount',
              type: 'size',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'CreateTime',
              type: 'date',
              sortable: true,
            },
            {
              field: 'Comments',
              type: 'text',
            },
            {
              field: 'FromParticipant',
              type: 'loanParticipant',
            },
            {
              field: 'ToParticipant',
              type: 'loanParticipant',
            },
            {
              field: 'SendersFromMarketAccount',
              type: 'marketAccount',
            },
            {
              field: 'SendersToMarketAccount',
              type: 'marketAccount',
            },
            {
              field: 'RecipientsToMarketAccount',
              type: 'marketAccount',
            },
            {
              field: 'ConversionRate',
              type: 'text',
            },
            {
              field: 'LoanID',
              type: 'text',
            },
            {
              field: 'ClientID',
              type: 'text',
            },
            {
              field: 'TransferClientID',
              type: 'text',
            },
            {
              field: 'SenderTransferID',
              type: 'text',
            },
            {
              field: 'Revision',
              type: 'text',
            },
            {
              field: 'RevisionBy',
              type: 'user',
            },
          ] satisfies ColumnDef<LoanTransaction>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<LoanTransaction>[] as Column[]).map(c => [
        getAgGridColId(c),
        c,
      ])
    );
  }, [defaultVisibleColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
