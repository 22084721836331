import { useCurrenciesContext } from '../../contexts';
import type { LoanTransaction } from '../../types';
import { abbreviateId } from '../../utils';
import { Box, Grid, HStack, type BoxProps } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Icon, IconName } from '../Icons';
import { Dd, Dl, Dt } from '../Lists';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

export interface LoanTransactionSummaryProps extends BoxProps {
  loanTransaction: LoanTransaction;
  showVersion: boolean;
}

export function LoanTransactionSummary({ loanTransaction, showVersion, ...props }: LoanTransactionSummaryProps) {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { Type, TransactionID, Revision, Amount, Currency } = loanTransaction;
  const currency = currenciesBySymbol.get(Currency ?? '');

  return (
    <Box fontSize="fontSizeSmall" {...props}>
      <Grid columns="auto repeat(3, 1fr)" gap="spacingDefault">
        <Dl>
          <Dt>{Type} Amount</Dt>
          <Dd>
            <InlineFormattedNumber number={Amount} currency={Currency} increment={currency?.DefaultIncrement} />
          </Dd>
        </Dl>
      </Grid>
      {showVersion && (
        <HStack justifyContent="space-between" mt="spacingLarge">
          <HStack>
            {TransactionID != null && (
              <Tooltip tooltip="TransactionID">
                <Icon icon={IconName.Hashtag} variant="Subtle" />
                <Text color="colorTextSubtle">{abbreviateId(TransactionID)}</Text>
              </Tooltip>
            )}
          </HStack>
          <HStack gap="spacingLarge">
            <Text color="colorTextSubtle">Rev: {Revision}</Text>
          </HStack>
        </HStack>
      )}
    </Box>
  );
}
