import { TradeSettleStatusEnum, getAgGridColId, useDefaultColumns, type Column, type ColumnDef } from '@talos/kyoko';
import type { CheckboxSelectionCallbackParams } from 'ag-grid-community';
import { compact } from 'lodash';
import { useMemo } from 'react';
import type { SettleableTrade, TradeSettlementColumn } from './types';

interface UseTradeSettlementColumnsParams {
  defaultColumns: TradeSettlementColumn[];
}

export const useTradeSettlementColumns = ({ defaultColumns }: UseTradeSettlementColumnsParams) => {
  const columnDefinitions = useMemo(() => {
    return new Map<string, Column>(
      (
        compact([
          {
            type: 'date',
            field: 'TransactTime',
            checkboxSelection: (params: CheckboxSelectionCallbackParams<SettleableTrade>) =>
              params.data?.SettleStatus === TradeSettleStatusEnum.Unsettled,
          },
          { type: 'id', field: 'TradeID', width: 80 },
          { type: 'id', field: 'OrderID', width: 80 },
          { type: 'counterparty', field: 'Counterparty' },
          { type: 'account', field: 'MarketAccount' },
          { type: 'security', field: 'Symbol' },
          { type: 'side', field: 'Side', title: 'Side' },
          { type: 'size', field: 'Quantity', title: 'Qty', params: { currencyField: 'Currency' } },
          {
            type: 'size',
            field: 'directionalQuantity',
            title: 'Directional Qty',
            params: { currencyField: 'Currency' },
            hide: true,
          },
          { type: 'size', field: 'Amount', title: 'Amount', params: { currencyField: 'AmountCurrency' } },
          { type: 'price', field: 'Price', title: 'Price', params: { quoteCurrencyField: 'AmountCurrency' } },
          { type: 'text', field: 'AmountCurrency', title: 'Amount Currency', hide: true },
          { type: 'text', field: 'SettleStatus', title: 'Status', width: 100 },
          { type: 'size', field: 'CumSettledDue', title: 'Cum Settled Due', params: { currencyField: 'DueAsset' } },
          { type: 'text', field: 'DueAsset', title: 'Due Asset', hide: true },
          {
            type: 'size',
            field: 'CumSettledProceeds',
            title: 'Cum Settled Proceeds',
            params: { currencyField: 'ProceedsAsset' },
          },
          { type: 'text', field: 'ProceedsAsset', title: 'Proceeds Asset', hide: true },
          { type: 'id', field: 'SettleReportID', title: 'Report ID', width: 80, hide: true },
        ]) satisfies ColumnDef<SettleableTrade>[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, []);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};
