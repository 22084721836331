import { getAllChildrenOfSubAccount, getAllParentsForSubAccount } from '@talos/kyoko';
import { useSubAccountRollupMemberships } from 'providers/SubAccountRollupMembershipsProvider';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { useMemo } from 'react';

/** Mapping of ALL unique parents and children ids for each SubAccount */
export function useSubAccountRelationMap(): Map<
  number,
  {
    parents: Set<number>;
    children: Set<number>;
  }
> {
  const { rollupMembershipsByChildParent, rollupMembershipsByParentChild } = useSubAccountRollupMemberships();
  const { allSubAccounts } = useSubAccounts();

  return useMemo(() => {
    return allSubAccounts.reduce(
      (acc, subAccount) => {
        const parents = getAllParentsForSubAccount(subAccount.SubaccountID, rollupMembershipsByChildParent);
        const children = getAllChildrenOfSubAccount(subAccount.SubaccountID, rollupMembershipsByParentChild);
        acc.set(subAccount.SubaccountID, {
          parents,
          children,
        });
        return acc;
      },
      new Map<
        number,
        {
          parents: Set<number>;
          children: Set<number>;
        }
      >()
    );
  }, [allSubAccounts, rollupMembershipsByChildParent, rollupMembershipsByParentChild]);
}
