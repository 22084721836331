import { useCallback, useRef, useState, type MutableRefObject } from 'react';

/**
 * useHighchartsRef can be used to get a stable reference to the Highcharts chart object, and ensure
 * that the chart is fully loaded before the reference is set. (Do not use useState for holding the chart object)
 * @param  arg.onChartCreated A callback that is called when the chart is created, useful for chaining the ref to outer components.
 * @returns an object with the following properties:
 * - isLoaded: A boolean that is true when the chart is loaded (if depending on state change, use this)
 * - chartRef: A mutable ref object that holds the Highcharts chart object; the highcharts object will change internally but the ref will remain the same.
 * - setChartObject: Set the chart object to the ref object.
 */
export function useHighchartsRef({
  onChartCreated,
}: {
  onChartCreated?: (chart: Highcharts.Chart) => void;
} = {}): {
  isLoaded: boolean;
  chartRef: MutableRefObject<Highcharts.Chart | undefined>;
  setChartObject: (chart: Highcharts.Chart) => void;
} {
  const chartRef = useRef<Highcharts.Chart>();
  const [isLoaded, setIsLoaded] = useState(false);

  const setChartObject = useCallback(
    (chart: Highcharts.Chart) => {
      chartRef.current = chart;
      onChartCreated?.(chart);
      setIsLoaded(true);
    },
    [onChartCreated]
  );
  return {
    isLoaded,
    chartRef,
    setChartObject,
  };
}
