import { Checkbox } from '@talos/kyoko';
import type React from 'react';
import { useCallback, useMemo } from 'react';
import { usePositionsFormContext } from '../providers/PositionsFormProvider';
import { PositionsActionType } from './PositionsFormReducer';
import { getCheckedType, isChecked, isIndeterminate, type CheckedType } from './utils';

export const HeaderCheckbox = () => {
  const { state, dispatch } = usePositionsFormContext();

  const check: CheckedType = useMemo(() => {
    return getCheckedType(state);
  }, [state]);

  const isDisabled = useMemo(() => {
    if (isChecked(check)) {
      return false;
    } else {
      // we are disabled if there are no outgoing entries anywhere
      for (const mktAccMap of [...state.values()]) {
        for (const entry of [...mktAccMap.values()]) {
          if (entry.isOutgoing) {
            return false;
          }
        }
      }

      return true;
    }
  }, [check, state]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: PositionsActionType.HeaderCheckedChange,
        payload: {
          checked: e.target.checked,
        },
      });
    },
    [dispatch]
  );

  return (
    <Checkbox
      checked={isChecked(check)}
      indeterminate={isIndeterminate(check)}
      onChange={handleChange}
      disabled={isDisabled}
    />
  );
};
