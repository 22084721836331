import {
  FormControlSizes,
  IconButton,
  IconName,
  Tab,
  TabAppearance,
  TabList,
  Tabs,
  TabSize,
  useTabs,
} from '@talos/kyoko';
import type { PropsWithChildren } from 'react';

import { ContentHeader } from './styles';

interface HeaderProps {
  onMaximizePanel?: () => void;
  onMinimizePanel?: () => void;
  isMaximized?: boolean;
  isMinimized?: boolean;
  activePanelIndex: number;
  onTabSelected?: (idx: number) => void;
  tabs: string[];
  showControls?: boolean;
}

/** @deprecated Use Kyoko version instead */
export function ExpandablePanelHeader({
  onMaximizePanel,
  onMinimizePanel,
  onTabSelected,
  children,
  tabs: tabLabels,
  isMinimized,
  isMaximized,
  showControls = true,
}: PropsWithChildren<HeaderProps>) {
  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: tabLabels.map(t => ({ label: t, id: t })),
    onSelect: onTabSelected,
  });

  return (
    <ContentHeader isPanelMinimized={!!isMinimized}>
      <Tabs {...tabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
        <TabList>
          {tabs.items.map((tab, idx) => (
            <Tab key={idx} {...tab} />
          ))}
        </TabList>
      </Tabs>
      {children}
      {showControls && onMinimizePanel && (
        <IconButton
          ghost
          color={isMinimized ? 'colorTextImportant' : 'colorTextDefault'}
          icon={isMinimized ? IconName.ChevronUp : IconName.Minus}
          onClick={e => {
            e.stopPropagation();
            onMinimizePanel();
          }}
          size={FormControlSizes.Small}
          style={{ alignSelf: 'center' }}
        />
      )}
      {showControls && onMaximizePanel && (
        <IconButton
          ghost
          color={isMaximized ? 'colorTextImportant' : 'colorTextDefault'}
          icon={isMaximized ? IconName.ChevronDown : IconName.ArrowsExpand}
          onClick={e => {
            e.stopPropagation();
            onMaximizePanel();
          }}
          size={FormControlSizes.Small}
          style={{ alignSelf: 'center' }}
        />
      )}
    </ContentHeader>
  );
}
