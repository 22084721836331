import {
  EMPTY_ARRAY,
  getAgGridColId,
  useDefaultColumns,
  type BuyingPower,
  type Column,
  type ColumnDef,
} from '@talos/kyoko';

import { useMemo } from 'react';

interface UseBuyingPowerColumns {
  defaultColumns?: (keyof BuyingPower | Partial<Column>)[];
}

export function useBuyingPowerColumns({ defaultColumns = EMPTY_ARRAY }: UseBuyingPowerColumns): Column[] {
  const columnDefinitions = useMemo(
    () =>
      new Map<string, ColumnDef<BuyingPower>>(
        (
          [
            { type: 'marketAccount', field: 'MarketAccount', rowGroup: true, hide: true }, // default market account to grouped
            {
              type: 'currency',
              field: 'Currency',
              params: {
                colorful: true,
              },
            },
            { type: 'market', field: 'Counterparty', title: 'Counterparty' },
            {
              type: 'security',
              field: 'Symbol',
              params: {
                colorful: true,
              },
            },
            { type: 'date', field: 'Timestamp', hide: true },
            { type: 'text', field: 'Text', hide: true },
            {
              type: 'size',
              field: 'AvailableBuy',
              aggregate: true,
              params: { currencyField: 'Currency' satisfies keyof BuyingPower, highlightNegative: true },
            },
            {
              type: 'size',
              field: 'AvailableSell',
              aggregate: true,
              params: { currencyField: 'Currency' satisfies keyof BuyingPower, highlightNegative: true },
            },
            {
              type: 'size',
              field: 'MaxBuyOrderSize',
              aggregate: true,
              params: { currencyField: 'Currency' satisfies keyof BuyingPower, highlightNegative: true },
            },
            {
              type: 'size',
              field: 'MaxSellOrderSize',
              aggregate: true,
              params: { currencyField: 'Currency' satisfies keyof BuyingPower, highlightNegative: true },
            },
            { type: 'text', field: 'Status', hide: true },
          ] satisfies ColumnDef<BuyingPower>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );

  return useDefaultColumns(defaultColumns, columnDefinitions);
}
