import type { Column, Trade } from '@talos/kyoko';

export const ORDER_DETAILS_DEFAULT_TRADE_COLUMNS: (keyof Trade | Partial<Column>)[] = [
  'TransactTime',
  'Side',
  'Quantity',
  'Price',
  'Fee',
  'PriceAllIn',
  'Amount',
  'MarketAccount',
  'AggressorSide',
  'SubAccount',
  'TradeID',
  'TradeStatus',
  'EID',
];
