import {
  ParameterTypeEnum,
  ProductTypeEnum,
  useMarketAccountsContext,
  useMarketsContext,
  type Market,
  type MarketAccount,
  type OrderStrategy,
  type Security,
} from '@talos/kyoko';
import { useCallback } from 'react';

export function useGetReduceOnlySupport() {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { marketsByName } = useMarketsContext();

  const getReduceOnlySupportFn = useCallback(
    (
      security: Security | undefined,
      strategy: OrderStrategy | undefined,
      selectedMarketAccounts: string[] | undefined,
      isUnifiedLiquidityEnabled: boolean
    ) =>
      getReduceOnlySupport(
        security,
        strategy,
        selectedMarketAccounts,
        isUnifiedLiquidityEnabled,
        marketAccountsByName,
        marketsByName
      ),
    [marketAccountsByName, marketsByName]
  );

  return {
    getReduceOnlySupport: getReduceOnlySupportFn,
  };
}

export function getReduceOnlySupport(
  security: Security | undefined,
  strategy: OrderStrategy | undefined,
  selectedMarketAccounts: string[] | undefined,
  isUnifiedLiquidityEnabled: boolean | undefined,
  marketAccountsByName: Map<string, MarketAccount>,
  marketsByName: Map<string, Market>
): boolean {
  if (!security || !strategy || !selectedMarketAccounts || isUnifiedLiquidityEnabled) {
    return false;
  }
  const isSecuritySupported = [ProductTypeEnum.Option, ProductTypeEnum.Future, ProductTypeEnum.PerpetualSwap].includes(
    security.ProductType
  );
  if (!isSecuritySupported) {
    return false;
  }

  const isStrategySupported = strategy.Parameters.findIndex(param => param.Name === ParameterTypeEnum.ReduceOnly) > -1;
  if (!isStrategySupported) {
    return false;
  }

  const isMarketSupported = selectedMarketAccounts.some(marketAccountName => {
    const marketName = marketAccountsByName.get(marketAccountName)?.Market;
    if (!marketName) {
      return false;
    }
    return marketsByName.get(marketName)?.Flags?.SupportsReduceOnly === true;
  });
  if (!isMarketSupported) {
    return false;
  }

  return true;
}
