import { scan, startWith, type Observable } from 'rxjs';

interface WsResponse<T> {
  data: T[];
  initial?: boolean;
}

/**
 * This pipe appends the newly received messages to the previous list of received items and returns the concatenation
 * @param createEntity Factory function to map each entity into another type (e.g. Object -> Order)
 * @returns
 */
export function scanWsResponseToArray<TInput, TOutput>(
  createEntity: (data: TInput) => TOutput
): (source: Observable<WsResponse<TInput>>) => Observable<TOutput[]> {
  return (source: Observable<WsResponse<TInput>>) =>
    source.pipe(
      scan((items, json) => {
        if (json.initial) {
          items = [] as TOutput[];
        }
        return [...items, ...json.data.map(createEntity)];
      }, [] as TOutput[]),
      startWith([] as TOutput[])
    );
}
