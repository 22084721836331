import {
  Card,
  CurrencyIcon,
  Divider,
  HStack,
  InlineFormattedNumber,
  NumberVariants,
  Text,
  useMarketAccountsContext,
  VStack,
} from '@talos/kyoko';
import { useMemo, type ReactNode } from 'react';
import { useSubAccounts } from '../../../../providers';
import type { ReconBreak } from './types';

export const ReconBreakSummary = ({ reconBreak }: { reconBreak: ReconBreak }) => {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { subAccountsByName } = useSubAccounts();

  const subAccountsLabel = useMemo(() => {
    if (!reconBreak.subAccounts) {
      return undefined;
    }

    return reconBreak.subAccounts.map(sa => subAccountsByName.get(sa)?.DisplayName ?? sa).join(', ');
  }, [reconBreak.subAccounts, subAccountsByName]);

  const marketAccount = reconBreak.marketAccount;
  const marketAccountLabel = marketAccount
    ? marketAccountsByName.get(marketAccount)?.DisplayName ?? marketAccount
    : undefined;

  const breakVariant = reconBreak.hasBreak ? NumberVariants.Warning : undefined;
  const subAccountsPlural = reconBreak.subAccounts != null && reconBreak.subAccounts.length > 1;

  return (
    <Card w="100%" data-testid="break-summary-card">
      <VStack w="100%" gap="spacingDefault">
        <Row>
          <Text color="colorTextImportant">{reconBreak.type}</Text>
          <HStack gap="spacingSmall">
            <CurrencyIcon currency={reconBreak.asset} />
            <Text>{reconBreak.asset}</Text>
          </HStack>
        </Row>

        <Row>
          <Text>Break</Text>
          <InlineFormattedNumber number={reconBreak.breakAmount} currency={reconBreak.asset} variant={breakVariant} />
        </Row>

        <Divider orientation="horizontal" />

        <Row>
          <Text>{subAccountsPlural ? 'Sub Accounts' : 'Sub Account'}</Text>
          <TextOrMissingValue text={subAccountsLabel} />
        </Row>

        <Row>
          <Text>Market Account</Text>
          <TextOrMissingValue text={marketAccountLabel} />
        </Row>
      </VStack>
    </Card>
  );
};

const Row = ({ children }: { children: ReactNode }) => {
  return (
    <HStack fontSize="fontSizeSmall" w="100%" gap="spacingDefault" justifyContent="space-between">
      {children}
    </HStack>
  );
};

const TextOrMissingValue = ({ text }: { text: string | undefined }) => {
  if (!text) {
    return <Text color="colorTextWarning">Missing Value</Text>;
  }

  return <Text color="colorTextImportant">{text}</Text>;
};
