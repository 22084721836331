import type { MinimalSubscriptionResponse } from '@talos/kyoko';
import { map, of, pairwise, pipe, startWith, switchMap, timer } from 'rxjs';

/**
 * Interval timer that resets on updated Subscription tag (this helps reduce animation issues when the data source changes)
 * - Using this hook likely requires a startWith operator to initialize the observable with an empty tag
 *
 * - TODO: Improve this hook to be more generic (based on change operator method callback) then move to kyoko.
 * - This currently only works because the SubscriptionResponse has a tag field that changes when the data changes.
 */
export function getWsIntervalOnChangedTag<T extends MinimalSubscriptionResponse<any>>(updateInterval: number = 2000) {
  return pipe(
    // Start with an empty object to have a immediate last for the pairwise operator
    startWith({} as T),
    pairwise<T>(),
    switchMap(([last, current]: [T, T]) => {
      return !last.tag || last.tag !== current.tag
        ? of(current).pipe(
            switchMap(() =>
              timer(0, updateInterval).pipe(
                map(() => {
                  return current;
                })
              )
            )
          )
        : of(current).pipe(
            switchMap(() =>
              timer(updateInterval).pipe(
                map(() => {
                  return current;
                })
              )
            )
          );
    })
  );
}
