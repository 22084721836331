import { QueueIndicator, QueueWrapper } from './styles';
const COLORS = ['#579834', '#699834', '#859834', '#A8962D', '#CC4234'];

export function DeleverageQueueIndicator({ value }) {
  return (
    <QueueWrapper>
      {[0.2, 0.4, 0.6, 0.8, 1].map((step, index) => (
        <QueueIndicator key={index} active={step <= value} color={COLORS[index]} />
      ))}
    </QueueWrapper>
  );
}
