import { ACTION, isTalosUser, type Order } from '@talos/kyoko';
import { every, flatten } from 'lodash';
import { useCallback } from 'react';
import { useSubAccounts } from '../providers/SubAccountsContext';
import { useFeatureFlag } from './useFeatureFlag';
import { useRoleAuth } from './useRoleAuth';
import { useUser } from './useUser';

/**
 * Subtype of Order. The type contains necessary information related to permissioning
 * w.r.t. Trading Account Segregation.
 */
export type OrderAccessEntity = Pick<Order, 'SubAccount' | 'Allocation'>;

/**
 * Hook for determining modify-access of an Order.
 */
export function useOrderAccess(): (order: OrderAccessEntity) => boolean {
  const { isAuthorized } = useRoleAuth();
  const user = useUser();
  const { tradableSubAccountNamesSet } = useSubAccounts();
  const { enableAccountSegregation } = useFeatureFlag();
  return useCallback(
    order => {
      // Talos users are always exempt from any order access checks.
      if (isTalosUser(user)) {
        return true;
      }
      if (!isAuthorized(ACTION.SUBMIT_ORDER)) {
        return false;
      }

      if (enableAccountSegregation) {
        return (
          isAuthorized(ACTION.BYPASS_FILTER_PERMISSIONS_RESTRICTIONS_SUBACCOUNTS) ||
          hasPermissionToTradeSubAccounts({ order, tradableSubAccountNamesSet })
        );
      }

      return true;
    },
    [enableAccountSegregation, isAuthorized, user, tradableSubAccountNamesSet]
  );
}

function hasPermissionToTradeSubAccounts({
  order,
  tradableSubAccountNamesSet,
}: {
  tradableSubAccountNamesSet: Set<string>;
  order: OrderAccessEntity;
}): boolean {
  const ordersSubAccounts = flatten(
    order.Allocation ? order.Allocation.Allocations.map(a => a.SubAccount) : order.SubAccount ? [order.SubAccount] : []
  );

  return every(ordersSubAccounts, subaccount => tradableSubAccountNamesSet.has(subaccount));
}
