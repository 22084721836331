import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { memo, useCallback, useEffect, useMemo, type ReactNode } from 'react';
import shajs from 'sha.js';

import {
  CardViewEnum,
  GET_MIXPANEL_MOCK,
  MixpanelContext,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelMetadata,
  isUserProdMon,
  useFavoriteSecurities,
  useUserContext,
  useWatchlistSettings,
  type MixpanelEventSource,
  type MixpanelInstance,
} from '@talos/kyoko';
import { isArray, isObject, max, maxBy } from 'lodash';
import { useDisplaySettings } from './DisplaySettingsProvider';
import type { GeneralMarketTab, MarketTab } from './MarketTabs.types';
import { MarketTabType } from './MarketTabs.types';
import { useMarketTabs } from './MarketTabsProvider';
import { useOrderPresets } from './OrderPresetsContext';
import { useTradingSettings } from './TradingSettingsContext';

const hash = value => shajs('sha1').update(value).digest('hex');

export const MixpanelProvider = memo(function MixpanelProvider(props: { children: ReactNode }) {
  const { user } = useUserContext();
  const _marketTabs = useMarketTabs();
  const marketTabs = _marketTabs.items;
  const { showAllInPrices, showFirmLiquidity } = useDisplaySettings();
  const {
    enableGroups,
    allowSyntheticCcy,
    useTradeAllocations,
    defaultSubAccountId,
    allowedSlippage,
    clickToTradeAll,
    alwaysShowBestBidOffer,
  } = useTradingSettings();
  const { isVisible } = useWatchlistSettings();

  const userIsProdMon = isUserProdMon(user);

  const { favoriteSecurities } = useFavoriteSecurities();

  const getInstance = useCallback((source?: MixpanelEventSource | undefined): MixpanelInstance => {
    if (import.meta.env.VITE_AVA_ENV === 'prod' || import.meta.env.VITE_AVA_ENV === 'sandbox') {
      return {
        set_group: function (...args) {
          // Sentry.addBreadcrumb({ category: 'Mixpanel', type: 'set_group', data: args });
          mixpanel.set_group(...args);
        },
        remove_group: function (...args) {
          mixpanel.remove_group(...args);
        },
        add_group: function (...args) {
          mixpanel.add_group(...args);
        },
        identify: function (...args) {
          // Sentry.addBreadcrumb({ category: 'Mixpanel', type: 'identify', data: args });
          mixpanel.identify(...args);
        },
        register: function (...args) {
          // Sentry.addBreadcrumb({ category: 'Mixpanel', type: 'register', data: args });
          mixpanel.register(...args);
        },
        time_event: function (...args) {
          // Sentry.addBreadcrumb({ category: 'Mixpanel', type: 'register', data: args });
          mixpanel.time_event(...args);
        },
        track: function (...args) {
          const [eventName, properties = {}] = args;
          if (source && !properties[MixpanelEventProperty.Source]) {
            properties[MixpanelEventProperty.Source] = source;
          }

          Sentry.addBreadcrumb({
            category: `mixpanel.track`,
            type: 'user',
            message: eventName as string,
            data: properties as Record<string, unknown>,
          });
          args[1] = { ...properties, windowWidth: window.innerWidth, windowHeight: window.innerHeight };
          mixpanel.track(...args);
        },
      };
    }
    return GET_MIXPANEL_MOCK(source);
  }, []);

  const internalInstance = useMemo(() => getInstance(), [getInstance]);

  useEffect(() => {
    if (internalInstance && user) {
      internalInstance.add_group('organization', user.Organization);
      internalInstance.identify(
        hash(`${user.ID}_${user.Organization}_${import.meta.env.VITE_AVA_ENV}`).substring(0, 6)
      );
      internalInstance.register({
        [MixpanelMetadata.Environment]: import.meta.env.VITE_AVA_ENV,
        [MixpanelMetadata.LastSeen]: new Date().toISOString(),
        [MixpanelMetadata.Release]: import.meta.env.VITE_GIT_HASH,
        [MixpanelMetadata.Version]: import.meta.env.VITE_NPM_PACKAGE_VERSION,
      });
    }
  }, [internalInstance, user]);

  useEffect(() => {
    const roles = user?.Roles ?? [];
    if (internalInstance && user) {
      for (const role of roles) {
        internalInstance.add_group('role', role);
      }

      if (userIsProdMon) {
        internalInstance.add_group('talos_user', 'prodmon');
      }
      return () => {
        for (const role of roles) {
          internalInstance.remove_group('role', role);
        }
        internalInstance.remove_group('talos_user', 'prodmon');
      };
    }
  }, [internalInstance, user, userIsProdMon]);

  // Track count of tabs for each blotter
  useEffect(() => {
    if (internalInstance && user.AppConfig) {
      const appConfigJSON = JSON.parse(user.AppConfig);

      if (isObject(appConfigJSON)) {
        if ('tabs' in appConfigJSON && isObject(appConfigJSON.tabs)) {
          const tabsObj = appConfigJSON.tabs;
          const keyNumberOfTabsPairObject: Record<string, number> = {};
          for (const [key, tabObj] of Object.entries(tabsObj)) {
            if (isObject(tabObj) && 'items' in tabObj && isArray(tabObj.items)) {
              const numberOfTabs = tabObj.items.length;
              keyNumberOfTabsPairObject[key] = numberOfTabs;
            }
          }
          internalInstance.register(keyNumberOfTabsPairObject);
        }
      }
    }
  }, [internalInstance, user.AppConfig]);

  useEffect(() => {
    if (marketTabs && internalInstance) {
      // Exclude empty market tabs.
      const allTabs = marketTabs.filter(
        tab =>
          tab.type !== MarketTabType.Market || (tab.columns != null && tab.columns.filter(c => c.length > 0).length > 0)
      );

      const isGeneralMarketTab = (tab: MarketTab): tab is GeneralMarketTab => tab.type === MarketTabType.Market;

      // Only track tabs with cards.
      const marketCardsTab = marketTabs.filter(isGeneralMarketTab);
      const optionCardsTab = marketTabs.filter(tab => tab.type === MarketTabType.Option);

      const allCards = marketCardsTab.flatMap(tab => (tab.columns ?? []).flatMap(c => c));

      const compactCards = allCards.filter(c => c.view === CardViewEnum.COMPACT);
      const expandedCards = allCards.filter(c => c.view === CardViewEnum.EXPANDED);
      const marketData = {
        [MixpanelMetadata.NumberOfTabs]: allTabs.length,
        [MixpanelMetadata.NumberOfOpenOptionTabs]: optionCardsTab.length,
        [MixpanelMetadata.NumberOfOpenMarketTabs]: marketCardsTab.length,
        [MixpanelMetadata.NumberOfOpenMarketCards]: allCards.length,
        [MixpanelMetadata.MaxCards]: max(
          marketCardsTab.map(mt => {
            if (mt.type !== MarketTabType.Market) {
              return 0;
            }
            return mt.columns?.reduce((res, c) => res + c.length, 0) ?? 0;
          })
        ),
        [MixpanelMetadata.MaxCardColumns]: max(
          marketCardsTab.map(mt => {
            if (mt.type !== MarketTabType.Market) {
              return 0;
            }
            return mt.columns?.reduceRight((res, c, i) => Math.max(res, c.length > 0 ? i + 1 : -1), 1) ?? 0;
          })
        ),
        [MixpanelMetadata.MaxCardRows]: max(
          marketCardsTab.map(mt => {
            if (mt.type !== MarketTabType.Market) {
              return 0;
            }
            return maxBy(mt?.columns ?? [], c => c.length)?.length || 0;
          })
        ),
        [MixpanelMetadata.NumberOfCardsWithPreset]: marketCardsTab.reduce((res, mt) => {
          if (mt.type !== MarketTabType.Market) {
            return res;
          }
          // Doesn't care if the preset is removed or not.
          return res + (mt.columns?.reduce((r, c) => r + c.filter(card => card.orderPresetID).length, 0) ?? 0);
        }, 0),
        [MixpanelMetadata.NumberOfCardsWithCompactView]: compactCards.length,
        [MixpanelMetadata.NumberOfCardsWithExpandedView]: expandedCards.length,
        [MixpanelMetadata.NumberOfCardsWithVolumeLadder]: allCards.filter(
          c => c.view !== CardViewEnum.COMPACT && c.activeSlideIndex === 1
        ).length,
        [MixpanelMetadata.NumberOfCardsWithPriceLadder]: allCards.filter(
          c => c.view !== CardViewEnum.COMPACT && c.activeSlideIndex === 2
        ).length,
        [MixpanelMetadata.NumberOfCardsWithMiniChart]: allCards.filter(
          c => c.view !== CardViewEnum.COMPACT && c.activeSlideIndex === 3
        ).length,
        [MixpanelMetadata.NumberOfCardsWithMarketsList]: allCards.filter(
          c => c.view !== CardViewEnum.COMPACT && c.activeSlideIndex === 4
        ).length,
      };

      const deepdiveTabs = marketTabs.filter(tab => tab.type === MarketTabType.DeepDive);
      const deepdiveData = {
        [MixpanelMetadata.NumberOfOpenDeepDiveTabs]: deepdiveTabs.length,
      };

      const orderDetailsTabs = marketTabs.filter(tab => tab.type === MarketTabType.OrderDetails);
      const orderDetailsData = {
        [MixpanelMetadata.NumberOfOpenOrderDetailsTabs]: orderDetailsTabs.length,
      };

      internalInstance.register({
        ...marketData,
        ...deepdiveData,
        ...orderDetailsData,
      });
    }
  }, [internalInstance, marketTabs]);

  useEffect(() => {
    internalInstance &&
      internalInstance.register({
        [MixpanelMetadata.ShowAllInPrices]: !!showAllInPrices,
        [MixpanelMetadata.FirmLiquidity]: !!showFirmLiquidity,
        [MixpanelMetadata.ShowWatchlist]: isVisible,
      });
  }, [internalInstance, showAllInPrices, isVisible, showFirmLiquidity]);

  useEffect(() => {
    internalInstance &&
      internalInstance.register({
        [MixpanelMetadata.AllowedSlippage]: allowedSlippage?.value,
        [MixpanelMetadata.EnableClickToTrade]: clickToTradeAll,
        [MixpanelMetadata.EnableOrderGroups]: enableGroups,
        [MixpanelMetadata.EnableSyntheticCurrency]: allowSyntheticCcy,
        [MixpanelMetadata.UseSubaccounts]: !useTradeAllocations && !!defaultSubAccountId,
        [MixpanelMetadata.UseTradeAllocations]: useTradeAllocations,
        [MixpanelMetadata.AlwaysDisplayBestBidOffer]: alwaysShowBestBidOffer,
      });
  }, [internalInstance, enableGroups, allowSyntheticCcy, useTradeAllocations, defaultSubAccountId, allowedSlippage, clickToTradeAll, alwaysShowBestBidOffer]);

  useEffect(() => {
    internalInstance &&
      internalInstance.register({
        [MixpanelMetadata.NumberOfFavoriteSymbols]: favoriteSecurities ? favoriteSecurities.length : 0,
      });
  }, [internalInstance, favoriteSecurities]);

  const { orderPresetsList } = useOrderPresets();
  useEffect(() => {
    internalInstance &&
      internalInstance.register({
        [MixpanelMetadata.NumberOfOrderPresets]: orderPresetsList?.length ?? 0,
      });
  }, [internalInstance, orderPresetsList]);

  useEffect(() => {
    if (!internalInstance || !window.matchMedia) {
      return;
    }
    const query = window.matchMedia('(display-mode: standalone)');

    const displayMode = query.matches ? 'standalone' : 'browser';
    internalInstance.register({
      [MixpanelMetadata.DisplayMode]: displayMode,
    });

    const displayModeChangeCallback = function (e) {
      const displayMode = e.matches ? 'standalone' : 'browser';
      internalInstance.track(MixpanelEvent.ChangeDisplayMode, {
        [MixpanelEventProperty.DisplayMode]: displayMode,
      });
    };
    query.addEventListener('change', displayModeChangeCallback);

    const appInstalledCallback = () => {
      internalInstance.track(MixpanelEvent.WebAppInstalled);
    };
    window.addEventListener('appinstalled', appInstalledCallback);
    return () => {
      query.removeEventListener('change', displayModeChangeCallback);
      window.removeEventListener('appinstalled', appInstalledCallback);
    };
  }, [internalInstance]);

  return <MixpanelContext.Provider value={getInstance}>{props.children}</MixpanelContext.Provider>;
});
