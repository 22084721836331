import { Button, ButtonGroup, ButtonVariants, FormControlSizes, OrderFormSides, type SideEnum } from '@talos/kyoko';

export interface HeaderSideSelectorProps {
  side: string | undefined;
  onChange: (side: OrderFormSides | SideEnum) => void;
  disabled?: boolean;
  size?: FormControlSizes;

  /** Show/hide the two-way button */
  showTwoWay?: boolean;
  /** Enable/disable the buy button */
  enableBuy?: boolean;
  /** Enable/disable the sell button */
  enableSell?: boolean;
  /** Enable/disable the two-way button */
  enableTwoWay?: boolean;
}

export const HeaderSideSelector = ({
  enableBuy = true,
  enableTwoWay = true,
  enableSell = true,
  showTwoWay,
  side,
  onChange,
  disabled,
  size = FormControlSizes.Default,
}: HeaderSideSelectorProps) => {
  return (
    <ButtonGroup size={size} data-testid="order-form-side">
      <Button
        data-selected={side === OrderFormSides.Buy}
        variant={side === OrderFormSides.Buy ? ButtonVariants.Positive : ButtonVariants.Default}
        onClick={() => onChange(OrderFormSides.Buy)}
        disabled={disabled || !enableBuy}
        data-testid="buy-button"
        value="Buy"
      >
        Buy
      </Button>
      {showTwoWay && (
        <Button
          data-selected={side === OrderFormSides.Twoway || side == null}
          variant={side === OrderFormSides.Twoway || side == null ? ButtonVariants.Primary : ButtonVariants.Default}
          onClick={() => onChange(OrderFormSides.Twoway)}
          disabled={disabled || !enableTwoWay}
          data-testid="two-way-button"
          value="Two-way"
        >
          2 way
        </Button>
      )}
      <Button
        data-selected={side === OrderFormSides.Sell}
        variant={side === OrderFormSides.Sell ? ButtonVariants.Negative : ButtonVariants.Default}
        onClick={() => onChange(OrderFormSides.Sell)}
        disabled={disabled || !enableSell}
        data-testid="sell-button"
        value="Sell"
      >
        Sell
      </Button>
    </ButtonGroup>
  );
};
