import styled from 'styled-components';
import { BLOTTER_HEADER_HEIGHT, SUB_HEADER_HEIGHT } from 'styles/dimensions';

export const Wrapper = styled.div<{ noHeader?: boolean }>`
  display: flex;
  flex-direction: column;

  height: ${({ noHeader }) => (noHeader ? `100%` : `calc(100% - ${SUB_HEADER_HEIGHT}px)`)};
`;

export const Header = styled.div`
  padding: 0 ${({ theme }) => theme.spacingComfortable}px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${BLOTTER_HEADER_HEIGHT}px;
`;

export const Content = styled.div`
  flex: 1 1 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ResumeDialogWrapper = styled.div`
  h3 {
    margin: 0;
  }
  text-align: left;
`;

export const CancelDialogWrapper = styled.div`
  h3 {
    margin: 0;
  }
`;

export const SummaryWrapper = styled.div`
  text-align: left;
  p {
    margin: ${({ theme }) => theme.spacingSmall}px;
    margin-left: 0px;
    display: flex;
  }
  p span {
    margin-left: auto;
  }
`;

export const SummaryLines = styled.div`
  margin-top: ${({ theme }) => theme.spacingDefault}px;
  border-left: 2px solid darkgray;
  padding-left: ${({ theme }) => theme.spacingDefault}px;
`;

export const AccountCurrencyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingDefault}px;
  margin: ${({ theme }) => theme.spacingMedium}px 0px;
`;
