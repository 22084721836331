import {
  Dialog,
  FormControlSizes,
  FormGroup,
  Input,
  LoaderTalos,
  NotificationVariants,
  useGlobalToasts,
  type DialogProps,
  type MarketCredential,
  type User,
} from '@talos/kyoko';
import { forwardRef, useCallback, useEffect, useState, type ForwardedRef } from 'react';
import { useUserSettings } from '../useUserSettings';

interface EditMarketCredentialsDialogProps extends DialogProps {
  selected: MarketCredential | undefined;
  allowedKeys: Map<string, string[]>;
  user: User;
}

export const EditMarketCredentialsDialog = forwardRef(
  (props: EditMarketCredentialsDialogProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { selected, allowedKeys, user } = props;
    const [values, setValues] = useState<Map<string, string>>(new Map());
    const [orgID, setOrgID] = useState<number | undefined>(undefined);
    const { listUserCredentials, saveUserCredentials } = useUserSettings();
    const [loading, setLoading] = useState(true);
    const { add: addToast } = useGlobalToasts();

    useEffect(() => {
      if (!selected) {
        return;
      }
      setLoading(true);
      setValues(new Map());
      listUserCredentials(user?.ID ?? '', selected?.Market ?? '', String(selected?.CredentialID))
        .then(result => {
          setValues(new Map(Object.entries(result?.data?.[0]?.Data ?? {})));
          setOrgID(result?.data[0]?.OrgID);
        })
        .finally(() => setLoading(false));
    }, [selected, user, listUserCredentials]);

    const allowed = allowedKeys?.[0]?.[selected?.Market ?? ''] ?? [];

    const handleSaveCredentials = useCallback(() => {
      setLoading(true);
      return saveUserCredentials(user.Name, selected?.Market ?? '', String(selected?.CredentialID), orgID, values)
        .then(() => {
          addToast({
            text: 'User Market Credentials saved.',
            variant: NotificationVariants.Positive,
          });
        })
        .catch(error => {
          addToast({
            text: error?.toString() || 'An error occurred',
            variant: NotificationVariants.Negative,
          });
          throw error;
        })
        .finally(() => setLoading(false));
    }, [selected, user, saveUserCredentials, values, addToast, orgID]);

    return (
      <Dialog
        {...props}
        ref={ref}
        onConfirm={handleSaveCredentials}
        title="Market Credentials"
        width={400}
        confirmLabel="Save"
        confirmDisabled={loading}
      >
        {loading && <LoaderTalos />}
        {!loading &&
          allowed.map(key => (
            <FormGroup inline labelWidth="5rem" label={key} key={key}>
              <Input
                value={values.get(key) ?? ''}
                size={FormControlSizes.Small}
                onChange={e => {
                  setValues(prev => new Map(prev).set(key, e.target.value));
                }}
              />
            </FormGroup>
          ))}
      </Dialog>
    );
  }
);
