export const POSITIONS_V3_BLOTTER_PREFIX = 'principal/positions-v3';
export const BLOTTER_ID_UNIFIED_DERIVATIVES = 'unified-derivatives';
export const BLOTTER_ID_UNIFIED_SPOT_AND_DERIVS = 'unified-spot-derivs';
export const BLOTTER_ID_SPOT_POSITIONS = `spot-positions`;
export const BLOTTER_ID_POSITIONS_SUBACCOUNT = `subaccount`;

export const GROUP_COL_WIDTH = 190;

// Default conversion tolerance on the backend is currently '0.0001', Positions blotter overrides that
export const POSITIONS_BLOTTER_CONVERSION_TOLERANCE = '0.00001';
