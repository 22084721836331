import { ENV_COLORS, parseColor, PopoverStyles } from '@talos/kyoko';
import styled from 'styled-components';

export const SiteName = styled.h1`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin: 0px;

  svg {
    margin-right: ${({ theme }) => theme.spacingLarge}px;
    cursor: pointer;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-left: ${({ theme }) => theme.spacingLarge}px;
  }

  &:last-child {
    // This element contains icon buttons with padding and no bg, so we need to adjust margin slightly
    margin-right: ${({ theme }) => theme.spacingDefault}px;
  }
`;

export const StatusSection = styled(Section)`
  width: 100%;
  & > ${PopoverStyles.Target} {
    width: 100%;
    display: flex;
    align-items: center;

    &:hover {
      cursor: default;
    }
  }
`;

export const Environment = styled.div`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => parseColor(theme, ENV_COLORS[import.meta.env.VITE_AVA_ENV ?? ''] ?? 'gray.070')};
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  text-align: right;
`;

export const Version = styled.span`
  color: ${({ theme }) => parseColor(theme, ENV_COLORS[import.meta.env.VITE_AVA_ENV ?? ''] ?? 'gray.050')};
  text-transform: none;
`;
