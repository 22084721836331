import {
  DealerPosition,
  MarketAccountTypeEnum,
  POSITION,
  PositionUpdateSourceEnum,
  ProductTypeEnum,
  useAggDeltaUpdatesPipe,
  useSubscription,
  type Leaves,
  type MinimalSubscriptionResponse,
  type Position,
  type WebsocketRequest,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { map, type Observable } from 'rxjs';
import { useDisplaySettings } from '../../../../../providers/DisplaySettingsProvider';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../../../../Blotters/PositionsV3/tokens';
import type { SettlementMonitoringFilter } from '../types';

function getUniqueKey(cp: DealerPosition) {
  return cp.rowID;
}

const AGG_SPECS = [
  { valuePath: 'incomingEquivalent', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'outgoingEquivalent', currencyPath: 'Equivalent.Currency' },
] satisfies { valuePath: Leaves<DealerPosition>; currencyPath: Leaves<DealerPosition> }[];

interface DealerPositionsRequest extends WebsocketRequest, SettlementMonitoringFilter {
  EquivalentCurrency: string;
  Tolerance: string;
  Source: PositionUpdateSourceEnum;
}

interface UsePositionMonitoringBaseObsParams {
  filter: SettlementMonitoringFilter;
}

export const usePositionMonitoringBaseObs = ({ filter }: UsePositionMonitoringBaseObsParams) => {
  const { homeCurrency } = useDisplaySettings();

  const [request, setRequest] = useState<DealerPositionsRequest | null>(null);

  useEffect(() => {
    setRequest(currentRequest => {
      const newRequest: DealerPositionsRequest = {
        name: POSITION,
        tag: 'Settlement Monitoring - Positions',
        EquivalentCurrency: homeCurrency,
        Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
        MarketAccountTypes: [MarketAccountTypeEnum.Customer],
        AssetTypes: [ProductTypeEnum.Spot],
        Source: PositionUpdateSourceEnum.Internal,
        ...positionServerFilterKeys(filter),
      };

      return isEqual(currentRequest, newRequest) ? currentRequest : newRequest;
    });
  }, [filter, homeCurrency]);

  const { data } = useSubscription<Position>(request);
  const dealerPositionsObs: Observable<MinimalSubscriptionResponse<DealerPosition>> = useMemo(
    () => data.pipe(map(json => ({ ...json, data: json.data.map(position => new DealerPosition(position)) }))),
    [data]
  );

  const aggregationPipe = useAggDeltaUpdatesPipe({ getUniqueKey, aggSpecs: AGG_SPECS });
  const totalsDataObs = useMemo(() => dealerPositionsObs.pipe(aggregationPipe), [dealerPositionsObs, aggregationPipe]);

  return { dataObs: dealerPositionsObs, totalsDataObs };
};

function positionServerFilterKeys(filter: SettlementMonitoringFilter): SettlementMonitoringFilter {
  return {
    Symbols: filter.Symbols,
    MarketAccounts: filter.MarketAccounts,
  };
}
