export const FUNDING_RATE = 'FundingRate';
export const FUNDING_TIME = 'FundingTime';
export const MARK_PRICE = 'MarkPrice';
export const LAST_PRICE = 'LastPrice';
export const NEXT_FUNDING_TIME = 'NextFundingTime';
export const OPEN_VALUE = 'OpenValue';
export const OPEN_INTEREST = 'OpenInterest';
export const PREDICTED_NEXT_FUNDING_RATE = 'PredictedNextFundingRate';
export const SYMBOL = 'Symbol';
export const TURNOVER24H = 'Turnover24h';
export const VOLUME24H = 'Volume24h';
export const OPEN_POSITION = 'OpenPosition';
export const DELEVERAGE_QUEUE = 'DeleverageQueue';
export const UNDERLYING_PRICE = 'UnderlyingPrice';
export const MARK_PRICE_IV = 'MarkPriceIV';
export const BEST_BID_IV = 'BestBidIV';
export const BEST_OFFER_IV = 'BestOfferIV';
export const INDEX_PRICE = 'IndexPrice';
