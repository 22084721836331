import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  notSpecified: {
    defaultMessage: 'Not Specified',
    id: 'CustomerTransactionSummary.notSpecified',
  },
  pendingDealerApproval: {
    defaultMessage: 'Pending Dealer Approval',
    id: 'CustomerTransactionSummary.pendingDealerApproval',
  },
  pendingSecondaryApproval: {
    defaultMessage: 'Pending Secondary Approval',
    id: 'CustomerTransactionSummary.pendingSecondaryApproval',
  },
  pendingTransferConfirmation: {
    defaultMessage: 'Pending Transfer Confirmation',
    id: 'CustomerTransactionSummary.pendingTransferConfirmation',
  },
  txHash: {
    defaultMessage: 'TxHash',
    id: 'CustomerTransactionSummary.txHash',
  },
  txHashCopiedToClipboard: {
    defaultMessage: 'TxHash copied to clipboard',
    id: 'CustomerTransactionSummary.txHashCopiedToClipboard',
  },
  typeAutoApproved: {
    defaultMessage: '{transactionType} Auto-Approved',
    id: 'CustomerTransactionSummary.typeAutoApproved',
  },
  userApprovedType: {
    defaultMessage: '{user} Approved {transactionType, select, Withdrawal {Withdrawal} other {Deposit}}',
    id: 'CustomerTransactionSummary.userApprovedType',
  },
  userLockedQuantity: {
    defaultMessage: '{user} Locked <quantity></quantity>',
    id: 'CustomerTransactionSummary.userLockedQuantity',
  },
  userRejectedDueToReason: {
    defaultMessage: '{user} Rejected due to: {reason}',
    id: 'CustomerTransactionSummary.userRejectedDueToReason',
  },
  userReleasedQuantity: {
    defaultMessage: '{user} Released <quantity></quantity>',
    id: 'CustomerTransactionSummary.userReleasedQuantity',
  },
  userRejectedTypeForQuantity: {
    defaultMessage:
      '{user} Rejected {transactionType, select, Withdrawal {Withdrawal} other {Deposit}} for <quantity></quantity>',
    id: 'CustomerTransactionSummary.userRejectedTypeForQuantity',
  },
  completedUserApprovedTypeForQuantity: {
    defaultMessage:
      'Completed: {user} Approved {transactionType, select, Withdrawal {Withdrawal} other {Deposit}} for <quantity></quantity>',
    id: 'CustomerTransactionSummary.completedUserApprovedTypeForQuantity',
  },
  completedUserConfirmedTransfer: {
    defaultMessage: 'Completed: {user} Confirmed Transfer',
    id: 'CustomerTransactionSummary.completedUserConfirmedTransfer',
  },
  completedUserConfirmedTransferForQuantity: {
    defaultMessage: 'Completed: {user} Confirmed Transfer for <quantity></quantity>',
    id: 'CustomerTransactionSummary.completedUserConfirmedTransferForQuantity',
  },
  completedTypeForQuantity: {
    defaultMessage:
      'Completed: {transactionType, select, Withdrawal {Withdrawal} other {Deposit}} for <quantity></quantity>',
    id: 'CustomerTransactionSummary.completedTypeForQuantity',
  },
  completedUserEnteredTypeForQuantity: {
    defaultMessage:
      'Completed: {user} Entered {transactionType, select, Withdrawal {Withdrawal} other {Deposit}} for <quantity></quantity>',
    id: 'CustomerTransactionSummary.completedUserEnteredTypeForQuantity',
  },
  userApprovedTypeForQuantity: {
    defaultMessage:
      '{user} Approved {transactionType, select, Withdrawal {Withdrawal} other {Deposit}} for <quantity></quantity>',
    id: 'CustomerTransactionSummary.userApprovedTypeForQuantity',
  },
  userEnteredTypeForQuantity: {
    defaultMessage:
      '{user} Entered {transactionType, select, Withdrawal {Withdrawal} other {Deposit}} for <quantity></quantity>',
    id: 'CustomerTransactionSummary.userEnteredTypeForQuantity',
  },
  userRejectedTypeRequest: {
    defaultMessage: '{user} Rejected {transactionType, select, Withdrawal {Withdrawal} other {Deposit}} Request',
    id: 'CustomerTransactionSummary.userRejectedTypeRequest',
  },
});
