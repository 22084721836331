const performExport = (endpoint: string, model: string, q: Record<string, any>) => {
  const eq = { ...q };
  delete eq.limit;
  delete eq.offset;
  const url = `//${endpoint}/v1/organization/blotters/${model}?${new URLSearchParams(eq).toString()}`;
  const win = window.open(url, '_blank');
  win?.focus();
};

export const exportOMSOrders = (endpoint: string, q: object) => {
  return performExport(endpoint, 'OMSOrders/export', q);
};

export const exportOMSTrades = (endpoint: string, q: object) => {
  return performExport(endpoint, 'OMSTrades/export', q);
};

export const exportOMSQuotes = (endpoint: string, q: object) => {
  return performExport(endpoint, 'OMSQuotes/export', q);
};

export const exportMarketExecutionReports = (endpoint: string, q: object) => {
  return performExport(endpoint, 'MarketExecutionReports/export', q);
};

export const exportMarketOrders = (endpoint: string, q: object) => {
  return performExport(endpoint, 'MarketOrders/export', q);
};

export const exportMarketTrades = (endpoint: string, q: object) => {
  return performExport(endpoint, 'MarketTrades/export', q);
};

export const exportMarketQuotes = (endpoint: string, q: object) => {
  return performExport(endpoint, 'MarketQuotes/export', q);
};
