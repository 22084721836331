import type { FilterableProperty } from '@talos/kyoko';
import { IconName, ModeEnum, getStringLabel } from '@talos/kyoko';
import { values } from 'lodash';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for Mode properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for Mode filters
 */
export function useModeFilter<TKey extends string = 'Modes', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
): FilterableProperty<TKey> {
  return useMemo(
    () => ({
      ...({
        key: 'Modes' as TKey,
        label: 'Mode',
        field: 'Mode',
        icon: IconName.ArrowUpDown,
        options: values(ModeEnum),
        getOptionLabel: getStringLabel,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [overrides]
  );
}
