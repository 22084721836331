import { ACTION, MixpanelEventSource, ProductTypeEnum, useDynamicCallback } from '@talos/kyoko';
import { useMemo } from 'react';
import { useRoleAuth, useTransfersDrawer } from '../../../../hooks';
import { useTransfers } from '../../../../providers';
import { usePortfolioSettings } from '../../../../providers/PortfolioSettingContext';
import type { Balance } from '../../../../types';
import { useTradePositionColumn } from '../../../../utils/columns/useTradePositionColumn';
import { useTransferButtonColumn } from '../../../../utils/columns/useTransferButtonColumn';
import type { BalancesBlotterColumnSpecification } from '../../../Blotters/BalancesV2/types';
import { useBalancesColumnDefinitions } from '../../../Blotters/BalancesV2/useBalancesV2Columns';
import type { TransferForm } from '../../../Transfers/types';

// Create a simple type here to give us an extra layer of type safety around these parts
export type ExtraTreasuryColumns = 'buy' | 'sell' | 'withdraw' | 'deposit';
export type TreasuryBlotterColumnSpecification = BalancesBlotterColumnSpecification | ExtraTreasuryColumns;

export const useTreasuryColumnDefinitions = () => {
  const { enablePMSLayout } = usePortfolioSettings();
  // We only show the action buttons in the old (non-PMS) variant of treasury management
  const showActionButtons = !enablePMSLayout;

  const balancesColumnDefinitions = useBalancesColumnDefinitions();

  const { getPrimeableTransfer } = useTransfers();
  const { openTransfersDrawer } = useTransfersDrawer();

  const handleOpenTransfersDrawer = useDynamicCallback((primedTransfer: TransferForm) => {
    openTransfersDrawer({ initialTransfer: primedTransfer, source: MixpanelEventSource.TreasuryBlotter });
  });

  const { isAuthorized } = useRoleAuth();
  const authorizedToTrade = isAuthorized(ACTION.SUBMIT_ORDER);
  const authorizedToTransfer = isAuthorized(ACTION.SUBMIT_TRANSFER);

  const buyColumn = useTradePositionColumn<Balance>({
    assetField: 'Currency',
    marketAccountField: 'marketAccountName',
    constrainSecuritiesToMarket: true,
    action: 'Buy',
    columnId: 'buy' satisfies ExtraTreasuryColumns,
    getAssetType: ProductTypeEnum.Spot,
    showTitle: true,
  });

  const sellColumn = useTradePositionColumn<Balance>({
    assetField: 'Currency',
    marketAccountField: 'marketAccountName',
    constrainSecuritiesToMarket: true,
    action: 'Sell',
    columnId: 'sell' satisfies ExtraTreasuryColumns,
    getAssetType: ProductTypeEnum.Spot,
    showTitle: true,
  });

  const withdrawColumn = useTransferButtonColumn<Balance>({
    currencyField: 'Currency',
    marketField: 'Market',
    marketAccountField: 'MarketAccountID',
    type: 'withdraw',
    getPrimeableTransfer,
    onClick: handleOpenTransfersDrawer,
    columnID: 'withdraw' satisfies ExtraTreasuryColumns,
    tryWithLinkedAccount: true,
  });

  const depositColumn = useTransferButtonColumn<Balance>({
    currencyField: 'Currency',
    marketField: 'Market',
    marketAccountField: 'MarketAccountID',
    type: 'deposit',
    getPrimeableTransfer,
    onClick: handleOpenTransfersDrawer,
    columnID: 'deposit' satisfies ExtraTreasuryColumns,
    tryWithLinkedAccount: true,
  });

  const columnDefinitions = useMemo(() => {
    const map = new Map(balancesColumnDefinitions);

    if (showActionButtons && authorizedToTrade) {
      map.set('buy', buyColumn);
      map.set('sell', sellColumn);
    }

    if (showActionButtons && authorizedToTransfer) {
      map.set('withdraw', withdrawColumn);
      map.set('deposit', depositColumn);
    }

    return map;
  }, [
    balancesColumnDefinitions,
    authorizedToTrade,
    authorizedToTransfer,
    showActionButtons,
    buyColumn,
    sellColumn,
    withdrawColumn,
    depositColumn,
  ]);

  return columnDefinitions;
};
