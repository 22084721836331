import { Box, type BoxProps, Divider, Flex, Text } from '@talos/kyoko';
import type { ReactNode } from 'react';

type DetailsSectionTitleProps = BoxProps & {
  title: string;
  suffix?: ReactNode;
};

/**
 * A component for section matching the order details designs
 * ```
 * {title}----------{suffix}
 * ```
 */
export function DetailsSectionTitle({ title, suffix, ...boxProps }: DetailsSectionTitleProps) {
  return (
    <Flex gap="spacingDefault" {...boxProps}>
      <Text fontSize="fontSizeLg" whiteSpace="nowrap">
        {title}
      </Text>
      <Box w="100%" alignSelf="center">
        <Divider />
      </Box>
      {suffix}
    </Flex>
  );
}
