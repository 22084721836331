import { Wrapper } from './styles';

export const NoMatchPage = () => {
  return (
    <Wrapper>
      <h1>Sorry, the page you’re looking for can’t be found.</h1>
      <h3 onClick={() => (window.location.href = '/')}>Click here to return</h3>
    </Wrapper>
  );
};
