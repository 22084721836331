import { Children, type ReactElement, type ReactNode } from 'react';

export function Switch<T>({ children, value }: { value: T; children: ReactElement<CaseProps<T>>[] }) {
  let defaultCase: ReactNode = null;
  let matchingCase: ReactNode = null;
  Children.forEach(children, child => {
    if (child.type === Case && child.props.value === value) {
      matchingCase = child;
    } else if (child.type === Default) {
      defaultCase = child;
    } else if (
      child.type !== Case &&
      child.type !== Default &&
      import.meta.env.VITE_AVA_ENV !== 'prod' &&
      import.meta.env.VITE_AVA_ENV !== 'sandbox'
    ) {
      throw new Error('Switch children should be either Case or Default components.');
    }
  });

  return <>{matchingCase ?? defaultCase}</>;
}

type CaseProps<T> = { value: T } & DefaultProps;
export function Case<T>({ children }: CaseProps<T>): ReactElement {
  return <>{children}</>;
}

type DefaultProps = { children: ReactNode };
export function Default({ children }: DefaultProps): ReactElement {
  return <>{children}</>;
}
