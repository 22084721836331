import {
  ConnectionType,
  MarketAccountTypeEnum,
  MarketTypeEnum,
  useMarketAccountsContext,
  useMarketsContext,
  useSecuritiesContext,
  useStrategiesContext,
  useUnifiedLiquidity,
  type Allocation,
  type FixingIndex,
  type Market,
  type MarketAccount,
  type OrderStrategy,
  type Security,
} from '@talos/kyoko';
import { compact, concat, uniq } from 'lodash';
import { useMemo } from 'react';

import { useFixingIndices } from 'providers/FixingIndexProvider';
import { useTradingSettings } from '../providers/TradingSettingsContext';
import { STRATEGY_GROUP } from '../tokens/order';
import type { OrderStrategiesEnum } from '../tokens/orderStrategy';
import { usePermittedTradableMarketAccountsSet } from './usePermittedTradableMarketAccountsSet';

type UseTradableMarketAccountNamesProps = {
  security: Security | undefined;
  /**
   * If set to -1, there will be no filtering and all available market accounts will be returned
   */
  subAccountAllocations: Allocation[] | -1;
  combineAllMarketAccounts?: boolean;
  strategy?: OrderStrategiesEnum;
  fixingIndex?: FixingIndex;
} & (
  | {
      connectionType: ConnectionType.Orders;
      isUnifiedLiquidityEnabled?: boolean;
    }
  | {
      connectionType: ConnectionType.RFQ;
      isUnifiedLiquidityEnabled?: false;
    }
);

/**
 * Given a Security, strategy, configuration of the organizations PermissionFilters and subAccountAllocation(s),
 * returns list of configured, tradable market account names, independent of their status.
 *
 */
export function useTradableMarketAccountNames({
  security,
  fixingIndex,
  subAccountAllocations,
  connectionType,
  isUnifiedLiquidityEnabled = false,
  combineAllMarketAccounts = false,
  strategy,
}: UseTradableMarketAccountNamesProps): MarketAccount['Name'][] {
  const unifiedLiquidityToken = useUnifiedLiquidity(security?.Symbol);
  const { isMarketConfigured, isMarketOnline, marketsByName } = useMarketsContext();

  const { marketAccountsByMarket } = useMarketAccountsContext();
  const { allowSyntheticCrosses } = useTradingSettings();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { strategiesByName } = useStrategiesContext();
  const { marketsByFixingIndex } = useFixingIndices();

  // If Set<string>, only those marketAccounts included are allowed, if null, everything goes
  const permittedTradableMarketAccountsSet = usePermittedTradableMarketAccountsSet({
    subAccountAllocations,
    combineAllMarketAccounts,
  });

  const possibleMarketAccountNames = useMemo(() => {
    if (!security || !security.Markets) {
      return [];
    }

    let possibleMarkets = security.Markets.filter(market => isMarketConfigured(market, connectionType));

    // Possibly extend the market selection with rfq synthethic crossing
    if (connectionType === ConnectionType.RFQ && allowSyntheticCrosses) {
      const crossSecurity = securitiesBySymbol.get(`${security.BaseCurrency}-USD`);
      if (crossSecurity != null) {
        const crossSecurityMarkets = crossSecurity.Markets?.filter(m => isMarketOnline(m, ConnectionType.RFQ)) || [];
        possibleMarkets = concat(possibleMarkets, crossSecurityMarkets);
      }
    }
    // Possibly extend the market selection with unified liquidity enabled
    else if (isUnifiedLiquidityEnabled && unifiedLiquidityToken) {
      const unifiedLiquidityMarkets = unifiedLiquidityToken.Tokens.map(m => m.Market);
      possibleMarkets = concat(possibleMarkets, unifiedLiquidityMarkets);
    }

    // remove markets based on selected strategy
    if (strategy) {
      const orderStrategy = strategiesByName.get(strategy);
      possibleMarkets = possibleMarkets.filter(m => {
        const market = marketsByName.get(m);
        return filterMarketsByStrategy(market, orderStrategy);
      });
    }

    if (fixingIndex == null) {
      // Remove all markets that have a market fixing index
      const marketsWithFixingIndex = [...marketsByFixingIndex.values()].flat();
      possibleMarkets = possibleMarkets.filter(market => !marketsWithFixingIndex.includes(market));
    } else {
      // Possibly exclude the market selection based on fixing index
      const marketsForFixingIndex = marketsByFixingIndex.get(fixingIndex.Name) || [];
      possibleMarkets = possibleMarkets.filter(market => marketsForFixingIndex.includes(market));
    }

    return compact(
      uniq(possibleMarkets).flatMap(market =>
        marketAccountsByMarket.has(market)
          ? marketAccountsByMarket.get(market)!.map(ma =>
              // Ensure market account is of trading type
              ma.Type === MarketAccountTypeEnum.Trading ? ma.Name : null
            )
          : null
      )
    );
  }, [
    allowSyntheticCrosses,
    connectionType,
    isMarketConfigured,
    isMarketOnline,
    isUnifiedLiquidityEnabled,
    marketAccountsByMarket,
    marketsByName,
    strategiesByName,
    securitiesBySymbol,
    security,
    unifiedLiquidityToken,
    strategy,
    marketsByFixingIndex,
    fixingIndex,
  ]);

  return useMemo(() => {
    if (permittedTradableMarketAccountsSet === null || subAccountAllocations === -1) {
      return possibleMarketAccountNames;
    }
    // there is a whitelist of market accounts for the particular selection of subaccounts and org setup
    return possibleMarketAccountNames.filter(marketaccount => permittedTradableMarketAccountsSet.has(marketaccount));
  }, [permittedTradableMarketAccountsSet, possibleMarketAccountNames, subAccountAllocations]);
}

function filterMarketsByStrategy(market: Market | undefined, orderStrategy: OrderStrategy | undefined): boolean {
  if (!market || !orderStrategy) {
    return false;
  }
  if (orderStrategy.Group === STRATEGY_GROUP.DARK) {
    return market.Type === MarketTypeEnum.Dark;
  } else {
    return market.Type !== MarketTypeEnum.Dark;
  }
}
