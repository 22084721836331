import { Crossmark } from '@talos/kyoko';

import { ErrorMessage } from './styles';

import { useTheme } from 'styled-components';

/** @deprecated Use kyoko component <FormMessage error={true}> */
export function FormError(props) {
  const { children, style, color } = props;
  const theme = useTheme();

  return (
    <ErrorMessage color={color || theme.colors.red.lighten} style={style}>
      <Crossmark />
      {children}
    </ErrorMessage>
  );
}
