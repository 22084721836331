import { allowContracts, getScaleFromIncrement, type Security } from '@talos/kyoko';

export const getQuantityIncrement = (security?: Security, quantityCurrency?: string) => {
  if (!security || !quantityCurrency) {
    return 0;
  }
  const { BaseCurrency, MinSizeIncrement, MinPriceIncrement } = security;
  const minIncrement = quantityCurrency === BaseCurrency ? MinSizeIncrement : MinPriceIncrement;

  if (allowContracts(security)) {
    // Relying on the fact that contracts have their own validation
    return undefined;
  }

  return getScaleFromIncrement(minIncrement);
};

/**
 * Selectors causes re-rendering anytime anything selected changes, default using === comparison
 * However we don't always want every single selector to re-render when reference data / dependencies changes
 *
 * Useful for those selectors that select entire slice to reduce unnecessary re-renders
 * Specialized selectors that do care about ref data would take care of re-rendering only when necessary
 */
export const noRefDataDataEqualityCheck = (oldState, newState) => {
  return Object.keys(oldState)
    .filter(key => key !== 'referenceData' && key !== 'dependencies')
    .every(key => oldState[key] === newState[key]);
};
