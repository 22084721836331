import styled from 'styled-components';

export const StatsWrapper = styled.div`
  max-height: 365px;
  flex: 1 0 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const StatRow = styled.div`
  display: flex;
  flex: 1 1 ${({ theme }) => theme.spacingMedium}px;
  align-items: center;
`;

export const QueueWrapper = styled.div`
  display: inline-flex;
  height: 12px;
`;

export const QueueIndicator = styled.div<{ color: string; active: boolean }>`
  width: 20%;
  background: ${({ color, theme }) => color || theme.colors.gray['060']};
  opacity: ${({ active }) => (active ? 1 : 0.1)};
  transition: opacity 200ms ease;
  height: 12px;
  width: 15px;
`;
