import {
  NotificationVariants,
  OrdTypeEnum,
  SideEnum,
  TimeInForceEnum,
  getPositionAmount,
  toBig,
  useGlobalToasts,
  useSecuritiesContext,
  type Position,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import { compact } from 'lodash';
import { useCallback, useMemo } from 'react';
import { v1 as uuid } from 'uuid';
import { useStrategies } from '../../../../providers/StrategiesProvider';
import { useTradingSettings } from '../../../../providers/TradingSettingsContext';
import type { SendOrderArgs } from '../../../../providers/orders.types';
import { useGetTradePositionDetails, type TradePositionDetails } from '../../../../utils/getTradePositionDetails';

/**
 * The ClosablePosition interface describes a position's ability to be closed.
 */
export interface ClosablePosition {
  orderArgs: SendOrderArgs;
  closingDetails: TradePositionDetails<Position>;
}

/**
 * Returns an **unstable** function which can be invoked to map from positions to "ClosablePositions", which essentially represents your
 * position + necessary details in order to close that position.
 */
export const useGetBulkClosablePositions = () => {
  const { strategiesByName } = useStrategies();
  const toasts = useGlobalToasts();
  const { spotTradingPairsByBaseCurrency } = useSecuritiesContext();
  const getTradePositionDetails = useGetTradePositionDetails();

  const { bulkClosePositionQuoteCurrencies } = useTradingSettings();
  const bulkCloseQuoteCurrenciesSet = useMemo(
    () => new Set(bulkClosePositionQuoteCurrencies),
    [bulkClosePositionQuoteCurrencies]
  );

  const getBulkClosablePositions = useCallback(
    (positions: Position[]): ClosablePosition[] => {
      const marketStrategy = strategiesByName.get('Market');
      if (!marketStrategy) {
        toasts.add({
          text: 'Unable to close positions due to not finding the Market strategy',
          variant: NotificationVariants.Negative,
        });
        return [];
      }

      const ordersToSend = positions.map(position => {
        // If the position is of an asset we close into, its already "closed"
        const positionAlreadyClosed = bulkCloseQuoteCurrenciesSet.has(position.Asset);
        if (positionAlreadyClosed) {
          return undefined;
        }

        // For bulk closing, we only want to create SendOrderArgs with a small, selected, set of quote currencies.
        const filteredSpotTradingPairsByBaseCurrency = new Map<string, Security[]>();
        filteredSpotTradingPairsByBaseCurrency.set(
          position.Asset,
          (spotTradingPairsByBaseCurrency.get(position.Asset) ?? []).filter(security =>
            bulkCloseQuoteCurrenciesSet.has(security.QuoteCurrency)
          )
        );

        const closingDetails = getTradePositionDetails<Position>({
          action: 'Close',
          position,
          assetType: position.AssetType,
          assetField: 'Asset',
          marketAccountField: 'MarketAccount',
          subAccountField: 'SubAccount',
          constrainSecuritiesToMarket: true,
          spotTradingPairsByBaseCurrency: filteredSpotTradingPairsByBaseCurrency,
          spotTradingPairsByQuoteCurrency: undefined, // effectively dont allow inverse closing here
        });

        if (!closingDetails.tradable) {
          return undefined;
        }

        const defaultSecurity = closingDetails.possibleSecurities.at(0);
        if (!defaultSecurity) {
          return undefined;
        }

        const rawPositionAmount = toBig(position.Amount);
        if (rawPositionAmount == null) {
          return undefined;
        }

        const side = rawPositionAmount.gt(0) ? SideEnum.Sell : SideEnum.Buy;
        const positionAmount = getPositionAmount(position.Amount, defaultSecurity);

        const orderArgs: SendOrderArgs = {
          symbol: defaultSecurity.Symbol,
          side,
          orderQty: Big(positionAmount.value).abs().toFixed(),
          orderCurrency: positionAmount.currency,
          marketAccounts: [position.MarketAccount],
          subAccount: position.SubAccount,
          group: '',
          clOrdID: uuid(),
          selectedStrategy: marketStrategy,
          timeInForce: TimeInForceEnum.GoodTillCancel,
          ordType: OrdTypeEnum.Market,
        };

        return { orderArgs: orderArgs, closingDetails };
      });

      return compact(ordersToSend);
    },
    [bulkCloseQuoteCurrenciesSet, spotTradingPairsByBaseCurrency, strategiesByName, toasts, getTradePositionDetails]
  );

  return { getBulkClosablePositions };
};
