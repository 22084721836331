import { useCustomerOrder } from './useCustomerOrder';
import { useOrder } from './useOrder';

/**
 * This hook will return the customer order and the principal order by making the requests either simultaneously or one after the other.
 * Typically only one of the ids would be provided, and the other request will then be made once the first request returns.
 *
 * The hook supports resolving several types of orders. If there is an ID yet to be resolved, the isLoading value will be true until that entity has been received.
 *
 * TODO expand this hook to allow for multiple hedge orders to exist and for callers to be able to tell the difference between a hedge order
 * not existing and a hedge order request being in flight.
 */
export function useOrderAndCustomerOrder({
  orderID,
  customerOrderID,
  tag,
}: {
  orderID?: string;
  customerOrderID?: string;
  tag: string;
}) {
  const order = useOrder({ orderID, tag: `${tag}/useOrderAndCustomerOrder` });

  const effectiveCustomerOrderID = order?.customerParentOrderID ?? customerOrderID;
  const customerOrder = useCustomerOrder({
    orderID: effectiveCustomerOrderID,
    tag: `${tag}/useOrderAndCustomerOrder - customer order`,
  });

  const effectiveHedgeOrderID = customerOrder?.OrderID ?? customerOrderID;
  const hedgeOrder = useOrder({
    parentOrderID: effectiveHedgeOrderID,
    tag: `${tag}/useOrderAndCustomerOrder - hedge order`,
  });

  const isLoading = !!(
    (orderID != null && !order) ||
    (effectiveCustomerOrderID != null && !customerOrder) ||
    (effectiveHedgeOrderID != null && !hedgeOrder)
  );

  return { customerOrder, order: hedgeOrder ?? order, isLoading };
}
