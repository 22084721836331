import type { DrawerProps, MarketAccount, MarketCredential } from '@talos/kyoko';
import { memo } from 'react';
import { TradingControlsDetailsDrawer } from './TradingControlsDetailsDrawer';

interface TradingControlsDrawerProps {
  selectedEntity: MarketAccount | undefined;
  marketCredentials: MarketCredential[];
}

/**
 * Wrapper for the TradingControlsDetailsDrawer component that fetches the necessary data for the drawer and can be embedded
 * in other components with minimal setup paired with useTradingControlsDetailsDrawerWrapper.
 */
export const TradingControlsDetailsDrawerWrapper = memo((props: TradingControlsDrawerProps & DrawerProps) => {
  const { selectedEntity } = props;

  return <TradingControlsDetailsDrawer {...props} selectedEntity={selectedEntity} />;
});
