import type { Position } from '@talos/kyoko';
import type { PortfolioRiskDataItem } from '../../types/PortfolioRiskDataItem';
import type { PortfolioRiskGridData } from '../../types/PortfolioRiskGridData';
import type { PortfolioRiskSubscriptionData } from '../../types/PortfolioRiskSubscriptionData';
import type { HierarchicalGridData } from '../PortfolioRiskBlotter/useRollupTreeGridBuilders';

export class PortfolioBreakdownBlotterGridItem implements HierarchicalGridData {
  hierarchicalRowInfo: string[];
  skipAggregation: boolean;
  rowID: string;

  RiskData: PortfolioRiskGridData;
  RiskWsData: PortfolioRiskSubscriptionData;

  RiskPosition?: Position;

  constructor(data: PortfolioRiskDataItem, position?: Position) {
    const gridData = data.gridData;
    this.hierarchicalRowInfo = gridData.hierarchicalRowInfo;
    this.skipAggregation = gridData.skipAggregation;
    this.rowID = gridData.rowID;
    this.RiskData = gridData;
    this.RiskPosition = position;
    this.RiskWsData = data.wsData;
  }
}

export class PortfolioPerformanceBlotterGridItem implements HierarchicalGridData {
  hierarchicalRowInfo: string[];
  skipAggregation: boolean;
  rowID: string;

  RiskData: PortfolioRiskGridData;
  RiskWsData: PortfolioRiskSubscriptionData;

  RiskPosition?: Position;

  constructor(data: PortfolioRiskDataItem, position?: Position) {
    const gridData = data.gridData;
    this.hierarchicalRowInfo = gridData.hierarchicalRowInfo;
    this.skipAggregation = gridData.skipAggregation;
    this.rowID = gridData.rowID;
    this.RiskData = gridData;
    this.RiskPosition = position;
    this.RiskWsData = data.wsData;
  }
}
