import {
  Box,
  Button,
  ButtonVariants,
  FormRowStatus,
  FormTable,
  HStack,
  IconName,
  ModeEnum,
  NotificationVariants,
  Text,
  VStack,
  promisesHaveResolved,
  useFormTable,
  useGlobalToasts,
  type Column,
} from '@talos/kyoko';
import { Loader, LoaderWrapper } from 'components/Loader';
import { useTradingLimits, type Limit } from 'providers/TradingLimitsContext';
import { useCallback, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import { useSymbolOrCurrencyColumn, useUsersColumn } from '../../../../utils/columns';
import { LIMIT_CURRENCIES_MAP } from '../utils';

export const validateLimit = limit => {
  let validationError: string | undefined;
  if (limit.WarnThreshold === '') {
    validationError = `All limits must have a warn threshold.`;
  } else if (limit.RejectThreshold === '') {
    validationError = `All limits must have a reject threshold.`;
  } else if (limit.ThresholdCurrency == null) {
    validationError = `Please specify a threshold currency for all limits.`;
  }
  if (validationError != null) {
    throw new Error(validationError);
  }
};

export function MaxOrderSize() {
  const [isSaving, setIsSaving] = useState(false);
  const { add: addToast } = useGlobalToasts();

  const { listMaxOrderSizeLimits, createMaxOrderSizeLimit, updateMaxOrderSizeLimit, deleteMaxOrderSizeLimit } =
    useTradingLimits();
  const { value: maxOrderSizeLimits } = useAsync(listMaxOrderSizeLimits, []);

  const symbolOrCurrencyColumn = useSymbolOrCurrencyColumn({});
  const usersColumn = useUsersColumn<Limit>({
    field: 'User',
    title: 'User(s)',
    includeGroups: true,
    wildcard: true,
    headerTooltip:
      'Enforce the limit for an individual user or a user group, or leave empty (*) to enforce the limit for all users and user groups.',
  });

  const columns = useMemo<Column[]>(
    () => [
      { type: 'mode', field: 'Mode', title: 'Enabled', width: 80 },
      {
        type: 'text',
        field: 'Description',
        description: 'Give your limit a description to distinguish it from other limits.',
        width: 120,
        editable: true,
      },
      usersColumn,
      symbolOrCurrencyColumn,
      {
        type: 'number',
        title: 'Warn threshold',
        field: 'WarnThreshold',
        editable: true,
        width: 160,
        description: 'Display a warning in the Talos user-interface when the order size exceeds this threshold',
      },
      {
        type: 'number',
        title: 'Reject threshold',
        field: 'RejectThreshold',
        editable: true,
        width: 160,
        description: 'Prevent the order from being submitted when the order size exceeds this threshold.',
      },
      {
        type: 'currency',
        field: 'ThresholdCurrency',
        title: 'Threshold Currency',
        editable: true,
        params: {
          currencies: LIMIT_CURRENCIES_MAP,
        },
      },
      { type: 'filler', id: 'filler' },
      { type: 'remove', id: 'remove' },
    ],
    [symbolOrCurrencyColumn, usersColumn]
  );

  const formTable = useFormTable<Partial<Limit>>({
    rowID: 'TradingLimitID',
    data: maxOrderSizeLimits?.data || [],
    columns,
  });

  const handleAdd = useCallback(() => {
    formTable.addRow({ Mode: ModeEnum.Enabled, Description: '', WarnThreshold: '', RejectThreshold: '' });
  }, [formTable]);

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const rows: any[] = formTable.getRows();
    const requests: any[] = [];

    try {
      for (const row of rows) {
        validateLimit(row.data);
      }
    } catch (e: any) {
      addToast({
        text: `Could not save trading limits: ${e.message}`,
        variant: NotificationVariants.Negative,
      });
      setIsSaving(false);
      return;
    }

    for (const row of rows) {
      switch (row.status) {
        case FormRowStatus.Added:
          requests.push(
            createMaxOrderSizeLimit(row.data)
              .then(({ data }) => {
                row.setData(data[0]);
                return data[0];
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not add new max order size limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        case FormRowStatus.Updated:
          requests.push(
            updateMaxOrderSizeLimit(row.data.TradingLimitID, row.data)
              .then(() => {
                row.setData(row.data);
                return row.data;
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not update max order size limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        case FormRowStatus.Removed:
          requests.push(
            deleteMaxOrderSizeLimit(row.data.TradingLimitID)
              .then(() => {
                row.remove(true);
                return row.data;
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not delete max order size limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        default:
          break;
      }
    }
    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Max Order Size Limits saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Max Order Size Limits could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [addToast, formTable, createMaxOrderSizeLimit, updateMaxOrderSizeLimit, deleteMaxOrderSizeLimit]);

  return maxOrderSizeLimits ? (
    <VStack w="100%" h="100%" alignItems="flex-start">
      <VStack alignItems="flex-start" my="spacingDefault" gap="spacingDefault" w="100%">
        <Text>The maximum order size that can be placed.</Text>
        <HStack w="100%" justifyContent="flex-end" gap="spacingDefault">
          <Button startIcon={IconName.Plus} onClick={handleAdd}>
            Add Limit
          </Button>
          <Button variant={ButtonVariants.Primary} onClick={handleSave} disabled={isSaving || !formTable.isDirty}>
            Save
          </Button>
        </HStack>
      </VStack>
      <Box h="100%" w="100%">
        <FormTable {...formTable} />
      </Box>
    </VStack>
  ) : (
    <LoaderWrapper style={{ minHeight: '122px' }}>
      <Loader />
    </LoaderWrapper>
  );
}
