import { addSeconds, differenceInSeconds, isAfter } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface UseReconCheckpointNavigationParams {
  /** A Date object of the latest received Checkpoint's .StartTime property */
  latestReceivedStartTime: Date | undefined;
  /** A Date object of the latest received Checkpoint's .Endtime property */
  latestReceivedEndTime: Date | undefined;
  /** The start time the user has selected for viewing. Undefined means that nothing has been set, signifying "Now" or "Live" */
  selectedStartTime: Date | undefined;
  /** See `selectedStartTime` */
  selectedEndTime: Date | undefined;

  /** Called when the selected start time should changed. */
  onChangeStartTime: (newStartTime: Date | undefined) => void;
  /** Called when the selected end time should change. */
  onChangeEndTime: (newEndTime: Date | undefined) => void;
}

/**
 * This hook exposes an API for navigating across SubAccountReconCheckpoint time windows. This hook is controlled, meaning that the implementer
 * needs to maintain the `selectedStartTime` and `selectedEndTime` pieces of state, and update those when their respective onChange handlers are called.
 *
 * The problem this hook solves is that the UI doesn't know 1) what the window duration is for checkpoints, and 2) what checkpoint windows are currently
 * available to be viewed. This is worked around by 1) grabbing the assumed window duration from the latest received start and end times, and 2) setting
 * the selected start and end times to undefined on reset. Setting these to undefined causes the backend to send us the latest window. We can then go
 * backwards from there.
 */
export const useReconCheckpointNavigation = ({
  latestReceivedStartTime,
  latestReceivedEndTime,
  selectedStartTime,
  selectedEndTime,
  onChangeStartTime,
  onChangeEndTime,
}: UseReconCheckpointNavigationParams) => {
  // We maintain the latest / "extreme" end time we have received during the life cycle of this hook.
  const [extremeEndTime, setExtremeEndTime] = useState<Date>();
  useEffect(() => {
    if (!latestReceivedEndTime) {
      return;
    }

    setExtremeEndTime(curr => {
      if (!curr) {
        return latestReceivedEndTime;
      }

      return isAfter(latestReceivedEndTime, curr) ? latestReceivedEndTime : curr;
    });
  }, [latestReceivedEndTime]);

  // The interval we observe the checkpoints to be generated at in terms of seconds.
  // Should be the same for every checkpoint we get but just for simplicity we do this
  const checkpointIntervalSeconds = useMemo(() => {
    if (!latestReceivedStartTime || !latestReceivedEndTime) {
      return undefined;
    }

    return differenceInSeconds(latestReceivedEndTime, latestReceivedStartTime);
  }, [latestReceivedEndTime, latestReceivedStartTime]);

  const resetToNow = useCallback(() => {
    onChangeStartTime(undefined);
    onChangeEndTime(undefined);
  }, [onChangeEndTime, onChangeStartTime]);

  const currentStartTime = selectedStartTime ?? latestReceivedStartTime;
  const currentEndTime = selectedEndTime ?? latestReceivedEndTime;

  const step = useCallback(
    (direction: 'forwards' | 'backwards') => {
      if (!checkpointIntervalSeconds || !currentStartTime || !currentEndTime) {
        return; // noop
      }

      const change = direction === 'backwards' ? -checkpointIntervalSeconds : checkpointIntervalSeconds;
      const newStartTime = addSeconds(currentStartTime, change);
      const newEndTime = addSeconds(currentEndTime, change);

      // If this step brings us to now, then just reset instead.
      if (newEndTime.getTime() === extremeEndTime?.getTime()) {
        resetToNow();
      } else {
        onChangeStartTime(newStartTime);
        onChangeEndTime(newEndTime);
      }
    },
    [
      currentEndTime,
      currentStartTime,
      checkpointIntervalSeconds,
      onChangeEndTime,
      onChangeStartTime,
      resetToNow,
      extremeEndTime,
    ]
  );

  const stepBackwards = useCallback(() => step('backwards'), [step]);
  const stepForwards = useCallback(() => step('forwards'), [step]);

  const selectedEndTimeIsNow =
    selectedEndTime && extremeEndTime && selectedEndTime?.getTime() === extremeEndTime?.getTime();

  // selectedEndTime being nullish means we're at "now"
  const stepForwardsDisabled = checkpointIntervalSeconds == null || selectedEndTime == null || selectedEndTimeIsNow;
  const stepBackwardsDisabled = checkpointIntervalSeconds == null;
  const resetDisabled = selectedEndTime == null;

  return {
    resetToNow,
    stepBackwards,
    stepForwards,
    resetDisabled,
    stepForwardsDisabled,
    stepBackwardsDisabled,
  };
};
