import { AgTooltipSimpleStyle, Z_INDEX } from '@talos/kyoko';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  @media print{
    print-color-adjust: exact;
    @page {
      size: A3 landscape;
      margin: 0in;
    }
  }

  #app {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  h1, h2, h3, strong {
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }

  h4 {
    color: ${({ theme }) => theme.colors.gray['090']};
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }

  h5 {
    color: ${({ theme }) => theme.colors.gray['080']};
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }

  h6 {
    color: ${({ theme }) => theme.colors.gray['070']};
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }

  strong {
    white-space: nowrap;
  }

  pre, code {
    font-family: Roboto Mono, monospace;
  }

  a {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray['070']}`};
    color: ${({ theme }) => theme.colors.gray['090']};
    text-decoration: none;
  }

  // Make sure the context menu (which is attached to document.body) is visible
  .ag-popup-child {
    z-index: ${Z_INDEX.popover};
  }

  .ag-tooltip {
    ${AgTooltipSimpleStyle}
  }
  
`;
