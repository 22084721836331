import {
  AgGridTextTooltip,
  BlotterTable,
  type ColumnDef,
  type Leaves,
  type MinimalSubscriptionResponse,
  type Order,
  useBlotterTable,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { type Observable, of } from 'rxjs';
import { HedgeOrderIcon } from '../../../../../../../components/DDH/DDHIcon';

interface DDHDetailsTableProps {
  order: Order;
}

type OrderLeaves = Leaves<Order>;

export const DDHParentOrderDetailsBlotter = ({ order }: DDHDetailsTableProps) => {
  const dataObservable: Observable<MinimalSubscriptionResponse<Order>> = useMemo(() => {
    return of({ initial: true, data: [order] });
  }, [order]);

  const blotterTable = useBlotterTable({
    dataObservable,
    columns,
    rowID: 'OrderID' satisfies keyof Order,
  });

  const gridOptions = useMemo(
    () => ({
      ...blotterTable.gridOptions,
      popupParent: document.body,
    }),
    [blotterTable.gridOptions]
  );

  return <BlotterTable {...blotterTable} gridOptions={gridOptions} />;
};

const columns = [
  {
    type: 'security',
    field: 'HedgeOrderStatus.HedgeSymbol',
    flex: 1,
    minWidth: 80,
    title: 'Symbol',
    params: {
      suffix: <HedgeOrderIcon />,
    },
  },
  {
    type: 'side',
    field: 'HedgeOrderStatus.HedgeSide',
    title: 'Side',
  },
  {
    type: 'strategy',
    field: 'HedgeOrderStatus.HedgeStrategy',
    title: 'Strategy',
  },
  {
    type: 'size',
    field: 'HedgeOrderStatus.totalQty',
    title: 'Hedge Qty',
    params: {
      currencyField: 'HedgeOrderStatus.Currency' satisfies OrderLeaves,
    },
    tooltipValueGetter: () =>
      "Hedge Quantity refers to the total amount the system aims to hedge. For each fill, the system calculates the hedge amount based on the option's delta at the time of the fill and the fill quantity. The Hedge Quantity is the cumulative sum of these hedge amounts, calculated for each fill at its specific timestamp.",
    tooltipComponent: AgGridTextTooltip,
  },
  {
    type: 'size',
    field: 'HedgeOrderStatus.CumHedgeQty',
    title: 'Filled Qty',
    params: {
      currencyField: 'HedgeOrderStatus.Currency' satisfies OrderLeaves,
    },
  },
  {
    type: 'price',
    field: 'HedgeOrderStatus.AvgHedgePx',
    title: 'Filled Price',
    params: {
      assetField: 'HedgeOrderStatus.HedgeSymbol' satisfies OrderLeaves,
    },
  },
  {
    type: 'size',
    field: 'HedgeOrderStatus.CumHedgeAmt',
    title: 'Filled Amount',
    params: { currencyField: 'HedgeOrderStatus.AmountCurrency' satisfies OrderLeaves },
  },
  {
    type: 'size',
    field: 'HedgeOrderStatus.CumHedgeFee',
    title: 'Fees',
    params: {
      currencyField: 'HedgeOrderStatus.FeeCurrency' satisfies OrderLeaves,
    },
  },
] satisfies ColumnDef<Order>[];
