import { ProductTypeEnum, type Security } from '@talos/kyoko';
import { useCallback } from 'react';

export function useGetReduceFirstSupport() {
  const getReduceFirstSupportFn = useCallback(
    (security: Security | undefined, isUnifiedLiquidityEnabled: boolean) =>
      getReduceFirstSupport(security, isUnifiedLiquidityEnabled),
    []
  );

  return {
    getReduceFirstSupport: getReduceFirstSupportFn,
  };
}

export function getReduceFirstSupport(
  security: Security | undefined,
  isUnifiedLiquidityEnabled: boolean | undefined
): boolean {
  if (!security || !isUnifiedLiquidityEnabled) {
    return false;
  }
  const isSecuritySupported = [ProductTypeEnum.PerpetualSwap].includes(security.ProductType);
  if (!isSecuritySupported) {
    return false;
  }

  return true;
}
