import { wsSubscriptionCache, type MinimalSubscriptionResponse } from '@talos/kyoko';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { Observable } from 'rxjs';
import { getBalanceKey, type Balance } from '../../../../types';
import { useBalancesSub } from '../hooks/useBalancesSub';
import { useHistoricalPositionsSub } from '../hooks/useHistoricalPositionsSub';
import { useTreasuryManagementContext } from './TreasuryManagementStateAndTabsProvider';

export const TreasuryManagementBalancesContext = createContext<TreasuryManagementBalancesContextProps | undefined>(
  undefined
);

export type TreasuryManagementBalancesContextProps = {
  balancesObs: Observable<MinimalSubscriptionResponse<Balance>>;
};

export function useTreasuryManagementBalances() {
  const context = useContext(TreasuryManagementBalancesContext);
  if (context === undefined) {
    throw new Error(
      'Missing TreasuryManagementBalancesContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export const TreasuryManagementBalancesProvider = function TreasuryManagementBalancesProvider({
  children,
}: PropsWithChildren) {
  const {
    state: { snapshotDate },
  } = useTreasuryManagementContext();

  const balancesSub = useBalancesSub();
  const historicalPositionsSub = useHistoricalPositionsSub({ tag: 'TreasuryManagementBalancesProvider' });

  // We also cache each subscription independently
  const cachedBalancesObs = useMemo(() => {
    return balancesSub.pipe(wsSubscriptionCache(getBalanceKey));
  }, [balancesSub]);
  const cachedHistoricalPositionsObs = useMemo(() => {
    return historicalPositionsSub.pipe(wsSubscriptionCache(getBalanceKey));
  }, [historicalPositionsSub]);

  const subscribeToHistoricalData = snapshotDate != null;
  const balancesObs = useMemo(
    () =>
      new Observable<MinimalSubscriptionResponse<Balance>>(output => {
        const sub = !subscribeToHistoricalData
          ? cachedBalancesObs.subscribe(json => {
              output.next(json);
            })
          : cachedHistoricalPositionsObs.subscribe(json => {
              output.next(json);
            });

        // cleanup
        return () => {
          sub.unsubscribe();
        };
      }),
    [subscribeToHistoricalData, cachedBalancesObs, cachedHistoricalPositionsObs]
  );

  const value = useMemo(() => {
    return {
      balancesObs,
    };
  }, [balancesObs]);

  return (
    <TreasuryManagementBalancesContext.Provider value={value}>{children}</TreasuryManagementBalancesContext.Provider>
  );
};
