import {
  Box,
  HStack,
  IndicatorBadge,
  IndicatorBadgeSizes,
  readableDateWithSeconds,
  Text,
  usePortal,
} from '@talos/kyoko';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useSubAccounts } from '../../../../../providers';
import { RECON_OVERVIEW_BLOTTER_PORTAL_ID_MODAL } from '../../ReconOverview/tokens';
import { useBreakDetailsState } from '../providers/BreakDetailsStateProvider';

export const ControlPanel = () => {
  const { state } = useBreakDetailsState();
  const { subAccountsByName } = useSubAccounts();
  const subAccountsStr = useMemo(
    () => state.subAccounts.map(sa => subAccountsByName.get(sa)?.DisplayName ?? sa).join(', '),
    [state.subAccounts, subAccountsByName]
  );

  const subAccsPlural = state.subAccounts.length > 1;
  const subAccsBadgeLabel = subAccsPlural ? 'Sub Accounts' : 'Sub Account';

  const { setPortalRef: filtersContainerRef } = usePortal(RECON_OVERVIEW_BLOTTER_PORTAL_ID_MODAL);

  return (
    <ControlPanelContent w="100%">
      <HStack px="spacingDefault" gap="spacingDefault" w="100%" justifyContent="space-between" p="spacingDefault">
        <HStack gap="spacingDefault" justifyContent="flex-start">
          <BadgeData label="Instrument" value={state.asset} />
          <BadgeData label={subAccsBadgeLabel} value={subAccountsStr} />
          <BadgeData label="As Of" value={readableDateWithSeconds(state.lastUpdateTime)} />
        </HStack>

        <Box ref={filtersContainerRef} />
      </HStack>
    </ControlPanelContent>
  );
};

export const BadgeData = ({ label, value }: { label: string; value: string }) => {
  return (
    <>
      <IndicatorBadge size={IndicatorBadgeSizes.Large}>
        <HStack gap="spacingSmall">
          <Text fontWeight="bold" color="colorTextSubtle">
            {label}
          </Text>
          <Text color="colorTextImportant">{value}</Text>
        </HStack>
      </IndicatorBadge>
    </>
  );
};

const ControlPanelContent = styled(Box)`
  border-bottom: 2px solid ${({ theme }) => theme.backgroundBody};
  background: ${({ theme }) => theme.colors.gray.main};
`;
