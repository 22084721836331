import { CUSTOMER_TRADE, useObservable, useSubscription, type CustomerTrade } from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { filter, map } from 'rxjs/operators';

export function useCustomerTrade({ tradeID }: { tradeID?: string }): CustomerTrade | undefined {
  const [request, setRequest] = useState<any>(null);
  const [customerTrade, setCustomerTrade] = useState<CustomerTrade>();
  const { data: subscription } = useSubscription(request, { replay: false });
  const observable = useObservable(
    () =>
      subscription.pipe(
        filter((json: { data: CustomerTrade[] }) => json.data.length > 0),
        map((response: { data: CustomerTrade[] }) => {
          const trade = response.data.find(trade => trade.TradeID === tradeID);
          return trade;
        })
      ),
    [tradeID, subscription]
  );

  useEffect(() => {
    setRequest(tradeID ? { name: CUSTOMER_TRADE, TradeID: tradeID } : null);
  }, [tradeID]);

  useEffect(() => {
    const subscription = observable.subscribe(trade => {
      if (trade) {
        setCustomerTrade(trade);
      }
    });
    return () => subscription.unsubscribe();
  }, [observable]);

  return customerTrade;
}
