import type { WritableDraft } from 'immer';

import {
  FieldValidationLevel,
  toBig,
  toBigWithDefault,
  validatePrecision,
  type FieldValidationRule,
  type NumericField,
} from '@talos/kyoko';
import type { CareOrderState } from './types';

export const quantityValidation: FieldValidationRule<NumericField, WritableDraft<CareOrderState>> = (
  field,
  context
) => {
  if (!context) {
    return null;
  }

  const { form, careOrderBeingModified } = context;

  const security = form?.symbolField.value;
  if (!security) {
    return null;
  }

  const quantity = toBigWithDefault(field.value, 0);

  const { MinimumSize, MinSizeIncrement } = security;

  const minSize = MinimumSize;
  const minIncr = MinSizeIncrement;

  if (quantity.lt(minSize)) {
    return {
      message: `Min quantity is ${minSize}`,
      level: FieldValidationLevel.Error,
    };
  }

  if (!validatePrecision(minIncr, field.value)) {
    return {
      message: `Min increment is ${minIncr}`,
      level: FieldValidationLevel.Error,
    };
  }

  if (careOrderBeingModified == null) {
    return null;
  }

  const workingQty = toBig(careOrderBeingModified.WorkingQty);
  if (workingQty == null) {
    return {
      message: 'Loading care order...',
      level: FieldValidationLevel.Error,
    };
  }

  if (workingQty.gt(0) && quantity.lt(careOrderBeingModified.OrderQty)) {
    return {
      message: `Quantity cannot be decreased while there are open children.`,
      level: FieldValidationLevel.Error,
    };
  }

  const filledQty = toBigWithDefault(careOrderBeingModified.CumQty, 0).plus(workingQty);
  if (quantity.lt(filledQty)) {
    return {
      message: `Quantity cannot be less than already executed quantity: ${filledQty}`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};
