import {
  AbbrLimit,
  Box,
  ExposureLimitSideTypeEnum,
  HStack,
  InlineFormattedNumber,
  LeftMiddleRightFlexbox,
  Meter,
  Text,
  Tooltip,
  VStack,
  type CreditBlotterExposure,
} from '@talos/kyoko';
import { useTheme } from 'styled-components';
import { CreditUsageTooltip } from './CreditUsageTooltip';

interface CreditUsageProps {
  /**
   * Value from the valueGetter of the creditUsage column.
   * This is effectively just a subset of the data passed below, but it is important we properly
   * define and pass a value so that AgGrid runs change detection and re-renders us.
   */
  value: CreditBlotterExposure['creditUsages'];
  /**
   * The complete data of the row.
   */
  data: CreditBlotterExposure;
}

export const CreditUsage = ({ value, data }: CreditUsageProps) => {
  const theme = useTheme();

  if (value?.bothUsage != null) {
    const limit = value.bothUsage.limit;
    if (limit == null || limit.eq(0)) {
      return <Text color="colorTextSubtle">No limit defined</Text>;
    }

    return (
      <Tooltip
        tooltip={<CreditUsageTooltip data={data} />}
        targetStyle={{ width: '100%', height: '100%' }}
        contentMaxWidth="none"
      >
        <VStack
          w="100%"
          h="100%"
          py="spacingSmall"
          fontSize="fontSizeTiny"
          alignItems="flex-end"
          color="colorTextDefault"
          justifyContent="space-between"
        >
          <Meter
            value={Math.min(1, value.bothUsage.usage.toNumber())}
            showInitialAnimation={false}
            color="colors.blue.lighten"
            showLabel={false}
          />
          <LeftMiddleRightFlexbox
            middle={
              <InlineFormattedNumber
                number={value.bothUsage.usage.times(100)}
                increment="0"
                currency="%"
                highlightColor="colorTextDefault"
                round
              />
            }
            right={<AbbrLimit value={limit.toFixed()} currency={value.bothUsage.currency} />}
          />
        </VStack>
      </Tooltip>
    );
  }

  if (
    data.effectiveSide?.ExposureSide === ExposureLimitSideTypeEnum.Long ||
    data.effectiveSide?.ExposureSide === ExposureLimitSideTypeEnum.Short
  ) {
    const effectiveUsage = data.effectiveSide?.usage;

    return (
      <Tooltip
        tooltip={<CreditUsageTooltip data={data} />}
        targetStyle={{ width: '100%', height: '100%' }}
        contentMaxWidth="none"
      >
        <VStack
          w="100%"
          h="100%"
          py="spacingSmall"
          justifyContent="space-between"
          fontSize="fontSizeTiny"
          color="colorTextDefault"
        >
          {/* In-between of spacingSmall and spacingDefault... */}
          <HStack w="100%" gap={`${theme.baseSize * 0.375}px`}>
            <Meter
              value={Math.min(1, value.shortUsage?.usage?.toNumber() ?? 0)}
              showLabel={false}
              alignBar="right"
              color="colors.red.lighten"
              showInitialAnimation={false}
              roundStartingEdge={false}
            />
            <Box minWidth="2px" background="backgroundProgressBar" h="100%" />
            <Meter
              value={Math.min(1, value.longUsage?.usage?.toNumber() ?? 0)}
              showLabel={false}
              color="colors.green.lighten"
              showInitialAnimation={false}
              roundStartingEdge={false}
            />
          </HStack>
          <LeftMiddleRightFlexbox
            left={
              value.shortUsage?.limit ? (
                <AbbrLimit value={value.shortUsage.limit.toFixed()} currency={value.shortUsage.currency} />
              ) : (
                <Text>N/A</Text>
              )
            }
            middle={
              <InlineFormattedNumber
                number={effectiveUsage?.usage.times(100)}
                increment="0"
                currency="%"
                highlightColor="colorTextDefault"
                round
              />
            }
            right={
              value.longUsage?.limit ? (
                <AbbrLimit value={value.longUsage.limit.toFixed()} currency={value.longUsage.currency} />
              ) : (
                <Text>N/A</Text>
              )
            }
          />
        </VStack>
      </Tooltip>
    );
  }

  return <Text color="colorTextSubtle">No limit defined</Text>;
};
