import { FormControlSizes, Toggle, useDynamicCallback } from '@talos/kyoko';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';

export const BenchmarkControl = () => {
  const {
    state: { benchmarks, pnlUnit },
    dispatch,
  } = usePerformanceContext();

  // For now, the reducer supports having an array of whatever benchmark currency strings you want, but
  // we only allow the user to select btc for now.
  // The state supports an array of any currency just so in the future when we inevitably wanna show ETH benchmark we can do that
  // without migrating state :)
  const handleChange = useDynamicCallback((checked: boolean) => {
    dispatch({
      type: PerformanceActionType.BenchmarksChange,
      payload: {
        benchmarks: checked ? ['BTC'] : [],
      },
    });
  });

  // Only show this toggle if we're in percentage unit land
  return pnlUnit === 'amount' ? null : (
    <Toggle
      checked={benchmarks.length === 1}
      onChange={handleChange}
      label="BTC Benchmark"
      size={FormControlSizes.Small}
    />
  );
};
