import type { Position } from '@talos/kyoko';
import type { PositionsTableFilter } from '../../Blotters/PositionsV3/types';

export type OperationsOverviewShowBy = 'Market' | 'MarketAccount' | 'Asset';

export interface OperationsOverviewState {
  /** Which grouping we're currently viewing the data through */
  showBy: OperationsOverviewShowBy;
  /** Currently applied filters */
  filter: PositionsTableFilter;
  /** Whether or not to receive "0" balances over ws */
  showZeroBalances: boolean;
}

export const DEFAULT_OPERATIONS_OVERVIEW_STATE: OperationsOverviewState = {
  showBy: 'MarketAccount',
  filter: {},
  showZeroBalances: false,
};

export const SHOW_BY_DICT = {
  Market: { showBy: 'Market', label: 'Market', key: 'Market' },
  MarketAccount: { showBy: 'MarketAccount', label: 'Market Account', key: 'MarketAccount' },
  Asset: { showBy: 'Asset', label: 'Instrument', key: 'Asset' },
} as const satisfies {
  [key in OperationsOverviewShowBy]: { showBy: key; label: string; key: keyof Position };
};
