import {
  AG_GRID_OPTION_MARK_KEY,
  AG_GRID_OPTION_PRICE_QUOTE_KEY,
  ProductTypeEnum,
  Text,
  toBig,
  toBigWithDefault,
  type AgGridSearchSelectDropdownProps,
  type GridOptionMarkParams,
  type GridOptionsPriceQuoteParams,
  type Security,
  type StringSelectItem,
} from '@talos/kyoko';
import type { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { OPTION_SIDES, type OptionStrategy } from '../OMS/NewOrder/models';

const getDefaultRenderOptions = (key: string): Partial<ColDef> => {
  return {
    field: key,
    valueGetter: ({ data }) => {
      if (!data) {
        return '';
      }
      return data[key];
    },
    cellRenderer: ({ data, value, valueFormatted, context }) => {
      if (!data) {
        return '';
      }
      const usesOwnData = data[key] != null && data[key] !== '';
      // Aggregations column uses the result from its valueFormatter
      const effectiveValue = valueFormatted ?? value;
      return (
        <Text color={usesOwnData ? 'colorTextDefault' : 'colorTextMuted'} theme={context.current.theme}>
          {effectiveValue}
        </Text>
      );
    },
  };
};

export const getOptionsColDef = (
  handleSecurityChanged: (security: Security, index: number) => void,
  optionStrategy?: OptionStrategy,
  isEntryMode?: boolean
) => {
  return [
    {
      ...getDefaultRenderOptions('leg'),
      editable: false,
      headerName: 'Leg',
      minWidth: 40,
      width: 40,
      maxWidth: 40,
      cellClass: 'ag-cell-leg-cell',
    },
    {
      editable: false,
      headerName: '',
      minWidth: 40,
      width: 40,
      maxWidth: 40,
      cellClass: 'ag-cell-symbol-selector-cell',
      cellRenderer: 'symbolSelector',
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          isDisabled: !isEntryMode,
          onChange: security => params.node.rowIndex != null && handleSecurityChanged(security, params.node.rowIndex),
          filter: (security: Security) => {
            return security.ProductType === ProductTypeEnum.Option;
          },
        };
      },
    },
    {
      ...getDefaultRenderOptions('coin'),
      headerName: 'Coin',
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps) => {
        return {
          ...params,
          useSearchSelectParams: {
            items: optionStrategy?.legs[params.rowIndex].data.coinField.availableItems || [],
            getLabel: (v: StringSelectItem) => v.label,
            initialSortByLabel: false,
          },
          portalize: true,
          showClear: true,
        } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
      },
      editable: isEntryMode,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value?.label;
      },
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      cellClass: isEntryMode ? 'ag-cell-editable' : undefined,
    },
    {
      ...getDefaultRenderOptions('exchange'),
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps) => {
        return {
          ...params,
          useSearchSelectParams: {
            items: optionStrategy?.legs[params.rowIndex].data.exchangeField.availableItems || [],
            getLabel: (v: StringSelectItem) => v.label,
            initialSortByLabel: false,
          },
          portalize: true,
        } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
      },
      editable: isEntryMode,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value?.label;
      },
      minWidth: 120,
      cellClass: isEntryMode ? 'ag-cell-editable' : undefined,
    },
    {
      ...getDefaultRenderOptions('expiryDate'),
      cellEditor: 'searchSelectDropdown',
      editable: isEntryMode,
      headerName: 'Expiry Date',
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps) => {
        return {
          ...params,
          useSearchSelectParams: {
            items: optionStrategy?.legs[params.rowIndex].data.expiryField.availableItems || [],
            getLabel: (v: StringSelectItem) => v.label,
            initialSortByLabel: false,
          },
          portalize: true,
        } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value?.label?.toUpperCase();
      },
      minWidth: 120,
      cellClass: isEntryMode ? 'ag-cell-editable' : undefined,
    },
    {
      ...getDefaultRenderOptions('strike'),
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps) => {
        const availableStrikes = optionStrategy?.legs[params.rowIndex].data.strikeField.availableItems;

        return {
          ...params,
          useSearchSelectParams: {
            items: availableStrikes || [],
            getLabel: (v: StringSelectItem) => v.label,
            initialSortByLabel: false,
          },
          portalize: true,
        } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
      },
      editable: isEntryMode,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value?.label;
      },
      minWidth: 120,
      cellClass: isEntryMode ? 'ag-cell-editable' : undefined,
    },
    {
      ...getDefaultRenderOptions('type'),
      headerName: 'Type',
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps) => {
        return {
          ...params,
          useSearchSelectParams: {
            items: OPTION_SIDES,
            getLabel: (v: StringSelectItem) => v.label,
            initialSortByLabel: false,
          },
          portalize: true,
        } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
      },
      editable: isEntryMode,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value?.label;
      },
      minWidth: 120,
      cellClass: isEntryMode ? 'ag-cell-editable' : undefined,
      cellClassRules: {
        'ag-cell-type-call': params => params.value?.value === 'Call',
        'ag-cell-type-put': params => params.value?.value === 'Put',
      },
    },
    {
      ...getDefaultRenderOptions('marketAccount'),
      cellEditor: 'searchSelectDropdown',
      cellRenderer: 'marketAccountRenderer',
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps) => {
        return {
          ...params,
          useSearchSelectParams: {
            items: optionStrategy?.legs[params.rowIndex].data.marketAccountField.availableItems || [],
            getLabel: (v: StringSelectItem) => v.label,
            initialSortByLabel: false,
          },
          portalize: true,
        } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
      },
      editable: isEntryMode,
      valueFormatter: (params: ValueFormatterParams) => {
        // quirk surrounding ag-grid api and searchSelectDropdown; immediately after selecting the value is the selected object
        // and after it's processed by the redux reducers it become a multiselect (array of object) to be consistent with delta1
        if (params.value instanceof Array) {
          return params.value?.[0]?.label;
        }
        return params.value?.label;
      },
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      cellClass: isEntryMode ? ['ag-cell-editable', 'ag-cell-larger'] : 'ag-cell-larger',
    },
    {
      ...getDefaultRenderOptions('ratio'),
      cellEditor: 'amountInput',
      editable: false, // temporarily disabled until backend can support non 1:1 relationships
      headerName: 'Ratio',
      cellClassRules: {
        'ag-cell-ratio-positive': params => toBig(params.value)?.gte(0) || false,
        'ag-cell-ratio-negative': params => toBig(params.value)?.lt(0) || false,
      },
      valueFormatter: params => {
        return toBigWithDefault(params.value, 0).gt(0) ? `+${params.value}` : params.value;
      },
      minWidth: 75,
    },
    {
      ...getDefaultRenderOptions('initiating'),
      cellRenderer: 'checkbox',
      cellRendererParams: {
        disabled: !isEntryMode,
      },
      editable: false,
      headerName: 'Initiating',
      cellClass: 'ag-cell-initiating',
      minWidth: 75,
    },
    {
      field: 'bid',
      cellRenderer: AG_GRID_OPTION_PRICE_QUOTE_KEY,
      cellRendererParams: ({ data }: ICellRendererParams) => {
        return {
          currency: data?.currency,
          type: 'Bid',
          underlyingQuoteCurrency: data?.underlyingQuoteCurrency,
          underlyingPrice: data?.underlyingPrice,
          expiration: data?.expiryDate?.value,
          quoteCurrency: data?.quoteCurrency,
        } satisfies GridOptionsPriceQuoteParams;
      },
      minWidth: 110,
      flex: 1,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'mark',
      cellRenderer: AG_GRID_OPTION_MARK_KEY,
      cellRendererParams: ({ data }: ICellRendererParams) =>
        ({ currency: data?.quoteCurrency } satisfies GridOptionMarkParams),
      valueFormatter: ({ value }) => {
        if (value && typeof value === 'object' && 'markPrice' in value) {
          return value.markPrice;
        }
        return value;
      },
      minWidth: 90,
      flex: 1,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'offer',
      cellRenderer: AG_GRID_OPTION_PRICE_QUOTE_KEY,
      cellRendererParams: ({ data }: ICellRendererParams) => {
        return {
          currency: data?.currency,
          type: 'Offer',
          underlyingQuoteCurrency: data?.underlyingQuoteCurrency,
          underlyingPrice: data?.underlyingPrice,
          expiration: data?.expiryDate?.value,
          quoteCurrency: data?.quoteCurrency,
        } satisfies GridOptionsPriceQuoteParams;
      },
      minWidth: 110,
      flex: 1,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'delta',
      cellRenderer: 'formattedNumberColumn',
      cellRendererParams: { increment: '0.01' },
      minWidth: 90,
      flex: 1,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'gamma',
      cellRenderer: 'formattedNumberColumn',
      cellRendererParams: { increment: '0.000001' },
      minWidth: 90,
      flex: 1,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'vega',
      cellRenderer: 'formattedNumberColumn',
      cellRendererParams: { increment: '0.01' },
      minWidth: 90,
      flex: 1,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'theta',
      cellRenderer: 'formattedNumberColumn',
      cellRendererParams: { increment: '0.01' },
      minWidth: 90,
      flex: 1,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
  ];
};

export const DEFAULT_COLUMN_DEFINITION = {
  suppressMovable: true,
  resizable: false,
  cellClassRules: {
    editable: params => params.colDef.editable,
  },
  suppressHeaderMenuButton: true,
};
