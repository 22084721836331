import { IconButton } from '@talos/kyoko';
import styled from 'styled-components';

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacingSmall}px;
  right: ${({ theme }) => theme.spacingSmall}px;
`;

export const Amount = styled.span`
  color: ${({ theme }) => theme.colorTextPrimary};
`;

export const HoverIconButton = styled.div`
  opacity: 0;
  transition: opacity 200ms;

  > a {
    border-bottom: none;
  }
`;

export const TransferInstructionTr = styled.tr`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  &:not(:first-child) > td {
    padding-top: ${({ theme }) => theme.spacingMedium}px;
  }

  > td:not(:last-child) {
    padding-right: ${({ theme }) => theme.spacingMedium}px;
  }

  :hover {
    ${HoverIconButton} {
      opacity: 1;
    }
  }
`;

export const TransferInstructionTdDiv = styled.div`
  height: 50px;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  padding: ${({ theme }) => theme.spacingBig}px;
  height: 500px;
`;

export const ModalTitle = styled.h2`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacingMedium}px;
`;

export const TransferAccountWarningContent = styled.div`
  max-width: 200px;
`;
