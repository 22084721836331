import { ProductTypeEnum, type MarketAccount, type Security } from '@talos/kyoko';
import { compact, get } from 'lodash';

export type TradePositionButtonAction = 'Buy' | 'Sell' | 'Close';

export const CLOSE_POSITION_QUOTE_CURRENCIES = ['USDT', 'USDC', 'USD', 'EUR']; // todo might wanna move these into an appconfig multiselect setting

export interface PositionLike {
  Amount: string;
}

export interface GetPositionSecuritiesParams<T extends PositionLike> {
  action: TradePositionButtonAction;

  /** A PositionLike entity to grab properties off of.  */
  position: T;
  /** Whether or not to constrain the returned list of securities by the Market of the found MarketAccount on the Position */
  constrainSecuritiesToMarket?: boolean;
  /** The field to check for the "asset" on. Can be the field corresponding to either a security or currency, or both */
  assetField?: keyof T;
  /** The field to get the market account from. Defaults to `"MarketAccount"` */
  marketAccountField?: keyof T;
  /**
   * Required - The ProductTypeEnum of the position like entity we're trying to get securities for.
   *
   * This parameter is required but made possible to be undefined due to react conditional rendering devx
   */
  assetType: ProductTypeEnum | undefined;

  marketAccountsByName: Map<string, MarketAccount>;
  securitiesBySymbol: Map<string, Security>;
  /**
   * Pre-built mapping of spot trading pairs by base currency. So for each found base currency (eg ETH), we create an array of
   * associated trading pairs (ETH-USD, ETH-USDT, ETH-USDC, etc...)
   */
  spotTradingPairsByBaseCurrency: Map<string, Security[]>;
  /**
   * Pre-built mapping of spot trading pairs by quote currency. So for each found quote currency (eg ETH), we create an array of
   * associated trading pairs (BTC-ETH, etc...)
   *
   * Does not need to be provided. Must be provided if you want to be able to select inverse instruments.
   */
  spotTradingPairsByQuoteCurrency?: Map<string, Security[]>;
}

/**
 * A function returning a list of securities relevant for the given position-like entity.
 */
export function getPositionSecurities<T extends PositionLike>({
  action,
  position,
  constrainSecuritiesToMarket,
  assetType,
  assetField,
  marketAccountField,
  securitiesBySymbol,
  marketAccountsByName,
  spotTradingPairsByBaseCurrency,
  spotTradingPairsByQuoteCurrency,
}: GetPositionSecuritiesParams<T>) {
  if (!assetType) {
    return [];
  }

  let newPossibleSecurities: Security[] = [];

  // Given the product type of the position, grab
  if (assetType === ProductTypeEnum.Spot) {
    const currency = assetField ? get(position, assetField) : undefined;
    if (!currency || !(typeof currency === 'string')) {
      return [];
    }

    newPossibleSecurities = spotTradingPairsByBaseCurrency.get(currency) ?? [];
    if (action === 'Close' && spotTradingPairsByQuoteCurrency) {
      // For the closing action, we also add in the inverse instruments (if the user desires it by providing the quote ccy mapping).
      newPossibleSecurities = newPossibleSecurities.concat(spotTradingPairsByQuoteCurrency.get(currency) ?? []);
    }
  } else {
    // Non-spot
    const symbol = assetField ? get(position, assetField) : undefined;
    if (!symbol || typeof symbol !== 'string') {
      return [];
    }
    newPossibleSecurities = compact([securitiesBySymbol.get(symbol)]);
  }

  // Now apply some filtering to the output list of securities
  if (constrainSecuritiesToMarket) {
    if (!marketAccountField) {
      console.error('If you are constraining securities to a market, you need to provide a marketAccountField param');
      return [];
    }

    const marketAccountName = get(position, marketAccountField);
    if (!marketAccountName || typeof marketAccountName !== 'string') {
      return [];
    }

    const marketAccount = marketAccountsByName.get(marketAccountName);
    if (!marketAccount) {
      return [];
    }

    newPossibleSecurities = newPossibleSecurities.filter(security => security.Markets?.includes(marketAccount.Market));
  }

  return newPossibleSecurities;
}
