import {
  DELETE,
  NotificationVariants,
  PATCH,
  POST,
  request,
  runValidation,
  toBigWithDefault,
  useDynamicCallback,
  useGlobalToasts,
  useUserContext,
  validatePrecision,
  type MapTo,
  type MarketAccount,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { number, object, type BaseSchema } from 'yup';
import { useFees } from '../../../../../providers';
import type { Fee } from '../../../../../providers/FeesProvider';

export enum FeeSource {
  Market = 'MARKET',
  Custom = 'CUSTOM',
}

export type EditFeeFormEntry = {
  makerFeeRate: string;
  takerFeeRate: string;
  source: FeeSource;
};

type EditFeeForm = {
  initial: EditFeeFormEntry;
  current: EditFeeFormEntry;
  marketFees: Fee[];
  dirty: boolean;
  errors: Partial<MapTo<EditFeeFormEntry, string>>;
};

interface UseFeeEditorProps {
  selectableMarketAccunts: MarketAccount[];
}

export interface FeeEditorSaveResult {
  selectedIndex: number;
}

export interface FeeEditorOutput {
  form: Map<string, EditFeeForm>;
  updateForm: (marketAccount: string, update: Partial<EditFeeForm['current']>) => void;
  resetForm: () => void;
  isDirty: boolean;
  loading: boolean;
  save: () => Promise<FeeEditorSaveResult>;
}

const MIN_FEE_INCREMENT = '0.00000001';

function updateFormMap(
  prevForm: Map<string, EditFeeForm>,
  key: string,
  update: Partial<EditFeeForm>
): Map<string, EditFeeForm> {
  return new Map(
    prevForm.set(key, {
      ...prevForm.get(key)!,
      ...update,
    })
  );
}

export function useFeeEditor({ selectableMarketAccunts }: UseFeeEditorProps): FeeEditorOutput {
  const [loading, setIsLoading] = useState(false);
  const { orgApiEndpoint } = useUserContext();
  const { add: addToast } = useGlobalToasts();
  const [form, setForm] = useState<Map<string, EditFeeForm>>(new Map()); // MarketAccount.Name
  const { listCustomFees, listMarketFees } = useFees();

  useEffect(() => {
    if (selectableMarketAccunts.length === 0) {
      return;
    }
    setIsLoading(true);
    Promise.all([listMarketFees(), listCustomFees()])
      .then(([marketFees, customFees]) => {
        const marketFeesMap = new Map(marketFees.map(fee => [fee.MarketAccount, fee]));
        const customFeesMap = new Map(customFees.map(fee => [fee.MarketAccount, fee]));

        selectableMarketAccunts.forEach(marketAccount => {
          const source = customFeesMap.has(marketAccount.Name) ? FeeSource.Custom : FeeSource.Market;
          const makerFeeRate =
            source === FeeSource.Custom
              ? customFeesMap.get(marketAccount.Name)?.MakerFeeRate
              : marketFeesMap.get(marketAccount.Name)?.MakerFeeRate;
          const takerFeeRate =
            source === FeeSource.Custom
              ? customFeesMap.get(marketAccount.Name)?.TakerFeeRate
              : marketFeesMap.get(marketAccount.Name)?.TakerFeeRate;

          const initial = {
            source,
            makerFeeRate: toBigWithDefault(makerFeeRate, 0).times(100).toFixed(),
            takerFeeRate: toBigWithDefault(takerFeeRate, 0).times(100).toFixed(),
          };
          setForm(prevForm =>
            updateFormMap(prevForm, marketAccount.Name, {
              initial,
              current: initial,
              marketFees,
              dirty: false,
              errors: {},
            })
          );
        });
      })
      .finally(() => setIsLoading(false));
  }, [listMarketFees, listCustomFees, selectableMarketAccunts]);

  const updateForm = useDynamicCallback((marketAccount: string, update: Partial<EditFeeForm['current']>) => {
    const initial = form.get(marketAccount)?.initial;
    const newCurrent = {
      ...form.get(marketAccount)!.current,
      ...update,
    };
    const dirty = !isEqual(initial, newCurrent);
    setForm(prevForm =>
      updateFormMap(prevForm, marketAccount, {
        current: newCurrent,
        dirty,
        errors: validate(newCurrent),
      })
    );
  });

  const resetForm = useDynamicCallback(() => {
    setForm(new Map());
  });

  const isDirty = useMemo(() => {
    return selectableMarketAccunts.some(marketAccount => form.get(marketAccount.Name)?.dirty);
  }, [selectableMarketAccunts, form]);

  const save = useDynamicCallback(async (): Promise<FeeEditorSaveResult> => {
    // Mark form tab as clean when saved successfully to not repeat same operations when other tabs fail
    const resetFormTab = (marketAccount: string) => {
      setForm(prevForm =>
        updateFormMap(prevForm, marketAccount, {
          initial: prevForm.get(marketAccount)!.current,
          dirty: false,
          errors: {},
        })
      );
    };

    return new Promise((resolve, reject) => {
      const errorTabIndex = selectableMarketAccunts.findIndex(marketAccount => {
        const errors = validate(form.get(marketAccount.Name)!.current);
        setForm(prevForm => updateFormMap(prevForm, marketAccount.Name, { errors }));
        return Object.keys(errors).length > 0;
      });
      if (errorTabIndex > -1) {
        reject({ selectedIndex: errorTabIndex });
        return;
      }
      const promises: Promise<void>[] = [];
      selectableMarketAccunts.forEach(marketAccount => {
        const { initial, current, dirty } = form.get(marketAccount.Name)!;
        if (!dirty) {
          return;
        }
        if (current.source === FeeSource.Custom) {
          const newFee: Fee = {
            MarketAccount: marketAccount.Name,
            MakerFeeRate: toBigWithDefault(current.makerFeeRate, 0).div(100).toFixed(),
            TakerFeeRate: toBigWithDefault(current.takerFeeRate, 0).div(100).toFixed(),
          };
          const reqType = initial.source === FeeSource.Market ? POST : PATCH;
          promises.push(
            request(reqType, `${orgApiEndpoint}/fees/configs`, newFee).then(() => resetFormTab(marketAccount.Name))
          );
        } else {
          promises.push(
            request(DELETE, `${orgApiEndpoint}/fees/configs/${marketAccount.Name}/symbol/default`, {}).then(() =>
              resetFormTab(marketAccount.Name)
            )
          );
        }
      });
      Promise.allSettled(promises)
        .then(() => {
          addToast({
            variant: NotificationVariants.Positive,
            text: `Fee rates saved`,
          });
          resolve({ selectedIndex: 0 });
        })
        .catch(error => {
          addToast({
            variant: NotificationVariants.Negative,
            text: `Could not save fees. ${error?.toString() ?? ''}`,
          });
          reject({
            selectedIndex: 0,
          });
        });
    });
  });

  function validate(current: EditFeeForm['current']): Partial<MapTo<EditFeeFormEntry, string>> {
    if (current.source === FeeSource.Market) {
      return {};
    }
    const schema: Partial<Record<keyof EditFeeForm['current'], BaseSchema>> = {
      makerFeeRate: number()
        .required('Please enter Maker Fee Rate')
        .typeError('Please enter Maker Fee Rate')
        .test('precision', `Min increment is ${MIN_FEE_INCREMENT}`, q => validatePrecision(MIN_FEE_INCREMENT, q)),
      takerFeeRate: number()
        .required('Please enter Taker Fee Rate')
        .typeError('Please enter Taker Fee Rate')
        .test('precision', `Min increment is ${MIN_FEE_INCREMENT}`, q => validatePrecision(MIN_FEE_INCREMENT, q)),
    };
    return runValidation(object().shape(schema), current);
  }

  return {
    form,
    updateForm,
    resetForm,
    isDirty,
    loading,
    save,
  };
}
