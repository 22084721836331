import { HStack, Text, VStack, type BoxProps } from '@talos/kyoko';
import type { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

interface ModuleProps extends React.PropsWithChildren<BoxProps> {
  title: string;
  suffix?: ReactNode;
}

export const Module = ({ title, children, suffix, ...boxProps }: ModuleProps) => {
  const theme = useTheme();
  return (
    <VStack gap="1px" {...boxProps} background={theme.colors.gray.main} justifyContent="flex-start" overflow="hidden">
      <Header justifyContent="space-between" h="40px" w="100%" px="spacingMedium">
        <Text fontSize="fontSizeMd">{title}</Text>
        {suffix}
      </Header>
      <VStack flex="1" w="100%" overflow="hidden">
        {children}
      </VStack>
    </VStack>
  );
};

const Header = styled(HStack)`
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  flex-shrink: 0;
`;
