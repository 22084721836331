import mixpanel from 'mixpanel-browser';

export interface MixpanelArgs {
  environment: string;
  mixpanelToken?: string;
  apiHost?: string;
}
export function initMixpanel({ environment, mixpanelToken, apiHost }: MixpanelArgs) {
  if (mixpanelToken && (environment === 'prod' || environment === 'sandbox')) {
    mixpanel.init(mixpanelToken, {
      api_host: apiHost,
    });
  }
}
