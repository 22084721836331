import Big from 'big.js';
import { isNil } from 'lodash';
import { memo, useMemo } from 'react';
import { RefRateContext, type RefRate, type RefRateContextProps } from '../contexts';
import { useMultipleStreamsSubscription } from '../hooks';
import { REFERENCE_RATE } from '../tokens';
import type { RequestStream } from '../types/RequestStream';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';
import { ConnectionStatusEnum, type IReferenceRateSnapshot } from '../types/types';

interface RefRateRequest extends RequestStream {
  name: typeof REFERENCE_RATE;
  Symbol: string;
  Aggregation: string;
  Throttle: string;
}

function getRefRateStreamRequestForSymbol(symbol: string): RefRateRequest {
  return {
    name: REFERENCE_RATE,
    tag: 'RefRateContextProvider',
    Symbol: symbol,
    Aggregation: 'talos-reference-rate',
    Throttle: '10s',
  };
}
function getRefRateStreamRequestForAllSymbols(requestMap: Map<string, RefRateRequest>): RefRateRequest[] {
  return Array.from(requestMap.values());
}

function subscriptionCallback(
  memo: Map<string, RefRate | undefined>,
  json: SubscriptionResponse<IReferenceRateSnapshot, string>
) {
  for (const d of json.data) {
    const prevRefRate = memo.get(d.Symbol)?.currentPrice ?? memo.get(d.Symbol)?.prevPrice;
    const newRate = d.Status === ConnectionStatusEnum.Online ? Big(d.Rate ?? 0) : prevRefRate;
    if ((isNil(prevRefRate) && !isNil(newRate)) || (!isNil(prevRefRate) && !newRate?.eq(prevRefRate))) {
      memo.set(d.Symbol, { currentPrice: newRate, prevPrice: prevRefRate, isLoaded: true });
    } else {
      // Store null to track that server sent initial response
      memo.set(d.Symbol, { currentPrice: prevRefRate, prevPrice: prevRefRate, isLoaded: true });
    }
  }
  return memo;
}
export const RefRateContextProvider = memo(function RefRateContextProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const {
    valueMapObservable: refRatesBySymbol,
    registerSubscription,
    registerSubscriptions,
    unregisterSubscription,
    unregisterSubscriptions,
  } = useMultipleStreamsSubscription({
    getRequest: getRefRateStreamRequestForSymbol,
    getAllRequests: getRefRateStreamRequestForAllSymbols,
    subscriptionCallback: subscriptionCallback,
  });

  const value = useMemo<RefRateContextProps>(
    () => ({
      refRatesBySymbol,
      registerSubscription,
      registerSubscriptions,
      unregisterSubscription,
      unregisterSubscriptions,
    }),
    [refRatesBySymbol, registerSubscription, unregisterSubscription, registerSubscriptions, unregisterSubscriptions]
  );
  return <RefRateContext.Provider value={value}>{children}</RefRateContext.Provider>;
});
