import { ConnectionType, EMPTY_OBJECT, IconName, useMarketsContext, type FilterableProperty } from '@talos/kyoko';
import { useMemo } from 'react';

interface UseMarketsFilterParams {
  /**
   * Set to true if you only want to include Markets which are configured for ConnectionType.Orders/RFQ.
   * Defaults to false.
   */
  onlyTradingMarkets?: boolean;
}

/**
 * This hook creates the filter property definition for Markets properties.
 *
 * @returns Filter Property definition to use for Markets filters
 */
export function useMarketsFilter({ onlyTradingMarkets }: UseMarketsFilterParams = EMPTY_OBJECT) {
  const { marketsByName, marketsList, isMarketConfigured } = useMarketsContext();

  const options = useMemo(() => {
    let workingMarkets = marketsList;
    if (onlyTradingMarkets) {
      workingMarkets = workingMarkets.filter(
        m =>
          // We don't want to use `isMarketSupported` here, as that'd at worst show 50 different markets
          // even though the user might only be trading on a few
          isMarketConfigured(m, ConnectionType.Orders) || isMarketConfigured(m, ConnectionType.RFQ)
      );
    }

    return workingMarkets.map(m => m.Name);
  }, [marketsList, isMarketConfigured, onlyTradingMarkets]);

  return useMemo(
    () => ({
      ...({
        key: 'Markets',
        label: 'Market',
        icon: IconName.PresentationChartLine,
        control: 'multi-select',
        options,
        getOptionLabel: (option: string) => marketsByName.get(option)?.DisplayName || '',
      } as const satisfies FilterableProperty),
    }),
    [marketsByName, options]
  );
}
