// Common re-usable field rules (should not depende on any field context since re-usable implies generic)

import {
  FieldValidationLevel,
  toBig,
  toBigWithDefault,
  type FieldValidationRule,
  type NumericField,
} from '@talos/kyoko';

export const numberIsPositive: FieldValidationRule<NumericField> = field => {
  if (field.value) {
    if (toBigWithDefault(field.value, 0).lte(0)) {
      return {
        message: `${field.name} must be positive`,
        level: FieldValidationLevel.Error,
      };
    }
  }
  return null;
};

export const numberNotZero: FieldValidationRule<NumericField> = field => {
  try {
    const bigValue = toBig(field.value);
    if (bigValue && bigValue.eq(0)) {
      return {
        message: `${field.name} cannot be 0`,
        level: FieldValidationLevel.Error,
      };
    }
  } catch (e) {
    // ignore
  }

  return null;
};
