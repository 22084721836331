import { AssetTransactionAllocationStatusEnum, type FilterableProperty, IconName } from '@talos/kyoko';
import { identity } from 'lodash';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for ConnectionStatus properties.
 *
 * @returns Filter Property definition to use for ConnectionStatus filters
 */
export const useAllocationStatusFilter = () => {
  return useMemo(
    () => ({
      ...({
        key: 'AllocationStatuses',
        label: 'Allocation Status',
        icon: IconName.BookOpen,
        options: Object.values(AssetTransactionAllocationStatusEnum),
        getOptionLabel: identity,
      } as const satisfies FilterableProperty),
    }),
    []
  );
};
