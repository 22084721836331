import {
  ACTION,
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  Button,
  ButtonVariants,
  FormControlSizes,
  HStack,
  IconName,
  Panel,
  PanelContent,
  PanelHeader,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  columnToColumnState,
  tabLabelerEnumerated,
  useDisclosure,
  useDynamicCallback,
  usePersistedTabs,
  usePortal,
  useTabs,
  type Column,
  type CustomerBalance,
  type CustomerLedgerUpdateTypeEnum,
  type RowGroupsOpenedState,
  type Transfer,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useState } from 'react';
import type { CustomerBalancesFilter, CustomerBalancesTabsProps } from './types';

import { useDetailsDrawer } from '../../../components/DetailsDrawer/useDetailsDrawer';
import { useRoleAuth } from '../../../hooks';
import { CustomerBalancesBlotter } from './CustomerBalancesBlotter';
import { NewBalanceTransactionDialog } from './NewBalanceTransactionDialog';
import { DEFAULT_CUSTOMER_BALANCES_TABS } from './defaults';

const CUSTOMER_BALANCES_BLOTTER_PREFIX = 'dealer/customer-balances';

const tabLabeler = tabLabelerEnumerated('Balances');

const TABS_EXCLUDED_IDS = compact(DEFAULT_CUSTOMER_BALANCES_TABS.map(tab => tab.id));

export const CustomerBalances = () => {
  const { isAuthorized } = useRoleAuth();

  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);
  const handleSelect = useDynamicCallback(() => setInitialFiltersOpen(false));
  const handleAdd = useDynamicCallback(() => setInitialFiltersOpen(true));

  const newBalanceTransactionDialog = useDisclosure();
  const [customerBalance, setCustomerBalance] = useState<CustomerBalance | undefined>();
  const [transactionType, setTransactionType] = useState<CustomerLedgerUpdateTypeEnum | undefined>();

  // reset form state when opening new
  const openNewBalanceTransactionDialog = useDynamicCallback(
    (customerBalance?: CustomerBalance, transactionType?: CustomerLedgerUpdateTypeEnum, transfer?: Transfer) => {
      setCustomerBalance(customerBalance);
      setTransactionType(transactionType);
      newBalanceTransactionDialog.open();
    }
  );

  const handleClickTransaction = useDynamicCallback(
    (customerBalance: CustomerBalance, transactionType?: CustomerLedgerUpdateTypeEnum) => {
      openNewBalanceTransactionDialog(customerBalance, transactionType);
    }
  );

  const persistedTabs = usePersistedTabs(CUSTOMER_BALANCES_BLOTTER_PREFIX, {
    defaultInitialItems: DEFAULT_CUSTOMER_BALANCES_TABS,
    excludedIDs: TABS_EXCLUDED_IDS,
    defaultInitialSelectedIndex: 0,
    onSelect: handleSelect,
  });

  const tabs = useTabs({ ...persistedTabs, showAddTab: true, tabLabeler, onAdd: handleAdd });

  const handleCloneTab = useDynamicCallback(
    (
      filter: CustomerBalancesFilter,
      columns: Column[],
      rowGroupsOpenedState: RowGroupsOpenedState | undefined,
      showZeroBalances: boolean
    ) => {
      tabs.clone(tabs.selectedIndex, {
        defaultColumns: columns.map(columnToColumnState),
        defaultFilter: filter,
        defaultRowGroupsOpened: rowGroupsOpenedState,
        showZeroBalances,
        closable: true,
        reorderable: true,
      });
    }
  );

  const handleUpdateTab = useDynamicCallback((updatedTab: CustomerBalancesTabsProps) => {
    tabs.updateItem(updatedTab);
  });

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  const { openDetailsDrawer, closeDetailsDrawer, isOpen: isDrawerOpen } = useDetailsDrawer();

  const [lastSelectedRowID, setLastSelectedRowID] = useState<string | undefined>();
  const handleClickRow = useDynamicCallback((data: CustomerBalance) => {
    const id = data.rowID;
    // If clicking the same row, close the drawer
    if (lastSelectedRowID === id && isDrawerOpen) {
      setLastSelectedRowID(undefined);
      closeDetailsDrawer();
      return;
    }

    // If clicking a different row, open the drawer
    openDetailsDrawer({ entity: data, onClickTransaction: handleClickTransaction });
    setLastSelectedRowID(id);
  });

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader>
          <h2>Customer Balances</h2>
          <Button
            startIcon={IconName.ArrowLeftRight}
            onClick={() => openNewBalanceTransactionDialog()}
            variant={ButtonVariants.Positive}
            disabled={!isAuthorized(ACTION.DEALER_TRADING)}
            size={FormControlSizes.Small}
          >
            New Balance Transaction
          </Button>
        </PanelHeader>

        <PanelContent style={{ overflow: 'hidden' }}>
          <Tabs h="100%" {...tabs} size={TabSize.Large}>
            <TabList
              rightItems={
                <HStack gap="spacingComfortable">
                  <Box ref={filtersContainerRef} />
                </HStack>
              }
            >
              {tabs.items.map((tab, index) => (
                <Tab key={index} {...tab} />
              ))}
            </TabList>
            <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
              {tabs.items.map(tab => (
                <AccordionGroup key={tab.id}>
                  <CustomerBalancesBlotter
                    tab={tab}
                    blotterID={`${CUSTOMER_BALANCES_BLOTTER_PREFIX}/${tab.id}`}
                    initialFiltersOpen={initialFiltersOpen}
                    onCloneTab={handleCloneTab}
                    onTransactionPrime={openNewBalanceTransactionDialog}
                    onUpdateTab={handleUpdateTab}
                    onClickRow={handleClickRow}
                  />
                </AccordionGroup>
              ))}
            </TabPanels>
          </Tabs>
        </PanelContent>
        <NewBalanceTransactionDialog
          {...newBalanceTransactionDialog}
          entity={customerBalance}
          transactionType={transactionType}
        />
      </Panel>
    </HStack>
  );
};
