import { type BoxProps, Dd, Dl, Dt } from '@talos/kyoko';
import type { ReactNode } from 'react';

export function OrderSummaryItem({ title, children, ...props }: { title: ReactNode } & Omit<BoxProps, 'title'>) {
  return (
    <Dl alignItems="stretch" {...props}>
      <Dt fontSize="fontSizeTiny">{title}</Dt>
      <Dd mt="spacingSmall" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {children}
      </Dd>
    </Dl>
  );
}
