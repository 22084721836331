import { Tab, TabAppearance, TabList, TabSize, Tabs, useDynamicCallback } from '@talos/kyoko';
import {
  SettlementMonitoringActionType,
  useSettlementMonitoringState,
} from '../providers/SettlementMonitoringStateProvider';
import type { SettlementMonitoringState } from '../types';

export const GroupByControl = () => {
  const {
    state: { groupBy },
    dispatch,
  } = useSettlementMonitoringState();

  const handleClick = useDynamicCallback((groupBy: SettlementMonitoringState['groupBy']) => {
    dispatch({
      type: SettlementMonitoringActionType.GroupByChange,
      payload: {
        groupBy,
      },
    });
  });

  return (
    <Tabs
      selectedIndex={groupBy === 'account' ? 0 : 1}
      appearance={TabAppearance.Pill}
      size={TabSize.Small}
      flex="0 0 auto"
      w="auto"
      data-testid="group-by-buttons"
    >
      <TabList suppressOverflowList={true}>
        <Tab
          isSelected={groupBy === 'account'}
          onClick={() => handleClick('account')}
          data-testid="by-account-button"
          label="By Account"
        />
        <Tab
          isSelected={groupBy === 'asset'}
          onClick={() => handleClick('asset')}
          data-testid="by-asset-button"
          label="By Asset"
        />
      </TabList>
    </Tabs>
  );
};
