import {
  Box,
  InlineFormattedNumber,
  NumberVariants,
  SummaryLineWrapper,
  Total,
  toBigWithDefault,
  type BlotterTableRow,
  type LedgerEvent,
} from '@talos/kyoko';
import Big from 'big.js';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

interface LedgerEventsBlotterSummaryLineProps {
  rows: BlotterTableRow<LedgerEvent>[];
}

export const LedgerEventsBlotterSummaryLine = ({ rows }: LedgerEventsBlotterSummaryLineProps) => {
  const { colors } = useTheme();
  const total = useMemo(() => {
    let agg = Big(0);
    rows.forEach(row => {
      const value = toBigWithDefault(row.data?.Amount, 0);
      agg = agg.plus(value);
    });
    return agg;
  }, [rows]);

  const currency = rows.at(0)?.data?.AmountAsset;

  return (
    <SummaryLineWrapper alignItems="center" w="100%">
      <Box px="spacingSmall">
        <Total>Total Amount</Total>
        <InlineFormattedNumber
          background={colors.gray['010']}
          number={total}
          currency={currency}
          variant={total.lt(0) ? NumberVariants.Negative : undefined}
          truncate={false}
        />
      </Box>
    </SummaryLineWrapper>
  );
};
