import { ConnectionModeEnum, Dialog, Divider, IconName, Text, VStack, type DialogProps } from '@talos/kyoko';
import type { HydratedMarketCredential } from '../types';
import { SelectedCredentialsList } from './SelectedCredentialsList';

export const EnableDisableSelectedDialog = <T extends HydratedMarketCredential>({
  selectedItems,
  enableDisableDialog,
  onConfirm,
  mode,
}: {
  selectedItems: T[];
  enableDisableDialog: DialogProps;
  onConfirm: (credentials: T[]) => void;
  mode: ConnectionModeEnum;
}) => {
  if (selectedItems.length === 0 || selectedItems.length === 1) {
    return null;
  }
  const label = mode === ConnectionModeEnum.Up ? 'Enable' : 'Disable';
  const titleText = `${label} ${selectedItems.length} Credentials`;
  const description = `Are you sure you want to ${label.toLowerCase()} ${selectedItems.length} credentials?`;
  return (
    <Dialog
      {...enableDisableDialog}
      onConfirm={() => {
        onConfirm(selectedItems);
      }}
      confirmLabel="Confirm"
      cancelLabel="Return"
      title={titleText}
      headerIcon={mode === ConnectionModeEnum.Down ? IconName.ExclamationSolid : IconName.CheckCircleSolid}
      headerIconColor={mode === ConnectionModeEnum.Down ? 'colorTextWarning' : 'colorTextPositive'}
      stretchButtons
    >
      <VStack gap="spacingDefault" alignItems="stretch">
        <Text textAlign="left" color="colorTextAttention">
          {description}
        </Text>
        <Divider />
        <SelectedCredentialsList credentials={selectedItems} mode={mode} />
      </VStack>
    </Dialog>
  );
};
