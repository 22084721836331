import { NumberVariants, toBigWithDefault } from '@talos/kyoko';
import { toNumber } from 'lodash';

export function pnlPercentToNumberVariant(percent: string | undefined) {
  if (percent == null) {
    return undefined;
  }

  const percentNumber = toNumber(percent);
  return percentNumber > 0 ? NumberVariants.Positive : percentNumber < 0 ? NumberVariants.Negative : undefined;
}

/** Get a NumberVariant from a string value. Returns Negative if the value is sub-zero */
export function getNumberVariant(value: string | undefined): NumberVariants | undefined {
  const big = toBigWithDefault(value, 0);
  return big.lt(0) ? NumberVariants.Negative : undefined;
}

/** Returns whether or not to show a negative sign for the given string value. If the value is sub-zero, returns true. */
export function showNegativeSign(value: string | undefined): boolean {
  // return true (to show a sign) if the value is negative.
  return toBigWithDefault(value, 0).lt(0);
}
