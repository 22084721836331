import {
  API_PERMISSION_WILDCARD,
  ApiPermissionActionEnum,
  intersectTwoSets,
  useMarketAccountsContext,
  type Allocation,
  type MarketAccount,
} from '@talos/kyoko';
import { useMarketAccountPermissionFilters } from 'providers/MarketAccountPermissionFiltersProvider';
import { useMemo } from 'react';
import { UNSELECTED_SUB_ACCOUNT } from './consts';
import { useFeatureFlag } from './useFeatureFlag';

interface UseTradableMarketAccountNamesProps {
  /**
   * The sub account allocation used to determine which markets accounts that can be traded.
   * If set to -1, there will be no filtering and all available market accounts will be returned
   */
  subAccountAllocations: Allocation[] | -1;
  /**
   * Whether return all market accounts names across all subAccounts or just intersection (default)
   */
  combineAllMarketAccounts?: boolean;
}

/**
 * Given the selected subaccountAllocation(s) returns
 *
 * `Set<MarketAccount['Name']>`: signifying that the included market accounts are permitted.
 *
 * or
 *
 * `null`: signifying that there are no restrictions to what market accounts that are permitted
 */
export function usePermittedTradableMarketAccountsSet({
  subAccountAllocations,
  combineAllMarketAccounts = false,
}: UseTradableMarketAccountNamesProps): Set<MarketAccount['Name']> | null {
  const { enableAccountSegregation } = useFeatureFlag();
  const { marketAccountsList } = useMarketAccountsContext();

  const { marketAccountFilterIDsBySubAccountName, marketAccountPermissionFiltersByFilterID } =
    useMarketAccountPermissionFilters();

  // If Set<string>, only those marketAccounts included are allowed, if null, everything goes
  return useMemo(() => {
    if (!enableAccountSegregation) {
      return null;
    }

    if (subAccountAllocations === -1) {
      return new Set(marketAccountsList.map(m => m.Name));
    }

    const hasSubAccountAllocations = !!subAccountAllocations?.length;
    const hasUnselectedInAllocations = !!subAccountAllocations?.find(
      alloc => alloc.subAccount === UNSELECTED_SUB_ACCOUNT
    );
    const shouldReturnEmptyOptions =
      enableAccountSegregation && (!hasSubAccountAllocations || hasUnselectedInAllocations);

    // if marketaccount segregation is enabled but no selection is made, return empty set signalling nothing is selectable
    if (
      shouldReturnEmptyOptions ||
      !marketAccountFilterIDsBySubAccountName ||
      !marketAccountPermissionFiltersByFilterID
    ) {
      return new Set<string>();
    }

    let result = combineAllMarketAccounts ? new Set<string>() : new Set<string>(marketAccountsList.map(ma => ma.Name));
    for (const allocation of subAccountAllocations!) {
      const permissionFilterIDs = marketAccountFilterIDsBySubAccountName.get(allocation.subAccount) || [];
      const marketAccountNames = new Set<string>();
      let wildcardFound = false;
      for (const filterID of permissionFilterIDs) {
        const permissionFilter = marketAccountPermissionFiltersByFilterID.get(filterID);
        if (permissionFilter) {
          const isWriteAccess = permissionFilter.Action === ApiPermissionActionEnum.Write;
          const isWildCard = permissionFilter.Filter.MarketAccount === API_PERMISSION_WILDCARD;

          // Wild card rules will not allow us to narrow down the set any further
          if (isWildCard && isWriteAccess) {
            wildcardFound = true;
            break;
          }

          // Otherwise, just add it to the set (assuming we have write access)
          if (isWriteAccess) {
            marketAccountNames.add(permissionFilter.Filter.MarketAccount);
          }
        }
      }

      // Intersect these market accounts with the previous set of market accounts
      if (!wildcardFound) {
        if (combineAllMarketAccounts) {
          result = new Set([...result, ...marketAccountNames]);
        } else {
          result = intersectTwoSets(result, marketAccountNames);
        }
      }
    }

    return result;
  }, [
    enableAccountSegregation,
    marketAccountFilterIDsBySubAccountName,
    marketAccountPermissionFiltersByFilterID,
    marketAccountsList,
    subAccountAllocations,
    combineAllMarketAccounts,
  ]);
}
