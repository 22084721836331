import { abbreviateId, EMPTY_ARRAY, type Security } from '@talos/kyoko';
import type { ResolutionString } from '@trading-view/charting_library';
import type { DockviewApi, GroupPanelViewState } from 'dockview';
import { maxBy, uniq } from 'lodash';
import type { MutableRefObject } from 'react';
import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { v1 as uuid } from 'uuid';
import { setTemporaryPanel } from '../../components/AppLayout/AppLayoutSlice';
import { DEFAULT_LAYOUTS } from '../../components/AppLayout/Layouts';
import { DEFAULT_LAYOUT_ID } from '../../components/AppLayout/Layouts/DefaultLayoutId';
import { LEAF } from '../../components/Layout/tokens';
import type { AddPanelOptions } from '../../components/Layout/types';
import { getEnumeratedTitle } from '../../components/Layout/utils';
import type { MultilegComboType } from '../../components/MultilegCombo/enums';
import type { PanelConfig, PanelType } from '../../types/LayoutConfig';
import { useAppStateDispatch, useAppStateSelector } from '../AppStateProvider';
import { applyLayoutSpec } from './applyLayoutSpec';
import { getAddedPanels } from './getAddedPanels';
import { getLargestLayoutGroup } from './getLargestGroup';
import { getLayoutGroupById } from './getLayoutGroupById';

export interface AppLayoutContextProps {
  addPanel: (type: PanelType, options: AddPanelOptions) => void;
  addPanelToMain: (type: PanelType, options: Omit<AddPanelOptions, 'groupId' | 'panelId'>) => void;
  dockviewApiRef?: MutableRefObject<DockviewApi | undefined>;
  resetLayout: () => void;
  resetLayoutAndTabs: () => void;

  addTemporaryPanel: (type: PanelType, label: string, params: PanelConfig) => void;

  addAutoHedgingControlsPanel: () => void;
  addOrderDetailsPanel: (orderID: string) => void;
  addDeepDivePanel: (
    security: Security | undefined,
    marketAccounts: string[] | undefined,
    markets: string[] | undefined
  ) => void;
  addMultilegDeepDivePanel: (security: Security | undefined, multilegInstrumentType: MultilegComboType) => void;
}

export const AppLayoutContext = createContext<AppLayoutContextProps | undefined>(undefined);
AppLayoutContext.displayName = 'AppLayoutContext';

export function useAppLayoutContext() {
  const context = useContext(AppLayoutContext);
  if (context === undefined) {
    throw new Error('Missing AppLayoutContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/** Provide access to methods for modifying the layout for components outside the flexible part of the layout */
export function AppLayoutContextProvider({ children }) {
  const dispatch = useAppStateDispatch();
  const temporaryPanel = useAppStateSelector(state => state.appLayout.temporaryPanel);
  const layoutSection = useAppStateSelector(state => state.appLayout.layoutSection);
  const selectedLayoutId = useAppStateSelector(state => state.appLayout.currentLayout?.id);
  const dockviewApiRef = useRef<DockviewApi | undefined>(undefined);

  const addPanel = useCallback(
    (type: PanelType, { label, labelGetter, groupId, panelId, direction, params }: AddPanelOptions = {}) => {
      const dockviewApi = dockviewApiRef.current;
      if (dockviewApi == null) {
        return;
      }
      const referenceGroup = groupId ? dockviewApi.getGroup(groupId) : undefined;
      const referencePanel = panelId ? dockviewApi.getPanel(panelId) : undefined;
      const defaultTitle = label ?? labelGetter?.() ?? type;
      const title = getEnumeratedTitle(defaultTitle, dockviewApi.panels);
      const id = uuid();

      return dockviewApi.addPanel({
        id,
        component: type,
        title,
        params,
        position: referenceGroup
          ? {
              referenceGroup: groupId,
              direction: direction ?? 'within',
            }
          : referencePanel
          ? {
              referencePanel: panelId,
              direction: direction ?? 'right',
            }
          : undefined,
      });
    },
    []
  );

  const addTemporaryPanel = useCallback(
    (type: PanelType, title: string, params: PanelConfig) => {
      dispatch(
        setTemporaryPanel({
          type,
          params,
          title,
        })
      );
    },
    [dispatch]
  );

  const addPanelToMain = useCallback(
    (type: PanelType, options: Omit<AddPanelOptions, 'groupId' | 'panelId'>) => {
      const dockviewApi = dockviewApiRef.current;
      const group = dockviewApi?.getGroup('main') ?? maxBy(dockviewApi?.groups, group => group.width * group.height);
      addPanel(type, { ...options, groupId: group?.api.id ?? 'main', direction: 'within' });
    },
    [addPanel]
  );

  const addDeepDivePanel = useCallback(
    (security: Security | undefined, marketAccounts: string[] | undefined, markets: string[] | undefined) => {
      if (security == null) {
        return;
      }
      addTemporaryPanel('deepDive', security?.DisplaySymbol, {
        symbol: security?.Symbol,
        selectedMarketAccounts: marketAccounts ?? EMPTY_ARRAY,
        selectedMarkets: markets ?? EMPTY_ARRAY,
        chartResolution: '60' as ResolutionString,
        showMarkets: false,
        closable: true,
        editable: true,
      });
    },
    [addTemporaryPanel]
  );

  const addMultilegDeepDivePanel = useCallback(
    (security: Security | undefined, multilegInstrumentType: MultilegComboType) => {
      if (security == null) {
        return;
      }
      addTemporaryPanel('multilegDeepDive', security?.DisplaySymbol, {
        resolvedSymbol: security?.Symbol,
        instrumentType: multilegInstrumentType,
        initiatingLegs: [true, false],
        compactOrderBook: false,
        closable: true,
        editable: true,
      });
    },
    [addTemporaryPanel]
  );

  const addOrderDetailsPanel = useCallback(
    (orderID: string) => {
      addTemporaryPanel('orderDetails', `#${abbreviateId(orderID)}`, {
        orderID,
        openTab: 'details',
        closable: true,
        editable: true,
      });
    },
    [addTemporaryPanel]
  );

  const addAutoHedgingControlsPanel = useCallback(() => {
    addTemporaryPanel('positionAutoHedgingControls', 'Position Auto Hedging Rules', { closable: true, editable: true });
  }, [addTemporaryPanel]);

  /** Move all additional panels to the main group and reset layout */
  const resetLayout = useCallback(() => {
    const dockviewApi = dockviewApiRef.current;
    if (dockviewApi == null) {
      return;
    }
    const currentLayout = dockviewApi?.toJSON();
    const layoutSpec = DEFAULT_LAYOUTS[layoutSection][selectedLayoutId ?? DEFAULT_LAYOUT_ID];
    const defaultLayout = layoutSpec.dockViewLayout;
    if (currentLayout != null) {
      const newLayout = { ...defaultLayout };

      // Get all panels that's not in the initial layout
      const addedPanels = getAddedPanels(defaultLayout, currentLayout);

      // Find the main group and add the panels
      const mainGroup = getLayoutGroupById(newLayout, 'main') ?? getLargestLayoutGroup(newLayout);
      if (mainGroup && mainGroup.type === LEAF && addedPanels.length > 0) {
        const mainGroupData = mainGroup.data as GroupPanelViewState;
        mainGroupData.views = uniq([...mainGroupData.views, ...addedPanels]);
        newLayout.panels = { ...defaultLayout.panels, ...currentLayout.panels };
        const newLayoutSpec = { ...layoutSpec, dockViewLayout: newLayout };
        applyLayoutSpec(dockviewApi, newLayoutSpec);
        return;
      }
    }
    applyLayoutSpec(dockviewApi, layoutSpec);
  }, [layoutSection, selectedLayoutId]);

  const resetLayoutAndTabs = useCallback(() => {
    const layoutSpec = DEFAULT_LAYOUTS[layoutSection][selectedLayoutId ?? DEFAULT_LAYOUT_ID];
    const dockviewApi = dockviewApiRef.current;
    if (dockviewApi) {
      applyLayoutSpec(dockviewApi, layoutSpec);
    }
  }, [layoutSection, selectedLayoutId]);

  const value = useMemo(
    () => ({
      dockviewApiRef,
      temporaryPanel,
      addTemporaryPanel,
      addPanel,
      addPanelToMain,
      addDeepDivePanel,
      addMultilegDeepDivePanel,
      addAutoHedgingControlsPanel,
      addOrderDetailsPanel,
      resetLayout,
      resetLayoutAndTabs,
    }),
    [
      dockviewApiRef,
      temporaryPanel,
      addTemporaryPanel,
      addPanel,
      addPanelToMain,
      addDeepDivePanel,
      addMultilegDeepDivePanel,
      addAutoHedgingControlsPanel,
      addOrderDetailsPanel,
      resetLayout,
      resetLayoutAndTabs,
    ]
  );

  return <AppLayoutContext.Provider value={value}>{children}</AppLayoutContext.Provider>;
}
