import { abbreviate, Flex, Text, TransferStatus, type TransferStatusEnum } from '@talos/kyoko';
import { useTheme } from 'styled-components';

export interface TransfersInfoToastContentProps {
  rows: TransfersToastRow[];
}

export interface TransfersToastRow {
  currency: string;
  amount: string | undefined;
  status: TransferStatusEnum;
}

export const TransfersInfoToastContent = ({ rows }: TransfersInfoToastContentProps) => {
  const theme = useTheme();
  return (
    <Flex flexDirection="column" gap="spacingDefault">
      {rows.map((row, i) => {
        return (
          <Flex gap="spacingMedium" key={i}>
            <TransferStatus status={row.status} theme={theme} />
            <Text>
              {abbreviate(row.amount)} {row.currency}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
