import {
  CustomerUser,
  useDynamicCallback,
  type AgGridSearchSelectDropdownProps,
  type Column,
  type Customer,
  type CustomerTradingLimit,
} from '@talos/kyoko';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { useCustomerUsers, useCustomers, useCustomersByName } from 'hooks/useCustomer';
import { useCustomerMarketAccounts } from 'providers';
import { useMemo } from 'react';
import type { UsersAutocompleteItem } from './shared';

interface CustomersAutocompleteItem {
  label: string;
  value?: Customer | CustomerUser;
  description?: string;
}

export const useCustomersColumn = (): Column => {
  const customers = useCustomers();
  const customerUsers = useCustomerUsers();
  const customersByName = useCustomersByName();
  const { customerMarketAccountsByName } = useCustomerMarketAccounts();

  const cellEditorParams = useDynamicCallback((params: CustomCellEditorProps<CustomerTradingLimit>) => {
    const { data } = params;
    let entities: (CustomerUser | Customer)[] = [];
    if (data.MarketAccount) {
      // If a market account is selected, only show CustomerUser's that match the counterparty of the market account
      const counterparty = customerMarketAccountsByName.get(data.MarketAccount)?.Counterparty;
      entities = customerUsers?.filter(user => user?.Counterparty === counterparty) ?? [];
    } else {
      entities = [...((data.MarketAccount == null && customers) || []), ...(customerUsers ?? [])];
    }
    const items: CustomersAutocompleteItem[] = entities
      .filter(u => u.DisplayName)
      .map(u => ({
        label: getCustomerLabel(u, customersByName),
        value: u,
        description: getCustomerDescription(u),
      }));
    items.unshift({
      label: '*',
      value: undefined,
      description: 'All customers and users',
    });
    return {
      ...params,
      useDropdownParams: {
        dropdownWidth: params.column.getActualWidth() + 100,
        dropdownPlacement: 'bottom-start',
      },
      useSearchSelectParams: {
        items,
        getLabel: (item: CustomersAutocompleteItem) => item.label,
        getDescription: (item: CustomersAutocompleteItem) => item.description ?? '',
      },
      searchPlaceholder: 'Customer or User',
    } satisfies AgGridSearchSelectDropdownProps<CustomersAutocompleteItem>;
  });

  const valueGetter = useDynamicCallback(({ data }: { data: { CustomerUser?: string; Counterparty?: string } }) => {
    const userEmail = data.CustomerUser;
    const customerName = data.Counterparty;
    if (userEmail != null) {
      return customerUsers?.find(user => user.Email === userEmail);
    }
    return customers?.find(customer => customer.Name === customerName);
  });

  const valueFormatter = useDynamicCallback(
    ({ data, value }: { data: { MarketAccount?: string }; value?: Customer | CustomerUser }) => {
      const counterparty = data.MarketAccount
        ? customerMarketAccountsByName.get(data.MarketAccount)?.Counterparty
        : undefined;
      if (value == null) {
        // Append the name of the counterparty to row with a MarketAccount
        return counterparty ? `* (${customersByName?.get(counterparty)?.DisplayName})` : '*';
      }
      if (value instanceof CustomerUser) {
        return getCustomerLabel(value, customersByName);
      }
      return value?.DisplayName;
    }
  );

  const valueSetter = ({
    newValue,
    data,
  }: {
    newValue: UsersAutocompleteItem;
    data: { CustomerUser?: string; Counterparty?: string };
  }) => {
    delete data.Counterparty;
    delete data.CustomerUser;

    if (newValue.value) {
      if ('Email' in newValue.value && newValue.value.Email != null) {
        data.CustomerUser = newValue.value.Email;
      } else if ('Name' in newValue.value && newValue.value.Name != null) {
        data.Counterparty = newValue.value.Name;
      }
    }
  };

  return useMemo(
    () => ({
      type: 'custom',
      id: 'customer',
      title: 'Customer or User',
      params: {
        headerTooltip:
          'Enforce the limit for an individual customer user or the all customer users of a customer, or leave empty (*) to enforce the limit for all customers and customer users.',
        colId: 'customer',
        width: 300,
        suppressKeyboardEvent: () => true,
        cellEditor: 'searchSelectDropdown',
        cellEditorPopup: true,
        cellEditorParams,
        valueSetter,
        valueGetter,
        valueFormatter,
        editable: true,
      },
    }),
    [cellEditorParams, valueFormatter, valueGetter]
  );
};

const getCustomerLabel = (u: CustomerUser | Customer, customersByName?: Map<string, Customer>) => {
  return u instanceof CustomerUser
    ? `${u.DisplayName} (${customersByName?.get(u.Counterparty)?.DisplayName}) | ${u.Email}`
    : u.DisplayName;
};

const getCustomerDescription = (u: CustomerUser | Customer) => (u instanceof CustomerUser ? 'User' : `Customer`);
