import { useTheme } from 'styled-components';
import { useMarketsContext } from '../../contexts';
import { useSecurity } from '../../hooks';
import { CustomerQuote, PricingModeEnum, type Quote } from '../../types';
import { QuoteStatusEnum } from '../../types/Status';
import { percentToBps } from '../../utils';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Icon, IconName } from '../Icons';
import { Table, Tbody, Td, Th, Thead, Tr } from '../Table';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { RejectionWrapper, TextWrapper } from './styles';

export function QuotesSummary({ quote }: { quote: Quote | CustomerQuote }) {
  const { colorTextWarning } = useTheme();

  const { marketsByName } = useMarketsContext();

  const security = useSecurity(quote.Symbol);

  const rejection = () => {
    return (
      <RejectionWrapper>
        <Icon icon={IconName.ExclamationCircle} color={colorTextWarning} />
        <TextWrapper>
          <Text>Rejected: {quote.Text}</Text>
        </TextWrapper>
      </RejectionWrapper>
    );
  };

  const isSpreadToFixing = quote.PricingMode === PricingModeEnum.SpreadToFixing;

  if (quote instanceof CustomerQuote) {
    if (quote.QuoteStatus === QuoteStatusEnum.Rejected) {
      return rejection();
    }

    return (
      <Table w="100%">
        <Thead>
          <Tr>
            <Th>Market</Th>
            <Th align="right">Bid</Th>
            <Th align="right">Offer</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Th>Dealer</Th>
            <Td align="right">
              {quote.BidPx == null ? (
                '-'
              ) : (
                <InlineFormattedNumber
                  highlightAll={false}
                  number={isSpreadToFixing ? percentToBps(quote.BidPx) : quote.BidPx}
                  currency={isSpreadToFixing ? 'BPS' : security!.QuoteCurrency}
                  increment={security!.DefaultPriceIncrement}
                  pretty={true}
                  round={true}
                />
              )}
            </Td>
            <Td align="right">
              {quote.OfferPx == null ? (
                '-'
              ) : (
                <InlineFormattedNumber
                  highlightAll={false}
                  number={isSpreadToFixing ? percentToBps(quote.OfferPx) : quote.OfferPx}
                  currency={isSpreadToFixing ? 'BPS' : security!.QuoteCurrency}
                  increment={security!.DefaultPriceIncrement}
                  pretty={true}
                  round={true}
                />
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    );
  }

  return quote.Markets == null ? null : (
    <Table w="100%">
      <Thead>
        <Tr>
          <Th>Market</Th>
          <Th align="right">Bid</Th>
          <Th align="right">Offer</Th>
        </Tr>
      </Thead>
      <Tbody>
        {quote.Markets.map(market => {
          const displayName = marketsByName.get(market.Market)?.DisplayName;

          return (
            <Tr key={market.Market}>
              <Th>
                <Tooltip tooltip={market.Text} usePortal={true}>
                  {displayName}{' '}
                  {market.Text != null && <Icon icon={IconName.ExclamationCircle} variant="Warning" size={10} />}
                </Tooltip>
              </Th>
              {market.Text == null ? (
                <>
                  <Td align="right">
                    {market.BidPx == null ? (
                      '-'
                    ) : (
                      <InlineFormattedNumber
                        highlightAll={false}
                        number={isSpreadToFixing ? percentToBps(market.BidPx) : market.BidPx}
                        currency={isSpreadToFixing ? 'BPS' : security!.QuoteCurrency}
                        increment={security!.DefaultPriceIncrement}
                        pretty={true}
                        round={true}
                      />
                    )}
                  </Td>
                  <Td align="right">
                    {market.OfferPx == null ? (
                      '-'
                    ) : (
                      <InlineFormattedNumber
                        highlightAll={false}
                        number={isSpreadToFixing ? percentToBps(market.OfferPx) : market.OfferPx}
                        currency={isSpreadToFixing ? 'BPS' : security!.QuoteCurrency}
                        increment={security!.DefaultPriceIncrement}
                        pretty={true}
                        round={true}
                      />
                    )}
                  </Td>
                </>
              ) : (
                <Td
                  colSpan={2}
                  style={{
                    textAlign: 'right',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: 200,
                  }}
                >
                  <div>
                    <Tooltip tooltip={market.Text} usePortal={true}>
                      <div style={{ opacity: 0.5, overflow: 'hidden', textOverflow: 'ellipsis' }}>{market.Text}</div>
                    </Tooltip>
                  </div>
                </Td>
              )}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
