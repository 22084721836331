import { HStack, VStack } from '@talos/kyoko';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacingBig}px;
  height: 100%;
`;

export const IconButtonWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacingMedium}px;
`;

export const BottomButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacingMedium}px;
`;

export const Wrapper = styled.div`
  height: 100%;
`;

export const ActivityWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
`;

export const DetailsWrapper = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SummaryRow = styled(HStack)`
  background: ${({ theme }) => theme.backgroundContent};
  border: 1px solid ${({ theme }) => theme.borderColorDivider};
  line-height: 1;
  border-left: none;
  border-right: none;
`;

export const UserRoleWrapper = styled(VStack)`
  gap: ${({ theme }) => theme.spacingMedium}px;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spacingBig}px;
  padding: ${({ theme }) => theme.spacingMedium}px;
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
`;
