import {
  HStack,
  Icon,
  IconName,
  Text,
  bpsToPercent,
  format,
  getCumAmt,
  percentToBps,
  toBig,
  useSecurity,
} from '@talos/kyoko';
import Big from 'big.js';
import { useCallback, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

export interface ManualTradeEntryForm {
  quantity?: string;
  price?: string;
  amount?: string;
  fee?: string;
  feeBPS?: string;
  feeCurrency?: string;
}
export interface useManualTradeEntryProps {
  quantity?: string;
  price?: string;
  amount?: string;
  symbol?: string;
  fee?: string;
  feeBPS?: string;
  feeCurrency?: string;
  updateForm: (form: ManualTradeEntryForm) => void;
}

const AmountWarningComponentWrapper = styled(HStack)`
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
`;

export const AmountWarningComponent = ({ text, ...props }: { text: string }) => {
  const theme = useTheme();
  return (
    <AmountWarningComponentWrapper gap="spacingDefault" justifyContent="flex-start" p="spacingMedium">
      <Icon icon={IconName.ExclamationCircle} color={theme.colors.yellow.lighten} />
      <Text {...props}>{text}</Text>
    </AmountWarningComponentWrapper>
  );
};

export const useManualTradeEntry = ({
  quantity,
  price,
  amount,
  symbol,
  fee,
  feeBPS,
  feeCurrency,
  updateForm,
}: useManualTradeEntryProps) => {
  const security = useSecurity(symbol);

  const {
    PositionCurrency,
    QuoteCurrency,
    BaseCurrency,
    NotionalMultiplier = '1',
    MinPriceIncrement,
    MinSizeIncrement,
    SettlementCurrency,
  } = useSecurity(symbol) ?? {};

  const computedAmount = useMemo(() => {
    const amount = getCumAmt(quantity || '0', price || '0', PositionCurrency ?? '', security);
    return format(amount.value, { spec: MinPriceIncrement, pretty: false });
  }, [PositionCurrency, MinPriceIncrement, price, quantity, security]);

  const amountWarningText = useMemo(() => {
    if (quantity && price && amount && !Big(computedAmount).eq(0) && !Big(computedAmount || 0).eq(amount || 0)) {
      return `Computed amount ${computedAmount} ${SettlementCurrency} is different from entered amount
      ${format(Big(amount || 0).toFixed())} ${SettlementCurrency}`;
    }
  }, [quantity, price, amount, computedAmount, SettlementCurrency]);

  const computedFeeBPS = useMemo(() => {
    if (feeCurrency === QuoteCurrency) {
      return percentToBps(
        toBig(fee)
          ?.div(toBig(amount) || '1')
          .toFixed() || ''
      );
    } else if (feeCurrency === BaseCurrency) {
      return percentToBps(
        toBig(fee)
          ?.div(toBig(quantity) || '1')
          .toFixed() || ''
      );
    }
    return '';
  }, [BaseCurrency, QuoteCurrency, amount, fee, feeCurrency, quantity]);

  const computedFee = useMemo(() => {
    const feePct = bpsToPercent(feeBPS);
    if (feeCurrency === QuoteCurrency) {
      return (
        toBig(amount)
          ?.times(feePct || '0')
          .toFixed() || ''
      );
    } else if (feeCurrency === BaseCurrency) {
      return (
        toBig(quantity)
          ?.times(feePct || '0')
          .toFixed() || ''
      );
    }
    return '';
  }, [BaseCurrency, QuoteCurrency, amount, feeBPS, feeCurrency, quantity]);

  const feeWarningText = useMemo(() => {
    if (fee && feeBPS && computedFeeBPS !== '' && !toBig(computedFee)?.eq(fee) && !toBig(computedFeeBPS)?.eq(feeBPS)) {
      return `Computed fee ${format(fee)} ${feeCurrency} is different from ${format(feeBPS)} BPS fee`;
    }
  }, [fee, feeBPS, computedFee, computedFeeBPS, feeCurrency]);

  const onRecompute = useCallback(
    (field?: 'quantity' | 'amount' | 'price' | 'fee' | 'feeBPS') => {
      // Quantity is Blank
      if (isFilled(price) && isFilled(amount) && (isBlank(quantity) || field === 'quantity')) {
        if (PositionCurrency === QuoteCurrency) {
          updateForm({
            quantity: format(Big(amount).times(price).times(NotionalMultiplier).toFixed(), {
              spec: MinSizeIncrement,
              pretty: false,
            }),
          });
        } else if (price !== '0') {
          updateForm({
            quantity: format(Big(amount).div(price).times(NotionalMultiplier).toFixed(), {
              spec: MinSizeIncrement,
              pretty: false,
            }),
          });
        }
      }
      // Price is Blank
      else if (isFilled(quantity) && isFilled(amount) && (isBlank(price) || field === 'price')) {
        if (PositionCurrency === QuoteCurrency) {
          updateForm({
            price: format(Big(amount).times(quantity).times(NotionalMultiplier).toFixed(), {
              spec: MinPriceIncrement,
              pretty: false,
            }),
          });
        } else if (quantity !== '0') {
          updateForm({
            price: format(Big(amount).div(quantity).times(NotionalMultiplier).toFixed(), {
              spec: MinPriceIncrement,
              pretty: false,
            }),
          });
        }
      }
      // Amount is Blank
      else if (isFilled(quantity) && isFilled(price) && (isBlank(amount) || field === 'amount')) {
        updateForm({ amount: computedAmount });
      }
      // Fee is Blank
      else if (isFilled(feeBPS, true) && isFilled(computedFee) && (isBlank(fee, true) || field === 'fee')) {
        updateForm({ fee: computedFee });
      }
      // FeeBPS is Blank
      else if (isFilled(fee, true) && isFilled(computedFeeBPS) && (isBlank(feeBPS, true) || field === 'feeBPS')) {
        updateForm({
          feeBPS: computedFeeBPS,
        });
      }
    },
    [
      price,
      amount,
      quantity,
      feeBPS,
      fee,
      PositionCurrency,
      QuoteCurrency,
      updateForm,
      NotionalMultiplier,
      MinSizeIncrement,
      MinPriceIncrement,
      computedAmount,
      computedFee,
      computedFeeBPS,
    ]
  );

  return {
    amountWarningText,
    feeWarningText,
    onRecompute,
  };
};

function isBlank(a: string | undefined | null, canBeZero = false): a is undefined {
  return a == null || a === '' || (!canBeZero && a === '0');
}

function isFilled(a: string | undefined | null, canBeZero = false): a is string {
  return !isBlank(a, canBeZero);
}
