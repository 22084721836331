import type React from 'react';
import { createContext, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { BehaviorSubject, asyncScheduler, combineLatest } from 'rxjs';
import { map, scan, shareReplay, throttleTime } from 'rxjs/operators';
import { v1 as uuid } from 'uuid';

import {
  ExecTypeEnum,
  Get,
  NEW_ORDER_SINGLE,
  ORDER,
  ORDER_CANCEL_REQUEST,
  ORDER_CONTROL_REQUEST,
  ORDER_MODIFY_REQUEST,
  OrderControlActionEnum,
  compareTimestampsWithMicrosecondPrecision,
  formattedDateForSubscription,
  prepareAllocationsForRequest,
  useConstant,
  useObservable,
  useSecuritiesContext,
  useSocketClient,
  useStaticSubscription,
  wsStitchWith,
  type ExecutionReport,
  type Order,
} from '@talos/kyoko';

import { assertOrderCurrency, cleanParametersForOrder } from 'utils/order';

import { ORDER_SUB_THROTTLE } from '../containers/Blotters/Orders/tokens';
import { useOpenCustomerOrders } from '../containers/Blotters/Orders/useOpenCustomerOrders';
import { OPEN_ORDER_STATUSES } from './constants';
import { useHedgeOrderStatuses } from './HedgeOrderStatusProvider';
import type {
  FullOrderRequest,
  ModifyOrderArgs,
  ModifyOrderRequest,
  OrdersContextProps,
  SendOrderArgs,
} from './orders.types';

const RECENT_ORDERS_THROTTLE_MS = 1000;

const Orders = createContext<OrdersContextProps | undefined>(undefined);
Orders.displayName = 'OrdersContext';

export const useOrders = () => {
  const context = useContext(Orders);
  if (context === undefined) {
    throw new Error('Missing OrderContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const OrdersProvider = memo(function OrdersProvider(props: React.PropsWithChildren<unknown>) {
  const client = useSocketClient();
  const { securitiesBySymbol } = useSecuritiesContext();

  const send = useCallback(
    (
      {
        symbol,
        side,
        orderQty,
        group,
        price,
        parameters,
        marketAccounts,
        subAccountAllocations,
        clOrdID,
        ordType,
        timeInForce,
        selectedStrategy,
        subAccounts,
        allocationValueType,
        useTradeAllocations,
        subAccount,
        orderCurrency,
        expectedFillPrice,
        expectedFillQty,
        comment,
        legParams,
        initialDecisionStatus,
        ...rest
      }: SendOrderArgs,
      _transactTime = new Date()
    ): boolean => {
      const _typecheck: EmptyObject = rest;
      const modifiedParameters = cleanParametersForOrder({
        parameters,
        selectedStrategy,
      });
      const data: FullOrderRequest = {
        Symbol: symbol,
        ClOrdID: clOrdID,
        Side: side,
        TransactTime: formattedDateForSubscription(_transactTime),
        OrderQty: orderQty,
        OrdType: ordType as string, // This is weird. Should the request type have the OrdType enum? when does the enum become a string?
        Price: price,
        TimeInForce: timeInForce,
        Markets: marketAccounts,
        Parameters: modifiedParameters,
        ExpectedFillPrice: expectedFillPrice,
        ExpectedFillQty: expectedFillQty,
        Comments: comment,
        LegParams: legParams,
      };
      if (group) {
        data.Group = group;
      }
      if (selectedStrategy) {
        data.Strategy = selectedStrategy.Name;
      }
      if (orderCurrency) {
        data.Currency = orderCurrency;
      }
      if (initialDecisionStatus) {
        data.InitialDecisionStatus = initialDecisionStatus;
      }
      if (subAccounts && subAccounts?.length > 0) {
        if (useTradeAllocations) {
          const preparedTradeAllocations = prepareAllocationsForRequest({
            subAccountAllocations,
            subAccounts,
            allocationValueType,
            quantity: orderQty,
          });
          data.Allocation = preparedTradeAllocations;
        } else if (subAccountAllocations?.length === 1) {
          data.SubAccount = subAccountAllocations[0].subAccount;
        } else if (subAccount) {
          // Click to Trade
          data.SubAccount = subAccount;
        }
      }

      if (!assertOrderCurrency(data, securitiesBySymbol.get(data.Symbol)!)) {
        return false;
      }

      client.registerPublication({
        type: NEW_ORDER_SINGLE,
        data: [data],
      });
      return true;
    },
    [client, securitiesBySymbol]
  );

  const modify = useCallback(
    (
      {
        symbol,
        price,
        orderID,
        clOrdID,
        orderQty,
        marketAccounts,
        subAccountAllocations,
        parameters,
        group,
        selectedStrategy,
        subAccounts,
        allocationValueType,
        useTradeAllocations,
        subAccount,
        comment,
        legParams,
      }: ModifyOrderArgs,
      _transactTime = new Date()
    ) => {
      const modifiedParameters = cleanParametersForOrder({
        parameters,
        selectedStrategy,
      });
      const data: ModifyOrderRequest = {
        Symbol: symbol,
        Price: price,
        OrderID: orderID,
        ClOrdID: clOrdID,
        TransactTime: formattedDateForSubscription(_transactTime) || '',
        OrderQty: orderQty,
        Markets: marketAccounts,
        Strategy: selectedStrategy.Name,
        Parameters: modifiedParameters,
        Group: group,
        Comments: comment,
      };
      if (legParams) {
        data.LegParams = legParams;
      }
      if (subAccounts && subAccounts?.length > 0) {
        if (useTradeAllocations) {
          const preparedTradeAllocations = prepareAllocationsForRequest({
            subAccountAllocations,
            subAccounts,
            allocationValueType,
            quantity: orderQty,
          });
          data.Allocation = preparedTradeAllocations;
        } else if (subAccountAllocations?.length === 1) {
          data.SubAccount = subAccountAllocations[0].subAccount;
        } else if (subAccount) {
          // Click to Trade
          data.SubAccount = subAccount;
        }
      }
      // Safeguard against poorly formatted or not required SubAccount selection
      if (data.SubAccount == null || data.SubAccount === '') {
        delete data.SubAccount;
      }

      client.registerPublication({
        type: ORDER_MODIFY_REQUEST,
        data: [data],
      });
    },
    [client]
  );
  // More then Group is returned by this RestEndpoint but it is the only property caller of this crappy function need
  const historicalOrders = useCallback((endpoint: string) => {
    const queryString = new URLSearchParams();
    queryString.append('offset', '0');
    queryString.append('limit', '100');
    queryString.append('orderBy', '-SubmitTime');
    return Get<{ data: Pick<ExecutionReport, 'Group'>[] }>(
      endpoint,
      `/organization/blotters/OMSOrders?${queryString.toString()}`
    );
  }, []) satisfies OrdersContextProps['historicalOrders'];

  const cancel = useCallback(
    (orderID?: string) =>
      client.registerPublication({
        type: ORDER_CANCEL_REQUEST,
        data: [
          {
            ClOrdID: uuid(),
            OrderID: orderID,
            TransactTime: formattedDateForSubscription(new Date()),
          },
        ],
      }),
    [client]
  );

  const pause = useCallback(
    (orderID?: string) =>
      client.registerPublication({
        type: ORDER_CONTROL_REQUEST,
        data: [
          {
            ClOrdID: uuid(),
            OrderID: orderID,
            Action: OrderControlActionEnum.Pause,
          },
        ],
      }),
    [client]
  );

  const resume = useCallback(
    (orderID?: string) =>
      client.registerPublication({
        type: ORDER_CONTROL_REQUEST,
        data: [
          {
            ClOrdID: uuid(),
            OrderID: orderID,
            Action: OrderControlActionEnum.Resume,
          },
        ],
      }),
    [client]
  );

  // Recent Orders (Last 2 days)
  // TODO why are we doing this? why can't we just subscribe to open orders only? might be legacy from when this was used for the
  // recent orders blotter but now it's only being used for the open orders blotter.
  // TODO should this include the loadAll flag? I think if we don't load all of the orders
  const { data: recentSub } = useStaticSubscription<Order>({
    name: ORDER,
    tag: 'RECENT_ORDERS',
    StartDate: formattedDateForSubscription(Sugar.Date.create('2 days ago')),
    sort_by: '-SubmitTime',
    HideApiCalls: true,
    IncludeHedgeOrderStatus: true,
    Throttle: ORDER_SUB_THROTTLE,
    CoalesceExecs: [ExecTypeEnum.Restated, ExecTypeEnum.Trade],
  });

  // Open Orders (All time)
  const { data: openSub } = useStaticSubscription<Order>(
    {
      name: ORDER,
      tag: 'OPEN_ORDERS',
      Statuses: OPEN_ORDER_STATUSES,
      sort_by: '-Timestamp',
      HideApiCalls: true,
      IncludeHedgeOrderStatus: true,
      Throttle: ORDER_SUB_THROTTLE,
      CoalesceExecs: [ExecTypeEnum.Restated, ExecTypeEnum.Trade],
    },
    { loadAll: true }
  );

  const openCustomerOrdersObs = useOpenCustomerOrders();

  // Hedge order status update stitching onto orders
  const { hedgeOrderStatusObs } = useHedgeOrderStatuses();

  const enrichedOpenSub = useMemo(() => {
    return openSub.pipe(
      wsStitchWith({
        secondarySource: hedgeOrderStatusObs,
        getPrimaryTypeKey: order => order.OrderID,
        getSecondaryTypeKey: hos => hos.InitiatingOrderID,
        stitch: (order, hos) => {
          if (hos) {
            order.enrichWithHedgeOrderStatus(hos);
          }
          return order;
        },
      })
    );
  }, [hedgeOrderStatusObs, openSub]);

  const enrichedRecentSub = useMemo(() => {
    return recentSub.pipe(
      wsStitchWith({
        secondarySource: hedgeOrderStatusObs,
        getPrimaryTypeKey: order => order.OrderID,
        getSecondaryTypeKey: hos => hos.InitiatingOrderID,
        stitch: (order, hos) => {
          if (hos) {
            order.enrichWithHedgeOrderStatus(hos);
          }
          return order;
        },
      })
    );
  }, [hedgeOrderStatusObs, recentSub]);

  // Make sure we're always storing the data received from the two subscriptions above,
  // even if no one is listening on e.g. the `recentOrders` observable.
  const recentAndOpenOrdersObs = useConstant(new BehaviorSubject(new Map()));
  useEffect(() => {
    const subscription = combineLatest([enrichedRecentSub, enrichedOpenSub])
      .pipe(
        map(([recent, open]) => [...recent.data, ...open.data]),
        scan((orders, data) => {
          data.forEach(d => {
            const id = d.OrderID;
            const existingOrder = orders.get(id);
            // If an existing order exists in the map with the same revision, only update it if the revision is newer or
            // equal with later timestamp.
            // This is because exectype=restated does not update revision #.
            // If the revision is larger always update as under load we cannot trust Timestamps to be in order.
            if (existingOrder == null || d.Revision > existingOrder.Revision) {
              orders.set(id, d);
            } else if (
              d.Revision === existingOrder.Revision &&
              (!d.Timestamp ||
                !existingOrder?.Timestamp ||
                // d.Timestamp "isAfterOrEqual" (>= 0) existingOrder.Timestamp
                compareTimestampsWithMicrosecondPrecision(d.Timestamp, existingOrder.Timestamp) >= 0)
            ) {
              orders.set(id, d);
            }
          });
          return orders;
        }, new Map<string, Order>())
      )
      .subscribe(orders => recentAndOpenOrdersObs.next(orders));
    return () => {
      subscription.unsubscribe();
    };
  }, [recentAndOpenOrdersObs, enrichedRecentSub, enrichedOpenSub]);

  // Recent Orders (Combined)
  const recentOrders = useObservable(
    () =>
      combineLatest([recentAndOpenOrdersObs, openCustomerOrdersObs]).pipe(
        map(([orders, openCustomerOrders]) => {
          return Array.from(orders.values()).map((order: Order) => {
            if (order.ParentOrderID && openCustomerOrders.has(order.ParentOrderID)) {
              const customerOrder = openCustomerOrders.get(order.ParentOrderID);
              order.enrichOrderWithCustomerOrder(customerOrder);
            }
            return order;
          });
        }),
        throttleTime(RECENT_ORDERS_THROTTLE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [recentAndOpenOrdersObs, openCustomerOrdersObs]
  );

  return (
    <Orders.Provider
      value={{
        recentOrders,
        historicalOrders,
        send,
        modify,
        cancel,
        pause,
        resume,
      }}
    >
      {props.children}
    </Orders.Provider>
  );
});
