import {
  AccordionRowCell,
  InlineFormattedNumber,
  Popover,
  useCurrenciesContext,
  usePopoverState,
  type Currency,
} from '@talos/kyoko';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { portfolioAbbreviation } from '../../portfolioAbbreviation';
import type { Position } from '../../types';
import { IncomingOutgoingPosition } from './styles';
import { useSummarizedPositions } from './useSummarizedPositions';

export interface SummarizedPositionsProps {
  positions: Position[];
}

export const SummarizedPositions = ({ positions }: SummarizedPositionsProps) => {
  const { homeCurrency } = useDisplaySettings();
  const currenciesContext = useCurrenciesContext();

  const homeCurrencyObject = currenciesContext.currenciesBySymbol.get(homeCurrency);

  const { incoming, outgoing } = useSummarizedPositions(positions);

  if (homeCurrencyObject == null) {
    return null;
  }

  return (
    <>
      <AccordionRowCell>
        <SummarizedPosition value={incoming} currency={homeCurrencyObject} />
      </AccordionRowCell>
      <AccordionRowCell>
        <SummarizedPosition value={outgoing} currency={homeCurrencyObject} />
      </AccordionRowCell>
    </>
  );
};

function SummarizedPosition({ value, currency }: { value: string; currency: Currency }) {
  const popover = usePopoverState({
    trigger: 'hover',
    placement: 'top',
  });

  return (
    <Popover {...popover} overflowY="visible">
      <IncomingOutgoingPosition>
        {value !== '0' && (
          <>
            {portfolioAbbreviation(value)} {currency.Symbol}
          </>
        )}
      </IncomingOutgoingPosition>
      <InlineFormattedNumber
        number={value}
        currency={currency.Symbol}
        increment={currency.DefaultIncrement}
        truncate={true}
      />
    </Popover>
  );
}
