import { Box, Icon, IconName, Spinner } from '@talos/kyoko';
import { BulkModifyOrderStatusEnum } from '../Dialogs/types';

interface OrdersStatusIconProps {
  status?: BulkModifyOrderStatusEnum | null;
}

export function BulkEditOrderStatus({ status }: OrdersStatusIconProps) {
  if (!status) {
    return null;
  }
  let icon;
  let color;
  let loading = false;

  switch (status) {
    case BulkModifyOrderStatusEnum.Success:
      icon = IconName.CheckCircleSolid;
      color = 'green.lighten';
      break;
    case BulkModifyOrderStatusEnum.Error:
      icon = IconName.CloseCircleSolid;
      color = 'red.lighten';
      break;
    case BulkModifyOrderStatusEnum.Pending:
    default:
      icon = IconName.Refresh;
      color = 'colorTextDefault';
      loading = true;
  }

  return loading ? (
    <Box display="inline-block" ml="spacingSmall">
      <Spinner size={12} />
    </Box>
  ) : (
    <Icon icon={icon} spaceBefore="spacingSmall" color={color} />
  );
}
