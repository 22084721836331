import type Big from 'big.js';
import { toBig } from '../utils';
import { Position } from './Position';

/**
 * The DealerPosition class is a wrapper around the Position class, providing an API
 * to use when you want to view positions of a dealer from the dealer's perspective.
 *
 * The wrapper provides views with calculated metrics on the position which would be relevant for the client to oversee.
 */
export class DealerPosition extends Position {
  private amountBig: Big | undefined = undefined;
  private equivalentAmountBig: Big | undefined = undefined;

  /** The Position.Amount owed to me by the customer (positive) */
  get incoming() {
    return this.amountBig?.gte(0) ? this.amountBig.toFixed() : undefined;
  }

  /** The Position.Amount owed by me to the customer (negative) */
  get outgoing() {
    return this.amountBig?.lt(0) ? this.amountBig.toFixed() : undefined;
  }
  /** The home currency equivalent of incoming */
  get incomingEquivalent() {
    return this.equivalentAmountBig?.gte(0) ? this.equivalentAmountBig.toFixed() : undefined;
  }

  /** The home currency equivalent of outgoing */
  get outgoingEquivalent() {
    return this.equivalentAmountBig?.lt(0) ? this.equivalentAmountBig.toFixed() : undefined;
  }

  constructor(position: Position) {
    super(position);

    // Just instantiate these once so we dont have to keep doing it in all the getters
    this.amountBig = toBig(position.Amount);
    this.equivalentAmountBig = toBig(position.Equivalent?.Amount);
  }
}
