import {
  FormControlSizes,
  SymbolSelector,
  SymbolSelectorAppearance,
  useDynamicCallback,
  useSecuritiesContext,
  type Security,
} from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { UseComboboxStateChange } from 'downshift';
import { useMemo, useState } from 'react';

export interface AgSymbolSelectorProps {
  onChange: (value?: Security) => void;
  size?: FormControlSizes;
  isDisabled?: boolean;
  filter?: (security: Security) => boolean;
}

export function AgSymbolSelector({
  onChange,
  isDisabled = false,
  filter,
}: ICellRendererParams & AgSymbolSelectorProps) {
  const { searchableSecurities, securitiesBySymbol } = useSecuritiesContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleSymbolChange = useDynamicCallback((value?: string) => {
    const security = securitiesBySymbol.get(value || '');
    onChange(security);
  });

  const filteredSecurities = useMemo(() => {
    if (!isOpen) {
      return [];
    }
    return searchableSecurities.filter(security => (filter ? filter(security) : true));
  }, [isOpen, searchableSecurities, filter]);

  const handleIsOpenChange = useDynamicCallback((changes: UseComboboxStateChange<Security>) => {
    setIsOpen(changes?.isOpen || false);
  });

  return (
    <SymbolSelector
      appearance={SymbolSelectorAppearance.Search}
      availableSecurities={filteredSecurities}
      onSymbolChanged={handleSymbolChange}
      onIsOpenChange={handleIsOpenChange}
      disabled={isDisabled}
      startOpen={false}
      dropdownWidth={400}
      maxHeight={480}
      showGroups={true}
      centerBasicSelect
      size={FormControlSizes.Small}
      showFavorites={true}
      portalize
    />
  );
}
