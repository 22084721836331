import {
  Button,
  ButtonGroup,
  Flex,
  FormControlSizes,
  FormGroup,
  HStack,
  Icon,
  IconName,
  type MarketAccount,
  MarketAccountStatusEnum,
  NotificationVariants,
  NumberInput,
  runValidation,
  Text,
  Toggle,
  useDynamicCallback,
  useGlobalToasts,
  validatePrecision,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import { transparentize } from 'polished';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { type BaseSchema, number, object } from 'yup';
import { useEquityMarginRatioLimitContext } from '../../../../providers/EquityMarginRatioLimitProvider';
import { type ITradingControlsDrawerTab, MIN_EQUITY_MARGIN_RATIO_INCREMENT } from '../types';
import { type EquityMarginRatioLimitRequest, useEquityMarginRatioLimits } from '../useEquityMarginRatioLimits';
import { useTradingControls } from '../useTradingControls';
import { TradingStatus } from './components/TradingStatus';

interface GeneralTabForm {
  tradingEnabled: boolean;
  equityMarginRatio?: string;
  globalEquityMarginRatio?: string;
  confirm?: string;
}

export type GeneralTabFormArg = {
  [K in keyof Required<GeneralTabForm>]: { key: K; value: GeneralTabForm[K] };
}[keyof GeneralTabForm];

interface TradingControlsGeneralTabProps extends UseTradingControlsGeneralTabProps {
  showWarning: boolean;
  form: GeneralTabForm;
  updateForm: (arg: GeneralTabFormArg) => void;
  errors: Partial<GeneralTabForm>;
}

export function TradingControlsGeneralTab({
  entity,
  showWarning,
  form,
  updateForm,
  errors,
}: TradingControlsGeneralTabProps) {
  const theme = useTheme();
  const { isEquityMarginRatioLimitCheckEnabled } = useEquityMarginRatioLimits();

  const handleResetEMR = useCallback(() => {
    updateForm({ key: 'equityMarginRatio', value: '' });
  }, [updateForm]);

  if (!entity || entity.Status === MarketAccountStatusEnum.Inactive) {
    return (
      <Flex flexDirection="column" flex="1 0 auto" p="spacingDefault">
        <TradingStatus status={MarketAccountStatusEnum.Inactive} />
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" flex="1 0 auto" p="spacingDefault" pb="0" h="100%">
      <TradingStatus status={entity.Status} />
      <FormGroup mt="spacingLarge" inline label="Trading Enabled" alignItems="center">
        <ButtonGroup justifyContent="flex-end" flex="1">
          <Toggle
            data-testid="trading-enabled-checkbox"
            size={FormControlSizes.Default}
            checked={form.tradingEnabled}
            onChange={(val: boolean) =>
              updateForm({
                key: 'tradingEnabled',
                value: val,
              })
            }
          />
        </ButtonGroup>
      </FormGroup>
      {isEquityMarginRatioLimitCheckEnabled && (
        <FormGroup
          tooltip="The Equity Margin Ratio Limit allows an organization to reduce risk associated with derivatives trading by enforcing their leverage limits across different trading platforms. An optional global default value can be set and then overwritten on a per market account basis."
          label="Equity Margin Ratio"
          labelSuffix={
            <Button
              startIcon={IconName.Reply}
              size={FormControlSizes.Xxs}
              disabled={!form.equityMarginRatio}
              onClick={handleResetEMR}
            >
              Reset to Default
            </Button>
          }
          error={errors.equityMarginRatio}
        >
          <NumberInput
            data-testid="equity-margin-ratio-input"
            placeholder={form?.globalEquityMarginRatio ?? ''}
            value={form.equityMarginRatio ?? ''}
            minIncrement={MIN_EQUITY_MARGIN_RATIO_INCREMENT}
            onChange={value => updateForm({ key: 'equityMarginRatio', value })}
          />
        </FormGroup>
      )}
      {showWarning && (
        <Flex alignItems="flex-end" flex="1" ml={-theme.spacingMedium} mr={-theme.spacingMedium}>
          <HStack
            gap="spacingDefault"
            background={transparentize(0.96, theme.colors.gray['100'])}
            p="spacingMedium"
            w="100%"
            justifyContent="flex-start"
          >
            <>
              <Icon color="colors.red.lighten" icon={IconName.ExclamationCircleSolid} />
              <Text color="colors.gray.100">
                Disabling a market account will not cancel orders using this account. Open market orders will be
                cancelled and no new market orders will be sent while disabled
              </Text>
            </>
          </HStack>
        </Flex>
      )}
    </Flex>
  );
}

interface UseTradingControlsGeneralTabProps {
  entity: MarketAccount | undefined;
}

const schema: Partial<Record<keyof GeneralTabForm, BaseSchema>> = {
  equityMarginRatio: number()
    .nullable()
    .transform((v, o) => (!o ? null : v))
    .typeError('Please enter Equity Margin Ratio')
    .moreThan(0, 'Equity Margin Ratio must be be greater than 0')
    .test('precision', `Min increment is ${MIN_EQUITY_MARGIN_RATIO_INCREMENT}`, q =>
      q ? validatePrecision(MIN_EQUITY_MARGIN_RATIO_INCREMENT, q) : true
    ),
};

const validate = function (form: Partial<GeneralTabForm>) {
  return runValidation(object().shape(schema), form);
};

export function useTradingControlsGeneralTab(props: UseTradingControlsGeneralTabProps): ITradingControlsDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const { globalEquityMarginRatioLimit, equityMarginRatioLimitByMarketAccount } = useEquityMarginRatioLimitContext();
  const {
    isEquityMarginRatioLimitCheckEnabled,
    createEquityMarginRatioLimit,
    updateEquityMarginRatioLimit,
    deleteEquityMarginRatioLimit,
  } = useEquityMarginRatioLimits();

  const { entity } = props;
  const [form, setForm] = useState<GeneralTabForm>({
    tradingEnabled: entity?.Status === MarketAccountStatusEnum.Active,
  });
  const [errors, setErrors] = useState<Partial<GeneralTabForm>>(validate(form));
  const initialForm = useRef(form);
  const showWarning = !form.tradingEnabled && entity?.Status === MarketAccountStatusEnum.Active;
  const isDirty = useMemo(() => !isEqual(initialForm.current, form), [form]);
  const isValid = useMemo(() => Object.keys(errors).length === 0, [errors]);

  const updateForm = useDynamicCallback(({ key, value }: GeneralTabFormArg) => {
    const newForm = { ...form, [key]: value };
    setForm(newForm);
    setErrors(validate(newForm));
  });
  const { toggleTrading } = useTradingControls();

  const resetForm = useCallback(
    (entity: MarketAccount | undefined) => {
      const initialValues: GeneralTabForm = {
        tradingEnabled: entity?.Status === MarketAccountStatusEnum.Active,
        equityMarginRatio: equityMarginRatioLimitByMarketAccount.get(entity?.Name ?? '')?.Ratio ?? '',
        globalEquityMarginRatio: globalEquityMarginRatioLimit?.Ratio,
      };
      setForm(initialValues);
      initialForm.current = initialValues;
      setErrors(validate(initialValues));
    },
    [equityMarginRatioLimitByMarketAccount, globalEquityMarginRatioLimit]
  );

  useEffect(() => {
    resetForm(entity);
  }, [entity, resetForm]);

  const handleSaveChanges = useDynamicCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!entity) {
        reject();
        return;
      }
      // Trading Enabled
      const mode = form.tradingEnabled ? MarketAccountStatusEnum.Active : MarketAccountStatusEnum.Disabled;
      const requests: Promise<any>[] = [toggleTrading(entity.Name, mode)];

      // Equity Margin Ratio
      if (isEquityMarginRatioLimitCheckEnabled && form.equityMarginRatio !== initialForm.current.equityMarginRatio) {
        const equityMarginRatio = equityMarginRatioLimitByMarketAccount.get(entity.Name);
        const request: Promise<EquityMarginRatioLimitRequest> = equityMarginRatio?.LimitID
          ? form.equityMarginRatio
            ? updateEquityMarginRatioLimit(equityMarginRatio.LimitID, {
                Ratio: form.equityMarginRatio ?? '',
                MarketAccount: entity.Name,
              })
            : deleteEquityMarginRatioLimit(equityMarginRatio.LimitID)
          : createEquityMarginRatioLimit({
              Ratio: form.equityMarginRatio ?? '',
              MarketAccount: entity.Name,
            });
        requests.push(request);
      }

      Promise.allSettled(requests)
        .then(() => {
          addToast({
            text: `Updated ${entity.DisplayName}`,
            variant: NotificationVariants.Positive,
          });
          resolve();
        })
        .catch(e => {
          addToast({
            text: e?.toString() || `Could not update ${entity.DisplayName}`,
            variant: NotificationVariants.Negative,
          });
          reject();
        });
    });
  });

  return {
    name: 'General',
    save: handleSaveChanges,
    isDirty,
    isValid,
    component: (
      <TradingControlsGeneralTab
        entity={entity}
        showWarning={showWarning}
        form={form}
        updateForm={updateForm}
        errors={errors}
      />
    ),
  };
}
