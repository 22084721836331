import {
  Card,
  Divider,
  InlineFormattedNumber,
  Side,
  SmartSummary,
  SymbolDisplay,
  SymbolDisplaySize,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnifiedLiquidityEnum,
  getFilledNotional,
  getPricingReference,
  getSymbolsAcrossMarkets,
  getTotalNotional,
  isMultileg,
  useCurrency,
  useSecurity,
  type CardProps,
  type CustomerOrder,
  type Order,
} from '@talos/kyoko';

export interface OrderCardProps extends CardProps {
  order: Order;
  customerOrder?: CustomerOrder;
  showExpandedDetails?: boolean;
}

export function OrderCard({ order, customerOrder, showExpandedDetails, ...props }: OrderCardProps) {
  const security = useSecurity(order.Symbol);
  const currency = useCurrency(order.Currency);
  const amountCurrency = useCurrency(order.AmountCurrency);

  const totalNotional = getTotalNotional(order, security);
  const customerTotalNotional = customerOrder == null ? undefined : getTotalNotional(customerOrder, security);
  const filledNotional = getFilledNotional(order, security);
  const notionalCurrencyInfo = useCurrency(filledNotional.currency);
  const notionalIncrement = notionalCurrencyInfo?.DefaultIncrement ?? amountCurrency?.DefaultIncrement;

  const underlyingSymbols =
    order.unifiedLiquidity === UnifiedLiquidityEnum.Enabled ? getSymbolsAcrossMarkets(order) : [];

  const placedPriceAmount = getPricingReference(order);

  return (
    <Card title="Order" {...props}>
      <Table w="100%">
        {customerOrder != null && (
          <Thead>
            <Tr>
              <Th align="left" colSpan={customerOrder == null ? 2 : 1} pl={0} pb="spacingDefault">
                <Card.Subtitle mb={0}>Order</Card.Subtitle>
              </Th>
              <Th align="right" w="33%" pb="spacingDefault">
                Customer
              </Th>
              <Th align="right" w="33%" pb="spacingDefault">
                Hedge
              </Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          <Tr>
            <Th>Symbol</Th>
            {customerOrder != null && (
              <Td align="right">
                <SymbolDisplay
                  symbol={customerOrder.Symbol}
                  underlyingSymbols={underlyingSymbols}
                  showDescription={false}
                  size={SymbolDisplaySize.Small}
                  p={0}
                  justifyContent="flex-end"
                />
              </Td>
            )}
            <Td align="right" width="100%">
              <SymbolDisplay
                symbol={order.Symbol}
                underlyingSymbols={underlyingSymbols}
                showDescription={false}
                showJSON={true}
                size={SymbolDisplaySize.Small}
                p={0}
                justifyContent="flex-end"
              />
            </Td>
          </Tr>
          <Tr>
            <Th>Side</Th>
            {customerOrder != null && (
              <Td align="right">
                <Side side={order.Side}>{order.Side}</Side>
              </Td>
            )}
            <Td align="right">
              <Side side={order.Side}>{order.Side}</Side>
            </Td>
          </Tr>
          {isMultileg(security) && (
            <Tr>
              <Th>Product</Th>
              {customerOrder != null && <Td align="right" />}
              <Td align="right" w="33%" pb="spacingDefault">
                {security?.MultilegDetails?.SyntheticProductType}
              </Td>
            </Tr>
          )}
          <Tr>
            <Th whiteSpace="nowrap">Order Qty</Th>
            {customerOrder != null && (
              <Td align="right">
                <SmartSummary entity={customerOrder} type="order" showPrice={false} showSide={false} />
              </Td>
            )}
            <Td align="right">
              <SmartSummary entity={order} type="order" showPrice={false} showSide={false} />
            </Td>
          </Tr>
          <Tr>
            <Th>Price</Th>
            {customerOrder != null && (
              <Td align="right">
                <SmartSummary entity={customerOrder} type="order" showQty={false} showSide={false} />
              </Td>
            )}
            <Td align="right">
              <SmartSummary entity={order} type="order" showQty={false} showSide={false} />
            </Td>
          </Tr>

          {placedPriceAmount && (
            <Tr>
              <Th>Reference Price</Th>
              <Td align="right">
                <InlineFormattedNumber number={placedPriceAmount.value} currency={placedPriceAmount.currency} />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {showExpandedDetails && (
        <>
          <Divider my="spacingComfortable" />
          <Table w="100%">
            <Tbody>
              <Tr>
                <Th>Total Notional</Th>
                {customerTotalNotional != null && (
                  <Td align="right" w="33%">
                    <InlineFormattedNumber
                      number={customerTotalNotional.value}
                      currency={customerTotalNotional.currency}
                      increment={notionalIncrement}
                    />
                  </Td>
                )}
                <Td align="right" w={customerTotalNotional == null ? undefined : '33%'}>
                  <InlineFormattedNumber
                    number={totalNotional.value}
                    currency={totalNotional.currency}
                    increment={notionalIncrement}
                  />
                </Td>
              </Tr>
              <Tr>
                <Th>Expected Fill Price</Th>
                <Td align="right" colSpan={customerOrder == null ? 1 : 2}>
                  <InlineFormattedNumber
                    number={order.ExpectedFillPrice}
                    currency={order.AmountCurrency}
                    increment={amountCurrency?.DefaultIncrement}
                    align="right"
                  />
                </Td>
              </Tr>
              <Tr>
                <Th>Expected Fill Qty</Th>
                <Td align="right" colSpan={customerOrder == null ? 1 : 2}>
                  <InlineFormattedNumber
                    number={order.ExpectedFillQty}
                    currency={order.Currency}
                    increment={currency?.DefaultIncrement}
                    align="right"
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      )}
    </Card>
  );
}
