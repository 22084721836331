import { DEFAULT_DATE_PICKER_EOD, type FeeModeEnum } from '@talos/kyoko';
import { createContext, useContext } from 'react';
export interface CustomEOD {
  hours: number;
  minutes: number;
}
export const DefaultDisplaySettingsConfig = {
  pinSidebar: false,
  showAllInPrices: null as FeeModeEnum | null | undefined,
  showFirmLiquidity: false,
  showTotalEstimates: true,
  homeCurrency: 'USD',
  customEOD: DEFAULT_DATE_PICKER_EOD as CustomEOD,
  latestReleaseNotesRead: undefined as string | undefined,
};
export const DefaultDisplaySettingsMethods = {
  setPinSidebar: (pinSidebar: boolean) => {},
  setShowAllInPrices: (showAllInPrices: FeeModeEnum | null) => {},
  setShowFirmLiquidity: (showFirmLiquidity: boolean) => {},
  setShowTotalEstimates: (showTotalEstimates: boolean) => {},
  setHomeCurrency: (homeCurrency: string) => {},
  setCustomEOD: (customEOD: { hours?: string | number; minutes?: string | number }) => {},
  setLatestReleaseNotesRead: (latestReleaseNotesRead: string | undefined) => {},
};
export const DefaultDisplaySettingsContext = { ...DefaultDisplaySettingsConfig, ...DefaultDisplaySettingsMethods };
export const DisplaySettingsContext = createContext(DefaultDisplaySettingsContext);
DisplaySettingsContext.displayName = 'DisplaySettingsContext';
export const useDisplaySettings = () => useContext(DisplaySettingsContext);
