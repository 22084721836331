import {
  AccordionGroup,
  Box,
  LookbackOption,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  TradeSettleStatusEnum,
  VStack,
  usePersistedTabs,
  usePortal,
  useTabs,
} from '@talos/kyoko';
import { TRADE_SETTLEMENT_BLOTTER_PORTAL_ID, TRADE_SETTLEMENT_BLOTTER_PREFIX } from '../tokens';
import type { SettlementMonitoringFilter } from '../types';
import { TradeSettlementBlotter } from './TradeSettlementBlotter';
import type { TradeSettlementColumn, TradeSettlementFilter, TradeSettlementTabProps } from './types';

const defaultTradeSettlementColumns: TradeSettlementColumn[] = [
  'TransactTime',
  'Counterparty',
  'MarketAccount',
  'Symbol',
  'Side',
  'SettleStatus',
  'Quantity',
  'Amount',
  'Price',
  'CumSettledDue',
  'CumSettledProceeds',
  'TradeID',
  'OrderID',
];
const defaultTradeSettlementUnsettledFilter: TradeSettlementFilter = {
  _start: LookbackOption.PastWeek,
  Statuses: [TradeSettleStatusEnum.Unsettled],
};

const defaultTradeSettlementSettledFilter: TradeSettlementFilter = {
  _start: LookbackOption.PastWeek,
  Statuses: [TradeSettleStatusEnum.Settled],
};

const TABS: TradeSettlementTabProps[] = [
  {
    id: 'settle-trades-blotter-unsettled',
    label: 'Unsettled',
    editable: false,
    closable: false,
    defaultFilter: defaultTradeSettlementUnsettledFilter,
    defaultColumns: defaultTradeSettlementColumns,
  },
  {
    id: 'settle-trades-blotter-settled',
    label: 'Settled',
    editable: false,
    closable: false,
    defaultFilter: defaultTradeSettlementSettledFilter,
    defaultColumns: defaultTradeSettlementColumns,
  },
];

const TABS_ID = 'settlement-monitoring/trade-settlement-blotter';

export const TradeSettlement = ({ externalFilter }: { externalFilter: SettlementMonitoringFilter }) => {
  const persistedTabs = usePersistedTabs(TABS_ID, {
    defaultInitialItems: TABS,
  });

  const tabs = useTabs<TradeSettlementTabProps>({
    size: TabSize.Large,
    ...persistedTabs,
  });

  const { setPortalRef: filtersContainerRef } = usePortal(TRADE_SETTLEMENT_BLOTTER_PORTAL_ID);

  return (
    <VStack h="100%" data-testid="trade-settlement-blotter">
      <Tabs {...tabs} h="100%">
        <TabList isBordered rightItems={<Box ref={filtersContainerRef} />}>
          {tabs.items.map((tab, idx) => (
            <Tab key={idx} {...tab} />
          ))}
        </TabList>
        <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          {tabs.items.map(tab => {
            return (
              <AccordionGroup key={tab.id}>
                <TradeSettlementBlotter
                  key={tab.id}
                  blotterID={`${TRADE_SETTLEMENT_BLOTTER_PREFIX}/${tab.id}`}
                  defaultInternalFilter={tab.defaultFilter}
                  defaultColumns={tab.defaultColumns}
                  externalFilter={externalFilter}
                />
              </AccordionGroup>
            );
          })}
        </TabPanels>
      </Tabs>
    </VStack>
  );
};
