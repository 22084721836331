import { isError } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import {
  ACTION,
  AccordionGroup,
  AccordionTable,
  AggregationType,
  Box,
  Button,
  ButtonVariants,
  Dialog,
  IconName,
  Input,
  LoaderSizes,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Text,
  useDisclosure,
  useGlobalToasts,
  type AccordionTableColumn,
} from '@talos/kyoko';

import { Loader, LoaderWrapper } from 'components/Loader';
import { useRoleAuth } from 'hooks';
import { useAggregations } from 'hooks/useAggregations';
import type { Aggregation } from 'types';
import { AggregationAccordion } from './AggregationAccordion';
import { NewAggregationDialog } from './NewAggregationDialog';

const accordionTableColumns: AccordionTableColumn[] = [
  {
    id: 'displayName',
    label: 'Display Name',
  },
  {
    id: 'name',
    label: 'Name',
  },
];

export function CustomerAggregations() {
  const { listAggregations, createAggregation, deleteAggregation, updateAggregation } = useAggregations();
  const [aggregations, setAggregations] = useState<Aggregation[]>();
  const [isAdding, setAdding] = useState(false);
  const { add: addToast } = useGlobalToasts();
  const [displayName, setDisplayName] = useState<string>('');
  const [selectedAggregation, setSelectedAggregation] = useState<Aggregation | null>();
  const { isAuthorized } = useRoleAuth();

  const refreshAggregations = useCallback(() => {
    listAggregations(AggregationType.Customer)
      .then(({ data }) => setAggregations(data.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName))))
      .catch((e: ErrorEvent) => {
        addToast({
          text: `Could not list aggregations: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      });
  }, [addToast, listAggregations]);

  useEffect(() => {
    refreshAggregations();
  }, [refreshAggregations]);

  const newAggregationDialog = useDisclosure();

  const editDisplayNameDialog = useDisclosure();

  const handleEditAggregation = useCallback(
    (a: Aggregation) => {
      setDisplayName(a.DisplayName);
      setSelectedAggregation(a);
      editDisplayNameDialog.open();
    },
    [editDisplayNameDialog]
  );

  const handleAddAggregation = useCallback(
    async ({ name, displayName }) => {
      setAdding(true);
      try {
        await createAggregation({ Name: name, DisplayName: displayName, AggregationType: AggregationType.Customer });
        const { data } = await listAggregations(AggregationType.Customer);
        setAggregations(data);
        newAggregationDialog.close();
      } catch (e: unknown) {
        let errorMessage = e;
        if (isError(e)) {
          errorMessage = e.message;
        }
        addToast({
          text: `Could not create aggregation: ${errorMessage}`,
          variant: NotificationVariants.Negative,
        });
      }
      setAdding(false);
    },
    [addToast, newAggregationDialog, listAggregations, createAggregation]
  );

  const handleDeleteAggregation = useCallback(
    async (name: string) => {
      try {
        await deleteAggregation(name);
        const { data } = await listAggregations(AggregationType.Customer);
        setAggregations(data);
      } catch (e) {
        let errorMessage = e;
        if (isError(e)) {
          errorMessage = e.message;
        }
        addToast({
          text: `Could not delete aggregation: ${errorMessage}`,
          variant: NotificationVariants.Negative,
        });
      }
    },
    [addToast, listAggregations, deleteAggregation]
  );

  return (
    <Panel>
      <PanelHeader>
        <h2>Customer Aggregations</h2>
        <PanelActions>
          <Button
            startIcon={IconName.Plus}
            onClick={() => newAggregationDialog.open()}
            variant={ButtonVariants.Positive}
            disabled={!isAuthorized(ACTION.DEALER_TRADING)}
          >
            Add Aggregation
          </Button>
        </PanelActions>
      </PanelHeader>
      <PanelContent>
        {aggregations == null ? (
          <LoaderWrapper>
            <Loader size={LoaderSizes.SMALL} />
          </LoaderWrapper>
        ) : (
          <AccordionGroup>
            <AccordionTable columns={accordionTableColumns} stickyHeader={true}>
              {aggregations.map(aggregation => (
                <AggregationAccordion
                  key={aggregation.Name}
                  aggregation={aggregation}
                  onDeleteAggregation={handleDeleteAggregation}
                  onEditAggregation={handleEditAggregation}
                />
              ))}
            </AccordionTable>
          </AccordionGroup>
        )}
        <NewAggregationDialog
          {...newAggregationDialog}
          title="New Aggregation"
          onConfirm={handleAddAggregation}
          confirmLabel="Add New Aggregation"
          showClose={true}
          confirmDisabled={isAdding}
          confirmLoading={isAdding}
        />
        <Dialog
          {...editDisplayNameDialog}
          title="Edit Display Name"
          onConfirm={() =>
            updateAggregation({ ...selectedAggregation, DisplayName: displayName }).then(() => refreshAggregations())
          }
          confirmLabel="Confirm"
          showClose={true}
          width="350px"
          stretchButtons
        >
          <Box mb="spacingDefault" textAlign="left">
            <Text>
              Display Name of: <Text color="colorTextImportant">{selectedAggregation?.Name}</Text>
            </Text>
          </Box>
          <Input value={displayName} autoComplete="off" onChange={e => setDisplayName(e.target.value)} />
        </Dialog>
      </PanelContent>
    </Panel>
  );
}
