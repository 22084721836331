import { useHomeCurrencyRatesValue } from '@talos/kyoko';
import Big from 'big.js';
import type { Position } from 'containers/Portfolio/types';
import { useMemo } from 'react';

export const useSummarizedPositions = (positions: Position[]): { incoming: string; outgoing: string } => {
  const positionCurrencies = useMemo(() => {
    return positions.map(position => position.Currency);
  }, [positions]);

  const rates = useHomeCurrencyRatesValue(positionCurrencies);

  const { incoming, outgoing } = useMemo(() => {
    if (!rates) {
      return {
        incoming: '',
        outgoing: '',
      };
    }

    const ratesByPositionCurrency = new Map(rates.map(rate => [rate.Asset, rate]));

    let incoming = Big(0);
    let outgoing = Big(0);

    for (const position of positions) {
      const positionCurrencyRate = ratesByPositionCurrency.get(position.Currency);
      if (positionCurrencyRate == null || positionCurrencyRate.Rate == null || positionCurrencyRate.Rate === '') {
        continue;
      }

      const rate = Big(positionCurrencyRate.Rate);
      let homeCurrPosition = Big(position.Amount).times(rate);

      if (homeCurrPosition.gte(0)) {
        incoming = incoming.plus(homeCurrPosition);
      } else {
        homeCurrPosition = homeCurrPosition.times(-1); // flip sign
        outgoing = outgoing.plus(homeCurrPosition);
      }
    }

    return { incoming: incoming.toFixed(), outgoing: outgoing.toFixed() };
  }, [rates, positions]);

  return { incoming, outgoing };
};
