import type { ReactNode } from 'react';
import { AccordionContext } from '../AccordionContext';
import type { AccordionOutputs } from '../useAccordion';

export type AccordionRowProps = {
  children: ReactNode;
} & AccordionOutputs;

/**
 * This component is a sibling component to the standard Accordion, difference being that this is to be used as a row
 * within the AccordionTable.
 * @returns
 */
export const AccordionRow = ({ children, ...accordion }: AccordionRowProps) => {
  return <AccordionContext.Provider value={accordion}>{children}</AccordionContext.Provider>;
};
