import {
  FieldValidationLevel,
  SettleValueTypeEnum,
  toBigWithDefault,
  validatePrecision,
  type Currency,
  type FieldValidationResult,
  type Security,
} from '@talos/kyoko';
import type { Big } from 'big.js';

// https://talostrading.atlassian.net/browse/UI-4444
export const validateQuantity = (
  security: Security,
  quantity: Big,
  orderCurrency: string,
  currenciesBySymbol: Map<string, Currency>
): FieldValidationResult | null => {
  const orderCurrencyInfo = currenciesBySymbol.get(orderCurrency);
  const { MinimumSize, MinSizeIncrement } = security;
  const { MinIncrement } = orderCurrencyInfo || {};
  let minSize = MinimumSize;
  let minIncr = MinSizeIncrement;

  if (!orderCurrency) {
    // Contracts
    if (quantity.lt(MinimumSize)) {
      return {
        message: `Min contract is ${MinimumSize}`,
        level: FieldValidationLevel.Error,
      };
    }

    if (!validatePrecision(MinSizeIncrement, quantity)) {
      return {
        message: `Min increment is ${MinSizeIncrement}`,
        level: FieldValidationLevel.Error,
      };
    }
  } else {
    if (security?.SettleValueType === SettleValueTypeEnum.Inverted) {
      if (orderCurrency === security.BaseCurrency) {
        // Inverted, Base
        minSize = MinIncrement ?? '0.01';
        minIncr = MinIncrement ?? '0.01';
      } else {
        // Inverted, Quote
        minSize = toBigWithDefault(MinimumSize, 1)
          .mul(security?.NotionalMultiplier ?? 1)
          .toFixed();
        minIncr = toBigWithDefault(MinSizeIncrement, 1)
          .mul(security?.NotionalMultiplier ?? 1)
          .toFixed();
      }
    } else {
      if (orderCurrency === security.BaseCurrency) {
        // Regular, Base
        minSize = toBigWithDefault(MinimumSize, 1)
          .mul(security?.NotionalMultiplier ?? 1)
          .toFixed();
        minIncr = toBigWithDefault(MinSizeIncrement, 1)
          .mul(security?.NotionalMultiplier ?? 1)
          .toFixed();
      } else {
        // Regular, Quote
        minSize = MinIncrement ?? '0.01';
        minIncr = MinIncrement ?? '0.01';
      }
    }

    if (quantity.lt(minSize)) {
      return {
        message: `Min quantity is ${minSize}`,
        level: FieldValidationLevel.Error,
      };
    }

    if (!validatePrecision(minIncr, quantity)) {
      return {
        message: `Min increment is ${minIncr}`,
        level: FieldValidationLevel.Error,
      };
    }
  }

  return null;
};
