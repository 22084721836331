import { HStack, Icon, IconName, Text, VStack } from '@talos/kyoko';
import { isMarketDataEnabled, isSecurityStatusEnabled } from '../helpers';
import type { EnableDisableDialogType, MarketSecurityStatusLocal } from '../types';

interface SelectedSecurityMastersListProps {
  selectedSecurities: MarketSecurityStatusLocal[];
  type: EnableDisableDialogType;
}

export const SelectedSecurityMasterList = ({ selectedSecurities, type }: SelectedSecurityMastersListProps) => {
  if (!selectedSecurities?.length) {
    return null;
  }

  return (
    <>
      {selectedSecurities.map((selectedSecurityMaster, idx, arr) => {
        const id = `${selectedSecurityMaster.MarketAccount} - ${selectedSecurityMaster.Symbol}`;
        if (idx < 5) {
          return (
            <HStack key={id} gap="spacingDefault">
              <Text>{id}</Text>
              <Icon icon={IconName.ArrowRight} />
              <Text fontWeight="bold">
                {(
                  type === 'Symbol'
                    ? isSecurityStatusEnabled(selectedSecurityMaster)
                    : isMarketDataEnabled(selectedSecurityMaster)
                )
                  ? 'Disable'
                  : 'Enable'}
              </Text>
            </HStack>
          );
        }
        if (idx === arr.length - 1) {
          return (
            <VStack alignItems="center" justifyContent="center" key={selectedSecurityMaster.ID}>
              <h3>+ {arr.length - 5} Statuses</h3>
            </VStack>
          );
        }
        return null;
      })}
    </>
  );
};
