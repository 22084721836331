import { invariant } from '@epic-web/invariant';
import { isEqual } from 'lodash';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getPortfolioLayoutOptions } from './layoutConfiguration/getPortfolioLayoutOptions';
import type { LayoutOptionView } from './portfolioDashboard.types';
import type { PortfolioViewType } from './portfolioDashboardLayouts';

/** layoutOptions - Singleton object to store the layout options, so that results are memoized, but also allows
 * results to be mocked in tests.
 */
let layoutOptions: LayoutOptionView<PortfolioViewType>[];
export function usePortfolioRouting(): {
  selectedView: LayoutOptionView<PortfolioViewType>;
  selectedTab: LayoutOptionView<PortfolioViewType>['tabs'][number];
  selectedTabIndex: number;
} {
  layoutOptions = layoutOptions ?? getPortfolioLayoutOptions();
  const location = useLocation();
  const tabRoute = location.pathname.split('/').pop();
  const viewRoute = location.pathname.split('/')[2];
  const selectedView = layoutOptions.find(option => option.route === `/${viewRoute}`);
  invariant(selectedView, `Selected view layout for view ${viewRoute} should be defined.`);
  const detectedTabIndex = selectedView.tabs.findIndex(tab => tab.route === `/${tabRoute ?? ''}`);
  const selectedTabIndex = detectedTabIndex >= 0 ? detectedTabIndex : 0;
  const lastSelectedTab = useRef<(typeof selectedView.tabs)[number]>(selectedView.tabs[selectedTabIndex]);
  const selectedTab = useMemo(() => {
    const nextTab = selectedView.tabs[selectedTabIndex];
    if (!isEqual(lastSelectedTab.current, nextTab)) {
      lastSelectedTab.current = nextTab;
      return nextTab;
    }
    return lastSelectedTab.current;
  }, [selectedTabIndex, selectedView.tabs]);
  return { selectedView, selectedTab, selectedTabIndex };
}
