import type { PropsWithChildren } from 'react';

import { HStack, Text, type BoxProps } from '@talos/kyoko';

export const DetailsRow = ({
  children,
  color = 'colorTextAttention',
  title,
  whiteSpace,
}: PropsWithChildren<Pick<BoxProps, 'color' | 'whiteSpace'> & { title: string }>) => {
  return (
    <HStack justifyContent="space-between" w="100%">
      <Text color={color} whiteSpace={whiteSpace}>
        {title}
      </Text>
      {children}
    </HStack>
  );
};
