import {
  Delete,
  Get,
  Post,
  isUserUpsertAllowed,
  request,
  useUserContext,
  type ModeEnum,
  type User,
} from '@talos/kyoko';
import { pickBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useUser } from '../../../hooks';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../providers';
import { DEFAULT_WHITELISTED_ROLES } from '../../../tokens/roles';
import type { EditUserForm, NewUserForm } from './types';
import { getEditableRoles, getViewableRoles } from './utils';

export function useUserSettings() {
  const user = useUser();
  const { getConfig } = useOrgConfiguration();
  const editableRoles = useMemo(
    () =>
      getEditableRoles(
        user,
        getConfig(OrgConfigurationKey.AdminUserAdminCanAddRoles, DEFAULT_WHITELISTED_ROLES.join(','))
      ),
    [user, getConfig]
  );
  const viewableRoles = useMemo(() => getViewableRoles(user), [user]);
  const { orgApiEndpoint } = useUserContext();

  const createUser = useCallback(
    (userForm: NewUserForm) => {
      const { ok, message } = isUserUpsertAllowed(userForm);
      if (!ok) {
        return Promise.reject({ message });
      }

      return request<User>('POST', `${orgApiEndpoint}/users`, {
        Name: userForm.Name,
        Email: userForm.Email,
        Roles: userForm.Roles,
      });
    },
    [orgApiEndpoint]
  );

  const updateUser = useCallback(
    (userForm: EditUserForm & { Comment?: string }) => {
      const { ok, message } = isUserUpsertAllowed(userForm);
      if (!ok) {
        return Promise.reject({ message });
      }

      return request<User>('PUT', `${orgApiEndpoint}/users/${userForm.ID}`, {
        Name: userForm.Name,
        Email: userForm.Email,
        Roles: userForm.Roles,
        Comment: userForm.Comment,
      });
    },
    [orgApiEndpoint]
  );

  const deleteUser = useCallback((user: User) => Delete(orgApiEndpoint, `/users/${user.ID}`), [orgApiEndpoint]);

  const deleteAppConfig = useCallback(
    (user: User) => Delete(orgApiEndpoint, `/users/${user.ID}/configs`),
    [orgApiEndpoint]
  );

  const createResetPasswordUrl = useCallback(
    (user: User) => Post(orgApiEndpoint, `/users/${user.ID}/reset-password`),
    [orgApiEndpoint]
  );

  const sendResetPasswordEmail = useCallback(
    (user: User) => Post(orgApiEndpoint, `/users/${user.ID}/send-reset-password-email`),
    [orgApiEndpoint]
  );

  const logoutUser = useCallback(
    (user: User) => Delete(orgApiEndpoint, `/users/${user.ID}/sessions`),
    [orgApiEndpoint]
  );

  const getUserHistory = useCallback(
    (user: User) => Get(orgApiEndpoint, `/users/${user.ID}/history`),
    [orgApiEndpoint]
  );

  const reEnableUser = useCallback(
    (user: User) =>
      request('POST', `${orgApiEndpoint}/users`, {
        Name: user.Name,
        Email: user.Email,
        Roles: user.Roles ?? [],
      }),
    [orgApiEndpoint]
  );

  const getMarketSessionLimit = useCallback(
    async (user: User) =>
      Get(orgApiEndpoint, `/admin/market-data-limit`).then(result => {
        return result.data.filter(row => row.User === user.Name);
      }),
    [orgApiEndpoint]
  );

  const addMarketSessionLimit = useCallback(
    async (user: User, market: string, limit: number, mode: ModeEnum) =>
      request('POST', `${orgApiEndpoint}/admin/market-data-limit`, {
        Market: market,
        User: user.Name,
        IsApi: false,
        Mode: mode,
        ViewLimit: limit,
      }),
    [orgApiEndpoint]
  );

  const updateMarketSessionLimit = useCallback(
    async (limitID: string, limit: number, mode: ModeEnum) =>
      request('PUT', `${orgApiEndpoint}/admin/market-data-limit/${limitID}`, {
        ViewLimit: limit,
        IsApi: false,
        Mode: mode,
      }),
    [orgApiEndpoint]
  );

  const removeMarketSessionLimit = useCallback(
    async (limitID: string) => Delete(orgApiEndpoint, `/admin/market-data-limit/${limitID}`),
    [orgApiEndpoint]
  );

  const listAllowedUserCredentialsKeys = useCallback(
    () => Get(orgApiEndpoint, `/user-credentials/keys`),
    [orgApiEndpoint]
  );

  const listUserCredentials = useCallback(
    (userID: string, market: string, credentialID: string) =>
      Get(orgApiEndpoint, `/user-credentials?userID=${userID}&market=${market}&credentialID=${credentialID}`),
    [orgApiEndpoint]
  );

  const saveUserCredentials = useCallback(
    (userName: string, market: string, credentialID: string, orgID: number | undefined, data: Map<string, string>) =>
      request('PUT', `${orgApiEndpoint}/user-credentials`, {
        User: userName,
        Market: market,
        CredentialID: parseInt(credentialID),
        OrgID: orgID,
        Data: pickBy(Object.fromEntries(data), v => v !== ''), // save only non-empty strings
      }),
    [orgApiEndpoint]
  );

  return {
    editableRoles,
    viewableRoles,
    createUser,
    updateUser,
    deleteUser,
    deleteAppConfig,
    createResetPasswordUrl,
    sendResetPasswordEmail,
    logoutUser,
    getUserHistory,
    reEnableUser,
    getMarketSessionLimit,
    addMarketSessionLimit,
    updateMarketSessionLimit,
    removeMarketSessionLimit,
    listAllowedUserCredentialsKeys,
    listUserCredentials,
    saveUserCredentials,
  };
}
