import { FormGroup, Icon } from '@talos/kyoko';
import styled from 'styled-components';

export const TransfersWrapper = styled.div`
  background: ${({ theme }) => theme.backgroundContent};
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const Content = styled.div`
  flex: 1 1 0;
  padding: ${({ theme }) => theme.spacingBig}px;
  max-width: 500px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacingMedium}px;

  gap: ${({ theme }) => theme.spacingMedium}px;

  ${FormGroup} {
    flex: 1;
    margin-bottom: ${({ theme }) => theme.baseSize * theme.fontSizeFormHelp + theme.spacingDefault}px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacingLarge}px;
`;

export const InfoWrapper = styled(Row)`
  p {
    margin-top: ${({ theme }) => theme.spacingTiny}px;
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DialogWrapper = styled.div`
  h3 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacingDefault}px;
  }
`;

export const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacingTiny}px;
  ${Icon} {
    margin: 0 ${({ theme }) => theme.spacingSmall}px;
  }
`;
