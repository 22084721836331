import type { ResolutionEnumNoPrefix } from '@talos/kyoko';

const MAX_DATA_POINTS = 600;

/**
 * This utility automatically figures out what ResolutionEnumNoPrefix to use given a date range. We only want to show
 * less or equal to `MAX_DATA_POINTS` in MarketExecutionChart at any given time.
 * @param startTime
 * @param endTime
 */
export function getAnalyticsResolution(startTime: Date, endTime: Date): ResolutionEnumNoPrefix {
  const span = endTime.getTime() - startTime.getTime();

  // Guess a fitting resolution. Add a couple seconds to each interval for leniency.
  const numSeconds = span / 1000;
  if (numSeconds < MAX_DATA_POINTS) {
    return '1S';
  }

  const numMinutes = numSeconds / 60;
  if (numMinutes < MAX_DATA_POINTS) {
    return '1M';
  }

  const numHours = numMinutes / 60;
  if (numHours < MAX_DATA_POINTS) {
    return '1H';
  }

  return '1D';
}
