import { Put, logger } from '@talos/kyoko';
import type { AppConfigState } from './AppConfigProvider.types';

export function putAppConfig(appConfig: AppConfigState) {
  Put(import.meta.env.VITE_AVA_API_ENDPOINT, `/user/app_config`, {
    appConfig: JSON.stringify(appConfig),
  }).catch((e: ErrorEvent) => {
    logger.error(new Error(`Failed to update user app_config`), {
      extra: {
        errorMessage: e.message,
        appConfigLength: JSON.stringify(appConfig).length,
      },
    });
    console.error(e);
  });
}
