import { useEffect, useLayoutEffect, useRef } from 'react';

/**
 * A simple hook which just converts the provided value into a react Ref, and then ensures that the ref.current stays up to date
 * through a useEffect on value itself.
 *
 * IMPORTANT: If you're using this with a callback method, do NOT send that result outside the component, as state may not be updated yet
 */
export const useSyncedRef = <T,>(value: T) => {
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);
  return valueRef;
};

/**
 * A simple hook which just converts the provided value into a react Ref, and then ensures that the ref.current stays up to date
 * through a useLayoutEffect on value itself.
 *
 * IMPORTANT: If you're using this with a callback method, do NOT send that result outside the component, as state may not be updated yet
 */
export const useLayoutSyncedRef = <T,>(value: T) => {
  const valueRef = useRef(value);
  useLayoutEffect(() => {
    valueRef.current = value;
  }, [value]);
  return valueRef;
};
