import { BALANCES_V2_BLOTTER_PREFIX } from 'containers/Blotters/BalancesV2/tokens';
import { CREDIT_BLOTTER_PREFIX } from 'containers/Blotters/Credit/tokens';
import { ORDERS_BLOTTER_PREFIX } from 'containers/Blotters/Orders/tokens';
import { POSITIONS_V3_BLOTTER_PREFIX } from 'containers/Blotters/PositionsV3/tokens';
import { RECON_MISMATCHES_BLOTTER_PREFIX } from 'containers/Blotters/Reconciliation/tokens';
import { TRADES_BLOTTER_PREFIX } from 'containers/Blotters/Trades/tokens';
import { TRANSFERS_BLOTTER_PREFIX } from 'containers/Blotters/Transfers/tokens';
import { MONITORING_BLOTTER_PREFIX } from 'containers/Monitoring/Blotters/types';
import { PERFORMANCE_BLOTTER_PREFIX, PERFORMANCE_BLOTTER_TABS_ID } from 'containers/Portfolio/Performance/tokens';
import { TRADE_SETTLEMENT_BLOTTER_PREFIX } from 'containers/Portfolio/Settlement/CustomerSettlementMonitoring/tokens';
import {
  TREASURY_BLOTTER_PREFIX,
  TREASURY_MANAGEMENT_BLOTTER_TABS_ID,
} from 'containers/Portfolio/TreasuryManagement/tokens';
import { get } from 'lodash';
import {
  BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS,
  BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS,
  BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS,
  BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS,
  BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS,
  BLOTTER_ID_TRADES_ORDER_DETAILS,
} from 'tokens/blotters';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';

interface PruningConfig {
  blotterTabPrefix: string;
  tabPrefix: string;
}

// For each blotter + tab combo we want to prune, we need a config supplied so we know where to look
const PRUNING_CONFIGS: PruningConfig[] = [
  {
    blotterTabPrefix: CREDIT_BLOTTER_PREFIX,
    tabPrefix: CREDIT_BLOTTER_PREFIX,
  },
  {
    blotterTabPrefix: ORDERS_BLOTTER_PREFIX,
    tabPrefix: ORDERS_BLOTTER_PREFIX,
  },
  {
    blotterTabPrefix: BALANCES_V2_BLOTTER_PREFIX,
    tabPrefix: BALANCES_V2_BLOTTER_PREFIX,
  },
  {
    blotterTabPrefix: POSITIONS_V3_BLOTTER_PREFIX,
    tabPrefix: POSITIONS_V3_BLOTTER_PREFIX,
  },
  {
    blotterTabPrefix: TRADES_BLOTTER_PREFIX,
    tabPrefix: TRADES_BLOTTER_PREFIX,
  },
  {
    blotterTabPrefix: TRANSFERS_BLOTTER_PREFIX,
    tabPrefix: TRANSFERS_BLOTTER_PREFIX,
  },
  {
    blotterTabPrefix: RECON_MISMATCHES_BLOTTER_PREFIX,
    tabPrefix: RECON_MISMATCHES_BLOTTER_PREFIX,
  },
  {
    blotterTabPrefix: PERFORMANCE_BLOTTER_PREFIX,
    tabPrefix: PERFORMANCE_BLOTTER_TABS_ID,
  },
  {
    blotterTabPrefix: TREASURY_BLOTTER_PREFIX,
    tabPrefix: TREASURY_MANAGEMENT_BLOTTER_TABS_ID,
  },
  {
    blotterTabPrefix: MONITORING_BLOTTER_PREFIX,
    tabPrefix: MONITORING_BLOTTER_PREFIX,
  },
  {
    blotterTabPrefix: TRADE_SETTLEMENT_BLOTTER_PREFIX,
    tabPrefix: TRADE_SETTLEMENT_BLOTTER_PREFIX,
  },
];

const ORDER_DETAILS_CONFIGS: string[] = [
  BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS,
  BLOTTER_ID_TRADES_ORDER_DETAILS,
  BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS,
  BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS,
  BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS,
  BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS,
];

/**
 * This function prunes PersistedBlotterTable state belonging to no longer existent Order Details Market Tabs.
 *
 * Given an array of configs, we go through all of the Market tabs, specifically checking for OrderDetails
 * Order details tab contains orderID property, which means that if we cannot find it means it has been closed and we can safely prune it
 */
export function pruneOrderDetailsBlotterState(config: any) {
  const allBlotterTabs: object | undefined = get(config, 'blotters2');
  if (!allBlotterTabs) {
    return;
  }

  for (const blotterTabPrefix of ORDER_DETAILS_CONFIGS) {
    const blotterTabsForPrefixEntries = [
      ...Object.entries(allBlotterTabs).filter(([key, value]) => key.startsWith(blotterTabPrefix)),
    ];

    const marketTabs = get(config, `marketTabs`) || [];
    const orderDetailsTabs = marketTabs.filter(tab => tab.orderID != null);

    for (const [key] of blotterTabsForPrefixEntries) {
      const splitString = key.split('/');
      const orderID = splitString[1];

      if (!orderID) {
        continue;
      }

      if (orderDetailsTabs.some(tab => tab.orderID === orderID)) {
        // We exist! Do not prune.
        continue;
      }

      // Else we do not exist and we should delete ourselves
      delete allBlotterTabs[key];
    }
  }
}

/**
 * This function prunes PersistedBlotterTable state belonging to no longer existent tabs.
 *
 * Given an array of configs which define how blotter prefixes are related to tab prefixes (IDs), we go through all of these
 * pieces of blotter state and check if their equivalent tab is still present in the tabs state. If it is not, then remove
 * the persisted blotter state since it can never get reached again.
 */
export function prunePersistedBlotterTableState(config: any) {
  const allBlotterTabs: object | undefined = get(config, 'blotters2');
  if (!allBlotterTabs) {
    return;
  }

  for (const { blotterTabPrefix, tabPrefix } of PRUNING_CONFIGS) {
    const blotterTabsForPrefixEntries = [
      ...Object.entries(allBlotterTabs).filter(([key, value]) => key.startsWith(blotterTabPrefix)),
    ];

    for (const [key] of blotterTabsForPrefixEntries) {
      const splitString = key.split('/');
      const uuidIndex = splitString.findIndex(str => isUuid(str));

      // We only prune on blotterTab keys that contain a uuid. These have been generated by the user creating new tabs themselves.
      if (uuidIndex === -1) {
        continue;
      }

      // See if we can find ourselves in the tabs.<tabPath>.find(tab => tab.id === uuid)
      const tabsItems = get(config, `tabs.${tabPrefix}.items`); // try to grab the items array

      const uuid = splitString[uuidIndex];
      if (tabsItems != null && tabsItems instanceof Array && tabsItems.some(item => item.id === uuid)) {
        // We exist! Do not prune.
        continue;
      }

      // Else we do not exist and we should delete ourselves
      delete allBlotterTabs[key];
    }
  }
}

const supportedUuidVersions = [1, 4];
function isUuid(str: string): boolean {
  return uuidValidate(str) && supportedUuidVersions.some(v => uuidVersion(str) === v);
}
