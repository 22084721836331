import { HomeCurrencyContext } from '@talos/kyoko';
import { useMemo } from 'react';
import { useDisplaySettings } from './AppConfigProvider';

export function HomeCurrencyContextProvider({ children }) {
  const { homeCurrency } = useDisplaySettings();

  const value = useMemo(
    () => ({
      homeCurrency,
    }),
    [homeCurrency]
  );

  return <HomeCurrencyContext.Provider value={value}>{children}</HomeCurrencyContext.Provider>;
}
