import Big, { type BigSource } from 'big.js';
import { compact, sortBy, uniq } from 'lodash';
import { memo, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useCurrenciesContext, useSecuritiesContext } from '../../contexts';
import { useHomeCurrencyRatesValue } from '../../hooks';
import { Order, Trade, WarningSeverity, isCurrencyConversionRatePopulated } from '../../types';
import type { Currency } from '../../types/Currency';
import type { Security } from '../../types/Security';
import { OrdTypeEnum, ProductTypeEnum, SettleValueTypeEnum, SideEnum } from '../../types/types';
import { isCounterCurrency, toBigWithDefault } from '../../utils';
import { HStack, VStack, type BoxProps } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { WarningSeverityIcon } from '../Icons';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { Buy, Sell, SummaryLineWrapper, SummarySection, SymbolSummary, Total } from './styles';
import type { SummaryLineEntity } from './types';

export { SummaryLineWrapper, Total } from './styles';
export { getOrderAmount, getOrderQuantity, getTradeAmount, getTradeQuantity } from './utils';

interface SummaryLineProps<T extends SummaryLineEntity> extends BoxProps {
  rows: T[];
  homeCurrency: string;
  /** Given an entity T, return the Quantity on the entity */
  getQuantity: (entity: T) => BigSource;
  /** Given an entity T, return the Amount on the entity */
  getAmount: (entity: T) => BigSource;
}

const SummaryLineInner = <T extends SummaryLineEntity>({
  rows,
  homeCurrency,
  getQuantity,
  getAmount,
  ...props
}: SummaryLineProps<T>) => {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const homeCurrencyInfo = currenciesBySymbol.get(homeCurrency);
  const theme = useTheme();

  // Currencies and Symbols (for contracts) that we want to get rates for
  const { ratesToRequest, rowsNeedingRates } = useMemo(() => {
    const _rowsNeedingRates: T[] = rows.filter(row => {
      const rateImplicit = homeCurrencyInfo?.Symbol === row.Currency || homeCurrencyInfo?.Symbol === row.AmountCurrency;
      const isInContracts = !row.Currency;

      // If the rate is not implicit, or the entity is denominated in contracts, the row needs a rate in order to calc a HomeCurrency total.
      return !rateImplicit || isInContracts;
    });

    const quoteCurrenciesAndContractSymbolsNeedingRates = _rowsNeedingRates.map(row => {
      const security = securitiesBySymbol.get(row.Symbol);
      if (!security) {
        return undefined;
      }

      const isInContracts = !row.Currency;
      return isInContracts ? security.Symbol : security.QuoteCurrency;
    });

    // The ratesToRequest data set is passed directly to the HomeCurrencyRates subscription hook.
    // The symbolsNeedingRates data set tells us which symbols these rate requests represent. Remember that, for example, for a BTCUSDT Perp
    // you dont necessarily request a rate for the symbol "BTCUSDT Perp X", instead you might just request for USDT-USD, since that's what the Amount is specified in.
    return {
      ratesToRequest: compact(uniq(quoteCurrenciesAndContractSymbolsNeedingRates)),
      symbolsNeedingRates: _rowsNeedingRates.map(row => row.Symbol),
      rowsNeedingRates: _rowsNeedingRates,
    };
  }, [rows, homeCurrencyInfo, securitiesBySymbol]);

  const ratesArray = useHomeCurrencyRatesValue(ratesToRequest);
  const ratesByAsset: Map<string, string> | undefined = useMemo(() => {
    if (!ratesArray) {
      return undefined;
    }

    return new Map<string, string>(
      ratesArray.filter(isCurrencyConversionRatePopulated).map(rate => [rate.Asset, rate.Rate])
    );
  }, [ratesArray]);

  const offlineRatesReceived = useMemo(() => {
    if (!ratesArray) {
      return [];
    }
    return ratesArray.filter(rate => rate.Rate == null);
  }, [ratesArray]);

  const { summary, bought, sold } = useMemo(() => {
    if (!rows || !ratesByAsset) {
      return {
        bought: undefined,
        sold: undefined,
        summary: undefined,
      };
    }

    // Summarize rows into bought and sold, grouped by symbols
    const newSummary = rows.reduce(
      (result, row) => {
        const security = securitiesBySymbol.get(row.Symbol);
        if (!security) {
          return result;
        }

        const bought = result.bought;
        const sold = result.sold;
        const qty = toBigWithDefault(getQuantity(row), 0);
        const amt = toBigWithDefault(getAmount(row), 0);

        let equivalentAmount: Big = Big(0);
        // If the base/quote currency is the same as our home currency, we take a shortcut and just grab the qty/amt as is since we know its in HomeCurrency denomination
        if (row.Currency === homeCurrencyInfo?.Symbol) {
          equivalentAmount = qty;
        } else if (row.AmountCurrency === homeCurrencyInfo?.Symbol) {
          equivalentAmount = amt;
        } else {
          // we need to cross a rate to get a home currency amount
          // for contract traded instruments use rate for Symbol instead of QuoteCurrency
          // we default the rate to 0 if it is not found. This will cause the equivalentAmount to be 0, which when added
          // to the reducer's total will have no impact (as in, it is effectively not applied to the total)
          const quantityIsInContracts = !row.Currency;
          if (quantityIsInContracts) {
            const rate = toBigWithDefault(ratesByAsset.get(security.Symbol), 0);
            equivalentAmount = Big(qty).times(rate);
          } else {
            const rate = toBigWithDefault(ratesByAsset.get(security.QuoteCurrency), 0);
            const isCounter = isCounterCurrency(row.Currency, security);
            equivalentAmount = (isCounter ? qty : amt).times(rate);
          }
        }

        addToResult(row.Side === SideEnum.Sell ? sold : bought, row, currenciesBySymbol, securitiesBySymbol, qty, amt);

        return {
          total: equivalentAmount.add(result.total).toFixed(),
          bought,
          sold,
        };
      },
      {
        total: '0',
        bought: new Map<string, SummaryResult>(),
        sold: new Map<string, SummaryResult>(),
      }
    );
    const newBought = sortBy(
      Array.from(newSummary.bought.entries()).filter(([_, value]) => Big(value.qty).gt(0)),
      ([key]) => key
    );
    const newSold = sortBy(
      Array.from(newSummary.sold.entries()).filter(([_, value]) => Big(value.qty).gt(0)),
      ([key]) => key
    );

    return { summary: newSummary, bought: newBought, sold: newSold };
  }, [rows, ratesByAsset, currenciesBySymbol, securitiesBySymbol, getQuantity, getAmount, homeCurrencyInfo]);

  if (!summary || !bought || !sold) {
    return null;
  }

  const showWarningIcon = rowsNeedingRates.length > 0 || offlineRatesReceived.length > 0;
  const background = theme.colors.gray['010'];

  return (
    <SummaryLineWrapper {...props} background={background}>
      <SummarySection>
        <HStack w="100%" gap="spacingSmall">
          {showWarningIcon && (
            <Tooltip
              tooltip={
                <VStack gap="spacingDefault">
                  {rowsNeedingRates.length > 0 && (
                    <Text>
                      Live rate(s) are being used to calculate the Total amount for {rowsNeedingRates.length}{' '}
                      selections.
                    </Text>
                  )}
                  {offlineRatesReceived.length > 0 && (
                    <Text>
                      {offlineRatesReceived.length} offline rate(s) received. Due to this, the calculated Total amount
                      will exclude some selection(s) entirely.
                    </Text>
                  )}
                </VStack>
              }
            >
              <WarningSeverityIcon severity={WarningSeverity.LOW} size="fontSizeMd" />
            </Tooltip>
          )}
          <Total>Total</Total>
          <InlineFormattedNumber
            background={background}
            number={summary.total}
            increment={homeCurrencyInfo?.DefaultIncrement}
            currency={homeCurrency}
          />
        </HStack>
      </SummarySection>
      {bought.length > 0 && (
        <SummarySection>
          <Buy>Bought</Buy>
          {bought.map(([symbol, buy]) => (
            <SymbolSummary key={symbol}>
              <InlineFormattedNumber
                background={background}
                number={buy.qty}
                increment={buy.baseIncrement}
                currency={buy.baseCurrency}
              />{' '}
              @{' '}
              <InlineFormattedNumber
                background={background}
                number={toBigWithDefault(buy.amt, 0)
                  .div(buy.qty || '1')
                  .toFixed()}
                increment={buy.quoteIncrement}
                currency={buy.quoteCurrency}
              />
              {buy.productType !== ProductTypeEnum.Spot && <> ({buy.securitySymbol})</>}
            </SymbolSummary>
          ))}
        </SummarySection>
      )}
      {sold.length > 0 && (
        <SummarySection>
          <Sell>Sold</Sell>
          {sold.map(([symbol, sell]) => (
            <SymbolSummary key={symbol}>
              <InlineFormattedNumber
                background={background}
                number={sell.qty}
                increment={sell.baseIncrement}
                currency={sell.baseCurrency}
              />{' '}
              @{' '}
              <InlineFormattedNumber
                background={background}
                number={toBigWithDefault(sell.amt, 0)
                  .div(sell.qty || '1')
                  .toFixed()}
                increment={sell.quoteIncrement}
                currency={sell.quoteCurrency}
              />
              {sell.productType !== ProductTypeEnum.Spot && <> ({sell.securitySymbol})</>}
            </SymbolSummary>
          ))}
        </SummarySection>
      )}
    </SummaryLineWrapper>
  );
};

// memo() and generics dont work well together, so we do this for a few of our components.
// Essentially adding the memo but casting the type of the memoized component as if the memo was never there
export const SummaryLine = memo(SummaryLineInner) as typeof SummaryLineInner;

interface SummaryResult {
  qty: string;
  amt: string;
  baseIncrement: string;
  quoteIncrement: string;
  baseCurrency: string;
  quoteCurrency: string;
  productType: ProductTypeEnum;
  securitySymbol: string;
}

function addToResult<T extends SummaryLineEntity>(
  result: Map<string, SummaryResult>,
  row: T,
  currenciesBySymbol: Map<string, Currency>,
  securities: Map<string, Security>,
  rowQty: Big,
  rowAmt: Big
) {
  const security = securities.get(row.Symbol);
  if (!security) {
    return;
  }

  // Add Qty and Amt to previous result, based on symbol
  const prev = result.get(getResultTransactionKey(row)) || { qty: 0, amt: 0 };
  let qty = Big(0);
  let amt = Big(0);

  const isCounter = isCounterCurrency(row.Currency, security);
  const isQuantityInContracts = !row.Currency;
  if (isQuantityInContracts) {
    qty = Big(prev.qty).add(rowQty);

    // For Inverted SettleValueType, the amount of a BTCUSD perp for example is in BTC, not USD.
    // This means we cannot calculate the price from the amount, we instead have to resort to the AvgPx.
    if (security.SettleValueType === SettleValueTypeEnum.Inverted) {
      let avgPx = Big(0);
      if (row instanceof Order) {
        avgPx = toBigWithDefault(row.OrdType === OrdTypeEnum.LimitAllIn ? row.AvgPxAllIn : row.AvgPx, 0);
      } else if (row instanceof Trade) {
        avgPx = toBigWithDefault(row.Price, 0);
      }
      amt = avgPx.times(rowQty);
    } else {
      // SettleValueType Regular
      // In this case we are able to derive the price through the Amount paid
      amt = Big(prev.amt).add(Big(rowAmt).div(security.NotionalMultiplier || 1));
    }
  } else {
    qty = Big(prev.qty).add(isCounter ? rowAmt : rowQty);
    amt = Big(prev.amt).add(isCounter ? rowQty : rowAmt);
  }

  // Get correct baseCurrency and quoteCurrency for counter currency orders and derivatives
  const baseCurrency = isCounter ? row.AmountCurrency : row.Currency;
  const quoteCurrency = isCounter ? row.Currency : security.QuoteCurrency;
  const baseCurrencyInfo = currenciesBySymbol.get(baseCurrency || '');
  const quoteCurrencyInfo = currenciesBySymbol.get(quoteCurrency || '');

  const summaryResult = {
    qty: qty.toFixed(),
    amt: amt.toFixed(),
    baseIncrement: Math.min(
      parseFloat(baseCurrencyInfo?.DefaultIncrement ?? '0'),
      parseFloat(security?.DefaultSizeIncrement ?? 0)
    ).toString(),
    quoteIncrement: Math.min(
      parseFloat(quoteCurrencyInfo?.DefaultIncrement ?? '0'),
      parseFloat(security?.DefaultPriceIncrement ?? 0)
    ).toString(),
    baseCurrency: baseCurrency || '',
    quoteCurrency: quoteCurrency || '',

    // Include symbol and productType for derivatives
    productType: security?.ProductType,
    securitySymbol: security?.DisplaySymbol ?? row.Symbol,
  };

  result.set(getResultTransactionKey(row), summaryResult);
}

/**
 * Builds the key used to collect and combine results of entities (trades and orders)
 *
 * The key is not Symbol, Currency and AmountCurrency because we have to differentiate between counter currency orders
 * and orders placed in contracts etc, the symbol on its own isnt enough. If we only had symbol as the key, we could be combining a perp
 * order placed in USD with a perp order placed in contracts without realizing.
 */
function getResultTransactionKey<T extends SummaryLineEntity>(row: T) {
  return `${row.Symbol}-${row.Currency}-${row.AmountCurrency}`;
}
