import { SideEnum } from '@talos/kyoko';
import type { CustomerTradeForm } from './types';

export const initialState: CustomerTradeForm = {
  counterparty: '',
  symbol: '',
  side: SideEnum.Buy,
  currency: '',
  quantity: '',
  price: '',
  amount: '',
  amountCurrency: '',
  salesCommission: '',
  clTradeID: '',
  tradeID: '',
  marketAccount: '',
  comments: '',
};
