import {
  ACTION,
  HedgeControlStatusEnum,
  IconName,
  IndicatorBadgeVariants,
  MarketAccountStatusEnum,
  MixpanelEvent,
  MixpanelEventSource,
  ModeEnum,
  useDisclosure,
  useMarketAccountsContext,
  useMixpanel,
  useSyncedRef,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFeatureFlag, useRoleAuth } from '../../../../hooks';
import { OrgConfigurationKey, useOrgConfiguration, usePricingRules } from '../../../../providers';
import { useHedgePositionStatus } from '../../../../providers/HedgePositionStatusProvider';

import { useHedgeRuleCommandMutation } from '../../../Trading/Markets/PositionAutoHedgingRules/queries';
import { useAllTradableMarketAccountNames } from '../useAllTradableMarketAccountNames';
import type { TradingControlsDialogInfo, TradingControlsDialogProps, TradingControlsForm } from './types';

export function useTradingControlsDialog(): TradingControlsDialogProps {
  const { isAuthorized } = useRoleAuth();
  const { enableTradingControls, enableAutoHedging } = useFeatureFlag();
  const { getConfig, disableTrading, enableTrading } = useOrgConfiguration();
  const dialog = useDisclosure();
  const { globalDefault, isGlobalDefaultLoading, updatePricingRule } = usePricingRules();
  const { globalHedgeRule } = useHedgePositionStatus();
  const sendHedgeCommandMutation = useHedgeRuleCommandMutation();
  const allTradableMarketAccountNames = useAllTradableMarketAccountNames();
  const { marketAccountsByName, marketAccountDisplayNameByName } = useMarketAccountsContext();
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);
  const mixpanel = useMixpanel(MixpanelEventSource.TradingControls);

  // Show certain sections based on feature flags and/or rights
  const showPositionAutoHedgingInfo = enableAutoHedging;
  const showCustomerTradingInfo = isAuthorized(ACTION.EDIT_DEALER_TRADING);
  const showDdhInfo = false; // @todo: Enabled when ready based on feature flag or rights

  // Show the dialog only when feature is enabled and current user is allowed to view it
  const enabled = enableTradingControls && isAuthorized(ACTION.VIEW_TRADING_CONTROLS_DIALOG);

  // Global Trading is enabled
  const tradingEnabled = getConfig(OrgConfigurationKey.TradingDisableAll, '0') !== '1';

  // Customer Trading is enabled
  const customerTradingEnabled = globalDefault?.Mode === ModeEnum.Enabled;

  // Position Auto-Hedging is enabled
  const positionAutoHedgingEnabled =
    globalHedgeRule !== undefined && globalHedgeRule.HedgeControlStatus !== HedgeControlStatusEnum.Disabled;

  // @todo: Get current value for DDH enabled
  const ddhEnabled = true;

  // When still loading, show loading spinner and make Trading Controls button disabled
  const loading =
    (customerTradingEnabled && isGlobalDefaultLoading) || (positionAutoHedgingEnabled && !globalHedgeRule);

  const allIsEnabled = tradingEnabled && customerTradingEnabled && positionAutoHedgingEnabled && ddhEnabled;

  // Information for disabled Market Accounts
  const disabledMarketAccounts = useMemo(() => {
    return compact(
      allTradableMarketAccountNames
        .filter(
          marketAccount =>
            marketAccountsByNameRef.current.get(marketAccount)?.Status === MarketAccountStatusEnum.Disabled
        )
        .sort()
    );
  }, [allTradableMarketAccountNames, marketAccountsByNameRef]);

  const updateControls = useCallback(
    (initialForm: TradingControlsForm, form: TradingControlsForm) => {
      const promises: Promise<any>[] = [];
      // Global Trading
      if (initialForm.tradingEnabled !== form.tradingEnabled) {
        promises.push(form.tradingEnabled ? enableTrading() : disableTrading());
        mixpanel.track(
          form.tradingEnabled ? MixpanelEvent.TradingControlsEnableTrading : MixpanelEvent.TradingControlsDisableTrading
        );
      }

      // Position Auto-Hedging
      if (initialForm.positionAutoHedgingEnabled !== form.positionAutoHedgingEnabled) {
        promises.push(
          sendHedgeCommandMutation.mutateAsync({
            Command: form.positionAutoHedgingEnabled ? 'Activate' : 'Kill',
          })
        );
        mixpanel.track(
          form.positionAutoHedgingEnabled
            ? MixpanelEvent.TradingControlsEnablePositionAutoHedging
            : MixpanelEvent.TradingControlsDisablePositionAutoHedging
        );
      }

      // Customer Trading
      if (initialForm.customerTradingEnabled !== form.customerTradingEnabled) {
        promises.push(
          updatePricingRule({
            ...globalDefault,
            Mode: form.customerTradingEnabled ? ModeEnum.Enabled : ModeEnum.Disabled,
          })
        );
        mixpanel.track(
          form.customerTradingEnabled
            ? MixpanelEvent.TradingControlsEnableCustomerTrading
            : MixpanelEvent.TradingControlsDisableCustomerTrading
        );
      }

      // DDH: @todo

      return Promise.allSettled(promises);
    },
    [updatePricingRule, globalDefault, enableTrading, disableTrading, sendHedgeCommandMutation, mixpanel]
  );

  return {
    dialog,
    enabled,
    loading,
    allIsEnabled,
    tradingEnabled,
    customerTradingEnabled,
    positionAutoHedgingEnabled,
    ddhEnabled,
    dialogInfo: getDialogInfo(
      loading,
      tradingEnabled,
      customerTradingEnabled,
      positionAutoHedgingEnabled,
      ddhEnabled,
      disabledMarketAccounts,
      showPositionAutoHedgingInfo,
      showCustomerTradingInfo,
      showDdhInfo,
      marketAccountDisplayNameByName
    ),
    disabledMarketAccounts,
    updateControls,
    showPositionAutoHedgingInfo,
    showCustomerTradingInfo,
    showDdhInfo,
  };
}

function getDialogInfo(
  loading: boolean,
  tradingEnabled: boolean,
  customerTradingEnabled: boolean,
  positionAutoHedgingEnabled: boolean,
  ddhEnabled: boolean,
  disabledMarketAccounts: string[],
  showPositionAutoHedgingInfo: boolean,
  showCustomerTradingInfo: boolean,
  showDdhInfo: boolean,
  marketAccountDisplayNameByName: Map<string, string>
): TradingControlsDialogInfo | undefined {
  if (loading) {
    return {
      iconName: IconName.Check,
      iconColor: 'colors.green.lighten',
      loading,
      variant: IndicatorBadgeVariants.Positive,
    };
  }
  if (!tradingEnabled) {
    return {
      iconName: IconName.ExclamationSolid,
      iconColor: 'colors.red.lighten',
      variant: IndicatorBadgeVariants.Negative,
      warnings: ['Trading is disabled.'],
    };
  }
  const warnings: string[] = [];
  if (showPositionAutoHedgingInfo && !positionAutoHedgingEnabled) {
    warnings.push('Position Auto-Hedging is disabled.');
  }
  if (showCustomerTradingInfo && !customerTradingEnabled) {
    warnings.push('Customer Trading is disabled.');
  }
  if (showDdhInfo && !ddhEnabled) {
    warnings.push('Dynamic Delta Hedging is disabled.');
  }
  if (disabledMarketAccounts.length > 0) {
    warnings.push(
      `Disabled Market Accounts (${disabledMarketAccounts.length}): ${disabledMarketAccounts
        .map(marketAccount => marketAccountDisplayNameByName?.get(marketAccount))
        .join(', ')} `
    );
  }
  if (warnings.length > 0) {
    return {
      iconName: IconName.ExclamationSolid,
      iconColor: 'colors.yellow.lighten',
      variant: IndicatorBadgeVariants.Warning,
      warnings,
      counter: warnings.length,
    };
  }
  return {
    iconName: IconName.Check,
    iconColor: 'colors.green.lighten',
    variant: IndicatorBadgeVariants.Positive,
  };
}
