import { IconName, type AllInTier, type FeeTier, type FilterableProperty, type SymbolGroup } from '@talos/kyoko';
import { useCustomerPricingTierContext } from 'providers';
import { useEffect, useMemo, useState } from 'react';

export function usePricingTierFilters(): {
  allInTierFilter: FilterableProperty<'AllInTiers'> | null;
  feeTierFilter: FilterableProperty<'FeeTiers'> | null;
  symbolGroupFilter: FilterableProperty<'SymbolGroups'> | null;
} {
  const { getAllInTiers, getFeeTiers, getSymbolGroups } = useCustomerPricingTierContext();

  const [allInTiers, setAllInTiers] = useState<AllInTier[]>();
  const [feeTiers, setFeeTiers] = useState<FeeTier[]>();
  const [symbolGroups, setSymbolGroups] = useState<SymbolGroup[]>();

  const [allInTierOptions, setAllInTierOptions] = useState<string[]>();
  const [feeTierOptions, setFeeTierOptions] = useState<string[]>();
  const [symbolGroupOptions, setSymbolGroupOptions] = useState<string[]>();

  useEffect(() => {
    getAllInTiers().then(({ data }) => {
      setAllInTiers(data);
      setAllInTierOptions(data.map(tier => tier.Tier));
    });
    getFeeTiers().then(({ data }) => {
      setFeeTiers(data);
      setFeeTierOptions(data.map(tier => tier.Tier));
    });
    getSymbolGroups().then(({ data }) => {
      setSymbolGroups(data);
      setSymbolGroupOptions(data.map(tier => tier.Tier));
    });
  }, [getAllInTiers, getFeeTiers, getSymbolGroups]);

  const allInTierFilter: FilterableProperty<'AllInTiers'> | null = useMemo(
    () =>
      allInTiers && allInTierOptions
        ? {
            key: 'AllInTiers',
            label: 'Base Tier',
            field: 'AllInTier',
            icon: IconName.Cube,
            options: allInTierOptions,
            getOptionLabel: option => allInTiers.find(tier => tier.Tier === option)?.Tier ?? option,
          }
        : null,
    [allInTierOptions, allInTiers]
  );

  const feeTierFilter: FilterableProperty<'FeeTiers'> | null = useMemo(
    () =>
      feeTiers && feeTierOptions
        ? {
            key: 'FeeTiers',
            label: 'Fee Tier',
            field: 'FeeTier',
            icon: IconName.Cube,
            options: feeTierOptions,
            getOptionLabel: option => feeTiers.find(tier => tier.Tier === option)?.Tier ?? option,
          }
        : null,
    [feeTierOptions, feeTiers]
  );

  const symbolGroupFilter: FilterableProperty<'SymbolGroups'> | null = useMemo(
    () =>
      symbolGroups && symbolGroupOptions
        ? {
            key: 'SymbolGroups',
            label: 'Symbol Group',
            field: 'SymbolGroup',
            icon: IconName.Cube,
            options: symbolGroupOptions,
            getOptionLabel: option => symbolGroups.find(tier => tier.Tier === option)?.Tier ?? option,
          }
        : null,
    [symbolGroupOptions, symbolGroups]
  );

  return useMemo(
    () => ({ allInTierFilter, feeTierFilter, symbolGroupFilter }),
    [allInTierFilter, feeTierFilter, symbolGroupFilter]
  );
}
