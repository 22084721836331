import type { CareOrder } from '@talos/kyoko';
import {
  abbreviateId,
  ACTION,
  HamburgerMenu,
  IconName,
  NotificationVariants,
  request,
  useDynamicCallback,
  useEndpointsContext,
  useGetDefaultContextMenuItems,
  useGlobalDialog,
  useGlobalToasts,
  useJsonModal,
  type Column,
  type ColumnDef,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { getOrderDetailsRoute } from 'containers/Routes/routes';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { compact } from 'lodash';
import { useMemo, type ReactNode } from 'react';
import { cancelCareOrder, modify } from '../../../components/OMS/CareOrderFormView/CareOrderSlice';
import { primeNewRFQForm } from '../../../components/OMS/NewRFQ/RFQSlice';
import { openView } from '../../../components/OMS/OMSSlice';
import { OMSView } from '../../../components/OMS/OMSView';
import { useAppStateDispatch } from '../../../providers/AppStateProvider';
import { useOrderMenu } from '../Orders/useOrderMenu';
import { useQuotesMenu } from '../Quotes/useQuotesMenu';
import { isOrderRow, isQuoteRow } from './types';

export function useCareOrderMenu(): {
  column: Column;
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const { handleClickJson, jsonModal } = useJsonModal();
  const { isAuthorized } = useRoleAuth();
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const dispatch = useAppStateDispatch();
  const { open } = useGlobalDialog();
  const { add: addToast } = useGlobalToasts();
  const { orgApiEndpoint } = useEndpointsContext();

  const orderMenu = useOrderMenu({
    generateOrderDetailsRoute: getOrderDetailsRoute,
  });
  const quoteMenu = useQuotesMenu();

  const handleForceCancel = useDynamicCallback((entity: CareOrder) => {
    open({
      title: 'Force cancel Care Order',
      content: <>{`Are you sure you want to force cancel care order #${abbreviateId(entity.OrderID)}?`}</>,
      onConfirm: () => {
        request('PUT', `${orgApiEndpoint}/admin/care/orders/${entity.OrderID}/force-cancel`)
          .then(() => {
            addToast({
              text: `Care Order force cancelled.`,
              variant: NotificationVariants.Positive,
            });
          })
          .catch((e: ErrorEvent) =>
            addToast({
              text: `Could not force cancel Care Order: ${e.message}`,
              variant: NotificationVariants.Negative,
            })
          );
      },
    });
  });

  const handleCancel = useDynamicCallback((entity: CareOrder) => {
    open({
      title: 'Cancel Care Order',
      content: (
        <>
          <p>Are you sure you want to cancel Care Order #{`${abbreviateId(entity.OrderID)}`}?</p>
        </>
      ),
      onConfirm: () => {
        dispatch(cancelCareOrder(entity.OrderID));
        addToast({
          text: `Care Order cancelled.`,
          variant: NotificationVariants.Positive,
        });
      },
    });
  });

  const handlePrimeRFQ = useDynamicCallback((entity: CareOrder) => {
    dispatch(openView(OMSView.RFQForm));
    dispatch(
      primeNewRFQForm({
        group: entity.Group,
        symbol: entity.Symbol,
        side: entity.Side,
        currency: entity.Currency,
        orderQty: entity.LeavesQty,
        parentID: entity.OrderID,
      })
    );
  });

  const handleModifyOrder = useDynamicCallback((entity: CareOrder) => {
    dispatch(modify(entity));
    dispatch(openView(OMSView.CareOrderForm));
  });

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const entity = params.node?.data;

    if (isOrderRow(entity)) {
      return orderMenu.getContextMenuItems(params);
    }

    if (isQuoteRow(entity)) {
      return quoteMenu.getContextMenuItems(params);
    }

    // Now we know that entity is a CareOrder
    let isCancelable: boolean = entity.isCancelable;
    if (params.node?.childrenAfterFilter != null) {
      for (const row of params.node.childrenAfterFilter) {
        if (isQuoteRow(row.data) && !row.data.isTerminal) {
          isCancelable = false;
          break;
        }
        if (isOrderRow(row.data) && !row.data.isComplete) {
          isCancelable = false;
          break;
        }
      }
    }

    const items = [
      {
        icon: `<i class="ag-icon ${IconName.BookOpen}"/>`,
        disabled: entity.isComplete,
        name: 'Send RFQ',
        action: () => handlePrimeRFQ(entity),
      },
      isAuthorized(ACTION.SUBMIT_CARE_ORDER)
        ? {
            name: 'Modify',
            disabled: entity.isComplete,
            icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
            action: () => handleModifyOrder(entity),
          }
        : undefined,
      isAuthorized(ACTION.CANCEL_ORDER)
        ? {
            name: `Cancel`,
            disabled: !isCancelable,
            icon: `<i class="ag-icon ${IconName.Trash}"/>`,
            action: () => handleCancel(entity),
          }
        : null,
      entity
        ? {
            name: `Show JSON`,
            action: () => handleClickJson(entity),
            icon: `<i class="ag-icon ${IconName.Braces}"/>`,
          }
        : undefined,
      isAuthorized(ACTION.FORCE_CANCEL_ORDER)
        ? {
            name: `Force cancel`,
            action: () => handleForceCancel(entity),
          }
        : null,
    ];

    return compact([...items, ...getDefaultContextMenuItems(params)]);
  });

  const dialogs = useMemo(
    () => (
      <>
        {jsonModal}
        {orderMenu.dialogComponents}
        {quoteMenu.dialogs}
      </>
    ),
    [jsonModal, orderMenu.dialogComponents, quoteMenu.dialogs]
  );

  const column = useMemo(
    () =>
      ({
        type: 'hamburgerMenu',
        id: 'rowMenu',
        params: {
          renderItems: params => <HamburgerMenu {...params} onShowJson={handleClickJson} />,
        },
      } satisfies ColumnDef<CareOrder>),
    [handleClickJson]
  );

  return {
    column,
    getContextMenuItems,
    dialogs,
  };
}
