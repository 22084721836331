import { DELETE, type ModeEnum, POST, PUT, request, useUserContext } from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../providers';

export interface EquityMarginRatioLimitRequest {
  MarketAccount?: string;
  Ratio: string;
  Mode?: ModeEnum;
}

export function useEquityMarginRatioLimits() {
  const { orgApiEndpoint } = useUserContext();
  const { getConfig } = useOrgConfiguration();

  const isEquityMarginRatioLimitCheckEnabled = useMemo(
    (): boolean => getConfig(OrgConfigurationKey.DisableEquityMarginRatioLimitCheck, '0') === '0',
    [getConfig]
  );

  const createEquityMarginRatioLimit = useCallback(
    (payload: EquityMarginRatioLimitRequest) => request(POST, `${orgApiEndpoint}/equity-margin-ratio-limits`, payload),
    [orgApiEndpoint]
  );

  const updateEquityMarginRatioLimit = useCallback(
    (limitID: string, payload: EquityMarginRatioLimitRequest) =>
      request(PUT, `${orgApiEndpoint}/equity-margin-ratio-limits/${limitID}`, payload),
    [orgApiEndpoint]
  );

  const deleteEquityMarginRatioLimit = useCallback(
    (limitID: string) => request(DELETE, `${orgApiEndpoint}/equity-margin-ratio-limits/${limitID}`),
    [orgApiEndpoint]
  );

  return {
    // Org Config
    isEquityMarginRatioLimitCheckEnabled,

    // REST API calls
    createEquityMarginRatioLimit,
    updateEquityMarginRatioLimit,
    deleteEquityMarginRatioLimit,
  };
}
