import { Button, ButtonVariants } from '../Button';
import { FormControlSizes } from '../Form/types';

export const RefreshButton = () => {
  return (
    <Button
      ghost
      size={FormControlSizes.Tiny}
      variant={ButtonVariants.Primary}
      onClick={() => window.location.reload()}
    >
      Refresh
    </Button>
  );
};
