import type { ReactNode } from 'react';
import { Direction } from '../../types';
import { Benchmark, type BenchmarkProps } from '../Benchmark';
import { HStack, type BoxProps } from '../Core';
import { HelpIcon } from '../Icons';
import { Text } from '../Text';
import { InsightContainer } from './styles';

type InsightProps = {
  /** Top label of the insight component */
  label: string;
  /** Tooltip shown next to the top label */
  helpIconTooltip?: ReactNode;
  /** The text shown left of the insight percentage */
  text: ReactNode;
  /** Whether or not to show the right benchmark tag. Defaults to true. */
  showBenchmark?: boolean;
} & Pick<BenchmarkProps, 'value' | 'benchmark' | 'percentageChange' | 'positiveDirection'> &
  BoxProps;

export const Insight = ({
  label,
  helpIconTooltip,
  text,
  value,
  benchmark,
  positiveDirection = Direction.Asc,
  showBenchmark = true,
  percentageChange,
  ...boxProps
}: InsightProps) => {
  return (
    <InsightContainer gap="spacingSmall" alignItems="flex-start" {...boxProps}>
      <HStack gap="spacingSmall" alignItems="baseline">
        <Text color="colorTextDefault">{label}</Text>
        {helpIconTooltip && <HelpIcon tooltip={helpIconTooltip} />}
      </HStack>

      <HStack gap="spacingDefault" justifyContent="space-between" w="100%">
        {text}
        {showBenchmark && (
          <Benchmark
            value={value}
            benchmark={benchmark}
            percentageChange={percentageChange}
            positiveDirection={positiveDirection}
          />
        )}
      </HStack>
    </InsightContainer>
  );
};
