import {
  Box,
  ButtonVariants,
  Checkbox,
  Flex,
  ICON_SIZES,
  Icon,
  IconName,
  InlineFormattedNumber,
  Popover,
  Text,
  useCurrency,
  usePopoverState,
} from '@talos/kyoko';
import Big from 'big.js';
import { usePositionsFormDispatch } from 'containers/Portfolio/providers';
import type { Position } from 'containers/Portfolio/types';
import { memo, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { PositionsActionType, type AmountEntry } from '../PositionsFormReducer';
import { AvailableBalanceCell } from './AvailableBalanceCell';
import { SettlementAmountInput } from './SettlementAmountInput';
import {
  CheckboxWrapper,
  SettleAmountWrapper,
  SettleButton,
  SettlementBodyCell,
  SettlementNumber,
  StyledCurrencyDisplayer,
} from './styles';

export interface CurrencySettlementRowProps {
  position: Position;
  formState: AmountEntry;
  marketAccount: string;
  onSettleClick: (position: Position, amount: string) => void;
}

export const CurrencySettlementRow = memo(
  ({ position, marketAccount, formState, onSettleClick }: CurrencySettlementRowProps) => {
    const { inputValue, checked, wsAmount } = formState;
    const dispatch = usePositionsFormDispatch();
    const currency = useCurrency(position.Currency);
    const isOutgoing = Big(position.Amount).lt(0);

    const { colorTextWarning, fontSizeDefault } = useTheme();

    const amountWarningPopover = usePopoverState({
      trigger: 'hover',
    });

    const showWarning = useMemo(() => {
      if (!inputValue) {
        return false;
      }
      return Big(inputValue).gt(Big(wsAmount).abs());
    }, [inputValue, wsAmount]);

    const handleInputValueChange = useCallback(
      (change?: string | null) => {
        // only emit valid numbers
        if (change != null && isNaN(+change)) {
          return;
        }

        dispatch({
          type: PositionsActionType.AmountInputValueChange,
          payload: {
            marketAccount,
            currency: position.Currency,
            inputValue: change || '',
          },
        });
      },
      [dispatch, marketAccount, position]
    );

    const handleCheckedChange = useCallback(
      (checked: boolean) => {
        dispatch({
          type: PositionsActionType.AmountCheckedChange,
          payload: {
            marketAccount,
            currency: position.Currency,
            checked,
          },
        });
      },
      [dispatch, marketAccount, position]
    );

    const handleSettleNumberClick = useCallback(() => {
      if (isOutgoing) {
        dispatch({
          type: PositionsActionType.AmountClicked,
          payload: {
            marketAccount,
            currency: position.Currency,
          },
        });
      }
    }, [isOutgoing, dispatch, marketAccount, position]);

    const settleDisabled = useMemo(() => {
      if (inputValue === '') {
        return true;
      }

      const inputBig = Big(inputValue);
      if (!inputBig.gt(Big(0))) {
        return true;
      } else {
        return false;
      }
    }, [inputValue]);

    return (
      <tr>
        <SettlementBodyCell width="1%">
          <CheckboxWrapper>
            <Checkbox checked={checked} onChange={e => handleCheckedChange(e.target.checked)} />
          </CheckboxWrapper>
        </SettlementBodyCell>
        <SettlementBodyCell>
          <StyledCurrencyDisplayer currency={position.Currency} />
        </SettlementBodyCell>
        <SettlementBodyCell>
          {currency && <AvailableBalanceCell marketAccount={marketAccount} currency={currency} />}
        </SettlementBodyCell>
        <SettlementBodyCell />
        <SettlementBodyCell style={{ textAlign: 'right' }}>
          <SettlementNumber onClick={handleSettleNumberClick} clickable={isOutgoing}>
            <InlineFormattedNumber
              number={position.Amount}
              currency={currency?.Symbol}
              increment={currency?.DefaultIncrement}
            />
          </SettlementNumber>
        </SettlementBodyCell>
        <SettlementBodyCell />
        {currency && (
          <>
            <SettlementBodyCell>
              <SettleAmountWrapper>
                <SettlementAmountInput value={inputValue} onChange={handleInputValueChange} currency={currency} />
              </SettleAmountWrapper>
            </SettlementBodyCell>
            <SettlementBodyCell width="1%">
              <Flex pl="spacingDefault" alignItems="center" gap="spacingDefault">
                <SettleButton
                  visible={checked}
                  onClick={() => onSettleClick(position, inputValue)}
                  variant={ButtonVariants.Default}
                  disabled={settleDisabled}
                >
                  Settle
                </SettleButton>

                <Box w={ICON_SIZES.MEDIUM + 'px'}>
                  {showWarning && (
                    <Popover {...amountWarningPopover}>
                      <Icon icon={IconName.ExclamationCircle} size={ICON_SIZES.MEDIUM} color={colorTextWarning} />
                      <Text size={fontSizeDefault}>
                        The manually entered amount to settle is larger than the perceived settleable amount.
                      </Text>
                    </Popover>
                  )}
                </Box>
              </Flex>
            </SettlementBodyCell>
          </>
        )}
      </tr>
    );
  }
);
