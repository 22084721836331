import { HStack, Popover } from '@talos/kyoko';
import styled from 'styled-components';

export const LimitText = styled.span<{ warning: boolean }>`
  color: ${({ warning, theme }) => (warning ? theme.colorTextWarning : theme.colorTextSubtle)};
  white-space: nowrap;
`;

export const LimitWarningContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacingDefault}px;
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingMedium}px`};
  background: ${({ theme }) => theme.backgroundContent};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
`;

export const LimitWarningText = styled.div`
  color: ${({ theme }) => theme.colorTextWarning};
`;

export const AnimatedHStack = styled(HStack)`
  transition: width 200ms ease;
`;

export const FullWidthPopover = styled(Popover)`
  width: 100%;
`;
