import { useFeatureFlag } from '../../../hooks';
import { Markets } from '../Markets';
import { CredentialsBlotter } from './CredentialsBlotter';

export function Credentials() {
  const { enableNewCredentials } = useFeatureFlag();

  if (enableNewCredentials) {
    return <CredentialsBlotter />;
  } else {
    return <Markets />;
  }
}
