import { FormGroup, MultiSelect, useObservableValue, type CustomerRole } from '@talos/kyoko';
import { useCustomersContext } from 'hooks/useCustomer';
import { useMemo } from 'react';

const UI_ROLE_DESCRIPTION_MAPPING = new Map([
  ['DefaultViewer', 'Users with the Viewer role are able to view all data on the system but may not execute orders.'],
  ['DefaultTrader', 'Users with the Trader role are able to view all data on the system and execute orders.'],
  [
    'DefaultAdmin',
    'Users with the Admin role have the capabilities of a Trader and Viewer and may also submit withdrawal requests.',
  ],
  [
    'DefaultTraderOrdersOnly',
    'Users with Orders only role do not have RFQ capabilities. Users with this role must also have the Viewer role.',
  ],
  ['ViewCFDPositions', 'Users with the View CFD Positions role can view CFD positions.'],
]);

interface Props {
  selectedRoles?: CustomerRole[];
  onChange: (selectedRoles: CustomerRole[]) => void;
}

export function CustomerUserRoleSelector(props: Props) {
  const customersService = useCustomersContext();
  const customerRoles = useObservableValue(() => customersService.customerRoles, [customersService.customerRoles]);
  const availableRoles = useMemo(() => {
    const selectedRoleIds = props.selectedRoles?.map(r => r.RoleID);
    return customerRoles?.filter(role => !selectedRoleIds?.includes(role.RoleID));
  }, [props.selectedRoles, customerRoles]);

  return (
    <FormGroup label="Roles" controlId="roles">
      <MultiSelect
        disabled={!customerRoles}
        selections={props.selectedRoles ?? []}
        options={availableRoles ?? []}
        onChange={props.onChange}
        getLabel={role => role.DisplayName}
        getDescription={role => UI_ROLE_DESCRIPTION_MAPPING.get(role.Name) ?? ''}
        portalize
        data-testid="roles"
      />
    </FormGroup>
  );
}
