import { Flex } from '@talos/kyoko';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  background: ${({ theme }) => theme.backgroundContentHover};
`;

export const SettlementToastContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: ${({ theme }) => theme.spacingMedium}px;
  right: ${({ theme }) => theme.spacingMedium}px;
`;
