import type { TabProps, UseTabs } from '@talos/kyoko';
import { createContext } from 'react';
import type { PerformanceTabState } from '../types';

export type PerformanceTabProps = TabProps & PerformanceTabState;

export const DEFAULT_PERFORMANCE_TAB_ID = 'default-performance';

export interface PerformanceTabsContextProps {
  tabs: UseTabs<PerformanceTabProps>;
  currentTab: PerformanceTabProps;
  cloneCurrentTab: () => void;
  filtersInitialOpen: boolean;
}

/**
 * There are three contexts being created below and then provided through the one merged provided below.
 * The three contexts are created to allow consumers of the data that is provided in more precise ways for better clarity
 * as well as some performance gains.
 *
 * The three contexts: PerformanceTabsContext, PerformanceContext and PerformanceDispatchContext all have a lot in common and interact with each other
 * closely so keeping them in one provider makes things simpler. Most notably its the interaction between 1) initializing reducer state from persisted tabs, and
 * 2) persisting any state changes to the persisted tabs set.
 */

export const PerformanceTabsContext = createContext<PerformanceTabsContextProps | undefined>(undefined);
export const PerformanceContext = createContext({});
export const PerformanceDispatchContext = createContext({});
