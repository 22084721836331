import { createContext, useContext, useMemo } from 'react';
import { useTransfersProcessing } from '../DealerSettlement/useTransfersProcessing';
import { usePortfolioPositions } from './index';

export interface ProcessingTransfers {
  transfersProcessing: any[];
  nTransfersProcessing: number;
}

export const ProcessingTransfersProviderContext = createContext<ProcessingTransfers | undefined>(undefined);
ProcessingTransfersProviderContext.displayName = 'ProcessingTransfersProviderContext';

export function useProcessingTransfersContext() {
  const context = useContext(ProcessingTransfersProviderContext);
  if (context === undefined) {
    throw new Error(
      'Missing ProcessingTransfersProviderContext.Provider further up in the tree. Did you forget to add it?'
    );
  }

  return context;
}

export const ProcessingTransfersProvider = function ProcessingTransfersProvider({ children }) {
  const { OTCPositionsList } = usePortfolioPositions();
  const transfersProcessing = useTransfersProcessing(OTCPositionsList ?? []);
  const nTransfersProcessing = transfersProcessing.length;

  const value = useMemo(() => {
    return {
      transfersProcessing,
      nTransfersProcessing,
    };
  }, [transfersProcessing, nTransfersProcessing]);

  return (
    <ProcessingTransfersProviderContext.Provider value={value}>{children}</ProcessingTransfersProviderContext.Provider>
  );
};
