import { Box, Flex, Grid } from '@talos/kyoko';
import styled, { css } from 'styled-components';

export const DetailsWrapper = styled(Box)`
  scrollbar-gutter: stable;
`;
DetailsWrapper.defaultProps = {
  overflow: 'auto',
  p: 'spacingComfortable',
  pr: 'spacingTiny',
  background: 'backgroundContent',
  flex: 1,
  h: '100%',
};

export const GridItem = styled(Flex)``;
GridItem.defaultProps = {
  flexDirection: 'column',
  gap: 'spacingDefault',
};

export type GridSize = 'sm' | 'md' | 'lg';

/**
 * Documentation on the grid-template-areas building we're doing below
 *
 * We conditionally might want to add in one of or both of multileg and ddh details cards. To achieve this, we specify their grid-areas
 * but make sure that those rows have min-content set on their heights. This means that if nothing is rendered in those grid-areas,
 * then the grid looks as if there is no space reserved for those conditional multileg and ddh cards.
 */

function maybeMultilegLgRow(isOrderMultileg: boolean) {
  return `${isOrderMultileg ? 'multileg' : 'general'} order timeline` as const;
}

function maybeMultilegMdRow(isOrderMultileg: boolean) {
  return `${isOrderMultileg ? 'multileg multileg' : 'general order'} timeline` as const;
}

function maybeMultilegSmRow(isOrderMultileg: boolean) {
  return `${isOrderMultileg ? 'multileg' : 'general'}  timeline` as const;
}

export const CustomGridLayout = styled(Grid)<{ size: GridSize; isOrderMultiLeg: boolean; isDDHEnabled: boolean }>`
  ${({ size, isOrderMultiLeg, isDDHEnabled }) => {
    switch (size) {
      case 'lg':
        return css`
          grid-template-areas:
            '${isDDHEnabled ? 'ddh ddh timeline' : maybeMultilegLgRow(isOrderMultiLeg)}'
            '${maybeMultilegLgRow(isOrderMultiLeg)}'
            'general order timeline';
          grid-template-rows: min-content min-content 1fr;
          grid-template-columns: 1fr 1fr 1fr;
        `;

      case 'md':
        return css`
          grid-template-areas:
            '${isDDHEnabled ? 'ddh ddh timeline' : maybeMultilegMdRow(isOrderMultiLeg)}'
            '${maybeMultilegMdRow(isOrderMultiLeg)}'
            'general order timeline';
          grid-template-rows: min-content min-content 1fr;
          grid-template-columns: 1fr 1fr 1fr;
        `;

      case 'sm':
        return css`
          grid-template-areas:
            '${isDDHEnabled ? 'ddh timeline' : maybeMultilegSmRow(isOrderMultiLeg)}'
            '${maybeMultilegSmRow(isOrderMultiLeg)}'
            'general timeline'
            'order timeline';
          grid-template-rows: min-content min-content min-content 1fr;
          grid-template-columns: 1fr 1fr;
        `;
    }
  }}
`;
CustomGridLayout.defaultProps = {
  gap: 'spacingDefault',
  minHeight: '100%',
};
