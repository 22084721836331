import { AuthContextProvider, BaseStyle, DarkTalosTheme, LightTalosTheme, PortalContextProvider } from '@talos/kyoko';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Routes } from 'containers/Routes';
import { useThemeType } from 'hooks/useThemeType';
import { startCase } from 'lodash';
import { UserContextProvider } from 'providers/UserContextProvider';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles/GlobalStyle';

export default function App() {
  const isProd = import.meta.env.VITE_AVA_ENV === 'prod';
  const [themeType] = useThemeType();

  document.title = `Talos ${isProd ? 'Trading' : `- ${startCase(import.meta.env.VITE_AVA_ENV)}`}`;

  return (
    <ThemeProvider theme={themeType === 'dark' ? DarkTalosTheme : LightTalosTheme}>
      <BaseStyle />
      <GlobalStyle />
      <ErrorBoundary>
        <PortalContextProvider>
          <AuthContextProvider
            loginUrl={import.meta.env.VITE_AUTH_LOGIN_URL}
            logoutUrl={import.meta.env.VITE_AUTH_LOGOUT_URL}
            sessionCookieName={import.meta.env.VITE_SESSION_COOKIE}
          >
            {/* Note: This User Context Provider should remain above the WebSocketClientProvider - if not, see note in WebSocketClientProvider */}
            <UserContextProvider>
              <Routes />
            </UserContextProvider>
          </AuthContextProvider>
        </PortalContextProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
