import { useCallback, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useDynamicCallback, useIntl } from '../../hooks';
import type { DateFnInput } from '../../utils';
import { Box } from '../Core';
import { BaseSelect, Dropdown, useDropdownPopper, type BaseSelectProps, type FormControlProps } from '../Form';
import { FormattedMessage } from '../Intl';
import { Text } from '../Text';
import { DateTimePickerContent, type DateTimePickerContentProps, type SelectionOrigin } from './DateTimePickerContent';
import {
  DEFAULT_DATE_PICKER_EOD,
  DEFAULT_SHORTCUTS,
  getFormatter,
  shouldEmitChange,
  validateMaxDate,
  validateMinDate,
} from './utils';

const messages = defineMessages({
  date: {
    defaultMessage: 'Date',
    id: 'DateTimePicker.date',
  },
  select: {
    defaultMessage: 'Select...',
    id: 'DateTimePicker.select',
  },
});

export type DateTimePickerSelectProps = {
  /** Current value of the date / time picker */
  value: Date | null;
  /** Change event handler */
  onChange: (value: Date | null) => void;
  /** Portalize component: @default: true */
  portalize?: boolean;
  getLabel?: (date: Date | null) => string;
} & Omit<FormControlProps<HTMLDivElement>, 'onChange' | 'value'> &
  Pick<
    DateTimePickerContentProps,
    | 'showCalendar'
    | 'showShortcuts'
    | 'showTimePicker'
    | 'showMilliseconds'
    | 'shortcuts'
    | 'customShortcutResolver'
    | 'timePickerVariant'
    | 'timeSelectorIntervalMinutes'
    | 'customEOD'
    | 'useDaySelectCustomEOD'
    | 'minValue'
    | 'maxValue'
  > &
  Pick<BaseSelectProps<Date | null>, 'placeholderColor' | 'placeholder'>;

export const DateTimePickerSelect = ({
  onChange,
  value,
  showCalendar = true,
  showShortcuts = true,
  showTimePicker = true,
  showMilliseconds = false,
  shortcuts = DEFAULT_SHORTCUTS,
  minValue,
  maxValue,
  customEOD = DEFAULT_DATE_PICKER_EOD,
  useDaySelectCustomEOD,
  timePickerVariant = 'picker',
  timeSelectorIntervalMinutes = 60,
  portalize = true,
  size,
  getLabel,
  invalid,
  disabled,
  prefix,
  suffix,
  customShortcutResolver,
  placeholder,
  placeholderColor,
}: DateTimePickerSelectProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLLabelElement>(null);
  const timeSelectorDropdownContentRef = useRef<HTMLDivElement>(null);

  const dropdown = useDropdownPopper({
    isOpen,
    referenceElement: selectRef.current,
    onClickOutside: e => {
      if (
        timePickerVariant === 'selector' &&
        timeSelectorDropdownContentRef &&
        timeSelectorDropdownContentRef.current
      ) {
        const el = e.currentTarget;
        if (el instanceof Node && e.composedPath().includes(timeSelectorDropdownContentRef.current)) {
          // dont close, we're inside of the time selector dropdown
          return;
        }
      }

      setIsOpen(false);
    },
    dropdownPlacement: 'bottom-start',
    dropdownWidth: 'fit-content',
  });

  const handleSelectClick = useDynamicCallback(() => {
    setIsOpen(open => !open);
  });

  const maybeEmitChange = useDynamicCallback((maybeChangedDate: Date | null) => {
    if (maybeChangedDate && minValue) {
      maybeChangedDate = validateMinDate(maybeChangedDate, minValue, showTimePicker);
    }

    if (maybeChangedDate && maxValue) {
      maybeChangedDate = validateMaxDate(maybeChangedDate, maxValue, showTimePicker);
    }

    if (shouldEmitChange(value, maybeChangedDate)) {
      onChange(maybeChangedDate);
    }
  });

  const handleSelection = useDynamicCallback((date: Date | null, from: SelectionOrigin) => {
    maybeEmitChange(date);

    const shouldClose = !showTimePicker || from === 'shortcut';
    if (shouldClose) {
      setIsOpen(false);
    }
  });

  const defaultGetLabel = useCallback(
    (date: DateFnInput | null) => {
      if (date == null) {
        return formatMessage(messages.select);
      }
      try {
        const formatter = getFormatter(showMilliseconds, showTimePicker);
        return formatter(date);
      } catch (e) {
        return '';
      }
    },
    [formatMessage, showMilliseconds, showTimePicker]
  );

  return (
    <BaseSelect
      prefix={prefix ?? <DefaultPrefix />}
      value={value}
      getLabel={getLabel ?? defaultGetLabel}
      wrapperRef={selectRef}
      isDropdownOpened={isOpen}
      onClick={handleSelectClick}
      invalid={invalid}
      disabled={disabled}
      size={size}
      placeholder={placeholder}
      placeholderColor={placeholderColor}
    >
      <Dropdown {...dropdown}>
        <Box p="spacingDefault">
          <DateTimePickerContent
            value={value}
            showCalendar={showCalendar}
            showTimePicker={showTimePicker}
            showShortcuts={showShortcuts}
            showMilliseconds={showMilliseconds}
            shortcuts={shortcuts}
            timePickerVariant={timePickerVariant}
            timeSelectorIntervalMinutes={timeSelectorIntervalMinutes}
            timeSelectorDropdownContentRef={timeSelectorDropdownContentRef}
            onSelection={handleSelection}
            onTabOut={() => setIsOpen(false)}
            minValue={minValue}
            maxValue={maxValue}
            customShortcutResolver={customShortcutResolver}
          />
        </Box>
      </Dropdown>
    </BaseSelect>
  );
};

const DefaultPrefix = () => (
  <Text color="inherit" weight="fontWeightMedium" pr="spacingSmall">
    <FormattedMessage {...messages.date} />
  </Text>
);
