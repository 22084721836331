import { Box, HStack, type BoxProps } from '../Core';
import { EllipsisBoxWithTooltip } from '../Form/BaseSelect/EllipsisBoxWithTooltip';
import { Tooltip } from '../Tooltip';

/** (Internal) Overflowing textbox and '+#' chip render component for the OverflowItemsBox  */
export function OverflowItemsRenderer({
  isReady,
  defaultLabel,
  visibleItems,
  overflowItems,
  tooltipZIndex,
  chipFontSize,
}: {
  isReady: boolean;
  defaultLabel: React.ReactNode;
  visibleItems: string[];
  overflowItems: string[];
  tooltipZIndex?: number;
  chipFontSize: number;
}) {
  let singleDisplayItem: React.ReactNode | undefined;
  const overflowItemsToUse = [...overflowItems];
  if (visibleItems.length === 0) {
    singleDisplayItem = overflowItemsToUse.shift() ?? defaultLabel;
  }
  return (
    <HStack gap="spacingSmall" maxWidth="100%">
      {isReady ? (
        <>
          {singleDisplayItem ? (
            <EllipsisBoxWithTooltip data-testid="selection-label" tooltipZIndex={tooltipZIndex}>
              {singleDisplayItem}
            </EllipsisBoxWithTooltip>
          ) : (
            <Box as="span" fontWeight="normal" data-testid="selection-label">
              {visibleItems.join(', ')}
            </Box>
          )}
        </>
      ) : null}
      <OverflowChip tooltipZIndex={tooltipZIndex} overflowItems={overflowItemsToUse} chipFontSize={chipFontSize} />
    </HStack>
  );
}
function OverflowChip({
  overflowItems,
  tooltipZIndex,
  chipFontSize,
  ...boxProps
}: {
  overflowItems: string[];
  tooltipZIndex?: number;
  chipFontSize: number;
} & BoxProps) {
  if (overflowItems.length === 0) {
    return null;
  }
  return (
    <Tooltip
      tooltip={<ChipList overflowItems={overflowItems} />}
      usePortal={true}
      placement="bottom-start"
      tooltipTestID="overflow-chip-tooltip"
      zIndex={tooltipZIndex}
    >
      <Box
        background="gray.050"
        p="2px 5px"
        fontSize={chipFontSize}
        borderRadius="3px"
        lineHeight="12px"
        color="gray.090"
        data-testid="overflow-chip"
        {...boxProps}
      >
        +{overflowItems.length}
      </Box>
    </Tooltip>
  );
}

function ChipList({ overflowItems }: { overflowItems: string[] }) {
  return (
    <>
      {overflowItems.sort().map(item => (
        <Box key={item} py="spacingTiny">
          {item}
        </Box>
      ))}
    </>
  );
}
