import { DockviewDefaultTab, type IDockviewDefaultTabProps, type IDockviewPanelProps } from 'dockview';
import 'dockview/dist/styles/dockview.css';
import { DockViewPanelWrapper } from './DockViewPanelWrapper';
import type { TabComponentSpec } from './types';

export function WrapComponentsForDockView(components: TabComponentSpec[]): {
  [key: string]: (props: IDockviewPanelProps) => JSX.Element;
} {
  return components.reduce((res, component) => {
    res[component.type] = props => {
      return <DockViewPanelWrapper>{component.renderer(props)}</DockViewPanelWrapper>;
    };
    return res;
  }, {} as { [key: string]: (props: IDockviewPanelProps) => JSX.Element });
}

export const TAB_COMPONENTS = {
  // Replace close icon SVG with our own icon for this and all other tabs
  // Merge with default tab and control with prop
  readOnly: (props: IDockviewDefaultTabProps) => {
    return <DockviewDefaultTab hideClose={true} {...props} />;
  },
  // Replace close icon SVG with our own icon for this and all other tabs
  // Add close confirmation dialog
  default: (props: IDockviewDefaultTabProps) => {
    return <DockviewDefaultTab {...props} />;
  },
};
