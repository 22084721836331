import type { RowGroupsOpenedState } from '@talos/kyoko';
import { defaultSubAccountsBlotterColumns } from '../../../Blotters/PositionsV3/defaults';
import { usePerformanceContext, usePerformanceTabs } from '../providers/PerformanceStateAndTabsProvider';
import { PERFORMANCE_BLOTTER_PREFIX } from '../tokens';
import { PerformanceBlotter } from './PerformanceBlotter';

const defaultRowGroupsOpened: RowGroupsOpenedState = {};

// This component wraps the blotter and specifically handles grabbing the correct blotterID and passing it to the blotter,
// also making sure to re-mount the blotter on blotterID change
export const PerformanceBlotterContainer = () => {
  const { currentTab } = usePerformanceTabs();
  const {
    state: { showing },
  } = usePerformanceContext();

  // For each tab, we keep two blotter IDs, one for each showing mode (Performance / Position). These are regarded as different blotters
  const blotterID = `${PERFORMANCE_BLOTTER_PREFIX}/${currentTab.id}/${showing}`;
  return (
    <PerformanceBlotter
      key={blotterID}
      blotterID={blotterID}
      defaultBlotterColumns={defaultSubAccountsBlotterColumns}
      defaultRowGroupsOpened={defaultRowGroupsOpened}
    />
  );
};
