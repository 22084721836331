import { useCallback, useMemo, useState } from 'react';

import { EMPTY_OBJECT, useDisclosure, type UseDisclosureReturn } from '@talos/kyoko';
import type { CreateMultilegDialogProps } from './types';
interface UseCreateMultilegDialogProps {
  onUseSymbol?: (symbol: string) => void;
  useSymbolLabel?: string;
}

export function useCreateMultilegDialog(
  props: UseCreateMultilegDialogProps = EMPTY_OBJECT
): CreateMultilegDialogProps & UseDisclosureReturn & { onEdit: (symbol: string) => void } {
  const dialog = useDisclosure({
    onClose: () => setSymbol(undefined),
  });

  const [symbol, setSymbol] = useState<string>();

  const onEdit = useCallback(
    (symbol: string) => {
      setSymbol(symbol);
      dialog.open();
    },
    [dialog]
  );

  return useMemo(
    () => ({
      ...props,
      ...dialog,
      symbol,
      onEdit,
    }),
    [props, dialog, symbol, onEdit]
  );
}
