import { HStack, InlineFormattedNumber, Text } from '@talos/kyoko';
import { pnlPercentToNumberVariant } from './utils';

interface DeltaSeriesTooltipProps {
  point: Highcharts.TooltipFormatterContextObject;
}

export const BenchmarkSeriesTooltip = ({ point }: DeltaSeriesTooltipProps) => {
  if (point.x == null || point.y == null) {
    return null;
  }

  return (
    <HStack gap="spacingDefault" minWidth="200px" justifyContent="space-between" fontSize="fontSizeMd">
      <Text>BTC Benchmark</Text>
      <InlineFormattedNumber
        number={point.y}
        increment="0.01"
        currency="%"
        round
        variant={pnlPercentToNumberVariant(point.y.toString())}
      />
    </HStack>
  );
};
