import {
  FUNDING_RATE,
  FUNDING_TIME,
  MARK_PRICE,
  NEXT_FUNDING_TIME,
  OPEN_INTEREST,
  OPEN_VALUE,
  PREDICTED_NEXT_FUNDING_RATE,
  SYMBOL,
  TURNOVER24H,
  VOLUME24H,
} from './marketDataStats';

export const FEES_TOGGLE = 'Show prices and place orders using All-In prices';
export const FIRM_LIQUIDITY_TOGGLE = 'Show executable prices';
export const ALLIN_LIMIT_PRICE = 'Limit price, including fees';
export const RFQ_COUNTER_CURRENCY_AMT = 'Estimated counter amount of the order';
export const COUNTER_CURRENCY_AMT = 'Estimated counter amount of the order, excluding fees';
export const ALLIN_COUNTER_CURRENCY_AMT = 'Estimated counter amount of the order, including fees';

export const ALLIN_ORDER_TOTAL = 'Estimated total cost for the order, including fees';
export const ORDER_TOTAL = 'Estimated total cost for the order, excluding fees';

/**
 * Helper function for denominating a description to a home currency.
 */
export const appendHomeCurrencyTerms = (totalDescription: string, homeCurrency: string) =>
  `${totalDescription}, in ${homeCurrency} terms`;

export const MARKET_DATA_STATS = {
  [FUNDING_RATE]:
    'The calculated funding rate for this funding period. This is the rate that longs will pay shorts. If negative, shorts pay longs.',
  [FUNDING_TIME]: '',
  [MARK_PRICE]: 'The price used for PnL and margin calculations',
  [NEXT_FUNDING_TIME]: '',
  [OPEN_VALUE]: 'The total value of contracts in existence',
  [OPEN_INTEREST]: 'The total number of contracts in existence',
  [PREDICTED_NEXT_FUNDING_RATE]:
    'The predicted funding rate for the next funding period. This is the rate that longs will pay shorts. If negative, shorts pay longs.',
  [SYMBOL]: '',
  [TURNOVER24H]: 'The value of contracts traded in the last 24 hours',
  [VOLUME24H]: 'The number of contracts traded in the last 24 hours',
};

export const RFQ_LIMIT_PRICE_WARNING = (
  <>
    Only the <b>expected</b> average price is shown for this Limit Order execution provider. Additional price slippage
    or improvement may occur.
  </>
);

export const TRADE_BEST = (
  <>
    Trade on the quote with the best available price.
    <br />
    <br />
    If allowed slippage is specified, the system will automatically retry to execute up to the specified slippage. This
    is useful when quotes change rapidly.
  </>
);

export const DISABLED_FOR_SYNTHETIC_CCY = (
  <>
    This provider does not support native counter currency - synthetic counter currency support can be enabled in{' '}
    <a href="/settings/trading">Settings</a>.
  </>
);
export const SYNTHETIC_CCY_WARNING =
  'Counter currency pricing calculated by Talos. Due to rounding, executed amount may vary slightly from specified order size.';

export const ORDER_NOTIONAL = 'Estimated Notional Amount on the Trade.';

export const TRADE_ALLOCATIONS_SETTINGS =
  'When Sub Account Allocations are enabled, trades are allocated to the Sub Accounts specified on an order using the provided percentages/amounts.';

export const TRADE_ALLOCATIONS_OMS = (
  <>
    {TRADE_ALLOCATIONS_SETTINGS} This option can be toggled in <a href="/settings/trading">Settings</a>.
  </>
);

export const TRADE_ALLOCATIONS_OMS_SETTINGS = (
  <>
    Sub Account Allocations (using multiple Sub Accounts on a single order) can be enabled in{' '}
    <a href="/settings/trading">Settings</a>.
  </>
);

export const MODIFY_ALLOCATIONS_ROW = 'You cannot modify allocations on this order until it is completed.';

export const EXCHANGES_DISABLED_STRATEGY = 'Exchanges are disabled for your selected strategy.';
export const DEALERS_DISABLED_STRATEGY = 'Dealers are disabled for your selected strategy.';
export const DARK_DISABLED_STRATEGY = 'Dark are disabled for your selected strategy.';
