import { orderBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { ReplaySubject, asyncScheduler } from 'rxjs';
import { combineLatestWith, map, shareReplay, throttleTime } from 'rxjs/operators';
import { useMarketAccountsContext, useMarketsContext, useUserContext, type MarketCredential } from '../contexts';
import {
  ConnectionStatusContext,
  type ConnectionStatus,
  type MarketCredentialWithConnectionStatus,
} from '../contexts/ConnectionStatusContext';
import { useConstant, useObservable, useObservableValue, useStaticSubscription } from '../hooks';
import { wsScanToDoubleMap } from '../pipes/wsScanToDoubleMap';

const CONNECTION_STATUS = 'ConnectionStatus';
const THROTTLE_MS = 2000;

export const ConnectionStatusProvider = function ConnectionStatusProvider({ children }) {
  const { user, listMarketCredentials } = useUserContext();
  const { marketsWithConnTypesSupported } = useMarketsContext();
  const { marketAccountsByCredentialIDByName } = useMarketAccountsContext();

  const marketCredentials = useConstant(new ReplaySubject<MarketCredential[]>(1));

  const updateMarketCredentials = useCallback(() => {
    listMarketCredentials().then(credentials => {
      marketCredentials.next(credentials);
    });
  }, [listMarketCredentials, marketCredentials]);

  useEffect(() => {
    if (user != null) {
      updateMarketCredentials();
    } else {
      marketCredentials.next([]);
    }
  }, [user, updateMarketCredentials, marketCredentials]);

  const { data: subscription } = useStaticSubscription<ConnectionStatus>({
    name: CONNECTION_STATUS,
    tag: 'ConnectionStatusProvider',
  });

  const connectionStatusByCredentialIDAndMarketName = useObservable<Map<number, Map<string, ConnectionStatus>>>(
    () =>
      subscription.pipe(
        wsScanToDoubleMap({
          getKey1: d => d.CredentialID,
          getKey2: d => d.Market,
          newOuterMapEachUpdate: true,
          newInnerMapsEachUpdate: false,
        }),
        throttleTime(THROTTLE_MS, asyncScheduler, { leading: true, trailing: true }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const connectionStatusByConnectionIDAndMarketNameObservable = useObservable<
    Map<number, Map<string, ConnectionStatus>>
  >(
    () =>
      subscription.pipe(
        wsScanToDoubleMap({
          getKey1: d => d.ConnectionID,
          getKey2: d => d.Market,
          newOuterMapEachUpdate: true,
          newInnerMapsEachUpdate: false,
        }),
        throttleTime(THROTTLE_MS, asyncScheduler, { leading: true, trailing: true }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const marketCredentialsWithConnectionStatusByCredentialID = useObservable(
    () =>
      connectionStatusByCredentialIDAndMarketName.pipe(
        combineLatestWith(
          marketCredentials,
          marketsWithConnTypesSupported().pipe(map(markets => new Map(markets.map(m => [m.Name, m]))))
        ),
        map(
          ([statuses, credentials, markets]) =>
            new Map(
              // Order by Market Display Name, then Market Credential Label
              orderBy(credentials, [c => markets?.get(c.Market)?.DisplayName, 'Label']).map(c => [
                c.CredentialID,
                { credential: c, connectionStatus: statuses?.get(c.CredentialID)?.get(c.Market) },
              ])
            )
        )
      ),
    [connectionStatusByCredentialIDAndMarketName, marketCredentials, marketsWithConnTypesSupported]
  );

  const marketCredentialsWithConnectionStatusByMarketAccountName = useObservableValue<
    Map<string, MarketCredentialWithConnectionStatus>
  >(
    () =>
      marketCredentialsWithConnectionStatusByCredentialID.pipe(
        map(connsByCredId => {
          const mappedValues = [...connsByCredId.values()].flatMap(conn => {
            const marketAccountsForCredentialIDMap = marketAccountsByCredentialIDByName.get(
              conn.credential.CredentialID
            );
            if (!marketAccountsForCredentialIDMap) {
              return [];
            }

            const marketAccountNamesForCredentialID = [...marketAccountsForCredentialIDMap.keys()];
            return marketAccountNamesForCredentialID.map(marketAccountName => [marketAccountName, conn] as const);
          });

          return new Map(
            // Map entries are ordered by insertion order, so this will keep the `connsByCredId` ordering
            mappedValues
          );
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketAccountsByCredentialIDByName, marketCredentialsWithConnectionStatusByCredentialID],
    new Map()
  );

  const connectionStatusByConnectionIDAndMarketName = useObservableValue(
    () => connectionStatusByConnectionIDAndMarketNameObservable,
    [connectionStatusByConnectionIDAndMarketNameObservable]
  );

  const value = useMemo(
    () => ({
      updateMarketCredentials,
      connectionStatusByConnectionIDAndMarketName,
      connectionStatusByCredentialIDAndMarketName,
      marketCredentialsWithConnectionStatusByCredentialID,
      marketCredentialsWithConnectionStatusByMarketAccountName,
    }),
    [
      updateMarketCredentials,
      connectionStatusByConnectionIDAndMarketName,
      connectionStatusByCredentialIDAndMarketName,
      marketCredentialsWithConnectionStatusByCredentialID,
      marketCredentialsWithConnectionStatusByMarketAccountName,
    ]
  );
  return <ConnectionStatusContext.Provider value={value}>{children}</ConnectionStatusContext.Provider>;
};
