import { Get, useUserContext } from '@talos/kyoko';
import { createContext, useCallback, useContext } from 'react';

export const FeesContext = createContext<FeesContextProps | undefined>(undefined);
FeesContext.displayName = 'FeesContext';

/*
  Effective fees are those used by the OMS
  Exchange fees are those specified by a gateway to the exchange
  Custom fees are those overwritten by users
*/
export interface FeesContextProps {
  listMarketFees: () => Promise<Fee[]>;
  listCustomFees: () => Promise<Fee[]>;
  listEffectiveFees: () => Promise<Fee[]>;
}

export function useFees() {
  const context = useContext(FeesContext);
  if (context === undefined) {
    throw new Error('Missing FeesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const FeesProvider = function FeesProvider({ children }) {
  const { orgApiEndpoint } = useUserContext();

  const listMarketFees = useCallback(() => {
    return orgApiEndpoint
      ? Get(orgApiEndpoint, `/fees/markets`).then(results => {
          return results.data as Fee[];
        })
      : Promise.reject('Missing orgApiEndpoint');
  }, [orgApiEndpoint]);

  const listCustomFees = useCallback(() => {
    return orgApiEndpoint
      ? Get(orgApiEndpoint, `/fees/configs`).then(results => {
          return results.data as Fee[];
        })
      : Promise.reject('Missing orgApiEndpoint');
  }, [orgApiEndpoint]);

  const listEffectiveFees = useCallback(() => {
    return orgApiEndpoint
      ? Get(orgApiEndpoint, `/fees`).then(results => {
          return results.data as Fee[];
        })
      : Promise.reject('Missing orgApiEndpoint');
  }, [orgApiEndpoint]);

  return (
    <FeesContext.Provider value={{ listMarketFees, listCustomFees, listEffectiveFees }}>
      {children}
    </FeesContext.Provider>
  );
};

export interface Fee {
  Symbol?: string;
  MarketAccount: string;
  MakerFeeRate: string;
  TakerFeeRate: string;
}
