import {
  Box,
  CurrencyIcon,
  Divider,
  HStack,
  InlineFormattedNumber,
  NumberVariants,
  SummaryLineWrapper,
  Total,
  useCurrenciesContext,
} from '@talos/kyoko';
import Big from 'big.js';
import { Fragment, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';

const MAX_CURRENCIES_SHOWN = 100;

interface BalanceLike {
  Amount: string;
  Currency: string;
  Equivalent?: {
    Amount: string;
    Currency: string;
  };
}

interface BalancesBlotterSummaryLineProps {
  rows: BalanceLike[];
}

export function BalancesBlotterSummaryLine({ rows }: BalancesBlotterSummaryLineProps) {
  const { colors } = useTheme();
  const { homeCurrency } = useDisplaySettings();
  const { currenciesBySymbol } = useCurrenciesContext();
  const homeCurrencyInfo = currenciesBySymbol.get(homeCurrency);

  const totals = useMemo(() => {
    const { currencyAmounts: currencyAmountsMap, totalEquivalent } = rows.reduce(
      ({ currencyAmounts, totalEquivalent }, balance) => {
        const addAmount = Big(balance.Amount || '0');
        const addEquivalent = Big(balance.Equivalent?.Amount || '0');

        // If we don't find any entries for this currency just create a default with Big(0) on both fields
        const { amount, equivalent } = currencyAmounts.get(balance.Currency) ?? {
          amount: Big(0),
          equivalent: Big(0),
        };

        currencyAmounts.set(balance.Currency, {
          amount: amount.add(addAmount),
          equivalent: equivalent.add(addEquivalent),
        });

        totalEquivalent = totalEquivalent.plus(addEquivalent);

        return { currencyAmounts, totalEquivalent };
      },
      { currencyAmounts: new Map<string, { amount: Big; equivalent: Big }>(), totalEquivalent: Big(0) }
    );

    return {
      currencyAmounts: [...currencyAmountsMap.entries()]
        .sort(([, { equivalent: aEquivalent }], [, { equivalent: bEquivalent }]) => {
          // Sort by absolute equivalent values
          return bEquivalent.abs().minus(aEquivalent.abs()).toNumber();
        })
        // And safeguard performance wise
        .slice(0, MAX_CURRENCIES_SHOWN),
      totalEquivalent,
    };
  }, [rows]);

  return (
    <SummaryLineWrapper alignItems="center" w="100%">
      <Box pl="spacingSmall" pr="spacingSmall">
        <Total>Total</Total>
        <InlineFormattedNumber
          background={colors.gray['010']}
          number={totals.totalEquivalent}
          increment={homeCurrencyInfo?.DefaultIncrement}
          currency={homeCurrency}
          variant={totals.totalEquivalent.lt(0) ? NumberVariants.Negative : undefined}
        />
      </Box>
      <HStack gap="spacingSmall">
        {totals.currencyAmounts.map(([currency, { amount }]) => {
          const currencyInfo = currenciesBySymbol.get(currency);
          if (!currencyInfo) {
            return null;
          }

          return (
            <Fragment key={currency}>
              <Divider orientation="vertical" />
              <HStack gap="spacingSmall">
                <InlineFormattedNumber
                  background={colors.gray['010']}
                  number={amount}
                  increment={currencyInfo.DefaultIncrement}
                  currency={currency}
                  variant={amount.lt(0) ? NumberVariants.Negative : undefined}
                />
                <CurrencyIcon currency={currency} colorful />
              </HStack>
            </Fragment>
          );
        })}
      </HStack>
    </SummaryLineWrapper>
  );
}
