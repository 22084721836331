export const ORDER_SIDES = {
  BUY: 'Buy',
  SELL: 'Sell',
  TWOWAY: '2 way',
};

export const STRATEGY_GROUP = {
  ALL: 'All',
  DEALER: 'Dealer',
  EXCHANGE: 'Exchange',
  DARK: 'Dark',
};
