import { isEqual, sortBy } from 'lodash';
import { useState, type ReactNode } from 'react';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import { MixpanelEvent } from '../../tokens/mixpanel';
import { logger } from '../../utils';
import { Tab, TabAppearance, TabList, Tabs, type TabSize } from '../Tabs';
import { getAgGridColId, type Column } from './columns';
import type { UseBlotterTableUtilitiesOutput } from './types';

export const CUSTOM_BLOTTER_GROUPING = 'Custom';

export interface BlotterGroupingDefinition<AllowedGroupings extends string, GroupableColumns extends string> {
  key: AllowedGroupings;
  label: string;
  rowGroupColumns: GroupableColumns[];
  mixpanelEvent?: MixpanelEvent;
  buttonDataTestId?: string;
}

interface UseBlotterGroupingParams<AllowedGroupings extends string, GroupableColumns extends string> {
  /** Just a name for the blotter, used to identify it in a potential Sentry warning message. Can be anything. */
  blotterName: string;
  /** The initial columns of the blotter. Usually coming from usePersistedBlotterTable. */
  initialColumns: Column[];
  /** The definitions of the groupings you want to support. */
  groupings: BlotterGroupingDefinition<AllowedGroupings, GroupableColumns>[];
  /** The function this hook uses to update blotter grouping state when columns change. Taken from the return object of useBlotterTable. */
  setRowGroupColumns: UseBlotterTableUtilitiesOutput<any>['setRowGroupColumns'];
  /** Allow unselecting all groupings, effectively removing any grouping in the blotter. Default: false. */
  allowNoGrouping?: boolean;

  /** Size of the (tab) buttons returned */
  size?: TabSize;

  /** TreeData from GridOptions - as treeData views manage their groupings via autoColumnGroupDef Data Path, the functionality
   * of this hook is not needed. Default: false.
   */
  treeData?: boolean;
}

interface UseBlotterGroupingOutput {
  /** Function to call when blotter columns change. */
  updateGroupingOnColumnsChanged?: (columns: Column[]) => void;
  groupingToggleButtons: ReactNode;
}

export const useBlotterGroupingToggling = <AllowedGroupings extends string, GroupableColumns extends string>({
  blotterName,
  initialColumns,
  groupings,
  setRowGroupColumns,
  allowNoGrouping,
  treeData,
  size,
}: UseBlotterGroupingParams<AllowedGroupings, GroupableColumns>): UseBlotterGroupingOutput => {
  const mixpanel = useMixpanel();

  // if treeData is true, the reset of this hook is not needed as the autoGroupColumnDef will be used
  const columnsToGroupingMethod: typeof columnsToGrouping = treeData
    ? () => {
        return undefined;
      }
    : columnsToGrouping;

  // This state can be undefined -- which means that we are either are in a treeData view or in some unresolved grouping state.
  // The user resolves this by clicking on any of the grouping toggle buttons.
  // Shouldn't happen -- and if it does, we don't want to do anything to accidentally trigger an infinite re-render. Just leave be.
  const [selectedGrouping, setSelectedGrouping] = useState<AllowedGroupings | undefined>(
    columnsToGroupingMethod(initialColumns, groupings, blotterName)
  );

  const handleColumnsChanged = useDynamicCallback((columns: Column[]) => {
    setSelectedGrouping(columnsToGroupingMethod(columns, groupings, blotterName));
  });

  const handleToggleButtonClicked = useDynamicCallback(
    (grouping: BlotterGroupingDefinition<AllowedGroupings, GroupableColumns>) => {
      if (selectedGrouping === grouping.key && allowNoGrouping) {
        // If we're clicking the already selected grouping, and we allow "ungrouping", we remove all grouping
        setRowGroupColumns([]);
        mixpanel.track(MixpanelEvent.GroupingRemoved);
      } else {
        if (grouping.mixpanelEvent) {
          mixpanel.track(grouping.mixpanelEvent);
        }

        setRowGroupColumns(grouping.rowGroupColumns);
      }
    }
  );

  if (treeData) {
    return {
      updateGroupingOnColumnsChanged: undefined,
      groupingToggleButtons: null,
    };
  }

  return {
    updateGroupingOnColumnsChanged: handleColumnsChanged,
    groupingToggleButtons: (
      <Tabs
        appearance={TabAppearance.Pill}
        selectedIndex={groupings.findIndex(group => group.key === selectedGrouping)}
        w="auto"
        size={size}
      >
        <TabList suppressOverflowList={true}>
          {groupings.map(grouping => (
            <Tab
              key={grouping.key}
              onClick={() => handleToggleButtonClicked(grouping)}
              data-testid={grouping.buttonDataTestId}
              label={grouping.label}
            />
          ))}
        </TabList>
      </Tabs>
    ),
  };
};

export function columnsToGrouping<AllowedGroupings extends string, GroupableColumns extends string>(
  columns: Column<any>[],
  groupings: UseBlotterGroupingParams<AllowedGroupings, GroupableColumns>['groupings'],
  blotterName: string
): AllowedGroupings | undefined {
  // Find all columns which have rowGroup true, meaning that they are used in the grouping.
  // Then sort by their rowGroupIndex, which puts them in the order they are being used to group
  const groupingColIds = sortBy(
    columns.filter(column => column.rowGroup),
    'rowGroupIndex'
  ).map(getAgGridColId);

  const groupingBeingUsed = groupings.find(grouping => isEqual(groupingColIds, grouping.rowGroupColumns))?.key;

  if (groupingBeingUsed == null) {
    const maybeCustomGrouping = groupings.find(grouping => grouping.key === CUSTOM_BLOTTER_GROUPING);
    if (maybeCustomGrouping) {
      // We couldn't find any matching grouping spec, but we do support fully custom groupings, so we return that we are doing Custom grouping.
      return maybeCustomGrouping.key;
    }

    // We weren't able to find anything... shouldnt happen, something is wrong
    logger.warn(`Unable to resolve grouped colIds in blotter: ${blotterName}`, {
      extra: {
        colIds: columns.map(getAgGridColId),
      },
    });
  }

  return groupingBeingUsed;
}
