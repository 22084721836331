import { HStack, InlineFormattedNumber, PopoverContent, Text, VStack } from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import { useSubAccounts } from '../../../../../providers';
import { ReconAssetRow, type SubAccountReconOverviewRow } from './reconCheckpointRows';

export const SubAccountAmountTooltip = (props: ICellRendererParams<SubAccountReconOverviewRow>) => {
  const row = props.data;
  if (!(row instanceof ReconAssetRow)) {
    return '';
  }

  return (
    <PopoverContent>
      <SubAccountDetailsTable row={row} />
    </PopoverContent>
  );
};

const SubAccountDetailsTable = ({ row }: { row: ReconAssetRow }) => {
  const { subAccountsByName } = useSubAccounts();

  return (
    <VStack gap="spacingDefault" data-testid="sub-account-details-breakdown-table">
      {row.SubAccountDetails.SubAccounts.map(sa => (
        <HStack w="100%" justifyContent="space-between" gap="spacingDefault" key={sa.SubAccount}>
          <Text>{subAccountsByName.get(sa.SubAccount)?.DisplayName ?? sa.SubAccount}</Text>
          <InlineFormattedNumber number={sa.Amount} currency={row.Asset} align="right" />
        </HStack>
      ))}
    </VStack>
  );
};
