import {
  HISTORICAL_POSITION_STAT,
  formattedDateForSubscription,
  useObservableValue,
  useSubscription,
} from '@talos/kyoko';
import { tap } from 'rxjs';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import type { HistoricalPositionStat } from '../../types';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import type { HistoricalPositionsRequest } from './useChartSubscriptions';

/**
 * This hook subscribes to since-inception HistoricalPositionStat data and then lets the state provider know what
 * the actual LifeToDate StartDate is once it receives that information.
 */
export const useLifeToDateStartDate = () => {
  const { state, dispatch } = usePerformanceContext();
  const { homeCurrency } = useDisplaySettings();

  const { data } = useSubscription<HistoricalPositionStat>(
    state.chartRequestDateRange.StartDate == null
      ? ({
          name: HISTORICAL_POSITION_STAT,
          tag: 'PortfolioPerformanceLifeToDateCheck',
          Resolution: '1D',
          SubAccounts: state.subAccounts,
          EquivalentCurrency: homeCurrency,
          // This date is somewhat arbitrary -- it is just some date that's before when we started gathering HistoricalPositionStat
          // data points for any of our clients. We started doing it some time early 2023, so we hard code this LTD check StartDate
          // to simply be jan 1st 2023.
          StartDate: formattedDateForSubscription(new Date(2023, 0, 1, 0)),
          ...state.filter,
        } satisfies HistoricalPositionsRequest)
      : null
  );

  useObservableValue(
    () =>
      data.pipe(
        tap(json => {
          if (json.initial && json.data.length > 0) {
            dispatch({
              type: PerformanceActionType.LifeToDateStartTimeChange,
              payload: {
                lifeToDateStartTime: json.data[0].Timestamp,
              },
            });
          }
        })
      ),
    [data, dispatch]
  );
};
