import {
  BlotterTable,
  Box,
  Button,
  CustomerSettlementPerspectiveEnum,
  EMPTY_ARRAY,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  formattedDate,
  perspectiveLabel,
  useBehaviorSubject,
  useBlotterTable,
  useDynamicCallback,
  useGlobalToasts,
  useMixpanel,
  type MinimalSubscriptionResponse,
  type SettlementInstruction,
  type SettlementPreview,
  type UseBlotterTable,
} from '@talos/kyoko';
import type { ExcelRow, RowDataUpdatedEvent } from 'ag-grid-community';
import { exportMultipleSheetsAsExcel } from 'ag-grid-enterprise';
import { compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTradeSettlementRequests, type SettlementOption } from '../../useTradeSettlementRequests';
import type { SettleableTrade } from '../TradeSettlementBlotter/types';
import { useTradeSettlementColumns } from '../TradeSettlementBlotter/useTradeSettlementColumns';
import { useCustomerSettlementDetails } from './useCustomerSettlementDetails';

interface SettlementExportButtonProps {
  preview: SettlementPreview;
  settlementOption: SettlementOption;
  getInstructionsBlotterSheetData: UseBlotterTable<SettlementInstruction>['getSheetDataForExcel'];
}

/**
 * This component allows the user to export a Settlement Report.
 *
 * To build a settlement report, the component combines data from two places: The Settlement Instructions blotter
 * and a display-none Settleable Trade blotter this button instanciates.
 *
 * The flow of this component is: user clicks Export -> Make Trades request -> push results into blotter data observable -> receive row data updated
 * event from the blotter -> trigger excel export behavior.
 */
export const SettlementExportButton = ({
  preview,
  settlementOption,
  getInstructionsBlotterSheetData,
}: SettlementExportButtonProps) => {
  const mixpanel = useMixpanel();
  const { add: addToast } = useGlobalToasts();
  const [exporting, setExporting] = useState(false);
  const { gridDetails, ownerLabel, customerLabel } = useCustomerSettlementDetails({ preview, settlementOption });

  const [trades, setTrades] = useState<SettleableTrade[] | undefined>(undefined);
  // When the provided settlement option changes, it means were viewing new data. We should reset state.
  useEffect(() => {
    setExporting(false);
    setTrades(undefined);
  }, [settlementOption]);

  // Given the perspective the user has selected (dealer or client), we also apply this perspective to any trades we load for export.
  // This is the data set that should be used. Trades state above should only be used to derive this directional trades state.
  const directionalTrades = useMemo(() => {
    if (!trades) {
      return undefined;
    }

    // the trades we receive over the wire ("trades" state) are always in the dealer perspective.
    return preview.perspective === CustomerSettlementPerspectiveEnum.Dealer
      ? trades
      : trades.map(trade => trade.getAsOtherSide());
  }, [trades, preview.perspective]);

  const { previewSettlementTrades } = useTradeSettlementRequests();
  const handleExportClicked = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportSettlementReport, {
      [MixpanelEventProperty.Perspective]: preview.perspective,
    });
    setExporting(true);
    previewSettlementTrades(settlementOption)
      .then(trades => {
        setTrades(trades);
      })
      .catch((e: ErrorEvent) => {
        setExporting(false);
        addToast({
          variant: NotificationVariants.Negative,
          text: `Failed to fetch trades for report: ${e.message}`,
        });
      });
  });

  const handleRowDataUpdated = useDynamicCallback((event: RowDataUpdatedEvent<SettleableTrade>) => {
    // We trigger the export action when we hear about the blotter's row data updating. The row data updating happens as a result of us
    // fetching trades and pushing into the blotter.
    // This row data updated event does of course trigger more often than this though, so we need to only react to row data updated
    // when we are expecting it to update to the data to be used for exporting (hence the if-exporting clause)
    if (exporting) {
      const settlementDetailRows: ExcelRow[] = gridDetails.map(buildExcelRow);
      settlementDetailRows.push(buildExcelRow(['']));
      settlementDetailRows.unshift(buildExcelRow([`${ownerLabel} View Report`]));

      const sheets = compact([
        getInstructionsBlotterSheetData({
          sheetName: 'Preview',
          includeHiddenColumns: true,
          prependContent: settlementDetailRows,
        }),
        blotterTable.getSheetDataForExcel({ sheetName: 'Trades', includeHiddenColumns: true }),
      ]);

      exportMultipleSheetsAsExcel({
        data: sheets,
        fileName: `${customerLabel} Settlement Report - ${perspectiveLabel(preview.perspective)} View - ${formattedDate(
          new Date(),
          '{yyyy}-{MM}-{dd} {HH}:{mm}'
        )}`,
      });
      addToast({
        variant: NotificationVariants.Positive,
        text: 'Successfully exported report.',
      });
      setExporting(false);
    }
  });

  const { observable: directionalTradesDataObs } = useBehaviorSubject(
    () =>
      ({
        data: directionalTrades ?? [],
        initial: true,
        type: '',
        ts: '',
      } satisfies MinimalSubscriptionResponse<SettleableTrade>),
    [directionalTrades]
  );

  const columns = useTradeSettlementColumns({ defaultColumns: EMPTY_ARRAY });
  const blotterTable = useBlotterTable({
    dataObservable: directionalTradesDataObs,
    columns,
    rowID: 'TradeID' satisfies keyof SettleableTrade,
    sort: '-TransactTime',
    onRowDataUpdated: handleRowDataUpdated,
  });

  return (
    <>
      <Box display="none">
        <BlotterTable {...blotterTable} />
      </Box>
      <Button
        startIcon={IconName.Upload}
        size={FormControlSizes.Small}
        onClick={handleExportClicked}
        loading={exporting}
        disabled={exporting}
        dim
        data-testid="customer-settlement-export-button"
      >
        Export {perspectiveLabel(preview.perspective)} Report
      </Button>
    </>
  );
};

function buildExcelRow(values: string[]): ExcelRow {
  return {
    cells: values.map(value => ({ data: { type: 'str', value } })),
  } satisfies ExcelRow;
}
