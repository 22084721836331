import type { Options } from 'highcharts/highcharts';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { setAlpha } from '../../../styles';
import { getColorScale } from '../utils';

export function useDefaultChartOptions() {
  const theme = useTheme();
  return useMemo(() => {
    const options = {
      colors: getColorScale(theme, 12),

      accessibility: {
        enabled: false,
      },

      chart: {
        backgroundColor: 'transparent',
        style: {
          fontFamily: theme.fontFamily,
        },
        // Highcharts by default opts all HighchartsStock charts into zooming, which we don't want.
        zooming: {
          mouseWheel: {
            enabled: false,
          },
        },
      },

      time: {
        useUTC: false,
      },

      title: {
        text: '',
        style: {
          color: theme.colorTextImportant,
          font: `normal ${theme.fontSizeDefault}rem ${theme.fontFamily}`,
        },
      },

      subtitle: {
        style: {
          color: theme.colorTextDefault,
          font: `normal ${theme.fontSizeSmall}rem ${theme.fontFamily}`,
        },
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          label: {
            enabled: false,
          },
          states: {
            hover: {
              brightness: 0.2,
            },
          },
        },
        line: {
          label: {
            style: {
              fontFamily: theme.fontFamily,
            },
            enabled: false,
          },
        },
        candlestick: {
          lineColor: theme.colors.red.lighten,
          color: theme.colors.red.lighten,
          upColor: theme.colors.green.lighten,
          upLineColor: theme.colors.green.lighten,
        },
        column: {
          borderRadius: 0,
        },
        bar: {
          borderRadius: 0,
        },
        bubble: {
          dataLabels: {
            shadow: false,
            borderWidth: 0,
            padding: 0,
            allowOverlap: true,
            style: {
              fontFamily: theme.fontFamily,
              fontWeight: theme.fontWeightRegular,
              color: theme.colorTextImportant,
              textOutline: 'none',
              stroke: 'transparent',
            },
            enabled: true,
            format: '{point.name}',
          },
        },
      },

      xAxis: {
        gridLineColor: theme.borderColorChartAxis,
        gridLineWidth: 1,
        minorGridLineColor: setAlpha(0.25, theme.borderColorChartGridLine),
        minorGridLineWidth: 1,
        minorTickInterval: 'auto',
        labels: {
          style: {
            fontFamily: theme.fontFamily,
            color: theme.colorTextDefault,
            fontSize: `${theme.fontSizeTiny}rem`,
          },
        },
        lineColor: theme.borderColorChartGridLine,
        tickColor: theme.borderColorChartGridLine,
        title: {
          text: '',
          style: {
            color: theme.colorTextImportant,
            fontSize: `${theme.fontSizeSmall}rem`,
            fontFamily: theme.fontFamily,
          },
        },
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e %b',
          week: '%e %b',
          month: "%b '%y",
          year: '%Y',
        },
      },

      yAxis: {
        gridLineColor: theme.borderColorChartAxis,
        labels: {
          style: {
            fontFamily: theme.fontFamily,
            color: theme.colorTextDefault,
            fontSize: `${theme.fontSizeTiny}rem`,
          },
        },
        lineColor: theme.borderColorChartGridLine,
        lineWidth: 1,
        tickColor: theme.borderColorChartGridLine,
        tickWidth: 1,
        title: {
          text: '',
          style: {
            color: theme.colorTextImportant,
            fontSize: `${theme.fontSizeSmall}rem`,
            fontFamily: theme.fontFamily,
          },
        },
      },

      tooltip: {
        backgroundColor: theme.backgroundPopover,
        shadow: false,
        borderWidth: 0,
        style: {
          fontFamily: theme.fontFamily,
          color: theme.colorTextImportant,
        },
      },

      legend: {
        align: 'right',
        layout: 'horizontal',
        verticalAlign: 'top',
        symbolPadding: 5,
        symbolWidth: 8,
        symbolHeight: 8,
        symbolRadius: 8,
        itemStyle: {
          font: `${theme.fontSizeTiny}rem ${theme.fontFamily}`,
          color: theme.colorTextDefault,
        },
        itemHoverStyle: {
          color: theme.colorTextImportant,
        },
      },
      credits: {
        enabled: false,
      },
    } satisfies Options;
    return options;
  }, [theme]);
}
