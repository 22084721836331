import {
  MARKET_SECURITY_STATUS,
  useObservableValue,
  useSubscription,
  wsScanToMap,
  type MarketSecurityStatus,
} from '@talos/kyoko';
import { get } from 'lodash';
import { useMemo } from 'react';
import type { AvailabilityCondition } from '../types';

type CapabilityKey = keyof NonNullable<MarketSecurityStatus['Capabilities']>;

/**
 * A AvailabilityCondition which asserts that the MarketSecurityStatus.[Capability] is true.
 *
 * If no MarketSecurityStatus has been resolved and attached to the passed record, returns true.
 * If there is no Capabilities struct on the successfully resolved MarketSecurityStatus record, returns false.
 */
export const useMSSCapabilityEnabledCondition = (
  symbol: string | undefined,
  capability: CapabilityKey
): AvailabilityCondition => {
  const { data: marketSecurityStatusesObs } = useSubscription<MarketSecurityStatus>(
    symbol ? { name: MARKET_SECURITY_STATUS, tag: `MarketSelector:${symbol}`, Symbols: [symbol] } : null
  );

  const securityStatusesByMarketAccount = useObservableValue(
    () =>
      marketSecurityStatusesObs.pipe(wsScanToMap({ getUniqueKey: item => item.MarketAccount, newMapEachUpdate: true })),
    [marketSecurityStatusesObs]
  );

  return useMemo(
    () =>
      ({
        id: `mss-${capability}-enabled`,
        ready: securityStatusesByMarketAccount != null,
        condition: (market, marketAccount) => {
          if (!securityStatusesByMarketAccount) {
            return { available: true };
          }

          if (!marketAccount) {
            // MarketSecurityStatuses are only applicable for market accounts
            return { available: true };
          }

          const status = securityStatusesByMarketAccount.get(marketAccount.Name);

          // Not all items will have their own MSS resolvable. In these cases, just allow them to proceed.
          if (!status) {
            return { available: true };
          }

          const capabilitiesStruct = status.Capabilities;
          if (!capabilitiesStruct) {
            return {
              available: false,
              failedReason: `Capability ${capability} is not enabled for this Market Account`,
            };
          }

          const capabilityOk = get(capabilitiesStruct, capability) === true;

          return capabilityOk
            ? { available: true }
            : {
                available: false,
                failedReason: `Capability ${capability} is not enabled for this Market Account`,
              };
        },
      } satisfies AvailabilityCondition),
    [capability, securityStatusesByMarketAccount]
  );
};
