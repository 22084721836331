import { compactArray } from '@talos/kyoko';
import type { StackedChartDataItem } from './InnerStackedChart';

/**
 * Based on the inner stack data, filter and sort the chart display series
 *
 * @returns The sorted and filtered result, in order to produce a chart with large series
 *
 * For clarity, the sort order for the desired series display is the following:
 * - Most to least positive, followed by optional 'Other'
 * followed by
 * - Most to least negative, followed by optional 'Other'
 */
export function filterAndSortChartDisplaySeries(data: StackedChartDataItem[]): StackedChartDataItem[] {
  const {
    filteredData: filteredChartData,
    minOther: otherMinItem,
    maxOther: otherMaxItem,
  } = filterStackedChartDisplaySeries(data);

  const posValuesSorted = filteredChartData.filter(item => item.value > 0).sort((a, b) => b.value - a.value);

  const negValuesSorted = filteredChartData
    .filter(item => item.value < 0)
    .sort((a, b) => Math.abs(b.value) - Math.abs(a.value));

  return compactArray([...posValuesSorted, otherMaxItem, ...negValuesSorted, otherMinItem]);
}

/**
 * Highcharts stacked charts starts showing a very thin stack when the data is over 48 entries long.
 *
 * Filter (really constrain) the minimum positive and minimum negative values, whichever is smaller, until the
 * data is under 48 entries; if 'other' items sit at the edges, add 'Other' items to fill in the typically tiny gaps
 */
export function filterStackedChartDisplaySeries(data: StackedChartDataItem[]): {
  filteredData: StackedChartDataItem[];
  maxOther: StackedChartDataItem | undefined;
  minOther: StackedChartDataItem | undefined;
} {
  if (data.length <= 48) {
    return {
      filteredData: data,
      maxOther: undefined,
      minOther: undefined,
    };
  }
  const dataToAnalyze = data.filter(val => val.value !== 0);
  const sortableNegativeFromSmallToLarge = dataToAnalyze
    .filter(item => item.value < 0)
    .sort((a, b) => b.value - a.value);
  const sortablePositiveFromLargeToSmall = dataToAnalyze
    .filter(item => item.value > 0)
    .sort((a, b) => b.value - a.value);

  const arrayToEval = sortableNegativeFromSmallToLarge.concat(sortablePositiveFromLargeToSmall);
  let otherMinTotal = 0;
  let otherMaxTotal = 0;

  let min = 0;
  let max = arrayToEval.length - 1;

  while (max - min >= 48) {
    if (Math.abs(arrayToEval[min].value) < Math.abs(arrayToEval[max].value)) {
      otherMinTotal += arrayToEval[min].value;
      min++;
    } else {
      otherMaxTotal += arrayToEval[max].value;
      max--;
    }
  }

  // add the other min and max items if they exist
  let otherMinItem: StackedChartDataItem | undefined;
  let otherMaxItem: StackedChartDataItem | undefined;
  if (otherMinTotal !== 0) {
    otherMinItem = {
      value: otherMinTotal + arrayToEval[min].value,
      asset: 'Other',
      assetDescription: '',
      color: undefined,
    };
    min++;
  }
  if (otherMaxTotal !== 0) {
    otherMaxItem = {
      value: otherMaxTotal + arrayToEval[max].value,
      asset: 'Other',
      assetDescription: '',
      color: undefined,
    };
    max--;
  }

  return {
    filteredData: arrayToEval.slice(min, max + 1),
    maxOther: otherMaxItem,
    minOther: otherMinItem,
  };
}
