import { uniq } from 'lodash';
import { BLOTTER_ID_OPEN_ORDERS } from '../../containers/Blotters/Orders/tokens';
import { TRADES_BLOTTER_PREFIX } from '../../containers/Blotters/Trades/tokens';
import { BLOTTER_ID_V1_OLD_RECENT_ORDERS, BLOTTER_ID_V1_OLD_RECENT_TRADES } from '../../tokens/blotters';
import { type GeneralMarketTab, type MarketTab, MarketTabType } from '../MarketTabs.types';
import { enableSmartLabelledAnalyticsTabs } from './AppConfigMigrations/AnalyticsTabsMigration';
import {
  renameBalancesBlotterTabColumns,
  renamePersistedBalancesBlotterColumns,
} from './AppConfigMigrations/BalancesBlotterColumnMigrations';
import { replaceDealerMonitoringFilters } from './AppConfigMigrations/DealerMonitoringMigration';
import {
  pruneOrderDetailsBlotterState,
  prunePersistedBlotterTableState,
} from './AppConfigMigrations/PrunePersistedBlotterTableState';
import { ensureReconMismatchesBlotterTabsHaveStartDate } from './AppConfigMigrations/ReconMismatchesBlotterMigrations';
import { removeUseNewPositionsBlotter2 } from './AppConfigMigrations/RemoveUseNewPositionsBlotter2';
import type { AppConfigState } from './AppConfigProvider.types';
import { convertMarketTabsToDockviewLayout } from './convertMarketTabsToDockviewLayout';
import { replaceBlotterV1Config } from './replaceBlotterV1Config';

export function replaceLegacyConfig(c: any): AppConfigState {
  const replacedConfig = { ...c };
  if (c.subAccountId != null) {
    delete replacedConfig.subAccountId;
    replacedConfig.defaultSubAccountId = c.subAccountId;
  }
  if (c.order?.enableGroups != null) {
    delete replacedConfig.order;
    replacedConfig.enableGroups = c.order.enableGroups;
  }
  if (c.columns) {
    delete replacedConfig.columns;
  }

  // Remove old Dealer Blotter Filter IDs
  delete replacedConfig.filteredIDCustomerUsers;
  delete replacedConfig.filteredIDCustomerBalances;
  delete replacedConfig.filteredIDCustomerTradingLimits;

  if (replacedConfig.enableSalesRFQ != null) {
    replacedConfig.enableSalesWorkflow = replacedConfig.enableSalesRFQ;
    delete replacedConfig.enableSalesRFQ;
  }

  if (typeof c.customEOD === 'number') {
    // Some old configs have the custom EOD saved as a number representing hours
    c.customEOD = {
      hours: `${c.customEOD}`,
      minutes: DEFAULT_DATE_PICKER_EOD.minutes,
    };
  }
  // Ensure that the MarketDataCard configurations have selectedMarketAccounts defined
  if (Array.isArray(replacedConfig.marketTabs)) {
    replacedConfig.marketTabs.forEach(marketTab => {
      if (marketTab && Array.isArray(marketTab.columns)) {
        marketTab.columns.forEach(col => {
          col.forEach(card => {
            card.availableMarketAccounts = uniq(card.availableMarketAccounts ?? []);
            card.selectedMarketAccounts = uniq(card.selectedMarketAccounts ?? []);
            card.availableMarkets = uniq(card.availableMarkets ?? []);
            card.selectedMarkets = uniq(card.selectedMarkets ?? []);
          });
        });
      }
    });

    replacedConfig.layoutConfigs = replacedConfig.layoutConfigs ?? {
      trading: {
        default: convertMarketTabsToDockviewLayout(replacedConfig.marketTabs),
      },
      selectedLayoutIds: {
        trading: 'default',
      },
    };
  }

  if (replacedConfig.layoutConfigs != null) {
    const config = replacedConfig as AppConfigState;
    // Default trading layout watchlist panel should use default tab component and not readOnly
    const watchlistPanel = config?.layoutConfigs?.trading?.default?.dockViewLayout?.panels?.watchlist;
    if (watchlistPanel?.tabComponent === 'readOnly') {
      watchlistPanel.tabComponent = undefined;
    }
  }

  // Migrate v1 recent orders blotter to v2
  if (replacedConfig.blotters != null && BLOTTER_ID_V1_OLD_RECENT_ORDERS in replacedConfig.blotters) {
    replaceBlotterV1Config(replacedConfig, BLOTTER_ID_V1_OLD_RECENT_ORDERS, BLOTTER_ID_OPEN_ORDERS, '-SubmitTime', {
      submitTime: 'SubmitTime',
      side: 'Side',
      symbol: 'Symbol',
      orderQty: 'OrderQty',
      cumQty: 'CumQty',
      price: 'Price',
      filledPrice: 'filledPx',
      cumFee: 'CumFee',
      marketAccounts: 'orderMarketAccounts',
      strategy: 'Strategy',
      subAccount: 'SubAccount',
      user: 'User',
      filledPercent: 'filledPercent',
      ordStatus: 'OrdStatus',
      filledPriceNoFees: 'AvgPx',
      filledPriceWithFees: 'AvgPxAllIn',
      markets: 'Markets',
      filledNotional: 'filledNotional',
      openNotional: 'openNotional',
      totalNotional: 'totalNotional',
      orderMarkets: 'orderMarkets',
      group: 'Group',
      productType: 'productType',
      endTime: 'EndTime',
      startTime: 'StartTime',
      timestamp: 'Timestamp',
      baseQuantity: 'baseQty',
      ordType: 'OrdType',
      orderID: 'OrderID',
      clOrdID: 'ClOrdID',
      leavesQty: 'LeavesQty',
      remainQuantity: 'remainQty',
      timeInForce: 'TimeInForce',
    });
  }

  // Migrate v1 recent trades blotter to v2
  if (replacedConfig.blotters != null && BLOTTER_ID_V1_OLD_RECENT_TRADES in replacedConfig.blotters) {
    replaceBlotterV1Config(
      replacedConfig,
      BLOTTER_ID_V1_OLD_RECENT_TRADES,
      `${TRADES_BLOTTER_PREFIX}/recent-trades`,
      '-TransactTime',
      {
        transactTime: 'TransactTime',
        side: 'Side',
        symbol: 'Symbol',
        quantity: 'Quantity',
        price: 'Price',
        priceAllIn: 'PriceAllIn',
        amount: 'Amount',
        fee: 'Fee',
        user: 'User',
        tradeID: 'TradeID',
        marketAccount: 'MarketAccount',
        market: 'Market',
        subAccount: 'SubAccount',
        group: 'Group',
        orderID: 'OrderID',
        marketTradeID: 'MarketTradeID',
        timestamp: 'Timestamp',
        dealtCurrency: 'DealtCurrency',
        tradeStatus: 'TradeStatus',
        aggressorSide: 'AggressorSide',
        comments: 'Comments',
      }
    );
  }

  // Remove any temporary market tab and ensure mandatory property exist
  if (replacedConfig.marketTabs != null) {
    replacedConfig.marketTabs = replacedConfig.marketTabs
      .filter((tab: MarketTab) => !tab.isTemporary)
      .map((tab: MarketTab) => {
        if (!tab.type) {
          // Some configs may not have type saved before, we need to fix that as it's mandatory field now
          (tab as GeneralMarketTab).type = MarketTabType.Market;
        }
        return tab;
      });
  }

  ensureReconMismatchesBlotterTabsHaveStartDate(replacedConfig);
  renamePersistedBalancesBlotterColumns(replacedConfig);
  renameBalancesBlotterTabColumns(replacedConfig);
  removeUseNewPositionsBlotter2(replacedConfig);
  replaceDealerMonitoringFilters(replacedConfig);
  prunePersistedBlotterTableState(replacedConfig);
  pruneOrderDetailsBlotterState(replacedConfig);
  enableSmartLabelledAnalyticsTabs(replacedConfig);

  return replacedConfig;
}
export const DEFAULT_DATE_PICKER_EOD = { hours: '17', minutes: '00' };
