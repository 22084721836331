import { Box, Dialog, Input, Text, type DialogProps } from '@talos/kyoko';
import { TitleRow } from 'containers/Dealer/styles';
import { forwardRef, useState } from 'react';

interface NewAggregationDialogProps extends DialogProps {
  onConfirm?: (aggregation: { name: string; displayName: string }) => void;
}

export const NewAggregationDialog = forwardRef<HTMLDivElement | null, NewAggregationDialogProps>(
  function NewAggregationDialog({ onConfirm, ...props }: NewAggregationDialogProps, ref) {
    const [name, setName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const handleConfirm = () => onConfirm?.({ name, displayName });
    return (
      <Dialog {...props} ref={ref} onConfirm={handleConfirm}>
        <Box mb="spacingDefault">
          <TitleRow>
            <Text>Name</Text>
          </TitleRow>
          <Input value={name} autoComplete="off" onChange={e => setName(e.target.value)} />
        </Box>
        <Box>
          <TitleRow>
            <Text>Display Name</Text>
          </TitleRow>
          <Input value={displayName} autoComplete="off" onChange={e => setDisplayName(e.target.value)} />
        </Box>
      </Dialog>
    );
  }
);
