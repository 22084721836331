import {
  useObservable,
  useSubscription,
  type OrderAnalytic,
  type ResolutionEnumNoPrefix,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { shareReplay } from 'rxjs/operators';

/**
 * Subscribe to `OrderAnalytic` given an `orderID` and a `resolution`. No subscription is made if `orderID` is missing.
 * @param orderID string
 * @param resolution ResolutionEnumNoPrefix
 * @param tag string
 */
export function useOrderAnalytics({
  orderID,
  resolution,
  tag,
}: {
  orderID?: string;
  resolution?: ResolutionEnumNoPrefix;
  tag: string;
}) {
  const [request, setRequest] = useState<{
    name: 'OrderAnalytic';
    tag: string;
    Resolution: ResolutionEnumNoPrefix;
    OrderID: string;
  } | null>(null);

  useEffect(() => {
    if (orderID == null || resolution == null) {
      setRequest(null);
    } else {
      setRequest({
        name: 'OrderAnalytic',
        tag,
        Resolution: resolution,
        OrderID: orderID,
      });
    }
  }, [orderID, resolution, tag]);

  const { data: analyticsSub } = useSubscription<SubscriptionResponse<OrderAnalytic, 'OrderAnalytic'>>(request, {
    loadAll: true,
  });

  return useObservable<SubscriptionResponse<OrderAnalytic, 'OrderAnalytic'>>(
    () =>
      analyticsSub.pipe(
        shareReplay({
          bufferSize: 5, // Larger buffer size as execution chart sometimes is slow to mount
          refCount: true,
        })
      ),
    [analyticsSub]
  );
}
