import { MixpanelEvent, useEventListener, useMixpanel, useNotes } from '@talos/kyoko';
import type React from 'react';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface BeforeInstallPromptevent extends Event {
  prompt: () => Promise<{
    outcome: 'accepted' | 'dismissed';
  }>;
}

interface AppInstallPromptContextProps {
  installPrompt: BeforeInstallPromptevent | undefined;
}

const INSTALL_WEB_APP_NOTE_ID = 'install-web-app';

const AppInstallPromptContext = createContext<AppInstallPromptContextProps | undefined>(undefined);

export const AppInstallPromptProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [installPrompt, setInstallPrompt] = useState<BeforeInstallPromptevent>();
  const { addNote, removeNote } = useNotes();
  const mixpanel = useMixpanel();

  // @ts-expect-error beforeinstallprompt is not a standard event
  useEventListener('beforeinstallprompt', (event: BeforeInstallPromptevent) => {
    setInstallPrompt(event);
    event.preventDefault();
  });

  const installAppPrompt = useCallback(() => {
    if (installPrompt == null) {
      return;
    }

    installPrompt.prompt().then((choiceResult: { outcome: 'dismissed' | 'accepted' }) => {
      removeNote(INSTALL_WEB_APP_NOTE_ID);
      if (choiceResult.outcome === 'dismissed') {
        mixpanel.track(MixpanelEvent.WebAppDismissed);
      }
    });
  }, [installPrompt, removeNote, mixpanel]);

  useEffect(() => {
    if (installPrompt == null) {
      return;
    }
    // Disable until we wan't to make users aware that they can "install" the app
    // addNote({
    //   id: INSTALL_WEB_APP_NOTE_ID,
    //   text: (
    //     <>
    //       <strong>Talos is now available as a standalone web app.</strong> Click install to add it to your desktop
    //     </>
    //   ),
    //   closable: true,
    //   onDismiss: () => {
    //     mixpanel.track(MixpanelEvent.WebAppDismissed);
    //   },
    //   icon: IconName.DeviceDesktop,
    //   variant: NoteVariant.Primary,
    //   actions: (
    //     <Button size={FormControlSizes.Small} variant={ButtonVariants.Default} onClick={installAppPrompt}>
    //       Install
    //     </Button>
    //   ),
    // });
  }, [installPrompt, installAppPrompt, addNote, mixpanel]);

  return <AppInstallPromptContext.Provider value={{ installPrompt }}>{children}</AppInstallPromptContext.Provider>;
};

export const useAppInstallPrompt = (): AppInstallPromptContextProps => {
  const context = useContext(AppInstallPromptContext);
  if (!context) {
    throw new Error('useAppInstallPrompt must be used within an AppInstallPromptProvider');
  }
  return context;
};
