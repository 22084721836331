import { MixpanelEvent, useDynamicCallback, useMixpanel } from '@talos/kyoko';
import { ErrorBoundary } from 'components/ErrorBoundary';

export const PEFrame = function PEFrame({ src, title }: { src: string; title: string }) {
  const mixpanel = useMixpanel();
  const handleLoadFrame = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.LoadApp, { app: title });
  });

  return (
    <ErrorBoundary>
      <iframe src={src} title={title} style={{ border: 'none', width: '100%' }} onLoad={handleLoadFrame} />
    </ErrorBoundary>
  );
};
export default PEFrame;
