import { useCallback, useEffect, useState } from 'react';
import type { ExecutionTimelineEntry, WithTimestamp } from '../ExecutionTimeline/types';

function isScrollable(element: HTMLDivElement): boolean {
  return element.scrollHeight > element.offsetHeight;
}

function isScrollAtBottom(element: HTMLDivElement): boolean {
  return element.scrollTop + element.offsetHeight < element.scrollHeight;
}

export const useScrollToBottom = <T extends WithTimestamp>(
  scrollListElement: HTMLDivElement | null,
  items: ExecutionTimelineEntry<T>[]
) => {
  const [showToBottom, setShowToBottom] = useState(false);

  const handleScroll = useCallback(() => {
    if (scrollListElement) {
      setShowToBottom(isScrollable(scrollListElement) && isScrollAtBottom(scrollListElement));
    }
  }, [scrollListElement]);

  useEffect(() => {
    scrollListElement?.addEventListener('scroll', handleScroll);
    return () => scrollListElement?.removeEventListener('scroll', handleScroll);
  }, [scrollListElement, handleScroll]);

  useEffect(() => {
    if (scrollListElement) {
      setShowToBottom(isScrollable(scrollListElement) && isScrollAtBottom(scrollListElement));
    }
  }, [scrollListElement, items]);

  return {
    showToBottom,
  };
};
