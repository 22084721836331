import type { LayoutSpec } from '../../../types/LayoutConfig';
import { FEED_CONSTRAINTS, OMS_FORMS_CONSTRAINTS, WATCHLIST_CONTSTRAINTS } from './shared';
import { SYMBOL_VIEWS_LAYOUT } from './SymbolViews';

export const SYMBOL_VIEWS_LAYOUT_SPEC: LayoutSpec = {
  label: 'Symbol views',
  id: 'symbolViews',
  dockViewLayout: SYMBOL_VIEWS_LAYOUT,
  constraints: [WATCHLIST_CONTSTRAINTS, OMS_FORMS_CONSTRAINTS, FEED_CONSTRAINTS],
  collapsablePanels: [],
};
