import {
  Button,
  ButtonSelect,
  FormControlSizes,
  JITTooltip,
  MixpanelEvent,
  ProductTypeEnum,
  ReduceOnlyEnum,
  UnifiedLiquidityEnum,
  toBigWithDefault,
  useDynamicCallback,
  useMixpanel,
  type AutocompleteGroupSorterFunc,
  type ButtonSelectProps,
  type Security,
} from '@talos/kyoko';
import type { PrimeOMSParams } from 'components/OMS/NewOrder/types';
import { OMSView } from 'components/OMS/OMSView';
import { capitalize, compact, get } from 'lodash';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useMemo, useState } from 'react';
import type { AppState } from '../../providers/AppStateProvider/types';
import type { OMSFormOrderParameters } from '../../providers/OMSContext.types';
import type { PositionLike, TradePositionButtonAction } from '../../utils/getPositionSecurities';
import { useGetTradePositionDetails, type GetTradePositionDetailsParams } from '../../utils/getTradePositionDetails';
import { primeNewOrderForm } from '../OMS/NewOrder/OrderSlice';
import { openView } from '../OMS/OMSSlice';
import { useGetReduceOnlySupport } from '../OMS/useGetReduceOnlySupport';
import { getPrimeOrderFormInstructions } from './getPrimeOrderFormInstructions';
import { useCommonTradeSpotPositionCurrencies } from './useCommonTradeSpotPositionCurrencies';

export type TradePositionButtonProps<T extends PositionLike> = {
  /** Define what asset type all entities are, or a function defining how to resolve the asset type per entitiy. */
  getAssetType: ProductTypeEnum | ((item: T) => ProductTypeEnum);
  /** If you want to be closing positions using sub accounts present on the PositionLike entity, you need to provide a SubAccount field so the column can resolve it. */
  subAccountField?: keyof T;
  /** The action the button rendered in the column should represent -- either a buy, sell or a "close" (buy or sell depending on Amount) */
  action: TradePositionButtonAction;
} & Pick<
  GetTradePositionDetailsParams<T>,
  'position' | 'assetField' | 'constrainSecuritiesToMarket' | 'marketAccountField'
>;

export function TradePositionButton<T extends PositionLike>({
  position,
  action,
  getAssetType,
  assetField,
  constrainSecuritiesToMarket,
  marketAccountField,
  subAccountField,
}: TradePositionButtonProps<T>) {
  const mixpanel = useMixpanel();
  const assetType = resolveAssetType(position, getAssetType);

  const { getReduceOnlySupport } = useGetReduceOnlySupport();
  const strategyField = useAppStateSelector((state: AppState) => state.order.form.strategyField);
  const unifiedLiquidity = useAppStateSelector((state: AppState) => state.order.form.parameters?.UnifiedLiquidity);
  const isUnifiedLiquidityEnabled = unifiedLiquidity?.value === UnifiedLiquidityEnum.Enabled;
  const getTradePositionDetails = useGetTradePositionDetails();

  const asset: string | undefined = useMemo(() => {
    const resolved = position && assetField ? get(position, assetField) : undefined;
    return typeof resolved === 'string' ? resolved : undefined;
  }, [position, assetField]);

  const dispatch = useAppStateDispatch();
  const handlePrimeTradePosition = useDynamicCallback(
    ({ position, securityToTradeThrough }: { position: PositionLike; securityToTradeThrough: Security }) => {
      if (!securityToTradeThrough || !position || !asset) {
        return; // just for safety...
      }

      const marketAccounts: string[] = compact([marketAccountField ? get(position, marketAccountField) : undefined]);

      const parameters: OMSFormOrderParameters = {
        reduceOnly: getReduceOnlySupport(
          securityToTradeThrough,
          strategyField.value,
          marketAccounts,
          isUnifiedLiquidityEnabled
        )
          ? ReduceOnlyEnum.Enabled
          : ReduceOnlyEnum.Disabled,
      };

      const { currency, side, amount } = getPrimeOrderFormInstructions({
        asset,
        positionAmount: position.Amount,
        securityToTradeThrough,
        action,
      });

      const amountBig = toBigWithDefault(amount, 0);
      // Only prime with an amount if our amount is non-zero. Priming with 0 never makes sense.
      const nonZeroAmount = amountBig.eq(0) ? undefined : amountBig.abs().toFixed();

      const primeOmsParams: PrimeOMSParams = {
        symbol: securityToTradeThrough.Symbol,
        side,
        orderQty: nonZeroAmount,
        marketAccounts,
        subAccount: subAccountField ? get(position, subAccountField) : undefined,
        currency,
        parameters,
      };

      dispatch(primeNewOrderForm(primeOmsParams));
      dispatch(openView(OMSView.NewOrderForm));
    }
  );

  const handleSelection = useDynamicCallback((security: Security) => {
    if (!position) {
      return;
    }
    mixpanel.track(MixpanelEvent.ClosePosition);
    handlePrimeTradePosition({ position, securityToTradeThrough: security });
  });

  const { notTradableReason, tradeButtonVisible, tradable, possibleSecurities } = useMemo(
    () =>
      getTradePositionDetails({
        position,
        assetField: assetField,
        constrainSecuritiesToMarket,
        marketAccountField,
        subAccountField,
        assetType,
        action,
      }),
    [
      getTradePositionDetails,
      position,
      assetField,
      constrainSecuritiesToMarket,
      marketAccountField,
      assetType,
      action,
      subAccountField,
    ]
  );

  if (!tradeButtonVisible || !asset) {
    return <></>;
  }

  return assetType === ProductTypeEnum.Spot ? (
    <TradeButtonForSpot
      possibleSecurities={possibleSecurities}
      onSelection={handleSelection}
      action={action}
      notTradableReason={notTradableReason}
      asset={asset}
      dataTestID="close-position-button"
    />
  ) : (
    <JITTooltip tooltip={notTradableReason}>
      <Button
        onClick={() => handleSelection(possibleSecurities[0])}
        size={FormControlSizes.Small}
        disabled={!tradable}
        data-testid="close-position-button"
      >
        {capitalize(action)}
      </Button>
    </JITTooltip>
  );
}

function resolveAssetType<T extends PositionLike>(
  positionLike: T | undefined,
  getAssetType: TradePositionButtonProps<T>['getAssetType']
) {
  if (typeof getAssetType === 'function') {
    return positionLike ? getAssetType(positionLike) : undefined;
  }

  return getAssetType;
}

interface TradeButtonForSpotProps {
  possibleSecurities: Security[];
  onSelection: (security: Security) => void;
  action: TradePositionButtonProps<any>['action'];
  notTradableReason: string | undefined;
  asset: string;
  dataTestID?: string;
}

const autoCompleteGroupSorterFunc: AutocompleteGroupSorterFunc = (a, b) => {
  // We have 2 groups, common and all, sort common to the top.
  return a.group === 'Common' ? -1 : 1;
};

// We render different buttons based on if we're gonna be closing a spot or derivative position.
function TradeButtonForSpot({
  possibleSecurities,
  onSelection,
  action,
  notTradableReason,
  asset,
  dataTestID,
}: TradeButtonForSpotProps) {
  const [renderSelector, setRenderSelector] = useState(false);
  const commonCurrencies = useCommonTradeSpotPositionCurrencies();

  const commonCurrenciesSet = useMemo(
    // If the asset we're trying to perform an action on is itself in the common set, then we create a new common subset
    // without "ourselves", otherwise every spot trading pair would be regarding as "common"
    () => new Set(commonCurrencies.filter(c => c !== asset)),
    [commonCurrencies, asset]
  );

  const searchSelectProps: ButtonSelectProps<Security>['useSearchSelectProps'] = useMemo(
    () => ({
      items: possibleSecurities,
      getLabel: security => security.DisplaySymbol,
      getGroup: security => {
        const isCommon =
          commonCurrenciesSet.has(security.BaseCurrency) || commonCurrenciesSet.has(security.QuoteCurrency);
        return isCommon ? 'Common' : 'All';
      },
      groupSorter: autoCompleteGroupSorterFunc,
      initialIsOpen: true, // this needs to be true since we only render the selector after a click has happened
    }),
    [possibleSecurities, commonCurrenciesSet]
  );

  const dropdownProps: ButtonSelectProps<Security>['useDropdownPopperProps'] = useMemo(
    () => ({
      dropdownWidth: '200px',
      dropdownPlacement: 'bottom',
    }),
    []
  );

  // All this conditional logic below here is for performance reasons.
  // We only want to render the ButtonSelect, which in turn would initialize all the downshift and dropdown-popper stuff, when we click to open the selector.
  // Similarly for the tooltip, we only need to render the tooltip if there's something we want to show in it, and if the user is actually hovering over the content (JustInTimeTooltip)

  function renderButton() {
    return renderSelector ? (
      <ButtonSelect
        size={FormControlSizes.Small}
        useSearchSelectProps={searchSelectProps}
        useDropdownPopperProps={dropdownProps}
        onSelection={onSelection}
        disabled={notTradableReason != null}
        onClose={() => setRenderSelector(false)}
        dropdownHeight={280}
        dataTestID={dataTestID}
      >
        {capitalize(action)}
      </ButtonSelect>
    ) : (
      <Button
        size={FormControlSizes.Small}
        onClick={() => setRenderSelector(true)}
        disabled={notTradableReason != null}
        data-testid={dataTestID}
      >
        {capitalize(action)}
      </Button>
    );
  }

  // Only render the tooltip if we're disabled, on top of it also being a JustInTime tooltip.
  return notTradableReason ? <JITTooltip tooltip={notTradableReason}>{renderButton()}</JITTooltip> : renderButton();
}
