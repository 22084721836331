import { Flex } from '@talos/kyoko';
import styled from 'styled-components';
import { FormRow } from '../../../components/Form';

export const SubAccountFormRow = styled(FormRow)`
  gap: ${({ theme }) => theme.spacingMedium}px;
  max-width: 1000px;
  width: 100%;
  justify-content: space-between;
`;

export const FlexContainer = styled(Flex)`
  flex-flow: column;
`;

export const FlexHeader = styled(Flex)`
  flex: 0 1 auto;
`;

export const FlexContent = styled(Flex)`
  flex: 1 1 auto;
`;

export const SubAccountDrawerHeader = styled(Flex)`
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingMedium}px`};
  min-height: 3.5rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;
