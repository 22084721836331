import {
  HStack,
  IndicatorBadge,
  IndicatorBadgeSizes,
  IndicatorBadgeVariants,
  readableDate,
  useDateNow,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { BlueMarkerBox } from './BlueMarkerBox';

interface AsOfDateBadgeProps {
  size?: IndicatorBadgeSizes;
  snapshotDate: string | null;
}

export const AsOfDateBadge = ({ size = IndicatorBadgeSizes.Large, snapshotDate }: AsOfDateBadgeProps) => {
  const date = useMemo(() => (snapshotDate ? new Date(snapshotDate) : undefined), [snapshotDate]);

  const dateNow = useDateNow({ updateIntervalMs: 1000 });
  const dateToUse = date ?? dateNow;

  return (
    <IndicatorBadge
      whiteSpace="nowrap"
      size={size}
      children={
        <HStack gap="spacingDefault">
          {date != null && <BlueMarkerBox />}
          As of {readableDate(dateToUse, true)}
        </HStack>
      }
      variant={IndicatorBadgeVariants.Default}
    />
  );
};
