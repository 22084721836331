import { useCurrency } from '@talos/kyoko';
import Big from 'big.js';
import { useEffect, useState } from 'react';
import type { Balance } from '../../types';
import { getEmptyTransferForm, type TransferForm } from './types';
import type { useChainCurrencyOptions } from './useTransferFormOptions';

type TransferFormDisabled = {
  [key in keyof TransferForm]: boolean;
};

interface TransferFormErrors {
  balances: string;
  chainCurrency: string;
  provider: string;
  asset: string;
  amount: string;
  source: string;
  destination: string;
}

export const useTransferFormValidation = (
  form: TransferForm,
  allInputsDisabled: boolean,
  chainCurrencyOptions: ReturnType<typeof useChainCurrencyOptions>,
  availableSourceAmount?: string,
  balances?: Map<string, Map<number, Balance>>
) => {
  const { asset, destination, provider, source, chainCurrency, amount } = form;
  const currencyInfo = useCurrency(asset);

  const [errors, setErrors] = useState<Partial<TransferFormErrors>>({});
  const [disabledInputs, setDisabledInputs] = useState<Partial<TransferFormDisabled>>({});
  useEffect(() => {
    const disabled: Partial<TransferFormDisabled> = {};
    if (allInputsDisabled) {
      // Disable all inputs in the UI
      Object.keys(getEmptyTransferForm()).forEach(key => {
        disabled[key as string] = true;
      });
    } else {
      if (provider === '') {
        disabled.asset = true;
        disabled.amount = true;
      }
      if (asset === '' || disabled.asset) {
        disabled.source = true;
        disabled.destination = true;
      }
      if (source === undefined || destination === undefined) {
        disabled.description = true;
        disabled.reference = true;
      }
      if (provider === 'fireblocks') {
        disabled.reference = true;
      }
    }
    setDisabledInputs(disabled);
  }, [allInputsDisabled, asset, destination, provider, source]);

  // Input Errors
  useEffect(() => {
    const newErrors: Partial<TransferFormErrors> = {};
    if (!balances) {
      newErrors.balances = 'Balances are unavailable. Contact Talos Support.';
    }

    if (!provider) {
      newErrors.provider = 'Provider is required';
    }
    if (!asset) {
      newErrors.asset = 'Asset is required';
    }
    if (!amount) {
      newErrors.amount = 'Amount is required';
    } else {
      const bigAmount = Big(amount ?? '0');
      if (bigAmount.lte(currencyInfo?.MinIncrement ?? 0)) {
        newErrors.amount = `Amount must be greater than ${currencyInfo?.MinIncrement ?? 0}`;
      } else if (bigAmount.gt(availableSourceAmount || 0) && source) {
        newErrors.amount = 'Amount is greater than available amount';
      }
    }

    if (!source) {
      newErrors.source = 'Source is required';
    }

    if (!destination) {
      newErrors.destination = 'Destination is required';
    }

    if (source === destination && destination != null) {
      newErrors.destination = 'Destination must be different from source';
    }

    if (chainCurrencyOptions.required && !chainCurrency) {
      newErrors.chainCurrency = 'Chain is required';
    }

    if (JSON.stringify(newErrors) !== JSON.stringify(errors)) {
      setErrors(newErrors);
    }
  }, [
    amount,
    asset,
    destination,
    provider,
    source,
    balances,
    availableSourceAmount,
    errors,
    currencyInfo?.MinIncrement,
    chainCurrency,
    chainCurrencyOptions,
  ]);

  return {
    disabledInputs,
    errors,
  };
};
