import { MixpanelEvent } from '@talos/kyoko';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { TradingSettingCheckbox } from './TradingSettingCheckbox';

export function OrderDetailsCheckbox() {
  const { enableAdvancedOrderDetails, setEnableAdvancedOrderDetails } = useTradingSettings();

  return (
    <TradingSettingCheckbox
      id="orderDetails"
      isEnabled={!!enableAdvancedOrderDetails}
      onChecked={setEnableAdvancedOrderDetails}
      mixPanelEvent={MixpanelEvent.EnableAdvancedOrderDetails}
      toastDisplayName="Advanced Order Details"
      displayName="Enable Advanced Order Details"
      displayDescription="View additional information in the Order Details screens. This adds tabs for Market Orders, Execution Reports and Market Execution Reports."
    />
  );
}
