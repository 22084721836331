import { QUOTE_CANCEL_REQUEST, formattedDateForSubscription, useSocketClient } from '@talos/kyoko';
import type React from 'react';
import { createContext, memo, useCallback, useContext } from 'react';
import type { QuotesContextProps } from './quotes.types';

const Quotes = createContext<QuotesContextProps | null>(null);
Quotes.displayName = 'QuotesContext';

export const useQuotes = () => {
  const context = useContext(Quotes);
  if (context == null) {
    throw new Error('Missing Quotes.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const QuotesProvider = memo(function QuotesProvider(props: React.PropsWithChildren<unknown>) {
  const client = useSocketClient();

  const cancel = useCallback(
    (quoteReqID?: string, transactTime = new Date()) =>
      client!.registerPublication({
        type: QUOTE_CANCEL_REQUEST,
        data: [
          {
            QuoteReqID: quoteReqID,
            TransactTime: formattedDateForSubscription(transactTime),
          },
        ],
      }),
    [client]
  );

  return (
    <Quotes.Provider
      value={{
        cancel,
      }}
    >
      {props.children}
    </Quotes.Provider>
  );
});
