import { Flex, HStack, Meter, Text, type FlexProps } from '@talos/kyoko';
import Big from 'big.js';

export const MakerTakerChart = ({ maker = 0, taker = 0, ...props }: { maker?: number; taker?: number } & FlexProps) => {
  const makerPct = Big(maker).times(100).toFixed(2);
  const takerPct = Big(taker).times(100).toFixed(2);

  return (
    <Flex flexDirection="column" gap="spacingSmall" {...props}>
      <HStack justifyContent="space-between">
        <Text color="colorTextSubtle" size="fontSizeSmall">
          Maker <Text color="colors.blue.lighten">{makerPct}%</Text>
        </Text>
        <Text color="colorTextSubtle" size="fontSizeSmall">
          Taker <Text color="colors.gray.090">{takerPct}%</Text>
        </Text>
      </HStack>
      <Meter value={maker} showLabel={false} color="colors.blue.lighten" backgroundColor="colors.gray.090" />
    </Flex>
  );
};
