import type { WebsocketRequest } from '@talos/kyoko';
import { useCallback, useRef } from 'react';

type UseStateTaggingArg<TRequest, TExtraState> = {
  request: TRequest;
  extraState?: TExtraState;
  /** Function to generate a new tag if the request or extra state changes, with a common prefix */
  getNewTag: () => string;
  /** Callback to trigger an update when the request or extra state changes */
  onChanged?: () => void;
  options?: {
    /** Whether to return an actual request */
    isEnabled?: boolean;
  };
};

type StoredRequest<TRequest, TExtraState> = {
  request: TRequest;
  /** Non-request state that should be considered when generating a new tag */
  extraState?: TExtraState;
};

// Create a new custom tag for each request if the request or extra state changes
// - this makes is signigicantly easier to associate request data with the full state that drove the request
export function useRequestStateTagging<TRequest extends Omit<WebsocketRequest, 'tag'>, TExtraState>({
  request,
  extraState,
  getNewTag,
  onChanged,
  options,
}: UseStateTaggingArg<TRequest, TExtraState>): {
  /** The request to use with the generated tag */
  request: TRequest & { tag: string };
  /** Stable function to get the tagged state for a specific tag */
  getTaggedState: (tag: string) => StoredRequest<TRequest, TExtraState> | undefined;
  /** The tag to use for the current request based on the request and extra state */
  tag: string;
  /** Ref to the map of tags to request and state */
  tagMapRef: React.MutableRefObject<Map<string, StoredRequest<TRequest, TExtraState>>>;
} {
  const tagMapRef = useRef(new Map(new Map<string, StoredRequest<TRequest, TExtraState>>()));
  const getTaggedState = useCallback((tag: string) => {
    return tagMapRef.current.get(tag);
  }, []);

  const baseResult = {
    getTaggedState,
    tagMapRef,
  };

  const lastTagBasisRef = useRef<string | undefined>(undefined);
  const lastTag = useRef<string | undefined>(undefined);
  const tagBasis = JSON.stringify({ request, extraState });
  if (lastTag.current && lastTagBasisRef.current === tagBasis) {
    return { ...baseResult, tag: lastTag.current, request: { ...request, tag: lastTag.current } };
  }

  // Request or state is new, generate a new tag, and update the tag map
  const tag = getNewTag();
  lastTag.current = tag;
  lastTagBasisRef.current = tagBasis;
  // This is admittedly not best use of a ref, but
  // it's the easiest way to ensure that the tagMapRef is always up to date
  tagMapRef.current.set(tag, { request, extraState });
  onChanged?.();

  return { ...baseResult, tag, request: { ...request, tag } };
}
