import {
  NotificationVariants,
  abbreviateId,
  request,
  useDynamicCallback,
  useEndpointsContext,
  useGlobalToasts,
  type MarketOrder,
} from '@talos/kyoko';

export function useAdminUtils() {
  const { orgApiEndpoint } = useEndpointsContext();
  const { add: addToast } = useGlobalToasts();

  const forceCancelOrder = useDynamicCallback((orderID: string) => {
    if (orderID == null) {
      return;
    }
    request('PUT', `${orgApiEndpoint}/admin/orders/${orderID}/force-cancel`)
      .then(() => {
        addToast({
          text: `Order #${abbreviateId(orderID)} force cancelled.`,
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) =>
        addToast({
          text: `Could not force cancel order #${abbreviateId(orderID)}: ${e.message}`,
          variant: NotificationVariants.Negative,
        })
      );
  });

  const tryCancelMarketOrder = useDynamicCallback((marketOrder: MarketOrder) => {
    const endpoint = `${orgApiEndpoint}/admin/orders/${marketOrder.Market}/${marketOrder.OID}/cancel`;
    request('PUT', endpoint)
      .then(() => {
        addToast({
          text: `Market order ${marketOrder.OID} cancelled.`,
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) =>
        addToast({
          text: `Could not cancel market order ${marketOrder.OID}: ${e.message}`,
          variant: NotificationVariants.Negative,
        })
      );
  });

  const tryForceCancelMarketOrder = useDynamicCallback((marketOrder: MarketOrder) => {
    const endpoint = `${orgApiEndpoint}/admin/orders/${marketOrder.Market}/${marketOrder.OID}/force-cancel`;
    request('PUT', endpoint)
      .then(() => {
        addToast({
          text: `Market order ${marketOrder.OID} force cancelled.`,
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) =>
        addToast({
          text: `Could not force cancel market order ${marketOrder.OID}: ${e.message}`,
          variant: NotificationVariants.Negative,
        })
      );
  });

  return {
    forceCancelOrder,
    tryCancelMarketOrder,
    tryForceCancelMarketOrder,
  };
}
