import {
  ACTION,
  Accordion,
  AccordionBody,
  BlotterDensity,
  BlotterTable,
  Box,
  Button,
  ConnectionModeEnum,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  EMPTY_ARRAY,
  FilterBuilder,
  FilterBuilderToggleButton,
  FormControlSizes,
  IconName,
  LocalFilterInput,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  createCSVFileName,
  createFilterClausesFromQueryParams,
  createQueryParamsFromFilterClauses,
  getMarketSecurityStatusKey,
  removeEmptyFilters,
  useAccordion,
  useAccordionFilterBuilder,
  useBlotterTable,
  useDisclosure,
  useMixpanel,
  useObservable,
  usePersistedBlotterTable,
  useRouter,
  withAccordionGroup,
  type FilterClause,
  type MarketAccount,
  type MarketSecurityStatus,
} from '@talos/kyoko';
import type { RowClassParams } from 'ag-grid-community';
import { useRoleAuth } from 'hooks';
import { cloneDeep, isEqual } from 'lodash';
import { useMarketSecurityStatuses } from 'providers/MarketSecurityStatusProvider';
import { useCallback, useEffect, useState } from 'react';
import { map, shareReplay } from 'rxjs/operators';
import { EditMarketSecModeDialog } from './EditMarketSecModeDialog';
import { useSecurityMastersMenu } from './SecurityMasterBlotterMenu';
import type { MarketSecurityStatusLocal, SecurityBlotterParams } from './types';
import { useSecurityMasterColumns } from './useSecurityMasterColumns';
import { useSecurityMasterFilter, type SecurityMasterFilter } from './useSecurityMasterFilter';

export const SecurtyMasterBlotter = withAccordionGroup(function (props: {
  marketAccountsByName: Map<string, MarketAccount>;
}) {
  const { marketSecurityStatusesCache } = useMarketSecurityStatuses();
  const { queryParams, setQueryParams } = useRouter<SecurityBlotterParams>();
  const { isAuthorized } = useRoleAuth();
  const [selectedStatus, setSelectedStatus] = useState<MarketSecurityStatusLocal | undefined>();
  const mixpanel = useMixpanel();
  const editMarketSecModeDialog = useDisclosure();
  const { open } = editMarketSecModeDialog;

  const handleEdit = useCallback(
    (row: MarketSecurityStatusLocal) => {
      if (isAuthorized(ACTION.EDIT_SECMASTER)) {
        setSelectedStatus(cloneDeep(row));
        open();
      }
    },
    [open, isAuthorized]
  );

  const columns = useSecurityMasterColumns(isAuthorized, handleEdit);

  const persisted = usePersistedBlotterTable('SecurityMaster', {
    columns,
  });

  const filterBuilderAccordion = useAccordion({ id: 'securityMasterFilter', initialOpen: true });
  const { changeFilter, filterableProperties, clientSideFilter, filter } = useSecurityMasterFilter({
    saveFilter: persisted.onFilterChanged,
  });

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, setParams?: boolean) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters({
          ...curr,
          MarketAccounts: filterClausesByPropertyKey.get('MarketAccounts')?.selections,
          Markets: filterClausesByPropertyKey.get('Markets')?.selections,
          Statuses: filterClausesByPropertyKey.get('Statuses')?.selections,
          Enableds: filterClausesByPropertyKey.get('Enableds')?.selections,
          ProductTypes: filterClausesByPropertyKey.get('ProductTypes')?.selections,
          MarketDataEnableds: filterClausesByPropertyKey.get('MarketDataEnableds')?.selections,
          Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        const filtersToQueryParam = createQueryParamsFromFilterClauses(filterClausesByPropertyKey);
        if (setParams) {
          setQueryParams(filtersToQueryParam);
        }
        return newFilter;
      });
    },
    [changeFilter, setQueryParams]
  );

  const filterBuilder = useAccordionFilterBuilder({
    filterBuilderProps: {
      initialFilterClauses: EMPTY_ARRAY,
      properties: filterableProperties,
      onFilterClausesChanged: filterClauses => handleFilterClausesChanged(filterClauses, true),
    },
    accordionProps: filterBuilderAccordion,
  });

  const { getContextMenuItems, dialogComponents } = useSecurityMastersMenu({
    openClause: filterBuilder.openClause,
    filterableProperties,
  });

  useEffect(() => {
    const paramObject: SecurityMasterFilter = {};
    const paramFilterClauses = createFilterClausesFromQueryParams(queryParams);
    for (const [key, param] of paramFilterClauses.entries()) {
      paramObject[key] = param.selections;
    }
    if (!isEqual(paramObject, filter)) {
      const newFilters = createFilterClausesFromQueryParams(queryParams);
      filterBuilder.filterBuilder.resetFilterClauses(Array.from(newFilters.values()));
      handleFilterClausesChanged(newFilters, false);
    }
  }, [filter, queryParams, handleFilterClausesChanged, filterBuilder.filterBuilder]);

  const marketSecurityStatusesObs = useObservable(
    () =>
      marketSecurityStatusesCache.pipe(
        map(json => {
          return {
            ...json,
            data: json.data.map(transformMsg),
          };
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketSecurityStatusesCache]
  );

  const blotterTable = useBlotterTable<MarketSecurityStatusLocal>({
    dataObservable: marketSecurityStatusesObs,
    columns: columns,
    rowID: 'ID',
    selection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
    clientLocalFilter: clientSideFilter,
    onDoubleClickRow: handleEdit,
    getContextMenuItems,
  });

  const handleExport = useCallback(() => {
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'SecurityMaster',
      }),
    });
  }, [blotterTable]);

  return (
    <Panel>
      <PanelHeader>
        <h2>Security Master</h2>
        <PanelActions>
          <LocalFilterInput
            value={blotterTable.blotterTableFiltersProps.quickFilterText}
            onChange={evt => {
              blotterTable.blotterTableFiltersProps.onQuickFilterTextChanged(evt);
              mixpanel.track(MixpanelEvent.FilterQuickSearch, {
                [MixpanelEventProperty.Source]: MixpanelEventSource.SecurityMaster,
              });
            }}
            width="200px"
          />
          <FilterBuilderToggleButton
            filterClauses={filterBuilder.filterBuilder.filterClauses}
            isOpen={filterBuilderAccordion.isOpen}
            onClick={() => filterBuilderAccordion.toggle()}
            size={FormControlSizes.Default}
          />
          <Button startIcon={IconName.Download} onClick={handleExport}>
            Export CSV
          </Button>
        </PanelActions>
      </PanelHeader>
      <Box background="colors.gray.main" w="100%" px="spacingBig">
        <Accordion {...filterBuilderAccordion}>
          <AccordionBody>
            <FilterBuilder {...filterBuilder.filterBuilder} />
          </AccordionBody>
        </Accordion>
      </Box>
      <PanelContent pb={0}>
        <BlotterTable
          {...blotterTable}
          gridOptions={{
            ...blotterTable.gridOptions,
            getRowStyle: (params: RowClassParams<MarketSecurityStatusLocal>) => {
              if (params.data?._marketEnabled !== ConnectionModeEnum.Up) {
                return { opacity: '0.5' };
              }
            },
          }}
          density={BlotterDensity.Comfortable}
        />
        {selectedStatus && <EditMarketSecModeDialog {...editMarketSecModeDialog} mktSecStatus={selectedStatus} />}
      </PanelContent>
      {dialogComponents}
    </Panel>
  );
});

function transformMsg(msg: MarketSecurityStatus): MarketSecurityStatusLocal {
  // We pick all properties manually instead of spreading since spreading is significantly slower and we
  // are handling tens of thousands of records with this function
  return {
    Capabilities: msg.Capabilities,
    ConnectionType: msg.ConnectionType,
    Enabled: msg.Enabled,
    Market: msg.Market,
    MarketAccount: msg.MarketAccount,
    MaximumSize: msg.MaximumSize,
    MinPriceIncrement: msg.MinPriceIncrement,
    MinSizeIncrement: msg.MinSizeIncrement,
    MinimumAmount: msg.MinimumAmount,
    MinimumSize: msg.MinimumSize,
    SizeBuckets: msg.SizeBuckets,
    Status: msg.Status,
    Symbol: msg.Symbol,
    Timestamp: msg.Timestamp,
    MarketCapabilities: msg.MarketCapabilities,
    MarketEnabled: msg.MarketEnabled,
    MarketMinPriceIncrement: msg.MarketMinPriceIncrement,
    MarketMinSizeIncrement: msg.MarketMinSizeIncrement,
    MarketMaximumSize: msg.MarketMaximumSize,
    MarketMinimumSize: msg.MarketMinimumSize,
    MarketMinimumAmount: msg.MarketMinimumAmount,
    MarketSizeBuckets: msg.MarketSizeBuckets,
    RequestedEnabled: msg.RequestedEnabled,
    RequestedMinPriceIncrement: msg.RequestedMinPriceIncrement,
    RequestedMinSizeIncrement: msg.RequestedMinSizeIncrement,
    RequestedMinimumSize: msg.RequestedMinimumSize,
    RequestedMaximumSize: msg.RequestedMaximumSize,
    RequestedMinimumAmount: msg.RequestedMinimumAmount,
    RequestedCapabilities: msg.RequestedCapabilities,
    RequestedSizeBuckets: msg.RequestedSizeBuckets,
    ID: getMarketSecurityStatusKey(msg.MarketAccount, msg.Symbol),
    _capabilities: capabilitiesToList(msg.Capabilities),
    _marketEnabled: msg.MarketEnabled !== undefined ? msg.MarketEnabled : ConnectionModeEnum.Up,
  } satisfies MarketSecurityStatusLocal;
}

function capabilitiesToList(caps?: { [key: string]: boolean }): string {
  if (caps == null) {
    return '';
  }
  return Object.keys(caps)
    .map(key => (caps[key] ? key : ''))
    .compact()
    .join(', ');
}
