import type { TabProps } from '@talos/kyoko';
import { OMSView } from './OMSView';

export const viewsWithTabs = [
  OMSView.SalesOrder,
  OMSView.SalesRFQForm,
  OMSView.RFQForm,
  OMSView.NewOrderForm,
  OMSView.ManualRFQPricingForm,
  OMSView.CareOrderForm,
];
export const orderFormViews = [OMSView.SalesOrder, OMSView.SalesRFQForm, OMSView.RFQForm, OMSView.NewOrderForm];
export const newOrderTab: TabProps = { id: OMSView.NewOrderForm, label: 'New Order' };
export const rfqTab: TabProps = { id: OMSView.RFQForm, label: 'New RFQ' };
export const salesOrderTab: TabProps = { id: OMSView.SalesOrder, label: 'Sales Order' };
export const salesRfqTab: TabProps = { id: OMSView.SalesRFQForm, label: 'Sales RFQ' };
export const manualRfqPricingTab: TabProps = { id: OMSView.ManualRFQPricingForm, label: 'Manual Pricing' };
export const careOrderTab: TabProps = { id: OMSView.CareOrderForm, label: 'Care Order' };
