import { SearchSelect, type Market } from '@talos/kyoko';
import { useProviderOptions } from 'containers/Transfers';
import { useMemo } from 'react';

import type { TransferOverviewSelector } from '../../types';

export interface ProvidersSelectorProps extends TransferOverviewSelector<Market> {
  onChange: (provider?: Market) => void;
  value: string;
}

const getLabel = (provider: Market) => provider.DisplayName;

export function ProvidersSelector({ onChange, value, ...searchSelectProps }: ProvidersSelectorProps) {
  const providerOptions = useProviderOptions();
  const selection = useMemo(() => providerOptions.find(provider => provider.Name === value), [providerOptions, value]);

  return (
    <SearchSelect
      {...searchSelectProps}
      selection={selection}
      options={providerOptions}
      onChange={onChange}
      getLabel={getLabel}
      data-testid="providers-select"
    />
  );
}
