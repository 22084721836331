import type HighchartsReact from 'highcharts-react-official';
import type { Options, SeriesLineOptions } from 'highcharts/highcharts';
import { forwardRef, useMemo } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import { setAlpha } from '../../styles';
import { BaseChart, type BaseChartProps } from './BaseChart';
import { useChartOptionsOverrides, useHighchartsRef, useSeriesOptions } from './hooks';
import type { SharedChartProps } from './types';

export const getNavigatorColor = (theme: DefaultTheme) => theme.colors.gray['060'];

const EMPTY_OPTIONS = {};

export interface LineChartProps extends SharedChartProps<SeriesLineOptions>, BaseChartProps {}

export const LineChart = forwardRef<HighchartsReact.RefObject | null, LineChartProps>(function LineChart(
  { series, seriesObservable, options, onChartCreated, mergeSeriesUpdates, ...props },
  ref
) {
  const { chartRef, isLoaded, setChartObject } = useHighchartsRef({
    onChartCreated,
  });
  const theme = useTheme();

  const defaultLinechartOptions = useDefaultLineChartOptions(theme);

  const seriesOptions = useSeriesOptions({ series, seriesObservable, chartRef, isLoaded, mergeSeriesUpdates });
  const combinedOptions = useChartOptionsOverrides(defaultLinechartOptions, options ?? EMPTY_OPTIONS, seriesOptions);

  return (
    <BaseChart
      options={combinedOptions}
      onChartCreated={setChartObject}
      chartRef={chartRef}
      constructorType="stockChart"
      {...props}
    />
  );
});

export function useDefaultLineChartOptions(theme) {
  return useMemo(() => {
    return {
      chart: {
        type: 'line',
        selectionMarkerFill: setAlpha(0.04, theme.colors.gray['100']),
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        opposite: false, // highstock defaults to "opposite: true", which is labels on the right
      },
      tooltip: {
        // https://api.highcharts.com/highstock/tooltip.shared and https://api.highcharts.com/highstock/tooltip.split
        split: false,
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        enabled: false, // default to false and let the implementer opt-in
        maskFill: setAlpha(0.04, theme.colors.gray['100']),
        height: 40,
        outlineWidth: 0,
        xAxis: {
          type: 'datetime',
          lineWidth: 0,
          gridLineColor: theme.borderColorChartGridLine,
          gridLineWidth: 1,
          labels: {
            style: {
              color: theme.colors.gray['070'],
              textOutline: undefined,
            },
          },
        },
        series: {
          type: 'line',
          color: getNavigatorColor(theme),
        },
      },
      scrollbar: {
        enabled: true,
        height: 0,
        minWidth: 0,
        liveRedraw: true, // this is the option causing the redrawing upon moving the navigator
      },
      plotOptions: {
        line: {
          lineWidth: 1,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 2,
          },
        },
      },
    } satisfies Options;
  }, [theme]);
}
