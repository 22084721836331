import {
  formattedDateForSubscription,
  removeEmptyFilters,
  type DateRangeFilter,
  type PnlLookbackEnum,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import {
  periodToChartDateRange,
  type CustomPeriod,
  type PerformanceFilter,
  type PerformanceState,
  type Period,
  type PnLUnit,
  type Product,
} from './types';

export enum PerformanceActionType {
  FilterChange,
  TabChange,
  SnapshotDateChange,
  PeriodChange,
  PnLUnitChange,
  ChartRequestDateRangeChange,
  ShowDeltaChange,
  BenchmarksChange,
  PnlLookbacksChange,
  ShowInsightsChange,
  SubAccountsChange,
  ShowZeroBalancesChange,
  LifeToDateStartTimeChange,
  ProductChange,
}

interface FilterChangeAction {
  type: PerformanceActionType.FilterChange;
  payload: {
    filter: PerformanceFilter;
  };
}

interface TabChangeAction {
  type: PerformanceActionType.TabChange;
  payload: PerformanceState;
}

interface SnapshotDateChange {
  type: PerformanceActionType.SnapshotDateChange;
  payload: {
    // Here we accept Date | string | null, which is then converted into string | null for storage in the tab.
    snapshotDate: Date | string | null;
  };
}

interface PeriodChange {
  type: PerformanceActionType.PeriodChange;
  payload: {
    period: Period | CustomPeriod;
  };
}

interface PnLUnitChange {
  type: PerformanceActionType.PnLUnitChange;
  payload: {
    pnlUnit: PnLUnit;
  };
}

interface ChartRequestDateRangeChange {
  type: PerformanceActionType.ChartRequestDateRangeChange;
  payload: {
    chartRequestDateRange: DateRangeFilter;
  };
}

interface ShowDeltaChange {
  type: PerformanceActionType.ShowDeltaChange;
  payload: {
    showDelta: boolean;
  };
}

interface BenchmarksChangeAction {
  type: PerformanceActionType.BenchmarksChange;
  payload: {
    benchmarks: string[];
  };
}

interface PnlLookbacksChangeAction {
  type: PerformanceActionType.PnlLookbacksChange;
  payload: {
    pnlLookbacks: PnlLookbackEnum[];
  };
}

interface ShowInsightsChangeAction {
  type: PerformanceActionType.ShowInsightsChange;
  payload: {
    showInsights: boolean;
  };
}

interface SubAccountsChangeAction {
  type: PerformanceActionType.SubAccountsChange;
  payload: {
    subAccounts: string[];
  };
}

interface ShowZeroBalancesChangeAction {
  type: PerformanceActionType.ShowZeroBalancesChange;
  payload: {
    showZeroBalances: boolean;
  };
}

interface LifeToDateStartTimeChangeAction {
  type: PerformanceActionType.LifeToDateStartTimeChange;
  payload: {
    lifeToDateStartTime: string | null;
  };
}

interface ProductChangeAction {
  type: PerformanceActionType.ProductChange;
  payload: {
    product: Product;
  };
}

export type PerformanceAction =
  | FilterChangeAction
  | TabChangeAction
  | SnapshotDateChange
  | PeriodChange
  | PnLUnitChange
  | ShowDeltaChange
  | BenchmarksChangeAction
  | PnlLookbacksChangeAction
  | ShowInsightsChangeAction
  | SubAccountsChangeAction
  | ShowZeroBalancesChangeAction
  | LifeToDateStartTimeChangeAction
  | ProductChangeAction
  | ChartRequestDateRangeChange;

export const performanceReducer = (state: PerformanceState, action: PerformanceAction) => {
  switch (action.type) {
    case PerformanceActionType.FilterChange: {
      const currentFilters = removeEmptyFilters(state.filter);
      const nextFilters = removeEmptyFilters(action.payload.filter);
      if (isEqual(currentFilters, nextFilters)) {
        return state;
      }

      return {
        ...state,
        lifeToDateStartTime: null,
        chartRequestDateRange: periodToChartDateRange(state.period),
        filter: nextFilters,
      };
    }

    case PerformanceActionType.TabChange: {
      return {
        ...action.payload,
      } satisfies PerformanceState;
    }

    case PerformanceActionType.SnapshotDateChange: {
      // We accept Date | string | null. If is instance of date, convert to string for storage.
      return {
        ...state,
        snapshotDate:
          action.payload.snapshotDate instanceof Date
            ? formattedDateForSubscription(action.payload.snapshotDate)
            : action.payload.snapshotDate,
      };
    }

    case PerformanceActionType.PeriodChange: {
      return {
        ...state,
        period: action.payload.period,
        // On period change we also reset the chart requets date range to the default of the newly selected period
        chartRequestDateRange: periodToChartDateRange(action.payload.period),
        snapshotDate: null,
        lifeToDateStartTime: null,
      };
    }

    case PerformanceActionType.PnLUnitChange: {
      return {
        ...state,
        pnlUnit: action.payload.pnlUnit,
      };
    }

    case PerformanceActionType.ChartRequestDateRangeChange: {
      return {
        ...state,
        chartRequestDateRange: action.payload.chartRequestDateRange,
      };
    }

    case PerformanceActionType.ShowDeltaChange: {
      return {
        ...state,
        showDelta: action.payload.showDelta,
      };
    }

    case PerformanceActionType.BenchmarksChange: {
      return {
        ...state,
        benchmarks: action.payload.benchmarks,
      };
    }

    case PerformanceActionType.PnlLookbacksChange: {
      return {
        ...state,
        pnlLookbacks: action.payload.pnlLookbacks,
      };
    }

    case PerformanceActionType.ShowInsightsChange: {
      return {
        ...state,
        showInsights: action.payload.showInsights,
      };
    }

    case PerformanceActionType.SubAccountsChange: {
      return {
        ...state,
        chartRequestDateRange: periodToChartDateRange(state.period),
        lifeToDateStartTime: null,
        subAccounts: action.payload.subAccounts,
      };
    }

    case PerformanceActionType.ShowZeroBalancesChange: {
      return {
        ...state,
        showZeroBalances: action.payload.showZeroBalances,
      };
    }

    case PerformanceActionType.ProductChange: {
      return {
        ...state,
        product: action.payload.product,
      };
    }

    case PerformanceActionType.LifeToDateStartTimeChange: {
      const newState = { ...state, lifeToDateStartTime: action.payload.lifeToDateStartTime };
      if (newState.period === 'LifeToDate') {
        newState.chartRequestDateRange = { StartDate: action.payload.lifeToDateStartTime ?? undefined };
      }

      return newState;
    }

    default:
      return state;
  }
};
