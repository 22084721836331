/** Defines what an editable drawer tab has to expose in order to be shown in the drawer */
export interface IEditSubAccountsDrawerTab {
  name: string;
  viewable: boolean;
  save: () => Promise<void> | void;
  isDirty: boolean;
  badgeContent?: React.ReactNode;
  component: JSX.Element;
}

export const SUB_ACCOUNT_SETTINGS_PANEL_ACTIONS_PORTAL_ID = 'sub-account-settings-panel-action-portal';
