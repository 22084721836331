import { Card, Table, Tbody, Td, Th, Tr, type CardProps, type Order } from '@talos/kyoko';
import { startCase } from 'lodash';

export interface OtherCardProps extends CardProps {
  order: Order;
  showExpandedDetails?: boolean;
}

export function OtherCard({ order, showExpandedDetails, ...props }: OtherCardProps) {
  if (!showExpandedDetails) {
    return null;
  }
  return (
    <Card {...props}>
      <Card.Subtitle>Other</Card.Subtitle>
      <Table w="100%">
        {[
          'OrderID',
          'ClOrdID',
          'OrigClOrdID',
          'ParentOrdID',
          'Timestamp',
          'TransactTime',
          'DecisionStatus',
          'TimeInForce',
          'OrdType',
          'Text',
          'Comments',
          'SessionID',
          'Revision',
        ].map((property, index) => (
          <Tbody key={property}>
            <Tr>
              <Th style={{ whiteSpace: 'nowrap' }}>{startCase(property)}</Th>
              <Td style={{ wordBreak: 'break-word' }} align="right">
                {order[property]}
              </Td>
            </Tr>
          </Tbody>
        ))}
      </Table>
    </Card>
  );
}
