import type { RowGroupsOpenedState } from '@talos/kyoko';
import type { BalancesBlotterColumnSpecification, BalancesV2TabProps } from './types';

export const DEFAULT_BY_COUNTERPARTY_TAB_ID = 'by-counterparty-balances';
export const DEFAULT_BY_ASSET_TAB_ID = 'by-asset-balances';

export const defaultSharedColumns = [
  'Amount',
  'Equivalent.Amount',
  'AvailableAmount',
  'Equivalent.AvailableAmount',
  'OutstandingBuy',
  'OutstandingSell',
  'Equivalent.OutstandingBuy',
  'Equivalent.OutstandingSell',
] satisfies BalancesBlotterColumnSpecification[];

export const defaultByCounterpartyColumns = [
  'reconWarning',
  { field: 'marketAccountGroup', rowGroupIndex: 0, rowGroup: false, hide: true },
  { field: 'marketAccountName', rowGroupIndex: 1, rowGroup: true, hide: true },
  'Currency',
  ...defaultSharedColumns,
] satisfies BalancesBlotterColumnSpecification[];

export const defaultByAssetColumns = [
  'reconWarning',
  { field: 'Currency', rowGroup: true, hide: true },
  'marketAccountName',
  ...defaultSharedColumns,
] satisfies BalancesBlotterColumnSpecification[];

export const balancesBlotterDefaultRowGroupsOpened: RowGroupsOpenedState = {};

const byCounterpartyTab: BalancesV2TabProps = {
  label: 'By Counterparty',
  id: DEFAULT_BY_COUNTERPARTY_TAB_ID,
  closable: true,
  editable: true,
  defaultColumns: defaultByCounterpartyColumns,
  defaultFilter: {},
  defaultRowGroupsOpened: balancesBlotterDefaultRowGroupsOpened,
  showZeroBalances: false,
  showTotals: true,
};

const byAssetTab: BalancesV2TabProps = {
  label: 'By Asset',
  id: DEFAULT_BY_ASSET_TAB_ID,
  closable: true,
  editable: true,
  defaultColumns: defaultByAssetColumns,
  defaultFilter: {},
  defaultRowGroupsOpened: balancesBlotterDefaultRowGroupsOpened,
  showZeroBalances: false,
  showTotals: true,
};

export const defaultBalancesTabs = [byCounterpartyTab, byAssetTab];
