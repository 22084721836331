import { Box } from '@talos/kyoko';
import styled from 'styled-components';

export const TradingStatusWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.gray['020']};
  border: solid 1px ${({ theme }) => theme.colors.gray['040']};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  padding: ${({ theme }) => theme.spacingMedium}px;
  width: 100%;
`;
