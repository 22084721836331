import type { ICellRendererParams } from 'ag-grid-community';
import { Box } from '../../Core';
import { PopoverContent } from '../../Popover';
import { Text } from '../../Text';
/**
 * Basic text tooltip that works with aggrid, reading value from the tooltipValueGetter callback, and expects it to be a string.
 *
 * Improve upon in the future if you need to set your own width, or just increase it
 */
export const AgGridTextTooltip = (props: ICellRendererParams<unknown>) => {
  return (
    <PopoverContent>
      <Box maxWidth="300px">
        <Text color="colorTextDefault">{props.value}</Text>
      </Box>
    </PopoverContent>
  );
};
