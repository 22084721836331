import { logger } from '@talos/kyoko';

interface ServiceWorkerRegistrationConfig {
  onSuccess: (registration: ServiceWorkerRegistration) => void;
  onUpdate: (registration: ServiceWorkerRegistration) => void;
}

const UPDATE_INTERVAL = 60_000;
const SERVICE_WORKER_URL = '/service-worker.js';

export function registerServiceWorker(config: ServiceWorkerRegistrationConfig) {
  if (import.meta.env.PROD && 'serviceWorker' in navigator) {
    // eslint-disable-next-line
    console.debug('Registering service worker...');
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(SERVICE_WORKER_URL)
        .then(registration => {
          config.onSuccess(registration);

          // Periodically check for updates (not only on refresh/navigation)
          setInterval(() => {
            // eslint-disable-next-line
            console.debug('Checking for updates...');
            registration.update().catch(error => {
              logger.error(error);
            });
          }, UPDATE_INTERVAL);

          navigator.serviceWorker.addEventListener('controllerchange', () => {
            // eslint-disable-next-line
            console.debug('New content is available!');
            // New content is available; prompt user to refresh
            config.onUpdate(registration);
          });
        })
        .catch(error => {
          console.error('Error during service worker registration:', error);
          logger.error(error);
        });
    });
  }
}
