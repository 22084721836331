import { Box, DistributionBar, Flex } from '@talos/kyoko';
import { useTheme } from 'styled-components';
import { SettlementDistributionBarHeader } from './SettlementDistributionBarHeader';
import { useSummedPositionBars, type SummedPositionBarsOutput } from './useSummedPositionBars';
import { useTotalTreasury } from './useTotalTreasury';

export const TreasuryVisualization = () => {
  const totalTreasury = useTotalTreasury();
  const bars = useSummedPositionBars();
  const { colorTextImportant } = useTheme();

  if (!bars || totalTreasury == null || !showVisualization(bars)) {
    return null;
  }

  return (
    <Flex alignItems="flex-end" gap="spacingLarge" maxWidth="900px" m="auto">
      <Flex justifyContent="center" gap="spacingDefault" flex="1">
        <Box flexBasis="30%" alignSelf="flex-end">
          <DistributionBar {...bars.incoming} />
        </Box>
        <Box flexBasis="70%" alignSelf="flex-end">
          <DistributionBar {...bars.outgoing} />
        </Box>
      </Flex>
      <Box>
        <SettlementDistributionBarHeader
          label="Total Treasury"
          wrapLabel={false}
          value={totalTreasury}
          valueColor={colorTextImportant}
          direction="ltr"
        />
      </Box>
    </Flex>
  );
};

// We show the visualization if there is some non-zero value
function showVisualization(bars: SummedPositionBarsOutput) {
  const hasSomeNonZeroValue = [bars.incoming, bars.outgoing].some(direction =>
    direction.sections.some(section => section.parts.some(part => part.value.gt(0)))
  );

  return hasSomeNonZeroValue;
}
