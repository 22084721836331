import type { CredentialTemplate, ReplaceTokens } from '@talos/kyoko';
import type { AddEditCredentialForm } from './types';

export const EMPTY_FORM: AddEditCredentialForm = {
  label: '',
};

export function getCredentialTemplateLabel(credentialTemplate: CredentialTemplate) {
  return credentialTemplate.displayName || credentialTemplate.type;
}

export function validateCheckboxChecked(value: any | undefined): boolean {
  return value === true || value === 'true';
}

export function getReplaceTokens(externalIP: string): ReplaceTokens {
  return {
    ExternalIP: externalIP,
  };
}
