import {
  abbreviateId,
  Card,
  CopyButton,
  formattedDateWithMilliseconds,
  FormControlSizes,
  IndicatorBadge,
  Table,
  Tag,
  TagSizes,
  Tbody,
  Td,
  Th,
  Tooltip,
  Tr,
  type CardProps,
  type CustomerOrder,
  type Order,
} from '@talos/kyoko';
import { useCustomersByName } from 'hooks/useCustomer';
import { truncate } from 'lodash';
import { prettifySubAccount, useSubAccounts } from '../../../../../providers/SubAccountsContext';
import { prettifyAllocations } from '../../../../../utils/allocation';
import { prepareAllocationOrderForUI } from '../../../../../utils/order';

export interface GeneralCardProps extends CardProps {
  order: Order;
  customerOrder?: CustomerOrder;
  showExpandedDetails?: boolean;
}

export function GeneralCard({ order, customerOrder, showExpandedDetails, ...props }: GeneralCardProps) {
  const customersByName = useCustomersByName();
  const { subAccountsByName } = useSubAccounts();
  const { subAccountAllocations, allocationValueType } = prepareAllocationOrderForUI(order);

  return (
    <Card title="General" {...props}>
      <Table w="100%">
        <Tbody>
          {customerOrder != null && (
            <Tr>
              <Th>Customer Order ID</Th>
              <Td
                align="right"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap="spacingSmall"
                data-testid="gen-cust-order-id"
              >
                #{abbreviateId(customerOrder.OrderID)}
                <CopyButton size={FormControlSizes.Tiny} value={customerOrder.OrderID} />
              </Td>
            </Tr>
          )}
          <Tr>
            <Th>Order ID</Th>
            <Td
              align="right"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="spacingSmall"
              data-testid="gen-order-id"
            >
              #{abbreviateId(order.OrderID)}
              <CopyButton size={FormControlSizes.Tiny} value={order.OrderID} />
            </Td>
          </Tr>
          <Tr>
            <Th>Date</Th>
            <Td align="right">{formattedDateWithMilliseconds(order.SubmitTime)}</Td>
          </Tr>
          {customerOrder != null && (
            <Tr>
              <Th>Customer</Th>
              <Td align="right">
                <IndicatorBadge
                  children={
                    customersByName?.get(customerOrder.Counterparty!)?.DisplayName ?? customerOrder.Counterparty
                  }
                />
              </Td>
            </Tr>
          )}
          <Tr>
            <Th>User</Th>
            <Td align="right">
              <IndicatorBadge children={order.User} />
            </Td>
          </Tr>
          {(order.Group || showExpandedDetails) && (
            <Tr>
              <Th>Group</Th>
              <Td align="right">
                {order.Group ? (
                  <Tag title="Group" size={TagSizes.Small}>
                    <Tooltip tooltip={order.Group}>{truncate(order.Group, { length: 20 })}</Tooltip>
                  </Tag>
                ) : (
                  '-'
                )}
              </Td>
            </Tr>
          )}
          {(subAccountAllocations || order.SubAccount || showExpandedDetails) && (
            <Tr>
              <Th>Sub Account(s)</Th>
              <Td align="right">
                {subAccountAllocations?.length
                  ? prettifyAllocations(subAccountAllocations, allocationValueType, order.Currency, subAccountsByName)
                  : prettifySubAccount(order.SubAccount, subAccountsByName) || '-'}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Card>
  );
}
