import { Box, Flex, HStack, Tbody, Th } from '@talos/kyoko';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  ${Tbody} ${Th} {
    padding-left: 0;
  }

  overflow-y: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none !important;
  }
`;
Wrapper.defaultProps = {
  h: '100%',
  w: '100%',
};

export const TopWrapper = styled(Flex)``;
TopWrapper.defaultProps = {
  justifyContent: 'space-between',
  background: 'colors.gray.030',
};

export const AnalyticsWrapper = styled(Box)`
  scrollbar-gutter: stable;
`;
AnalyticsWrapper.defaultProps = {
  overflow: 'auto',
  p: 'spacingComfortable',
  pr: 'spacingTiny',
  background: 'backgroundContent',
  minHeight: '100%',
};

export const OrderInfo = styled(HStack)``;
OrderInfo.defaultProps = {
  gap: 'spacingMedium',
  p: 'spacingComfortable',
  justifyContent: 'flex-start',
  overflow: 'auto hidden',
  h: 64,
};
