import type { GetContextMenuItems, GetContextMenuItemsParams, IRowNode } from 'ag-grid-enterprise';
import { compact } from 'lodash';

import {
  ACTION,
  IconName,
  MixpanelEvent,
  getRowNodesToOperateOn,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
} from '@talos/kyoko';
import { getOrderDetailsRoute } from 'containers/Routes/routes';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppLayoutConfig } from '../../../providers/AppLayoutConfig/AppLayoutConfigContext';
import { useAppLayoutContext } from '../../../providers/AppLayoutContextProvider/AppLayoutContextProvider';
import type { IHedgePositionStatusRow } from '../../../providers/HedgePositionStatusProvider';
import { openDetailsMenuItem } from '../Orders/useOrderMenu';

export const useHedgePositionStatusMenu = (): {
  getContextMenuItems: GetContextMenuItems<IHedgePositionStatusRow>;
  modals: React.ReactNode[];
} => {
  const mixpanel = useMixpanel();
  const history = useHistory();
  const { isAuthorized } = useRoleAuth();

  const openDetailsItem = useDynamicCallback((rows: IRowNode<IHedgePositionStatusRow>[]) => {
    const orderIds = rows
      .map(row => row.data?.HedgeOrderID)
      .filter<string>((maybeString): maybeString is string => maybeString !== undefined);

    if (orderIds.length === 0) {
      return null;
    }

    return openDetailsMenuItem({
      orders: orderIds.map(OrderID => ({ OrderID })),
      onOpenDetails,
      text: 'Open Hedge Order Details',
    });
  });
  const getMenuItems = useDynamicCallback((rows: IRowNode<IHedgePositionStatusRow>[]) => {
    if (isAuthorized(ACTION.VIEW_ORDER)) {
      return [openDetailsItem(rows)];
    }
    return [];
  });
  const { enableFlexibleLayout } = useAppLayoutConfig();
  const { addOrderDetailsPanel } = useAppLayoutContext();
  const onOpenDetails = useCallback(
    (orderID: string) => {
      mixpanel.track(MixpanelEvent.OpenOrderDetails);
      if (enableFlexibleLayout) {
        addOrderDetailsPanel(orderID);
      } else {
        history.push(getOrderDetailsRoute({ orderID, tab: 'details', type: 'principal' }));
      }
    },
    [history, mixpanel, enableFlexibleLayout, addOrderDetailsPanel]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { handleClickJson, jsonModal } = useJsonModal();

  const getContextMenuItems: GetContextMenuItems<IHedgePositionStatusRow> = useDynamicCallback(
    (params: GetContextMenuItemsParams<IHedgePositionStatusRow>) => {
      const menuItems = getMenuItems(getRowNodesToOperateOn(params));

      return compact([
        ...menuItems,
        ...getDefaultContextMenuItems(params),
        params?.node?.data
          ? {
              icon: `<i class="ag-icon ${IconName.Braces}"/>`,
              name: `Show JSON`,
              action: () => handleClickJson(params?.node?.data),
            }
          : null,
      ]);
    }
  );

  const modals = useMemo(() => [jsonModal], [jsonModal]);

  return {
    getContextMenuItems,
    modals,
  };
};
