import {
  logger,
  useDynamicCallback,
  type AgGridSearchSelectDropdownProps,
  type ColumnDef,
  type User,
} from '@talos/kyoko';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { useUsers } from 'hooks/useUsers';
import { get, set } from 'lodash';
import { useUserGroups } from 'providers';
import { useEffect, useMemo, useState } from 'react';
import type { UserGroup } from 'types';
import type { UsersAutocompleteItem } from './shared';

interface UseUsersColumnParams<T> {
  includeGroups?: boolean;
  title?: string;
  headerTooltip?: string;
  /** Whether or not to allow wildcard (*) selections */
  wildcard?: boolean;
  /** What field to read off of the entities in the blotter to get the user. We do not support paths. */
  field: keyof T;
}

export const useUsersColumn = <T,>({
  includeGroups,
  title = 'User',
  headerTooltip,
  wildcard,
  field,
}: UseUsersColumnParams<T>) => {
  const { listUserGroups } = useUserGroups();
  const [userGroups, setUserGroups] = useState<UserGroup[]>();
  const users = useUsers();

  useEffect(() => {
    let isMounted = true;
    listUserGroups()
      .then(response => {
        if (isMounted) {
          setUserGroups(response.data || []);
        }
      })
      .catch(e => {
        logger.error(e);
        if (isMounted) {
          setUserGroups([]);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [listUserGroups]);

  const cellEditorParams = useDynamicCallback((params: CustomCellEditorProps) => {
    let usersAndMaybeGroups: (User | UserGroup)[] = users;
    if (includeGroups && userGroups) {
      usersAndMaybeGroups = usersAndMaybeGroups.concat(userGroups);
    }

    const items: UsersAutocompleteItem[] = usersAndMaybeGroups.map(u => ({
      label: isGroup(u) ? u.DisplayName : u.Name,
      value: u,
      description: isGroup(u) ? 'Group' : 'User',
    }));

    if (wildcard) {
      items.unshift({
        label: '*',
        value: undefined,
        description: includeGroups ? 'All users and groups' : 'All users',
      });
    }
    return {
      ...params,
      useSearchSelectParams: {
        items,
        getLabel: item => item.label,
        getDescription: item => item.description ?? '',
        getGroup: item => {
          if (item.value == null) {
            return 'Wildcard';
          }
          if (isGroup(item.value)) {
            return 'Groups';
          }
          return 'Users';
        },
      },
      useDropdownParams: {
        dropdownWidth: '300px',
      },
      maxHeight: 400,
      searchPlaceholder: includeGroups ? 'User or user group' : 'User',
    } satisfies AgGridSearchSelectDropdownProps<UsersAutocompleteItem>;
  });

  const valueGetter = useDynamicCallback(({ data }: { data: { User?: string; UserGroupID?: string } }) => {
    const userName = get(data, field);
    const userGroupID = data.UserGroupID;
    if (userName != null) {
      return users.find(user => user.Name === userName);
    }
    return userGroups?.find(group => group.GroupID === userGroupID);
  });

  return useMemo(
    () =>
      ({
        type: 'custom',
        id: 'users',
        title,
        params: {
          headerTooltip,
          colId: 'users',
          width: 140,
          editable: true,
          suppressKeyboardEvent: () => true,
          cellEditor: 'searchSelectDropdown',
          cellEditorPopup: true,
          cellEditorParams,
          valueSetter: ({ newValue, data }: { newValue: UsersAutocompleteItem; data: any }) => {
            delete data.UserGroupID;
            delete data[field];

            if (newValue?.value) {
              if (isUser(newValue.value)) {
                set(data, field, newValue.value.Name);
              } else if (isGroup(newValue.value)) {
                data.UserGroupID = newValue.value.GroupID;
              }
            }

            return true;
          },
          valueGetter,
          valueFormatter: params => {
            if (params.value == null) {
              return '*';
            }
            return params.value?.Name ?? params.value?.DisplayName;
          },
        },
      } satisfies ColumnDef<T>),
    [cellEditorParams, valueGetter, title, headerTooltip, field]
  );
};

function isGroup(value: User | UserGroup): value is UserGroup {
  return 'GroupID' in value;
}

function isUser(value: User | UserGroup): value is User {
  return 'ID' in value;
}
