import { useState } from 'react';

import { ACTION, CustomerBalanceTransactionStatusEnum, VStack, type CustomerTransaction } from '@talos/kyoko';

import { useRoleAuth, useUser } from 'hooks';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';

import { InitiateTransferForm } from './InitiateTransferForm';
import { ManualTransferForm } from './ManualTransferForm';
import { useApproveTransactionDisabledTooltip } from './hooks';
import { ToggleButton, ToggleButtonWrapper } from './styles';

enum TransferFormType {
  Initiate = 'Initiate',
  Manual = 'Manual',
}

interface TransferFormProps {
  entity: CustomerTransaction;
  isDisabled: boolean;
  onFailedRequest: (e: ErrorEvent, errorDescription: string) => void;
  portalId: string;
}

export function TransferForm({ entity, isDisabled, onFailedRequest, portalId }: TransferFormProps) {
  const [transferFormType, setTransferFormType] = useState<TransferFormType>(TransferFormType.Initiate);
  const user = useUser();
  const { getConfig } = useOrgConfiguration();
  const { isAuthorized } = useRoleAuth();

  // If this config option is set to "1", the InitiatorUser cannot approve any of the transfers.
  const disabledForMatchingUser =
    getConfig(OrgConfigurationKey.TradingDisableAll, '0') === '1' &&
    (entity.InitiatorUser === user.Name || entity.DealerUser === user.Name);

  // Approving a Pending Transfer Transaction requires the org.treasury role.
  // However, everyone can Approve a Pending Approval Transaction.
  const disabledForUnauthorizedAction =
    entity.Status === CustomerBalanceTransactionStatusEnum.PendingTransfer &&
    !isAuthorized(ACTION.APPROVE_PENDING_TRANSACTION);

  const approveDisabled = disabledForMatchingUser || disabledForUnauthorizedAction;
  const approveDisabledTooltip = useApproveTransactionDisabledTooltip(
    approveDisabled,
    disabledForMatchingUser,
    entity.InitiatorUser,
    user.Name
  );

  return (
    <VStack gap="spacingMedium" p="spacingMedium" w="100%">
      <ToggleButtonWrapper>
        <ToggleButton
          disabled={isDisabled}
          isSelected={transferFormType === TransferFormType.Initiate}
          onClick={() => setTransferFormType(TransferFormType.Initiate)}
        >
          Initiate
        </ToggleButton>
        <ToggleButton
          disabled={isDisabled}
          isSelected={transferFormType === TransferFormType.Manual}
          onClick={() => setTransferFormType(TransferFormType.Manual)}
        >
          Manual
        </ToggleButton>
      </ToggleButtonWrapper>
      {transferFormType === TransferFormType.Initiate && (
        <InitiateTransferForm
          approveDisabledTooltip={approveDisabledTooltip}
          entity={entity}
          isApproveDisabled={approveDisabled}
          isDisabled={isDisabled}
          onFailedRequest={onFailedRequest}
          portalId={portalId}
        />
      )}
      {transferFormType === TransferFormType.Manual && (
        <ManualTransferForm
          approveDisabledTooltip={approveDisabledTooltip}
          entity={entity}
          isApproveDisabled={approveDisabled}
          isDisabled={isDisabled}
          onFailedRequest={onFailedRequest}
          portalId={portalId}
        />
      )}
    </VStack>
  );
}
