import {
  FilterClauseType,
  removeEmptyFilters,
  useSymbolsFilter,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useExecutionStrategiesFilter } from 'hooks/filters/useExecutionStrategiesFilter';
import { useOrdTypeFilter } from 'hooks/filters/useOrdTypeFilter';
import { useTimeInForceFilter } from 'hooks/filters/useTimeInForceFilter';
import { compact, isArray, isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useCounterpartyFilter } from '../../../hooks';
import type { CustomerExecutionRulesFilter } from './types';

export interface UseCustomerExecutionRulesFilterParams<ICustomerOrderExecutionRule> {
  persistedBlotterTable: UsePersistedBlotterTable<ICustomerOrderExecutionRule>;
}
export function useCustomerExecutionRulesFilter<ICustomerOrderExecutionRule>({
  persistedBlotterTable,
}: UseCustomerExecutionRulesFilterParams<ICustomerOrderExecutionRule>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<CustomerExecutionRulesFilter>({});

  const changeFilter = useCallback(
    (action: SetStateAction<CustomerExecutionRulesFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const symbolsFilter = useSymbolsFilter();
  const counterpartiesFilter = useCounterpartyFilter();
  const timeInForcesFilter = useTimeInForceFilter();
  const executionStrategiesFilter = useExecutionStrategiesFilter();
  const ordTypesFilter = useOrdTypeFilter();

  const filterableProperties: FilterableProperty<keyof CustomerExecutionRulesFilter>[] = useMemo(
    () => [
      { ...counterpartiesFilter, key: 'Counterparties' },
      symbolsFilter,
      timeInForcesFilter,
      executionStrategiesFilter,
      ordTypesFilter,
    ],
    [counterpartiesFilter, symbolsFilter, timeInForcesFilter, executionStrategiesFilter, ordTypesFilter]
  );

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<CustomerExecutionRulesFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CustomerExecutionRulesFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CustomerExecutionRulesFilter)[]).forEach((key: keyof CustomerExecutionRulesFilter) => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }
    return clauses;
  }, [filter]);

  const filterBuilderProps = useMemo(
    () =>
      ({
        initialFilterClauses,
        properties: filterableProperties,
        onFilterClausesChanged: handleFilterClausesChanged,
      } satisfies UseFilterBuilderProps),
    [initialFilterClauses, filterableProperties, handleFilterClausesChanged]
  );

  return {
    filter,
    filterBuilderProps,
  };
}
