import { HStack, InlineFormattedNumber, Text, toBig, type Currency } from '@talos/kyoko';
import type { HistoricalPositionStat } from '../../types';
import { pnlPercentToNumberVariant } from './utils';

interface DeltaSeriesTooltipProps {
  point: Highcharts.TooltipFormatterContextObject;
  homeCurrencyInfo: Currency | undefined;
  positionStat: HistoricalPositionStat;
  previousPositionStat: HistoricalPositionStat | undefined;
}

export const DeltaSeriesTooltip = ({
  point,
  homeCurrencyInfo,
  positionStat,
  previousPositionStat,
}: DeltaSeriesTooltipProps) => {
  if (point.x == null || point.y == null || previousPositionStat == null) {
    return null;
  }

  const percentageChange = getPercentageChange(positionStat, previousPositionStat);
  const change = getChange(positionStat, previousPositionStat);

  return (
    <HStack gap="spacingDefault" minWidth="200px" justifyContent="space-between" fontSize="fontSizeMd">
      <Text>Delta</Text>
      <InlineFormattedNumber
        number={change}
        increment={homeCurrencyInfo?.DefaultIncrement ?? '0.01'}
        currency={homeCurrencyInfo?.Symbol}
        round
      />
      {percentageChange ? (
        <InlineFormattedNumber
          number={percentageChange}
          increment="0.01"
          currency="%"
          round
          variant={pnlPercentToNumberVariant(percentageChange)}
        />
      ) : (
        <HStack>
          <Text>N/A</Text>
          <Text color="colorTextDefault">%</Text>
        </HStack>
      )}
    </HStack>
  );
};

function getChange(stat: HistoricalPositionStat, prevStat: HistoricalPositionStat | undefined) {
  if (prevStat == null) {
    return undefined;
  }

  const value = toBig(stat.PnLTotal);
  const prevValue = toBig(prevStat.PnLTotal);
  if (value == null || prevValue == null) {
    return undefined;
  }

  return value.minus(prevValue).toFixed();
}

function getPercentageChange(stat: HistoricalPositionStat, prevStat: HistoricalPositionStat | undefined) {
  if (prevStat == null) {
    return undefined;
  }

  const value = toBig(stat.PnLTotal);
  const prevValue = toBig(prevStat.PnLTotal);
  if (value == null || prevValue == null) {
    return undefined;
  }

  const diff = value.minus(prevValue);

  if (diff.eq(0)) {
    return '0';
  }

  if (prevValue.eq(0)) {
    return undefined;
  }

  return diff.div(prevValue.abs()).times(100).toFixed();
}
