import { useCurrenciesContext } from '../contexts';
import type { Security } from '../types/Security';

interface UseCurrencyIncrementProps {
  security: Security | undefined;
  isCcy: boolean;
}

export const useSecurityDefaultIncrement = ({ security, isCcy }: UseCurrencyIncrementProps): string | undefined => {
  const { currenciesBySymbol } = useCurrenciesContext();
  if (!security) {
    return '';
  }

  const baseCurrency = currenciesBySymbol.get(security.BaseCurrency);
  const quoteCurrency = currenciesBySymbol.get(security.QuoteCurrency);
  return isCcy ? quoteCurrency?.DefaultIncrement : baseCurrency?.DefaultIncrement;
};
