import { ThresholdTypeEnum, prettifyThresholdTypeEnum, type SubAccountWindowLimit } from '@talos/kyoko';

const REQUIRED = ['ThresholdType', 'ThresholdValue', 'WindowDuration'] satisfies (keyof SubAccountWindowLimit)[];
const LIMITS_REQUIRING_ASSET = [ThresholdTypeEnum.NewOrderVolume, ThresholdTypeEnum.TotalActiveVolume];

export function validateWindowLimit(limit: Partial<SubAccountWindowLimit>) {
  const problemField = REQUIRED.find(requirement => limit[requirement] == null || limit[requirement] === '');
  if (problemField) {
    throw new Error(`All limits must specify a ${problemField}`);
  }

  // Check that, if thresholdType is defined, there's an asset also defined if thats required
  if (limit.ThresholdType && LIMITS_REQUIRING_ASSET.includes(limit.ThresholdType) && limit.ThresholdAsset == null) {
    throw new Error(
      `When using Threshold Type ${prettifyThresholdTypeEnum(
        limit.ThresholdType
      )}, you are required to specify a Threshold Asset as well`
    );
  }
}
