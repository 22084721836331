import {
  CreditBlotterExposure,
  getAgGridColId,
  numericComparator,
  useDefaultColumns,
  type Column,
  type ColumnDef,
  type DealerPosition,
} from '@talos/kyoko';
import type { ICellRendererParams, IRowNode, ValueFormatterParams } from 'ag-grid-community';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useDisplaySettings } from '../../../../../../providers/DisplaySettingsProvider';
import { CreditUsage } from '../../../../../Blotters/Credit/CreditUsage';
import type { PositionMonitoringColumn } from '../types';
import type { ByAccountEntity } from './types';

export const usePositionMonitoringByAccountColumns = (defaultColumns: PositionMonitoringColumn[]) => {
  const { homeCurrency } = useDisplaySettings();

  const columnDefinitions = useMemo(() => {
    return new Map<string, Column>(
      compact([
        {
          id: 'counterparty',
          type: 'counterpartyFromMarketAccount', // derived column
          title: 'Counterparty',
          sortable: true,
          width: 150,
          params: {
            marketAccountNameField: 'MarketAccount' satisfies keyof DealerPosition,
          },
          aggregate: true,
        },
        {
          id: 'MarketAccount',
          field: 'MarketAccount',
          type: 'account',
          sortable: true,
          aggregate: true,
        },
        {
          id: 'Asset',
          field: 'Asset',
          type: 'asset',
          sortable: true,
          title: 'Asset',
          params: {
            assetTypeField: 'AssetType',
            colorful: true,
          },
        },
        {
          field: 'incoming',
          type: 'size',
          title: 'Receive',
          sortable: true,
          aggregate: true,
          width: 150,
          params: {
            currencyField: 'Asset',
            highlightNegative: true,
          },
        },
        {
          field: 'outgoing',
          type: 'size',
          title: 'Send',
          sortable: true,
          aggregate: true,
          width: 150,
          params: {
            currencyField: 'Asset',
            highlightNegative: true,
          },
        },
        {
          field: 'incomingEquivalent',
          type: 'size',
          title: `Receive (${homeCurrency})`,
          sortable: true,
          aggregate: true,
          width: 150,
          params: {
            currencyField: 'Equivalent.Currency',
            highlightNegative: true,
          },
        },
        {
          field: 'outgoingEquivalent',
          type: 'size',
          title: `Send (${homeCurrency})`,
          sortable: true,
          aggregate: true,
          width: 150,
          params: {
            currencyField: 'Equivalent.Currency',
            highlightNegative: true,
          },
        },
        {
          type: 'custom',
          field: 'creditUsages',
          sortable: true,
          params: {
            width: 240,
            cellRenderer: ({
              value,
              data,
            }: ICellRendererParams<ByAccountEntity, CreditBlotterExposure['creditUsages']>) => {
              if (!value || !data || !(data instanceof CreditBlotterExposure)) {
                return <></>;
              }

              return <CreditUsage value={value} data={data} />;
            },
            valueFormatter: ({
              data,
            }: ValueFormatterParams<ByAccountEntity, CreditBlotterExposure['creditUsages']>) => {
              if (!(data instanceof CreditBlotterExposure)) {
                return '';
              }
              return data?.effectiveSide?.usage?.usage.toFixed() ?? '';
            },
            comparator: (valueA, valueB, nodeA: IRowNode<ByAccountEntity>, nodeB: IRowNode<ByAccountEntity>) => {
              if (!(nodeA.data instanceof CreditBlotterExposure) || !(nodeB.data instanceof CreditBlotterExposure)) {
                return 0;
              }
              return numericComparator(
                nodeA.data?.effectiveSide?.usage?.usage ?? 0,
                nodeB.data?.effectiveSide?.usage?.usage ?? 0
              );
            },
          },
        },
        {
          field: 'AvgCost',
          type: 'sizedAvg',
          width: 150,
          params: {
            sizeField: 'Amount',
            sizeCurrencyField: 'Asset',
            markField: 'AvgCost',
            markCurrencyField: 'AvgCostCurrency',
          },
          aggregate: true,
        },
        {
          field: 'MarkPrice',
          type: 'price',
          width: 110,
          params: {
            assetField: 'Asset',
            quoteCurrencyField: 'MarkPriceCurrency',
          },
        },
        {
          field: 'UnsettledAmount',
          type: 'size',
          title: 'Unsettled Amount',
          params: {
            currencyField: 'Asset',
            highlightNegative: true,
          },
          aggregate: true,
        },
        {
          field: 'Equivalent.UnsettledAmount',
          type: 'size',
          title: `Unsettled Amount (${homeCurrency})`,
          params: {
            currencyField: 'Equivalent.Currency',
            highlightNegative: true,
          },
          aggregate: true,
        },
        {
          field: 'UnsettledAvgCost',
          type: 'price',
          width: 150,
          title: 'Unsettled Avg Cost',
          params: {
            quoteCurrencyField: 'AvgCostCurrency',
          },
        },
        {
          field: 'UnsettledPnL',
          type: 'size',
          title: 'Unsettled Exposure PnL',
          width: 200,
          params: {
            currencyField: 'PnLCurrency',
            highlightNegative: true,
          },
          aggregate: true,
        },
        {
          field: 'Equivalent.UnsettledPnL',
          type: 'size',
          title: `Unsettled Exposure PnL (${homeCurrency})`,
          width: 220,
          params: {
            currencyField: 'Equivalent.Currency',
            highlightNegative: true,
          },
          aggregate: true,
        },
        {
          field: 'LastUpdateTime',
          type: 'date',
          hide: true,
        },
        {
          field: 'Status',
          type: 'text',
          hide: true,
        },
      ] satisfies (ColumnDef<ByAccountEntity> | undefined)[]).map(c => [getAgGridColId(c), c])
    );
  }, [homeCurrency]);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};
