import {
  ACTION,
  Button,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  TradeStatusEnum,
  filterByCellValueMenuItem,
  getRowNodesToOperateOn,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type Column,
  type FilterableProperty,
  type MixpanelInstance,
  type Trade,
  type UseDisclosureReturn,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams, MenuItemDef } from 'ag-grid-enterprise';
import { OMSView } from 'components/OMS/OMSView';
import { CancelDialog } from 'containers/Blotters/Trades/CancelDialog';
import { BulkModifyTradeSubAccountDialog } from 'containers/Blotters/Trades/ModifyTradeSubAccountDialog';
import type { GenerateOrderDetailsRoute, OrderRouteType } from 'containers/Trading/Markets/OrderDetails/types';
import { compact } from 'lodash';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo, useState, type ReactNode } from 'react';
import { prime } from '../../../components/OMS/ManualTradeView/ManualTradeSlice';
import { openView } from '../../../components/OMS/OMSSlice';
import { useRoleAuth } from '../../../hooks';
import { openDetailsMenuItem, useOnOpenOrderDetails } from '../Orders/useOrderMenu';
import { colIDToFilterBuilderKey } from './useTradeFilter';

export function TradeMenu({
  data: trade,
  onShowJson,
}: ICellRendererParams & {
  onShowJson(data: Trade): void;
}) {
  return (
    <>
      <Button size={FormControlSizes.Small} ghost={true} onClick={() => onShowJson(trade)}>
        Show JSON
      </Button>
    </>
  );
}

export const useTradeMenu = ({
  openClause,
  filterableProperties,
  generateOrderDetailsRoute,
  showOpenOrderDetails = true,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
  showOpenOrderDetails?: boolean;
}): {
  column: Column;
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} => {
  const [selectedTrades, setSelectedTrades] = useState<Trade[]>([]);
  const { handleClickJson, jsonModal } = useJsonModal();
  const mixpanel = useMixpanel();
  const dispatch = useAppStateDispatch();
  const { isAuthorized } = useRoleAuth();
  const modifyManualOrOrphanTradeSubAccountDialog = useDisclosure();
  const cancelManualTradeDialog = useDisclosure();
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const modifyManualTrade = useDynamicCallback(({ trade }: { trade: Trade }) => {
    dispatch(prime(trade));
    dispatch(openView(OMSView.ManualTradeForm));
  });

  const onOpenDetails = useOnOpenOrderDetails(generateOrderDetailsRoute);

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<Trade>) => {
    // Get the selected rows (trades) from the api as of this function running to ensure we get the correct ones
    const trades = compact(getRowNodesToOperateOn(params).map(node => node.data));
    setSelectedTrades(trades);

    const authorizedToEditRegularTrade = isAuthorized(ACTION.EDIT_TRADE);

    const rightClickedTrade = params.node?.data;
    if (rightClickedTrade == null) {
      return [];
    }

    return compact([
      ...filterByCellValueMenuItem({
        params,
        filterableProperties,
        openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      {
        name: `Show JSON`,
        action: () => {
          mixpanel.track(MixpanelEvent.ShowJSON);
          handleClickJson(rightClickedTrade);
        },
        icon: `<i class="ag-icon ${IconName.Braces}"/>`,
      },
      ...modifyCancelManualTradeMenuItem({
        trades,
        modify: modifyManualTrade,
        cancel: () => cancelManualTradeDialog.open(),
      }),
      showOpenOrderDetails ? openOrderDetailsMenuItem({ trade: rightClickedTrade, onOpenDetails }) : undefined,
      modifyManualOrOrphanTradeSubAccountItem({
        trades,
        dialog: modifyManualOrOrphanTradeSubAccountDialog,
        mixpanel,
      }),
      authorizedToEditRegularTrade
        ? modifyRegularTradeMenuItem({
            trades,
            modify: modifyManualTrade,
          })
        : undefined,
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);
  });

  const column = useMemo<Column>(
    () => ({
      type: 'hamburgerMenu',
      id: 'tradeMenu',
      params: {
        renderItems: params => <TradeMenu {...params} onShowJson={handleClickJson} />,
      },
    }),
    [handleClickJson]
  );

  return {
    column,
    getContextMenuItems,
    dialogs: (
      <>
        {jsonModal}
        <CancelDialog key="cancel-trade-dialog" selectedTrades={selectedTrades} {...cancelManualTradeDialog} />

        <BulkModifyTradeSubAccountDialog
          key="modify-manual-trades-sub-acct"
          selectedTrades={modifyManualOrOrphanTradeSubAccountItem({ trades: selectedTrades, mixpanel })?.trades}
          {...modifyManualOrOrphanTradeSubAccountDialog}
        />
      </>
    ),
  };
};

// TODO should add panel to main when using flex layout
const openOrderDetailsMenuItem = ({
  trade,
  onOpenDetails,
}: {
  trade: Trade;
  onOpenDetails: (orderID: string, type: OrderRouteType) => void;
}) => {
  // Based on the trade, we identify if the orderID on the trade is for a CustomerOrder or a regular Order
  let type: OrderRouteType = 'principal';
  // provide a orderID only function to open the details curried with the type
  const curriedOpenDetails = (type: OrderRouteType) => {
    return (orderID: string) => onOpenDetails(orderID, type);
  };

  // 1. presumably a manual trade, no order details available
  if (!trade.OrderID) {
    return null;
  }

  // 2. dealer trade
  if (trade.Market === 'dealer') {
    type = 'customer';
  }

  // 3. assume order/:orderID
  return openDetailsMenuItem({
    orders: [{ OrderID: trade.OrderID }],
    onOpenDetails: curriedOpenDetails(type),
  });
};

export const modifyCancelManualTradeMenuItem = ({
  trades,
  modify,
  cancel,
}: {
  trades: Trade[];
  modify: ({ trade }: { trade: Trade }) => any;
  cancel: (tradeID: string) => any;
}) => {
  if (!trades || trades.length !== 1) {
    return [];
  }
  const trade = trades[0];
  if (!trade.isManualBookedTrade || trade?.TradeStatus === TradeStatusEnum.Canceled) {
    return [];
  }
  return [
    {
      name: `Modify Trade`,
      action: () => modify({ trade }),
      icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
    },
    {
      name: `Cancel Trade`,
      action: () => cancel(trade.TradeID),
      icon: `<i class="ag-icon ${IconName.MinusCircle}"/>`,
    },
  ];
};

export const modifyRegularTradeMenuItem = ({
  trades,
  modify,
}: {
  trades: Trade[];
  modify: ({ trade }: { trade: Trade }) => void;
}) => {
  if (!trades || trades.length !== 1) {
    return undefined;
  }
  const trade = trades[0];
  // Dont go this path if we're a manual trade, theres another menu item for that. We're just here for the non-manual trades.
  if (trade.isManualBookedTrade || trade.TradeStatus === TradeStatusEnum.Canceled) {
    return undefined;
  }

  return {
    name: `Modify Trade`,
    action: () => modify({ trade }),
    icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
  };
};

function modifyManualOrOrphanTradeSubAccountItem({
  trades,
  dialog,
  mixpanel,
}: {
  trades: Trade[];
  dialog?: UseDisclosureReturn;
  mixpanel: MixpanelInstance;
}) {
  const relevantTrades = trades.filter(trade => trade.isManualBookedTrade || trade.isOrphanTrade);
  if (relevantTrades.length === 0) {
    return undefined;
  }

  return {
    name:
      relevantTrades.length === 1
        ? `Modify Sub Account(s)`
        : `Modify Sub Account(s) for ${relevantTrades.length} Trades`,
    action: () => {
      dialog?.open();
      mixpanel.track(MixpanelEvent.ModifySubaccount);
    },
    icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
    trades: relevantTrades,
  };
}
