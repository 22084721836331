import {
  SubAccountTypeEnum,
  compactArray,
  createTreeFromRollupMemberships,
  getAllChildrenOfSubAccount,
  getAllParentsForSubAccount,
  useSyncedRef,
  type ISubaccount,
  type Tree,
} from '@talos/kyoko';
import { useSubAccountRollupMemberships } from 'providers/SubAccountRollupMembershipsProvider';
import { useMemo } from 'react';
import { useSubAccounts } from '../providers/SubAccountsContext';

/**
 * Builds a sub account rollup tree for you
 */
export const useRollupTree = (): Tree<ISubaccount> => {
  const { rollupMembershipsByParentChild, rollupMembershipsByChildParent } = useSubAccountRollupMemberships();
  const { subAccountsByID } = useSubAccounts();

  const rollupTree = useMemo(() => {
    const tree = createTreeFromRollupMemberships(
      rollupMembershipsByParentChild,
      rollupMembershipsByChildParent,
      subAccountsByID || new Map()
    );
    return tree;
  }, [subAccountsByID, rollupMembershipsByParentChild, rollupMembershipsByChildParent]);

  return rollupTree;
};

function sortSubAccountsById(subAccountsByID: Map<number, ISubaccount>) {
  return (a: number, b: number) => {
    const nameA = subAccountsByID.get(a)?.DisplayName;
    const nameB = subAccountsByID.get(b)?.DisplayName;
    return nameA && nameB ? nameA.localeCompare(nameB) : 0;
  };
}

/**
 * Builds a sub account rollup tree for you and returns it as a ref for performance needs
 */
export const useRollupTreeRef = () => {
  const rollupTree = useRollupTree();
  const rollupTreeRef = useSyncedRef(rollupTree);

  return rollupTreeRef;
};

type RollupHierarchyRowNode = {
  subAccountId: number;
  name: string;
  key: string;
  level: number;
  parents: number[];
  searchDescription: string;
};

type SubAccountNode = {
  id: number;
  children: number[];
  item: ISubaccount | undefined;
};

/** Builds a rollup/subaccount array useful for display building using levels for indent.  The results will be sorted by DisplayName at each level */
export function useRollupTreeList(): RollupHierarchyRowNode[] {
  const tree = useRollupTree();
  const { rollupMembershipsByChildParent } = useSubAccountRollupMemberships();
  const { subAccountsByID, allSubAccounts } = useSubAccounts();

  const result = useMemo(() => {
    const hasRollups = allSubAccounts.some(subAccount => subAccount.Type === SubAccountTypeEnum.Rollup);
    if (hasRollups && !rollupMembershipsByChildParent.size) {
      // If we have rollups but no rollup memberships, don't return the tree yet
      return [];
    }
    const subAccountIdsWithParent = Array.from(rollupMembershipsByChildParent.keys());
    const allSubAccountIds = Array.from(subAccountsByID.keys());
    const missingSubAccountIds = allSubAccountIds.filter(
      id => subAccountsByID.get(id)?.Type === SubAccountTypeEnum.Book && !subAccountIdsWithParent.includes(id)
    );
    const missingSubAccounts: Array<[number, SubAccountNode]> = missingSubAccountIds.map(id => [
      id,
      {
        id,
        children: [],
        item: subAccountsByID.get(id),
      },
    ]);

    const rollupResult: RollupHierarchyRowNode[] = [];
    const idToRollupSubAccountMap: Array<[number, SubAccountNode]> = compactArray(
      Array.from(tree.nodes.entries()).map(([key, value]) => [
        Number(key),
        {
          id: Number(key),
          children: value.children.map(Number),
          item: value.item,
        },
      ])
    );
    const idToSubAccountMap = idToRollupSubAccountMap.concat(missingSubAccounts);
    const treeByNumericKeys = new Map(idToSubAccountMap);
    const numericRoots = tree.rootIds.map(Number).concat(missingSubAccountIds);
    numericRoots.sort(sortSubAccountsById(subAccountsByID));
    numericRoots.forEach(id => {
      const queue: Array<RollupHierarchyRowNode> = [
        {
          subAccountId: id,
          level: 0,
          key: id.toString(),
          parents: [],
          name: subAccountsByID.get(id)?.DisplayName ?? '',
          searchDescription: subAccountsByID.get(id)?.DisplayName ?? '',
        },
      ];
      while (queue.length > 0) {
        // queue is never empty here, hence the non-null assertion
        const { subAccountId, level, key, name, searchDescription, parents } = queue.shift()!;
        rollupResult.push({ subAccountId, level, key, name, searchDescription, parents });

        const nextLevelChildIds = treeByNumericKeys.get(subAccountId)?.children ?? [];
        nextLevelChildIds.sort(sortSubAccountsById(subAccountsByID));
        queue.unshift(
          ...nextLevelChildIds.map(childId => {
            const childName = subAccountsByID.get(childId)?.DisplayName ?? '';
            return {
              subAccountId: childId,
              level: level + 1,
              key: `${key}-${childId}`,
              parents: parents.concat(subAccountId),
              name: childName,
              searchDescription: `${searchDescription} > ${childName}`,
            };
          })
        );
      }
    });

    return rollupResult;
  }, [allSubAccounts, rollupMembershipsByChildParent, subAccountsByID, tree.nodes, tree.rootIds]);

  return result;
}

/** Mapping of ALL unique parents and children ids for each SubAccount */
export function useSubAccountRelationMap(): Map<
  number,
  {
    parents: Set<number>;
    children: Set<number>;
  }
> {
  const { rollupMembershipsByChildParent, rollupMembershipsByParentChild } = useSubAccountRollupMemberships();
  const { allSubAccounts } = useSubAccounts();

  return useMemo(() => {
    return allSubAccounts.reduce(
      (acc, subAccount) => {
        const parents = getAllParentsForSubAccount(subAccount.SubaccountID, rollupMembershipsByChildParent);
        const children = getAllChildrenOfSubAccount(subAccount.SubaccountID, rollupMembershipsByParentChild);
        acc.set(subAccount.SubaccountID, {
          parents,
          children,
        });
        return acc;
      },
      new Map<
        number,
        {
          parents: Set<number>;
          children: Set<number>;
        }
      >()
    );
  }, [allSubAccounts, rollupMembershipsByChildParent, rollupMembershipsByParentChild]);
}
