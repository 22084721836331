import { request, useDynamicCallback, useEndpointsContext, type User } from '@talos/kyoko';
import { useEffect, useMemo, useState } from 'react';
import { UsersContext, type UsersContextState } from './UsersContext';

export function UsersContextProvider({ children }) {
  const { orgApiEndpoint } = useEndpointsContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  const fetchUsers = useDynamicCallback(async (includeDeleted?: boolean) => {
    request<{ data: User[] }>('GET', `${orgApiEndpoint}/users${includeDeleted ? '?include-deleted=true' : ''}`)
      .then(res => {
        setUsers(res.data);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(false);
        setUsers([]);
      });
  });

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const userByUserName = useMemo(() => new Map<string, User>((users || []).map(user => [user.Name, user])), [users]);
  const userByID = useMemo(() => new Map<string, User>((users || []).map(user => [user.ID ?? '', user])), [users]);
  const refetchUsers = fetchUsers;

  const value: UsersContextState = useMemo(
    () => ({
      isLoaded,
      users,
      userByUserName,
      userByID,
      refetchUsers,
    }),
    [isLoaded, users, userByUserName, userByID, refetchUsers]
  );

  return <UsersContext.Provider value={value}>{children} </UsersContext.Provider>;
}
