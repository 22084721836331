import { InlineFormattedNumber } from '@talos/kyoko';
import Big, { type BigSource } from 'big.js';

export function rateify(value: BigSource) {
  return (
    <InlineFormattedNumber
      number={Big(value || '0')
        .times(100)
        .toFixed()}
      increment="0.000001"
      round={true}
      currency="%"
    />
  );
}
