import { createContext, useCallback, useContext, useMemo } from 'react';
import { usePositionsFormContext } from './PositionsFormProvider';

export interface DerivedPositionsFormState {
  isOnlySelectedMarketAccount: (marketAccount: string) => boolean;
}

export const DerivedPositionsFormStateContext = createContext<DerivedPositionsFormState | undefined>(undefined);
DerivedPositionsFormStateContext.displayName = 'DerivedPositionsFormStateContext';

export function useDerivedPositionsFormState() {
  const context = useContext(DerivedPositionsFormStateContext);
  if (context === undefined) {
    throw new Error(
      'Missing DerivedPositionsFormStateContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

/**
 * This provider listens to state changes in the PositionsFormContext and derives certain
 * state properties from this. These are calculations that we only want to do once, and not have every child
 * element do them on every render. We can also be smart here and do some more rigorous checks if whether or not
 * the underlying data has changed before just updating the derived state.
 *
 * The whole point of this provider is  to increase performance. Also to learn about potential design patterns to use going forward.
 */
export const DerivedPositionsFormStateProvider = function DerivedPositionsFormStateProvider({ children }) {
  const { state } = usePositionsFormContext();

  // The first piece of derived state we want is for each row to know whether or not any marketaccount is regarded as the only one selected
  const onlyMap = useMemo(() => {
    const onlyMap = new Map<string, boolean>();
    state.forEach((mktAccMap, mktAcc) => {
      const mktAccMapHasSomeCheckedEntry = [...mktAccMap.values()].some(entry => entry.checked);
      if (mktAccMapHasSomeCheckedEntry) {
        onlyMap.set(mktAcc, mktAccMapHasSomeCheckedEntry);
      }
    });

    if (onlyMap.size > 1) {
      onlyMap.clear();
    }

    return onlyMap;
  }, [state]);

  const isOnlySelectedMarketAccount = useCallback(
    (marketAccount: string) => {
      return onlyMap.get(marketAccount) ?? false;
    },
    [onlyMap]
  );

  const value = useMemo(() => {
    return {
      isOnlySelectedMarketAccount,
    };
  }, [isOnlySelectedMarketAccount]);

  return (
    <DerivedPositionsFormStateContext.Provider value={value}>{children}</DerivedPositionsFormStateContext.Provider>
  );
};
