import type { Position } from '@talos/kyoko';

/** Common Props used with the Blotter components */
export interface BaseBlotterProps {
  id: string;
  label: string;
  tag: string;
}
export function getPositionMapKey(item: Pick<Position, 'SubAccount' | 'Asset'>): string {
  return !item.SubAccount ? '' : item.SubAccount + '@@@@' + item.Asset;
}
