// Note this is a subset of the Order Strategies that exist. The full set is driven by the backend.
export enum OrderStrategiesEnum {
  Limit = 'Limit',
  FullAmount = 'FullAmount',
  Pegged = 'Pegged',
  TWAP = 'TWAP',
  TimeSliced = 'TimeSliced',
  SteadyPace = 'SteadyPace',
  StopLimit = 'StopLimit',
  TakeProfitLimit = 'TakeProfitLimit',
  EnclaveCross = 'EnclaveCross',
  MultilegAggressiveLimit = 'MultilegAggressiveLimit',
  PostOnly = 'PostOnly',
  Iceberg = 'Iceberg',
  MultilegTWAP = 'MultilegTWAP',
  MarketTWAP = 'MarketTWAP',
  RefRatePegSteadyPace = 'RefRatePegSteadyPace',
  RefRatePegTimeSliced = 'RefRatePegTimeSliced',
  AggressivePeggedMarket = 'AggressivePeggedMarket',
  SteadyPaceStop = 'SteadyPaceStop',
  Market = 'Market',
  SteadyPaceRefRate = 'SteadyPaceRefRate',
  TWAPv1 = 'TWAPv1',
  PegMid = 'PegMid',
  StopMarket = 'StopMarket',
  TakeProfitMarket = 'TakeProfitMarket',
  PercentOfVolume = 'PercentOfVolume',
  MultilegPassiveLimit = 'MultilegPassiveLimit',
  StopFullAmount = 'StopFullAmount',
  TakeProfitFullAmount = 'TakeProfitFullAmount',
  TWAPStopv2 = 'TWAPStopv2',
  StopTWAP = 'StopTWAP',
  TakeProfitTWAP = 'TakeProfitTWAP',
  MultilegLimitV2 = 'MultilegLimitV2',
  OpportunisticLimit = 'OpportunisticLimit',
}

// Note this is a subset of the Parameter Keys that exist. The full set is driven by the backend.
export enum ParameterKeysEnum {
  PriceOffset = 'PriceOffset',
  PriceProtection = 'PriceProtection',
  UnifiedLiquidity = 'UnifiedLiquidity',
  ReduceOnly = 'ReduceOnly',
  ReduceFirst = 'ReduceFirst',
  ShowQty = 'ShowQty',
  Duration = 'Duration',
  EndTime = 'EndTime',
  StartTime = 'StartTime',
}

export const DISABLED_REASONABILITY_CHECK_STRATEGIES: Set<string> = new Set<OrderStrategiesEnum>([
  OrderStrategiesEnum.Pegged,
  OrderStrategiesEnum.TWAP,
  OrderStrategiesEnum.SteadyPace,
  OrderStrategiesEnum.TimeSliced,
]);
