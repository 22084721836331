import type { ReactNode } from 'react';
import { AccordionBody } from '../AccordionBody';
import { AccordionRowCell } from './styles';

export interface AccordionRowBodyCellProps {
  children?: ReactNode;
  colSpan?: number;
}

export const AccordionRowBodyCell = ({ children, colSpan = 1 }: AccordionRowBodyCellProps) => {
  return (
    <AccordionRowCell body={true} colSpan={colSpan}>
      <AccordionBody p="0">{children}</AccordionBody>
    </AccordionRowCell>
  );
};
