import { OrdStatusEnum, QuoteStatusEnum, type MeterBarProps } from '@talos/kyoko';
import Big from 'big.js';
import { isOrderRow, isQuoteRow, type OrderRow, type QuoteRow } from './types';

const pendingFixingQuoteStatuses = [QuoteStatusEnum.PendingFix];
const fixingAppliedQuoteStatuses = [QuoteStatusEnum.Filled];
const workingQuoteStatuses = [QuoteStatusEnum.Open, QuoteStatusEnum.PendingFill, QuoteStatusEnum.PendingNew];

const pendingFixingOrdStatuses = [OrdStatusEnum.Filled];
const fixingAppliedOrdStatuses = [OrdStatusEnum.DoneForDay];
const workingOrdStatuses = [
  OrdStatusEnum.New,
  OrdStatusEnum.PartiallyFilled,
  OrdStatusEnum.PendingNew,
  OrdStatusEnum.PendingCancel,
  OrdStatusEnum.PendingReplace,
  OrdStatusEnum.Replaced,
  OrdStatusEnum.Replaced,
];

export interface CareOrderProgressBar {
  id: 'fixingApplied' | 'pendingFixing' | 'working';
  valuePercent: Big;
  valueNotional: Big;
  color: MeterBarProps['color'];
  appearance?: MeterBarProps['appearance'];
}

export function childRowsToMeterBars(childRows: { data: OrderRow | QuoteRow }[] | null | undefined, totalQty: string) {
  if (childRows == null) {
    throw new Error('missing child rows');
  }
  const fixingApplied: CareOrderProgressBar = {
    id: 'fixingApplied',
    valueNotional: Big(0),
    valuePercent: Big(0),
    color: 'var(--colors-green-lighten)',
    appearance: 'filled',
  } as const;
  const pendingFixing: CareOrderProgressBar = {
    id: 'pendingFixing',
    valueNotional: Big(0),
    valuePercent: Big(0),
    color: 'var(--colors-blue-lighten)',
    appearance: 'filled',
  } as const;
  const working: CareOrderProgressBar = {
    id: 'working',
    valueNotional: Big(0),
    valuePercent: Big(0),
    color: 'var(--colors-blue-lighten)',
    appearance: 'dashed',
  } as const;

  // Compute notional values
  for (const row of childRows) {
    let value: string | undefined;
    // Used as a mutable reference
    let target: { valueNotional: Big; valuePercent: Big } | undefined;

    if (isQuoteRow(row.data)) {
      const quote = row.data;
      value = quote.OrderQty;
      if (pendingFixingQuoteStatuses.includes(quote.QuoteStatus)) {
        target = pendingFixing;
      } else if (fixingAppliedQuoteStatuses.includes(quote.QuoteStatus)) {
        target = fixingApplied;
      } else if (workingQuoteStatuses.includes(quote.QuoteStatus)) {
        target = working;
      }
    } else if (isOrderRow(row.data)) {
      const order = row.data;
      value = order.OrderQty;
      if (pendingFixingOrdStatuses.includes(order.OrdStatus)) {
        target = pendingFixing;
      } else if (fixingAppliedOrdStatuses.includes(order.OrdStatus)) {
        target = fixingApplied;
      } else if (workingOrdStatuses.includes(order.OrdStatus)) {
        target = working;
        continue;
      }
    }

    if (value == null || target == null) {
      continue;
    }

    target.valueNotional = target.valueNotional.plus(Big(value));
  }

  // Compute percentages of total
  for (const target of [fixingApplied, pendingFixing, working]) {
    target.valuePercent = target.valueNotional.div(totalQty);
  }

  return [fixingApplied, pendingFixing, working];
}
