import {
  Box,
  Button,
  ButtonVariants,
  Dialog,
  Divider,
  FormControlSizes,
  FormGroup,
  HStack,
  NotificationVariants,
  SearchSelect,
  Text,
  TreasuryLinkDirectionEnum,
  TreasuryLinkTypeEnum,
  VStack,
  useCurrenciesContext,
  useDisclosure,
  useDynamicCallback,
  useGlobalToasts,
  useObservableValue,
  wsScanToMap,
  type Currency,
  type MinimalSubscriptionResponse,
  type TreasuryLink,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { map, type Observable } from 'rxjs';
import { useSuggestedTreasuryLinkCurrencies } from './hooks/useSuggestedTreasuryLinkCurrencies';
import { useTreasuryRequests } from './hooks/useTreasuryRequests';

interface NewLinkButtonProps {
  marketAccount: string;
  type: TreasuryLinkTypeEnum;
  direction: TreasuryLinkDirectionEnum;
  treasuryLinksObs: Observable<MinimalSubscriptionResponse<TreasuryLink>>;
}

function getLabel(currency: Currency) {
  return currency.Symbol;
}

export function NewLinkButton({ marketAccount, type, direction, treasuryLinksObs }: NewLinkButtonProps) {
  const { add: addToast } = useGlobalToasts();
  const { putTreasuryLink } = useTreasuryRequests();
  const dialogProps = useDisclosure();
  const { open } = dialogProps;

  const currenciesWithTreasuryLink: Set<string> | undefined = useObservableValue(() => {
    return treasuryLinksObs.pipe(
      wsScanToMap({ getUniqueKey: link => link.LinkID, newMapEachUpdate: false }),
      map(linksByLinkID => {
        const linksArray = [...linksByLinkID.values()];
        const linksCurrencies = compact(linksArray.map(link => link.Currency));
        const currenciesSet = new Set(linksCurrencies);
        return currenciesSet;
      })
    );
  }, [treasuryLinksObs]);

  const [currency, setCurrency] = useState<Currency | undefined>(undefined);
  const { currenciesList } = useCurrenciesContext();

  const handleAddSelectedCurrency = useDynamicCallback(() => {
    if (currency == null) {
      return;
    }
    addCurrency(currency.Symbol);
    setCurrency(undefined);
  });

  const addCurrency = useDynamicCallback((currencyToAdd: string) => {
    putTreasuryLink({
      Currency: currencyToAdd,
      MarketAccount: marketAccount,
      Type: type,
      Direction: direction,
    })
      .then(() => {
        addToast({
          text: 'Treasury link added',
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) => {
        addToast({
          text: e?.toString() || `Could not delete API Key`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  const handleAddAllClicked = useDynamicCallback(() => {
    const promises = remainingSuggestedCurrencies.map(currency =>
      putTreasuryLink({
        Currency: currency,
        MarketAccount: marketAccount,
        Type: type,
        Direction: direction,
      })
    );

    Promise.allSettled(promises)
      .then(results => {
        const fulfilledCount = results.filter(r => r.status === 'fulfilled').length;
        const rejectedCount = results.filter(r => r.status === 'rejected').length;
        if (fulfilledCount > 0) {
          addToast({
            text: `${fulfilledCount} Treasury links added`,
            variant: NotificationVariants.Positive,
          });
        }
        if (rejectedCount > 0) {
          addToast({
            text: `${rejectedCount} Treasury links failed to add`,
            variant: NotificationVariants.Negative,
          });
        }
      })
      .catch((e: ErrorEvent) => {
        addToast({
          text: `Failed to add all suggested currencies as Treasury links: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  // Needs to be a useCallback - reference needs to change when currenciesWithTreasuryLinks changes.
  const isAddCurrencyDisabled = useCallback(
    (currencySymbol: string) => {
      if (!currenciesWithTreasuryLink) {
        return false;
      }

      // For Customer Deposits, we allow multiple Treasury Links per MarketAccount+Currency.
      // This means that we should allow the user to click on ETH Add several times for example to create n amount of links.
      const allowMultipleTreasuryLinksPerCurrency =
        marketAccount != null &&
        type === TreasuryLinkTypeEnum.CustomerTransfer &&
        direction === TreasuryLinkDirectionEnum.Inbound;
      if (allowMultipleTreasuryLinksPerCurrency) {
        // Adding any currency is always enabled
        return false;
      }
      return currenciesWithTreasuryLink.has(currencySymbol);
    },
    [marketAccount, type, direction, currenciesWithTreasuryLink]
  );

  const isAddCurrencyDisabledStrict = useCallback(
    (currency: Currency) => {
      return isAddCurrencyDisabled(currency.Symbol);
    },
    [isAddCurrencyDisabled]
  );

  const getOptionDescription = useCallback(
    (currency: Currency) => {
      if (isAddCurrencyDisabled(currency.Symbol)) {
        return 'Disabled - link already exists';
      }

      return '';
    },
    [isAddCurrencyDisabled]
  );

  const allSuggestedCurrencies = useSuggestedTreasuryLinkCurrencies({ type, marketAccount });
  // We want to remove a suggested currency from the suggestions list upon selection.
  const remainingSuggestedCurrencies = useMemo(
    () => allSuggestedCurrencies.filter(c => !currenciesWithTreasuryLink?.has(c)),
    [allSuggestedCurrencies, currenciesWithTreasuryLink]
  );

  return (
    <>
      <Button variant={ButtonVariants.Positive} onClick={open} data-testid="new-entry-button">
        New Entry
      </Button>
      <Dialog {...dialogProps} title="Add New Entry" autoFocusFirstElement={false} confirmLabel="Done">
        {remainingSuggestedCurrencies.length > 0 && (
          <FormGroup
            label="Suggested Currencies"
            labelSuffix={
              <HStack w="100%" justifyContent="flex-end">
                <Button size={FormControlSizes.Small} onClick={handleAddAllClicked}>
                  Add all ({remainingSuggestedCurrencies.length})
                </Button>
              </HStack>
            }
          >
            <Box maxHeight="200px" w="100%" overflow="auto" mt="spacingMedium">
              <VStack gap="spacingSmall" overflow="auto">
                {remainingSuggestedCurrencies.map(suggestedCurrency => (
                  <Fragment key={suggestedCurrency}>
                    <HStack justifyContent="space-between" w="100%" data-testid="suggested-currency-row">
                      <Text fontSize="fontSizeDefault" data-testid="suggested-currency-label">
                        {suggestedCurrency}
                      </Text>
                      <Button
                        size={FormControlSizes.Small}
                        onClick={() => addCurrency(suggestedCurrency)}
                        disabled={isAddCurrencyDisabled(suggestedCurrency)}
                        data-testid="add-specific-currency-button"
                      >
                        Add
                      </Button>
                    </HStack>
                    <Divider />
                  </Fragment>
                ))}
              </VStack>
            </Box>
          </FormGroup>
        )}
        <FormGroup label="Add any Currency" minWidth="250px">
          <HStack w="100%" gap="spacingDefault">
            <SearchSelect
              options={currenciesList}
              selection={currency}
              onChange={setCurrency}
              getLabel={getLabel}
              isItemDisabled={isAddCurrencyDisabledStrict}
              getDescription={getOptionDescription}
              data-testid="currency-selector"
              w="100%"
            />
            <Button
              onClick={handleAddSelectedCurrency}
              disabled={currency == null}
              data-testid="add-any-currency-button"
            >
              Add
            </Button>
          </HStack>
        </FormGroup>
      </Dialog>
    </>
  );
}
