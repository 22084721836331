import {
  IconName,
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  useMarketAccountsContext,
  useMarketsContext,
  type FilterableProperty,
} from '@talos/kyoko';
import { compact, uniq } from 'lodash';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for Account properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for Account filters
 */

export function useAccountsFilter<TKey extends string = 'Accounts', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
) {
  const { marketAccountsList } = useMarketAccountsContext();
  const { marketDisplayNameByName } = useMarketsContext();

  const sourceAccountIDs = useMemo(() => {
    return uniq(
      compact(
        marketAccountsList
          .filter(ma => ma.Type === MarketAccountTypeEnum.Customer && ma.Status === MarketAccountStatusEnum.Active)
          .map(ma => {
            const sourceAccountID = ma?.SourceAccountID;
            const marketDisplayName = marketDisplayNameByName.get(ma.Market);
            return sourceAccountID ?? marketDisplayName;
          })
      )
    );
  }, [marketAccountsList, marketDisplayNameByName]);

  return useMemo(
    () => ({
      ...({
        key: 'Accounts' as TKey,
        label: 'Account',
        icon: IconName.Identification,
        options: sourceAccountIDs,
        getOptionLabel: (sourceAccountID: string) => sourceAccountID,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [sourceAccountIDs, overrides]
  );
}
