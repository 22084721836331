import {
  PORTFOLIO_TREASURY_LINKS,
  getTreasuryLinkDirection,
  useSubscription,
  useWSFilterPipe,
  wsScanToMap,
  type TreasuryLink,
  type TreasuryLinkTransactionType,
  type TreasuryLinkTypeEnum,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { map, shareReplay } from 'rxjs';

/**
 * This hook returns a filtered observable of the TreasuryLinks relevant for your provided constraints.
 */
export const useEditableCurrencyRowsBlotterDataObs = ({
  type,
  transactionType,
  marketAccountName,
}: {
  type: TreasuryLinkTypeEnum;
  transactionType?: TreasuryLinkTransactionType;
  marketAccountName?: string;
}) => {
  const { data } = useSubscription({
    name: PORTFOLIO_TREASURY_LINKS,
    tag: 'EditableCurrencyRowsBlotter',
  });

  const filterFunc = useCallback(
    (link: TreasuryLink) =>
      link.Type === type &&
      link.Direction === getTreasuryLinkDirection(type, transactionType) &&
      link.MarketAccount === marketAccountName &&
      link.Currency != null,
    [type, transactionType, marketAccountName]
  );
  const filterPipe = useWSFilterPipe<TreasuryLink>({ getUniqueKey: getTreasuryLinkKey, filterFunc });

  return useMemo(
    () =>
      data.pipe(
        filterPipe,
        // We need to support Deletion actions. Our blotters dont understand UpdateAction.Remove, so we have to do wsScanToMap which does understand it,
        // and then instead just always send initial: true messages. This is fine for (filtered down) treasury links, there will never be _that_ many. For now, I guess...
        wsScanToMap({ getUniqueKey: link => link.LinkID, newMapEachUpdate: false }),
        map(scannedMap => ({
          initial: true,
          ts: '',
          data: [...scannedMap.values()],
        })),
        shareReplay(1)
      ),
    [data, filterPipe]
  );
};

function getTreasuryLinkKey(treasuryLink: TreasuryLink) {
  return treasuryLink.LinkID;
}
