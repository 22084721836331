import {
  FormRowStatus,
  ModeEnum,
  NotificationVariants,
  PositionSideTypeEnum,
  promisesHaveResolved,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  type ISubaccount,
  type SubAccountPositionLimit,
  type UseFormTableProps,
} from '@talos/kyoko';
import { useSubAccountPositionLimits } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { useSubAccountLimitsForSubAccount, useSubAccountOptionsForSubAccount } from '../utils';
import { useColumns } from './useColumns';
import { validatePositionLimit } from './validation';

interface UseSubAccountTradingLimitsBlotterProps {
  forSubAccount?: ISubaccount;
  quickSearchParams?: UseFormTableProps<SubAccountPositionLimit>['quickSearchParams'];
}

export const useSubAccountTradingLimitsBlotter = ({
  forSubAccount,
  quickSearchParams,
}: UseSubAccountTradingLimitsBlotterProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { add: addToast } = useGlobalToasts();
  const {
    subAccountPositionLimitsList,
    createSubAccountPositionLimit,
    updateSubAccountPositionLimit,
    deleteSubAccountPositionLimit,
  } = useSubAccountPositionLimits();

  const { forSubAccountParentLimits } = useSubAccountLimitsForSubAccount({
    limitsList: subAccountPositionLimitsList,
    forSubAccount,
  });

  const data = useMemo(() => {
    return forSubAccount ? forSubAccountParentLimits : subAccountPositionLimitsList;
  }, [forSubAccount, forSubAccountParentLimits, subAccountPositionLimitsList]);

  const subAccountOptionsOverride = useSubAccountOptionsForSubAccount({ forSubAccount });

  const getSubAccountOptionsGroup = useCallback(
    (subAccount: ISubaccount | undefined) => {
      return subAccount === undefined
        ? 'Wildcard'
        : subAccount.Name === forSubAccount?.Name
        ? 'Selected Sub Account'
        : 'Parent Rollups';
    },
    [forSubAccount]
  );

  const columns = useColumns({
    subAccountOptionsOverride,
    initialSelectedItem: forSubAccount,
    getDropdownGroup: forSubAccount ? getSubAccountOptionsGroup : undefined,
  });

  const formTable = useFormTable<Partial<SubAccountPositionLimit>>({
    rowID: 'LimitID' satisfies keyof SubAccountPositionLimit,
    data,
    columns,
    quickSearchParams,
  });

  const handleAdd = useDynamicCallback(() => {
    formTable.addRow({
      Mode: ModeEnum.Enabled,
      Direction: PositionSideTypeEnum.Both,
      SubAccount: forSubAccount?.Name,
    });
  });

  const handleSave = useDynamicCallback(() => {
    setIsSaving(true);

    const rows = formTable.getRows();
    const requests: Promise<unknown>[] = [];

    try {
      for (const row of rows) {
        validatePositionLimit(row.data);
      }
    } catch (e: any) {
      addToast({
        text: `Could not save trading limits: ${e.message}`,
        variant: NotificationVariants.Negative,
      });
      setIsSaving(false);
      return;
    }

    for (const row of rows) {
      switch (row.status) {
        case FormRowStatus.Added:
          requests.push(
            createSubAccountPositionLimit(row.data)
              .then(({ data }) => {
                row.setData(data[0]);
                return data[0];
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not add new Sub Account Trading Limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        case FormRowStatus.Updated:
          requests.push(
            updateSubAccountPositionLimit(row.data)
              .then(() => {
                row.setData(row.data);
                return row.data;
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not update Sub Account Trading Limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        case FormRowStatus.Removed:
          requests.push(
            deleteSubAccountPositionLimit(row.data)
              .then(() => {
                row.remove(true);
                return row.data;
              })
              .catch((e: ErrorEvent) => {
                addToast({
                  text: e?.toString() || `Could not delete Sub Account Trading Limit.`,
                  variant: NotificationVariants.Negative,
                });
              })
          );
          break;
        default:
          break;
      }
    }
    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Sub Account Trading Limits saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Sub Account Trading Limits could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  });

  return {
    onAdd: handleAdd,
    onSave: handleSave,
    formTable,
    isSaving,
    nDataEntries: data?.length ?? 0,
  };
};
