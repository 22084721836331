import { IconName, type FilterableProperty } from '@talos/kyoko';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for ID properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for ID filters
 */

export function useIDFilter<TKey extends string, P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
) {
  return useMemo(
    () => ({
      ...({
        key: 'ID' as TKey,
        label: 'ID',
        icon: IconName.Hashtag,
        control: 'text',
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [overrides]
  );
}
