import { Button, ButtonVariants, FormControlSizes, useJsonModal } from '@talos/kyoko';
import { useDockViewProvider } from 'components/FlexibleLayout/DockViewProvider';
import type { SerializedDockview } from 'dockview';

export const MenuDockView = ({ getLayoutForCurrentView }: { getLayoutForCurrentView: () => SerializedDockview }) => {
  const { restoreLayout } = useDockViewProvider();
  const { handleClickJson, jsonModal } = useJsonModal();

  return (
    <>
      <Button variant={ButtonVariants.Default} size={FormControlSizes.Small} onClick={restoreLayout}>
        Reset to default layout
      </Button>
      <Button
        variant={ButtonVariants.Default}
        size={FormControlSizes.Small}
        onClick={() => {
          handleClickJson(getLayoutForCurrentView());
        }}
      >
        Show layout JSON
      </Button>
      {jsonModal}
    </>
  );
};
