import { useDynamicCallback, useGlobalToasts, useServiceInstantiator, useUserContext } from '@talos/kyoko';
import type { PrimeOMSParams } from 'components/OMS/NewOrder/types';
import { OMSView } from 'components/OMS/OMSView';
import { cloneDeep } from 'lodash';
import { useMarketTabs } from 'providers';
import { useAppStateDispatch, useAppStateStore } from 'providers/AppStateProvider';
import type { MultilegMarketTab, MultilegTabData } from 'providers/MarketTabs.types';
import { primeNewOrderForm } from '../../OMS/NewOrder/OrderSlice';
import { openView } from '../../OMS/OMSSlice';
import { MultilegComboService } from '../MultilegComboService';

export const useMultilegComboService = (tabID: string | undefined): MultilegComboService | undefined => {
  const store = useAppStateStore();
  const dispatch = useAppStateDispatch();
  const { orgApiEndpoint } = useUserContext();
  const { setItems } = useMarketTabs();
  const { add: addToast } = useGlobalToasts();

  const updateMultilegTabDataCallback = useDynamicCallback((tabData: MultilegTabData, label: string) => {
    setItems(prev => {
      const newMarketTabs = [...prev];
      const marketTabIndex = newMarketTabs.findIndex(tab => tab.id === tabID);
      if (marketTabIndex !== -1) {
        const newMarketTab = cloneDeep(newMarketTabs[marketTabIndex]) as MultilegMarketTab;
        newMarketTab.data = tabData;
        newMarketTab.label = label;
        newMarketTab.canSave = true;
        newMarketTabs[marketTabIndex] = newMarketTab;
        return newMarketTabs;
      }
      return prev;
    });
  });

  const primeOrderFormCallback = useDynamicCallback((params: PrimeOMSParams) => {
    dispatch(primeNewOrderForm(params));
    dispatch(openView(OMSView.NewOrderForm));
  });

  const service = useServiceInstantiator(
    () =>
      new MultilegComboService(store, orgApiEndpoint!, primeOrderFormCallback, updateMultilegTabDataCallback, addToast),
    [addToast, orgApiEndpoint, primeOrderFormCallback, store, updateMultilegTabDataCallback]
  );

  return service;
};
