import {
  Box,
  FilterClauseType,
  Panel,
  Tab,
  TabAppearance,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  type FilterClause,
} from '@talos/kyoko';
import type React from 'react';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { PerformanceTab } from './PerformanceTab';
import { usePerformanceTabs } from './providers/PerformanceStateAndTabsProvider';
import { getDefaultPerformanceState } from './providers/getDefaultPerformanceState';
import type { PerformanceFilter } from './types';

const TAB_PANELS_STYLE: React.CSSProperties = {
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

export const Performance = () => {
  const { tabs, filtersInitialOpen } = usePerformanceTabs();

  // This memo computes the current initialFilterClauses based on the current tab.
  // When the current tab changes, it will re-compute and re-pass to the child FilterBuilder
  const initialFilterClauses: FilterClause[] = useMemo(() => {
    const filterToUse = tabs.items[tabs.selectedIndex].filter ?? getDefaultPerformanceState().filter;
    const filterKeys = Object.keys(filterToUse) as (keyof PerformanceFilter)[];
    return filterKeys
      .map(key => {
        const selections = filterToUse[key];

        if (selections == null) {
          // safeguard
          return undefined;
        }

        return {
          key,
          type: FilterClauseType.INCLUSIVE,
          selections,
        };
      })
      .compact();
  }, [tabs.selectedIndex, tabs.items]);

  const theme = useTheme();

  return (
    <Panel w="100%" position="relative" justifyContent="start" flex="1" alignItems="left" h="100%" overflow="hidden">
      <Tabs {...tabs} flex="1" appearance={TabAppearance.Filled} size={TabSize.Small}>
        <Box style={{ borderBottom: `2px solid`, borderBottomColor: theme.backgroundBody }}>
          <TabList isBordered>
            {tabs.items.map((tab, idx) => (
              <Tab key={idx} {...tab} />
            ))}
          </TabList>
        </Box>
        <TabPanels style={TAB_PANELS_STYLE}>
          {tabs.items.map(tab => (
            <PerformanceTab
              key={tab.id!}
              tabId={tab.id!}
              initialFilterClauses={initialFilterClauses}
              filtersInitialOpen={filtersInitialOpen}
            />
          ))}
        </TabPanels>
      </Tabs>
    </Panel>
  );
};
