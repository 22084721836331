import styled from 'styled-components';

export const Actions = styled.div`
  width: 80%;
  margin-left: auto;
  align-items: end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: ${({ theme }) => theme.spacingDefault}px;
  column-gap: ${({ theme }) => theme.spacingDefault}px;
`;
