import type { UseAccordionFilterBuilderOutput } from '@talos/kyoko';
import { createContext, useContext, useMemo, useState, type Dispatch, type SetStateAction } from 'react';

export const SettlementMonitoringInteractionsContext = createContext<
  SettlementMonitoringInteractionsContextProps | undefined
>(undefined);

export type SettlementMonitoringInteractionsContextProps = {
  /** Open a filter builder clause, and create it if it doesn't exist */
  openClause: UseAccordionFilterBuilderOutput['openClause'] | undefined;
  setOpenClause: Dispatch<SetStateAction<UseAccordionFilterBuilderOutput['openClause'] | undefined>>;
};

export function useSettlementMonitoringInteractions() {
  const context = useContext(SettlementMonitoringInteractionsContext);
  if (context === undefined) {
    throw new Error(
      'Missing SettlementMonitoringInteractionsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

// This provider holds a few functions at the top of the page which any child can use in order to interact with different elements of the page
export const SettlementMonitoringInteractionsProvider = function SettlementMonitoringInteractionsProvider({
  children,
}) {
  const [openClause, setOpenClause] = useState<SettlementMonitoringInteractionsContextProps['openClause']>(undefined);

  const value = useMemo(() => {
    return {
      openClause,
      setOpenClause,
    };
  }, [openClause]);

  return (
    <SettlementMonitoringInteractionsContext.Provider value={value}>
      {children}
    </SettlementMonitoringInteractionsContext.Provider>
  );
};
