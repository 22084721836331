import type { useDisclosureWithContext } from '@talos/kyoko';
import {
  Button,
  ButtonGroup,
  ButtonVariants,
  Flex,
  InlineFormattedNumber,
  Input,
  PreviewView,
  Text,
} from '@talos/kyoko';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import type { CustomerTransactionForm } from './types';

const EXPECTED_CONFIRM_TEXT = 'confirm';

interface BalanceTransactionPreviewProps {
  previewDisclosure: ReturnType<
    typeof useDisclosureWithContext<{ form: CustomerTransactionForm; isForceWithdraw: boolean }>
  >;
  onConfirmTransaction: () => Promise<void>;
}

export function BalanceTransactionPreview({ previewDisclosure, onConfirmTransaction }: BalanceTransactionPreviewProps) {
  const [confirmInput, setConfirmInput] = useState<string>('');

  const onHandleCancel = useCallback(() => {
    previewDisclosure.close();
    setConfirmInput('');
  }, [previewDisclosure]);

  const onHandleConfirm = useCallback(() => {
    onConfirmTransaction()
      .then(() => {
        previewDisclosure.close();
      })
      .finally(() => {
        setConfirmInput('');
      });
  }, [previewDisclosure, onConfirmTransaction]);

  if (previewDisclosure.context === undefined) {
    return null;
  }

  const { form, isForceWithdraw } = previewDisclosure.context;

  return (
    <PreviewView
      disclosure={previewDisclosure}
      header="Confirm Transaction"
      footer={
        <ButtonGroup gap="spacingComfortable">
          <Button data-testid="preview-balance-cancel" variant={ButtonVariants.Default} onClick={onHandleCancel}>
            Cancel
          </Button>
          <Button
            data-testid="preview-balance-confirm"
            variant={ButtonVariants.Primary}
            onClick={onHandleConfirm}
            disabled={confirmInput.toLowerCase() !== EXPECTED_CONFIRM_TEXT}
          >
            Confirm
          </Button>
        </ButtonGroup>
      }
    >
      <Flex flexDirection="column">
        <Flex p="spacingComfortable" flexDirection="column" gap="spacingDefault">
          {form.Counterparty && (
            <ItemRow>
              <Text>Customer</Text>
              <Text>{form.Counterparty}</Text>
            </ItemRow>
          )}
          {form.MarketAccount && (
            <ItemRow>
              <Text>Customer Account</Text>
              <Text>{form.MarketAccount}</Text>
            </ItemRow>
          )}
          {form.Amount && (
            <ItemRow>
              <Text>Amount</Text>
              <InlineFormattedNumber number={form.Amount} currency={form.Currency} trimTrailingZeroes />
            </ItemRow>
          )}
          {form.Type && (
            <ItemRow>
              <Text>Type</Text>
              <Text>{form.Type}</Text>
            </ItemRow>
          )}
          {form.Comments && (
            <ItemRow>
              <Text>Comments</Text>
              <Text>{form.Comments}</Text>
            </ItemRow>
          )}
          {form.ExternalComments && (
            <ItemRow>
              <Text>External Comments</Text>
              <Text>{form.ExternalComments}</Text>
            </ItemRow>
          )}
          {form.ExternalID && (
            <ItemRow>
              <Text>ExternalID</Text>
              <Text>{form.ExternalID}</Text>
            </ItemRow>
          )}
          {form.TxHash && (
            <ItemRow>
              <Text>TxHash</Text>
              <Text>{form.TxHash}</Text>
            </ItemRow>
          )}
          {isForceWithdraw && (
            <ItemRow>
              <Text>Force Withdraw</Text>
              <Text>Yes</Text>
            </ItemRow>
          )}

          <ItemRow mt="spacingSmall">
            {`Type "${EXPECTED_CONFIRM_TEXT}" to continue with the transaction`}
            <Input
              width="12ch"
              value={confirmInput}
              onChange={e => setConfirmInput(e.target.value)}
              data-testid="preview-transaction-input"
            />
          </ItemRow>
        </Flex>
      </Flex>
    </PreviewView>
  );
}

const ItemRow = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacingDefault}px;
  align-items: center;

  > *:last-child {
    color: ${({ theme }) => theme.colorTextImportant};
  }
`;
