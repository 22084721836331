import { isMarketOnline, type ConnectionType } from '@talos/kyoko';
import { useMemo } from 'react';
import type { AvailabilityCondition } from '../types';

interface UseMarketOnlineConditionParams {
  connectionType: ConnectionType;
}

/**
 * A AvailabilityCondition asserting that the Market.[ConnectionType] is present (defined) and that Market.[ConnectionType].Status
 * is Online
 */
export const useMarketOnlineCondition = ({ connectionType }: UseMarketOnlineConditionParams): AvailabilityCondition => {
  return useMemo(
    () =>
      ({
        id: 'marketOnline',
        ready: true,
        condition: market => {
          const ok = isMarketOnline(market, connectionType);
          return ok
            ? { available: true }
            : { available: false, failedReason: `Market is not online for connection type ${connectionType}` };
        },
      } satisfies AvailabilityCondition),
    [connectionType]
  );
};
