import {
  Box,
  Button,
  ButtonGroup,
  ButtonVariants,
  FormGroup,
  HStack,
  LoaderTalos,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  ReconResolutionEnum,
  ReconResolutionEnumLabels,
  ReconStateEnum,
  SearchSelect,
  TextArea,
  ToggleButton,
  VStack,
  reduceReconStatuses,
  request,
  useDynamicCallback,
  useEndpointsContext,
  useGlobalToasts,
  useMixpanel,
} from '@talos/kyoko';
import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useReconMismatches } from '../../containers/Trading/Markets/Recon/useReconMismatches';
import { ReconMismatchesSummary } from './ReconMismatchesSummary';

type ResolveType = 'manualresolve' | 'ignore';

interface ResolveReconMismatchesDrawerContextProps {
  mismatchIDs: string[];
}

const RESOLUTION_TYPES = [
  ReconResolutionEnum.MissedTrade,
  ReconResolutionEnum.MissedFees,
  ReconResolutionEnum.MissedTransfer,
  ReconResolutionEnum.BelowThreshold,
  ReconResolutionEnum.InitialPosition,
];
const getReconResolutionEnumLabel = (item: ReconResolutionEnum) => ReconResolutionEnumLabels[item];

export const ResolveReconMismatchesDrawerContent = ({ mismatchIDs }: ResolveReconMismatchesDrawerContextProps) => {
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const [resolveType, setResolveType] = useState<ResolveType>('manualresolve');
  const [comments, setComments] = useState<string>('');
  const [resolution, setResolution] = useState<ReconResolutionEnum | undefined>();
  const { orgApiEndpoint } = useEndpointsContext();
  const resolveEndpoint = `${orgApiEndpoint}/recon/mismatch/${resolveType.toLowerCase()}`;
  const { add: addToast } = useGlobalToasts();

  const resolveMismatches = useDynamicCallback((ids: string[]) => {
    mixpanel.track(MixpanelEvent.ResolveReconMismatches, {
      [MixpanelEventProperty.Amount]: ids.length,
      [MixpanelEventProperty.Type]: resolveType,
    });

    request('POST', resolveEndpoint, { IDs: ids, Resolution: resolution, Comments: comments })
      .then(() => {
        addToast({
          variant: NotificationVariants.Positive,
          text: 'Successfully resolved mismatch.',
        });
      })
      .catch((e: ErrorEvent) => {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Unable to resolve mismatch: ${e?.message}`,
        });
      });
  });

  const reconMismatches = useReconMismatches(mismatchIDs, 'ResolveReconMismatchesDrawerContent');

  const reducedMismatchesState = useMemo(
    () => (reconMismatches ? reduceReconStatuses(reconMismatches.map(rm => rm.State)) : undefined),
    [reconMismatches]
  );

  if (!reconMismatches) {
    return <LoaderTalos />;
  }

  const applyDisabled =
    comments === '' || resolution == null || reducedMismatchesState !== ReconStateEnum.PendingReview;

  return (
    <VStack w="100%" h="100%" justifyContent="space-between" data-testid="resolve-recon-mismatches-drawer">
      <Box borderBottom={`1px solid ${theme.backgroundBody}`} p="spacingMedium" w="100%">
        <ReconMismatchesSummary reconMismatches={reconMismatches} />
      </Box>
      <VStack p="spacingMedium" w="100%" h="100%" justifyContent="flex-start">
        <ButtonGroup w="100%" mb="spacingMedium">
          <ToggleButton selected={resolveType === 'manualresolve'} onClick={() => setResolveType('manualresolve')}>
            Resolve
          </ToggleButton>
          <ToggleButton selected={resolveType === 'ignore'} onClick={() => setResolveType('ignore')}>
            Ignore
          </ToggleButton>
        </ButtonGroup>

        <FormGroup w="100%" label={getText(resolveType, 'resolutionLabel')} mb="spacingMedium">
          <SearchSelect
            options={RESOLUTION_TYPES}
            selection={resolution}
            getLabel={getReconResolutionEnumLabel}
            onChange={r => setResolution(r)}
            data-testid="resolution-type-selector"
          />
        </FormGroup>

        <FormGroup w="100%" label="Comments*" mb="spacingMedium">
          <TextArea
            rows={1}
            value={comments}
            onChange={e => setComments(e.target.value)}
            style={{ textAlign: 'left' }}
            data-testid="comment-text-area"
          />
        </FormGroup>
      </VStack>

      <HStack w="100%" p="spacingMedium" borderTop={`1px solid ${theme.backgroundBody}`}>
        <Button
          width="100%"
          variant={ButtonVariants.Primary}
          onClick={() => resolveMismatches(mismatchIDs)}
          disabled={applyDisabled}
          data-testid="apply-button"
        >
          Apply
        </Button>
      </HStack>
    </VStack>
  );
};

function getText(resolveType: ResolveType, textKey: keyof Texts): string {
  return resolveTypes[resolveType][textKey];
}

interface Texts {
  titlePrefix: string;
  confirmLabel: string;
  resolutionLabel: string;
}

const resolveTypes = {
  ignore: {
    titlePrefix: 'Ignore',
    confirmLabel: 'Ignore',
    resolutionLabel: 'Ignore as*',
  },
  manualresolve: {
    titlePrefix: 'Resolve',
    confirmLabel: 'Resolve',
    resolutionLabel: 'Resolve as*',
  },
} as const satisfies { [key in ResolveType]: Texts };
