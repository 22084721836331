import { SearchSelect } from '@talos/kyoko';
import { useAssetOptions } from 'containers/Transfers';
import { useMemo } from 'react';

import type { TransferOverviewSelector } from '../../types';

export interface AssetsSelectorProps extends TransferOverviewSelector<string> {
  onChange: (asset?: string) => void;
  market?: string;
  marketAccountID?: number;
  value: string;
}

const getLabel = (asset: string) => asset;

export function AssetsSelector({
  market,
  marketAccountID,
  onChange,
  value,
  ...searchSelectProps
}: AssetsSelectorProps) {
  const assetOptions = useAssetOptions({ market, marketAccountID });
  const memoizedAssetOptions = useMemo(() => [...(assetOptions ?? [])], [assetOptions]);

  return (
    <SearchSelect
      {...searchSelectProps}
      selection={value}
      options={memoizedAssetOptions}
      onChange={onChange}
      getLabel={getLabel}
      data-testid="assets-select"
    />
  );
}
