import {
  DELETE,
  PUT,
  TreasuryLink,
  TreasuryLinkStatusEnum,
  request,
  useEndpointsContext,
  type TreasuryLinkSourceOrDestinationField,
} from '@talos/kyoko';
import type { TreasuryLinkRequest } from 'containers/Portfolio/types';
import { usePortfolioAccounts } from 'providers';
import { useCallback } from 'react';

export const useTreasuryRequests = () => {
  const { orgApiEndpoint } = useEndpointsContext();
  const treasuryEndpoint = `${orgApiEndpoint}/treasury`;
  const { updateTreasuryLinks } = usePortfolioAccounts();

  const putTreasuryLink = useCallback(
    async (body: TreasuryLinkRequest) => {
      return request<{ data: TreasuryLink[] }>(PUT, `${treasuryEndpoint}/accounts/link`, {
        ...body,
        /**
         * we always send Active. This is a safety net + backwards compat thing as of 2.45.1 where we
         * no longer toggle Links between Active and Inactive.
         *
         * The goal in the future is to remove this field all-together. If a Link exists in the frontend, it should be regarded as Active by definition.
         */
        Status: TreasuryLinkStatusEnum.Active,
      }).then(response => {
        updateTreasuryLinks(response.data.map(updatedLink => new TreasuryLink(updatedLink)));
      });
    },
    [treasuryEndpoint, updateTreasuryLinks]
  );

  const resetTreasuryLinkField = useCallback(
    (treasuryLink: TreasuryLinkRequest, field: TreasuryLinkSourceOrDestinationField) => {
      return putTreasuryLink({
        ...treasuryLink,
        [field]: undefined,
      }).then(() => {
        // Immediately reflect this field being reset on the blotters.
        treasuryLink[field] = undefined;
      });
    },
    [putTreasuryLink]
  );

  const deleteTreasuryLink = useCallback(
    (linkID: string) => {
      return request(DELETE, `${treasuryEndpoint}/accounts/link/${linkID}`);
    },
    [treasuryEndpoint]
  );

  return {
    putTreasuryLink,
    resetTreasuryLinkField,
    deleteTreasuryLink,
  };
};
