import type { DetailsDrawerState } from 'components/OMS/Details/DetailsDrawerSlice';
import { OMSView } from 'components/OMS/OMSView';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useCallback } from 'react';
import { openDetails } from '../OMS/Details/DetailsDrawerSlice';
import { closeView, openView, selectView } from '../OMS/OMSSlice';

/**
 *
 * @returns
 */
export function useDetailsDrawer() {
  const view = useAppStateSelector(selectView);
  const state = useAppStateSelector(state => state.detailsDrawer);
  const dispatch = useAppStateDispatch();

  const openDetailsDrawer = useCallback(
    ({
      entity,
      getDetailsHref,
      onClickTransaction,
    }: {
      entity: DetailsDrawerState['entity'];
      getDetailsHref?: DetailsDrawerState['getDetailsHref'];
      onClickTransaction?: DetailsDrawerState['onClickTransaction'];
    }) => {
      dispatch(openDetails({ entity, getDetailsHref, onClickTransaction }));
      dispatch(openView(OMSView.DetailsDrawer));
    },
    [dispatch]
  );

  const closeForm = useCallback(() => {
    dispatch(closeView());
  }, [dispatch]);

  return {
    openDetailsDrawer,
    closeDetailsDrawer: closeForm,
    isOpen: view === OMSView.DetailsDrawer,
    entity: state.entity,
    width: state.width,
    getDetailsHref: state.getDetailsHref,
    onClickTransaction: state.onClickTransaction,
  };
}

export type UseDetailsDrawerReturn = ReturnType<typeof useDetailsDrawer>;
