import { useMarketAccountsContext, useObservableValue } from '@talos/kyoko';
import { getSourceDestinationKey, usePortfolioAccounts, useTransfers } from 'providers';
import { useMemo } from 'react';

/**
 * Listens to transfers and maps them to position-like data structure MarketAccount-Currency
 */
export const useTransfersByPosition = () => {
  const { transfersObs } = useTransfers();
  const transfers: any[] | undefined = useObservableValue(() => transfersObs, [transfersObs]);
  const { treasuryLinksBySourceDestinationMarketAccounts } = usePortfolioAccounts();
  const { marketAccountsByID } = useMarketAccountsContext();

  const transfersByMarketAccountAndCurrency: Map<string, Map<string, any[]>> | undefined = useMemo(() => {
    if (transfers == null || treasuryLinksBySourceDestinationMarketAccounts == null) {
      return undefined;
    }

    const newMap = new Map<string, Map<string, any>>();

    transfers.forEach(transfer => {
      const fromID = transfer.fromMarketAccountID;
      const toID = transfer.toMarketAccountID;
      const fromMktAcc = marketAccountsByID.get(+fromID);
      const toMktAcc = marketAccountsByID.get(+toID);

      if (!fromMktAcc || !toMktAcc) {
        return;
      }

      // for each transfer lets get the MarketAccount.Name and Currency we can use to check for related positions
      const fromMktAccName = fromMktAcc.Name;
      const toMktAccName = toMktAcc.Name;

      const key = getSourceDestinationKey(fromMktAccName, toMktAccName);
      const link = treasuryLinksBySourceDestinationMarketAccounts.get(key);

      if (!link) {
        return;
      }
      const mktAcc = link.getMarketAccountOrWildcard;
      const currency = transfer.currency;

      if (!newMap.has(mktAcc)) {
        newMap.set(mktAcc, new Map());
      }

      const mktAccMap = newMap.get(mktAcc) as Map<string, any[]>; // enforce
      const entry = mktAccMap.get(currency);
      if (entry) {
        entry.push(transfer);
      } else {
        mktAccMap.set(currency, [transfer]);
      }
    });

    return newMap;
  }, [transfers, treasuryLinksBySourceDestinationMarketAccounts, marketAccountsByID]);

  return transfersByMarketAccountAndCurrency;
};
