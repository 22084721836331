import { Card, TableSize, useSecurity, type CardProps, type Order } from '@talos/kyoko';
import { ContractDetails } from 'components/ContractDetails';
import { canViewContractDetails } from '../../../../../components/ContractDetails/utils';

export interface ContractDetailsCardProps extends CardProps {
  order: Order;
  showExpandedDetails?: boolean;
}

export function ContractDetailsCard({ order, showExpandedDetails, ...props }: ContractDetailsCardProps) {
  const orderSecurity = useSecurity(order.Symbol);

  if (!showExpandedDetails || !canViewContractDetails(orderSecurity)) {
    return null;
  }

  return (
    <Card {...props}>
      <Card.Subtitle>Contract Details</Card.Subtitle>
      <ContractDetails
        size={TableSize.Default}
        symbol={order.Symbol}
        selectedMarketAccounts={order.selectedAndTradedMarketAccounts}
        tag="ORDER_FORM"
      />
    </Card>
  );
}
