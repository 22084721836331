import Big from 'big.js';

/**
 * Given a list of numbers, goes through them and computes their relative sizes to each other
 * Returns a value 0-1, 1 meaning that you are the biggest value, and any number smaller than that
 * signifying your relative size to the biggest value
 * @param values
 * @returns an array of number
 * Returns undefined if the passed in array is == null
 */
export const getRelativeSizes = (values: number[]): number[] | undefined => {
  if (!values) {
    return undefined;
  }

  const max = Math.max(...values);
  return values.map(value => value / max);
};

/**
 * Given a list of numbers, calculates what percentage of the total value each one is, returning a list of values from 0-1.
 * For example, [10, 10, 20] -> [0.25, 0.25, 0.5]
 * @param values
 * @returns
 */
export const getFractions = (values: Big[]): number[] => {
  const total = values.reduce((prev, curr) => prev.plus(curr), Big(0));

  if (total.eq(0)) {
    return values.map(() => 0);
  }

  const fractions = values.map(value => value.div(total).toNumber());
  return fractions;
};
