import { Flex, type LedgerAccountTypeEnum, Modal, PopperHeader, useDisclosure, useDynamicCallback } from '@talos/kyoko';
import { createContext, useContext, useMemo, useState } from 'react';
import { AccountLedgerEventsDetails } from '../containers/Trading/Markets/AccountLedgerEvents';

type DetailablePosition = {
  asset: string;
  account: string;
  accountType: LedgerAccountTypeEnum;
};

interface PositionDetailsModalContextProps {
  /** Open the modal to show details for the provided position details */
  open(position: DetailablePosition): void;
}

const PositionDetailsModalContext = createContext<PositionDetailsModalContextProps | null>(null);

export const usePositionDetailsModal = () => {
  const context = useContext(PositionDetailsModalContext);
  if (context == null) {
    throw new Error('Missing PositionDetailsModalContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const PositionDetailsModalProvider = function PositionDetailsModalProvider(
  props: React.PropsWithChildren<unknown>
) {
  const [openedPosition, setOpenedPosition] = useState<DetailablePosition>();
  const disclosure = useDisclosure({ onClose: () => setOpenedPosition(undefined) });

  const open = useDynamicCallback((position: DetailablePosition) => {
    setOpenedPosition(position);
    disclosure.open();
  });

  const value = useMemo(() => ({ open }), [open]);

  return (
    <PositionDetailsModalContext.Provider value={value}>
      {props.children}
      {/* For simplicity, just conditionally render this modal component. Animations are a bit worse but thats acceptable */}
      {disclosure.isOpen && openedPosition != null && (
        <Modal
          {...disclosure}
          minHeight="90vh"
          maxHeight="90vh"
          minWidth="90vw"
          maxWidth="90vw"
          position="relative"
          data-testid="position-details-modal"
        >
          <Flex flexDirection="column" position="absolute" top="0" bottom="0" right="0" left="0">
            <PopperHeader title="Position History" onCloseClicked={() => disclosure.close()} />
            <AccountLedgerEventsDetails {...openedPosition} />
          </Flex>
        </Modal>
      )}
    </PositionDetailsModalContext.Provider>
  );
};
