import {
  Box,
  FeeModeEnum,
  FormControlSizes,
  FormGroup,
  LoaderTalos,
  NumberInput,
  RadioButton,
  toBigWithDefault,
  type MarketAccount,
  type MarketConfig,
} from '@talos/kyoko';
import type { Fee } from 'providers/FeesProvider';
import { useCallback, useMemo, useState } from 'react';
import {
  CustomFeeLevelInputs,
  FeeCell,
  FeeDescription,
  FeeLabel,
  FeeRow,
  FeeSection,
  FeeTitle,
  FeesWrapper,
  Options,
  ShowMoreButton,
} from './styles';
import { FeeSource, type FeeEditorOutput } from './useFeeEditor';

export interface FeeEditorProps {
  marketAccount: MarketAccount;
  marketConfig: MarketConfig;
  feeEditor: FeeEditorOutput;
}

const SHOW_MAX_SYMBOLS = 5;

const toPercentString = (value: string) => toBigWithDefault(value, 0).times(100).toFixed();

export const FeeEditor = ({ marketAccount, marketConfig, feeEditor }: FeeEditorProps) => {
  const formEntry = feeEditor.form.get(marketAccount.Name);
  const feeSource = formEntry?.current.source ?? FeeSource.Market;
  const errors = formEntry?.errors ?? {};
  const [expanded, setExpanded] = useState<Set<number>>(new Set());

  const exchangeFees = useMemo(() => {
    return groupFees((formEntry?.marketFees ?? []).filter(fee => fee.MarketAccount === marketAccount.Name));
  }, [formEntry?.marketFees, marketAccount.Name]);

  const handleSetCustomFee = useCallback(
    (side: FeeModeEnum, newFee: string) => {
      if (side === FeeModeEnum.Maker) {
        feeEditor.updateForm(marketAccount.Name, { makerFeeRate: newFee });
      } else {
        feeEditor.updateForm(marketAccount.Name, { takerFeeRate: newFee });
      }
    },
    [feeEditor, marketAccount.Name]
  );

  const handleSelectFeeSource = useCallback(
    (newFeeSource: FeeSource) => {
      feeEditor.updateForm(marketAccount.Name, { source: newFeeSource });
    },
    [feeEditor, marketAccount.Name]
  );

  if (feeEditor.loading) {
    return (
      <Box h="250px">
        <LoaderTalos />
      </Box>
    );
  }

  return (
    <FeesWrapper data-testid="fee-editor">
      <Options>
        <FeeSection selected={feeSource === FeeSource.Market} data-testid="fee-section-market">
          <Box alignSelf="center" mr="spacingDefault">
            <RadioButton
              checked={feeSource === FeeSource.Market}
              id="Market"
              name="Market"
              onChange={() => handleSelectFeeSource(FeeSource.Market)}
              isDisabled={exchangeFees.length === 0}
            />
          </Box>

          <FeeTitle>
            <h3 onClick={() => handleSelectFeeSource(FeeSource.Market)}>Fee Rates from Exchange</h3>
          </FeeTitle>
          <FeeDescription>
            <p>These fees were retrieved from the exchange and, unless specified, do not apply to a specific symbol</p>
          </FeeDescription>
          {exchangeFees.map((fee: FeeGroup, index: number) => {
            const limit = expanded.has(index) ? fee.Symbols.length : SHOW_MAX_SYMBOLS;
            const canExpand = fee.Symbols.length > SHOW_MAX_SYMBOLS;
            return (
              <FeeRow key={index}>
                <FeeCell style={{ textAlign: 'left' }}>
                  {fee.Symbols.length > 0 ? fee.Symbols.slice(0, limit).join(', \n') : '*'}
                  {canExpand && (
                    <ShowMoreButton
                      size={FormControlSizes.Tiny}
                      ghost={true}
                      onClick={() => {
                        setExpanded(prev => {
                          prev.has(index) ? prev.delete(index) : prev.add(index);
                          return new Set(prev);
                        });
                      }}
                    >
                      {expanded.has(index) ? 'Show less' : 'Show more'}
                    </ShowMoreButton>
                  )}
                </FeeCell>
                <FeeCell>
                  <FeeLabel>Maker</FeeLabel> {toPercentString(fee.MakerFeeRate)}%
                </FeeCell>
                <FeeCell>
                  <FeeLabel>Taker</FeeLabel> {toPercentString(fee.TakerFeeRate)}%
                </FeeCell>
              </FeeRow>
            );
          })}
        </FeeSection>

        <FeeSection selected={feeSource === FeeSource.Custom} data-testid="fee-section-custom">
          <Box alignSelf="center" mr="spacingDefault">
            <RadioButton
              checked={feeSource === FeeSource.Custom}
              id="Custom"
              name="Custom"
              onChange={() => handleSelectFeeSource(FeeSource.Custom)}
              isDisabled={exchangeFees.length === 0}
            />
          </Box>

          <FeeTitle>
            <h3 onClick={() => handleSelectFeeSource(FeeSource.Custom)}>Custom Fee Level</h3>
          </FeeTitle>
          <FeeDescription>
            <p>
              Enter your own fee level to override the exchange level.{' '}
              {marketConfig.feesUrl ? (
                <>
                  For reference, you can find more information here:{' '}
                  <a href={marketConfig.feesUrl} target="_blank" rel="noreferrer">
                    {marketConfig.feesUrl}
                  </a>
                </>
              ) : (
                ''
              )}
            </p>
          </FeeDescription>
          <CustomFeeLevelInputs gap="spacingLarge" mt="spacingLarge">
            <FeeCell />
            <FeeCell>
              <FormGroup error={errors?.makerFeeRate}>
                <NumberInput
                  data-testid="custom-maker-fee-input"
                  invalid={!!errors?.makerFeeRate}
                  value={formEntry?.current.makerFeeRate ?? '0.0'}
                  onChange={value => handleSetCustomFee(FeeModeEnum.Maker, value)}
                  suffix="%"
                  defaultIncrement="0.01"
                  prefix={FeeModeEnum.Maker}
                  disabled={feeSource !== FeeSource.Custom}
                />
              </FormGroup>
            </FeeCell>
            <FeeCell>
              <FormGroup error={errors?.takerFeeRate}>
                <NumberInput
                  data-testid="custom-taker-fee-input"
                  invalid={!!errors?.takerFeeRate}
                  value={formEntry?.current.takerFeeRate ?? '0.0'}
                  onChange={value => handleSetCustomFee(FeeModeEnum.Taker, value)}
                  suffix="%"
                  defaultIncrement="0.01"
                  prefix={FeeModeEnum.Taker}
                  disabled={feeSource !== FeeSource.Custom}
                />
              </FormGroup>
            </FeeCell>
          </CustomFeeLevelInputs>
        </FeeSection>
      </Options>
    </FeesWrapper>
  );
};

interface FeeGroup {
  Symbols: string[];
  MakerFeeRate: string;
  TakerFeeRate: string;
}

function groupFees(fees: Fee[]): FeeGroup[] {
  const groupedFees = fees.reduce((res, fee) => {
    const symbol = fee.Symbol || '';
    const key = `${fee.MakerFeeRate}___${fee.TakerFeeRate}`;
    if (res.has(key)) {
      res.get(key)?.Symbols.push(symbol);
    } else {
      res.set(key, {
        Symbols: [symbol],
        MakerFeeRate: fee.MakerFeeRate,
        TakerFeeRate: fee.TakerFeeRate,
      });
    }
    return res;
  }, new Map<string, FeeGroup>());
  return Array.from(groupedFees.values());
}
