import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export function useWindowSize(debounceDuration = 0): { width: number; height: number } {
  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(getSize());
    }, debounceDuration);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [debounceDuration]);

  return windowSize;
}
