import {
  ACTION,
  Button,
  HStack,
  IconName,
  LoaderTalos,
  LocalFilterInput,
  MarketTypeEnum,
  Panel,
  PanelContent,
  PanelHeader,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  Text,
  TreasuryLinkTypeEnum,
  VStack,
  useMarketsContext,
  useTabs,
  type MarketAccount,
  type TabProps,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { useCustomersByName } from 'hooks/useCustomer';
import { useCallback, useMemo, useState } from 'react';
import type { DestinationAccount } from '../types';
import { ManageAddresses } from './ManageAddresses';
import { TreasuryLinkEntitiesBlotter } from './TreasuryLinkEntitiesBlotter';
import { getLinkTypeSummary } from './utils/getLinkTypeSummary';

export const enum AddressTypeLabel {
  Customers = 'Customers',
  Exchanges = 'Exchanges',
  OTCs = 'OTCs',
}

interface AddressTabProps extends TabProps {
  label: AddressTypeLabel;
  linkType: TreasuryLinkTypeEnum;
}

export const Addresses = () => {
  const { isAuthorized } = useRoleAuth();

  const TABS = useMemo(() => {
    const effectiveTabs: AddressTabProps[] = [];
    // Only include the Customers tab if we have the VIEW_DEALER permission
    if (isAuthorized(ACTION.VIEW_DEALER)) {
      effectiveTabs.push({
        label: AddressTypeLabel.Customers,
        linkType: TreasuryLinkTypeEnum.CustomerTransfer,
      });
    }
    // Only include the Exchanges and OTCs tabs if we have the VIEW_PORTFOLIO permission
    if (isAuthorized(ACTION.VIEW_PORTFOLIO)) {
      effectiveTabs.push({
        label: AddressTypeLabel.Exchanges,
        linkType: TreasuryLinkTypeEnum.ExchangeRebalance,
      });
      effectiveTabs.push({ label: AddressTypeLabel.OTCs, linkType: TreasuryLinkTypeEnum.OTCSettlement });
    }
    return effectiveTabs;
  }, [isAuthorized]);

  const tabs = useTabs({ initialItems: TABS });
  const [manageAddresses, setManageAddresses] = useState<boolean>(false);
  const { linkType } = TABS[tabs.selectedIndex];

  const { description, title } = getLinkTypeSummary(linkType);
  const [entity, setEntity] = useState<MarketAccount | DestinationAccount>();

  const [filterValue, setFilterValue] = useState<string>('');

  const customersByName = useCustomersByName();
  const { marketsByName } = useMarketsContext();

  const manageAddressesOfMarketAccount = useCallback((entity?: MarketAccount | DestinationAccount) => {
    setEntity(entity);
    setManageAddresses(true);
  }, []);

  if (customersByName == null) {
    return <LoaderTalos />;
  }

  if (manageAddresses) {
    return <ManageAddresses entity={entity} linkType={linkType} onClickReturn={() => setManageAddresses(false)} />;
  }

  return (
    <Panel>
      <PanelHeader>
        <Text size="fontSizeBig" color="colorTextAttention">
          Addresses
        </Text>
      </PanelHeader>
      <PanelContent>
        <Tabs h="100%" gap="spacingMedium" {...tabs} size={TabSize.Large}>
          <HStack w="100%" justifyContent="space-between">
            <TabList>
              {tabs.items.map((tab, index) => (
                <Tab key={index} {...tab} />
              ))}
            </TabList>
            <LocalFilterInput placeholder="Filter by Account" value={filterValue} onChange={setFilterValue} />
          </HStack>

          <HStack
            justifyContent="space-between"
            background="backgroundCard"
            borderRadius="borderRadiusSmall"
            p="spacingLarge"
          >
            <VStack alignItems="flex-start" gap="spacingDefault">
              <Text size="fontSizeBig" color="colorTextAttention">
                {title}
              </Text>
              <Text>{description}</Text>
            </VStack>
            {/* undefined here signifies the global MarketAccount */}
            <Button
              startIcon={IconName.Pencil}
              onClick={() => manageAddressesOfMarketAccount(undefined)}
              data-testid="addresses-manage-defaults"
            >
              Manage Defaults
            </Button>
          </HStack>
          <TabPanels overflow="auto" flex="1">
            {TABS.map(tab => {
              switch (tab.linkType) {
                case TreasuryLinkTypeEnum.CustomerTransfer:
                  return (
                    <TreasuryLinkEntitiesBlotter
                      key={tab.linkType}
                      customersByName={customersByName}
                      onClickRow={manageAddressesOfMarketAccount}
                      filterText={filterValue}
                    />
                  );
                case TreasuryLinkTypeEnum.ExchangeRebalance:
                  return (
                    <TreasuryLinkEntitiesBlotter
                      key={tab.linkType}
                      onClickRow={manageAddressesOfMarketAccount}
                      filterText={filterValue}
                      marketsByName={marketsByName}
                      marketType={MarketTypeEnum.Exchange}
                    />
                  );
                case TreasuryLinkTypeEnum.OTCSettlement:
                  return (
                    <TreasuryLinkEntitiesBlotter
                      key={tab.linkType}
                      onClickRow={manageAddressesOfMarketAccount}
                      filterText={filterValue}
                      marketsByName={marketsByName}
                      marketType={MarketTypeEnum.Dealer}
                    />
                  );
                default:
                  return null;
              }
            })}
          </TabPanels>
        </Tabs>
      </PanelContent>
    </Panel>
  );
};
