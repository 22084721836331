import type { RowGroupsOpenedState } from '@talos/kyoko';
import {
  useTreasuryManagementContext,
  useTreasuryManagementTabs,
} from '../providers/TreasuryManagementStateAndTabsProvider';
import { TREASURY_BLOTTER_PREFIX } from '../tokens';
import { TreasuryManagementBlotter } from './TreasuryManagementBlotter';
import type { TreasuryBlotterColumnSpecification } from './useTreasuryColumnDefinitions';

const sharedColumns = [
  'Amount',
  'Equivalent.Amount',
  'AvailableAmount',
  'Equivalent.AvailableAmount',
  'OutstandingBuy',
  'OutstandingSell',
  'Equivalent.OutstandingBuy',
  'Equivalent.OutstandingSell',
  'buy',
  'sell',
  'withdraw',
  'deposit',
] satisfies TreasuryBlotterColumnSpecification[];

const defaultRowGroupsOpened: RowGroupsOpenedState = {};

const byCounterpartyColumns = [
  'reconWarning',
  { field: 'marketAccountGroup', rowGroupIndex: 0, rowGroup: false, hide: true },
  { field: 'marketAccountName', rowGroupIndex: 1, rowGroup: true, hide: true },
  'Currency',
  ...sharedColumns,
] satisfies TreasuryBlotterColumnSpecification[];

const byAssetColumns = [
  'reconWarning',
  { field: 'Currency', rowGroup: true, hide: true },
  'marketAccountName',
  ...sharedColumns,
] satisfies TreasuryBlotterColumnSpecification[];

// This component wraps the blotter and specifically handles grabbing the correct blotterID and passing it to the blotter,
// also making sure to re-mount the blotter on blotterID change
export const TreasuryManagementBlotterContainer = () => {
  const { currentTab } = useTreasuryManagementTabs();
  const {
    state: { showBy },
  } = useTreasuryManagementContext();

  const columnsToUse = showBy === 'asset' ? byAssetColumns : byCounterpartyColumns;

  // For each tab, we keep two blotter IDs, one for each showBy (by counterparty or by asset). These are regarded as different blotters
  const blotterID = `${TREASURY_BLOTTER_PREFIX}/${currentTab.id}/${showBy}`;
  return (
    <TreasuryManagementBlotter
      key={blotterID}
      blotterID={blotterID}
      defaultColumns={columnsToUse}
      defaultRowGroupsOpened={defaultRowGroupsOpened}
    />
  );
};
