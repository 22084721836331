import { IconName, MarketTypeEnum, type FilterableProperty } from '@talos/kyoko';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for Market types properties.
 *
 * @returns Filter Property definition to use for Market types filters
 */

export function useMarketTypesFilter() {
  return useMemo(
    () => ({
      ...({
        key: 'Types',
        label: 'Type',
        icon: IconName.Cube,
        options: Object.values(MarketTypeEnum),
        getOptionLabel: (option: string) => option,
      } as const satisfies FilterableProperty),
    }),
    []
  );
}
