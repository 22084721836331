import { keys } from 'lodash';
import { MAX_NUM_COLUMNS } from '../../tokens/appconfig';
import { MarketTabType } from '../MarketTabs.types';
import { DEFAULT_APP_CONFIG } from './AppConfigProvider.types';
import { fillToNumColumns } from './fillToNumColumns';
import { replaceLegacyConfig } from './replaceLegacyConfig';

export const initialValues = (c = {}) => {
  const appConfigInitials = replaceLegacyConfig(c);
  keys(DEFAULT_APP_CONFIG).forEach(key => {
    appConfigInitials[key] = key in appConfigInitials ? appConfigInitials[key] : DEFAULT_APP_CONFIG[key];
  });

  // Fill market tab columns with xNUM_COLUMNS Empty Arrays
  const newMarketTabs = [...(appConfigInitials?.marketTabs ?? [])];
  for (const marketTab of newMarketTabs) {
    if (
      (!marketTab.type || marketTab.type === MarketTabType.Market) &&
      (marketTab?.columns?.length ?? 0) < MAX_NUM_COLUMNS
    ) {
      marketTab.columns = fillToNumColumns(marketTab?.columns ?? []);
    }
  }
  appConfigInitials.marketTabs = newMarketTabs;

  return appConfigInitials;
};
