import { Icon, IconName } from '../Icons';
import {
  ItemContent,
  ItemContentWrapper,
  ItemHeader,
  ItemHeaderDetails,
  ItemInitiator,
  ItemLabel,
  ItemTimestamp,
  ItemTrack,
  ItemTrackContainer,
  ItemWrapper,
} from './styles';
import type { TimelineItemProps } from './types';

export function TimelineItem({
  icon = IconName.Circle,
  variant = 'default',
  timestamp,
  label,
  content,
  actions,
  initiator,
}: TimelineItemProps) {
  return (
    <ItemWrapper>
      <ItemTrackContainer variant={variant}>
        <Icon icon={icon} size={16} />
        <ItemTrack />
      </ItemTrackContainer>

      <ItemContentWrapper>
        <ItemHeader>
          <div>
            {label != null && <ItemLabel>{label}</ItemLabel>}
            <ItemHeaderDetails>
              {initiator != null && <ItemInitiator>{initiator}</ItemInitiator>}
              {initiator != null && timestamp != null && <>&bull;</>}
              {timestamp != null && <ItemTimestamp>{timestamp}</ItemTimestamp>}
            </ItemHeaderDetails>
          </div>
          <div>{actions}</div>
        </ItemHeader>
        {content != null && <ItemContent>{content}</ItemContent>}
      </ItemContentWrapper>
    </ItemWrapper>
  );
}
