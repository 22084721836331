import { useCallback, useEffect, useState } from 'react';

import {
  Button,
  FormControlSizes,
  HStack,
  Text,
  useObservableValue,
  type BoxProps,
  type CustomerTransaction,
} from '@talos/kyoko';

import { useCustomersContext } from 'hooks/useCustomer';

import { DetailsRow } from './DetailsRow';

interface CustomerWithdrawAddressRowProps extends Pick<BoxProps, 'color' | 'justifyContent' | 'w'> {
  entity: CustomerTransaction;
}

export function CustomerWithdrawAddressRow({ color, entity, ...hstackProps }: CustomerWithdrawAddressRowProps) {
  const { getCustomerWithdrawAddress, customerAddressesByCustomerAddressID: addressMapObs } = useCustomersContext();
  const customerAddressesByCustomerAddressID = useObservableValue(() => addressMapObs, [addressMapObs]);
  const [customerWithdrawAddress, setCustomerWithdrawAddress] = useState<string | undefined>();
  const [loadedCustomerWithdrawAddress, setLoadedCustomerWithdrawAddress] = useState<boolean>(false);
  const { ClReqID } = entity;

  const handleOnClickShowAddress = useCallback(() => {
    if (entity.CustomerAddressID) {
      getCustomerWithdrawAddress(entity.CustomerAddressID)
        .then(({ data }) => {
          setLoadedCustomerWithdrawAddress(true);
          const response = data.at(0);
          if (response != null) {
            const walletAddress = response.RoutingInfo.WalletAddress;
            const addressName = customerAddressesByCustomerAddressID?.get(response.CustomerAddressID)?.Name;
            setCustomerWithdrawAddress(walletAddress ?? addressName);
          }
        })
        .catch(e => {
          setLoadedCustomerWithdrawAddress(true);
        });
    } else {
      setLoadedCustomerWithdrawAddress(true);
    }
  }, [entity.CustomerAddressID, getCustomerWithdrawAddress, customerAddressesByCustomerAddressID]);

  useEffect(() => {
    // Once we switch to a new transaction, forget the previous customer withdraw address
    setLoadedCustomerWithdrawAddress(false);
    setCustomerWithdrawAddress(undefined);
  }, [ClReqID]);

  return (
    <DetailsRow color={color} title={`${entity.TransactionType} Address`}>
      <HStack {...hstackProps} gap="spacingSmall" h="20px">
        <Text data-testid="customer-withdrawal-address">
          {loadedCustomerWithdrawAddress ? customerWithdrawAddress ?? 'No Address Specified' : ''}
        </Text>
        {!loadedCustomerWithdrawAddress && (
          <Button
            size={FormControlSizes.Small}
            onClick={handleOnClickShowAddress}
            data-testid="show-customer-withdrawal-address-button"
          >
            Show
          </Button>
        )}
      </HStack>
    </DetailsRow>
  );
}
