import {
  AccordionGroup,
  Box,
  ExpandableBottomPanel,
  HStack,
  type SubAccountReconMatch,
  Tab,
  TabAppearance,
  TabList,
  TabSize,
  Tabs,
  VStack,
  useDrawer,
  useDynamicCallback,
  useExpandablePanel,
  useTabs,
} from '@talos/kyoko';
import { type RefObject, useCallback, useMemo, useState } from 'react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { BlotterWrapper, XScrollableContainer } from '../../styles';

import { BreakResolutionDrawer } from '../BreakResolution/BreakResolutionDrawer';
import type { ReconBreak } from '../BreakResolution/types';
import type { ReconAssetRow } from '../ReconOverview/blotter/reconCheckpointRows';
import { SubAccountReconOverviewBlotter } from '../ReconOverview/blotter/SubAccountReconOverviewBlotter';
import { useReconOverviewDataObs } from '../ReconOverview/blotter/useReconOverviewDataObs';
import type { BreakDetailsState } from '../types';
import { reconAssetRowToBreak, reconMatchToBreak } from '../utils';
import { BreakDetailsBlotterWrapper } from './BreakDetailsBlotter/BreakDetailsBlotterWrapper';
import { ControlPanel } from './components/ControlPanel';
import { BreakDetailsStateProvider } from './providers/BreakDetailsStateProvider';

const DEFAULT_BLOTTER_HEIGHT = 450;
const BOTTOM_BLOTTER_HEIGHT = 'BreakDetailsBottomBlotterHeight';

const BOTTOM_BLOTTER_TABS = [
  {
    label: 'Transactions',
  },
];

interface BreakDetailsProps {
  state: BreakDetailsState;
  modalContainerRef: RefObject<HTMLDivElement>;
}

export const BreakDetails = ({ state, modalContainerRef }: BreakDetailsProps) => {
  const checkpointIDs = useMemo(() => [state.checkpointID], [state.checkpointID]);

  const [isBlotterMaximized, setIsBlotterMaximized] = useState(false);
  const [isBlotterMinimized, setIsBlotterMinimized] = useState(false);

  const handleMaximizeBlotter = useDynamicCallback((shouldExpand: boolean) => {
    setIsBlotterMinimized(false);
    setIsBlotterMaximized(shouldExpand);
  });

  const handleMinimizeBlotter = useDynamicCallback((shouldMinimize: boolean) => {
    setIsBlotterMaximized(false);
    setIsBlotterMinimized(shouldMinimize);
  });

  const { containerRef, ...panelProps } = useExpandablePanel<HTMLDivElement>({
    initialHeight: parseInt(localStorage.getItem(BOTTOM_BLOTTER_HEIGHT) ?? '0') || DEFAULT_BLOTTER_HEIGHT,
    isExpanded: isBlotterMaximized,
    isMinimized: isBlotterMinimized,
    onToggleExpanded: handleMaximizeBlotter,
    onToggleMinimize: handleMinimizeBlotter,
    onAdjustedHeight(newHeight) {
      localStorage.setItem(BOTTOM_BLOTTER_HEIGHT, newHeight.toString());
    },
  });

  const bottomBlotterTabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: BOTTOM_BLOTTER_TABS,
  });

  const [resolvingBreak, setResolvingBreak] = useState<ReconBreak>();
  const resolutionDrawer = useDrawer({
    closeOnClickOutside: false,
    closeOnEscape: false,
    position: 'relative',
    placement: 'right',
    width: 420,
  });

  const { open: openDrawer } = resolutionDrawer;

  const handleClickResolveMatch = useCallback(
    (match: SubAccountReconMatch) => {
      setResolvingBreak(reconMatchToBreak(match, state.subAccounts));
      openDrawer();
    },
    [openDrawer, state.subAccounts]
  );

  const handleClickResolveCheckpoint = useCallback(
    (cp: ReconAssetRow) => {
      setResolvingBreak(reconAssetRowToBreak(cp));
      openDrawer();
    },
    [openDrawer]
  );

  const [showZeroBalances, setShowZeroBalances] = useState(false);
  const { dataObservable } = useReconOverviewDataObs({ checkpointIDs, showZeroBalances, tag: 'details' });

  return (
    <BreakDetailsStateProvider state={state}>
      <HStack w="100%" h="100%" flex="1" overflow="hidden" background="backgroundBody" gap="2px">
        <VStack w="100%" h="100%" flex="1" position="relative">
          <AccordionGroup>
            <ControlPanel />
          </AccordionGroup>
          <Box flex="1" w="100%" overflow="hidden">
            <VStack w="100%" h="100%" flex="1" ref={containerRef}>
              <XScrollableContainer w="100%">
                <HStack minHeight="150px" w="100%" h="100%" minWidth="1000px" gap="spacingTiny">
                  <SubAccountReconOverviewBlotter
                    dataObservable={dataObservable}
                    onResolveCheckpoint={handleClickResolveCheckpoint}
                    // make this a static id, its a temporary blotter
                    blotterID="recon-break-details-focused-checkpoint-blotter"
                    isInModal
                    showZeroBalances={showZeroBalances}
                    setShowZeroBalances={setShowZeroBalances}
                  />
                </HStack>
              </XScrollableContainer>
              <BlotterWrapper>
                <ExpandableBottomPanel
                  {...panelProps}
                  modalContainerRef={modalContainerRef}
                  showControls
                  header={
                    <Tabs {...bottomBlotterTabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
                      <TabList isBordered>
                        {BOTTOM_BLOTTER_TABS.map((tab, i) => (
                          <Tab key={i} {...tab} />
                        ))}
                      </TabList>
                    </Tabs>
                  }
                >
                  <ErrorBoundary>
                    <BreakDetailsBlotterWrapper
                      onClickResolve={handleClickResolveMatch}
                      checkpointID={state.checkpointID}
                    />
                  </ErrorBoundary>
                </ExpandableBottomPanel>
              </BlotterWrapper>
            </VStack>
          </Box>
        </VStack>

        <BreakResolutionDrawer {...resolutionDrawer} reconBreak={resolvingBreak} />
      </HStack>
    </BreakDetailsStateProvider>
  );
};
