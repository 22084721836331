import { catchError, concatMap, EMPTY, of, type OperatorFunction, pipe } from 'rxjs';

/**
 * Wrap an observable pipe with error handling to allow pipe to continue.
 * If an error occurs, the error handler will be called and the observable will continue.
 * @param pipeToWrap The pipe to wrap with error handling
 * @param errorHandler The error handler to call when an error occurs - this will not stop the observable
 * @returns The wrapped pipe - any errors will be caught but the observable will continue
 */
export function wrapForErrorHandling<FromObsValue, ToObsValue = FromObsValue>({
  wrappedPipe,
  errorHandler,
}: {
  /** The pipe (or simple unary rx function, like a map) to wrap with error handling */
  wrappedPipe: OperatorFunction<FromObsValue, ToObsValue>;
  /** The error handler to call when an error occurs - this will not stop the observable */
  errorHandler: (error: unknown) => void;
}) {
  return pipe(
    concatMap((message: FromObsValue) =>
      of(message).pipe(
        wrappedPipe,
        catchError(error => {
          errorHandler(error);
          // Return an empty observable to ignore the error and continue
          return EMPTY;
        })
      )
    )
  );
}
