import { LookbackOption } from '@talos/kyoko';
import type { CustomerTransactionsColumn, CustomerTransactionsFilter, CustomerTransactionsTabsProps } from './types';

export const defaultCustomerTransactionColumns: CustomerTransactionsColumn[] = [
  'Timestamp',
  'Counterparty',
  'MarketAccount',
  'Currency',
  'Quantity',
  'TransactionType',
  'Status',
  'ExternalComments',
];
export const defaultCustomerTransactionFilter: CustomerTransactionsFilter = {
  _start: LookbackOption.Past30Days,
};

export const DEFAULT_CUSTOMER_TRANSACTION_TABS: CustomerTransactionsTabsProps[] = [
  {
    label: 'Transfers',
    id: 'customer-transfers-default',
    defaultColumns: defaultCustomerTransactionColumns,
    defaultFilter: defaultCustomerTransactionFilter,
  },
];
